import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX, faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
export default function Feedback() {


const surveys = [
    {
        img_url: "assets/1605623761.388814_Biogen01_t.png",
        name: "Tommy Duke",
        role: "Biogen (CBRE) | RTP Bio Facility",
        rating: 5.00,
        feedback: "SBM has always been there when needed from a project perspective. Schedules change, but they seem to adjust accordingly and always get the job done when required!"
    },
    {
        img_url: "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
        name: "Haley Muraisi",
        role: "Meta (CBRE) | Bayfront",
        rating: 4.75,
        feedback: "The partnership we have been able to build with the team has been pivotal to the success of the program/this site. From the friendly day staff to the fast-responding leads, supervisor, and site manager are nothing short of amazing. There have been numerous flags by the SBM team pertaining to building issues / events that have played a huge role in our ability as a team to respond quickly and effectively. So thank you team! The support you provide for day to day work, emergencies, and projects has been a key reason for our sites success. I look forward to our continued partnership. Thank you Luis, Sal, Miguel, and Arturo for leading the teams and proving constant support at Bayfront! Luckily, we have not had to really escalate anything up the chain recently, but wanted to note that Luna you are also appreciated when you do jump into things to assist!"
    },
    {
        img_url: "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
        name: "Kelly Woodard",
        role: "Wells Fargo | Chandler Campus - AZ",
        rating: 4.00,
        feedback: "With my brief tenure at the Wells Fargo Chandler Campus, I believe the team is performing very well. I understand that soft services expectations are very high and there is very little reward."
    }
   
]

function renderStars(rating) {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
        if (i <= rating) {
            stars.push(<FontAwesomeIcon icon={faStar}  />)
        } else if (((i - 1) < rating) && i > rating) {
            stars.push(<FontAwesomeIcon icon={faStarHalfStroke}  />)
        } 
    }
    return stars;
}

  return (
    <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center ">
      <p className="newmonthly-tag text-primary-300 text-center">Feedback</p>
      <p className="newmonthly-title text-center !mb-4">
        The voices of our customers
        <br />
        speak the loudest.
      </p>
      <p className="font-normal text-neutral-500 text-lg mb-6">
        That's why we track feedback from you, our client PoCs, and end users.
      </p>
      <div className="relative mx-auto rounded-xl new-linear z-10 p-8 overflow-hidden mb-6">
        <div className="flex flex-row divide-x-2 divide-white/20">
          <div className="border-l-4 border-primary-300 px-6 pr-12 w-64">
            <p className="font-bold text-white">Surveys Completed</p>
            <p className="text-4xl font-normal text-white">1,661</p>
          </div>

          <div className=" px-6 pr-12 w-60">
            <p className="font-bold text-white">Average Score</p>
            <p className="text-4xl font-normal text-white">
              4.48<span className="text-xl opacity-50">/5</span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-6 flex-wrap">
        {surveys.map((h, i) => {
          return (
            <div className="new-linear rounded-xl p-8 w-[28%] flex-shrink-0 ">
              <div className="flex flex-row items-start justify-between gap-6">
                <div className="flex flex-row items-center justify-center gap-2">
                  <img
                    src={
                      "https://cdn.4insite.com/" +
                      (h.img_url.indexOf("asset") >= 0 ? "" : "image/") +
                      h.img_url
                    }
                    alt=""
                    className="rounded-lg h-12 w-12 object-cover"
                  />
                  <div>
                    <p className="text-lg font-bold text-white">{h.name}</p>
                    <p className="text-sm text-neutral-600 leading-none whitespace-nowrap">
                      {h.role}
                    </p>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <p className="font-normal text-white">
                    {h.rating}
                    <span
                      className={`ml-2 ${
                        h.rating >= 4 ? "text-success" : "text-failure"
                      }`}
                    >
                      {renderStars(h.rating)}
                    </span>
                  </p>
                </div>
              </div>
              <p className="text-white mt-4 line-clamp-4">{h.feedback}</p>
            </div>
          );
        })}
      </div>
    </section>
  );

}