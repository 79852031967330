import {useState, useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './newMonthly.css';
import noise from '../../assets/images/noise.png'

import Agenda from './agenda';
import Globe from './/globe/globe';
import Intro from './intro';

import Highlights from './Highlights';
import Complaints from './complaints';
import Incidents from './incidents';
import Feedback from './feedback';
import SOW from './sow';
import Next from './next'
import Market from './market';
import People from './people';


gsap.registerPlugin(ScrollToPlugin);
//Highcharts Config
require("highcharts/highcharts-more")(Highcharts);

Highcharts.setOptions({
    colors: ['#4AA8FF', '#734CFF', '#A231D2', '#DC00B6', '#FF7DB1', '#FF4334', '#FF6F19', '#C29201', '#E5C801', '#00FF8D', '#01CCA7', '#D0D5D9']
});

Highcharts.setOptions({
    lang: {
        thousandsSep: ',' 
    }
});
//end Highcharts Config

export default function NewMonthlyHome({setShow}) {
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        let sections = [...document.querySelectorAll('section')];
        document.addEventListener('keydown', (e) => {
            e.preventDefault();
            let position = window.scrollY;

            if (e.key === ' ' || e.key === 'ArrowDown') {
                let nextSection = sections.find(section => section.offsetTop - 100 > position);
                if (nextSection) {
                    gsap.to(window, {duration: 0.75, scrollTo: nextSection.offsetTop, ease: 'power3.inOut'});
                }
            } else if (e.key === 'ArrowUp') {
                let nextSection = sections.slice().reverse().find(section => section.offsetTop + 100 < position);
                if (nextSection) {
                    gsap.to(window, {duration: 0.75, scrollTo: nextSection.offsetTop, ease: 'power3.inOut'});
                }
            }
        })
    }, [])

    return (
        <div className={modalOpen ? "overflow-hidden" : ""}>
            <div className="fixed z-0 h-screen w-screen top-0 left-0 pointer-events-none overflow-hidden"
                style={{
                    backgroundImage: 'radial-gradient(ellipse at top, #24588780, #0C172380 80%), linear-gradient(#0C1723, #0C1723)',
                    // backgroundSize: '200vw 200%',
                    // backgroundPostion: '-50vw -50vh',
                    backgroundRepear: 'no-repeat',
                }}
            >
                <div className="h-full w-full opacity-50" style={{
                    backgroundImage: `url(${noise})`,
                    backgroundRepeat: 'repeat',
                    mixBlendMode: 'screen'
                }}></div>
            </div>
            <div className="fixed z-20 pointer-events-none top-0 left-0 overflow-hidden h-screen w-screen"
            style ={{
                backgroundImage: 'radial-gradient(ellipse at top, transparent 80%, #00000066)',
                backgroundSize: '200vw 200%',
            }}>

            </div>
            <div className="relative z-10 px-12">
                <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center ">
                    {/* Agenda */}
                    <div className="w-full">
                    <p className="newmonthly-tag text-primary-300">February Monthly Report</p>
                    <p className="text-4xl 2xl:text-8xl text-white !font-black">
                        Unlocking performance insights and empowering future-forward success.
                    </p>
                    </div>

                    </section>
                <Agenda />
                <Globe />
                <Intro />
                <Highlights />
                <Complaints />
                <Feedback />
                <SOW />
                <Incidents setShow={setShow}/>
                <People setShow={setShow}/>
                <Market />
                <Next />
            </div>
        </div>
    )
}