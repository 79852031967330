import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import mapImage from "./map_fill.png";
import { sites, sbmSites } from "./cbresites.js";
// import * as d3 from "d3";
import gsap from "gsap";
// import { LineMaterial } from "three/addons/lines/LineMaterial.js";
import { arc, geoInterpolate } from "d3";
// import { CubicBezierCurve3 } from "three";
import { getFresnelMat } from "./getFresnelMap.js";
import { useRef, useEffect } from "react";


export default function Globe() {
    const globeRef = useRef();
    useEffect(() => {
        while (!globeRef.current) {
            console.log("waiting for globeRef");
        }
        initGlobe();
    },[]);


  function loadMapImageAndCreateCanvas(callback) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;

      // Set the background to white
      ctx.fillStyle = "white"; // Set fill style to white
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white

      ctx.drawImage(img, 0, 0, img.width, img.height);
      callback(ctx); // Pass the canvas' context for pixel analysis
    };
    img.src = mapImage;
  }

  function initGlobe() {
    // Create a scene, camera, and renderer
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera(
      35,
      window.innerWidth / (window.innerHeight * 1.5),
      0.1,
      1000
    ); // Move the camera further away
    var renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true });
    renderer.domElement.classList.add("relative");
    renderer.setSize(window.innerWidth, window.innerHeight * 1.5);
    // renderer.setClearColor('transparent'); // 0x808080 is the hexadecimal color code for gray
    globeRef.current.innerHTML = '';
    globeRef.current.appendChild(renderer.domElement);

    // Add resize event listener
    window.addEventListener('resize', () => {
        // Update camera aspect ratio
        camera.aspect = window.innerWidth / (window.innerHeight * 1.5);
        camera.updateProjectionMatrix();
    
        // Resize renderer
        renderer.setSize(window.innerWidth, window.innerHeight * 1.5);
    });

    // Create controls for the camera
    // var controls = new OrbitControls(scene, renderer.domElement);

    // Variables for the phyllotaxis pattern
    // var n = 0; // index
    // var a = 0; // angle
    // var c = 0.0005; // gap
    // var angle = 137.50755; // sunflower phyllotaxis
    var quantity = 60000; // Declare quantity before using it
    var center = new THREE.Vector3();

    // Create a sphere geometry for the dots
    var circleGeometry = new THREE.CircleGeometry(0.02, 5);
    var dotMaterial = new THREE.MeshPhongMaterial({
      // color: 0xffffff,
      color: 0x2151ee,
      side: THREE.DoubleSide,
    });

    // Create an instanced mesh
    var instancedMesh = new THREE.InstancedMesh(
      circleGeometry,
      dotMaterial,
      quantity
    );

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    // Modified dot drawing part to include pixel check
    function drawDotsBasedOnMap() {
      loadMapImageAndCreateCanvas((ctx) => {
        // Calculate the angles increment based on the quantity of dots
        var angleIncrement = Math.PI * (3 - Math.sqrt(5)); // golden angle in radians

        for (var i = 0; i < quantity; i++) {
          // Calculate the position of each dot using a phyllotaxis pattern
          var theta = i * angleIncrement; // azimuthal angle
          theta = theta % (2 * Math.PI); // wrap theta around the sphere
          var phi = Math.acos(1 - (2 * (i + 0.5)) / quantity); // polar angle

          var r = 5; // Increase 'c' or modify this formula to scale up the sphere

          var x = Math.sin(phi) * Math.cos(theta) * r;
          var y = Math.sin(phi) * Math.sin(theta) * r;
          var z = Math.cos(phi) * r;

          // Convert 3D coordinates to 2D image coordinates
          let imageX = Math.floor(
            (1 - theta / (2 * Math.PI)) * ctx.canvas.width
          );
          let imageY = Math.floor((1 - phi / Math.PI) * ctx.canvas.height);

          // Get the pixel data at the calculated image position
          const pixel = ctx.getImageData(imageX, imageY, 1, 1).data;

          // Determine if the pixel is dark (adjust the threshold as needed)
          if (pixel[0] < 200 && pixel[1] < 200 && pixel[2] < 200) {
            // Set the matrix for this instance if dark
            var matrix = new THREE.Matrix4();
            matrix.lookAt(
              new THREE.Vector3(x, y, z),
              center,
              new THREE.Vector3(0, 1, 0)
            );
            matrix.setPosition(x, y, z); // Position only, no need to lookAt, since it's a point

            // Set the matrix for this instance
            instancedMesh.setMatrixAt(i, matrix);
            //generate a random number between -20 and 20
            var random = Math.random() * 100;
            instancedMesh.setColorAt(
              i,
              new THREE.Color(
                (0x21 + random) / 100,
                (0x51 + random) / 100,
                (0x95 + random) / 100
              )
            );
          }
        }
        // Make sure to update the instance matrix
        instancedMesh.instanceMatrix.needsUpdate = true;
        instancedMesh.instanceColor.needsUpdate = true;

        // Add the instanced mesh to the scene if not already added
        if (!scene.children.includes(instancedMesh)) {
          scene.add(instancedMesh);
        }
      });
    }

    drawDotsBasedOnMap();

    // Rotate the instanced mesh so that the north pole is pointing up
    instancedMesh.rotation.x = Math.PI / 2;

    // Add the instanced mesh to the scene instead of the group
    // scene.add(instancedMesh);

    // Position the camera and render the scene
    camera.position.z = 25;
    // renderer.render(scene, camera);

    var innerSphere;
    function createInnerSphere() {
      // Create a sphere geometry
      var sphereGeometry = new THREE.SphereGeometry(4.998, 64, 32); // Slightly smaller than the dot sphere)
      // Create a material with some opacity
      var sphereMaterial = new THREE.MeshBasicMaterial({
        color: 0x0a1e39,
        transparent: true,
        opacity: 0.8,
      });

      // Create a mesh with the sphere geometry and material
      innerSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

      // Add the sphere to the scene
      scene.add(innerSphere);
    }

    createInnerSphere();

    var xOffset = 0; //
    var yOffset = 0; //-2
    instancedMesh.position.x = xOffset;
    innerSphere.position.x = xOffset;
    instancedMesh.position.y = yOffset;
    innerSphere.position.y = yOffset;

    let combinedSites;
    function combineSites(cbre, sbm) {
      cbre.forEach((site) => {
        site.IFM = "CBRE";
        var foundSite = sbm.findIndex((sbmSite) => sbmSite.id === site.id);
        if (foundSite !== -1) {
          //remove sbm site at foundSite Index
          sbm.splice(foundSite, 1);
        }
      });
      sbm.forEach((site) => {
        site.IFM = "other";
      });
      return cbre.concat(sbm);
    }

    combinedSites = combineSites(sites, sbmSites);

    function addCirclesToSphere(dataArray, scene) {
      // const RADIUS = 5.01; // Slightly larger than the sphere's radius
      // const circleGeometry = new THREE.CircleGeometry(0.02, 32); // Small circle geometry for the points
      // const circleMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 }); // Red color for visibility

      dataArray.forEach((item) => {
        // const lat = item.lat;
        // const lon = item.lon;

        if (item.lat !== 0 && item.lon !== 0) {
          createSiteMarker(item.lat, item.lon, scene, item.IFM);
        }
      });
    }
    const dotGroup = new THREE.Group();

    var renderedSites = [];

    function createSiteMarker(lat, lon, scene, ifm) {
      var styles = {
        CBRE: {
          color: 0x66ffba,
          opacity: 1,
          size: 0.06,
          radius: 5.01,
        },
        other: {
          color: 0x008bff,
          opacity: 1,
          size: 0.05,
          radius: 5,
        },
      };
      const RADIUS = styles[ifm]?.radius || 5.01; // Slightly larger than the sphere's radius
      const dotGeometry = new THREE.SphereGeometry(
        styles[ifm]?.size || 0.06,
        32,
        32
      );
      const dotMaterial = new THREE.MeshBasicMaterial({
        color: styles[ifm]?.color || 0xff0000,
        opacity: styles[ifm]?.opacity || 0.6,
        transparent: true,
      }); // Central dot color
      const dotMesh = new THREE.Mesh(dotGeometry, dotMaterial);

      const strokeGeometry = new THREE.TorusGeometry(0.1, 0.01, 16, 100);
      const strokeMaterial = new THREE.MeshBasicMaterial({
        color: 0x66ffba, // Stroke color
        transparent: true,
        opacity: 0.5,
      });
      const strokeMesh = new THREE.Mesh(strokeGeometry, strokeMaterial);

      // Convert lat/lon to spherical coordinates
      const phi = (90 - lat) * (Math.PI / 180);
      const theta = (lon + 180) * (Math.PI / 180);

      // Convert spherical coordinates to Cartesian coordinates
      const x = -RADIUS * Math.sin(phi) * Math.cos(theta);
      const y = RADIUS * Math.cos(phi);
      const z = RADIUS * Math.sin(phi) * Math.sin(theta);

      // Set the position for both the dot and the stroke
      dotMesh.position.set(x, y, z);
      strokeMesh.position.set(x, y, z);
      renderedSites.push([x, y, z]);
      // Orient the stroke geometry correctly
      strokeMesh.lookAt(center);
      // strokeMesh.rotateX(Math.PI / 2); // Align the torus to encircle the sphere at the correct latitude

      // Add the meshes to the scene
      // scene.add(dotMesh);
      // scene.add(strokeMesh);
      dotGroup.add(dotMesh);
      // dotGroup.add(strokeMesh);
    }

    // Define the atmosphere shader material
    const atmosphereMaterial = new THREE.ShaderMaterial({
      uniforms: {
        glowColor: { value: new THREE.Color(0x7eaaff) },
      },
      vertexShader: `
      varying vec3 vNormal;
      void main() {
        vNormal = normalize(normalMatrix * normal);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
      fragmentShader: `
      uniform vec3 glowColor;
      varying vec3 vNormal;
      void main() {
        float intensity = pow(0.9 - dot(vNormal, vec3(0, 0, 1.0)), 10.0);
        gl_FragColor = vec4(glowColor, 1.0) * intensity;
      }
    `,
      side: THREE.BackSide, // Render on the inside
      blending: THREE.AdditiveBlending, // Use additive blending to create the glow effect
      transparent: true, // Ensure the shader material is transparent where we don't draw the glow
      opacity: 0.7,
    });

    // Create the atmosphere sphere
    const atmosphereGeometry = new THREE.SphereGeometry(5.2, 64, 32); // Slightly larger than the globe sphere
    const atmosphereMesh = new THREE.Mesh(
      atmosphereGeometry,
      atmosphereMaterial
    );

    // Add the atmosphere to the scene
    //   scene.add(atmosphereMesh);

    const fresnelGeometry = new THREE.SphereGeometry(4.97, 64, 32); // Slightly larger than the globe sphere
    const fresnelMatg = getFresnelMat();
    const fresnelMat = getFresnelMat();
    const glowMesh = new THREE.Mesh(fresnelGeometry, fresnelMat);
    glowMesh.scale.setScalar(1.01);
    scene.add(glowMesh);

    // Example usage:
    // Assuming `dataArray` is your array of objects and `scene` is your Three.js scene
    addCirclesToSphere(combinedSites, scene);
    // dotGroup.add(instancedMesh);
    dotGroup.add(atmosphereMesh);
    scene.add(dotGroup);
    dotGroup.position.x = xOffset;
    dotGroup.position.y = yOffset;

    scene.position.x = 3.7;
    scene.position.y = -1;
    scene.rotateX(0.25);

    function animate() {
      requestAnimationFrame(animate);
      // controls.update();

      // scene.rotateY(-0.001);
      renderer.render(scene, camera);
    }
    animate();

    function wiggle() {
      let offsetX = (Math.random() * Math.PI - Math.PI / 2) / 20;
      let offsetY = (Math.random() * Math.PI - Math.PI / 2) / 20;
      gsap.to(scene.rotation, {
        duration: 5,
        x: offsetX + 0.25,
        y: offsetY,
        ease: "power2.inOut",
      });
    }

    setInterval(() => {
      wiggle();
    }, 4000);


    function getRandomSites2() {
      var index1 = Math.floor(Math.random() * combinedSites.length);
      while (
        combinedSites[index1].lat === 0 ||
        combinedSites[index1].lon === 0
      ) {
        index1 = Math.floor(Math.random() * combinedSites.length);
      }
      return [combinedSites[index1].lat, combinedSites[index1].lon];
    }

    class ArcAnimator extends THREE.Object3D {
      constructor(startLatLong, endLatLong, radius) {
        super();
        // Helper function to convert lat/long to XYZ needs to be defined
        const startXYZ = toXYZ(startLatLong[0], startLatLong[1], radius);
        const endXYZ = toXYZ(endLatLong[0], endLatLong[1], radius);

        // D3 interpolation along the great arc
        const d3Interpolate = geoInterpolate(
          [startLatLong[1], startLatLong[0]],
          [endLatLong[1], endLatLong[0]]
        );

        // Control points for the Bezier curve
        const control1 = d3Interpolate(0.25);
        const control2 = d3Interpolate(0.75);

        // Calculate arc height as a function of distance
        const arcHeight = startXYZ.distanceTo(endXYZ) * 0.5 + radius;
        const controlXYZ1 = toXYZ(control1[1], control1[0], arcHeight);
        const controlXYZ2 = toXYZ(control2[1], control2[0], arcHeight);

        // Create the curve
        const curve = new THREE.CubicBezierCurve3(
          startXYZ,
          controlXYZ1,
          controlXYZ2,
          endXYZ
        );

        // Geometry, material, and mesh setup
        this.geometry = new THREE.TubeGeometry(curve, 44, 0.015, 8, false);
        //   this.material = new THREE.MeshBasicMaterial({ color: 0xffff00 }); // Simplified for example
        this.material = new THREE.ShaderMaterial({
          vertexShader: `
        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
          fragmentShader: `
        uniform float time;
        uniform vec3 color1;
        uniform vec3 color2;

        void main() {
          // Calculate the fraction of time that has passed
          float t = mod(time / 10.0, 1.0); // Repeat every 5 seconds
          // Interpolate between color1 and color2
          vec3 color = mix(color1, color2, t);
          gl_FragColor = vec4(color, 1.0);
        }
      `,
          uniforms: {
            time: { value: 0.0 }, // Initialize time uniform
            color1: { value: new THREE.Color(0x008bff) }, // Gradient start color
            color2: { value: new THREE.Color(0x66ffba) }, // Example gradient end color
          },
        });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.add(this.mesh);

        // Initial draw range
        this.geometry.setDrawRange(0, 1);

        // Begin animation
        this.startTime = performance.now();
        this.drawAnimatedLine();
      }

    //   dispose = () => {
    //     // this.mesh.parent.dispose();
    //     console.log(this.mesh.dispose);
    //   }

      drawAnimatedLine = () => {
        const totalDuration = 3750; // Total duration for the full animation
        const timeElapsed = performance.now() - this.startTime;

        // Normalize progress to range from 0 to 1
        const progress = timeElapsed / totalDuration;

        // Assuming the curve is made up of roughly 3000 vertices
        const totalVertices = 3000;
        let drawRangeStart = 0;
        let drawCount = 0;

        drawCount = Math.floor(totalVertices * Math.sin(progress * Math.PI));
        if (progress <= 0.5) {
          // First half of the animation - draw the line
          //   drawCount = Math.floor(progress * 2 * totalVertices);
        } else {
          // Second half of the animation - undraw the line
          drawRangeStart = Math.floor((progress - 0.5) * 2 * totalVertices);
          drawRangeStart = Math.round(drawRangeStart / 3) * 3; // Round to nearest multiple of 3
        }
        this.material.uniforms.time.value += 0.05; // Adjust speed of color transition as needed

        // Update the draw range to reveal or hide the curve
        this.geometry.setDrawRange(drawRangeStart, drawCount);

        if (timeElapsed < totalDuration) {
          requestAnimationFrame(this.drawAnimatedLine);
        } else {
          // Once the animation is complete, remove this arc
          scene.remove(this.mesh);
          this.geometry.dispose();
          this.material.dispose();
        }
      };
    }

    function toXYZ(latitude, longitude, radius) {

      const phi = (90 - latitude) * (Math.PI / 180);
      const theta = (longitude + 180) * (Math.PI / 180);

      // Convert spherical coordinates to Cartesian coordinates
      const x = -radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.cos(phi);
      const z = radius * Math.sin(phi) * Math.sin(theta);

      return new THREE.Vector3(x, y, z);
    }

    setInterval(() => {
      var arcanimator = new ArcAnimator(
        getRandomSites2(),
        getRandomSites2(),
        5
      );
      scene.add(arcanimator);
      setTimeout(() => {
        scene.remove(arcanimator);
        // console.log(arcanimator.texture);
      }, 4000);
    }, 500);
  }

  return (
    <section className="h-screen w-full flex items-center justify-center relative z-10" >

        <div className="absolute mix-blend-screen bottom-0 max-h-[125vh] overflow-hidden z-10">
            <div ref={globeRef} className="relative z-10 h-full w-full flex items-center justify-center"></div>
            {/* <div className="absolute z-20 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black"></div> */}
        </div>
        <div className="absolute bottom-0 w-screen h-full bg-gradient-to-b from-transparent via-[#0f1d2b22] to-[#0F1D2B] z-20"></div>

        <div className="w-full h-full flex flex-col justify-end max-w-screen-2xl mx-auto py-40 relative z-30">
        <p className="newmonthly-tag text-primary-300">
            Our Footprint
          </p>
          <p className="text-2xl 2xl:text-6xl max-w-[28ch] text-white !font-black"
            style={{
                filter: "drop-shadow(0px 2px 4px #12263Caa), drop-shadow(0px 5px 10px #12263C), drop-shadow(0px 5px 10px #12263C)",
            }}
          >
            Together, we support the world's most influential companies.
          </p>
          <div className="w-full">
          <div className="mt-6 relative mx-auto rounded-xl new-linear z-10 p-8 overflow-hidden mb-6 inline-block">
        <div className="flex flex-row divide-x-2 divide-white/20 ">
          <div className="border-l-4 border-primary-300 px-6 pr-12 w-64">
            <p className="font-bold text-white">Clients</p>
            <p className="text-4xl font-normal text-white">46</p>
          </div>

          <div className=" px-6 pr-12 w-60">
            <p className="font-bold text-white">Sites</p>
            <p className="text-4xl font-normal text-white">
              235
            </p>
          </div>
          <div className=" px-6 pr-12 w-60">
            <p className="font-bold text-white">States</p>
            <p className="text-4xl font-normal text-white">
              35
            </p>
          </div>
          <div className=" px-6 pr-12 w-60">
            <p className="font-bold text-white">Countries</p>
            <p className="text-4xl font-normal text-white">
              3
            </p>
          </div>
        </div>
          </div>
      </div>
        </div>
    </section>
  );
}
