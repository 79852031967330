import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from 'react';
import ArrowButton from './button';

const rootData = [{
    "client_id": 166,
    "client_name": "HP (CR) (JLL)",
    "logo_url": null,
    "program_count": null,
    "total_amount": 47838.00,
    "owner_poc_full_name": "Katherine Loría",
    "customer_poc_full_name": "Carla Cordero  ",
    "owner_poc": {
        "id": 19499,
        "last_name": "Loría",
        "photo_url": "assets/1591075416.5870674_WhatsAppImage20200601at23.19_t.36",
        "first_name": "Katherine"
    },
    "customer_poc": {
        "id": 23860,
        "last_name": "",
        "photo_url": "",
        "first_name": "Carla Cordero "
    },
    "programs": null
}, {
    "client_id": 391,
    "client_name": "Delta",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/Delta.svg",
    "program_count": 3,
    "total_amount": 4139179.47,
    "owner_poc_full_name": "Colleen Doles",
    "customer_poc_full_name": "Timothy Dunn",
    "scorecard": 4.95,
    "owner_poc": {
        "id": 7112,
        "last_name": "Doles",
        "photo_url": "assets/1576012853.5400362_CoCoChaCha12_t.19",
        "first_name": "Colleen"
    },
    "customer_poc": {
        "id": 78168,
        "last_name": "Dunn",
        "photo_url": "",
        "first_name": "Timothy"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 4178246.82
    }, {
        "id": 204,
        "name": "Hazardous Waste",
        "total_amount": 10418.21
    }, {
        "id": 234,
        "name": "Snow Removal",
        "total_amount": 11091.84
    }]
}, {
    "client_id": 29,
    "client_name": "Ggl",
    "logo_url": "assets/1605309974.4638548_unnamed_t.png",
    "program_count": 4,
    "total_amount": 1303114.04,
    "owner_poc_full_name": "Ryan Blackwood",
    "customer_poc_full_name": "Jennifer Jasso",
    "scorecard": 5,
    "owner_poc": {
        "id": 47231,
        "last_name": "Blackwood",
        "photo_url": "assets/1619727421.457733_pic_t.jpg",
        "first_name": "Ryan"
    },
    "customer_poc": {
        "id": 88425,
        "last_name": "Jasso",
        "photo_url": "",
        "first_name": "Jennifer"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 1380989.66
    }, {
        "id": 9,
        "name": "Pressure Washing",
        "total_amount": 18150.0
    }, {
        "id": 130,
        "name": "Window Cleaning",
        "total_amount": 2755.51
    }, {
        "id": 204,
        "name": "Hazardous Waste",
        "total_amount": 736.31
    }]
}, {
    "client_id": 11,
    "client_name": "Amgen",
    "logo_url": "assets/1605312134.3328376_Artboard12_t.png",
    "program_count": 3,
    "total_amount": 1305385.56,
    "owner_poc_full_name": "Dan Hartman",
    "customer_poc_full_name": "Dan Rose",
    "scorecard": 4.56,
    "owner_poc": {
        "id": 3813,
        "last_name": "Hartman",
        "photo_url": "assets/1590091364.4842527_DanHartmanheadshot3_t.png",
        "first_name": "Dan"
    },
    "customer_poc": {
        "id": 46331,
        "last_name": "Rose",
        "photo_url": "",
        "first_name": "Dan"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 1185385.81
    }, {
        "id": 3,
        "name": "GMP",
        "total_amount": 95284.67
    }, {
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 32825.05
    }]
}, {
    "client_id": 81,
    "client_name": "BMS (JLL)",
    "logo_url": "assets/1611954197.67563_BMS_t.PNG",
    "program_count": 5,
    "total_amount": 1037098.78,
    "owner_poc_full_name": "Danny Chan",
    "customer_poc_full_name": "Jason Burgoyne ",
    "scorecard": 5,
    "owner_poc": {
        "id": 36274,
        "last_name": "Chan",
        "photo_url": "assets/1590615825.579835_Dchan2_t.jpg",
        "first_name": "Danny"
    },
    "customer_poc": {
        "id": 42292,
        "last_name": "",
        "photo_url": "",
        "first_name": "Jason Burgoyne"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 953757.71
    }, {
        "id": 3,
        "name": "GMP",
        "total_amount": 14972.51
    }, {
        "id": 5,
        "name": "Floor Care",
        "total_amount": 39446.0
    }, {
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 6835.0
    }, {
        "id": 147,
        "name": "Landscape",
        "total_amount": 3915.0
    }]
}, {
    "client_id": 154,
    "client_name": "Genentech (JLL)",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/Genentech.svg",
    "program_count": 2,
    "total_amount": 887122.66,
    "owner_poc_full_name": "Jacqueline Maravilla",
    "customer_poc_full_name": "Brandon Hayden",
    "scorecard": 4.88,
    "owner_poc": {
        "id": 80162,
        "last_name": "Maravilla",
        "photo_url": "assets/a61914450f5c4119836b228c76f78479_B65731D36F71441C9F460B39B41285BE_t.JPG",
        "first_name": "Jacqueline"
    },
    "customer_poc": {
        "id": 82374,
        "last_name": "Hayden",
        "photo_url": "",
        "first_name": "Brandon"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 103464.09
    }, {
        "id": 3,
        "name": "GMP",
        "total_amount": 813811.03
    }]
}, {
    "client_id": 275,
    "client_name": "P&G (JLL)",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/PG.svg",
    "program_count": 8,
    "total_amount": 829980.92,
    "owner_poc_full_name": "Dustin Jones",
    "customer_poc_full_name": "Trynai Baker",
    "scorecard": 5,
    "owner_poc": {
        "id": 76991,
        "last_name": "Jones",
        "photo_url": "assets/r52fac19f076e49558fb46dfae281a5bf_djonesheadshot_t.jpg",
        "first_name": "Dustin"
    },
    "customer_poc": {
        "id": 51126,
        "last_name": "Baker",
        "photo_url": "",
        "first_name": "Trynai"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 638446.17
    }, {
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 23090.48
    }, {
        "id": 136,
        "name": "Event Support",
        "total_amount": 25909.57
    }, {
        "id": 148,
        "name": "Shredding/Document Disposal",
        "total_amount": 4872.44
    }, {
        "id": 158,
        "name": "Pest Control",
        "total_amount": 168.01
    }, {
        "id": 181,
        "name": "Conference Room Setup",
        "total_amount": 62139.27
    }, {
        "id": 183,
        "name": "Mailroom",
        "total_amount": 51336.3
    }, {
        "id": 204,
        "name": "Hazardous Waste",
        "total_amount": 40482.1
    }]
}, {
    "client_id": 87,
    "client_name": "Wells Fargo",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/Wells.svg",
    "program_count": 1,
    "total_amount": 1157468.36,
    "owner_poc_full_name": "Miriam Ledezma",
    "customer_poc_full_name": "James Dunnican",
    "scorecard": 4.58,
    "owner_poc": {
        "id": 11598,
        "last_name": "Ledezma",
        "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
        "first_name": "Miriam"
    },
    "customer_poc": {
        "id": 78718,
        "last_name": "Dunnican",
        "photo_url": "",
        "first_name": "James"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 843082.26
    }]
}, {
    "client_id": 315,
    "client_name": "Ford",
    "logo_url": "assets/1605311926.006089_Artboard11_t.png",
    "program_count": 1,
    "total_amount": 847741.68,
    "owner_poc_full_name": "Bryan Belville",
    "customer_poc_full_name": "Daniel Johnson",
    "scorecard": 3,
    "owner_poc": {
        "id": 80079,
        "last_name": "Belville",
        "photo_url": "assets/re1ef2b8782b04382a4f29f07b98dbfea_IMG_5382_t.jpg",
        "first_name": "Bryan"
    },
    "customer_poc": {
        "id": 83854,
        "last_name": "Johnson",
        "photo_url": "",
        "first_name": "Daniel"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 821208.94
    }]
}, {
    "client_id": 49,
    "client_name": "Intel- Malaysia (JLL)",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/Intel.svg",
    "program_count": 1,
    "total_amount": 174622.29,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 769677.91
    }]
}, {
    "client_id": 257,
    "client_name": "Meta",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg",
    "program_count": 2,
    "total_amount": 662221.75,
    "owner_poc_full_name": "Ryan Moeller",
    "customer_poc_full_name": "Cathleen Morris",
    "scorecard": 4.83,
    "owner_poc": {
        "id": 85535,
        "last_name": "Moeller",
        "photo_url": "assets/c80df59297f543099c143ab582a2450c_SBMHeadshot06_2023_t.png",
        "first_name": "Ryan"
    },
    "customer_poc": {
        "id": 89931,
        "last_name": "Morris",
        "photo_url": "",
        "first_name": "Cathleen"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 595982.64
    }, {
        "id": 151,
        "name": "Cafe/Kitchen",
        "total_amount": 37075.76
    }]
}, {
    "client_id": 286,
    "client_name": "Moderna (JLL)",
    "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/moderna.svg",
    "program_count": 3,
    "total_amount": 596802.34,
    "owner_poc_full_name": "Savannah Balkam",
    "customer_poc_full_name": "Monica DaSilva",
    "scorecard": 4.63,
    "owner_poc": {
        "id": 19451,
        "last_name": "Balkam",
        "photo_url": "assets/1580336190.408361_SavannahHeadshot_t.jpg",
        "first_name": "Savannah"
    },
    "customer_poc": {
        "id": 87464,
        "last_name": "DaSilva",
        "photo_url": "",
        "first_name": "Monica"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 224577.03
    }, {
        "id": 3,
        "name": "GMP",
        "total_amount": 364540.5
    }, {
        "id": 153,
        "name": "Glassware Preparation",
        "total_amount": 7684.81
    }]
}, {
    "client_id": 47,
    "client_name": "Bayer (JLL)",
    "logo_url": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
    "program_count": 3,
    "total_amount": 439573.42,
    "owner_poc_full_name": "Isaac Calderon",
    "customer_poc_full_name": "Charlie Hoff",
    "owner_poc": {
        "id": 78076,
        "last_name": "Calderon",
        "photo_url": "assets/06b03dcc0e8149b8bc0f22a450bf1740_IMG_4729_t.jpg",
        "first_name": "Isaac"
    },
    "customer_poc": {
        "id": 72056,
        "last_name": "Hoff",
        "photo_url": "",
        "first_name": "Charlie"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 452120.43
    }, {
        "id": 8,
        "name": "Mat Services",
        "total_amount": 6829.55
    }, {
        "id": 181,
        "name": "Conference Room Setup",
        "total_amount": 4918.95
    }]
}, {
    "client_id": 224,
    "client_name": "Cardinal Health (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 279853.51,
    "owner_poc_full_name": "Angela Hileman",
    "customer_poc_full_name": "Gregg Gibson",
    "owner_poc": {
        "id": 50588,
        "last_name": "Hileman",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "first_name": "Angela"
    },
    "customer_poc": {
        "id": 89970,
        "last_name": "Gibson",
        "photo_url": "",
        "first_name": "Gregg"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 271595.75
    }]
}, {
    "client_id": 158,
    "client_name": "HP",
    "logo_url": null,
    "program_count": 4,
    "total_amount": 270091.35,
    "owner_poc_full_name": "Mark Schlerf",
    "customer_poc_full_name": "Sylvia Rodriguez",
    "owner_poc": {
        "id": 6257,
        "last_name": "Schlerf",
        "photo_url": "assets/rea49b5e014d445fbaef87ea2d84e35ff_Mark_Navybg_t.png",
        "first_name": "Mark"
    },
    "customer_poc": {
        "id": 49186,
        "last_name": "Rodriguez",
        "photo_url": "",
        "first_name": "Sylvia"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 187900.52
    }, {
        "id": 5,
        "name": "Floor Care",
        "total_amount": 2247.15
    }, {
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 39300.3
    }, {
        "id": 137,
        "name": "Cleanroom",
        "total_amount": 24533.08
    }]
}, {
    "client_id": 131,
    "client_name": "Westinghouse (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 246325.61,
    "owner_poc_full_name": "Michael Prettyman",
    "customer_poc_full_name": "Carl  Nelson",
    "owner_poc": {
        "id": 65569,
        "last_name": "Prettyman",
        "photo_url": "assets/e3f6ef75e6a0489090ee72f2e7422919_MP_t.png",
        "first_name": "Michael"
    },
    "customer_poc": {
        "id": 21060,
        "last_name": "Nelson",
        "photo_url": "",
        "first_name": "Carl "
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 248106.79
    }]
}, {
    "client_id": 161,
    "client_name": "Collins Aerospace (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 213289.97,
    "owner_poc_full_name": "Angela Hileman",
    "customer_poc_full_name": "Greg Palma ",
    "owner_poc": {
        "id": 50588,
        "last_name": "Hileman",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "first_name": "Angela"
    },
    "customer_poc": {
        "id": 31804,
        "last_name": "",
        "photo_url": "",
        "first_name": "Greg Palma"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 213110.17
    }]
}, {
    "client_id": 225,
    "client_name": "T. Rowe Price",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 163826.21,
    "owner_poc_full_name": "Anthony George",
    "customer_poc_full_name": "Jeremy Bergeron",
    "owner_poc": {
        "id": 80120,
        "last_name": "George",
        "photo_url": "assets/bbe0076382a141d185130abb19139898_attachment1692585641270_t.jpg",
        "first_name": "Anthony"
    },
    "customer_poc": {
        "id": 43046,
        "last_name": "Bergeron",
        "photo_url": "",
        "first_name": "Jeremy"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 161684.35
    }]
}, {
    "client_id": 31,
    "client_name": "Asurion (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 117771.66,
    "owner_poc_full_name": "Jaime Sandoval Davila",
    "customer_poc_full_name": "Debbie Roberson",
    "owner_poc": {
        "id": 12740,
        "last_name": "Sandoval Davila",
        "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
        "first_name": "Jaime"
    },
    "customer_poc": {
        "id": 68426,
        "last_name": "Roberson",
        "photo_url": "",
        "first_name": "Debbie"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 117771.66
    }]
}, {
    "client_id": 168,
    "client_name": "HP- (SG) (JLL)",
    "logo_url": null,
    "program_count": 2,
    "total_amount": 81146.71,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 108030.8
    }, {
        "id": 136,
        "name": "Event Support",
        "total_amount": 1910.0
    }]
}, {
    "client_id": 67,
    "client_name": "Boeing (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 92724.66,
    "owner_poc_full_name": "Fatima Saunders",
    "customer_poc_full_name": "Sandra Sutton",
    "owner_poc": {
        "id": 15973,
        "last_name": "Saunders",
        "photo_url": "1e640c38-34b4-9b6d-4aab-30c07191f41f.png",
        "first_name": "Fatima"
    },
    "customer_poc": {
        "id": 20081,
        "last_name": "Sutton",
        "photo_url": "",
        "first_name": "Sandra"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 90743.15
    }]
}, {
    "client_id": 135,
    "client_name": "Xerox (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 88582.72,
    "owner_poc_full_name": "Mark Schlerf",
    "customer_poc_full_name": "Osman Besic",
    "owner_poc": {
        "id": 6257,
        "last_name": "Schlerf",
        "photo_url": "assets/rea49b5e014d445fbaef87ea2d84e35ff_Mark_Navybg_t.png",
        "first_name": "Mark"
    },
    "customer_poc": {
        "id": 20171,
        "last_name": "Besic",
        "photo_url": "",
        "first_name": "Osman"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 88582.72
    }]
}, {
    "client_id": 129,
    "client_name": "Hasbro (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 60982.55,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 60982.55
    }]
}, {
    "client_id": 432,
    "client_name": "HP (MYS)(JLL)",
    "logo_url": "assets/2af78a2731524afebe8f5a94faaf7f64_kamizi_t.jpg",
    "program_count": 2,
    "total_amount": 17795.37,
    "owner_poc_full_name": "MOHD KAMIZI BIN MOHD SHAPIAI",
    "customer_poc_full_name": "Sri Ragunathan Perumal",
    "owner_poc": {
        "id": 39161,
        "last_name": "BIN MOHD SHAPIAI",
        "photo_url": "assets/5ab819e8738a4d0c8574fba315ebfca9_313021192_1201692873760880_3319806319428360463_n_t.webp",
        "first_name": "MOHD KAMIZI"
    },
    "customer_poc": {
        "id": 55189,
        "last_name": "Perumal",
        "photo_url": "",
        "first_name": "Sri Ragunathan"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 55988.2
    }, {
        "id": 158,
        "name": "Pest Control",
        "total_amount": 2176.0
    }]
}, {
    "client_id": 137,
    "client_name": "GM",
    "logo_url": "assets/1605312005.83312_GM_logo_t.jpg",
    "program_count": 1,
    "total_amount": 37999.85,
    "owner_poc_full_name": "Joshua Chiesa",
    "customer_poc_full_name": "Dave Painter",
    "owner_poc": {
        "id": 88107,
        "last_name": "Chiesa",
        "photo_url": "assets/83949c64bc5e43d086a53ca8d9a8d8bb_headshot_t.jpg",
        "first_name": "Joshua"
    },
    "customer_poc": {
        "id": 89065,
        "last_name": "Painter",
        "photo_url": "",
        "first_name": "Dave"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 38299.85
    }]
}, {
    "client_id": 258,
    "client_name": "Microsoft (JLL)(SG)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 25718.88,
    "owner_poc_full_name": null,
    "customer_poc_full_name": "Sandra Ng",
    "owner_poc": null,
    "customer_poc": {
        "id": 43406,
        "last_name": "Ng",
        "photo_url": "",
        "first_name": "Sandra"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 34871.45
    }]
}, {
    "client_id": 189,
    "client_name": "Thomson Reuters (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 33765.88,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 33765.88
    }]
}, {
    "client_id": 71,
    "client_name": "Pfizer",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 15843.61,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 15843.61
    }]
}, {
    "client_id": 317,
    "client_name": "Intel (SG) (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 12268.88,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "scorecard": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 11937.0
    }]
}, {
    "client_id": 156,
    "client_name": "Becton Dickinson (SG) (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 27084.57,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 11615.0
    }]
}, {
    "client_id": 634,
    "client_name": "Massachusetts Institute of Tech (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 7982.39,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 7982.39
    }]
}, {
    "client_id": 1783,
    "client_name": "SOLIDIGM (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 4297.92,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 5557.59
    }]
}, {
    "client_id": 486,
    "client_name": "PWC",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 5406.80,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 5406.8
    }]
}, {
    "client_id": 628,
    "client_name": "City Of Phoenix (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 4147.16,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 4147.16
    }]
}, {
    "client_id": 183,
    "client_name": "NTT Data (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 973.00,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 1139.75
    }]
}, {
    "client_id": 1315,
    "client_name": "Hills Plaza (JLL)",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 976.80,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "PTC",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 40244.00,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "NCR",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 5545.87,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "LAM Research",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 3407.41,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "Koch",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 783.00,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "P&G Sub",
    "logo_url": null,
    "program_count": 1,
    "total_amount": 385.58,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 10000,
    "client_name": "Intel",
    "logo_url": null,
    "program_count": 1,
    "total_amount": -135.56,
    "owner_poc_full_name": null,
    "customer_poc_full_name": null,
    "owner_poc": null,
    "customer_poc": null,
    "programs": [{
        "id": 6,
        "name": "Recycle/Diversion",
        "total_amount": 976.8
    }]
}, {
    "client_id": 2143,
    "client_name": "DBS Asia Hub (JLL) (SG)",
    "logo_url": null,
    "program_count": 2,
    "total_amount": 140343.67,
    "owner_poc_full_name": "TEY HWA WEE",
    "customer_poc_full_name": "Danny Chan",
    "owner_poc": {
        "id": 78621,
        "last_name": "HWA WEE",
        "photo_url": "assets/287c688024684a36ac0f31c68803ddbe_HwaWee_t.PNG",
        "first_name": "TEY"
    },
    "customer_poc": {
        "id": 87781,
        "last_name": "Chan",
        "photo_url": "",
        "first_name": "Danny"
    },
    "programs": [{
        "id": 1,
        "name": "Custodial",
        "total_amount": 150.0
    }, {
        "id": 158,
        "name": "Pest Control",
        "total_amount": 150.0
    }]
}]

const colors = [
    "#4BA8FF",
    "#734CFF",
    "#A230D2",
    "#DB33B6",
    "#FF7DB1",
    "#FF4332",
    "#FF6E19",
    "#C29200",
    "#E0C942",
    "#02FA8E",
    "#02CCA6",
    "#02CCA6",
    "#48A4FA",
  ]

export default function TopTen({data, title}) {
    const [clients, setClients] = useState(reduceData(data ? data.sort((a, b) => b.total_amount - a.total_amount) : rootData.sort((a, b) => b.total_amount - a.total_amount)))
    const [total, setTotal] = useState(clients[0].data.reduce((acc, curr) => acc + (curr.y || 0), 0))

    if (data === undefined) {
        data = rootData.sort((a, b) => b.total_amount - a.total_amount)
    }

    function reduceData(data) {
        let sortedData = data.sort((a, b) => b.total_amount - a.total_amount)
        if (sortedData.filter(client => client.show_top_client === false || client.client_name.indexOf('Other') > -1).length > 0) {
            let other = sortedData.shift();
            sortedData.push(other);
        } else {
            // sortedData.push({client_name: 'Others', total_amount: sortedData.slice(10).reduce((acc, curr) => acc + curr.total_amount, 0)})
        }
        let outputData = sortedData.slice(0,11);
        return [{data: outputData.map((client, i) => {return {name: client.client_name, y: client.total_amount, color: client.client_name === 'Others' ? '#D1D5D9' : colors[i]}})}]
    }

    function BillingPieChart({data}) {
            const options = {
                title: {
                  text: "",
                },
                chart: {
                  backgroundColor: "transparent",
                  type: "pie",
                //   spacing: [24, 24, 24, 24],
                    margin: [0,0,0,0],
                    height: '100%'
                },
                legend: {
                    enabled: true,
                    // width: '100%',
                    layout: "vertical",
                    symbolRadius: 2,
                    labelFormatter: function() {
                        return `<div class="w-full flex items-center justify-between"><p>${this.name}</p><p>${this.percentage.toFixed(1)}%</p></div>` ;
                     },
                     useHTML: true,
                    itemStyle: {
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '14px !important'
                    }
                },
                colors: colors,
                credits: {
                  enabled: false,
                },
                tooltip: {
                    backgroundColor: "rgb(12, 15, 18)",
                    outside: true,
                    shadow: false,
                    formatter: function() {
                        return `${this.point.name}: ${this.percentage.toFixed(1)}%`
                    },
                    style: { color: "#ffffff" , fontSize: '16px'},
    
                },
                plotOptions: {
                  pie: {
                    innerSize: '70%',
                    labels: {
                        enabled: false
                    },
                    showInLegend: false,
                  },
                  series: {
                    dataLabels: {
                        enabled: false
                    },
                    borderRadius: 0
                  }
                },
                series: data,
              };
    
              return <HighchartsReact className={'mx-auto'}style={{height: '30vh', width: '30vh'}}highcharts={Highcharts} options={options} />
    }
    console.log(clients);
    return (
      <Section>
        <>
          <div className="px-12 w-full">
            <div className="relative flex flex-between w-full">
                <div className="w-full">
                    <p className="monthly-tag text-yellow-500">{title?.tag || "Overview"}</p>
                    <p className="monthly-title mb-6 max-w-[32ch]">{title?.headline  || "Top 10"} <span><ArrowButton label="View All Scorecards" href="https://app.4insite.com/site-scorecards" newTab={true}/></span></p>
                </div>
                <div className="self-end px-6 py-4 bg-green-700 text-white rounded-lg mb-6 flex-shrink-0">
                    <p className="text-center font-bold text-white leading-none mb-2">Avg. Score</p>
                    <p className="text-center text-4xl font-black text-white">4.65<span className="text-base"> / 5</span></p>
                </div>
            </div>
          <div className="w-full grid grid-cols-4 gap-6 ">
            <div className="col-span-3 bg-neutral-800 rounded-xl overflow-hidden flex flex-col items-start justify-start">
              <div class="w-full grid grid-cols-9 border-b border-solid border-neutral-900/20 dark:border-neutral-100/20">
                <div class="flex items-center my-4 pl-4 col-span-2">
                  <h3
                    data-ds2="h3"
                    class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                  >
                    Client
                  </h3>
                </div>
                <div class="flex items-center my-4 col-span-2">
                  <h3
                    data-ds2="h3"
                    class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                  >
                    Owner
                  </h3>
                </div>
                <div class="flex items-center my-4 col-span-2">
                  <h3
                    data-ds2="h3"
                    class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                  >
                    Customer POC
                  </h3>
                </div>
                <div class="flex items-center my-4 col-span-2">
                  <h3
                    data-ds2="h3"
                    class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                  >
                    Billing
                  </h3>
                </div>
                <div class="flex items-center justify-center my-4">
                  <h3
                    data-ds2="h3"
                    class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                  >
                    Scorecard
                  </h3>
                </div>
              </div>
              <div className="w-full flex-grow flex flex-col items-around justify-evenly">

              {clients[0].data
                // .sort((a, b) => b.total_amount - a.total_amount)
                .slice(0, 10)
                .map((client) => {
                  return (
                    <div className="w-full grid grid-cols-9 hover:bg-neutral-700">
                      <div className="flex items-center 2xl:my-2 pl-4 col-span-2">
                        {(client.logo_url && client.logo_url.indexOf('https:') < 0)  ? (
                          <img
                            src={"https://cdn.4insite.com/" + client.logo_url}
                            className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
                          />
                        ) : (client.logo_url && client.logo_url.indexOf('https:') > -1) ? (
                            <img
                            src={client.logo_url}
                            className="h-6 w-6 p-1 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
                          />) :
                        (
                          <div className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 bg-neutral-900 flex items-center justify-center text-xl text-white font-black">
                            {client.client_name?.slice(0, 1)}
                          </div>
                        )}
                        <div>
                          <p className="text-white text-sm font-roboto font-bold">
                            {client.client_name}
                          </p>
                          <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium">
                            {client.programs?.length} Programs
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center 2xl:my-2 col-span-2">
                        {client.owner_poc?.photo_url ? (
                          <img
                            src={
                              "https://cdn.4insite.com/" +
                              client.owner_poc.photo_url
                            }
                            className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
                          />
                        ) : (
                          <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
                            {client.owner_poc && (client.owner_poc?.first_name?.slice(0, 1) +
                              client.owner_poc?.last_name.slice(0, 1))}
                          </div>
                        )}
                        <p className="text-white text-sm font-roboto font-bold">
                          {(client.owner_poc && (client.owner_poc?.first_name +
                            " " +
                            client.owner_poc?.last_name)) || "Unassigned"}
                        </p>
                      </div>
                      <div className="flex items-center 2xl:my-2 col-span-2">
                        <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-xs 2xl:text-sm text-white font-black">
                          {client.customer_poc && (client.customer_poc?.first_name?.slice(0, 1) +
                            client.customer_poc?.last_name.slice(0, 1))}
                        </div>
                        <p className="text-white text-sm font-roboto font-bold">
                          {(client.customer_poc && (client.customer_poc?.first_name +
                            " " +
                            client.customer_poc?.last_name)) || "Unassigned"}
                        </p>
                      </div>
                      <div className="flex items-center 2xl:my-2 col-span-2">
                        <p className="text-white text-sm font-roboto font-bold">
                          {(document.referrer === "https://app.4insite.com/" || document.location.href.indexOf('localhost') > -1) && client.total_amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0
                          })}
                        </p>
                      </div>
                      <div className={`flex items-center justify-center 2xl:my-2 `}>
                        <p
                          className={`w-16 py-1 text-center text-white text-sm 2xl:text-lg font-black rounded-lg ${
                            client.scorecard > 4
                              ? "bg-green-700"
                              : client.scorecard >= 3
                              ? "bg-yellow-700"
                              : !client.scorecard
                              ? "bg-neutral-700"
                              : "bg-red-700"
                          }`}
                        >
                          {client.scorecard || "--"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-neutral-800 rounded-xl">
                <div class="border-b border-solid border-neutral-900/20 dark:border-neutral-100/20">
                    <div class="flex items-center my-4 pl-4 col-span-2">
                    <h3
                        data-ds2="h3"
                        class="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                    >
                        Clients
                    </h3>
                    </div>
                </div>
                {/* <BillingPieChart data={[{data:data.sort((a, b) => b.total_amount - a.total_amount).slice(0, 10).map(client => {return {"name":client.client_name, y: client.total_amount}})}]} /> */}
                <div className=" mx-auto">
                    <div className="mx-auto" style={{height: '30vh', width: '30vh'}}>
                    <BillingPieChart data={reduceData(data)} />

                    </div>
                <div className="w-full px-6 flex flex-col items-start justify-evenly">
                    {clients[0].data.map((client, i) => {
                        return (
                        <div className="flex items-center mt-1 last:mb-2 w-full">
                            <div className="w-4 h-4 rounded-full mr-2 flex-shrink-0" style={{backgroundColor: i === 10 ? '#D1D5D9' : colors[i]}}></div>
                            <p className="text-white text-xs 2xl:text-sm font-roboto font-bold w-full">{client.name}</p>
                            <p className="text-white text-xs 2xl:text-sm font-roboto font-bold text-right w-full">{(client.y/total* 100).toFixed(1)}%</p>
                        </div>)
                    })}
                </div>
                </div>
            </div>
          </div>
          </div>

        </>
      </Section>
    );
}