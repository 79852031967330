import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { motion } from "framer-motion";
import { useSwiperSlide } from 'swiper/react';


import auditsAI from './assets/auditsai.png'
import threeDScorecard from './assets/3dScorecard4.png'
import competition from './assets/competition.png'

export default function Market() {
    return (

    <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center relative">
        <img src={competition} alt="" className="absolute h-3/4 w-full object-contain z-0 pointer-events-none" />
    <p className="newmonthly-tag text-primary-300 text-center">
      Market Conditions
    </p>
    <p className="newmonthly-title text-center">
      Understanding the<br/>competitive landscape
    </p>

  </section>
    )
}