import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import { Navigation, Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faStar, faStarHalf} from '@fortawesome/free-solid-svg-icons'

export default function CSAT({data}) {
    

    return (
        <Section>
            <div className=" w-full p-12 flex flex-row flex-wrap gap-y-12">
                <div className="w-1/2 flex flex-col items-start justify-center flex-shrink-0 flex-grow-0">
                    <p className="monthly-tag text-[#FFC980]">Quality Surveys</p>
                    <p className="monthly-title">
                        The average survey score for November was 4.48 out of 5.
                    </p>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-full">
                        <div className="w-full bg-neutral-800 rounded-lg">
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                                pagination={{ clickable: true }}
                                className="w-full"
                            >
                                <SwiperSlide>
                                    <div className="h-full w-full flex flex-col items-start justify-center p-4 2xl:p-6 pb-8 2xl:pb-12">
                                    <p className="text-lg 2xl:text-xl font-bold text-white">Highlight</p>
                                    <p className="text-base 2xl:text-lg text-white/70">Along with the passing scorecard, New Albany also received this survey response.</p>

                                <div className="w-full mt-4 flex flex-col gap-4 ">
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-12 w-12 bg-white rounded-full flex-shrink-0">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/amgen.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <div>
                                        <p className="text-lg 2xl:text-xl font-bold text-white flex-grow">Amgen - New Albany</p>
                                        <p className="text-base 2xl:text-lg font-normal text-white">“…we were approved by the FDA and I am supremely confident in saying that would not have been possible without your crew! Thank you for everything you do!”</p>
                                        </div>

                                    </div>
                                </div>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="h-full w-full flex flex-col items-start justify-center p-4 2xl:p-6 pb-8 2xl:pb-12">
                                    <p className="text-lg 2xl:text-xl font-bold text-white">Opportunity</p>
                                <p className="text-base 2xl:text-lg text-white/70">The improvements being made at Collins Aerospace are being noticed by our customer.</p>
                                <div className="w-full mt-4 flex flex-col gap-4 ">

                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-12 w-12 bg-white rounded-full flex-shrink-0">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Collins.svg" alt="" className="h-full w-full object-contain flex-shrink-0" />
                                        </div>
                                        <div className="w-full">
                                        <p className="text-lg 2xl:text-xl font-bold text-white flex-grow">Collins Aerospace - Phoenix Electronic</p>
                                        <p className="text-base 2xl:text-lg font-normal text-white">“I have seen a lot of improvement over the last month. The attention to detail has been improving...”</p>
                                        </div>
                                    </div>
                                </div>

                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-around">
                    <FontAwesomeIcon style={{fill: 'url(#star-gradient)'}} className={'star-gradient text-[24vh]'} icon={faStar} />
                    <FontAwesomeIcon style={{fill: 'url(#star-gradient)'}} className={'star-gradient text-[24vh]'} icon={faStar} />
                    <FontAwesomeIcon style={{fill: 'url(#star-gradient)'}} className={'star-gradient text-[24vh]'} icon={faStar} />
                    <FontAwesomeIcon style={{fill: 'url(#star-gradient)'}} className={'star-gradient text-[24vh]'} icon={faStar} />
                    <div className="relative">
                    <FontAwesomeIcon style={{fill: 'url(#star-gradient)'}} className={'star-gradient text-[24vh] relative z-10'} icon={faStarHalf} />
                    <FontAwesomeIcon style={{fill: ''}} className={' text-[24vh] absolute top-0 left-0 z-0 opacity-20'} icon={faStar} />

                    </div>
                </div>
            </div>
            <div className="h-0 w-0 overflow-hidden opacity-0">
            <svg width="120" height="240" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="star-gradient" x1="1" x2="0" y1="0" y2="1">
                    <stop class="stop1" offset="0%" />
                    <stop class="stop2" offset="100%" />
                    </linearGradient>
                </defs>
            </svg>
            <style>
                {`
                .stop1 {
                    stop-color: #FFF299;
                }
                .stop2 {
                    stop-color: #FF9D19;
                }
                `}
            </style>
            </div>
        </Section>
    )
}
  