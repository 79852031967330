import { Section } from "./monthly";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Swiper, SwiperSlide } from "swiper/react";

import { motion } from "framer-motion";

import { Pagination } from "swiper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

import { StoryModalButton } from "./storyModal.jsx";

export default function Highlights({ data, showModal }) {
  return (
    <Section>
      <div className="w-full p-12 flex flex-row flex-wrap">
        <div className="w-1/2 flex flex-col items-start justify-center flex-shrink-0">
          <p className="monthly-tag text-orange-500">SBM | JLL</p>
          <p className="monthly-title">Monthly Highlights</p>
        </div>
        {/* <div className="w-1/2 flex items-center justify-center pl-6">
                    <div className="rounded-lg bg-neutral-800 overflow-hidden shadow-xl shadow-neutral-950/40">
                        <div className="">
                            <p className="text-center w-full p-4 text-2xl font-black bg-rose-500 text-neutral-900 uppercase">November, 2023</p>
                            <div className="grid grid-cols-7 gap-6 p-8">
                            </div>
                        </div>

                    </div>
                </div> */}
        <div className="w-full flex flex-row items-center gap-6 mt-6 flex-shrink-0">
          <div className="relative rounded-xl bg-neutral-800 h-[50vh] w-full flex items-end justify-start">
            <video
              data-autoplay="true"
              preload="auto"
              loop
              mute
              playsInline
              src="https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/msp2.mp4"
              className="absolute z-0 top-0 left-0 h-full w-full rounded-xl object-cover"
            ></video>
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10 rounded-xl"></div>

            <p className="relative z-20 text-3xl text-white p-6 font-black font-lato">
              Delta MSP
            </p>
          </div>
          <div
            className="relative rounded-xl bg-neutral-800 h-[50vh] w-full flex items-end justify-start"
            style={{
              background:
                "url(https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/boeing.jpg) no-repeat center center / cover",
            }}
          >
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10 rounded-xl"></div>
            <StoryModalButton showModal={showModal} story={"hi"} />
            <p className="relative z-10 text-3xl text-white p-6 font-black font-lato">
              Boeing Future of Flight Museum Reopening
            </p>
          </div>
          <div
            className="relative rounded-xl bg-neutral-800 h-[50vh] w-full flex items-end justify-start"
            style={{
              background:
                "url(https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/jhonatanb.jpg) no-repeat center center / cover",
            }}
          >
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10 rounded-xl"></div>
            <div className="p-6 relative z-10">
              <p className="text-3xl text-white font-black font-lato">
                Jonathan Avalos Limon
              </p>
              <p className="text-base text-white">
                Site Manager, HP — Palo Alto
              </p>
            </div>
          </div>
          <div
            className="relative rounded-xl bg-neutral-800 h-[50vh] w-full flex items-end justify-start"
            style={{
              background:
                "url(https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/genentech.jpeg) no-repeat center center / cover",
            }}
          >
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10 rounded-xl"></div>

            <p className="relative z-10 text-3xl text-white p-6 font-black font-lato">
              Green Cleaning Portfolio Reviews
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}
