export default class Client  {
    constructor(names, logo, industries, slides) {
    this.names = names; 
      this.logo = logo;
      this.industies = industries;
      this.slides = slides;
    }
  
    get name() {
      return this.calcName();
    }
  
    calcName() {
        let clientSet = [...new Set(this.names)];
        let industrySet = [...new Set(this.industries)];
        if (clientSet.length === 1) {
            return clientSet[0]
        } else if (industrySet.length === 1) {
            return industrySet[0]
        } else {return ''};
  }
}

export class NewClient {
    constructor(clients) {
        this.clients = clients;
    }

    get events() {
        let output = this.clients.map(c => [c.customerUpdates,c.sites.map(s => s.siteUpdates)].flat(3)).filter(n => n !== undefined)
        return output.sort((a,b) => {
            return a.sort > b.sort;
        }).flat(2);
    }

    get photos() {
        let output = this.clients.map(c => [c.photos,c.sites.map(s => s.photos)].flat(3)).filter(n => n !== undefined)
            return output.sort((a,b) => {
                return a.sort > b.sort;
            }).flat(2).filter(n => n !== undefined)
    }

    hide = function(i) {
        let output = Array.from(new Set(this.clients.map(c => [c.hide,c.sites.map(s => s.hide)].flat(3)).flat().filter(n => n !== undefined)));
        return output.indexOf(i) === -1;
    }

    show = function(i) {
        let output = Array.from(new Set(this.clients.map(c => [c.show,c.sites.map(s => s.show)].flat(3)).flat().filter(n => n !== undefined)));
        return output.indexOf(i) > -1;
    }
}