import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import {motion} from 'framer-motion';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faGraduationCap} from '@fortawesome/free-solid-svg-icons'

export default function GoodCatches({data, headline, story}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "pie",
              spacing: [24, 24, 24, 24],
              height: '400px',
            },
            legend: {
                enabled: true,
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                itemMarginBottom: 10,
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
                "#4BA8FF",
                "#734CFF",
                "#A230D2",
                "#DB33B6",
                "#FF7DB1",
                "#FF4332",
                "#FF6E19",
                "#C29200",
                "#E0C942",
                "#02FA8E",
                "#02CCA6",
                "#02CCA6",
                "#48A4FA",
              ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              min: 0,
              title: '',
              gridLineColor: "rgba(0,0,0,0.4)",
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            tooltip: {
                backgroundColor: "rgb(12, 15, 18)",
                outside: true,
                shadow: false,
                style: { color: "#ffffff" , fontSize: '16px'},

            },
            plotOptions: {
                pie: {
                    innerSize: '70%',
                    showInLegend: true,
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    labels: {
                        enabled: false
                    }
                },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

   

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-orange-500">Good Catches</p>
                    <p className="monthly-title">
                        {headline || "SBM captured 64 critical good catches in November, and 1,161 good catches across all sites."}
                    </p>
                    {story && <p className="text-xl font-normal text-white/70 mt-4">{story}</p>}
                    {/* <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                      <div className="p-6">
                          <div className="flex flex-row items-center gap-6">
                              <div className="w-20 h-20 rounded-full flex-shrink-0 bg-blue-500/20 text-blue-500 flex items-center justify-center ">
                              <FontAwesomeIcon className="text-4xl" icon={faGraduationCap} />
                              </div>
                          <div>
                          <p className="text-xl font-bold text-white">Custom Training</p>
                          <p className="text-lg text-white/70">Our Knowledge Center includes over 20 Original Courses designed specifically to solve the unique issues facing our joint clients.</p>
                          </div>
                          </div>


                      </div>


              </div> */}
                </div>
                <div className="w-1/2 flex items-center justify-center pl-6">
                    <div className="rounded-lg overflow-hidden w-full">
                        <Chart className="w-full" data={data || [
                            {
                                name: "Good Catches",
                                data: [
                                    { name: "Spills/Leaks", y: 290},
                                    { name: "Slips/Trips", y: 237},
                                    { name: "Maintenance", y: 201},
                                    { name: "Behavior", y: 163},
                                    { name: "Equipment", y: 115 },
                                    { name: "Environmental", y: 93},
                                    { name: "Improper Storage", y: 48},
                                    { name: "Chemical", y: 10},
                                    { name: "Lifting", y: 5}
                                ]
                            }
                        ]} />
                    </div>
                </div>
            </div>
        </Section>
    )
}
  