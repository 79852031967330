export const data = [
  {
    client_id: 1649,
    client_name: "Others",
    logo_url: null,
    show_top_client: false,
    program_count: 1,
    total_amount: 4983301,
    owner_poc_full_name: null,
    customer_poc_full_name: null,
    owner_poc: null,
    customer_poc: null,
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 17609.66,
      },
    ],
  },
  {
    client_id: 1422,
    client_name: "Meta (CBRE)",
    logo_url: "https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg",
    program_count: 1,
    total_amount: 2546903,
    owner_poc_full_name: "Luna Duarte",
    customer_poc_full_name: "Alex Worth",
    scorecard: 4.93,
    owner_poc: {
      id: 21850,
      last_name: "Duarte",
      photo_url: "assets/1597765547.152738_Lunaphoto_t.jpg",
      first_name: "Luna",
    },
    customer_poc: {
      id: 80336,
      last_name: "Worth",
      photo_url: "",
      first_name: "Alex",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 2752848.04,
      },
    ],
  },
  {
    client_id: 72,
    client_name: "Eli Lilly (CBRE)",
    logo_url: "assets/1605311746.7267878_Artboard8_t.png",
    program_count: 2,
    total_amount: 1334895,
    owner_poc_full_name: "Sara Brake",
    customer_poc_full_name: "Rich Garrison",
    scorecard: 4.52,
    owner_poc: {
      id: 8763,
      last_name: "Brake",
      photo_url:
        "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
      first_name: "Sara",
    },
    customer_poc: {
      id: 24953,
      last_name: "Garrison",
      photo_url: "",
      first_name: "Rich",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 1379861.68,
      },
      {
        id: 3,
        name: "GMP",
        total_amount: 6100,
      },
    ],
  },
  {
    client_id: 267,
    client_name: "Merck (CBRE)",
    logo_url: "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
    program_count: 8,
    total_amount: 866313,
    owner_poc_full_name: "Jenna Rosa",
    customer_poc_full_name: "Mike Basalyga",
    scorecard: 4.99,
    owner_poc: {
      id: 37079,
      last_name: "Rosa",
      photo_url: "assets/b845d0960f214a4b8df444745692370c_2023Headshot_t.jpg",
      first_name: "Jenna",
    },
    customer_poc: {
      id: 38084,
      last_name: "Basalyga",
      photo_url: "",
      first_name: "Mike",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 988344.47,
      },
      {
        id: 8,
        name: "Mat Services",
        total_amount: 7853.21,
      },
      {
        id: 135,
        name: "Lab Services",
        total_amount: 2844.47,
      },
      {
        id: 158,
        name: "Pest Control",
        total_amount: 780.16,
      },
      {
        id: 172,
        name: "Supplier Management Fee",
        total_amount: 6208.33,
      },
      {
        id: 196,
        name: "Uniforms",
        total_amount: 59737.78,
      },
      {
        id: 204,
        name: "Hazardous Waste",
        total_amount: 315711.21,
      },
      {
        id: 325,
        name: "IFM Managed Services",
        total_amount: 1367.42,
      },
    ],
  },
  {
    client_id: 87,
    client_name: "Wells Fargo",
    logo_url: "https://sbmupdates.s3.amazonaws.com/clients_isolated/Wells.svg",
    program_count: 2,
    total_amount: 1129695,
    owner_poc_full_name: "Miriam Ledezma",
    customer_poc_full_name: "Teresa Braithwait",
    scorecard: 4.77,
    owner_poc: {
      id: 11598,
      last_name: "Ledezma",
      photo_url:
        "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
      first_name: "Miriam",
    },
    customer_poc: {
      id: 81287,
      last_name: "Braithwait",
      photo_url: "",
      first_name: "Teresa",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 1019861.67,
      },
      {
        id: 278,
        name: "Virus Response",
        total_amount: 3043.16,
      },
    ],
  },
  {
    client_id: 180,
    client_name: "Takeda (CBRE)",
    logo_url: "assets/1605623711.8547332_ogp_logo_t.jpg",
    program_count: 2,
    total_amount: 651473,
    owner_poc_full_name: "Luis Cabrera",
    customer_poc_full_name: "Gualter Almeida",
    scorecard: 4.65,
    owner_poc: {
      id: 5102,
      last_name: "Cabrera",
      photo_url: "assets/1577824247.6788175_LuisCabreraHeadshot_07252019_t.jpg",
      first_name: "Luis",
    },
    customer_poc: {
      id: 82339,
      last_name: "Almeida",
      photo_url: "",
      first_name: "Gualter",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 843137.2,
      },
      {
        id: 3,
        name: "GMP",
        total_amount: 62126.96,
      },
    ],
  },
  {
    client_id: 5,
    client_name: "Nvidia (CBRE)",
    logo_url: "assets/1605311860.3280911_Artboard10_t.png",
    program_count: 5,
    total_amount: 676485,
    owner_poc_full_name: "Stuart Kelloff",
    customer_poc_full_name: "Arman Bagdasaryan",
    scorecard: 5,
    owner_poc: {
      id: 84308,
      last_name: "Kelloff",
      photo_url:
        "assets/r9caff6d0b6b04667bbfebdad11191ea7_StuKelloffsProfilePic_t.png",
      first_name: "Stuart",
    },
    customer_poc: {
      id: 22290,
      last_name: "Bagdasaryan",
      photo_url: "",
      first_name: "Arman",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 661843.79,
      },
      {
        id: 5,
        name: "Floor Care",
        total_amount: 2200.24,
      },
      {
        id: 6,
        name: "Recycle/Diversion",
        total_amount: 715.19,
      },
      {
        id: 136,
        name: "Event Support",
        total_amount: 2059.48,
      },
      {
        id: 138,
        name: "General Maintenance",
        total_amount: 1162.5,
      },
    ],
  },
  {
    client_id: 253,
    client_name: "Biogen (CBRE)",
    logo_url: "assets/1605623761.388814_Biogen01_t.png",
    program_count: 2,
    total_amount: 696939,
    owner_poc_full_name: "Matthew Borsick",
    customer_poc_full_name: "Lee Johnson",
    scorecard: 4.41,
    owner_poc: {
      id: 73885,
      last_name: "Borsick",
      photo_url:
        "assets/e885d28c1ee54708a7b4e945a938bc60_BorsickProfessionProfilePicture_t.jpg",
      first_name: "Matthew",
    },
    customer_poc: {
      id: 49746,
      last_name: "Johnson",
      photo_url: "",
      first_name: "Lee",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 632975.8,
      },
      {
        id: 3,
        name: "GMP",
        total_amount: 72.22,
      },
    ],
  },
  {
    client_id: 8,
    client_name: "Microsoft (CBRE)",
    logo_url: "assets/1605312306.0531929_Artboard4_t.png",
    program_count: 2,
    total_amount: 3593311,
    owner_poc_full_name: "Shane Follmann",
    customer_poc_full_name: "CBRE Facility MGMT",
    scorecard: 4.92,
    owner_poc: {
      id: 3744,
      last_name: "Follmann",
      photo_url: "assets/1587505724.8169758_ShaneFollmann_t.jpg",
      first_name: "Shane",
    },
    customer_poc: {
      id: 74997,
      last_name: "Facility MGMT",
      photo_url: "",
      first_name: "CBRE",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 568605.96,
      },
      {
        id: 278,
        name: "Virus Response",
        total_amount: 51423.24,
      },
    ],
  },
  {
    client_id: 468,
    client_name: "Deutsche Bank",
    logo_url:
      "https://sbmupdates.s3.amazonaws.com/clients_isolated/deutsche-bank.svg",
    program_count: 2,
    total_amount: 495671,
    scorecard: 4.75,
    owner_poc_full_name: "Sarah Behrman",
    customer_poc_full_name: "John Papadopoulos",
    owner_poc: {
      id: 85087,
      last_name: "Behrman",
      photo_url:
        "assets/311b982448d24b42ab6b2bf771685a0b_SarahBehrmanpic_t.jfif",
      first_name: "Sarah",
    },
    customer_poc: {
      id: 86307,
      last_name: "Papadopoulos",
      photo_url: "",
      first_name: "John",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 553430.55,
      },
      {
        id: 278,
        name: "Virus Response",
        total_amount: 9662.82,
      },
    ],
  },
  {
    client_id: 270,
    client_name: "Novartis (CBRE)",
    logo_url: "assets/1605623818.436738_novartislogo775x500_t.png",
    program_count: 1,
    total_amount: 505558,
    owner_poc_full_name: "Michael Barry",
    customer_poc_full_name: "John Courville",
    scorecard: 4.92,
    owner_poc: {
      id: 8352,
      last_name: "Barry",
      photo_url: "assets/1610682875.1928341_worksnip2_t.JPG",
      first_name: "Michael",
    },
    customer_poc: {
      id: 21318,
      last_name: "Courville",
      photo_url: "",
      first_name: "John",
    },
    programs: [
      {
        id: 1,
        name: "Custodial",
        total_amount: 490271.72,
      },
    ],
  },
];

export const allCBRE = {
  ifm: "CBRE",
  clients: "39",
  sites: "200+",
  sqft: "100M+",
  logos: [
    {
      client: "Abbvie",
      sqft: "729694",
      sites: "4",
      logo: "Abbvie",
      scorecard: 4.76,
    },
    {
      client: "Adient",
      sqft: "356000",
      sites: "1",
      logo: "Adient",
      scorecard: 4.71,
    },
    {
      client: "ASML",
      sqft: "228500",
      sites: "1",
      logo: "Asml",
      scorecard: 4.13,
    },
    {
      client: "Biogen",
      sqft: "2500000",
      sites: "2",
      logo: "Biogen",
      scorecard: 4.41,
    },
    {
      client: "Chemours",
      sqft: "685312",
      sites: "1",
      logo: "Chemours",
      scorecard: 4.88,
    },
    {
      client: "Chubb Insurance",
      sqft: "637763",
      sites: "2",
      logo: "chubb",
      scorecard: 4.88,
    },
    {
      client: "Cigna",
      sqft: "2273699",
      sites: "30",
      logo: "Cigna",
      scorecard: 4.79,
    },
    {
      client: "Clorox",
      sqft: "572891",
      sites: "2",
      logo: "Clorox",
      scorecard: 4.84,
    },
    // {
    //   client: "Coca-Cola",
    //   sqft: "2500000",
    //   sites: "1",
    //   logo: "Coke",
    //   scorecard: "--",
    // },
    // {
    //   client: "Collins ",
    //   sqft: "125000",
    //   sites: "1",
    //   logo: "Collins",
    //   scorecard: "--",
    // },
    {
      client: "Covestro",
      sqft: "135000",
      sites: "1",
      logo: "covestro",
      scorecard: 3.77,
    },
    {
      client: "Deutsche Bank",
      sqft: "1238425",
      sites: "3",
      logo: "deutsche-bank",
      scorecard: 4.75,
    },
    {
      client: "Elanco",
      sqft: "1170000",
      sites: "3",
      logo: "Elanco",
      scorecard: 4.66,
    },
    {
      client: "Eli Lilly",
      sqft: "12679000",
      sites: "2",
      logo: "Lilly",
      scorecard: 4.52,
    },
    {
      client: "Ford",
      sqft: "7931408",
      sites: "34",
      logo: "Ford",
      scorecard: 4.32,
    },
    {
      client: "GE",
      sqft: "22000",
      sites: "2",
      logo: "GE",
      scorecard: 4.71,
    },
    {
      client: "GM",
      sqft: "3600000",
      sites: "1",
      logo: "GM",
      scorecard:4.92,
    },
    // {
    //   client: "Goldman Sachs",
    //   sqft: "15000",
    //   sites: "1",
    //   logo: "Goldman",
    //   scorecard: "--",
    // },
    {
      client: "GSK",
      sqft: "310000",
      sites: "1",
      logo: "GSK",
      scorecard: 4.5,
    },
    // {
    //   client: "IQVIA",
    //   sqft: "298725",
    //   sites: "2",
    //   logo: "IQVIA",
    //   scorecard: "--",
    // },
    // {
    //   client: "JPMC",
    //   sqft: "1487942",
    //   sites: "2",
    //   logo: "jpmc",
    //   scorecard: 3.19,
    // },
    {
      client: "Juniper",
      sqft: "900000",
      sites: "1",
      logo: "Juniper",
      scorecard: 5,
    },
    {
      client: "McKesson",
      sqft: "4520771",
      sites: "15",
      logo: "Mckesson",
      scorecard: 4.98,
    },
    {
      client: "Merck",
      sqft: "7133800",
      sites: "5",
      logo: "Merck",
      scorecard: 4.99,
    },
    {
      client: "Meta",
      sqft: "5283786",
      sites: "6",
      logo: "Meta",
      scorecard: 4.93,
    },
    {
      client: "Microsoft",
      sqft: "15570656",
      sites: "13",
      logo: "Microsoft",
      scorecard: 4.92,
    },
    {
      client: "Novartis",
      sqft: "4382548",
      sites: "5",
      logo: "Novartis",
      scorecard: 4.98,
    },
    {
      client: "Nvidia",
      sqft: "2500000",
      sites: "1",
      logo: "Nvidia",
      scorecard: 5,
    },
    {
      client: "Organon",
      sqft: "11500",
      sites: "1",
      logo: "Organon",
      scorecard: 4.86,
    },
    {
      client: "Pratt & Whitney",
      sqft: "1507600",
      sites: "2",
      logo: "Pratt",
      scorecard: 4.38,
    },
    // {
    //     "client": "Siemens",
    //     "sqft": "84701",
    //     "sites": "2",
    //     "logo": "Siemens",
    // "scorecard": 0
    // },
    {
      client: "Sikorsky",
      sqft: "305312",
      sites: "1",
      logo: "sikorsky",
      scorecard: 4.23,
    },
    // {
    //   client: "State Farm",
    //   sqft: "4465185",
    //   sites: "14",
    //   logo: "State",
    //   scorecard: "--",
    // },
    {
      client: "Takeda",
      sqft: "2391272",
      sites: "5",
      logo: "Takeda",
      scorecard: 4.65,
    },
    // {
    //   client: "Tenaya",
    //   sqft: "44000",
    //   sites: "1",
    //   logo: "Tenaya",
    //   scorecard: "--",
    // },
    {
      client: "Uber",
      sqft: "1591145",
      sites: "4",
      logo: "Uber",
      scorecard: 4.78,
    },
    {
      client: "Vantiva",
      sqft: "3214338",
      sites: "2",
      logo: "Vantiva",
      scorecard: 5,
    },
    {
      client: "Wells Fargo",
      sqft: "6598200",
      sites: "34",
      logo: "Wells",
      scorecard: 4.77,
    },
  ],
};
