import {useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";


export default function MonthlyCard({title, src, poster, colSpan, rowSpan, modalOpen,  showModal, newUI, newSafety, children, objectPosition}) {
    return (
        <div
        className={`${colSpan} ${rowSpan} monthly-hero-card group cursor-pointer`}
        onClick={(el) =>
          showModal(
            el,
            null,
            children
          )
        }
      >
       {src.length ? <VideoBG
          src={src}
          objectPosition={objectPosition}
          modalOpen={modalOpen}
        /> : null}
        <div
          className={`relative z-10 h-full w-full bg-gradient-to-b pointer-events-none from-black/80 via-black/40 to-black/20 p-6 `}
        >
          {newUIButton()}
          <p className={"text-4xl font-black text-lato text-left text-white"}>
            {title}
          </p>
        </div>
      </div>
    )
}


export function VideoBG({ src, modalOpen, classList, objectPosition }) {
    const video = useRef(null);
    // useEffect(() => {
    //   if (modalOpen) {
    //     video.current.pause();
    //   } else {
    //     video.current.play();
    //   }
  
    // }, [modalOpen])
    return (
      <video
        ref={video}
        // autoPlay
        onMouseEnter={(el) => {
          el.currentTarget.play();
        }}
        onMouseLeave={(el) => {
          el.currentTarget.pause();
          el.currentTarget.currentTime = 0;
        }}
        playsInline
        muted
        preload="auto"
        onCanPlay={(el) => {
          el.target.classList.remove("opacity-0");
        }}
        // src={sowloop}
        className={`absolute z-0 top-0 left-0 h-full w-full transition-all ggroup-hover:opacity-70 ggroup-hover:scale-110 duration-500 ease-in-out object-cover ${objectPosition || ''} oopacity-0 ${classList}`}
      >
        {src.map((src, i) => {
          return (
            <source
              key={i}
              src={src}
              type={
                src.indexOf("mov") >= 0
                  ? "video/quicktime"
                  : src.indexOf("webm") >= 0
                  ? "video/webm"
                  : ""
              }
              codecs={src.indexOf("mov") >= 0 ? "hvc1" : ""}
            />
          );
        })}
      </video>
    );
  }

  function newUIButton() {
    return (
      <div className="absolute bottom-4 left-6 z-40 rounded-full pointer-events-none">
        <FontAwesomeIcon
          className={
            "text-white group-hover:translate-x-2 transition-transform duration-300 ease-in-out mr-2 text-2xl"
          }
          icon={faChevronCircleRight}
        />
      </div>
    );
  }