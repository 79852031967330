export default function ModalViewer({props}) {
    console.log(props, props.type === 'pdf');
  return (
    <div className="w-full">
        {props.type === 'img' && <img alt=""src={props.url} className="event-image" style={{maxWidth: '100%'}}></img>}
        {props.type === 'video' && <video className="modal-video" style={{maxHeight: '80vh'}} controls controlsList="nodownload noremoteplayback" disablePictureInPicture autoPlay playsInline src={props.url}/>}
        {props.type === 'pdf' && <iframe title="modaliframe" className="w-full h-full" src={props.url + "#toolbar=0&navpanes=0&scrollbar=0&page=1&view=fitV"}></iframe>}
    </div>
  );
}
