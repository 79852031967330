import loop from '../../assets/videos/hero_loop_no_title (1080p).mp4'
import ModalViewer from '../modalViewer/modalviewer';
import { innovationList } from "../monthly/MonthlyHero";

export default function Section({slide, showModal}) {

    function PersonImage({person}) {
        return (
          <img src={person} alt="" className="shadow-3d h-full max-h-64 aspect-square rounded-full object-cover object-center border-2 border-blue-500"/>
        )
      }

    function SlideSlicer(slide, showModal) {
        let output;
        switch (slide.type) {
          case 'event': {
           output = (
              <div data-type="event" data-section={slide.label} className="aspect-video h-full w-full relative p-4 flex overflow-hidden bg-white dark:bg-neutral-900">
                <img alt={slide.label} className='absolute z-0 top-0 left-0 h-full w-full opacity-50 object-cover blur-xl ' src={slide.url}/>
                  <img src={slide.url} alt={slide.label} className="relative block z-10 rounded-lg shadow-lg mx-auto" />
              </div>
            )
            break;
          }
          case 'slide': {
            output = (
            <div data-type="slide" className="py-6 px-6 h-full w-full flex items-center justify-center">
                <img data-section={slide.label} src={slide.url} className="block rounded-xl object-contain max-w-full max-h-full" />
            </div>
            )
            break;
          }
          case 'article': {
            output = (
              <div data-type="article" data-section={slide.label} className="py-6 px-6 h-full w-full flex items-center justify-center">
                <img alt={slide.label} className="block rounded-xl object-contain max-w-full max-h-full" src={'https://source.unsplash.com/random'}></img>
                <div className="max-w-screen-xl p-6">
                </div>
              </div>
            )
            break;
          }
          case 'video': {
            output = (
              <div data-section={slide.label} className="py-6 px-6 h-full w-full relative flex items-center justify-center">
                <video src={slide.url} controls controlsList='nodownload nofullscreen noremoteplayback' playsInline className="max-h-full max-w-full rounded-xl"></video>
              </div>
            )
            break;
          }
          case 'training': {
            output = (
              <div className="aspect-video h-full w-full relative flex bg-white dark:bg-neutral-900 z-10">
                <video src={slide.background  || loop} data-autoplay="true" muted loop playsInline className="absolute top-0 left-0 h-full w-full opacity-50 z-[-1]"></video>
                <div className="p-6 w-full h-full flex flex-col items-center justify-evenly transform translate-y-1 z-20 scale-100">
                  <div className="pt-12 2xl:pt-32 ">
                  <p className="h1 text-center ">Your Training <span className="highlight2 orange">Originals</span></p>
                  <p className="h3 pt-6 text-center ">Available now, our exclusive collection of your original content.</p>
                  </div>
                  <div className=" flex flex-row items-center justify-center gap-6 w-full">
                    {slide.trainings.map((training, i) => {
                      return (
                      <div key={i} className="group relative p-8 py-12 h-32 2xl:h-48 flex items-center bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-900 transition-colors duration-200 rounded-xl w-1/4 overflow-hidden cursor-pointer" onClick={(el) => {showModal(el, null, <ModalViewer props={{type: 'video', url: training.url}}/>)}}>
                        <div className={`absolute -left-2 h-1/2 w-4 group-hover:translate-x-1 transition-tranform duration-200 ease-in-out rounded-r bg-gradient-to-b from-iteration-${i+1} to-iteration-${i+2}`}></div>
                        <p className="h3">{training.title}</p>
                      </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
            break;
          }
         
          case 'pdf': {
            output = (
              <div data-section={slide.label} className="aspect-video w-full relative flex normal-scroll overflow-y-scroll">
                <object aria-label={slide.label} data={slide.url} type="application/pdf" className='h-full w-full normal-scroll' frameBorder="0"></object>
              </div>
            )
            break;
          }
          case 'image-scroll': {
            output = (
              <div data-type="scroll" data-section={slide.label} className="aspect-video w-full relative normal-scroll overflow-auto">
                <img alt={slide.label} className='w-full' src={slide.url}></img>
              </div>
            )
            break;
          }
          case 'amr-robotics': {
            output = (
              <div className="py-16 min-h-full flex items-center justify-center max-w-screen-2xl mx-auto">
              <div className="relative bg-neutral-900 rounded-xl h-[80vh] no-scrollbar overflow-auto pointer-events-auto w-full max-h-full normal-scroll">
                <div className="p-12 px-6">
                  <p className="monthly-tag text-orange-500">
                    Robotics Literacy
                  </p>
                  <p className="monthly-title">
                    Assessing AMR Equipment{" "}
                  </p>
                </div>
                <div className="grid grid-cols-10 w-full border-b-2 border-neutral-700 sticky top-0 bg-neutral-900 z-20">
                  <div className="py-4 px-6 col-span-2">
                    <p className=" font-black uppercase text-white">
                      Equipment
                    </p>
                  </div>
                  <div className="py-4 px-6 col-span-2">
                    <p className="text-lg font-bold text-white"></p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      Efficiency
                    </p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      Run Time
                    </p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      Value
                    </p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      ROI
                    </p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      Safety
                    </p>
                  </div>
                  <div className="py-4 px-6">
                    <p className=" font-black uppercase text-white text-center">
                      Rating
                    </p>
                  </div>
                </div>
                {innovationList.map((item, i) => (
                  <div key={i}
                    className={`grid grid-cols-10 w-full border-b border-neutral-800 last:border-b-0 z-10 ${
                      item.highlight === true ? "bg-success/10" : ""
                    }`}
                  >
                    <div className="py-4 px-6 col-span-1">
                      <img
                        src={item.img}
                        alt=""
                        className="w-full aspect-square object-contain"
                      />
                    </div>
                    <div className="py-4 px-6 col-span-3">
                      <p className="text-lg font-black text-white">
                        {item.system}
                      </p>
                      <p className="text-sm font-bold uppercase  text-white/80">
                        {item.type}
                      </p>
                      <p className="text-sm font-normal text-white/80">
                        {item.floorTypes}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                        {item.efficiency}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                        {item.runtime}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p className="text-lg font-black text-white/80 text-center  py-2 rounded-xl ">
                        {item.value}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                        {item.roi}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p className="text-lg font-black text-white/80  text-center w-20 py-2 rounded-xl ">
                        {item.safety}
                      </p>
                    </div>
                    <div className="py-4 px-6 flex items-center justify-center">
                      <p
                        className={`text-lg font-black text-white text-center w-20 py-2 rounded-xl ${
                          item.highlight === true
                            ? "bg-success/20"
                            : "bg-neutral-700"
                        }`}
                      >
                        {item.rating}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            )
            break;
          }
          default: 
            output = (slide.slide);
        }
        return output;
      }

      return (
        <section className="section">
           {SlideSlicer(slide, showModal)}
        </section>
      )
}