import React, {useContext} from "react";
import { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { gsap } from "gsap";
import ReactFullpage from "@fullpage/react-fullpage";
import './alternate.css'
import { useParams } from "react-router-dom";
import { decompressFromEncodedURIComponent } from "lz-string";
import phonefloat from '../assets/videos/phonefloat2.mp4';
import phonefloatwebm from '../assets/videos/phonenewdash.webm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faPerson } from '@fortawesome/free-solid-svg-icons'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import nyny from "../assets/videos/nyny.mp4";
import lightwaves from './lightwaves.mp4';
import { ModalContext } from "../App";
import sbm from '../assets/images/sbm.svg';

export default function AlternateLayout({clientID}) {
  const [transition, setTransition] = useState(null);
  let params = useParams();

  useEffect(() => {
    async function fetchData() {
      if (!clientID.length) {
        clientID = decompressFromEncodedURIComponent(params.client).split(',');
      }
      try {
        if (!clientID[0]) {
          throw new Error('Client ID not found');
          return;
        };
        const response = await fetch(`https://sbmqbrassets.s3-us-west-1.amazonaws.com/transitions/tx_${clientID[0]}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTransition(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [clientID]); // Empty dependency array ensures the effect runs once, like componentDidMount
  return (transition && 
    <>
      <div style={{background: "radial-gradient(at center center, rgb(0, 136, 255) 0%, rgba(0, 136, 255, 0) 50%) 10% 10% / 200% 200% no-repeat"}} alt="" className="fixed top-0 left-0 h-full w-full object-cover mix-blend-overlay opacity-0 dark:opacity-50 transitioni-opacity duration-1000"></div>
      <div  alt="" className="fixed top-0 left-0 h-full w-full -scale-x-100 object-cover mix-blend-overlay opacity-50 dark:opacity-0 transitioni-opacity duration-500"></div>
      <video src={lightwaves} loop muted autoPlay playsInline className="fixed top-0 left-0 h-full w-full object-cover transition-opacity duration-1000 mix-blend-overlay opacity-0 dark:opacity-100 z-10"></video>
      <video src={lightwaves} loop muted autoPlay playsInline className="fixed top-0 left-0 h-full w-full object-cover transition-opacity duration-1000 mix-blend-multiply opacity-1000 dark:opacity-0 z-10"></video>
      <div className="relative z-20">
        <ReactFullpage
          //fullpage options
          licenseKey={"LCHQH-KZTCJ-3R8KK-JGQJ6-VBLTJ"}
          scrollingSpeed={700} /* Options here */
          scrollBar={true}
          responsiveWidth={1000}
          slidesNavigation={true}
          normalScrollElements={"#modal, .normal-scroll"}
          credits={{ enabled: false, label: "" }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {transition && <Layout transition={transition}></Layout>}
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    </>
  );
}

function Section({ children }) {
  return (
    <section className="section relative w-full overflow-hidden">
      {children}
    </section>
  );
}



function Layout({transition}) {

  function refreshPage(e) {
    if (!e.shiftKey) return;
    window.location.reload();
  }

  function sectionSwitch(section, i) {
    // var output;
    switch (section.type) {
      case "full": 
        return  <Section key={i}>
        <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden flex flex-col">
          <h3 className="font-bold text-xl text-white mb-4">
            {section.title}
          </h3>
          <hr className="mb-6 opacity-30" />
          <div className="h-full w-full rounded-2xl overflow-auto shrink-1 normal-scroll">
            <img src={section.cards[0]?.img} alt="" className="w-full object-cover" />
          </div>
        </div>
      </Section>
        break;
      default: 
        return  <Section key={i}>
        <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden">
          <h3 className="font-bold text-xl text-white mb-4">
            {section.title}
          </h3>
          <hr className="mb-6 opacity-30" />
          <Slideshow slides={
            section.cards.map((card,i) => {
        return MouseCard(card, i)
      })} />
        </div>
      </Section>
    }
  }

  return (
    <>
      <Section>
        <video src={transition.background?.url || nyny} playsInline data-autoplay="true" loop muted className="absolute top-0 left-0 h-full w-full object-cover" ></video>

        <div className="relative flex-grow h-full w-full flex flex-col items-start lg:items-center justify-between lg:justify-center gap-4 p-4 lg:p-16 2xl:p-32 bg-gradient-to-b from-neutral-900/10 via-neutral-900/30 to-neutral-900">
        <div style={{background: `radial-gradient(at center center, rgb(0, 136, 255) 0%, rgba(0, 136, 255, 0) 50%) 10% 10% / 200% 200% no-repeat`}} alt="" className="absolute top-0 left-0 h-full w-full object-cover mix-blend-overlay opacity-0 dark:opacity-40 transition-opacity duration-1000"></div>
          <img
            src={sbm}
            alt=""
            className="h-6 lg:h-10 mb-2 brightness-0 invert"
          />
          <div>
          <p onClick={refreshPage} className="h1 text-left lg:text-center text-white max-w-1/2">
            {transition.title || "We're Invested in Protecting Your Workplace Assets"}
          </p>
          <p className="text-left lg:text-center text-lg lg:text-3xl serif text-cyan-500 italic ">
            Your facilities are evolving and so are we
          </p>
          </div>
        </div>
      </Section>
      {transition?.people?.length > 0 && (
      <Section>
        <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden ">
          <h3 className="font-bold text-xl text-white mb-4">
            Expert Leadership
          </h3>
          <hr className="mb-6 opacity-30" />
          <Slideshow slides={
            // [
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/Sarah-Behrman.png', name: 'Sarah Behrman', position: 'Account Director', bg: 'white'},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/GabrielVasquez.png', name: 'Gabriel Vasquez', position: 'Site Manager', bg: 'white', message: ""},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/Yesenia-Hurtado.png', name: 'Yesenia Hurtado', position: 'Sr. Manager, Operations', bg: 'white', message: ""},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/alexmasnfield.png', name: 'Alex Mansfield', position: 'Sr. Director, Labor Relations', bg: 'white', message: ""},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/Rachael-Rambert.png', name: 'Rachael Rambert', position: 'Shop Steward', bg: 'white', message: "When SBM came on board, I was like everyone else—skeptical…\nHowever, when they came in, they had one-on-one meetings with reassurance that the only thing changing was the company, and that held true. If anyone has concerns, the door is always open. They don’t just hear our concerns—they listen to them and find a solution to correct them to our satisfaction.\nI’m a firm believer if you treat your employees well, you will always have the best workers who enjoy coming to work. I am one of them."},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/Milagros-Mercado.png', name: 'Milagros Mercado', position: 'Shop Steward', bg: 'white', message: "Before the transition [to SBM], there were a lot of issues at the site. I feel very satisfied with the support SBM has given us. The employees and I can talk to SBM and they have the workplace with more organization and guidance."},
            //   {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/Megan-Lunceford-Garcia.png', name: 'Megan Garcia', position: 'Sr Program Manager, Transitions', bg: 'white'}            ]
           transition.people.map((card,i) => {
        return MouseCard(card, i)
      })} />
        </div>
      </Section>

      )}
      {transition?.results?.length > 0 && (
      <Section>
        <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden">
          <h3 className="font-bold text-xl text-white mb-4">
            Results
          </h3>
          <hr className="mb-6 opacity-30" />
          <Slideshow slides={
            // [
            //     {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db1b.png', name: 'LEED Certification', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db2.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db3.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db4.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db5.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db6.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db7.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db8.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db9.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db10.png', name: '', title: ''},
            //     // {img: 'https://sbmqbrassets.s3-us-west-1.amazonaws.com/assets/db/images/db11.png', name: '', title: ''},
            //     {name: 'Gene Limia', position: 'CBRE', type: 'survey', message: 'SBM did an excellent job in preparation that led up to the transition which ensured a high level of success and seamless integration.', score: 5, date: '07/26/2023', flippable: false},
            //     {name: 'Ekaterina Montero', position: 'Deutsche Bank', type: 'survey', message: 'A lot of things have been thoroughly thought through from the start and were actioned on the first week of transition. SBM team was keeping facilities team well-informed through the whole time, and FM pain points and needs were heard by transition management and addressed from day 1 of start of operations. Thank you for this pleasant experience of working together and your dedication to our site!', score: 5, date: '07/26/2023', flippable: false},
            // ]
            transition.results.map((card,i) => {
        return MouseCard(card, i)
      })} />
        </div>
      </Section>
      )}
       {transition?.sections?.map((section, i) => {return (
      //   <Section>
      //   <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden">
      //     <h3 className="font-bold text-xl text-white mb-4">
      //       {section.title}
      //     </h3>
      //     <hr className="mb-6 opacity-30" />
      //     <Slideshow slides={
      //       section.cards.map(card => {
      //   return MouseCard(card)
      // })} />
      //   </div>
      // </Section>
      sectionSwitch(section, i)
       )
       })} 
    
      <Section>
      <div className="lg:h-screen w-full overflow-hidden p-4 lg:p-0 flex flex-col lg:flex-row items-center justify-center mx-auto gap-8">
        <div className=" w-full flex flex-row items-center gap-6 lg:pl-16 2xl:pl-32 2xl:py-8 2xl:py-20">
            {/* <img src="./images/getinsite.svg" alt="" className="h-48 select-none"/> */}
            <div className="">
                <h1 className="h1 text-white lg:whitespace-nowrap">Investing in Your<br/>Mobile Experience</h1>
                <p className="text-cyan-500 text-3xl serif italic mt-4">60+ features to make your job easier</p>
            </div>
        </div>
        <div className="h-full w-full flex items-center justify-center pr-32 py-8 2xl:py-20 bg-[#B4BEC7]">
            <video id="phonedash" style={{clipPath: "polygon(0 0,100% 0,100% 99%,0% 99%", filter: 'brightness(0.96)', transform: "translateZ(1px)"}} preload="auto" className="block overflow-hidden object-contain border-0 h-full w-full" loop playsInline muted data-autoplay="true">
                <source src={phonefloat} type='video/mp4; codecs="hvc1"'/>
                <source src={phonefloatwebm} type="video/webm"/>
            </video>
        </div>
    </div>
      </Section> 
      <Section>
        <div className="h-full w-full p-4 lg:p-16 2xl:p-20 2xl:px-32 overflow-hidden">
            <h3 className="font-bold text-xl text-white mb-4">60+ Features</h3>
            <hr className="mb-6 opacity-30"/>
            <div className="overflow-visible">
                <div className="inline-flex flex-row relative video-row mb-8 h-[70vh] gap-8">
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659068673.hd.mp4?s=bcf9e78f7638ed5b12571404899220803381491e&profile_id=174#t=3"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659068716.hd.mp4?s=cffc0c1377224a6b52593072570cb809c4c43c6e&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659414969.hd.mp4?s=72654101c33d64858e8800a7a48a51b83e9b4b64&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659068795.hd.mp4?s=adc3a5a8140b133f09b8f486004e3d8bbc5e042d&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659068834.hd.mp4?s=8c1b8bb2e81c87f96352df0739d10462cfb5a53a&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659068928.hd.mp4?s=9c0f985feed34005955ef988de643b733adbe0c6&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069005.hd.mp4?s=f2113d3179faf8b297cddb50558363319cf7516c&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069101.hd.mp4?s=f46061c8949c43ed00dfdc375b9defcf74394a92&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069196.hd.mp4?s=19798445cc9307c598e7b6326c7dfeff7a66bfeb&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069284.hd.mp4?s=75fce2a3dece0512ebb8df24045f81fe43ecdb93&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069361.hd.mp4?s=f34cbfc80f2d05ab7a039a4cd5aa458021f67209&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069452.hd.mp4?s=f47d931eaa3a6989e48f461b89ff2e3993f8577d&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069403.hd.mp4?s=9ff7bc3f8567d9e285584eeaacdb05bb6569e4cb&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069490.hd.mp4?s=4b1f6f9aba25b10153888643d512cda349ecde06&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659069546.hd.mp4?s=b7decee3b2b148bfa245c3c496b200513a25ab62&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070265.hd.mp4?s=5fa804e16f0e7c62f21caa315b3b683a8119cebe&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070329.hd.mp4?s=504a55f2457491771e9550a89ba7241dd400706f&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070375.hd.mp4?s=3896f872a4714bf1d99838247219c4b542ac8188&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070438.hd.mp4?s=a6b8345932a1c09f2610008978c7920f4dc816cd&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070506.hd.mp4?s=929c9f435130f62cb6546192e310e910fa7d431c&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070605.hd.mp4?s=1dfe2e77e3864d0e55c9d015d0ce1dcfc3d0fa0e&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070656.hd.mp4?s=49b8cf92a40055219f0c655cf23a3f7d13811b6b&profile_id=174#t=2"></video>
                    </div>
                <div className="aspect-[9/16] h-full">
                    
                    <video preload="auto" data-autoplay loop muted playsInline className="rounded-xl h-full" src="https://player.vimeo.com/external/659070699.hd.mp4?s=f2eb35be484493a92d966a6e72e92ccfaa83e913&profile_id=174#t=2"></video>
                    </div>
                <p>hi</p>
                </div>
            </div>
        </div>
      </Section>
      <Section>
      <div className="h-full w-full p-4 lg:p-8 lg:px-12 2xl:p-20 2xl:px-32overflow-hidden">
          <h3 className="font-bold text-xl text-white mb-4">
            Path Forward
          </h3>
          <hr className="mb-6 opacity-30" />
          <Slideshow slides={
            // [
            //     {video: 'https://player.vimeo.com/progressive_redirect/playback/659069490/rendition/720p/file.mp4?loc=external&signature=ccfb33cff200920726b05827eaeee041bade3ac0dffaa5f93acf86992e6ca069', name: 'Periodical Scheduling & Route Optimization', title: ''},
            //     {video: 'https://player.vimeo.com/progressive_redirect/playback/644917142/rendition/720p/file.mp4?loc=external&signature=36fb32a8a26c5714998f98b4b0c6b0e4ad3d2ef961c6481599cf57f7c74779eb', name: 'Maximo Integration', title: ''},
            //     {video: 'https://player.vimeo.com/progressive_redirect/playback/350005695/rendition/720p/file.mp4?loc=external&signature=cd6c7acee0089b4f0b9e34adbeb4052dd3fff1ae92fcd892a7ba372844140dd2', name: 'Mobile Rollout', title: ''},             
            // ]
            transition.pathForward.map((card,i) => {
        return MouseCard(card, i)
      })} />
        </div>
      </Section>
    </>
  );
}

function Slideshow({ slides, setSection, currentSection, spaceBetween  }) {
  return (
    <Swiper
      className="!overflow-visible"
      spaceBetween={spaceBetween | 50}
      slidesPerView={1}
      navigation={true}
      modules={[Navigation]}
      breakpoints={{
        "600": {
          slidesPerView:2
        },
        "1280": {
          slidesPerView:3
        }
      }}
    >
      <>
      {slides}
      </>
    </Swiper>
  );
}

function MouseCard({img, video, name, position, title, bg, message, type, flippable, date, score, before, after, button}, i) {
    const cardContainer = useRef(null);
    const cardBack = useRef(null);
    const card = useRef(null);
    const showModal = useContext(ModalContext)
    const [flipOveride, setflipOveride] = useState(type === "before/after" ? true : false)
    const [flipped, setFlipped] = useState(false);
    // useEffect(() => {
    //     cardContainer.current.addEventListener('mousemove' , function(e) {
    //         let parent = cardContainer.current.parentNode;
    //         const string = parent.style.transform;
    //         let xValue;
    //         if (string) {
    //             xValue = string.match(/-?\d+\.?\d*(?=px)/)[0] || 0;
    //         } else {
    //             xValue = 0;
    //         }
            
    //         let cardX = e.clientX - cardContainer.current.offsetLeft - xValue;
    //         let cardY = e.clientY - cardContainer.current.offsetTop;
    
    //         let cardHalfWidth = cardContainer.current.offsetWidth / 2;
    //         let cardHalfHeight = cardContainer.current.offsetHeight / 2;
    
    //         let cardRotateX = (cardY - cardHalfHeight) / cardHalfHeight * 3;
    //         let cardRotateY = (cardX - cardHalfWidth) / cardHalfWidth * -3;


    //         gsap.to(card.current, {
    //             duration: 0.5,
    //             scale: 1.05,
    //             // rotationX: cardRotateX,
    //             // rotationY: cardRotateY,
    //             // ease: 'ease'
    //         })
    //         if (cardBack.current) {
    //             gsap.to(cardBack.current, {
    //                 duration: 0.5,
    //                 scale: 1.05,
    //                 // rotationX: cardRotateX * -1,
    //                 // rotationY: cardRotateY + 180,
    //                 // translateZ: -1,
    //                 ease: 'ease'
    //             })
    //         }
    //     }, []);
    
        // cardContainer.current.addEventListener('mouseleave', function(e) {
        //     gsap.to(card.current, {
        //         duration: 0.5,
        //         scale: 1,
        //         rotationX: 0,
        //         rotationY: 0,
        //         ease: 'ease'
        //         });
        //         if (cardBack.current) {
        //             gsap.to(cardBack.current, {
        //                 duration: 0.5,
        //                 scale: 1,
        //                 rotationX: 0,
        //                 rotationY: 180,
        //                 ease: 'ease'
        //             })
        //         }
        // })
    // })

    function handleClick() {
        if ((message === undefined || message === '' || flippable === false) && flipOveride !== true) return;
        setFlipped(!flipped);
        gsap.to(cardContainer.current, {
            rotateY: !flipped ? 180 : 0,
        })
    }

    function cardSwitch({img, name, position, title, bg, message, type, date, score, video, flippable, before, after, button}) {
      function renderStars(score) {
        let stars = [];
        for (let i = 1; i <= score; i++) {
                stars.push(<FontAwesomeIcon icon={faStar} className="text-neutral-900" />)
        }
        if (score - Math.floor(score) > 0.3) {
            stars.push(<FontAwesomeIcon icon={faStarHalfAlt} className="text-neutral-900" />)
        }
        return stars;
    }
      let output, initials, month, outputDate;
      switch (type) {
        case "survey":
          outputDate = new Date(date);
          month = outputDate.toLocaleDateString('defualt', {day:'numeric', month: 'long', year: 'numeric'})
          initials = name?.split(' ').map(word => word[0]).join('') || '';
          output = (
            <SwiperSlide key={i} className={`swiper-slide relative card-container  ${ flipped ? 'flipped' : '' }`} style={{backfaceVisibility: 'visible !important'}} ref={cardContainer} >
                    <div className="card h-[70vh] ease-in-out group rounded-xl overflow-hidden relative z-10 bg-[#FEAF80] p-8 text-neutral-900" ref={card} >
                        <div className="absolute top-0 right-0 z-0  w-full h-full">
                            <div className="relative flex items-center justify-center h-full w-full translate-x-1/4 -translate-y-1/4">
                                <FontAwesomeIcon icon={faStar} className="absolute text-white/10 text-9xl" />
                                <FontAwesomeIcon icon={faStar} className="absolute text-white/10 text-9xl scale-[3] " />
                                <FontAwesomeIcon icon={faStar} className="absolute text-white/10 text-9xl scale-[5] " />
                                <FontAwesomeIcon icon={faStar} className="absolute text-white/10 text-9xl scale-[7] " />
                                <FontAwesomeIcon icon={faStar} className="absolute text-white/10 text-9xl scale-[9] " />

                            </div>
                        </div>
                        <div className="flex flex-row items-start justify-between w-full relative z-20">
                            <p className="text-2xl font-extrabold text-neutral-900">{title || "Survey"}</p>
                            <p className="text-base text-neutral-900">{month}</p>
                        </div>
                        <div className="flex flex-row mt-2 items-center justify-start gap-1 text-neutral-900 relative z-20">
                            <p className="text-2xl font-extrabold mr-2 text-neutral-900">{score}</p>
                            {renderStars(score)}
                        </div>
                            <p className="text-sm 2xl:text-xl xl:leading-snug mt-4 relative z-20 ">{`${name?.length > 0 ? '“' : ""}${message}${name?.length > 0 ? '”': ''}`}</p>
                            {name && name.length > 0 && 
                            <div className="flex flex-row items-center justify-start gap-2 mt-4 relative z-20">
                                <div className="h-12 w-12 bg-[#4AA8FF] rounded-full grid place-items-center text-white" >
                                    {initials}
                                </div>
                                <div>
                                    <p className="text-lg 2xl:text-xl font-bold mb-0 leading-none">{name}</p>
                                    <p className="text-base mt-0 leading-none">{position}</p>
                                </div>
                            </div>
                            }
                    </div>
                </SwiperSlide>
          );
          break;
        case "before/after":
          message = 'flip'
          output = (
            <SwiperSlide key={i} onClick={handleClick} className={`swiper-slide relative card-container  ${ flipped ? 'flipped' : '' }`} style={{backfaceVisibility: 'visible !important'}} ref={cardContainer} >
              <div className="card h-[70vh] ease-in-out group rounded-xl overflow-hidden relative z-10" ref={card} >
                        <div className="front absolute top-0 left-0 h-full w-full">
                            {message && message.length > 0 && <div className=" absolute top-0 right-0 h-16 w-16 bg-blue-500 rounded-tr-xl z-20" style={{clipPath: "polygon(0 0, 100% 0, 100% 100%)"}}></div>}
                            {before && before.length > 0 && <img src={before} alt="" className={`w-full h-full rounded-xl object-cover object-top  transition-all duration-1000 z-10 relative bg-${bg ? bg : 'white'}`} />}
                            {video && video.length > 0 && <video src={video} muted loop data-autoplay="true" playsInline className="w-full h-full rounded-xl object-cover object-center  transition-all duration-1000 z-10 relative "></video>}
                            <div className="absolute bottom-0 left-0 w-full p-8 z-20 bg-gradient-to-b from-neutral-900/0 to-neutral-900 rounded-xl">
                                <p className="text-3xl 2xl:text-5xl text-white font-bold">{ title }</p>
                                {/* <p className="serif text-lg text-white italic mt-2">{ position }</p> */}
                            </div>
                        </div>
                    </div>
                        
                          <div ref={cardBack} className="back absolute top-0 left-0 h-full w-full z-10 rounded-xl " style={{transform:'translate3d(0,0,-1px) rotateY(180deg) '}}>
                          {after && after.length > 0 && <img src={after} alt="" className={`w-full h-full rounded-xl object-cover object-top transition-all duration-1000 z-10 relative bg-${bg ? bg : 'white'}`} />}

                          </div>
            </SwiperSlide>
          );
          break;
          case "card":
            output = (
              <SwiperSlide key={i} className={`swiper-slide relative card-container  ${ flipped ? 'flipped' : '' }`} style={{backfaceVisibility: 'visible !important'}} ref={cardContainer} >
                      <div className="card h-[70vh] ease-in-out group rounded-xl overflow-hidden relative z-10 bg-[#FEAF80] p-8 text-neutral-900" ref={card} >
                          <div className="absolute top-0 right-0 z-0  w-full h-full">
                              <div className="relative flex items-center justify-center h-full w-full -translate-x-1/4 translate-y-1/4">
                                  <FontAwesomeIcon icon={faPerson} className="absolute text-white/10 text-9xl" />
                                  <FontAwesomeIcon icon={faPerson} className="absolute text-white/10 text-9xl scale-[3] " />
                                  <FontAwesomeIcon icon={faPerson} className="absolute text-white/10 text-9xl scale-[5] " />
                                  <FontAwesomeIcon icon={faPerson} className="absolute text-white/10 text-9xl scale-[7] " />
                                  <FontAwesomeIcon icon={faPerson} className="absolute text-white/10 text-9xl scale-[9] " />
  
                              </div>
                          </div>
                          <div className="flex flex-row items-start justify-between w-full relative z-20">
                              <p className="text-lg 3xl:text-2xl font-extrabold text-neutral-900">{title || "Survey"}</p>
                              {/* <p className="text-base text-neutral-900">{month}</p> */}
                          </div>
                              <p className="xl:text-lg 3xl:text-xl xl:leading-snug mt-4 relative z-20 ">{`${name?.length > 0 ? '“' : ""}${message}${name?.length > 0 ? '”': ''}`}</p>
                      </div>
                  </SwiperSlide>
            );
            break;

        case "photo": 
          output = (
              <SwiperSlide key={i} onClick={handleClick} className={`swiper-slide relative card-container  ${ flipped ? 'flipped' : '' }`} style={{backfaceVisibility: 'visible !important'}} ref={cardContainer} >
                            <div className="card h-[70vh] ease-in-out group rounded-xl overflow-hidden relative z-10" ref={card} >
                                      <div className="front absolute top-0 left-0 h-full w-full">
                                          {message && message.length > 0 && <div className=" absolute top-0 right-0 h-16 w-16 bg-blue-500 rounded-tr-xl z-20" style={{clipPath: "polygon(0 0, 100% 0, 100% 100%)"}}></div>}
                                          {img && img.length > 0 && <img src={img} alt="" className={`w-full h-full rounded-xl object-cover object-top   z-10 relative bg-${bg ? bg : 'white'}`} />}
                                          {video && video.length > 0 && <video src={video} muted loop data-autoplay="true" playsInline className="w-full h-full rounded-xl object-cover object-center brightness-75  transition-all duration-1000 z-10 relative "></video>}
                                          {title && title.length > 0 && (<div className="absolute bottom-0 left-0 w-full p-8 z-20 bg-gradient-to-b from-neutral-900/0 to-neutral-900 rounded-xl">
                                              <p className="text-3xl 2xl:text-5xl text-white font-bold">{ title }</p>
                                          </div>)}
                                          {}
                                      </div>
                                  </div>
                                      {message && 
                                        <div ref={cardBack} className="back absolute top-0 left-0 h-full w-full z-10 bg-blue-500/80 p-8 rounded-xl backdrop-blur-xl" style={{transform:'translate3d(0,0,-1px) rotateY(180deg) '}}>
                                          {message.split('\n').map((line,i) => <p key={i} className="text-sm 2xl:text-2xl text-white mb-4">{line}</p>)}
                                        </div>}
                          </SwiperSlide>
              )
          break;
        default: 
          initials = name?.split(' ').map(word => word[0]).join('') || '';
          output = (
            <SwiperSlide key={i} onClick={handleClick} className={`swiper-slide relative card-container  ${ flipped ? 'flipped' : '' }`} style={{backfaceVisibility: 'visible !important'}} ref={cardContainer} >
                    <div className="card h-[70vh] ease-in-out group rounded-xl overflow-hidden relative z-10" ref={card} >
                        <div className="front absolute top-0 left-0 h-full w-full">
                            {message && message.length > 0 && <div className=" absolute top-0 right-0 h-16 w-16 bg-blue-500 rounded-tr-xl z-20" style={{clipPath: "polygon(0 0, 100% 0, 100% 100%)"}}></div>}
                            {img && img.length > 0 && <img src={img} alt="" className={`w-full h-full rounded-xl object-cover object-top  transition-all duration-1000 z-10 relative bg-${bg ? bg : 'white'}`} />}
                            {video && video.length > 0 && <video src={video} muted loop data-autoplay="true" playsInline className="w-full h-full rounded-xl object-cover object-center  transition-all duration-1000 z-10 relative "></video>}
                            {(name || position ) && <div className="absolute bottom-0 left-0 w-full p-8 z-20 bg-gradient-to-b from-neutral-900/0 to-neutral-900 rounded-xl" style={{transform: "translateZ(0)"}}>
                                <p className="text-xl 2xl:text-4xl text-white font-bold">{ name }</p>
                                <p className="serif text-lg text-white italic mt-2">{ position }</p>
                            </div>}
                            {button && <div className="absolute bottom-0 z-20 bg-gradient-to-b from-neutral-900/0 to-neutral-900 right-0 w-full z-20 p-8 min-h-1/3 flex items-end justify-center">
                              <button 
                               onClick={(el) =>
                                showModal(
                                  el,
                                  null,
                                  <div className="max-w-screen-2xl rounded-xl overflow-hidden">
                                    <img src={button.link} alt="" className="w-full max-w-unset" />
                                  </div>
                                )
                              }
                              
                              className="text-white px-4 py-2 border-white border-2 rounded-full">{button.text}</button>

                            </div>
                              }
                        </div>
                    </div>
                        {message && <div ref={cardBack} className="back absolute top-0 left-0 h-full w-full z-10 bg-blue-500/80 p-8 rounded-xl backdrop-blur-xl" style={{transform:'translate3d(0,0,-1px) rotateY(180deg) '}}>
                            {message.split('\n').map((line,i) => <p key={i} className="text-sm 2xl:text-2xl text-white mb-4">{line}</p>)}
                            <div className="flex flex-row items-center justify-start gap-2 mt-4">
                                    <div className="h-12 w-12 bg-neutral-900 rounded-full grid place-items-center text-white" >
                                        {initials}
                                    </div>
                                    <div>
                                        <p className="text-lg 2xl:text-xl font-bold mb-0 leading-none text-white">{name}</p>
                                        <p className="text-base mt-0 leading-none text-white">{position}</p>
                                    </div>
                                </div>
                            </div>}
                </SwiperSlide>
          )
      }
      return output;
    }

    return cardSwitch({img, video, name, position, title, bg, message, type, date, score, flippable, before, after, button})

}
