export default function Agenda() {
    return (
        <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-left justify-center ">
            <div className="pl-8 border-l-2 border-primary-300">
            <p className="newmonthly-tag text-primary-300">Agenda</p>
            <p className="text-4xl 2xl:text-7xl text-white !font-black mb-8">Account Overview</p>
            <p className="text-4xl 2xl:text-7xl text-white !font-black mb-8">Performance Results</p>
            <p className="text-4xl 2xl:text-7xl text-white !font-black mb-8">Market Updates</p>
            <p className="text-4xl 2xl:text-7xl text-white !font-black">Path Forward</p>
            </div>
        </section>
    )
}