import React, { useEffect, useRef, useState } from 'react';
import "./image.css";


export function LazyImage({ src, placeholder, alt = '', className }) {
  const imgRef = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const imgObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const src = img.getAttribute('data-src');
          img.setAttribute('src', src);
          img.classList.add('fade-in');
          observer.disconnect();
        }
      });
    });

    imgObserver.observe(imgRef.current);

    return () => imgObserver.disconnect();
  }, []);

  return (
    <img
      ref={imgRef}
      data-src={src}
      src={placeholder}
      alt={alt}
      className={`lazy-image ${loaded ? 'visible' : 'blur'} ${className}`}
      onLoad={() => setLoaded(true)}
    />
  );
}

export default LazyImage;