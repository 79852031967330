import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faClipboardCheck} from '@fortawesome/free-solid-svg-icons'

export default function Complaints({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: true,
                align: 'center',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
                labels: {
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "white",
                  },
              }
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              min: 0,
              title: '',
              gridLineColor: 'rgb(40, 48, 56)',
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            tooltip: {
              backgroundColor: "rgb(12, 15, 18)",
              outside: true,
              shadow: false,
              style: { color: "#ffffff", fontSize: '16px' },

          },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                borderRadius: 5,
                crisp: true,
                groupPadding: 0.2,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: true,
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-rose-500">Complaints</p>
                    <p className="monthly-title">
                    Complaints remain lower than their September high. 
                    </p>
                    <p className="text-xl 2xl:text-3xl text-white mt-4">We're tracking an increase in escalated and repeat complaints this month due to close monitoring of key account challenges.</p>
                    {/* <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                            <div className="p-6">
                                <div className="flex flex-row items-center gap-6">
                                    <div className="w-20 h-20 rounded-full flex-shrink-0 bg-blue-500/20 text-blue-500 flex items-center justify-center ">
                                        <FontAwesomeIcon className={'text-4xl'} icon={faClipboardCheck} />
                                    </div>
                                <div>
                                <p className="text-xl font-bold text-white">Failed Audits</p>
                                <p className="text-lg text-white/70">SBM Recorded only 25 failing audits in November—a substantial improvement from the 42 recorded in October.</p>
                                </div>
                                </div>


                            </div>


                    </div>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                            <div className="p-6">
                                <div className="flex flex-row items-center gap-6">
                                    <div className="w-20 h-20 rounded-full flex-shrink-0 bg-[#A230D222] text-[#A230D2] flex items-center justify-center ">
                                        <FontAwesomeIcon className={'text-4xl'} icon={faClipboardCheck} />
                                    </div>
                                <div>
                                <p className="text-xl font-bold text-white">Customer Audits Audits</p>
                                <p className="text-lg text-white/70">We've continued to see an upward trend in customer audits, an almost 30% improvement over the past 6 months.</p>
                                </div>
                                </div>


                            </div>


                    </div> */}
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-full">
                        <Chart className="w-full" data={[
  {
    name: "Complaints",
    color: '#f43f5e',
    data: [
      { name: "Jun", color: "#f43f5e55", y: 44 },
      { name: "Jul", color: "#f43f5e55", y: 67 },
      { name: "Aug", color: "#f43f5e55", y: 77 },
      { name: "Sep", color: "#f43f5e55", y: 79 },
      { name: "Oct", color: "#f43f5e55", y: 61 },
      { name: "Nov", color: "#f43f5e", y: 71 },
    ],
  },
  {
    name: "Repeat",
    color: "#be123c",
    data: [
      { name: "Jun", color: "#be123c55", y: 4},
      { name: "Jul", color: "#be123c55", y: 4 },
      { name: "Aug", color: "#be123c55", y: 5 },
      { name: "Sep", color: "#be123c55", y: 5 },
      { name: "Oct", color: "#be123c55", y: 6 },
      { name: "Nov", color: "#be123c", y: 12 }
    ],
  },
  {
    name: "Escalated",
    color: '#FDF3A4',
    data: [
      { name: "Jun", color: "#FDF3A455", y: 1},
      { name: "Jul", color: "#FDF3A455", y: 1 },
      { name: "Aug", color: "#FDF3A455", y: 2 },
      { name: "Sep", color: "#FDF3A455", y: 8 },
      { name: "Oct", color: "#FDF3A455", y: 8 },
      { name: "Nov", color: "#FDF3A4", y: 11 }
    ],
  },
]} />
                    </div>
                </div>
            </div>
        </Section>
    )
}
  