import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faClipboardCheck} from '@fortawesome/free-solid-svg-icons'

export default function Turnover({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: true,
                align: 'center',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
                labels: {
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    },
                }
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              min: 0,
              title: '',
              gridLineColor: 'rgb(40, 48, 56)',
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            tooltip: {
                backgroundColor: "rgb(12, 15, 18)",
                outside: true,
                shadow: false,
                style: { color: "#ffffff", fontSize: '16px' },

            },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                borderRadius: 5,
                crisp: true,
                groupPadding: 0.2,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: false,
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-cyan-400">Turnover</p>
                    <p className="monthly-title">
                        SBM reduced turnover in November to 2%, down from the previous 4% in October.
                    </p>
                    <div className="w-full mt-6 bg-neutral-800 rounded-lg p-6">
                    <p className="text-lg 2xl:text-xl font-bold text-white">Tenure</p>
                                <p className="text-base 2xl:text-lg text-white/70">Our industry-best turnover creates tenured, experienced teams capable of providing the best service for our customers.</p>
                                <div className="w-full mt-4 flex flex-col gap-4 ">
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-12 w-12 2xl:h-16 2xl:w-16 flex items-center justify-center bg-cyan-400 rounded-full">
                                            <p className="text-xl 2xl:text-2xl font-black text-neutral-900">65%</p>
                                        </div>
                                        <div className="flex-grow">
                                        <p className="text-lg 2xl:text-xl font-bold text-white flex-grow">1+ Years</p>
                                        <p className="text-base 2xl:text-lg font-normal text-neutral-500">1,697 Employees</p>
                                        </div>

                                    </div>
                                </div>
                    </div>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-full">
                        <Chart className="w-full" data={[{
                            name:"Turnover",
                            color: '#ff8A80',
                            data:[
                                {color: "#ff8A8055", y:106},
                                {color: "#ff8A8055", y:107},
                                {color: "#ff8A8055", y:106},
                                {color: "#ff8A8055", y:131},
                                {color: "#ff8A8055", y:138},
                                {color: "#ff8A80", y:76}]
                        }, {
                            name: "New Hires",
                            color: '#80e6ff',
                            data: [
                                {color: "#80e6ff55", y:61},
                                {color: "#80e6ff55", y:78},
                                {color: "#80e6ff55", y:77},
                                {color: "#80e6ff55", y:94},
                                {color: "#80e6ff55", y:110},
                                {color: "#80e6ff", y:83}]
                        }]} />
                    </div>
                </div>
            </div>
        </Section>
    )
}
  