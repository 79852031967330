export const sites = [
    {
        "id": 5849,
        "name": "10 Exchange Place, NJ",
        "correlation_id": "102763",
        "billing_address": {
            "id": 4108,
            "street1": "10 Exchange Place",
            "street2": "",
            "city": "Jersey City",
            "region": "New Jersey",
            "postal_code": null,
            "lat": 40.7164058,
            "lon": -74.0333545,
            "country": "United States"
        },
        "start_date": "2024-02-06T17:01:19.433000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1018,
            "name": "Chubb Insurance (CBRE)",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 1442,
                    "street1": "202 Halls Mill Rd A",
                    "street2": "",
                    "city": "Whitehouse Station",
                    "region": "New Jersey",
                    "postal_code": "08889",
                    "lat": 40.6459052,
                    "lon": -74.7647032,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033"
        },
        "parent_id": null,
        "lat": 40.71662,
        "lon": -74.033523,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Chubb Insurance (CBRE)",
        "_label": "Chubb Insurance (CBRE)",
        "_name": "10 Exchange Place, NJ",
        "_lat": 40.71662,
        "_lng": -74.033523,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -74.033523
    },
    {
        "id": 6747,
        "name": "1633 Fairlane Circle",
        "correlation_id": "103414",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29452,
        "lon": -83.19307,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "1633 Fairlane Circle",
        "_lat": 42.29452,
        "_lng": -83.19307,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.19307
    },
    {
        "id": 5681,
        "name": "2047 Lakewood Blvd*",
        "correlation_id": null,
        "billing_address": null,
        "start_date": "2023-04-01T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 42.07426,
        "lon": -88.13233,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "eda9d9ff-83be-4934-a5ea-468c801ea128",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "2047 Lakewood Blvd*",
        "_lat": 42.07426,
        "_lng": -88.13233,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -88.13233
    },
    {
        "id": 5551,
        "name": "333 Market (JLL)",
        "correlation_id": "102367",
        "billing_address": {
            "id": 3788,
            "street1": "333 Market Street",
            "street2": "",
            "city": "San Francisco",
            "region": "California",
            "postal_code": null,
            "lat": 37.7915342,
            "lon": -122.3976135,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 37.7915341,
        "lon": -122.4021035,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f7d4a594-a35d-40ca-9396-0f9db82700ff",
        "is_mobile": true,
        "mobile_live_date": "2023-09-26",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "333 Market (JLL)",
        "_lat": 37.7915341,
        "_lng": -122.4021035,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.4021035
    },
    {
        "id": 5566,
        "name": "550 California (JLL)",
        "correlation_id": "102371",
        "billing_address": null,
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 37.7927928,
        "lon": -122.4035646,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "78c1e2c7-0d04-4da4-834d-f78a80e70012",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "550 California (JLL)",
        "_lat": 37.7927928,
        "_lng": -122.4035646,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.4035646
    },
    {
        "id": 6742,
        "name": "800 Republic",
        "correlation_id": "103422",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29718,
        "lon": -83.20118,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "800 Republic",
        "_lat": 42.29718,
        "_lng": -83.20118,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.20118
    },
    
    {
        "id": 2623,
        "name": "Albany, OH",
        "correlation_id": "101564",
        "billing_address": {
            "id": 735,
            "street1": "5400 New Albany Rd E",
            "street2": "",
            "city": "New Albany",
            "region": "Ohio",
            "postal_code": "43054",
            "lat": 40.0949648,
            "lon": -82.817737,
            "country": "United States"
        },
        "start_date": "2021-09-30T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 40.09347,
        "lon": -82.82072,
        "local_timezone": {
            "id": 2247,
            "code": "America/North_Dakota/Center",
            "display_name": "America/North_Dakota/Center",
            "description": "",
            "child_group": null,
            "alt_sequence": 113
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "cb4abfa7-fddc-4aac-872c-7bd811c6fc36",
        "is_mobile": true,
        "mobile_live_date": "2022-09-29",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Albany, OH",
        "_lat": 40.09347,
        "_lng": -82.82072,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -82.82072
    },
    {
        "id": 2632,
        "name": "Alpharetta, GA",
        "correlation_id": "101577",
        "billing_address": {
            "id": 3027,
            "street1": "3000 Marconi Dr",
            "street2": "",
            "city": "Alpharetta",
            "region": "Georgia",
            "postal_code": null,
            "lat": 34.0849895,
            "lon": -84.2458835,
            "country": "United States"
        },
        "start_date": "2021-10-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 34.08514,
        "lon": -84.24585,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Alpharetta, GA",
        "_lat": 34.08514,
        "_lng": -84.24585,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.24585
    },
    {
        "id": 2252,
        "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
        "correlation_id": "101246",
        "billing_address": {
            "id": 205,
            "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": "48124",
            "lat": 42.2941466,
            "lon": -83.2271782,
            "country": "United States"
        },
        "start_date": "2020-11-08T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": 5847,
        "lat": 42.3794728,
        "lon": -83.2885154,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
        "is_mobile": true,
        "mobile_live_date": "2021-03-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "1",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
        "_lat": 42.3794728,
        "_lng": -83.2885154,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2885154
    },
    {
        "id": 5775,
        "name": "AOC",
        "correlation_id": "102624",
        "billing_address": {
            "id": 3629,
            "street1": "1 Coca Cola Pl SE",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": null,
            "lat": 33.7711748,
            "lon": -84.3972825,
            "country": "United States"
        },
        "start_date": "2023-07-01T21:35:03Z",
        "total_sq_ft": "0",
        "total_buildings": "12",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.7709481,
        "lon": -84.3971999,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "AOC",
        "_lat": 33.7709481,
        "_lng": -84.3971999,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.3971999
    },
    {
        "id": 5687,
        "name": "Apache Junction",
        "correlation_id": "102622",
        "billing_address": {
            "id": 3739,
            "street1": "2870 West Apache Trail",
            "street2": "",
            "city": "Apache Junction",
            "region": "Arizona",
            "postal_code": null,
            "lat": 33.4162348,
            "lon": -111.5772852,
            "country": "United States"
        },
        "start_date": "2023-03-29T20:24:25Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.41617,
        "lon": -111.57709,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "491e8f78-3149-4575-b854-eb644c6daa09",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Apache Junction",
        "_lat": 33.41617,
        "_lng": -111.57709,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.57709
    },
    
    {
        "id": 1800,
        "name": "Arrowhead",
        "correlation_id": "102701",
        "billing_address": {
            "id": 3740,
            "street1": "21731 N. 77th Avenue",
            "street2": "",
            "city": "Peoria",
            "region": "Arizona",
            "postal_code": null,
            "lat": 33.6810115,
            "lon": -112.223266,
            "country": "United States"
        },
        "start_date": "2023-03-29T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.6810115,
        "lon": -112.223266,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "309d2a98-4019-4aec-b07a-27da30b0af9f",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Arrowhead",
        "_lat": 33.6810115,
        "_lng": -112.223266,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -112.223266
    },
    {
        "id": 4836,
        "name": "Asheville, NC*",
        "correlation_id": "102208",
        "billing_address": {
            "id": 3964,
            "street1": "GC38+XX",
            "street2": "",
            "city": "Asheville",
            "region": "North Carolina",
            "postal_code": null,
            "lat": 35.5049375,
            "lon": -82.5825625,
            "country": "United States"
        },
        "start_date": "2023-12-27T20:26:33Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 438,
            "name": "Pratt & Whitney (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "101371",
            "addresses": [
                {
                    "id": 273,
                    "street1": "17900 Bee Line HWY",
                    "street2": "",
                    "city": "Jupitor",
                    "region": "Florida",
                    "postal_code": "33478",
                    "lat": 26.9047849,
                    "lon": -80.3040239,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "250a2c64-7e26-4b6d-8f36-167c56bcee3d"
        },
        "parent_id": null,
        "lat": 35.5049375,
        "lon": -82.5825625,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Pratt & Whitney (CBRE)",
        "_label": "Pratt & Whitney (CBRE)",
        "_name": "Asheville, NC*",
        "_lat": 35.5049375,
        "_lng": -82.5825625,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -82.5825625
    },
    
    
    {
        "id": 3999,
        "name": "Bannockburn",
        "correlation_id": "102072",
        "billing_address": {
            "id": 1975,
            "street1": "1200 Lakeside Drive",
            "street2": "",
            "city": "Bannockburn",
            "region": "Illinois",
            "postal_code": "60015",
            "lat": 42.1942834,
            "lon": -87.8824906,
            "country": "United States"
        },
        "start_date": "2022-07-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2218,
            "code": "America/Kentucky/Louisville",
            "display_name": "America/Kentucky/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 84
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
        "is_mobile": true,
        "mobile_live_date": "2023-04-25",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Bannockburn",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 3996,
        "name": "Bayfront",
        "correlation_id": "102100",
        "billing_address": {
            "id": 1971,
            "street1": "1 Facebook Way",
            "street2": "",
            "city": "Menlo Park",
            "region": "California",
            "postal_code": "94025",
            "lat": 37.4810678,
            "lon": -122.1516142,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "38",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
        "is_mobile": true,
        "mobile_live_date": "2022-11-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Bayfront",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 2640,
        "name": "Bethlehem",
        "correlation_id": "101568",
        "billing_address": {
            "id": 776,
            "street1": "1510 Valley Center Parkway, Suite #130",
            "street2": "",
            "city": "Bethlehem",
            "region": "Pennsylvania",
            "postal_code": "18017",
            "lat": 40.654568,
            "lon": -75.4068888,
            "country": "United States"
        },
        "start_date": "2021-06-01T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 470,
            "name": "IQVIA (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 573,
                    "street1": "2400 Ellis road",
                    "street2": "",
                    "city": "Durham",
                    "region": "North Carolina",
                    "postal_code": "27703",
                    "lat": 35.9353484,
                    "lon": -78.8545872,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "d7f7ea55-d5e5-4f58-9ffe-85e17bde2246"
        },
        "parent_id": null,
        "lat": 40.654568,
        "lon": -75.4068888,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7dd14439-d031-4edb-80a1-d29ebbbae2e6",
        "is_mobile": true,
        "mobile_live_date": "2022-08-15",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "IQVIA (CBRE)",
        "_label": "IQVIA (CBRE)",
        "_name": "Bethlehem",
        "_lat": 40.654568,
        "_lng": -75.4068888,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -75.4068888
    },
    {
        "id": 4231,
        "name": "Blings Ops*",
        "correlation_id": "101962",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2262,
            "code": "America/Regina",
            "display_name": "America/Regina",
            "description": "",
            "child_group": null,
            "alt_sequence": 128
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8a48eee0-2e12-4296-bd2c-fb7877f8da28",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Blings Ops*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 1962,
        "name": "Bloomfield",
        "correlation_id": "11570",
        "billing_address": {
            "id": 651,
            "street1": "900 Cottage Grove Rd",
            "street2": "",
            "city": "Bloomfield",
            "region": "Connecticut",
            "postal_code": "06473",
            "lat": 41.8128862,
            "lon": -72.7469102,
            "country": "United States"
        },
        "start_date": "2021-08-04T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 41.8128862,
        "lon": -72.7469102,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "674967cb-d560-4440-809d-d682f82678b8",
        "is_mobile": true,
        "mobile_live_date": "2021-05-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Bloomfield",
        "_lat": 41.8128862,
        "_lng": -72.7469102,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -72.7469102
    },
    {
        "id": 2264,
        "name": "Bothell, WA",
        "correlation_id": "101294",
        "billing_address": {
            "id": 137,
            "street1": "22422 29th Dr SE",
            "street2": "",
            "city": "Bothell",
            "region": "Washington",
            "postal_code": "98021",
            "lat": 47.7946092,
            "lon": -122.1967686,
            "country": "United States"
        },
        "start_date": "2021-01-29T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "908fc000-28d0-439e-9912-ff88967db144",
        "is_mobile": true,
        "mobile_live_date": "2021-01-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Bothell, WA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 1798,
        "name": "Branchburg",
        "correlation_id": "11523",
        "billing_address": {
            "id": 2987,
            "street1": "1 Millennium Way",
            "street2": "",
            "city": "Branchburg",
            "region": "New Jersey",
            "postal_code": null,
            "lat": 40.5640024,
            "lon": -74.7101129,
            "country": "United States"
        },
        "start_date": "2019-07-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 40.5612593,
        "lon": -74.7270898,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
        "is_mobile": true,
        "mobile_live_date": "2022-12-07",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Branchburg",
        "_lat": 40.5612593,
        "_lng": -74.7270898,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -74.7270898
    },
    {
        "id": 2486,
        "name": "Bristol, PA",
        "correlation_id": "101437",
        "billing_address": {
            "id": 646,
            "street1": "360 George Patterson Blvd",
            "street2": "",
            "city": "Bristol",
            "region": "Pennsylvania",
            "postal_code": "19007",
            "lat": 40.0927335,
            "lon": -74.8709066,
            "country": "United States"
        },
        "start_date": "2021-07-23T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 448,
            "name": "Abzena (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "4d596964-3e74-4f53-ada1-efe738a50a1c"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8ae7a2df-156a-4702-9b27-80ff858ddac1",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Abzena (CBRE)",
        "_label": "Abzena (CBRE)",
        "_name": "Bristol, PA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 2257,
        "name": "Burbank*",
        "correlation_id": "101035",
        "billing_address": {
            "id": 679,
            "street1": "4201 Vanowen St",
            "street2": "",
            "city": "Burbank",
            "region": "California",
            "postal_code": "91505",
            "lat": 34.1933726,
            "lon": -118.3568512,
            "country": "United States"
        },
        "start_date": "2019-10-07T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 34.1928261,
        "lon": -118.3592953,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Suspended",
        "uuid": "201f8359-cb7b-46b5-b8d6-3eba2fbf17a6",
        "is_mobile": true,
        "mobile_live_date": "2021-09-02",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Burbank*",
        "_lat": 34.1928261,
        "_lng": -118.3592953,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -118.3592953
    },
    {
        "id": 3992,
        "name": "Burlingame",
        "correlation_id": "102107",
        "billing_address": {
            "id": 1967,
            "street1": "322 Airport Blvd.",
            "street2": "",
            "city": "Burlingame",
            "region": "California",
            "postal_code": "94010",
            "lat": 37.5903727,
            "lon": -122.3359498,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
        "is_mobile": true,
        "mobile_live_date": "2022-11-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Burlingame",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 1955,
        "name": "Cambridge",
        "correlation_id": "11429",
        "billing_address": {
            "id": 3755,
            "street1": "225 Binney Street",
            "street2": "",
            "city": "Cambridge",
            "region": "Massachusetts",
            "postal_code": null,
            "lat": 42.3652992,
            "lon": -71.0872894,
            "country": "United States"
        },
        "start_date": "2018-12-21T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "13",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 253,
            "name": "Biogen (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11428",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
        },
        "parent_id": null,
        "lat": 42.3662897,
        "lon": -71.0857637,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
        "is_mobile": true,
        "mobile_live_date": "2020-08-25",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Biogen (CBRE)",
        "_label": "Biogen (CBRE)",
        "_name": "Cambridge",
        "_lat": 42.3662897,
        "_lng": -71.0857637,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859837,
                "create_date": "2020-11-17T14:36:01.480552Z",
                "update_date": "2020-11-17T14:36:01.480579Z",
                "organization_id": 253,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623761.388814_Biogen01_t.png",
                "size": 6068,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.0857637
    },
    {
        "id": 5828,
        "name": "Cambridge Central*",
        "correlation_id": "102748",
        "billing_address": null,
        "start_date": "2023-07-24T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 42.36081,
        "lon": -71.09966,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "447bf290-3990-4d32-b24d-f7addb23fd34",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Cambridge Central*",
        "_lat": 42.36081,
        "_lng": -71.09966,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.09966
    },
    {
        "id": 3299,
        "name": "Cambridge Kendall SQ",
        "correlation_id": "102095",
        "billing_address": {
            "id": 3021,
            "street1": "125 Binney St.",
            "street2": "",
            "city": "Cambridge",
            "region": "Massachusetts",
            "postal_code": null,
            "lat": 42.3658233,
            "lon": -71.08079,
            "country": "United States"
        },
        "start_date": "2022-08-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 42.36606901,
        "lon": -71.08077927,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
        "is_mobile": true,
        "mobile_live_date": "2023-01-12",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Cambridge Kendall SQ",
        "_lat": 42.36606901,
        "_lng": -71.08077927,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.08077927
    },
    {
        "id": 1999,
        "name": "Cambridge, MA",
        "correlation_id": "11657",
        "billing_address": {
            "id": 488,
            "street1": "181 Massachusetts Ave.",
            "street2": "",
            "city": "Cambridge",
            "region": "Alabama",
            "postal_code": "02139",
            "lat": 32.3182314,
            "lon": -86.902298,
            "country": "United States"
        },
        "start_date": "2019-12-31T23:59:59Z",
        "total_sq_ft": "1420281",
        "total_buildings": "9",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 42.373611,
        "lon": -71.110558,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "302a70b3-c3d6-468e-a8a4-d1ec9f4d9a80",
        "is_mobile": true,
        "mobile_live_date": "2021-03-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "Cambridge, MA",
        "_lat": 42.373611,
        "_lng": -71.110558,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.110558
    },
    {
        "id": 2087,
        "name": "Campus - MN",
        "correlation_id": "11692",
        "billing_address": {
            "id": 722,
            "street1": "2791 Well Fargo Way",
            "street2": "",
            "city": "Minneapolis",
            "region": "Minnesota",
            "postal_code": "55467",
            "lat": 44.9526737,
            "lon": -93.2702566,
            "country": "United States"
        },
        "start_date": "2014-04-30T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.9534026,
        "lon": -93.2726714,
        "local_timezone": {
            "id": 2233,
            "code": "America/Menominee",
            "display_name": "America/Menominee",
            "description": "",
            "child_group": null,
            "alt_sequence": 99
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
        "is_mobile": true,
        "mobile_live_date": "2021-07-13",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Campus - MN",
        "_lat": 44.9534026,
        "_lng": -93.2726714,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.2726714
    },
    {
        "id": 5858,
        "name": "CBB – Crash Barrier – Dearborn, MI",
        "correlation_id": "102792",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "CBB – Crash Barrier – Dearborn, MI",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5544,
        "name": "Cedar Rapids Downtown",
        "correlation_id": "102358",
        "billing_address": {
            "id": 3063,
            "street1": "150 1st Ave NE",
            "street2": "",
            "city": "Cedar Rapids",
            "region": "Iowa",
            "postal_code": null,
            "lat": 41.9787834,
            "lon": -91.6692618,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.97856,
        "lon": -91.66904,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b81ab5f3-6dcd-4e50-969b-56157fdb7d82",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Cedar Rapids Downtown",
        "_lat": 41.97856,
        "_lng": -91.66904,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -91.66904
    },
    {
        "id": 2107,
        "name": "Cedral",
        "correlation_id": "11714",
        "billing_address": {
            "id": 806,
            "street1": "Centro Corporativo El Cedral",
            "street2": "",
            "city": "San Jose",
            "region": "Alabama",
            "postal_code": "000000",
            "lat": 32.3182314,
            "lon": -86.902298,
            "country": "United States"
        },
        "start_date": "2019-10-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 115,
            "name": "Uber (CBRE) (CR)",
            "type": "CUSTOMER",
            "correlation_id": "10479",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a0fa919b-e92b-469f-9fec-38f2bd9fa7a4"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2177,
            "code": "America/Costa_Rica",
            "display_name": "America/Costa_Rica",
            "description": "",
            "child_group": null,
            "alt_sequence": 43
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c8a55f2e-c98e-4e93-b76d-d65741bd4c0a",
        "is_mobile": true,
        "mobile_live_date": "2021-11-22",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE) (CR)",
        "_label": "Uber (CBRE) (CR)",
        "_name": "Cedral",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 932145,
                "create_date": "2020-12-10T19:10:56.836917Z",
                "update_date": "2020-12-10T19:10:56.836940Z",
                "organization_id": 115,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607627456.7434294_UberCR_t.jpg",
                "size": 7874,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5497,
        "name": "Central",
        "correlation_id": "102405",
        "billing_address": {
            "id": 2994,
            "street1": "2329 Central Avenue Northeast",
            "street2": "",
            "city": "Minneapolis",
            "region": "Minnesota",
            "postal_code": null,
            "lat": 45.0117979,
            "lon": -93.2467593,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "48755",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 45.0117,
        "lon": -93.24736,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Central",
        "_lat": 45.0117,
        "_lng": -93.24736,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.24736
    },
    {
        "id": 1647,
        "name": "Central SQ",
        "correlation_id": "11218",
        "billing_address": {
            "id": 459,
            "street1": "300 Massachusetts Ave.",
            "street2": "",
            "city": "Cambridge",
            "region": "Massachusetts",
            "postal_code": "02139",
            "lat": 42.3622734,
            "lon": -71.098686,
            "country": "United States"
        },
        "start_date": "2018-06-30T23:59:59Z",
        "total_sq_ft": "4728000",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 42.3604977,
        "lon": -71.0995145,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Central SQ",
        "_lat": 42.3604977,
        "_lng": -71.0995145,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.0995145
    },
    {
        "id": 1809,
        "name": "Chandler",
        "correlation_id": "102702",
        "billing_address": {
            "id": 366,
            "street1": "4625 E Cotton Center Blvd, Suite 177",
            "street2": "",
            "city": "Phoenix",
            "region": "Arizona",
            "postal_code": "480",
            "lat": 33.4007794,
            "lon": -111.9816957,
            "country": "United States"
        },
        "start_date": "2019-07-14T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.3055493,
        "lon": -111.8712977,
        "local_timezone": {
            "id": 2164,
            "code": "America/Boise",
            "display_name": "America/Boise",
            "description": "",
            "child_group": null,
            "alt_sequence": 30
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
        "is_mobile": true,
        "mobile_live_date": "2021-04-07",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Chandler",
        "_lat": 33.3055493,
        "_lng": -111.8712977,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.8712977
    },
    {
        "id": 2681,
        "name": "Charlottesville, VA",
        "correlation_id": "101591",
        "billing_address": {
            "id": 802,
            "street1": "1500 State Farm Blvd",
            "street2": "",
            "city": "Charlotte",
            "region": "Virginia",
            "postal_code": "22909",
            "lat": 38.0228509,
            "lon": -78.4499721,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 38.02241,
        "lon": -78.44999,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "03307a6c-63d5-44ef-9e7b-050479a0f7a0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Charlottesville, VA",
        "_lat": 38.02241,
        "_lng": -78.44999,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -78.44999
    },
    {
        "id": 1486,
        "name": "Cherokee, PA",
        "correlation_id": "10071",
        "billing_address": {
            "id": 676,
            "street1": "100 Avenue C",
            "street2": "",
            "city": "Riverside",
            "region": "Pennsylvania",
            "postal_code": "17868",
            "lat": 40.9614032,
            "lon": -76.6365697,
            "country": "United States"
        },
        "start_date": "2013-10-31T23:59:59Z",
        "total_sq_ft": "6380000",
        "total_buildings": "67",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 40.96156646,
        "lon": -76.63851066,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
        "is_mobile": true,
        "mobile_live_date": "2021-10-13",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "Cherokee, PA",
        "_lat": 40.96156646,
        "_lng": -76.63851066,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -76.63851066
    },
    {
        "id": 5977,
        "name": "CHICAGO 20*",
        "correlation_id": "102872",
        "billing_address": null,
        "start_date": "2023-11-23T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 41.92782,
        "lon": -87.85933,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3fa7ed8b-d7e3-4f0b-900e-3db28c9a580f",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "CHICAGO 20*",
        "_lat": 41.92782,
        "_lng": -87.85933,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -87.85933
    },
    {
        "id": 5978,
        "name": "CHICAGO 22*",
        "correlation_id": "102873",
        "billing_address": null,
        "start_date": "2023-11-21T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 41.92917,
        "lon": -87.86152,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e88bcf7b-e607-4a1c-a6f8-fb435f30a6df",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "CHICAGO 22*",
        "_lat": 41.92917,
        "_lng": -87.86152,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -87.86152
    },
    {
        "id": 2267,
        "name": "Chicago, IL",
        "correlation_id": "101289",
        "billing_address": {
            "id": 185,
            "street1": "2200 Busse Road",
            "street2": "",
            "city": "Elk Grove Village",
            "region": "Illinois",
            "postal_code": "60007",
            "lat": 41.9942563,
            "lon": -87.9619655,
            "country": "United States"
        },
        "start_date": "2020-12-06T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "9",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
        "is_mobile": true,
        "mobile_live_date": "2021-02-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Chicago, IL",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 3994,
        "name": "Chilco",
        "correlation_id": "102102",
        "billing_address": {
            "id": 1969,
            "street1": "190 Jefferson Dr.",
            "street2": "",
            "city": "Menlo Park",
            "region": "California",
            "postal_code": "94025",
            "lat": 37.4807649,
            "lon": -122.1704363,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "10",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 37.48135,
        "lon": -122.17006,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
        "is_mobile": true,
        "mobile_live_date": "2022-11-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Chilco",
        "_lat": 37.48135,
        "_lng": -122.17006,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.17006
    },
    {
        "id": 5790,
        "name": "CIC",
        "correlation_id": "102718",
        "billing_address": null,
        "start_date": "2023-06-30T15:35:36Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.9290528,
        "lon": -84.3468382,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0646f543-0824-4de7-89a6-2af2c217f0b4",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "CIC",
        "_lat": 33.9290528,
        "_lng": -84.3468382,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.3468382
    },
    {
        "id": 1823,
        "name": "Cincinnati",
        "correlation_id": "11560",
        "billing_address": {
            "id": 687,
            "street1": "5000 Brotherton Rd, Cincinnati. OH",
            "street2": "",
            "city": "Cincinnati",
            "region": "Ohio",
            "postal_code": "45209",
            "lat": 39.153127,
            "lon": -84.4072535,
            "country": "United States"
        },
        "start_date": "2019-07-23T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 39.1031182,
        "lon": -84.5120196,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
        "is_mobile": true,
        "mobile_live_date": "2021-08-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Cincinnati",
        "_lat": 39.1031182,
        "_lng": -84.5120196,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -84.5120196
    },
    {
        "id": 4225,
        "name": "City of Industry",
        "correlation_id": "102094",
        "billing_address": {
            "id": 2167,
            "street1": "18543 E. Gale Ave",
            "street2": "",
            "city": null,
            "region": "California",
            "postal_code": "91748",
            "lat": 33.995909,
            "lon": -117.8990496,
            "country": "United States"
        },
        "start_date": "2022-05-09T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 33.99451,
        "lon": -117.89802,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "48e90c2c-d6f9-4a38-bde4-a7d63f3c4b35",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "City of Industry",
        "_lat": 33.99451,
        "_lng": -117.89802,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -117.89802
    },
    {
        "id": 1808,
        "name": "CJ Harris",
        "correlation_id": "102703",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.3788645,
        "lon": -111.9035294,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "CJ Harris",
        "_lat": 33.3788645,
        "_lng": -111.9035294,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.9035294
    },
    {
        "id": 3851,
        "name": "Classic",
        "correlation_id": "102106",
        "billing_address": {
            "id": 3033,
            "street1": "1 Hacker Way.",
            "street2": "",
            "city": "Menlo Park",
            "region": "California",
            "postal_code": null,
            "lat": 37.484314,
            "lon": -122.1474096,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "11",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 37.48402,
        "lon": -122.15084,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
        "is_mobile": true,
        "mobile_live_date": "2022-11-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Classic",
        "_lat": 37.48402,
        "_lng": -122.15084,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.15084
    },
    {
        "id": 2256,
        "name": "Clermont, KY",
        "correlation_id": "101254",
        "billing_address": {
            "id": 491,
            "street1": "170 Clermont Rd",
            "street2": "",
            "city": "Shepardsville",
            "region": "Kentucky",
            "postal_code": "40165",
            "lat": 37.9801905,
            "lon": -85.6835851,
            "country": "United States"
        },
        "start_date": "2020-09-22T23:59:59Z",
        "total_sq_ft": "1300000",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 38.328732,
        "lon": -85.764771,
        "local_timezone": {
            "id": 2224,
            "code": "America/Louisville",
            "display_name": "America/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 90
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Clermont, KY",
        "_lat": 38.328732,
        "_lng": -85.764771,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -85.764771
    },
    {
        "id": 2016,
        "name": "Clinton",
        "correlation_id": "11591",
        "billing_address": {
            "id": 3761,
            "street1": "938 S Dupont Pl West",
            "street2": "",
            "city": "Terre Haute",
            "region": "Indiana",
            "postal_code": null,
            "lat": 39.4548384,
            "lon": -87.4719086,
            "country": "United States"
        },
        "start_date": "2017-12-31T23:59:59Z",
        "total_sq_ft": "8100000",
        "total_buildings": "29",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 273,
            "name": "Elanco (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11589",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
        "is_mobile": true,
        "mobile_live_date": "2020-09-08",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Elanco (CBRE)",
        "_label": "Elanco (CBRE)",
        "_name": "Clinton",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 918792,
                "create_date": "2020-12-07T22:03:11.720761Z",
                "update_date": "2020-12-07T22:03:11.720786Z",
                "organization_id": 273,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607378591.6016922_Elanco_t.png",
                "size": 6049,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5747,
        "name": "Coeur D’ Alene*",
        "correlation_id": "102589",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "310e1528-0491-4d86-a474-497c83bec3cd",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Coeur D’ Alene*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 5776,
        "name": "Columbia, MO*",
        "correlation_id": "102623",
        "billing_address": null,
        "start_date": "2023-06-05T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 38.71177,
        "lon": -93.21539,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "78ab5a19-75d1-4f21-a6bb-966bed3a0b2d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Columbia, MO*",
        "_lat": 38.71177,
        "_lng": -93.21539,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.21539
    },
    {
        "id": 6751,
        "name": "Commerce Park 2 15080",
        "correlation_id": "103420",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.31116,
        "lon": -83.18368,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Commerce Park 2 15080",
        "_lat": 42.31116,
        "_lng": -83.18368,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.18368
    },
    {
        "id": 6740,
        "name": "Commerce Park 2 15090",
        "correlation_id": "103412",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.31074,
        "lon": -83.18417,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Commerce Park 2 15090",
        "_lat": 42.31074,
        "_lng": -83.18417,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.18417
    },
    {
        "id": 5575,
        "name": "Concord, NC",
        "correlation_id": "102414",
        "billing_address": null,
        "start_date": "2023-01-31T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 72,
            "name": "Eli Lilly (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10042",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Eli Lilly (CBRE)",
        "_label": "Eli Lilly (CBRE)",
        "_name": "Concord, NC",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853030,
                "create_date": "2020-11-13T23:55:46.918511Z",
                "update_date": "2020-11-13T23:55:46.918539Z",
                "organization_id": 72,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311746.7267878_Artboard8_t.png",
                "size": 413839,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 2633,
        "name": "Concordville, PA",
        "correlation_id": "101579",
        "billing_address": {
            "id": 769,
            "street1": "1 State Farm Rd",
            "street2": "",
            "city": "Concordville",
            "region": "Pennsylvania",
            "postal_code": "19737",
            "lat": 39.8827697,
            "lon": -75.5458222,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 39.88296,
        "lon": -75.5461,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Concordville, PA",
        "_lat": 39.88296,
        "_lng": -75.5461,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -75.5461
    },
    {
        "id": 6076,
        "name": "Cover My Meds 2",
        "correlation_id": "103005",
        "billing_address": {
            "id": 4102,
            "street1": "911 St. John street",
            "street2": "",
            "city": "Columbus",
            "region": "Ohio",
            "postal_code": null,
            "lat": 39.9636303,
            "lon": -83.0261094,
            "country": "United States"
        },
        "start_date": "2024-01-31T23:45:54.420000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 39.9638,
        "lon": -83.02484,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
        "is_mobile": true,
        "mobile_live_date": "2024-01-31",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Cover My Meds 2",
        "_lat": 39.9638,
        "_lng": -83.02484,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.02484
    },
    {
        "id": 2471,
        "name": "Cover My Meds Columbus",
        "correlation_id": "101428",
        "billing_address": {
            "id": 314,
            "street1": "910 John St.",
            "street2": "",
            "city": "Columbus",
            "region": "Ohio",
            "postal_code": "43222",
            "lat": 39.9640812,
            "lon": -83.0255971,
            "country": "United States"
        },
        "start_date": "2021-06-28T23:59:59Z",
        "total_sq_ft": "1374000",
        "total_buildings": "8",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 39.964,
        "lon": -83.0255,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
        "is_mobile": true,
        "mobile_live_date": "2021-09-23",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Cover My Meds Columbus",
        "_lat": 39.964,
        "_lng": -83.0255,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.0255
    },
    {
        "id": 5859,
        "name": "DDE – Driving Dynamics East – Dearborn, MI",
        "correlation_id": "102790",
        "billing_address": {
            "id": 3983,
            "street1": "20500 Oakwood Blvd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2991455,
            "lon": -83.2304597,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:13:19Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2991455,
        "lon": -83.2304597,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "DDE – Driving Dynamics East – Dearborn, MI",
        "_lat": 42.2991455,
        "_lng": -83.2304597,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2304597
    },
    {
        "id": 5860,
        "name": "DDW – Driving Dynamics West – Dearborn, MI",
        "correlation_id": "102791",
        "billing_address": {
            "id": 3982,
            "street1": "20500 Oakwood Blvd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2991455,
            "lon": -83.2304597,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:10:36Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2991455,
        "lon": -83.2304597,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "DDW – Driving Dynamics West – Dearborn, MI",
        "_lat": 42.2991455,
        "_lng": -83.2304597,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2304597
    },
    {
        "id": 1674,
        "name": "Denver",
        "correlation_id": "11281",
        "billing_address": {
            "id": 429,
            "street1": "1701 Lincoln St.",
            "street2": "",
            "city": "Denver",
            "region": "Colorado",
            "postal_code": "80274",
            "lat": 39.7439066,
            "lon": -104.9863433,
            "country": "United States"
        },
        "start_date": "2021-05-12T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "14",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 39.7439704,
        "lon": -104.9878976,
        "local_timezone": {
            "id": 2183,
            "code": "America/Denver",
            "display_name": "America/Denver",
            "description": "",
            "child_group": null,
            "alt_sequence": 49
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
        "is_mobile": true,
        "mobile_live_date": "2021-05-12",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Denver",
        "_lat": 39.7439704,
        "_lng": -104.9878976,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -104.9878976
    },
    {
        "id": 5831,
        "name": "Denver Service Center",
        "correlation_id": "102586",
        "billing_address": {
            "id": 4095,
            "street1": "5700 DTC Parkway",
            "street2": "",
            "city": "Greenwood Village",
            "region": "Colorado",
            "postal_code": null,
            "lat": 39.612517,
            "lon": -104.888335,
            "country": "United States"
        },
        "start_date": "2024-01-23T23:26:31Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 39.612517,
        "lon": -104.888335,
        "local_timezone": {
            "id": 2183,
            "code": "America/Denver",
            "display_name": "America/Denver",
            "description": "",
            "child_group": null,
            "alt_sequence": 49
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "aa9ac4b5-0c72-4ca1-8e5a-aac497872ead",
        "is_mobile": true,
        "mobile_live_date": "2024-01-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Denver Service Center",
        "_lat": 39.612517,
        "_lng": -104.888335,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -104.888335
    },
    {
        "id": 1325,
        "name": "Des Moines",
        "correlation_id": "10334",
        "billing_address": {
            "id": 678,
            "street1": "800 Walnut Street",
            "street2": "",
            "city": "Des Moines",
            "region": "Iowa",
            "postal_code": "50309",
            "lat": 41.584977,
            "lon": -93.627585,
            "country": "United States"
        },
        "start_date": "2014-04-30T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "10",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.5854643,
        "lon": -93.6298999,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
        "is_mobile": true,
        "mobile_live_date": "2021-08-31",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Des Moines",
        "_lat": 41.5854643,
        "_lng": -93.6298999,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.6298999
    },
    {
        "id": 2120,
        "name": "Des Moines, IA",
        "correlation_id": "11728",
        "billing_address": {
            "id": 2997,
            "street1": "550 White Crane Rd.",
            "street2": "",
            "city": "Des Moines",
            "region": "Iowa",
            "postal_code": null,
            "lat": 41.5160509,
            "lon": -93.7088467,
            "country": "United States"
        },
        "start_date": "2020-05-14T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "24",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 41.619549,
        "lon": -93.598022,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
        "is_mobile": true,
        "mobile_live_date": "2022-12-13",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Des Moines, IA",
        "_lat": 41.619549,
        "_lng": -93.598022,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -93.598022
    },
    {
        "id": 2220,
        "name": "DSC 1 & 2",
        "correlation_id": "103417",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29371,
        "lon": -83.19775,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "DSC 1 & 2",
        "_lat": 42.29371,
        "_lng": -83.19775,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.19775
    },
    {
        "id": 5979,
        "name": "DSM05*",
        "correlation_id": "102874",
        "billing_address": null,
        "start_date": "2023-11-22T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 41.51469,
        "lon": -93.70955,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "aec9ad2f-3351-4612-a2d3-584bd2976987",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "DSM05*",
        "_lat": 41.51469,
        "_lng": -93.70955,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -93.70955
    },
    {
        "id": 2222,
        "name": "DTF – Drivability Test Facility – Allen Park, MI",
        "correlation_id": "101285",
        "billing_address": {
            "id": 210,
            "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": "48124",
            "lat": 42.2941466,
            "lon": -83.2271782,
            "country": "United States"
        },
        "start_date": "2020-11-08T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": 5847,
        "lat": 42.2575,
        "lon": -83.211,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
        "is_mobile": true,
        "mobile_live_date": "2021-03-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "1",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "DTF – Drivability Test Facility – Allen Park, MI",
        "_lat": 42.2575,
        "_lng": -83.211,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.211
    },
    {
        "id": 2651,
        "name": "Dulles, VA",
        "correlation_id": "101538",
        "billing_address": {
            "id": 780,
            "street1": "12012 Sunset Hills Rd",
            "street2": "",
            "city": "Reston",
            "region": "Virginia",
            "postal_code": "20190",
            "lat": 38.9560719,
            "lon": -77.3578354,
            "country": "United States"
        },
        "start_date": "2022-02-03T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 38.9560719,
        "lon": -77.3578354,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
        "is_mobile": true,
        "mobile_live_date": "2022-02-03",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Dulles, VA",
        "_lat": 38.9560719,
        "_lng": -77.3578354,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -77.3578354
    },
    {
        "id": 2641,
        "name": "Duluth, GA",
        "correlation_id": "101589",
        "billing_address": {
            "id": 3585,
            "street1": "11650 Johns Creek Pkwy",
            "street2": "",
            "city": "Duluth",
            "region": "Georgia",
            "postal_code": null,
            "lat": 34.0641474,
            "lon": -84.1631889,
            "country": "United States"
        },
        "start_date": "2021-10-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 34.06327,
        "lon": -84.16199,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d3c2aa73-b697-4188-911b-09211a1b2d21",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Duluth, GA",
        "_lat": 34.06327,
        "_lng": -84.16199,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.16199
    },
    {
        "id": 2642,
        "name": "DuPont",
        "correlation_id": "101592",
        "billing_address": {
            "id": 777,
            "street1": "1000 Wilmington Dr",
            "street2": "",
            "city": "DuPont",
            "region": "Washington",
            "postal_code": "98327",
            "lat": 47.0922308,
            "lon": -122.6352784,
            "country": "United States"
        },
        "start_date": "2021-10-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 47.0922308,
        "lon": -122.6352784,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7d1ce66a-541b-40b5-82f9-77a4eba21b48",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "DuPont",
        "_lat": 47.0922308,
        "_lng": -122.6352784,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.6352784
    },
    {
        "id": 2576,
        "name": "Durham",
        "correlation_id": "101454",
        "billing_address": {
            "id": 574,
            "street1": "2400 Ellis road",
            "street2": "",
            "city": "Durham",
            "region": "North Carolina",
            "postal_code": "27703",
            "lat": 35.9353484,
            "lon": -78.8545872,
            "country": "United States"
        },
        "start_date": "2021-06-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "7",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 470,
            "name": "IQVIA (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 573,
                    "street1": "2400 Ellis road",
                    "street2": "",
                    "city": "Durham",
                    "region": "North Carolina",
                    "postal_code": "27703",
                    "lat": 35.9353484,
                    "lon": -78.8545872,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "d7f7ea55-d5e5-4f58-9ffe-85e17bde2246"
        },
        "parent_id": null,
        "lat": 35.9353484,
        "lon": -78.8545872,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "IQVIA (CBRE)",
        "_label": "IQVIA (CBRE)",
        "_name": "Durham",
        "_lat": 35.9353484,
        "_lng": -78.8545872,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -78.8545872
    },
    {
        "id": 1487,
        "name": "Durham, NC",
        "correlation_id": "10072",
        "billing_address": {
            "id": 3789,
            "street1": "5325 Old Oxford Road",
            "street2": "",
            "city": "Durham",
            "region": "North Carolina",
            "postal_code": null,
            "lat": 36.1116519,
            "lon": -78.8444576,
            "country": "United States"
        },
        "start_date": "2010-11-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "14",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 36.1116685,
        "lon": -78.8450932,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
        "is_mobile": true,
        "mobile_live_date": "2023-10-03",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "Durham, NC",
        "_lat": 36.1116685,
        "_lng": -78.8450932,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -78.8450932
    },
    {
        "id": 3687,
        "name": "Durham, NC",
        "correlation_id": "102059",
        "billing_address": {
            "id": 1638,
            "street1": "59 Moore Drive",
            "street2": "",
            "city": "Durham",
            "region": "North Carolina",
            "postal_code": "27703",
            "lat": 35.9380499,
            "lon": -78.8652083,
            "country": "United States"
        },
        "start_date": "1969-12-31T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "15",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 72,
            "name": "Eli Lilly (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10042",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
        "is_mobile": true,
        "mobile_live_date": "2023-04-18",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Eli Lilly (CBRE)",
        "_label": "Eli Lilly (CBRE)",
        "_name": "Durham, NC",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853030,
                "create_date": "2020-11-13T23:55:46.918511Z",
                "update_date": "2020-11-13T23:55:46.918539Z",
                "organization_id": 72,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311746.7267878_Artboard8_t.png",
                "size": 413839,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5862,
        "name": "DYNO – Dynamometer – Dearborn, MI",
        "correlation_id": "102802",
        "billing_address": {
            "id": 3981,
            "street1": "1701 Village Rd, Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.296369,
            "lon": -83.2349588,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:08:22Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.296369,
        "lon": -83.2349588,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "DYNO – Dynamometer – Dearborn, MI",
        "_lat": 42.296369,
        "_lng": -83.2349588,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2349588
    },
    {
        "id": 1284,
        "name": "East Hanover",
        "correlation_id": "10261",
        "billing_address": {
            "id": 485,
            "street1": "1 Health Plaza",
            "street2": "",
            "city": "East Hanover",
            "region": "New Jersey",
            "postal_code": "07936",
            "lat": 40.8041041,
            "lon": -74.3862377,
            "country": "United States"
        },
        "start_date": "2021-06-04T23:59:59Z",
        "total_sq_ft": "70640289",
        "total_buildings": "2016",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 40.8059016,
        "lon": -74.3922846,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8fc775dc-3aaa-4ae0-981b-1065911126f3",
        "is_mobile": true,
        "mobile_live_date": "2021-03-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "East Hanover",
        "_lat": 40.8059016,
        "_lng": -74.3922846,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -74.3922846
    },
    {
        "id": 2903,
        "name": "Easton, PA",
        "correlation_id": "101885",
        "billing_address": {
            "id": 1129,
            "street1": "300 Morrison Ave",
            "street2": "",
            "city": "Easton",
            "region": "Pennsylvania",
            "postal_code": "18042",
            "lat": 40.7028473,
            "lon": -75.225481,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9827c6ef-43db-4196-af29-ced35f3fc1cd",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Easton, PA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 2088,
        "name": "East Town - MN",
        "correlation_id": "11694",
        "billing_address": {
            "id": 724,
            "street1": "600 South 4th Street",
            "street2": "",
            "city": "Minneapolis",
            "region": "Minnesota",
            "postal_code": "55415",
            "lat": 44.9763688,
            "lon": -93.2606165,
            "country": "United States"
        },
        "start_date": "2014-04-30T23:59:59Z",
        "total_sq_ft": "1027394",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.9763975,
        "lon": -93.2633265,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
        "is_mobile": true,
        "mobile_live_date": "2021-07-13",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "East Town - MN",
        "_lat": 44.9763975,
        "_lng": -93.2633265,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.2633265
    },
    {
        "id": 2608,
        "name": "Ellabell, GA",
        "correlation_id": "101549",
        "billing_address": {
            "id": 718,
            "street1": "1338 Interstate Centro",
            "street2": "",
            "city": "Ellabell",
            "region": "Georgia",
            "postal_code": "31308",
            "lat": 32.1865174,
            "lon": -81.4615965,
            "country": "United States"
        },
        "start_date": "2021-08-19T16:59:22Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 32.19877,
        "lon": -81.47159,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4bd33d9e-e410-4fde-8de1-492858a2075d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Ellabell, GA",
        "_lat": 32.19877,
        "_lng": -81.47159,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -81.47159
    },
    {
        "id": 5861,
        "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
        "correlation_id": "102805",
        "billing_address": {
            "id": 3980,
            "street1": "17000 Southfield Fwy, Allen Park, MI 48101",
            "street2": "",
            "city": "Allen Park",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2658233,
            "lon": -83.2187652,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:06:03Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2658233,
        "lon": -83.2187652,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
        "_lat": 42.2658233,
        "_lng": -83.2187652,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2187652
    },
    {
        "id": 2005,
        "name": "Emeryville, CA",
        "correlation_id": "101054",
        "billing_address": {
            "id": 484,
            "street1": "5959 Horton St.",
            "street2": "",
            "city": "Emeryville",
            "region": "California",
            "postal_code": "94608",
            "lat": 37.8411201,
            "lon": -122.2916231,
            "country": "United States"
        },
        "start_date": "2020-01-31T23:59:59Z",
        "total_sq_ft": "61665",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 37.8362998,
        "lon": -122.2905721,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3383eec2-d76c-4064-8722-ae535cf94417",
        "is_mobile": true,
        "mobile_live_date": "2021-04-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "Emeryville, CA",
        "_lat": 37.8362998,
        "_lng": -122.2905721,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.2905721
    },
    {
        "id": 5863,
        "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
        "correlation_id": "102810",
        "billing_address": {
            "id": 3979,
            "street1": "20800 Oakwood",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3007649,
            "lon": -83.232279,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:04:23Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3007649,
        "lon": -83.232279,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "21922489-dd81-4295-be37-291ba4839fae",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "EVB – Experimental Vehicle Building – Dearborn, MI",
        "_lat": 42.3007649,
        "_lng": -83.232279,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.232279
    },
    {
        "id": 4124,
        "name": "Exton",
        "correlation_id": "102089",
        "billing_address": {
            "id": 2099,
            "street1": "730 Stockton Dr.",
            "street2": "",
            "city": "Exton",
            "region": "Pennsylvania",
            "postal_code": "19341",
            "lat": 40.0698213,
            "lon": -75.6900788,
            "country": "United States"
        },
        "start_date": "2022-07-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 40.07007,
        "lon": -75.687828,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f7086bec-946e-4b69-9337-bc15669afd06",
        "is_mobile": true,
        "mobile_live_date": "2023-05-16",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Exton",
        "_lat": 40.07007,
        "_lng": -75.687828,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.687828
    },
    {
        "id": 4819,
        "name": "Fairdale",
        "correlation_id": "102156",
        "billing_address": null,
        "start_date": "2022-12-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 38.11951,
        "lon": -85.74424,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e5fce2e5-69cc-4a8f-b03d-6dd1b0794590",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Fairdale",
        "_lat": 38.11951,
        "_lng": -85.74424,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -85.74424
    },
    {
        "id": 6746,
        "name": "Fairlane Business Park 3",
        "correlation_id": "103413",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29506,
        "lon": -83.19604,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Fairlane Business Park 3",
        "_lat": 42.29506,
        "_lng": -83.19604,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.19604
    },
    {
        "id": 2221,
        "name": "Fairlane Business Park 5",
        "correlation_id": "103409",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29458,
        "lon": -83.20061,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Fairlane Business Park 5",
        "_lat": 42.29458,
        "_lng": -83.20061,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.20061
    },
    {
        "id": 2217,
        "name": "Fairlane North",
        "correlation_id": "103411",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.33287,
        "lon": -83.21037,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Fairlane North",
        "_lat": 42.33287,
        "_lng": -83.21037,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.21037
    },
    {
        "id": 6744,
        "name": "Fairlane Plaza",
        "correlation_id": "103418",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.33287,
        "lon": -83.21037,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Fairlane Plaza",
        "_lat": 42.33287,
        "_lng": -83.21037,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.21037
    },
    {
        "id": 5514,
        "name": "Fashion Valley, CA*",
        "correlation_id": "102332",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 32.7673614,
        "lon": -117.1715558,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "31b858b2-bf76-4fd3-a4bc-3225d28bb906",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Fashion Valley, CA*",
        "_lat": 32.7673614,
        "_lng": -117.1715558,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -117.1715558
    },
    {
        "id": 5864,
        "name": "FEL – Ford Engineering Lab – Dearborn, MI",
        "correlation_id": "102785",
        "billing_address": {
            "id": 3978,
            "street1": "21500 Oakwood Blvd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3042264,
            "lon": -83.2368731,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:02:32.782000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3042264,
        "lon": -83.2368731,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "FEL – Ford Engineering Lab – Dearborn, MI",
        "_lat": 42.3042264,
        "_lng": -83.2368731,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2368731
    },
    {
        "id": 6698,
        "name": "Fluor - Lebanon*",
        "correlation_id": "103374",
        "billing_address": null,
        "start_date": "2024-01-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 72,
            "name": "Eli Lilly (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10042",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
        },
        "parent_id": null,
        "lat": 40.10464,
        "lon": -86.5013,
        "local_timezone": {
            "id": 2207,
            "code": "America/Indiana/Petersburg",
            "display_name": "America/Indiana/Petersburg",
            "description": "",
            "child_group": null,
            "alt_sequence": 73
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6c63ba96-6852-4283-80f0-1a75f0cfd8f7",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Eli Lilly (CBRE)",
        "_label": "Eli Lilly (CBRE)",
        "_name": "Fluor - Lebanon*",
        "_lat": 40.10464,
        "_lng": -86.5013,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853030,
                "create_date": "2020-11-13T23:55:46.918511Z",
                "update_date": "2020-11-13T23:55:46.918539Z",
                "organization_id": 72,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311746.7267878_Artboard8_t.png",
                "size": 413839,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -86.5013
    },
    {
        "id": 5794,
        "name": "Fulton County Airport Hangar",
        "correlation_id": "102721",
        "billing_address": null,
        "start_date": "2023-06-30T22:32:05Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.7668267,
        "lon": -84.5263004,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8baabbd9-e6e5-413f-abbb-be0d5d268562",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "Fulton County Airport Hangar",
        "_lat": 33.7668267,
        "_lng": -84.5263004,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.5263004
    },
    {
        "id": 2215,
        "name": "FXC – Ford Experience Center - Dearborn, MI",
        "correlation_id": "101286",
        "billing_address": {
            "id": 206,
            "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": "48124",
            "lat": 42.2941466,
            "lon": -83.2271782,
            "country": "United States"
        },
        "start_date": "2020-11-08T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": 5847,
        "lat": 42.322262,
        "lon": -83.176315,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
        "is_mobile": true,
        "mobile_live_date": "2021-03-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "1",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "FXC – Ford Experience Center - Dearborn, MI",
        "_lat": 42.322262,
        "_lng": -83.176315,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.176315
    },
    {
        "id": 2611,
        "name": "Gahanna",
        "correlation_id": "102231",
        "billing_address": {
            "id": 721,
            "street1": "915 Taylor Rd",
            "street2": "",
            "city": "Columbus",
            "region": "Ohio",
            "postal_code": "43230",
            "lat": 40.0011604,
            "lon": -82.8504071,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 40.00266,
        "lon": -82.85011,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "430bc9e7-1d5f-44b5-9ad7-9b7d10a354f9",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Gahanna",
        "_lat": 40.00266,
        "_lng": -82.85011,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -82.85011
    },
    {
        "id": 2467,
        "name": "Gallatin, TN",
        "correlation_id": "101427",
        "billing_address": {
            "id": 305,
            "street1": "179 Hancock St Ste 304",
            "street2": "",
            "city": "Gallatin",
            "region": "Tennessee",
            "postal_code": "37066",
            "lat": 36.3796429,
            "lon": -86.4557318,
            "country": "United States"
        },
        "start_date": "2019-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 36.379,
        "lon": -86.455,
        "local_timezone": {
            "id": 2224,
            "code": "America/Louisville",
            "display_name": "America/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 90
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ae7d3ed1-544e-4b11-b40f-ba2a6e458a13",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Gallatin, TN",
        "_lat": 36.379,
        "_lng": -86.455,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -86.455
    },
    {
        "id": 3997,
        "name": "Gateway",
        "correlation_id": "102104",
        "billing_address": {
            "id": 1972,
            "street1": "100 Independence Dr.",
            "street2": "",
            "city": "Menlo Park",
            "region": "California",
            "postal_code": "94025",
            "lat": 37.4834951,
            "lon": -122.1787815,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 37.48358,
        "lon": -122.17897,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "517ee32c-80d0-4e44-8ce1-f0e0432c248e",
        "is_mobile": true,
        "mobile_live_date": "2022-11-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Gateway",
        "_lat": 37.48358,
        "_lng": -122.17897,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.17897
    },
    {
        "id": 5674,
        "name": "Grand Rapids, MI",
        "correlation_id": "102545",
        "billing_address": {
            "id": 3580,
            "street1": "2100 Burlingame Ave. SW",
            "street2": "",
            "city": "Wyoming",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.9226603,
            "lon": -85.7031869,
            "country": "United States"
        },
        "start_date": "2023-05-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "13",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 137,
            "name": "GM",
            "type": "CUSTOMER",
            "correlation_id": "10828",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
        },
        "parent_id": null,
        "lat": 42.92355,
        "lon": -85.70565,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6ef15f02-8bc5-41d2-84d1-e1e7bf1d0537",
        "is_mobile": true,
        "mobile_live_date": "2023-04-16",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "GM",
        "_label": "GM",
        "_name": "Grand Rapids, MI",
        "_lat": 42.92355,
        "_lng": -85.70565,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853049,
                "create_date": "2020-11-14T00:00:06.049828Z",
                "update_date": "2020-11-14T00:00:06.049855Z",
                "organization_id": 137,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
                "size": 1080345,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -85.70565
    },
    {
        "id": 3662,
        "name": "Greeley, CO*",
        "correlation_id": "101588",
        "billing_address": {
            "id": 3584,
            "street1": "1555 Promontory Cir",
            "street2": "",
            "city": "Greeley",
            "region": "Colorado",
            "postal_code": null,
            "lat": 40.4145722,
            "lon": -104.8573251,
            "country": "United States"
        },
        "start_date": "2023-05-02T19:26:39.058000Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 40.41488,
        "lon": -104.85719,
        "local_timezone": {
            "id": 2229,
            "code": "America/Martinique",
            "display_name": "America/Martinique",
            "description": "",
            "child_group": null,
            "alt_sequence": 95
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "645adbe3-eab2-45e9-8781-9f6cb558cd66",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Greeley, CO*",
        "_lat": 40.41488,
        "_lng": -104.85719,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -104.85719
    },
    {
        "id": 2017,
        "name": "Greenfield, IN",
        "correlation_id": "11590",
        "billing_address": {
            "id": 432,
            "street1": "2500 Innovation Way",
            "street2": "",
            "city": "Greenfield",
            "region": "Indiana",
            "postal_code": "46140",
            "lat": 39.8218481,
            "lon": -85.7748672,
            "country": "United States"
        },
        "start_date": "2012-09-30T23:59:59Z",
        "total_sq_ft": "113600",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 273,
            "name": "Elanco (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11589",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
        },
        "parent_id": null,
        "lat": 39.8223751,
        "lon": -85.7751072,
        "local_timezone": {
            "id": 2204,
            "code": "America/Indiana/Indianapolis",
            "display_name": "America/Indiana/Indianapolis",
            "description": "",
            "child_group": null,
            "alt_sequence": 70
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
        "is_mobile": true,
        "mobile_live_date": "2020-08-20",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Elanco (CBRE)",
        "_label": "Elanco (CBRE)",
        "_name": "Greenfield, IN",
        "_lat": 39.8223751,
        "_lng": -85.7751072,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 918792,
                "create_date": "2020-12-07T22:03:11.720761Z",
                "update_date": "2020-12-07T22:03:11.720786Z",
                "organization_id": 273,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607378591.6016922_Elanco_t.png",
                "size": 6049,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -85.7751072
    },
    {
        "id": 2616,
        "name": "Groveport",
        "correlation_id": "102232",
        "billing_address": {
            "id": 731,
            "street1": "6500 Adelaide ct",
            "street2": "",
            "city": "Groveport",
            "region": "Ohio",
            "postal_code": "43125",
            "lat": 39.8333433,
            "lon": -82.9357025,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 39.837401,
        "lon": -82.936481,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3bb6dd38-6fa1-4f73-8565-8051e79ab1be",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Groveport",
        "_lat": 39.837401,
        "_lng": -82.936481,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -82.936481
    },
    {
        "id": 5865,
        "name": "GTL – Gas Turbine Labs – Dearborn, MI",
        "correlation_id": "102799",
        "billing_address": {
            "id": 3977,
            "street1": "1751 Village Rd, Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3002124,
            "lon": -83.233564,
            "country": "United States"
        },
        "start_date": "2023-12-27T23:01:20Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3002124,
        "lon": -83.233564,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "GTL – Gas Turbine Labs – Dearborn, MI",
        "_lat": 42.3002124,
        "_lng": -83.233564,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.233564
    },
    {
        "id": 2163,
        "name": "Harleysville, PA",
        "correlation_id": "101244",
        "billing_address": {
            "id": 1277,
            "street1": "2060 Detwiler Rd., Ste. 111",
            "street2": "",
            "city": "Harleysville",
            "region": "Pennsylvania",
            "postal_code": "19438",
            "lat": 40.2547959,
            "lon": -75.3420225,
            "country": "United States"
        },
        "start_date": "2020-09-07T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 311,
            "name": "Siemens (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "7e18d041-a341-42b2-a49a-9c10ba6df5aa"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9e1ba37b-3af4-446a-9c9b-ac7e4baa4825",
        "is_mobile": true,
        "mobile_live_date": "2022-02-24",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Siemens (CBRE)",
        "_label": "Siemens (CBRE)",
        "_name": "Harleysville, PA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933087,
                "create_date": "2020-12-10T21:55:19.109328Z",
                "update_date": "2020-12-10T21:55:19.109352Z",
                "organization_id": 311,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607637318.9761856_Siemens_t.png",
                "size": 1646,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5572,
        "name": "Headquarters HMHQ",
        "correlation_id": "102359",
        "billing_address": {
            "id": 3780,
            "street1": "7001 Westown Parkway.",
            "street2": "",
            "city": "West Des Moines",
            "region": "Iowa",
            "postal_code": null,
            "lat": 41.5990268,
            "lon": -93.8028149,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.59696,
        "lon": -93.8045,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "76aea59d-6532-4759-8887-190df768c9d5",
        "is_mobile": true,
        "mobile_live_date": "2023-09-13",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Headquarters HMHQ",
        "_lat": 41.59696,
        "_lng": -93.8045,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.8045
    },
    {
        "id": 2683,
        "name": "Hillsboro, OR*",
        "correlation_id": "101567",
        "billing_address": {
            "id": 810,
            "street1": "9820 NW Gibson Rd",
            "street2": "",
            "city": "Hillsboro",
            "region": "Oregon",
            "postal_code": "97006",
            "lat": 45.5167437,
            "lon": -122.9005159,
            "country": "United States"
        },
        "start_date": "2021-12-03T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2207,
            "code": "America/Indiana/Petersburg",
            "display_name": "America/Indiana/Petersburg",
            "description": "",
            "child_group": null,
            "alt_sequence": 73
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ea0e9860-4cbb-4df6-aa6c-dc768045b890",
        "is_mobile": true,
        "mobile_live_date": "2022-04-07",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Hillsboro, OR*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 5981,
        "name": "IAD11*",
        "correlation_id": "102876",
        "billing_address": null,
        "start_date": "2023-11-22T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 38.94548,
        "lon": -77.55901,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "07572032-5a22-4b19-970f-4adad56c25e0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "IAD11*",
        "_lat": 38.94548,
        "_lng": -77.55901,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -77.55901
    },
    {
        "id": 1324,
        "name": "Illinois",
        "correlation_id": "10333",
        "billing_address": {
            "id": 696,
            "street1": "4800 Wabash Ave,",
            "street2": "",
            "city": "Springfield",
            "region": "Illinois",
            "postal_code": "62711",
            "lat": 39.755076,
            "lon": -89.7404571,
            "country": "United States"
        },
        "start_date": "2014-03-06T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.7728659,
        "lon": -88.1528542,
        "local_timezone": {
            "id": 2204,
            "code": "America/Indiana/Indianapolis",
            "display_name": "America/Indiana/Indianapolis",
            "description": "",
            "child_group": null,
            "alt_sequence": 70
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
        "is_mobile": true,
        "mobile_live_date": "2021-08-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Illinois",
        "_lat": 41.7728659,
        "_lng": -88.1528542,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -88.1528542
    },
    {
        "id": 1469,
        "name": "Indianapolis, IN",
        "correlation_id": "10045",
        "billing_address": {
            "id": 3581,
            "street1": "639 S. Delaware St-Dock 57",
            "street2": "",
            "city": "Indianapolis",
            "region": "Indiana",
            "postal_code": null,
            "lat": 39.758263,
            "lon": -86.1540166,
            "country": "United States"
        },
        "start_date": "2020-10-09T23:59:59Z",
        "total_sq_ft": "1864967",
        "total_buildings": "393",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 72,
            "name": "Eli Lilly (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10042",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
        },
        "parent_id": 2655,
        "lat": 39.7562681,
        "lon": -86.1546757,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
        "is_mobile": true,
        "mobile_live_date": "2021-05-18",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Eli Lilly (CBRE)",
        "_label": "Eli Lilly (CBRE)",
        "_name": "Indianapolis, IN",
        "_lat": 39.7562681,
        "_lng": -86.1546757,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853030,
                "create_date": "2020-11-13T23:55:46.918511Z",
                "update_date": "2020-11-13T23:55:46.918539Z",
                "organization_id": 72,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311746.7267878_Artboard8_t.png",
                "size": 413839,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -86.1546757
    },
    {
        "id": 1880,
        "name": "Indy",
        "correlation_id": "101071",
        "billing_address": {
            "id": 435,
            "street1": "1301 White Rivery Parkway",
            "street2": "",
            "city": "Indianapolis",
            "region": "Indiana",
            "postal_code": "46221",
            "lat": 39.7496213,
            "lon": -86.1712263,
            "country": "United States"
        },
        "start_date": "2020-01-01T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 273,
            "name": "Elanco (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11589",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
        },
        "parent_id": null,
        "lat": 39.8014813,
        "lon": -86.195104,
        "local_timezone": {
            "id": 2212,
            "code": "America/Indianapolis",
            "display_name": "America/Indianapolis",
            "description": "",
            "child_group": null,
            "alt_sequence": 78
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f6d700cf-a4c8-44d7-8d83-1ce085a21b8d",
        "is_mobile": true,
        "mobile_live_date": "2020-09-22",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Elanco (CBRE)",
        "_label": "Elanco (CBRE)",
        "_name": "Indy",
        "_lat": 39.8014813,
        "_lng": -86.195104,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 918792,
                "create_date": "2020-12-07T22:03:11.720761Z",
                "update_date": "2020-12-07T22:03:11.720786Z",
                "organization_id": 273,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607378591.6016922_Elanco_t.png",
                "size": 6049,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -86.195104
    },
    {
        "id": 2566,
        "name": "Interchange",
        "correlation_id": "101510",
        "billing_address": {
            "id": 552,
            "street1": "5150 Interchange Way",
            "street2": "",
            "city": "Louisville",
            "region": "Kentucky",
            "postal_code": "40229",
            "lat": 38.0952973,
            "lon": -85.6804033,
            "country": "United States"
        },
        "start_date": "2021-06-01T12:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 38.09494,
        "lon": -85.67985,
        "local_timezone": {
            "id": 2218,
            "code": "America/Kentucky/Louisville",
            "display_name": "America/Kentucky/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 84
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "87cfe09e-ff48-43e9-98b4-c52f9fb9ffbf",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Interchange",
        "_lat": 38.09494,
        "_lng": -85.67985,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -85.67985
    },
    {
        "id": 5565,
        "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
        "correlation_id": "102400",
        "billing_address": {
            "id": 3742,
            "street1": "38100 Ecorse Rd.",
            "street2": "",
            "city": "Romulus",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2535573,
            "lon": -83.4124863,
            "country": "United States"
        },
        "start_date": "2023-01-31T18:17:26Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.25812,
        "lon": -83.4135,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
        "is_mobile": true,
        "mobile_live_date": "2023-07-05",
        "total_employees": 0,
        "chatbot_sms_phone_number": "7347896550",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "ION – Ford Battery Center of Excellence – Romulus, MI",
        "_lat": 42.25812,
        "_lng": -83.4135,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.4135
    },
    {
        "id": 4227,
        "name": "Irvine",
        "correlation_id": "101853",
        "billing_address": {
            "id": 2169,
            "street1": "2 Witherspoon Loop",
            "street2": "",
            "city": null,
            "region": "California",
            "postal_code": "92604",
            "lat": 33.6731881,
            "lon": -117.791666,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1648,
            "name": "TGS",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cecd50a4-ad75-4178-b1af-47fc306e65bc"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "TGS",
        "_label": "TGS",
        "_name": "Irvine",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 2069,
        "name": "Irving, TX",
        "correlation_id": "11596",
        "billing_address": {
            "id": 521,
            "street1": "6555 State hwy",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75039",
            "lat": 32.8916766,
            "lon": -96.9477532,
            "country": "United States"
        },
        "start_date": "2019-10-06T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 32.89502,
        "lon": -96.97407,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
        "is_mobile": true,
        "mobile_live_date": "2021-06-02",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Irving, TX",
        "_lat": 32.89502,
        "_lng": -96.97407,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -96.97407
    },
    {
        "id": 2618,
        "name": "Irving, TX",
        "correlation_id": "101556",
        "billing_address": {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
        },
        "start_date": "2021-10-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 32.91871,
        "lon": -96.99089,
        "local_timezone": {
            "id": 2247,
            "code": "America/North_Dakota/Center",
            "display_name": "America/North_Dakota/Center",
            "description": "",
            "child_group": null,
            "alt_sequence": 113
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
        "is_mobile": true,
        "mobile_live_date": "2022-11-14",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Irving, TX",
        "_lat": 32.91871,
        "_lng": -96.99089,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -96.99089
    },
    {
        "id": 6750,
        "name": "IT Bic",
        "correlation_id": "103421",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29249,
        "lon": -83.19583,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "IT Bic",
        "_lat": 42.29249,
        "_lng": -83.19583,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.19583
    },
    {
        "id": 6599,
        "name": "ITHQ-C",
        "correlation_id": "103370",
        "billing_address": null,
        "start_date": "2023-12-19T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.29249,
        "lon": -83.19583,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "ITHQ-C",
        "_lat": 42.29249,
        "_lng": -83.19583,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.19583
    },
    {
        "id": 1968,
        "name": "Jacksonville",
        "correlation_id": "11582",
        "billing_address": null,
        "start_date": "2019-08-31T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 30.24834,
        "lon": -81.52064,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Jacksonville",
        "_lat": 30.24834,
        "_lng": -81.52064,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -81.52064
    },
    {
        "id": 2664,
        "name": "Jacksonville FL",
        "correlation_id": "101553",
        "billing_address": {
            "id": 795,
            "street1": "8001 Baymeadows Way",
            "street2": "",
            "city": "Jacksonville",
            "region": "Florida",
            "postal_code": "32256",
            "lat": 30.2271286,
            "lon": -81.5770369,
            "country": "United States"
        },
        "start_date": "2022-01-21T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 30.22642,
        "lon": -81.57705,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
        "is_mobile": true,
        "mobile_live_date": "2022-01-20",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Jacksonville FL",
        "_lat": 30.22642,
        "_lng": -81.57705,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -81.57705
    },
    {
        "id": 2635,
        "name": "Jollyville (Austin)",
        "correlation_id": "101581",
        "billing_address": {
            "id": 770,
            "street1": "8900 Amberglen Blvd",
            "street2": "",
            "city": "Austin",
            "region": "Texas",
            "postal_code": "78729",
            "lat": 30.472599,
            "lon": -97.7669678,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 30.47264,
        "lon": -97.76696,
        "local_timezone": {
            "id": 2191,
            "code": "America/Fort_Wayne",
            "display_name": "America/Fort_Wayne",
            "description": "",
            "child_group": null,
            "alt_sequence": 57
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Jollyville (Austin)",
        "_lat": 30.47264,
        "_lng": -97.76696,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -97.76696
    },
    {
        "id": 2445,
        "name": "Jupiter, FL",
        "correlation_id": "101371",
        "billing_address": {
            "id": 274,
            "street1": "17900 Bee Line HWY",
            "street2": "",
            "city": "Jupitor",
            "region": "Florida",
            "postal_code": "33478",
            "lat": 26.9047849,
            "lon": -80.3040239,
            "country": "United States"
        },
        "start_date": "2021-03-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "14",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 438,
            "name": "Pratt & Whitney (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "101371",
            "addresses": [
                {
                    "id": 273,
                    "street1": "17900 Bee Line HWY",
                    "street2": "",
                    "city": "Jupitor",
                    "region": "Florida",
                    "postal_code": "33478",
                    "lat": 26.9047849,
                    "lon": -80.3040239,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "250a2c64-7e26-4b6d-8f36-167c56bcee3d"
        },
        "parent_id": null,
        "lat": 26.9047849,
        "lon": -80.3040239,
        "local_timezone": {
            "id": 2224,
            "code": "America/Louisville",
            "display_name": "America/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 90
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
        "is_mobile": true,
        "mobile_live_date": "2021-07-06",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Pratt & Whitney (CBRE)",
        "_label": "Pratt & Whitney (CBRE)",
        "_name": "Jupiter, FL",
        "_lat": 26.9047849,
        "_lng": -80.3040239,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -80.3040239
    },
    {
        "id": 2634,
        "name": "Kalamazoo, MI",
        "correlation_id": "101578",
        "billing_address": {
            "id": 2931,
            "street1": "5564 Portage Rd.",
            "street2": "",
            "city": "Portage",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2364493,
            "lon": -85.5636222,
            "country": "United States"
        },
        "start_date": "2021-10-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 42.23648,
        "lon": -85.56364,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "791209fc-57ee-4271-bd07-90aa1e8978d8",
        "is_mobile": true,
        "mobile_live_date": "2022-12-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Kalamazoo, MI",
        "_lat": 42.23648,
        "_lng": -85.56364,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -85.56364
    },
    {
        "id": 2590,
        "name": "Laguna South, WA",
        "correlation_id": "101532",
        "billing_address": {
            "id": 650,
            "street1": "14980 NE 31st St",
            "street2": "",
            "city": "Redmond",
            "region": "Washington",
            "postal_code": "98052",
            "lat": 47.638752,
            "lon": -122.1408995,
            "country": "United States"
        },
        "start_date": "2021-08-31T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "abb421e8-0707-42f0-b247-657048924721",
        "is_mobile": true,
        "mobile_live_date": "2022-05-10",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Laguna South, WA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 2003,
        "name": "La Jolla, CA",
        "correlation_id": "11650",
        "billing_address": {
            "id": 481,
            "street1": "10675 John Jay Hopkins Dr.",
            "street2": "",
            "city": "San Diego",
            "region": "California",
            "postal_code": "92121",
            "lat": 32.8966561,
            "lon": -117.2386756,
            "country": "United States"
        },
        "start_date": "2019-12-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "7",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 32.8961194,
        "lon": -117.2395112,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "01acf3a0-42d1-427a-902d-0086a8c92120",
        "is_mobile": true,
        "mobile_live_date": "2021-03-15",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "La Jolla, CA",
        "_lat": 32.8961194,
        "_lng": -117.2395112,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -117.2395112
    },
    {
        "id": 2598,
        "name": "Lansdale",
        "correlation_id": "101541",
        "billing_address": {
            "id": 775,
            "street1": "1180 Church Rd",
            "street2": "",
            "city": "Lansdale",
            "region": "Pennsylvania",
            "postal_code": "19446",
            "lat": 40.2291765,
            "lon": -75.2834557,
            "country": "United States"
        },
        "start_date": "2021-10-15T23:59:59Z",
        "total_sq_ft": "225000",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 477,
            "name": "Organon (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "08a94e30-365e-43b4-b5b2-acb91ade287c"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e259507c-7bdb-40b9-8f5a-14706dd3a918",
        "is_mobile": true,
        "mobile_live_date": "2021-12-08",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Organon (CBRE)",
        "_label": "Organon (CBRE)",
        "_name": "Lansdale",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 5531,
        "name": "Las Vegas Operations Center* (JLL)",
        "correlation_id": "102348",
        "billing_address": null,
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 36.1437844,
        "lon": -115.0976588,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "59a1084c-5eb2-48ef-bd4b-151f59a727df",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Las Vegas Operations Center* (JLL)",
        "_lat": 36.1437844,
        "_lng": -115.0976588,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -115.0976588
    },
    {
        "id": 5879,
        "name": "Lexington Columbia Construction*",
        "correlation_id": "102767",
        "billing_address": null,
        "start_date": "2023-09-25T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 42.42739,
        "lon": -71.25175,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b85cdcfb-d2fc-48d2-87c2-361b88590586",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Lexington Columbia Construction*",
        "_lat": 42.42739,
        "_lng": -71.25175,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.25175
    },
    {
        "id": 3398,
        "name": "Lexington, MA",
        "correlation_id": "101955",
        "billing_address": {
            "id": 2660,
            "street1": "200 Shire Way",
            "street2": "",
            "city": "Lexington",
            "region": "Massachusetts",
            "postal_code": "02421",
            "lat": 42.426388,
            "lon": -71.2516907,
            "country": "United States"
        },
        "start_date": "2022-08-31T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "17",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 180,
            "name": "Takeda (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11217",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
        },
        "parent_id": null,
        "lat": 42.426528,
        "lon": -71.25170203,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
        "is_mobile": true,
        "mobile_live_date": "2023-01-17",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Takeda (CBRE)",
        "_label": "Takeda (CBRE)",
        "_name": "Lexington, MA",
        "_lat": 42.426528,
        "_lng": -71.25170203,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859832,
                "create_date": "2020-11-17T14:35:11.969745Z",
                "update_date": "2020-11-17T14:35:11.969770Z",
                "organization_id": 180,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
                "size": 5626,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -71.25170203
    },
    {
        "id": 2643,
        "name": "Lincoln South",
        "correlation_id": "101590",
        "billing_address": {
            "id": 778,
            "street1": "500 S. 84th st",
            "street2": "",
            "city": "Lincoln",
            "region": "Nebraska",
            "postal_code": "68510",
            "lat": 40.8092744,
            "lon": -96.6037851,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 40.80939,
        "lon": -96.60344,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Lincoln South",
        "_lat": 40.80939,
        "_lng": -96.60344,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -96.60344
    },
    {
        "id": 2148,
        "name": "Lincoln Square 2",
        "correlation_id": "101307",
        "billing_address": {
            "id": 81,
            "street1": "700 Bellevue Way NE  Floor 22",
            "street2": "",
            "city": "Bellevue",
            "region": "Washington",
            "postal_code": "98004",
            "lat": 47.6164098,
            "lon": -122.2012735,
            "country": "United States"
        },
        "start_date": "2017-12-06T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 47.6062095,
        "lon": -122.3320708,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0158ddd5-982a-4899-adcf-d8bf368c44ee",
        "is_mobile": true,
        "mobile_live_date": "2022-05-10",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Lincoln Square 2",
        "_lat": 47.6062095,
        "_lng": -122.3320708,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.3320708
    },
    {
        "id": 5534,
        "name": "Lodi Vineyard*",
        "correlation_id": "102352",
        "billing_address": {
            "id": 3053,
            "street1": "702 S Ham Ln",
            "street2": "",
            "city": "Lodi Vineyard",
            "region": "California",
            "postal_code": null,
            "lat": 38.1260401,
            "lon": -121.2880258,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 38.1260401,
        "lon": -121.2880258,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4ef63fbd-7124-4bfb-81e5-0eb8c16bcabb",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Lodi Vineyard*",
        "_lat": 38.1260401,
        "_lng": -121.2880258,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -121.2880258
    },
    {
        "id": 2276,
        "name": "Los Angeles, CA*",
        "correlation_id": "101299",
        "billing_address": {
            "id": 680,
            "street1": "2417 Beverly Blvd",
            "street2": "",
            "city": "Los Angelas",
            "region": "California",
            "postal_code": "90057",
            "lat": 34.0687494,
            "lon": -118.2745679,
            "country": "United States"
        },
        "start_date": "2020-12-06T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 34.06874,
        "lon": -118.27456,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Suspended",
        "uuid": "2fc15fc4-8f2e-43a4-9efe-0b77aa566db7",
        "is_mobile": true,
        "mobile_live_date": "2021-09-02",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Los Angeles, CA*",
        "_lat": 34.06874,
        "_lng": -118.27456,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -118.27456
    },
    {
        "id": 5656,
        "name": "Los Angeles Cash Vault",
        "correlation_id": "102407",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 34.0534675,
        "lon": -118.2530973,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "10297d83-39ff-44d2-8463-246944bce406",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Los Angeles Cash Vault",
        "_lat": 34.0534675,
        "_lng": -118.2530973,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -118.2530973
    },
    {
        "id": 6684,
        "name": "LTP – Livonia Transmission Plant – Wayne, MI",
        "correlation_id": "103373",
        "billing_address": null,
        "start_date": "2024-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "6",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.37114,
        "lon": -83.39166,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "da632665-87df-4e1a-8ccf-6819d4038f95",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "LTP – Livonia Transmission Plant – Wayne, MI",
        "_lat": 42.37114,
        "_lng": -83.39166,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.39166
    },
    {
        "id": 1956,
        "name": "Madison, NJ",
        "correlation_id": "11432",
        "billing_address": {
            "id": 313,
            "street1": "5 Giralda Farms, Dodge Drive",
            "street2": "",
            "city": "Madison",
            "region": "New Jersey",
            "postal_code": "07940",
            "lat": 40.7636689,
            "lon": -74.4375951,
            "country": "United States"
        },
        "start_date": "2019-01-14T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "9",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 40.7636689,
        "lon": -74.4375951,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
        "is_mobile": true,
        "mobile_live_date": "2021-12-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Madison, NJ",
        "_lat": 40.7636689,
        "_lng": -74.4375951,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -74.4375951
    },
    {
        "id": 2624,
        "name": "Malta, NY",
        "correlation_id": "101563",
        "billing_address": null,
        "start_date": "2021-09-30T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 43.0096102,
        "lon": -73.870832,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "eb1902d2-9a86-4850-bb32-1404cefdba7d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Malta, NY",
        "_lat": 43.0096102,
        "_lng": -73.870832,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -73.870832
    },
    {
        "id": 2162,
        "name": "Manteno, IL",
        "correlation_id": "101248",
        "billing_address": {
            "id": 748,
            "street1": "1125 Sycamore Rd",
            "street2": "",
            "city": "Manteno",
            "region": "Illinois",
            "postal_code": "69050",
            "lat": 41.2381249,
            "lon": -87.8258079,
            "country": "United States"
        },
        "start_date": "2020-09-09T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 41.238249,
        "lon": -87.854641,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
        "is_mobile": true,
        "mobile_live_date": "2021-09-29",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Manteno, IL",
        "_lat": 41.238249,
        "_lng": -87.854641,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -87.854641
    },
    {
        "id": 2444,
        "name": "Market Street",
        "correlation_id": "101368",
        "billing_address": {
            "id": 269,
            "street1": "1455 Market St.",
            "street2": "",
            "city": "San Francisco",
            "region": "California",
            "postal_code": "94103",
            "lat": 37.7755556,
            "lon": -122.4178981,
            "country": "United States"
        },
        "start_date": "2023-11-22T18:13:37.847000Z",
        "total_sq_ft": "0",
        "total_buildings": "27",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "476b5b30-02ec-41da-b74a-86b56fbe7ab4",
        "is_mobile": true,
        "mobile_live_date": "2023-11-20",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Market Street",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 2586,
        "name": "Mason",
        "correlation_id": "101518",
        "billing_address": {
            "id": 645,
            "street1": "3600 Pharma Wy",
            "street2": "",
            "city": "Lebanon",
            "region": "Ohio",
            "postal_code": "45036",
            "lat": 39.3838251,
            "lon": -84.2907469,
            "country": "United States"
        },
        "start_date": "2021-06-28T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 39.38379,
        "lon": -84.28839,
        "local_timezone": {
            "id": 2218,
            "code": "America/Kentucky/Louisville",
            "display_name": "America/Kentucky/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 84
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
        "is_mobile": true,
        "mobile_live_date": "2021-09-27",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Mason",
        "_lat": 39.38379,
        "_lng": -84.28839,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -84.28839
    },
    {
        "id": 5495,
        "name": "McKnight Road",
        "correlation_id": "102320",
        "billing_address": {
            "id": 2993,
            "street1": "670 McKnight Road N",
            "street2": "",
            "city": "Maplewood",
            "region": "Minnesota",
            "postal_code": null,
            "lat": 44.9623013,
            "lon": -93.0036189,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "62329",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.96158,
        "lon": -93.00487,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
        "is_mobile": true,
        "mobile_live_date": "2023-09-06",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "McKnight Road",
        "_lat": 44.96158,
        "_lng": -93.00487,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.00487
    },
    {
        "id": 1974,
        "name": "Michigan*",
        "correlation_id": "11579",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 42.1185463,
        "lon": -86.476829,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "20a980a5-f48d-444c-b160-beb18ee9afc3",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Michigan*",
        "_lat": 42.1185463,
        "_lng": -86.476829,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -86.476829
    },
    {
        "id": 1,
        "name": "Milford PG, MI",
        "correlation_id": "10872",
        "billing_address": {
            "id": 2916,
            "street1": "3300 General Motor Rd",
            "street2": "",
            "city": "Milford",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.5762086,
            "lon": -83.6620009,
            "country": "United States"
        },
        "start_date": "2017-04-07T23:59:59Z",
        "total_sq_ft": "4838388",
        "total_buildings": "62",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 137,
            "name": "GM",
            "type": "CUSTOMER",
            "correlation_id": "10828",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
        },
        "parent_id": null,
        "lat": 42.5694305,
        "lon": -83.6742871,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
        "is_mobile": true,
        "mobile_live_date": "2018-10-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "GM",
        "_label": "GM",
        "_name": "Milford PG, MI",
        "_lat": 42.5694305,
        "_lng": -83.6742871,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853049,
                "create_date": "2020-11-14T00:00:06.049828Z",
                "update_date": "2020-11-14T00:00:06.049855Z",
                "organization_id": 137,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
                "size": 1080345,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.6742871
    },
    {
        "id": 2141,
        "name": "Mission Bay",
        "correlation_id": "101234",
        "billing_address": {
            "id": 3815,
            "street1": "1725 3rd St.",
            "street2": "",
            "city": "San Francisco,",
            "region": "California",
            "postal_code": null,
            "lat": 37.7673292,
            "lon": -122.3886371,
            "country": "United States"
        },
        "start_date": "2020-07-24T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 37.773972,
        "lon": -122.431297,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
        "is_mobile": true,
        "mobile_live_date": "2023-11-20",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Mission Bay",
        "_lat": 37.773972,
        "_lng": -122.431297,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.431297
    },
    {
        "id": 5745,
        "name": "Moberly*",
        "correlation_id": "102588",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c69889fb-e12c-4c02-9f86-f6491aa86793",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Moberly*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 5866,
        "name": "MOB – Maintenance & Operations – Dearborn, MI",
        "correlation_id": "102800",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2959395,
        "lon": -83.2314887,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "MOB – Maintenance & Operations – Dearborn, MI",
        "_lat": 42.2959395,
        "_lng": -83.2314887,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2314887
    },
    {
        "id": 4324,
        "name": "MOON TWP PA",
        "correlation_id": "102077",
        "billing_address": {
            "id": 2429,
            "street1": "520 Lindbergh Dr",
            "street2": "",
            "city": "Moon twp",
            "region": "Pennsylvania",
            "postal_code": null,
            "lat": 40.4984401,
            "lon": -80.2154365,
            "country": "United States"
        },
        "start_date": "2022-08-09T07:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 40.4984401,
        "lon": -80.2154365,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e02e2b9e-07c4-40f5-a6c6-ef2a900d31f7",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "MOON TWP PA",
        "_lat": 40.4984401,
        "_lng": -80.2154365,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -80.2154365
    },
    {
        "id": 5673,
        "name": "Morris Plains, NJ",
        "correlation_id": "102544",
        "billing_address": null,
        "start_date": "2023-03-21T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 40.8145,
        "lon": -74.47213,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7c72a9b1-b2bd-4155-8ac0-baeee081768d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "Morris Plains, NJ",
        "_lat": 40.8145,
        "_lng": -74.47213,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -74.47213
    },
    {
        "id": 2656,
        "name": "Murfreesboro",
        "correlation_id": "101555",
        "billing_address": {
            "id": 781,
            "street1": "2500 Memorial Blvd",
            "street2": "",
            "city": "Murfreesboro",
            "region": "Tennessee",
            "postal_code": "37130",
            "lat": 35.8922064,
            "lon": -86.380159,
            "country": "United States"
        },
        "start_date": "1969-12-31T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 35.89183,
        "lon": -86.38013,
        "local_timezone": {
            "id": 2224,
            "code": "America/Louisville",
            "display_name": "America/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 90
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "69c31c57-abb7-405d-9a8c-60e05813605b",
        "is_mobile": true,
        "mobile_live_date": "2022-09-27",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Murfreesboro",
        "_lat": 35.89183,
        "_lng": -86.38013,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -86.38013
    },
    {
        "id": 5742,
        "name": "Naperville*",
        "correlation_id": null,
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e2bbb81f-8b5d-4e14-89ae-65db86a2926f",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Naperville*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 1997,
        "name": "Newark, DE",
        "correlation_id": "11634",
        "billing_address": {
            "id": 2707,
            "street1": "201 Discovery Blvd.",
            "street2": "",
            "city": "Newark",
            "region": "Delaware",
            "postal_code": null,
            "lat": 39.6621516,
            "lon": -75.7555389,
            "country": "United States"
        },
        "start_date": "2022-10-18T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 269,
            "name": "Chemours (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100008",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "930d637c-d6ff-4734-a6e0-1f1e78b8876e"
        },
        "parent_id": null,
        "lat": 39.66318,
        "lon": -75.75244,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
        "is_mobile": true,
        "mobile_live_date": "2022-10-18",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Chemours (CBRE)",
        "_label": "Chemours (CBRE)",
        "_name": "Newark, DE",
        "_lat": 39.66318,
        "_lng": -75.75244,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 917976,
                "create_date": "2020-12-07T19:23:59.828737Z",
                "update_date": "2020-12-07T19:23:59.828765Z",
                "organization_id": 269,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607369039.649657_Chemours_t.png",
                "size": 8724,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.75244
    },
    {
        "id": 5714,
        "name": "New York",
        "correlation_id": "102580",
        "billing_address": {
            "id": 3622,
            "street1": "1 Columbus Circle",
            "street2": "",
            "city": "New York",
            "region": "New York",
            "postal_code": null,
            "lat": 40.76847,
            "lon": -73.983092,
            "country": "United States"
        },
        "start_date": "2023-07-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 468,
            "name": "Deutsche Bank",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
        },
        "parent_id": null,
        "lat": 40.76822,
        "lon": -73.98236,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
        "is_mobile": true,
        "mobile_live_date": "2023-09-22",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Deutsche Bank",
        "_label": "Deutsche Bank",
        "_name": "New York",
        "_lat": 40.76822,
        "_lng": -73.98236,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -73.98236
    },
    {
        "id": 1815,
        "name": "Norte",
        "correlation_id": "102706",
        "billing_address": null,
        "start_date": "2023-03-29T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.58624,
        "lon": -111.92705,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "92d39c75-3b17-4076-b411-4f84bd97f2a8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Norte",
        "_lat": 33.58624,
        "_lng": -111.92705,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.92705
    },
    {
        "id": 4447,
        "name": "North Dakota*",
        "correlation_id": "101963",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2174,
            "code": "America/Chihuahua",
            "display_name": "America/Chihuahua",
            "description": "",
            "child_group": null,
            "alt_sequence": 40
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b2771291-3b98-47c4-bb33-3844fb643bd2",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "North Dakota*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 1802,
        "name": "North Valley",
        "correlation_id": "102704",
        "billing_address": {
            "id": 4101,
            "street1": "38 North Central Avenue",
            "street2": "",
            "city": "Phoenix",
            "region": "Arizona",
            "postal_code": null,
            "lat": 33.4489064,
            "lon": -112.0742571,
            "country": "United States"
        },
        "start_date": "2024-01-31T15:08:23Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.4484,
        "lon": -112.074,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1140ded6-7083-44f9-86e0-5374fc09baa4",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "North Valley",
        "_lat": 33.4484,
        "_lng": -112.074,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.074
    },
    {
        "id": 1491,
        "name": "North Wales, PA",
        "correlation_id": "10077",
        "billing_address": {
            "id": 506,
            "street1": "351 North Sumneytown Pike",
            "street2": "",
            "city": "North Wales",
            "region": "Pennsylvania",
            "postal_code": "19454",
            "lat": 40.215304,
            "lon": -75.285274,
            "country": "United States"
        },
        "start_date": "2010-06-29T23:59:59Z",
        "total_sq_ft": "150000",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 40.2180846,
        "lon": -75.2821322,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "a153d850-6493-4210-a779-9ec041201f67",
        "is_mobile": true,
        "mobile_live_date": "2020-08-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "North Wales, PA",
        "_lat": 40.2180846,
        "_lng": -75.2821322,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.2821322
    },
    {
        "id": 5793,
        "name": "Northyard",
        "correlation_id": "102717",
        "billing_address": null,
        "start_date": "2023-06-30T22:18:54Z",
        "total_sq_ft": "0",
        "total_buildings": "3",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.7676877,
        "lon": -84.4027842,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "Northyard",
        "_lat": 33.7676877,
        "_lng": -84.4027842,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.4027842
    },
    {
        "id": 1768,
        "name": "Oak Hill, NY",
        "correlation_id": "11475",
        "billing_address": {
            "id": 462,
            "street1": "3169 NY-145",
            "street2": "",
            "city": "East Durham",
            "region": "New York",
            "postal_code": "12423",
            "lat": 42.3838465,
            "lon": -74.1244651,
            "country": "United States"
        },
        "start_date": "2019-03-01T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 33,
            "name": "GSK (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10264",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "4218fd8e-cac0-4db7-a800-73823d3df849"
        },
        "parent_id": null,
        "lat": 40.73061,
        "lon": -73.935242,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
        "is_mobile": true,
        "mobile_live_date": "2021-03-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "GSK (CBRE)",
        "_label": "GSK (CBRE)",
        "_name": "Oak Hill, NY",
        "_lat": 40.73061,
        "_lng": -73.935242,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 940392,
                "create_date": "2020-12-14T15:55:53.832689Z",
                "update_date": "2020-12-14T15:55:53.832715Z",
                "organization_id": 33,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607961353.7332714_GSK_t.jpeg",
                "size": 8042,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -73.935242
    },
    {
        "id": 2439,
        "name": "Oakland, CA",
        "correlation_id": "101366",
        "billing_address": {
            "id": 261,
            "street1": "1221 Broadway",
            "street2": "",
            "city": "Oakland",
            "region": "California",
            "postal_code": "94612",
            "lat": 37.8034631,
            "lon": -122.272345,
            "country": "United States"
        },
        "start_date": "2021-03-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 90,
            "name": "Clorox (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10394",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "f62cf9fa-612e-44fa-9813-ce2607cc371f"
        },
        "parent_id": null,
        "lat": 37.804829,
        "lon": -122.272476,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
        "is_mobile": true,
        "mobile_live_date": "2021-08-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Clorox (CBRE)",
        "_label": "Clorox (CBRE)",
        "_name": "Oakland, CA",
        "_lat": 37.804829,
        "_lng": -122.272476,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859890,
                "create_date": "2020-11-17T14:46:45.795075Z",
                "update_date": "2020-11-17T14:46:45.795102Z",
                "organization_id": 90,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
                "size": 10109,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.272476
    },
    {
        "id": 5852,
        "name": "OB1 – Office Building 1 – Dearborn, MI",
        "correlation_id": "102787",
        "billing_address": {
            "id": 3966,
            "street1": "20000 Rotunda Dr",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2948836,
            "lon": -83.2233969,
            "country": "United States"
        },
        "start_date": "2023-12-27T20:36:35Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2948836,
        "lon": -83.2233969,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB1 – Office Building 1 – Dearborn, MI",
        "_lat": 42.2948836,
        "_lng": -83.2233969,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2233969
    },
    {
        "id": 5853,
        "name": "OB2 – Office Building 2 – Dearborn, MI",
        "correlation_id": "102786",
        "billing_address": {
            "id": 3961,
            "street1": "20000 Rotunda Dr",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2948836,
            "lon": -83.2233969,
            "country": "United States"
        },
        "start_date": "2023-12-27T20:25:25Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2948836,
        "lon": -83.2233969,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB2 – Office Building 2 – Dearborn, MI",
        "_lat": 42.2948836,
        "_lng": -83.2233969,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2233969
    },
    {
        "id": 5854,
        "name": "OB3 – Office Building 3 – Dearborn, MI",
        "correlation_id": "102808",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.294421,
        "lon": -83.2279312,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB3 – Office Building 3 – Dearborn, MI",
        "_lat": 42.294421,
        "_lng": -83.2279312,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2279312
    },
    {
        "id": 5855,
        "name": "OB4 – Office Building 4 – Dearborn, MI",
        "correlation_id": "102811",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2948836,
        "lon": -83.2233969,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB4 – Office Building 4 – Dearborn, MI",
        "_lat": 42.2948836,
        "_lng": -83.2233969,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2233969
    },
    {
        "id": 5856,
        "name": "OB5 – Office Building 5 – Dearborn, MI",
        "correlation_id": "102788",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2931898,
        "lon": -83.2304411,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB5 – Office Building 5 – Dearborn, MI",
        "_lat": 42.2931898,
        "_lng": -83.2304411,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2304411
    },
    {
        "id": 5857,
        "name": "OB6 – Office Building 6 – Dearborn, MI",
        "correlation_id": "102812",
        "billing_address": {
            "id": 3951,
            "street1": "20600 Rotunda Dr",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2924974,
            "lon": -83.2342895,
            "country": "United States"
        },
        "start_date": "2023-12-27T19:09:38Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2924974,
        "lon": -83.2342895,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "OB6 – Office Building 6 – Dearborn, MI",
        "_lat": 42.2924974,
        "_lng": -83.2342895,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2342895
    },
    {
        "id": 5498,
        "name": "Old Saint Anthony/OSA*",
        "correlation_id": "102322",
        "billing_address": {
            "id": 2995,
            "street1": "425 East Hennepin Avenue",
            "street2": "",
            "city": "Minneapolis",
            "region": "Minnesota",
            "postal_code": null,
            "lat": 44.989117,
            "lon": -93.2539718,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "27822",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.98881,
        "lon": -93.25391,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Old Saint Anthony/OSA*",
        "_lat": 44.98881,
        "_lng": -93.25391,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.25391
    },
    {
        "id": 1803,
        "name": "Palm Valley",
        "correlation_id": "102705",
        "billing_address": null,
        "start_date": "2023-03-29T20:25:15Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.46372,
        "lon": -112.34871,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "60b3860e-c67b-449f-be64-9bbcad37cebd",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Palm Valley",
        "_lat": 33.46372,
        "_lng": -112.34871,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -112.34871
    },
    {
        "id": 1807,
        "name": "Paradise Valley",
        "correlation_id": "102629",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.55215,
        "lon": -111.960845,
        "local_timezone": {
            "id": 2181,
            "code": "America/Dawson",
            "display_name": "America/Dawson",
            "description": "",
            "child_group": null,
            "alt_sequence": 47
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ec867db4-3974-42ce-bc29-5d61f69ae6a8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Paradise Valley",
        "_lat": 33.55215,
        "_lng": -111.960845,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.960845
    },
    {
        "id": 2285,
        "name": "Parsippany",
        "correlation_id": "101314",
        "billing_address": {
            "id": 477,
            "street1": "8 Sylvan Way",
            "street2": "",
            "city": "Parsippany",
            "region": "New Jersey",
            "postal_code": "07054",
            "lat": 40.8477134,
            "lon": -74.4471814,
            "country": "United States"
        },
        "start_date": "2020-12-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 270,
            "name": "Novartis (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
        },
        "parent_id": null,
        "lat": 40.8471,
        "lon": -74.4472,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4355e3f2-863c-4791-a388-c79cce3df3af",
        "is_mobile": true,
        "mobile_live_date": "2021-03-24",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Novartis (CBRE)",
        "_label": "Novartis (CBRE)",
        "_name": "Parsippany",
        "_lat": 40.8471,
        "_lng": -74.4472,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859846,
                "create_date": "2020-11-17T14:36:58.595850Z",
                "update_date": "2020-11-17T14:36:58.595883Z",
                "organization_id": 270,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
                "size": 7078,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -74.4472
    },
    {
        "id": 1811,
        "name": "Paseo",
        "correlation_id": "102630",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.608367,
        "lon": -112.1854029,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "685ec8d0-0246-4b4c-baa1-6c43c9d2acbf",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Paseo",
        "_lat": 33.608367,
        "_lng": -112.1854029,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.1854029
    },
    {
        "id": 5870,
        "name": "PDC – Product Development Center - Dearborn, MI",
        "correlation_id": "102784",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2971988,
        "lon": -83.2393097,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "PDC – Product Development Center - Dearborn, MI",
        "_lat": 42.2971988,
        "_lng": -83.2393097,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2393097
    },
    {
        "id": 5792,
        "name": "PETC",
        "correlation_id": "102719",
        "billing_address": null,
        "start_date": "2023-06-30T22:19:48Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.832539,
        "lon": -84.4702182,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "29dba90e-76e1-4a8f-93be-eeefa24cff6b",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "PETC",
        "_lat": 33.832539,
        "_lng": -84.4702182,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.4702182
    },
    {
        "id": 5869,
        "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
        "correlation_id": "102803",
        "billing_address": {
            "id": 3970,
            "street1": "2440 Village Rd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2929714,
            "lon": -83.2423202,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:21:54.485000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2929714,
        "lon": -83.2423202,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
        "_lat": 42.2929714,
        "_lng": -83.2423202,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2423202
    },
    {
        "id": 5809,
        "name": "Pharma Facility",
        "correlation_id": "102736",
        "billing_address": {
            "id": 3745,
            "street1": "900 Davis Drive",
            "street2": "",
            "city": "Morrisville",
            "region": "North Carolina",
            "postal_code": null,
            "lat": 35.8718788,
            "lon": -78.8695008,
            "country": "United States"
        },
        "start_date": "2021-10-31T21:21:28Z",
        "total_sq_ft": "0",
        "total_buildings": "5",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 253,
            "name": "Biogen (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11428",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
        "is_mobile": true,
        "mobile_live_date": "2023-09-28",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Biogen (CBRE)",
        "_label": "Biogen (CBRE)",
        "_name": "Pharma Facility",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859837,
                "create_date": "2020-11-17T14:36:01.480552Z",
                "update_date": "2020-11-17T14:36:01.480579Z",
                "organization_id": 253,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623761.388814_Biogen01_t.png",
                "size": 6068,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 4454,
        "name": "Phoenix, AZ*",
        "correlation_id": "102130",
        "billing_address": {
            "id": 2462,
            "street1": "201 E. Washington Street  Floor 10",
            "street2": "",
            "city": null,
            "region": "Arizona",
            "postal_code": "85004",
            "lat": 33.4481186,
            "lon": -112.0705859,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0b13e65b-7545-4ad8-bf4f-b68d7a523795",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Phoenix, AZ*",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 1810,
        "name": "Phoenix Central",
        "correlation_id": "102631",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.4831035,
        "lon": -112.0682232,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Phoenix Central",
        "_lat": 33.4831035,
        "_lng": -112.0682232,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.0682232
    },
    {
        "id": 2333,
        "name": "Pittsburg",
        "correlation_id": "101846",
        "billing_address": {
            "id": 101,
            "street1": "1 Covestro Circle",
            "street2": "",
            "city": "Pittsburgh",
            "region": "Pennsylvania",
            "postal_code": "15205",
            "lat": 40.4418128,
            "lon": -80.0868497,
            "country": "United States"
        },
        "start_date": "2021-01-07T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "8",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 352,
            "name": "Covestro (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 100,
                    "street1": "1 Covestro Cirlce",
                    "street2": "",
                    "city": "Pittsurgh",
                    "region": "Pennsylvania",
                    "postal_code": "15205",
                    "lat": 40.443517,
                    "lon": -80.163434,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "932bcd1f-c316-4ddb-88c5-6b98c3c13292"
        },
        "parent_id": null,
        "lat": 40.4418128,
        "lon": -80.0868497,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
        "is_mobile": true,
        "mobile_live_date": "2022-08-25",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Covestro (CBRE)",
        "_label": "Covestro (CBRE)",
        "_name": "Pittsburg",
        "_lat": 40.4418128,
        "_lng": -80.0868497,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -80.0868497
    },
    {
        "id": 1356,
        "name": "Pleasanton",
        "correlation_id": "10395",
        "billing_address": {
            "id": 367,
            "street1": "4900 Johnson Dr",
            "street2": "",
            "city": "Pleasanton",
            "region": "California",
            "postal_code": "94588",
            "lat": 37.6939166,
            "lon": -121.9068135,
            "country": "United States"
        },
        "start_date": "2015-06-30T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "7",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 90,
            "name": "Clorox (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "10394",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "f62cf9fa-612e-44fa-9813-ce2607cc371f"
        },
        "parent_id": null,
        "lat": 37.693178,
        "lon": -121.907468,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Clorox (CBRE)",
        "_label": "Clorox (CBRE)",
        "_name": "Pleasanton",
        "_lat": 37.693178,
        "_lng": -121.907468,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859890,
                "create_date": "2020-11-17T14:46:45.795075Z",
                "update_date": "2020-11-17T14:46:45.795102Z",
                "organization_id": 90,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
                "size": 10109,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -121.907468
    },
    {
        "id": 1766,
        "name": "Pleasanton",
        "correlation_id": "11471",
        "billing_address": null,
        "start_date": "2019-02-11T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 37.696871,
        "lon": -121.8750228,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Pleasanton",
        "_lat": 37.696871,
        "_lng": -121.8750228,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -121.8750228
    },
    {
        "id": 2071,
        "name": "Plymouth",
        "correlation_id": "11603",
        "billing_address": {
            "id": 401,
            "street1": "49200 Halyard Drive",
            "street2": "",
            "city": "Plymouth",
            "region": "Michigan",
            "postal_code": "48170",
            "lat": 42.3854604,
            "lon": -83.5268434,
            "country": "United States"
        },
        "start_date": "2019-10-12T23:59:59Z",
        "total_sq_ft": "462000",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 280,
            "name": "Adient",
            "type": "CUSTOMER",
            "correlation_id": "11602",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "d4affd5e-1b58-47fa-9888-cc35a7481a7c"
        },
        "parent_id": null,
        "lat": 42.3844543,
        "lon": -83.5201148,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
        "is_mobile": true,
        "mobile_live_date": "2020-10-14",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Adient",
        "_label": "Adient",
        "_name": "Plymouth",
        "_lat": 42.3844543,
        "_lng": -83.5201148,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859875,
                "create_date": "2020-11-17T14:42:37.971083Z",
                "update_date": "2020-11-17T14:42:37.971117Z",
                "organization_id": 280,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605624157.877317_adientvectorlogosmall_t.png",
                "size": 6440,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.5201148
    },
    {
        "id": 5868,
        "name": "Powerhouse – Dearborn, MI",
        "correlation_id": "102797",
        "billing_address": {
            "id": 3971,
            "street1": "3001 Miller Rd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3083375,
            "lon": -83.1563578,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:27:32.734000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3083375,
        "lon": -83.1563578,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0d84e8b7-49f2-4140-8852-e9f6f0cc2a46",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "Powerhouse – Dearborn, MI",
        "_lat": 42.3083375,
        "_lng": -83.1563578,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.1563578
    },
    {
        "id": 5867,
        "name": "PRC – Product Review Center – Dearborn, MI",
        "correlation_id": "102793",
        "billing_address": {
            "id": 3972,
            "street1": "2101 Village Rd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2938341,
            "lon": -83.2383704,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:30:46Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2938341,
        "lon": -83.2383704,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "PRC – Product Review Center – Dearborn, MI",
        "_lat": 42.2938341,
        "_lng": -83.2383704,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2383704
    },
    {
        "id": 7,
        "name": "Puget Sound, WA (CBRE)",
        "correlation_id": "11277",
        "billing_address": {
            "id": 492,
            "street1": "15700 NE 39th St B25/G15",
            "street2": "",
            "city": "Redmond",
            "region": "Washington",
            "postal_code": "98052",
            "lat": 47.6451337,
            "lon": -122.1309164,
            "country": "United States"
        },
        "start_date": "2017-12-08T23:59:59Z",
        "total_sq_ft": "14373565",
        "total_buildings": "182",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 47.6062095,
        "lon": -122.3320708,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Puget Sound, WA (CBRE)",
        "_lat": 47.6062095,
        "_lng": -122.3320708,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.3320708
    },
    {
        "id": 1995,
        "name": "Puget Sound, WA (Compass)",
        "correlation_id": "11669",
        "billing_address": null,
        "start_date": "2017-12-06T23:59:59Z",
        "total_sq_ft": "13593983",
        "total_buildings": "111",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 47.6062095,
        "lon": -122.3320708,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
        "is_mobile": true,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Puget Sound, WA (Compass)",
        "_lat": 47.6062095,
        "_lng": -122.3320708,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.3320708
    },
    {
        "id": 1805,
        "name": "Queen Creek",
        "correlation_id": "102632",
        "billing_address": null,
        "start_date": "2023-03-29T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.20889,
        "lon": -111.56447,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6e8c47b4-e6f3-4658-951f-f2b342053d1d",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Queen Creek",
        "_lat": 33.20889,
        "_lng": -111.56447,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.56447
    },
    {
        "id": 3470,
        "name": "Redlands",
        "correlation_id": "101921",
        "billing_address": null,
        "start_date": "2021-06-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 468,
            "name": "Deutsche Bank",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
        },
        "parent_id": null,
        "lat": 34.0712234,
        "lon": -117.2428528,
        "local_timezone": {
            "id": 2273,
            "code": "America/St_Barthelemy",
            "display_name": "America/St_Barthelemy",
            "description": "",
            "child_group": null,
            "alt_sequence": 139
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "219a07d3-50c9-4f61-ba29-3cc2aeeb14ef",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Deutsche Bank",
        "_label": "Deutsche Bank",
        "_name": "Redlands",
        "_lat": 34.0712234,
        "_lng": -117.2428528,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -117.2428528
    },
    {
        "id": 1801,
        "name": "Red Mountain",
        "correlation_id": "102633",
        "billing_address": {
            "id": 3737,
            "street1": "5735 E. McKellips Rd., #101",
            "street2": "",
            "city": "Mesa",
            "region": "Arizona",
            "postal_code": null,
            "lat": 33.4513006,
            "lon": -111.7085589,
            "country": "United States"
        },
        "start_date": "2023-03-29T20:31:33Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.45135,
        "lon": -111.70855,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4b9e0380-2649-4b06-88fc-bf2d670dafbf",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Red Mountain",
        "_lat": 33.45135,
        "_lng": -111.70855,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.70855
    },
    {
        "id": 2348,
        "name": "Reno, NV",
        "correlation_id": "101333",
        "billing_address": {
            "id": 135,
            "street1": "6840 Sierra Center Pkwy",
            "street2": "",
            "city": "Reno",
            "region": "Nevada",
            "postal_code": "89511",
            "lat": 39.4663787,
            "lon": -119.7800313,
            "country": "Afghanistan"
        },
        "start_date": "2021-03-04T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 39.4663787,
        "lon": -119.7800313,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
        "is_mobile": true,
        "mobile_live_date": "2021-07-29",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Reno, NV",
        "_lat": 39.4663787,
        "_lng": -119.7800313,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -119.7800313
    },
    {
        "id": 3467,
        "name": "Richmond, IN",
        "correlation_id": "102047",
        "billing_address": {
            "id": 1571,
            "street1": "120 N 9Th St.",
            "street2": "",
            "city": "Richmond",
            "region": "Indiana",
            "postal_code": "47374",
            "lat": 39.8314409,
            "lon": -84.890706,
            "country": "United States"
        },
        "start_date": "2022-05-09T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1018,
            "name": "Chubb Insurance (CBRE)",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 1442,
                    "street1": "202 Halls Mill Rd A",
                    "street2": "",
                    "city": "Whitehouse Station",
                    "region": "New Jersey",
                    "postal_code": "08889",
                    "lat": 40.6459052,
                    "lon": -74.7647032,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033"
        },
        "parent_id": null,
        "lat": 39.83131,
        "lon": -84.89069,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "519c7469-e232-466e-9e16-fb9c702efc59",
        "is_mobile": true,
        "mobile_live_date": "2023-05-10",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Chubb Insurance (CBRE)",
        "_label": "Chubb Insurance (CBRE)",
        "_name": "Richmond, IN",
        "_lat": 39.83131,
        "_lng": -84.89069,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.89069
    },
    {
        "id": 2251,
        "name": "RIC – Research & Engineering – Dearborn, MI",
        "correlation_id": "101262",
        "billing_address": {
            "id": 216,
            "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": "48124",
            "lat": 42.2948836,
            "lon": -83.2233969,
            "country": "United States"
        },
        "start_date": "2020-11-08T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "214",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": 5847,
        "lat": 42.2979002,
        "lon": -83.2281853,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
        "is_mobile": true,
        "mobile_live_date": "2020-11-09",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": true,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "RIC – Research & Engineering – Dearborn, MI",
        "_lat": 42.2979002,
        "_lng": -83.2281853,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2281853
    },
    {
        "id": 5499,
        "name": "Ridgedale",
        "correlation_id": "102323",
        "billing_address": {
            "id": 2996,
            "street1": "1809 Plymouth Road South",
            "street2": "",
            "city": "Minnetonka",
            "region": "Minnesota",
            "postal_code": null,
            "lat": 44.9667231,
            "lon": -93.4418737,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "40600",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.96648,
        "lon": -93.44187,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
        "is_mobile": true,
        "mobile_live_date": "2022-07-05",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Ridgedale",
        "_lat": 44.96648,
        "_lng": -93.44187,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.44187
    },
    {
        "id": 2589,
        "name": "Roseville",
        "correlation_id": "101530",
        "billing_address": {
            "id": 649,
            "street1": "7701 Foothill Blvd.",
            "street2": "",
            "city": "Roseville",
            "region": "California",
            "postal_code": "95747",
            "lat": 38.779129,
            "lon": -121.3124168,
            "country": "United States"
        },
        "start_date": "2021-07-01T12:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 38.78127,
        "lon": -121.31374,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "123db32d-6425-45a7-a771-e015c599d908",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Roseville",
        "_lat": 38.78127,
        "_lng": -121.31374,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -121.31374
    },
    {
        "id": 2637,
        "name": "RTP Bio Facility",
        "correlation_id": "101586",
        "billing_address": {
            "id": 771,
            "street1": "5000 Davis Dr",
            "street2": "",
            "city": "Morrisville",
            "region": "North Carolina",
            "postal_code": "27560",
            "lat": 35.8603544,
            "lon": -78.8686077,
            "country": "United States"
        },
        "start_date": "2021-10-31T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "11",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 253,
            "name": "Biogen (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11428",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
        "is_mobile": true,
        "mobile_live_date": "2022-03-10",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Biogen (CBRE)",
        "_label": "Biogen (CBRE)",
        "_name": "RTP Bio Facility",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859837,
                "create_date": "2020-11-17T14:36:01.480552Z",
                "update_date": "2020-11-17T14:36:01.480579Z",
                "organization_id": 253,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623761.388814_Biogen01_t.png",
                "size": 6068,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 1626,
        "name": "San Antonio, TX",
        "correlation_id": "11180",
        "billing_address": {
            "id": 3023,
            "street1": "4101 Wiseman Blvd.",
            "street2": "",
            "city": "San Antonio",
            "region": "Texas",
            "postal_code": null,
            "lat": 29.478178,
            "lon": -98.705919,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "11",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 29.4295961,
        "lon": -98.6543005,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
        "is_mobile": true,
        "mobile_live_date": "2023-05-10",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "San Antonio, TX",
        "_lat": 29.4295961,
        "_lng": -98.6543005,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -98.6543005
    },
    {
        "id": 2072,
        "name": "San Diego, CA",
        "correlation_id": "11672",
        "billing_address": {
            "id": 674,
            "street1": "17075 Thormint Ct",
            "street2": "",
            "city": "San Diego",
            "region": "California",
            "postal_code": "92127",
            "lat": 33.0181702,
            "lon": -117.0989902,
            "country": "United States"
        },
        "start_date": "2020-03-03T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 281,
            "name": "ASML (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11671",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "5d48c550-59a7-4017-8897-0ce78fc6794d"
        },
        "parent_id": null,
        "lat": 33.0187007,
        "lon": -117.1027417,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
        "is_mobile": true,
        "mobile_live_date": "2021-09-01",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "ASML (CBRE)",
        "_label": "ASML (CBRE)",
        "_name": "San Diego, CA",
        "_lat": 33.0187007,
        "_lng": -117.1027417,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 916871,
                "create_date": "2020-12-07T16:46:46.706485Z",
                "update_date": "2020-12-07T16:46:46.706507Z",
                "organization_id": 281,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607359606.6159544_ASML_t.png",
                "size": 2878,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -117.1027417
    },
    {
        "id": 3993,
        "name": "San Francisco",
        "correlation_id": "102103",
        "billing_address": {
            "id": 1968,
            "street1": "181 Fremont St.",
            "street2": "",
            "city": null,
            "region": "California",
            "postal_code": "94105",
            "lat": 37.7896113,
            "lon": -122.3954593,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 37.78975,
        "lon": -122.39529,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "San Francisco",
        "_lat": 37.78975,
        "_lng": -122.39529,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.39529
    },
    {
        "id": 5817,
        "name": "San Francisco, CA",
        "correlation_id": "102741",
        "billing_address": {
            "id": 3758,
            "street1": "1355 Market St. Suite #300",
            "street2": "",
            "city": "San Francisco",
            "region": "California",
            "postal_code": null,
            "lat": 37.7770971,
            "lon": -122.4164914,
            "country": "United States"
        },
        "start_date": "2023-08-31T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 37.77725,
        "lon": -122.41664,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
        "is_mobile": true,
        "mobile_live_date": "2023-08-26",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "San Francisco, CA",
        "_lat": 37.77725,
        "_lng": -122.41664,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.41664
    },
    {
        "id": 2570,
        "name": "Santa Ana",
        "correlation_id": "101514",
        "billing_address": {
            "id": 3777,
            "street1": "1761 E St Andrew Pl",
            "street2": "",
            "city": "Santa Ana",
            "region": "California",
            "postal_code": null,
            "lat": 33.7231528,
            "lon": -117.8422379,
            "country": "United States"
        },
        "start_date": "2021-06-30T17:45:08Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 468,
            "name": "Deutsche Bank",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
        },
        "parent_id": null,
        "lat": 33.72296,
        "lon": -117.84189,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Deutsche Bank",
        "_label": "Deutsche Bank",
        "_name": "Santa Ana",
        "_lat": 33.72296,
        "_lng": -117.84189,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -117.84189
    },
    {
        "id": 839,
        "name": "Santa Clara, CA",
        "correlation_id": "11085",
        "billing_address": {
            "id": 476,
            "street1": "2788 San Tomas Expy",
            "street2": "",
            "city": "Santa Clara",
            "region": "California",
            "postal_code": "95051",
            "lat": 37.3708002,
            "lon": -121.9673611,
            "country": "United States"
        },
        "start_date": "2017-08-31T23:59:59Z",
        "total_sq_ft": "1952981",
        "total_buildings": "15",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 5,
            "name": "Nvidia (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11034",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "1c39849c-f884-456b-9642-ee55b07a76b6"
        },
        "parent_id": null,
        "lat": 37.37086468,
        "lon": -121.96483141,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Nvidia (CBRE)",
        "_label": "Nvidia (CBRE)",
        "_name": "Santa Clara, CA",
        "_lat": 37.37086468,
        "_lng": -121.96483141,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853037,
                "create_date": "2020-11-13T23:57:40.522980Z",
                "update_date": "2020-11-13T23:57:40.523005Z",
                "organization_id": 5,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311860.3280911_Artboard10_t.png",
                "size": 195877,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -121.96483141
    },
    {
        "id": 2266,
        "name": "Santa Clara, CA",
        "correlation_id": "101290",
        "billing_address": {
            "id": 201,
            "street1": "2220 De La Cruz Blvd  Santa Clara, CA 95050, USA",
            "street2": "",
            "city": "santa clara",
            "region": "California",
            "postal_code": "95050",
            "lat": 37.3619616,
            "lon": -121.9434003,
            "country": "United States"
        },
        "start_date": "2021-02-23T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "10",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
        "is_mobile": true,
        "mobile_live_date": "2021-02-21",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Santa Clara, CA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 4226,
        "name": "Santa Fe Springs",
        "correlation_id": "102030",
        "billing_address": {
            "id": 2168,
            "street1": "9501 Norwalk Blvd.",
            "street2": "",
            "city": null,
            "region": "California",
            "postal_code": "90670",
            "lat": 33.9537939,
            "lon": -118.0740669,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 33.95344,
        "lon": -118.07427,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "Santa Fe Springs",
        "_lat": 33.95344,
        "_lng": -118.07427,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -118.07427
    },
    {
        "id": 1806,
        "name": "San Tan",
        "correlation_id": "102634",
        "billing_address": {
            "id": 3738,
            "street1": "3530 S Val Visa Drive",
            "street2": "",
            "city": "Gilbert",
            "region": "Arizona",
            "postal_code": null,
            "lat": 33.2858296,
            "lon": -111.7575901,
            "country": "United States"
        },
        "start_date": "2023-03-29T20:21:59Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 33.28605,
        "lon": -111.75754,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c9619e89-b869-45bc-bdae-dc199a005480",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "San Tan",
        "_lat": 33.28605,
        "_lng": -111.75754,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.75754
    },
    {
        "id": 3995,
        "name": "Sausalito",
        "correlation_id": "102105",
        "billing_address": {
            "id": 1970,
            "street1": "2658 Bridgeway",
            "street2": "",
            "city": null,
            "region": "California",
            "postal_code": "94965",
            "lat": 37.868757,
            "lon": -122.5016847,
            "country": "United States"
        },
        "start_date": "2022-07-25T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1422,
            "name": "Meta (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
        },
        "parent_id": null,
        "lat": 37.86851,
        "lon": -122.50189,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4711f2f6-bb8c-4cfb-aaaf-49edee61dbb7",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Meta (CBRE)",
        "_label": "Meta (CBRE)",
        "_name": "Sausalito",
        "_lat": 37.86851,
        "_lng": -122.50189,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -122.50189
    },
    {
        "id": 1814,
        "name": "Scottsdale (Skysong)",
        "correlation_id": "102635",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.4621,
        "lon": -111.92463,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "1b828096-e8e3-4647-828c-8608cfb8389e",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Scottsdale (Skysong)",
        "_lat": 33.4621,
        "_lng": -111.92463,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.92463
    },
    {
        "id": 5496,
        "name": "Shoreview Operations CTR",
        "correlation_id": "102319",
        "billing_address": {
            "id": 2992,
            "street1": "1801 Park View Dr.",
            "street2": "",
            "city": "Shoreview",
            "region": "Minnesota",
            "postal_code": null,
            "lat": 45.1226173,
            "lon": -93.1778569,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 45.12135,
        "lon": -93.17957,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Shoreview Operations CTR",
        "_lat": 45.12135,
        "_lng": -93.17957,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.17957
    },
    {
        "id": 5871,
        "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
        "correlation_id": "102789",
        "billing_address": {
            "id": 3973,
            "street1": "20050 Oakwood, Dearborn, MI 48124",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.297636,
            "lon": -83.2252915,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:33:42Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.297636,
        "lon": -83.2252915,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
        "_lat": 42.297636,
        "_lng": -83.2252915,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2252915
    },
    {
        "id": 5500,
        "name": "Sioux Falls Main",
        "correlation_id": "102554",
        "billing_address": {
            "id": 2998,
            "street1": "101 N Phillips Avenue",
            "street2": "",
            "city": "Sioux Falls",
            "region": "South Dakota",
            "postal_code": null,
            "lat": 43.5475764,
            "lon": -96.727741,
            "country": "United States"
        },
        "start_date": "2018-09-30T00:00:00Z",
        "total_sq_ft": "56911",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 43.54766,
        "lon": -96.7268,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Sioux Falls Main",
        "_lat": 43.54766,
        "_lng": -96.7268,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -96.7268
    },
    {
        "id": 1673,
        "name": "Sioux Falls OPS",
        "correlation_id": "11280",
        "billing_address": {
            "id": 417,
            "street1": "3201 North 4th Ave",
            "street2": "",
            "city": "sioux Falls",
            "region": "South Dakota",
            "postal_code": "57104",
            "lat": 43.580928,
            "lon": -96.7238115,
            "country": "United States"
        },
        "start_date": "2018-09-30T23:59:59Z",
        "total_sq_ft": "403443",
        "total_buildings": "4",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 43.5445959,
        "lon": -96.7311034,
        "local_timezone": {
            "id": 2247,
            "code": "America/North_Dakota/Center",
            "display_name": "America/North_Dakota/Center",
            "description": "",
            "child_group": null,
            "alt_sequence": 113
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Sioux Falls OPS",
        "_lat": 43.5445959,
        "_lng": -96.7311034,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -96.7311034
    },
    {
        "id": 2553,
        "name": "Soquel",
        "correlation_id": "101503",
        "billing_address": {
            "id": 539,
            "street1": "3150 Crow Canyon Rd",
            "street2": "",
            "city": "Soquel",
            "region": "California",
            "postal_code": "95073",
            "lat": 37.7794031,
            "lon": -121.9705085,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 269,
            "name": "Chemours (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100008",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "930d637c-d6ff-4734-a6e0-1f1e78b8876e"
        },
        "parent_id": null,
        "lat": 37.7794031,
        "lon": -121.9705085,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "36218ea9-1cbd-4e87-9aa9-28abb70b6b8c",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Chemours (CBRE)",
        "_label": "Chemours (CBRE)",
        "_name": "Soquel",
        "_lat": 37.7794031,
        "_lng": -121.9705085,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 917976,
                "create_date": "2020-12-07T19:23:59.828737Z",
                "update_date": "2020-12-07T19:23:59.828765Z",
                "organization_id": 269,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607369039.649657_Chemours_t.png",
                "size": 8724,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -121.9705085
    },
    {
        "id": 1816,
        "name": "South Mountain",
        "correlation_id": "102636",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.38039,
        "lon": -112.07391,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6099def8-9e0b-4883-9f22-d6cb2ae87bd0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "South Mountain",
        "_lat": 33.38039,
        "_lng": -112.07391,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.07391
    },
    {
        "id": 2604,
        "name": "Springhouse, PA",
        "correlation_id": "101548",
        "billing_address": {
            "id": 712,
            "street1": "727 Norristown Rd. Bldg 4",
            "street2": "",
            "city": "Lower Gwynedd Township",
            "region": "Pennsylvania",
            "postal_code": "19002",
            "lat": 40.1840507,
            "lon": -75.2126261,
            "country": "United States"
        },
        "start_date": "2023-07-07T22:26:53.064000Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 477,
            "name": "Organon (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "08a94e30-365e-43b4-b5b2-acb91ade287c"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "6100a342-7776-41ab-a4a9-9e4a092fb692",
        "is_mobile": true,
        "mobile_live_date": "2023-06-08",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Organon (CBRE)",
        "_label": "Organon (CBRE)",
        "_name": "Springhouse, PA",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 5651,
        "name": "Springhouse Plant, PA",
        "correlation_id": "102528",
        "billing_address": {
            "id": 3741,
            "street1": "727 Norristown Rd Building 2",
            "street2": "",
            "city": "Lower Gwynedd",
            "region": "Pennsylvania",
            "postal_code": null,
            "lat": 40.1834221,
            "lon": -75.2146856,
            "country": "United States"
        },
        "start_date": "2023-04-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 40.18209,
        "lon": -75.21772,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
        "is_mobile": true,
        "mobile_live_date": "2023-07-07",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "Springhouse Plant, PA",
        "_lat": 40.18209,
        "_lng": -75.21772,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.21772
    },
    {
        "id": 5872,
        "name": "SRC – Service Research Center – Dearborn, MI",
        "correlation_id": "102809",
        "billing_address": {
            "id": 3974,
            "street1": "18751 Oakwood Blvd.",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.293941,
            "lon": -83.2197383,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:48:55Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.293941,
        "lon": -83.2197383,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "SRC – Service Research Center – Dearborn, MI",
        "_lat": 42.293941,
        "_lng": -83.2197383,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2197383
    },
    {
        "id": 1813,
        "name": "Stapley",
        "correlation_id": "102637",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.3817582,
        "lon": -111.8090694,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "36c16e7b-11db-4b03-b2a0-d7d67985f7ca",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Stapley",
        "_lat": 33.3817582,
        "_lng": -111.8090694,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.8090694
    },
    {
        "id": 2612,
        "name": "St. Louis (Express Scripts)",
        "correlation_id": "101552",
        "billing_address": {
            "id": 727,
            "street1": "1 Express",
            "street2": "",
            "city": "St Louis",
            "region": "Missouri",
            "postal_code": "63121",
            "lat": 38.7102377,
            "lon": -90.3042778,
            "country": "United States"
        },
        "start_date": "2021-09-29T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "116",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 38.71867,
        "lon": -90.31076,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
        "is_mobile": true,
        "mobile_live_date": "2021-12-14",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "St. Louis (Express Scripts)",
        "_lat": 38.71867,
        "_lng": -90.31076,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -90.31076
    },
    {
        "id": 1323,
        "name": "St. Louis, MO",
        "correlation_id": "10332",
        "billing_address": {
            "id": 430,
            "street1": "1 North Jefferson Street",
            "street2": "",
            "city": "Saint Louis",
            "region": "Missouri",
            "postal_code": "63103",
            "lat": 38.6317842,
            "lon": -90.2164111,
            "country": "United States"
        },
        "start_date": "2009-09-30T23:59:59Z",
        "total_sq_ft": "1801389",
        "total_buildings": "25",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 38.6312741,
        "lon": -90.2158183,
        "local_timezone": {
            "id": 2218,
            "code": "America/Kentucky/Louisville",
            "display_name": "America/Kentucky/Louisville",
            "description": "",
            "child_group": null,
            "alt_sequence": 84
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": "3147893557",
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": true,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "St. Louis, MO",
        "_lat": 38.6312741,
        "_lng": -90.2158183,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -90.2158183
    },
    {
        "id": 1666,
        "name": "St. Louis, MO",
        "correlation_id": "11270",
        "billing_address": {
            "id": 292,
            "street1": "4220 Duncan Avenue",
            "street2": "",
            "city": "St.Louis",
            "region": "Alabama",
            "postal_code": "63110",
            "lat": 32.3182314,
            "lon": -86.902298,
            "country": "United States"
        },
        "start_date": "2018-06-30T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 38.634292,
        "lon": -90.2492292,
        "local_timezone": {
            "id": 2274,
            "code": "America/St_Johns",
            "display_name": "America/St_Johns",
            "description": "",
            "child_group": null,
            "alt_sequence": 140
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "c63a0b01-8803-4f82-bceb-eed4af6ada8d",
        "is_mobile": true,
        "mobile_live_date": "2021-03-24",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "St. Louis, MO",
        "_lat": 38.634292,
        "_lng": -90.2492292,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -90.2492292
    },
    {
        "id": 6335,
        "name": "St. Petersburg*",
        "correlation_id": "103363",
        "billing_address": null,
        "start_date": "2023-12-13T15:20:43Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 220,
            "name": "McKesson (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11558",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
        },
        "parent_id": null,
        "lat": 27.884088,
        "lon": -82.6723645,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d6067b11-8ffd-4843-89f5-4e40036439a2",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "McKesson (CBRE)",
        "_label": "McKesson (CBRE)",
        "_name": "St. Petersburg*",
        "_lat": 27.884088,
        "_lng": -82.6723645,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859826,
                "create_date": "2020-11-17T14:34:24.774886Z",
                "update_date": "2020-11-17T14:34:24.774913Z",
                "organization_id": 220,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
                "size": 3175,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -82.6723645
    },
    {
        "id": 1819,
        "name": "Sun City",
        "correlation_id": "102638",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.580944,
        "lon": -112.237068,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Sun City",
        "_lat": 33.580944,
        "_lng": -112.237068,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.237068
    },
    {
        "id": 1818,
        "name": "Sun City West",
        "correlation_id": "102639",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.64875,
        "lon": -112.36341,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2aefa12a-781f-491e-a736-c5e0415005c1",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Sun City West",
        "_lat": 33.64875,
        "_lng": -112.36341,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.36341
    },
    {
        "id": 2172,
        "name": "Sunnyvale",
        "correlation_id": "101250",
        "billing_address": {
            "id": 1116,
            "street1": "190 Mathilda Place",
            "street2": "",
            "city": "Sunnyvale",
            "region": "California",
            "postal_code": "94086",
            "lat": 37.377487,
            "lon": -122.0335341,
            "country": "United States"
        },
        "start_date": "2020-09-13T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 303,
            "name": "Uber (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
        "is_mobile": true,
        "mobile_live_date": "2021-12-14",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE)",
        "_label": "Uber (CBRE)",
        "_name": "Sunnyvale",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 933016,
                "create_date": "2020-12-10T21:36:11.844767Z",
                "update_date": "2020-12-10T21:36:11.844800Z",
                "organization_id": 303,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607636171.7063708_Uber_t.jpg",
                "size": 16927,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": 0
    },
    {
        "id": 1151,
        "name": "Sunnyvale, CA",
        "correlation_id": "10990",
        "billing_address": {
            "id": 740,
            "street1": "1137 Innovation Way",
            "street2": "",
            "city": "Sunnyvale",
            "region": "California",
            "postal_code": "95117",
            "lat": 37.4083292,
            "lon": -122.028921,
            "country": "United States"
        },
        "start_date": "2017-09-05T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "8",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 63,
            "name": "Juniper",
            "type": "CUSTOMER",
            "correlation_id": "10989",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "9ba78891-40df-480f-bce1-a24565a99a30"
        },
        "parent_id": null,
        "lat": 37.4075923,
        "lon": -122.0292407,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
        "is_mobile": true,
        "mobile_live_date": "2021-09-06",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Juniper",
        "_label": "Juniper",
        "_name": "Sunnyvale, CA",
        "_lat": 37.4075923,
        "_lng": -122.0292407,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859896,
                "create_date": "2020-11-17T14:48:53.678933Z",
                "update_date": "2020-11-17T14:48:53.678957Z",
                "organization_id": 63,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605624533.566394_JN_t.png",
                "size": 4049,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -122.0292407
    },
    {
        "id": 2350,
        "name": "Superstition Springs",
        "correlation_id": "102640",
        "billing_address": {
            "id": 428,
            "street1": "13255 West Peck Dr",
            "street2": "",
            "city": "Litchfield Park",
            "region": "Arizona",
            "postal_code": "85340",
            "lat": 33.5257052,
            "lon": -112.3449252,
            "country": "United States"
        },
        "start_date": "2021-06-04T23:59:59Z",
        "total_sq_ft": "13250",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": 2533,
        "lat": 33.3837369,
        "lon": -111.6865126,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "563ae7fc-1776-4457-8803-0f751025b6e8",
        "is_mobile": true,
        "mobile_live_date": "2021-06-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Superstition Springs",
        "_lat": 33.3837369,
        "_lng": -111.6865126,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -111.6865126
    },
    {
        "id": 5873,
        "name": "TEE – Truck Engine Engineering – Dearborn, MI",
        "correlation_id": "102801",
        "billing_address": {
            "id": 3975,
            "street1": "2000 Enterprise Dr.",
            "street2": "",
            "city": "Allen Park",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2881615,
            "lon": -83.1908566,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:50:35Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2881615,
        "lon": -83.1908566,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "TEE – Truck Engine Engineering – Dearborn, MI",
        "_lat": 42.2881615,
        "_lng": -83.1908566,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.1908566
    },
    {
        "id": 2658,
        "name": "Tempe",
        "correlation_id": "101638",
        "billing_address": {
            "id": 787,
            "street1": "2925 S Sunland Dr",
            "street2": "",
            "city": "Tempe",
            "region": "Arizona",
            "postal_code": "85282",
            "lat": 33.3978204,
            "lon": -111.9624029,
            "country": "United States"
        },
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 33.39786,
        "lon": -111.96239,
        "local_timezone": {
            "id": 2183,
            "code": "America/Denver",
            "display_name": "America/Denver",
            "description": "",
            "child_group": null,
            "alt_sequence": 49
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e162ed58-c003-44f6-a09d-311a5348dfb8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Tempe",
        "_lat": 33.39786,
        "_lng": -111.96239,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -111.96239
    },
    {
        "id": 2226,
        "name": "TFC – The Factory – Detroit, MI",
        "correlation_id": "102781",
        "billing_address": null,
        "start_date": "2020-11-09T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3314,
        "lon": -83.0458,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "TFC – The Factory – Detroit, MI",
        "_lat": 42.3314,
        "_lng": -83.0458,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.0458
    },
    {
        "id": 5538,
        "name": "Thomas Edison",
        "correlation_id": "102349",
        "billing_address": null,
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.92309,
        "lon": -91.63905,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b82f11dc-9828-47ad-ae9b-b8379522f558",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Thomas Edison",
        "_lat": 41.92309,
        "_lng": -91.63905,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -91.63905
    },
    {
        "id": 5533,
        "name": "Toms River Main St*",
        "correlation_id": "102353",
        "billing_address": null,
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 39.9533765,
        "lon": -74.1989016,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b52bb03f-14c2-43e7-ae7a-213be4ffd080",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Toms River Main St*",
        "_lat": 39.9533765,
        "_lng": -74.1989016,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -74.1989016
    },
    {
        "id": 2627,
        "name": "Tulsa, OK",
        "correlation_id": "101575",
        "billing_address": {
            "id": 763,
            "street1": "12222 State Farm Blvd.",
            "street2": "",
            "city": "Tulsa",
            "region": "Oklahoma",
            "postal_code": "74146",
            "lat": 36.0939624,
            "lon": -95.835085,
            "country": "United States"
        },
        "start_date": "2021-09-30T07:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 36.09371,
        "lon": -95.83937,
        "local_timezone": {
            "id": 2191,
            "code": "America/Fort_Wayne",
            "display_name": "America/Fort_Wayne",
            "description": "",
            "child_group": null,
            "alt_sequence": 57
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d6f64213-b922-467f-8c79-04580edbd9ce",
        "is_mobile": true,
        "mobile_live_date": "2022-10-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Tulsa, OK",
        "_lat": 36.09371,
        "_lng": -95.83937,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -95.83937
    },
    {
        "id": 1416,
        "name": "Uber Global",
        "correlation_id": "10480",
        "billing_address": {
            "id": 54,
            "street1": "Ultrapark Free Zone, La Aurora,",
            "street2": "",
            "city": "Heredia",
            "region": "Alabama",
            "postal_code": "40101",
            "lat": 32.3182314,
            "lon": -86.902298,
            "country": "United States"
        },
        "start_date": "2019-10-31T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 115,
            "name": "Uber (CBRE) (CR)",
            "type": "CUSTOMER",
            "correlation_id": "10479",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "a0fa919b-e92b-469f-9fec-38f2bd9fa7a4"
        },
        "parent_id": null,
        "lat": 9.9650978,
        "lon": -84.1200942,
        "local_timezone": {
            "id": 2177,
            "code": "America/Costa_Rica",
            "display_name": "America/Costa_Rica",
            "description": "",
            "child_group": null,
            "alt_sequence": 43
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b30e9a6c-8de0-4c38-bee9-2a77daa148bd",
        "is_mobile": true,
        "mobile_live_date": "2021-10-31",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Uber (CBRE) (CR)",
        "_label": "Uber (CBRE) (CR)",
        "_name": "Uber Global",
        "_lat": 9.9650978,
        "_lng": -84.1200942,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 932145,
                "create_date": "2020-12-10T19:10:56.836917Z",
                "update_date": "2020-12-10T19:10:56.836940Z",
                "organization_id": 115,
                "uploaded_by_id": 41295,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1607627456.7434294_UberCR_t.jpg",
                "size": 7874,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -84.1200942
    },
    {
        "id": 3233,
        "name": "Union City",
        "correlation_id": "102031",
        "billing_address": null,
        "start_date": "2022-04-04T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 921,
            "name": "Tenaya(CBRE)",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "24364b66-c922-4036-bafc-f9b54e54a934"
        },
        "parent_id": null,
        "lat": 0,
        "lon": 0,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Tenaya(CBRE)",
        "_label": "Tenaya(CBRE)",
        "_name": "Union City",
        "_lat": 0,
        "_lng": 0,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": 0
    },
    {
        "id": 1459,
        "name": "Upper Gwynedd, PA",
        "correlation_id": "10031",
        "billing_address": {
            "id": 3750,
            "street1": "351 North Sumneytown Pike",
            "street2": "",
            "city": "North Wales",
            "region": "Pennsylvania",
            "postal_code": null,
            "lat": 40.215304,
            "lon": -75.285274,
            "country": "United States"
        },
        "start_date": "2020-10-09T23:59:59Z",
        "total_sq_ft": "1700000",
        "total_buildings": "7",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 40.2146934,
        "lon": -75.2844011,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
        "is_mobile": true,
        "mobile_live_date": "2020-08-05",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "Upper Gwynedd, PA",
        "_lat": 40.2146934,
        "_lng": -75.2844011,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.2844011
    },
    {
        "id": 5875,
        "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
        "correlation_id": "102794",
        "billing_address": {
            "id": 3976,
            "street1": "3001 Miller Rd, Dearborn, MI 48120",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3083375,
            "lon": -83.1563578,
            "country": "United States"
        },
        "start_date": "2023-12-27T22:53:51Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3083375,
        "lon": -83.1563578,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
        "_lat": 42.3083375,
        "_lng": -83.1563578,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.1563578
    },
    {
        "id": 5874,
        "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
        "correlation_id": "102798",
        "billing_address": {
            "id": 3969,
            "street1": "17200 Southfield Rd",
            "street2": "",
            "city": "Allen Park",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2657473,
            "lon": -83.221329,
            "country": "United States"
        },
        "start_date": "2023-12-27T20:40:48Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2657473,
        "lon": -83.221329,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
        "_lat": 42.2657473,
        "_lng": -83.221329,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.221329
    },
    {
        "id": 5542,
        "name": "Venture Dr.*",
        "correlation_id": "102355",
        "billing_address": {
            "id": 3056,
            "street1": "2842 Venture Dr",
            "street2": "",
            "city": "Marquette",
            "region": "California",
            "postal_code": null,
            "lat": 46.5436199,
            "lon": -87.3953713,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 46.5436199,
        "lon": -87.3953713,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "9f70832b-d52e-4c66-b383-16b551c1be44",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "Venture Dr.*",
        "_lat": 46.5436199,
        "_lng": -87.3953713,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -87.3953713
    },
    {
        "id": 1329,
        "name": "Virginia Beach*",
        "correlation_id": "10338",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "2",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 36.7620677,
        "lon": -76.2329974,
        "local_timezone": {
            "id": 2212,
            "code": "America/Indianapolis",
            "display_name": "America/Indianapolis",
            "description": "",
            "child_group": null,
            "alt_sequence": 78
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "02d82a59-236e-4a68-b3c8-d5e05eae546f",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Virginia Beach*",
        "_lat": 36.7620677,
        "_lng": -76.2329974,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -76.2329974
    },
    {
        "id": 2055,
        "name": "Waco, TX",
        "correlation_id": "11667",
        "billing_address": {
            "id": 655,
            "street1": "8301 Mars D",
            "street2": "",
            "city": "Waco",
            "region": "Texas",
            "postal_code": "76712",
            "lat": 31.4865245,
            "lon": -97.2002648,
            "country": "United States"
        },
        "start_date": "2020-02-16T23:59:59Z",
        "total_sq_ft": "490000",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 31.4867828,
        "lon": -97.2037799,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
        "is_mobile": true,
        "mobile_live_date": "2021-08-04",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Waco, TX",
        "_lat": 31.4867828,
        "_lng": -97.2037799,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -97.2037799
    },
    {
        "id": 1198,
        "name": "Waukegan, IL",
        "correlation_id": "10136",
        "billing_address": {
            "id": 400,
            "street1": "1150 Northpoint Blvd",
            "street2": "",
            "city": "Waukegan",
            "region": "Illinois",
            "postal_code": "60085",
            "lat": 42.3386902,
            "lon": -87.8907436,
            "country": "United States"
        },
        "start_date": "2012-12-31T23:59:59Z",
        "total_sq_ft": "86400000",
        "total_buildings": "28",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 75,
            "name": "AbbVie",
            "type": "CUSTOMER",
            "correlation_id": "10135",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
        },
        "parent_id": null,
        "lat": 42.338588,
        "lon": -87.89054,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
        "is_mobile": true,
        "mobile_live_date": "2021-04-30",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "AbbVie",
        "_label": "AbbVie",
        "_name": "Waukegan, IL",
        "_lat": 42.338588,
        "_lng": -87.89054,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 5630176,
                "create_date": "2023-01-27T16:07:24.909099Z",
                "update_date": "2023-01-27T16:07:24.909120Z",
                "organization_id": 75,
                "uploaded_by_id": 43918,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
                "size": 5975,
                "description": null,
                "link": null,
                "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
            }
        ],
        "lng": -87.89054
    },
    {
        "id": 1464,
        "name": "Wayzata",
        "correlation_id": "10038",
        "billing_address": {
            "id": 725,
            "street1": "751 Kasota Ave SE, Minneapolis, MN 55414",
            "street2": "",
            "city": "Minneapolis",
            "region": "Minnesota",
            "postal_code": "55414",
            "lat": 44.9830256,
            "lon": -93.2168801,
            "country": "United States"
        },
        "start_date": "2014-05-01T00:00:00Z",
        "total_sq_ft": "257314",
        "total_buildings": "86",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 44.9534064,
        "lon": -93.2726714,
        "local_timezone": {
            "id": 2247,
            "code": "America/North_Dakota/Center",
            "display_name": "America/North_Dakota/Center",
            "description": "",
            "child_group": null,
            "alt_sequence": 113
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
        "is_mobile": true,
        "mobile_live_date": "2021-07-08",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "Wayzata",
        "_lat": 44.9534064,
        "_lng": -93.2726714,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.2726714
    },
    {
        "id": 5530,
        "name": "Wenatchee, WA",
        "correlation_id": "102417",
        "billing_address": {
            "id": 3005,
            "street1": "875 Urban Industrial Way",
            "street2": "",
            "city": "East Wenatchee",
            "region": "Washington",
            "postal_code": "98802",
            "lat": 47.4152516,
            "lon": -120.209875,
            "country": "United States"
        },
        "start_date": "2023-04-13T19:04:54Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 8,
            "name": "Microsoft (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11269",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
        },
        "parent_id": null,
        "lat": 47.41684,
        "lon": -120.21207,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
        "is_mobile": true,
        "mobile_live_date": "2023-04-11",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Microsoft (CBRE)",
        "_label": "Microsoft (CBRE)",
        "_name": "Wenatchee, WA",
        "_lat": 47.41684,
        "_lng": -120.21207,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853065,
                "create_date": "2020-11-14T00:05:06.333784Z",
                "update_date": "2020-11-14T00:05:06.333810Z",
                "organization_id": 8,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605312306.0531929_Artboard4_t.png",
                "size": 88382,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -120.21207
    },
    {
        "id": 5567,
        "name": "West Des Moines Campus",
        "correlation_id": "102361",
        "billing_address": {
            "id": 3779,
            "street1": "800 S Jordan Creek Pkwy.",
            "street2": "",
            "city": "West Des Moines",
            "region": "Iowa",
            "postal_code": null,
            "lat": 41.5570969,
            "lon": -93.8057542,
            "country": "United States"
        },
        "start_date": "2023-02-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "8",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 87,
            "name": "Wells Fargo",
            "type": "CUSTOMER",
            "correlation_id": "10011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
        },
        "parent_id": null,
        "lat": 41.5580141,
        "lon": -93.8076602,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
        "is_mobile": true,
        "mobile_live_date": "2023-09-12",
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo",
        "_label": "Wells Fargo",
        "_name": "West Des Moines Campus",
        "_lat": 41.5580141,
        "_lng": -93.8076602,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -93.8076602
    },
    {
        "id": 5480,
        "name": "Westerville*",
        "correlation_id": "102258",
        "billing_address": {
            "id": 3042,
            "street1": "440 Polaris Pkwy Suite 300",
            "street2": "",
            "city": "Westerville",
            "region": "Ohio",
            "postal_code": null,
            "lat": 40.1472357,
            "lon": -82.9441873,
            "country": "United States"
        },
        "start_date": "2023-03-06T22:27:53Z",
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2154,
            "name": "Cigna (CBRE) - Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
        },
        "parent_id": null,
        "lat": 40.1472357,
        "lon": -82.9441873,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ac02db2f-9bc7-49d9-9033-9ca500d61ce2",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE) - Sub",
        "_label": "Cigna (CBRE) - Sub",
        "_name": "Westerville*",
        "_lat": 40.1472357,
        "_lng": -82.9441873,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -82.9441873
    },
    {
        "id": 2686,
        "name": "West Palm Beach",
        "correlation_id": "101672",
        "billing_address": {
            "id": 845,
            "street1": "17900 Beeline Hwy",
            "street2": "",
            "city": "Jupiter",
            "region": "Florida",
            "postal_code": "33478",
            "lat": 26.9044684,
            "lon": -80.3038295,
            "country": "United States"
        },
        "start_date": "2020-04-10T08:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "50",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 504,
            "name": "Sikorsky (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 813,
                    "street1": "17900 Bee Line Hwy",
                    "street2": "",
                    "city": "Jupiter",
                    "region": "Florida",
                    "postal_code": "33478",
                    "lat": 26.9044924,
                    "lon": -80.303552,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "0b2421c4-c0b9-46f1-a856-48420d7102b1"
        },
        "parent_id": null,
        "lat": 26.9044924,
        "lon": -80.303552,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
        "is_mobile": true,
        "mobile_live_date": "2022-07-12",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Sikorsky (CBRE)",
        "_label": "Sikorsky (CBRE)",
        "_name": "West Palm Beach",
        "_lat": 26.9044924,
        "_lng": -80.303552,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -80.303552
    },
    {
        "id": 1490,
        "name": "West Point, PA",
        "correlation_id": "10076",
        "billing_address": null,
        "start_date": "2010-06-30T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "111",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 267,
            "name": "Merck (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "100011",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
        },
        "parent_id": null,
        "lat": 40.2177396,
        "lon": -75.2989916,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
        "is_mobile": true,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Merck (CBRE)",
        "_label": "Merck (CBRE)",
        "_name": "West Point, PA",
        "_lat": 40.2177396,
        "_lng": -75.2989916,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 953527,
                "create_date": "2020-12-17T16:57:21.149904Z",
                "update_date": "2020-12-17T16:57:21.149930Z",
                "organization_id": 267,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
                "size": 114753,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -75.2989916
    },
    {
        "id": 1812,
        "name": "Westridge",
        "correlation_id": "102641",
        "billing_address": null,
        "start_date": "2019-07-15T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 219,
            "name": "Cigna (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": "11525",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
        },
        "parent_id": null,
        "lat": 33.4734047,
        "lon": -112.2213862,
        "local_timezone": {
            "id": 2253,
            "code": "America/Phoenix",
            "display_name": "America/Phoenix",
            "description": "",
            "child_group": null,
            "alt_sequence": 119
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d818e965-5360-4456-a460-9d794f4588a0",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Cigna (CBRE)",
        "_label": "Cigna (CBRE)",
        "_name": "Westridge",
        "_lat": 33.4734047,
        "_lng": -112.2213862,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 859854,
                "create_date": "2020-11-17T14:38:16.374186Z",
                "update_date": "2020-11-17T14:38:16.374213Z",
                "organization_id": 219,
                "uploaded_by_id": 13851,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
                "size": 9022,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -112.2213862
    },
    {
        "id": 5743,
        "name": "WFSC*",
        "correlation_id": null,
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": null,
        "total_buildings": "0",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1649,
            "name": "Wells Fargo-Sub",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
        },
        "parent_id": null,
        "lat": 38.66143,
        "lon": -121.34702,
        "local_timezone": {
            "id": 2223,
            "code": "America/Los_Angeles",
            "display_name": "America/Los_Angeles",
            "description": "",
            "child_group": null,
            "alt_sequence": 89
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "7249f993-3d8a-46ae-bfa3-e4d777f90cf6",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Wells Fargo-Sub",
        "_label": "Wells Fargo-Sub",
        "_name": "WFSC*",
        "_lat": 38.66143,
        "_lng": -121.34702,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -121.34702
    },
    {
        "id": 3336,
        "name": "Whitehouse Station, NJ",
        "correlation_id": "102048",
        "billing_address": {
            "id": 1443,
            "street1": "202 Halls Mill Rd A",
            "street2": "",
            "city": "Richmond",
            "region": "Indiana",
            "postal_code": "08889",
            "lat": 39.8289369,
            "lon": -84.8902382,
            "country": "United States"
        },
        "start_date": "2022-05-09T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "13",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1018,
            "name": "Chubb Insurance (CBRE)",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 1442,
                    "street1": "202 Halls Mill Rd A",
                    "street2": "",
                    "city": "Whitehouse Station",
                    "region": "New Jersey",
                    "postal_code": "08889",
                    "lat": 40.6459052,
                    "lon": -74.7647032,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033"
        },
        "parent_id": null,
        "lat": 40.64583,
        "lon": -74.76436,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": "",
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Chubb Insurance (CBRE)",
        "_label": "Chubb Insurance (CBRE)",
        "_name": "Whitehouse Station, NJ",
        "_lat": 40.64583,
        "_lng": -74.76436,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -74.76436
    },
    {
        "id": 2639,
        "name": "Winter Haven",
        "correlation_id": "101554",
        "billing_address": {
            "id": 773,
            "street1": "7401 Cypress Gardens blvd",
            "street2": "",
            "city": "Winter  Haven",
            "region": "Florida",
            "postal_code": "33884",
            "lat": 27.9801345,
            "lon": -81.6506313,
            "country": "United States"
        },
        "start_date": "2022-01-21T23:59:59Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 485,
            "name": "State Farm (CBRE)",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [
                {
                    "id": 732,
                    "street1": "8225 Bent Branch Road",
                    "street2": "",
                    "city": "Irving",
                    "region": "Texas",
                    "postal_code": "75063",
                    "lat": 32.9185433,
                    "lon": -96.9908672,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
        },
        "parent_id": null,
        "lat": 28.02145,
        "lon": -81.73114,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "8cd64e8b-2467-42c2-8bb3-fe2aca91c0e9",
        "is_mobile": true,
        "mobile_live_date": "2022-01-20",
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "State Farm (CBRE)",
        "_label": "State Farm (CBRE)",
        "_name": "Winter Haven",
        "_lat": 28.02145,
        "_lng": -81.73114,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -81.73114
    },
    {
        "id": 5777,
        "name": "WOCC",
        "correlation_id": "102720",
        "billing_address": null,
        "start_date": "2023-06-30T21:35:47Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 2145,
            "name": "Coca Cola",
            "type": "CUSTOMER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
        },
        "parent_id": null,
        "lat": 33.7659218,
        "lon": -84.3947532,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Coca Cola",
        "_label": "Coca Cola",
        "_name": "WOCC",
        "_lat": 33.7659218,
        "_lng": -84.3947532,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -84.3947532
    },
    {
        "id": 2214,
        "name": "WPE – Wagner Place East – Dearborn, MI",
        "correlation_id": "102782",
        "billing_address": null,
        "start_date": "2020-11-09T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.322262,
        "lon": -83.176315,
        "local_timezone": {
            "id": 2184,
            "code": "America/Detroit",
            "display_name": "America/Detroit",
            "description": "",
            "child_group": null,
            "alt_sequence": 50
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": false,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "WPE – Wagner Place East – Dearborn, MI",
        "_lat": 42.322262,
        "_lng": -83.176315,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.176315
    },
    {
        "id": 5878,
        "name": "WPW – Wagner Place West – Dearborn, MI",
        "correlation_id": "102783",
        "billing_address": {
            "id": 3967,
            "street1": "22001 Michigan Ave",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.3058361,
            "lon": -83.2444519,
            "country": "United States"
        },
        "start_date": "2023-12-27T20:34:25Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.3058361,
        "lon": -83.2444519,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "0880b424-7af9-404e-9336-727138961123",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "WPW – Wagner Place West – Dearborn, MI",
        "_lat": 42.3058361,
        "_lng": -83.2444519,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2444519
    },
    {
        "id": 5876,
        "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
        "correlation_id": "102795",
        "billing_address": {
            "id": 3948,
            "street1": "2101 Village Rd",
            "street2": "",
            "city": "Dearborn",
            "region": "Michigan",
            "postal_code": null,
            "lat": 42.2938341,
            "lon": -83.2383704,
            "country": "United States"
        },
        "start_date": "2023-12-27T17:22:27Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2938341,
        "lon": -83.2383704,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
        "_lat": 42.2938341,
        "_lng": -83.2383704,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2383704
    },
    {
        "id": 5877,
        "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
        "correlation_id": "102796",
        "billing_address": null,
        "start_date": null,
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 315,
            "name": "Ford",
            "type": "CUSTOMER",
            "correlation_id": "315",
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
        },
        "parent_id": null,
        "lat": 42.2938341,
        "lon": -83.2383704,
        "local_timezone": {
            "id": 2243,
            "code": "America/New_York",
            "display_name": "America/New_York",
            "description": "",
            "child_group": null,
            "alt_sequence": 109
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Ford",
        "_label": "Ford",
        "_name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
        "_lat": 42.2938341,
        "_lng": -83.2383704,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [
            {
                "id": 853042,
                "create_date": "2020-11-13T23:58:46.210777Z",
                "update_date": "2020-11-13T23:58:46.210805Z",
                "organization_id": 315,
                "uploaded_by_id": 6711,
                "source_id": null,
                "type": "logo",
                "mime_type": "logo",
                "file_name": "assets/1605311926.006089_Artboard11_t.png",
                "size": 950087,
                "description": null,
                "link": null,
                "unique_identifier": null
            }
        ],
        "lng": -83.2383704
    },
    {
        "id": 4125,
        "name": "York, NE",
        "correlation_id": "102087",
        "billing_address": {
            "id": 2100,
            "street1": "2800 Division Ave.",
            "street2": "",
            "city": null,
            "region": "Nebraska",
            "postal_code": "68467",
            "lat": 40.8905217,
            "lon": -97.5963435,
            "country": "United States"
        },
        "start_date": "2022-06-01T00:00:00Z",
        "total_sq_ft": "0",
        "total_buildings": "1",
        "service_provider": {
            "id": 1,
            "name": "SBM",
            "type": "SERVICE_PROVIDER",
            "correlation_id": "10049",
            "addresses": [
                {
                    "id": 82,
                    "street1": "5241 Arnold Ave",
                    "street2": "",
                    "city": "McClellan Park",
                    "region": "CA",
                    "postal_code": "95652",
                    "lat": 38.6630216,
                    "lon": -121.3854525,
                    "country": "United States"
                }
            ],
            "status": "Unsuspended",
            "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
        },
        "customer": {
            "id": 1547,
            "name": "Collins Aerospace (CBRE)",
            "type": "SERVICE_PROVIDER",
            "correlation_id": null,
            "addresses": [],
            "status": "Unsuspended",
            "uuid": "af546d13-be70-42f2-ac60-720c0ac733cd"
        },
        "parent_id": null,
        "lat": 40.89056,
        "lon": -97.59635,
        "local_timezone": {
            "id": 2173,
            "code": "America/Chicago",
            "display_name": "America/Chicago",
            "description": "",
            "child_group": null,
            "alt_sequence": 39
        },
        "hours_upload": true,
        "has_SOW": false,
        "action_status": "Unsuspended",
        "uuid": "ee153d8f-a7fd-4c7f-b56b-e0060da0230e",
        "is_mobile": false,
        "mobile_live_date": null,
        "total_employees": 0,
        "chatbot_sms_phone_number": null,
        "chatbot_sms_country_code": "1",
        "chatbot_is_active": false,
        "use_area_links": true,
        "_client": "Collins Aerospace (CBRE)",
        "_label": "Collins Aerospace (CBRE)",
        "_name": "York, NE",
        "_lat": 40.89056,
        "_lng": -97.59635,
        "_grade": "default",
        "_marker": "drop",
        "customer_logo": [],
        "lng": -97.59635
    }
]

export const sbmSites = [
    {
      "id": 3122,
      "name": "10111 Old Placerville Rd*",
      "correlation_id": "102011",
      "billing_address": null,
      "start_date": "2022-02-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "73c6804d-6f9f-44f5-afc0-fa79b3a0fa72",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "10111 Old Placerville Rd*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5849,
      "name": "10 Exchange Place, NJ",
      "correlation_id": "102763",
      "billing_address": {
        "id": 4108,
        "street1": "10 Exchange Place",
        "street2": "",
        "city": "Jersey City",
        "region": "New Jersey",
        "postal_code": null,
        "lat": 40.7164058,
        "lon": -74.0333545,
        "country": "United States"
      },
      "start_date": "2022-04-01T17:01:19Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1018,
        "name": "Chubb Insurance (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1442,
            "street1": "202 Halls Mill Rd A",
            "street2": "",
            "city": "Whitehouse Station",
            "region": "New Jersey",
            "postal_code": "08889",
            "lat": 40.6459052,
            "lon": -74.7647032,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033"
      },
      "parent_id": null,
      "lat": 40.71662,
      "lon": -74.033523,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Chubb Insurance (CBRE)",
      "_label": "Chubb Insurance (CBRE)",
      "_name": "10 Exchange Place, NJ",
      "_lat": 40.71662,
      "_lng": -74.033523,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10588121,
          "create_date": "2024-02-09T20:02:46.893594Z",
          "update_date": "2024-02-09T20:02:46.893619Z",
          "organization_id": 1018,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
          "size": 3015,
          "description": null,
          "link": null,
          "unique_identifier": "b6e3c419c49b4746a9cdfaf251371b61"
        }
      ],
      "lng": -74.033523
    },
    {
      "id": 2812,
      "name": "1100 J Street",
      "correlation_id": "101737",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "da0ca490-b4cc-41af-805e-3e142e819bad",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "1100 J Street",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3105,
      "name": "11155 INTERNATIONAL DRIVE",
      "correlation_id": "101995",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 791,
        "name": "DOMINIC CORTESE FAMILTY PARTNERSHIP, LP",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "fe297f6a-ecdd-47ee-b59e-3f4cb7f7c8e6"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3831277-b158-4238-b6b8-0d3374bcf097",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DOMINIC CORTESE FAMILTY PARTNERSHIP, LP",
      "_label": "DOMINIC CORTESE FAMILTY PARTNERSHIP, LP",
      "_name": "11155 INTERNATIONAL DRIVE",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3104,
      "name": "11185 INTERNATIONAL DRIVE",
      "correlation_id": "101993",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 790,
        "name": "Corsair Management L.P.",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "99048ddf-78ee-4e80-ad56-4e53ca66859e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "111b5743-1366-47b5-a7d9-206f029d4b9b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corsair Management L.P.",
      "_label": "Corsair Management L.P.",
      "_name": "11185 INTERNATIONAL DRIVE",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3480,
      "name": "1119 K Street, Sacramento*",
      "correlation_id": "102525",
      "billing_address": null,
      "start_date": "2022-05-06T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 629,
        "name": "CPM Property Management",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "88e3f2bc-ca78-4537-be89-da2f8046e04b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "81e07bd7-a4cf-4017-b967-c5f424bf08c9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPM Property Management",
      "_label": "CPM Property Management",
      "_name": "1119 K Street, Sacramento*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4490,
      "name": "11333 SUNRISE PARK DR",
      "correlation_id": "101722",
      "billing_address": null,
      "start_date": "2022-08-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1812,
        "name": "BERGELECTRIC CORP",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9b82f786-732d-4c80-9e76-0ddf3cd26371"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "21c2072f-ee7a-498b-82a6-871ef96847b8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BERGELECTRIC CORP",
      "_label": "BERGELECTRIC CORP",
      "_name": "11333 SUNRISE PARK DR",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2813,
      "name": "1515 K St",
      "correlation_id": "101735",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 626,
        "name": "CAPITOL CENTRE BUILDING LLC",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e450f88f-e9ef-4187-9e20-9374cf26858f"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d6c4e745-a31b-40cd-80fd-167ced719f08",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CAPITOL CENTRE BUILDING LLC",
      "_label": "CAPITOL CENTRE BUILDING LLC",
      "_name": "1515 K St",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3108,
      "name": "1616 29th (Twenty-Nineth) St*",
      "correlation_id": "101994",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 629,
        "name": "CPM Property Management",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "88e3f2bc-ca78-4537-be89-da2f8046e04b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7fbc65f2-1da9-4bcd-9aef-f4ca307c3af6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPM Property Management",
      "_label": "CPM Property Management",
      "_name": "1616 29th (Twenty-Nineth) St*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6747,
      "name": "1633 Fairlane Circle",
      "correlation_id": "103414",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29452,
      "lon": -83.19307,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "1633 Fairlane Circle",
      "_lat": 42.29452,
      "_lng": -83.19307,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.19307
    },
    {
      "id": 3114,
      "name": "1726 28th (Twenty-Eighth) St",
      "correlation_id": "102003",
      "billing_address": null,
      "start_date": "2011-04-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 798,
        "name": "SUTTER HEALTH",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d0c9dc87-4308-4662-8f3a-db112e7e9998"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "265aa3f5-d390-41e1-9557-4fb7de7fd4fd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SUTTER HEALTH",
      "_label": "SUTTER HEALTH",
      "_name": "1726 28th (Twenty-Eighth) St",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2817,
      "name": "1825 BELL*",
      "correlation_id": "101756",
      "billing_address": {
        "id": 2503,
        "street1": "1826 Bell ST",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": null,
        "lat": 38.599005,
        "lon": -121.41075,
        "country": "United States"
      },
      "start_date": "2000-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 630,
        "name": "JB COMPANY",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "75668f97-39ed-45ab-9c37-7f7d9d5c185c"
      },
      "parent_id": null,
      "lat": 38.599005,
      "lon": -121.41075,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "148356a7-4a20-4c3e-a1ec-1e55b4859875",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "JB COMPANY",
      "_label": "JB COMPANY",
      "_name": "1825 BELL*",
      "_lat": 38.599005,
      "_lng": -121.41075,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.41075
    },
    {
      "id": 3120,
      "name": "1881 Alhambra, CalTrans*",
      "correlation_id": "102009",
      "billing_address": null,
      "start_date": "2022-02-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 629,
        "name": "CPM Property Management",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "88e3f2bc-ca78-4537-be89-da2f8046e04b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7df2982f-4776-4fb5-97f5-d1051cb4e1d0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPM Property Management",
      "_label": "CPM Property Management",
      "_name": "1881 Alhambra, CalTrans*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5681,
      "name": "2047 Lakewood Blvd*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-04-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 42.07426,
      "lon": -88.13233,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eda9d9ff-83be-4934-a5ea-468c801ea128",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "2047 Lakewood Blvd*",
      "_lat": 42.07426,
      "_lng": -88.13233,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -88.13233
    },
    {
      "id": 4488,
      "name": "2729 Prospect Park Drive",
      "correlation_id": "101480",
      "billing_address": null,
      "start_date": "2017-12-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1810,
        "name": "CITY OF RANCHO CORDOVA",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "662d86b6-e700-4cac-ba74-cea1b384e4a4"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76688ffb-235f-404c-9a6a-59c50252c224",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CITY OF RANCHO CORDOVA",
      "_label": "CITY OF RANCHO CORDOVA",
      "_name": "2729 Prospect Park Drive",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2816,
      "name": "2801 K Street",
      "correlation_id": "101741",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 629,
        "name": "CPM Property Management",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "88e3f2bc-ca78-4537-be89-da2f8046e04b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1175e84f-f7ab-4d84-a636-b4141f4873f6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPM Property Management",
      "_label": "CPM Property Management",
      "_name": "2801 K Street",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2821,
      "name": "3077 FITE CIRCLE*",
      "correlation_id": "101747",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9be2a67f-8fe6-4c92-91c1-32f1294c5e90",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "3077 FITE CIRCLE*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3123,
      "name": "3241 KILGORE",
      "correlation_id": "102012",
      "billing_address": null,
      "start_date": "2022-02-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 800,
        "name": "THE WONDERFUL REAL ESTATE CO",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "22342b08-e902-40ce-bdcc-c22e4c38623e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2f6f9949-5ce6-4c4a-bac2-48cf05072b4c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "THE WONDERFUL REAL ESTATE CO",
      "_label": "THE WONDERFUL REAL ESTATE CO",
      "_name": "3241 KILGORE",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5551,
      "name": "333 Market (JLL)",
      "correlation_id": "102367",
      "billing_address": {
        "id": 3788,
        "street1": "333 Market Street",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": null,
        "lat": 37.7915342,
        "lon": -122.3976135,
        "country": "United States"
      },
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 37.7915341,
      "lon": -122.4021035,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f7d4a594-a35d-40ca-9396-0f9db82700ff",
      "is_mobile": true,
      "mobile_live_date": "2023-09-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "333 Market (JLL)",
      "_lat": 37.7915341,
      "_lng": -122.4021035,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -122.4021035
    },
    {
      "id": 3113,
      "name": "3390 Lanatt*",
      "correlation_id": "102002",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 797,
        "name": "SARA PARTNERS",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cfbafca6-9564-40cf-a05c-d0e0421f7bee"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ad6635b-9480-440b-9e7a-f4f23ff9de1e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SARA PARTNERS",
      "_label": "SARA PARTNERS",
      "_name": "3390 Lanatt*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3503,
      "name": "3400 Data Drive",
      "correlation_id": "101721",
      "billing_address": null,
      "start_date": "2022-05-10T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1185,
        "name": "DIGNITY HEALTH",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1c4095f8-7202-4a77-a100-e75ea300e94e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "14b20d27-3370-4712-a3d3-7dc6048cd71c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DIGNITY HEALTH",
      "_label": "DIGNITY HEALTH",
      "_name": "3400 Data Drive",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5680,
      "name": "360 Binney St*",
      "correlation_id": "102549",
      "billing_address": null,
      "start_date": "2023-03-20T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 11,
        "name": "Amgen",
        "type": "CUSTOMER",
        "correlation_id": "10005",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4039c7d0-6c24-4c2d-881b-30b19567fc29"
      },
      "parent_id": null,
      "lat": 42.36712,
      "lon": -71.08935,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "23aa3d00-6ab6-4d9b-bd7c-0423e812d2dd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amgen",
      "_label": "Amgen",
      "_name": "360 Binney St*",
      "_lat": 42.36712,
      "_lng": -71.08935,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853055,
          "create_date": "2020-11-14T00:02:14.473739Z",
          "update_date": "2020-11-14T00:02:14.473766Z",
          "organization_id": 11,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312134.3328376_Artboard12_t.png",
          "size": 134976,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.08935
    },
    {
      "id": 5511,
      "name": "3rd - Brannan",
      "correlation_id": "102325",
      "billing_address": null,
      "start_date": "2021-08-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 37.779997,
      "lon": -122.3960559,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a8f911c1-11e3-4fa0-8382-2f0a79f6457b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "3rd - Brannan",
      "_lat": 37.779997,
      "_lng": -122.3960559,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -122.3960559
    },
    {
      "id": 3863,
      "name": "4235 Forcum*",
      "correlation_id": "101796",
      "billing_address": null,
      "start_date": "2022-06-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 794,
        "name": "Quest",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4042de3c-c052-4223-840e-f0fb6a294eac"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "337bc815-c2eb-4459-89d8-c5320cd58260",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Quest",
      "_label": "Quest",
      "_name": "4235 Forcum*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4489,
      "name": "5105 MANZANITA AVENUE*",
      "correlation_id": "102132",
      "billing_address": null,
      "start_date": "2022-08-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1811,
        "name": "Eskaton",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b18313c8-fece-4c52-92a2-ca214dd22645"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c734674b-9541-4920-b8d1-f87d34ef96aa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "9162842220",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eskaton",
      "_label": "Eskaton",
      "_name": "5105 MANZANITA AVENUE*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3875,
      "name": "6249 Sunrise Boulevard",
      "correlation_id": "101975",
      "billing_address": null,
      "start_date": "2022-06-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d62ca887-b2db-4583-a390-f89e6a8c7be1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "6249 Sunrise Boulevard",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6742,
      "name": "800 Republic",
      "correlation_id": "103422",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29718,
      "lon": -83.20118,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "800 Republic",
      "_lat": 42.29718,
      "_lng": -83.20118,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.20118
    },
    {
      "id": 5643,
      "name": "8th Street SF*",
      "correlation_id": "102426",
      "billing_address": null,
      "start_date": "2023-03-06T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 37.77557,
      "lon": -122.41076,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "56ca03f0-fb6a-4ca7-a731-e36b5ca66ae7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "8th Street SF*",
      "_lat": 37.77557,
      "_lng": -122.41076,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.41076
    },
    {
      "id": 3110,
      "name": "9000 FOOTHILLS*",
      "correlation_id": "101999",
      "billing_address": {
        "id": 2502,
        "street1": "9000 Foothills Blvd.",
        "street2": "",
        "city": "Roseville",
        "region": "California",
        "postal_code": null,
        "lat": 38.7909551,
        "lon": -121.3175455,
        "country": "United States"
      },
      "start_date": "2022-08-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 794,
        "name": "Quest",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4042de3c-c052-4223-840e-f0fb6a294eac"
      },
      "parent_id": null,
      "lat": 38.7909551,
      "lon": -121.3175455,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c87615e0-6b7b-4245-8f65-1967b161e8f1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Quest",
      "_label": "Quest",
      "_name": "9000 FOOTHILLS*",
      "_lat": 38.7909551,
      "_lng": -121.3175455,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3175455
    },
    {
      "id": 6038,
      "name": "90 Castilian Drive*",
      "correlation_id": "102877",
      "billing_address": null,
      "start_date": "2023-11-18T18:11:13Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 34.43336,
      "lon": -119.86164,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c84c0cbc-b094-41c7-9ed6-8e47ac16be5b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "90 Castilian Drive*",
      "_lat": 34.43336,
      "_lng": -119.86164,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.86164
    },
    {
      "id": 2832,
      "name": "9580/50/60-Micron*",
      "correlation_id": "101748",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "388387f0-2701-48c4-960d-4725e849572d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "9580/50/60-Micron*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3107,
      "name": "9750 Bus Park (Bradshaw Plaza)*",
      "correlation_id": "101997",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b5b2a5f8-19da-4374-9635-72c4c744a8cd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "9750 Bus Park (Bradshaw Plaza)*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2822,
      "name": "9833/45/51/57 Horn Rd(Wine I)*",
      "correlation_id": "101745",
      "billing_address": null,
      "start_date": "2000-06-10T14:48:44Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "571d3c6c-6167-4494-a132-0c4653495681",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "9833/45/51/57 Horn Rd(Wine I)*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1722,
      "name": "ABBO00OVHD",
      "correlation_id": "11352",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a441fd86-3785-46fc-b4cc-814808e99f41",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "ABBO00OVHD",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1197,
      "name": "Abbott Park",
      "correlation_id": "10134",
      "billing_address": {
        "id": 399,
        "street1": "100 Abbott Park Road",
        "street2": "",
        "city": "Abbott Park",
        "region": "Illinois",
        "postal_code": "60064",
        "lat": 42.3032638,
        "lon": -87.8966983,
        "country": "United States"
      },
      "start_date": "2002-01-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "14",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 42.3021141,
      "lon": -87.8934647,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "80ad3d54-a096-4709-a8e1-f00c6540ffad",
      "is_mobile": true,
      "mobile_live_date": "2021-03-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Abbott Park",
      "_lat": 42.3021141,
      "_lng": -87.8934647,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.8934647
    },
    {
      "id": 2453,
      "name": "Abilene",
      "correlation_id": "102134",
      "billing_address": {
        "id": 282,
        "street1": "3560 Catclaw Drive",
        "street2": "",
        "city": "Abilene",
        "region": "Texas",
        "postal_code": "79606-8243",
        "lat": 32.4073289,
        "lon": -99.7766574,
        "country": "United States"
      },
      "start_date": "2021-03-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2248,
        "code": "America/North_Dakota/New_Salem",
        "display_name": "America/North_Dakota/New_Salem",
        "description": "",
        "child_group": null,
        "alt_sequence": 114
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "66848b93-d530-4987-86f8-dfbc42f4be88",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Abilene",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2023,
      "name": "Adapt",
      "correlation_id": "11653",
      "billing_address": {
        "id": 3782,
        "street1": "165 Wingo Road",
        "street2": "",
        "city": "Byhalia",
        "region": "Mississippi",
        "postal_code": null,
        "lat": 34.9770717,
        "lon": -89.6260343,
        "country": "United States"
      },
      "start_date": "2020-01-06T12:00:00Z",
      "total_sq_ft": "700000",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 82,
        "name": "NIKE",
        "type": "CUSTOMER",
        "correlation_id": "10206",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "893d2ac1-1816-4f09-a9ca-c9ef9373af99"
      },
      "parent_id": null,
      "lat": 34.97714,
      "lon": -89.64037,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9992caf3-13d6-4904-a4c1-e1c4081621df",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "NIKE",
      "_label": "NIKE",
      "_name": "Adapt",
      "_lat": 34.97714,
      "_lng": -89.64037,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -89.64037
    },
    {
      "id": 1921,
      "name": "ADD - Great America",
      "correlation_id": "10803",
      "billing_address": {
        "id": 396,
        "street1": "4551 Great American Pkwy",
        "street2": "",
        "city": "Santa Clara",
        "region": "California",
        "postal_code": "95054",
        "lat": 37.3954544,
        "lon": -121.9768295,
        "country": "United States"
      },
      "start_date": "2016-11-01T07:00:00Z",
      "total_sq_ft": "130000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.395402,
      "lon": -121.976608,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "65d2a98e-4028-4292-88c7-ff651b0bd6d6",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "ADD - Great America",
      "_lat": 37.395402,
      "_lng": -121.976608,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.976608
    },
    {
      "id": 6368,
      "name": "ADHOC Facade*",
      "correlation_id": "103364",
      "billing_address": null,
      "start_date": "2023-12-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2503,
        "name": "Agilent (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bc719723-2f3b-404e-9deb-430e98c565dc"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "02678b0f-22af-4398-9178-a14a852dbd28",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Agilent (JLL)",
      "_label": "Agilent (JLL)",
      "_name": "ADHOC Facade*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3143,
      "name": "Admedes, Cartago",
      "correlation_id": "101913",
      "billing_address": {
        "id": 1300,
        "street1": "Zona Franca Z",
        "street2": "",
        "city": "Tejar Cartago",
        "region": "Alabama",
        "postal_code": "30106",
        "lat": 9.8448839,
        "lon": -83.9453168,
        "country": "Costa Rica"
      },
      "start_date": "2022-03-08T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 820,
        "name": "Admedes (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1297,
            "street1": "Parque Industrial Zeta",
            "street2": "",
            "city": "Cartago",
            "region": "Cartago",
            "postal_code": "30111",
            "lat": 9.8682919,
            "lon": -83.948741,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "94a5cd08-b14b-4080-96d4-ecf8726d3b0e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d84524e9-9bce-40df-b66b-dc88edd415c3",
      "is_mobile": true,
      "mobile_live_date": "2022-06-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Admedes (CR)",
      "_label": "Admedes (CR)",
      "_name": "Admedes, Cartago",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5851,
      "name": "AEC – Advanced Electrification Center – Dearborn, MI",
      "correlation_id": "102804",
      "billing_address": null,
      "start_date": "2020-11-09T14:49:12Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "AEC – Advanced Electrification Center – Dearborn, MI",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2569,
      "name": "AFZ",
      "correlation_id": "101523",
      "billing_address": {
        "id": 556,
        "street1": "Zona Franca America",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40104",
        "lat": 32.3182314,
        "lon": -86.902298,
        "country": "United States"
      },
      "start_date": "2021-12-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 466,
        "name": "Citrix (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 555,
            "street1": "Zona Franca America",
            "street2": "",
            "city": "Heredia",
            "region": "Costa rica",
            "postal_code": "40104",
            "lat": 9.9884318,
            "lon": -84.1613195,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "d0f6e527-cdb7-49c8-b303-6bf14d96fb8c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0bd6ee3f-0a2f-469c-8339-9337276d4c39",
      "is_mobile": true,
      "mobile_live_date": "2021-11-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Citrix (CR)",
      "_label": "Citrix (CR)",
      "_name": "AFZ",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1594,
      "name": "AFZ (JLL)",
      "correlation_id": "11135",
      "billing_address": {
        "id": 17,
        "street1": "America Free Zone Park Building C10",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 10.473523,
        "lon": -84.0167423,
        "country": "Costa Rica"
      },
      "start_date": "2018-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 166,
        "name": "HP (CR) (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11134",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "96cd1803-d40b-49b0-8659-4398d6bf79ae"
      },
      "parent_id": null,
      "lat": 9.9884318,
      "lon": -84.1613195,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "30af7c16-37e9-4f9a-8f3e-f9272551dc03",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP (CR) (JLL)",
      "_label": "HP (CR) (JLL)",
      "_name": "AFZ (JLL)",
      "_lat": 9.9884318,
      "_lng": -84.1613195,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10771736,
          "create_date": "2024-02-21T22:19:37.772047Z",
          "update_date": "2024-02-21T22:19:37.772086Z",
          "organization_id": 166,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/944f1c217f944a21af0f2d59fed6f779_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "944f1c217f944a21af0f2d59fed6f779"
        }
      ],
      "lng": -84.1613195
    },
    {
      "id": 6741,
      "name": "Air 7 - Janitorial*",
      "correlation_id": "103410",
      "billing_address": null,
      "start_date": "2024-02-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2603,
        "name": "Alfatech",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7f07c5c1-7f8a-40d7-97d7-3af2fc86eefa"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7d1346af-6e3d-4e5a-9126-a74f192e4805",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Alfatech",
      "_label": "Alfatech",
      "_name": "Air 7 - Janitorial*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1899,
      "name": "Alameda",
      "correlation_id": "10762",
      "billing_address": null,
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "192000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.7223684,
      "lon": -122.2355309,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2853f526-a476-4261-b41b-25605d4fe7d8",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Alameda",
      "_lat": 37.7223684,
      "_lng": -122.2355309,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.2355309
    },
    {
      "id": 1980,
      "name": "Alameda, CA (JLL)",
      "correlation_id": "11586",
      "billing_address": {
        "id": 457,
        "street1": "5099 Hyland Ave",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": "95127",
        "lat": 37.376771,
        "lon": -121.8239657,
        "country": "United States"
      },
      "start_date": "2019-09-03T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 37.786106,
      "lon": -122.318258,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "65418407-5a66-49bc-a50a-ecfec77fc767",
      "is_mobile": true,
      "mobile_live_date": "2021-03-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Alameda, CA (JLL)",
      "_lat": 37.786106,
      "_lng": -122.318258,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.318258
    },
    {
      "id": 2623,
      "name": "Albany, OH",
      "correlation_id": "101564",
      "billing_address": {
        "id": 735,
        "street1": "5400 New Albany Rd E",
        "street2": "",
        "city": "New Albany",
        "region": "Ohio",
        "postal_code": "43054",
        "lat": 40.0949648,
        "lon": -82.817737,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 40.09347,
      "lon": -82.82072,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cb4abfa7-fddc-4aac-872c-7bd811c6fc36",
      "is_mobile": true,
      "mobile_live_date": "2022-09-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Albany, OH",
      "_lat": 40.09347,
      "_lng": -82.82072,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -82.82072
    },
    {
      "id": 5832,
      "name": "Albuquerque, NM",
      "correlation_id": "102757",
      "billing_address": null,
      "start_date": "2023-09-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 35.1067,
      "lon": -106.56872,
      "local_timezone": {
        "id": 2235,
        "code": "America/Mexico_City",
        "display_name": "America/Mexico_City",
        "description": "",
        "child_group": null,
        "alt_sequence": 101
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "51c61537-a9ef-4a11-a4bd-52081aaf2b45",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Albuquerque, NM",
      "_lat": 35.1067,
      "_lng": -106.56872,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -106.56872
    },
    {
      "id": 1476,
      "name": "Aloha",
      "correlation_id": "101824",
      "billing_address": {
        "id": 102,
        "street1": "3585 SW 198th Ave",
        "street2": "",
        "city": "Beaverton",
        "region": "Oregon",
        "postal_code": "97078",
        "lat": 45.4933196,
        "lon": -122.8834,
        "country": "United States"
      },
      "start_date": "2021-03-12T23:59:59Z",
      "total_sq_ft": "954574",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 45.49311,
      "lon": -122.88405,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f26dc2a8-6411-4a02-a38c-8733dfc04d38",
      "is_mobile": true,
      "mobile_live_date": "2020-11-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Aloha",
      "_lat": 45.49311,
      "_lng": -122.88405,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.88405
    },
    {
      "id": 2632,
      "name": "Alpharetta, GA",
      "correlation_id": "101577",
      "billing_address": {
        "id": 3027,
        "street1": "3000 Marconi Dr",
        "street2": "",
        "city": "Alpharetta",
        "region": "Georgia",
        "postal_code": null,
        "lat": 34.0849895,
        "lon": -84.2458835,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 34.08514,
      "lon": -84.24585,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Alpharetta, GA",
      "_lat": 34.08514,
      "_lng": -84.24585,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -84.24585
    },
    {
      "id": 1830,
      "name": "Altavista",
      "correlation_id": "10623",
      "billing_address": {
        "id": 516,
        "street1": "Main St",
        "street2": "",
        "city": "Altavista",
        "region": "Virginia",
        "postal_code": "24517",
        "lat": 37.1219479,
        "lon": -79.2739307,
        "country": "United States"
      },
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "10500000",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.1328551,
      "lon": -79.2665472,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "195ee448-5d27-496b-8dea-ed76519308ca",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Altavista",
      "_lat": 37.1328551,
      "_lng": -79.2665472,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -79.2665472
    },
    {
      "id": 2113,
      "name": "Altera",
      "correlation_id": "101367",
      "billing_address": null,
      "start_date": "2017-08-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aac5d2dd-c656-46e6-9f5b-c2f4eb3f94a7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Altera",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 7028,
      "name": "Altera NC*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-23T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b891c877-8d46-45f7-a27e-3cdae5cdd2e3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Altera NC*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2697,
      "name": "Altos RC",
      "correlation_id": "101815",
      "billing_address": {
        "id": 822,
        "street1": "1300 Island Dr",
        "street2": "",
        "city": "redwood City",
        "region": "CA",
        "postal_code": "94065",
        "lat": 37.5372665,
        "lon": -122.2558618,
        "country": "United States"
      },
      "start_date": "2021-12-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 557,
        "name": "Altos Labs",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 822,
            "street1": "1300 Island Dr",
            "street2": "",
            "city": "redwood City",
            "region": "CA",
            "postal_code": "94065",
            "lat": 37.5372665,
            "lon": -122.2558618,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "9e39ba08-4354-4afb-85fa-340e9b4d2f71"
      },
      "parent_id": null,
      "lat": 37.5372665,
      "lon": -122.2558618,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "22fbdfdc-0a5e-43fd-890b-feb73eaf347e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Altos Labs",
      "_label": "Altos Labs",
      "_name": "Altos RC",
      "_lat": 37.5372665,
      "_lng": -122.2558618,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.2558618
    },
    {
      "id": 2252,
      "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
      "correlation_id": "101246",
      "billing_address": {
        "id": 205,
        "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": "48124",
        "lat": 42.2941466,
        "lon": -83.2271782,
        "country": "United States"
      },
      "start_date": "2021-09-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": 5847,
      "lat": 42.3794728,
      "lon": -83.2885154,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
      "is_mobile": true,
      "mobile_live_date": "2021-03-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
      "_lat": 42.3794728,
      "_lng": -83.2885154,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2885154
    },
    {
      "id": 4823,
      "name": "Ames, IA",
      "correlation_id": "102220",
      "billing_address": {
        "id": 2703,
        "street1": "2800 13th St.",
        "street2": "",
        "city": "Ames",
        "region": "Iowa",
        "postal_code": "50010",
        "lat": 42.0323055,
        "lon": -93.5733607,
        "country": "United States"
      },
      "start_date": "2022-10-17T20:53:34Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2077,
        "name": "Amentum Danfoss Power Solutions",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 2702,
            "street1": "2800 E 13th St.",
            "street2": "",
            "city": "Ames",
            "region": "Iowa",
            "postal_code": "50010",
            "lat": 42.0323055,
            "lon": -93.5733607,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "146ba0cf-b9bd-45ef-be7c-64ad3b2e4568"
      },
      "parent_id": null,
      "lat": 42.03198,
      "lon": -93.57385,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d8b3e5b1-5cfd-49c2-baf4-7399e08769c2",
      "is_mobile": true,
      "mobile_live_date": "2022-12-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amentum Danfoss Power Solutions",
      "_label": "Amentum Danfoss Power Solutions",
      "_name": "Ames, IA",
      "_lat": 42.03198,
      "_lng": -93.57385,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686598,
          "create_date": "2024-02-16T12:11:27.224321Z",
          "update_date": "2024-02-16T12:11:27.224348Z",
          "organization_id": 2077,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/36e9d56bb0ab4530981389a811066e0e_AmentumIconFullNameGreen_t.png",
          "size": 9669,
          "description": null,
          "link": null,
          "unique_identifier": "36e9d56bb0ab4530981389a811066e0e"
        }
      ],
      "lng": -93.57385
    },
    {
      "id": 5907,
      "name": "AMS Project*",
      "correlation_id": "102817",
      "billing_address": null,
      "start_date": "2023-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 44.58404,
      "lon": -123.24423,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "67ce0f4c-eb00-44ac-854d-6dfb6c8f111e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "AMS Project*",
      "_lat": 44.58404,
      "_lng": -123.24423,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -123.24423
    },
    {
      "id": 5785,
      "name": "AM Tech - Mesa AZ*",
      "correlation_id": "102714",
      "billing_address": null,
      "start_date": "2023-06-21T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2074,
        "name": "AM Technical Solutions",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "95002b8f-1e19-451d-a169-d32c9d3a57c3"
      },
      "parent_id": null,
      "lat": 33.29646,
      "lon": -111.59263,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aad47679-398b-4ebc-bf96-8b0bd6e5a92b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AM Technical Solutions",
      "_label": "AM Technical Solutions",
      "_name": "AM Tech - Mesa AZ*",
      "_lat": 33.29646,
      "_lng": -111.59263,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -111.59263
    },
    {
      "id": 5912,
      "name": "Anasco, PR",
      "correlation_id": "102819",
      "billing_address": null,
      "start_date": "2024-03-01T23:34:02Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.2923154,
      "lon": -67.1395343,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d82f8bb8-c4e0-44fd-8f97-3468e8c52410",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Anasco, PR",
      "_lat": 18.2923154,
      "_lng": -67.1395343,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -67.1395343
    },
    {
      "id": 1659,
      "name": "Ann Arbor",
      "correlation_id": "11243",
      "billing_address": {
        "id": 682,
        "street1": "6300 Interfirst Dr. Ann Arbor MI 48108",
        "street2": "",
        "city": "Ann Arbor",
        "region": "Michigan",
        "postal_code": "48108",
        "lat": 42.196069,
        "lon": -83.726844,
        "country": "United States"
      },
      "start_date": "2018-08-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 189,
        "name": "Thomson Reuters (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11242",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "58187e40-8904-4863-a736-34015c559cce"
      },
      "parent_id": null,
      "lat": 44.3148443,
      "lon": -85.6023643,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "810922a8-7797-4293-86d7-9242a3a9649e",
      "is_mobile": true,
      "mobile_live_date": "2021-08-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Thomson Reuters (JLL)",
      "_label": "Thomson Reuters (JLL)",
      "_name": "Ann Arbor",
      "_lat": 44.3148443,
      "_lng": -85.6023643,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -85.6023643
    },
    {
      "id": 2310,
      "name": "Ann Arbor - HEL",
      "correlation_id": "101317",
      "billing_address": {
        "id": 90,
        "street1": "2420 Oak Valley Dr",
        "street2": "",
        "city": "Ann Arbor",
        "region": "Michigan",
        "postal_code": "48103",
        "lat": 42.2511196,
        "lon": -83.7743094,
        "country": "United States"
      },
      "start_date": "2021-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 347,
        "name": "Honda",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 87,
            "street1": "2420 Oak Valley Dr",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48103",
            "lat": 42.2511196,
            "lon": -83.7743094,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bc0f895a-4b0d-4954-9fec-348b76f6ace1"
      },
      "parent_id": null,
      "lat": 42.2511196,
      "lon": -83.7743094,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b9d2f654-0484-4678-9301-1ecfff8efe0d",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Honda",
      "_label": "Honda",
      "_name": "Ann Arbor - HEL",
      "_lat": 42.2511196,
      "_lng": -83.7743094,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.7743094
    },
    {
      "id": 2594,
      "name": "Ann Arbor, MI",
      "correlation_id": "102148",
      "billing_address": {
        "id": 663,
        "street1": "3953 Research Park Drive",
        "street2": "",
        "city": "Ann Arbor",
        "region": "Michigan",
        "postal_code": "48108",
        "lat": 42.2325395,
        "lon": -83.7312863,
        "country": "United States"
      },
      "start_date": "2021-09-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 474,
        "name": "Mercedes Benz*",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 658,
            "street1": "3953 Research Park DR",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48108",
            "lat": 42.2325395,
            "lon": -83.7312863,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "ed2274bd-8d09-4e81-a765-5d290ae3267d"
      },
      "parent_id": null,
      "lat": 42.2324513,
      "lon": -83.7320142,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3220c67-1299-49b2-abb6-6be73946689f",
      "is_mobile": true,
      "mobile_live_date": "2022-04-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Mercedes Benz*",
      "_label": "Mercedes Benz*",
      "_name": "Ann Arbor, MI",
      "_lat": 42.2324513,
      "_lng": -83.7320142,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.7320142
    },
    {
      "id": 1077,
      "name": "Ann Arbor, MI (JLL)",
      "correlation_id": "10859",
      "billing_address": {
        "id": 217,
        "street1": "2300 Traverwood Dr.",
        "street2": "",
        "city": "Ann Arbor",
        "region": "Michigan",
        "postal_code": "48105",
        "lat": 42.306724,
        "lon": -83.7132924,
        "country": "United States"
      },
      "start_date": "2017-03-06T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 42.2511196,
      "lon": -83.7743094,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f2813785-eee2-4b5f-8273-07ad4243e511",
      "is_mobile": true,
      "mobile_live_date": "2021-02-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Ann Arbor, MI (JLL)",
      "_lat": 42.2511196,
      "_lng": -83.7743094,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.7743094
    },
    {
      "id": 5775,
      "name": "AOC",
      "correlation_id": "102624",
      "billing_address": {
        "id": 3629,
        "street1": "1 Coca Cola Pl SE",
        "street2": "",
        "city": "Atlanta",
        "region": "Georgia",
        "postal_code": null,
        "lat": 33.7711748,
        "lon": -84.3972825,
        "country": "United States"
      },
      "start_date": "2023-07-01T21:35:03Z",
      "total_sq_ft": "0",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
      },
      "parent_id": null,
      "lat": 33.7709481,
      "lon": -84.3971999,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Coca Cola",
      "_label": "Coca Cola",
      "_name": "AOC",
      "_lat": 33.7709481,
      "_lng": -84.3971999,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10560545,
          "create_date": "2024-02-08T17:24:10.318130Z",
          "update_date": "2024-02-08T17:24:10.318155Z",
          "organization_id": 2145,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
          "size": 22904,
          "description": null,
          "link": null,
          "unique_identifier": "9182f361e2ff41d3bded657bde583842"
        }
      ],
      "lng": -84.3971999
    },
    {
      "id": 7064,
      "name": "APAC Centre-SG*",
      "correlation_id": "103435",
      "billing_address": null,
      "start_date": "2024-04-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2800,
        "name": "Phillips (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "32cfc5a2-f8af-4bf7-a900-a2364e3ecc26"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3c8f175d-6bf2-4a7e-b77b-d5aaf248b629",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Phillips (CBRE)",
      "_label": "Phillips (CBRE)",
      "_name": "APAC Centre-SG*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5687,
      "name": "Apache Junction",
      "correlation_id": "102622",
      "billing_address": {
        "id": 3739,
        "street1": "2870 West Apache Trail",
        "street2": "",
        "city": "Apache Junction",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.4162348,
        "lon": -111.5772852,
        "country": "United States"
      },
      "start_date": "2023-03-29T20:24:25Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.41617,
      "lon": -111.57709,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "491e8f78-3149-4575-b854-eb644c6daa09",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Apache Junction",
      "_lat": 33.41617,
      "_lng": -111.57709,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -111.57709
    },
    {
      "id": 2243,
      "name": "Aperia",
      "correlation_id": "100605",
      "billing_address": null,
      "start_date": "2017-08-08T00:00:00Z",
      "total_sq_ft": "60000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 317,
        "name": "Intel (SG) (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "100605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6903ae7d-0c0d-4d74-920e-dab0afab1a1e"
      },
      "parent_id": null,
      "lat": 1.303548,
      "lon": 103.879809,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "75c5c6c4-4a74-48ca-af5a-7282d811e9ac",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel (SG) (JLL)",
      "_label": "Intel (SG) (JLL)",
      "_name": "Aperia",
      "_lat": 1.303548,
      "_lng": 103.879809,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.879809
    },
    {
      "id": 3630,
      "name": "APOC*",
      "correlation_id": "101000",
      "billing_address": null,
      "start_date": "2022-05-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 258,
        "name": "Microsoft (JLL)(SG)",
        "type": "CUSTOMER",
        "correlation_id": "11583",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3bd4e4b3-5abf-45c6-b9d9-4c023ac0df1f"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e0a135df-4e1a-4883-8dfa-245c5f7714fb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (JLL)(SG)",
      "_label": "Microsoft (JLL)(SG)",
      "_name": "APOC*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2497,
      "name": "Arcadia, CA",
      "correlation_id": "102135",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 34.1398288,
      "lon": -118.0290769,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "329b2597-49bc-4e32-adc6-a272bd7b3be0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Arcadia, CA",
      "_lat": 34.1398288,
      "_lng": -118.0290769,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -118.0290769
    },
    {
      "id": 2048,
      "name": "Arden Fair Mall (JLL)",
      "correlation_id": "11702",
      "billing_address": {
        "id": 726,
        "street1": "1510 Arden Way",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": "95833",
        "lat": 38.6004794,
        "lon": -121.4301923,
        "country": "United States"
      },
      "start_date": "2015-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 38.65311,
      "lon": -121.47709,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4ac8e080-e0ad-438c-bd4b-429fab6acc2b",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Arden Fair Mall (JLL)",
      "_lat": 38.65311,
      "_lng": -121.47709,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -121.47709
    },
    {
      "id": 5568,
      "name": "Arden Hills, MN*",
      "correlation_id": "102401",
      "billing_address": null,
      "start_date": "2023-01-30T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2103,
        "name": "Boston Scientific",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cda77a91-b61f-4ef1-b74d-644148e34274"
      },
      "parent_id": null,
      "lat": 45.068403,
      "lon": -93.1509771,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "465cbf15-ee9e-4633-8b02-0ff12b939d48",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boston Scientific",
      "_label": "Boston Scientific",
      "_name": "Arden Hills, MN*",
      "_lat": 45.068403,
      "_lng": -93.1509771,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -93.1509771
    },
    {
      "id": 5763,
      "name": "ARI Gap Analysis*",
      "correlation_id": "102604",
      "billing_address": null,
      "start_date": "2023-06-06T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 11,
        "name": "Amgen",
        "type": "CUSTOMER",
        "correlation_id": "10005",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4039c7d0-6c24-4c2d-881b-30b19567fc29"
      },
      "parent_id": null,
      "lat": 41.6577,
      "lon": -71.56948,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "32ace974-27ab-420f-aa3f-ddce6368aea6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amgen",
      "_label": "Amgen",
      "_name": "ARI Gap Analysis*",
      "_lat": 41.6577,
      "_lng": -71.56948,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853055,
          "create_date": "2020-11-14T00:02:14.473739Z",
          "update_date": "2020-11-14T00:02:14.473766Z",
          "organization_id": 11,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312134.3328376_Artboard12_t.png",
          "size": 134976,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.56948
    },
    {
      "id": 5906,
      "name": "ARI TRUE Project*",
      "correlation_id": "102816",
      "billing_address": null,
      "start_date": "2023-11-02T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 11,
        "name": "Amgen",
        "type": "CUSTOMER",
        "correlation_id": "10005",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4039c7d0-6c24-4c2d-881b-30b19567fc29"
      },
      "parent_id": null,
      "lat": 41.6577,
      "lon": -71.56948,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "515025b4-9457-4b9e-a557-247dfcb16c5e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amgen",
      "_label": "Amgen",
      "_name": "ARI TRUE Project*",
      "_lat": 41.6577,
      "_lng": -71.56948,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853055,
          "create_date": "2020-11-14T00:02:14.473739Z",
          "update_date": "2020-11-14T00:02:14.473766Z",
          "organization_id": 11,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312134.3328376_Artboard12_t.png",
          "size": 134976,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.56948
    },
    {
      "id": 2739,
      "name": "Arkansas City, KS - Engine Services",
      "correlation_id": "101610",
      "billing_address": {
        "id": 1507,
        "street1": "7577 4th Ave",
        "street2": "",
        "city": "Arkansas City",
        "region": "Kansas",
        "postal_code": null,
        "lat": 37.0619736,
        "lon": -97.0383712,
        "country": "United States"
      },
      "start_date": "2021-07-06T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 37.16907,
      "lon": -97.0314,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2b3eefd2-56d4-4936-a80f-fb8dfddcbc1b",
      "is_mobile": true,
      "mobile_live_date": "2022-04-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Arkansas City, KS - Engine Services",
      "_lat": 37.16907,
      "_lng": -97.0314,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -97.0314
    },
    {
      "id": 1800,
      "name": "Arrowhead",
      "correlation_id": "102701",
      "billing_address": {
        "id": 3740,
        "street1": "21731 N. 77th Avenue",
        "street2": "",
        "city": "Peoria",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.6810115,
        "lon": -112.223266,
        "country": "United States"
      },
      "start_date": "2023-03-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.6810115,
      "lon": -112.223266,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "309d2a98-4019-4aec-b07a-27da30b0af9f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Arrowhead",
      "_lat": 33.6810115,
      "_lng": -112.223266,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -112.223266
    },
    {
      "id": 2140,
      "name": "Ascent",
      "correlation_id": "101233",
      "billing_address": null,
      "start_date": "2019-08-01T00:00:00Z",
      "total_sq_ft": "38750",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 302,
        "name": "Firmenich SIngapore (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100992",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "06a586b8-2bf0-4a27-8493-01675f71ef60"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2386,
        "code": "Asia/Kabul",
        "display_name": "Asia/Kabul",
        "description": "",
        "child_group": null,
        "alt_sequence": 252
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8d0f4e6f-e778-4550-a2bc-097745f9542e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Firmenich SIngapore (CBRE)",
      "_label": "Firmenich SIngapore (CBRE)",
      "_name": "Ascent",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918809,
          "create_date": "2020-12-07T22:05:57.541190Z",
          "update_date": "2020-12-07T22:05:57.541215Z",
          "organization_id": 302,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607378757.4569967_Firmenich_t.jpg",
          "size": 3634,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 4836,
      "name": "Asheville, NC*",
      "correlation_id": "102208",
      "billing_address": {
        "id": 3964,
        "street1": "GC38+XX",
        "street2": "",
        "city": "Asheville",
        "region": "North Carolina",
        "postal_code": null,
        "lat": 35.5049375,
        "lon": -82.5825625,
        "country": "United States"
      },
      "start_date": "2023-12-27T20:26:33Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 438,
        "name": "Pratt & Whitney (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "101371",
        "addresses": [
          {
            "id": 273,
            "street1": "17900 Bee Line HWY",
            "street2": "",
            "city": "Jupitor",
            "region": "Florida",
            "postal_code": "33478",
            "lat": 26.9047849,
            "lon": -80.3040239,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "250a2c64-7e26-4b6d-8f36-167c56bcee3d"
      },
      "parent_id": null,
      "lat": 35.5049375,
      "lon": -82.5825625,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Pratt & Whitney (CBRE)",
      "_label": "Pratt & Whitney (CBRE)",
      "_name": "Asheville, NC*",
      "_lat": 35.5049375,
      "_lng": -82.5825625,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10673771,
          "create_date": "2024-02-15T17:15:48.453287Z",
          "update_date": "2024-02-15T17:15:48.453311Z",
          "organization_id": 438,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
          "size": 5217,
          "description": null,
          "link": null,
          "unique_identifier": "789a82307f294d5ea7c748b88c882565"
        }
      ],
      "lng": -82.5825625
    },
    {
      "id": 2095,
      "name": "Ash Tower",
      "correlation_id": "101161",
      "billing_address": {
        "id": 752,
        "street1": "888 South Harrison",
        "street2": "",
        "city": "Fort wayne",
        "region": "Indiana",
        "postal_code": "46802",
        "lat": 41.0782856,
        "lon": -85.1411015,
        "country": "United States"
      },
      "start_date": "2013-08-31T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 289,
        "name": "Ash Brokerage",
        "type": "CUSTOMER",
        "correlation_id": "100006",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f61dea58-8e9a-4a98-beae-997f7034422b"
      },
      "parent_id": null,
      "lat": 41.0611916,
      "lon": -85.1413791,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ebdcb521-78bb-46aa-8d2d-8cc87fb50a79",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ash Brokerage",
      "_label": "Ash Brokerage",
      "_name": "Ash Tower",
      "_lat": 41.0611916,
      "_lng": -85.1413791,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -85.1413791
    },
    {
      "id": 2006,
      "name": "Atlanta,GA",
      "correlation_id": "11595",
      "billing_address": {
        "id": 4107,
        "street1": "110 Kendall Park Lane",
        "street2": "",
        "city": "Atlanta",
        "region": "Georgia",
        "postal_code": null,
        "lat": 33.6932413,
        "lon": -84.6044098,
        "country": "United States"
      },
      "start_date": "2019-09-27T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 33.8935894,
      "lon": -84.2665825,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "919d005b-c16a-4c56-845c-4168a00d75d9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Atlanta,GA",
      "_lat": 33.8935894,
      "_lng": -84.2665825,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2665825
    },
    {
      "id": 3477,
      "name": "Atlanta, GA* (JLL)",
      "correlation_id": "101959",
      "billing_address": null,
      "start_date": "2022-05-06T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 22,
        "name": "Volkswagen*",
        "type": "CUSTOMER",
        "correlation_id": "10842",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cc427051-7ee5-4efa-85f3-0f55f668e146"
      },
      "parent_id": null,
      "lat": 33.9076092,
      "lon": -84.29138,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3ca0be4-7793-4133-843b-0121de31fcbc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Volkswagen*",
      "_label": "Volkswagen*",
      "_name": "Atlanta, GA* (JLL)",
      "_lat": 33.9076092,
      "_lng": -84.29138,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.29138
    },
    {
      "id": 5781,
      "name": "Atlanta Reservation",
      "correlation_id": "102745",
      "billing_address": null,
      "start_date": "2021-04-01T14:11:10Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2d89985e-a53a-4b04-8e4d-a78616a05936",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "Atlanta Reservation",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5807,
      "name": "ATL Augusta BOCC",
      "correlation_id": "102734",
      "billing_address": null,
      "start_date": "2021-04-01T14:12:02Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fb4a4178-18f5-4f64-ac64-23c872820f74",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "ATL Augusta BOCC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2375,
      "name": "ATL Base (Atlanta, GA)",
      "correlation_id": "101345",
      "billing_address": {
        "id": 153,
        "street1": "1030 Delta Blvd",
        "street2": "",
        "city": "Atlanta",
        "region": "Georgia",
        "postal_code": "30354",
        "lat": 33.6562452,
        "lon": -84.4242419,
        "country": "Afghanistan"
      },
      "start_date": "2021-04-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "85",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 33.65601,
      "lon": -84.42254,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4150bfb7-6029-41f8-a046-7515d331f808",
      "is_mobile": true,
      "mobile_live_date": "2021-07-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "ATL Base (Atlanta, GA)",
      "_lat": 33.65601,
      "_lng": -84.42254,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.42254
    },
    {
      "id": 2336,
      "name": "Auburn",
      "correlation_id": "101323",
      "billing_address": {
        "id": 112,
        "street1": "360 Grant Ave",
        "street2": "",
        "city": "Auburn",
        "region": "New York",
        "postal_code": "13021",
        "lat": 42.9630414,
        "lon": -76.5384253,
        "country": "Afghanistan"
      },
      "start_date": "2021-01-08T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 42.9630414,
      "lon": -76.5384253,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "60b2bd31-7d7e-45c0-955d-e3ca3cb45912",
      "is_mobile": true,
      "mobile_live_date": "2021-04-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Auburn",
      "_lat": 42.9630414,
      "_lng": -76.5384253,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -76.5384253
    },
    {
      "id": 2546,
      "name": "Aurora",
      "correlation_id": "101658",
      "billing_address": {
        "id": 532,
        "street1": "2353 Porospect Dr.",
        "street2": "",
        "city": "Aurora",
        "region": "Illinois",
        "postal_code": "60502",
        "lat": 41.8035124,
        "lon": -88.238892,
        "country": "United States"
      },
      "start_date": "2021-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 41.8035,
      "lon": -88.23762,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "15cd519f-1e4b-4706-9109-399ba283dfde",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Aurora",
      "_lat": 41.8035,
      "_lng": -88.23762,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -88.23762
    },
    {
      "id": 1834,
      "name": "Austin",
      "correlation_id": "101837",
      "billing_address": {
        "id": 2198,
        "street1": "1300 S MoPac Expy",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": "78746",
        "lat": 30.2620019,
        "lon": -97.794953,
        "country": "United States"
      },
      "start_date": "2021-03-12T07:00:00Z",
      "total_sq_ft": "453235",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 30.262352,
      "lon": -97.793346,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "78449d15-4a81-4287-9be5-c56e3356ed72",
      "is_mobile": true,
      "mobile_live_date": "2020-07-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Austin",
      "_lat": 30.262352,
      "_lng": -97.793346,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -97.793346
    },
    {
      "id": 2450,
      "name": "Austin Congress",
      "correlation_id": "102137",
      "billing_address": {
        "id": 279,
        "street1": "501 Congress Ave., Suite 125",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": "78701-3568",
        "lat": 30.2672199,
        "lon": -97.7427138,
        "country": "United States"
      },
      "start_date": "2021-03-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d54d335f-c294-4fdc-8313-03a1c4ff4527",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Austin Congress",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1961,
      "name": "Austin,TX",
      "correlation_id": "11565",
      "billing_address": {
        "id": 1243,
        "street1": "607 W 3rd St",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": "78703",
        "lat": 30.2667619,
        "lon": -97.7500735,
        "country": "United States"
      },
      "start_date": "2023-04-10T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 30.2667619,
      "lon": -97.7500735,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "773db8f4-6ac1-4e0c-8d7c-42421b8e2ca9",
      "is_mobile": true,
      "mobile_live_date": "2022-02-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Austin,TX",
      "_lat": 30.2667619,
      "_lng": -97.7500735,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -97.7500735
    },
    {
      "id": 3850,
      "name": "Austin, TX",
      "correlation_id": "102066",
      "billing_address": {
        "id": 1976,
        "street1": "7171 Southwest Pkwy",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": null,
        "lat": 30.251601,
        "lon": -97.86112,
        "country": "United States"
      },
      "start_date": "2022-06-24T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1421,
        "name": "AMD",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6b732b3c-6589-46e7-b94f-815108dc3f98"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "09d53eda-b90e-4692-a6d7-b4d60fa8451e",
      "is_mobile": true,
      "mobile_live_date": "2022-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AMD",
      "_label": "AMD",
      "_name": "Austin, TX",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5548,
      "name": "Austin, TX",
      "correlation_id": "102373",
      "billing_address": {
        "id": 3566,
        "street1": "13101 Tesla Rd.",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": null,
        "lat": 30.217533,
        "lon": -97.628702,
        "country": "United States"
      },
      "start_date": "2023-02-08T22:28:21Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2102,
        "name": "Tesla",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c36f3839-8ffa-4e46-a856-acbdbe086c22"
      },
      "parent_id": null,
      "lat": 30.22764,
      "lon": -97.61648,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7cf3dcd7-d415-4aac-88d7-a0bdfcaa9b20",
      "is_mobile": true,
      "mobile_live_date": "2023-03-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Tesla",
      "_label": "Tesla",
      "_name": "Austin, TX",
      "_lat": 30.22764,
      "_lng": -97.61648,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -97.61648
    },
    {
      "id": 2020,
      "name": "Austin, TX (JLL)",
      "correlation_id": "10861",
      "billing_address": null,
      "start_date": "2017-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 30.26444,
      "lon": -97.74368,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6c8e7dae-9dbb-4d0f-9285-f12ab622ada7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Austin, TX (JLL)",
      "_lat": 30.26444,
      "_lng": -97.74368,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -97.74368
    },
    {
      "id": 5711,
      "name": "Automotive Vertical Overhead SBM",
      "correlation_id": "102576",
      "billing_address": null,
      "start_date": "2023-04-30T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2122,
        "name": "CRM NAD",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "81f5b455-4c85-40ce-9d10-0f1b32a6959d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "68195b19-6be3-4148-979c-89a492606cd3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CRM NAD",
      "_label": "CRM NAD",
      "_name": "Automotive Vertical Overhead SBM",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1195,
      "name": "Aviation C-10",
      "correlation_id": "10131",
      "billing_address": {
        "id": 452,
        "street1": "2 Cessna Blvd",
        "street2": "",
        "city": "Wichita",
        "region": "Alaska",
        "postal_code": "67215",
        "lat": 37.6871761,
        "lon": -97.330053,
        "country": "United States"
      },
      "start_date": "2004-12-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.6374869,
      "lon": -97.4139926,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c9e6bdd5-1ce9-41cc-add2-d4bcc9e028f6",
      "is_mobile": true,
      "mobile_live_date": "2020-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation C-10",
      "_lat": 37.6374869,
      "_lng": -97.4139926,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.4139926
    },
    {
      "id": 1337,
      "name": "Aviation East",
      "correlation_id": "10352",
      "billing_address": {
        "id": 453,
        "street1": "9709 E. Central",
        "street2": "",
        "city": "Wichita",
        "region": "Kansas",
        "postal_code": "67206",
        "lat": 37.692067,
        "lon": -97.221518,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "136500000",
      "total_buildings": "47",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.6374869,
      "lon": -97.4139926,
      "local_timezone": {
        "id": 2248,
        "code": "America/North_Dakota/New_Salem",
        "display_name": "America/North_Dakota/New_Salem",
        "description": "",
        "child_group": null,
        "alt_sequence": 114
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5f168d54-4457-4c64-abd1-2bd3bea9d493",
      "is_mobile": true,
      "mobile_live_date": "2020-07-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation East",
      "_lat": 37.6374869,
      "_lng": -97.4139926,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.4139926
    },
    {
      "id": 1194,
      "name": "Aviation IMF",
      "correlation_id": "10130",
      "billing_address": {
        "id": 3747,
        "street1": "1643 S Maize Rd.",
        "street2": "",
        "city": "Wichita",
        "region": "Kansas",
        "postal_code": null,
        "lat": 37.6627443,
        "lon": -97.463295,
        "country": "United States"
      },
      "start_date": "2015-06-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.6629623,
      "lon": -97.463292,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e32568e8-32db-4f23-b887-c599fd5410a7",
      "is_mobile": true,
      "mobile_live_date": "2020-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation IMF",
      "_lat": 37.6629623,
      "_lng": -97.463292,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.463292
    },
    {
      "id": 1193,
      "name": "Aviation Independence",
      "correlation_id": "10129",
      "billing_address": {
        "id": 449,
        "street1": "14115 Russ Meyer Blvd.",
        "street2": "",
        "city": "Independence",
        "region": "Kansas",
        "postal_code": "67301",
        "lat": 37.1632589,
        "lon": -95.7714104,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "2500000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.162876,
      "lon": -95.765209,
      "local_timezone": {
        "id": 2248,
        "code": "America/North_Dakota/New_Salem",
        "display_name": "America/North_Dakota/New_Salem",
        "description": "",
        "child_group": null,
        "alt_sequence": 114
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ad0142bf-399e-4864-85c0-46fc6bb19ee5",
      "is_mobile": true,
      "mobile_live_date": "2020-08-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation Independence",
      "_lat": 37.162876,
      "_lng": -95.765209,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -95.765209
    },
    {
      "id": 2153,
      "name": "Aviation Indianapolis",
      "correlation_id": "101219",
      "billing_address": {
        "id": 742,
        "street1": "6911 Pierson Drive",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": "46241",
        "lat": 39.729565,
        "lon": -86.280343,
        "country": "United States"
      },
      "start_date": "2020-08-24T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 39.729565,
      "lon": -86.280343,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "83ef5cf2-2e47-49a8-9752-227826fdd9a2",
      "is_mobile": true,
      "mobile_live_date": "2021-09-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation Indianapolis",
      "_lat": 39.729565,
      "_lng": -86.280343,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -86.280343
    },
    {
      "id": 1331,
      "name": "Aviation Orlando",
      "correlation_id": "10343",
      "billing_address": {
        "id": 446,
        "street1": "4138 Bear Road",
        "street2": "",
        "city": "Orlando",
        "region": "Florida",
        "postal_code": "32827",
        "lat": 28.4482919,
        "lon": -81.3321207,
        "country": "United States"
      },
      "start_date": "2016-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 28.447001,
      "lon": -81.331575,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d2c039ef-6713-4a6c-b720-325a9b95d3b8",
      "is_mobile": true,
      "mobile_live_date": "2021-03-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation Orlando",
      "_lat": 28.447001,
      "_lng": -81.331575,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -81.331575
    },
    {
      "id": 1196,
      "name": "Aviation Pawnee",
      "correlation_id": "10132",
      "billing_address": null,
      "start_date": "2010-12-31T23:59:59Z",
      "total_sq_ft": "8500000",
      "total_buildings": "19",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.6374869,
      "lon": -97.4139926,
      "local_timezone": {
        "id": 2289,
        "code": "America/Winnipeg",
        "display_name": "America/Winnipeg",
        "description": "",
        "child_group": null,
        "alt_sequence": 155
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6c4428a3-d1c6-4335-95f2-ef12a0b2c750",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation Pawnee",
      "_lat": 37.6374869,
      "_lng": -97.4139926,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.4139926
    },
    {
      "id": 2269,
      "name": "Aviation/Pittsfield",
      "correlation_id": "101357",
      "billing_address": {
        "id": 3041,
        "street1": "1 Plastics Ave",
        "street2": "",
        "city": "Pittsfield",
        "region": "Massachusetts",
        "postal_code": null,
        "lat": 42.4627118,
        "lon": -73.2132829,
        "country": "United States"
      },
      "start_date": "2020-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 328,
        "name": "GE (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7d3590ee-2648-4669-a686-b561971724c2"
      },
      "parent_id": null,
      "lat": 42.46283,
      "lon": -73.21289,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "33fd521e-b263-48d5-acc3-408af6afc3be",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE (CBRE)",
      "_label": "GE (CBRE)",
      "_name": "Aviation/Pittsfield",
      "_lat": 42.46283,
      "_lng": -73.21289,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918859,
          "create_date": "2020-12-07T22:14:54.265585Z",
          "update_date": "2020-12-07T22:14:54.265610Z",
          "organization_id": 328,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607379294.1564417_GE_t.png",
          "size": 119619,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -73.21289
    },
    {
      "id": 1463,
      "name": "Aviation West",
      "correlation_id": "10037",
      "billing_address": {
        "id": 447,
        "street1": "2 Cessna Blvd",
        "street2": "",
        "city": "Wichita",
        "region": "Kansas",
        "postal_code": "67215",
        "lat": 37.6381273,
        "lon": -97.4141581,
        "country": "United States"
      },
      "start_date": "2010-12-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "50",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.6374869,
      "lon": -97.4139926,
      "local_timezone": {
        "id": 2248,
        "code": "America/North_Dakota/New_Salem",
        "display_name": "America/North_Dakota/New_Salem",
        "description": "",
        "child_group": null,
        "alt_sequence": 114
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c16972f1-5449-4004-bfe5-31b4c6423154",
      "is_mobile": true,
      "mobile_live_date": "2020-07-27",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation West",
      "_lat": 37.6374869,
      "_lng": -97.4139926,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.4139926
    },
    {
      "id": 5327,
      "name": "Aviation Wichita, KS*",
      "correlation_id": "102403",
      "billing_address": {
        "id": 2914,
        "street1": "9709 E. Central City Wichita, KS, 67206",
        "street2": "",
        "city": null,
        "region": "Kansas",
        "postal_code": "67206",
        "lat": 37.692067,
        "lon": -97.221518,
        "country": "United States"
      },
      "start_date": "2022-11-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 37.692067,
      "lon": -97.221518,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "97299273-0b45-467a-8313-551e3457faeb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Aviation Wichita, KS*",
      "_lat": 37.692067,
      "_lng": -97.221518,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.221518
    },
    {
      "id": 2117,
      "name": "Baker Hughes, GE Measurement",
      "correlation_id": "100673",
      "billing_address": null,
      "start_date": "2017-11-01T00:00:00Z",
      "total_sq_ft": "98762",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 454,
        "name": "GE Singapore",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "177af7a3-9f39-4553-aa70-e89ca7faf1b1"
      },
      "parent_id": null,
      "lat": 1.3251,
      "lon": 103.6928,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6775c3bb-a640-40a8-8e00-473b7b6b464d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Singapore",
      "_label": "GE Singapore",
      "_name": "Baker Hughes, GE Measurement",
      "_lat": 1.3251,
      "_lng": 103.6928,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.6928
    },
    {
      "id": 2505,
      "name": "Bakersfield, CA",
      "correlation_id": "102138",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 35.3650727,
      "lon": -119.0671366,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db0f6842-fb7c-4ebe-88e5-9a05297b2b8a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Bakersfield, CA",
      "_lat": 35.3650727,
      "_lng": -119.0671366,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -119.0671366
    },
    {
      "id": 3829,
      "name": "Baltimore, MD",
      "correlation_id": "101659",
      "billing_address": {
        "id": 4244,
        "street1": "7611 Brandon Woods",
        "street2": "",
        "city": "Baltimore",
        "region": "Maryland",
        "postal_code": null,
        "lat": 39.172369,
        "lon": -76.5455797,
        "country": "United States"
      },
      "start_date": "2022-06-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 39.17431,
      "lon": -76.54632,
      "local_timezone": {
        "id": 2276,
        "code": "America/St_Lucia",
        "display_name": "America/St_Lucia",
        "description": "",
        "child_group": null,
        "alt_sequence": 142
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a3abdb9b-fe0a-4060-968d-a458e99f422d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Baltimore, MD",
      "_lat": 39.17431,
      "_lng": -76.54632,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -76.54632
    },
    {
      "id": 3839,
      "name": "Baltimore, MD*",
      "correlation_id": "101882",
      "billing_address": null,
      "start_date": "2022-06-14T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2278,
        "code": "America/St_Vincent",
        "display_name": "America/St_Vincent",
        "description": "",
        "child_group": null,
        "alt_sequence": 144
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dc8e03a6-5376-40d6-b762-5e21b492d5cf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Baltimore, MD*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": 0
    },
    {
      "id": 2106,
      "name": "Bambu",
      "correlation_id": "11713",
      "billing_address": {
        "id": 53,
        "street1": "Bambú Eco Urbano, Ave 52A",
        "street2": "",
        "city": "San Jose",
        "region": "Alabama",
        "postal_code": "11801",
        "lat": 9.9280694,
        "lon": -84.0907246,
        "country": "United States"
      },
      "start_date": "2019-10-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 115,
        "name": "Uber (CBRE) (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10479",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a0fa919b-e92b-469f-9fec-38f2bd9fa7a4"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8624b5f8-820c-4b1c-b5f9-a333d19964d3",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Uber (CBRE) (CR)",
      "_label": "Uber (CBRE) (CR)",
      "_name": "Bambu",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 932145,
          "create_date": "2020-12-10T19:10:56.836917Z",
          "update_date": "2020-12-10T19:10:56.836940Z",
          "organization_id": 115,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607627456.7434294_UberCR_t.jpg",
          "size": 7874,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 3999,
      "name": "Bannockburn",
      "correlation_id": "102072",
      "billing_address": {
        "id": 1975,
        "street1": "1200 Lakeside Drive",
        "street2": "",
        "city": "Bannockburn",
        "region": "Illinois",
        "postal_code": "60015",
        "lat": 42.1942834,
        "lon": -87.8824906,
        "country": "United States"
      },
      "start_date": "2022-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
      "is_mobile": true,
      "mobile_live_date": "2023-04-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Bannockburn",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 3497,
      "name": "Batavia*",
      "correlation_id": "101750",
      "billing_address": {
        "id": 3960,
        "street1": "4310 Batavia Rd",
        "street2": "",
        "city": "Batavia",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.0728883,
        "lon": -84.1171152,
        "country": "United States"
      },
      "start_date": "2020-05-01T20:22:06Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.0728883,
      "lon": -84.1171152,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fa859619-4813-4823-b8a5-98aa419fdb49",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Batavia*",
      "_lat": 39.0728883,
      "_lng": -84.1171152,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.1171152
    },
    {
      "id": 2593,
      "name": "Batu Kawan",
      "correlation_id": "101232",
      "billing_address": {
        "id": 656,
        "street1": "14100 Bandar Cassia",
        "street2": "",
        "city": "Seberang",
        "region": "Alabama",
        "postal_code": "8674562",
        "lat": 5.2591989,
        "lon": 100.4411995,
        "country": "Malaysia"
      },
      "start_date": "2021-08-09T15:50:26Z",
      "total_sq_ft": "0",
      "total_buildings": "43",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 473,
        "name": "Micron Memory Malaysia- Grande",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 657,
            "street1": "14100 Bandar Cassia, Seberang Perai Selatan",
            "street2": "",
            "city": "Lingkaran Cassia Selatan",
            "region": "Taman Perindustrian",
            "postal_code": "799",
            "lat": 5.2202621,
            "lon": 100.4350961,
            "country": "Malaysia"
          }
        ],
        "status": "Unsuspended",
        "uuid": "f4153bf6-1a87-4e24-b469-57ca4c144e45"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2408,
        "code": "Asia/Phnom_Penh",
        "display_name": "Asia/Phnom_Penh",
        "description": "",
        "child_group": null,
        "alt_sequence": 274
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "04dcc2bc-81d3-4391-9791-a801b318b412",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron Memory Malaysia- Grande",
      "_label": "Micron Memory Malaysia- Grande",
      "_name": "Batu Kawan",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5561,
      "name": "Baxter AFZ",
      "correlation_id": "102218",
      "billing_address": {
        "id": 3015,
        "street1": "American Free Zone",
        "street2": "",
        "city": "N/A",
        "region": "Alabama",
        "postal_code": "0000",
        "lat": 9.748917,
        "lon": -83.753428,
        "country": "Costa Rica"
      },
      "start_date": "2023-05-12T08:54:45.602000Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2106,
        "name": "Baxter Americas (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e5fdea57-0e3c-499b-b6ac-f8c8dc0d8565"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "79813260-841a-49c6-879e-a9d6ab522d90",
      "is_mobile": true,
      "mobile_live_date": "2023-05-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Baxter Americas (CR)",
      "_label": "Baxter Americas (CR)",
      "_name": "Baxter AFZ",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1495,
      "name": "Bayer Metro",
      "correlation_id": "10826",
      "billing_address": {
        "id": 26,
        "street1": "Metropolitana Free Zone, Bloque B. Lote 1.Barreal de Heredia.",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 9.9789823,
        "lon": -84.1382368,
        "country": "Costa Rica"
      },
      "start_date": "2019-03-15T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 136,
        "name": "Bayer (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10797",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "11c2aa64-daef-451f-8e86-a375b86f0312"
      },
      "parent_id": null,
      "lat": 9.9810234,
      "lon": -84.1443944,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4e7b3492-50f3-41d0-8484-1167228dbba7",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (CR)",
      "_label": "Bayer (CR)",
      "_name": "Bayer Metro",
      "_lat": 9.9810234,
      "_lng": -84.1443944,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.1443944
    },
    {
      "id": 3996,
      "name": "Bayfront",
      "correlation_id": "102100",
      "billing_address": {
        "id": 1971,
        "street1": "1 Facebook Way",
        "street2": "",
        "city": "Menlo Park",
        "region": "California",
        "postal_code": "94025",
        "lat": 37.4810678,
        "lon": -122.1516142,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "39",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
      "is_mobile": true,
      "mobile_live_date": "2022-11-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Bayfront",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": 0
    },
    {
      "id": 2609,
      "name": "BCT",
      "correlation_id": "101601",
      "billing_address": {
        "id": 1126,
        "street1": "La Lima Free Zone",
        "street2": "",
        "city": "Cartago",
        "region": "Alabama",
        "postal_code": "30106",
        "lat": 9.8683434,
        "lon": -83.9488982,
        "country": "United States"
      },
      "start_date": "2021-09-09T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 481,
        "name": "Terumo (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 719,
            "street1": "Zona Industrial La Lima de Cartago",
            "street2": "",
            "city": "Cartago",
            "region": "Cartago",
            "postal_code": "607354",
            "lat": 9.8520126,
            "lon": -83.9470982,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "91d9787d-902b-40e0-b804-ca9c29cb2a98"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c9b7b16e-67cd-430d-bcdc-01978bdfb304",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Terumo (CR)",
      "_label": "Terumo (CR)",
      "_name": "BCT",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5630,
      "name": "BDSI EVERETT, WA*",
      "correlation_id": "102221",
      "billing_address": null,
      "start_date": "2023-02-06T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "652e9ac2-f1ef-4ac1-b700-f31290d3140e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "BDSI EVERETT, WA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": 0
    },
    {
      "id": 1563,
      "name": "Beaverton",
      "correlation_id": "11075",
      "billing_address": {
        "id": 474,
        "street1": "1500 NW Bethany Blvd Sutie 170",
        "street2": "",
        "city": "Beaverton",
        "region": "Alabama",
        "postal_code": "97006",
        "lat": 45.4869283,
        "lon": -122.804032,
        "country": "United States"
      },
      "start_date": "2014-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 152,
        "name": "OHSU",
        "type": "CUSTOMER",
        "correlation_id": "11074",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9436a0fe-2996-4820-a463-93a4a1a97288"
      },
      "parent_id": null,
      "lat": 45.4525258,
      "lon": -122.7890768,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "07361a8e-6667-45f6-b577-627632734928",
      "is_mobile": true,
      "mobile_live_date": "2021-03-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "OHSU",
      "_label": "OHSU",
      "_name": "Beaverton",
      "_lat": 45.4525258,
      "_lng": -122.7890768,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7890768
    },
    {
      "id": 3267,
      "name": "Belen",
      "correlation_id": "102035",
      "billing_address": {
        "id": 1869,
        "street1": "XRWF+HFQ",
        "street2": "",
        "city": "Heredia",
        "region": "California",
        "postal_code": null,
        "lat": 36.778261,
        "lon": -119.4179324,
        "country": "Costa Rica"
      },
      "start_date": "2022-03-31T07:00:00Z",
      "total_sq_ft": "1000000",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 25.892585,
      "lon": -99.008789,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "37ad0aa3-816a-483a-8bea-d7896d57c300",
      "is_mobile": true,
      "mobile_live_date": "2022-06-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Belen",
      "_lat": 25.892585,
      "_lng": -99.008789,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -99.008789
    },
    {
      "id": 1334,
      "name": "Bell Amarillo",
      "correlation_id": "10346",
      "billing_address": null,
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "1779541",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 35.2128785,
      "lon": -101.7143899,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5b42b381-608c-46cb-90bd-8074fc47c1c7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Bell Amarillo",
      "_lat": 35.2128785,
      "_lng": -101.7143899,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -101.7143899
    },
    {
      "id": 2454,
      "name": "Bellevue, WA",
      "correlation_id": "101389",
      "billing_address": {
        "id": 283,
        "street1": "1550 121st Ave",
        "street2": "",
        "city": "Bellevue",
        "region": "Washington",
        "postal_code": "98005",
        "lat": 47.6224877,
        "lon": -122.177532,
        "country": "United States"
      },
      "start_date": "2023-04-10T08:00:00Z",
      "total_sq_ft": "460000",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 47.621,
      "lon": -122.175,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "df0aaa1a-eba7-45a0-a43f-296101c0751d",
      "is_mobile": true,
      "mobile_live_date": "2022-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Bellevue, WA",
      "_lat": 47.621,
      "_lng": -122.175,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -122.175
    },
    {
      "id": 5645,
      "name": "Bellevue, WA",
      "correlation_id": "102521",
      "billing_address": {
        "id": 3743,
        "street1": "700 Bellevue Way NE Suite 1700",
        "street2": "",
        "city": "Bellevue",
        "region": "Washington",
        "postal_code": null,
        "lat": 47.6169661,
        "lon": -122.2013426,
        "country": "United States"
      },
      "start_date": "2023-02-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2121,
        "name": "Linkedin (C&W)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ac89c803-cb69-48f4-a40e-37be49584897"
      },
      "parent_id": null,
      "lat": 47.617,
      "lon": -122.20171,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e6784676-a068-4282-b6f7-a0ec4a10ee64",
      "is_mobile": true,
      "mobile_live_date": "2023-03-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Linkedin (C&W)",
      "_label": "Linkedin (C&W)",
      "_name": "Bellevue, WA",
      "_lat": 47.617,
      "_lng": -122.20171,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 6604255,
          "create_date": "2023-05-10T19:41:25.105872Z",
          "update_date": "2023-05-10T19:41:25.105896Z",
          "organization_id": 2121,
          "uploaded_by_id": 80157,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bb999701654a414d8f81d99019e1c2e2_LinkedIn_t.jpg",
          "size": 17042,
          "description": null,
          "link": null,
          "unique_identifier": "bb999701654a414d8f81d99019e1c2e2"
        }
      ],
      "lng": -122.20171
    },
    {
      "id": 1765,
      "name": "Bellevue, WA (JLL)",
      "correlation_id": "11470",
      "billing_address": {
        "id": 709,
        "street1": "1120 112th Ave NE Bellevue WA 98004",
        "street2": "",
        "city": "Bellevue",
        "region": "Washington",
        "postal_code": "98004",
        "lat": 47.6206874,
        "lon": -122.1897039,
        "country": "United States"
      },
      "start_date": "2019-01-14T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 47.6204588,
      "lon": -122.1896931,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "07669afa-1a93-4939-b863-8c6c2f1fbd80",
      "is_mobile": true,
      "mobile_live_date": "2021-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Bellevue, WA (JLL)",
      "_lat": 47.6204588,
      "_lng": -122.1896931,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.1896931
    },
    {
      "id": 1333,
      "name": "Bell Hurst",
      "correlation_id": "10345",
      "billing_address": {
        "id": 454,
        "street1": "600 East Hurst Blvd.",
        "street2": "",
        "city": "Hurst",
        "region": "Texas",
        "postal_code": "76053",
        "lat": 32.8072972,
        "lon": -97.1610683,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "3004145",
      "total_buildings": "25",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 32.8072972,
      "lon": -97.1610683,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "050d121c-1225-43e3-aa34-77650243593e",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Bell Hurst",
      "_lat": 32.8072972,
      "_lng": -97.1610683,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.1610683
    },
    {
      "id": 4426,
      "name": "Bendemeer*",
      "correlation_id": "101944",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1785,
        "name": "Intel Maxlinear (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c70a53c-0c33-4ca4-af5a-aca5bff5b05b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "00704c79-1e7a-4461-b9f2-5d505299b67a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel Maxlinear (SG)",
      "_label": "Intel Maxlinear (SG)",
      "_name": "Bendemeer*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2464,
      "name": "Bethel",
      "correlation_id": "101423",
      "billing_address": {
        "id": 300,
        "street1": "14 Research Dr",
        "street2": "",
        "city": "Bethel",
        "region": "Connecticut",
        "postal_code": "06801",
        "lat": 41.425832,
        "lon": -73.40499,
        "country": "United States"
      },
      "start_date": "2021-05-08T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 442,
        "name": "Duracell",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 299,
            "street1": "14 Research Dr",
            "street2": "",
            "city": "Bethel",
            "region": "Connecticut",
            "postal_code": "06801",
            "lat": 41.425832,
            "lon": -73.40499,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "e88bc060-eb82-46d4-b369-dbdf9436012c"
      },
      "parent_id": null,
      "lat": 41.425832,
      "lon": -73.40499,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c36c27c7-c71e-4778-bf50-a95fdf6afc47",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Duracell",
      "_label": "Duracell",
      "_name": "Bethel",
      "_lat": 41.425832,
      "_lng": -73.40499,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025480,
          "create_date": "2024-03-07T17:56:27.037987Z",
          "update_date": "2024-03-07T17:56:27.038007Z",
          "organization_id": 442,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/cd3086beb77c467eae57468076022abb_duracelllogo_t.png",
          "size": 11434,
          "description": null,
          "link": null,
          "unique_identifier": "cd3086beb77c467eae57468076022abb"
        }
      ],
      "lng": -73.40499
    },
    {
      "id": 2640,
      "name": "Bethlehem",
      "correlation_id": "101568",
      "billing_address": {
        "id": 776,
        "street1": "1510 Valley Center Parkway, Suite #130",
        "street2": "",
        "city": "Bethlehem",
        "region": "Pennsylvania",
        "postal_code": "18017",
        "lat": 40.654568,
        "lon": -75.4068888,
        "country": "United States"
      },
      "start_date": "2021-06-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 470,
        "name": "IQVIA (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 573,
            "street1": "2400 Ellis road",
            "street2": "",
            "city": "Durham",
            "region": "North Carolina",
            "postal_code": "27703",
            "lat": 35.9353484,
            "lon": -78.8545872,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "d7f7ea55-d5e5-4f58-9ffe-85e17bde2246"
      },
      "parent_id": null,
      "lat": 40.654568,
      "lon": -75.4068888,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7dd14439-d031-4edb-80a1-d29ebbbae2e6",
      "is_mobile": true,
      "mobile_live_date": "2022-08-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IQVIA (CBRE)",
      "_label": "IQVIA (CBRE)",
      "_name": "Bethlehem",
      "_lat": 40.654568,
      "_lng": -75.4068888,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567372,
          "create_date": "2024-02-08T22:57:39.786518Z",
          "update_date": "2024-02-08T22:57:39.786542Z",
          "organization_id": 470,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
          "size": 1894,
          "description": null,
          "link": null,
          "unique_identifier": "5fc2aad4b42343a4886a3ce342496b83"
        }
      ],
      "lng": -75.4068888
    },
    {
      "id": 2843,
      "name": "Bethlehem, PA",
      "correlation_id": "101820",
      "billing_address": {
        "id": 1077,
        "street1": "1455 Valley Center Pwky #100",
        "street2": "",
        "city": "Bethlehem",
        "region": "PA",
        "postal_code": "18017",
        "lat": 40.6554252,
        "lon": -75.401438,
        "country": "United States"
      },
      "start_date": "2022-01-02T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 639,
        "name": "New York Life (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1077,
            "street1": "1455 Valley Center Pwky #100",
            "street2": "",
            "city": "Bethlehem",
            "region": "PA",
            "postal_code": "18017",
            "lat": 40.6554252,
            "lon": -75.401438,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "3338f6ab-eab9-4506-8d7c-bd509933d502"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0d3a8a4a-2bfd-4563-b4b0-8abfeab05306",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "New York Life (C&W)",
      "_label": "New York Life (C&W)",
      "_name": "Bethlehem, PA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2442,
      "name": "BID",
      "correlation_id": "101420",
      "billing_address": {
        "id": 265,
        "street1": "Zona Franca Ultra Park 2 Edificio # 10",
        "street2": "",
        "city": "Lagunilla de Heredia",
        "region": "Alabama",
        "postal_code": "10203",
        "lat": 9.9653127,
        "lon": -84.1205983,
        "country": "Costa Rica"
      },
      "start_date": "2021-12-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 437,
        "name": "BID (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 265,
            "street1": "Zona Franca Ultra Park 2 Edificio # 10",
            "street2": "",
            "city": "Lagunilla de Heredia",
            "region": "Alabama",
            "postal_code": "10203",
            "lat": 9.9653127,
            "lon": -84.1205983,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bb1886e7-2902-416a-bfbc-579088086fa6"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "00d26321-d345-414a-8323-3a0fac201f3e",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BID (CR)",
      "_label": "BID (CR)",
      "_name": "BID",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2101,
      "name": "Bio",
      "correlation_id": "101375",
      "billing_address": null,
      "start_date": "2020-01-01T00:00:00Z",
      "total_sq_ft": "79612",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 292,
        "name": "Novartis  (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": "11706",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2743e426-ec49-4278-b837-8bed72a68ee1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9542fb18-bcfe-4223-b8af-b5ee9b97651e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis  (CBRE) (SG)",
      "_label": "Novartis  (CBRE) (SG)",
      "_name": "Bio",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 940468,
          "create_date": "2020-12-14T16:08:17.854724Z",
          "update_date": "2020-12-14T16:08:17.854750Z",
          "organization_id": 292,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607962097.739492_Novarits_t.png",
          "size": 4146,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 6075,
      "name": "BioPlus Pharmacy*",
      "correlation_id": "103004",
      "billing_address": null,
      "start_date": "2023-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2272,
        "name": "Elevance (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c020b3a4-b353-42b6-9656-6ff4bf7f88f6"
      },
      "parent_id": null,
      "lat": 39.70164,
      "lon": -86.07539,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "483e5cbb-99e4-4519-b09a-22c6de53ed1c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance (C&W)",
      "_label": "Elevance (C&W)",
      "_name": "BioPlus Pharmacy*",
      "_lat": 39.70164,
      "_lng": -86.07539,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.07539
    },
    {
      "id": 5706,
      "name": "Biotech 2*",
      "correlation_id": "102571",
      "billing_address": null,
      "start_date": "2023-05-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 292,
        "name": "Novartis  (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": "11706",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2743e426-ec49-4278-b837-8bed72a68ee1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fa93ad2f-3359-49e7-a733-286632e01154",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis  (CBRE) (SG)",
      "_label": "Novartis  (CBRE) (SG)",
      "_name": "Biotech 2*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 940468,
          "create_date": "2020-12-14T16:08:17.854724Z",
          "update_date": "2020-12-14T16:08:17.854750Z",
          "organization_id": 292,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607962097.739492_Novarits_t.png",
          "size": 4146,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 3869,
      "name": "Birmingham, AL",
      "correlation_id": "101954",
      "billing_address": null,
      "start_date": "2022-06-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2734,
        "name": "State Farm (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "32aff8f6-eb4c-4b7e-862c-0ba02ba9d1e1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2216,
        "code": "America/Jujuy",
        "display_name": "America/Jujuy",
        "description": "",
        "child_group": null,
        "alt_sequence": 82
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "23693617-15a1-4cc1-bdcd-a46e405e59c1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE) - Sub",
      "_label": "State Farm (CBRE) - Sub",
      "_name": "Birmingham, AL",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5684,
      "name": "Birmingham, MI",
      "correlation_id": "102575",
      "billing_address": {
        "id": 3575,
        "street1": "735 Forest Avenue",
        "street2": "",
        "city": "Birmingham",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.5456613,
        "lon": -83.2093128,
        "country": "United States"
      },
      "start_date": "2023-04-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 42.5455,
      "lon": -83.20927,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1d1b246f-2c79-4426-a99b-d628c1b8c4f9",
      "is_mobile": true,
      "mobile_live_date": "2023-04-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Birmingham, MI",
      "_lat": 42.5455,
      "_lng": -83.20927,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -83.20927
    },
    {
      "id": 4231,
      "name": "Blings Ops*",
      "correlation_id": "101962",
      "billing_address": null,
      "start_date": "2022-07-14T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2262,
        "code": "America/Regina",
        "display_name": "America/Regina",
        "description": "",
        "child_group": null,
        "alt_sequence": 128
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8a48eee0-2e12-4296-bd2c-fb7877f8da28",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Blings Ops*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": 0
    },
    {
      "id": 1962,
      "name": "Bloomfield",
      "correlation_id": "11570",
      "billing_address": {
        "id": 651,
        "street1": "900 Cottage Grove Rd",
        "street2": "",
        "city": "Bloomfield",
        "region": "Connecticut",
        "postal_code": "06473",
        "lat": 41.8128862,
        "lon": -72.7469102,
        "country": "United States"
      },
      "start_date": "2019-08-26T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 41.8128862,
      "lon": -72.7469102,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "674967cb-d560-4440-809d-d682f82678b8",
      "is_mobile": true,
      "mobile_live_date": "2021-05-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Bloomfield",
      "_lat": 41.8128862,
      "_lng": -72.7469102,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -72.7469102
    },
    {
      "id": 1863,
      "name": "Bloomington",
      "correlation_id": "10697",
      "billing_address": null,
      "start_date": "2009-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 44.8430975,
      "lon": -93.3124067,
      "local_timezone": {
        "id": 2234,
        "code": "America/Merida",
        "display_name": "America/Merida",
        "description": "",
        "child_group": null,
        "alt_sequence": 100
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "237cb6b0-0455-4710-b5a2-aac1e4775d78",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Bloomington",
      "_lat": 44.8430975,
      "_lng": -93.3124067,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -93.3124067
    },
    {
      "id": 4158,
      "name": "Blue Bell, PA",
      "correlation_id": "101881",
      "billing_address": null,
      "start_date": "2017-10-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 311,
        "name": "Siemens (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7e18d041-a341-42b2-a49a-9c10ba6df5aa"
      },
      "parent_id": null,
      "lat": 40.14724,
      "lon": -75.28658,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "004c3034-23fb-4a72-9491-c8851fe378c4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Siemens (CBRE)",
      "_label": "Siemens (CBRE)",
      "_name": "Blue Bell, PA",
      "_lat": 40.14724,
      "_lng": -75.28658,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 933087,
          "create_date": "2020-12-10T21:55:19.109328Z",
          "update_date": "2020-12-10T21:55:19.109352Z",
          "organization_id": 311,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607637318.9761856_Siemens_t.png",
          "size": 1646,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -75.28658
    },
    {
      "id": 5830,
      "name": "Blue Zoo",
      "correlation_id": "102752",
      "billing_address": {
        "id": 3783,
        "street1": "10805 Sunset Office Dr. Suite 307",
        "street2": "",
        "city": "St. Louis",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.5569512,
        "lon": -90.4109804,
        "country": "United States"
      },
      "start_date": "2023-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 32,
        "name": "Aramark",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10874",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8910b89c-9184-4e0d-821c-0604c052b193"
      },
      "parent_id": null,
      "lat": 38.76835,
      "lon": -90.37543,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8035b342-eaf0-4724-8be4-846f9f1c93c8",
      "is_mobile": true,
      "mobile_live_date": "2023-09-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Aramark",
      "_label": "Aramark",
      "_name": "Blue Zoo",
      "_lat": 38.76835,
      "_lng": -90.37543,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686500,
          "create_date": "2024-02-16T11:58:32.131512Z",
          "update_date": "2024-02-16T11:58:32.131534Z",
          "organization_id": 32,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/e347e2c0d74f41ea8a61f57779a9af9c_aramark_t.jpeg",
          "size": 3019,
          "description": null,
          "link": null,
          "unique_identifier": "e347e2c0d74f41ea8a61f57779a9af9c"
        }
      ],
      "lng": -90.37543
    },
    {
      "id": 1759,
      "name": "Boise",
      "correlation_id": "11464",
      "billing_address": {
        "id": 3756,
        "street1": "3851 E Columbia Road",
        "street2": "",
        "city": "Boise",
        "region": "Idaho",
        "postal_code": null,
        "lat": 43.5300411,
        "lon": -116.1360538,
        "country": "United States"
      },
      "start_date": "2002-06-30T23:59:59Z",
      "total_sq_ft": "32505000",
      "total_buildings": "15",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 209,
        "name": "Micron",
        "type": "CUSTOMER",
        "correlation_id": "10763",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "728233df-1757-4e90-984f-2734580bb51d"
      },
      "parent_id": null,
      "lat": 43.529953,
      "lon": -116.136231,
      "local_timezone": {
        "id": 2164,
        "code": "America/Boise",
        "display_name": "America/Boise",
        "description": "",
        "child_group": null,
        "alt_sequence": 30
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3486b6bf-a6a3-4981-b193-c76cf7012b69",
      "is_mobile": true,
      "mobile_live_date": "2020-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron",
      "_label": "Micron",
      "_name": "Boise",
      "_lat": 43.529953,
      "_lng": -116.136231,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -116.136231
    },
    {
      "id": 2522,
      "name": "Boise",
      "correlation_id": "101439",
      "billing_address": {
        "id": 374,
        "street1": "10136 S Federal Way",
        "street2": "",
        "city": "Boise",
        "region": "Utah",
        "postal_code": "83716",
        "lat": 43.512327,
        "lon": -116.1403577,
        "country": "United States"
      },
      "start_date": "2021-06-01T17:09:12Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 462,
        "name": "Photronics",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 373,
            "street1": "10136 S Federal Way",
            "street2": "",
            "city": "Boise",
            "region": "Utah",
            "postal_code": "83716",
            "lat": 43.512327,
            "lon": -116.1403577,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0ba878ba-d427-40b9-9ab7-7a9a781dcb32"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "55ac49da-6f71-40b7-a454-da2e58158786",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Photronics",
      "_label": "Photronics",
      "_name": "Boise",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2645,
      "name": "Boise, ID",
      "correlation_id": "101582",
      "billing_address": {
        "id": 945,
        "street1": "8000 S Federal Way",
        "street2": "",
        "city": "Boise",
        "region": "Idaho",
        "postal_code": "83716",
        "lat": 43.5305808,
        "lon": -116.1498385,
        "country": "United States"
      },
      "start_date": "2021-11-01T23:59:59Z",
      "total_sq_ft": "13609910",
      "total_buildings": "43",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 493,
        "name": "Micron (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f8922e27-f57a-4029-8bc3-609db025f265"
      },
      "parent_id": null,
      "lat": 43.5305808,
      "lon": -116.1498385,
      "local_timezone": {
        "id": 2164,
        "code": "America/Boise",
        "display_name": "America/Boise",
        "description": "",
        "child_group": null,
        "alt_sequence": 30
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2126cfb6-96bb-4be5-8318-6b2dbc7eec4b",
      "is_mobile": true,
      "mobile_live_date": "2022-02-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron (C&W)",
      "_label": "Micron (C&W)",
      "_name": "Boise, ID",
      "_lat": 43.5305808,
      "_lng": -116.1498385,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -116.1498385
    },
    {
      "id": 5786,
      "name": "Boise, ID*",
      "correlation_id": "102711",
      "billing_address": null,
      "start_date": "2023-06-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 43.66159,
      "lon": -116.32383,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ff1ce3aa-10a0-47b6-9215-6be8a49da6ba",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Boise, ID*",
      "_lat": 43.66159,
      "_lng": -116.32383,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -116.32383
    },
    {
      "id": 1326,
      "name": "Boise (JLL)",
      "correlation_id": "10335",
      "billing_address": {
        "id": 426,
        "street1": "3033 Elder Street",
        "street2": "",
        "city": "Boise",
        "region": "Idaho",
        "postal_code": "83705",
        "lat": 43.5726791,
        "lon": -116.2195873,
        "country": "United States"
      },
      "start_date": "2013-11-01T23:59:59Z",
      "total_sq_ft": "174148",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 43.5728551,
      "lon": -116.2227422,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4e3ee1f9-e6e4-4e01-ab9e-f4bbd35efa6a",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Boise (JLL)",
      "_lat": 43.5728551,
      "_lng": -116.2227422,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -116.2227422
    },
    {
      "id": 2814,
      "name": "Boston*",
      "correlation_id": "101772",
      "billing_address": null,
      "start_date": "2021-01-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 627,
        "name": "Alexion Pharmaceuticals",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "36cc0d87-d9a7-4b8d-9521-875a32ca0195"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4c38c1f5-6049-4beb-9c25-2e787ccd827b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Alexion Pharmaceuticals",
      "_label": "Alexion Pharmaceuticals",
      "_name": "Boston*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1483,
      "name": "Boston, MA",
      "correlation_id": "10068",
      "billing_address": {
        "id": 500,
        "street1": "33 Avenue Louis Pasteur",
        "street2": "",
        "city": "Boston",
        "region": "Massachusetts",
        "postal_code": "02115",
        "lat": 42.3394829,
        "lon": -71.1035377,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 207,
        "name": "Merck",
        "type": "CUSTOMER",
        "correlation_id": "11459",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ac6c1c69-fd86-48b6-970a-06c86ce500db"
      },
      "parent_id": 2527,
      "lat": 42.3398408,
      "lon": -71.1029385,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "70961969-4841-4626-951d-159771c6f1b1",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck",
      "_label": "Merck",
      "_name": "Boston, MA",
      "_lat": 42.3398408,
      "_lng": -71.1029385,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953529,
          "create_date": "2020-12-17T16:57:39.106237Z",
          "update_date": "2020-12-17T16:57:39.106267Z",
          "organization_id": 207,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224258.8895628_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.1029385
    },
    {
      "id": 2004,
      "name": "Bothell, WA",
      "correlation_id": "11632",
      "billing_address": {
        "id": 415,
        "street1": "1631 220th St. Ste",
        "street2": "",
        "city": "Bothell",
        "region": "Washington",
        "postal_code": "98021",
        "lat": 47.7995824,
        "lon": -122.2104962,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "43150",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 272,
        "name": "Cepheid",
        "type": "CUSTOMER",
        "correlation_id": "100007",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5e4699ae-dee5-4511-a986-de5df15c01e2"
      },
      "parent_id": null,
      "lat": 47.7995824,
      "lon": -122.2126849,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0209a3b9-5702-41e7-8478-4a14350c02ed",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cepheid",
      "_label": "Cepheid",
      "_name": "Bothell, WA",
      "_lat": 47.7995824,
      "_lng": -122.2126849,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.2126849
    },
    {
      "id": 2264,
      "name": "Bothell, WA",
      "correlation_id": "101294",
      "billing_address": {
        "id": 137,
        "street1": "22422 29th Dr SE",
        "street2": "",
        "city": "Bothell",
        "region": "Washington",
        "postal_code": "98021",
        "lat": 47.7946092,
        "lon": -122.1967686,
        "country": "United States"
      },
      "start_date": "2020-12-07T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "908fc000-28d0-439e-9912-ff88967db144",
      "is_mobile": true,
      "mobile_live_date": "2021-01-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Bothell, WA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2543,
      "name": "Boulder*",
      "correlation_id": "102139",
      "billing_address": {
        "id": 529,
        "street1": "2480 Canyon Blvd",
        "street2": "",
        "city": "Boulder",
        "region": "Colorado",
        "postal_code": "80302",
        "lat": 40.0170581,
        "lon": -105.2616392,
        "country": "United States"
      },
      "start_date": "2021-06-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": 2671,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "673b9b9e-21e7-4d1c-99a3-57559ad591f2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Boulder*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3136,
      "name": "Boulder, CO*",
      "correlation_id": "102023",
      "billing_address": {
        "id": 1286,
        "street1": "3200 Walnut St",
        "street2": "",
        "city": "Boulder",
        "region": "Colorado",
        "postal_code": "80301",
        "lat": 40.019125,
        "lon": -105.250666,
        "country": "United States"
      },
      "start_date": "2022-03-04T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 71,
        "name": "Pfizer",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a947b043-b271-463a-87cb-992405816077"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2182,
        "code": "America/Dawson_Creek",
        "display_name": "America/Dawson_Creek",
        "description": "",
        "child_group": null,
        "alt_sequence": 48
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2cbc37b9-4aba-4b13-b706-0a421fe824cd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Pfizer",
      "_label": "Pfizer",
      "_name": "Boulder, CO*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1481,
      "name": "Boulder, CO (JLL)",
      "correlation_id": "100022",
      "billing_address": {
        "id": 219,
        "street1": "2930 Pearl Street",
        "street2": "",
        "city": "Boulder",
        "region": "Colorado",
        "postal_code": "80301",
        "lat": 40.0216013,
        "lon": -105.2545612,
        "country": "United States"
      },
      "start_date": "2021-02-18T23:59:59Z",
      "total_sq_ft": "385431",
      "total_buildings": "18",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 40.0149856,
      "lon": -105.2705456,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "35328557-2d7e-4cc8-a6e0-48b0e5ba3260",
      "is_mobile": true,
      "mobile_live_date": "2020-12-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Boulder, CO (JLL)",
      "_lat": 40.0149856,
      "_lng": -105.2705456,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -105.2705456
    },
    {
      "id": 2142,
      "name": "Bowers",
      "correlation_id": "101825",
      "billing_address": {
        "id": 103,
        "street1": "3065 Bowers Ave.",
        "street2": "",
        "city": "Santa Clara",
        "region": "California",
        "postal_code": "95054",
        "lat": 37.3762696,
        "lon": -121.9752156,
        "country": "United States"
      },
      "start_date": "2016-01-12T00:00:00Z",
      "total_sq_ft": "277164",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 37.385751,
      "lon": -121.966449,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8b622665-d334-46de-97d5-f28cfe410d24",
      "is_mobile": true,
      "mobile_live_date": "2020-10-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Bowers",
      "_lat": 37.385751,
      "_lng": -121.966449,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.966449
    },
    {
      "id": 1798,
      "name": "Branchburg",
      "correlation_id": "11523",
      "billing_address": {
        "id": 2987,
        "street1": "1 Millennium Way",
        "street2": "",
        "city": "Branchburg",
        "region": "New Jersey",
        "postal_code": null,
        "lat": 40.5640024,
        "lon": -74.7101129,
        "country": "United States"
      },
      "start_date": "2019-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 75,
        "name": "AbbVie",
        "type": "CUSTOMER",
        "correlation_id": "10135",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
      },
      "parent_id": null,
      "lat": 40.5612593,
      "lon": -74.7270898,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
      "is_mobile": true,
      "mobile_live_date": "2022-12-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AbbVie",
      "_label": "AbbVie",
      "_name": "Branchburg",
      "_lat": 40.5612593,
      "_lng": -74.7270898,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 5630176,
          "create_date": "2023-01-27T16:07:24.909099Z",
          "update_date": "2023-01-27T16:07:24.909120Z",
          "organization_id": 75,
          "uploaded_by_id": 43918,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
          "size": 5975,
          "description": null,
          "link": null,
          "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
        }
      ],
      "lng": -74.7270898
    },
    {
      "id": 2124,
      "name": "Branches",
      "correlation_id": "102594",
      "billing_address": null,
      "start_date": "2014-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "28",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 252,
        "name": "SCB (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10786",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3c5ff5ca-946c-48c2-a279-417f871c9969"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c701dcff-ccfc-4b1e-b0e4-08b1a785ffee",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SCB (SG) (CBRE)",
      "_label": "SCB (SG) (CBRE)",
      "_name": "Branches",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10833080,
          "create_date": "2024-02-26T15:45:18.559062Z",
          "update_date": "2024-02-26T15:45:18.559085Z",
          "organization_id": 252,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8ccd474a0aa04ae085f21c36240403aa_1_t.png",
          "size": 775522,
          "description": null,
          "link": null,
          "unique_identifier": "8ccd474a0aa04ae085f21c36240403aa"
        }
      ],
      "lng": 0
    },
    {
      "id": 5517,
      "name": "Brawley, CA (JLL)",
      "correlation_id": "102331",
      "billing_address": {
        "id": 3046,
        "street1": "297 W Main Street",
        "street2": "",
        "city": "Brawley",
        "region": "California",
        "postal_code": null,
        "lat": 32.9788377,
        "lon": -115.546528,
        "country": "United States"
      },
      "start_date": "2023-03-09T23:46:40Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 32.9788377,
      "lon": -115.546528,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8d83332f-c8d7-4cae-9e00-4c6aef374956",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Brawley, CA (JLL)",
      "_lat": 32.9788377,
      "_lng": -115.546528,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -115.546528
    },
    {
      "id": 2501,
      "name": "Brea, CA",
      "correlation_id": "102140",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 33.9086862,
      "lon": -117.8527401,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d436d3e4-c479-4d9f-a5f7-b24231e5522f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Brea, CA",
      "_lat": 33.9086862,
      "_lng": -117.8527401,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.8527401
    },
    {
      "id": 5655,
      "name": "Brentwood Office - UCBRC (JLL)",
      "correlation_id": "102411",
      "billing_address": {
        "id": 3054,
        "street1": "11836 San Vicente Blvd",
        "street2": "",
        "city": "Los Angeles",
        "region": "California",
        "postal_code": null,
        "lat": 34.0526301,
        "lon": -118.4691969,
        "country": "United States"
      },
      "start_date": "2023-03-10T00:05:52Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 34.0526301,
      "lon": -118.4691969,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "71b18377-81b7-4acc-8d81-0b31cd584fc7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Brentwood Office - UCBRC (JLL)",
      "_lat": 34.0526301,
      "_lng": -118.4691969,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -118.4691969
    },
    {
      "id": 5675,
      "name": "Brewery",
      "correlation_id": "102740",
      "billing_address": {
        "id": 3068,
        "street1": "3939 W. Highlands Blvd.",
        "street2": "",
        "city": "Milwaukee",
        "region": "Wisconsin",
        "postal_code": "53208",
        "lat": 43.0440562,
        "lon": -87.9624248,
        "country": "United States"
      },
      "start_date": "2023-01-30T19:33:44Z",
      "total_sq_ft": "0",
      "total_buildings": "14",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2104,
        "name": "Molson Coors",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "959ff740-96a6-4be4-803a-93ef99c9c7df"
      },
      "parent_id": null,
      "lat": 39.75,
      "lon": -104.99022,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b2a48905-9afa-4303-b480-1f37c93e762b",
      "is_mobile": true,
      "mobile_live_date": "2023-04-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Molson Coors",
      "_label": "Molson Coors",
      "_name": "Brewery",
      "_lat": 39.75,
      "_lng": -104.99022,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025435,
          "create_date": "2024-03-07T17:53:48.204279Z",
          "update_date": "2024-03-07T17:53:48.204305Z",
          "organization_id": 2104,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c6dcff670102483ea02c19995e85751f_oPVydH8n_400x400_t.jpg",
          "size": 21690,
          "description": null,
          "link": null,
          "unique_identifier": "c6dcff670102483ea02c19995e85751f"
        }
      ],
      "lng": -104.99022
    },
    {
      "id": 1072,
      "name": "Bridgewater, NJ - Healthcare",
      "correlation_id": "10854",
      "billing_address": {
        "id": 1208,
        "street1": "1007 US Hwy 202/206, Building JR-2",
        "street2": "",
        "city": "Bridgewater",
        "region": "New Jersey",
        "postal_code": "8807",
        "lat": 40.5743636,
        "lon": -74.6542003,
        "country": "United States"
      },
      "start_date": "2017-04-24T23:59:59Z",
      "total_sq_ft": "98000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 721,
        "name": "Nestle",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "67b06b43-0a3d-4590-afcd-81b959e1ae38"
      },
      "parent_id": null,
      "lat": 40.6236007,
      "lon": -74.6294938,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c6c8742b-73a8-4003-a44c-7931f00d6c06",
      "is_mobile": true,
      "mobile_live_date": "2020-08-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Nestle",
      "_label": "Nestle",
      "_name": "Bridgewater, NJ - Healthcare",
      "_lat": 40.6236007,
      "_lng": -74.6294938,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025316,
          "create_date": "2024-03-07T17:48:17.883086Z",
          "update_date": "2024-03-07T17:48:17.883117Z",
          "organization_id": 721,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/0b5a3f66eb9a4fd7869d0bc1a506b8cd_0_FKwIAXSv8sFEWPmL_t.png",
          "size": 233845,
          "description": null,
          "link": null,
          "unique_identifier": "0b5a3f66eb9a4fd7869d0bc1a506b8cd"
        }
      ],
      "lng": -74.6294938
    },
    {
      "id": 5850,
      "name": "Brisbane*",
      "correlation_id": "102766",
      "billing_address": null,
      "start_date": "2023-09-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2165,
        "name": "BMS",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "926b1881-18c9-4efd-a4f9-a281a16017a3"
      },
      "parent_id": null,
      "lat": 37.67407,
      "lon": -122.38408,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c92abbfe-e94b-40c6-891d-b33cbacaf4e3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS",
      "_label": "BMS",
      "_name": "Brisbane*",
      "_lat": 37.67407,
      "_lng": -122.38408,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.38408
    },
    {
      "id": 4025,
      "name": "Brisbane, CA",
      "correlation_id": "102110",
      "billing_address": {
        "id": 2000,
        "street1": "1000 Sierra Point Parkway",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "94005",
        "lat": 37.6729283,
        "lon": -122.3831415,
        "country": "United States"
      },
      "start_date": "2022-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6bd2325e-356a-4272-955f-9dce7e05546e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Brisbane, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2486,
      "name": "Bristol, PA",
      "correlation_id": "101437",
      "billing_address": {
        "id": 646,
        "street1": "360 George Patterson Blvd",
        "street2": "",
        "city": "Bristol",
        "region": "Pennsylvania",
        "postal_code": "19007",
        "lat": 40.0927335,
        "lon": -74.8709066,
        "country": "United States"
      },
      "start_date": "2021-07-23T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 448,
        "name": "Abzena (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4d596964-3e74-4f53-ada1-efe738a50a1c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8ae7a2df-156a-4702-9b27-80ff858ddac1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abzena (CBRE)",
      "_label": "Abzena (CBRE)",
      "_name": "Bristol, PA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2029,
      "name": "BRTC",
      "correlation_id": "11610",
      "billing_address": {
        "id": 326,
        "street1": "700 Wilmer Ave",
        "street2": "",
        "city": "Cincinnati,",
        "region": "Ohio",
        "postal_code": "45226",
        "lat": 39.11356,
        "lon": -84.4172789,
        "country": "United States"
      },
      "start_date": "2019-11-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 275,
        "name": "P&G (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "73c20d05-69b7-4adc-b8c0-7f265c556fe3"
      },
      "parent_id": null,
      "lat": 39.340803,
      "lon": -84.4473982,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "63f3b15d-fcd8-4c11-ba09-9b0377da48eb",
      "is_mobile": true,
      "mobile_live_date": "2021-04-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "P&G (JLL)",
      "_label": "P&G (JLL)",
      "_name": "BRTC",
      "_lat": 39.340803,
      "_lng": -84.4473982,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10676982,
          "create_date": "2024-02-15T19:30:31.713635Z",
          "update_date": "2024-02-15T19:30:31.713660Z",
          "organization_id": 275,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9efe32dff7ab4f479526367623a6a20d_Procter__Gamble_logo_t.png",
          "size": 107371,
          "description": null,
          "link": null,
          "unique_identifier": "9efe32dff7ab4f479526367623a6a20d"
        }
      ],
      "lng": -84.4473982
    },
    {
      "id": 5558,
      "name": "BSP – Buffalo Stamping Plant – Buffalo, NY",
      "correlation_id": "102397",
      "billing_address": {
        "id": 3034,
        "street1": "3663 Lake Shore Road",
        "street2": "",
        "city": "Buffalo",
        "region": "New York",
        "postal_code": null,
        "lat": 42.7876916,
        "lon": -78.8425112,
        "country": "United States"
      },
      "start_date": "2023-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.7878821,
      "lon": -78.8446461,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8c725d59-2e2e-4470-ae29-6102552da54f",
      "is_mobile": true,
      "mobile_live_date": "2023-02-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "BSP – Buffalo Stamping Plant – Buffalo, NY",
      "_lat": 42.7878821,
      "_lng": -78.8446461,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -78.8446461
    },
    {
      "id": 2261,
      "name": "Buffalo",
      "correlation_id": "101350",
      "billing_address": {
        "id": 691,
        "street1": "3115 Rivers Road",
        "street2": "",
        "city": "Buffalo",
        "region": "New York",
        "postal_code": "14207",
        "lat": 42.9654241,
        "lon": -78.9189896,
        "country": "United States"
      },
      "start_date": "2020-12-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 326,
        "name": "DuPont",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4668b06b-a547-4504-b0aa-e2be16bac9b4"
      },
      "parent_id": null,
      "lat": 42.98279,
      "lon": -78.9354,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eb9286ae-1def-46bd-a690-53e7328ac1da",
      "is_mobile": true,
      "mobile_live_date": "2021-08-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DuPont",
      "_label": "DuPont",
      "_name": "Buffalo",
      "_lat": 42.98279,
      "_lng": -78.9354,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698154,
          "create_date": "2024-02-16T20:28:33.946575Z",
          "update_date": "2024-02-16T20:28:33.946598Z",
          "organization_id": 326,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8506194989694f86b1f7259ff193d285_logo_t.PNG",
          "size": 5219,
          "description": null,
          "link": null,
          "unique_identifier": "8506194989694f86b1f7259ff193d285"
        }
      ],
      "lng": -78.9354
    },
    {
      "id": 3992,
      "name": "Burlingame",
      "correlation_id": "102107",
      "billing_address": {
        "id": 1967,
        "street1": "322 Airport Blvd.",
        "street2": "",
        "city": "Burlingame",
        "region": "California",
        "postal_code": "94010",
        "lat": 37.5903727,
        "lon": -122.3359498,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
      "is_mobile": true,
      "mobile_live_date": "2022-11-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Burlingame",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": 0
    },
    {
      "id": 5661,
      "name": "Burlington",
      "correlation_id": "102530",
      "billing_address": {
        "id": 3583,
        "street1": "23 Fourth Ave",
        "street2": "",
        "city": "Burlington",
        "region": "Massachusetts",
        "postal_code": null,
        "lat": 42.4909,
        "lon": -71.22572,
        "country": "United States"
      },
      "start_date": "2023-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 42.49075,
      "lon": -71.2263,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d2eb1b3f-2390-4c7c-aa08-56b596840207",
      "is_mobile": true,
      "mobile_live_date": "2023-11-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Burlington",
      "_lat": 42.49075,
      "_lng": -71.2263,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.2263
    },
    {
      "id": 1860,
      "name": "Burnsville, MN",
      "correlation_id": "10691",
      "billing_address": null,
      "start_date": "2015-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 161,
        "name": "Collins Aerospace (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10689",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "126b4051-f68e-412e-832d-13f5939487db"
      },
      "parent_id": null,
      "lat": 44.743623,
      "lon": -93.31198,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ee82b6e6-9ea8-4fac-916c-3e51c7b836fd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Collins Aerospace (JLL)",
      "_label": "Collins Aerospace (JLL)",
      "_name": "Burnsville, MN",
      "_lat": 44.743623,
      "_lng": -93.31198,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686686,
          "create_date": "2024-02-16T12:25:12.469654Z",
          "update_date": "2024-02-16T12:25:12.469678Z",
          "organization_id": 161,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1ba66198d2044a67a2aacc1c0beaece8_Screenshot20240216at07.23_t.42",
          "size": 94362,
          "description": null,
          "link": null,
          "unique_identifier": "1ba66198d2044a67a2aacc1c0beaece8"
        }
      ],
      "lng": -93.31198
    },
    {
      "id": 5486,
      "name": "Butano (JLL)",
      "correlation_id": "102303",
      "billing_address": {
        "id": 2985,
        "street1": "2125 Butano Drive",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": null,
        "lat": 38.6052306,
        "lon": -121.3875739,
        "country": "United States"
      },
      "start_date": "2015-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 38.60483,
      "lon": -121.38792,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "806dc5df-332a-41ea-939a-6131fe8bd94a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Butano (JLL)",
      "_lat": 38.60483,
      "_lng": -121.38792,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -121.38792
    },
    {
      "id": 1367,
      "name": "BVAP - Belvidere Assembly Plant - Belvidere, IL",
      "correlation_id": "101716",
      "billing_address": {
        "id": 883,
        "street1": "3000 W Chrysler Dr",
        "street2": "",
        "city": "Belvidere",
        "region": "Illinois",
        "postal_code": "61008",
        "lat": 42.2368691,
        "lon": -88.8706944,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.2368691,
      "lon": -88.8706944,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86e504cb-ea38-4991-923a-457109bebdc6",
      "is_mobile": true,
      "mobile_live_date": "2022-05-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "BVAP - Belvidere Assembly Plant - Belvidere, IL",
      "_lat": 42.2368691,
      "_lng": -88.8706944,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -88.8706944
    },
    {
      "id": 1376,
      "name": "CAAP - Conner Ave Assembly Plant - Detroit, MI",
      "correlation_id": "101703",
      "billing_address": {
        "id": 903,
        "street1": "20000 Conner St",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48234",
        "lat": 42.443442,
        "lon": -83.0170029,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.443442,
      "lon": -83.0170029,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d15ca304-937a-422f-9294-ab62277544b3",
      "is_mobile": true,
      "mobile_live_date": "2022-07-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "CAAP - Conner Ave Assembly Plant - Detroit, MI",
      "_lat": 42.443442,
      "_lng": -83.0170029,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.0170029
    },
    {
      "id": 2307,
      "name": "Cal Center",
      "correlation_id": "101303",
      "billing_address": {
        "id": 744,
        "street1": "8950 Cal Center Dr.",
        "street2": "",
        "city": "Rancho Cordova",
        "region": "Colorado",
        "postal_code": "95655",
        "lat": 38.555245,
        "lon": -121.374443,
        "country": "United States"
      },
      "start_date": "2021-01-03T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 274,
        "name": "Basin Street Properties",
        "type": "CUSTOMER",
        "correlation_id": "11587",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7ed64a4-0d4f-47ae-8b1e-e8405d87e13c"
      },
      "parent_id": null,
      "lat": 38.55527,
      "lon": -121.37446,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "77342510-0c9a-461f-ae87-3e8e48c4bca0",
      "is_mobile": true,
      "mobile_live_date": "2021-09-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Basin Street Properties",
      "_label": "Basin Street Properties",
      "_name": "Cal Center",
      "_lat": 38.55527,
      "_lng": -121.37446,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.37446
    },
    {
      "id": 1955,
      "name": "Cambridge",
      "correlation_id": "11429",
      "billing_address": {
        "id": 3755,
        "street1": "225 Binney Street",
        "street2": "",
        "city": "Cambridge",
        "region": "Massachusetts",
        "postal_code": null,
        "lat": 42.3652992,
        "lon": -71.0872894,
        "country": "United States"
      },
      "start_date": "2018-12-22T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 253,
        "name": "Biogen (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11428",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
      },
      "parent_id": null,
      "lat": 42.3662897,
      "lon": -71.0857637,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
      "is_mobile": true,
      "mobile_live_date": "2020-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Biogen (CBRE)",
      "_label": "Biogen (CBRE)",
      "_name": "Cambridge",
      "_lat": 42.3662897,
      "_lng": -71.0857637,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859837,
          "create_date": "2020-11-17T14:36:01.480552Z",
          "update_date": "2020-11-17T14:36:01.480579Z",
          "organization_id": 253,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623761.388814_Biogen01_t.png",
          "size": 6068,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.0857637
    },
    {
      "id": 5828,
      "name": "Cambridge Central*",
      "correlation_id": "102748",
      "billing_address": null,
      "start_date": "2023-07-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 42.36081,
      "lon": -71.09966,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "447bf290-3990-4d32-b24d-f7addb23fd34",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Cambridge Central*",
      "_lat": 42.36081,
      "_lng": -71.09966,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.09966
    },
    {
      "id": 3299,
      "name": "Cambridge Kendall SQ",
      "correlation_id": "102095",
      "billing_address": {
        "id": 3021,
        "street1": "125 Binney St.",
        "street2": "",
        "city": "Cambridge",
        "region": "Massachusetts",
        "postal_code": null,
        "lat": 42.3658233,
        "lon": -71.08079,
        "country": "United States"
      },
      "start_date": "2018-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 42.36606901,
      "lon": -71.08077927,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
      "is_mobile": true,
      "mobile_live_date": "2023-01-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Cambridge Kendall SQ",
      "_lat": 42.36606901,
      "_lng": -71.08077927,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.08077927
    },
    {
      "id": 1993,
      "name": "Cambridge, MA",
      "correlation_id": "11639",
      "billing_address": null,
      "start_date": "2015-05-31T23:59:59Z",
      "total_sq_ft": "45000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 207,
        "name": "Merck",
        "type": "CUSTOMER",
        "correlation_id": "11459",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ac6c1c69-fd86-48b6-970a-06c86ce500db"
      },
      "parent_id": 2528,
      "lat": 42.3675577,
      "lon": -71.0896638,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0c9b1a03-eaca-4d0e-b83c-6d3d3190acc5",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck",
      "_label": "Merck",
      "_name": "Cambridge, MA",
      "_lat": 42.3675577,
      "_lng": -71.0896638,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953529,
          "create_date": "2020-12-17T16:57:39.106237Z",
          "update_date": "2020-12-17T16:57:39.106267Z",
          "organization_id": 207,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224258.8895628_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.0896638
    },
    {
      "id": 1999,
      "name": "Cambridge, MA",
      "correlation_id": "11657",
      "billing_address": {
        "id": 488,
        "street1": "181 Massachusetts Ave.",
        "street2": "",
        "city": "Cambridge",
        "region": "Alabama",
        "postal_code": "02139",
        "lat": 32.3182314,
        "lon": -86.902298,
        "country": "United States"
      },
      "start_date": "2019-12-31T23:59:59Z",
      "total_sq_ft": "1420281",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 270,
        "name": "Novartis (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
      },
      "parent_id": null,
      "lat": 42.373611,
      "lon": -71.110558,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "302a70b3-c3d6-468e-a8a4-d1ec9f4d9a80",
      "is_mobile": true,
      "mobile_live_date": "2021-03-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis (CBRE)",
      "_label": "Novartis (CBRE)",
      "_name": "Cambridge, MA",
      "_lat": 42.373611,
      "_lng": -71.110558,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859846,
          "create_date": "2020-11-17T14:36:58.595850Z",
          "update_date": "2020-11-17T14:36:58.595883Z",
          "organization_id": 270,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
          "size": 7078,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.110558
    },
    {
      "id": 3469,
      "name": "Cambridge, MA",
      "correlation_id": "101609",
      "billing_address": {
        "id": 1738,
        "street1": "238 Main St.",
        "street2": "",
        "city": "Cambridge",
        "region": "Massachusetts",
        "postal_code": null,
        "lat": 42.3621167,
        "lon": -71.0843383,
        "country": "United States"
      },
      "start_date": "2021-11-22T08:00:00Z",
      "total_sq_ft": "30000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a69c8b0c-fcfc-4393-97ea-7352cf5554c5",
      "is_mobile": true,
      "mobile_live_date": "2023-09-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Cambridge, MA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": 0
    },
    {
      "id": 2819,
      "name": "Cambridge, MA*",
      "correlation_id": "101761",
      "billing_address": null,
      "start_date": "2017-09-01T23:01:10Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 634,
        "name": "Massachusetts Institute of Tech (JLL)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c8e4013a-4602-4f8f-815a-f2dda05952ee"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bd9a3486-88f4-4787-933f-316e2eacdbb9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Massachusetts Institute of Tech (JLL)",
      "_label": "Massachusetts Institute of Tech (JLL)",
      "_name": "Cambridge, MA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5905,
      "name": "Cambridge, MA*",
      "correlation_id": "102815",
      "billing_address": null,
      "start_date": "2023-11-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1876,
        "name": "Bluerock Theraputics",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0595831f-6810-4412-bb4d-29f66d901206"
      },
      "parent_id": null,
      "lat": 42.36224,
      "lon": -71.08446,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d88a1934-b524-4644-bd75-e35226b079ec",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bluerock Theraputics",
      "_label": "Bluerock Theraputics",
      "_name": "Cambridge, MA*",
      "_lat": 42.36224,
      "_lng": -71.08446,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -71.08446
    },
    {
      "id": 1327,
      "name": "Campus Chandler -AZ",
      "correlation_id": "10336",
      "billing_address": {
        "id": 571,
        "street1": "2850 S Price Rd",
        "street2": "",
        "city": "Chandler",
        "region": "[{'long_name': 'Arizona', 'short_name': 'AZ', 'types': ['administrative_area_level_1', 'political']}]",
        "postal_code": "85286",
        "lat": 33.263911,
        "lon": -111.890674,
        "country": "US"
      },
      "start_date": "2013-11-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 33.265715,
      "lon": -111.8937404,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Campus Chandler -AZ",
      "_lat": 33.265715,
      "_lng": -111.8937404,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -111.8937404
    },
    {
      "id": 2087,
      "name": "Campus - MN",
      "correlation_id": "11692",
      "billing_address": {
        "id": 722,
        "street1": "2791 Well Fargo Way",
        "street2": "",
        "city": "Minneapolis",
        "region": "Minnesota",
        "postal_code": "55467",
        "lat": 44.9526737,
        "lon": -93.2702566,
        "country": "United States"
      },
      "start_date": "2014-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 44.9534026,
      "lon": -93.2726714,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Campus - MN",
      "_lat": 44.9534026,
      "_lng": -93.2726714,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.2726714
    },
    {
      "id": 5527,
      "name": "CAP – Chicago Assembly Plant – Chicago, IL",
      "correlation_id": "102377",
      "billing_address": {
        "id": 3012,
        "street1": "12600 S Torrence Ave.",
        "street2": "",
        "city": "Chicago",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.6632848,
        "lon": -87.5612149,
        "country": "United States"
      },
      "start_date": "2023-02-05T04:45:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 41.6632848,
      "lon": -87.5612149,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "85c2a706-1299-4532-9d38-cbac2cdb7dd2",
      "is_mobile": true,
      "mobile_live_date": "2023-01-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "CAP – Chicago Assembly Plant – Chicago, IL",
      "_lat": 41.6632848,
      "_lng": -87.5612149,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.5612149
    },
    {
      "id": 6137,
      "name": "Car Custodial*",
      "correlation_id": "103361",
      "billing_address": null,
      "start_date": "2023-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2338,
        "name": "Anc Car SA (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d93ce891-53af-4360-8def-1b5a4bf5c7c0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f25d6a1-2a42-4935-befc-f8328e690641",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Anc Car SA (CR)",
      "_label": "Anc Car SA (CR)",
      "_name": "Car Custodial*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1590,
      "name": "Cardinal Health",
      "correlation_id": "11127",
      "billing_address": {
        "id": 30,
        "street1": "Coyol Free Zone & Business Park 562 Parkway,  Building B20",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 9.9946658,
        "lon": -84.2709936,
        "country": "Costa Rica"
      },
      "start_date": "2016-04-28T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 164,
        "name": "Cardinal Health (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11126",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0ffcb7ca-fb13-467a-b0b9-e894e9e6eab8"
      },
      "parent_id": null,
      "lat": 10.391583,
      "lon": -84.4382721,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2f9f45b6-8662-4e40-b9dc-0fad84edf9f6",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (CR)",
      "_label": "Cardinal Health (CR)",
      "_name": "Cardinal Health",
      "_lat": 10.391583,
      "_lng": -84.4382721,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.4382721
    },
    {
      "id": 3142,
      "name": "Cardio Vascular",
      "correlation_id": "101602",
      "billing_address": {
        "id": 3019,
        "street1": "V28X+9CQ, Provincia de Cartago, Cartago, Costa Rica",
        "street2": "",
        "city": "Costa Rica",
        "region": "Alabama",
        "postal_code": null,
        "lat": null,
        "lon": null,
        "country": "Costa Rica"
      },
      "start_date": "2023-01-27T19:53:53.021000Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 481,
        "name": "Terumo (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 719,
            "street1": "Zona Industrial La Lima de Cartago",
            "street2": "",
            "city": "Cartago",
            "region": "Cartago",
            "postal_code": "607354",
            "lat": 9.8520126,
            "lon": -83.9470982,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "91d9787d-902b-40e0-b804-ca9c29cb2a98"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d4be9b54-b4ef-4ed7-8696-af78f13dfca0",
      "is_mobile": true,
      "mobile_live_date": "2023-01-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Terumo (CR)",
      "_label": "Terumo (CR)",
      "_name": "Cardio Vascular",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1661,
      "name": "Carlsbad, CA",
      "correlation_id": "11252",
      "billing_address": {
        "id": 489,
        "street1": "6211 El Camino Real",
        "street2": "",
        "city": "Carlsbad",
        "region": "California",
        "postal_code": "92009",
        "lat": 33.1242795,
        "lon": -117.270092,
        "country": "United States"
      },
      "start_date": "2018-04-30T23:59:59Z",
      "total_sq_ft": "59010",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 190,
        "name": "Millipore Sigma",
        "type": "CUSTOMER",
        "correlation_id": "11251",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf5d01db-498a-4b31-bc79-2fb34708bb60"
      },
      "parent_id": null,
      "lat": 33.1241799,
      "lon": -117.2701765,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8684ca88-2620-40ea-8857-94c12a35aab3",
      "is_mobile": true,
      "mobile_live_date": "2020-06-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Millipore Sigma",
      "_label": "Millipore Sigma",
      "_name": "Carlsbad, CA",
      "_lat": 33.1241799,
      "_lng": -117.2701765,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.2701765
    },
    {
      "id": 1799,
      "name": "Carlsbad RDx",
      "correlation_id": "100334",
      "billing_address": {
        "id": 379,
        "street1": "2251 Faraday Avenue",
        "street2": "",
        "city": "Carlsbad",
        "region": "California",
        "postal_code": "92008",
        "lat": 33.1336994,
        "lon": -117.2773493,
        "country": "United States"
      },
      "start_date": "2018-10-01T00:00:00Z",
      "total_sq_ft": "99491",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 33.135705,
      "lon": -117.275441,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d82d7ed7-e80a-4f75-8a88-0b8f1d1686ff",
      "is_mobile": true,
      "mobile_live_date": "2021-02-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Carlsbad RDx",
      "_lat": 33.135705,
      "_lng": -117.275441,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.275441
    },
    {
      "id": 2551,
      "name": "Carmel Valley",
      "correlation_id": "102143",
      "billing_address": {
        "id": 537,
        "street1": "100 Clock Tower Pl",
        "street2": "",
        "city": "Carmel",
        "region": "California",
        "postal_code": "93923",
        "lat": 36.5395363,
        "lon": -121.9052631,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6b34187f-740a-45aa-be7b-48ce83ed2dca",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Carmel Valley",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1638,
      "name": "Carson*",
      "correlation_id": "11200",
      "billing_address": null,
      "start_date": "2019-10-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 135,
        "name": "Xerox (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10758",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5e49ffe1-2f29-4391-a283-62207d27fee9"
      },
      "parent_id": null,
      "lat": 33.8675791,
      "lon": -118.2809131,
      "local_timezone": {
        "id": 2208,
        "code": "America/Indiana/Tell_City",
        "display_name": "America/Indiana/Tell_City",
        "description": "",
        "child_group": null,
        "alt_sequence": 74
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "36eddf03-80cd-40a0-9970-c870f10ed054",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Xerox (JLL)",
      "_label": "Xerox (JLL)",
      "_name": "Carson*",
      "_lat": 33.8675791,
      "_lng": -118.2809131,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10767468,
          "create_date": "2024-02-21T19:02:11.125759Z",
          "update_date": "2024-02-21T19:02:11.125781Z",
          "organization_id": 135,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/339e6ea28cd34e4fb23bec97f35320ce_xeroxlogo_t.png",
          "size": 20821,
          "description": null,
          "link": null,
          "unique_identifier": "339e6ea28cd34e4fb23bec97f35320ce"
        }
      ],
      "lng": -118.2809131
    },
    {
      "id": 1362,
      "name": "Cartago",
      "correlation_id": "10407",
      "billing_address": {
        "id": 25,
        "street1": "Parque Industrial Zeta, El Guarco. Avenida las Américas,",
        "street2": "",
        "city": "El Guarco",
        "region": "Alabama",
        "postal_code": "30801",
        "lat": 9.7250952,
        "lon": -83.9214896,
        "country": "Costa Rica"
      },
      "start_date": "2015-11-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 96,
        "name": "Baxter (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10406",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d4588eac-4faa-4b9e-a67d-0c40aef6f3ec"
      },
      "parent_id": null,
      "lat": 9.8560723,
      "lon": -83.9542902,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "207fe108-98c0-4195-b593-a7194344f837",
      "is_mobile": true,
      "mobile_live_date": "2021-11-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Baxter (CR)",
      "_label": "Baxter (CR)",
      "_name": "Cartago",
      "_lat": 9.8560723,
      "_lng": -83.9542902,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.9542902
    },
    {
      "id": 1916,
      "name": "Cartago",
      "correlation_id": "10796",
      "billing_address": {
        "id": 67,
        "street1": "Coris de Cartago, detrás del parque Industrial Zeta",
        "street2": "",
        "city": "Cartago",
        "region": "Alabama",
        "postal_code": "30106",
        "lat": 9.8638091,
        "lon": -83.9161935,
        "country": "Costa Rica"
      },
      "start_date": "2018-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 251,
        "name": "Paradise Ingredients (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10795",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f3a3d464-5f0e-4d0a-80a9-dc054d3f58bd"
      },
      "parent_id": null,
      "lat": 9.8622311,
      "lon": -83.9214187,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ae69e6ff-0861-4cdc-914f-ab6be0c15a82",
      "is_mobile": true,
      "mobile_live_date": "2021-11-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Paradise Ingredients (CR)",
      "_label": "Paradise Ingredients (CR)",
      "_name": "Cartago",
      "_lat": 9.8622311,
      "_lng": -83.9214187,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.9214187
    },
    {
      "id": 3431,
      "name": "Cartago",
      "correlation_id": "102014",
      "billing_address": {
        "id": 1870,
        "street1": "La Lima Free Zone",
        "street2": "",
        "city": "Costa Rica",
        "region": "Alabama",
        "postal_code": null,
        "lat": 9.8683434,
        "lon": -83.9488982,
        "country": "Costa Rica"
      },
      "start_date": "2022-03-27T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 104,
        "name": "Boston Scientific (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10436",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4247ddd5-edc0-4232-ab9b-9cacd7459d46"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b027fb9d-3cc0-4129-9933-a96553fe2e6c",
      "is_mobile": true,
      "mobile_live_date": "2022-06-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boston Scientific (CR)",
      "_label": "Boston Scientific (CR)",
      "_name": "Cartago",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1828,
      "name": "Casa Grande",
      "correlation_id": "10621",
      "billing_address": {
        "id": 383,
        "street1": "1250 W. Maricopa Hwy",
        "street2": "",
        "city": "Casa Grande",
        "region": "Arizona",
        "postal_code": "85193",
        "lat": 32.8782747,
        "lon": -111.756576,
        "country": "United States"
      },
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 32.8972031,
      "lon": -111.7850677,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9ec42fc1-1bda-4cd3-9391-7f1b1187913f",
      "is_mobile": true,
      "mobile_live_date": "2021-05-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Casa Grande",
      "_lat": 32.8972031,
      "_lng": -111.7850677,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.7850677
    },
    {
      "id": 5858,
      "name": "CBB – Crash Barrier – Dearborn, MI",
      "correlation_id": "102792",
      "billing_address": {
        "id": 4112,
        "street1": "pending address",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.3222599,
        "lon": -83.1763145,
        "country": "United States"
      },
      "start_date": "2023-09-28T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
      "is_mobile": true,
      "mobile_live_date": "2020-11-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "CBB – Crash Barrier – Dearborn, MI",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1092,
      "name": "CBP",
      "correlation_id": "10882",
      "billing_address": null,
      "start_date": "2016-02-01T00:00:00Z",
      "total_sq_ft": "320000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 34,
        "name": "IBM (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10881",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c0657c07-3307-427f-a2af-5b1ec564f16c"
      },
      "parent_id": null,
      "lat": 1.3363799,
      "lon": 103.965487,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "131df1d2-27f0-4a50-bafc-428d08df1ab5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IBM (SG) (CBRE)",
      "_label": "IBM (SG) (CBRE)",
      "_name": "CBP",
      "_lat": 1.3363799,
      "_lng": 103.965487,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.965487
    },
    {
      "id": 2650,
      "name": "CBRE00OVRHD",
      "correlation_id": "101595",
      "billing_address": null,
      "start_date": "2021-10-21T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f98d067d-b816-4e84-89b0-69647fd5b135",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "CBRE00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5544,
      "name": "Cedar Rapids Downtown",
      "correlation_id": "102358",
      "billing_address": {
        "id": 3063,
        "street1": "150 1st Ave NE",
        "street2": "",
        "city": "Cedar Rapids",
        "region": "Iowa",
        "postal_code": null,
        "lat": 41.9787834,
        "lon": -91.6692618,
        "country": "United States"
      },
      "start_date": "2024-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 41.97856,
      "lon": -91.66904,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b81ab5f3-6dcd-4e50-969b-56157fdb7d82",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Cedar Rapids Downtown",
      "_lat": 41.97856,
      "_lng": -91.66904,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -91.66904
    },
    {
      "id": 2107,
      "name": "Cedral",
      "correlation_id": "11714",
      "billing_address": {
        "id": 806,
        "street1": "Centro Corporativo El Cedral",
        "street2": "",
        "city": "San Jose",
        "region": "Alabama",
        "postal_code": "000000",
        "lat": 32.3182314,
        "lon": -86.902298,
        "country": "United States"
      },
      "start_date": "2019-10-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 115,
        "name": "Uber (CBRE) (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10479",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a0fa919b-e92b-469f-9fec-38f2bd9fa7a4"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c8a55f2e-c98e-4e93-b76d-d65741bd4c0a",
      "is_mobile": true,
      "mobile_live_date": "2021-11-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Uber (CBRE) (CR)",
      "_label": "Uber (CBRE) (CR)",
      "_name": "Cedral",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 932145,
          "create_date": "2020-12-10T19:10:56.836917Z",
          "update_date": "2020-12-10T19:10:56.836940Z",
          "organization_id": 115,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607627456.7434294_UberCR_t.jpg",
          "size": 7874,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 6765,
      "name": "Celanese*",
      "correlation_id": "103425",
      "billing_address": null,
      "start_date": "2024-02-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2604,
        "name": "Mylar Specialty Films",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "71541adc-90e6-455e-8727-fbbdcf8b0a9d"
      },
      "parent_id": null,
      "lat": 37.454031,
      "lon": -77.427381,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a13916ee-9c2c-45d8-a430-07c36eec67ed",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Mylar Specialty Films",
      "_label": "Mylar Specialty Films",
      "_name": "Celanese*",
      "_lat": 37.454031,
      "_lng": -77.427381,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.427381
    },
    {
      "id": 5755,
      "name": "Celanese - Richmond VA",
      "correlation_id": "102595",
      "billing_address": null,
      "start_date": "2023-05-24T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 326,
        "name": "DuPont",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4668b06b-a547-4504-b0aa-e2be16bac9b4"
      },
      "parent_id": null,
      "lat": 37.454031,
      "lon": -77.427381,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "95319829-e507-49db-9e41-f7c4813153b5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DuPont",
      "_label": "DuPont",
      "_name": "Celanese - Richmond VA",
      "_lat": 37.454031,
      "_lng": -77.427381,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698154,
          "create_date": "2024-02-16T20:28:33.946575Z",
          "update_date": "2024-02-16T20:28:33.946598Z",
          "organization_id": 326,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8506194989694f86b1f7259ff193d285_logo_t.PNG",
          "size": 5219,
          "description": null,
          "link": null,
          "unique_identifier": "8506194989694f86b1f7259ff193d285"
        }
      ],
      "lng": -77.427381
    },
    {
      "id": 3234,
      "name": "CENTENNIAL CO",
      "correlation_id": "102028",
      "billing_address": {
        "id": 1374,
        "street1": "9100 E Mineral Circle",
        "street2": "",
        "city": null,
        "region": "Colorado",
        "postal_code": "80112",
        "lat": 39.5753075,
        "lon": -104.882405,
        "country": "United States"
      },
      "start_date": "2022-03-30T17:17:18Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 922,
        "name": "Centura Health",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1373,
            "street1": "9100 E Mineral Circle",
            "street2": "",
            "city": "Centinennial",
            "region": "Colorado",
            "postal_code": "80112",
            "lat": 39.5753075,
            "lon": -104.882405,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "c40939a1-e2c9-4b40-987b-f5bbc07f9afc"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "025f4c43-d69e-486b-af6c-863dfec9b7d6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Centura Health",
      "_label": "Centura Health",
      "_name": "CENTENNIAL CO",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1146,
      "name": "Center Valley",
      "correlation_id": "10983",
      "billing_address": {
        "id": 1127,
        "street1": "3501 Corporate Parkway",
        "street2": "",
        "city": "Center Valley",
        "region": "Pennsylvania",
        "postal_code": "18034",
        "lat": 40.5541195,
        "lon": -75.4199965,
        "country": "United States"
      },
      "start_date": "2013-07-29T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 61,
        "name": "Dun & Bradstreet",
        "type": "CUSTOMER",
        "correlation_id": "10982",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0048efdf-47e7-48af-ae6c-b33779ff362a"
      },
      "parent_id": null,
      "lat": 40.5541019,
      "lon": -75.4199978,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0daf1f1c-d28c-4854-adf9-3669fa26ae7b",
      "is_mobile": true,
      "mobile_live_date": "2021-11-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Dun & Bradstreet",
      "_label": "Dun & Bradstreet",
      "_name": "Center Valley",
      "_lat": 40.5541019,
      "_lng": -75.4199978,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -75.4199978
    },
    {
      "id": 5497,
      "name": "Central",
      "correlation_id": "102405",
      "billing_address": {
        "id": 2994,
        "street1": "2329 Central Avenue Northeast",
        "street2": "",
        "city": "Minneapolis",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 45.0117979,
        "lon": -93.2467593,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "48755",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 45.0117,
      "lon": -93.24736,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Central",
      "_lat": 45.0117,
      "_lng": -93.24736,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.24736
    },
    {
      "id": 2056,
      "name": "Central Falls",
      "correlation_id": "11661",
      "billing_address": null,
      "start_date": "2018-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 129,
        "name": "Hasbro (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10569",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "432db518-6de6-4823-a9d5-c8589c848920"
      },
      "parent_id": null,
      "lat": 41.894004,
      "lon": -71.3890557,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "97c94356-57ce-43f5-a1fa-503d9a436930",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hasbro (JLL)",
      "_label": "Hasbro (JLL)",
      "_name": "Central Falls",
      "_lat": 41.894004,
      "_lng": -71.3890557,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026237,
          "create_date": "2024-03-07T18:22:00.313766Z",
          "update_date": "2024-03-07T18:22:00.313790Z",
          "organization_id": 129,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/7c7c1e41c753408d83b43be30ae995f6_Hasbro_logosvg_t.png",
          "size": 151554,
          "description": null,
          "link": null,
          "unique_identifier": "7c7c1e41c753408d83b43be30ae995f6"
        }
      ],
      "lng": -71.3890557
    },
    {
      "id": 1723,
      "name": "CENTRALOH",
      "correlation_id": "11353",
      "billing_address": {
        "id": 636,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d5a6b826-a36f-4f2b-8d99-ed4ee7e04916",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CENTRALOH",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1647,
      "name": "Central SQ",
      "correlation_id": "11218",
      "billing_address": {
        "id": 459,
        "street1": "300 Massachusetts Ave.",
        "street2": "",
        "city": "Cambridge",
        "region": "Massachusetts",
        "postal_code": "02139",
        "lat": 42.3622734,
        "lon": -71.098686,
        "country": "United States"
      },
      "start_date": "2018-07-01T23:59:59Z",
      "total_sq_ft": "4728000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 42.3604977,
      "lon": -71.0995145,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Central SQ",
      "_lat": 42.3604977,
      "_lng": -71.0995145,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.0995145
    },
    {
      "id": 5798,
      "name": "CEP – Cleveland Engine Plant – Brook Park, OH",
      "correlation_id": "102725",
      "billing_address": {
        "id": 3785,
        "street1": "5600 Henry Ford Blvd.",
        "street2": "",
        "city": "Brook Park",
        "region": "Ohio",
        "postal_code": null,
        "lat": 41.414983,
        "lon": -81.824476,
        "country": "United States"
      },
      "start_date": "2023-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 41.40948,
      "lon": -81.81978,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8b656f84-9cf0-4ae3-b729-5b6e4e3fd8da",
      "is_mobile": true,
      "mobile_live_date": "2023-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "CEP – Cleveland Engine Plant – Brook Park, OH",
      "_lat": 41.40948,
      "_lng": -81.81978,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -81.81978
    },
    {
      "id": 1724,
      "name": "CESS00OVHD",
      "correlation_id": "11354",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4969a4a4-a3a5-4c94-b1c9-4e503adbb4ad",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CESS00OVHD",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1342,
      "name": "Cessna Mesa*",
      "correlation_id": "10357",
      "billing_address": null,
      "start_date": "2005-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 33.3169829,
      "lon": -111.6776736,
      "local_timezone": {
        "id": 2438,
        "code": "Africa/Abidjan",
        "display_name": "Africa/Abidjan",
        "description": "",
        "child_group": null,
        "alt_sequence": 304
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4315b324-0b22-4a67-b2bf-822bfc2234c2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Cessna Mesa*",
      "_lat": 33.3169829,
      "_lng": -111.6776736,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -111.6776736
    },
    {
      "id": 1809,
      "name": "Chandler",
      "correlation_id": "102702",
      "billing_address": {
        "id": 366,
        "street1": "4625 E Cotton Center Blvd, Suite 177",
        "street2": "",
        "city": "Phoenix",
        "region": "Arizona",
        "postal_code": "480",
        "lat": 33.4007794,
        "lon": -111.9816957,
        "country": "United States"
      },
      "start_date": "2019-07-15T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.3055493,
      "lon": -111.8712977,
      "local_timezone": {
        "id": 2164,
        "code": "America/Boise",
        "display_name": "America/Boise",
        "description": "",
        "child_group": null,
        "alt_sequence": 30
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
      "is_mobile": true,
      "mobile_live_date": "2021-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Chandler",
      "_lat": 33.3055493,
      "_lng": -111.8712977,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.8712977
    },
    {
      "id": 3856,
      "name": "Chandler, AZ",
      "correlation_id": "101939",
      "billing_address": null,
      "start_date": "2021-08-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 33.30993,
      "lon": -111.96738,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "671e35fe-e2cf-4f82-8503-4ab3084f8277",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "Chandler, AZ",
      "_lat": 33.30993,
      "_lng": -111.96738,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": -111.96738
    },
    {
      "id": 5806,
      "name": "Chandler, AZ*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-07-13T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2153,
        "name": "SDB Construction",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "12a47b96-8bc7-4b9e-ab6e-aca5ae57a4ac"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e0af443c-6cf9-4284-93d4-613c01be530c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SDB Construction",
      "_label": "SDB Construction",
      "_name": "Chandler, AZ*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2572,
      "name": "Chandler, AZ (JLL)",
      "correlation_id": "101515",
      "billing_address": {
        "id": 563,
        "street1": "2900 W. Geronimo Place",
        "street2": "",
        "city": "Chandler",
        "region": "Arizona",
        "postal_code": "85224",
        "lat": 33.2941642,
        "lon": -111.8909535,
        "country": "United States"
      },
      "start_date": "2021-07-01T21:57:42Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
      },
      "parent_id": null,
      "lat": 33.2941642,
      "lon": -111.8909535,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bb930400-1798-4e7c-8a67-439c9e781c3a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GM",
      "_label": "GM",
      "_name": "Chandler, AZ (JLL)",
      "_lat": 33.2941642,
      "_lng": -111.8909535,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853049,
          "create_date": "2020-11-14T00:00:06.049828Z",
          "update_date": "2020-11-14T00:00:06.049855Z",
          "organization_id": 137,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
          "size": 1080345,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.8909535
    },
    {
      "id": 1917,
      "name": "Changi Business Park (CBP)",
      "correlation_id": "10798",
      "billing_address": null,
      "start_date": "2016-06-01T00:00:00Z",
      "total_sq_ft": "5120000",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 252,
        "name": "SCB (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10786",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3c5ff5ca-946c-48c2-a279-417f871c9969"
      },
      "parent_id": null,
      "lat": 1.3348269,
      "lon": 103.9665665,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0501a93a-7791-46ec-a49e-7c1008b64649",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SCB (SG) (CBRE)",
      "_label": "SCB (SG) (CBRE)",
      "_name": "Changi Business Park (CBP)",
      "_lat": 1.3348269,
      "_lng": 103.9665665,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10833080,
          "create_date": "2024-02-26T15:45:18.559062Z",
          "update_date": "2024-02-26T15:45:18.559085Z",
          "organization_id": 252,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8ccd474a0aa04ae085f21c36240403aa_1_t.png",
          "size": 775522,
          "description": null,
          "link": null,
          "unique_identifier": "8ccd474a0aa04ae085f21c36240403aa"
        }
      ],
      "lng": 103.9665665
    },
    {
      "id": 1069,
      "name": "Charles City",
      "correlation_id": "10849",
      "billing_address": {
        "id": 403,
        "street1": "2000 Rockford Road",
        "street2": "",
        "city": "Charles City",
        "region": "Iowa",
        "postal_code": "50616",
        "lat": 43.0592874,
        "lon": -92.6982871,
        "country": "United States"
      },
      "start_date": "2011-05-01T14:32:57Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 25,
        "name": "Zoetis",
        "type": "CUSTOMER",
        "correlation_id": "10847",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ea4d6ed7-b2bc-419d-bf29-2bc14d32cf15"
      },
      "parent_id": null,
      "lat": 43.0592874,
      "lon": -92.6982871,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86623bd3-9f40-4a3d-8d0d-1e286ebdc3b6",
      "is_mobile": true,
      "mobile_live_date": "2020-08-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Zoetis",
      "_label": "Zoetis",
      "_name": "Charles City",
      "_lat": 43.0592874,
      "_lng": -92.6982871,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -92.6982871
    },
    {
      "id": 6733,
      "name": "Charleston*",
      "correlation_id": "103403",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 32.9048,
      "lon": -79.91237,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f822ed7-3a55-44be-9272-4275383bd8e3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "Charleston*",
      "_lat": 32.9048,
      "_lng": -79.91237,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": -79.91237
    },
    {
      "id": 1123,
      "name": "Charlotte",
      "correlation_id": "10951",
      "billing_address": null,
      "start_date": "2014-01-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 51,
        "name": "Prinston Labs",
        "type": "CUSTOMER",
        "correlation_id": "10950",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f72e87d-fb5c-4658-ba36-38965cfbffed"
      },
      "parent_id": null,
      "lat": 35.2676603,
      "lon": -80.8222182,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a8498ccd-87d0-49b8-8de3-5c715118e77a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Prinston Labs",
      "_label": "Prinston Labs",
      "_name": "Charlotte",
      "_lat": 35.2676603,
      "_lng": -80.8222182,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -80.8222182
    },
    {
      "id": 2681,
      "name": "Charlottesville, VA",
      "correlation_id": "101591",
      "billing_address": {
        "id": 802,
        "street1": "1500 State Farm Blvd",
        "street2": "",
        "city": "Charlotte",
        "region": "Virginia",
        "postal_code": "22909",
        "lat": 38.0228509,
        "lon": -78.4499721,
        "country": "United States"
      },
      "start_date": "2021-11-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2734,
        "name": "State Farm (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "32aff8f6-eb4c-4b7e-862c-0ba02ba9d1e1"
      },
      "parent_id": null,
      "lat": 38.02241,
      "lon": -78.44999,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "03307a6c-63d5-44ef-9e7b-050479a0f7a0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE) - Sub",
      "_label": "State Farm (CBRE) - Sub",
      "_name": "Charlottesville, VA",
      "_lat": 38.02241,
      "_lng": -78.44999,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -78.44999
    },
    {
      "id": 1486,
      "name": "Cherokee, PA",
      "correlation_id": "10071",
      "billing_address": {
        "id": 676,
        "street1": "100 Avenue C",
        "street2": "",
        "city": "Riverside",
        "region": "Pennsylvania",
        "postal_code": "17868",
        "lat": 40.9614032,
        "lon": -76.6365697,
        "country": "United States"
      },
      "start_date": "2013-11-01T23:59:59Z",
      "total_sq_ft": "6380000",
      "total_buildings": "67",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 267,
        "name": "Merck (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
      },
      "parent_id": null,
      "lat": 40.96156646,
      "lon": -76.63851066,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
      "is_mobile": true,
      "mobile_live_date": "2021-10-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck (CBRE)",
      "_label": "Merck (CBRE)",
      "_name": "Cherokee, PA",
      "_lat": 40.96156646,
      "_lng": -76.63851066,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953527,
          "create_date": "2020-12-17T16:57:21.149904Z",
          "update_date": "2020-12-17T16:57:21.149930Z",
          "organization_id": 267,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -76.63851066
    },
    {
      "id": 2007,
      "name": "Chesterfield, MO",
      "correlation_id": "100958",
      "billing_address": {
        "id": 3759,
        "street1": "700 Chesterfield Parkway",
        "street2": "",
        "city": "Chesterfield",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.6640367,
        "lon": -90.5569069,
        "country": "United States"
      },
      "start_date": "2013-11-13T23:59:59Z",
      "total_sq_ft": "1649073",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 38.662989,
      "lon": -90.5591256,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "df313adb-4305-4083-9973-cd6d9b3c32f3",
      "is_mobile": true,
      "mobile_live_date": "2020-09-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Chesterfield, MO",
      "_lat": 38.662989,
      "_lng": -90.5591256,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -90.5591256
    },
    {
      "id": 2544,
      "name": "Cheyenne",
      "correlation_id": "102141",
      "billing_address": null,
      "start_date": "2021-06-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 41.1607538,
      "lon": -104.7995973,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db583f5a-40cb-4a1a-96b0-346721e1cc73",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Cheyenne",
      "_lat": 41.1607538,
      "_lng": -104.7995973,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -104.7995973
    },
    {
      "id": 5977,
      "name": "CHICAGO 20*",
      "correlation_id": "102872",
      "billing_address": null,
      "start_date": "2023-11-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 41.92782,
      "lon": -87.85933,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3fa7ed8b-d7e3-4f0b-900e-3db28c9a580f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "CHICAGO 20*",
      "_lat": 41.92782,
      "_lng": -87.85933,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.85933
    },
    {
      "id": 5978,
      "name": "CHICAGO 22*",
      "correlation_id": "102873",
      "billing_address": null,
      "start_date": "2023-11-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 41.92917,
      "lon": -87.86152,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e88bcf7b-e607-4a1c-a6f8-fb435f30a6df",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "CHICAGO 22*",
      "_lat": 41.92917,
      "_lng": -87.86152,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.86152
    },
    {
      "id": 2267,
      "name": "Chicago, IL",
      "correlation_id": "101289",
      "billing_address": {
        "id": 185,
        "street1": "2200 Busse Road",
        "street2": "",
        "city": "Elk Grove Village",
        "region": "Illinois",
        "postal_code": "60007",
        "lat": 41.9942563,
        "lon": -87.9619655,
        "country": "United States"
      },
      "start_date": "2020-12-07T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
      "is_mobile": true,
      "mobile_live_date": "2021-02-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Chicago, IL",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2480,
      "name": "Chicago, IL",
      "correlation_id": "101404",
      "billing_address": {
        "id": 327,
        "street1": "4201 Medical Centre Dr",
        "street2": "",
        "city": "McHenry",
        "region": "Illinois",
        "postal_code": "60050",
        "lat": 42.3162973,
        "lon": -88.2781265,
        "country": "United States"
      },
      "start_date": "2022-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 447,
        "name": "GE Healthcare",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 320,
            "street1": "3300 Mercy Health Blvd",
            "street2": "",
            "city": "Cincinnati",
            "region": "OH",
            "postal_code": "45211",
            "lat": 39.1802011,
            "lon": -84.5968153,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "2da69092-ba9c-43de-bff7-d27dc0705eaa"
      },
      "parent_id": null,
      "lat": 42.31817,
      "lon": -88.27897,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8deaea3c-fad5-497f-b450-ca7e1bd03873",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Healthcare",
      "_label": "GE Healthcare",
      "_name": "Chicago, IL",
      "_lat": 42.31817,
      "_lng": -88.27897,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025818,
          "create_date": "2024-03-07T18:08:58.813413Z",
          "update_date": "2024-03-07T18:08:58.813437Z",
          "organization_id": 447,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/7a33f9c63a9140fd9b180f72c56c2a74_ge_healthcare_logo_t.png",
          "size": 18070,
          "description": null,
          "link": null,
          "unique_identifier": "7a33f9c63a9140fd9b180f72c56c2a74"
        }
      ],
      "lng": -88.27897
    },
    {
      "id": 5577,
      "name": "Chicago* (JLL)",
      "correlation_id": "102252",
      "billing_address": null,
      "start_date": "2023-02-03T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4b231114-dd09-4af5-98fc-15dd369a9ef3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Chicago* (JLL)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 3994,
      "name": "Chilco",
      "correlation_id": "102102",
      "billing_address": {
        "id": 1969,
        "street1": "190 Jefferson Dr.",
        "street2": "",
        "city": "Menlo Park",
        "region": "California",
        "postal_code": "94025",
        "lat": 37.4807649,
        "lon": -122.1704363,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 37.48135,
      "lon": -122.17006,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
      "is_mobile": true,
      "mobile_live_date": "2022-11-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Chilco",
      "_lat": 37.48135,
      "_lng": -122.17006,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": -122.17006
    },
    {
      "id": 3169,
      "name": "Chin Bee",
      "correlation_id": "101849",
      "billing_address": null,
      "start_date": "2022-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2510,
        "name": "Iron Mountain (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "09ffb73b-dee9-4619-9580-56b73ddaf5bb"
      },
      "parent_id": null,
      "lat": 1.33664,
      "lon": 103.71755,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "10ab119f-48af-4a4f-befe-053083fa6bab",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Iron Mountain (CBRE) (SG)",
      "_label": "Iron Mountain (CBRE) (SG)",
      "_name": "Chin Bee",
      "_lat": 1.33664,
      "_lng": 103.71755,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.71755
    },
    {
      "id": 2425,
      "name": "Chisholm, MN- IRC Reservations",
      "correlation_id": "101383",
      "billing_address": {
        "id": 249,
        "street1": "601 Iron Dr.",
        "street2": "",
        "city": "Chislom",
        "region": "Minnesota",
        "postal_code": "55719",
        "lat": 47.4809076,
        "lon": -92.8914032,
        "country": "United States"
      },
      "start_date": "2021-04-01T23:59:59Z",
      "total_sq_ft": "41600",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 47.38407,
      "lon": -92.84758,
      "local_timezone": {
        "id": 2205,
        "code": "America/Indiana/Knox",
        "display_name": "America/Indiana/Knox",
        "description": "",
        "child_group": null,
        "alt_sequence": 71
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7c177779-2944-4549-9ff6-e0d733c1d640",
      "is_mobile": true,
      "mobile_live_date": "2021-04-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "Chisholm, MN- IRC Reservations",
      "_lat": 47.38407,
      "_lng": -92.84758,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -92.84758
    },
    {
      "id": 1507,
      "name": "Churchill",
      "correlation_id": "10583",
      "billing_address": {
        "id": 707,
        "street1": "1332 Beulah Rd",
        "street2": "",
        "city": "Pittsburg",
        "region": "Pennsylvania",
        "postal_code": "15235",
        "lat": 40.4397585,
        "lon": -79.8483184,
        "country": "United States"
      },
      "start_date": "2016-04-16T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 40.4411227,
      "lon": -79.8452034,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "572b961b-fa6b-4361-ae58-fd57532195bd",
      "is_mobile": true,
      "mobile_live_date": "2021-08-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Churchill",
      "_lat": 40.4411227,
      "_lng": -79.8452034,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -79.8452034
    },
    {
      "id": 5790,
      "name": "CIC",
      "correlation_id": "102718",
      "billing_address": null,
      "start_date": "2023-07-01T15:35:36Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
      },
      "parent_id": null,
      "lat": 33.9290528,
      "lon": -84.3468382,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0646f543-0824-4de7-89a6-2af2c217f0b4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Coca Cola",
      "_label": "Coca Cola",
      "_name": "CIC",
      "_lat": 33.9290528,
      "_lng": -84.3468382,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10560545,
          "create_date": "2024-02-08T17:24:10.318130Z",
          "update_date": "2024-02-08T17:24:10.318155Z",
          "organization_id": 2145,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
          "size": 22904,
          "description": null,
          "link": null,
          "unique_identifier": "9182f361e2ff41d3bded657bde583842"
        }
      ],
      "lng": -84.3468382
    },
    {
      "id": 1453,
      "name": "Cincinnati",
      "correlation_id": "10573",
      "billing_address": {
        "id": 413,
        "street1": "1624 Herald Ave",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45207",
        "lat": 39.1503841,
        "lon": -84.4718356,
        "country": "United States"
      },
      "start_date": "2018-07-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "28",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 109,
        "name": "Xavier University",
        "type": "CUSTOMER",
        "correlation_id": "10448",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08de1eb4-9530-416c-af73-82609adab969"
      },
      "parent_id": null,
      "lat": 39.1489123,
      "lon": -84.4796222,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a5c68adf-12e0-46c8-b2f6-6b9890c022b5",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Xavier University",
      "_label": "Xavier University",
      "_name": "Cincinnati",
      "_lat": 39.1489123,
      "_lng": -84.4796222,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.4796222
    },
    {
      "id": 1512,
      "name": "Cincinnati",
      "correlation_id": "10590",
      "billing_address": null,
      "start_date": "2015-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 140,
        "name": "Mallinckrodt",
        "type": "CUSTOMER",
        "correlation_id": "10584",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "320ded50-a989-4432-8212-a2af5ced81ad"
      },
      "parent_id": null,
      "lat": 39.210084,
      "lon": -84.4442936,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d6949178-e3ce-453c-977c-22303053af1a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Mallinckrodt",
      "_label": "Mallinckrodt",
      "_name": "Cincinnati",
      "_lat": 39.210084,
      "_lng": -84.4442936,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.4442936
    },
    {
      "id": 1823,
      "name": "Cincinnati",
      "correlation_id": "11560",
      "billing_address": {
        "id": 687,
        "street1": "5000 Brotherton Rd, Cincinnati. OH",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45209",
        "lat": 39.153127,
        "lon": -84.4072535,
        "country": "United States"
      },
      "start_date": "2019-07-23T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 75,
        "name": "AbbVie",
        "type": "CUSTOMER",
        "correlation_id": "10135",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
      },
      "parent_id": null,
      "lat": 39.1031182,
      "lon": -84.5120196,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
      "is_mobile": true,
      "mobile_live_date": "2021-08-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AbbVie",
      "_label": "AbbVie",
      "_name": "Cincinnati",
      "_lat": 39.1031182,
      "_lng": -84.5120196,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 5630176,
          "create_date": "2023-01-27T16:07:24.909099Z",
          "update_date": "2023-01-27T16:07:24.909120Z",
          "organization_id": 75,
          "uploaded_by_id": 43918,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
          "size": 5975,
          "description": null,
          "link": null,
          "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
        }
      ],
      "lng": -84.5120196
    },
    {
      "id": 2076,
      "name": "Cincinnati, OH",
      "correlation_id": "101425",
      "billing_address": {
        "id": 689,
        "street1": "5040 Duramed Dr Cinci OH",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45213",
        "lat": 39.1661721,
        "lon": -84.4292472,
        "country": "United States"
      },
      "start_date": "2020-03-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 282,
        "name": "Teva",
        "type": "CUSTOMER",
        "correlation_id": "11677",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0884d659-2212-4e94-886f-7fffdceebd4f"
      },
      "parent_id": null,
      "lat": 39.1661521,
      "lon": -84.431262,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "582e6244-f357-4c63-96a3-6e4dfd710f57",
      "is_mobile": true,
      "mobile_live_date": "2021-08-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Teva",
      "_label": "Teva",
      "_name": "Cincinnati, OH",
      "_lat": 39.1661521,
      "_lng": -84.431262,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.431262
    },
    {
      "id": 2125,
      "name": "Cincinnati, OH",
      "correlation_id": "11734",
      "billing_address": null,
      "start_date": "2005-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 297,
        "name": "Christ Hospital",
        "type": "CUSTOMER",
        "correlation_id": "11734",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7be50449-4f8c-4e55-adbe-43523a80443a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db958f96-888c-40c3-a74a-35b10d999058",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Christ Hospital",
      "_label": "Christ Hospital",
      "_name": "Cincinnati, OH",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2090,
      "name": "Cincinnati, OH - BEBC",
      "correlation_id": "11689",
      "billing_address": {
        "id": 232,
        "street1": "2630 Glendale Milford Road",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45241",
        "lat": 39.2572273,
        "lon": -84.4336778,
        "country": "United States"
      },
      "start_date": "2020-05-01T00:00:00Z",
      "total_sq_ft": "225000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.257434,
      "lon": -84.4355906,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d1b83823-a9cf-4509-bba9-dcd6075033b4",
      "is_mobile": true,
      "mobile_live_date": "2021-02-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Cincinnati, OH - BEBC",
      "_lat": 39.257434,
      "_lng": -84.4355906,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.4355906
    },
    {
      "id": 2524,
      "name": "Circleville",
      "correlation_id": "101460",
      "billing_address": {
        "id": 377,
        "street1": "800 DuPont Rd",
        "street2": "",
        "city": "Circleville",
        "region": "Ohio",
        "postal_code": "43113",
        "lat": 39.5603513,
        "lon": -82.9668004,
        "country": "United States"
      },
      "start_date": "2021-06-15T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "20",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 326,
        "name": "DuPont",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4668b06b-a547-4504-b0aa-e2be16bac9b4"
      },
      "parent_id": null,
      "lat": 39.56026,
      "lon": -82.96643,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c649caa5-ce17-4473-afe8-ca545d367d5b",
      "is_mobile": true,
      "mobile_live_date": "2021-12-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DuPont",
      "_label": "DuPont",
      "_name": "Circleville",
      "_lat": 39.56026,
      "_lng": -82.96643,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698154,
          "create_date": "2024-02-16T20:28:33.946575Z",
          "update_date": "2024-02-16T20:28:33.946598Z",
          "organization_id": 326,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8506194989694f86b1f7259ff193d285_logo_t.PNG",
          "size": 5219,
          "description": null,
          "link": null,
          "unique_identifier": "8506194989694f86b1f7259ff193d285"
        }
      ],
      "lng": -82.96643
    },
    {
      "id": 4225,
      "name": "City of Industry",
      "correlation_id": "102094",
      "billing_address": {
        "id": 2167,
        "street1": "18543 E. Gale Ave",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "91748",
        "lat": 33.995909,
        "lon": -117.8990496,
        "country": "United States"
      },
      "start_date": "2022-05-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 33.99451,
      "lon": -117.89802,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "48e90c2c-d6f9-4a38-bde4-a7d63f3c4b35",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "City of Industry",
      "_lat": 33.99451,
      "_lng": -117.89802,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.89802
    },
    {
      "id": 1808,
      "name": "CJ Harris",
      "correlation_id": "102703",
      "billing_address": null,
      "start_date": "2019-07-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.3788645,
      "lon": -111.9035294,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "CJ Harris",
      "_lat": 33.3788645,
      "_lng": -111.9035294,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.9035294
    },
    {
      "id": 3851,
      "name": "Classic",
      "correlation_id": "102106",
      "billing_address": {
        "id": 3033,
        "street1": "1 Hacker Way.",
        "street2": "",
        "city": "Menlo Park",
        "region": "California",
        "postal_code": null,
        "lat": 37.484314,
        "lon": -122.1474096,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 37.48402,
      "lon": -122.15084,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
      "is_mobile": true,
      "mobile_live_date": "2022-11-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Classic",
      "_lat": 37.48402,
      "_lng": -122.15084,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": -122.15084
    },
    {
      "id": 6675,
      "name": "CLE2 – Cleveland, OH",
      "correlation_id": "103384",
      "billing_address": {
        "id": 4085,
        "street1": "21500 Emery Road",
        "street2": "",
        "city": "North Randall",
        "region": "Ohio",
        "postal_code": null,
        "lat": 41.4332677,
        "lon": -81.5310571,
        "country": "United States"
      },
      "start_date": "2024-03-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.43516,
      "lon": -81.53185,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ee8044f0-62bb-4da6-970b-76ffba5cc0dd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CLE2 – Cleveland, OH",
      "_lat": 41.43516,
      "_lng": -81.53185,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -81.53185
    },
    {
      "id": 6665,
      "name": "CLE3 – Euclid, OH",
      "correlation_id": "103372",
      "billing_address": {
        "id": 4050,
        "street1": "1155 Babbitt Road",
        "street2": "",
        "city": "Euclid",
        "region": "Ohio",
        "postal_code": null,
        "lat": 41.5943953,
        "lon": -81.508425,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.594103,
      "lon": -81.50671,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a87683f7-75f8-45a3-ab59-13ca16c69915",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CLE3 – Euclid, OH",
      "_lat": 41.594103,
      "_lng": -81.50671,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -81.50671
    },
    {
      "id": 2638,
      "name": "Clearwater, FL - Aviation",
      "correlation_id": "101529",
      "billing_address": {
        "id": 772,
        "street1": "14200 Roosevelt Blvd",
        "street2": "",
        "city": "Clearwater",
        "region": "Florida",
        "postal_code": "33762",
        "lat": 27.9001177,
        "lon": -82.693757,
        "country": "United States"
      },
      "start_date": "2021-10-13T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 27.9001177,
      "lon": -82.693757,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0988b2ca-a881-4117-8587-f25db8115fc6",
      "is_mobile": true,
      "mobile_live_date": "2022-01-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Clearwater, FL - Aviation",
      "_lat": 27.9001177,
      "_lng": -82.693757,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -82.693757
    },
    {
      "id": 2256,
      "name": "Clermont, KY",
      "correlation_id": "101254",
      "billing_address": {
        "id": 491,
        "street1": "170 Clermont Rd",
        "street2": "",
        "city": "Shepardsville",
        "region": "Kentucky",
        "postal_code": "40165",
        "lat": 37.9801905,
        "lon": -85.6835851,
        "country": "United States"
      },
      "start_date": "2020-09-28T23:59:59Z",
      "total_sq_ft": "1300000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 38.328732,
      "lon": -85.764771,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Clermont, KY",
      "_lat": 38.328732,
      "_lng": -85.764771,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -85.764771
    },
    {
      "id": 2016,
      "name": "Clinton",
      "correlation_id": "11591",
      "billing_address": {
        "id": 3761,
        "street1": "938 S Dupont Pl West",
        "street2": "",
        "city": "Terre Haute",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.4548384,
        "lon": -87.4719086,
        "country": "United States"
      },
      "start_date": "2018-01-01T23:59:59Z",
      "total_sq_ft": "8100000",
      "total_buildings": "29",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 273,
        "name": "Elanco (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11589",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
      "is_mobile": true,
      "mobile_live_date": "2020-09-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elanco (CBRE)",
      "_label": "Elanco (CBRE)",
      "_name": "Clinton",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918792,
          "create_date": "2020-12-07T22:03:11.720761Z",
          "update_date": "2020-12-07T22:03:11.720786Z",
          "organization_id": 273,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607378591.6016922_Elanco_t.png",
          "size": 6049,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2118,
      "name": "Clubhouse*",
      "correlation_id": "100729",
      "billing_address": null,
      "start_date": "2018-03-01T00:00:00Z",
      "total_sq_ft": "55000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 168,
        "name": "HP- (SG) (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11149",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef2949fd-22c9-4c67-a18b-cb6aa3c53609"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "37798589-89d6-4c5f-a868-19c837eb45af",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP- (SG) (JLL)",
      "_label": "HP- (SG) (JLL)",
      "_name": "Clubhouse*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1431,
      "name": "CMH1 – Pataskala, OH",
      "correlation_id": "103408",
      "billing_address": {
        "id": 4051,
        "street1": "11999 National Rd SW",
        "street2": "",
        "city": "Pataskala",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.9563075,
        "lon": -82.7140807,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.954326,
      "lon": -82.713189,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "816ac462-5b42-4525-a397-e5eb8a27b354",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CMH1 – Pataskala, OH",
      "_lat": 39.954326,
      "_lng": -82.713189,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -82.713189
    },
    {
      "id": 6680,
      "name": "CMH2 – Groveport, OH (Pending Go-Live)",
      "correlation_id": "103389",
      "billing_address": {
        "id": 4090,
        "street1": "6050 Gateway Court",
        "street2": "",
        "city": "Groveport",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.8456647,
        "lon": -82.9306618,
        "country": "United States"
      },
      "start_date": "2024-04-02T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.846668,
      "lon": -82.931113,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8ff41301-d0d6-4fc3-8ce8-9914ad1e7b20",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CMH2 – Groveport, OH (Pending Go-Live)",
      "_lat": 39.846668,
      "_lng": -82.931113,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -82.931113
    },
    {
      "id": 6671,
      "name": "CMH4 - West Jefferson, OH",
      "correlation_id": "103380",
      "billing_address": {
        "id": 4072,
        "street1": "1550 West Main Street",
        "street2": "",
        "city": "West Jefferson",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.9431055,
        "lon": -83.31712,
        "country": "United States"
      },
      "start_date": "2024-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.943239,
      "lon": -83.318017,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ed202ac7-dc50-4118-b78b-9b980f5917c3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CMH4 - West Jefferson, OH",
      "_lat": 39.943239,
      "_lng": -83.318017,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.318017
    },
    {
      "id": 5825,
      "name": "CNO8 - Santa Clarita",
      "correlation_id": "102747",
      "billing_address": {
        "id": 4078,
        "street1": "21501 Needham Ranch Pkwy",
        "street2": "",
        "city": "Santa Clarita",
        "region": "California",
        "postal_code": null,
        "lat": 34.3582445,
        "lon": -118.5129771,
        "country": "United States"
      },
      "start_date": "2023-09-24T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 34.35804,
      "lon": -118.51401,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f841b412-59c3-4bc3-aff9-1236125b7be2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "CNO8 - Santa Clarita",
      "_lat": 34.35804,
      "_lng": -118.51401,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -118.51401
    },
    {
      "id": 5502,
      "name": "COB (JLL)",
      "correlation_id": "102529",
      "billing_address": {
        "id": 2999,
        "street1": "260 charles lindbergh dr",
        "street2": "",
        "city": "Salt Lake City",
        "region": "Utah",
        "postal_code": null,
        "lat": 40.7748957,
        "lon": -112.010243,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "190073",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 40.77485,
      "lon": -112.01152,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d75c0c67-9c54-469a-8168-0a35004c4430",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "COB (JLL)",
      "_lat": 40.77485,
      "_lng": -112.01152,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -112.01152
    },
    {
      "id": 1562,
      "name": "Colegio Federado de I y A",
      "correlation_id": "11065",
      "billing_address": {
        "id": 29,
        "street1": "Curridabat,  300 mts. este de Plaza del Sol",
        "street2": "",
        "city": "San José",
        "region": "Arkansas",
        "postal_code": "11801",
        "lat": 9.9145505,
        "lon": -84.0386633,
        "country": "Costa Rica"
      },
      "start_date": "2017-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 151,
        "name": "C de I&A (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11026",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "483c0154-f2b1-45ec-a03c-341647246f7f"
      },
      "parent_id": null,
      "lat": 10.0241714,
      "lon": -84.22498603,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b8b75fb3-96a4-4fd3-8e6c-ad61abaab3df",
      "is_mobile": true,
      "mobile_live_date": "2021-11-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "C de I&A (CR)",
      "_label": "C de I&A (CR)",
      "_name": "Colegio Federado de I y A",
      "_lat": 10.0241714,
      "_lng": -84.22498603,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.22498603
    },
    {
      "id": 2272,
      "name": "College Station, TX",
      "correlation_id": "102144",
      "billing_address": null,
      "start_date": "2020-12-07T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 30.635055,
      "lon": -96.328071,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ded08556-8358-40fe-a190-b721a27eba4b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "College Station, TX",
      "_lat": 30.635055,
      "_lng": -96.328071,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.328071
    },
    {
      "id": 1797,
      "name": "Colorado",
      "correlation_id": "11522",
      "billing_address": {
        "id": 673,
        "street1": "2121 Executive Cir",
        "street2": "",
        "city": "Coloraso Springs",
        "region": "Colorado",
        "postal_code": "80906",
        "lat": 38.7912457,
        "lon": -104.7884224,
        "country": "United States"
      },
      "start_date": "2019-06-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 38.791142,
      "lon": -104.7884289,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76b3ef87-febd-4226-976f-b98d7af2cfbe",
      "is_mobile": true,
      "mobile_live_date": "2021-09-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Colorado",
      "_lat": 38.791142,
      "_lng": -104.7884289,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -104.7884289
    },
    {
      "id": 3690,
      "name": "Columbia, MD*",
      "correlation_id": "101856",
      "billing_address": null,
      "start_date": "2022-06-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 558,
        "name": "Computershare",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 823,
            "street1": "1015 SE Tenth ave",
            "street2": "",
            "city": "Minneapolis",
            "region": "MN",
            "postal_code": "55414",
            "lat": 44.9894065,
            "lon": -93.2388427,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "554c6a87-fed8-480b-b780-d1f1daf09278"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ab354e94-1df8-4ff6-8b0a-a4ca79c29e8a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Computershare",
      "_label": "Computershare",
      "_name": "Columbia, MD*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1913,
      "name": "Columbia, MO",
      "correlation_id": "10789",
      "billing_address": {
        "id": 439,
        "street1": "213 Rothwell Gymnasium",
        "street2": "",
        "city": "Columbia",
        "region": "Missouri",
        "postal_code": "65211",
        "lat": 38.9415195,
        "lon": -92.325717,
        "country": "United States"
      },
      "start_date": "2013-03-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 114,
        "name": "University of Missouri",
        "type": "CUSTOMER",
        "correlation_id": "10476",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "92b95a16-8b66-4017-8f99-d31e475f4c43"
      },
      "parent_id": null,
      "lat": 38.9403808,
      "lon": -92.3277375,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b27631d1-a2b6-47c9-a504-358762d33bad",
      "is_mobile": true,
      "mobile_live_date": "2020-09-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "University of Missouri",
      "_label": "University of Missouri",
      "_name": "Columbia, MO",
      "_lat": 38.9403808,
      "_lng": -92.3277375,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -92.3277375
    },
    {
      "id": 5776,
      "name": "Columbia, MO*",
      "correlation_id": "102623",
      "billing_address": null,
      "start_date": "2023-06-05T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 38.71177,
      "lon": -93.21539,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "78ab5a19-75d1-4f21-a6bb-966bed3a0b2d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Columbia, MO*",
      "_lat": 38.71177,
      "_lng": -93.21539,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -93.21539
    },
    {
      "id": 1627,
      "name": "Columbia, SC",
      "correlation_id": "11182",
      "billing_address": null,
      "start_date": "2018-05-07T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "15",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 33.8748725,
      "lon": -80.89761,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7a017717-112f-49b4-a0d2-e52ac90b74df",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Columbia, SC",
      "_lat": 33.8748725,
      "_lng": -80.89761,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -80.89761
    },
    {
      "id": 1829,
      "name": "Columbus Division",
      "correlation_id": "10622",
      "billing_address": {
        "id": 389,
        "street1": "3300 Stelzer Road",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": "43219",
        "lat": 40.0400113,
        "lon": -82.9062822,
        "country": "United States"
      },
      "start_date": "2017-02-10T23:59:59Z",
      "total_sq_ft": "4200000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 40.0399772,
      "lon": -82.906337,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6d5eaf02-88a9-4ee3-b5ad-0fad61a551da",
      "is_mobile": true,
      "mobile_live_date": "2021-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Columbus Division",
      "_lat": 40.0399772,
      "_lng": -82.906337,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.906337
    },
    {
      "id": 1270,
      "name": "Columbus, GA (Midland)",
      "correlation_id": "10238",
      "billing_address": {
        "id": 1278,
        "street1": "6087 Technology Parkway",
        "street2": "",
        "city": "Midland",
        "region": "Georgia",
        "postal_code": "31807",
        "lat": 32.5294148,
        "lon": -84.8422127,
        "country": "United States"
      },
      "start_date": "2009-04-30T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 32.500533,
      "lon": -84.95826,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "27740f10-714e-443c-8289-cab72b4f036c",
      "is_mobile": true,
      "mobile_live_date": "2022-02-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Columbus, GA (Midland)",
      "_lat": 32.500533,
      "_lng": -84.95826,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -84.95826
    },
    {
      "id": 5532,
      "name": "Columbus Main (JLL)",
      "correlation_id": "102351",
      "billing_address": null,
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 32.4708803,
      "lon": -84.9912378,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "43e83099-aa43-4e5e-9a3f-70dd39d77047",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Columbus Main (JLL)",
      "_lat": 32.4708803,
      "_lng": -84.9912378,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -84.9912378
    },
    {
      "id": 3960,
      "name": "Columbus, OH",
      "correlation_id": "102099",
      "billing_address": {
        "id": 1934,
        "street1": "1086 N. 4th Street Suite 106",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": "43201",
        "lat": 39.9851873,
        "lon": -82.998917,
        "country": "United States"
      },
      "start_date": "2022-06-13T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 639,
        "name": "New York Life (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1077,
            "street1": "1455 Valley Center Pwky #100",
            "street2": "",
            "city": "Bethlehem",
            "region": "PA",
            "postal_code": "18017",
            "lat": 40.6554252,
            "lon": -75.401438,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "3338f6ab-eab9-4506-8d7c-bd509933d502"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2c434ba3-2528-47e5-aa29-598ded025d61",
      "is_mobile": true,
      "mobile_live_date": "2022-06-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "New York Life (C&W)",
      "_label": "New York Life (C&W)",
      "_name": "Columbus, OH",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2067,
      "name": "Columbus Plant",
      "correlation_id": "11717",
      "billing_address": {
        "id": 391,
        "street1": "625 Cleveland Avenue",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": "43215",
        "lat": 39.9770906,
        "lon": -82.9877428,
        "country": "United States"
      },
      "start_date": "2017-02-10T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 40.0078355,
      "lon": -82.9839962,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3e8c46a8-2d60-4e58-95f7-6f6b2d6e29b7",
      "is_mobile": true,
      "mobile_live_date": "2020-03-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Columbus Plant",
      "_lat": 40.0078355,
      "_lng": -82.9839962,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.9839962
    },
    {
      "id": 6751,
      "name": "Commerce Park 2 15080",
      "correlation_id": "103420",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.31116,
      "lon": -83.18368,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Commerce Park 2 15080",
      "_lat": 42.31116,
      "_lng": -83.18368,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.18368
    },
    {
      "id": 6740,
      "name": "Commerce Park 2 15090",
      "correlation_id": "103412",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.31074,
      "lon": -83.18417,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Commerce Park 2 15090",
      "_lat": 42.31074,
      "_lng": -83.18417,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.18417
    },
    {
      "id": 5575,
      "name": "Concord, NC",
      "correlation_id": "102414",
      "billing_address": null,
      "start_date": "2022-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 72,
        "name": "Eli Lilly (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10042",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eli Lilly (CBRE)",
      "_label": "Eli Lilly (CBRE)",
      "_name": "Concord, NC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853030,
          "create_date": "2020-11-13T23:55:46.918511Z",
          "update_date": "2020-11-13T23:55:46.918539Z",
          "organization_id": 72,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311746.7267878_Artboard8_t.png",
          "size": 413839,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2633,
      "name": "Concordville, PA",
      "correlation_id": "101579",
      "billing_address": {
        "id": 769,
        "street1": "1 State Farm Rd",
        "street2": "",
        "city": "Concordville",
        "region": "Pennsylvania",
        "postal_code": "19737",
        "lat": 39.8827697,
        "lon": -75.5458222,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 39.88296,
      "lon": -75.5461,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Concordville, PA",
      "_lat": 39.88296,
      "_lng": -75.5461,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -75.5461
    },
    {
      "id": 2585,
      "name": "Connect",
      "correlation_id": "101473",
      "billing_address": {
        "id": 644,
        "street1": "3633 Commerce Center Blvd",
        "street2": "",
        "city": "Bethlehem",
        "region": "Pennsylvania",
        "postal_code": "18015",
        "lat": 40.6116969,
        "lon": -75.3154205,
        "country": "United States"
      },
      "start_date": "2021-07-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "15",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 472,
        "name": "GXO/Nike",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 643,
            "street1": "3633 Commerce Center Blvd",
            "street2": "",
            "city": "Bethlehem",
            "region": "Pennsylvania",
            "postal_code": "18015",
            "lat": 40.6116969,
            "lon": -75.3154205,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "e06663e8-a140-47e1-a043-3844f1573c76"
      },
      "parent_id": null,
      "lat": 40.6116969,
      "lon": -75.3154205,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a5f064c8-4fb3-4963-8201-9dc54196b687",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GXO/Nike",
      "_label": "GXO/Nike",
      "_name": "Connect",
      "_lat": 40.6116969,
      "_lng": -75.3154205,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -75.3154205
    },
    {
      "id": 5483,
      "name": "Continuum (JLL)",
      "correlation_id": "102300",
      "billing_address": {
        "id": 4047,
        "street1": "2501 S Price Rd.",
        "street2": "",
        "city": "Chandler",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.267853,
        "lon": -111.8864547,
        "country": "United States"
      },
      "start_date": "2013-11-01T18:24:45Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 33.26876,
      "lon": -111.889,
      "local_timezone": {
        "id": 2206,
        "code": "America/Indiana/Marengo",
        "display_name": "America/Indiana/Marengo",
        "description": "",
        "child_group": null,
        "alt_sequence": 72
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "655efe44-7c11-454c-86e3-aaeb6600968a",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Continuum (JLL)",
      "_lat": 33.26876,
      "_lng": -111.889,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -111.889
    },
    {
      "id": 3534,
      "name": "Convera",
      "correlation_id": "102037",
      "billing_address": null,
      "start_date": "2022-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1261,
        "name": "Convera (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a1a4f780-e5d8-4980-95ad-bd799d73e804"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "37ca449f-8896-4ed6-b7fb-684b4e613d12",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Convera (CR)",
      "_label": "Convera (CR)",
      "_name": "Convera",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2672,
      "name": "Corona, CA",
      "correlation_id": "102158",
      "billing_address": null,
      "start_date": "2021-11-16T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5b5749e7-f976-4a20-97fa-0f26f7271063",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Corona, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5552,
      "name": "Corporate",
      "correlation_id": "102340",
      "billing_address": {
        "id": 3578,
        "street1": "3939 W. Highlands Blvd.",
        "street2": "",
        "city": "Milwaukee",
        "region": "Wisconsin",
        "postal_code": null,
        "lat": 43.0440562,
        "lon": -87.9624248,
        "country": "United States"
      },
      "start_date": "2023-01-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "28",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2104,
        "name": "Molson Coors",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "959ff740-96a6-4be4-803a-93ef99c9c7df"
      },
      "parent_id": null,
      "lat": 43.0445,
      "lon": -87.96257,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "22bec60a-812a-4924-92bd-0402d88b310e",
      "is_mobile": true,
      "mobile_live_date": "2023-04-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Molson Coors",
      "_label": "Molson Coors",
      "_name": "Corporate",
      "_lat": 43.0445,
      "_lng": -87.96257,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025435,
          "create_date": "2024-03-07T17:53:48.204279Z",
          "update_date": "2024-03-07T17:53:48.204305Z",
          "organization_id": 2104,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c6dcff670102483ea02c19995e85751f_oPVydH8n_400x400_t.jpg",
          "size": 21690,
          "description": null,
          "link": null,
          "unique_identifier": "c6dcff670102483ea02c19995e85751f"
        }
      ],
      "lng": -87.96257
    },
    {
      "id": 30,
      "name": "Corporate Facilities-McClellan Park",
      "correlation_id": "10050",
      "billing_address": {
        "id": 463,
        "street1": "3237 Peacekeeper Way",
        "street2": "",
        "city": "McClellan Park",
        "region": "CA",
        "postal_code": "95652",
        "lat": 38.6616292,
        "lon": -121.3872437,
        "country": "US"
      },
      "start_date": "1981-12-31T23:59:59Z",
      "total_sq_ft": "11455",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "parent_id": null,
      "lat": 38.6733377,
      "lon": -121.390048,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a3bb101a-026b-4312-83f6-bb34accfcffc",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": "8304654842",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": true,
      "use_area_links": true,
      "_client": "SBM",
      "_label": "SBM",
      "_name": "Corporate Facilities-McClellan Park",
      "_lat": 38.6733377,
      "_lng": -121.390048,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.390048
    },
    {
      "id": 2431,
      "name": "CORPW014",
      "correlation_id": "101373",
      "billing_address": null,
      "start_date": "2021-03-15T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 290,
        "name": "SBM-H",
        "type": "CUSTOMER",
        "correlation_id": "11697",
        "addresses": [
          {
            "id": 258,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0a023ec6-4462-490a-a4db-dcc67a63fda7"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d356b7e2-042e-4761-830a-4bbbe92d9653",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM-H",
      "_label": "SBM-H",
      "_name": "CORPW014",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3433,
      "name": "CORPW021",
      "correlation_id": "102062",
      "billing_address": null,
      "start_date": "2022-04-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1b98729b-0399-4a7e-8412-31a148608624",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW021",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4830,
      "name": "CORPW035",
      "correlation_id": "102237",
      "billing_address": null,
      "start_date": "2022-10-20T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 290,
        "name": "SBM-H",
        "type": "CUSTOMER",
        "correlation_id": "11697",
        "addresses": [
          {
            "id": 258,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0a023ec6-4462-490a-a4db-dcc67a63fda7"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c3325914-6fc1-44f5-882e-28bbd9277b4f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM-H",
      "_label": "SBM-H",
      "_name": "CORPW035",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6022,
      "name": "CORPW037",
      "correlation_id": "102854",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e5323008-404c-49aa-ac89-7db0896e24c2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW037",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6072,
      "name": "CORPW042",
      "correlation_id": "102911",
      "billing_address": null,
      "start_date": "2023-11-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "30eda3cd-85ac-4db9-878d-45a0a2df1e8e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW042",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6009,
      "name": "CORPW043",
      "correlation_id": "102841",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "197eeb90-84f8-4e88-96e6-4d7a58beee32",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW043",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6071,
      "name": "CORPW044",
      "correlation_id": "102908",
      "billing_address": null,
      "start_date": "2023-11-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "47dbfdc3-b58c-4a94-851c-ebfa0af773f1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW044",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6006,
      "name": "CORPW046",
      "correlation_id": "102838",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3a25297-7ce0-4cfa-ad53-a18d08e679d8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW046",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6011,
      "name": "CORPW061",
      "correlation_id": "102843",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "daefb929-f5c2-4ad7-9b11-41ab243a3753",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW061",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6010,
      "name": "CORPW065",
      "correlation_id": "102842",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6d1fcd8d-a538-455b-99f8-e5abeca331e2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW065",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6027,
      "name": "CORPW066",
      "correlation_id": "102859",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4dc9921e-ceb3-4be7-95c3-c411de5a5254",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW066",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6013,
      "name": "CORPW067",
      "correlation_id": "102845",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9379458e-82db-48e9-8fbe-77367c707075",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW067",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6014,
      "name": "CORPW069",
      "correlation_id": "102846",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a276d9c9-d286-487e-b483-b021b0272c87",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW069",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6012,
      "name": "CORPW070",
      "correlation_id": "102844",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "84fb9148-bbed-44ae-be71-1bee3c95951d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW070",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6074,
      "name": "CORPW071",
      "correlation_id": "102996",
      "billing_address": null,
      "start_date": "2023-11-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "46ff9b4b-b4c3-4bb4-9454-0e946c96a91f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW071",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6033,
      "name": "CORPW072",
      "correlation_id": "102865",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d47e6ddb-097f-4809-9058-69c97d4ac5d8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW072",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6073,
      "name": "CORPW079",
      "correlation_id": "102913",
      "billing_address": null,
      "start_date": "2023-11-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d34a3a07-79ac-4efe-a63e-e6ff9134a931",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW079",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6025,
      "name": "CORPW080",
      "correlation_id": "102857",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0f491c85-5607-484c-af96-4f4c24fadf1c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CORPW080",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4435,
      "name": "CORSAIR-9821 Business Park*",
      "correlation_id": "101842",
      "billing_address": {
        "id": 2496,
        "street1": "9821 Business Park Dr.",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": null,
        "lat": 38.5715367,
        "lon": -121.3293222,
        "country": "United States"
      },
      "start_date": "2022-08-18T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2075,
        "name": "The Evergreen Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a5aaf74c-a0fc-4301-bd49-76ba36522a88"
      },
      "parent_id": null,
      "lat": 38.5715367,
      "lon": -121.3293222,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4e98db02-8540-40c4-9d8f-7b62e2fb1fe5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "The Evergreen Company",
      "_label": "The Evergreen Company",
      "_name": "CORSAIR-9821 Business Park*",
      "_lat": 38.5715367,
      "_lng": -121.3293222,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3293222
    },
    {
      "id": 2554,
      "name": "Corte Madera",
      "correlation_id": "102145",
      "billing_address": {
        "id": 540,
        "street1": "1704 Redwood Hwy",
        "street2": "",
        "city": "Corte Madera",
        "region": "California",
        "postal_code": "94925",
        "lat": 37.9296343,
        "lon": -122.5141138,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0ba75028-16a7-4ee0-929b-03c723e5d744",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Corte Madera",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3682,
      "name": "Cortex",
      "correlation_id": "102091",
      "billing_address": {
        "id": 1637,
        "street1": "4220 Duncan Ave",
        "street2": "",
        "city": "St. Louis",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.6346715,
        "lon": -90.2503247,
        "country": "United States"
      },
      "start_date": "2022-05-03T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 38.63414,
      "lon": -90.24941,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aee1b4bd-83ea-41ea-a9bc-ddb40931d09a",
      "is_mobile": true,
      "mobile_live_date": "2022-05-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "Cortex",
      "_lat": 38.63414,
      "_lng": -90.24941,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": -90.24941
    },
    {
      "id": 5644,
      "name": "Corvallis*",
      "correlation_id": "102428",
      "billing_address": null,
      "start_date": "2023-02-22T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 44.58897,
      "lon": -123.24506,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c466f4e9-de12-415b-b4fa-ab293ecadfc2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Corvallis*",
      "_lat": 44.58897,
      "_lng": -123.24506,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -123.24506
    },
    {
      "id": 1580,
      "name": "Corvallis, OR (JLL)",
      "correlation_id": "11110",
      "billing_address": {
        "id": 471,
        "street1": "1070 NE Circle Blvd  Corvallis, OR 97330",
        "street2": "",
        "city": "Corvallis",
        "region": "Oregon",
        "postal_code": "97330",
        "lat": 44.5843102,
        "lon": -123.2439718,
        "country": "United States"
      },
      "start_date": "2018-02-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 44.5860372,
      "lon": -123.243509,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bffee7c9-9dc7-47c9-894c-89928bb6b3cc",
      "is_mobile": true,
      "mobile_live_date": "2020-11-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Corvallis, OR (JLL)",
      "_lat": 44.5860372,
      "_lng": -123.243509,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -123.243509
    },
    {
      "id": 1842,
      "name": "CO Springs (JLL)",
      "correlation_id": "10646",
      "billing_address": {
        "id": 672,
        "street1": "2220 Briargate Parkway",
        "street2": "",
        "city": "Colorado Springs",
        "region": "Colorado",
        "postal_code": "80921",
        "lat": 38.968288,
        "lon": -104.7860975,
        "country": "United States"
      },
      "start_date": "2016-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 225,
        "name": "T. Rowe Price",
        "type": "CUSTOMER",
        "correlation_id": "10645",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "51af8ac6-48c2-4774-b844-4fdd26e2d6ea"
      },
      "parent_id": null,
      "lat": 38.9681427,
      "lon": -104.7863673,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ca3e9274-44d6-407c-8088-fd41d2cbaec5",
      "is_mobile": true,
      "mobile_live_date": "2021-09-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "T. Rowe Price",
      "_label": "T. Rowe Price",
      "_name": "CO Springs (JLL)",
      "_lat": 38.9681427,
      "_lng": -104.7863673,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10769945,
          "create_date": "2024-02-21T20:47:06.306144Z",
          "update_date": "2024-02-21T20:47:06.306170Z",
          "organization_id": 225,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/464bbb059be54218b17cd131d05a3116_trowelogo_t.png",
          "size": 2901,
          "description": null,
          "link": null,
          "unique_identifier": "464bbb059be54218b17cd131d05a3116"
        }
      ],
      "lng": -104.7863673
    },
    {
      "id": 1064,
      "name": "Costa Rica",
      "correlation_id": "11430",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "parent_id": null,
      "lat": 38.6629065,
      "lon": -121.38545,
      "local_timezone": {
        "id": 2577,
        "code": "Antarctica/Casey",
        "display_name": "Antarctica/Casey",
        "description": "",
        "child_group": null,
        "alt_sequence": 443
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "31184e7a-6370-4a01-a7d3-2e82f98284e5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM",
      "_label": "SBM",
      "_name": "Costa Rica",
      "_lat": 38.6629065,
      "_lng": -121.38545,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.38545
    },
    {
      "id": 2081,
      "name": "Costa Rica",
      "correlation_id": "11687",
      "billing_address": {
        "id": 43,
        "street1": "Forum 1 Free Zone, Torre G, piso 8",
        "street2": "",
        "city": "Santa Ana",
        "region": "Alabama",
        "postal_code": "10901",
        "lat": 9.9312697,
        "lon": -84.1820735,
        "country": "Costa Rica"
      },
      "start_date": "2020-02-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 284,
        "name": "CWT (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11686",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1b9a5ce3-7d1c-4ac4-aa58-322e5a9ac480"
      },
      "parent_id": null,
      "lat": 9.9454177,
      "lon": -84.196986,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7ae4c322-d484-4bdd-babf-fe93bf56f567",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CWT (CR)",
      "_label": "CWT (CR)",
      "_name": "Costa Rica",
      "_lat": 9.9454177,
      "_lng": -84.196986,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.196986
    },
    {
      "id": 6076,
      "name": "Cover My Meds 2",
      "correlation_id": "103005",
      "billing_address": {
        "id": 4102,
        "street1": "911 St. John street",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.9636303,
        "lon": -83.0261094,
        "country": "United States"
      },
      "start_date": "2024-01-31T23:45:54.420000Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 39.9638,
      "lon": -83.02484,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
      "is_mobile": true,
      "mobile_live_date": "2024-01-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Cover My Meds 2",
      "_lat": 39.9638,
      "_lng": -83.02484,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.02484
    },
    {
      "id": 2471,
      "name": "Cover My Meds Columbus",
      "correlation_id": "101428",
      "billing_address": {
        "id": 314,
        "street1": "910 John St.",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": "43222",
        "lat": 39.9640812,
        "lon": -83.0255971,
        "country": "United States"
      },
      "start_date": "2021-06-04T23:59:59Z",
      "total_sq_ft": "1374000",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 39.964,
      "lon": -83.0255,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
      "is_mobile": true,
      "mobile_live_date": "2021-09-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Cover My Meds Columbus",
      "_lat": 39.964,
      "_lng": -83.0255,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.0255
    },
    {
      "id": 1401,
      "name": "Coyol",
      "correlation_id": "10437",
      "billing_address": {
        "id": 27,
        "street1": "Propark Free Zone,  2546 Calle Primera",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.0159394,
        "lon": -84.2141701,
        "country": "Costa Rica"
      },
      "start_date": "2015-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 104,
        "name": "Boston Scientific (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10436",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4247ddd5-edc0-4232-ab9b-9cacd7459d46"
      },
      "parent_id": null,
      "lat": 9.9862881,
      "lon": -84.2614889,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a3d441eb-afba-4c9d-8e6f-983500316e99",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boston Scientific (CR)",
      "_label": "Boston Scientific (CR)",
      "_name": "Coyol",
      "_lat": 9.9862881,
      "_lng": -84.2614889,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2614889
    },
    {
      "id": 1405,
      "name": "Coyol",
      "correlation_id": "10447",
      "billing_address": {
        "id": 37,
        "street1": "Coyol Free Zone & Business Park 562 Parkway, Building B9",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2015-09-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 108,
        "name": "MOOG (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10446",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e3a3607f-d4a5-495f-be7e-16d3311fb1f6"
      },
      "parent_id": null,
      "lat": 9.9926717,
      "lon": -84.2796969,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "72a290c3-9d80-4f40-a803-90ba65628bc8",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "MOOG (CR)",
      "_label": "MOOG (CR)",
      "_name": "Coyol",
      "_lat": 9.9926717,
      "_lng": -84.2796969,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2796969
    },
    {
      "id": 1414,
      "name": "Coyol",
      "correlation_id": "10475",
      "billing_address": {
        "id": 38,
        "street1": "Coyol Free Zone & Business Park 562 Parkway,  Building 32B",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2015-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 113,
        "name": "Smith & Nephew (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10474",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd26fd68-d97d-4d8b-9649-c62316d5cc4b"
      },
      "parent_id": null,
      "lat": 9.9863515,
      "lon": -84.2807784,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8ec3fd24-8001-420d-94d5-c42ad8df9fc7",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Smith & Nephew (CR)",
      "_label": "Smith & Nephew (CR)",
      "_name": "Coyol",
      "_lat": 9.9863515,
      "_lng": -84.2807784,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2807784
    },
    {
      "id": 1560,
      "name": "Coyol",
      "correlation_id": "11063",
      "billing_address": {
        "id": 66,
        "street1": "1KM al oeste de  RTV  calle paralela  a la General Cañas",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.0015839,
        "lon": -84.183367,
        "country": "Costa Rica"
      },
      "start_date": "2014-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 146,
        "name": "Laica (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11020",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d6ed91b2-b65d-4353-a332-63090f445ce7"
      },
      "parent_id": null,
      "lat": 9.8941912,
      "lon": -84.5562878,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3931d2c3-2f6d-4043-95ca-2c535d8e8005",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Laica (CR)",
      "_label": "Laica (CR)",
      "_name": "Coyol",
      "_lat": 9.8941912,
      "_lng": -84.5562878,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.5562878
    },
    {
      "id": 1628,
      "name": "Coyol",
      "correlation_id": "11186",
      "billing_address": {
        "id": 20,
        "street1": "Coyol Free Zone & Business Park 562 Parkway Building 44B",
        "street2": "",
        "city": "Alejuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2018-05-31T23:59:59Z",
      "total_sq_ft": "458266",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 175,
        "name": "Abbott (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11185",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3f1af2ce-418a-45da-a315-7bc17290a5e3"
      },
      "parent_id": null,
      "lat": 9.994758,
      "lon": -84.270688,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "03447505-0637-44db-ab55-9df1080aa377",
      "is_mobile": true,
      "mobile_live_date": "2021-06-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott (CR)",
      "_label": "Abbott (CR)",
      "_name": "Coyol",
      "_lat": 9.994758,
      "_lng": -84.270688,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.270688
    },
    {
      "id": 1677,
      "name": "Coyol",
      "correlation_id": "11297",
      "billing_address": {
        "id": 35,
        "street1": "Coyol Free Zone & Business Park 562 Parkway, Building B7",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2018-12-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 195,
        "name": "Medtronic (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11296",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ebe07005-71fd-47dd-a2d0-e2771746aaae"
      },
      "parent_id": null,
      "lat": 9.994959,
      "lon": -84.2568529,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6b5e5fe4-0015-4933-80cd-8f607ff51e7b",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Medtronic (CR)",
      "_label": "Medtronic (CR)",
      "_name": "Coyol",
      "_lat": 9.994959,
      "_lng": -84.2568529,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2568529
    },
    {
      "id": 1825,
      "name": "Coyol",
      "correlation_id": "11563",
      "billing_address": {
        "id": 40,
        "street1": "Coyol Free Zone & Business Park 562 Parkway, Building B37",
        "street2": "",
        "city": "Alajuala",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 9.9946658,
        "lon": -84.2709936,
        "country": "Costa Rica"
      },
      "start_date": "2019-06-01T23:59:59Z",
      "total_sq_ft": "225000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 221,
        "name": "Phillips (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11562",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "feb25df5-1851-41a7-bc70-49d4e0b5cfbf"
      },
      "parent_id": null,
      "lat": 9.9946658,
      "lon": -84.2709936,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c8245113-d331-41f4-b231-05880482a6f3",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Phillips (CR)",
      "_label": "Phillips (CR)",
      "_name": "Coyol",
      "_lat": 9.9946658,
      "_lng": -84.2709936,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2709936
    },
    {
      "id": 2545,
      "name": "Coyol",
      "correlation_id": "101542",
      "billing_address": {
        "id": 531,
        "street1": "Zona Franca Coyol",
        "street2": "",
        "city": "Alajuela",
        "region": "California",
        "postal_code": "20101",
        "lat": 36.778261,
        "lon": -119.4179324,
        "country": "Costa Rica"
      },
      "start_date": "2022-01-24T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 465,
        "name": "Nevro (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 530,
            "street1": "Zona Franca Coyol",
            "street2": "",
            "city": "alajuela",
            "region": "Costa Rica",
            "postal_code": "20101",
            "lat": 9.9949857,
            "lon": -84.2708552,
            "country": "Costa Rica"
          }
        ],
        "status": "Unsuspended",
        "uuid": "742a57d0-684c-40ac-ba86-85bc0dd9f9f4"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8fcbaf99-c813-4d85-ab78-d220955c67ea",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Nevro (CR)",
      "_label": "Nevro (CR)",
      "_name": "Coyol",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4159,
      "name": "Coyol",
      "correlation_id": "102115",
      "billing_address": null,
      "start_date": "2022-07-07T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1548,
        "name": "Condominio Horizontal ZFC (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ce10071a-8830-4238-9b19-6486de780564"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d83835bb-2eca-4f0f-b2e9-daccb5c2c648",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Condominio Horizontal ZFC (CR)",
      "_label": "Condominio Horizontal ZFC (CR)",
      "_name": "Coyol",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4343,
      "name": "Coyol",
      "correlation_id": "102125",
      "billing_address": null,
      "start_date": "2022-08-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1744,
        "name": "Theragenics (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70c19e2c-27a9-4087-aa49-557a3b608b2d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3f09f59f-dd76-4d43-b559-9915f05eed42",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Theragenics (CR)",
      "_label": "Theragenics (CR)",
      "_name": "Coyol",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4822,
      "name": "Coyol",
      "correlation_id": "102225",
      "billing_address": {
        "id": 2716,
        "street1": "0000 test st",
        "street2": "",
        "city": "costa rica",
        "region": "Alaska",
        "postal_code": null,
        "lat": 9.748917,
        "lon": -83.753428,
        "country": "Costa Rica"
      },
      "start_date": "2022-03-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2076,
        "name": "Bayer Medical (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "033ebe80-b35d-40ae-b74b-9c6e1844c82a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5aa66909-b14c-4afb-a231-d715a730d1de",
      "is_mobile": true,
      "mobile_live_date": "2022-11-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer Medical (CR)",
      "_label": "Bayer Medical (CR)",
      "_name": "Coyol",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5779,
      "name": "Coyol",
      "correlation_id": "102774",
      "billing_address": {
        "id": 3855,
        "street1": "Costa Rica",
        "street2": "",
        "city": "Costa Rica",
        "region": "California",
        "postal_code": null,
        "lat": 9.748917,
        "lon": -83.753428,
        "country": "Costa Rica"
      },
      "start_date": "2023-07-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2164,
        "name": "Shockwave Medical (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d6d043ef-6dc4-4223-9bbf-73f2d0a9e1d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "48283421-0d2a-44d3-8479-f1d98080be8c",
      "is_mobile": true,
      "mobile_live_date": "2023-11-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Shockwave Medical (CR)",
      "_label": "Shockwave Medical (CR)",
      "_name": "Coyol",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1361,
      "name": "Coyol CR",
      "correlation_id": "10405",
      "billing_address": {
        "id": 16,
        "street1": "Coyol Free Zone & Business Park 562 Parkway",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.0525756,
        "lon": -84.2067992,
        "country": "Costa Rica"
      },
      "start_date": "2007-12-01T23:59:59Z",
      "total_sq_ft": "169040",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 95,
        "name": "Hologic (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10404",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6c59991c-e4bf-48f7-b5ce-5f0f70dcfe26"
      },
      "parent_id": null,
      "lat": 9.9902134,
      "lon": -84.2794057,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "24858884-5d5d-4885-aa45-1f3205a3ec8a",
      "is_mobile": true,
      "mobile_live_date": "2019-11-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hologic (CR)",
      "_label": "Hologic (CR)",
      "_name": "Coyol CR",
      "_lat": 9.9902134,
      "_lng": -84.2794057,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.2794057
    },
    {
      "id": 1378,
      "name": "CPG - Chelsea Proving Grounds - Chelsea, MI",
      "correlation_id": "101693",
      "billing_address": {
        "id": 899,
        "street1": "3700 South Michigan State Road 52",
        "street2": "",
        "city": "Chelsea",
        "region": "Michigan",
        "postal_code": "48118",
        "lat": 42.3129934,
        "lon": -84.0203332,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "19",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.2679039,
      "lon": -84.0355054,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a49b71e6-7976-43ca-a4cb-3c1a25c494e0",
      "is_mobile": true,
      "mobile_live_date": "2022-05-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "CPG - Chelsea Proving Grounds - Chelsea, MI",
      "_lat": 42.2679039,
      "_lng": -84.0355054,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -84.0355054
    },
    {
      "id": 4721,
      "name": "CPM0030THQ",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2022-09-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 629,
        "name": "CPM Property Management",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "88e3f2bc-ca78-4537-be89-da2f8046e04b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2244,
        "code": "America/Nipigon",
        "display_name": "America/Nipigon",
        "description": "",
        "child_group": null,
        "alt_sequence": 110
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4454b2db-ee41-43a6-ab54-ddea83e460e5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPM Property Management",
      "_label": "CPM Property Management",
      "_name": "CPM0030THQ",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1457,
      "name": "Cranberry",
      "correlation_id": "10579",
      "billing_address": {
        "id": 1120,
        "street1": "1000 Westinghouse Drive",
        "street2": "",
        "city": "Cranberry",
        "region": "Pennsylvania",
        "postal_code": "16066",
        "lat": 40.6830426,
        "lon": -80.0842513,
        "country": "United States"
      },
      "start_date": "2016-04-16T23:59:59Z",
      "total_sq_ft": "3116804",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 40.6812565,
      "lon": -80.0849855,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "87c7b29f-88c4-44a8-a356-66ade310ab09",
      "is_mobile": true,
      "mobile_live_date": "2021-12-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Cranberry",
      "_lat": 40.6812565,
      "_lng": -80.0849855,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -80.0849855
    },
    {
      "id": 6763,
      "name": "CR Dentons Custodial*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-01-31T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2605,
        "name": "Dentons Muñoz Costa Rica Limitada",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5cca7fcc-bfc9-4b80-b291-aee43f7b9167"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bf754fd6-b096-4682-a0db-0bf427996aa9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Dentons Muñoz Costa Rica Limitada",
      "_label": "Dentons Muñoz Costa Rica Limitada",
      "_name": "CR Dentons Custodial*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1984,
      "name": "Crescentville, OH - Engine Services",
      "correlation_id": "101204",
      "billing_address": {
        "id": 233,
        "street1": "201 West Crescentville Road",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45246",
        "lat": 39.3014685,
        "lon": -84.4761615,
        "country": "United States"
      },
      "start_date": "2003-02-01T00:00:00Z",
      "total_sq_ft": "457000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.301479,
      "lon": -84.4783717,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "36514740-8039-4bca-98ac-1b0017a7df49",
      "is_mobile": true,
      "mobile_live_date": "2021-02-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Crescentville, OH - Engine Services",
      "_lat": 39.301479,
      "_lng": -84.4783717,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.4783717
    },
    {
      "id": 4000,
      "name": "Crete, NE - Purina",
      "correlation_id": "102090",
      "billing_address": null,
      "start_date": "2022-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 721,
        "name": "Nestle",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "67b06b43-0a3d-4590-afcd-81b959e1ae38"
      },
      "parent_id": null,
      "lat": 40.63709,
      "lon": -96.94421,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5c4b5eea-8900-47d7-b0e7-b28df35fc576",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Nestle",
      "_label": "Nestle",
      "_name": "Crete, NE - Purina",
      "_lat": 40.63709,
      "_lng": -96.94421,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025316,
          "create_date": "2024-03-07T17:48:17.883086Z",
          "update_date": "2024-03-07T17:48:17.883117Z",
          "organization_id": 721,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/0b5a3f66eb9a4fd7869d0bc1a506b8cd_0_FKwIAXSv8sFEWPmL_t.png",
          "size": 233845,
          "description": null,
          "link": null,
          "unique_identifier": "0b5a3f66eb9a4fd7869d0bc1a506b8cd"
        }
      ],
      "lng": -96.94421
    },
    {
      "id": 2010,
      "name": "Creve Coeur, MO",
      "correlation_id": "100010",
      "billing_address": {
        "id": 2504,
        "street1": "800 N Lindbergh Blvd",
        "street2": "",
        "city": "Creve Coeur",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.6708725,
        "lon": -90.3993253,
        "country": "United States"
      },
      "start_date": "2013-11-13T08:00:00Z",
      "total_sq_ft": "1803958",
      "total_buildings": "26",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 38.6711242,
      "lon": -90.402722,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "88910aeb-29ad-45d5-8efb-09d069755256",
      "is_mobile": true,
      "mobile_live_date": "2020-09-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Creve Coeur, MO",
      "_lat": 38.6711242,
      "_lng": -90.402722,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -90.402722
    },
    {
      "id": 5691,
      "name": "CRM Overhead-Tesla Inc.",
      "correlation_id": "888888",
      "billing_address": null,
      "start_date": "2023-04-11T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "976139cf-44ce-4a5d-86dd-18909e3357aa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "CRM Overhead-Tesla Inc.",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5821,
      "name": "Cropscience LP*",
      "correlation_id": "102744",
      "billing_address": {
        "id": 3769,
        "street1": "PO Box 98  Pittsburgh, PA 15230",
        "street2": "",
        "city": "Pittsburgh",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.442168,
        "lon": -79.9949422,
        "country": "United States"
      },
      "start_date": "2023-08-15T17:44:45Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2159,
        "name": "Bayer Cropscience",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b9764ed-3c91-4b2f-a212-d1ffc42df849"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "35a8ded4-3111-46c0-842a-916c524cf71f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer Cropscience",
      "_label": "Bayer Cropscience",
      "_name": "Cropscience LP*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6764,
      "name": "CR Supply Vantive CustodialI*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-01-31T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2606,
        "name": "Vantive SRL (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5fd58691-b60d-4890-83e3-5607ca5f5b8a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d69a2dee-1e3c-49bc-8c83-48685151a906",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Vantive SRL (CR)",
      "_label": "Vantive SRL (CR)",
      "_name": "CR Supply Vantive CustodialI*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5696,
      "name": "Crystal Drive",
      "correlation_id": "102555",
      "billing_address": {
        "id": 3956,
        "street1": "2231 Crystal Dr",
        "street2": "",
        "city": "Arlington",
        "region": "Virginia",
        "postal_code": null,
        "lat": 38.8538766,
        "lon": -77.0485809,
        "country": "United States"
      },
      "start_date": "2023-04-20T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 38.8538766,
      "lon": -77.0485809,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "49fba730-e469-4fe8-983c-8a4d7f0deaf8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Crystal Drive",
      "_lat": 38.8538766,
      "_lng": -77.0485809,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -77.0485809
    },
    {
      "id": 5529,
      "name": "CSP – Chicago Stamping Plant – Chicago, IL",
      "correlation_id": "102396",
      "billing_address": {
        "id": 3018,
        "street1": "1000 E Lincoln Hwy.",
        "street2": "",
        "city": "Chicago Heights",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.5034158,
        "lon": -87.6013317,
        "country": "United States"
      },
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "5529",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 41.5030221,
      "lon": -87.6013424,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "947a0fa5-2746-4848-8b5b-36c7dc076cd4",
      "is_mobile": true,
      "mobile_live_date": "2023-01-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "CSP – Chicago Stamping Plant – Chicago, IL",
      "_lat": 41.5030221,
      "_lng": -87.6013424,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.6013424
    },
    {
      "id": 1948,
      "name": "CTC - Chrysler Technology - Auburn Hills, MI",
      "correlation_id": "101709",
      "billing_address": {
        "id": 908,
        "street1": "1000 Chrysler Drive",
        "street2": "",
        "city": "Auburn Hills",
        "region": "Michigan",
        "postal_code": "48326-2766",
        "lat": 42.6519895,
        "lon": -83.2309171,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.6519895,
      "lon": -83.2309171,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9b2db303-6b13-4da3-9bba-202a175ac545",
      "is_mobile": true,
      "mobile_live_date": "2022-04-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "CTC - Chrysler Technology - Auburn Hills, MI",
      "_lat": 42.6519895,
      "_lng": -83.2309171,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.2309171
    },
    {
      "id": 2557,
      "name": "Cupertino",
      "correlation_id": "102159",
      "billing_address": {
        "id": 543,
        "street1": "19620 Stevens Creek Blvd",
        "street2": "",
        "city": "Cupertino",
        "region": "California",
        "postal_code": "95014",
        "lat": 37.3225371,
        "lon": -122.0167038,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "30fdf3a7-aa62-4392-9dac-8b4ee46bd245",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Cupertino",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6869,
      "name": "Custodial - Supplies*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-01-31T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2606,
        "name": "Vantive SRL (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5fd58691-b60d-4890-83e3-5607ca5f5b8a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c2d952e8-848a-41a5-9587-9c86fb800cb8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Vantive SRL (CR)",
      "_label": "Vantive SRL (CR)",
      "_name": "Custodial - Supplies*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4829,
      "name": "CVG Endeavor & Terminal",
      "correlation_id": "102234",
      "billing_address": {
        "id": 3626,
        "street1": "3306 Kenton Rd.",
        "street2": "",
        "city": "Hebron",
        "region": "Kentucky",
        "postal_code": null,
        "lat": 39.0606845,
        "lon": -84.6506254,
        "country": "United States"
      },
      "start_date": "2021-04-01T18:03:13Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "48f5ca10-b834-475e-8e6f-9d88dee0340b",
      "is_mobile": true,
      "mobile_live_date": "2023-04-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "CVG Endeavor & Terminal",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4821,
      "name": "CVG Reservations",
      "correlation_id": "102109",
      "billing_address": {
        "id": 2697,
        "street1": "3306 Kenton Rd.",
        "street2": "",
        "city": "Hebron",
        "region": "Kentucky",
        "postal_code": "41048",
        "lat": 39.04603,
        "lon": -84.644067,
        "country": "United States"
      },
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 39.0606845,
      "lon": -84.6506254,
      "local_timezone": {
        "id": 2219,
        "code": "America/Kentucky/Monticello",
        "display_name": "America/Kentucky/Monticello",
        "description": "",
        "child_group": null,
        "alt_sequence": 85
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d1ecc0c3-0068-443c-9e6d-9dbc2e9db589",
      "is_mobile": true,
      "mobile_live_date": "2023-04-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "CVG Reservations",
      "_lat": 39.0606845,
      "_lng": -84.6506254,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.6506254
    },
    {
      "id": 6737,
      "name": "Cypress",
      "correlation_id": "103405",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 33.80227,
      "lon": -118.03297,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b31aab58-8a05-4e4e-8521-8ea959a5a432",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "Cypress",
      "_lat": 33.80227,
      "_lng": -118.03297,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": -118.03297
    },
    {
      "id": 5768,
      "name": "DAE7 – North Versailles, PA",
      "correlation_id": "102618",
      "billing_address": {
        "id": 4057,
        "street1": "833 E Pittsburgh McKeesport Road",
        "street2": "",
        "city": "North Versailles",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.3788426,
        "lon": -79.8283164,
        "country": "United States"
      },
      "start_date": "2023-07-26T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 38.89036,
      "lon": -77.03196,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "678496f7-3b16-41e1-b54d-f45ad1efb6ce",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DAE7 – North Versailles, PA",
      "_lat": 38.89036,
      "_lng": -77.03196,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.03196
    },
    {
      "id": 5759,
      "name": "DAG Asia Gateway - Singapore",
      "correlation_id": "102601",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8bc20036-8753-41f4-afdf-e10159422e7b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DAG Asia Gateway - Singapore",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5784,
      "name": "DAH - Singapore",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-07-03T18:23:16Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 1.34039,
      "lon": 103.96798,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0f85d1a7-406e-4cc7-85fe-cf8dcca03f67",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DAH - Singapore",
      "_lat": 1.34039,
      "_lng": 103.96798,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.96798
    },
    {
      "id": 2259,
      "name": "Dallas",
      "correlation_id": "101352",
      "billing_address": {
        "id": 1122,
        "street1": "4855 Mountain Creek Pkwy",
        "street2": "",
        "city": "Dallas",
        "region": "Texas",
        "postal_code": "75236",
        "lat": 32.6750905,
        "lon": -96.9689812,
        "country": "United States"
      },
      "start_date": "2020-11-05T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 324,
        "name": "Cummins (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "574316cc-b5f2-4343-875f-9fed409fdd22"
      },
      "parent_id": null,
      "lat": 32.674991,
      "lon": -96.9710183,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8da094a8-40a6-4a7b-80ab-452696940e29",
      "is_mobile": true,
      "mobile_live_date": "2021-12-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cummins (C&W)",
      "_label": "Cummins (C&W)",
      "_name": "Dallas",
      "_lat": 32.674991,
      "_lng": -96.9710183,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.9710183
    },
    {
      "id": 2516,
      "name": "Dallas Beltline",
      "correlation_id": "102160",
      "billing_address": {
        "id": 353,
        "street1": "5100 Beltline Road",
        "street2": "",
        "city": "Dallas",
        "region": "Texas",
        "postal_code": "75254",
        "lat": 32.9515797,
        "lon": -96.8206605,
        "country": "United States"
      },
      "start_date": "2021-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c7201d4e-2d60-4706-a041-91bf66233860",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Dallas Beltline",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3695,
      "name": "Dallas, TX",
      "correlation_id": "102067",
      "billing_address": {
        "id": 1671,
        "street1": "3005 W. Wintergreen",
        "street2": "",
        "city": "Lacanster",
        "region": "Texas",
        "postal_code": "75134",
        "lat": 32.6152008,
        "lon": -96.8046393,
        "country": "United States"
      },
      "start_date": "2022-06-02T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1348,
        "name": "Waymo Lancaster",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1670,
            "street1": "3005 W. Wintergreen",
            "street2": "",
            "city": "Lancaster",
            "region": "Texas",
            "postal_code": "75134",
            "lat": 32.6152008,
            "lon": -96.8046393,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "988b7e53-edd1-457d-9949-04506a0e7d63"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5c46b8ee-cad5-4b00-86a8-5f303c2c99ac",
      "is_mobile": true,
      "mobile_live_date": "2022-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Waymo Lancaster",
      "_label": "Waymo Lancaster",
      "_name": "Dallas, TX",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4289,
      "name": "Dallas, TX",
      "correlation_id": "102117",
      "billing_address": {
        "id": 3955,
        "street1": "1700 N Hwy 360",
        "street2": "",
        "city": "Grand Prairie",
        "region": "Texas",
        "postal_code": null,
        "lat": 32.7749236,
        "lon": -97.0595914,
        "country": "United States"
      },
      "start_date": "2023-12-27T19:34:04Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 32.7749236,
      "lon": -97.0595914,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "88db8dbd-5e3e-4af9-9e9a-2d444e15ad85",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Dallas, TX",
      "_lat": 32.7749236,
      "_lng": -97.0595914,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -97.0595914
    },
    {
      "id": 5671,
      "name": "Dallas, TX",
      "correlation_id": "102542",
      "billing_address": {
        "id": 3574,
        "street1": "8750 N. Central Expy",
        "street2": "",
        "city": "Dallas",
        "region": "Texas",
        "postal_code": null,
        "lat": 32.8670206,
        "lon": -96.7690569,
        "country": "United States"
      },
      "start_date": "2023-04-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 32.86681,
      "lon": -96.76804,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9fcabf8d-9f93-4e38-b556-62207a0b5609",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Dallas, TX",
      "_lat": 32.86681,
      "_lng": -96.76804,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -96.76804
    },
    {
      "id": 3864,
      "name": "DASH (DHL)",
      "correlation_id": "102098",
      "billing_address": {
        "id": 1835,
        "street1": "1300 E. Fulghum Road, Bldg 3",
        "street2": "",
        "city": "Wilmer",
        "region": "Texas",
        "postal_code": "75172",
        "lat": 32.6241176,
        "lon": -96.6816518,
        "country": "United States"
      },
      "start_date": "2022-06-13T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1447,
        "name": "DHL/Nike",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "52dc54cc-28ba-49fd-858a-0c83b85e2efb"
      },
      "parent_id": null,
      "lat": 32.6241176,
      "lon": -96.6816518,
      "local_timezone": {
        "id": 2191,
        "code": "America/Fort_Wayne",
        "display_name": "America/Fort_Wayne",
        "description": "",
        "child_group": null,
        "alt_sequence": 57
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "331580c5-fc9b-4d85-8284-a1f4d99bb3be",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DHL/Nike",
      "_label": "DHL/Nike",
      "_name": "DASH (DHL)",
      "_lat": 32.6241176,
      "_lng": -96.6816518,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.6816518
    },
    {
      "id": 2558,
      "name": "Davis",
      "correlation_id": "102161",
      "billing_address": {
        "id": 544,
        "street1": "508 2nd St",
        "street2": "",
        "city": "Davis",
        "region": "California",
        "postal_code": "95616",
        "lat": 38.5427382,
        "lon": -121.7417471,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1b42ddf5-5568-432b-a375-434f450ee387",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Davis",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5758,
      "name": "DAX Asia X - Singapore",
      "correlation_id": "102602",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "efc9202c-ccc4-4809-bfad-3f12c9ca4c25",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DAX Asia X - Singapore",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2472,
      "name": "Dayton, OH",
      "correlation_id": "101429",
      "billing_address": {
        "id": 316,
        "street1": "9797 Springboro Pike",
        "street2": "",
        "city": "Dayton",
        "region": "Ohio",
        "postal_code": "45448",
        "lat": 39.6086531,
        "lon": -84.2320308,
        "country": "United States"
      },
      "start_date": "2021-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 445,
        "name": "Metlife",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 315,
            "street1": "9797 Springboro Pike",
            "street2": "",
            "city": "Dayton",
            "region": "Ohio",
            "postal_code": "45448",
            "lat": 39.6085841,
            "lon": -84.2315274,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "38ab93c4-5265-4140-8eb7-b500506ff6e5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "67074b42-3143-4702-a343-e9bc974822c2",
      "is_mobile": true,
      "mobile_live_date": "2021-09-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Metlife",
      "_label": "Metlife",
      "_name": "Dayton, OH",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5757,
      "name": "DBS Academy - Singapore",
      "correlation_id": "102599",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 1.33317,
      "lon": 103.96452,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e44c7e26-55b0-4281-b7a9-de9807b943e2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DBS Academy - Singapore",
      "_lat": 1.33317,
      "_lng": 103.96452,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.96452
    },
    {
      "id": 5756,
      "name": "DBS Asia Central - Singapore",
      "correlation_id": "102598",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 1.27912,
      "lon": 103.85437,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "67bf4abc-192e-4cea-8f09-44fd3174a3db",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DBS Asia Central - Singapore",
      "_lat": 1.27912,
      "_lng": 103.85437,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.85437
    },
    {
      "id": 5761,
      "name": "DBS Asia Hub - Singapore",
      "correlation_id": "103439",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e3ae5fa7-c523-4fae-b879-08073ce0ebd3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DBS Asia Hub - Singapore",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5760,
      "name": "DBS Digital Basecamp - Singapore",
      "correlation_id": "102600",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2143,
        "name": "DBS (JLL) (SG)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f7458587-4692-4a50-93f9-cff6b725eb1b"
      },
      "parent_id": null,
      "lat": 1.33233,
      "lon": 103.95212,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "64b49e7e-f0c6-44e6-b9d4-94b28ff0fde5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "DBS (JLL) (SG)",
      "_label": "DBS (JLL) (SG)",
      "_name": "DBS Digital Basecamp - Singapore",
      "_lat": 1.33233,
      "_lng": 103.95212,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.95212
    },
    {
      "id": 5859,
      "name": "DDE – Driving Dynamics East – Dearborn, MI",
      "correlation_id": "102790",
      "billing_address": {
        "id": 3983,
        "street1": "20500 Oakwood Blvd.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2991455,
        "lon": -83.2304597,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:13:19Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2991455,
      "lon": -83.2304597,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "DDE – Driving Dynamics East – Dearborn, MI",
      "_lat": 42.2991455,
      "_lng": -83.2304597,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2304597
    },
    {
      "id": 5773,
      "name": "DDP3 – Philadelphia, PA",
      "correlation_id": "102613",
      "billing_address": {
        "id": 3801,
        "street1": "6901 Elmwood Ave",
        "street2": "",
        "city": "Philadelphia",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 39.91966,
        "lon": -75.236221,
        "country": "United States"
      },
      "start_date": "2023-08-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.91817,
      "lon": -75.23604,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bf9da324-d027-4f3e-94a2-74c9bc6f1455",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DDP3 – Philadelphia, PA",
      "_lat": 39.91817,
      "_lng": -75.23604,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -75.23604
    },
    {
      "id": 5860,
      "name": "DDW – Driving Dynamics West – Dearborn, MI",
      "correlation_id": "102791",
      "billing_address": {
        "id": 3982,
        "street1": "20500 Oakwood Blvd.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2991455,
        "lon": -83.2304597,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:10:36Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2991455,
      "lon": -83.2304597,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "DDW – Driving Dynamics West – Dearborn, MI",
      "_lat": 42.2991455,
      "_lng": -83.2304597,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2304597
    },
    {
      "id": 2440,
      "name": "Deer Creek",
      "correlation_id": "101330",
      "billing_address": {
        "id": 264,
        "street1": "12999 W Deer Creek Canyon Road",
        "street2": "",
        "city": "Littleton",
        "region": "Colorado",
        "postal_code": "80127",
        "lat": 39.5563589,
        "lon": -105.1640291,
        "country": "United States"
      },
      "start_date": "2021-04-02T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 633,
        "name": "Lockheed Martin (EMCOR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c93ce0af-c00d-47d5-a625-b7df62dbc9e8"
      },
      "parent_id": null,
      "lat": 39.5563609,
      "lon": -105.1639996,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3c21aefd-30cf-418b-80fd-4d0e17f2ef88",
      "is_mobile": true,
      "mobile_live_date": "2021-04-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin (EMCOR)",
      "_label": "Lockheed Martin (EMCOR)",
      "_name": "Deer Creek",
      "_lat": 39.5563609,
      "_lng": -105.1639996,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3559853,
          "create_date": "2022-04-06T17:20:05.229290Z",
          "update_date": "2022-04-06T17:20:05.229315Z",
          "organization_id": 633,
          "uploaded_by_id": 37962,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/813978f43b28460882fb4afd51c0845c_LMLogo4insite_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": "813978f43b28460882fb4afd51c0845c"
        }
      ],
      "lng": -105.1639996
    },
    {
      "id": 5881,
      "name": "Delta Overhead",
      "correlation_id": "102768",
      "billing_address": null,
      "start_date": "2023-09-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2844286a-9754-4453-8c2f-f7c4b6ed690a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "Delta Overhead",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1674,
      "name": "Denver",
      "correlation_id": "11281",
      "billing_address": {
        "id": 429,
        "street1": "1701 Lincoln St.",
        "street2": "",
        "city": "Denver",
        "region": "Colorado",
        "postal_code": "80274",
        "lat": 39.7439066,
        "lon": -104.9863433,
        "country": "United States"
      },
      "start_date": "2021-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "14",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 39.7439704,
      "lon": -104.9878976,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
      "is_mobile": true,
      "mobile_live_date": "2021-05-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Denver",
      "_lat": 39.7439704,
      "_lng": -104.9878976,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -104.9878976
    },
    {
      "id": 4423,
      "name": "Denver*",
      "correlation_id": "102429",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 39.78542,
      "lon": -104.87289,
      "local_timezone": {
        "id": 2283,
        "code": "America/Tijuana",
        "display_name": "America/Tijuana",
        "description": "",
        "child_group": null,
        "alt_sequence": 149
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5aa4c872-f4fc-42c8-8b4a-414d93187fed",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Denver*",
      "_lat": 39.78542,
      "_lng": -104.87289,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -104.87289
    },
    {
      "id": 1281,
      "name": "Denver, CO",
      "correlation_id": "10256",
      "billing_address": {
        "id": 746,
        "street1": "700 Broadway",
        "street2": "",
        "city": "Denver",
        "region": "Colorado",
        "postal_code": "80203",
        "lat": 39.7282271,
        "lon": -104.9868044,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 39.7283546,
      "lon": -104.9867637,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "28858fc3-e7b5-4ef2-8e39-436a7256cd11",
      "is_mobile": true,
      "mobile_live_date": "2021-09-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Denver, CO",
      "_lat": 39.7283546,
      "_lng": -104.9867637,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -104.9867637
    },
    {
      "id": 1767,
      "name": "Denver, CO",
      "correlation_id": "11474",
      "billing_address": null,
      "start_date": "2019-02-18T00:00:00Z",
      "total_sq_ft": "170000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 632,
        "name": "Lockheed Martin Corporate",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b9491330-e8e7-4620-9abc-b0d7d83c18cc"
      },
      "parent_id": null,
      "lat": 39.8334334,
      "lon": -105.0036935,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4eae7e26-bb7e-4051-aadb-b3e4fe6e765e",
      "is_mobile": true,
      "mobile_live_date": "2022-06-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Corporate",
      "_label": "Lockheed Martin Corporate",
      "_name": "Denver, CO",
      "_lat": 39.8334334,
      "_lng": -105.0036935,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3567680,
          "create_date": "2022-04-07T14:21:18.071091Z",
          "update_date": "2022-04-07T14:21:18.071116Z",
          "organization_id": 632,
          "uploaded_by_id": 37962,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/0ab79d7dd15e463ba18c185fa4b8e783_LMLogo4insite_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": "0ab79d7dd15e463ba18c185fa4b8e783"
        }
      ],
      "lng": -105.0036935
    },
    {
      "id": 5831,
      "name": "Denver Service Center",
      "correlation_id": "102586",
      "billing_address": {
        "id": 4095,
        "street1": "5700 DTC Parkway",
        "street2": "",
        "city": "Greenwood Village",
        "region": "Colorado",
        "postal_code": null,
        "lat": 39.612517,
        "lon": -104.888335,
        "country": "United States"
      },
      "start_date": "2021-08-01T23:26:31Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 39.612517,
      "lon": -104.888335,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aa9ac4b5-0c72-4ca1-8e5a-aac497872ead",
      "is_mobile": true,
      "mobile_live_date": "2024-01-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Denver Service Center",
      "_lat": 39.612517,
      "_lng": -104.888335,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -104.888335
    },
    {
      "id": 1391,
      "name": "DEP - Dundee Engine Plant - Dundee, MI",
      "correlation_id": "101694",
      "billing_address": {
        "id": 922,
        "street1": "5800 Ann Arbor Rd",
        "street2": "",
        "city": "Dundee",
        "region": "Michigan",
        "postal_code": "48131",
        "lat": 41.9797666,
        "lon": -83.6726201,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 41.9797579,
      "lon": -83.6726897,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ac2be644-ba77-4e3f-98a8-b0ee55143e04",
      "is_mobile": true,
      "mobile_live_date": "2022-05-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "DEP - Dundee Engine Plant - Dundee, MI",
      "_lat": 41.9797579,
      "_lng": -83.6726897,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.6726897
    },
    {
      "id": 1325,
      "name": "Des Moines",
      "correlation_id": "10334",
      "billing_address": {
        "id": 678,
        "street1": "800 Walnut Street",
        "street2": "",
        "city": "Des Moines",
        "region": "Iowa",
        "postal_code": "50309",
        "lat": 41.584977,
        "lon": -93.627585,
        "country": "United States"
      },
      "start_date": "2014-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 41.5854643,
      "lon": -93.6298999,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
      "is_mobile": true,
      "mobile_live_date": "2021-08-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Des Moines",
      "_lat": 41.5854643,
      "_lng": -93.6298999,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.6298999
    },
    {
      "id": 2120,
      "name": "Des Moines, IA",
      "correlation_id": "11728",
      "billing_address": {
        "id": 2997,
        "street1": "550 White Crane Rd.",
        "street2": "",
        "city": "Des Moines",
        "region": "Iowa",
        "postal_code": null,
        "lat": 41.5160509,
        "lon": -93.7088467,
        "country": "United States"
      },
      "start_date": "2020-05-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "25",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 41.619549,
      "lon": -93.598022,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
      "is_mobile": true,
      "mobile_live_date": "2022-12-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Des Moines, IA",
      "_lat": 41.619549,
      "_lng": -93.598022,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -93.598022
    },
    {
      "id": 3140,
      "name": "Des Moines (JLL)",
      "correlation_id": "101972",
      "billing_address": {
        "id": 1289,
        "street1": "329 5th Street",
        "street2": "",
        "city": "West Des Moines",
        "region": "Iowa",
        "postal_code": "50265",
        "lat": 41.573626,
        "lon": -93.7086485,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 41.573626,
      "lon": -93.7086485,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eec4ca94-ef7a-4d07-9701-00a5c5dfedb9",
      "is_mobile": true,
      "mobile_live_date": "2022-12-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Des Moines (JLL)",
      "_lat": 41.573626,
      "_lng": -93.7086485,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -93.7086485
    },
    {
      "id": 1994,
      "name": "Des Plaines",
      "correlation_id": "11629",
      "billing_address": {
        "id": 267,
        "street1": "2602 Gilboa Ave.",
        "street2": "",
        "city": "Zion",
        "region": "Illinois",
        "postal_code": "60099",
        "lat": 42.4476764,
        "lon": -87.8424856,
        "country": "United States"
      },
      "start_date": "2019-11-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 42.0100199,
      "lon": -87.8953908,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0b4765d2-3d2d-4c02-8c6a-dcd2330964ec",
      "is_mobile": true,
      "mobile_live_date": "2021-03-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Des Plaines",
      "_lat": 42.0100199,
      "_lng": -87.8953908,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.8953908
    },
    {
      "id": 6666,
      "name": "DET1 – Livonia, MI",
      "correlation_id": "103374",
      "billing_address": {
        "id": 4052,
        "street1": "39000 Amrhein Rd",
        "street2": "",
        "city": "Livonia",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.3763435,
        "lon": -83.4233296,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 42.375587,
      "lon": -83.423309,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "39b4e2fd-7358-4b49-8f77-8e860f5c3ad3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DET1 – Livonia, MI",
      "_lat": 42.375587,
      "_lng": -83.423309,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.423309
    },
    {
      "id": 6672,
      "name": "DET2 – Utica, MI",
      "correlation_id": "103381",
      "billing_address": {
        "id": 4083,
        "street1": "50500 Mound Rd",
        "street2": "",
        "city": "Utica",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.6245344,
        "lon": -83.0518636,
        "country": "United States"
      },
      "start_date": "2024-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 42.665807,
      "lon": -83.04956,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ec58c2d-6467-4f49-bdb4-291e6a5c3ca8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DET2 – Utica, MI",
      "_lat": 42.665807,
      "_lng": -83.04956,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.04956
    },
    {
      "id": 4835,
      "name": "DET6 - Detroit, MI",
      "correlation_id": "102240",
      "billing_address": {
        "id": 4080,
        "street1": "20110 Woodward Ave",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.441317,
        "lon": -83.113331,
        "country": "United States"
      },
      "start_date": "2022-11-07T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 42.44145,
      "lon": -83.12131,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "890ac184-78b2-4b16-9cb3-30ecc34afb4e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DET6 - Detroit, MI",
      "_lat": 42.44145,
      "_lng": -83.12131,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.12131
    },
    {
      "id": 2084,
      "name": "Detroit, MI",
      "correlation_id": "101189",
      "billing_address": {
        "id": 708,
        "street1": "6000 Rosa Parks BLVD Detroit, MI 48208",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48208",
        "lat": 42.3583029,
        "lon": -83.0864236,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 42.359421,
      "lon": -83.0896827,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "810946b0-f603-405f-a1f7-c5bc7f28901d",
      "is_mobile": true,
      "mobile_live_date": "2021-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Detroit, MI",
      "_lat": 42.359421,
      "_lng": -83.0896827,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.0896827
    },
    {
      "id": 4586,
      "name": "Detroit, MI (DTW)",
      "correlation_id": "102146",
      "billing_address": {
        "id": 2988,
        "street1": "John D Dingell Drive",
        "street2": "",
        "city": "Romulus",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2100176,
        "lon": -83.352732,
        "country": "United States"
      },
      "start_date": "2022-10-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": 5493,
      "lat": 42.2162491,
      "lon": -83.3575529,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "94cdce4b-8e38-4d9f-9a80-f6462968baf6",
      "is_mobile": true,
      "mobile_live_date": "2022-11-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "Detroit, MI (DTW)",
      "_lat": 42.2162491,
      "_lng": -83.3575529,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.3575529
    },
    {
      "id": 4195,
      "name": "Detroit, MI (JLL)",
      "correlation_id": "102114",
      "billing_address": {
        "id": 2133,
        "street1": "52 Henry Street",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48201",
        "lat": 42.340458,
        "lon": -83.054494,
        "country": "United States"
      },
      "start_date": "2022-08-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 40.741895,
      "lon": -73.989308,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3a13f626-44f9-4176-872d-758e096200f8",
      "is_mobile": true,
      "mobile_live_date": "2022-09-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Detroit, MI (JLL)",
      "_lat": 40.741895,
      "_lng": -73.989308,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -73.989308
    },
    {
      "id": 4619,
      "name": "Detroit, MI (ODM)",
      "correlation_id": "102206",
      "billing_address": {
        "id": 3567,
        "street1": "52 Henry Street.",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.340458,
        "lon": -83.054494,
        "country": "United States"
      },
      "start_date": "2022-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 40.741895,
      "lon": -73.989308,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f0e60070-cb8e-4cda-8504-419acfdf70d8",
      "is_mobile": true,
      "mobile_live_date": "2023-03-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Detroit, MI (ODM)",
      "_lat": 40.741895,
      "_lng": -73.989308,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -73.989308
    },
    {
      "id": 1261,
      "name": "Devens",
      "correlation_id": "10223",
      "billing_address": {
        "id": 364,
        "street1": "38 Jackson Road",
        "street2": "",
        "city": "Devens",
        "region": "Massachusetts",
        "postal_code": "978",
        "lat": 42.5325991,
        "lon": -71.6283095,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "14000000",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 42.5319135,
      "lon": -71.624895,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2c4a5b92-d06b-409e-b634-124a715952bb",
      "is_mobile": true,
      "mobile_live_date": "2020-08-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Devens",
      "_lat": 42.5319135,
      "_lng": -71.624895,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.624895
    },
    {
      "id": 2314,
      "name": "DFE2*",
      "correlation_id": "101384",
      "billing_address": null,
      "start_date": "2020-12-28T00:00:00Z",
      "total_sq_ft": "59872",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 41,
        "name": "Amazon Singapore",
        "type": "CUSTOMER",
        "correlation_id": "10653",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "27af34db-bac5-47b0-a09f-6977eb4696cd"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a4442cfd-8528-4ef4-9541-72b708671b7a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon Singapore",
      "_label": "Amazon Singapore",
      "_name": "DFE2*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 916883,
          "create_date": "2020-12-07T16:49:50.464664Z",
          "update_date": "2020-12-07T16:49:50.464685Z",
          "organization_id": 41,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607359790.3595705_Amazon_t.png",
          "size": 103415,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 5688,
      "name": "DFW",
      "correlation_id": "101597",
      "billing_address": null,
      "start_date": "2023-03-31T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2125,
        "name": "United Cargo",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "95d4bff4-e915-4282-bc15-7e035d9c370d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3ecd5f3f-b2ad-4dca-b05c-9307c259c72c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "United Cargo",
      "_label": "United Cargo",
      "_name": "DFW",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2579,
      "name": "DFW Cargo Facility*",
      "correlation_id": "101521",
      "billing_address": {
        "id": 577,
        "street1": "2417 N. Support Road",
        "street2": "",
        "city": "Dallas",
        "region": "Texas",
        "postal_code": "75261",
        "lat": 32.8822618,
        "lon": -97.0359059,
        "country": "United States"
      },
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cc1f8e67-eca8-45a2-97d9-d751725ee14e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "DFW Cargo Facility*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2422,
      "name": "DFW Reservations (Dallas Fort Worth, TX)*",
      "correlation_id": "101363",
      "billing_address": {
        "id": 245,
        "street1": "2409 North Support Rd, DFW Airport",
        "street2": "",
        "city": "Dallas",
        "region": "Texas",
        "postal_code": "75261",
        "lat": 32.8830301,
        "lon": -97.0366164,
        "country": "United States"
      },
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "20",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 32.88281,
      "lon": -97.03738,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1f78a7db-fe23-48d1-9582-37f3ffbff09a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "DFW Reservations (Dallas Fort Worth, TX)*",
      "_lat": 32.88281,
      "_lng": -97.03738,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -97.03738
    },
    {
      "id": 5647,
      "name": "Distirubtion Vertical Overhead",
      "correlation_id": "102524",
      "billing_address": null,
      "start_date": "2023-03-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2122,
        "name": "CRM NAD",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "81f5b455-4c85-40ce-9d10-0f1b32a6959d"
      },
      "parent_id": null,
      "lat": 38.66302,
      "lon": -121.38516,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "162bba92-0f44-46eb-ad30-8769f5e71505",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CRM NAD",
      "_label": "CRM NAD",
      "_name": "Distirubtion Vertical Overhead",
      "_lat": 38.66302,
      "_lng": -121.38516,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.38516
    },
    {
      "id": 1710,
      "name": "DIV001",
      "correlation_id": "11339",
      "billing_address": {
        "id": 601,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "05ece597-56b2-4d56-985c-0ce267a6b950",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "DIV001",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1705,
      "name": "DIV002",
      "correlation_id": "11337",
      "billing_address": {
        "id": 596,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e9b10a68-1b30-4828-b775-05d995b2e239",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "DIV002",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1698,
      "name": "DIV003",
      "correlation_id": "11329",
      "billing_address": {
        "id": 623,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a05eb463-03f0-4e74-9d72-4b6b8fbc40e6",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "DIV003",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1715,
      "name": "DIV004",
      "correlation_id": "11344",
      "billing_address": {
        "id": 597,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3b19dfa3-4a13-42cd-9669-1204475153a0",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "DIV004",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1684,
      "name": "DIV005",
      "correlation_id": "11312",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ecfe8182-b0aa-420f-a1b7-5859efd4f3a0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "DIV005",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1701,
      "name": "DIV007",
      "correlation_id": "11333",
      "billing_address": {
        "id": 598,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "32ffcc81-b133-43fb-9065-ab51728b919f",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "DIV007",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1821,
      "name": "DIV009",
      "correlation_id": "11557",
      "billing_address": {
        "id": 624,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e17a58f9-dd09-409c-9d24-000226356daf",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "DIV009",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 2636,
      "name": "DIV012",
      "correlation_id": "100980",
      "billing_address": null,
      "start_date": "2021-10-11T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "28e866d2-7330-4b14-b7bf-138304bb559c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "DIV012",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1693,
      "name": "DIV013",
      "correlation_id": "11324",
      "billing_address": {
        "id": 617,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d9c0b3b9-1ae3-4fe4-ad7f-a999f3d5d581",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "DIV013",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1718,
      "name": "DIV016",
      "correlation_id": "11347",
      "billing_address": {
        "id": 599,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "495fbfda-5d7a-4c0e-8a39-157f0e4b48e0",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "DIV016",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1687,
      "name": "DIV017",
      "correlation_id": "11315",
      "billing_address": {
        "id": 634,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "58e63d75-c274-4977-81f0-35f9e3c7ab23",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "DIV017",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 5770,
      "name": "DJT6 – Riverside, CA",
      "correlation_id": "102617",
      "billing_address": {
        "id": 4053,
        "street1": "20920 Krameria Ave",
        "street2": "",
        "city": "Riverside",
        "region": "California",
        "postal_code": null,
        "lat": 33.8816538,
        "lon": -117.2963302,
        "country": "United States"
      },
      "start_date": "2023-07-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 33.8814,
      "lon": -117.29912,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bb831662-5e47-4d40-a790-7ec796275140",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DJT6 – Riverside, CA",
      "_lat": 33.8814,
      "_lng": -117.29912,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.29912
    },
    {
      "id": 5766,
      "name": "DMS6 – Centerville, MN",
      "correlation_id": "102610",
      "billing_address": {
        "id": 4063,
        "street1": "I-35E and Highway 14",
        "street2": "",
        "city": "Centerville",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.9798755,
        "lon": -93.0888773,
        "country": "United States"
      },
      "start_date": "2023-08-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 45.16393,
      "lon": -93.04853,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "13a7ac6d-3aaa-4f25-b04b-a99b83a618c2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DMS6 – Centerville, MN",
      "_lat": 45.16393,
      "_lng": -93.04853,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -93.04853
    },
    {
      "id": 1379,
      "name": "DOW - Detroit Office Warehouse - Detroit, MI",
      "correlation_id": "101702",
      "billing_address": {
        "id": 901,
        "street1": "12501 Chrysler Dr.",
        "street2": "",
        "city": "Highland Park",
        "region": "Michigan",
        "postal_code": "48203",
        "lat": 42.4182108,
        "lon": -83.0885923,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.4057194,
      "lon": -83.0757457,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dfe524da-3af7-4f7b-8e70-65fa1430d0a8",
      "is_mobile": true,
      "mobile_live_date": "2022-06-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "DOW - Detroit Office Warehouse - Detroit, MI",
      "_lat": 42.4057194,
      "_lng": -83.0757457,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.0757457
    },
    {
      "id": 5769,
      "name": "DPD8 – Hillsboro, OR",
      "correlation_id": "102619",
      "billing_address": {
        "id": 3985,
        "street1": "4230 NE Huffman St.",
        "street2": "",
        "city": "Hillsboro",
        "region": "Oregon",
        "postal_code": null,
        "lat": 45.5556104,
        "lon": -122.9337956,
        "country": "United States"
      },
      "start_date": "2023-08-02T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 45.55684,
      "lon": -122.93379,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f92ce6c8-a741-4821-bc63-8eb4e661b89b",
      "is_mobile": true,
      "mobile_live_date": "2023-12-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DPD8 – Hillsboro, OR",
      "_lat": 45.55684,
      "_lng": -122.93379,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.93379
    },
    {
      "id": 1604,
      "name": "DRD01*",
      "correlation_id": "11150",
      "billing_address": null,
      "start_date": "2018-03-01T00:00:00Z",
      "total_sq_ft": "733519",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 168,
        "name": "HP- (SG) (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11149",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef2949fd-22c9-4c67-a18b-cb6aa3c53609"
      },
      "parent_id": null,
      "lat": 1.35576553,
      "lon": 103.86707921,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "58c5fe4c-7353-417f-b624-82d0e3b78e45",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP- (SG) (JLL)",
      "_label": "HP- (SG) (JLL)",
      "_name": "DRD01*",
      "_lat": 1.35576553,
      "_lng": 103.86707921,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.86707921
    },
    {
      "id": 2449,
      "name": "Dripping Springs",
      "correlation_id": "102163",
      "billing_address": {
        "id": 278,
        "street1": "166 Hargraves Drive, Suite E300",
        "street2": "",
        "city": "Dripping Springs",
        "region": "Texas",
        "postal_code": "78737-4837",
        "lat": 30.2044344,
        "lon": -97.9788743,
        "country": "United States"
      },
      "start_date": "2021-03-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "39f91da8-7dad-4610-ad66-cb297dc3f4b7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Dripping Springs",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1587,
      "name": "Dry Plant",
      "correlation_id": "11122",
      "billing_address": {
        "id": 57,
        "street1": "La Lima Free Zone, De la entrada de Pequeño Mundo 100 mts oeste y 200 mts sur.",
        "street2": "",
        "city": "Cartago",
        "region": "Alabama",
        "postal_code": "30103",
        "lat": 9.8638091,
        "lon": -83.9161935,
        "country": "Costa Rica"
      },
      "start_date": "2016-12-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 162,
        "name": "Edwards Lifesciences (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11121",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c28bfc11-286b-4cc9-9e18-859e3cec5950"
      },
      "parent_id": null,
      "lat": 9.91968841,
      "lon": -83.98298353,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a650e027-3b02-4a2a-b6c8-fa5c9e8818d7",
      "is_mobile": true,
      "mobile_live_date": "2021-11-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Edwards Lifesciences (CR)",
      "_label": "Edwards Lifesciences (CR)",
      "_name": "Dry Plant",
      "_lat": 9.91968841,
      "_lng": -83.98298353,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.98298353
    },
    {
      "id": 2220,
      "name": "DSC 1 & 2",
      "correlation_id": "103417",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29371,
      "lon": -83.19775,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "DSC 1 & 2",
      "_lat": 42.29371,
      "_lng": -83.19775,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.19775
    },
    {
      "id": 5979,
      "name": "DSM05*",
      "correlation_id": "102874",
      "billing_address": null,
      "start_date": "2023-11-22T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 41.51469,
      "lon": -93.70955,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aec9ad2f-3351-4612-a2d3-584bd2976987",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "DSM05*",
      "_lat": 41.51469,
      "_lng": -93.70955,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -93.70955
    },
    {
      "id": 2222,
      "name": "DTF – Drivability Test Facility – Allen Park, MI",
      "correlation_id": "101285",
      "billing_address": {
        "id": 210,
        "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": "48124",
        "lat": 42.2941466,
        "lon": -83.2271782,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": 5847,
      "lat": 42.2575,
      "lon": -83.211,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
      "is_mobile": true,
      "mobile_live_date": "2021-03-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "DTF – Drivability Test Facility – Allen Park, MI",
      "_lat": 42.2575,
      "_lng": -83.211,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.211
    },
    {
      "id": 1841,
      "name": "Dublin",
      "correlation_id": "10644",
      "billing_address": {
        "id": 3753,
        "street1": "7000 Cardinal Place",
        "street2": "",
        "city": "Dublin",
        "region": "Ohio",
        "postal_code": null,
        "lat": 40.1091299,
        "lon": -83.120912,
        "country": "United States"
      },
      "start_date": "2010-01-10T00:00:00Z",
      "total_sq_ft": "4500000",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 40.1088517,
      "lon": -83.1203963,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "319ccc39-7400-4889-92dd-f9101c88c68a",
      "is_mobile": true,
      "mobile_live_date": "2020-08-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Dublin",
      "_lat": 40.1088517,
      "_lng": -83.1203963,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.1203963
    },
    {
      "id": 2651,
      "name": "Dulles, VA",
      "correlation_id": "101538",
      "billing_address": {
        "id": 780,
        "street1": "12012 Sunset Hills Rd",
        "street2": "",
        "city": "Reston",
        "region": "Virginia",
        "postal_code": "20190",
        "lat": 38.9560719,
        "lon": -77.3578354,
        "country": "United States"
      },
      "start_date": "2021-09-22T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 38.9560719,
      "lon": -77.3578354,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
      "is_mobile": true,
      "mobile_live_date": "2022-02-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Dulles, VA",
      "_lat": 38.9560719,
      "_lng": -77.3578354,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -77.3578354
    },
    {
      "id": 5744,
      "name": "Duluth PCS (CBRE)",
      "correlation_id": "102587",
      "billing_address": null,
      "start_date": "2023-05-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a4ae81ac-bbb8-454a-8aee-bf2d22b3f093",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Duluth PCS (CBRE)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": 0
    },
    {
      "id": 6021,
      "name": "DUPO00OVRHD",
      "correlation_id": "102853",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e9c44673-eafd-4fb0-acc8-5791ac3e93ed",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "DUPO00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2576,
      "name": "Durham",
      "correlation_id": "101454",
      "billing_address": {
        "id": 574,
        "street1": "2400 Ellis road",
        "street2": "",
        "city": "Durham",
        "region": "North Carolina",
        "postal_code": "27703",
        "lat": 35.9353484,
        "lon": -78.8545872,
        "country": "United States"
      },
      "start_date": "2021-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 470,
        "name": "IQVIA (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 573,
            "street1": "2400 Ellis road",
            "street2": "",
            "city": "Durham",
            "region": "North Carolina",
            "postal_code": "27703",
            "lat": 35.9353484,
            "lon": -78.8545872,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "d7f7ea55-d5e5-4f58-9ffe-85e17bde2246"
      },
      "parent_id": null,
      "lat": 35.9353484,
      "lon": -78.8545872,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IQVIA (CBRE)",
      "_label": "IQVIA (CBRE)",
      "_name": "Durham",
      "_lat": 35.9353484,
      "_lng": -78.8545872,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567372,
          "create_date": "2024-02-08T22:57:39.786518Z",
          "update_date": "2024-02-08T22:57:39.786542Z",
          "organization_id": 470,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
          "size": 1894,
          "description": null,
          "link": null,
          "unique_identifier": "5fc2aad4b42343a4886a3ce342496b83"
        }
      ],
      "lng": -78.8545872
    },
    {
      "id": 1487,
      "name": "Durham, NC",
      "correlation_id": "10072",
      "billing_address": {
        "id": 3789,
        "street1": "5325 Old Oxford Road",
        "street2": "",
        "city": "Durham",
        "region": "North Carolina",
        "postal_code": null,
        "lat": 36.1116519,
        "lon": -78.8444576,
        "country": "United States"
      },
      "start_date": "2010-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "22",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 267,
        "name": "Merck (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
      },
      "parent_id": null,
      "lat": 36.1116685,
      "lon": -78.8450932,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
      "is_mobile": true,
      "mobile_live_date": "2023-10-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck (CBRE)",
      "_label": "Merck (CBRE)",
      "_name": "Durham, NC",
      "_lat": 36.1116685,
      "_lng": -78.8450932,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953527,
          "create_date": "2020-12-17T16:57:21.149904Z",
          "update_date": "2020-12-17T16:57:21.149930Z",
          "organization_id": 267,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -78.8450932
    },
    {
      "id": 3687,
      "name": "Durham, NC",
      "correlation_id": "102059",
      "billing_address": {
        "id": 1638,
        "street1": "59 Moore Drive",
        "street2": "",
        "city": "Durham",
        "region": "North Carolina",
        "postal_code": "27703",
        "lat": 35.9380499,
        "lon": -78.8652083,
        "country": "United States"
      },
      "start_date": "2022-07-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "15",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 72,
        "name": "Eli Lilly (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10042",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
      "is_mobile": true,
      "mobile_live_date": "2023-04-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eli Lilly (CBRE)",
      "_label": "Eli Lilly (CBRE)",
      "_name": "Durham, NC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853030,
          "create_date": "2020-11-13T23:55:46.918511Z",
          "update_date": "2020-11-13T23:55:46.918539Z",
          "organization_id": 72,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311746.7267878_Artboard8_t.png",
          "size": 413839,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 5679,
      "name": "Durham, NC",
      "correlation_id": "102546",
      "billing_address": {
        "id": 3573,
        "street1": "334 Blackwell Street, Suite 1100",
        "street2": "",
        "city": "Durham",
        "region": "North Carolina",
        "postal_code": null,
        "lat": 35.99289,
        "lon": -78.90473,
        "country": "United States"
      },
      "start_date": "2023-04-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 35.92246,
      "lon": -78.87321,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9229697e-026e-415e-a8b7-5dae881bd61c",
      "is_mobile": true,
      "mobile_live_date": "2024-01-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Durham, NC",
      "_lat": 35.92246,
      "_lng": -78.87321,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -78.87321
    },
    {
      "id": 5774,
      "name": "DVB8– Miami, FL",
      "correlation_id": "102612",
      "billing_address": {
        "id": 4073,
        "street1": "3355 Northwest 22nd St",
        "street2": "",
        "city": "Miami",
        "region": "Florida",
        "postal_code": null,
        "lat": 25.7969618,
        "lon": -80.2512574,
        "country": "United States"
      },
      "start_date": "2023-09-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 25.79622,
      "lon": -80.25056,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "29465cc3-c912-4105-964d-4ae1c33c19ed",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "DVB8– Miami, FL",
      "_lat": 25.79622,
      "_lng": -80.25056,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -80.25056
    },
    {
      "id": 5862,
      "name": "DYNO – Dynamometer – Dearborn, MI",
      "correlation_id": "102802",
      "billing_address": {
        "id": 3981,
        "street1": "1701 Village Rd, Dearborn, MI 48124",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.296369,
        "lon": -83.2349588,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:08:22Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.296369,
      "lon": -83.2349588,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
      "is_mobile": true,
      "mobile_live_date": "2024-02-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "DYNO – Dynamometer – Dearborn, MI",
      "_lat": 42.296369,
      "_lng": -83.2349588,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2349588
    },
    {
      "id": 5573,
      "name": "Easley, SC",
      "correlation_id": "102409",
      "billing_address": {
        "id": 3959,
        "street1": "1407 Pelzer Hwy",
        "street2": "",
        "city": "Easley",
        "region": "South Carolina",
        "postal_code": null,
        "lat": 34.7844377,
        "lon": -82.5801999,
        "country": "United States"
      },
      "start_date": "2023-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2077,
        "name": "Amentum Danfoss Power Solutions",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 2702,
            "street1": "2800 E 13th St.",
            "street2": "",
            "city": "Ames",
            "region": "Iowa",
            "postal_code": "50010",
            "lat": 42.0323055,
            "lon": -93.5733607,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "146ba0cf-b9bd-45ef-be7c-64ad3b2e4568"
      },
      "parent_id": null,
      "lat": 34.7844377,
      "lon": -82.5801999,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cb588a0e-f8a7-4451-a1d9-6c2fea6651aa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amentum Danfoss Power Solutions",
      "_label": "Amentum Danfoss Power Solutions",
      "_name": "Easley, SC",
      "_lat": 34.7844377,
      "_lng": -82.5801999,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686598,
          "create_date": "2024-02-16T12:11:27.224321Z",
          "update_date": "2024-02-16T12:11:27.224348Z",
          "organization_id": 2077,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/36e9d56bb0ab4530981389a811066e0e_AmentumIconFullNameGreen_t.png",
          "size": 9669,
          "description": null,
          "link": null,
          "unique_identifier": "36e9d56bb0ab4530981389a811066e0e"
        }
      ],
      "lng": -82.5801999
    },
    {
      "id": 2473,
      "name": "East Alton, IL",
      "correlation_id": "101421",
      "billing_address": {
        "id": 317,
        "street1": "305 Lewis and Clark Blvd.",
        "street2": "",
        "city": "East Alton",
        "region": "Illinois",
        "postal_code": "62024",
        "lat": 38.8699635,
        "lon": -90.1184819,
        "country": "United States"
      },
      "start_date": "2021-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "22",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 754,
        "name": "Wieland",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "638821ce-9ba3-4fed-81e0-f810ef2c1900"
      },
      "parent_id": null,
      "lat": 38.87,
      "lon": -90.115,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "362e7e45-58f0-498f-97dc-b91b649887b5",
      "is_mobile": true,
      "mobile_live_date": "2021-08-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wieland",
      "_label": "Wieland",
      "_name": "East Alton, IL",
      "_lat": 38.87,
      "_lng": -90.115,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -90.115
    },
    {
      "id": 1284,
      "name": "East Hanover",
      "correlation_id": "10261",
      "billing_address": {
        "id": 485,
        "street1": "1 Health Plaza",
        "street2": "",
        "city": "East Hanover",
        "region": "New Jersey",
        "postal_code": "07936",
        "lat": 40.8041041,
        "lon": -74.3862377,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "70640289",
      "total_buildings": "2016",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 270,
        "name": "Novartis (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
      },
      "parent_id": null,
      "lat": 40.8059016,
      "lon": -74.3922846,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8fc775dc-3aaa-4ae0-981b-1065911126f3",
      "is_mobile": true,
      "mobile_live_date": "2021-03-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis (CBRE)",
      "_label": "Novartis (CBRE)",
      "_name": "East Hanover",
      "_lat": 40.8059016,
      "_lng": -74.3922846,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859846,
          "create_date": "2020-11-17T14:36:58.595850Z",
          "update_date": "2020-11-17T14:36:58.595883Z",
          "organization_id": 270,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
          "size": 7078,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.3922846
    },
    {
      "id": 5972,
      "name": "East Hanover, NJ*",
      "correlation_id": "102867",
      "billing_address": null,
      "start_date": "2023-11-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2239,
        "name": "Eurest Catering",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9d3bbf67-d710-4c24-a180-bf48125adb17"
      },
      "parent_id": null,
      "lat": 40.80651,
      "lon": -74.39196,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db2762dd-2413-4f62-ba60-c64335a3d375",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eurest Catering",
      "_label": "Eurest Catering",
      "_name": "East Hanover, NJ*",
      "_lat": 40.80651,
      "_lng": -74.39196,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -74.39196
    },
    {
      "id": 2771,
      "name": "East Liberty- Wind Tunnel",
      "correlation_id": "101819",
      "billing_address": {
        "id": 1010,
        "street1": "10820 State Route 347",
        "street2": "",
        "city": "East Liberty",
        "region": "Ohio",
        "postal_code": "43319",
        "lat": 40.3255016,
        "lon": -83.5797848,
        "country": "United States"
      },
      "start_date": "2022-04-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "108",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 347,
        "name": "Honda",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 87,
            "street1": "2420 Oak Valley Dr",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48103",
            "lat": 42.2511196,
            "lon": -83.7743094,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bc0f895a-4b0d-4954-9fec-348b76f6ace1"
      },
      "parent_id": null,
      "lat": 40.3255016,
      "lon": -83.5797848,
      "local_timezone": {
        "id": 2248,
        "code": "America/North_Dakota/New_Salem",
        "display_name": "America/North_Dakota/New_Salem",
        "description": "",
        "child_group": null,
        "alt_sequence": 114
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f37b8d67-f3f1-43a2-b68e-0d51f0f5e41a",
      "is_mobile": true,
      "mobile_live_date": "2022-05-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Honda",
      "_label": "Honda",
      "_name": "East Liberty- Wind Tunnel",
      "_lat": 40.3255016,
      "_lng": -83.5797848,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.5797848
    },
    {
      "id": 2088,
      "name": "East Town - MN",
      "correlation_id": "11694",
      "billing_address": {
        "id": 724,
        "street1": "600 South 4th Street",
        "street2": "",
        "city": "Minneapolis",
        "region": "Minnesota",
        "postal_code": "55415",
        "lat": 44.9763688,
        "lon": -93.2606165,
        "country": "United States"
      },
      "start_date": "2014-05-01T23:59:59Z",
      "total_sq_ft": "1027394",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 44.9763975,
      "lon": -93.2633265,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "East Town - MN",
      "_lat": 44.9763975,
      "_lng": -93.2633265,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.2633265
    },
    {
      "id": 1518,
      "name": "Elizabeth, NJ",
      "correlation_id": "10597",
      "billing_address": {
        "id": 518,
        "street1": "520 Division St",
        "street2": "",
        "city": "Elizabeth",
        "region": "New Jersey",
        "postal_code": "07271",
        "lat": 40.6687546,
        "lon": -74.1960956,
        "country": "United States"
      },
      "start_date": "2013-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 141,
        "name": "E&J Gallo Winery",
        "type": "CUSTOMER",
        "correlation_id": "10596",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641a582d-6107-41ef-9ff3-d87668519738"
      },
      "parent_id": null,
      "lat": 40.6685118,
      "lon": -74.1951822,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4692119f-5c59-4ec8-9848-ac141f0a17e2",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "E&J Gallo Winery",
      "_label": "E&J Gallo Winery",
      "_name": "Elizabeth, NJ",
      "_lat": 40.6685118,
      "_lng": -74.1951822,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026016,
          "create_date": "2024-03-07T18:13:38.497316Z",
          "update_date": "2024-03-07T18:13:38.497339Z",
          "organization_id": 141,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/d326f0a5923f4337b2a8492f1dfe73ee_00000328bGallo_t.jpg",
          "size": 74967,
          "description": null,
          "link": null,
          "unique_identifier": "d326f0a5923f4337b2a8492f1dfe73ee"
        }
      ],
      "lng": -74.1951822
    },
    {
      "id": 2608,
      "name": "Ellabell, GA",
      "correlation_id": "101549",
      "billing_address": {
        "id": 718,
        "street1": "1338 Interstate Centro",
        "street2": "",
        "city": "Ellabell",
        "region": "Georgia",
        "postal_code": "31308",
        "lat": 32.1865174,
        "lon": -81.4615965,
        "country": "United States"
      },
      "start_date": "2021-08-16T16:59:22Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 32.19877,
      "lon": -81.47159,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4bd33d9e-e410-4fde-8de1-492858a2075d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Ellabell, GA",
      "_lat": 32.19877,
      "_lng": -81.47159,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -81.47159
    },
    {
      "id": 5639,
      "name": "El Monte (JLL)",
      "correlation_id": "102422",
      "billing_address": {
        "id": 3029,
        "street1": "9000 Flair Drive/3440 East Flair Drive",
        "street2": "",
        "city": "El Monte",
        "region": "California",
        "postal_code": "91731",
        "lat": 34.0712129,
        "lon": -118.0702921,
        "country": "United States"
      },
      "start_date": "2023-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 34.07032,
      "lon": -118.07125,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "38f98250-7014-43d5-8b80-16a98ee2748f",
      "is_mobile": true,
      "mobile_live_date": "2023-05-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "El Monte (JLL)",
      "_lat": 34.07032,
      "_lng": -118.07125,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -118.07125
    },
    {
      "id": 5861,
      "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
      "correlation_id": "102805",
      "billing_address": {
        "id": 3980,
        "street1": "17000 Southfield Fwy, Allen Park, MI 48101",
        "street2": "",
        "city": "Allen Park",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2658233,
        "lon": -83.2187652,
        "country": "United States"
      },
      "start_date": "2021-09-01T23:06:03Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2658233,
      "lon": -83.2187652,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
      "_lat": 42.2658233,
      "_lng": -83.2187652,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2187652
    },
    {
      "id": 2005,
      "name": "Emeryville, CA",
      "correlation_id": "101054",
      "billing_address": {
        "id": 484,
        "street1": "5959 Horton St.",
        "street2": "",
        "city": "Emeryville",
        "region": "California",
        "postal_code": "94608",
        "lat": 37.8411201,
        "lon": -122.2916231,
        "country": "United States"
      },
      "start_date": "2020-02-01T23:59:59Z",
      "total_sq_ft": "61665",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 270,
        "name": "Novartis (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
      },
      "parent_id": null,
      "lat": 37.8362998,
      "lon": -122.2905721,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3383eec2-d76c-4064-8722-ae535cf94417",
      "is_mobile": true,
      "mobile_live_date": "2021-04-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis (CBRE)",
      "_label": "Novartis (CBRE)",
      "_name": "Emeryville, CA",
      "_lat": 37.8362998,
      "_lng": -122.2905721,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859846,
          "create_date": "2020-11-17T14:36:58.595850Z",
          "update_date": "2020-11-17T14:36:58.595883Z",
          "organization_id": 270,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
          "size": 7078,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.2905721
    },
    {
      "id": 4424,
      "name": "Englewood*",
      "correlation_id": "101986",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 39.56421,
      "lon": -104.83219,
      "local_timezone": {
        "id": 2240,
        "code": "America/Montreal",
        "display_name": "America/Montreal",
        "description": "",
        "child_group": null,
        "alt_sequence": 106
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f57ea168-4ac9-405f-833b-e8283222eca4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Englewood*",
      "_lat": 39.56421,
      "_lng": -104.83219,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -104.83219
    },
    {
      "id": 1653,
      "name": "Equifax Metro",
      "correlation_id": "11229",
      "billing_address": {
        "id": 31,
        "street1": "Metropolitana Free Zone, Building 1F",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 9.9981413,
        "lon": -84.1197643,
        "country": "Costa Rica"
      },
      "start_date": "2018-06-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 184,
        "name": "Equifax (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11228",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3c48acd2-7617-4cf7-a67e-c133751ae6e1"
      },
      "parent_id": null,
      "lat": 9.9794384,
      "lon": -84.1466126,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4740876c-848e-4171-af31-648a16f96612",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Equifax (CR)",
      "_label": "Equifax (CR)",
      "_name": "Equifax Metro",
      "_lat": 9.9794384,
      "_lng": -84.1466126,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.1466126
    },
    {
      "id": 4344,
      "name": "Equifax Torre Universal",
      "correlation_id": "102126",
      "billing_address": {
        "id": 2330,
        "street1": "Sabana Sur",
        "street2": "",
        "city": "Costa Rica",
        "region": "California",
        "postal_code": "40104",
        "lat": 36.778261,
        "lon": -119.4179324,
        "country": "United States"
      },
      "start_date": "2018-06-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1780,
        "name": "Equifax Tuns (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "78a0a659-79c6-4f71-a693-076af875615b"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9474c001-75d2-445e-9209-638259f3c5b5",
      "is_mobile": true,
      "mobile_live_date": "2022-08-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Equifax Tuns (CR)",
      "_label": "Equifax Tuns (CR)",
      "_name": "Equifax Torre Universal",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1787,
      "name": "Escazu-Gensler",
      "correlation_id": "11504",
      "billing_address": {
        "id": 33,
        "street1": "Avenida Escazu,  Escazú, Torre Lexus, Piso 7 ,",
        "street2": "",
        "city": "San Rafael",
        "region": "Alabama",
        "postal_code": "10203",
        "lat": 9.9393793,
        "lon": -84.142116,
        "country": "Costa Rica"
      },
      "start_date": "2019-04-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 215,
        "name": "Gensler (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11503",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "81405a92-9557-4482-81de-ed87e2746546"
      },
      "parent_id": null,
      "lat": 9.9383178,
      "lon": -84.142841,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "98c305a1-7f98-4ba5-96f8-b6c9af2816a2",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gensler (CR)",
      "_label": "Gensler (CR)",
      "_name": "Escazu-Gensler",
      "_lat": 9.9383178,
      "_lng": -84.142841,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.142841
    },
    {
      "id": 2137,
      "name": "Establishment Labs",
      "correlation_id": "101228",
      "billing_address": {
        "id": 45,
        "street1": "Coyol Free Zone & Business Park 562 Parkway, Building B15",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2020-07-18T23:59:59Z",
      "total_sq_ft": "40550",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 301,
        "name": "Establishment Labs (CR)",
        "type": "CUSTOMER",
        "correlation_id": "101228",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "05ea7edf-c823-476c-8454-a92356df2beb"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "185988ee-c5e4-4679-bcb1-dcc91fffd131",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Establishment Labs (CR)",
      "_label": "Establishment Labs (CR)",
      "_name": "Establishment Labs",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2969,
      "name": "Etna, OH (Yankee Candle)",
      "correlation_id": "101822",
      "billing_address": {
        "id": 1133,
        "street1": "175 Heritage DR",
        "street2": "",
        "city": "Pataskala",
        "region": "Ohio",
        "postal_code": "43062",
        "lat": 39.9614818,
        "lon": -82.7231123,
        "country": "United States"
      },
      "start_date": "2023-01-03T22:20:22Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 283,
        "name": "Newell",
        "type": "CUSTOMER",
        "correlation_id": "11684",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "39e902e9-411e-4212-ab63-e04b659a6abd"
      },
      "parent_id": null,
      "lat": 39.96156,
      "lon": -82.72229,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0a5b313a-7501-4332-ac41-ffd7c7a84db0",
      "is_mobile": true,
      "mobile_live_date": "2022-12-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Newell",
      "_label": "Newell",
      "_name": "Etna, OH (Yankee Candle)",
      "_lat": 39.96156,
      "_lng": -82.72229,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025405,
          "create_date": "2024-03-07T17:51:44.231135Z",
          "update_date": "2024-03-07T17:51:44.231161Z",
          "organization_id": 283,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/3f97abc0c64e4c58aa0433aa7e5ddfbe_newell_t.png",
          "size": 3241,
          "description": null,
          "link": null,
          "unique_identifier": "3f97abc0c64e4c58aa0433aa7e5ddfbe"
        }
      ],
      "lng": -82.72229
    },
    {
      "id": 2171,
      "name": "Euless, TX - Aviation Materials",
      "correlation_id": "101252",
      "billing_address": {
        "id": 284,
        "street1": "3001 W Airfield Dr.",
        "street2": "",
        "city": "Euless",
        "region": "Texas",
        "postal_code": "76039",
        "lat": 32.8788338,
        "lon": -97.0608672,
        "country": "United States"
      },
      "start_date": "2020-11-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 32.895959,
      "lon": -96.906115,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c07ee2b4-5d48-4f9d-993d-dbbeebcf9e9b",
      "is_mobile": true,
      "mobile_live_date": "2021-03-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Euless, TX - Aviation Materials",
      "_lat": 32.895959,
      "_lng": -96.906115,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -96.906115
    },
    {
      "id": 5863,
      "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
      "correlation_id": "102810",
      "billing_address": {
        "id": 3979,
        "street1": "20800 Oakwood",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.3003911,
        "lon": -83.2316961,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:04:23Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.3007649,
      "lon": -83.232279,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "21922489-dd81-4295-be37-291ba4839fae",
      "is_mobile": true,
      "mobile_live_date": "2024-02-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "EVB – Experimental Vehicle Building – Dearborn, MI",
      "_lat": 42.3007649,
      "_lng": -83.232279,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.232279
    },
    {
      "id": 1981,
      "name": "Evendale, OH - Aviation HQ",
      "correlation_id": "11585",
      "billing_address": {
        "id": 2396,
        "street1": "1 Neumann Way",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.2409068,
        "lon": -84.4491843,
        "country": "United States"
      },
      "start_date": "2019-10-01T07:00:00Z",
      "total_sq_ft": "1383250",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.2464982,
      "lon": -84.446776,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "95ccb035-60ea-49ec-af2c-160cd53af0d9",
      "is_mobile": true,
      "mobile_live_date": "2020-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Evendale, OH - Aviation HQ",
      "_lat": 39.2464982,
      "_lng": -84.446776,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.446776
    },
    {
      "id": 1173,
      "name": "Everett",
      "correlation_id": "10100",
      "billing_address": {
        "id": 412,
        "street1": "271 South 375 West",
        "street2": "",
        "city": "Grace",
        "region": "Idaho",
        "postal_code": "83241",
        "lat": 42.5760314,
        "lon": -111.7305061,
        "country": "United States"
      },
      "start_date": "2014-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "29",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 47.9445669,
      "lon": -122.2784834,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "91b52c2d-d553-4513-b724-0ec26fb80ccd",
      "is_mobile": true,
      "mobile_live_date": "2021-01-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "Everett",
      "_lat": 47.9445669,
      "_lng": -122.2784834,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": -122.2784834
    },
    {
      "id": 2339,
      "name": "Eversana (WorkSpace PM)",
      "correlation_id": "101328",
      "billing_address": {
        "id": 117,
        "street1": "8990 Duke blvd",
        "street2": "",
        "city": "Mason",
        "region": "3207",
        "postal_code": "45040",
        "lat": 39.3075543,
        "lon": -84.3018157,
        "country": "2017"
      },
      "start_date": "2021-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.30755,
      "lon": -84.30174,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ee97648b-6e09-462f-acc0-8ef9301a72f3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Eversana (WorkSpace PM)",
      "_lat": 39.30755,
      "_lng": -84.30174,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.30174
    },
    {
      "id": 4124,
      "name": "Exton",
      "correlation_id": "102089",
      "billing_address": {
        "id": 2099,
        "street1": "730 Stockton Dr.",
        "street2": "",
        "city": "Exton",
        "region": "Pennsylvania",
        "postal_code": "19341",
        "lat": 40.0698213,
        "lon": -75.6900788,
        "country": "United States"
      },
      "start_date": "2022-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 40.07007,
      "lon": -75.687828,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f7086bec-946e-4b69-9337-bc15669afd06",
      "is_mobile": true,
      "mobile_live_date": "2023-05-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Exton",
      "_lat": 40.07007,
      "_lng": -75.687828,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -75.687828
    },
    {
      "id": 2614,
      "name": "Exxon Mobil HQ",
      "correlation_id": "101558",
      "billing_address": null,
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 484,
        "name": "Exxon Mobil (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 730,
            "street1": "5959 Las Colinas Blvd",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75039-2298",
            "lat": 32.889876,
            "lon": -96.9501861,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "88166f7a-664b-414d-ac99-db7dfd054826"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4df09a9a-ec83-4e3a-afb9-1114cccea4f3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Exxon Mobil (CBRE)",
      "_label": "Exxon Mobil (CBRE)",
      "_name": "Exxon Mobil HQ",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5789,
      "name": "FACE00OVRHD",
      "correlation_id": "102715",
      "billing_address": null,
      "start_date": "2023-06-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2148,
        "name": "Meta Overhead",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31013c94-9cea-4e44-8542-b9ef436e7a37"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ca35db7d-e662-4254-b85d-cedecbf14be0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta Overhead",
      "_label": "Meta Overhead",
      "_name": "FACE00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2164,
      "name": "Fairburn, GA (JLL)",
      "correlation_id": "101351",
      "billing_address": null,
      "start_date": "2020-11-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 33.541161,
      "lon": -84.598297,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "06e500fa-49d4-4d7f-9fcd-d7d2ab2ca0ee",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Fairburn, GA (JLL)",
      "_lat": 33.541161,
      "_lng": -84.598297,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -84.598297
    },
    {
      "id": 4819,
      "name": "Fairdale",
      "correlation_id": "102156",
      "billing_address": null,
      "start_date": "2022-12-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 38.11951,
      "lon": -85.74424,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e5fce2e5-69cc-4a8f-b03d-6dd1b0794590",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Fairdale",
      "_lat": 38.11951,
      "_lng": -85.74424,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -85.74424
    },
    {
      "id": 3678,
      "name": "Fairfax, VA*",
      "correlation_id": "101752",
      "billing_address": {
        "id": 3957,
        "street1": "12450 Fair Lakes Circle",
        "street2": "",
        "city": "Fairfax",
        "region": "Virginia",
        "postal_code": null,
        "lat": 38.8601948,
        "lon": -77.3800833,
        "country": "United States"
      },
      "start_date": "2023-12-27T20:06:31Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 38.8601948,
      "lon": -77.3800833,
      "local_timezone": {
        "id": 2241,
        "code": "America/Montserrat",
        "display_name": "America/Montserrat",
        "description": "",
        "child_group": null,
        "alt_sequence": 107
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8b8a60e0-c93e-43d9-808c-6fb494c6da00",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Fairfax, VA*",
      "_lat": 38.8601948,
      "_lng": -77.3800833,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -77.3800833
    },
    {
      "id": 1894,
      "name": "Fairfield",
      "correlation_id": "10753",
      "billing_address": {
        "id": 394,
        "street1": "2302 E Courage Dr",
        "street2": "",
        "city": "Fairfiled",
        "region": "California",
        "postal_code": "94533",
        "lat": 38.2370395,
        "lon": -122.0725145,
        "country": "United States"
      },
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 38.2370469,
      "lon": -122.071882,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bafed81d-69a9-42e9-9f93-67a2e3620590",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Fairfield",
      "_lat": 38.2370469,
      "_lng": -122.071882,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.071882
    },
    {
      "id": 6746,
      "name": "Fairlane Business Park 3",
      "correlation_id": "103413",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29506,
      "lon": -83.19604,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Fairlane Business Park 3",
      "_lat": 42.29506,
      "_lng": -83.19604,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.19604
    },
    {
      "id": 2221,
      "name": "Fairlane Business Park 5",
      "correlation_id": "103409",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29458,
      "lon": -83.20061,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Fairlane Business Park 5",
      "_lat": 42.29458,
      "_lng": -83.20061,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.20061
    },
    {
      "id": 2217,
      "name": "Fairlane North",
      "correlation_id": "103411",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.33287,
      "lon": -83.21037,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Fairlane North",
      "_lat": 42.33287,
      "_lng": -83.21037,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.21037
    },
    {
      "id": 6744,
      "name": "Fairlane Plaza",
      "correlation_id": "103418",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.33287,
      "lon": -83.21037,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "Fairlane Plaza",
      "_lat": 42.33287,
      "_lng": -83.21037,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.21037
    },
    {
      "id": 2972,
      "name": "FCAC00OVRHD",
      "correlation_id": "101971",
      "billing_address": null,
      "start_date": "2022-01-27T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ed56422c-f54e-4168-8963-a12c07d18cfe",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "FCAC00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1380,
      "name": "FCATD - FCA Transport Detroit - Detroit, MI",
      "correlation_id": "101704",
      "billing_address": {
        "id": 904,
        "street1": "8555 Lynch Rd",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48234",
        "lat": 42.4066738,
        "lon": -83.0131417,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.4066738,
      "lon": -83.0131417,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7bf79cda-d331-4bd9-bd62-52dfbe7bdc73",
      "is_mobile": true,
      "mobile_live_date": "2022-07-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "FCATD - FCA Transport Detroit - Detroit, MI",
      "_lat": 42.4066738,
      "_lng": -83.0131417,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.0131417
    },
    {
      "id": 5864,
      "name": "FEL – Ford Engineering Lab – Dearborn, MI",
      "correlation_id": "102785",
      "billing_address": {
        "id": 3978,
        "street1": "21500 Oakwood Blvd.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.3042264,
        "lon": -83.2368731,
        "country": "United States"
      },
      "start_date": "2023-12-27T23:02:32.782000Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.3042264,
      "lon": -83.2368731,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "FEL – Ford Engineering Lab – Dearborn, MI",
      "_lat": 42.3042264,
      "_lng": -83.2368731,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2368731
    },
    {
      "id": 2033,
      "name": "FHCIC",
      "correlation_id": "11609",
      "billing_address": {
        "id": 293,
        "street1": "5348 Vine St",
        "street2": "",
        "city": "Cincinnati",
        "region": "OH",
        "postal_code": "45217",
        "lat": 39.1758304,
        "lon": -84.4961624,
        "country": "US"
      },
      "start_date": "2019-11-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 275,
        "name": "P&G (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "73c20d05-69b7-4adc-b8c0-7f265c556fe3"
      },
      "parent_id": null,
      "lat": 39.159779,
      "lon": -84.530899,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e0d5e6c7-1c9d-4f3f-9d2c-30680dde5d76",
      "is_mobile": true,
      "mobile_live_date": "2021-03-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "P&G (JLL)",
      "_label": "P&G (JLL)",
      "_name": "FHCIC",
      "_lat": 39.159779,
      "_lng": -84.530899,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10676982,
          "create_date": "2024-02-15T19:30:31.713635Z",
          "update_date": "2024-02-15T19:30:31.713660Z",
          "organization_id": 275,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9efe32dff7ab4f479526367623a6a20d_Procter__Gamble_logo_t.png",
          "size": 107371,
          "description": null,
          "link": null,
          "unique_identifier": "9efe32dff7ab4f479526367623a6a20d"
        }
      ],
      "lng": -84.530899
    },
    {
      "id": 6023,
      "name": "FINA00OVRHD",
      "correlation_id": "102855",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "885037a0-a9f4-4992-b462-8fa943f39cc6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "FINA00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 7095,
      "name": "Florence, KY*",
      "correlation_id": "103442",
      "billing_address": null,
      "start_date": "2024-06-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2834,
        "name": "Amazon Motors (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f582fe9d-ef57-41c3-8e68-5370257d3ab9"
      },
      "parent_id": null,
      "lat": 39.01439,
      "lon": -84.64848,
      "local_timezone": {
        "id": 2219,
        "code": "America/Kentucky/Monticello",
        "display_name": "America/Kentucky/Monticello",
        "description": "",
        "child_group": null,
        "alt_sequence": 85
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "08733cfd-0933-4a2c-be6c-7617c0dd43a3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon Motors (CBRE)",
      "_label": "Amazon Motors (CBRE)",
      "_name": "Florence, KY*",
      "_lat": 39.01439,
      "_lng": -84.64848,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.64848
    },
    {
      "id": 2410,
      "name": "Florence, SC",
      "correlation_id": "101353",
      "billing_address": {
        "id": 198,
        "street1": "1500 Otis Way",
        "street2": "",
        "city": "Florence",
        "region": "South Carolina",
        "postal_code": "29501",
        "lat": 34.2415464,
        "lon": -79.7901509,
        "country": "Afghanistan"
      },
      "start_date": "2021-02-23T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 425,
        "name": "Otis Elevator",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 197,
            "street1": "1500 Otis Way",
            "street2": "",
            "city": "Florence",
            "region": "South Carolina",
            "postal_code": "29501",
            "lat": 34.2400154,
            "lon": -79.7900971,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "c5423889-344f-4c26-aa9b-5b4e3454ce9b"
      },
      "parent_id": null,
      "lat": 34.2415464,
      "lon": -79.7901509,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "87ff5ccc-a3e6-4879-823b-85f20bb61492",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Otis Elevator",
      "_label": "Otis Elevator",
      "_name": "Florence, SC",
      "_lat": 34.2415464,
      "_lng": -79.7901509,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -79.7901509
    },
    {
      "id": 2605,
      "name": "Florham Park, NJ",
      "correlation_id": "10701",
      "billing_address": {
        "id": 713,
        "street1": "7-9 Vreeland Rd",
        "street2": "",
        "city": "Florham Park",
        "region": "New Jersey",
        "postal_code": "07923",
        "lat": 40.7925652,
        "lon": -74.3702856,
        "country": "United States"
      },
      "start_date": "2009-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 40.7925652,
      "lon": -74.3702856,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f8fce006-1cf0-4ae9-acc3-71406dd27279",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Florham Park, NJ",
      "_lat": 40.7925652,
      "_lng": -74.3702856,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -74.3702856
    },
    {
      "id": 6698,
      "name": "Fluor - Lebanon*",
      "correlation_id": "103445",
      "billing_address": null,
      "start_date": "2024-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 72,
        "name": "Eli Lilly (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10042",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
      },
      "parent_id": null,
      "lat": 40.10464,
      "lon": -86.5013,
      "local_timezone": {
        "id": 2207,
        "code": "America/Indiana/Petersburg",
        "display_name": "America/Indiana/Petersburg",
        "description": "",
        "child_group": null,
        "alt_sequence": 73
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6c63ba96-6852-4283-80f0-1a75f0cfd8f7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eli Lilly (CBRE)",
      "_label": "Eli Lilly (CBRE)",
      "_name": "Fluor - Lebanon*",
      "_lat": 40.10464,
      "_lng": -86.5013,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853030,
          "create_date": "2020-11-13T23:55:46.918511Z",
          "update_date": "2020-11-13T23:55:46.918539Z",
          "organization_id": 72,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311746.7267878_Artboard8_t.png",
          "size": 413839,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -86.5013
    },
    {
      "id": 1478,
      "name": "Folsom",
      "correlation_id": "101826",
      "billing_address": {
        "id": 2877,
        "street1": "1900 PRAIRIE CITY RD.",
        "street2": "",
        "city": "Folsom",
        "region": "California",
        "postal_code": "95630",
        "lat": 38.6442101,
        "lon": -121.1608452,
        "country": "United States"
      },
      "start_date": "2021-03-12T23:59:59Z",
      "total_sq_ft": "1750706",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 38.6459611,
      "lon": -121.1626234,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d4e1e8a2-3d38-40ae-a7c5-b61ee4dc89b2",
      "is_mobile": true,
      "mobile_live_date": "2020-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Folsom",
      "_lat": 38.6459611,
      "_lng": -121.1626234,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.1626234
    },
    {
      "id": 1900,
      "name": "Folsom",
      "correlation_id": "10764",
      "billing_address": null,
      "start_date": "2021-11-01T00:00:00Z",
      "total_sq_ft": "52000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 493,
        "name": "Micron (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f8922e27-f57a-4029-8bc3-609db025f265"
      },
      "parent_id": null,
      "lat": 38.6441546,
      "lon": -121.1323394,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0047c64b-16f4-402a-8b21-296cbf390fcb",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron (C&W)",
      "_label": "Micron (C&W)",
      "_name": "Folsom",
      "_lat": 38.6441546,
      "_lng": -121.1323394,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.1323394
    },
    {
      "id": 4416,
      "name": "Folsom, CA*",
      "correlation_id": "102045",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1783,
        "name": "SOLIDIGM (JLL)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b1312a0e-4299-4daa-92f1-1c63f8ad0de0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e5c7459a-82d1-4b29-905f-68d7bca2599a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SOLIDIGM (JLL)",
      "_label": "SOLIDIGM (JLL)",
      "_name": "Folsom, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6024,
      "name": "FORD00OVRHD",
      "correlation_id": "102856",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3bb11ce-8d8a-4f4c-98fd-0e049671cf49",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "FORD00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1503,
      "name": "Fort Wayne, IN",
      "correlation_id": "10840",
      "billing_address": {
        "id": 758,
        "street1": "1670 Magnavox Way",
        "street2": "",
        "city": "Fort Wayne",
        "region": "Indiana",
        "postal_code": "46804",
        "lat": 41.0712473,
        "lon": -85.225259,
        "country": "United States"
      },
      "start_date": "2013-09-01T07:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 138,
        "name": "Sturges",
        "type": "CUSTOMER",
        "correlation_id": "10839",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7bff45ab-8163-445a-b251-dcb50cee2061"
      },
      "parent_id": null,
      "lat": 41.0781173,
      "lon": -85.1409441,
      "local_timezone": {
        "id": 2211,
        "code": "America/Indiana/Winamac",
        "display_name": "America/Indiana/Winamac",
        "description": "",
        "child_group": null,
        "alt_sequence": 77
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c0ff8e41-c54c-4d3f-b37d-bb4f97dda63a",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Sturges",
      "_label": "Sturges",
      "_name": "Fort Wayne, IN",
      "_lat": 41.0781173,
      "_lng": -85.1409441,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -85.1409441
    },
    {
      "id": 2620,
      "name": "Fort Wayne, IN*",
      "correlation_id": "102520",
      "billing_address": {
        "id": 734,
        "street1": "1700 Mangavox Way",
        "street2": "",
        "city": "Fort Wayne",
        "region": "Indiana",
        "postal_code": "46804",
        "lat": 41.0704985,
        "lon": -85.2274725,
        "country": "United States"
      },
      "start_date": "2021-09-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 41.07022,
      "lon": -85.22699,
      "local_timezone": {
        "id": 2191,
        "code": "America/Fort_Wayne",
        "display_name": "America/Fort_Wayne",
        "description": "",
        "child_group": null,
        "alt_sequence": 57
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5535cb35-097e-42ba-bf20-24d95d15c2db",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Fort Wayne, IN*",
      "_lat": 41.07022,
      "_lng": -85.22699,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -85.22699
    },
    {
      "id": 2447,
      "name": "Fort Worth",
      "correlation_id": "102165",
      "billing_address": {
        "id": 276,
        "street1": "409 Throckmorton Street",
        "street2": "",
        "city": "Fort Worth",
        "region": "Texas",
        "postal_code": "76102-7410",
        "lat": 32.7542126,
        "lon": -97.3328954,
        "country": "United States"
      },
      "start_date": "2021-03-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f9adad83-7787-4049-82a1-ac9ade026dcf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Fort Worth",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2517,
      "name": "Fort Worth",
      "correlation_id": "101461",
      "billing_address": {
        "id": 354,
        "street1": "4200 Sylvania Ave",
        "street2": "",
        "city": "Fort Worth",
        "region": "Texas",
        "postal_code": "76317",
        "lat": 32.8168502,
        "lon": -97.3072545,
        "country": "United States"
      },
      "start_date": "2021-05-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 457,
        "name": "Trane",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2f426e6f-b444-4cd7-8c6b-0323aa44692b"
      },
      "parent_id": null,
      "lat": 32.8168502,
      "lon": -97.3072545,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "60c63dd6-c784-4ada-bbd5-652305c4ee49",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Trane",
      "_label": "Trane",
      "_name": "Fort Worth",
      "_lat": 32.8168502,
      "_lng": -97.3072545,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -97.3072545
    },
    {
      "id": 1785,
      "name": "Foster City",
      "correlation_id": "11500",
      "billing_address": {
        "id": 375,
        "street1": "300 Lincoln Centre Drive",
        "street2": "",
        "city": "Foster City",
        "region": "California",
        "postal_code": "94404",
        "lat": 37.5697816,
        "lon": -122.2690578,
        "country": "United States"
      },
      "start_date": "2020-10-09T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 179,
        "name": "Illumina",
        "type": "CUSTOMER",
        "correlation_id": "11211",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "069c1097-0984-4c00-9216-9031ac3a85a5"
      },
      "parent_id": null,
      "lat": 37.5585465,
      "lon": -122.2710788,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "215c94bd-2437-4b36-9b92-4595385130d4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Illumina",
      "_label": "Illumina",
      "_name": "Foster City",
      "_lat": 37.5585465,
      "_lng": -122.2710788,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.2710788
    },
    {
      "id": 13,
      "name": "Foster City, CA",
      "correlation_id": "10028",
      "billing_address": {
        "id": 2928,
        "street1": "353 Vintage Park Dr.",
        "street2": "",
        "city": "Foster City",
        "region": "California",
        "postal_code": null,
        "lat": 37.5645593,
        "lon": -122.2775288,
        "country": "United States"
      },
      "start_date": "2013-10-01T23:59:59Z",
      "total_sq_ft": "5424531",
      "total_buildings": "40",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 37.5670242,
      "lon": -122.281678,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f61e4cd5-d6bf-4cf0-ac97-283c7c4a931c",
      "is_mobile": true,
      "mobile_live_date": "2013-09-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Foster City, CA",
      "_lat": 37.5670242,
      "_lng": -122.281678,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.281678
    },
    {
      "id": 1966,
      "name": "Fraser Tower",
      "correlation_id": "11584",
      "billing_address": null,
      "start_date": "2019-01-09T00:00:00Z",
      "total_sq_ft": "144000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 258,
        "name": "Microsoft (JLL)(SG)",
        "type": "CUSTOMER",
        "correlation_id": "11583",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3bd4e4b3-5abf-45c6-b9d9-4c023ac0df1f"
      },
      "parent_id": null,
      "lat": 1.278645,
      "lon": 103.8452253,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a628ebff-8389-4f08-8d5d-241dcbd6e96c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (JLL)(SG)",
      "_label": "Microsoft (JLL)(SG)",
      "_name": "Fraser Tower",
      "_lat": 1.278645,
      "_lng": 103.8452253,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.8452253
    },
    {
      "id": 2560,
      "name": "Fremont",
      "correlation_id": "102166",
      "billing_address": {
        "id": 546,
        "street1": "3161 Stevenson Blvd",
        "street2": "",
        "city": "Fremont",
        "region": "Alabama",
        "postal_code": "94538",
        "lat": 37.5466605,
        "lon": -121.9727204,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2a2b4efd-3c70-4239-8bd6-c003a12e6ccf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Fremont",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5973,
      "name": "Fremont",
      "correlation_id": "102868",
      "billing_address": {
        "id": 4096,
        "street1": "4400 Cushing Pkwy.",
        "street2": "",
        "city": "Fremont",
        "region": "California",
        "postal_code": null,
        "lat": 37.4888418,
        "lon": -121.9542268,
        "country": "United States"
      },
      "start_date": "2024-01-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2240,
        "name": "Lam Research (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1e7b2470-0302-45b1-b34a-667694a5b5eb"
      },
      "parent_id": null,
      "lat": 37.48914,
      "lon": -121.95481,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
      "is_mobile": true,
      "mobile_live_date": "2024-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lam Research (CBRE)",
      "_label": "Lam Research (CBRE)",
      "_name": "Fremont",
      "_lat": 37.48914,
      "_lng": -121.95481,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10767784,
          "create_date": "2024-02-21T19:11:40.419335Z",
          "update_date": "2024-02-21T19:11:40.419360Z",
          "organization_id": 2240,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
          "size": 1275960,
          "description": null,
          "link": null,
          "unique_identifier": "ee68f0369eec4944829b45f1ba8b5011"
        }
      ],
      "lng": -121.95481
    },
    {
      "id": 5522,
      "name": "Fremont Center Ops (JLL)",
      "correlation_id": "102337",
      "billing_address": null,
      "start_date": "2023-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 37.5478289,
      "lon": -121.9840595,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ce645c9b-9112-4fe0-86c9-6040179ba04c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Fremont Center Ops (JLL)",
      "_lat": 37.5478289,
      "_lng": -121.9840595,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -121.9840595
    },
    {
      "id": 1075,
      "name": "Fremont Seattle, WA (JLL)",
      "correlation_id": "10857",
      "billing_address": {
        "id": 710,
        "street1": "1120 112th Ave NE",
        "street2": "",
        "city": "Bellevue",
        "region": "Alabama",
        "postal_code": "98004",
        "lat": 47.6207058,
        "lon": -122.189698,
        "country": "United States"
      },
      "start_date": "2018-04-01T23:59:59Z",
      "total_sq_ft": "181759",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 47.649386,
      "lon": -122.3503929,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "086b6925-3f1b-418c-b005-9b80d4a53d3d",
      "is_mobile": true,
      "mobile_live_date": "2021-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Fremont Seattle, WA (JLL)",
      "_lat": 47.649386,
      "_lng": -122.3503929,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.3503929
    },
    {
      "id": 5653,
      "name": "Fresno Cash Vault (JLL)",
      "correlation_id": "102333",
      "billing_address": {
        "id": 3048,
        "street1": "3556 E Ventura Ave",
        "street2": "",
        "city": "Fresno",
        "region": "California",
        "postal_code": null,
        "lat": 36.7356676,
        "lon": -119.7646411,
        "country": "United States"
      },
      "start_date": "2023-03-09T23:52:47Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 36.7356676,
      "lon": -119.7646411,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "048ad58a-d924-466b-a205-575cc2ec91ae",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Fresno Cash Vault (JLL)",
      "_lat": 36.7356676,
      "_lng": -119.7646411,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -119.7646411
    },
    {
      "id": 6700,
      "name": "Frisco, CO*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-01-05T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 33.15345,
      "lon": -96.89054,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8011157d-4c68-4d02-abad-4cce34edc014",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Frisco, CO*",
      "_lat": 33.15345,
      "_lng": -96.89054,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.89054
    },
    {
      "id": 1479,
      "name": "Ft Collins",
      "correlation_id": "101838",
      "billing_address": {
        "id": 104,
        "street1": "4701 Technology Pkwy",
        "street2": "",
        "city": "Fort Collins",
        "region": "Colorado",
        "postal_code": "80525",
        "lat": 40.5220916,
        "lon": -105.0178049,
        "country": "United States"
      },
      "start_date": "2021-03-12T00:00:00Z",
      "total_sq_ft": "203391",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 40.5220916,
      "lon": -105.0178049,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "66735ee8-7869-4420-a269-522a813879aa",
      "is_mobile": true,
      "mobile_live_date": "2020-10-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ft Collins",
      "_lat": 40.5220916,
      "_lng": -105.0178049,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -105.0178049
    },
    {
      "id": 2542,
      "name": "Ft. Collins",
      "correlation_id": "102164",
      "billing_address": null,
      "start_date": "2021-06-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 40.5250959,
      "lon": -105.0273385,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4fadff3b-5842-4377-9af5-d7f47bf6e914",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Ft. Collins",
      "_lat": 40.5250959,
      "_lng": -105.0273385,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -105.0273385
    },
    {
      "id": 5788,
      "name": "Ft. Collins, CO*",
      "correlation_id": "102713",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 40.52349,
      "lon": -105.01571,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a45aecfe-a31e-4b36-9fc9-a55cc7d6dcb9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Ft. Collins, CO*",
      "_lat": 40.52349,
      "_lng": -105.01571,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -105.01571
    },
    {
      "id": 2094,
      "name": "Ft Wayne, IN",
      "correlation_id": "11701",
      "billing_address": {
        "id": 759,
        "street1": "1670 Magnavox Way",
        "street2": "",
        "city": "Fort Wayne",
        "region": "Indiana",
        "postal_code": "46804",
        "lat": 41.0712473,
        "lon": -85.225259,
        "country": "United States"
      },
      "start_date": "2009-01-14T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 288,
        "name": "Swiss Re",
        "type": "CUSTOMER",
        "correlation_id": "11700",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d4d8a05a-46bd-4aca-bd0f-7c17c3e18dc6"
      },
      "parent_id": null,
      "lat": 41.093842,
      "lon": -85.139236,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b46c7aad-7a5b-40b9-af2c-6be4d2c07386",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Swiss Re",
      "_label": "Swiss Re",
      "_name": "Ft Wayne, IN",
      "_lat": 41.093842,
      "_lng": -85.139236,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -85.139236
    },
    {
      "id": 5794,
      "name": "Fulton County Airport Hangar",
      "correlation_id": "102721",
      "billing_address": null,
      "start_date": "2023-07-01T22:32:05Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
      },
      "parent_id": null,
      "lat": 33.7668267,
      "lon": -84.5263004,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8baabbd9-e6e5-413f-abbb-be0d5d268562",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Coca Cola",
      "_label": "Coca Cola",
      "_name": "Fulton County Airport Hangar",
      "_lat": 33.7668267,
      "_lng": -84.5263004,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10560545,
          "create_date": "2024-02-08T17:24:10.318130Z",
          "update_date": "2024-02-08T17:24:10.318155Z",
          "organization_id": 2145,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
          "size": 22904,
          "description": null,
          "link": null,
          "unique_identifier": "9182f361e2ff41d3bded657bde583842"
        }
      ],
      "lng": -84.5263004
    },
    {
      "id": 2215,
      "name": "FXC – Ford Experience Center - Dearborn, MI",
      "correlation_id": "101286",
      "billing_address": {
        "id": 206,
        "street1": "20000 Rotunda Dr.  Dearborn, MI 48124",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": "48124",
        "lat": 42.2941466,
        "lon": -83.2271782,
        "country": "United States"
      },
      "start_date": "2021-09-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": 5847,
      "lat": 42.322262,
      "lon": -83.176315,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
      "is_mobile": true,
      "mobile_live_date": "2021-03-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "FXC – Ford Experience Center - Dearborn, MI",
      "_lat": 42.322262,
      "_lng": -83.176315,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.176315
    },
    {
      "id": 2611,
      "name": "Gahanna",
      "correlation_id": "102231",
      "billing_address": {
        "id": 721,
        "street1": "915 Taylor Rd",
        "street2": "",
        "city": "Columbus",
        "region": "Ohio",
        "postal_code": "43230",
        "lat": 40.0011604,
        "lon": -82.8504071,
        "country": "United States"
      },
      "start_date": "2021-09-15T17:23:04Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 40.00266,
      "lon": -82.85011,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "430bc9e7-1d5f-44b5-9ad7-9b7d10a354f9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Gahanna",
      "_lat": 40.00266,
      "_lng": -82.85011,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.85011
    },
    {
      "id": 2467,
      "name": "Gallatin, TN",
      "correlation_id": "101427",
      "billing_address": {
        "id": 305,
        "street1": "179 Hancock St Ste 304",
        "street2": "",
        "city": "Gallatin",
        "region": "Tennessee",
        "postal_code": "37066",
        "lat": 36.3796429,
        "lon": -86.4557318,
        "country": "United States"
      },
      "start_date": "2019-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 36.379,
      "lon": -86.455,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ae7d3ed1-544e-4b11-b40f-ba2a6e458a13",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Gallatin, TN",
      "_lat": 36.379,
      "_lng": -86.455,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -86.455
    },
    {
      "id": 6898,
      "name": "Gallo Wineries - G3CL",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-12T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 141,
        "name": "E&J Gallo Winery",
        "type": "CUSTOMER",
        "correlation_id": "10596",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641a582d-6107-41ef-9ff3-d87668519738"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fa1e2eb4-1aac-4871-bfff-1a9b5864d403",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "E&J Gallo Winery",
      "_label": "E&J Gallo Winery",
      "_name": "Gallo Wineries - G3CL",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026016,
          "create_date": "2024-03-07T18:13:38.497316Z",
          "update_date": "2024-03-07T18:13:38.497339Z",
          "organization_id": 141,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/d326f0a5923f4337b2a8492f1dfe73ee_00000328bGallo_t.jpg",
          "size": 74967,
          "description": null,
          "link": null,
          "unique_identifier": "d326f0a5923f4337b2a8492f1dfe73ee"
        }
      ],
      "lng": 0
    },
    {
      "id": 1496,
      "name": "Garland, TX (JLL)",
      "correlation_id": "10830",
      "billing_address": {
        "id": 2915,
        "street1": "3635 S Shiloh Rd",
        "street2": "",
        "city": "Garland",
        "region": "Texas",
        "postal_code": null,
        "lat": 32.8718607,
        "lon": -96.6659272,
        "country": "United States"
      },
      "start_date": "2013-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
      },
      "parent_id": null,
      "lat": 32.871995,
      "lon": -96.6661533,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5bf602ce-cc82-4648-a7be-af211a80ea49",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GM",
      "_label": "GM",
      "_name": "Garland, TX (JLL)",
      "_lat": 32.871995,
      "_lng": -96.6661533,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853049,
          "create_date": "2020-11-14T00:00:06.049828Z",
          "update_date": "2020-11-14T00:00:06.049855Z",
          "organization_id": 137,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
          "size": 1080345,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -96.6661533
    },
    {
      "id": 4491,
      "name": "Gately 4244 S Market*",
      "correlation_id": "101749",
      "billing_address": {
        "id": 2495,
        "street1": "4244 S. Market Ct",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "95834",
        "lat": 38.6464578,
        "lon": -121.4808251,
        "country": "United States"
      },
      "start_date": "2022-08-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1813,
        "name": "Gately Enterprise LLC",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "57821663-9faf-4a13-9151-7085a13e6c1d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7fba159f-8d92-4c98-aa49-65f9554b9de7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gately Enterprise LLC",
      "_label": "Gately Enterprise LLC",
      "_name": "Gately 4244 S Market*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3997,
      "name": "Gateway",
      "correlation_id": "102104",
      "billing_address": {
        "id": 1972,
        "street1": "100 Independence Dr.",
        "street2": "",
        "city": "Menlo Park",
        "region": "California",
        "postal_code": "94025",
        "lat": 37.4834951,
        "lon": -122.1787815,
        "country": "United States"
      },
      "start_date": "2022-07-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 37.48358,
      "lon": -122.17897,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "517ee32c-80d0-4e44-8ce1-f0e0432c248e",
      "is_mobile": true,
      "mobile_live_date": "2022-11-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Gateway",
      "_lat": 37.48358,
      "_lng": -122.17897,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": -122.17897
    },
    {
      "id": 1663,
      "name": "Gateway Oaks, CA*",
      "correlation_id": "11255",
      "billing_address": {
        "id": 2497,
        "street1": "2804 Gateway Oaks Drive",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": null,
        "lat": 38.6194292,
        "lon": -121.5174258,
        "country": "United States"
      },
      "start_date": "2022-08-18T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 191,
        "name": "B&Z Properties",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11253",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "edc6b228-941a-409f-b8a6-efff8460896e"
      },
      "parent_id": null,
      "lat": 38.6175345,
      "lon": -121.5180503,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c05dfa3e-833e-4354-95c1-a64565c906fd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "B&Z Properties",
      "_label": "B&Z Properties",
      "_name": "Gateway Oaks, CA*",
      "_lat": 38.6175345,
      "_lng": -121.5180503,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.5180503
    },
    {
      "id": 5885,
      "name": "GE Additive West Chester Tennant*",
      "correlation_id": "102769",
      "billing_address": null,
      "start_date": "2023-10-04T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2167,
        "name": "Hyliion",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e772d6c4-7109-4dac-b051-f2fad9c49d3e"
      },
      "parent_id": null,
      "lat": 39.34509,
      "lon": -84.46568,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6a66d179-7849-4f8f-9247-563a5ebee41c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hyliion",
      "_label": "Hyliion",
      "_name": "GE Additive West Chester Tennant*",
      "_lat": 39.34509,
      "_lng": -84.46568,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.46568
    },
    {
      "id": 2263,
      "name": "Georgetown, TX",
      "correlation_id": "102167",
      "billing_address": null,
      "start_date": "2020-11-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 30.633263,
      "lon": -97.677986,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "79666676-e065-4e53-bc71-88faa5d812e9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Georgetown, TX",
      "_lat": 30.633263,
      "_lng": -97.677986,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -97.677986
    },
    {
      "id": 2489,
      "name": "GE OVRHD",
      "correlation_id": "101436",
      "billing_address": {
        "id": 338,
        "street1": "7500 State Rd.",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45255",
        "lat": 39.0856971,
        "lon": -84.348906,
        "country": "United States"
      },
      "start_date": "2021-05-05T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 450,
        "name": "General Electric OVRHD",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 337,
            "street1": "7500 State Rd.",
            "street2": "",
            "city": "Cincinnati",
            "region": "OH",
            "postal_code": "45255",
            "lat": 39.0856971,
            "lon": -84.348906,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "d9577f95-dabc-4339-9843-6bdcb48ad5f3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7daf911b-017c-4593-a5f6-87608f67ab0f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Electric OVRHD",
      "_label": "General Electric OVRHD",
      "_name": "GE OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1681,
      "name": "Gilbert, AZ",
      "correlation_id": "11306",
      "billing_address": {
        "id": 311,
        "street1": "1721 W Elliot Road",
        "street2": "",
        "city": "Gilbert",
        "region": "Arizona",
        "postal_code": "85233",
        "lat": 33.3476194,
        "lon": -111.829098,
        "country": "United States"
      },
      "start_date": "2018-02-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 198,
        "name": "Northrop Grumman",
        "type": "CUSTOMER",
        "correlation_id": "10741",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "74b494e2-e85e-4b0b-ac91-a75cb6885315"
      },
      "parent_id": null,
      "lat": 33.3528264,
      "lon": -111.789027,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ba73aaa-3ca3-4bb7-af53-d0d7562e3c1c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Northrop Grumman",
      "_label": "Northrop Grumman",
      "_name": "Gilbert, AZ",
      "_lat": 33.3528264,
      "_lng": -111.789027,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025980,
          "create_date": "2024-03-07T18:11:59.709378Z",
          "update_date": "2024-03-07T18:11:59.709398Z",
          "organization_id": 198,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/918fbc1ec9034a4db93c66855743b29b_NorthropGrummanLogo500x313_t.png",
          "size": 36836,
          "description": null,
          "link": null,
          "unique_identifier": "918fbc1ec9034a4db93c66855743b29b"
        }
      ],
      "lng": -111.789027
    },
    {
      "id": 3141,
      "name": "GILE00OVHD",
      "correlation_id": "102026",
      "billing_address": null,
      "start_date": "2022-03-07T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "656ad8fc-930f-481d-b360-0d9fefba30f7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "GILE00OVHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1498,
      "name": "Glendale* (JLL)",
      "correlation_id": "10832",
      "billing_address": null,
      "start_date": "2021-06-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
      },
      "parent_id": null,
      "lat": 34.137184,
      "lon": -118.263946,
      "local_timezone": {
        "id": 2217,
        "code": "America/Juneau",
        "display_name": "America/Juneau",
        "description": "",
        "child_group": null,
        "alt_sequence": 83
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8372ade6-e68d-4312-81b6-856bd5948ab4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GM",
      "_label": "GM",
      "_name": "Glendale* (JLL)",
      "_lat": 34.137184,
      "_lng": -118.263946,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853049,
          "create_date": "2020-11-14T00:00:06.049828Z",
          "update_date": "2020-11-14T00:00:06.049855Z",
          "organization_id": 137,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
          "size": 1080345,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -118.263946
    },
    {
      "id": 1423,
      "name": "Global",
      "correlation_id": "10498",
      "billing_address": {
        "id": 28,
        "street1": "Global Park Free Zone, 302 PARKWAY GLOBAL",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 10.473523,
        "lon": -84.0167423,
        "country": "Costa Rica"
      },
      "start_date": "2015-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 104,
        "name": "Boston Scientific (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10436",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4247ddd5-edc0-4232-ab9b-9cacd7459d46"
      },
      "parent_id": null,
      "lat": 9.9892995,
      "lon": -84.1541684,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17cf4b63-4d3c-46a7-a26e-53a3ebf377eb",
      "is_mobile": true,
      "mobile_live_date": "2021-11-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boston Scientific (CR)",
      "_label": "Boston Scientific (CR)",
      "_name": "Global",
      "_lat": 9.9892995,
      "_lng": -84.1541684,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.1541684
    },
    {
      "id": 2032,
      "name": "GO",
      "correlation_id": "11606",
      "billing_address": {
        "id": 472,
        "street1": "2 P&G Plaza",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45202",
        "lat": 39.1031182,
        "lon": -84.5120196,
        "country": "United States"
      },
      "start_date": "2019-11-09T00:00:00Z",
      "total_sq_ft": "2228363",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 275,
        "name": "P&G (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "73c20d05-69b7-4adc-b8c0-7f265c556fe3"
      },
      "parent_id": null,
      "lat": 39.104394,
      "lon": -84.5086982,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3cf4acce-7346-4125-9d37-598f2d4f06d3",
      "is_mobile": true,
      "mobile_live_date": "2020-03-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "P&G (JLL)",
      "_label": "P&G (JLL)",
      "_name": "GO",
      "_lat": 39.104394,
      "_lng": -84.5086982,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10676982,
          "create_date": "2024-02-15T19:30:31.713635Z",
          "update_date": "2024-02-15T19:30:31.713660Z",
          "organization_id": 275,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9efe32dff7ab4f479526367623a6a20d_Procter__Gamble_logo_t.png",
          "size": 107371,
          "description": null,
          "link": null,
          "unique_identifier": "9efe32dff7ab4f479526367623a6a20d"
        }
      ],
      "lng": -84.5086982
    },
    {
      "id": 1662,
      "name": "Gold Center*",
      "correlation_id": "11254",
      "billing_address": {
        "id": 2500,
        "street1": "10995 Gold Center Dr",
        "street2": "",
        "city": "Rancho Cordova",
        "region": "California",
        "postal_code": null,
        "lat": 38.5934266,
        "lon": -121.277747,
        "country": "United States"
      },
      "start_date": "2022-08-18T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 191,
        "name": "B&Z Properties",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11253",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "edc6b228-941a-409f-b8a6-efff8460896e"
      },
      "parent_id": null,
      "lat": 38.5934128,
      "lon": -121.2777401,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2cbe348b-bd07-48be-a12a-f6b50f8a3b26",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "B&Z Properties",
      "_label": "B&Z Properties",
      "_name": "Gold Center*",
      "_lat": 38.5934128,
      "_lng": -121.2777401,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.2777401
    },
    {
      "id": 1725,
      "name": "GOOG00OVHD",
      "correlation_id": "11355",
      "billing_address": {
        "id": 637,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "94e9b51e-57ce-4c37-9e35-90f9792b0557",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "GOOG00OVHD",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 3510,
      "name": "Google SLU",
      "correlation_id": "102055",
      "billing_address": {
        "id": 4100,
        "street1": "620 Boren Ave N.",
        "street2": "",
        "city": "Seattle",
        "region": "Washington",
        "postal_code": null,
        "lat": 47.6252018,
        "lon": -122.3356285,
        "country": "United States"
      },
      "start_date": "2019-08-01T00:00:00Z",
      "total_sq_ft": "667723",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0f30a8eb-ad49-46cf-98ce-3911c8c2ce1f",
      "is_mobile": true,
      "mobile_live_date": "2020-02-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Google SLU",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 5893,
      "name": "GQ2A*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-10-16T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 34.43204,
      "lon": -119.8114,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1aae8b54-2fe0-4d14-ae79-3fb606e484f3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "GQ2A*",
      "_lat": 34.43204,
      "_lng": -119.8114,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.8114
    },
    {
      "id": 1609,
      "name": "Gracy Farms",
      "correlation_id": "102136",
      "billing_address": {
        "id": 420,
        "street1": "2309 Gracy Farms LN",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": "78758",
        "lat": 30.3996706,
        "lon": -97.7117411,
        "country": "United States"
      },
      "start_date": "2018-03-30T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 30.3991328,
      "lon": -97.7124291,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "03cc456f-3e28-40d0-aebf-3074cbdb38c9",
      "is_mobile": true,
      "mobile_live_date": "2021-05-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Gracy Farms",
      "_lat": 30.3991328,
      "_lng": -97.7124291,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -97.7124291
    },
    {
      "id": 5674,
      "name": "Grand Rapids, MI",
      "correlation_id": "102545",
      "billing_address": {
        "id": 3580,
        "street1": "2100 Burlingame Ave. SW",
        "street2": "",
        "city": "Wyoming",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.9226603,
        "lon": -85.7031869,
        "country": "United States"
      },
      "start_date": "2023-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
      },
      "parent_id": null,
      "lat": 42.92355,
      "lon": -85.70565,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ef15f02-8bc5-41d2-84d1-e1e7bf1d0537",
      "is_mobile": true,
      "mobile_live_date": "2023-04-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GM",
      "_label": "GM",
      "_name": "Grand Rapids, MI",
      "_lat": 42.92355,
      "_lng": -85.70565,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853049,
          "create_date": "2020-11-14T00:00:06.049828Z",
          "update_date": "2020-11-14T00:00:06.049855Z",
          "organization_id": 137,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
          "size": 1080345,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -85.70565
    },
    {
      "id": 1912,
      "name": "Great American Ballpark",
      "correlation_id": "10783",
      "billing_address": {
        "id": 3022,
        "street1": "100 Joe Nuxhall Way (Dock 3)",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.0974395,
        "lon": -84.5086993,
        "country": "United States"
      },
      "start_date": "2012-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 249,
        "name": "Cincinnati Reds",
        "type": "CUSTOMER",
        "correlation_id": "10782",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c95523ae-a439-4256-b4ca-7e88a7306354"
      },
      "parent_id": null,
      "lat": 39.0972431,
      "lon": -84.5066258,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "71812fba-2ea0-4e8f-96d3-231a791aeaf0",
      "is_mobile": true,
      "mobile_live_date": "2023-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cincinnati Reds",
      "_label": "Cincinnati Reds",
      "_name": "Great American Ballpark",
      "_lat": 39.0972431,
      "_lng": -84.5066258,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.5066258
    },
    {
      "id": 3662,
      "name": "Greeley, CO*",
      "correlation_id": "101588",
      "billing_address": {
        "id": 3584,
        "street1": "1555 Promontory Cir",
        "street2": "",
        "city": "Greeley",
        "region": "Colorado",
        "postal_code": null,
        "lat": 40.4145722,
        "lon": -104.8573251,
        "country": "United States"
      },
      "start_date": "2023-05-02T19:26:39Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2734,
        "name": "State Farm (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "32aff8f6-eb4c-4b7e-862c-0ba02ba9d1e1"
      },
      "parent_id": null,
      "lat": 40.41488,
      "lon": -104.85719,
      "local_timezone": {
        "id": 2229,
        "code": "America/Martinique",
        "display_name": "America/Martinique",
        "description": "",
        "child_group": null,
        "alt_sequence": 95
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "645adbe3-eab2-45e9-8781-9f6cb558cd66",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE) - Sub",
      "_label": "State Farm (CBRE) - Sub",
      "_name": "Greeley, CO*",
      "_lat": 40.41488,
      "_lng": -104.85719,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -104.85719
    },
    {
      "id": 7096,
      "name": "Greencastle, PA",
      "correlation_id": "103441",
      "billing_address": null,
      "start_date": "2024-03-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2834,
        "name": "Amazon Motors (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f582fe9d-ef57-41c3-8e68-5370257d3ab9"
      },
      "parent_id": null,
      "lat": 39.76759,
      "lon": -77.73357,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "983274dc-e62a-4412-8e9f-899009be6c6e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon Motors (CBRE)",
      "_label": "Amazon Motors (CBRE)",
      "_name": "Greencastle, PA",
      "_lat": 39.76759,
      "_lng": -77.73357,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.73357
    },
    {
      "id": 2017,
      "name": "Greenfield, IN",
      "correlation_id": "11590",
      "billing_address": {
        "id": 432,
        "street1": "2500 Innovation Way",
        "street2": "",
        "city": "Greenfield",
        "region": "Indiana",
        "postal_code": "46140",
        "lat": 39.8218481,
        "lon": -85.7748672,
        "country": "United States"
      },
      "start_date": "2012-10-01T23:59:59Z",
      "total_sq_ft": "113600",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 273,
        "name": "Elanco (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11589",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
      },
      "parent_id": null,
      "lat": 39.8223751,
      "lon": -85.7751072,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
      "is_mobile": true,
      "mobile_live_date": "2020-08-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elanco (CBRE)",
      "_label": "Elanco (CBRE)",
      "_name": "Greenfield, IN",
      "_lat": 39.8223751,
      "_lng": -85.7751072,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918792,
          "create_date": "2020-12-07T22:03:11.720761Z",
          "update_date": "2020-12-07T22:03:11.720786Z",
          "organization_id": 273,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607378591.6016922_Elanco_t.png",
          "size": 6049,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -85.7751072
    },
    {
      "id": 1867,
      "name": "Greensboro",
      "correlation_id": "10702",
      "billing_address": {
        "id": 448,
        "street1": "3801 Boren Drive",
        "street2": "",
        "city": "Greensboro",
        "region": "North Carolina",
        "postal_code": "27407",
        "lat": 36.0530175,
        "lon": -79.8753586,
        "country": "United States"
      },
      "start_date": "2010-01-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 36.05358,
      "lon": -79.8742669,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0f08a43d-f9cd-4547-95cf-91d61853ef2f",
      "is_mobile": true,
      "mobile_live_date": "2021-05-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Greensboro",
      "_lat": 36.05358,
      "_lng": -79.8742669,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -79.8742669
    },
    {
      "id": 2438,
      "name": "Greenville",
      "correlation_id": "102168",
      "billing_address": {
        "id": 260,
        "street1": "3110 1-30 Frontage Rd, Suite 104",
        "street2": "",
        "city": "Greenville",
        "region": "Texas",
        "postal_code": "75402-7216",
        "lat": 33.113293,
        "lon": -96.101853,
        "country": "United States"
      },
      "start_date": "2021-03-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "841a66ec-eedd-436f-96b9-315f9f6e7fe6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Greenville",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3127,
      "name": "Greenville SC",
      "correlation_id": "102020",
      "billing_address": {
        "id": 2708,
        "street1": "300 Garlington Rd.",
        "street2": "",
        "city": "Greenville",
        "region": "South Carolina",
        "postal_code": null,
        "lat": 34.8324997,
        "lon": -82.2860882,
        "country": "United States"
      },
      "start_date": "2022-02-10T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 336,
        "name": "GE Power (Amentum)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "96b8cb78-cf37-42cb-8c13-09918c7bcce3"
      },
      "parent_id": null,
      "lat": 34.83118,
      "lon": -82.28684,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1eb8361d-bd1f-43c2-ac9c-3ee1a545851d",
      "is_mobile": true,
      "mobile_live_date": "2022-10-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Power (Amentum)",
      "_label": "GE Power (Amentum)",
      "_name": "Greenville SC",
      "_lat": 34.83118,
      "_lng": -82.28684,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686659,
          "create_date": "2024-02-16T12:20:18.750899Z",
          "update_date": "2024-02-16T12:20:18.750921Z",
          "organization_id": 336,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/27f74b1685fc4dd0aa65c81a773610ff_Screenshot20240216at07.19_t.19",
          "size": 97486,
          "description": null,
          "link": null,
          "unique_identifier": "27f74b1685fc4dd0aa65c81a773610ff"
        }
      ],
      "lng": -82.28684
    },
    {
      "id": 1959,
      "name": "Greenwood",
      "correlation_id": "11436",
      "billing_address": {
        "id": 737,
        "street1": "525 NORTH EMERALD ROAD",
        "street2": "",
        "city": "Greenwood",
        "region": "Alabama",
        "postal_code": "29646",
        "lat": 32.3182314,
        "lon": -86.902298,
        "country": "United States"
      },
      "start_date": "2018-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 212,
        "name": "Cardinal Health",
        "type": "CUSTOMER",
        "correlation_id": "11433",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0fc024f8-c4f1-4dd9-bb50-24a9737a5d6c"
      },
      "parent_id": null,
      "lat": 33.4734978,
      "lon": -82.0105148,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bb6f90de-6545-42d5-9d0c-07a3a3d3bad4",
      "is_mobile": true,
      "mobile_live_date": "2021-09-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health",
      "_label": "Cardinal Health",
      "_name": "Greenwood",
      "_lat": 33.4734978,
      "_lng": -82.0105148,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -82.0105148
    },
    {
      "id": 5633,
      "name": "Grove City",
      "correlation_id": "102418",
      "billing_address": null,
      "start_date": "2022-12-28T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 39.8347526,
      "lon": -83.0925096,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "26203c87-9f1b-4a69-ba0d-73679f67e49b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Grove City",
      "_lat": 39.8347526,
      "_lng": -83.0925096,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.0925096
    },
    {
      "id": 2616,
      "name": "Groveport",
      "correlation_id": "102232",
      "billing_address": {
        "id": 731,
        "street1": "6500 Adelaide ct",
        "street2": "",
        "city": "Groveport",
        "region": "Ohio",
        "postal_code": "43125",
        "lat": 39.8333433,
        "lon": -82.9357025,
        "country": "United States"
      },
      "start_date": "2021-09-16T17:27:57Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 39.837401,
      "lon": -82.936481,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3bb6dd38-6fa1-4f73-8565-8051e79ab1be",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Groveport",
      "_lat": 39.837401,
      "_lng": -82.936481,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.936481
    },
    {
      "id": 3498,
      "name": "GSE Kennesaw*",
      "correlation_id": "101008",
      "billing_address": null,
      "start_date": "2024-01-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b9f0d845-8247-40f4-a170-23a2d07ddb04",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "GSE Kennesaw*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": 0
    },
    {
      "id": 5865,
      "name": "GTL – Gas Turbine Labs – Dearborn, MI",
      "correlation_id": "102799",
      "billing_address": {
        "id": 3977,
        "street1": "1751 Village Rd, Dearborn, MI 48124",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.3002124,
        "lon": -83.233564,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:01:20Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.3002124,
      "lon": -83.233564,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "GTL – Gas Turbine Labs – Dearborn, MI",
      "_lat": 42.3002124,
      "_lng": -83.233564,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.233564
    },
    {
      "id": 1256,
      "name": "Guaynabo",
      "correlation_id": "10217",
      "billing_address": {
        "id": 190,
        "street1": "6 Calle Tabonuco Guaynabo, PR 00968",
        "street2": "",
        "city": "Guaynabo",
        "region": "Florida",
        "postal_code": "00968",
        "lat": 18.4053034,
        "lon": -66.1062297,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "23200",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 18.4060255,
      "lon": -66.106101,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f63a0ec9-4e84-452b-b6ec-b7823a702406",
      "is_mobile": true,
      "mobile_live_date": "2021-02-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Guaynabo",
      "_lat": 18.4060255,
      "_lng": -66.106101,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -66.106101
    },
    {
      "id": 5916,
      "name": "Guaynabo, PR",
      "correlation_id": "102823",
      "billing_address": null,
      "start_date": "2024-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.37226,
      "lon": -66.1126,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "369568c5-1ab4-48ed-a82d-94ed05866dcb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Guaynabo, PR",
      "_lat": 18.37226,
      "_lng": -66.1126,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -66.1126
    },
    {
      "id": 1650,
      "name": "Gulch (JLL)",
      "correlation_id": "11224",
      "billing_address": {
        "id": 1977,
        "street1": "1101 Church St.",
        "street2": "",
        "city": "Nashville",
        "region": "Tennessee",
        "postal_code": null,
        "lat": 36.1594949,
        "lon": -86.7879874,
        "country": "United States"
      },
      "start_date": "2017-03-31T07:00:00Z",
      "total_sq_ft": "958576",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 31,
        "name": "Asurion (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10869",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e2d5b9f5-fa89-4637-8c85-b9f234996fb0"
      },
      "parent_id": null,
      "lat": 36.1626638,
      "lon": -86.7816016,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fcf33610-10d8-4789-ad1c-206f0083fb90",
      "is_mobile": true,
      "mobile_live_date": "2022-02-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Asurion (JLL)",
      "_label": "Asurion (JLL)",
      "_name": "Gulch (JLL)",
      "_lat": 36.1626638,
      "_lng": -86.7816016,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.7816016
    },
    {
      "id": 5911,
      "name": "Gurabo, PR*",
      "correlation_id": "102818",
      "billing_address": null,
      "start_date": "2024-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.2451879,
      "lon": -65.9516496,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dc83f65c-0e48-4116-ac7a-c693fb068368",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Gurabo, PR*",
      "_lat": 18.2451879,
      "_lng": -65.9516496,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -65.9516496
    },
    {
      "id": 2158,
      "name": "Gurnee",
      "correlation_id": "101305",
      "billing_address": {
        "id": 397,
        "street1": "605 Tri State Parkway",
        "street2": "",
        "city": "Gurnee",
        "region": "Illinois",
        "postal_code": "60031",
        "lat": 42.3692036,
        "lon": -87.9452914,
        "country": "United States"
      },
      "start_date": "2020-08-26T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 42.3691,
      "lon": -87.9452,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c06085ce-d16b-4d20-ba61-67796f8c7f60",
      "is_mobile": true,
      "mobile_live_date": "2021-04-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Gurnee",
      "_lat": 42.3691,
      "_lng": -87.9452,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.9452
    },
    {
      "id": 6676,
      "name": "GYR1 – Goodyear, AZ (Pending Go-Live)",
      "correlation_id": "103385",
      "billing_address": {
        "id": 4086,
        "street1": "580 S Bullard Ave",
        "street2": "",
        "city": "Goodyear",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.4404508,
        "lon": -112.3725475,
        "country": "United States"
      },
      "start_date": "2024-03-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 33.441039,
      "lon": -112.375245,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8103ce41-9cca-4441-8b2b-fb93e32e7256",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "GYR1 – Goodyear, AZ (Pending Go-Live)",
      "_lat": 33.441039,
      "_lng": -112.375245,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -112.375245
    },
    {
      "id": 6681,
      "name": "GYR2 - Goodyear, AZ (Pending Go-Live)",
      "correlation_id": "103390",
      "billing_address": {
        "id": 4091,
        "street1": "17341 W Minnesota Av",
        "street2": "",
        "city": "Goodyear",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.4353394,
        "lon": -112.3576567,
        "country": "United States"
      },
      "start_date": "2024-04-02T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 33.495777,
      "lon": -112.430906,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "da3e25bd-2f1f-4e93-83eb-9d694b5d51fa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "GYR2 - Goodyear, AZ (Pending Go-Live)",
      "_lat": 33.495777,
      "_lng": -112.430906,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -112.430906
    },
    {
      "id": 4521,
      "name": "HACIENDA HEIGHTS",
      "correlation_id": "102150",
      "billing_address": null,
      "start_date": "2022-08-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1843,
        "name": "CAREMORE-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c9f23ade-e1a9-44fb-9e46-9c1145504975"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2181,
        "code": "America/Dawson",
        "display_name": "America/Dawson",
        "description": "",
        "child_group": null,
        "alt_sequence": 47
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6e400123-1382-4698-b1d0-3a7fc8492cdb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CAREMORE-Sub",
      "_label": "CAREMORE-Sub",
      "_name": "HACIENDA HEIGHTS",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1676,
      "name": "Halas Hall",
      "correlation_id": "11284",
      "billing_address": null,
      "start_date": "2018-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 194,
        "name": "Chicago Bears",
        "type": "CUSTOMER",
        "correlation_id": "11283",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "fefd324b-35f3-4fb7-ad4c-d5a9038ce6d8"
      },
      "parent_id": null,
      "lat": 42.256162,
      "lon": -87.895914,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8a1b6f21-3464-4745-92c0-6940af29ff5c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Chicago Bears",
      "_label": "Chicago Bears",
      "_name": "Halas Hall",
      "_lat": 42.256162,
      "_lng": -87.895914,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -87.895914
    },
    {
      "id": 6899,
      "name": "Halo Glass Recycling",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-12T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 141,
        "name": "E&J Gallo Winery",
        "type": "CUSTOMER",
        "correlation_id": "10596",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641a582d-6107-41ef-9ff3-d87668519738"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4de16ecc-028f-4814-b361-ce58aa2cffa4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "E&J Gallo Winery",
      "_label": "E&J Gallo Winery",
      "_name": "Halo Glass Recycling",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026016,
          "create_date": "2024-03-07T18:13:38.497316Z",
          "update_date": "2024-03-07T18:13:38.497339Z",
          "organization_id": 141,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/d326f0a5923f4337b2a8492f1dfe73ee_00000328bGallo_t.jpg",
          "size": 74967,
          "description": null,
          "link": null,
          "unique_identifier": "d326f0a5923f4337b2a8492f1dfe73ee"
        }
      ],
      "lng": 0
    },
    {
      "id": 2615,
      "name": "Hangar Dallas",
      "correlation_id": "101565",
      "billing_address": {
        "id": 3953,
        "street1": "22777 Springwoods Village",
        "street2": "",
        "city": "Parkway Spring",
        "region": "Texas",
        "postal_code": null,
        "lat": 30.1047076,
        "lon": -95.4444071,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 484,
        "name": "Exxon Mobil (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 730,
            "street1": "5959 Las Colinas Blvd",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75039-2298",
            "lat": 32.889876,
            "lon": -96.9501861,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "88166f7a-664b-414d-ac99-db7dfd054826"
      },
      "parent_id": null,
      "lat": 30.1047076,
      "lon": -95.4444071,
      "local_timezone": {
        "id": 2191,
        "code": "America/Fort_Wayne",
        "display_name": "America/Fort_Wayne",
        "description": "",
        "child_group": null,
        "alt_sequence": 57
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f0b925b6-3ee2-4d35-8a7d-bf1a09b62f47",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Exxon Mobil (CBRE)",
      "_label": "Exxon Mobil (CBRE)",
      "_name": "Hangar Dallas",
      "_lat": 30.1047076,
      "_lng": -95.4444071,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -95.4444071
    },
    {
      "id": 2163,
      "name": "Harleysville, PA",
      "correlation_id": "101244",
      "billing_address": {
        "id": 1277,
        "street1": "2060 Detwiler Rd., Ste. 111",
        "street2": "",
        "city": "Harleysville",
        "region": "Pennsylvania",
        "postal_code": "19438",
        "lat": 40.2547959,
        "lon": -75.3420225,
        "country": "United States"
      },
      "start_date": "2020-09-08T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 311,
        "name": "Siemens (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7e18d041-a341-42b2-a49a-9c10ba6df5aa"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9e1ba37b-3af4-446a-9c9b-ac7e4baa4825",
      "is_mobile": true,
      "mobile_live_date": "2022-02-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Siemens (CBRE)",
      "_label": "Siemens (CBRE)",
      "_name": "Harleysville, PA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 933087,
          "create_date": "2020-12-10T21:55:19.109328Z",
          "update_date": "2020-12-10T21:55:19.109352Z",
          "organization_id": 311,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607637318.9761856_Siemens_t.png",
          "size": 1646,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2019,
      "name": "Hayward",
      "correlation_id": "11642",
      "billing_address": {
        "id": 1540,
        "street1": "25861 INDUSTRIAL BLVD",
        "street2": "",
        "city": "HAYWARD",
        "region": "California",
        "postal_code": null,
        "lat": 37.6340323,
        "lon": -122.1131228,
        "country": "United States"
      },
      "start_date": "2019-05-05T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 179,
        "name": "Illumina",
        "type": "CUSTOMER",
        "correlation_id": "11211",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "069c1097-0984-4c00-9216-9031ac3a85a5"
      },
      "parent_id": null,
      "lat": 37.6335594,
      "lon": -122.1137824,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0403900b-0142-4e59-8dcc-3c127566d4bb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Illumina",
      "_label": "Illumina",
      "_name": "Hayward",
      "_lat": 37.6335594,
      "_lng": -122.1137824,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.1137824
    },
    {
      "id": 2026,
      "name": "Hazelwood",
      "correlation_id": "11630",
      "billing_address": {
        "id": 3748,
        "street1": "444 James McDonnell Blvd.",
        "street2": "",
        "city": "Hazelwood",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.7680366,
        "lon": -90.3771388,
        "country": "United States"
      },
      "start_date": "2019-11-25T00:00:00Z",
      "total_sq_ft": "75000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 38.7683471,
      "lon": -90.3774537,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86ed5bc5-6905-4cb0-ac89-10e99730a469",
      "is_mobile": true,
      "mobile_live_date": "2020-08-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Hazelwood",
      "_lat": 38.7683471,
      "_lng": -90.3774537,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -90.3774537
    },
    {
      "id": 2568,
      "name": "Hazelwood",
      "correlation_id": "101513",
      "billing_address": {
        "id": 554,
        "street1": "5463 Phantom Drive",
        "street2": "",
        "city": "Hazelwood",
        "region": "Missouri",
        "postal_code": "63042",
        "lat": 38.7666593,
        "lon": -90.3910817,
        "country": "United States"
      },
      "start_date": "2023-03-29T17:41:52.846000Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 60,
        "name": "USPS",
        "type": "CUSTOMER",
        "correlation_id": "10970",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c4dc1d81-7a21-4d63-a6f2-580ec5ec8b25"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4b33cfa8-f0d6-449a-99ce-548882d8fe5a",
      "is_mobile": true,
      "mobile_live_date": "2023-03-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "USPS",
      "_label": "USPS",
      "_name": "Hazelwood",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2008,
      "name": "Hazelwood, MO",
      "correlation_id": "100959",
      "billing_address": null,
      "start_date": "2013-11-13T14:04:45Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 38.775642,
      "lon": -90.3758245,
      "local_timezone": {
        "id": 2233,
        "code": "America/Menominee",
        "display_name": "America/Menominee",
        "description": "",
        "child_group": null,
        "alt_sequence": 99
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2f5b62d1-35d4-438c-b8f9-98060ce04781",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Hazelwood, MO",
      "_lat": 38.775642,
      "_lng": -90.3758245,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -90.3758245
    },
    {
      "id": 2096,
      "name": "Headquarters",
      "correlation_id": "11698",
      "billing_address": {
        "id": 253,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "1981-12-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 290,
        "name": "SBM-H",
        "type": "CUSTOMER",
        "correlation_id": "11697",
        "addresses": [
          {
            "id": 258,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0a023ec6-4462-490a-a4db-dcc67a63fda7"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "437dd686-9913-4b04-aca2-cf09edb080af",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM-H",
      "_label": "SBM-H",
      "_name": "Headquarters",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 5649,
      "name": "Hebron, KY",
      "correlation_id": "102339",
      "billing_address": null,
      "start_date": "2023-01-12T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2124,
        "name": "Menzies Aviation",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b58b0363-082b-45b0-a839-ca1370206a91"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2219,
        "code": "America/Kentucky/Monticello",
        "display_name": "America/Kentucky/Monticello",
        "description": "",
        "child_group": null,
        "alt_sequence": 85
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3b75a562-b3e2-4898-8b97-240dca472447",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Menzies Aviation",
      "_label": "Menzies Aviation",
      "_name": "Hebron, KY",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 7127,
      "name": "Helmkamp*",
      "correlation_id": "103444",
      "billing_address": null,
      "start_date": "2024-03-05T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 38.66505,
      "lon": -90.55921,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5c281443-44e0-45bf-93a8-c4985e4e038e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Helmkamp*",
      "_lat": 38.66505,
      "_lng": -90.55921,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -90.55921
    },
    {
      "id": 2246,
      "name": "Henderson",
      "correlation_id": "101259",
      "billing_address": null,
      "start_date": "2020-10-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "64",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 36.039524,
      "lon": -114.98172,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d30808ac-b321-4403-8159-f02158772d38",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Henderson",
      "_lat": 36.039524,
      "_lng": -114.98172,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -114.98172
    },
    {
      "id": 2702,
      "name": "Hennepin",
      "correlation_id": "101857",
      "billing_address": null,
      "start_date": "2021-11-01T14:06:15Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 558,
        "name": "Computershare",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 823,
            "street1": "1015 SE Tenth ave",
            "street2": "",
            "city": "Minneapolis",
            "region": "MN",
            "postal_code": "55414",
            "lat": 44.9894065,
            "lon": -93.2388427,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "554c6a87-fed8-480b-b780-d1f1daf09278"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ba7dbf69-0761-48bb-b76e-485485a0f717",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Computershare",
      "_label": "Computershare",
      "_name": "Hennepin",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2050,
      "name": "Hercules",
      "correlation_id": "11673",
      "billing_address": {
        "id": 3752,
        "street1": "235 Linus Pauling Suite C",
        "street2": "",
        "city": "Hercules",
        "region": "California",
        "postal_code": null,
        "lat": 38.0228124,
        "lon": -122.2742945,
        "country": "United States"
      },
      "start_date": "2019-09-01T23:59:59Z",
      "total_sq_ft": "537639",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 208,
        "name": "Bio-Rad",
        "type": "CUSTOMER",
        "correlation_id": "11462",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "86f2e6a9-8c5e-4070-afa7-db939edb358a"
      },
      "parent_id": 2531,
      "lat": 38.0232706,
      "lon": -122.2786569,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "84fffdb2-b5df-4203-9e52-4dddbd62c1df",
      "is_mobile": true,
      "mobile_live_date": "2020-08-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bio-Rad",
      "_label": "Bio-Rad",
      "_name": "Hercules",
      "_lat": 38.0232706,
      "_lng": -122.2786569,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.2786569
    },
    {
      "id": 2092,
      "name": "Heredia, CR",
      "correlation_id": "11691",
      "billing_address": {
        "id": 22,
        "street1": "Ultra Lag Free Zone 800mts Oeste de Jardines del Recuerdo",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 10.1387512,
        "lon": -84.0378894,
        "country": "Costa Rica"
      },
      "start_date": "2020-02-29T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 287,
        "name": "Refinitiv (CR) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11690",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d15241a4-9d75-4cd1-9b7b-b0250f807410"
      },
      "parent_id": null,
      "lat": 9.9983516,
      "lon": -84.1394012,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b4aa69b7-bdbb-4401-a9e1-601efe955ee1",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Refinitiv (CR) (CBRE)",
      "_label": "Refinitiv (CR) (CBRE)",
      "_name": "Heredia, CR",
      "_lat": 9.9983516,
      "_lng": -84.1394012,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 933076,
          "create_date": "2020-12-10T21:53:59.022328Z",
          "update_date": "2020-12-10T21:53:59.022352Z",
          "organization_id": 287,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607637238.930703_Refinitiv_t.jpg",
          "size": 5176,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -84.1394012
    },
    {
      "id": 5771,
      "name": "HGR5 – Shippensburg, PA",
      "correlation_id": "102616",
      "billing_address": {
        "id": 4065,
        "street1": "1480 United Drive",
        "street2": "",
        "city": "Shippensburg",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.011908,
        "lon": -77.5327053,
        "country": "United States"
      },
      "start_date": "2023-08-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 40.01328,
      "lon": -77.53334,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "62249ade-7fa5-42ce-bc3e-9374113e049c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "HGR5 – Shippensburg, PA",
      "_lat": 40.01328,
      "_lng": -77.53334,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.53334
    },
    {
      "id": 2111,
      "name": "Hicksville, NY",
      "correlation_id": "102230",
      "billing_address": {
        "id": 483,
        "street1": "55 Cantiague Rock Road.",
        "street2": "",
        "city": "Hicksville",
        "region": "New York",
        "postal_code": "11801",
        "lat": 40.7667184,
        "lon": -73.5511221,
        "country": "United States"
      },
      "start_date": "2022-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2078,
        "name": "Novartis",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a9d4629d-90b9-42e5-929f-72821bef8015"
      },
      "parent_id": null,
      "lat": 40.7667184,
      "lon": -73.5511221,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0ca96cb6-007c-4835-9c0c-888d6edee942",
      "is_mobile": true,
      "mobile_live_date": "2021-03-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis",
      "_label": "Novartis",
      "_name": "Hicksville, NY",
      "_lat": 40.7667184,
      "_lng": -73.5511221,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 4988519,
          "create_date": "2022-10-25T21:00:07.916575Z",
          "update_date": "2022-10-25T21:00:07.916605Z",
          "organization_id": 2078,
          "uploaded_by_id": 43333,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/30b395a352c844d086175f471e21216b_Novartis_image_t.png",
          "size": 13642,
          "description": null,
          "link": null,
          "unique_identifier": "30b395a352c844d086175f471e21216b"
        }
      ],
      "lng": -73.5511221
    },
    {
      "id": 4553,
      "name": "Highlands Ranch",
      "correlation_id": "102124",
      "billing_address": {
        "id": 2924,
        "street1": "6415 Business Center Dr.",
        "street2": "",
        "city": "Highlands Ranch",
        "region": "Colorado",
        "postal_code": null,
        "lat": 39.5630339,
        "lon": -104.9142374,
        "country": "United States"
      },
      "start_date": "2022-09-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 39.56291,
      "lon": -104.91427,
      "local_timezone": {
        "id": 2164,
        "code": "America/Boise",
        "display_name": "America/Boise",
        "description": "",
        "child_group": null,
        "alt_sequence": 30
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "afde9d5b-708c-4543-874d-25bb0be167d5",
      "is_mobile": true,
      "mobile_live_date": "2022-11-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "Highlands Ranch",
      "_lat": 39.56291,
      "_lng": -104.91427,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -104.91427
    },
    {
      "id": 1178,
      "name": "Hillsboro",
      "correlation_id": "10111",
      "billing_address": {
        "id": 438,
        "street1": "4625 NE Brookwood Pkwy- B1",
        "street2": "",
        "city": "Hillsboro",
        "region": "Oregon",
        "postal_code": "97124",
        "lat": 45.5537143,
        "lon": -122.932318,
        "country": "United States"
      },
      "start_date": "2008-06-01T23:59:59Z",
      "total_sq_ft": "1875000",
      "total_buildings": "107",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 69,
        "name": "Genentech",
        "type": "CUSTOMER",
        "correlation_id": "10107",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "fe334805-95a6-4ca7-9881-e4bd22d80b4f"
      },
      "parent_id": null,
      "lat": 45.5539684,
      "lon": -122.9287797,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "94f4e10c-ed9b-4857-9977-b976e000232b",
      "is_mobile": true,
      "mobile_live_date": "2021-04-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Genentech",
      "_label": "Genentech",
      "_name": "Hillsboro",
      "_lat": 45.5539684,
      "_lng": -122.9287797,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.9287797
    },
    {
      "id": 5976,
      "name": "Hillsboro",
      "correlation_id": "102871",
      "billing_address": {
        "id": 4014,
        "street1": "2345 NE Overlook Drive",
        "street2": "",
        "city": "Beaverton",
        "region": "Oregon",
        "postal_code": null,
        "lat": 45.5367676,
        "lon": -122.8854253,
        "country": "United States"
      },
      "start_date": "2024-01-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2240,
        "name": "Lam Research (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1e7b2470-0302-45b1-b34a-667694a5b5eb"
      },
      "parent_id": null,
      "lat": 45.53649,
      "lon": -122.88456,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
      "is_mobile": true,
      "mobile_live_date": "2024-01-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lam Research (CBRE)",
      "_label": "Lam Research (CBRE)",
      "_name": "Hillsboro",
      "_lat": 45.53649,
      "_lng": -122.88456,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10767784,
          "create_date": "2024-02-21T19:11:40.419335Z",
          "update_date": "2024-02-21T19:11:40.419360Z",
          "organization_id": 2240,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
          "size": 1275960,
          "description": null,
          "link": null,
          "unique_identifier": "ee68f0369eec4944829b45f1ba8b5011"
        }
      ],
      "lng": -122.88456
    },
    {
      "id": 2437,
      "name": "HIT",
      "correlation_id": "101316",
      "billing_address": {
        "id": 259,
        "street1": "Genentech HIT Building 21 - 4625  NE Brookwood Pkwy",
        "street2": "",
        "city": "Hillsboro",
        "region": "Oregon",
        "postal_code": "97124",
        "lat": 45.5537487,
        "lon": -122.9289827,
        "country": "United States"
      },
      "start_date": "2021-06-04T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 154,
        "name": "Genentech (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11090",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e2a5e3be-07f7-4e3c-a7cb-698e831a4be2"
      },
      "parent_id": null,
      "lat": 45.5537487,
      "lon": -122.9289827,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "85297d0b-0135-4301-a90e-70be634632e4",
      "is_mobile": true,
      "mobile_live_date": "2021-05-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Genentech (JLL)",
      "_label": "Genentech (JLL)",
      "_name": "HIT",
      "_lat": 45.5537487,
      "_lng": -122.9289827,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.9289827
    },
    {
      "id": 35,
      "name": "HJL, OR",
      "correlation_id": "101827",
      "billing_address": {
        "id": 2878,
        "street1": "2111 NE 25th Ave",
        "street2": "",
        "city": "Hillsboro",
        "region": "Oregon",
        "postal_code": "97124",
        "lat": 45.5452098,
        "lon": -122.9625613,
        "country": "United States"
      },
      "start_date": "2021-03-12T23:59:59Z",
      "total_sq_ft": "2303734",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 45.54093,
      "lon": -122.96034,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "68d76e0c-1094-4ec4-a9e7-eddc58eaf8e5",
      "is_mobile": true,
      "mobile_live_date": "2018-12-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "HJL, OR",
      "_lat": 45.54093,
      "_lng": -122.96034,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.96034
    },
    {
      "id": 5764,
      "name": "HLO3 – Lanesville, IN",
      "correlation_id": "102607",
      "billing_address": {
        "id": 4049,
        "street1": "4450 Crandall Lanesville Rd NE",
        "street2": "",
        "city": "Lanesville",
        "region": "Indiana",
        "postal_code": null,
        "lat": 38.2613547,
        "lon": -86.0017871,
        "country": "United States"
      },
      "start_date": "2023-07-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 38.28217,
      "lon": -86.04265,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e764e4d0-232a-4950-a5d7-e26d4eb3aa54",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "HLO3 – Lanesville, IN",
      "_lat": 38.28217,
      "_lng": -86.04265,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.04265
    },
    {
      "id": 4404,
      "name": "Holding Corp*",
      "correlation_id": "101982",
      "billing_address": null,
      "start_date": "2022-08-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1782,
        "name": "Realogy",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e03c9e-69ef-4a56-966a-c0f36e393c6c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7ede5c31-18d1-4f80-a665-af148cb05246",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Realogy",
      "_label": "Realogy",
      "_name": "Holding Corp*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2335,
      "name": "Horizon Deerfield, IL",
      "correlation_id": "101284",
      "billing_address": {
        "id": 469,
        "street1": "1 Horizon Way",
        "street2": "",
        "city": "Deerfiled",
        "region": "Illinois",
        "postal_code": "60015",
        "lat": 42.1540368,
        "lon": -87.8767135,
        "country": "United States"
      },
      "start_date": "2020-12-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 305,
        "name": "Centene (Hines)",
        "type": "CUSTOMER",
        "correlation_id": "101238",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1d80672f-8d65-43f6-bf72-66aff064666c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6101dc73-cd60-4667-a6c1-7cff249eba7c",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Centene (Hines)",
      "_label": "Centene (Hines)",
      "_name": "Horizon Deerfield, IL",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1475,
      "name": "Hudson",
      "correlation_id": "101828",
      "billing_address": {
        "id": 263,
        "street1": "75 Reed Road",
        "street2": "",
        "city": "Hudson",
        "region": "Massachusetts",
        "postal_code": "01749",
        "lat": 42.3828118,
        "lon": -71.5549621,
        "country": "United States"
      },
      "start_date": "2021-03-12T23:59:59Z",
      "total_sq_ft": "454705",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 42.38052,
      "lon": -71.55491,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5d9a474b-4218-4886-9e50-78f6b42e96ba",
      "is_mobile": true,
      "mobile_live_date": "2021-03-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Hudson",
      "_lat": 42.38052,
      "_lng": -71.55491,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.55491
    },
    {
      "id": 1258,
      "name": "Humacao",
      "correlation_id": "10219",
      "billing_address": {
        "id": 192,
        "street1": "Carr 3 Km 77.5",
        "street2": "",
        "city": "Humacao",
        "region": "Florida",
        "postal_code": "00791",
        "lat": 27.6648274,
        "lon": -81.5157535,
        "country": "United States"
      },
      "start_date": "2021-02-18T23:59:59Z",
      "total_sq_ft": "4325321",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 18.1496828,
      "lon": -65.8273847,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "559a9342-2242-489a-a36c-6adf4306f98f",
      "is_mobile": true,
      "mobile_live_date": "2021-02-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Humacao",
      "_lat": 18.1496828,
      "_lng": -65.8273847,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -65.8273847
    },
    {
      "id": 5652,
      "name": "Huntington Park (JLL)",
      "correlation_id": "102408",
      "billing_address": {
        "id": 3040,
        "street1": "3508-12 E Florence Ave",
        "street2": "",
        "city": "Huntington Park",
        "region": "California",
        "postal_code": "90255",
        "lat": 33.9714647,
        "lon": -118.2030506,
        "country": "United States"
      },
      "start_date": "2023-02-01T19:33:33Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 33.97124,
      "lon": -118.20313,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dac59c29-ebfb-48b0-b24c-e9d2892f4045",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Huntington Park (JLL)",
      "_lat": 33.97124,
      "_lng": -118.20313,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -118.20313
    },
    {
      "id": 2354,
      "name": "Huntsville (JLL)",
      "correlation_id": "101359",
      "billing_address": {
        "id": 143,
        "street1": "3005 L&N Drive",
        "street2": "",
        "city": "Huntsville",
        "region": "3214",
        "postal_code": "35801",
        "lat": 34.704722,
        "lon": -86.5853051,
        "country": "2017"
      },
      "start_date": "2021-02-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f78be99-2353-4ce5-9c68-c50d6f9a0b8d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Huntsville (JLL)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1566,
      "name": "Hunt Valley",
      "correlation_id": "11078",
      "billing_address": {
        "id": 456,
        "street1": "124 Industry Lane",
        "street2": "",
        "city": "Cockeysville",
        "region": "Maryland",
        "postal_code": "21030",
        "lat": 39.4672012,
        "lon": -76.6423542,
        "country": "United States"
      },
      "start_date": "2017-12-09T23:59:59Z",
      "total_sq_ft": "5283000",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 39.4661739,
      "lon": -76.6417521,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "979545e0-cb7f-46fc-aaf6-90da8bdff77c",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Hunt Valley",
      "_lat": 39.4661739,
      "_lng": -76.6417521,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -76.6417521
    },
    {
      "id": 5981,
      "name": "IAD11*",
      "correlation_id": "102876",
      "billing_address": null,
      "start_date": "2023-11-22T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 38.94548,
      "lon": -77.55901,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "07572032-5a22-4b19-970f-4adad56c25e0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "IAD11*",
      "_lat": 38.94548,
      "_lng": -77.55901,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -77.55901
    },
    {
      "id": 1321,
      "name": "ICB (JLL)",
      "correlation_id": "10330",
      "billing_address": {
        "id": 419,
        "street1": "5201 W Amelia Earhart Dr.",
        "street2": "",
        "city": "Salt Lake City",
        "region": "Utah",
        "postal_code": "84116",
        "lat": 40.775475,
        "lon": -112.0168815,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "215410",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 40.7748963,
      "lon": -112.0101001,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b2b272f8-f205-49f3-94a0-469a8b2407ce",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "ICB (JLL)",
      "_lat": 40.7748963,
      "_lng": -112.0101001,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -112.0101001
    },
    {
      "id": 6682,
      "name": "IGQ2 - University Park, IL (Pending Go-Live)",
      "correlation_id": "103391",
      "billing_address": {
        "id": 4092,
        "street1": "23257 South Central Ave",
        "street2": "",
        "city": "University Park",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.4672667,
        "lon": -87.749752,
        "country": "United States"
      },
      "start_date": "2024-04-02T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.429214,
      "lon": -87.747105,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "92a15365-cf36-4966-84b0-22a4ab3d38df",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "IGQ2 - University Park, IL (Pending Go-Live)",
      "_lat": 41.429214,
      "_lng": -87.747105,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -87.747105
    },
    {
      "id": 1324,
      "name": "Illinois",
      "correlation_id": "10333",
      "billing_address": {
        "id": 696,
        "street1": "4800 Wabash Ave,",
        "street2": "",
        "city": "Springfield",
        "region": "Illinois",
        "postal_code": "62711",
        "lat": 39.755076,
        "lon": -89.7404571,
        "country": "United States"
      },
      "start_date": "2014-03-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 41.7728659,
      "lon": -88.1528542,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
      "is_mobile": true,
      "mobile_live_date": "2021-08-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Illinois",
      "_lat": 41.7728659,
      "_lng": -88.1528542,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -88.1528542
    },
    {
      "id": 6677,
      "name": "IND4 – Indianapolis, IN (Pending Go-Live)",
      "correlation_id": "103386",
      "billing_address": {
        "id": 4087,
        "street1": "4026 Park 65 Drive",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.8272047,
        "lon": -86.2310415,
        "country": "United States"
      },
      "start_date": "2024-03-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.82711,
      "lon": -86.2303,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2b9ecf1e-3207-45d4-82b0-0ba08c7a6401",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "IND4 – Indianapolis, IN (Pending Go-Live)",
      "_lat": 39.82711,
      "_lng": -86.2303,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.2303
    },
    {
      "id": 6667,
      "name": "IND5 – Plainfield, IN",
      "correlation_id": "103375",
      "billing_address": {
        "id": 4054,
        "street1": "800 Perry Road",
        "street2": "",
        "city": "Plainfield",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.6965752,
        "lon": -86.3600191,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.695729,
      "lon": -86.359513,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5cecc699-3b03-4087-ae6f-50123d4c533b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "IND5 – Plainfield, IN",
      "_lat": 39.695729,
      "_lng": -86.359513,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.359513
    },
    {
      "id": 6678,
      "name": "IND8 - Indianapolis, IN (Pending Go-Live)",
      "correlation_id": "103387",
      "billing_address": {
        "id": 4088,
        "street1": "4026 Park 65 Drive",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.8272047,
        "lon": -86.2310415,
        "country": "United States"
      },
      "start_date": "2024-03-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.82711,
      "lon": -86.2303,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9d4df9b3-e373-48cc-b64e-2f6cf49b4f2f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "IND8 - Indianapolis, IN (Pending Go-Live)",
      "_lat": 39.82711,
      "_lng": -86.2303,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.2303
    },
    {
      "id": 6668,
      "name": "IND9 – Greenwood, IN",
      "correlation_id": "103376",
      "billing_address": {
        "id": 4056,
        "street1": "1151 S. Graham Road",
        "street2": "",
        "city": "Greenwood",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.6001721,
        "lon": -86.0640094,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 39.60119,
      "lon": -86.05487,
      "local_timezone": {
        "id": 2209,
        "code": "America/Indiana/Vevay",
        "display_name": "America/Indiana/Vevay",
        "description": "",
        "child_group": null,
        "alt_sequence": 75
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e661022a-3a8c-4e1d-a72f-ef11e8884a70",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "IND9 – Greenwood, IN",
      "_lat": 39.60119,
      "_lng": -86.05487,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.05487
    },
    {
      "id": 1469,
      "name": "Indianapolis, IN",
      "correlation_id": "10045",
      "billing_address": {
        "id": 3581,
        "street1": "639 S. Delaware St-Dock 57",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.758263,
        "lon": -86.1540166,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "1864967",
      "total_buildings": "395",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 72,
        "name": "Eli Lilly (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10042",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a"
      },
      "parent_id": 2655,
      "lat": 39.7562681,
      "lon": -86.1546757,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
      "is_mobile": true,
      "mobile_live_date": "2021-05-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Eli Lilly (CBRE)",
      "_label": "Eli Lilly (CBRE)",
      "_name": "Indianapolis, IN",
      "_lat": 39.7562681,
      "_lng": -86.1546757,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853030,
          "create_date": "2020-11-13T23:55:46.918511Z",
          "update_date": "2020-11-13T23:55:46.918539Z",
          "organization_id": 72,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311746.7267878_Artboard8_t.png",
          "size": 413839,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -86.1546757
    },
    {
      "id": 1996,
      "name": "Indianapolis, IN",
      "correlation_id": "11637",
      "billing_address": {
        "id": 464,
        "street1": "9239 Castlegate Dr. Indianapolis, IN 46256",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": "46256",
        "lat": 39.922153,
        "lon": -86.0261558,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "19",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 268,
        "name": "Roche",
        "type": "CUSTOMER",
        "correlation_id": "100009",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "fbddb1b4-89d1-4ed3-8609-66cf80aeb6dc"
      },
      "parent_id": null,
      "lat": 39.9243283,
      "lon": -86.0305643,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a2200923-dcc1-4e5c-8987-ce59e705a006",
      "is_mobile": true,
      "mobile_live_date": "2020-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Roche",
      "_label": "Roche",
      "_name": "Indianapolis, IN",
      "_lat": 39.9243283,
      "_lng": -86.0305643,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.0305643
    },
    {
      "id": 1266,
      "name": "Indianapolis, IN (Lytle)",
      "correlation_id": "10233",
      "billing_address": {
        "id": 3765,
        "street1": "220 Virginia Ave.",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.7632948,
        "lon": -86.1512909,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "965918",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 39.7636506,
      "lon": -86.1498384,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eef3d9fb-6955-483b-9e1a-65d336ec3680",
      "is_mobile": true,
      "mobile_live_date": "2020-07-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Indianapolis, IN (Lytle)",
      "_lat": 39.7636506,
      "_lng": -86.1498384,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -86.1498384
    },
    {
      "id": 1094,
      "name": "Indian Creek",
      "correlation_id": "10892",
      "billing_address": null,
      "start_date": "2016-06-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 35,
        "name": "Mondi Jackson",
        "type": "CUSTOMER",
        "correlation_id": "10891",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "50f42a50-7520-4add-863a-3649695f47ad"
      },
      "parent_id": null,
      "lat": 37.481208,
      "lon": -89.516791,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ec910aa-3276-4112-8a59-c9fa0fa84aa2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Mondi Jackson",
      "_label": "Mondi Jackson",
      "_name": "Indian Creek",
      "_lat": 37.481208,
      "_lng": -89.516791,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -89.516791
    },
    {
      "id": 2508,
      "name": "Indian Wells, CA",
      "correlation_id": "102169",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 33.7205704,
      "lon": -116.3624457,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ff796a89-25b2-4181-9ba8-2ce9178c64b0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Indian Wells, CA",
      "_lat": 33.7205704,
      "_lng": -116.3624457,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -116.3624457
    },
    {
      "id": 1880,
      "name": "Indy",
      "correlation_id": "101071",
      "billing_address": {
        "id": 435,
        "street1": "1301 White Rivery Parkway",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": "46221",
        "lat": 39.7496213,
        "lon": -86.1712263,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 273,
        "name": "Elanco (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11589",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b"
      },
      "parent_id": null,
      "lat": 39.8014813,
      "lon": -86.195104,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f6d700cf-a4c8-44d7-8d83-1ce085a21b8d",
      "is_mobile": true,
      "mobile_live_date": "2020-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elanco (CBRE)",
      "_label": "Elanco (CBRE)",
      "_name": "Indy",
      "_lat": 39.8014813,
      "_lng": -86.195104,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918792,
          "create_date": "2020-12-07T22:03:11.720761Z",
          "update_date": "2020-12-07T22:03:11.720786Z",
          "organization_id": 273,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607378591.6016922_Elanco_t.png",
          "size": 6049,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -86.195104
    },
    {
      "id": 3068,
      "name": "Indy",
      "correlation_id": "101486",
      "billing_address": {
        "id": 1241,
        "street1": "6812 Corporate Drive",
        "street2": "",
        "city": "Indianapolia",
        "region": "Indiana",
        "postal_code": "46278",
        "lat": 39.8786302,
        "lon": -86.2663357,
        "country": "United States"
      },
      "start_date": "2021-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 39.8786302,
      "lon": -86.2663357,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "af8c88ab-b969-4cc5-b274-a1b2b521892b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Indy",
      "_lat": 39.8786302,
      "_lng": -86.2663357,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.2663357
    },
    {
      "id": 2566,
      "name": "Interchange",
      "correlation_id": "101510",
      "billing_address": {
        "id": 552,
        "street1": "5150 Interchange Way",
        "street2": "",
        "city": "Louisville",
        "region": "Kentucky",
        "postal_code": "40229",
        "lat": 38.0952973,
        "lon": -85.6804033,
        "country": "United States"
      },
      "start_date": "2021-06-01T12:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 38.09494,
      "lon": -85.67985,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "87cfe09e-ff48-43e9-98b4-c52f9fb9ffbf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Interchange",
      "_lat": 38.09494,
      "_lng": -85.67985,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -85.67985
    },
    {
      "id": 6758,
      "name": "IOC",
      "correlation_id": "103400",
      "billing_address": {
        "id": 4104,
        "street1": "2001 S. Tibbs Avenue",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.7387792,
        "lon": -86.2136262,
        "country": "United States"
      },
      "start_date": "2024-01-01T15:45:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fef4b3bb-4c38-4335-827a-ae7746e03318",
      "is_mobile": true,
      "mobile_live_date": "2024-01-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "IOC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": 0
    },
    {
      "id": 5565,
      "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
      "correlation_id": "102400",
      "billing_address": {
        "id": 3742,
        "street1": "38100 Ecorse Rd.",
        "street2": "",
        "city": "Romulus",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2535573,
        "lon": -83.4124863,
        "country": "United States"
      },
      "start_date": "2020-11-09T18:17:26Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.25812,
      "lon": -83.4135,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
      "is_mobile": true,
      "mobile_live_date": "2023-07-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": "7347896550",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "ION – Ford Battery Center of Excellence – Romulus, MI",
      "_lat": 42.25812,
      "_lng": -83.4135,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.4135
    },
    {
      "id": 1071,
      "name": "Irvine",
      "correlation_id": "10852",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 27,
        "name": "Broadcom (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10851",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7f712c5e-3a2d-4451-8b66-a7d119841317"
      },
      "parent_id": null,
      "lat": 33.637543,
      "lon": -117.848196,
      "local_timezone": null,
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f03fff57-0f13-41bc-b78c-d3b1030941b9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Broadcom (CBRE)",
      "_label": "Broadcom (CBRE)",
      "_name": "Irvine",
      "_lat": 33.637543,
      "_lng": -117.848196,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859888,
          "create_date": "2020-11-17T14:46:02.784469Z",
          "update_date": "2020-11-17T14:46:02.784498Z",
          "organization_id": 27,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605624362.6945145_4991646015791417645684447_origin_t.png",
          "size": 5054,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.848196
    },
    {
      "id": 4227,
      "name": "Irvine",
      "correlation_id": "101853",
      "billing_address": {
        "id": 2169,
        "street1": "2 Witherspoon Loop",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "92604",
        "lat": 33.6731881,
        "lon": -117.791666,
        "country": "United States"
      },
      "start_date": "2023-02-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1648,
        "name": "TGS",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cecd50a4-ad75-4178-b1af-47fc306e65bc"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "TGS",
      "_label": "TGS",
      "_name": "Irvine",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10589786,
          "create_date": "2024-02-09T21:31:13.787266Z",
          "update_date": "2024-02-09T21:31:13.787289Z",
          "organization_id": 1648,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg",
          "size": 16389,
          "description": null,
          "link": null,
          "unique_identifier": "9860bdb84f0540ccb7875fa48053238b"
        }
      ],
      "lng": 0
    },
    {
      "id": 5823,
      "name": "Irvine",
      "correlation_id": "102743",
      "billing_address": null,
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 33.68031,
      "lon": -117.8374,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "24a3dde7-040b-482b-96de-b7443bdc8967",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Irvine",
      "_lat": 33.68031,
      "_lng": -117.8374,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.8374
    },
    {
      "id": 2352,
      "name": "Irvine (JLL)",
      "correlation_id": "101342",
      "billing_address": {
        "id": 142,
        "street1": "22 Prism Irvine",
        "street2": "",
        "city": "Irvine",
        "region": "Alabama",
        "postal_code": "95618",
        "lat": 33.6526935,
        "lon": -117.7503464,
        "country": "United States"
      },
      "start_date": "2021-02-16T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 33.6526935,
      "lon": -117.7503464,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1553481a-baad-4398-9f10-a0a70d249655",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Irvine (JLL)",
      "_lat": 33.6526935,
      "_lng": -117.7503464,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.7503464
    },
    {
      "id": 1895,
      "name": "Irving",
      "correlation_id": "10755",
      "billing_address": {
        "id": 237,
        "street1": "1821 Hurd Drive",
        "street2": "",
        "city": "Irving",
        "region": "Texas",
        "postal_code": "75038",
        "lat": 32.8743888,
        "lon": -96.9748941,
        "country": "United States"
      },
      "start_date": "2016-10-31T23:59:59Z",
      "total_sq_ft": "2300000",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 32.8702886,
      "lon": -96.9755231,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "627be1f3-717b-471a-a67c-236a2933be71",
      "is_mobile": true,
      "mobile_live_date": "2021-03-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Irving",
      "_lat": 32.8702886,
      "_lng": -96.9755231,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -96.9755231
    },
    {
      "id": 2069,
      "name": "Irving, TX",
      "correlation_id": "11596",
      "billing_address": {
        "id": 521,
        "street1": "6555 State hwy",
        "street2": "",
        "city": "Irving",
        "region": "Texas",
        "postal_code": "75039",
        "lat": 32.8916766,
        "lon": -96.9477532,
        "country": "United States"
      },
      "start_date": "2019-10-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 32.89502,
      "lon": -96.97407,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Irving, TX",
      "_lat": 32.89502,
      "_lng": -96.97407,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -96.97407
    },
    {
      "id": 2618,
      "name": "Irving, TX",
      "correlation_id": "101556",
      "billing_address": {
        "id": 732,
        "street1": "8225 Bent Branch Road",
        "street2": "",
        "city": "Irving",
        "region": "Texas",
        "postal_code": "75063",
        "lat": 32.9185433,
        "lon": -96.9908672,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 32.91871,
      "lon": -96.99089,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
      "is_mobile": true,
      "mobile_live_date": "2022-11-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Irving, TX",
      "_lat": 32.91871,
      "_lng": -96.99089,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -96.99089
    },
    {
      "id": 6750,
      "name": "IT Bic",
      "correlation_id": "103421",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29249,
      "lon": -83.19583,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "IT Bic",
      "_lat": 42.29249,
      "_lng": -83.19583,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.19583
    },
    {
      "id": 6599,
      "name": "ITHQ-C",
      "correlation_id": "103370",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.29249,
      "lon": -83.19583,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "ITHQ-C",
      "_lat": 42.29249,
      "_lng": -83.19583,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.19583
    },
    {
      "id": 1393,
      "name": "ITP1 - Indiana Transmission Plant - Kokomo, IN",
      "correlation_id": "101690",
      "billing_address": {
        "id": 917,
        "street1": "3660 N Reed Rd",
        "street2": "",
        "city": "Kokomo",
        "region": "Indiana",
        "postal_code": "46902",
        "lat": 40.5282303,
        "lon": -86.1229207,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 40.5282303,
      "lon": -86.1229207,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "50272465-0e17-45d0-88d6-16013e6d52af",
      "is_mobile": true,
      "mobile_live_date": "2022-03-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "ITP1 - Indiana Transmission Plant - Kokomo, IN",
      "_lat": 40.5282303,
      "_lng": -86.1229207,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -86.1229207
    },
    {
      "id": 1968,
      "name": "Jacksonville",
      "correlation_id": "11582",
      "billing_address": null,
      "start_date": "2019-08-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 30.24834,
      "lon": -81.52064,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Jacksonville",
      "_lat": 30.24834,
      "_lng": -81.52064,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -81.52064
    },
    {
      "id": 2664,
      "name": "Jacksonville FL",
      "correlation_id": "101553",
      "billing_address": {
        "id": 795,
        "street1": "8001 Baymeadows Way",
        "street2": "",
        "city": "Jacksonville",
        "region": "Florida",
        "postal_code": "32256",
        "lat": 30.2271286,
        "lon": -81.5770369,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 30.22642,
      "lon": -81.57705,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
      "is_mobile": true,
      "mobile_live_date": "2022-01-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Jacksonville FL",
      "_lat": 30.22642,
      "_lng": -81.57705,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -81.57705
    },
    {
      "id": 1305,
      "name": "Janssen-Raritan",
      "correlation_id": "101291",
      "billing_address": {
        "id": 1111,
        "street1": "1000 US 202",
        "street2": "",
        "city": "Raritan",
        "region": "New Jersey",
        "postal_code": "08869",
        "lat": 40.5770877,
        "lon": -74.6508391,
        "country": "United States"
      },
      "start_date": "2020-12-10T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 40.5770459,
      "lon": -74.6495619,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "baa964ad-0291-411f-aa14-b3939050e90d",
      "is_mobile": true,
      "mobile_live_date": "2022-01-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Janssen-Raritan",
      "_lat": 40.5770459,
      "_lng": -74.6495619,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -74.6495619
    },
    {
      "id": 5456,
      "name": "Jefferson, GA",
      "correlation_id": "102245",
      "billing_address": {
        "id": 2925,
        "street1": "874 Thomas PKWY",
        "street2": "",
        "city": null,
        "region": "Georgia",
        "postal_code": "30549",
        "lat": 34.173032,
        "lon": -83.5894357,
        "country": "United States"
      },
      "start_date": "2022-11-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 34.1729,
      "lon": -83.59037,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6fae54e1-e27b-425c-bf55-19de11629106",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Jefferson, GA",
      "_lat": 34.1729,
      "_lng": -83.59037,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.59037
    },
    {
      "id": 2009,
      "name": "Jerseyville, IL",
      "correlation_id": "101046",
      "billing_address": {
        "id": 3760,
        "street1": "26207 Davidson Rd.",
        "street2": "",
        "city": "Jerseyville",
        "region": "Illinois",
        "postal_code": null,
        "lat": 39.068638,
        "lon": -90.306753,
        "country": "United States"
      },
      "start_date": "2013-11-13T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 39.068638,
      "lon": -90.306753,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f0c4d67c-a23d-4f44-a739-94fbf9172622",
      "is_mobile": true,
      "mobile_live_date": "2020-09-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Jerseyville, IL",
      "_lat": 39.068638,
      "_lng": -90.306753,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -90.306753
    },
    {
      "id": 2705,
      "name": "JNAP - Jefferson North Assembly Plant - Detroit, MI",
      "correlation_id": "101699",
      "billing_address": {
        "id": 881,
        "street1": "2101 Conner St",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48215",
        "lat": 42.3731232,
        "lon": -82.9672939,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.3731232,
      "lon": -82.9672939,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db0d0cc3-a19d-474c-9702-8503d9a327f8",
      "is_mobile": true,
      "mobile_live_date": "2022-05-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "JNAP - Jefferson North Assembly Plant - Detroit, MI",
      "_lat": 42.3731232,
      "_lng": -82.9672939,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -82.9672939
    },
    {
      "id": 1608,
      "name": "John's Creek",
      "correlation_id": "11154",
      "billing_address": null,
      "start_date": "2015-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 169,
        "name": "Alcon Laboratories Inc.",
        "type": "CUSTOMER",
        "correlation_id": "11143",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9210933c-b00f-4df6-96eb-b126116dfea7"
      },
      "parent_id": null,
      "lat": 34.055243,
      "lon": -84.171126,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a66d931f-5f75-4f78-b225-155fb04a14cf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Alcon Laboratories Inc.",
      "_label": "Alcon Laboratories Inc.",
      "_name": "John's Creek",
      "_lat": 34.055243,
      "_lng": -84.171126,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.171126
    },
    {
      "id": 2635,
      "name": "Jollyville (Austin)",
      "correlation_id": "101581",
      "billing_address": {
        "id": 770,
        "street1": "8900 Amberglen Blvd",
        "street2": "",
        "city": "Austin",
        "region": "Texas",
        "postal_code": "78729",
        "lat": 30.472599,
        "lon": -97.7669678,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 30.47264,
      "lon": -97.76696,
      "local_timezone": {
        "id": 2191,
        "code": "America/Fort_Wayne",
        "display_name": "America/Fort_Wayne",
        "description": "",
        "child_group": null,
        "alt_sequence": 57
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Jollyville (Austin)",
      "_lat": 30.47264,
      "_lng": -97.76696,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -97.76696
    },
    {
      "id": 1726,
      "name": "JONE00OVHD2",
      "correlation_id": "11356",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ed292fa8-75b0-4bd0-86f8-eb5551f38ffe",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE00OVHD2",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1727,
      "name": "JONE09OVHD",
      "correlation_id": "11357",
      "billing_address": {
        "id": 638,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b4059f01-469e-445d-a894-1b0778c59d1e",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE09OVHD",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1688,
      "name": "JONE11MRKSC",
      "correlation_id": "11316",
      "billing_address": {
        "id": 633,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3ae3970-b4d9-4412-9e4e-ff3d70116a67",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "JONE11MRKSC",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1728,
      "name": "JONE11OVHD",
      "correlation_id": "100847",
      "billing_address": {
        "id": 639,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "19fa1beb-120b-486b-8174-954fdcc6b09f",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE11OVHD",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 6015,
      "name": "JONE15OVDPW",
      "correlation_id": "102847",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "43da9898-8327-4ee4-92fa-fff662bc9e17",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE15OVDPW",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6026,
      "name": "JONE24OVRHD",
      "correlation_id": "102858",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2d636131-9259-4364-8f84-ccb75660ee37",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE24OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2591,
      "name": "JONE33OVHD",
      "correlation_id": "105531",
      "billing_address": null,
      "start_date": "2021-08-03T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4ea1f4cd-7983-4468-8f07-e519ee007406",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE33OVHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6005,
      "name": "JONE35OVRHD",
      "correlation_id": "102837",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d6d0b552-a6df-46d3-9d1f-c2fad5e53822",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE35OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6016,
      "name": "JONE95OVDPW",
      "correlation_id": "102848",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5c45267b-f110-4e06-acf8-cb849bd8f13e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "JONE95OVDPW",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2445,
      "name": "Jupiter, FL",
      "correlation_id": "101371",
      "billing_address": {
        "id": 274,
        "street1": "17900 Bee Line HWY",
        "street2": "",
        "city": "Jupitor",
        "region": "Florida",
        "postal_code": "33478",
        "lat": 26.9047849,
        "lon": -80.3040239,
        "country": "United States"
      },
      "start_date": "2021-03-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "14",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 438,
        "name": "Pratt & Whitney (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "101371",
        "addresses": [
          {
            "id": 273,
            "street1": "17900 Bee Line HWY",
            "street2": "",
            "city": "Jupitor",
            "region": "Florida",
            "postal_code": "33478",
            "lat": 26.9047849,
            "lon": -80.3040239,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "250a2c64-7e26-4b6d-8f36-167c56bcee3d"
      },
      "parent_id": null,
      "lat": 26.9047849,
      "lon": -80.3040239,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Pratt & Whitney (CBRE)",
      "_label": "Pratt & Whitney (CBRE)",
      "_name": "Jupiter, FL",
      "_lat": 26.9047849,
      "_lng": -80.3040239,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10673771,
          "create_date": "2024-02-15T17:15:48.453287Z",
          "update_date": "2024-02-15T17:15:48.453311Z",
          "organization_id": 438,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
          "size": 5217,
          "description": null,
          "link": null,
          "unique_identifier": "789a82307f294d5ea7c748b88c882565"
        }
      ],
      "lng": -80.3040239
    },
    {
      "id": 4224,
      "name": "Jurupua Valley*",
      "correlation_id": "10342",
      "billing_address": {
        "id": 2166,
        "street1": "10317 Birtcher Drive",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "91752",
        "lat": 34.0049853,
        "lon": -117.5119751,
        "country": "United States"
      },
      "start_date": "2022-07-13T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1647,
        "name": "EZ-GO",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "77a4d05d-93b1-4c2a-aced-b6508449c50b"
      },
      "parent_id": null,
      "lat": 34.0049853,
      "lon": -117.5119751,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2479e167-d16d-43e6-954e-20ba4016d0e6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "EZ-GO",
      "_label": "EZ-GO",
      "_name": "Jurupua Valley*",
      "_lat": 34.0049853,
      "_lng": -117.5119751,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.5119751
    },
    {
      "id": 2634,
      "name": "Kalamazoo, MI",
      "correlation_id": "101578",
      "billing_address": {
        "id": 2931,
        "street1": "5564 Portage Rd.",
        "street2": "",
        "city": "Portage",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2364493,
        "lon": -85.5636222,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 42.23648,
      "lon": -85.56364,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "791209fc-57ee-4271-bd07-90aa1e8978d8",
      "is_mobile": true,
      "mobile_live_date": "2022-12-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Kalamazoo, MI",
      "_lat": 42.23648,
      "_lng": -85.56364,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -85.56364
    },
    {
      "id": 2347,
      "name": "Kansas City (JLL)",
      "correlation_id": "101334",
      "billing_address": {
        "id": 134,
        "street1": "908 Broadway Blvd",
        "street2": "",
        "city": "Kansas",
        "region": "Missouri",
        "postal_code": "64105",
        "lat": 39.1033566,
        "lon": -94.5886021,
        "country": "Afghanistan"
      },
      "start_date": "2021-01-28T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "65706e72-1fb9-4162-9d8d-0c18b3e6b759",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Kansas City (JLL)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2701,
      "name": "Kasota",
      "correlation_id": "101858",
      "billing_address": null,
      "start_date": "2021-11-01T14:06:44Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 558,
        "name": "Computershare",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 823,
            "street1": "1015 SE Tenth ave",
            "street2": "",
            "city": "Minneapolis",
            "region": "MN",
            "postal_code": "55414",
            "lat": 44.9894065,
            "lon": -93.2388427,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "554c6a87-fed8-480b-b780-d1f1daf09278"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a146b1ca-c65e-4b0e-8915-46f07df39a91",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Computershare",
      "_label": "Computershare",
      "_name": "Kasota",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4831,
      "name": "KCAP - Kansas City Assembly Plant - Kansas City, MO",
      "correlation_id": "102238",
      "billing_address": {
        "id": 3000,
        "street1": "8121 US-69",
        "street2": "",
        "city": "Kansas City",
        "region": "Missouri",
        "postal_code": null,
        "lat": 39.2029665,
        "lon": -94.479492,
        "country": "United States"
      },
      "start_date": "2022-12-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": 5847,
      "lat": 39.2029665,
      "lon": -94.479492,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b97ea5fa-b029-4ad9-84b1-b13252eb78a6",
      "is_mobile": true,
      "mobile_live_date": "2022-12-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": "8164953660",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": true,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "KCAP - Kansas City Assembly Plant - Kansas City, MO",
      "_lat": 39.2029665,
      "_lng": -94.479492,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -94.479492
    },
    {
      "id": 1729,
      "name": "KCP - Kokomo Casting Plant - Kokomo, IN",
      "correlation_id": "101691",
      "billing_address": {
        "id": 919,
        "street1": "1001 E Blvd",
        "street2": "",
        "city": "Kokomo",
        "region": "Indiana",
        "postal_code": "46902",
        "lat": 40.4620423,
        "lon": -86.1166141,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 40.4620423,
      "lon": -86.1166141,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "154b181c-329b-485e-b243-24ec8fb41e62",
      "is_mobile": true,
      "mobile_live_date": "2022-03-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "KCP - Kokomo Casting Plant - Kokomo, IN",
      "_lat": 40.4620423,
      "_lng": -86.1166141,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -86.1166141
    },
    {
      "id": 1466,
      "name": "Kenilworth, NJ",
      "correlation_id": "10040",
      "billing_address": {
        "id": 514,
        "street1": "2000 Galloping Hill Rd",
        "street2": "",
        "city": "Kenilworth",
        "region": "New Jersey",
        "postal_code": "07033",
        "lat": 40.6774305,
        "lon": -74.2723554,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "45",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 12,
        "name": "Merck (Sodexo)",
        "type": "CUSTOMER",
        "correlation_id": "10006",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e0e3d27b-1f3a-466d-9ba4-2bfba90407e7"
      },
      "parent_id": null,
      "lat": 40.678682,
      "lon": -74.2726036,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "71c53ff5-4eb5-402c-89bb-131cfdb13aca",
      "is_mobile": true,
      "mobile_live_date": "2020-08-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck (Sodexo)",
      "_label": "Merck (Sodexo)",
      "_name": "Kenilworth, NJ",
      "_lat": 40.678682,
      "_lng": -74.2726036,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953516,
          "create_date": "2020-12-17T16:56:00.661396Z",
          "update_date": "2020-12-17T16:56:00.661452Z",
          "organization_id": 12,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224160.5192304_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.2726036
    },
    {
      "id": 4402,
      "name": "Kenner, LA*",
      "correlation_id": "102019",
      "billing_address": null,
      "start_date": "2022-08-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b99f0b6b-2b95-4539-bad9-0e21b3d4cd06",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "Kenner, LA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5914,
      "name": "Kenvue, PR",
      "correlation_id": "102821",
      "billing_address": null,
      "start_date": "2024-03-04T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.2112347,
      "lon": -65.9570028,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d5568901-1fd4-4042-a328-7bbd7fae8dae",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Kenvue, PR",
      "_lat": 18.2112347,
      "_lng": -65.9570028,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -65.9570028
    },
    {
      "id": 1950,
      "name": "KEP - Kokomo Engine Plant - Kokomo, IN",
      "correlation_id": "101688",
      "billing_address": {
        "id": 914,
        "street1": "3360 US-31",
        "street2": "",
        "city": "Kokomo",
        "region": "Indiana",
        "postal_code": "46901",
        "lat": 40.5477422,
        "lon": -86.12699,
        "country": "United States"
      },
      "start_date": "2022-01-29T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 40.5236986,
      "lon": -86.1196631,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d5ad34d5-cf6c-47c9-979c-c54c27fd9796",
      "is_mobile": true,
      "mobile_live_date": "2022-03-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "KEP - Kokomo Engine Plant - Kokomo, IN",
      "_lat": 40.5236986,
      "_lng": -86.1196631,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -86.1196631
    },
    {
      "id": 5494,
      "name": "King of Prussia, PA",
      "correlation_id": "102259",
      "billing_address": null,
      "start_date": "2022-12-13T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 40.0862176,
      "lon": -75.396177,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f373db9c-41dc-4c37-80c9-843ba272027b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "King of Prussia, PA",
      "_lat": 40.0862176,
      "_lng": -75.396177,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -75.396177
    },
    {
      "id": 1074,
      "name": "Kirkland, WA (JLL)",
      "correlation_id": "10856",
      "billing_address": {
        "id": 220,
        "street1": "747 6th Street, South Building C",
        "street2": "",
        "city": "South Kirkland",
        "region": "Washington",
        "postal_code": "98033",
        "lat": 47.6703445,
        "lon": -122.1971175,
        "country": "United States"
      },
      "start_date": "2017-04-03T23:59:59Z",
      "total_sq_ft": "543080",
      "total_buildings": "13",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 47.6702316,
      "lon": -122.197222,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cb3c8b12-c005-4224-a0a1-aacc98a8de55",
      "is_mobile": true,
      "mobile_live_date": "2020-08-27",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Kirkland, WA (JLL)",
      "_lat": 47.6702316,
      "_lng": -122.197222,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.197222
    },
    {
      "id": 2077,
      "name": "Kirkland, WA (Urban) (JLL)",
      "correlation_id": "11679",
      "billing_address": {
        "id": 368,
        "street1": "747 6th St. South Building C",
        "street2": "",
        "city": "Kirkland",
        "region": "Washington",
        "postal_code": "98033",
        "lat": 47.6703445,
        "lon": -122.1971175,
        "country": "United States"
      },
      "start_date": "2021-04-12T23:59:59Z",
      "total_sq_ft": "280000",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 47.669846,
      "lon": -122.1996099,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "051e9fee-de1e-4024-b1bf-2c57390f845d",
      "is_mobile": true,
      "mobile_live_date": "2021-06-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Kirkland, WA (Urban) (JLL)",
      "_lat": 47.669846,
      "_lng": -122.1996099,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.1996099
    },
    {
      "id": 6104,
      "name": "Kitchen Clean*",
      "correlation_id": "103360",
      "billing_address": null,
      "start_date": "2023-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2305,
        "name": "OTG*",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ba7deb94-813c-442f-bcf3-17489c26eb3c"
      },
      "parent_id": null,
      "lat": 40.77224,
      "lon": -73.87054,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c74b840e-e76a-43e4-85a3-e4ba91fcd08e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "OTG*",
      "_label": "OTG*",
      "_name": "Kitchen Clean*",
      "_lat": 40.77224,
      "_lng": -73.87054,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -73.87054
    },
    {
      "id": 1779,
      "name": "Kite El Segundo",
      "correlation_id": "11491",
      "billing_address": {
        "id": 455,
        "street1": "2355 Utah Ave",
        "street2": "",
        "city": "El Segundo",
        "region": "California",
        "postal_code": "90245",
        "lat": 33.9100655,
        "lon": -118.3811649,
        "country": "United States"
      },
      "start_date": "2019-03-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 34.0283343,
      "lon": -118.4655013,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d03cf84b-5ed1-45c1-bdd8-412010db489b",
      "is_mobile": true,
      "mobile_live_date": "2021-05-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite El Segundo",
      "_lat": 34.0283343,
      "_lng": -118.4655013,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -118.4655013
    },
    {
      "id": 2436,
      "name": "Kite Emeryville",
      "correlation_id": "101386",
      "billing_address": {
        "id": 257,
        "street1": "5828 Horton",
        "street2": "",
        "city": "Emeryville",
        "region": "California",
        "postal_code": "94608",
        "lat": 37.8397294,
        "lon": -122.2910629,
        "country": "United States"
      },
      "start_date": "2021-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a956d043-aa9c-42aa-9528-0b1cca5efd11",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite Emeryville",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2260,
      "name": "Kite Frederick",
      "correlation_id": "101245",
      "billing_address": {
        "id": 705,
        "street1": "9021 Bennett Creek Blvd",
        "street2": "",
        "city": "Frederick",
        "region": "Maryland",
        "postal_code": "21704",
        "lat": 39.3168964,
        "lon": -77.3497293,
        "country": "United States"
      },
      "start_date": "2020-05-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 39.31707,
      "lon": -77.34993,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2e8af058-2f30-4a0b-84a9-3219f6bd7830",
      "is_mobile": true,
      "mobile_live_date": "2021-08-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite Frederick",
      "_lat": 39.31707,
      "_lng": -77.34993,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -77.34993
    },
    {
      "id": 2129,
      "name": "Kite Oceanside",
      "correlation_id": "101222",
      "billing_address": {
        "id": 1176,
        "street1": "4010 Ocean Ranch Blvd",
        "street2": "",
        "city": "Oceanside",
        "region": "California",
        "postal_code": "92056",
        "lat": 33.2131385,
        "lon": -117.304166,
        "country": "United States"
      },
      "start_date": "2020-07-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d9af56f6-6635-4310-8d84-c26a965188ab",
      "is_mobile": true,
      "mobile_live_date": "2021-05-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite Oceanside",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1778,
      "name": "Kite Santa Monica",
      "correlation_id": "11489",
      "billing_address": {
        "id": 3751,
        "street1": "1800 Swetward St.",
        "street2": "",
        "city": "Santa Monica",
        "region": "California",
        "postal_code": null,
        "lat": 34.0284801,
        "lon": -118.4656355,
        "country": "United States"
      },
      "start_date": "2019-03-31T23:59:59Z",
      "total_sq_ft": "400000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 34.0283343,
      "lon": -118.4655013,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "91ba4f95-a983-419e-becb-3861c644a394",
      "is_mobile": true,
      "mobile_live_date": "2020-08-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite Santa Monica",
      "_lat": 34.0283343,
      "_lng": -118.4655013,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -118.4655013
    },
    {
      "id": 3126,
      "name": "Kite Santa Monica HQ",
      "correlation_id": "100937",
      "billing_address": {
        "id": 1275,
        "street1": "2400 Broadway",
        "street2": "",
        "city": "Santa Monica",
        "region": "California",
        "postal_code": "90404",
        "lat": 34.0297222,
        "lon": -118.4747693,
        "country": "United States"
      },
      "start_date": "2022-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 34.0297222,
      "lon": -118.4747693,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dc2d9b82-7a02-409a-8328-c3c6a1ac4178",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Kite Santa Monica HQ",
      "_lat": 34.0297222,
      "_lng": -118.4747693,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -118.4747693
    },
    {
      "id": 6220,
      "name": "KKB Singapore*",
      "correlation_id": "103078",
      "billing_address": null,
      "start_date": "2023-12-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 34,
        "name": "IBM (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10881",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c0657c07-3307-427f-a2af-5b1ec564f16c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db695cb9-9eb2-4d97-8a2c-0bf4b99b1cbe",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IBM (SG) (CBRE)",
      "_label": "IBM (SG) (CBRE)",
      "_name": "KKB Singapore*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1730,
      "name": "KTP - Kokomo Transmission Plant - Kokomo, IN",
      "correlation_id": "101692",
      "billing_address": {
        "id": 921,
        "street1": "2401 S Reed Rd",
        "street2": "",
        "city": "Kokomo",
        "region": "Indiana",
        "postal_code": "46902",
        "lat": 40.4576116,
        "lon": -86.1121301,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 40.4576116,
      "lon": -86.1121301,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1a6f6f56-1666-4caf-8f1f-898bca5f7d4f",
      "is_mobile": true,
      "mobile_live_date": "2022-03-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "KTP - Kokomo Transmission Plant - Kokomo, IN",
      "_lat": 40.4576116,
      "_lng": -86.1121301,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -86.1121301
    },
    {
      "id": 6797,
      "name": "Kulim*",
      "correlation_id": "103430",
      "billing_address": null,
      "start_date": "2024-02-19T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2635,
        "name": "Falcon (JLL) Malaysia",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d8eaf65a-f224-45c8-b04c-ec2a2c1a4817"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c23e65b4-024d-4336-a89e-96c518e24080",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Falcon (JLL) Malaysia",
      "_label": "Falcon (JLL) Malaysia",
      "_name": "Kulim*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1121,
      "name": "Kulim Malaysia",
      "correlation_id": "10946",
      "billing_address": null,
      "start_date": "2017-08-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 5.4228569,
      "lon": 100.58639,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "142cf06e-5cb5-4899-84ea-97b4467efc44",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Kulim Malaysia",
      "_lat": 5.4228569,
      "_lng": 100.58639,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 100.58639
    },
    {
      "id": 7029,
      "name": "Kulim Malaysia NC*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-23T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e289c05e-b2c7-4b45-8a25-9818caf7d94c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Kulim Malaysia NC*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2049,
      "name": "Lafayette, IN",
      "correlation_id": "11663",
      "billing_address": {
        "id": 433,
        "street1": "3000 East Main St.",
        "street2": "",
        "city": "Lafayette",
        "region": "Indiana",
        "postal_code": "47905",
        "lat": 40.4057604,
        "lon": -86.861634,
        "country": "United States"
      },
      "start_date": "2020-02-06T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 278,
        "name": "Wabash National",
        "type": "CUSTOMER",
        "correlation_id": "11662",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b90a004e-cbf7-496d-9092-352f1f08277c"
      },
      "parent_id": null,
      "lat": 40.3882479,
      "lon": -86.8639205,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "88e956ac-75a0-486a-af32-f95ebc7d286e",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wabash National",
      "_label": "Wabash National",
      "_name": "Lafayette, IN",
      "_lat": 40.3882479,
      "_lng": -86.8639205,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.8639205
    },
    {
      "id": 2590,
      "name": "Laguna South, WA",
      "correlation_id": "101532",
      "billing_address": {
        "id": 650,
        "street1": "14980 NE 31st St",
        "street2": "",
        "city": "Redmond",
        "region": "Washington",
        "postal_code": "98052",
        "lat": 47.638752,
        "lon": -122.1408995,
        "country": "United States"
      },
      "start_date": "2021-08-31T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "abb421e8-0707-42f0-b247-657048924721",
      "is_mobile": true,
      "mobile_live_date": "2022-05-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Laguna South, WA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2003,
      "name": "La Jolla, CA",
      "correlation_id": "11650",
      "billing_address": {
        "id": 481,
        "street1": "10675 John Jay Hopkins Dr.",
        "street2": "",
        "city": "San Diego",
        "region": "California",
        "postal_code": "92121",
        "lat": 32.8966561,
        "lon": -117.2386756,
        "country": "United States"
      },
      "start_date": "2020-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 270,
        "name": "Novartis (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
      },
      "parent_id": null,
      "lat": 32.8961194,
      "lon": -117.2395112,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "01acf3a0-42d1-427a-902d-0086a8c92120",
      "is_mobile": true,
      "mobile_live_date": "2021-03-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis (CBRE)",
      "_label": "Novartis (CBRE)",
      "_name": "La Jolla, CA",
      "_lat": 32.8961194,
      "_lng": -117.2395112,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859846,
          "create_date": "2020-11-17T14:36:58.595850Z",
          "update_date": "2020-11-17T14:36:58.595883Z",
          "organization_id": 270,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
          "size": 7078,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.2395112
    },
    {
      "id": 4391,
      "name": "LA LIMA",
      "correlation_id": "102016",
      "billing_address": null,
      "start_date": "2022-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2042,
        "name": "GARNIER (CR)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3f99c018-4950-4fc2-a485-9dcf9f3a9250"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9f62900d-a04c-4052-ab73-e91984a2cd3c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GARNIER (CR)",
      "_label": "GARNIER (CR)",
      "_name": "LA LIMA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5386,
      "name": "La Lima, Cartago",
      "correlation_id": "102243",
      "billing_address": null,
      "start_date": "2022-11-16T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2096,
        "name": "GENER 8 (CR)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b34bc4ac-04e9-4069-b7ca-ab0d754164ad"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e07a11ac-b500-400f-ba00-ff3f9b51d184",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GENER 8 (CR)",
      "_label": "GENER 8 (CR)",
      "_name": "La Lima, Cartago",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5531,
      "name": "Las Vegas Operations Center (JLL)",
      "correlation_id": "102348",
      "billing_address": null,
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 36.1437844,
      "lon": -115.0976588,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "59a1084c-5eb2-48ef-bd4b-151f59a727df",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Las Vegas Operations Center (JLL)",
      "_lat": 36.1437844,
      "_lng": -115.0976588,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -115.0976588
    },
    {
      "id": 3432,
      "name": "LaVergne",
      "correlation_id": "102064",
      "billing_address": {
        "id": 1539,
        "street1": "437 New Sanford Rd",
        "street2": "",
        "city": null,
        "region": "Tennessee",
        "postal_code": "37086",
        "lat": 35.9838216,
        "lon": -86.5731939,
        "country": "United States"
      },
      "start_date": "2022-05-02T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 803,
        "name": "Vantiva (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "72641938-f560-4c4f-aebc-c1fbab13ecc8"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0782cfc4-1138-4ee2-9a49-a1adfc897e25",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Vantiva (CBRE)",
      "_label": "Vantiva (CBRE)",
      "_name": "LaVergne",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10673747,
          "create_date": "2024-02-15T17:14:54.094236Z",
          "update_date": "2024-02-15T17:14:54.094260Z",
          "organization_id": 803,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
          "size": 9568,
          "description": null,
          "link": null,
          "unique_identifier": "75e64d0f01e5470495cc79f21e44e8e0"
        }
      ],
      "lng": 0
    },
    {
      "id": 1769,
      "name": "La Verne",
      "correlation_id": "11476",
      "billing_address": {
        "id": 450,
        "street1": "1800 Wheeler Ave",
        "street2": "",
        "city": "La Verne",
        "region": "California",
        "postal_code": "91750",
        "lat": 34.0957298,
        "lon": -117.7825942,
        "country": "United States"
      },
      "start_date": "2021-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 33.2078462,
      "lon": -117.2953202,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dd257017-2e59-4a9a-b4e0-51f02b9105a3",
      "is_mobile": true,
      "mobile_live_date": "2021-05-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "La Verne",
      "_lat": 33.2078462,
      "_lng": -117.2953202,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.2953202
    },
    {
      "id": 1250,
      "name": "Lawrenceville",
      "correlation_id": "10211",
      "billing_address": {
        "id": 95,
        "street1": "3551 LAWRENCEVILLE ROAD",
        "street2": "",
        "city": "Princeton",
        "region": "New Jersey",
        "postal_code": "08540",
        "lat": 40.3215373,
        "lon": -74.7063674,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 40.356269,
      "lon": -74.6698911,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "40ad4744-6bc3-4d67-ade9-c36bd07957ab",
      "is_mobile": true,
      "mobile_live_date": "2020-12-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Lawrenceville",
      "_lat": 40.356269,
      "_lng": -74.6698911,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.6698911
    },
    {
      "id": 5767,
      "name": "LBE1– New Stanton, PA",
      "correlation_id": "102611",
      "billing_address": {
        "id": 4076,
        "street1": "West Side Siebe Dr",
        "street2": "",
        "city": "New Stanton",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.2316055,
        "lon": -79.6195987,
        "country": "United States"
      },
      "start_date": "2023-10-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 40.22159,
      "lon": -79.61171,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2175f6c0-da91-480e-acd5-68c86727ccd2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "LBE1– New Stanton, PA",
      "_lat": 40.22159,
      "_lng": -79.61171,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -79.61171
    },
    {
      "id": 5827,
      "name": "Legacy West*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-08-19T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1975,
        "name": "JPMC (Hines)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1ca54ed0-ee17-41df-af09-01f5733b560a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "45aae701-1df1-40ae-9985-7b6e7c48a2f5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "JPMC (Hines)",
      "_label": "JPMC (Hines)",
      "_name": "Legacy West*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10588869,
          "create_date": "2024-02-09T20:47:21.966490Z",
          "update_date": "2024-02-09T20:47:21.966516Z",
          "organization_id": 1975,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/076132eadcb34f15a3543846b4b3e835_13_t.png",
          "size": 2737,
          "description": null,
          "link": null,
          "unique_identifier": "076132eadcb34f15a3543846b4b3e835"
        }
      ],
      "lng": 0
    },
    {
      "id": 5837,
      "name": "LEP - Lima Engine Plant - Lima, OH",
      "correlation_id": "102753",
      "billing_address": {
        "id": 3796,
        "street1": "1155 Bible Rd.",
        "street2": "",
        "city": "Lima",
        "region": "Ohio",
        "postal_code": null,
        "lat": 40.7777452,
        "lon": -84.0861034,
        "country": "United States"
      },
      "start_date": "2023-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 40.77412,
      "lon": -84.08474,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
      "is_mobile": true,
      "mobile_live_date": "2023-10-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "LEP - Lima Engine Plant - Lima, OH",
      "_lat": 40.77412,
      "_lng": -84.08474,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -84.08474
    },
    {
      "id": 1446,
      "name": "Lewisville, TX",
      "correlation_id": "10564",
      "billing_address": null,
      "start_date": "2011-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 128,
        "name": "Texas Instruments",
        "type": "CUSTOMER",
        "correlation_id": "10563",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "765abe2b-45f3-4692-84a5-d49c3f222474"
      },
      "parent_id": null,
      "lat": 33.0005567,
      "lon": -96.9965247,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "75c664af-88a8-4975-a2de-6b73aaaead59",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Texas Instruments",
      "_label": "Texas Instruments",
      "_name": "Lewisville, TX",
      "_lat": 33.0005567,
      "_lng": -96.9965247,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10771782,
          "create_date": "2024-02-21T22:23:26.150249Z",
          "update_date": "2024-02-21T22:23:26.150274Z",
          "organization_id": 128,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a2414f8871f946d6afe1dae7aca6dd25_TI4insite_t.png",
          "size": 1215313,
          "description": null,
          "link": null,
          "unique_identifier": "a2414f8871f946d6afe1dae7aca6dd25"
        }
      ],
      "lng": -96.9965247
    },
    {
      "id": 5879,
      "name": "Lexington Columbia Construction*",
      "correlation_id": "102767",
      "billing_address": null,
      "start_date": "2023-09-25T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 42.42739,
      "lon": -71.25175,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b85cdcfb-d2fc-48d2-87c2-361b88590586",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Lexington Columbia Construction*",
      "_lat": 42.42739,
      "_lng": -71.25175,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.25175
    },
    {
      "id": 3398,
      "name": "Lexington, MA",
      "correlation_id": "101955",
      "billing_address": {
        "id": 2660,
        "street1": "200 Shire Way",
        "street2": "",
        "city": "Lexington",
        "region": "Massachusetts",
        "postal_code": "02421",
        "lat": 42.426388,
        "lon": -71.2516907,
        "country": "United States"
      },
      "start_date": "2022-07-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "17",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9"
      },
      "parent_id": null,
      "lat": 42.426528,
      "lon": -71.25170203,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
      "is_mobile": true,
      "mobile_live_date": "2023-01-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Takeda (CBRE)",
      "_label": "Takeda (CBRE)",
      "_name": "Lexington, MA",
      "_lat": 42.426528,
      "_lng": -71.25170203,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859832,
          "create_date": "2020-11-17T14:35:11.969745Z",
          "update_date": "2020-11-17T14:35:11.969770Z",
          "organization_id": 180,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623711.8547332_ogp_logo_t.jpg",
          "size": 5626,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -71.25170203
    },
    {
      "id": 3002,
      "name": "LGA-LaGuardia, NY",
      "correlation_id": "101854",
      "billing_address": {
        "id": 1142,
        "street1": "1 CENTRAL TERMINAL DR",
        "street2": "",
        "city": "Flushing",
        "region": "New York",
        "postal_code": "11371-1002",
        "lat": 40.7745558,
        "lon": -73.872763,
        "country": "United States"
      },
      "start_date": "2022-02-14T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "16",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 40.7745558,
      "lon": -73.872763,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "542643b2-4404-4348-b912-91e2f68cfb9f",
      "is_mobile": true,
      "mobile_live_date": "2022-03-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "LGA-LaGuardia, NY",
      "_lat": 40.7745558,
      "_lng": -73.872763,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -73.872763
    },
    {
      "id": 5713,
      "name": "Libertyville",
      "correlation_id": "102579",
      "billing_address": {
        "id": 4145,
        "street1": "1950 Innovation Dr.",
        "street2": "",
        "city": "Libertyville",
        "region": "Illinois",
        "postal_code": null,
        "lat": 42.2830786,
        "lon": -87.9531303,
        "country": "United States"
      },
      "start_date": "2023-08-13T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 42.28858,
      "lon": -88.00049,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f51165a-be54-4669-852a-7b14912efa3f",
      "is_mobile": true,
      "mobile_live_date": "2024-02-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Libertyville",
      "_lat": 42.28858,
      "_lng": -88.00049,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -88.00049
    },
    {
      "id": 5829,
      "name": "LIFE00VORHD",
      "correlation_id": "102750",
      "billing_address": null,
      "start_date": "2023-08-23T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9e65ef1c-d29c-4b56-bf31-0d114387b2dc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "LIFE00VORHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1068,
      "name": "Lincoln",
      "correlation_id": "10848",
      "billing_address": null,
      "start_date": "2016-07-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 25,
        "name": "Zoetis",
        "type": "CUSTOMER",
        "correlation_id": "10847",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ea4d6ed7-b2bc-419d-bf29-2bc14d32cf15"
      },
      "parent_id": null,
      "lat": 40.8335039,
      "lon": -96.7297993,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "746d1e2d-1e39-4069-aae9-3ea1b389322e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Zoetis",
      "_label": "Zoetis",
      "_name": "Lincoln",
      "_lat": 40.8335039,
      "_lng": -96.7297993,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.7297993
    },
    {
      "id": 1289,
      "name": "Lincoln",
      "correlation_id": "11593",
      "billing_address": {
        "id": 3749,
        "street1": "10401 Cornhusker HWY.",
        "street2": "",
        "city": "Lincoln",
        "region": "Nebraska",
        "postal_code": null,
        "lat": 40.8888407,
        "lon": -96.5779007,
        "country": "United States"
      },
      "start_date": "2017-02-16T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 476,
        "name": "GSK",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "22f0c0aa-30a1-4e6c-9cf9-8c74f7bd6b79"
      },
      "parent_id": null,
      "lat": 40.8895385,
      "lon": -96.5791216,
      "local_timezone": {
        "id": 2238,
        "code": "America/Monterrey",
        "display_name": "America/Monterrey",
        "description": "",
        "child_group": null,
        "alt_sequence": 104
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ef94d2db-9d33-4b39-a4b4-1f894a5616ca",
      "is_mobile": true,
      "mobile_live_date": "2020-08-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GSK",
      "_label": "GSK",
      "_name": "Lincoln",
      "_lat": 40.8895385,
      "_lng": -96.5791216,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.5791216
    },
    {
      "id": 2643,
      "name": "Lincoln South",
      "correlation_id": "101590",
      "billing_address": {
        "id": 778,
        "street1": "500 S. 84th st",
        "street2": "",
        "city": "Lincoln",
        "region": "Nebraska",
        "postal_code": "68510",
        "lat": 40.8092744,
        "lon": -96.6037851,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 40.80939,
      "lon": -96.60344,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Lincoln South",
      "_lat": 40.80939,
      "_lng": -96.60344,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -96.60344
    },
    {
      "id": 2148,
      "name": "Lincoln Square 2",
      "correlation_id": "101307",
      "billing_address": {
        "id": 81,
        "street1": "700 Bellevue Way NE  Floor 22",
        "street2": "",
        "city": "Bellevue",
        "region": "Washington",
        "postal_code": "98004",
        "lat": 47.6164098,
        "lon": -122.2012735,
        "country": "United States"
      },
      "start_date": "2017-12-06T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 47.6062095,
      "lon": -122.3320708,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0158ddd5-982a-4899-adcf-d8bf368c44ee",
      "is_mobile": true,
      "mobile_live_date": "2022-05-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Lincoln Square 2",
      "_lat": 47.6062095,
      "_lng": -122.3320708,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.3320708
    },
    {
      "id": 3531,
      "name": "Linthicum",
      "correlation_id": "100365",
      "billing_address": {
        "id": 3770,
        "street1": "709 Digital Dr.  MD 21090",
        "street2": "",
        "city": "Linthicum Heights",
        "region": "Maryland",
        "postal_code": null,
        "lat": 39.218549,
        "lon": -76.6727641,
        "country": "United States"
      },
      "start_date": "2016-04-01T17:39:51Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 225,
        "name": "T. Rowe Price",
        "type": "CUSTOMER",
        "correlation_id": "10645",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "51af8ac6-48c2-4774-b844-4fdd26e2d6ea"
      },
      "parent_id": null,
      "lat": 39.21863,
      "lon": -76.67398,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8b30eac4-5c74-40d5-a1e4-0241ff54a5ad",
      "is_mobile": true,
      "mobile_live_date": "2023-08-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "T. Rowe Price",
      "_label": "T. Rowe Price",
      "_name": "Linthicum",
      "_lat": 39.21863,
      "_lng": -76.67398,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10769945,
          "create_date": "2024-02-21T20:47:06.306144Z",
          "update_date": "2024-02-21T20:47:06.306170Z",
          "organization_id": 225,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/464bbb059be54218b17cd131d05a3116_trowelogo_t.png",
          "size": 2901,
          "description": null,
          "link": null,
          "unique_identifier": "464bbb059be54218b17cd131d05a3116"
        }
      ],
      "lng": -76.67398
    },
    {
      "id": 2466,
      "name": "Lititz, PA",
      "correlation_id": "101430",
      "billing_address": {
        "id": 304,
        "street1": "200 CHOCOLATE AVENUE",
        "street2": "",
        "city": "Mount Joy",
        "region": "Pennsylvania",
        "postal_code": "17552",
        "lat": 40.1119,
        "lon": -76.5198066,
        "country": "United States"
      },
      "start_date": "2021-04-21T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 443,
        "name": "Cargill",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 303,
            "street1": "200 CHOCOLATE AVENUE",
            "street2": "",
            "city": "Mount Joy",
            "region": "Pennsylvania",
            "postal_code": "17552",
            "lat": 40.1119,
            "lon": -76.5198066,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "cda36f33-0892-4c9a-82f3-0ba92e1349bf"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "da82d60e-d75c-4f54-87fa-00dd9f64548a",
      "is_mobile": true,
      "mobile_live_date": "2021-11-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cargill",
      "_label": "Cargill",
      "_name": "Lititz, PA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3926,
      "name": "Little Chute, WI - USA",
      "correlation_id": "102088",
      "billing_address": {
        "id": 1901,
        "street1": "401 W North Ave",
        "street2": "",
        "city": "Little Chute",
        "region": "Wisconsin",
        "postal_code": "54140",
        "lat": 44.2856516,
        "lon": -88.3252595,
        "country": "United States"
      },
      "start_date": "2022-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 721,
        "name": "Nestle",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "67b06b43-0a3d-4590-afcd-81b959e1ae38"
      },
      "parent_id": null,
      "lat": 44.48194,
      "lon": -88.38019,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "be76621f-ddbb-4b45-aeb2-ddf4c9d726bf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Nestle",
      "_label": "Nestle",
      "_name": "Little Chute, WI - USA",
      "_lat": 44.48194,
      "_lng": -88.38019,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025316,
          "create_date": "2024-03-07T17:48:17.883086Z",
          "update_date": "2024-03-07T17:48:17.883117Z",
          "organization_id": 721,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/0b5a3f66eb9a4fd7869d0bc1a506b8cd_0_FKwIAXSv8sFEWPmL_t.png",
          "size": 233845,
          "description": null,
          "link": null,
          "unique_identifier": "0b5a3f66eb9a4fd7869d0bc1a506b8cd"
        }
      ],
      "lng": -88.38019
    },
    {
      "id": 1174,
      "name": "Littleton",
      "correlation_id": "10106",
      "billing_address": {
        "id": 136,
        "street1": "12257 S. Wadsworth Blvd.",
        "street2": "",
        "city": "Littleton",
        "region": "Colorado",
        "postal_code": "80120",
        "lat": 39.4942002,
        "lon": -105.0968209,
        "country": "United States"
      },
      "start_date": "2015-12-01T23:59:59Z",
      "total_sq_ft": "2969515",
      "total_buildings": "65",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 39.49420019,
      "lon": -105.0968209,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c4412864-9bd4-4ec1-834b-180b9f07d445",
      "is_mobile": true,
      "mobile_live_date": "2021-01-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": "7206778477",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": true,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "Littleton",
      "_lat": 39.49420019,
      "_lng": -105.0968209,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -105.0968209
    },
    {
      "id": 5974,
      "name": "Livermore",
      "correlation_id": "102869",
      "billing_address": {
        "id": 4097,
        "street1": "1 Portola Ave.",
        "street2": "",
        "city": "Livermore",
        "region": "California",
        "postal_code": null,
        "lat": 37.6908828,
        "lon": -121.7525311,
        "country": "United States"
      },
      "start_date": "2024-01-30T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2240,
        "name": "Lam Research (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1e7b2470-0302-45b1-b34a-667694a5b5eb"
      },
      "parent_id": null,
      "lat": 37.70478,
      "lon": -121.80538,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
      "is_mobile": true,
      "mobile_live_date": "2024-01-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lam Research (CBRE)",
      "_label": "Lam Research (CBRE)",
      "_name": "Livermore",
      "_lat": 37.70478,
      "_lng": -121.80538,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10767784,
          "create_date": "2024-02-21T19:11:40.419335Z",
          "update_date": "2024-02-21T19:11:40.419360Z",
          "organization_id": 2240,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
          "size": 1275960,
          "description": null,
          "link": null,
          "unique_identifier": "ee68f0369eec4944829b45f1ba8b5011"
        }
      ],
      "lng": -121.80538
    },
    {
      "id": 5534,
      "name": "Lodi Vineyard (JLL)",
      "correlation_id": "102352",
      "billing_address": {
        "id": 3053,
        "street1": "702 S Ham Ln",
        "street2": "",
        "city": "Lodi Vineyard",
        "region": "California",
        "postal_code": null,
        "lat": 38.1260401,
        "lon": -121.2880258,
        "country": "United States"
      },
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 38.1260401,
      "lon": -121.2880258,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4ef63fbd-7124-4bfb-81e5-0eb8c16bcabb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Lodi Vineyard (JLL)",
      "_lat": 38.1260401,
      "_lng": -121.2880258,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -121.2880258
    },
    {
      "id": 1850,
      "name": "Lone Tree",
      "correlation_id": "102170",
      "billing_address": {
        "id": 423,
        "street1": "9899 Schwab Way",
        "street2": "",
        "city": "Lone Tree",
        "region": "Colorado",
        "postal_code": "80124",
        "lat": 39.5337903,
        "lon": -104.8753806,
        "country": "United States"
      },
      "start_date": "2015-10-07T23:59:59Z",
      "total_sq_ft": "1995897",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 39.5346578,
      "lon": -104.875587,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7495e22a-5cb6-414c-9095-c5757849bd23",
      "is_mobile": true,
      "mobile_live_date": "2021-05-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Lone Tree",
      "_lat": 39.5346578,
      "_lng": -104.875587,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -104.875587
    },
    {
      "id": 4429,
      "name": "Long Beach, CA*",
      "correlation_id": "101537",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 474,
        "name": "Mercedes Benz*",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 658,
            "street1": "3953 Research Park DR",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48108",
            "lat": 42.2325395,
            "lon": -83.7312863,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "ed2274bd-8d09-4e81-a765-5d290ae3267d"
      },
      "parent_id": null,
      "lat": 33.8107792,
      "lon": -118.1639832,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8f26f510-4ab9-4ad8-b5e7-378a5d595018",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Mercedes Benz*",
      "_label": "Mercedes Benz*",
      "_name": "Long Beach, CA*",
      "_lat": 33.8107792,
      "_lng": -118.1639832,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -118.1639832
    },
    {
      "id": 2647,
      "name": "Longmont, CO",
      "correlation_id": "101603",
      "billing_address": {
        "id": 1110,
        "street1": "1900 Pike Rd",
        "street2": "",
        "city": "Longmont",
        "region": "Colorado",
        "postal_code": "80501",
        "lat": 40.1389444,
        "lon": -105.1246164,
        "country": "United States"
      },
      "start_date": "2021-11-01T23:59:59Z",
      "total_sq_ft": "69413",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 493,
        "name": "Micron (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f8922e27-f57a-4029-8bc3-609db025f265"
      },
      "parent_id": null,
      "lat": 40.1389444,
      "lon": -105.1246164,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a3827c1e-4d65-4c5a-acbc-ce8b69084aba",
      "is_mobile": true,
      "mobile_live_date": "2022-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron (C&W)",
      "_label": "Micron (C&W)",
      "_name": "Longmont, CO",
      "_lat": 40.1389444,
      "_lng": -105.1246164,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -105.1246164
    },
    {
      "id": 4417,
      "name": "Longmont, CO",
      "correlation_id": "102032",
      "billing_address": null,
      "start_date": "2022-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1783,
        "name": "SOLIDIGM (JLL)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b1312a0e-4299-4daa-92f1-1c63f8ad0de0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2182,
        "code": "America/Dawson_Creek",
        "display_name": "America/Dawson_Creek",
        "description": "",
        "child_group": null,
        "alt_sequence": 48
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "29e187b3-0932-4712-a821-76ae24f96871",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SOLIDIGM (JLL)",
      "_label": "SOLIDIGM (JLL)",
      "_name": "Longmont, CO",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4524,
      "name": "LOS ANGELES, CA",
      "correlation_id": "102153",
      "billing_address": null,
      "start_date": "2022-08-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1843,
        "name": "CAREMORE-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c9f23ade-e1a9-44fb-9e46-9c1145504975"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e6e8438f-2f64-460f-bcea-a53fc0b604de",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CAREMORE-Sub",
      "_label": "CAREMORE-Sub",
      "_name": "LOS ANGELES, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5656,
      "name": "Los Angeles Cash Vault (JLL)",
      "correlation_id": "102407",
      "billing_address": null,
      "start_date": "2023-03-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 34.0534675,
      "lon": -118.2530973,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "10297d83-39ff-44d2-8463-246944bce406",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Los Angeles Cash Vault (JLL)",
      "_lat": 34.0534675,
      "_lng": -118.2530973,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -118.2530973
    },
    {
      "id": 5707,
      "name": "Loves Park, IL",
      "correlation_id": "102573",
      "billing_address": {
        "id": 3617,
        "street1": "4401 N Bell School Rd.",
        "street2": "",
        "city": "Loves Park",
        "region": "Illinois",
        "postal_code": null,
        "lat": 42.3254059,
        "lon": -88.966968,
        "country": "United States"
      },
      "start_date": "2023-05-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2077,
        "name": "Amentum Danfoss Power Solutions",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 2702,
            "street1": "2800 E 13th St.",
            "street2": "",
            "city": "Ames",
            "region": "Iowa",
            "postal_code": "50010",
            "lat": 42.0323055,
            "lon": -93.5733607,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "146ba0cf-b9bd-45ef-be7c-64ad3b2e4568"
      },
      "parent_id": null,
      "lat": 42.328593,
      "lon": -88.968573,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "62216cd3-be57-4bbb-8d0b-3ca1d75d2a4a",
      "is_mobile": true,
      "mobile_live_date": "2023-07-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amentum Danfoss Power Solutions",
      "_label": "Amentum Danfoss Power Solutions",
      "_name": "Loves Park, IL",
      "_lat": 42.328593,
      "_lng": -88.968573,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686598,
          "create_date": "2024-02-16T12:11:27.224321Z",
          "update_date": "2024-02-16T12:11:27.224348Z",
          "organization_id": 2077,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/36e9d56bb0ab4530981389a811066e0e_AmentumIconFullNameGreen_t.png",
          "size": 9669,
          "description": null,
          "link": null,
          "unique_identifier": "36e9d56bb0ab4530981389a811066e0e"
        }
      ],
      "lng": -88.968573
    },
    {
      "id": 2116,
      "name": "Loves Park, IL - Aviation Systems",
      "correlation_id": "11727",
      "billing_address": {
        "id": 286,
        "street1": "1354 Clifford Ave",
        "street2": "",
        "city": "Love Park",
        "region": "Illinois",
        "postal_code": "61111",
        "lat": 42.3191079,
        "lon": -89.0412278,
        "country": "United States"
      },
      "start_date": "2020-03-23T00:00:00Z",
      "total_sq_ft": "10000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 42.3191079,
      "lon": -89.0412278,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "29cdbbd9-e85f-4ef0-9524-2e5191317729",
      "is_mobile": true,
      "mobile_live_date": "2021-03-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "Loves Park, IL - Aviation Systems",
      "_lat": 42.3191079,
      "_lng": -89.0412278,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -89.0412278
    },
    {
      "id": 6684,
      "name": "LTP – Livonia Transmission Plant – Wayne, MI",
      "correlation_id": "103373",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.37114,
      "lon": -83.39166,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "da632665-87df-4e1a-8ccf-6819d4038f95",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "LTP – Livonia Transmission Plant – Wayne, MI",
      "_lat": 42.37114,
      "_lng": -83.39166,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.39166
    },
    {
      "id": 1368,
      "name": "MACK - Detroit Assembly Complex Mack - Detroit, MI",
      "correlation_id": "101698",
      "billing_address": {
        "id": 880,
        "street1": "11570 E Warren Ave",
        "street2": "",
        "city": "Detorit",
        "region": "Michigan",
        "postal_code": "48214",
        "lat": 42.3844049,
        "lon": -82.9774689,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "29",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.3743239,
      "lon": -82.9661858,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "85b4ff0b-70d3-4d3e-bee2-6dc397f75d47",
      "is_mobile": true,
      "mobile_live_date": "2022-04-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "MACK - Detroit Assembly Complex Mack - Detroit, MI",
      "_lat": 42.3743239,
      "_lng": -82.9661858,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -82.9661858
    },
    {
      "id": 1956,
      "name": "Madison, NJ",
      "correlation_id": "11432",
      "billing_address": {
        "id": 313,
        "street1": "5 Giralda Farms, Dodge Drive",
        "street2": "",
        "city": "Madison",
        "region": "New Jersey",
        "postal_code": "07940",
        "lat": 40.7636689,
        "lon": -74.4375951,
        "country": "United States"
      },
      "start_date": "2019-01-14T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 75,
        "name": "AbbVie",
        "type": "CUSTOMER",
        "correlation_id": "10135",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
      },
      "parent_id": null,
      "lat": 40.7636689,
      "lon": -74.4375951,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
      "is_mobile": true,
      "mobile_live_date": "2021-12-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AbbVie",
      "_label": "AbbVie",
      "_name": "Madison, NJ",
      "_lat": 40.7636689,
      "_lng": -74.4375951,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 5630176,
          "create_date": "2023-01-27T16:07:24.909099Z",
          "update_date": "2023-01-27T16:07:24.909120Z",
          "organization_id": 75,
          "uploaded_by_id": 43918,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
          "size": 5975,
          "description": null,
          "link": null,
          "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
        }
      ],
      "lng": -74.4375951
    },
    {
      "id": 1506,
      "name": "Madison-Waltz Mill",
      "correlation_id": "10582",
      "billing_address": {
        "id": 683,
        "street1": "680 Waltz Mill Rd, Ruffs Dale, PA 15679",
        "street2": "",
        "city": "Ruffs Dale",
        "region": "Pennsylvania",
        "postal_code": "15679",
        "lat": 40.2174525,
        "lon": -79.6622708,
        "country": "United States"
      },
      "start_date": "2016-04-16T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 40.2153124,
      "lon": -79.6639291,
      "local_timezone": {
        "id": 2438,
        "code": "Africa/Abidjan",
        "display_name": "Africa/Abidjan",
        "description": "",
        "child_group": null,
        "alt_sequence": 304
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2d4867b2-7e72-410c-b01e-addd1c330304",
      "is_mobile": true,
      "mobile_live_date": "2021-08-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Madison-Waltz Mill",
      "_lat": 40.2153124,
      "_lng": -79.6639291,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -79.6639291
    },
    {
      "id": 5525,
      "name": "Main Street Promenade, CA (JLL)",
      "correlation_id": "102334",
      "billing_address": null,
      "start_date": "2022-12-21T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 36.3304428,
      "lon": -119.2887646,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "860e989c-2592-4c65-b3d3-4d50a563ec93",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Main Street Promenade, CA (JLL)",
      "_lat": 36.3304428,
      "_lng": -119.2887646,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -119.2887646
    },
    {
      "id": 1649,
      "name": "Malaysia",
      "correlation_id": "11222",
      "billing_address": null,
      "start_date": "2018-06-21T18:00:00Z",
      "total_sq_ft": "1488000",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 182,
        "name": "IDT",
        "type": "CUSTOMER",
        "correlation_id": "11221",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4e4f7f5d-7b02-42db-a501-3ab04e73026c"
      },
      "parent_id": null,
      "lat": 4.210484,
      "lon": 101.975766,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "46681aaa-e73f-4742-8de2-9946663c16cc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IDT",
      "_label": "IDT",
      "_name": "Malaysia",
      "_lat": 4.210484,
      "_lng": 101.975766,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 101.975766
    },
    {
      "id": 4437,
      "name": "Malaysia",
      "correlation_id": "102041",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1788,
        "name": "Risen Solar Technology",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f06d477e-c8b6-4502-ba5b-95acf46da589"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ac66e39a-39bf-4012-91fc-630708036db6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Risen Solar Technology",
      "_label": "Risen Solar Technology",
      "_name": "Malaysia",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5943,
      "name": "Malaysia*",
      "correlation_id": "102836",
      "billing_address": null,
      "start_date": "2023-11-17T16:45:53Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2206,
        "name": "Pelican (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9f739f2d-cac3-42d4-819f-88e9db953bf3"
      },
      "parent_id": null,
      "lat": 5.3283593,
      "lon": 100.3006611,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cb152421-068f-4f5f-990f-1d0b81cb2229",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Pelican (JLL)",
      "_label": "Pelican (JLL)",
      "_name": "Malaysia*",
      "_lat": 5.3283593,
      "_lng": 100.3006611,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 100.3006611
    },
    {
      "id": 2624,
      "name": "Malta, NY",
      "correlation_id": "101563",
      "billing_address": null,
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 43.0096102,
      "lon": -73.870832,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eb1902d2-9a86-4850-bb32-1404cefdba7d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Malta, NY",
      "_lat": 43.0096102,
      "_lng": -73.870832,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -73.870832
    },
    {
      "id": 1774,
      "name": "Malvern",
      "correlation_id": "11483",
      "billing_address": null,
      "start_date": "2019-03-09T00:00:00Z",
      "total_sq_ft": "689084",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 211,
        "name": "Oracle Cerner (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11480",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "76aad52e-98ea-4707-b8d5-ecc9354099b0"
      },
      "parent_id": null,
      "lat": 41.2033,
      "lon": -77.1945,
      "local_timezone": {
        "id": 2205,
        "code": "America/Indiana/Knox",
        "display_name": "America/Indiana/Knox",
        "description": "",
        "child_group": null,
        "alt_sequence": 71
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b4c61c6d-a4c7-405d-8749-ef67f7e6e8a2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Oracle Cerner (JLL)",
      "_label": "Oracle Cerner (JLL)",
      "_name": "Malvern",
      "_lat": 77.1945,
      "_lng": 41.2033,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 41.2033
    },
    {
      "id": 1906,
      "name": "Malvern",
      "correlation_id": "10772",
      "billing_address": null,
      "start_date": "2017-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 240,
        "name": "Endo Pharmaceuticals Inc",
        "type": "CUSTOMER",
        "correlation_id": "10729",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e97c2c1e-b909-4a13-81f0-83daca059712"
      },
      "parent_id": null,
      "lat": 40.0711188,
      "lon": -75.5292892,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "69a1cc39-606e-40b8-aaa1-fc538f727ed0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Endo Pharmaceuticals Inc",
      "_label": "Endo Pharmaceuticals Inc",
      "_name": "Malvern",
      "_lat": 40.0711188,
      "_lng": -75.5292892,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -75.5292892
    },
    {
      "id": 1255,
      "name": "Manati",
      "correlation_id": "10216",
      "billing_address": {
        "id": 193,
        "street1": "Manati  Carr 686 Km 2.3 Cotto Norte, Manatí, PR 00674",
        "street2": "",
        "city": "Manati",
        "region": "Florida",
        "postal_code": "00674",
        "lat": 27.6648274,
        "lon": -81.5157535,
        "country": "United States"
      },
      "start_date": "2021-02-18T23:59:59Z",
      "total_sq_ft": "2300000",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 18.4685441,
      "lon": -66.4584461,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a5b7d3d1-0906-419b-9069-ef3f1ecb2aa8",
      "is_mobile": true,
      "mobile_live_date": "2021-02-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Manati",
      "_lat": 18.4685441,
      "_lng": -66.4584461,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -66.4584461
    },
    {
      "id": 5915,
      "name": "Manati, PR",
      "correlation_id": "102822",
      "billing_address": null,
      "start_date": "2023-11-08T15:06:22Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.4345347,
      "lon": -66.4561923,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "77e6002e-36b5-456a-9a32-8ab1b461775c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "Manati, PR",
      "_lat": 18.4345347,
      "_lng": -66.4561923,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -66.4561923
    },
    {
      "id": 1783,
      "name": "Mansfield",
      "correlation_id": "101680",
      "billing_address": {
        "id": 370,
        "street1": "777 West St",
        "street2": "",
        "city": "Mansfield",
        "region": "Massachusetts",
        "postal_code": "02048",
        "lat": 42.0254746,
        "lon": -71.2514321,
        "country": "United States"
      },
      "start_date": "2016-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 42.025475,
      "lon": -71.251527,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "44024708-360c-44f3-b86e-dd49c69e6720",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Mansfield",
      "_lat": 42.025475,
      "_lng": -71.251527,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -71.251527
    },
    {
      "id": 2162,
      "name": "Manteno, IL",
      "correlation_id": "101248",
      "billing_address": {
        "id": 748,
        "street1": "1125 Sycamore Rd",
        "street2": "",
        "city": "Manteno",
        "region": "Illinois",
        "postal_code": "69050",
        "lat": 41.2381249,
        "lon": -87.8258079,
        "country": "United States"
      },
      "start_date": "2020-09-09T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 41.238249,
      "lon": -87.854641,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
      "is_mobile": true,
      "mobile_live_date": "2021-09-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Manteno, IL",
      "_lat": 41.238249,
      "_lng": -87.854641,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -87.854641
    },
    {
      "id": 2444,
      "name": "Market Street",
      "correlation_id": "101368",
      "billing_address": {
        "id": 269,
        "street1": "1455 Market St.",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": "94103",
        "lat": 37.7755556,
        "lon": -122.4178981,
        "country": "United States"
      },
      "start_date": "2023-11-22T18:13:37Z",
      "total_sq_ft": "0",
      "total_buildings": "27",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 303,
        "name": "Uber (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "476b5b30-02ec-41da-b74a-86b56fbe7ab4",
      "is_mobile": true,
      "mobile_live_date": "2023-11-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Uber (CBRE)",
      "_label": "Uber (CBRE)",
      "_name": "Market Street",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 933016,
          "create_date": "2020-12-10T21:36:11.844767Z",
          "update_date": "2020-12-10T21:36:11.844800Z",
          "organization_id": 303,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607636171.7063708_Uber_t.jpg",
          "size": 16927,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1265,
      "name": "Mason",
      "correlation_id": "10230",
      "billing_address": {
        "id": 750,
        "street1": "361 IRWIN SIMPSON",
        "street2": "",
        "city": "Mason",
        "region": "Ohio",
        "postal_code": "45040",
        "lat": 39.3098065,
        "lon": -84.3182052,
        "country": "United States"
      },
      "start_date": "2009-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 39.309281,
      "lon": -84.302637,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ae5ef51c-b3ad-4dd7-b69b-a6d51d75081a",
      "is_mobile": true,
      "mobile_live_date": "2021-09-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Mason",
      "_lat": 39.309281,
      "_lng": -84.302637,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -84.302637
    },
    {
      "id": 2586,
      "name": "Mason",
      "correlation_id": "101518",
      "billing_address": {
        "id": 645,
        "street1": "3600 Pharma Wy",
        "street2": "",
        "city": "Lebanon",
        "region": "Ohio",
        "postal_code": "45036",
        "lat": 39.3838251,
        "lon": -84.2907469,
        "country": "United States"
      },
      "start_date": "2021-08-20T00:36:12Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 39.38379,
      "lon": -84.28839,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
      "is_mobile": true,
      "mobile_live_date": "2021-09-27",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Mason",
      "_lat": 39.38379,
      "_lng": -84.28839,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -84.28839
    },
    {
      "id": 2102,
      "name": "MBC",
      "correlation_id": "101275",
      "billing_address": null,
      "start_date": "2020-01-01T00:00:00Z",
      "total_sq_ft": "32000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 292,
        "name": "Novartis  (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": "11706",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2743e426-ec49-4278-b837-8bed72a68ee1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "41c1dca4-8663-46f3-bab1-f1c3adba323a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis  (CBRE) (SG)",
      "_label": "Novartis  (CBRE) (SG)",
      "_name": "MBC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 940468,
          "create_date": "2020-12-14T16:08:17.854724Z",
          "update_date": "2020-12-14T16:08:17.854750Z",
          "organization_id": 292,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607962097.739492_Novarits_t.png",
          "size": 4146,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1093,
      "name": "MBFC",
      "correlation_id": "10883",
      "billing_address": null,
      "start_date": "2016-02-01T00:00:00Z",
      "total_sq_ft": "270000",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 34,
        "name": "IBM (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10881",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c0657c07-3307-427f-a2af-5b1ec564f16c"
      },
      "parent_id": null,
      "lat": 1.2792463,
      "lon": 103.8541121,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "01a0ce85-6397-4285-8e0b-91d4b3288733",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IBM (SG) (CBRE)",
      "_label": "IBM (SG) (CBRE)",
      "_name": "MBFC",
      "_lat": 1.2792463,
      "_lng": 103.8541121,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.8541121
    },
    {
      "id": 2127,
      "name": "MBFC",
      "correlation_id": "11732",
      "billing_address": null,
      "start_date": "2020-06-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 252,
        "name": "SCB (SG) (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10786",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3c5ff5ca-946c-48c2-a279-417f871c9969"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2350,
        "code": "Asia/Aden",
        "display_name": "Asia/Aden",
        "description": "",
        "child_group": null,
        "alt_sequence": 216
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "93066bcd-42e6-4471-bbd9-de9090c2b939",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SCB (SG) (CBRE)",
      "_label": "SCB (SG) (CBRE)",
      "_name": "MBFC",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10833080,
          "create_date": "2024-02-26T15:45:18.559062Z",
          "update_date": "2024-02-26T15:45:18.559085Z",
          "organization_id": 252,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/8ccd474a0aa04ae085f21c36240403aa_1_t.png",
          "size": 775522,
          "description": null,
          "link": null,
          "unique_identifier": "8ccd474a0aa04ae085f21c36240403aa"
        }
      ],
      "lng": 0
    },
    {
      "id": 2022,
      "name": "MBIC",
      "correlation_id": "11607",
      "billing_address": {
        "id": 325,
        "street1": "8700 Mason Montgomery Rd",
        "street2": "",
        "city": "Mason",
        "region": "Ohio",
        "postal_code": "45040",
        "lat": 39.3153089,
        "lon": -84.3071944,
        "country": "United States"
      },
      "start_date": "2019-11-09T00:00:00Z",
      "total_sq_ft": "70000000",
      "total_buildings": "28",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 275,
        "name": "P&G (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "73c20d05-69b7-4adc-b8c0-7f265c556fe3"
      },
      "parent_id": null,
      "lat": 39.3148469,
      "lon": -84.3095664,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e90ef529-6a4f-47c2-a0a9-0e356ee0dd39",
      "is_mobile": true,
      "mobile_live_date": "2021-04-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "P&G (JLL)",
      "_label": "P&G (JLL)",
      "_name": "MBIC",
      "_lat": 39.3148469,
      "_lng": -84.3095664,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10676982,
          "create_date": "2024-02-15T19:30:31.713635Z",
          "update_date": "2024-02-15T19:30:31.713660Z",
          "organization_id": 275,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9efe32dff7ab4f479526367623a6a20d_Procter__Gamble_logo_t.png",
          "size": 107371,
          "description": null,
          "link": null,
          "unique_identifier": "9efe32dff7ab4f479526367623a6a20d"
        }
      ],
      "lng": -84.3095664
    },
    {
      "id": 2804,
      "name": "McClellan Park (3140 Pckpr Wy)*",
      "correlation_id": "101765",
      "billing_address": null,
      "start_date": "2005-06-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "95fc49bf-9ce7-4b20-bc23-667c5bc71149",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM",
      "_label": "SBM",
      "_name": "McClellan Park (3140 Pckpr Wy)*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2460,
      "name": "McKinney",
      "correlation_id": "102172",
      "billing_address": {
        "id": 290,
        "street1": "2775 S. Central Exwy, Suite 140",
        "street2": "",
        "city": "McKinney",
        "region": "Texas",
        "postal_code": "75070",
        "lat": 33.1743528,
        "lon": -96.6390509,
        "country": "United States"
      },
      "start_date": "2019-10-16T13:43:21Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 33.17408,
      "lon": -96.63909,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d6e89f72-f5f0-45eb-bc20-532f7d831237",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "McKinney",
      "_lat": 33.17408,
      "_lng": -96.63909,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.63909
    },
    {
      "id": 3202,
      "name": "McKinney",
      "correlation_id": null,
      "billing_address": {
        "id": 1340,
        "street1": "115 Industrial Blvd., Suite A",
        "street2": "",
        "city": null,
        "region": "Texas",
        "postal_code": "75069",
        "lat": 33.1800215,
        "lon": -96.6139248,
        "country": "United States"
      },
      "start_date": "2022-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 457,
        "name": "Trane",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2f426e6f-b444-4cd7-8c6b-0323aa44692b"
      },
      "parent_id": null,
      "lat": 32.3182314,
      "lon": -86.902298,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b5101d7f-1156-486a-b327-f868af99eddf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Trane",
      "_label": "Trane",
      "_name": "McKinney",
      "_lat": 32.3182314,
      "_lng": -86.902298,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.902298
    },
    {
      "id": 2344,
      "name": "McKinsey",
      "correlation_id": "101354",
      "billing_address": {
        "id": 1125,
        "street1": "Zona Franca UltraPark II, Building UH1, Heredia",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "N/A",
        "lat": 9.9871345,
        "lon": -84.1529333,
        "country": "Costa Rica"
      },
      "start_date": "2021-01-24T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 360,
        "name": "CR McKinsey",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6fa86709-eb76-4909-806d-62df7f99bb4d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2250,
        "code": "America/Panama",
        "display_name": "America/Panama",
        "description": "",
        "child_group": null,
        "alt_sequence": 116
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "87e38cc3-6d8e-478a-af0a-48b1dd37ccca",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CR McKinsey",
      "_label": "CR McKinsey",
      "_name": "McKinsey",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5495,
      "name": "McKnight Road",
      "correlation_id": "102320",
      "billing_address": {
        "id": 2993,
        "street1": "670 McKnight Road N",
        "street2": "",
        "city": "Maplewood",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.9623013,
        "lon": -93.0036189,
        "country": "United States"
      },
      "start_date": "2014-03-07T00:00:00Z",
      "total_sq_ft": "62329",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 44.96158,
      "lon": -93.00487,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
      "is_mobile": true,
      "mobile_live_date": "2023-09-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "McKnight Road",
      "_lat": 44.96158,
      "_lng": -93.00487,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.00487
    },
    {
      "id": 1304,
      "name": "McNeil-Ft Washington",
      "correlation_id": "10300",
      "billing_address": null,
      "start_date": "2014-02-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 40.1244486,
      "lon": -75.2082105,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "99166c9a-8f54-429a-95a6-b833187f8286",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "McNeil-Ft Washington",
      "_lat": 40.1244486,
      "_lng": -75.2082105,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -75.2082105
    },
    {
      "id": 5772,
      "name": "MDT9– Tremont, PA",
      "correlation_id": "102615",
      "billing_address": {
        "id": 4068,
        "street1": "US 209 & I81",
        "street2": "",
        "city": "Frailey",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.8670643,
        "lon": -75.7981231,
        "country": "United States"
      },
      "start_date": "2023-08-28T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 40.01328,
      "lon": -77.53334,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "78ebe2c2-33c6-47b7-a64a-7737d49c82e0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "MDT9– Tremont, PA",
      "_lat": 40.01328,
      "_lng": -77.53334,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.53334
    },
    {
      "id": 6683,
      "name": "MDW2 – Joliet, IL (Pending Go-Live)",
      "correlation_id": "103392",
      "billing_address": {
        "id": 4048,
        "street1": "250 Emerald Drive",
        "street2": "",
        "city": "Joliet",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.4838499,
        "lon": -88.0714113,
        "country": "United States"
      },
      "start_date": "2024-04-02T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.486286,
      "lon": -88.074625,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "765fe111-2142-48d0-8294-5f61d481bfdc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "MDW2 – Joliet, IL (Pending Go-Live)",
      "_lat": 41.486286,
      "_lng": -88.074625,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -88.074625
    },
    {
      "id": 6669,
      "name": "MDW7 – Monee, IL",
      "correlation_id": "103377",
      "billing_address": {
        "id": 4059,
        "street1": "6605 W Monee Manhattan Rd",
        "street2": "",
        "city": "Monee",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.4240386,
        "lon": -87.773282,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.424162,
      "lon": -87.772586,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7ee413b0-aae3-4577-8dc7-28268328cac2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "MDW7 – Monee, IL",
      "_lat": 41.424162,
      "_lng": -87.772586,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -87.772586
    },
    {
      "id": 6673,
      "name": "MDW9 – Aurora, IL",
      "correlation_id": "103382",
      "billing_address": {
        "id": 4093,
        "street1": "2865 Duke Parkway",
        "street2": "",
        "city": "Aurora",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.8160509,
        "lon": -88.2074073,
        "country": "United States"
      },
      "start_date": "2024-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.816073,
      "lon": -88.206933,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2ff1e544-e03c-431a-9bc9-3e40740b33eb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "MDW9 – Aurora, IL",
      "_lat": 41.816073,
      "_lng": -88.206933,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -88.206933
    },
    {
      "id": 4406,
      "name": "Medical*",
      "correlation_id": "101390",
      "billing_address": null,
      "start_date": "2022-08-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 156,
        "name": "Becton Dickinson (SG) (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11101",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6ce27fdc-7f02-4422-879c-fb650caed538"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "229c831b-28a7-49ef-8abf-7899b4076740",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Becton Dickinson (SG) (JLL)",
      "_label": "Becton Dickinson (SG) (JLL)",
      "_name": "Medical*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2112,
      "name": "Melville, NY",
      "correlation_id": "102229",
      "billing_address": {
        "id": 478,
        "street1": "60 Baylis Road",
        "street2": "",
        "city": "Melville",
        "region": "New York",
        "postal_code": "11747",
        "lat": 40.7700596,
        "lon": -73.4178369,
        "country": "United States"
      },
      "start_date": "2022-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2078,
        "name": "Novartis",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a9d4629d-90b9-42e5-929f-72821bef8015"
      },
      "parent_id": null,
      "lat": 40.7700596,
      "lon": -73.4178369,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "96a2eea6-22db-4be2-a622-aad97ac4cea2",
      "is_mobile": true,
      "mobile_live_date": "2021-03-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis",
      "_label": "Novartis",
      "_name": "Melville, NY",
      "_lat": 40.7700596,
      "_lng": -73.4178369,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 4988519,
          "create_date": "2022-10-25T21:00:07.916575Z",
          "update_date": "2022-10-25T21:00:07.916605Z",
          "organization_id": 2078,
          "uploaded_by_id": 43333,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/30b395a352c844d086175f471e21216b_Novartis_image_t.png",
          "size": 13642,
          "description": null,
          "link": null,
          "unique_identifier": "30b395a352c844d086175f471e21216b"
        }
      ],
      "lng": -73.4178369
    },
    {
      "id": 3119,
      "name": "Memphis",
      "correlation_id": "102008",
      "billing_address": null,
      "start_date": "2022-10-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 802,
        "name": "Boxer Property",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef740b73-bc5a-4f38-87cd-aa3b96552303"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5cf9649e-b2a4-4ca0-9ad7-31bd2bb4b5e6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boxer Property",
      "_label": "Boxer Property",
      "_name": "Memphis",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3144,
      "name": "Memphis",
      "correlation_id": "102013",
      "billing_address": {
        "id": 1305,
        "street1": "4926 Southridge Blvd",
        "street2": "",
        "city": null,
        "region": "Tennessee",
        "postal_code": "38141",
        "lat": 35.0133846,
        "lon": -89.850492,
        "country": "United States"
      },
      "start_date": "2022-04-01T19:10:39Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 803,
        "name": "Vantiva (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "72641938-f560-4c4f-aebc-c1fbab13ecc8"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8a33df06-5844-4653-bd10-077459324b24",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Vantiva (CBRE)",
      "_label": "Vantiva (CBRE)",
      "_name": "Memphis",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10673747,
          "create_date": "2024-02-15T17:14:54.094236Z",
          "update_date": "2024-02-15T17:14:54.094260Z",
          "organization_id": 803,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
          "size": 9568,
          "description": null,
          "link": null,
          "unique_identifier": "75e64d0f01e5470495cc79f21e44e8e0"
        }
      ],
      "lng": 0
    },
    {
      "id": 2475,
      "name": "Memphis, TN (JLL)",
      "correlation_id": "101424",
      "billing_address": {
        "id": 319,
        "street1": "1855 N Shelby Oaks Drive",
        "street2": "",
        "city": "Memphis",
        "region": "Tennessee",
        "postal_code": "38134",
        "lat": 35.1766311,
        "lon": -89.8566444,
        "country": "United States"
      },
      "start_date": "2021-05-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 71,
        "name": "Pfizer",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a947b043-b271-463a-87cb-992405816077"
      },
      "parent_id": null,
      "lat": 35.176,
      "lon": -89.856,
      "local_timezone": {
        "id": 2205,
        "code": "America/Indiana/Knox",
        "display_name": "America/Indiana/Knox",
        "description": "",
        "child_group": null,
        "alt_sequence": 71
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4984580d-096d-461e-a381-407a104c54ba",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Pfizer",
      "_label": "Pfizer",
      "_name": "Memphis, TN (JLL)",
      "_lat": 35.176,
      "_lng": -89.856,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -89.856
    },
    {
      "id": 1896,
      "name": "Menlo Park",
      "correlation_id": "10757",
      "billing_address": {
        "id": 398,
        "street1": "3885 Bohannan Dr",
        "street2": "",
        "city": "Menlo Park",
        "region": "California",
        "postal_code": "94025",
        "lat": 37.479512,
        "lon": -122.1822572,
        "country": "United States"
      },
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.479295,
      "lon": -122.182198,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ab2875d9-6239-4bf7-913d-303ce135cd93",
      "is_mobile": true,
      "mobile_live_date": "2021-04-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Menlo Park",
      "_lat": 37.479295,
      "_lng": -122.182198,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.182198
    },
    {
      "id": 5890,
      "name": "MENT301*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-10-16T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 34.4324,
      "lon": -119.81249,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ff793f26-4e83-4161-a1fa-dcad7c4ab91e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "MENT301*",
      "_lat": 34.4324,
      "_lng": -119.81249,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.81249
    },
    {
      "id": 5939,
      "name": "MERCK00PWOH (Merck Overhead Pacific West)",
      "correlation_id": "102394",
      "billing_address": null,
      "start_date": "2023-11-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2216e29a-d3f2-4051-b601-4d2f9aa4fbee",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "MERCK00PWOH (Merck Overhead Pacific West)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6896,
      "name": "MERCK-DIR-WALTHAM-BOS",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 207,
        "name": "Merck",
        "type": "CUSTOMER",
        "correlation_id": "11459",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ac6c1c69-fd86-48b6-970a-06c86ce500db"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1dfc381b-40ad-4ed0-90df-8f79e02e9db8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck",
      "_label": "Merck",
      "_name": "MERCK-DIR-WALTHAM-BOS",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953529,
          "create_date": "2020-12-17T16:57:39.106237Z",
          "update_date": "2020-12-17T16:57:39.106267Z",
          "organization_id": 207,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224258.8895628_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 6897,
      "name": "MERCK-DIR-WALTHAM-CAM",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 207,
        "name": "Merck",
        "type": "CUSTOMER",
        "correlation_id": "11459",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ac6c1c69-fd86-48b6-970a-06c86ce500db"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "21df1adb-9fcb-4787-a283-b76f6fea162e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck",
      "_label": "Merck",
      "_name": "MERCK-DIR-WALTHAM-CAM",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953529,
          "create_date": "2020-12-17T16:57:39.106237Z",
          "update_date": "2020-12-17T16:57:39.106267Z",
          "organization_id": 207,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224258.8895628_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 6735,
      "name": "Meridian",
      "correlation_id": "103401",
      "billing_address": {
        "id": 4106,
        "street1": "450 S. Meridian St.",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.7596915,
        "lon": -86.1597587,
        "country": "United States"
      },
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 39.76031,
      "lon": -86.1594,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "37db0113-195f-4860-90aa-5beeb48ad5b0",
      "is_mobile": true,
      "mobile_live_date": "2024-01-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "Meridian",
      "_lat": 39.76031,
      "_lng": -86.1594,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": -86.1594
    },
    {
      "id": 3794,
      "name": "Mesa Rim",
      "correlation_id": "102073",
      "billing_address": {
        "id": 1802,
        "street1": "9940 Mesa Rim Rd",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "92121",
        "lat": 32.899783,
        "lon": -117.1787696,
        "country": "United States"
      },
      "start_date": "2022-05-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 32.90045,
      "lon": -117.1787,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "aee3bb3a-acee-4a9b-b1fe-05c267cf7593",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Mesa Rim",
      "_lat": 32.90045,
      "_lng": -117.1787,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.1787
    },
    {
      "id": 1372,
      "name": "METD - Mt. Elliot Tool & Die - Detroit, MI",
      "correlation_id": "101701",
      "billing_address": {
        "id": 925,
        "street1": "3601 East Outer Dr",
        "street2": "",
        "city": "Detroit",
        "region": "Michigan",
        "postal_code": "48234",
        "lat": 42.4411201,
        "lon": -83.0417218,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.4411201,
      "lon": -83.0417218,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76237550-41e2-4e0a-b510-3710e0afbfb1",
      "is_mobile": true,
      "mobile_live_date": "2022-06-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "METD - Mt. Elliot Tool & Die - Detroit, MI",
      "_lat": 42.4411201,
      "_lng": -83.0417218,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.0417218
    },
    {
      "id": 2082,
      "name": "Metro",
      "correlation_id": "11064",
      "billing_address": {
        "id": 74,
        "street1": "ZONA FRANCA METROPOLITANA Barreal de Heredia, Building 7 C.",
        "street2": "",
        "city": "Heredia",
        "region": "Alabama",
        "postal_code": "40101",
        "lat": 9.9981413,
        "lon": -84.1197643,
        "country": "Costa Rica"
      },
      "start_date": "2014-08-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 66,
        "name": "Claro (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10996",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd3b5a83-4967-4d33-a99e-b06b63baef24"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fe12cf4b-efd8-4796-9b2e-d5e04b60c240",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Claro (CR)",
      "_label": "Claro (CR)",
      "_name": "Metro",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1974,
      "name": "Michigan (CBRE)",
      "correlation_id": "11579",
      "billing_address": null,
      "start_date": "2019-08-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 42.1185463,
      "lon": -86.476829,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "20a980a5-f48d-444c-b160-beb18ee9afc3",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Michigan (CBRE)",
      "_lat": 42.1185463,
      "_lng": -86.476829,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -86.476829
    },
    {
      "id": 3269,
      "name": "MICR00OVRHD",
      "correlation_id": "101608",
      "billing_address": null,
      "start_date": "2022-04-05T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7e4a655a-38ed-4a7e-9698-d2085b79849c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "MICR00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1402,
      "name": "MicroVention",
      "correlation_id": "10441",
      "billing_address": {
        "id": 36,
        "street1": "Coyol Free Zone & Business Park 562 Parkway, Costa Rica, Building B33",
        "street2": "",
        "city": "Alajuela",
        "region": "Alabama",
        "postal_code": "20101",
        "lat": 10.391583,
        "lon": -84.4382721,
        "country": "Costa Rica"
      },
      "start_date": "2015-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 105,
        "name": "MicroVention (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10440",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08ef4884-a04e-4e25-9b1d-c269116eead4"
      },
      "parent_id": null,
      "lat": 9.9794384,
      "lon": -84.1466126,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "238c7620-d0d2-4161-9a79-0ddb40bb99bf",
      "is_mobile": true,
      "mobile_live_date": "2021-10-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "MicroVention (CR)",
      "_label": "MicroVention (CR)",
      "_name": "MicroVention",
      "_lat": 9.9794384,
      "_lng": -84.1466126,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.1466126
    },
    {
      "id": 4594,
      "name": "Midtown Atlanta, GA*",
      "correlation_id": "102119",
      "billing_address": null,
      "start_date": "2022-08-26T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b60d4478-a7f0-4179-b15b-b6e8d9e58d04",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Midtown Atlanta, GA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1,
      "name": "Milford PG, MI",
      "correlation_id": "10872",
      "billing_address": {
        "id": 2916,
        "street1": "3300 General Motor Rd",
        "street2": "",
        "city": "Milford",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.5762086,
        "lon": -83.6620009,
        "country": "United States"
      },
      "start_date": "2017-04-07T23:59:59Z",
      "total_sq_ft": "4838388",
      "total_buildings": "62",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f"
      },
      "parent_id": null,
      "lat": 42.5694305,
      "lon": -83.6742871,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
      "is_mobile": true,
      "mobile_live_date": "2018-10-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GM",
      "_label": "GM",
      "_name": "Milford PG, MI",
      "_lat": 42.5694305,
      "_lng": -83.6742871,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853049,
          "create_date": "2020-11-14T00:00:06.049828Z",
          "update_date": "2020-11-14T00:00:06.049855Z",
          "organization_id": 137,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312005.83312_GM_logo_t.jpg",
          "size": 1080345,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.6742871
    },
    {
      "id": 2141,
      "name": "Mission Bay",
      "correlation_id": "101234",
      "billing_address": {
        "id": 3815,
        "street1": "1725 3rd St.",
        "street2": "",
        "city": "San Francisco,",
        "region": "California",
        "postal_code": null,
        "lat": 37.7673292,
        "lon": -122.3886371,
        "country": "United States"
      },
      "start_date": "2020-07-24T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 303,
        "name": "Uber (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629"
      },
      "parent_id": null,
      "lat": 37.773972,
      "lon": -122.431297,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
      "is_mobile": true,
      "mobile_live_date": "2023-11-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Uber (CBRE)",
      "_label": "Uber (CBRE)",
      "_name": "Mission Bay",
      "_lat": 37.773972,
      "_lng": -122.431297,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 933016,
          "create_date": "2020-12-10T21:36:11.844767Z",
          "update_date": "2020-12-10T21:36:11.844800Z",
          "organization_id": 303,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607636171.7063708_Uber_t.jpg",
          "size": 16927,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.431297
    },
    {
      "id": 2502,
      "name": "Mission Valley, CA",
      "correlation_id": "102181",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 32.770736,
      "lon": -117.1619354,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e7033622-13c3-4d87-81bf-92a7de3bf95c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Mission Valley, CA",
      "_lat": 32.770736,
      "_lng": -117.1619354,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.1619354
    },
    {
      "id": 5765,
      "name": "MLI1– Davenport, IA",
      "correlation_id": "102609",
      "billing_address": {
        "id": 4060,
        "street1": "2022 Research Parkway",
        "street2": "",
        "city": "Davenport",
        "region": "Iowa",
        "postal_code": null,
        "lat": 41.6062137,
        "lon": -90.6018123,
        "country": "United States"
      },
      "start_date": "2023-06-27T19:21:34Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.60398,
      "lon": -90.60291,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "49fa8b8d-ecc9-4aee-bc85-6cfa9af3526b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "MLI1– Davenport, IA",
      "_lat": 41.60398,
      "_lng": -90.60291,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -90.60291
    },
    {
      "id": 5745,
      "name": "Moberly (CBRE)",
      "correlation_id": "102588",
      "billing_address": null,
      "start_date": "2023-05-12T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c69889fb-e12c-4c02-9f86-f6491aa86793",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Moberly (CBRE)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": 0
    },
    {
      "id": 5866,
      "name": "MOB – Maintenance & Operations – Dearborn, MI",
      "correlation_id": "102800",
      "billing_address": null,
      "start_date": "2020-11-09T15:11:07Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2959395,
      "lon": -83.2314887,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "MOB – Maintenance & Operations – Dearborn, MI",
      "_lat": 42.2959395,
      "_lng": -83.2314887,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2314887
    },
    {
      "id": 1519,
      "name": "Modesto",
      "correlation_id": "10598",
      "billing_address": {
        "id": 517,
        "street1": "600 Yosemite Blvd",
        "street2": "",
        "city": "Modesto",
        "region": "California",
        "postal_code": "95354",
        "lat": 37.6351239,
        "lon": -120.9808364,
        "country": "United States"
      },
      "start_date": "2001-01-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "68",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 141,
        "name": "E&J Gallo Winery",
        "type": "CUSTOMER",
        "correlation_id": "10596",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641a582d-6107-41ef-9ff3-d87668519738"
      },
      "parent_id": null,
      "lat": 37.6341989,
      "lon": -120.9806391,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "08407a6e-cae8-424a-b4ad-3b93fa9485b2",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "E&J Gallo Winery",
      "_label": "E&J Gallo Winery",
      "_name": "Modesto",
      "_lat": 37.6341989,
      "_lng": -120.9806391,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026016,
          "create_date": "2024-03-07T18:13:38.497316Z",
          "update_date": "2024-03-07T18:13:38.497339Z",
          "organization_id": 141,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/d326f0a5923f4337b2a8492f1dfe73ee_00000328bGallo_t.jpg",
          "size": 74967,
          "description": null,
          "link": null,
          "unique_identifier": "d326f0a5923f4337b2a8492f1dfe73ee"
        }
      ],
      "lng": -120.9806391
    },
    {
      "id": 2565,
      "name": "Modesto",
      "correlation_id": "102173",
      "billing_address": {
        "id": 551,
        "street1": "1700 McHenry Ave",
        "street2": "",
        "city": "Modesto",
        "region": "California",
        "postal_code": "95350",
        "lat": 37.6691222,
        "lon": -120.990622,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 37.66984,
      "lon": -120.993,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1651ecba-4602-47df-a034-de4565adda69",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Modesto",
      "_lat": 37.66984,
      "_lng": -120.993,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -120.993
    },
    {
      "id": 6701,
      "name": "Modesto Batch Plant",
      "correlation_id": "103406",
      "billing_address": null,
      "start_date": "2024-01-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 141,
        "name": "E&J Gallo Winery",
        "type": "CUSTOMER",
        "correlation_id": "10596",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641a582d-6107-41ef-9ff3-d87668519738"
      },
      "parent_id": null,
      "lat": 37.63031,
      "lon": -120.97502,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17808225-79bc-4f6f-b4c7-aa0eb81d7805",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "E&J Gallo Winery",
      "_label": "E&J Gallo Winery",
      "_name": "Modesto Batch Plant",
      "_lat": 37.63031,
      "_lng": -120.97502,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026016,
          "create_date": "2024-03-07T18:13:38.497316Z",
          "update_date": "2024-03-07T18:13:38.497339Z",
          "organization_id": 141,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/d326f0a5923f4337b2a8492f1dfe73ee_00000328bGallo_t.jpg",
          "size": 74967,
          "description": null,
          "link": null,
          "unique_identifier": "d326f0a5923f4337b2a8492f1dfe73ee"
        }
      ],
      "lng": -120.97502
    },
    {
      "id": 2130,
      "name": "Modesto, CA",
      "correlation_id": "101229",
      "billing_address": {
        "id": 757,
        "street1": "1209 Woodrow Av",
        "street2": "",
        "city": "Modesto",
        "region": "Alabama",
        "postal_code": "95351",
        "lat": 37.6860646,
        "lon": -121.0108066,
        "country": "United States"
      },
      "start_date": "2020-06-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 299,
        "name": "Stanislaus County Health Services",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0ace92fd-719b-48c9-8d89-7b7bdf9db23c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e25d68e6-23a2-49df-bc5e-6e179bbc63e9",
      "is_mobile": true,
      "mobile_live_date": "2021-09-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stanislaus County Health Services",
      "_label": "Stanislaus County Health Services",
      "_name": "Modesto, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5513,
      "name": "Modesto Main (JLL)",
      "correlation_id": "102326",
      "billing_address": null,
      "start_date": "2022-12-21T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 37.6425327,
      "lon": -120.9996357,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0d4bbf83-4633-4e1f-b06d-82be5cc439d7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Modesto Main (JLL)",
      "_lat": 37.6425327,
      "_lng": -120.9996357,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -120.9996357
    },
    {
      "id": 4324,
      "name": "MOON TWP PA",
      "correlation_id": "102077",
      "billing_address": {
        "id": 2429,
        "street1": "520 Lindbergh Dr",
        "street2": "",
        "city": "Moon twp",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.4984401,
        "lon": -80.2154365,
        "country": "United States"
      },
      "start_date": "2022-08-09T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 40.4984401,
      "lon": -80.2154365,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e02e2b9e-07c4-40f5-a6c6-ef2a900d31f7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "MOON TWP PA",
      "_lat": 40.4984401,
      "_lng": -80.2154365,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -80.2154365
    },
    {
      "id": 5673,
      "name": "Morris Plains, NJ*",
      "correlation_id": "102544",
      "billing_address": null,
      "start_date": "2023-03-21T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 270,
        "name": "Novartis (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1a375c6f-b3c0-4884-9417-b0c544e21ef3"
      },
      "parent_id": null,
      "lat": 40.8145,
      "lon": -74.47213,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7c72a9b1-b2bd-4155-8ac0-baeee081768d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis (CBRE)",
      "_label": "Novartis (CBRE)",
      "_name": "Morris Plains, NJ*",
      "_lat": 40.8145,
      "_lng": -74.47213,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859846,
          "create_date": "2020-11-17T14:36:58.595850Z",
          "update_date": "2020-11-17T14:36:58.595883Z",
          "organization_id": 270,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623818.436738_novartislogo775x500_t.png",
          "size": 7078,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.47213
    },
    {
      "id": 1347,
      "name": "Morristown",
      "correlation_id": "10383",
      "billing_address": {
        "id": 410,
        "street1": "36 Columbia Rd",
        "street2": "",
        "city": "Morristown",
        "region": "New Jersey",
        "postal_code": "07960",
        "lat": 40.7966658,
        "lon": -74.453845,
        "country": "United States"
      },
      "start_date": "2014-01-23T23:59:59Z",
      "total_sq_ft": "340746",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 47,
        "name": "Bayer (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10379",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f625c0fa-62d0-4cd2-bab6-0c9268a9102e"
      },
      "parent_id": null,
      "lat": 40.7968509,
      "lon": -74.454424,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "666114fc-af61-4dc1-a980-d707d5258d73",
      "is_mobile": true,
      "mobile_live_date": "2021-02-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bayer (JLL)",
      "_label": "Bayer (JLL)",
      "_name": "Morristown",
      "_lat": 40.7968509,
      "_lng": -74.454424,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 2346369,
          "create_date": "2021-10-05T19:57:04.049722Z",
          "update_date": "2021-10-05T19:57:04.049745Z",
          "organization_id": 47,
          "uploaded_by_id": 42105,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/96886093fe0143fe9901fc84b025f98f_BAYERLOGO002_t.PNG",
          "size": 28581,
          "description": null,
          "link": null,
          "unique_identifier": "96886093fe0143fe9901fc84b025f98f"
        }
      ],
      "lng": -74.454424
    },
    {
      "id": 1129,
      "name": "Morrisville",
      "correlation_id": "10961",
      "billing_address": null,
      "start_date": "2015-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 55,
        "name": "Catalent Pharma.",
        "type": "CUSTOMER",
        "correlation_id": "10960",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "94bd3a7e-feaa-4cc0-a0fc-9fbed87615ca"
      },
      "parent_id": null,
      "lat": 35.8337068,
      "lon": -78.8112774,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6f3c3e98-ed07-4100-9618-1da715ee6a2a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Catalent Pharma.",
      "_label": "Catalent Pharma.",
      "_name": "Morrisville",
      "_lat": 35.8337068,
      "_lng": -78.8112774,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -78.8112774
    },
    {
      "id": 2416,
      "name": "MOT Delta Vacations (Minot, ND)*",
      "correlation_id": "101382",
      "billing_address": {
        "id": 246,
        "street1": "2915 N Broadway",
        "street2": "",
        "city": "Minot",
        "region": "North Dakota",
        "postal_code": "58703",
        "lat": 48.2681445,
        "lon": -101.2981158,
        "country": "United States"
      },
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 48.26844,
      "lon": -101.2991,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e6c8ca2a-1283-4e10-947d-579a9d1a780a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "MOT Delta Vacations (Minot, ND)*",
      "_lat": 48.26844,
      "_lng": -101.2991,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -101.2991
    },
    {
      "id": 4588,
      "name": "Mountain VIew, CA*",
      "correlation_id": "102085",
      "billing_address": null,
      "start_date": "2022-08-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "98cdb66f-1203-48ee-83aa-25cc8ace1fd4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Mountain VIew, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2474,
      "name": "Mount Joy, PA",
      "correlation_id": "101387",
      "billing_address": {
        "id": 318,
        "street1": "200 CHOCOLATE AVENUE",
        "street2": "",
        "city": "Mount Joy",
        "region": "Pennsylvania",
        "postal_code": "17552",
        "lat": 40.1119,
        "lon": -76.5198066,
        "country": "United States"
      },
      "start_date": "2022-01-24T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 443,
        "name": "Cargill",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 303,
            "street1": "200 CHOCOLATE AVENUE",
            "street2": "",
            "city": "Mount Joy",
            "region": "Pennsylvania",
            "postal_code": "17552",
            "lat": 40.1119,
            "lon": -76.5198066,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "cda36f33-0892-4c9a-82f3-0ba92e1349bf"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2284,
        "code": "America/Toronto",
        "display_name": "America/Toronto",
        "description": "",
        "child_group": null,
        "alt_sequence": 150
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "12c19e40-4eb9-4998-9fe4-e43fb1f37840",
      "is_mobile": true,
      "mobile_live_date": "2021-11-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cargill",
      "_label": "Cargill",
      "_name": "Mount Joy, PA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3200,
      "name": "MSFT00OVRHD",
      "correlation_id": "102034",
      "billing_address": null,
      "start_date": "2022-03-21T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f8caf97c-34d3-4414-a7f5-1692f8c55be9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "MSFT00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5910,
      "name": "MSP - Minneapolis, MN",
      "correlation_id": "102758",
      "billing_address": {
        "id": 3858,
        "street1": "MSP",
        "street2": "",
        "city": "Minneapolis",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.8849345,
        "lon": -93.2131232,
        "country": "United States"
      },
      "start_date": "2023-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "30",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 391,
        "name": "Delta",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 152,
            "street1": "1030 Delta BLVD",
            "street2": "",
            "city": "Atlanta",
            "region": "Georgia",
            "postal_code": "30354",
            "lat": 33.6562452,
            "lon": -84.4242419,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "fc021e99-c657-4765-9df7-f136ef3a73d0"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "131adba3-5548-46ab-892c-4546cc6713d4",
      "is_mobile": true,
      "mobile_live_date": "2023-11-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Delta",
      "_label": "Delta",
      "_name": "MSP - Minneapolis, MN",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5704,
      "name": "MS&T Lab*",
      "correlation_id": "102569",
      "billing_address": null,
      "start_date": "2023-05-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 292,
        "name": "Novartis  (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": "11706",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2743e426-ec49-4278-b837-8bed72a68ee1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5ae2ccb7-df54-44c4-9972-f36858f265fa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Novartis  (CBRE) (SG)",
      "_label": "Novartis  (CBRE) (SG)",
      "_name": "MS&T Lab*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 940468,
          "create_date": "2020-12-14T16:08:17.854724Z",
          "update_date": "2020-12-14T16:08:17.854750Z",
          "organization_id": 292,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607962097.739492_Novarits_t.png",
          "size": 4146,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 3125,
      "name": "Mt Laurel",
      "correlation_id": "102021",
      "billing_address": {
        "id": 1274,
        "street1": "115 Gaither Drive",
        "street2": "",
        "city": "Mt laurel Township",
        "region": "New Jersey",
        "postal_code": "08054",
        "lat": 39.939515,
        "lon": -74.9477289,
        "country": "United States"
      },
      "start_date": "2022-02-28T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 347,
        "name": "Honda",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 87,
            "street1": "2420 Oak Valley Dr",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48103",
            "lat": 42.2511196,
            "lon": -83.7743094,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bc0f895a-4b0d-4954-9fec-348b76f6ace1"
      },
      "parent_id": null,
      "lat": 39.94011,
      "lon": -74.94718,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cc70e329-8884-4999-9f2d-c6935717202d",
      "is_mobile": true,
      "mobile_live_date": "2022-05-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Honda",
      "_label": "Honda",
      "_name": "Mt Laurel",
      "_lat": 39.94011,
      "_lng": -74.94718,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -74.94718
    },
    {
      "id": 2600,
      "name": "Mukilteo, WA",
      "correlation_id": "10080",
      "billing_address": {
        "id": 666,
        "street1": "12401 Harbour Reach Dr.",
        "street2": "",
        "city": "Mukilteo",
        "region": "Washington",
        "postal_code": "98275-5316",
        "lat": 47.8855426,
        "lon": -122.2905994,
        "country": "United States"
      },
      "start_date": "2021-08-18T17:27:21Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 47.8855426,
      "lon": -122.2905994,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7c93df05-72db-4857-9312-eba3bc64e640",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "Mukilteo, WA",
      "_lat": 47.8855426,
      "_lng": -122.2905994,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": -122.2905994
    },
    {
      "id": 7094,
      "name": "Multipark (CR)*",
      "correlation_id": "103440",
      "billing_address": null,
      "start_date": "2024-02-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2833,
        "name": "Contract Workplaces Costa Rica S.A.",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "421f5903-23e1-43fc-ae92-8c4a26607e1d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4c404b0f-7168-4968-8dd9-89555e7320d6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Contract Workplaces Costa Rica S.A.",
      "_label": "Contract Workplaces Costa Rica S.A.",
      "_name": "Multipark (CR)*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2656,
      "name": "Murfreesboro",
      "correlation_id": "101555",
      "billing_address": {
        "id": 781,
        "street1": "2500 Memorial Blvd",
        "street2": "",
        "city": "Murfreesboro",
        "region": "Tennessee",
        "postal_code": "37130",
        "lat": 35.8922064,
        "lon": -86.380159,
        "country": "United States"
      },
      "start_date": "2021-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 732,
            "street1": "8225 Bent Branch Road",
            "street2": "",
            "city": "Irving",
            "region": "Texas",
            "postal_code": "75063",
            "lat": 32.9185433,
            "lon": -96.9908672,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08"
      },
      "parent_id": null,
      "lat": 35.89183,
      "lon": -86.38013,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "69c31c57-abb7-405d-9a8c-60e05813605b",
      "is_mobile": true,
      "mobile_live_date": "2022-09-27",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "State Farm (CBRE)",
      "_label": "State Farm (CBRE)",
      "_name": "Murfreesboro",
      "_lat": 35.89183,
      "_lng": -86.38013,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10563300,
          "create_date": "2024-02-08T19:22:18.819455Z",
          "update_date": "2024-02-08T19:22:18.819478Z",
          "organization_id": 485,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
          "size": 10049,
          "description": null,
          "link": null,
          "unique_identifier": "bceb470097b94120918c0fc266d302e1"
        }
      ],
      "lng": -86.38013
    },
    {
      "id": 1262,
      "name": "NALC",
      "correlation_id": "10224",
      "billing_address": null,
      "start_date": "2015-09-01T12:00:00Z",
      "total_sq_ft": "54400000",
      "total_buildings": "17",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 82,
        "name": "NIKE",
        "type": "CUSTOMER",
        "correlation_id": "10206",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "893d2ac1-1816-4f09-a9ca-c9ef9373af99"
      },
      "parent_id": null,
      "lat": 35.2256083,
      "lon": -89.9610997,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "975904a6-3e15-4653-aaec-106a8c44ea92",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "NIKE",
      "_label": "NIKE",
      "_name": "NALC",
      "_lat": 35.2256083,
      "_lng": -89.9610997,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -89.9610997
    },
    {
      "id": 1651,
      "name": "Nashville, TN",
      "correlation_id": "11226",
      "billing_address": null,
      "start_date": "2018-05-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 183,
        "name": "NTT Data (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84327e76-84f8-4857-ae28-c732826dba22"
      },
      "parent_id": null,
      "lat": 36.0975697,
      "lon": -86.6783181,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "112b1675-994d-4608-a1b0-2572c8905871",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "NTT Data (JLL)",
      "_label": "NTT Data (JLL)",
      "_name": "Nashville, TN",
      "_lat": 36.0975697,
      "_lng": -86.6783181,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -86.6783181
    },
    {
      "id": 1249,
      "name": "Nassau Park",
      "correlation_id": "10210",
      "billing_address": {
        "id": 141,
        "street1": "100 Nassau Park  #300",
        "street2": "",
        "city": "Princeton",
        "region": "New Jersey",
        "postal_code": "08540",
        "lat": 40.3041028,
        "lon": -74.6730982,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "200000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 40.3041028,
      "lon": -74.6730982,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8b63e501-fa54-46e2-b28f-9c1b1d986d55",
      "is_mobile": true,
      "mobile_live_date": "2021-01-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Nassau Park",
      "_lat": 40.3041028,
      "_lng": -74.6730982,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.6730982
    },
    {
      "id": 2694,
      "name": "NE-Georgia",
      "correlation_id": "101840",
      "billing_address": {
        "id": 821,
        "street1": "743 Spring St NE",
        "street2": "",
        "city": "Gainesville",
        "region": "GA",
        "postal_code": "30501",
        "lat": 34.3035028,
        "lon": -83.8172227,
        "country": "United States"
      },
      "start_date": "2021-12-28T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 447,
        "name": "GE Healthcare",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 320,
            "street1": "3300 Mercy Health Blvd",
            "street2": "",
            "city": "Cincinnati",
            "region": "OH",
            "postal_code": "45211",
            "lat": 39.1802011,
            "lon": -84.5968153,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "2da69092-ba9c-43de-bff7-d27dc0705eaa"
      },
      "parent_id": null,
      "lat": 34.303348,
      "lon": -83.817367,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c91a2e4d-5dd6-4ae0-ab92-9fedffbae6bb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Healthcare",
      "_label": "GE Healthcare",
      "_name": "NE-Georgia",
      "_lat": 34.303348,
      "_lng": -83.817367,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025818,
          "create_date": "2024-03-07T18:08:58.813413Z",
          "update_date": "2024-03-07T18:08:58.813437Z",
          "organization_id": 447,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/7a33f9c63a9140fd9b180f72c56c2a74_ge_healthcare_logo_t.png",
          "size": 18070,
          "description": null,
          "link": null,
          "unique_identifier": "7a33f9c63a9140fd9b180f72c56c2a74"
        }
      ],
      "lng": -83.817367
    },
    {
      "id": 5811,
      "name": "Nestle Way",
      "correlation_id": "102737",
      "billing_address": null,
      "start_date": "2023-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2152,
        "name": "Thermo Fisher Scientific",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9be8014b-8fdb-4abe-8d17-562a8782873b"
      },
      "parent_id": null,
      "lat": 40.5708601,
      "lon": -75.6371711,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3f8b3eda-781d-4c06-86de-5926a5075590",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Thermo Fisher Scientific",
      "_label": "Thermo Fisher Scientific",
      "_name": "Nestle Way",
      "_lat": 40.5708601,
      "_lng": -75.6371711,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 7670875,
          "create_date": "2023-08-15T20:48:02.132597Z",
          "update_date": "2023-08-15T20:48:02.132627Z",
          "organization_id": 2152,
          "uploaded_by_id": 14445,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/f8db466f29bd4ce98656bf3c7c0f303a_download_t.png",
          "size": 3648,
          "description": null,
          "link": null,
          "unique_identifier": "f8db466f29bd4ce98656bf3c7c0f303a"
        }
      ],
      "lng": -75.6371711
    },
    {
      "id": 5693,
      "name": "New Albany, OH (JLL)",
      "correlation_id": "102558",
      "billing_address": {
        "id": 3984,
        "street1": "4150 Ganton Parkway",
        "street2": "",
        "city": "New Albany",
        "region": "Ohio",
        "postal_code": null,
        "lat": 40.0764433,
        "lon": -82.7638343,
        "country": "United States"
      },
      "start_date": "2023-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 11,
        "name": "Amgen",
        "type": "CUSTOMER",
        "correlation_id": "10005",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4039c7d0-6c24-4c2d-881b-30b19567fc29"
      },
      "parent_id": null,
      "lat": 40.07843,
      "lon": -82.75886,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9fe9617f-b92e-4780-a3f1-8d20e0b55a31",
      "is_mobile": true,
      "mobile_live_date": "2023-12-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amgen",
      "_label": "Amgen",
      "_name": "New Albany, OH (JLL)",
      "_lat": 40.07843,
      "_lng": -82.75886,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853055,
          "create_date": "2020-11-14T00:02:14.473739Z",
          "update_date": "2020-11-14T00:02:14.473766Z",
          "organization_id": 11,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312134.3328376_Artboard12_t.png",
          "size": 134976,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.75886
    },
    {
      "id": 1997,
      "name": "Newark, DE",
      "correlation_id": "11634",
      "billing_address": {
        "id": 2707,
        "street1": "201 Discovery Blvd.",
        "street2": "",
        "city": "Newark",
        "region": "Delaware",
        "postal_code": null,
        "lat": 39.6621516,
        "lon": -75.7555389,
        "country": "United States"
      },
      "start_date": "2019-12-13T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 269,
        "name": "Chemours (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100008",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "930d637c-d6ff-4734-a6e0-1f1e78b8876e"
      },
      "parent_id": null,
      "lat": 39.66318,
      "lon": -75.75244,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
      "is_mobile": true,
      "mobile_live_date": "2022-10-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Chemours (CBRE)",
      "_label": "Chemours (CBRE)",
      "_name": "Newark, DE",
      "_lat": 39.66318,
      "_lng": -75.75244,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 917976,
          "create_date": "2020-12-07T19:23:59.828737Z",
          "update_date": "2020-12-07T19:23:59.828765Z",
          "organization_id": 269,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607369039.649657_Chemours_t.png",
          "size": 8724,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -75.75244
    },
    {
      "id": 1260,
      "name": "New Brunswick",
      "correlation_id": "10222",
      "billing_address": {
        "id": 365,
        "street1": "1 Squibb Dr",
        "street2": "",
        "city": "New Brunswick",
        "region": "New Jersey",
        "postal_code": "08901",
        "lat": 40.4711991,
        "lon": -74.4404721,
        "country": "United States"
      },
      "start_date": "2015-08-01T23:59:59Z",
      "total_sq_ft": "49156354",
      "total_buildings": "27",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 40.4770433,
      "lon": -74.4475311,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e8bf2f15-85b3-413f-84ac-3aacb0e9692b",
      "is_mobile": true,
      "mobile_live_date": "2020-08-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "New Brunswick",
      "_lat": 40.4770433,
      "_lng": -74.4475311,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.4475311
    },
    {
      "id": 1625,
      "name": "Newbury Park",
      "correlation_id": "11179",
      "billing_address": {
        "id": 785,
        "street1": "2430 Conejo Spectrum St",
        "street2": "",
        "city": "Thousand Oaks",
        "region": "California",
        "postal_code": "91320",
        "lat": 34.2021748,
        "lon": -118.9297532,
        "country": "United States"
      },
      "start_date": "2018-04-30T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 174,
        "name": "Fujifilm Bio",
        "type": "CUSTOMER",
        "correlation_id": "11178",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "895e884e-f868-4953-82cd-009f644129ca"
      },
      "parent_id": null,
      "lat": 34.1805403,
      "lon": -118.9070887,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ba89d339-53c0-4350-98b7-25d439f166ff",
      "is_mobile": true,
      "mobile_live_date": "2021-10-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fujifilm Bio",
      "_label": "Fujifilm Bio",
      "_name": "Newbury Park",
      "_lat": 34.1805403,
      "_lng": -118.9070887,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -118.9070887
    },
    {
      "id": 2580,
      "name": "Newbury Park, CA",
      "correlation_id": "101520",
      "billing_address": {
        "id": 579,
        "street1": "1300 Rancho Conejo Blvd",
        "street2": "",
        "city": "Thousand Oaks",
        "region": "California",
        "postal_code": "91320",
        "lat": 34.1980097,
        "lon": -118.9214156,
        "country": "United States"
      },
      "start_date": "2021-06-24T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 471,
        "name": "Capsida Biotherapeutics",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 578,
            "street1": "1300 Rancho Conejo Blvd",
            "street2": "",
            "city": "Thousand Oaks",
            "region": "CA",
            "postal_code": "91320",
            "lat": 34.1980097,
            "lon": -118.9214156,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "26dec1df-3dfd-4291-9716-1d7ad312b7be"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "908be7a0-e245-4da6-b1fe-d150f39d4153",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Capsida Biotherapeutics",
      "_label": "Capsida Biotherapeutics",
      "_name": "Newbury Park, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4494,
      "name": "Newbury Park, CA*",
      "correlation_id": "101811",
      "billing_address": null,
      "start_date": "2021-11-15T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1815,
        "name": "Latigo Bio",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ba967f4b-8fda-4ba1-acf9-79bc6167ce20"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2284,
        "code": "America/Toronto",
        "display_name": "America/Toronto",
        "description": "",
        "child_group": null,
        "alt_sequence": 150
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "25a1ec67-441f-49d0-bbc7-9614083dd66f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Latigo Bio",
      "_label": "Latigo Bio",
      "_name": "Newbury Park, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4190,
      "name": "New Century, KS",
      "correlation_id": "102049",
      "billing_address": {
        "id": 2132,
        "street1": "27080 W 159TH ST",
        "street2": "",
        "city": null,
        "region": "Kansas",
        "postal_code": "66031",
        "lat": 38.8400521,
        "lon": -94.8711918,
        "country": "United States"
      },
      "start_date": "2022-07-11T17:14:44Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1579,
        "name": "Upfield",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "75059fa8-53bb-4f9b-91ab-e2acba0591ec"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1a5c2c5a-ec37-443a-bc42-4732ef3ef20f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Upfield",
      "_label": "Upfield",
      "_name": "New Century, KS",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2564,
      "name": "Newport Beach",
      "correlation_id": "102174",
      "billing_address": {
        "id": 550,
        "street1": "16 Corporate Plaza Dr",
        "street2": "",
        "city": "Newport Beach",
        "region": "California",
        "postal_code": "92660",
        "lat": 33.6104788,
        "lon": -117.8772493,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "936cf19f-c8fa-4ad3-9266-800dbf78208f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Newport Beach",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1505,
      "name": "New Stanton",
      "correlation_id": "10581",
      "billing_address": {
        "id": 685,
        "street1": "1000 Westinghouse Dr",
        "street2": "",
        "city": "New Station",
        "region": "Pennsylvania",
        "postal_code": "15672",
        "lat": 40.2279178,
        "lon": -79.6191335,
        "country": "United States"
      },
      "start_date": "2016-04-15T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 40.228112,
      "lon": -79.618628,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3fae57f9-4569-45df-a515-c9be52db433f",
      "is_mobile": true,
      "mobile_live_date": "2021-08-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "New Stanton",
      "_lat": 40.228112,
      "_lng": -79.618628,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -79.618628
    },
    {
      "id": 5714,
      "name": "New York",
      "correlation_id": "102580",
      "billing_address": {
        "id": 3622,
        "street1": "1 Columbus Circle",
        "street2": "",
        "city": "New York",
        "region": "New York",
        "postal_code": null,
        "lat": 40.76847,
        "lon": -73.983092,
        "country": "United States"
      },
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 468,
        "name": "Deutsche Bank",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
      },
      "parent_id": null,
      "lat": 40.76822,
      "lon": -73.98236,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
      "is_mobile": true,
      "mobile_live_date": "2023-09-22",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Deutsche Bank",
      "_label": "Deutsche Bank",
      "_name": "New York",
      "_lat": 40.76822,
      "_lng": -73.98236,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10772753,
          "create_date": "2024-02-21T23:24:53.738860Z",
          "update_date": "2024-02-21T23:24:53.738883Z",
          "organization_id": 468,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
          "size": 139120,
          "description": null,
          "link": null,
          "unique_identifier": "dc7aa29633644a68beb3aeb2abd0e74a"
        }
      ],
      "lng": -73.98236
    },
    {
      "id": 2826,
      "name": "NIKE00OVRHD",
      "correlation_id": "101607",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1137bd69-03ef-4800-9720-9e4b5be6795b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "NIKE00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1278,
      "name": "Norfolk, VA (Concourse)",
      "correlation_id": "10250",
      "billing_address": {
        "id": 408,
        "street1": "5800 Northampton Blvd",
        "street2": "",
        "city": "Norfolk",
        "region": "Virginia",
        "postal_code": "23502",
        "lat": 36.8765993,
        "lon": -76.2057367,
        "country": "United States"
      },
      "start_date": "2020-07-01T23:59:59Z",
      "total_sq_ft": "325000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 36.8764207,
      "lon": -76.2059874,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "009aba70-a918-4775-be46-3ebdc78b7b12",
      "is_mobile": true,
      "mobile_live_date": "2020-10-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Norfolk, VA (Concourse)",
      "_lat": 36.8764207,
      "_lng": -76.2059874,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -76.2059874
    },
    {
      "id": 1815,
      "name": "Norte",
      "correlation_id": "102706",
      "billing_address": null,
      "start_date": "2023-03-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.58624,
      "lon": -111.92705,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "92d39c75-3b17-4076-b411-4f84bd97f2a8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Norte",
      "_lat": 33.58624,
      "_lng": -111.92705,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -111.92705
    },
    {
      "id": 4447,
      "name": "North Dakota (CBRE)",
      "correlation_id": "101963",
      "billing_address": null,
      "start_date": "2022-08-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2174,
        "code": "America/Chihuahua",
        "display_name": "America/Chihuahua",
        "description": "",
        "child_group": null,
        "alt_sequence": 40
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b2771291-3b98-47c4-bb33-3844fb643bd2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "North Dakota (CBRE)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": 0
    },
    {
      "id": 2870,
      "name": "North Denver Metro",
      "correlation_id": "101720",
      "billing_address": {
        "id": 1112,
        "street1": "6304 Spine Rd",
        "street2": "",
        "city": "Boulder",
        "region": "Colorado",
        "postal_code": "80301",
        "lat": 40.0665445,
        "lon": -105.2050372,
        "country": "United States"
      },
      "start_date": "2022-02-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 40.0665445,
      "lon": -105.2050372,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "68452acc-9048-4879-8f7c-5bbbb70ba668",
      "is_mobile": true,
      "mobile_live_date": "2022-04-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "North Denver Metro",
      "_lat": 40.0665445,
      "_lng": -105.2050372,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -105.2050372
    },
    {
      "id": 1245,
      "name": "North Haven",
      "correlation_id": "10200",
      "billing_address": {
        "id": 496,
        "street1": "195 McDermott RD",
        "street2": "",
        "city": "North Haven",
        "region": "Connecticut",
        "postal_code": "06473",
        "lat": 41.3383585,
        "lon": -72.8701319,
        "country": "United States"
      },
      "start_date": "2021-06-04T23:59:59Z",
      "total_sq_ft": "4400000",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 58,
        "name": "Medtronic",
        "type": "CUSTOMER",
        "correlation_id": "10187",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b5aa6b6c-f610-4333-be04-fee2fb181a8e"
      },
      "parent_id": null,
      "lat": 41.3388905,
      "lon": -72.8718716,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7069b654-e325-4bc5-b0ec-1cd971f8b7a9",
      "is_mobile": true,
      "mobile_live_date": "2021-02-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Medtronic",
      "_label": "Medtronic",
      "_name": "North Haven",
      "_lat": 41.3388905,
      "_lng": -72.8718716,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -72.8718716
    },
    {
      "id": 2610,
      "name": "North Kingstown",
      "correlation_id": "101546",
      "billing_address": {
        "id": 720,
        "street1": "60 Compass Cir",
        "street2": "",
        "city": "North Kingston",
        "region": "Rhode Island",
        "postal_code": "02852",
        "lat": 41.6021341,
        "lon": -71.4663635,
        "country": "United States"
      },
      "start_date": "2021-09-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 443,
        "name": "Cargill",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 303,
            "street1": "200 CHOCOLATE AVENUE",
            "street2": "",
            "city": "Mount Joy",
            "region": "Pennsylvania",
            "postal_code": "17552",
            "lat": 40.1119,
            "lon": -76.5198066,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "cda36f33-0892-4c9a-82f3-0ba92e1349bf"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c889319e-83e9-4843-96f6-3f5e432cef66",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cargill",
      "_label": "Cargill",
      "_name": "North Kingstown",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2315,
      "name": "North Pointe (WorkSpace PM)",
      "correlation_id": "101301",
      "billing_address": {
        "id": 3744,
        "street1": "6380 Aviation Way",
        "street2": "",
        "city": "West Chester",
        "region": "Ohio",
        "postal_code": null,
        "lat": 39.3219022,
        "lon": -84.4147224,
        "country": "United States"
      },
      "start_date": "2021-01-09T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 213,
        "name": "GE Aerospace",
        "type": "CUSTOMER",
        "correlation_id": "11498",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "61eaddb8-61f4-40e4-9727-b506c2ead4b8"
      },
      "parent_id": null,
      "lat": 39.32208,
      "lon": -84.41324,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fc02c897-2498-4f53-b6a6-93acbcc6ce00",
      "is_mobile": true,
      "mobile_live_date": "2021-07-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Aerospace",
      "_label": "GE Aerospace",
      "_name": "North Pointe (WorkSpace PM)",
      "_lat": 39.32208,
      "_lng": -84.41324,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686674,
          "create_date": "2024-02-16T12:21:32.358222Z",
          "update_date": "2024-02-16T12:21:32.358246Z",
          "organization_id": 213,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/44297d2e8bcc4181a7a638209c802e22_Screenshot20240216at07.20_t.51",
          "size": 74886,
          "description": null,
          "link": null,
          "unique_identifier": "44297d2e8bcc4181a7a638209c802e22"
        }
      ],
      "lng": -84.41324
    },
    {
      "id": 1317,
      "name": "Northridge",
      "correlation_id": "10314",
      "billing_address": {
        "id": 497,
        "street1": "08000 Devonshire St",
        "street2": "",
        "city": "Northridge",
        "region": "California",
        "postal_code": "91325",
        "lat": 34.2573922,
        "lon": -118.4806952,
        "country": "United States"
      },
      "start_date": "2021-06-04T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 58,
        "name": "Medtronic",
        "type": "CUSTOMER",
        "correlation_id": "10187",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b5aa6b6c-f610-4333-be04-fee2fb181a8e"
      },
      "parent_id": null,
      "lat": 34.2557931,
      "lon": -118.5252899,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6b82568b-2559-40fd-b545-f9d32255788c",
      "is_mobile": true,
      "mobile_live_date": "2021-04-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Medtronic",
      "_label": "Medtronic",
      "_name": "Northridge",
      "_lat": 34.2557931,
      "_lng": -118.5252899,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -118.5252899
    },
    {
      "id": 1826,
      "name": "Northridge",
      "correlation_id": "101296",
      "billing_address": null,
      "start_date": "2004-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 329,
        "name": "Kindeva Drug Delivery",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a18e2144-27bd-44e3-ad03-a005990779ef"
      },
      "parent_id": null,
      "lat": 37.7339786,
      "lon": -122.4409263,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "05b88509-c157-4c36-ab7e-d58cbba36bf5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Kindeva Drug Delivery",
      "_label": "Kindeva Drug Delivery",
      "_name": "Northridge",
      "_lat": 37.7339786,
      "_lng": -122.4409263,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.4409263
    },
    {
      "id": 1802,
      "name": "North Valley",
      "correlation_id": "102704",
      "billing_address": {
        "id": 4101,
        "street1": "38 North Central Avenue",
        "street2": "",
        "city": "Phoenix",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.4489064,
        "lon": -112.0742571,
        "country": "United States"
      },
      "start_date": "2019-07-15T15:08:23Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.4484,
      "lon": -112.074,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1140ded6-7083-44f9-86e0-5374fc09baa4",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "North Valley",
      "_lat": 33.4484,
      "_lng": -112.074,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -112.074
    },
    {
      "id": 1491,
      "name": "North Wales, PA",
      "correlation_id": "10077",
      "billing_address": {
        "id": 506,
        "street1": "351 North Sumneytown Pike",
        "street2": "",
        "city": "North Wales",
        "region": "Pennsylvania",
        "postal_code": "19454",
        "lat": 40.215304,
        "lon": -75.285274,
        "country": "United States"
      },
      "start_date": "2010-07-01T23:59:59Z",
      "total_sq_ft": "150000",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 267,
        "name": "Merck (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a"
      },
      "parent_id": null,
      "lat": 40.2180846,
      "lon": -75.2821322,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a153d850-6493-4210-a779-9ec041201f67",
      "is_mobile": true,
      "mobile_live_date": "2020-08-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck (CBRE)",
      "_label": "Merck (CBRE)",
      "_name": "North Wales, PA",
      "_lat": 40.2180846,
      "_lng": -75.2821322,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953527,
          "create_date": "2020-12-17T16:57:21.149904Z",
          "update_date": "2020-12-17T16:57:21.149930Z",
          "organization_id": 267,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -75.2821322
    },
    {
      "id": 5793,
      "name": "Northyard",
      "correlation_id": "102717",
      "billing_address": null,
      "start_date": "2023-07-01T22:18:54Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
      },
      "parent_id": null,
      "lat": 33.7676877,
      "lon": -84.4027842,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Coca Cola",
      "_label": "Coca Cola",
      "_name": "Northyard",
      "_lat": 33.7676877,
      "_lng": -84.4027842,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10560545,
          "create_date": "2024-02-08T17:24:10.318130Z",
          "update_date": "2024-02-08T17:24:10.318155Z",
          "organization_id": 2145,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
          "size": 22904,
          "description": null,
          "link": null,
          "unique_identifier": "9182f361e2ff41d3bded657bde583842"
        }
      ],
      "lng": -84.4027842
    },
    {
      "id": 2091,
      "name": "Norwood, MA",
      "correlation_id": "11696",
      "billing_address": null,
      "start_date": "2020-05-01T00:00:00Z",
      "total_sq_ft": "18432",
      "total_buildings": "44",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 286,
        "name": "Moderna (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11695",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7c787fca-6b84-455e-8aaf-eb3e25b6c09b"
      },
      "parent_id": null,
      "lat": 42.2074086,
      "lon": -71.2034589,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "18ae52f0-6462-4bde-8b23-fd56700b7766",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Moderna (JLL)",
      "_label": "Moderna (JLL)",
      "_name": "Norwood, MA",
      "_lat": 42.2074086,
      "_lng": -71.2034589,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -71.2034589
    },
    {
      "id": 2562,
      "name": "Novato",
      "correlation_id": "102175",
      "billing_address": {
        "id": 548,
        "street1": "1700 McHenry Ave",
        "street2": "",
        "city": "Novato",
        "region": "California",
        "postal_code": "94945",
        "lat": 37.6691222,
        "lon": -120.990622,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2182,
        "code": "America/Dawson_Creek",
        "display_name": "America/Dawson_Creek",
        "description": "",
        "child_group": null,
        "alt_sequence": 48
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "59b6a70f-9d04-4f93-b093-e1c6b42f2c65",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Novato",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6029,
      "name": "NVID00OVRHD",
      "correlation_id": "102861",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a43dc3dc-272f-4386-9d19-3e6fbd1e9725",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "NVID00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4428,
      "name": "NVIDIA Singapore Development Pte Ltd*",
      "correlation_id": "102051",
      "billing_address": null,
      "start_date": "2022-08-09T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 356,
        "name": "Lam Research Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 113,
            "street1": "Plot 30, Hilir Sungai Kluang, Bayan Lepas Free Industrial Zone Phase 4",
            "street2": "",
            "city": "Penang",
            "region": "Malaysia",
            "postal_code": "11900",
            "lat": 5.3073182,
            "lon": 100.2956805,
            "country": "Malawi"
          }
        ],
        "status": "Unsuspended",
        "uuid": "3de6db48-a8da-44a6-bf98-b258c0812b58"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dd2529a4-4d79-403c-ae99-5cc981284529",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lam Research Malaysia (JLL)",
      "_label": "Lam Research Malaysia (JLL)",
      "_name": "NVIDIA Singapore Development Pte Ltd*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1768,
      "name": "Oak Hill, NY",
      "correlation_id": "11475",
      "billing_address": {
        "id": 462,
        "street1": "3169 NY-145",
        "street2": "",
        "city": "East Durham",
        "region": "New York",
        "postal_code": "12423",
        "lat": 42.3838465,
        "lon": -74.1244651,
        "country": "United States"
      },
      "start_date": "2019-03-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 33,
        "name": "GSK (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10264",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4218fd8e-cac0-4db7-a800-73823d3df849"
      },
      "parent_id": null,
      "lat": 40.73061,
      "lon": -73.935242,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
      "is_mobile": true,
      "mobile_live_date": "2021-03-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GSK (CBRE)",
      "_label": "GSK (CBRE)",
      "_name": "Oak Hill, NY",
      "_lat": 40.73061,
      "_lng": -73.935242,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 940392,
          "create_date": "2020-12-14T15:55:53.832689Z",
          "update_date": "2020-12-14T15:55:53.832715Z",
          "organization_id": 33,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607961353.7332714_GSK_t.jpeg",
          "size": 8042,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -73.935242
    },
    {
      "id": 2439,
      "name": "Oakland, CA",
      "correlation_id": "101366",
      "billing_address": {
        "id": 261,
        "street1": "1221 Broadway",
        "street2": "",
        "city": "Oakland",
        "region": "California",
        "postal_code": "94612",
        "lat": 37.8034631,
        "lon": -122.272345,
        "country": "United States"
      },
      "start_date": "2021-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 90,
        "name": "Clorox (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10394",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f62cf9fa-612e-44fa-9813-ce2607cc371f"
      },
      "parent_id": null,
      "lat": 37.804829,
      "lon": -122.272476,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
      "is_mobile": true,
      "mobile_live_date": "2021-08-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Clorox (CBRE)",
      "_label": "Clorox (CBRE)",
      "_name": "Oakland, CA",
      "_lat": 37.804829,
      "_lng": -122.272476,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859890,
          "create_date": "2020-11-17T14:46:45.795075Z",
          "update_date": "2020-11-17T14:46:45.795102Z",
          "organization_id": 90,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
          "size": 10109,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.272476
    },
    {
      "id": 2665,
      "name": "Oakland (JLL)",
      "correlation_id": "101621",
      "billing_address": {
        "id": 796,
        "street1": "3310 E 12th St.",
        "street2": "",
        "city": "Oakland",
        "region": "California",
        "postal_code": "94601",
        "lat": 37.7763899,
        "lon": -122.2247898,
        "country": "United States"
      },
      "start_date": "2021-11-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8ff9796e-d762-4ba7-95ed-48f3451fa3b1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Oakland (JLL)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 1070,
      "name": "Oakley Station",
      "correlation_id": "10850",
      "billing_address": {
        "id": 751,
        "street1": "3075 VANDERCAR WAY",
        "street2": "",
        "city": "Cincinnati",
        "region": "Ohio",
        "postal_code": "45209",
        "lat": 39.1581933,
        "lon": -84.4301497,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 39.1570141,
      "lon": -84.4273739,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f0074666-0561-4294-b3d9-6a6eea89297b",
      "is_mobile": true,
      "mobile_live_date": "2021-09-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Oakley Station",
      "_lat": 39.1570141,
      "_lng": -84.4273739,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -84.4273739
    },
    {
      "id": 5852,
      "name": "OB1 – Office Building 1 – Dearborn, MI",
      "correlation_id": "102787",
      "billing_address": {
        "id": 3966,
        "street1": "20000 Rotunda Dr",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2948836,
        "lon": -83.2233969,
        "country": "United States"
      },
      "start_date": "2020-11-09T20:36:35Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2948836,
      "lon": -83.2233969,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB1 – Office Building 1 – Dearborn, MI",
      "_lat": 42.2948836,
      "_lng": -83.2233969,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2233969
    },
    {
      "id": 5853,
      "name": "OB2 – Office Building 2 – Dearborn, MI",
      "correlation_id": "102786",
      "billing_address": {
        "id": 3961,
        "street1": "20000 Rotunda Dr",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2948836,
        "lon": -83.2233969,
        "country": "United States"
      },
      "start_date": "2020-11-09T20:25:25Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2948836,
      "lon": -83.2233969,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB2 – Office Building 2 – Dearborn, MI",
      "_lat": 42.2948836,
      "_lng": -83.2233969,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2233969
    },
    {
      "id": 5854,
      "name": "OB3 – Office Building 3 – Dearborn, MI",
      "correlation_id": "102808",
      "billing_address": null,
      "start_date": "2020-11-09T14:51:51Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.294421,
      "lon": -83.2279312,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB3 – Office Building 3 – Dearborn, MI",
      "_lat": 42.294421,
      "_lng": -83.2279312,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2279312
    },
    {
      "id": 5855,
      "name": "OB4 – Office Building 4 – Dearborn, MI",
      "correlation_id": "102811",
      "billing_address": null,
      "start_date": "2020-11-09T14:52:23Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2948836,
      "lon": -83.2233969,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB4 – Office Building 4 – Dearborn, MI",
      "_lat": 42.2948836,
      "_lng": -83.2233969,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2233969
    },
    {
      "id": 5856,
      "name": "OB5 – Office Building 5 – Dearborn, MI",
      "correlation_id": "102788",
      "billing_address": null,
      "start_date": "2020-11-09T14:53:12Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2931898,
      "lon": -83.2304411,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB5 – Office Building 5 – Dearborn, MI",
      "_lat": 42.2931898,
      "_lng": -83.2304411,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2304411
    },
    {
      "id": 5857,
      "name": "OB6 – Office Building 6 – Dearborn, MI",
      "correlation_id": "102812",
      "billing_address": {
        "id": 3951,
        "street1": "20600 Rotunda Dr",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2924974,
        "lon": -83.2342895,
        "country": "United States"
      },
      "start_date": "2020-11-09T19:09:38Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2924974,
      "lon": -83.2342895,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OB6 – Office Building 6 – Dearborn, MI",
      "_lat": 42.2924974,
      "_lng": -83.2342895,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2342895
    },
    {
      "id": 5452,
      "name": "Ocean",
      "correlation_id": "102343",
      "billing_address": {
        "id": 2923,
        "street1": "4800 Sand Point Way NE",
        "street2": "",
        "city": null,
        "region": "Washington",
        "postal_code": "98105",
        "lat": 47.6627975,
        "lon": -122.2818514,
        "country": "United States"
      },
      "start_date": "2022-07-11T17:13:52Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2097,
        "name": "Seattle Children's Hospital",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 2922,
            "street1": "4800 Sand Point Way NE",
            "street2": "",
            "city": "Seattle",
            "region": "WA",
            "postal_code": "98105",
            "lat": 47.6627975,
            "lon": -122.2818514,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bd89fb80-3d00-48e4-92cd-6c42e880e426"
      },
      "parent_id": null,
      "lat": 47.6629251,
      "lon": -122.281639,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17cda697-28fc-4b0d-b85e-80646338900d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Seattle Children's Hospital",
      "_label": "Seattle Children's Hospital",
      "_name": "Ocean",
      "_lat": 47.6629251,
      "_lng": -122.281639,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.281639
    },
    {
      "id": 5,
      "name": "Oceanside, CA",
      "correlation_id": "10351",
      "billing_address": null,
      "start_date": "2011-09-02T23:59:59Z",
      "total_sq_ft": "393900",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 33.2078462,
      "lon": -117.2953202,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5b114a54-6799-446e-a5be-a314b256fc01",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "Oceanside, CA",
      "_lat": 33.2078462,
      "_lng": -117.2953202,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.2953202
    },
    {
      "id": 1838,
      "name": "Ocotillo",
      "correlation_id": "101829",
      "billing_address": {
        "id": 85,
        "street1": "4500 S Dobson Rd.",
        "street2": "",
        "city": "Chandler",
        "region": "Arizona",
        "postal_code": "85248",
        "lat": 33.2451845,
        "lon": -111.8902297,
        "country": "United States"
      },
      "start_date": "2016-12-01T23:59:59Z",
      "total_sq_ft": "6746037",
      "total_buildings": "51",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 33.2440288,
      "lon": -111.8849714,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e5b59793-64b4-4823-b9ab-034d1a8a9f79",
      "is_mobile": true,
      "mobile_live_date": "2020-12-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ocotillo",
      "_lat": 33.2440288,
      "_lng": -111.8849714,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.8849714
    },
    {
      "id": 2625,
      "name": "O'Fallon, MO",
      "correlation_id": "101585",
      "billing_address": {
        "id": 761,
        "street1": "3801 Lloyd King Drive",
        "street2": "",
        "city": "OFallon",
        "region": "Missouri",
        "postal_code": "63368",
        "lat": 38.7206152,
        "lon": -90.7205431,
        "country": "United States"
      },
      "start_date": "2021-09-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 67,
        "name": "Boeing (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10099",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e8869095-120a-40bb-9623-9a295b3c35fc"
      },
      "parent_id": null,
      "lat": 38.7206152,
      "lon": -90.7205431,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3775a06b-c859-4221-a302-e18b736f1281",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boeing (JLL)",
      "_label": "Boeing (JLL)",
      "_name": "O'Fallon, MO",
      "_lat": 38.7206152,
      "_lng": -90.7205431,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686555,
          "create_date": "2024-02-16T12:07:15.732131Z",
          "update_date": "2024-02-16T12:07:15.732157Z",
          "organization_id": 67,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/a30cc21dbcdd4a3da4c5ab635437c3ae_boeingpnglogo568134_t.png",
          "size": 140591,
          "description": null,
          "link": null,
          "unique_identifier": "a30cc21dbcdd4a3da4c5ab635437c3ae"
        }
      ],
      "lng": -90.7205431
    },
    {
      "id": 6520,
      "name": "O'Fallon, MO",
      "correlation_id": "103369",
      "billing_address": {
        "id": 4099,
        "street1": "1000 Technology Drive",
        "street2": "",
        "city": "O’Fallon",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.7172629,
        "lon": -90.692874,
        "country": "United States"
      },
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2119,
        "name": "Citibank (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7de7790c-ee0d-4d61-8ca4-dd89a3622350"
      },
      "parent_id": null,
      "lat": 38.71773,
      "lon": -90.70228,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dae9da19-b832-4986-a68a-3dcbeab69de9",
      "is_mobile": true,
      "mobile_live_date": "2024-01-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Citibank (C&W)",
      "_label": "Citibank (C&W)",
      "_name": "O'Fallon, MO",
      "_lat": 38.71773,
      "_lng": -90.70228,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -90.70228
    },
    {
      "id": 6759,
      "name": "Offsites",
      "correlation_id": "103419",
      "billing_address": {
        "id": 4105,
        "street1": "5345 Decatur Blvd.",
        "street2": "",
        "city": "Indianapolis",
        "region": "Indiana",
        "postal_code": null,
        "lat": 39.6849963,
        "lon": -86.2908808,
        "country": "United States"
      },
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 39.68517,
      "lon": -86.2897,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dc7fbd07-caad-4a83-a75d-158db7dfffbe",
      "is_mobile": true,
      "mobile_live_date": "2024-01-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "Offsites",
      "_lat": 39.68517,
      "_lng": -86.2897,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": -86.2897
    },
    {
      "id": 1794,
      "name": "Ogden",
      "correlation_id": "11516",
      "billing_address": {
        "id": 1119,
        "street1": "10,000 W 900 S",
        "street2": "",
        "city": "Ogden",
        "region": "Utah",
        "postal_code": "84404",
        "lat": 41.2501308,
        "lon": -112.2166786,
        "country": "United States"
      },
      "start_date": "2019-06-08T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "14",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 41.2607466,
      "lon": -112.2315724,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "54fa2db3-2978-4079-b7a5-7face82c5b29",
      "is_mobile": true,
      "mobile_live_date": "2021-12-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Ogden",
      "_lat": 41.2607466,
      "_lng": -112.2315724,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -112.2315724
    },
    {
      "id": 5797,
      "name": "OHAP – Ohio Assembly Plant – Avon Lake, OH",
      "correlation_id": "102726",
      "billing_address": {
        "id": 3786,
        "street1": "650 Miller Rd.",
        "street2": "",
        "city": "Avon Lake",
        "region": "Ohio",
        "postal_code": null,
        "lat": 41.4875341,
        "lon": -82.0645478,
        "country": "United States"
      },
      "start_date": "2023-10-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 41.48311,
      "lon": -82.06364,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1f3084fd-8ac6-41f4-a224-8ed7cbd74791",
      "is_mobile": true,
      "mobile_live_date": "2023-09-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "OHAP – Ohio Assembly Plant – Avon Lake, OH",
      "_lat": 41.48311,
      "_lng": -82.06364,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -82.06364
    },
    {
      "id": 1343,
      "name": "Ohio",
      "correlation_id": "10378",
      "billing_address": {
        "id": 418,
        "street1": "9569 Childrens Dr",
        "street2": "",
        "city": "Mason",
        "region": "Ohio",
        "postal_code": "45040",
        "lat": 39.2997397,
        "lon": -84.311162,
        "country": "United States"
      },
      "start_date": "2005-04-01T23:59:59Z",
      "total_sq_ft": "1391981",
      "total_buildings": "27",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 88,
        "name": "Cincinnati Children's Hospital",
        "type": "CUSTOMER",
        "correlation_id": "10358",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b49cec04-f00e-4c40-af08-2eb998375503"
      },
      "parent_id": null,
      "lat": 39.1329744,
      "lon": -84.4933883,
      "local_timezone": {
        "id": 2205,
        "code": "America/Indiana/Knox",
        "display_name": "America/Indiana/Knox",
        "description": "",
        "child_group": null,
        "alt_sequence": 71
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76379d46-cb72-4979-a69d-1dc69c0c45ed",
      "is_mobile": true,
      "mobile_live_date": "2021-01-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cincinnati Children's Hospital",
      "_label": "Cincinnati Children's Hospital",
      "_name": "Ohio",
      "_lat": 39.1329744,
      "_lng": -84.4933883,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.4933883
    },
    {
      "id": 5498,
      "name": "Old Saint Anthony/OSA*",
      "correlation_id": "102322",
      "billing_address": {
        "id": 2995,
        "street1": "425 East Hennepin Avenue",
        "street2": "",
        "city": "Minneapolis",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.989117,
        "lon": -93.2539718,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "27822",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 44.98881,
      "lon": -93.25391,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Old Saint Anthony/OSA*",
      "_lat": 44.98881,
      "_lng": -93.25391,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.25391
    },
    {
      "id": 3677,
      "name": "Old Winery(WineII)*",
      "correlation_id": "101746",
      "billing_address": null,
      "start_date": "2022-05-31T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 793,
        "name": "Fite Development Company",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e11be881-b051-4fc1-a5c7-293bc28f0b09"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "12052e12-3226-4253-bc1f-3fecb537d19f",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Fite Development Company",
      "_label": "Fite Development Company",
      "_name": "Old Winery(WineII)*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4228,
      "name": "Ontario",
      "correlation_id": "101866",
      "billing_address": {
        "id": 2170,
        "street1": "5100 S. Archibald Ave",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "91762",
        "lat": 33.979414,
        "lon": -117.5939269,
        "country": "United States"
      },
      "start_date": "2021-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 754,
        "name": "Wieland",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "638821ce-9ba3-4fed-81e0-f810ef2c1900"
      },
      "parent_id": null,
      "lat": 33.979414,
      "lon": -117.5939269,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3b6ca9a-3cf1-4171-96ea-9f7809c6c586",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wieland",
      "_label": "Wieland",
      "_name": "Ontario",
      "_lat": 33.979414,
      "_lng": -117.5939269,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.5939269
    },
    {
      "id": 5562,
      "name": "Ops Management (CBRE)",
      "correlation_id": "102392",
      "billing_address": null,
      "start_date": "2023-01-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e1c0214f-7580-4ed4-9545-42f2a416d8f0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "Ops Management (CBRE)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3468,
      "name": "OPSMGM0CENT",
      "correlation_id": "102070",
      "billing_address": null,
      "start_date": "2022-05-03T17:54:09Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5f08a2aa-5b4e-4a99-b2df-a77acc8b460d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "OPSMGM0CENT",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6030,
      "name": "OPSMGMWEST2",
      "correlation_id": "102862",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a88f3c15-aa36-45c7-b55b-02c73f21db9a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "OPSMGMWEST2",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6008,
      "name": "OPST00OVDPW",
      "correlation_id": "102840",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "811dff07-c6d7-45a5-b5ee-f651388cf8a9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "OPST00OVDPW",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6007,
      "name": "OPST00OVRHD",
      "correlation_id": "102839",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "02ace2d1-5ebd-4edc-9973-decc2fbcbcc9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "OPST00OVRHD",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6670,
      "name": "ORD5 – Matteson, IL",
      "correlation_id": "103378",
      "billing_address": {
        "id": 4061,
        "street1": "7001 Vollmer Road",
        "street2": "",
        "city": "Matteson",
        "region": "Illinois",
        "postal_code": null,
        "lat": 41.5250764,
        "lon": -87.7876973,
        "country": "United States"
      },
      "start_date": "2024-02-20T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.524387,
      "lon": -87.78677,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6bc62a3c-113c-472f-b37a-08c2b97ce670",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "ORD5 – Matteson, IL",
      "_lat": 41.524387,
      "_lng": -87.78677,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -87.78677
    },
    {
      "id": 2841,
      "name": "Oregon",
      "correlation_id": "101742",
      "billing_address": null,
      "start_date": "2020-03-10T17:21:06Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 176,
        "name": "Newmark Knight Frank",
        "type": "CUSTOMER",
        "correlation_id": "11187",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40179cef-cf8e-40b0-a03d-6bc2af2187f3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9e53a626-ae83-43e1-81ff-e9f81549f232",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Newmark Knight Frank",
      "_label": "Newmark Knight Frank",
      "_name": "Oregon",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6028,
      "name": "OVRHDNOVART",
      "correlation_id": "102860",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1317b87c-28ae-42d4-ab90-b9e2b8c1591b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "OVRHDNOVART",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1845,
      "name": "Owings MLS (JLL)",
      "correlation_id": "10651",
      "billing_address": {
        "id": 3768,
        "street1": "100 East Pratt Steet",
        "street2": "",
        "city": "Baltimore",
        "region": "Maryland",
        "postal_code": null,
        "lat": 39.2869679,
        "lon": -76.6127337,
        "country": "United States"
      },
      "start_date": "2016-04-01T17:56:50Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 225,
        "name": "T. Rowe Price",
        "type": "CUSTOMER",
        "correlation_id": "10645",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "51af8ac6-48c2-4774-b844-4fdd26e2d6ea"
      },
      "parent_id": null,
      "lat": 39.396215,
      "lon": -76.785928,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9b7a9c36-f4b7-4a27-b54b-2909f4384cf9",
      "is_mobile": true,
      "mobile_live_date": "2023-05-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "T. Rowe Price",
      "_label": "T. Rowe Price",
      "_name": "Owings MLS (JLL)",
      "_lat": 39.396215,
      "_lng": -76.785928,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10769945,
          "create_date": "2024-02-21T20:47:06.306144Z",
          "update_date": "2024-02-21T20:47:06.306170Z",
          "organization_id": 225,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/464bbb059be54218b17cd131d05a3116_trowelogo_t.png",
          "size": 2901,
          "description": null,
          "link": null,
          "unique_identifier": "464bbb059be54218b17cd131d05a3116"
        }
      ],
      "lng": -76.785928
    },
    {
      "id": 1803,
      "name": "Palm Valley",
      "correlation_id": "102705",
      "billing_address": null,
      "start_date": "2023-03-29T20:25:15Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.46372,
      "lon": -112.34871,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "60b3860e-c67b-449f-be64-9bbcad37cebd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Palm Valley",
      "_lat": 33.46372,
      "_lng": -112.34871,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -112.34871
    },
    {
      "id": 5515,
      "name": "Palo Alto",
      "correlation_id": "102324",
      "billing_address": {
        "id": 3799,
        "street1": "301 University Ave.",
        "street2": "",
        "city": "Palo Alto",
        "region": "California",
        "postal_code": null,
        "lat": 37.4462304,
        "lon": -122.1616993,
        "country": "United States"
      },
      "start_date": "2023-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 38.5719034,
      "lon": -121.4177343,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
      "is_mobile": true,
      "mobile_live_date": "2023-10-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Palo Alto",
      "_lat": 38.5719034,
      "_lng": -121.4177343,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -121.4177343
    },
    {
      "id": 2526,
      "name": "Palo Alto, CA",
      "correlation_id": "101478",
      "billing_address": {
        "id": 524,
        "street1": "3251 Hanover Street",
        "street2": "",
        "city": "Palo Alto",
        "region": "California",
        "postal_code": "94304",
        "lat": 37.41121,
        "lon": -122.1432472,
        "country": "United States"
      },
      "start_date": "2022-01-25T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 37.41121,
      "lon": -122.1432472,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0dad2fd8-06fc-4692-8c31-526ed30247f2",
      "is_mobile": true,
      "mobile_live_date": "2021-09-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "Palo Alto, CA",
      "_lat": 37.41121,
      "_lng": -122.1432472,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.1432472
    },
    {
      "id": 1578,
      "name": "Palo Alto, CA (JLL)",
      "correlation_id": "11107",
      "billing_address": {
        "id": 560,
        "street1": "401 Terry Ave N",
        "street2": "",
        "city": "Palo Alto",
        "region": "California",
        "postal_code": "98109",
        "lat": 37.4418834,
        "lon": -122.1430195,
        "country": "United States"
      },
      "start_date": "2018-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 37.4117131,
      "lon": -122.1488808,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db4c9a83-bd25-42b1-9ba3-55de3ad78ea5",
      "is_mobile": true,
      "mobile_live_date": "2021-06-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Palo Alto, CA (JLL)",
      "_lat": 37.4117131,
      "_lng": -122.1488808,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -122.1488808
    },
    {
      "id": 3168,
      "name": "Pandan",
      "correlation_id": "101848",
      "billing_address": null,
      "start_date": "2022-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2510,
        "name": "Iron Mountain (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "09ffb73b-dee9-4619-9580-56b73ddaf5bb"
      },
      "parent_id": null,
      "lat": 1.30712,
      "lon": 103.757683,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f2458701-4da0-4750-a35a-c6ea6b17dbc6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Iron Mountain (CBRE) (SG)",
      "_label": "Iron Mountain (CBRE) (SG)",
      "_name": "Pandan",
      "_lat": 1.30712,
      "_lng": 103.757683,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.757683
    },
    {
      "id": 2156,
      "name": "Pandemic Campus",
      "correlation_id": "101253",
      "billing_address": {
        "id": 437,
        "street1": "213 Rothwell Gymnasium",
        "street2": "",
        "city": "Columbia",
        "region": "Missouri",
        "postal_code": "95211",
        "lat": 49.080177,
        "lon": 2.108623,
        "country": "United States"
      },
      "start_date": "2020-10-09T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "101",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 114,
        "name": "University of Missouri",
        "type": "CUSTOMER",
        "correlation_id": "10476",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "92b95a16-8b66-4017-8f99-d31e475f4c43"
      },
      "parent_id": null,
      "lat": 38.94181,
      "lon": -92.3254,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8de12b00-3454-44a5-8581-8b271e7fcfb8",
      "is_mobile": true,
      "mobile_live_date": "2020-10-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "University of Missouri",
      "_label": "University of Missouri",
      "_name": "Pandemic Campus",
      "_lat": 38.94181,
      "_lng": -92.3254,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -92.3254
    },
    {
      "id": 1807,
      "name": "Paradise Valley",
      "correlation_id": "102629",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.55215,
      "lon": -111.960845,
      "local_timezone": {
        "id": 2181,
        "code": "America/Dawson",
        "display_name": "America/Dawson",
        "description": "",
        "child_group": null,
        "alt_sequence": 47
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ec867db4-3974-42ce-bc29-5d61f69ae6a8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Paradise Valley",
      "_lat": 33.55215,
      "_lng": -111.960845,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.960845
    },
    {
      "id": 2831,
      "name": "Parole / Immuno - GOTHE*",
      "correlation_id": "101802",
      "billing_address": {
        "id": 2501,
        "street1": "9825 Gothe Rd",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": null,
        "lat": 38.5546102,
        "lon": -121.3276242,
        "country": "United States"
      },
      "start_date": "2010-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1817,
        "name": "Kilmainham",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a0b8c6ca-eba6-44b1-b660-068867e03293"
      },
      "parent_id": null,
      "lat": 38.5546102,
      "lon": -121.3276242,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4e21e3d8-08eb-45e7-801c-75a822065836",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Kilmainham",
      "_label": "Kilmainham",
      "_name": "Parole / Immuno - GOTHE*",
      "_lat": 38.5546102,
      "_lng": -121.3276242,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3276242
    },
    {
      "id": 1811,
      "name": "Paseo",
      "correlation_id": "102630",
      "billing_address": null,
      "start_date": "2019-07-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.608367,
      "lon": -112.1854029,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "685ec8d0-0246-4b4c-baa1-6c43c9d2acbf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Paseo",
      "_lat": 33.608367,
      "_lng": -112.1854029,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -112.1854029
    },
    {
      "id": 1840,
      "name": "Patheon API",
      "correlation_id": "10642",
      "billing_address": null,
      "start_date": "2012-09-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2152,
        "name": "Thermo Fisher Scientific",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9be8014b-8fdb-4abe-8d17-562a8782873b"
      },
      "parent_id": null,
      "lat": 34.2054107,
      "lon": -79.6010548,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c695b6f0-8cb6-4f6c-9622-3658c9b4e155",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Thermo Fisher Scientific",
      "_label": "Thermo Fisher Scientific",
      "_name": "Patheon API",
      "_lat": 34.2054107,
      "_lng": -79.6010548,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 7670875,
          "create_date": "2023-08-15T20:48:02.132597Z",
          "update_date": "2023-08-15T20:48:02.132627Z",
          "organization_id": 2152,
          "uploaded_by_id": 14445,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/f8db466f29bd4ce98656bf3c7c0f303a_download_t.png",
          "size": 3648,
          "description": null,
          "link": null,
          "unique_identifier": "f8db466f29bd4ce98656bf3c7c0f303a"
        }
      ],
      "lng": -79.6010548
    },
    {
      "id": 1451,
      "name": "Pawtucket",
      "correlation_id": "10570",
      "billing_address": null,
      "start_date": "2018-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 129,
        "name": "Hasbro (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10569",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "432db518-6de6-4823-a9d5-c8589c848920"
      },
      "parent_id": null,
      "lat": 41.883631,
      "lon": -71.353007,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "256dabe1-517b-44f4-a349-a5b5ae2e084a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hasbro (JLL)",
      "_label": "Hasbro (JLL)",
      "_name": "Pawtucket",
      "_lat": 41.883631,
      "_lng": -71.353007,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026237,
          "create_date": "2024-03-07T18:22:00.313766Z",
          "update_date": "2024-03-07T18:22:00.313790Z",
          "organization_id": 129,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/7c7c1e41c753408d83b43be30ae995f6_Hasbro_logosvg_t.png",
          "size": 151554,
          "description": null,
          "link": null,
          "unique_identifier": "7c7c1e41c753408d83b43be30ae995f6"
        }
      ],
      "lng": -71.353007
    },
    {
      "id": 6674,
      "name": "PCW1 – Rossford, OH",
      "correlation_id": "103383",
      "billing_address": {
        "id": 4084,
        "street1": "27400 Crossroads Pkw",
        "street2": "",
        "city": "Rossford",
        "region": "Ohio",
        "postal_code": null,
        "lat": 41.5481199,
        "lon": -83.5797143,
        "country": "United States"
      },
      "start_date": "2024-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 41.547934,
      "lon": -83.577847,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b413cf1a-093c-4fdc-a6b7-53ee0ca4cd63",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "PCW1 – Rossford, OH",
      "_lat": 41.547934,
      "_lng": -83.577847,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.577847
    },
    {
      "id": 1932,
      "name": "PDC - Boston - Mansfield, MA",
      "correlation_id": "101681",
      "billing_address": {
        "id": 878,
        "street1": "550 Forbes Blvd",
        "street2": "",
        "city": "Mansfield",
        "region": "Massachusetts",
        "postal_code": "02048",
        "lat": 42.0122045,
        "lon": -71.2371077,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.0135408,
      "lon": -71.2371077,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "28d2cb18-b541-44c0-9ae4-8f43e4c94b1d",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Boston - Mansfield, MA",
      "_lat": 42.0135408,
      "_lng": -71.2371077,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -71.2371077
    },
    {
      "id": 1933,
      "name": "PDC - Centerline - Center Line, MI",
      "correlation_id": "101707",
      "billing_address": {
        "id": 888,
        "street1": "26025-26999 Liberal St",
        "street2": "",
        "city": "Center Line",
        "region": "Michigan",
        "postal_code": "48015",
        "lat": 42.4881989,
        "lon": -83.0340983,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "96",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.4881989,
      "lon": -83.0340983,
      "local_timezone": {
        "id": 2224,
        "code": "America/Louisville",
        "display_name": "America/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 90
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0b8579c9-e6b6-433e-b11c-a880f13a5bf4",
      "is_mobile": true,
      "mobile_live_date": "2022-04-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Centerline - Center Line, MI",
      "_lat": 42.4881989,
      "_lng": -83.0340983,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.0340983
    },
    {
      "id": 1934,
      "name": "PDC - Chicago - Naperville, IL",
      "correlation_id": "101715",
      "billing_address": {
        "id": 893,
        "street1": "1980 High Grove Ln",
        "street2": "",
        "city": "Naperville",
        "region": "Illinois",
        "postal_code": "60540",
        "lat": 41.7742999,
        "lon": -88.201983,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 41.7742999,
      "lon": -88.201983,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "81917958-c245-4e0b-a4aa-717d5f38c389",
      "is_mobile": true,
      "mobile_live_date": "2022-05-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Chicago - Naperville, IL",
      "_lat": 41.7742999,
      "_lng": -88.201983,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -88.201983
    },
    {
      "id": 1935,
      "name": "PDC - Cleveland - Streetsboro, OH",
      "correlation_id": "101686",
      "billing_address": {
        "id": 885,
        "street1": "9777 Mopar Dr",
        "street2": "",
        "city": "Streetsboro",
        "region": "Ohio",
        "postal_code": "44241",
        "lat": 41.2526135,
        "lon": -81.375804,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 41.2526135,
      "lon": -81.375804,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "71c42ccd-c182-439a-b55b-c263eb69eacb",
      "is_mobile": true,
      "mobile_live_date": "2022-05-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Cleveland - Streetsboro, OH",
      "_lat": 41.2526135,
      "_lng": -81.375804,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -81.375804
    },
    {
      "id": 1937,
      "name": "PDC - Denver - Denver, CO",
      "correlation_id": "101718",
      "billing_address": {
        "id": 895,
        "street1": "12225 E 39th Ave",
        "street2": "",
        "city": "Denver",
        "region": "Colorado",
        "postal_code": "80239",
        "lat": 39.7723111,
        "lon": -104.8456443,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 39.7723111,
      "lon": -104.8456443,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "09f077d8-f5fb-461c-9a73-644ef68cb670",
      "is_mobile": true,
      "mobile_live_date": "2022-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Denver - Denver, CO",
      "_lat": 39.7723111,
      "_lng": -104.8456443,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -104.8456443
    },
    {
      "id": 1939,
      "name": "PDC - Marysville - Marysville, MI",
      "correlation_id": "101700",
      "billing_address": {
        "id": 886,
        "street1": "801-817 Huron Blvd",
        "street2": "",
        "city": "Marysville",
        "region": "Michigan",
        "postal_code": "48040",
        "lat": 42.9084142,
        "lon": -82.4714892,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.904154,
      "lon": -82.473308,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ea122df9-4fb2-4be0-b5ab-25970c2e8c4b",
      "is_mobile": true,
      "mobile_live_date": "2022-05-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Marysville - Marysville, MI",
      "_lat": 42.904154,
      "_lng": -82.473308,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -82.473308
    },
    {
      "id": 1941,
      "name": "PDC - Milwaukee - Milwaukee, WI",
      "correlation_id": "101714",
      "billing_address": {
        "id": 892,
        "street1": "3280 South Clement Ave",
        "street2": "",
        "city": "Milwaukee",
        "region": "Wisconsin",
        "postal_code": "53207",
        "lat": 42.984908,
        "lon": -87.8917273,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.984908,
      "lon": -87.8917273,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bef2458a-6628-4b46-9b69-2cc482ac7ed9",
      "is_mobile": true,
      "mobile_live_date": "2022-05-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Milwaukee - Milwaukee, WI",
      "_lat": 42.984908,
      "_lng": -87.8917273,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -87.8917273
    },
    {
      "id": 1942,
      "name": "PDC - Minneapolis - Plymouth, MN",
      "correlation_id": "101713",
      "billing_address": {
        "id": 890,
        "street1": "13005 Hwy 55",
        "street2": "",
        "city": "Plymouth",
        "region": "Minnesota",
        "postal_code": "55441",
        "lat": 45.0033987,
        "lon": -93.4466566,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 45.0033987,
      "lon": -93.4466566,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2ecf91d5-4ee4-4bde-9c60-64ec6ada1b8d",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Minneapolis - Plymouth, MN",
      "_lat": 45.0033987,
      "_lng": -93.4466566,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -93.4466566
    },
    {
      "id": 1944,
      "name": "PDC - Portland - Beaverton, OR",
      "correlation_id": "101719",
      "billing_address": {
        "id": 897,
        "street1": "10030 SW Allen Blvd",
        "street2": "",
        "city": "Beaverton",
        "region": "Oregon",
        "postal_code": "97005",
        "lat": 45.4731742,
        "lon": -122.779918,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 45.4731742,
      "lon": -122.779918,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b7a1b5cd-01c9-455b-b6ab-79387f45d7fa",
      "is_mobile": true,
      "mobile_live_date": "2022-04-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Portland - Beaverton, OR",
      "_lat": 45.4731742,
      "_lng": -122.779918,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -122.779918
    },
    {
      "id": 5870,
      "name": "PDC – Product Development Center - Dearborn, MI",
      "correlation_id": "102784",
      "billing_address": null,
      "start_date": "2020-11-09T15:07:19Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2971988,
      "lon": -83.2393097,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "PDC – Product Development Center - Dearborn, MI",
      "_lat": 42.2971988,
      "_lng": -83.2393097,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2393097
    },
    {
      "id": 1945,
      "name": "PDC - Romulus - Romulus, MI",
      "correlation_id": "101696",
      "billing_address": {
        "id": 818,
        "street1": "16950 Pine Rd.",
        "street2": "",
        "city": "Romulus",
        "region": "Michigan",
        "postal_code": "48174",
        "lat": 42.1832082,
        "lon": -83.3906846,
        "country": "United States"
      },
      "start_date": "2022-01-31T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.1875459,
      "lon": -83.3904495,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d668fa97-6075-417e-8de9-09cdaf1e153e",
      "is_mobile": true,
      "mobile_live_date": "2022-05-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "PDC - Romulus - Romulus, MI",
      "_lat": 42.1875459,
      "_lng": -83.3904495,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.3904495
    },
    {
      "id": 1598,
      "name": "Penang",
      "correlation_id": "11140",
      "billing_address": null,
      "start_date": "2018-02-01T00:00:00Z",
      "total_sq_ft": "189300",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 432,
        "name": "HP (MYS)(JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a6a65b1c-2fbd-4565-9c9d-ea25418750ec"
      },
      "parent_id": null,
      "lat": 5.283528,
      "lon": 100.4768579,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0c4625ef-6810-4203-b065-d55257b49bec",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP (MYS)(JLL)",
      "_label": "HP (MYS)(JLL)",
      "_name": "Penang",
      "_lat": 5.283528,
      "_lng": 100.4768579,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 4036962,
          "create_date": "2022-06-12T17:18:20.226676Z",
          "update_date": "2022-06-12T17:18:20.226700Z",
          "organization_id": 432,
          "uploaded_by_id": 39161,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/2af78a2731524afebe8f5a94faaf7f64_kamizi_t.jpg",
          "size": 235261,
          "description": null,
          "link": null,
          "unique_identifier": "2af78a2731524afebe8f5a94faaf7f64"
        }
      ],
      "lng": 100.4768579
    },
    {
      "id": 2337,
      "name": "Penang",
      "correlation_id": "101227",
      "billing_address": {
        "id": 114,
        "street1": "Plot 30, Hilir Sungai Kluang, Bayan Lepas Free Industrial Zone Phase 4",
        "street2": "",
        "city": "Penang",
        "region": "3177",
        "postal_code": "11900",
        "lat": 5.302469,
        "lon": 100.2931964,
        "country": "1937"
      },
      "start_date": "2021-01-11T00:00:00Z",
      "total_sq_ft": "36000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 356,
        "name": "Lam Research Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 113,
            "street1": "Plot 30, Hilir Sungai Kluang, Bayan Lepas Free Industrial Zone Phase 4",
            "street2": "",
            "city": "Penang",
            "region": "Malaysia",
            "postal_code": "11900",
            "lat": 5.3073182,
            "lon": 100.2956805,
            "country": "Malawi"
          }
        ],
        "status": "Unsuspended",
        "uuid": "3de6db48-a8da-44a6-bf98-b258c0812b58"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "526692b7-2838-4175-8c1b-86e64576a880",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lam Research Malaysia (JLL)",
      "_label": "Lam Research Malaysia (JLL)",
      "_name": "Penang",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2836,
      "name": "Penang",
      "correlation_id": "101471",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": "79125",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 637,
        "name": "Ultra Clean Tech",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8bc38d79-bb55-4185-853f-9bc68fad082c"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d7abb631-f51a-4fe8-ae51-6b36784515dd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ultra Clean Tech",
      "_label": "Ultra Clean Tech",
      "_name": "Penang",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1120,
      "name": "Penang Malaysia",
      "correlation_id": "10945",
      "billing_address": null,
      "start_date": "2017-08-14T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "30",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 5.3283593,
      "lon": 100.3006611,
      "local_timezone": {
        "id": 2394,
        "code": "Asia/Kuala_Lumpur",
        "display_name": "Asia/Kuala_Lumpur",
        "description": "",
        "child_group": null,
        "alt_sequence": 260
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "824ad4a2-a04f-4827-b7d0-df7853d5ac1d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Penang Malaysia",
      "_lat": 5.3283593,
      "_lng": 100.3006611,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 100.3006611
    },
    {
      "id": 7030,
      "name": "Penang Malaysia NC*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2024-02-23T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 49,
        "name": "Intel- Malaysia (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10941",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec78d9a6-648c-4f88-8cbb-643ad13aed74"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9cfe4634-6f66-4c6d-9662-0c067841b444",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel- Malaysia (JLL)",
      "_label": "Intel- Malaysia (JLL)",
      "_name": "Penang Malaysia NC*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2806,
      "name": "Pennington",
      "correlation_id": "101225",
      "billing_address": null,
      "start_date": "2022-01-17T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 430,
        "name": "PTC",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "df058be1-c036-42a1-afaf-8df167551e11"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d77a6bb5-7cf9-499c-a03d-0af6661fd22a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "PTC",
      "_label": "PTC",
      "_name": "Pennington",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5792,
      "name": "PETC",
      "correlation_id": "102719",
      "billing_address": null,
      "start_date": "2023-07-01T22:19:48Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667"
      },
      "parent_id": null,
      "lat": 33.832539,
      "lon": -84.4702182,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "29dba90e-76e1-4a8f-93be-eeefa24cff6b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Coca Cola",
      "_label": "Coca Cola",
      "_name": "PETC",
      "_lat": 33.832539,
      "_lng": -84.4702182,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10560545,
          "create_date": "2024-02-08T17:24:10.318130Z",
          "update_date": "2024-02-08T17:24:10.318155Z",
          "organization_id": 2145,
          "uploaded_by_id": 5941,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
          "size": 22904,
          "description": null,
          "link": null,
          "unique_identifier": "9182f361e2ff41d3bded657bde583842"
        }
      ],
      "lng": -84.4702182
    },
    {
      "id": 5869,
      "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
      "correlation_id": "102803",
      "billing_address": {
        "id": 3970,
        "street1": "2440 Village Rd.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2929714,
        "lon": -83.2423202,
        "country": "United States"
      },
      "start_date": "2020-11-09T22:21:54Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2929714,
      "lon": -83.2423202,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
      "_lat": 42.2929714,
      "_lng": -83.2423202,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2423202
    },
    {
      "id": 5809,
      "name": "Pharma Facility",
      "correlation_id": "102736",
      "billing_address": {
        "id": 3745,
        "street1": "900 Davis Drive",
        "street2": "",
        "city": "Morrisville",
        "region": "North Carolina",
        "postal_code": null,
        "lat": 35.8718788,
        "lon": -78.8695008,
        "country": "United States"
      },
      "start_date": "2021-10-31T21:21:28Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 253,
        "name": "Biogen (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11428",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
      "is_mobile": true,
      "mobile_live_date": "2023-09-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Biogen (CBRE)",
      "_label": "Biogen (CBRE)",
      "_name": "Pharma Facility",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859837,
          "create_date": "2020-11-17T14:36:01.480552Z",
          "update_date": "2020-11-17T14:36:01.480579Z",
          "organization_id": 253,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623761.388814_Biogen01_t.png",
          "size": 6068,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 5537,
      "name": "Philadelphia, PA",
      "correlation_id": "102347",
      "billing_address": {
        "id": 3013,
        "street1": "101 N Independence Mall E",
        "street2": "",
        "city": "Philadelphia",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 39.9507333,
        "lon": -75.148014,
        "country": "United States"
      },
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 39.95258,
      "lon": -75.14849,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
      "is_mobile": true,
      "mobile_live_date": "2023-06-27",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Philadelphia, PA",
      "_lat": 39.95258,
      "_lng": -75.14849,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -75.14849
    },
    {
      "id": 1810,
      "name": "Phoenix Central",
      "correlation_id": "102631",
      "billing_address": null,
      "start_date": "2019-07-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42"
      },
      "parent_id": null,
      "lat": 33.4831035,
      "lon": -112.0682232,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cigna (CBRE)",
      "_label": "Cigna (CBRE)",
      "_name": "Phoenix Central",
      "_lat": 33.4831035,
      "_lng": -112.0682232,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859854,
          "create_date": "2020-11-17T14:38:16.374186Z",
          "update_date": "2020-11-17T14:38:16.374213Z",
          "organization_id": 219,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
          "size": 9022,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -112.0682232
    },
    {
      "id": 1861,
      "name": "Phoenix Electronic",
      "correlation_id": "10693",
      "billing_address": {
        "id": 688,
        "street1": "4625 E Cotton Center Blvd,",
        "street2": "",
        "city": "Phoenix",
        "region": "Arizona",
        "postal_code": "85040",
        "lat": 33.4008045,
        "lon": -111.9818816,
        "country": "United States"
      },
      "start_date": "2015-11-30T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 161,
        "name": "Collins Aerospace (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10689",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "126b4051-f68e-412e-832d-13f5939487db"
      },
      "parent_id": null,
      "lat": 33.415616,
      "lon": -112.067583,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "113891f5-dcab-47b7-a653-8769e78f6768",
      "is_mobile": true,
      "mobile_live_date": "2021-08-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Collins Aerospace (JLL)",
      "_label": "Collins Aerospace (JLL)",
      "_name": "Phoenix Electronic",
      "_lat": 33.415616,
      "_lng": -112.067583,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686686,
          "create_date": "2024-02-16T12:25:12.469654Z",
          "update_date": "2024-02-16T12:25:12.469678Z",
          "organization_id": 161,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1ba66198d2044a67a2aacc1c0beaece8_Screenshot20240216at07.23_t.42",
          "size": 94362,
          "description": null,
          "link": null,
          "unique_identifier": "1ba66198d2044a67a2aacc1c0beaece8"
        }
      ],
      "lng": -112.067583
    },
    {
      "id": 6679,
      "name": "PHX5 – Goodyear, AZ (Pending Go-Live)",
      "correlation_id": "103388",
      "billing_address": {
        "id": 4089,
        "street1": "16920 W. Commerce Dr.",
        "street2": "",
        "city": "Goodyear",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.411968,
        "lon": -112.4220684,
        "country": "United States"
      },
      "start_date": "2024-03-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 33.411938,
      "lon": -112.422001,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "063a0db9-1e6b-47bd-90e0-530ba6ed5b51",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "PHX5 – Goodyear, AZ (Pending Go-Live)",
      "_lat": 33.411938,
      "_lng": -112.422001,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -112.422001
    },
    {
      "id": 5485,
      "name": "Pinnacle Peak (JLL)",
      "correlation_id": "102302",
      "billing_address": {
        "id": 2986,
        "street1": "2150 W Pinnacle Peak Rd",
        "street2": "",
        "city": "Phoenix",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.700948,
        "lon": -112.1064144,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 33.70026,
      "lon": -112.10648,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c6c40b90-fd55-4595-823d-cab984b85a15",
      "is_mobile": true,
      "mobile_live_date": "2022-12-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Pinnacle Peak (JLL)",
      "_lat": 33.70026,
      "_lng": -112.10648,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -112.10648
    },
    {
      "id": 1648,
      "name": "Pioneer, GE Repair Solutions",
      "correlation_id": "11220",
      "billing_address": null,
      "start_date": "2017-06-01T00:00:00Z",
      "total_sq_ft": "190000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 454,
        "name": "GE Singapore",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "177af7a3-9f39-4553-aa70-e89ca7faf1b1"
      },
      "parent_id": null,
      "lat": 1.3027612,
      "lon": 103.6655388,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "10ed07ba-9b4b-4075-a43a-cccb3d023215",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE Singapore",
      "_label": "GE Singapore",
      "_name": "Pioneer, GE Repair Solutions",
      "_lat": 1.3027612,
      "_lng": 103.6655388,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.6655388
    },
    {
      "id": 2333,
      "name": "Pittsburg",
      "correlation_id": "101846",
      "billing_address": {
        "id": 101,
        "street1": "1 Covestro Circle",
        "street2": "",
        "city": "Pittsburgh",
        "region": "Pennsylvania",
        "postal_code": "15205",
        "lat": 40.4418128,
        "lon": -80.0868497,
        "country": "United States"
      },
      "start_date": "2021-01-07T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 352,
        "name": "Covestro (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 100,
            "street1": "1 Covestro Cirlce",
            "street2": "",
            "city": "Pittsurgh",
            "region": "Pennsylvania",
            "postal_code": "15205",
            "lat": 40.443517,
            "lon": -80.163434,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "932bcd1f-c316-4ddb-88c5-6b98c3c13292"
      },
      "parent_id": null,
      "lat": 40.4418128,
      "lon": -80.0868497,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
      "is_mobile": true,
      "mobile_live_date": "2022-08-25",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Covestro (CBRE)",
      "_label": "Covestro (CBRE)",
      "_name": "Pittsburg",
      "_lat": 40.4418128,
      "_lng": -80.0868497,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10673763,
          "create_date": "2024-02-15T17:15:26.729820Z",
          "update_date": "2024-02-15T17:15:26.729850Z",
          "organization_id": 352,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg",
          "size": 7156,
          "description": null,
          "link": null,
          "unique_identifier": "edac7c8f818243409ff8b58d2def6560"
        }
      ],
      "lng": -80.0868497
    },
    {
      "id": 6739,
      "name": "Pittsburgh",
      "correlation_id": "103407",
      "billing_address": null,
      "start_date": "2024-01-22T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 131,
        "name": "Westinghouse (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10577",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9c9f35ea-f9ac-4b25-ba9a-9c8d93e7cd90"
      },
      "parent_id": null,
      "lat": 40.4973,
      "lon": -79.9428,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c87d7d86-26d9-4ef9-9096-7ba3ba39498d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Westinghouse (JLL)",
      "_label": "Westinghouse (JLL)",
      "_name": "Pittsburgh",
      "_lat": 40.4973,
      "_lng": -79.9428,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686626,
          "create_date": "2024-02-16T12:15:15.155823Z",
          "update_date": "2024-02-16T12:15:15.155850Z",
          "organization_id": 131,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dbe4b374e8694e52a1db5c3c3e8f3b76_Westinghouselogostackedwhite_350x_t.png",
          "size": 60863,
          "description": null,
          "link": null,
          "unique_identifier": "dbe4b374e8694e52a1db5c3c3e8f3b76"
        }
      ],
      "lng": -79.9428
    },
    {
      "id": 5668,
      "name": "Pittsburgh, PA",
      "correlation_id": "102537",
      "billing_address": {
        "id": 3594,
        "street1": "131 15th Street",
        "street2": "",
        "city": "Pittsburgh",
        "region": "Pennsylvania",
        "postal_code": null,
        "lat": 40.449153,
        "lon": -79.9894655,
        "country": "United States"
      },
      "start_date": "2023-04-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 40.49476,
      "lon": -79.92655,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c3a826f3-f03f-480e-ae6d-3b5eaabfde40",
      "is_mobile": true,
      "mobile_live_date": "2023-07-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Pittsburgh, PA",
      "_lat": 40.49476,
      "_lng": -79.92655,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -79.92655
    },
    {
      "id": 1869,
      "name": "Pittsfield",
      "correlation_id": "10704",
      "billing_address": null,
      "start_date": "2009-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 42.4587757,
      "lon": -73.2131943,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2bc88312-8b78-4e2e-9d3c-c69bb2dd4a5c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Pittsfield",
      "_lat": 42.4587757,
      "_lng": -73.2131943,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -73.2131943
    },
    {
      "id": 5880,
      "name": "Plamex*",
      "correlation_id": "102765",
      "billing_address": null,
      "start_date": "2023-09-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 38.89036,
      "lon": -77.03196,
      "local_timezone": {
        "id": 2283,
        "code": "America/Tijuana",
        "display_name": "America/Tijuana",
        "description": "",
        "child_group": null,
        "alt_sequence": 149
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d208a0f3-9ca2-4072-8eec-146f7fd3bae1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "Plamex*",
      "_lat": 38.89036,
      "_lng": -77.03196,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -77.03196
    },
    {
      "id": 1862,
      "name": "Plano",
      "correlation_id": "101337",
      "billing_address": {
        "id": 196,
        "street1": "1000 Klein RD",
        "street2": "",
        "city": "Plano",
        "region": "Texas",
        "postal_code": "75074",
        "lat": 33.0112656,
        "lon": -96.668072,
        "country": "United States"
      },
      "start_date": "2016-01-30T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 424,
        "name": "CPI Satcom",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 195,
            "street1": "1000 Klein RD",
            "street2": "",
            "city": "Plano",
            "region": "Texas",
            "postal_code": "75074",
            "lat": 33.0112656,
            "lon": -96.668072,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "b7f7e9f1-4cd2-4307-a9ec-6e7c75aedaf8"
      },
      "parent_id": null,
      "lat": 32.9745953,
      "lon": -96.7039053,
      "local_timezone": {
        "id": 2218,
        "code": "America/Kentucky/Louisville",
        "display_name": "America/Kentucky/Louisville",
        "description": "",
        "child_group": null,
        "alt_sequence": 84
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2b164150-7715-4e47-ac68-53dbc1d012d0",
      "is_mobile": true,
      "mobile_live_date": "2021-12-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "CPI Satcom",
      "_label": "CPI Satcom",
      "_name": "Plano",
      "_lat": 32.9745953,
      "_lng": -96.7039053,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.7039053
    },
    {
      "id": 2446,
      "name": "Plano",
      "correlation_id": "102176",
      "billing_address": {
        "id": 275,
        "street1": "3310 Dallas Parkway, Suite 111",
        "street2": "",
        "city": "Plano",
        "region": "Texas",
        "postal_code": "75093-7709",
        "lat": 33.04722,
        "lon": -96.828725,
        "country": "United States"
      },
      "start_date": "2024-02-06T18:21:35Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 33.04741,
      "lon": -96.82924,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cec6734a-a412-4cc5-94c7-accedc9527ad",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Plano",
      "_lat": 33.04741,
      "_lng": -96.82924,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -96.82924
    },
    {
      "id": 4685,
      "name": "Plano, TX",
      "correlation_id": "102204",
      "billing_address": {
        "id": 2696,
        "street1": "8181 Communications Pkwy",
        "street2": "",
        "city": "Plano",
        "region": "Texas",
        "postal_code": null,
        "lat": 33.0869848,
        "lon": -96.8279168,
        "country": "United States"
      },
      "start_date": "2022-10-17T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1975,
        "name": "JPMC (Hines)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1ca54ed0-ee17-41df-af09-01f5733b560a"
      },
      "parent_id": null,
      "lat": 33.0869848,
      "lon": -96.8279168,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bf3aa0d9-9d2b-4d6b-bde0-2592e6874c88",
      "is_mobile": true,
      "mobile_live_date": "2023-02-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "JPMC (Hines)",
      "_label": "JPMC (Hines)",
      "_name": "Plano, TX",
      "_lat": 33.0869848,
      "_lng": -96.8279168,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10588869,
          "create_date": "2024-02-09T20:47:21.966490Z",
          "update_date": "2024-02-09T20:47:21.966516Z",
          "organization_id": 1975,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/076132eadcb34f15a3543846b4b3e835_13_t.png",
          "size": 2737,
          "description": null,
          "link": null,
          "unique_identifier": "076132eadcb34f15a3543846b4b3e835"
        }
      ],
      "lng": -96.8279168
    },
    {
      "id": 2815,
      "name": "Plaza, AZ",
      "correlation_id": "101812",
      "billing_address": {
        "id": 2594,
        "street1": "100 West Washington",
        "street2": "",
        "city": "Phoenix",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.449042,
        "lon": -112.0758615,
        "country": "United States"
      },
      "start_date": "2021-10-20T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 628,
        "name": "City Of Phoenix (JLL)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6d59b019-145d-4047-9df9-54ed02416e74"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "66e26e6f-b7f6-40f9-af5a-ec444b17658b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "City Of Phoenix (JLL)",
      "_label": "City Of Phoenix (JLL)",
      "_name": "Plaza, AZ",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1356,
      "name": "Pleasanton",
      "correlation_id": "10395",
      "billing_address": {
        "id": 367,
        "street1": "4900 Johnson Dr",
        "street2": "",
        "city": "Pleasanton",
        "region": "California",
        "postal_code": "94588",
        "lat": 37.6939166,
        "lon": -121.9068135,
        "country": "United States"
      },
      "start_date": "2015-07-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 90,
        "name": "Clorox (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10394",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f62cf9fa-612e-44fa-9813-ce2607cc371f"
      },
      "parent_id": null,
      "lat": 37.693178,
      "lon": -121.907468,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Clorox (CBRE)",
      "_label": "Clorox (CBRE)",
      "_name": "Pleasanton",
      "_lat": 37.693178,
      "_lng": -121.907468,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859890,
          "create_date": "2020-11-17T14:46:45.795075Z",
          "update_date": "2020-11-17T14:46:45.795102Z",
          "organization_id": 90,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
          "size": 10109,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.907468
    },
    {
      "id": 1766,
      "name": "Pleasanton",
      "correlation_id": "11471",
      "billing_address": null,
      "start_date": "2019-02-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 75,
        "name": "AbbVie",
        "type": "CUSTOMER",
        "correlation_id": "10135",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e"
      },
      "parent_id": null,
      "lat": 37.696871,
      "lon": -121.8750228,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AbbVie",
      "_label": "AbbVie",
      "_name": "Pleasanton",
      "_lat": 37.696871,
      "_lng": -121.8750228,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 5630176,
          "create_date": "2023-01-27T16:07:24.909099Z",
          "update_date": "2023-01-27T16:07:24.909120Z",
          "organization_id": 75,
          "uploaded_by_id": 43918,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
          "size": 5975,
          "description": null,
          "link": null,
          "unique_identifier": "c1633f981f03472ab425a5d5e7e47b15"
        }
      ],
      "lng": -121.8750228
    },
    {
      "id": 2027,
      "name": "Pleasanton",
      "correlation_id": "11652",
      "billing_address": {
        "id": 1978,
        "street1": "5731 W Las Positas Blvd",
        "street2": "",
        "city": "Pleasanton",
        "region": "California",
        "postal_code": null,
        "lat": 37.6876739,
        "lon": -121.88988,
        "country": "United States"
      },
      "start_date": "2019-09-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 208,
        "name": "Bio-Rad",
        "type": "CUSTOMER",
        "correlation_id": "11462",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "86f2e6a9-8c5e-4070-afa7-db939edb358a"
      },
      "parent_id": null,
      "lat": 37.6876739,
      "lon": -121.88988,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "39ffcd18-c875-4a57-b8b8-ee05bc9c3b5a",
      "is_mobile": true,
      "mobile_live_date": "2022-03-31",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bio-Rad",
      "_label": "Bio-Rad",
      "_name": "Pleasanton",
      "_lat": 37.6876739,
      "_lng": -121.88988,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.88988
    },
    {
      "id": 2443,
      "name": "Pleasanton",
      "correlation_id": "101379",
      "billing_address": {
        "id": 268,
        "street1": "6035 Stoneridge Dr",
        "street2": "",
        "city": "Pleasanton",
        "region": "California",
        "postal_code": "94588",
        "lat": 37.6919422,
        "lon": -121.9058037,
        "country": "United States"
      },
      "start_date": "2021-03-27T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.6919,
      "lon": -121.9057,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3f0259f-555b-4ea6-953b-e1c5ec5bbbf2",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Pleasanton",
      "_lat": 37.6919,
      "_lng": -121.9057,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.9057
    },
    {
      "id": 2071,
      "name": "Plymouth",
      "correlation_id": "11603",
      "billing_address": {
        "id": 401,
        "street1": "49200 Halyard Drive",
        "street2": "",
        "city": "Plymouth",
        "region": "Michigan",
        "postal_code": "48170",
        "lat": 42.3854604,
        "lon": -83.5268434,
        "country": "United States"
      },
      "start_date": "2019-10-13T00:00:00Z",
      "total_sq_ft": "462000",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 280,
        "name": "Adient",
        "type": "CUSTOMER",
        "correlation_id": "11602",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d4affd5e-1b58-47fa-9888-cc35a7481a7c"
      },
      "parent_id": null,
      "lat": 42.3844543,
      "lon": -83.5201148,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
      "is_mobile": true,
      "mobile_live_date": "2020-10-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Adient",
      "_label": "Adient",
      "_name": "Plymouth",
      "_lat": 42.3844543,
      "_lng": -83.5201148,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859875,
          "create_date": "2020-11-17T14:42:37.971083Z",
          "update_date": "2020-11-17T14:42:37.971117Z",
          "organization_id": 280,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605624157.877317_adientvectorlogosmall_t.png",
          "size": 6440,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.5201148
    },
    {
      "id": 2574,
      "name": "Portland, OR",
      "correlation_id": "101485",
      "billing_address": {
        "id": 565,
        "street1": "7200 NW Front Ave",
        "street2": "",
        "city": "Portland",
        "region": "Oregon",
        "postal_code": "97210",
        "lat": 45.5762708,
        "lon": -122.7505525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 233,
        "name": "Siltronic",
        "type": "CUSTOMER",
        "correlation_id": "10678",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2e57bbd8-edf7-4948-a1f5-0faa71f56ad8"
      },
      "parent_id": null,
      "lat": 45.5762708,
      "lon": -122.7505525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a9da2b1c-7902-4030-9a8b-5be43446fe6e",
      "is_mobile": true,
      "mobile_live_date": "2021-09-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Siltronic",
      "_label": "Siltronic",
      "_name": "Portland, OR",
      "_lat": 45.5762708,
      "_lng": -122.7505525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7505525
    },
    {
      "id": 1073,
      "name": "Portland, OR (JLL)",
      "correlation_id": "10855",
      "billing_address": {
        "id": 569,
        "street1": "309 SW 6th Ave",
        "street2": "",
        "city": "Portland",
        "region": "Oregon",
        "postal_code": "97204",
        "lat": 45.5216149,
        "lon": -122.6776255,
        "country": "United States"
      },
      "start_date": "2017-03-05T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "34",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 45.5216149,
      "lon": -122.6776255,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c3380adf-64b4-4062-b662-8a3cb14285da",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Portland, OR (JLL)",
      "_lat": 45.5216149,
      "_lng": -122.6776255,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.6776255
    },
    {
      "id": 2278,
      "name": "Power/Norfolk",
      "correlation_id": "101306",
      "billing_address": null,
      "start_date": "2020-07-06T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 328,
        "name": "GE (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7d3590ee-2648-4669-a686-b561971724c2"
      },
      "parent_id": null,
      "lat": 36.88078,
      "lon": -76.21545,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9f47f3e6-2806-4c04-a8ce-dcdd134268ff",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GE (CBRE)",
      "_label": "GE (CBRE)",
      "_name": "Power/Norfolk",
      "_lat": 36.88078,
      "_lng": -76.21545,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 918859,
          "create_date": "2020-12-07T22:14:54.265585Z",
          "update_date": "2020-12-07T22:14:54.265610Z",
          "organization_id": 328,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607379294.1564417_GE_t.png",
          "size": 119619,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -76.21545
    },
    {
      "id": 1535,
      "name": "Pozos SA",
      "correlation_id": "11014",
      "billing_address": {
        "id": 808,
        "street1": "Calle Lindora",
        "street2": "",
        "city": "San Jose",
        "region": "Alabama",
        "postal_code": "000000",
        "lat": 9.9485111,
        "lon": -84.1908251,
        "country": "Costa Rica"
      },
      "start_date": "2021-12-01T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 66,
        "name": "Claro (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10996",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd3b5a83-4967-4d33-a99e-b06b63baef24"
      },
      "parent_id": null,
      "lat": 10.00828221,
      "lon": -84.43548292,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4ecc9e82-9647-40eb-9c02-d1f86eb89321",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Claro (CR)",
      "_label": "Claro (CR)",
      "_name": "Pozos SA",
      "_lat": 10.00828221,
      "_lng": -84.43548292,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.43548292
    },
    {
      "id": 2830,
      "name": "Pratt St.",
      "correlation_id": "100362",
      "billing_address": {
        "id": 3778,
        "street1": "100 E Pratt St",
        "street2": null,
        "city": "Baltimore",
        "region": "MD",
        "postal_code": "21202",
        "lat": 39.2869679,
        "lon": -76.6127337,
        "country": "US"
      },
      "start_date": "2016-04-01T19:06:06Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 225,
        "name": "T. Rowe Price",
        "type": "CUSTOMER",
        "correlation_id": "10645",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "51af8ac6-48c2-4774-b844-4fdd26e2d6ea"
      },
      "parent_id": null,
      "lat": 39.286819,
      "lon": -76.612757,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3ea78b35-c565-4ea2-8027-eb7a4c16a5bd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "T. Rowe Price",
      "_label": "T. Rowe Price",
      "_name": "Pratt St.",
      "_lat": 39.286819,
      "_lng": -76.612757,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10769945,
          "create_date": "2024-02-21T20:47:06.306144Z",
          "update_date": "2024-02-21T20:47:06.306170Z",
          "organization_id": 225,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/464bbb059be54218b17cd131d05a3116_trowelogo_t.png",
          "size": 2901,
          "description": null,
          "link": null,
          "unique_identifier": "464bbb059be54218b17cd131d05a3116"
        }
      ],
      "lng": -76.612757
    },
    {
      "id": 5867,
      "name": "PRC – Product Review Center – Dearborn, MI",
      "correlation_id": "102793",
      "billing_address": {
        "id": 3972,
        "street1": "2101 Village Rd.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": null,
        "lat": 42.2938341,
        "lon": -83.2383704,
        "country": "United States"
      },
      "start_date": "2023-12-27T22:30:46Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": null,
      "lat": 42.2938341,
      "lon": -83.2383704,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "PRC – Product Review Center – Dearborn, MI",
      "_lat": 42.2938341,
      "_lng": -83.2383704,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2383704
    },
    {
      "id": 1914,
      "name": "Princeton Pike",
      "correlation_id": "10790",
      "billing_address": {
        "id": 140,
        "street1": "3401 Princeton Pike",
        "street2": "",
        "city": "Lawrence Township",
        "region": "New Jersey",
        "postal_code": "08648",
        "lat": 40.2891617,
        "lon": -74.7145122,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "4620000",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 40.290438,
      "lon": -74.710868,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "68f33988-0a57-4581-bab0-41732986e27f",
      "is_mobile": true,
      "mobile_live_date": "2021-01-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Princeton Pike",
      "_lat": 40.290438,
      "_lng": -74.710868,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.710868
    },
    {
      "id": 1520,
      "name": "Providence",
      "correlation_id": "10599",
      "billing_address": null,
      "start_date": "2018-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "439",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 129,
        "name": "Hasbro (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10569",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "432db518-6de6-4823-a9d5-c8589c848920"
      },
      "parent_id": null,
      "lat": 41.823052,
      "lon": -71.4168555,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "47ced4fb-4344-400e-9d06-49ba6e70085c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hasbro (JLL)",
      "_label": "Hasbro (JLL)",
      "_name": "Providence",
      "_lat": 41.823052,
      "_lng": -71.4168555,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026237,
          "create_date": "2024-03-07T18:22:00.313766Z",
          "update_date": "2024-03-07T18:22:00.313790Z",
          "organization_id": 129,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/7c7c1e41c753408d83b43be30ae995f6_Hasbro_logosvg_t.png",
          "size": 151554,
          "description": null,
          "link": null,
          "unique_identifier": "7c7c1e41c753408d83b43be30ae995f6"
        }
      ],
      "lng": -71.4168555
    },
    {
      "id": 1080,
      "name": "Provo West (JLL)",
      "correlation_id": "10863",
      "billing_address": null,
      "start_date": "2017-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 40.234051,
      "lon": -111.6631388,
      "local_timezone": {
        "id": 2164,
        "code": "America/Boise",
        "display_name": "America/Boise",
        "description": "",
        "child_group": null,
        "alt_sequence": 30
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3150b7d7-35af-4939-9376-8f849a2a5cf1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Provo West (JLL)",
      "_lat": 40.234051,
      "_lng": -111.6631388,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.6631388
    },
    {
      "id": 5699,
      "name": "Puget Sound*",
      "correlation_id": "102564",
      "billing_address": null,
      "start_date": "2023-05-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2137,
        "name": "Rudy's Barber Shop",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e9951867-7f29-427f-8dad-7cb08e6b175b"
      },
      "parent_id": null,
      "lat": 47.6448,
      "lon": -122.13685,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a7ac350f-a4c5-4a4b-bbce-a100b8140070",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rudy's Barber Shop",
      "_label": "Rudy's Barber Shop",
      "_name": "Puget Sound*",
      "_lat": 47.6448,
      "_lng": -122.13685,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.13685
    },
    {
      "id": 7,
      "name": "Puget Sound, WA (CBRE)",
      "correlation_id": "11277",
      "billing_address": {
        "id": 492,
        "street1": "15700 NE 39th St B25/G15",
        "street2": "",
        "city": "Redmond",
        "region": "Washington",
        "postal_code": "98052",
        "lat": 47.6451337,
        "lon": -122.1309164,
        "country": "United States"
      },
      "start_date": "2017-12-07T23:59:59Z",
      "total_sq_ft": "14373565",
      "total_buildings": "182",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 47.6062095,
      "lon": -122.3320708,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Puget Sound, WA (CBRE)",
      "_lat": 47.6062095,
      "_lng": -122.3320708,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.3320708
    },
    {
      "id": 1995,
      "name": "Puget Sound, WA (Compass)",
      "correlation_id": "11669",
      "billing_address": null,
      "start_date": "2018-01-01T23:59:59Z",
      "total_sq_ft": "13593983",
      "total_buildings": "111",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 47.6062095,
      "lon": -122.3320708,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Puget Sound, WA (Compass)",
      "_lat": 47.6062095,
      "_lng": -122.3320708,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.3320708
    },
    {
      "id": 1541,
      "name": "Puntarenas",
      "correlation_id": "11021",
      "billing_address": {
        "id": 65,
        "street1": "Corredores, Corredor",
        "street2": "",
        "city": "Puntarenas",
        "region": "Alabama",
        "postal_code": "61001",
        "lat": 32.3182314,
        "lon": -86.902298,
        "country": "Costa Rica"
      },
      "start_date": "2014-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 146,
        "name": "Laica (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11020",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d6ed91b2-b65d-4353-a332-63090f445ce7"
      },
      "parent_id": null,
      "lat": 9.2169531,
      "lon": -83.336188,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8e8fe8dc-e6a2-4575-bada-04d1c8814469",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Laica (CR)",
      "_label": "Laica (CR)",
      "_name": "Puntarenas",
      "_lat": 9.2169531,
      "_lng": -83.336188,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.336188
    },
    {
      "id": 1382,
      "name": "QEC - Quality Engineering Center - Auburn Hills, MI",
      "correlation_id": "101711",
      "billing_address": {
        "id": 819,
        "street1": "2021 Executive Hills Dr",
        "street2": "",
        "city": "Auburn Hills",
        "region": "Michigan",
        "postal_code": "48326",
        "lat": 42.6468363,
        "lon": -83.2471264,
        "country": "United States"
      },
      "start_date": "2022-01-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 100,
        "name": "Stellantis",
        "type": "CUSTOMER",
        "correlation_id": "11364",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ef84c06c-f3e5-439a-9440-8516b5ab8270"
      },
      "parent_id": null,
      "lat": 42.6468363,
      "lon": -83.2471264,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "47e6e860-6e88-4f7c-9c8f-b52a1d798ada",
      "is_mobile": true,
      "mobile_live_date": "2022-04-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Stellantis",
      "_label": "Stellantis",
      "_name": "QEC - Quality Engineering Center - Auburn Hills, MI",
      "_lat": 42.6468363,
      "_lng": -83.2471264,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 3483099,
          "create_date": "2022-03-27T14:39:52.937493Z",
          "update_date": "2022-03-27T14:39:52.937517Z",
          "organization_id": 100,
          "uploaded_by_id": 67202,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9f41889e7691437c84391e71efea0f11_stellantislogo_t.jpg",
          "size": 37722,
          "description": null,
          "link": null,
          "unique_identifier": "9f41889e7691437c84391e71efea0f11"
        }
      ],
      "lng": -83.2471264
    },
    {
      "id": 3170,
      "name": "Quality*",
      "correlation_id": "101850",
      "billing_address": null,
      "start_date": "2022-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2510,
        "name": "Iron Mountain (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "09ffb73b-dee9-4619-9580-56b73ddaf5bb"
      },
      "parent_id": null,
      "lat": 1.32746,
      "lon": 103.7097,
      "local_timezone": {
        "id": 2420,
        "code": "Asia/Singapore",
        "display_name": "Asia/Singapore",
        "description": "",
        "child_group": null,
        "alt_sequence": 286
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d055f88f-fc79-4c52-95fa-a2a4d25e4b7b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Iron Mountain (CBRE) (SG)",
      "_label": "Iron Mountain (CBRE) (SG)",
      "_name": "Quality*",
      "_lat": 1.32746,
      "_lng": 103.7097,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 103.7097
    },
    {
      "id": 1805,
      "name": "Queen Creek",
      "correlation_id": "102632",
      "billing_address": null,
      "start_date": "2023-03-29T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.20889,
      "lon": -111.56447,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6e8c47b4-e6f3-4658-951f-f2b342053d1d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Queen Creek",
      "_lat": 33.20889,
      "_lng": -111.56447,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -111.56447
    },
    {
      "id": 5888,
      "name": "Quincy, MA*",
      "correlation_id": "102772",
      "billing_address": null,
      "start_date": "2023-10-04T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2103,
        "name": "Boston Scientific",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cda77a91-b61f-4ef1-b74d-644148e34274"
      },
      "parent_id": null,
      "lat": 42.29259,
      "lon": -71.03119,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4ebeeb5c-edc1-4a50-9aed-19b557f9de76",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Boston Scientific",
      "_label": "Boston Scientific",
      "_name": "Quincy, MA*",
      "_lat": 42.29259,
      "_lng": -71.03119,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -71.03119
    },
    {
      "id": 2059,
      "name": "Racine",
      "correlation_id": "10403",
      "billing_address": {
        "id": 566,
        "street1": "1525 Howe St",
        "street2": "",
        "city": "Racine",
        "region": "Wisconsin",
        "postal_code": "53403",
        "lat": 42.714385,
        "lon": -87.7900226,
        "country": "United States"
      },
      "start_date": "2015-03-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "26",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 94,
        "name": "SC Johnson (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10402",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bc1bc0ed-b84d-422c-ba98-99301e8a7490"
      },
      "parent_id": null,
      "lat": 42.7296794,
      "lon": -87.8878802,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cd27b2ec-ee4b-4ee5-bbdf-aa683f926fc1",
      "is_mobile": true,
      "mobile_live_date": "2021-06-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SC Johnson (JLL)",
      "_label": "SC Johnson (JLL)",
      "_name": "Racine",
      "_lat": 42.7296794,
      "_lng": -87.8878802,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11026063,
          "create_date": "2024-03-07T18:15:07.859204Z",
          "update_date": "2024-03-07T18:15:07.859228Z",
          "organization_id": 94,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/163e56dfc6d84b48b7d05c063fa50b15_S.C_t.JohnsonLogo500x313",
          "size": 12982,
          "description": null,
          "link": null,
          "unique_identifier": "163e56dfc6d84b48b7d05c063fa50b15"
        }
      ],
      "lng": -87.8878802
    },
    {
      "id": 15,
      "name": "Rahway, NJ",
      "correlation_id": "10030",
      "billing_address": {
        "id": 502,
        "street1": "126 E. Lincoln Ave. Bold. 55-238",
        "street2": "",
        "city": "Rahway",
        "region": "New Jersey",
        "postal_code": "07065",
        "lat": 40.593941,
        "lon": -74.29149,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "135000000",
      "total_buildings": "71",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 12,
        "name": "Merck (Sodexo)",
        "type": "CUSTOMER",
        "correlation_id": "10006",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e0e3d27b-1f3a-466d-9ba4-2bfba90407e7"
      },
      "parent_id": null,
      "lat": 40.612185,
      "lon": -74.26706,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b8fd40fb-39dc-42ed-ace9-4ea5feb43a87",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Merck (Sodexo)",
      "_label": "Merck (Sodexo)",
      "_name": "Rahway, NJ",
      "_lat": 40.612185,
      "_lng": -74.26706,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 953516,
          "create_date": "2020-12-17T16:56:00.661396Z",
          "update_date": "2020-12-17T16:56:00.661452Z",
          "organization_id": 12,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1608224160.5192304_1605311478.4740288_Artboard5_t_t.png",
          "size": 114753,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -74.26706
    },
    {
      "id": 5536,
      "name": "Rainbow Sunset (JLL)",
      "correlation_id": "102344",
      "billing_address": null,
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 36.0736318,
      "lon": -115.2447691,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bc91fb5d-342e-472f-810c-325fd2099636",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Rainbow Sunset (JLL)",
      "_lat": 36.0736318,
      "_lng": -115.2447691,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -115.2447691
    },
    {
      "id": 3116,
      "name": "RANCHO CORDOVA",
      "correlation_id": "102005",
      "billing_address": null,
      "start_date": "2022-02-16T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 800,
        "name": "THE WONDERFUL REAL ESTATE CO",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "22342b08-e902-40ce-bdcc-c22e4c38623e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "641fdc36-4b1a-4068-905d-09c0531c7d3c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "THE WONDERFUL REAL ESTATE CO",
      "_label": "THE WONDERFUL REAL ESTATE CO",
      "_name": "RANCHO CORDOVA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4354,
      "name": "Rancho Cordova, CA*",
      "correlation_id": "101803",
      "billing_address": {
        "id": 3947,
        "street1": "2001 Aerojet Road",
        "street2": "",
        "city": "Rancho Cordova",
        "region": "California",
        "postal_code": null,
        "lat": 38.6273388,
        "lon": -121.204925,
        "country": "United States"
      },
      "start_date": "2023-12-27T17:17:57Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1748,
        "name": "AEROJET ROCKETDYNE",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "ec0badb8-8668-49e5-8a06-22fdf50fa1a2"
      },
      "parent_id": null,
      "lat": 38.6273388,
      "lon": -121.204925,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e1b1d5ac-72bb-4d05-acd1-9ef008497f78",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AEROJET ROCKETDYNE",
      "_label": "AEROJET ROCKETDYNE",
      "_name": "Rancho Cordova, CA*",
      "_lat": 38.6273388,
      "_lng": -121.204925,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.204925
    },
    {
      "id": 1577,
      "name": "Raritan",
      "correlation_id": "11105",
      "billing_address": null,
      "start_date": "2018-02-05T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2135,
        "name": "QuidelOrtho",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "641d83c6-a19d-4e6d-993c-64a11bb0c61d"
      },
      "parent_id": null,
      "lat": 40.5727852,
      "lon": -74.6507569,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3c4c42b0-9aea-4831-b490-53c2f1d93dad",
      "is_mobile": true,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "QuidelOrtho",
      "_label": "QuidelOrtho",
      "_name": "Raritan",
      "_lat": 40.5727852,
      "_lng": -74.6507569,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -74.6507569
    },
    {
      "id": 2433,
      "name": "Raymond",
      "correlation_id": "101370",
      "billing_address": {
        "id": 252,
        "street1": "21001 SR-739",
        "street2": "",
        "city": "Raymond",
        "region": "Ohio",
        "postal_code": "43067",
        "lat": 40.3027556,
        "lon": -83.5296744,
        "country": "United States"
      },
      "start_date": "2022-02-28T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "26",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 347,
        "name": "Honda",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 87,
            "street1": "2420 Oak Valley Dr",
            "street2": "",
            "city": "Ann Arbor",
            "region": "Michigan",
            "postal_code": "48103",
            "lat": 42.2511196,
            "lon": -83.7743094,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "bc0f895a-4b0d-4954-9fec-348b76f6ace1"
      },
      "parent_id": null,
      "lat": 40.3027556,
      "lon": -83.5296744,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a680b127-3d6c-4525-8cd4-cfd4fbe0fc22",
      "is_mobile": true,
      "mobile_live_date": "2021-07-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Honda",
      "_label": "Honda",
      "_name": "Raymond",
      "_lat": 40.3027556,
      "_lng": -83.5296744,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -83.5296744
    },
    {
      "id": 1951,
      "name": "RCG016",
      "correlation_id": "11419",
      "billing_address": {
        "id": 612,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1b221916-7042-46a0-88e3-58c11e67beae",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RCG016",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1738,
      "name": "RCG017",
      "correlation_id": "11440",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "35dd1386-10f2-439b-ac85-afb3c99f54b9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "RCG017",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1753,
      "name": "RCG110",
      "correlation_id": "11455",
      "billing_address": {
        "id": 589,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1ee970ec-21ad-41bb-ad75-c9eb09569b86",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RCG110",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1752,
      "name": "RCG220",
      "correlation_id": "11454",
      "billing_address": {
        "id": 610,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b236e77a-efc3-47b6-932b-66a7c37414fd",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RCG220",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1744,
      "name": "RCG301",
      "correlation_id": "11446",
      "billing_address": {
        "id": 619,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86276f45-6ea5-4360-97fb-1f31453b593d",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RCG301",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1743,
      "name": "RCG310",
      "correlation_id": "11445",
      "billing_address": {
        "id": 618,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "820d7480-996f-42a1-9a1e-90538a241644",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RCG310",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1739,
      "name": "RCG510",
      "correlation_id": "11441",
      "billing_address": {
        "id": 632,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c7bec253-facc-408f-967a-0c6ff94bf8db",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "RCG510",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1750,
      "name": "RCG710",
      "correlation_id": "11452",
      "billing_address": {
        "id": 593,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "49206e2e-ba37-46ba-8a5a-a5ad0942074a",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RCG710",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1694,
      "name": "RE1301",
      "correlation_id": "11325",
      "billing_address": {
        "id": 620,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7f76693f-28eb-4269-859f-83e328a882f2",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RE1301",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1695,
      "name": "RE1302",
      "correlation_id": "11326",
      "billing_address": {
        "id": 621,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17decf93-b21b-401d-a88b-9e5c5e6d7683",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RE1302",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1696,
      "name": "RE1303",
      "correlation_id": "11327",
      "billing_address": {
        "id": 613,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d5de5556-f248-4206-90c7-e4076e2ca37b",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RE1303",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1697,
      "name": "RE1304",
      "correlation_id": "11328",
      "billing_address": {
        "id": 622,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0fa21dac-689d-42ba-9aba-deee50c08aee",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "RE1304",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1719,
      "name": "RE1601",
      "correlation_id": "11348",
      "billing_address": {
        "id": 607,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c85f4ad8-13f6-4c9a-9735-aeea9ad0bfcf",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RE1601",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1720,
      "name": "RE1602",
      "correlation_id": "11349",
      "billing_address": {
        "id": 604,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7419cbea-229a-41ab-97c4-5897676f8c66",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RE1602",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1721,
      "name": "RE1603",
      "correlation_id": "11350",
      "billing_address": {
        "id": 588,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a1815ba0-27b0-4330-b6d8-8d4c9f17e752",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RE1603",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1749,
      "name": "RE1604",
      "correlation_id": "11451",
      "billing_address": {
        "id": 605,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dcf9d200-3ce9-4b8d-a0cb-eb6ec15cc1f3",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "RE1604",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1689,
      "name": "RE1701",
      "correlation_id": "11317",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7147c4f0-0583-4eef-9ac2-b5e5025c495d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "RE1701",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1690,
      "name": "RE1702",
      "correlation_id": "11318",
      "billing_address": {
        "id": 631,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e75a5807-1fa1-423a-9b55-9e3f80691f90",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "RE1702",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 3470,
      "name": "Redlands",
      "correlation_id": "101921",
      "billing_address": null,
      "start_date": "2023-07-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 468,
        "name": "Deutsche Bank",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
      },
      "parent_id": null,
      "lat": 34.0712234,
      "lon": -117.2428528,
      "local_timezone": {
        "id": 2273,
        "code": "America/St_Barthelemy",
        "display_name": "America/St_Barthelemy",
        "description": "",
        "child_group": null,
        "alt_sequence": 139
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "219a07d3-50c9-4f61-ba29-3cc2aeeb14ef",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Deutsche Bank",
      "_label": "Deutsche Bank",
      "_name": "Redlands",
      "_lat": 34.0712234,
      "_lng": -117.2428528,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10772753,
          "create_date": "2024-02-21T23:24:53.738860Z",
          "update_date": "2024-02-21T23:24:53.738883Z",
          "organization_id": 468,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
          "size": 139120,
          "description": null,
          "link": null,
          "unique_identifier": "dc7aa29633644a68beb3aeb2abd0e74a"
        }
      ],
      "lng": -117.2428528
    },
    {
      "id": 2485,
      "name": "Redmond",
      "correlation_id": "101422",
      "billing_address": {
        "id": 333,
        "street1": "22857 NE Marketplace Dr",
        "street2": "",
        "city": "Redmond",
        "region": "Washington",
        "postal_code": "98053",
        "lat": 47.695027,
        "lon": -122.035441,
        "country": "United States"
      },
      "start_date": "2021-04-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 439,
        "name": "Just Evoctec Biologics, Inc",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 287,
            "street1": "401 Terry Ave N",
            "street2": "",
            "city": "Seattle",
            "region": "Washington",
            "postal_code": "98109",
            "lat": 47.6225773,
            "lon": -122.3374358,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "6815b705-ac79-4a72-b5bb-a6c8636fb459"
      },
      "parent_id": null,
      "lat": 47.6950773,
      "lon": -122.0359241,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "911b48ee-be43-497e-abed-3def1092303e",
      "is_mobile": true,
      "mobile_live_date": "2021-06-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Just Evoctec Biologics, Inc",
      "_label": "Just Evoctec Biologics, Inc",
      "_name": "Redmond",
      "_lat": 47.6950773,
      "_lng": -122.0359241,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.0359241
    },
    {
      "id": 2459,
      "name": "Redmond, WA",
      "correlation_id": "101402",
      "billing_address": {
        "id": 289,
        "street1": "10915 Willows Road",
        "street2": "",
        "city": "Redmond",
        "region": "Washington",
        "postal_code": "98052",
        "lat": 47.6980016,
        "lon": -122.1547539,
        "country": "United States"
      },
      "start_date": "2023-04-10T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "269e5278-e8cc-49d6-b192-1682f01ddcff",
      "is_mobile": true,
      "mobile_live_date": "2021-08-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Redmond, WA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": 0
    },
    {
      "id": 1977,
      "name": "Redmond, WA (JLL)",
      "correlation_id": "11626",
      "billing_address": {
        "id": 222,
        "street1": "17275 NE 67th Court",
        "street2": "",
        "city": "Redmond",
        "region": "Washington",
        "postal_code": "98052",
        "lat": 47.6645751,
        "lon": -122.1105077,
        "country": "United States"
      },
      "start_date": "2018-04-01T23:59:59Z",
      "total_sq_ft": "59300",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 47.665178,
      "lon": -122.108958,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b062e923-d661-44af-80c3-33a8f5bed669",
      "is_mobile": true,
      "mobile_live_date": "2021-08-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Redmond, WA (JLL)",
      "_lat": 47.665178,
      "_lng": -122.108958,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.108958
    },
    {
      "id": 1801,
      "name": "Red Mountain",
      "correlation_id": "102633",
      "billing_address": {
        "id": 3737,
        "street1": "5735 E. McKellips Rd., #101",
        "street2": "",
        "city": "Mesa",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.4513006,
        "lon": -111.7085589,
        "country": "United States"
      },
      "start_date": "2023-03-29T20:31:33Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.45135,
      "lon": -111.70855,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4b9e0380-2649-4b06-88fc-bf2d670dafbf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "Red Mountain",
      "_lat": 33.45135,
      "_lng": -111.70855,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -111.70855
    },
    {
      "id": 1248,
      "name": "Redwood City",
      "correlation_id": "10209",
      "billing_address": {
        "id": 79,
        "street1": "700 Bay Road",
        "street2": "",
        "city": "Redwood City,",
        "region": "California",
        "postal_code": "94063",
        "lat": 37.4849871,
        "lon": -122.2181684,
        "country": "United States"
      },
      "start_date": "2015-08-01T00:00:00Z",
      "total_sq_ft": "763344",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 81,
        "name": "BMS (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10208",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9bb4ea97-9d60-434d-8840-9f744e384203"
      },
      "parent_id": null,
      "lat": 37.4851249,
      "lon": -122.218277,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d75d2da9-0574-4f68-bd7f-4db418c3694f",
      "is_mobile": true,
      "mobile_live_date": "2020-10-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "BMS (JLL)",
      "_label": "BMS (JLL)",
      "_name": "Redwood City",
      "_lat": 37.4851249,
      "_lng": -122.218277,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 1075497,
          "create_date": "2021-01-29T21:03:17.789523Z",
          "update_date": "2021-01-29T21:03:17.789549Z",
          "organization_id": 81,
          "uploaded_by_id": 36274,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1611954197.67563_BMS_t.PNG",
          "size": 23811,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.218277
    },
    {
      "id": 1711,
      "name": "REG001",
      "correlation_id": "11340",
      "billing_address": {
        "id": 606,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f9b3e304-86fa-498a-82d8-d65dfdfee068",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG001",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1712,
      "name": "REG002",
      "correlation_id": "11341",
      "billing_address": {
        "id": 611,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "76a43100-00e7-42d2-8403-2c04a6ba1dbe",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG002",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1713,
      "name": "REG004",
      "correlation_id": "11342",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "28d322e8-b83b-45c9-a209-2dcb6244cdab",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG004",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1714,
      "name": "REG103",
      "correlation_id": "11343",
      "billing_address": {
        "id": 582,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "86af3ef8-24f6-4482-827c-0165bc6ee592",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG103",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1964,
      "name": "REG104",
      "correlation_id": "11566",
      "billing_address": {
        "id": 590,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "811df3da-7ca7-4fe9-a6fd-2628c83acd79",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG104",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 2262,
      "name": "Reg110",
      "correlation_id": "101293",
      "billing_address": null,
      "start_date": "2020-11-18T00:00:00Z",
      "total_sq_ft": "3888",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 290,
        "name": "SBM-H",
        "type": "CUSTOMER",
        "correlation_id": "11697",
        "addresses": [
          {
            "id": 258,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0a023ec6-4462-490a-a4db-dcc67a63fda7"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "39bc090f-505a-4248-89bb-95233d56bcb0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM-H",
      "_label": "SBM-H",
      "_name": "Reg110",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4784,
      "name": "Reg130",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2022-06-15T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 290,
        "name": "SBM-H",
        "type": "CUSTOMER",
        "correlation_id": "11697",
        "addresses": [
          {
            "id": 258,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "0a023ec6-4462-490a-a4db-dcc67a63fda7"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e2cdb23a-0c63-44b3-b312-382eb28cb827",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "SBM-H",
      "_label": "SBM-H",
      "_name": "Reg130",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2424,
      "name": "REG130",
      "correlation_id": "101360",
      "billing_address": {
        "id": 592,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2515ce6a-8c6d-44ca-bffe-94517fe1712b",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG130",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1770,
      "name": "REG133",
      "correlation_id": "11477",
      "billing_address": {
        "id": 587,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ea945e5d-558c-4f7b-8f7c-0107d01458ab",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG133",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1754,
      "name": "REG201",
      "correlation_id": "11456",
      "billing_address": {
        "id": 635,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4eb14343-c9da-4278-9b71-10b22a2e685a",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "REG201",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1706,
      "name": "REG202",
      "correlation_id": "11338",
      "billing_address": {
        "id": 595,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bae8f643-05d6-414a-a7d6-2c379fa0082d",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG202",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1700,
      "name": "REG301",
      "correlation_id": "11331",
      "billing_address": {
        "id": 625,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b7619e78-7d19-4ebf-b0b0-f55b72630377",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "REG301",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1747,
      "name": "REG321",
      "correlation_id": "11449",
      "billing_address": {
        "id": 626,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 203,
        "name": "Central",
        "type": "CUSTOMER",
        "correlation_id": "11323",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "876cfeca-9d03-409b-84f1-baf8b5d1da49"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d3e82bd1-e003-4d69-a711-4b63e15788da",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Central",
      "_label": "Central",
      "_name": "REG321",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1748,
      "name": "REG401",
      "correlation_id": "11450",
      "billing_address": {
        "id": 586,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "841cd0b0-885b-470d-9a94-329b6c955d95",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG401",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1717,
      "name": "REG404",
      "correlation_id": "11346",
      "billing_address": {
        "id": 594,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "72b33dc1-c3cb-4326-b7c7-1205462d71e9",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG404",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1740,
      "name": "REG501",
      "correlation_id": "11442",
      "billing_address": {
        "id": 627,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0081f289-e50d-4336-964b-ad56d92b980a",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "REG501",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1741,
      "name": "REG502",
      "correlation_id": "11443",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b03c2791-0c78-44c2-a9b8-9f8d9140b6a0",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "REG502",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1685,
      "name": "REG503",
      "correlation_id": "11313",
      "billing_address": {
        "id": 629,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "942c84c8-29af-44fe-962b-8ba1dcd08e16",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "REG503",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1686,
      "name": "REG504",
      "correlation_id": "11314",
      "billing_address": {
        "id": 630,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-20T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2a5c413f-99b0-4eb5-939f-c9ccb90ea1fb",
      "is_mobile": true,
      "mobile_live_date": "2021-07-20",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "REG504",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1742,
      "name": "REG505",
      "correlation_id": "11444",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 200,
        "name": "East",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11311",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ef3b37b-6d2b-46e9-8c32-987b6b8f3508"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "04b82d86-b3d8-4fa7-9987-f60cd48138d5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "East",
      "_label": "East",
      "_name": "REG505",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1703,
      "name": "REG701",
      "correlation_id": "11335",
      "billing_address": {
        "id": 602,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7824e0bc-aaae-4704-b288-88361e83c20e",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG701",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1702,
      "name": "REG702",
      "correlation_id": "11334",
      "billing_address": {
        "id": 584,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "190ff85a-39a8-44fa-909e-7aa8db7cdc4d",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG702",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 1704,
      "name": "REG703",
      "correlation_id": "11336",
      "billing_address": {
        "id": 600,
        "street1": "5241 Arnold Ave",
        "street2": "",
        "city": "McClellan Park",
        "region": "California",
        "postal_code": "95652",
        "lat": 38.6630216,
        "lon": -121.3854525,
        "country": "United States"
      },
      "start_date": "2021-07-19T23:59:59Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 204,
        "name": "West",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "11332",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "e35aebd6-0eb8-4873-b063-2bdbced4fd80"
      },
      "parent_id": null,
      "lat": 38.6630216,
      "lon": -121.3854525,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "f01bce0e-fbc7-412e-947c-c45ab010d904",
      "is_mobile": true,
      "mobile_live_date": "2021-07-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "West",
      "_label": "West",
      "_name": "REG703",
      "_lat": 38.6630216,
      "_lng": -121.3854525,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.3854525
    },
    {
      "id": 6031,
      "name": "REG960",
      "correlation_id": "102863",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b5855dfd-0dfc-426b-8385-e078c041ab65",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "REG960",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 6032,
      "name": "REG970",
      "correlation_id": "102864",
      "billing_address": null,
      "start_date": "2023-11-24T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 98,
        "name": "Corporate Overhead",
        "type": "CUSTOMER",
        "correlation_id": "11351",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c9cb0b-c981-4240-9b1a-9c4eabe85013"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0835379f-6134-4f75-b9d4-39e70311c3f2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Corporate Overhead",
      "_label": "Corporate Overhead",
      "_name": "REG970",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2561,
      "name": "Reno",
      "correlation_id": "102177",
      "billing_address": {
        "id": 547,
        "street1": "5301 Kietzke Ln",
        "street2": "",
        "city": "Reno",
        "region": "Nevada",
        "postal_code": "89511",
        "lat": 39.4704896,
        "lon": -119.7926211,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 39.470591,
      "lon": -119.792489,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "42fc4211-b350-4582-af54-ce2ae7afae38",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Reno",
      "_lat": 39.470591,
      "_lng": -119.792489,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -119.792489
    },
    {
      "id": 2348,
      "name": "Reno, NV",
      "correlation_id": "101333",
      "billing_address": {
        "id": 135,
        "street1": "6840 Sierra Center Pkwy",
        "street2": "",
        "city": "Reno",
        "region": "Nevada",
        "postal_code": "89511",
        "lat": 39.4663787,
        "lon": -119.7800313,
        "country": "Afghanistan"
      },
      "start_date": "2021-02-16T08:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 39.4663787,
      "lon": -119.7800313,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
      "is_mobile": true,
      "mobile_live_date": "2021-07-29",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Reno, NV",
      "_lat": 39.4663787,
      "_lng": -119.7800313,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.7800313
    },
    {
      "id": 5896,
      "name": "RENTOKIL - RENT2*",
      "correlation_id": "102780",
      "billing_address": null,
      "start_date": "2023-10-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 275,
        "name": "P&G (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "11605",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "73c20d05-69b7-4adc-b8c0-7f265c556fe3"
      },
      "parent_id": null,
      "lat": 39.33498,
      "lon": -84.44418,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a5977393-d3bc-4094-9ed6-63c85c0f363b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "P&G (JLL)",
      "_label": "P&G (JLL)",
      "_name": "RENTOKIL - RENT2*",
      "_lat": 39.33498,
      "_lng": -84.44418,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10676982,
          "create_date": "2024-02-15T19:30:31.713635Z",
          "update_date": "2024-02-15T19:30:31.713660Z",
          "organization_id": 275,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9efe32dff7ab4f479526367623a6a20d_Procter__Gamble_logo_t.png",
          "size": 107371,
          "description": null,
          "link": null,
          "unique_identifier": "9efe32dff7ab4f479526367623a6a20d"
        }
      ],
      "lng": -84.44418
    },
    {
      "id": 5677,
      "name": "Reston, VA",
      "correlation_id": "102548",
      "billing_address": {
        "id": 3571,
        "street1": "1818 Library St.",
        "street2": "",
        "city": "Reston",
        "region": "Virginia",
        "postal_code": null,
        "lat": 38.9583127,
        "lon": -77.358929,
        "country": "United States"
      },
      "start_date": "2023-04-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 257,
        "name": "Meta",
        "type": "CUSTOMER",
        "correlation_id": "11564",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2b87412d-4913-4ad6-b7a1-3b7f96102dde"
      },
      "parent_id": null,
      "lat": 38.9584,
      "lon": -77.3586,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "59fcd24f-edfc-4069-8dfa-a5477d908d45",
      "is_mobile": true,
      "mobile_live_date": "2023-06-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta",
      "_label": "Meta",
      "_name": "Reston, VA",
      "_lat": 38.9584,
      "_lng": -77.3586,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590143,
          "create_date": "2024-02-09T21:51:39.560945Z",
          "update_date": "2024-02-09T21:51:39.560968Z",
          "organization_id": 257,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/61402652075d468e9ce510e0c759cee9_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "61402652075d468e9ce510e0c759cee9"
        }
      ],
      "lng": -77.3586
    },
    {
      "id": 5741,
      "name": "RFD2 - Huntley, IL",
      "correlation_id": "102582",
      "billing_address": {
        "id": 4081,
        "street1": "11500 Freeman Rd",
        "street2": "",
        "city": "Huntley",
        "region": "Illinois",
        "postal_code": null,
        "lat": 42.1282294,
        "lon": -88.4245083,
        "country": "United States"
      },
      "start_date": "2023-10-12T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 42.13196,
      "lon": -88.45983,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0ac59b3f-cacd-4c93-86e7-5b44b1c0dca7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "RFD2 - Huntley, IL",
      "_lat": 42.13196,
      "_lng": -88.45983,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -88.45983
    },
    {
      "id": 4834,
      "name": "RFD4 - Huntley, IL",
      "correlation_id": "102236",
      "billing_address": {
        "id": 2713,
        "street1": "11400 Venture Court",
        "street2": "",
        "city": "Hunley",
        "region": "Illinois",
        "postal_code": "60142",
        "lat": 42.127383,
        "lon": -88.420852,
        "country": "United States"
      },
      "start_date": "2023-10-12T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 24,
        "name": "Amazon",
        "type": "CUSTOMER",
        "correlation_id": "10205",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "84155e89-3643-4246-b6fc-4781b5786b84"
      },
      "parent_id": null,
      "lat": 42.12842,
      "lon": -88.42749,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "92e4faf9-430d-4af9-b24f-95bc7637060c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon",
      "_label": "Amazon",
      "_name": "RFD4 - Huntley, IL",
      "_lat": 42.12842,
      "_lng": -88.42749,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -88.42749
    },
    {
      "id": 2051,
      "name": "Richmond",
      "correlation_id": "101080",
      "billing_address": {
        "id": 700,
        "street1": "2500 Atlas, Richmond, CA",
        "street2": "",
        "city": "Richmond",
        "region": "California",
        "postal_code": "94806",
        "lat": 37.9976943,
        "lon": -122.351668,
        "country": "United States"
      },
      "start_date": "2019-09-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 208,
        "name": "Bio-Rad",
        "type": "CUSTOMER",
        "correlation_id": "11462",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "86f2e6a9-8c5e-4070-afa7-db939edb358a"
      },
      "parent_id": null,
      "lat": 37.970012,
      "lon": -122.3850823,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6ee07c72-3aa5-4bc1-9324-8543f9a1ebb0",
      "is_mobile": true,
      "mobile_live_date": "2021-08-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bio-Rad",
      "_label": "Bio-Rad",
      "_name": "Richmond",
      "_lat": 37.970012,
      "_lng": -122.3850823,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.3850823
    },
    {
      "id": 2131,
      "name": "Richmond, IN",
      "correlation_id": "100332",
      "billing_address": {
        "id": 236,
        "street1": "4200 W Industries Road",
        "street2": "",
        "city": "Richmond",
        "region": "Indiana",
        "postal_code": "47374",
        "lat": 39.862087,
        "lon": -84.953102,
        "country": "United States"
      },
      "start_date": "2016-11-01T00:00:00Z",
      "total_sq_ft": "64000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 39.8318108,
      "lon": -84.889974,
      "local_timezone": {
        "id": 2204,
        "code": "America/Indiana/Indianapolis",
        "display_name": "America/Indiana/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 70
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e54b927b-429c-460f-b369-94c8f1af1f50",
      "is_mobile": true,
      "mobile_live_date": "2021-02-23",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Richmond, IN",
      "_lat": 39.8318108,
      "_lng": -84.889974,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -84.889974
    },
    {
      "id": 3467,
      "name": "Richmond, IN",
      "correlation_id": "102047",
      "billing_address": {
        "id": 1571,
        "street1": "120 N 9Th St.",
        "street2": "",
        "city": "Richmond",
        "region": "Indiana",
        "postal_code": "47374",
        "lat": 39.8314409,
        "lon": -84.890706,
        "country": "United States"
      },
      "start_date": "2022-04-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1018,
        "name": "Chubb Insurance (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 1442,
            "street1": "202 Halls Mill Rd A",
            "street2": "",
            "city": "Whitehouse Station",
            "region": "New Jersey",
            "postal_code": "08889",
            "lat": 40.6459052,
            "lon": -74.7647032,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033"
      },
      "parent_id": null,
      "lat": 39.83131,
      "lon": -84.89069,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "519c7469-e232-466e-9e16-fb9c702efc59",
      "is_mobile": true,
      "mobile_live_date": "2023-05-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Chubb Insurance (CBRE)",
      "_label": "Chubb Insurance (CBRE)",
      "_name": "Richmond, IN",
      "_lat": 39.83131,
      "_lng": -84.89069,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10588121,
          "create_date": "2024-02-09T20:02:46.893594Z",
          "update_date": "2024-02-09T20:02:46.893619Z",
          "organization_id": 1018,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
          "size": 3015,
          "description": null,
          "link": null,
          "unique_identifier": "b6e3c419c49b4746a9cdfaf251371b61"
        }
      ],
      "lng": -84.89069
    },
    {
      "id": 2409,
      "name": "Richmond, VA",
      "correlation_id": "101347",
      "billing_address": {
        "id": 194,
        "street1": "450 Southlake Boulevard",
        "street2": "",
        "city": "Richmond",
        "region": "3218",
        "postal_code": "23236",
        "lat": 37.4999803,
        "lon": -77.6032245,
        "country": "2017"
      },
      "start_date": "2023-07-18T20:03:05.287000Z",
      "total_sq_ft": "136000",
      "total_buildings": "8",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 37.4999,
      "lon": -77.6032,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b604565c-2836-4801-864e-aedef2229022",
      "is_mobile": true,
      "mobile_live_date": "2021-04-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "Richmond, VA",
      "_lat": 37.4999,
      "_lng": -77.6032,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -77.6032
    },
    {
      "id": 1275,
      "name": "Richmond, VA (Davis Center)",
      "correlation_id": "10246",
      "billing_address": {
        "id": 80,
        "street1": "2015 Staples Mill Road",
        "street2": "",
        "city": "Richmond",
        "region": "Virginia",
        "postal_code": "23230",
        "lat": 37.5835544,
        "lon": -77.4903653,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "1650000",
      "total_buildings": "9",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 37.5832607,
      "lon": -77.4905388,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "819f5707-4bf3-4c9b-bcee-999e266bfb41",
      "is_mobile": true,
      "mobile_live_date": "2020-10-15",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Richmond, VA (Davis Center)",
      "_lat": 37.5832607,
      "_lng": -77.4905388,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -77.4905388
    },
    {
      "id": 2251,
      "name": "RIC – Research & Engineering – Dearborn, MI",
      "correlation_id": "101262",
      "billing_address": {
        "id": 216,
        "street1": "20000 Rotunda Dr.",
        "street2": "",
        "city": "Dearborn",
        "region": "Michigan",
        "postal_code": "48124",
        "lat": 42.2948836,
        "lon": -83.2233969,
        "country": "United States"
      },
      "start_date": "2020-11-09T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "214",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd"
      },
      "parent_id": 5847,
      "lat": 42.2979002,
      "lon": -83.2281853,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
      "is_mobile": true,
      "mobile_live_date": "2020-11-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": true,
      "use_area_links": true,
      "_client": "Ford",
      "_label": "Ford",
      "_name": "RIC – Research & Engineering – Dearborn, MI",
      "_lat": 42.2979002,
      "_lng": -83.2281853,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853042,
          "create_date": "2020-11-13T23:58:46.210777Z",
          "update_date": "2020-11-13T23:58:46.210805Z",
          "organization_id": 315,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311926.006089_Artboard11_t.png",
          "size": 950087,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -83.2281853
    },
    {
      "id": 5499,
      "name": "Ridgedale",
      "correlation_id": "102323",
      "billing_address": {
        "id": 2996,
        "street1": "1809 Plymouth Road South",
        "street2": "",
        "city": "Minnetonka",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.9667231,
        "lon": -93.4418737,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "40600",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 44.96648,
      "lon": -93.44187,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
      "is_mobile": true,
      "mobile_live_date": "2022-07-05",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Ridgedale",
      "_lat": 44.96648,
      "_lng": -93.44187,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -93.44187
    },
    {
      "id": 2065,
      "name": "Ridgepoint",
      "correlation_id": "101830",
      "billing_address": {
        "id": 105,
        "street1": "2700 156th Ave NE",
        "street2": "",
        "city": "Bellevue",
        "region": "Washington",
        "postal_code": "98007",
        "lat": 47.6334347,
        "lon": -122.1318511,
        "country": "United States"
      },
      "start_date": "2021-03-12T00:00:00Z",
      "total_sq_ft": "34352",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 47.6335356,
      "lon": -122.1319298,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "30706873-535e-4427-b9fa-c985f666b6f4",
      "is_mobile": true,
      "mobile_live_date": "2020-10-14",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ridgepoint",
      "_lat": 47.6335356,
      "_lng": -122.1319298,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.1319298
    },
    {
      "id": 1831,
      "name": "Rio Rancho",
      "correlation_id": "101831",
      "billing_address": {
        "id": 106,
        "street1": "1600 RIO RANCHO BLVD SE",
        "street2": "",
        "city": "Rio Rancho",
        "region": "New Mexico",
        "postal_code": "87124",
        "lat": 35.2248563,
        "lon": -106.6602238,
        "country": "United States"
      },
      "start_date": "2021-03-12T00:00:00Z",
      "total_sq_ft": "3228898",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 35.23377,
      "lon": -106.65784,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a7008709-8004-48d4-9701-3d39d3a4540c",
      "is_mobile": true,
      "mobile_live_date": "2020-10-28",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Rio Rancho",
      "_lat": 35.23377,
      "_lng": -106.65784,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -106.65784
    },
    {
      "id": 2122,
      "name": "Riverside",
      "correlation_id": "101385",
      "billing_address": null,
      "start_date": "2020-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 224,
        "name": "Cardinal Health (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10643",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bf898a7d-8fea-416c-9e32-ca590b75d006"
      },
      "parent_id": null,
      "lat": 33.95335,
      "lon": -117.396156,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "eee2d1ed-3640-49c7-b453-3e089aba0969",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cardinal Health (JLL)",
      "_label": "Cardinal Health (JLL)",
      "_name": "Riverside",
      "_lat": 33.95335,
      "_lng": -117.396156,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.396156
    },
    {
      "id": 1276,
      "name": "Roanoke, VA",
      "correlation_id": "10247",
      "billing_address": {
        "id": 2717,
        "street1": "602 S. Jefferson Street",
        "street2": "",
        "city": "Roanoke",
        "region": "Virginia",
        "postal_code": null,
        "lat": 37.2690736,
        "lon": -79.9398189,
        "country": "United States"
      },
      "start_date": "2009-05-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 37.2690576,
      "lon": -79.9401117,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "6a559e0c-9e08-43e7-b44c-975ffd69bbff",
      "is_mobile": true,
      "mobile_live_date": "2022-11-03",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Roanoke, VA",
      "_lat": 37.2690576,
      "_lng": -79.9401117,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -79.9401117
    },
    {
      "id": 1115,
      "name": "Rochester, MN",
      "correlation_id": "10932",
      "billing_address": {
        "id": 3754,
        "street1": "2900 37th St NW Building 101",
        "street2": "",
        "city": "Rochester",
        "region": "Minnesota",
        "postal_code": null,
        "lat": 44.0576392,
        "lon": -92.5062912,
        "country": "United States"
      },
      "start_date": "2017-07-15T23:59:59Z",
      "total_sq_ft": "1144985",
      "total_buildings": "19",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 45,
        "name": "IRG",
        "type": "CUSTOMER",
        "correlation_id": "10929",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "31c1238d-fbe9-4121-b4bd-443ad4847321"
      },
      "parent_id": null,
      "lat": 43.5150621,
      "lon": -91.8697298,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "db1fe8a4-94c1-4e0f-90d8-e247df299486",
      "is_mobile": true,
      "mobile_live_date": "2020-08-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "IRG",
      "_label": "IRG",
      "_name": "Rochester, MN",
      "_lat": 43.5150621,
      "_lng": -91.8697298,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -91.8697298
    },
    {
      "id": 1584,
      "name": "Rockford, IL.",
      "correlation_id": "11117",
      "billing_address": {
        "id": 804,
        "street1": "4747 Harrison Ave",
        "street2": "",
        "city": "Rockford",
        "region": "Illinois",
        "postal_code": "61108",
        "lat": 42.2361201,
        "lon": -89.0247991,
        "country": "United States"
      },
      "start_date": "2018-02-02T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 161,
        "name": "Collins Aerospace (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10689",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "126b4051-f68e-412e-832d-13f5939487db"
      },
      "parent_id": null,
      "lat": 42.2360817,
      "lon": -89.0249264,
      "local_timezone": {
        "id": 2212,
        "code": "America/Indianapolis",
        "display_name": "America/Indianapolis",
        "description": "",
        "child_group": null,
        "alt_sequence": 78
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e2f57c3b-2d2d-4913-ae89-015776d3bd45",
      "is_mobile": true,
      "mobile_live_date": "2021-11-12",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Collins Aerospace (JLL)",
      "_label": "Collins Aerospace (JLL)",
      "_name": "Rockford, IL.",
      "_lat": 42.2360817,
      "_lng": -89.0249264,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686686,
          "create_date": "2024-02-16T12:25:12.469654Z",
          "update_date": "2024-02-16T12:25:12.469678Z",
          "organization_id": 161,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1ba66198d2044a67a2aacc1c0beaece8_Screenshot20240216at07.23_t.42",
          "size": 94362,
          "description": null,
          "link": null,
          "unique_identifier": "1ba66198d2044a67a2aacc1c0beaece8"
        }
      ],
      "lng": -89.0249264
    },
    {
      "id": 1287,
      "name": "Rockville",
      "correlation_id": "11594",
      "billing_address": {
        "id": 465,
        "street1": "9911 Belward Campus Dr.",
        "street2": "",
        "city": "Rockville",
        "region": "Maryland",
        "postal_code": "20850",
        "lat": 39.104274,
        "lon": -77.2108965,
        "country": "United States"
      },
      "start_date": "2014-12-01T23:59:59Z",
      "total_sq_ft": "254282",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 476,
        "name": "GSK",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "22f0c0aa-30a1-4e6c-9cf9-8c74f7bd6b79"
      },
      "parent_id": null,
      "lat": 39.1034288,
      "lon": -77.2087236,
      "local_timezone": {
        "id": 2184,
        "code": "America/Detroit",
        "display_name": "America/Detroit",
        "description": "",
        "child_group": null,
        "alt_sequence": 50
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Suspended",
      "uuid": "e8441d64-4ba4-4ac2-81da-9ea6b7de3c88",
      "is_mobile": true,
      "mobile_live_date": "2020-12-09",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "GSK",
      "_label": "GSK",
      "_name": "Rockville",
      "_lat": 39.1034288,
      "_lng": -77.2087236,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -77.2087236
    },
    {
      "id": 1477,
      "name": "Ronler",
      "correlation_id": "101833",
      "billing_address": {
        "id": 262,
        "street1": "2501 NW 229th Avenue",
        "street2": "",
        "city": "Hillsboro",
        "region": "Oregon",
        "postal_code": "97124",
        "lat": 45.5469062,
        "lon": -122.9121802,
        "country": "United States"
      },
      "start_date": "2023-03-12T22:16:30Z",
      "total_sq_ft": "12139383",
      "total_buildings": "35",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 45.50151,
      "lon": -122.91211,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "880e03b1-a4dc-4bb2-bb3b-695d427d8193",
      "is_mobile": true,
      "mobile_live_date": "2021-03-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ronler",
      "_lat": 45.50151,
      "_lng": -122.91211,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.91211
    },
    {
      "id": 4818,
      "name": "Ronler FOUP Wash*",
      "correlation_id": "102215",
      "billing_address": null,
      "start_date": "2021-01-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "67b423e4-40f8-4582-a53e-3e0a4ef22e8d",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ronler FOUP Wash*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 4232,
      "name": "Ronler Projects*",
      "correlation_id": "101757",
      "billing_address": null,
      "start_date": "2022-07-14T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2577,
        "code": "Antarctica/Casey",
        "display_name": "Antarctica/Casey",
        "description": "",
        "child_group": null,
        "alt_sequence": 443
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b8f85145-b754-403a-a4dd-f785b245ce08",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Ronler Projects*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 4817,
      "name": "Ronler Protocol*",
      "correlation_id": "102214",
      "billing_address": null,
      "start_date": "2019-08-20T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2074,
        "name": "AM Technical Solutions",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "95002b8f-1e19-451d-a169-d32c9d3a57c3"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "98aad7d1-6b37-42ff-9587-82c28fe18514",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "AM Technical Solutions",
      "_label": "AM Technical Solutions",
      "_name": "Ronler Protocol*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2089,
      "name": "Rose Garden Lane (JLL)",
      "correlation_id": "11693",
      "billing_address": {
        "id": 572,
        "street1": "2222 W Rose Garden Lane",
        "street2": "",
        "city": "Phenix",
        "region": "Arizona",
        "postal_code": "85027",
        "lat": 33.6770201,
        "lon": -112.1073459,
        "country": "United States"
      },
      "start_date": "2013-11-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "136",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 33.67679,
      "lon": -112.1076,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d53d9a30-6f23-4d3c-a8c7-70b91e7d715e",
      "is_mobile": true,
      "mobile_live_date": "2021-07-06",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "Rose Garden Lane (JLL)",
      "_lat": 33.67679,
      "_lng": -112.1076,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -112.1076
    },
    {
      "id": 2589,
      "name": "Roseville",
      "correlation_id": "101530",
      "billing_address": {
        "id": 649,
        "street1": "7701 Foothill Blvd.",
        "street2": "",
        "city": "Roseville",
        "region": "California",
        "postal_code": "95747",
        "lat": 38.779129,
        "lon": -121.3124168,
        "country": "United States"
      },
      "start_date": "2021-07-01T12:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 38.78127,
      "lon": -121.31374,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "123db32d-6425-45a7-a771-e015c599d908",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Roseville",
      "_lat": 38.78127,
      "_lng": -121.31374,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.31374
    },
    {
      "id": 5648,
      "name": "Roseville, CA*",
      "correlation_id": "102526",
      "billing_address": null,
      "start_date": "2023-02-28T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2123,
        "name": "USA Properties",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "56473223-a364-410a-92af-68a14eed58f2"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "791bde65-d899-46ef-a360-c3d2589dbdd5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "USA Properties",
      "_label": "USA Properties",
      "_name": "Roseville, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2637,
      "name": "RTP Bio Facility",
      "correlation_id": "101586",
      "billing_address": {
        "id": 771,
        "street1": "5000 Davis Dr",
        "street2": "",
        "city": "Morrisville",
        "region": "North Carolina",
        "postal_code": "27560",
        "lat": 35.8603544,
        "lon": -78.8686077,
        "country": "United States"
      },
      "start_date": "2021-11-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 253,
        "name": "Biogen (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11428",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "55abaac4-7852-4686-be88-409bed152f1d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
      "is_mobile": true,
      "mobile_live_date": "2022-03-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Biogen (CBRE)",
      "_label": "Biogen (CBRE)",
      "_name": "RTP Bio Facility",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859837,
          "create_date": "2020-11-17T14:36:01.480552Z",
          "update_date": "2020-11-17T14:36:01.480579Z",
          "organization_id": 253,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623761.388814_Biogen01_t.png",
          "size": 6068,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 2080,
      "name": "Rubbermaid",
      "correlation_id": "11684",
      "billing_address": {
        "id": 523,
        "street1": "29 E Stephenson St",
        "street2": "",
        "city": "Freeport",
        "region": "Illinois",
        "postal_code": "61032",
        "lat": 42.2986527,
        "lon": -89.6188744,
        "country": "United States"
      },
      "start_date": "2020-04-15T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 283,
        "name": "Newell",
        "type": "CUSTOMER",
        "correlation_id": "11684",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "39e902e9-411e-4212-ab63-e04b659a6abd"
      },
      "parent_id": null,
      "lat": 42.29858,
      "lon": -89.61885,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "5f0bb07d-42a7-4055-8117-c1b2fc44c058",
      "is_mobile": true,
      "mobile_live_date": "2021-06-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Newell",
      "_label": "Newell",
      "_name": "Rubbermaid",
      "_lat": 42.29858,
      "_lng": -89.61885,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11025405,
          "create_date": "2024-03-07T17:51:44.231135Z",
          "update_date": "2024-03-07T17:51:44.231161Z",
          "organization_id": 283,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/3f97abc0c64e4c58aa0433aa7e5ddfbe_newell_t.png",
          "size": 3241,
          "description": null,
          "link": null,
          "unique_identifier": "3f97abc0c64e4c58aa0433aa7e5ddfbe"
        }
      ],
      "lng": -89.61885
    },
    {
      "id": 2559,
      "name": "Sacramento",
      "correlation_id": "102179",
      "billing_address": {
        "id": 545,
        "street1": "525 Fulton Ave",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": "95825",
        "lat": 38.5748287,
        "lon": -121.4058931,
        "country": "United States"
      },
      "start_date": "2021-04-01T13:43:51Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 38.574881,
      "lon": -121.405905,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "31d7aad2-4ed6-4c30-90db-fbb970c27a8c",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Sacramento",
      "_lat": 38.574881,
      "_lng": -121.405905,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.405905
    },
    {
      "id": 2659,
      "name": "Sacramento",
      "correlation_id": "101620",
      "billing_address": {
        "id": 789,
        "street1": "5850 Citation Way",
        "street2": "",
        "city": "Sacramento",
        "region": "California",
        "postal_code": "95837",
        "lat": 38.6846395,
        "lon": -121.5955931,
        "country": "United States"
      },
      "start_date": "2022-02-11T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 73,
        "name": "Textron",
        "type": "CUSTOMER",
        "correlation_id": "10010",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8ed5121f-df26-4d9c-852b-dc4be6ef483f"
      },
      "parent_id": null,
      "lat": 38.685,
      "lon": -121.59597,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7b0ed892-94ac-43a4-907c-8d38d07754c5",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Textron",
      "_label": "Textron",
      "_name": "Sacramento",
      "_lat": 38.685,
      "_lng": -121.59597,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 11024133,
          "create_date": "2024-03-07T17:00:42.267893Z",
          "update_date": "2024-03-07T17:00:42.267915Z",
          "organization_id": 73,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/833841639ab643f3a178c14860636341_TextronLogo_t.jpg",
          "size": 74180,
          "description": null,
          "link": null,
          "unique_identifier": "833841639ab643f3a178c14860636341"
        }
      ],
      "lng": -121.59597
    },
    {
      "id": 3103,
      "name": "Sacramento",
      "correlation_id": "101992",
      "billing_address": null,
      "start_date": "2019-09-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 789,
        "name": "Tesco Controls, Inc.",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f847d48e-2561-4ecf-a816-e1bfca980fcd"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "dd11bd78-fec5-4ab2-9111-8a160e2bde6e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Tesco Controls, Inc.",
      "_label": "Tesco Controls, Inc.",
      "_name": "Sacramento",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 3681,
      "name": "Sacramento, CA*",
      "correlation_id": "101798",
      "billing_address": null,
      "start_date": "2022-05-31T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1317,
        "name": "Cornerstone (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0d7ae4ef-e030-42af-893d-dce81e3c9a4e"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "edc6f145-9ec2-4072-94da-b6c5a4e1ed58",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cornerstone (CBRE)",
      "_label": "Cornerstone (CBRE)",
      "_name": "Sacramento, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 4493,
      "name": "Sacramento, CA*",
      "correlation_id": "101740",
      "billing_address": {
        "id": 2499,
        "street1": "1801 30th Street",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "95816",
        "lat": 38.5635872,
        "lon": -121.4704057,
        "country": "United States"
      },
      "start_date": "2022-08-19T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1814,
        "name": "Cordova 83 Properties",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "13aa8dd5-07ad-44d7-9974-ec0a01eddc1d"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "34891015-9b38-4a3d-9385-8c000fe42675",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Cordova 83 Properties",
      "_label": "Cordova 83 Properties",
      "_name": "Sacramento, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1263,
      "name": "Saint Louis, MO",
      "correlation_id": "10226",
      "billing_address": {
        "id": 3766,
        "street1": "1831 Chestnut St.",
        "street2": "",
        "city": "St. Louis",
        "region": "Missouri",
        "postal_code": null,
        "lat": 38.6302992,
        "lon": -90.2074729,
        "country": "United States"
      },
      "start_date": "2009-05-01T23:59:59Z",
      "total_sq_ft": "400075",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 26,
        "name": "Elevance",
        "type": "CUSTOMER",
        "correlation_id": "10225",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "593e587a-ae72-4c35-b4ee-202beb68bee2"
      },
      "parent_id": null,
      "lat": 38.6303751,
      "lon": -90.2069183,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "510b5f97-69b4-4ee9-8dd4-ec1c91a5b39a",
      "is_mobile": true,
      "mobile_live_date": "2020-07-30",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Elevance",
      "_label": "Elevance",
      "_name": "Saint Louis, MO",
      "_lat": 38.6303751,
      "_lng": -90.2069183,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10303915,
          "create_date": "2024-01-23T22:57:54.893760Z",
          "update_date": "2024-01-23T22:57:54.893793Z",
          "organization_id": 26,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/fff07d6dbd75466da01340341e475db6_whiteelevedi_t.png",
          "size": 6424,
          "description": null,
          "link": null,
          "unique_identifier": "fff07d6dbd75466da01340341e475db6"
        }
      ],
      "lng": -90.2069183
    },
    {
      "id": 7128,
      "name": "Salt Lake City, UT*",
      "correlation_id": "103443",
      "billing_address": null,
      "start_date": "2024-06-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2834,
        "name": "Amazon Motors (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f582fe9d-ef57-41c3-8e68-5370257d3ab9"
      },
      "parent_id": null,
      "lat": 40.78778,
      "lon": -112.02069,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d08659f5-26c9-4634-a237-8f8809fe74e7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amazon Motors (CBRE)",
      "_label": "Amazon Motors (CBRE)",
      "_name": "Salt Lake City, UT*",
      "_lat": 40.78778,
      "_lng": -112.02069,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -112.02069
    },
    {
      "id": 1079,
      "name": "Salt Lake City, UT (JLL)",
      "correlation_id": "10862",
      "billing_address": null,
      "start_date": "2017-03-05T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 40.7565895,
      "lon": -111.8718008,
      "local_timezone": {
        "id": 2183,
        "code": "America/Denver",
        "display_name": "America/Denver",
        "description": "",
        "child_group": null,
        "alt_sequence": 49
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2d34789b-6db3-4797-b315-5756b82cc920",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Salt Lake City, UT (JLL)",
      "_lat": 40.7565895,
      "_lng": -111.8718008,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -111.8718008
    },
    {
      "id": 2452,
      "name": "San Antonio",
      "correlation_id": "102184",
      "billing_address": {
        "id": 281,
        "street1": "1435 N. Loop 1604 W, Suite 101",
        "street2": "",
        "city": "San Antonio",
        "region": "Texas",
        "postal_code": "78258-3013",
        "lat": 29.6096683,
        "lon": -98.5146416,
        "country": "United States"
      },
      "start_date": "2021-03-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 29.609525,
      "lon": -98.514528,
      "local_timezone": {
        "id": 2247,
        "code": "America/North_Dakota/Center",
        "display_name": "America/North_Dakota/Center",
        "description": "",
        "child_group": null,
        "alt_sequence": 113
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7a41c920-e52d-4b6d-a614-cad8345e9a2e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "San Antonio",
      "_lat": 29.609525,
      "_lng": -98.514528,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -98.514528
    },
    {
      "id": 5715,
      "name": "San Antonio Heights",
      "correlation_id": "102581",
      "billing_address": null,
      "start_date": "2023-05-04T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 29.49515,
      "lon": -98.47891,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ce671c94-5a4f-4a9d-9f7a-c1d51e9a3ca1",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "San Antonio Heights",
      "_lat": 29.49515,
      "_lng": -98.47891,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -98.47891
    },
    {
      "id": 1626,
      "name": "San Antonio, TX",
      "correlation_id": "11180",
      "billing_address": {
        "id": 3023,
        "street1": "4101 Wiseman Blvd.",
        "street2": "",
        "city": "San Antonio",
        "region": "Texas",
        "postal_code": null,
        "lat": 29.478178,
        "lon": -98.705919,
        "country": "United States"
      },
      "start_date": "2023-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "11",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 29.4295961,
      "lon": -98.6543005,
      "local_timezone": {
        "id": 2173,
        "code": "America/Chicago",
        "display_name": "America/Chicago",
        "description": "",
        "child_group": null,
        "alt_sequence": 39
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
      "is_mobile": true,
      "mobile_live_date": "2023-05-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "San Antonio, TX",
      "_lat": 29.4295961,
      "_lng": -98.6543005,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -98.6543005
    },
    {
      "id": 5636,
      "name": "San Bernardino Main (JLL)",
      "correlation_id": "102535",
      "billing_address": null,
      "start_date": "2023-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 34.10456,
      "lon": -117.2906,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "fdcf72af-9050-4465-a007-0c4f715773cc",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "San Bernardino Main (JLL)",
      "_lat": 34.10456,
      "_lng": -117.2906,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -117.2906
    },
    {
      "id": 1645,
      "name": "San Diego",
      "correlation_id": "11212",
      "billing_address": {
        "id": 479,
        "street1": "5200 Illumina Way",
        "street2": "",
        "city": "San Diego",
        "region": "California",
        "postal_code": "92122",
        "lat": 32.8708716,
        "lon": -117.1990312,
        "country": "United States"
      },
      "start_date": "2017-06-30T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 179,
        "name": "Illumina",
        "type": "CUSTOMER",
        "correlation_id": "11211",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "069c1097-0984-4c00-9216-9031ac3a85a5"
      },
      "parent_id": null,
      "lat": 32.8708716,
      "lon": -117.1990312,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c7427fd4-d598-4d68-b44e-5ad897b90457",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Illumina",
      "_label": "Illumina",
      "_name": "San Diego",
      "_lat": 32.8708716,
      "_lng": -117.1990312,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -117.1990312
    },
    {
      "id": 1832,
      "name": "San Diego",
      "correlation_id": "101839",
      "billing_address": {
        "id": 107,
        "street1": "16409 W Bernardo Dr, Suite 100",
        "street2": "",
        "city": "San Diego",
        "region": "California",
        "postal_code": "92131",
        "lat": 33.0123049,
        "lon": -117.0857519,
        "country": "United States"
      },
      "start_date": "2021-03-12T00:00:00Z",
      "total_sq_ft": "53301",
      "total_buildings": "3",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 32.940075,
      "lon": -117.103041,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "cf61fb94-ebaf-4082-84d4-62c91b52de16",
      "is_mobile": true,
      "mobile_live_date": "2020-10-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "San Diego",
      "_lat": 32.940075,
      "_lng": -117.103041,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.103041
    },
    {
      "id": 3172,
      "name": "San Diego",
      "correlation_id": "102018",
      "billing_address": {
        "id": 1307,
        "street1": "4545 Town Center Ct",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "92121",
        "lat": 32.88351,
        "lon": -117.2115272,
        "country": "United States"
      },
      "start_date": "2022-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 74,
        "name": "Abbott",
        "type": "CUSTOMER",
        "correlation_id": "10133",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8c8cedcf-dd44-44da-a522-f48a33f74bd8"
      },
      "parent_id": null,
      "lat": 32.8835,
      "lon": -117.2115,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "81576ce5-e7dc-4251-a867-c9382695dedf",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Abbott",
      "_label": "Abbott",
      "_name": "San Diego",
      "_lat": 32.8835,
      "_lng": -117.2115,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852957,
          "create_date": "2020-11-13T23:31:58.061272Z",
          "update_date": "2020-11-13T23:31:58.061296Z",
          "organization_id": 74,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605310317.960061_ABBOTTLOGO1_t.jpg",
          "size": 62993,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.2115
    },
    {
      "id": 2072,
      "name": "San Diego, CA",
      "correlation_id": "11672",
      "billing_address": {
        "id": 674,
        "street1": "17075 Thormint Ct",
        "street2": "",
        "city": "San Diego",
        "region": "California",
        "postal_code": "92127",
        "lat": 33.0181702,
        "lon": -117.0989902,
        "country": "United States"
      },
      "start_date": "2020-03-04T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 281,
        "name": "ASML (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11671",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5d48c550-59a7-4017-8897-0ce78fc6794d"
      },
      "parent_id": null,
      "lat": 33.0187007,
      "lon": -117.1027417,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
      "is_mobile": true,
      "mobile_live_date": "2021-09-01",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "ASML (CBRE)",
      "_label": "ASML (CBRE)",
      "_name": "San Diego, CA",
      "_lat": 33.0187007,
      "_lng": -117.1027417,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 916871,
          "create_date": "2020-12-07T16:46:46.706485Z",
          "update_date": "2020-12-07T16:46:46.706507Z",
          "organization_id": 281,
          "uploaded_by_id": 41295,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1607359606.6159544_ASML_t.png",
          "size": 2878,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.1027417
    },
    {
      "id": 2837,
      "name": "San Diego, CA*",
      "correlation_id": "101759",
      "billing_address": null,
      "start_date": "2019-10-01T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 135,
        "name": "Xerox (JLL)",
        "type": "CUSTOMER",
        "correlation_id": "10758",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5e49ffe1-2f29-4391-a283-62207d27fee9"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "bd5baa94-a7fb-4447-a086-fbbd4e9c2ccd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Xerox (JLL)",
      "_label": "Xerox (JLL)",
      "_name": "San Diego, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10767468,
          "create_date": "2024-02-21T19:02:11.125759Z",
          "update_date": "2024-02-21T19:02:11.125781Z",
          "organization_id": 135,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/339e6ea28cd34e4fb23bec97f35320ce_xeroxlogo_t.png",
          "size": 20821,
          "description": null,
          "link": null,
          "unique_identifier": "339e6ea28cd34e4fb23bec97f35320ce"
        }
      ],
      "lng": 0
    },
    {
      "id": 3872,
      "name": "San Diego, CA*",
      "correlation_id": "101966",
      "billing_address": null,
      "start_date": "2022-06-17T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1432,
        "name": "Vistage",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "20d09133-4187-4b34-9a9c-795995c047c6"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "17bdf1bb-1d32-49b5-966f-f3c357c654b8",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Vistage",
      "_label": "Vistage",
      "_name": "San Diego, CA*",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 1583,
      "name": "San Diego, CA (JLL)",
      "correlation_id": "11114",
      "billing_address": {
        "id": 558,
        "street1": "16399 W. BERNARDO DRIVE",
        "street2": "",
        "city": "San Diego",
        "region": "California",
        "postal_code": "92127",
        "lat": 33.0117285,
        "lon": -117.0896131,
        "country": "United States"
      },
      "start_date": "2018-02-01T23:59:59Z",
      "total_sq_ft": "3500000",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 158,
        "name": "HP",
        "type": "CUSTOMER",
        "correlation_id": "11106",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08e22142-6e8a-4e86-9da9-4ea479b68bb2"
      },
      "parent_id": null,
      "lat": 33.0113327,
      "lon": -117.0880608,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8188f4e0-572f-44c0-a828-bd6c1f2f109e",
      "is_mobile": true,
      "mobile_live_date": "2021-06-16",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "HP",
      "_label": "HP",
      "_name": "San Diego, CA (JLL)",
      "_lat": 33.0113327,
      "_lng": -117.0880608,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10766701,
          "create_date": "2024-02-21T18:23:57.465829Z",
          "update_date": "2024-02-21T18:23:57.465850Z",
          "organization_id": 158,
          "uploaded_by_id": 6257,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/bfd8993c88b84132b07d42a79779cf13_hp4insitewh_t.png",
          "size": 768676,
          "description": null,
          "link": null,
          "unique_identifier": "bfd8993c88b84132b07d42a79779cf13"
        }
      ],
      "lng": -117.0880608
    },
    {
      "id": 1791,
      "name": "San Dimas",
      "correlation_id": "11512",
      "billing_address": {
        "id": 451,
        "street1": "650 Cliffside Dr",
        "street2": "",
        "city": "San Dimas",
        "region": "California",
        "postal_code": "91773",
        "lat": 34.0970539,
        "lon": -117.8175259,
        "country": "United States"
      },
      "start_date": "2019-05-19T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 6,
        "name": "Gilead",
        "type": "CUSTOMER",
        "correlation_id": "10004",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "40490231-e0a4-472a-9333-f502280550fb"
      },
      "parent_id": null,
      "lat": 34.1066756,
      "lon": -117.8067257,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "02552785-259e-42bb-9f84-8f1d3409bb94",
      "is_mobile": true,
      "mobile_live_date": "2021-05-11",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Gilead",
      "_label": "Gilead",
      "_name": "San Dimas",
      "_lat": 34.1066756,
      "_lng": -117.8067257,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 869694,
          "create_date": "2020-11-19T19:29:37.020854Z",
          "update_date": "2020-11-19T19:29:37.020887Z",
          "organization_id": 6,
          "uploaded_by_id": 21219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605814176.888503_PNGPIXCOMGileadLogoPNGTransparent_t.png",
          "size": 197322,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -117.8067257
    },
    {
      "id": 3474,
      "name": "Sanford, NC",
      "correlation_id": "101874",
      "billing_address": {
        "id": 2232,
        "street1": "5000 Womack Road",
        "street2": "",
        "city": "Sanford",
        "region": "North Carolina",
        "postal_code": null,
        "lat": 35.546115,
        "lon": -79.170165,
        "country": "United States"
      },
      "start_date": "2022-07-20T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 501,
        "name": "Caterpillar",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 797,
            "street1": "1118 Rawson Ave",
            "street2": "",
            "city": "South Milwaukee",
            "region": "WI",
            "postal_code": "53172",
            "lat": 42.915569,
            "lon": -87.862785,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "5b9571f8-3f16-44d8-9ac0-6474c01232c3"
      },
      "parent_id": null,
      "lat": 35.546115,
      "lon": -79.170165,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d1161769-d77a-4004-afd6-58a38e7a0a1b",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Caterpillar",
      "_label": "Caterpillar",
      "_name": "Sanford, NC",
      "_lat": 35.546115,
      "_lng": -79.170165,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686586,
          "create_date": "2024-02-16T12:09:28.554699Z",
          "update_date": "2024-02-16T12:09:28.554720Z",
          "organization_id": 501,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/df2e3426784245ea910dec80174aa9ec_31fnFxMfKNL._AC_UF8941000_QL80__t.jpg",
          "size": 23329,
          "description": null,
          "link": null,
          "unique_identifier": "df2e3426784245ea910dec80174aa9ec"
        }
      ],
      "lng": -79.170165
    },
    {
      "id": 3993,
      "name": "San Francisco",
      "correlation_id": "102103",
      "billing_address": {
        "id": 1968,
        "street1": "250 Howard St.",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": "94105",
        "lat": 37.7900471,
        "lon": -122.3946707,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 37.78975,
      "lon": -122.39529,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
      "is_mobile": true,
      "mobile_live_date": "2024-02-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "San Francisco",
      "_lat": 37.78975,
      "_lng": -122.39529,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": -122.39529
    },
    {
      "id": 3679,
      "name": "San Francisco, CA",
      "correlation_id": "101762",
      "billing_address": {
        "id": 2930,
        "street1": "2 Harrison Street, Suite # 180",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": null,
        "lat": 37.7894009,
        "lon": -122.3889559,
        "country": "United States"
      },
      "start_date": "2022-12-01T23:41:02.639000Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1315,
        "name": "Hills Plaza (JLL)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "3c643273-2d4d-490a-8c9c-ccffab1ad44a"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "ed47b367-a94e-4c38-8486-a8ca4ed4f169",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Hills Plaza (JLL)",
      "_label": "Hills Plaza (JLL)",
      "_name": "San Francisco, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5817,
      "name": "San Francisco, CA",
      "correlation_id": "102741",
      "billing_address": {
        "id": 3758,
        "street1": "1355 Market St. Suite #300",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": null,
        "lat": 37.7770971,
        "lon": -122.4164914,
        "country": "United States"
      },
      "start_date": "2020-12-07T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 37.77725,
      "lon": -122.41664,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
      "is_mobile": true,
      "mobile_live_date": "2023-08-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "San Francisco, CA",
      "_lat": 37.77725,
      "_lng": -122.41664,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.41664
    },
    {
      "id": 1480,
      "name": "San Francisco, CA (JLL)",
      "correlation_id": "10062",
      "billing_address": {
        "id": 1506,
        "street1": "345 Spear St",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": null,
        "lat": 37.7899545,
        "lon": -122.389913,
        "country": "United States"
      },
      "start_date": "2016-05-01T07:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "17",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 37.7749295,
      "lon": -122.4194155,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "183b9c41-c951-4d97-acb6-70b753a98a40",
      "is_mobile": true,
      "mobile_live_date": "2022-03-24",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "San Francisco, CA (JLL)",
      "_lat": 37.7749295,
      "_lng": -122.4194155,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.4194155
    },
    {
      "id": 5523,
      "name": "San Francisco/Head Office (JLL)",
      "correlation_id": "102327",
      "billing_address": {
        "id": 3787,
        "street1": "420 Montgomery Street",
        "street2": "",
        "city": "San Francisco,",
        "region": "California",
        "postal_code": null,
        "lat": 37.7933827,
        "lon": -122.4026176,
        "country": "United States"
      },
      "start_date": "2023-01-03T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 37.79334,
      "lon": -122.40286,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "98ed1974-2405-49f9-96f9-e1c60e22ef93",
      "is_mobile": true,
      "mobile_live_date": "2023-09-26",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "San Francisco/Head Office (JLL)",
      "_lat": 37.79334,
      "_lng": -122.40286,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -122.40286
    },
    {
      "id": 1231,
      "name": "San Francisco (JLL)",
      "correlation_id": "10180",
      "billing_address": {
        "id": 240,
        "street1": "1120 Veterans Blvd.",
        "street2": "",
        "city": "San Francisco",
        "region": "California",
        "postal_code": "94090",
        "lat": 37.8007508,
        "lon": -122.4694115,
        "country": "United States"
      },
      "start_date": "2008-12-31T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "12",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 11,
        "name": "Amgen",
        "type": "CUSTOMER",
        "correlation_id": "10005",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4039c7d0-6c24-4c2d-881b-30b19567fc29"
      },
      "parent_id": null,
      "lat": 37.6631424,
      "lon": -122.3918849,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0098c957-b192-4bc2-a046-ea84ae6c633a",
      "is_mobile": true,
      "mobile_live_date": "2021-02-17",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Amgen",
      "_label": "Amgen",
      "_name": "San Francisco (JLL)",
      "_lat": 37.6631424,
      "_lng": -122.3918849,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853055,
          "create_date": "2020-11-14T00:02:14.473739Z",
          "update_date": "2020-11-14T00:02:14.473766Z",
          "organization_id": 11,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312134.3328376_Artboard12_t.png",
          "size": 134976,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.3918849
    },
    {
      "id": 1504,
      "name": "San Jose",
      "correlation_id": "10841",
      "billing_address": {
        "id": 2932,
        "street1": "2688 Orchard Pkwy.",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": null,
        "lat": 37.3873697,
        "lon": -121.9313169,
        "country": "United States"
      },
      "start_date": "2016-12-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 37.3869117,
      "lon": -121.9315263,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a87ae0ae-3b7f-4a40-a646-a6d352960791",
      "is_mobile": true,
      "mobile_live_date": "2022-12-02",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "San Jose",
      "_lat": 37.3869117,
      "_lng": -121.9315263,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": -121.9315263
    },
    {
      "id": 1542,
      "name": "San Jose",
      "correlation_id": "11022",
      "billing_address": {
        "id": 63,
        "street1": "Avenida 15, Calle 3, Cantón Goicochea PO. Box: 2330-1000",
        "street2": "",
        "city": "San José",
        "region": "Alabama",
        "postal_code": "11802",
        "lat": 9.9546686,
        "lon": -84.0220298,
        "country": "Costa Rica"
      },
      "start_date": "2014-10-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 146,
        "name": "Laica (CR)",
        "type": "CUSTOMER",
        "correlation_id": "11020",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d6ed91b2-b65d-4353-a332-63090f445ce7"
      },
      "parent_id": null,
      "lat": 9.9431116,
      "lon": -84.0761995,
      "local_timezone": {
        "id": 2177,
        "code": "America/Costa_Rica",
        "display_name": "America/Costa_Rica",
        "description": "",
        "child_group": null,
        "alt_sequence": 43
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9aa7294f-059a-4abd-b619-bef4bc7a1936",
      "is_mobile": true,
      "mobile_live_date": "2021-11-10",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Laica (CR)",
      "_label": "Laica (CR)",
      "_name": "San Jose",
      "_lat": 9.9431116,
      "_lng": -84.0761995,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.0761995
    },
    {
      "id": 1833,
      "name": "San Jose",
      "correlation_id": "101835",
      "billing_address": {
        "id": 108,
        "street1": "101 Innovation Dr.",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": "95134",
        "lat": 37.4010646,
        "lon": -121.9354895,
        "country": "United States"
      },
      "start_date": "2016-01-12T00:00:00Z",
      "total_sq_ft": "530633",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 37.4010923,
      "lon": -121.9354746,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "7a4c8479-fa78-453e-aa6e-a2a0a8953580",
      "is_mobile": true,
      "mobile_live_date": "2020-10-07",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "San Jose",
      "_lat": 37.4010923,
      "_lng": -121.9354746,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.9354746
    },
    {
      "id": 2556,
      "name": "San Jose",
      "correlation_id": "102182",
      "billing_address": {
        "id": 542,
        "street1": "12 Ranch Dr",
        "street2": "",
        "city": "Milpitas",
        "region": "California",
        "postal_code": "95035",
        "lat": 37.4261501,
        "lon": -121.9233415,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 37.42597,
      "lon": -121.92334,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1d200f7e-9727-4937-91d9-d58f5784f1dd",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "San Jose",
      "_lat": 37.42597,
      "_lng": -121.92334,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.92334
    },
    {
      "id": 2143,
      "name": "San Jose, CA",
      "correlation_id": "101235",
      "billing_address": {
        "id": 466,
        "street1": "5099 Hyland Ave.",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": "95127",
        "lat": 37.376771,
        "lon": -121.8239657,
        "country": "United States"
      },
      "start_date": "2020-09-13T23:59:59Z",
      "total_sq_ft": "90000",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 304,
        "name": "QuantumScape",
        "type": "CUSTOMER",
        "correlation_id": "101235",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9d9e7dbf-6479-4c69-b203-b2a4d855bbb8"
      },
      "parent_id": null,
      "lat": 37.33548,
      "lon": -121.893028,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "729a9828-f457-459d-8cee-da78e6b5c4ec",
      "is_mobile": true,
      "mobile_live_date": "2021-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "QuantumScape",
      "_label": "QuantumScape",
      "_name": "San Jose, CA",
      "_lat": 37.33548,
      "_lng": -121.893028,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.893028
    },
    {
      "id": 2167,
      "name": "San Jose, CA",
      "correlation_id": "101251",
      "billing_address": {
        "id": 386,
        "street1": "5099 Hyland Ave",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": "95237",
        "lat": 37.376771,
        "lon": -121.8239657,
        "country": "United States"
      },
      "start_date": "2020-09-29T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 314,
        "name": "Zscaler",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "101251",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f904ff4d-08eb-44e0-a88a-fd2b1e6852f2"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2cc2d54f-e27d-4ef0-9f0e-67158ba29104",
      "is_mobile": true,
      "mobile_live_date": "2021-01-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Zscaler",
      "_label": "Zscaler",
      "_name": "San Jose, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 2648,
      "name": "San Jose, CA",
      "correlation_id": "101583",
      "billing_address": {
        "id": 979,
        "street1": "110 Holger Way",
        "street2": "",
        "city": "San Jose",
        "region": "California",
        "postal_code": "95134",
        "lat": 37.4176017,
        "lon": -121.9537546,
        "country": "United States"
      },
      "start_date": "2021-11-01T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "4",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 493,
        "name": "Micron (C&W)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f8922e27-f57a-4029-8bc3-609db025f265"
      },
      "parent_id": null,
      "lat": 37.4176017,
      "lon": -121.9537546,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e28b9721-0365-4756-a9f2-e405eaf84e9e",
      "is_mobile": true,
      "mobile_live_date": "2022-01-13",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Micron (C&W)",
      "_label": "Micron (C&W)",
      "_name": "San Jose, CA",
      "_lat": 37.4176017,
      "_lng": -121.9537546,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.9537546
    },
    {
      "id": 2619,
      "name": "San Juan, PR* (JLL)",
      "correlation_id": "101528",
      "billing_address": {
        "id": 733,
        "street1": "1234 staduim st",
        "street2": "",
        "city": "stadium st",
        "region": "Puerto Rico",
        "postal_code": "00936",
        "lat": 18.466224,
        "lon": -66.1057244,
        "country": "Switzerland"
      },
      "start_date": "2021-09-20T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 486,
        "name": "PWC",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [
          {
            "id": 733,
            "street1": "1234 staduim st",
            "street2": "",
            "city": "stadium st",
            "region": "Puerto Rico",
            "postal_code": "00936",
            "lat": 18.466224,
            "lon": -66.1057244,
            "country": "Switzerland"
          }
        ],
        "status": "Unsuspended",
        "uuid": "da321ef1-93ab-4370-b61a-3f2b39dacc23"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "e7833bba-b0bb-4878-9110-bd3802cbc0e2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "PWC",
      "_label": "PWC",
      "_name": "San Juan, PR* (JLL)",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": 0
    },
    {
      "id": 5520,
      "name": "San Leandro Ops (JLL)",
      "correlation_id": "102328",
      "billing_address": {
        "id": 3798,
        "street1": "794 Davis Street",
        "street2": "",
        "city": "San Leandro",
        "region": "California",
        "postal_code": null,
        "lat": 37.7235419,
        "lon": -122.1636098,
        "country": "United States"
      },
      "start_date": "2023-08-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42"
      },
      "parent_id": null,
      "lat": 37.7236406,
      "lon": -122.1655343,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "080cf858-d8ec-4300-9a1a-9f90a2b1c6ca",
      "is_mobile": true,
      "mobile_live_date": "2023-10-19",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo",
      "_label": "Wells Fargo",
      "_name": "San Leandro Ops (JLL)",
      "_lat": 37.7236406,
      "_lng": -122.1655343,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10567382,
          "create_date": "2024-02-08T22:58:05.885457Z",
          "update_date": "2024-02-08T22:58:05.885482Z",
          "organization_id": 87,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "320ac12a50b64a148d2b21f7138ea2d8"
        }
      ],
      "lng": -122.1655343
    },
    {
      "id": 5913,
      "name": "San Lorenzo, PR*",
      "correlation_id": "102820",
      "billing_address": null,
      "start_date": "2024-03-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "7",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 84,
        "name": "Johnson & Johnson",
        "type": "CUSTOMER",
        "correlation_id": "10051",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd845bd8-ad79-4912-a0fb-929b3803bbef"
      },
      "parent_id": null,
      "lat": 18.2218072,
      "lon": -65.9814313,
      "local_timezone": {
        "id": 2258,
        "code": "America/Puerto_Rico",
        "display_name": "America/Puerto_Rico",
        "description": "",
        "child_group": null,
        "alt_sequence": 124
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c1319a62-0fa0-47d0-8437-2743430b6e35",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Johnson & Johnson",
      "_label": "Johnson & Johnson",
      "_name": "San Lorenzo, PR*",
      "_lat": 18.2218072,
      "_lng": -65.9814313,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -65.9814313
    },
    {
      "id": 1531,
      "name": "San Ramon",
      "correlation_id": "11010",
      "billing_address": null,
      "start_date": "2019-07-29T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 66,
        "name": "Claro (CR)",
        "type": "CUSTOMER",
        "correlation_id": "10996",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bd3b5a83-4967-4d33-a99e-b06b63baef24"
      },
      "parent_id": null,
      "lat": 10.08130321,
      "lon": -84.51238722,
      "local_timezone": null,
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "85045540-ad81-436f-ae1b-2f738b4b30a6",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Claro (CR)",
      "_label": "Claro (CR)",
      "_name": "San Ramon",
      "_lat": 10.08130321,
      "_lng": -84.51238722,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -84.51238722
    },
    {
      "id": 2555,
      "name": "San Ramon*",
      "correlation_id": "102185",
      "billing_address": {
        "id": 541,
        "street1": "3150 Crow Canyon Rd",
        "street2": "",
        "city": "San Ramon",
        "region": "California",
        "postal_code": "95035",
        "lat": 37.7794031,
        "lon": -121.9705085,
        "country": "United States"
      },
      "start_date": "2021-06-25T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 37.77966,
      "lon": -121.97045,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "320f8d59-cd5a-43ff-8c0e-af9697b7f05a",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "San Ramon*",
      "_lat": 37.77966,
      "_lng": -121.97045,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.97045
    },
    {
      "id": 2570,
      "name": "Santa Ana",
      "correlation_id": "101514",
      "billing_address": {
        "id": 3777,
        "street1": "1761 E St Andrew Pl",
        "street2": "",
        "city": "Santa Ana",
        "region": "California",
        "postal_code": null,
        "lat": 33.7231528,
        "lon": -117.8422379,
        "country": "United States"
      },
      "start_date": "2023-07-01T17:45:08Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 468,
        "name": "Deutsche Bank",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a"
      },
      "parent_id": null,
      "lat": 33.72296,
      "lon": -117.84189,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Deutsche Bank",
      "_label": "Deutsche Bank",
      "_name": "Santa Ana",
      "_lat": 33.72296,
      "_lng": -117.84189,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10772753,
          "create_date": "2024-02-21T23:24:53.738860Z",
          "update_date": "2024-02-21T23:24:53.738883Z",
          "organization_id": 468,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
          "size": 139120,
          "description": null,
          "link": null,
          "unique_identifier": "dc7aa29633644a68beb3aeb2abd0e74a"
        }
      ],
      "lng": -117.84189
    },
    {
      "id": 5889,
      "name": "Santa Barbara",
      "correlation_id": "102775",
      "billing_address": null,
      "start_date": "2023-11-08T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 34.4324,
      "lon": -119.81249,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "b50071e7-d8af-43e1-82d0-eaf8bd9c6b32",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "Santa Barbara",
      "_lat": 34.4324,
      "_lng": -119.81249,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.81249
    },
    {
      "id": 2509,
      "name": "Santa Barbara, CA",
      "correlation_id": "102183",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 34.4203744,
      "lon": -119.7049558,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "a2bb2b77-a489-4007-a725-1bc98be69f35",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Santa Barbara, CA",
      "_lat": 34.4203744,
      "_lng": -119.7049558,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -119.7049558
    },
    {
      "id": 1568,
      "name": "Santa Clara",
      "correlation_id": "11081",
      "billing_address": null,
      "start_date": "2017-10-18T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "2",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 153,
        "name": "Arris",
        "type": "CUSTOMER",
        "correlation_id": "11080",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "39f82829-1ad8-47ab-b88a-095fb387e097"
      },
      "parent_id": null,
      "lat": 37.3697813,
      "lon": -121.9719996,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "38e5c35e-b848-482b-8b81-b02df2137f56",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Arris",
      "_label": "Arris",
      "_name": "Santa Clara",
      "_lat": 37.3697813,
      "_lng": -121.9719996,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -121.9719996
    },
    {
      "id": 1837,
      "name": "Santa Clara",
      "correlation_id": "10637",
      "billing_address": {
        "id": 110,
        "street1": "2200 Mission College Blvd",
        "street2": "",
        "city": "Santa Clara",
        "region": "California",
        "postal_code": "95054",
        "lat": 37.3878827,
        "lon": -121.9636602,
        "country": "United States"
      },
      "start_date": "2021-03-12T23:59:59Z",
      "total_sq_ft": "2994102",
      "total_buildings": "6",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 21,
        "name": "Intel",
        "type": "CUSTOMER",
        "correlation_id": "10054",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2da3186b-e4ad-424c-90bf-0d3eb9926e87"
      },
      "parent_id": null,
      "lat": 37.3875909,
      "lon": -121.9637869,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "33900bd0-2064-4951-a5e7-f53c0bd3b230",
      "is_mobile": true,
      "mobile_live_date": "2020-10-08",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Intel",
      "_label": "Intel",
      "_name": "Santa Clara",
      "_lat": 37.3875909,
      "_lng": -121.9637869,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853027,
          "create_date": "2020-11-13T23:54:16.683280Z",
          "update_date": "2020-11-13T23:54:16.683306Z",
          "organization_id": 21,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311656.4718857_Artboard7_t.png",
          "size": 440995,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.9637869
    },
    {
      "id": 839,
      "name": "Santa Clara, CA",
      "correlation_id": "11085",
      "billing_address": {
        "id": 476,
        "street1": "2788 San Tomas Expy",
        "street2": "",
        "city": "Santa Clara",
        "region": "California",
        "postal_code": "95051",
        "lat": 37.3708002,
        "lon": -121.9673611,
        "country": "United States"
      },
      "start_date": "2017-12-18T23:59:59Z",
      "total_sq_ft": "1952981",
      "total_buildings": "15",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 5,
        "name": "Nvidia (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11034",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1c39849c-f884-456b-9642-ee55b07a76b6"
      },
      "parent_id": null,
      "lat": 37.37086468,
      "lon": -121.96483141,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Nvidia (CBRE)",
      "_label": "Nvidia (CBRE)",
      "_name": "Santa Clara, CA",
      "_lat": 37.37086468,
      "_lng": -121.96483141,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853037,
          "create_date": "2020-11-13T23:57:40.522980Z",
          "update_date": "2020-11-13T23:57:40.523005Z",
          "organization_id": 5,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605311860.3280911_Artboard10_t.png",
          "size": 195877,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -121.96483141
    },
    {
      "id": 2266,
      "name": "Santa Clara, CA",
      "correlation_id": "101290",
      "billing_address": {
        "id": 201,
        "street1": "2220 De La Cruz Blvd  Santa Clara, CA 95050, USA",
        "street2": "",
        "city": "santa clara",
        "region": "California",
        "postal_code": "95050",
        "lat": 37.3619616,
        "lon": -121.9434003,
        "country": "United States"
      },
      "start_date": "2020-12-07T23:59:59Z",
      "total_sq_ft": "0",
      "total_buildings": "10",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
      "is_mobile": true,
      "mobile_live_date": "2021-02-21",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Microsoft (CBRE)",
      "_label": "Microsoft (CBRE)",
      "_name": "Santa Clara, CA",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853065,
          "create_date": "2020-11-14T00:05:06.333784Z",
          "update_date": "2020-11-14T00:05:06.333810Z",
          "organization_id": 8,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312306.0531929_Artboard4_t.png",
          "size": 88382,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": 0
    },
    {
      "id": 6929,
      "name": "Santa Cruz",
      "correlation_id": "103433",
      "billing_address": null,
      "start_date": "2024-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 68,
        "name": "Lockheed Martin Space",
        "type": "CUSTOMER",
        "correlation_id": "10104",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4fa0a7fa-ae48-4482-9361-73b289b09b91"
      },
      "parent_id": null,
      "lat": 37.13374,
      "lon": -122.20114,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d63d90cd-b7b6-4572-86a9-8e8a88d61ee9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Lockheed Martin Space",
      "_label": "Lockheed Martin Space",
      "_name": "Santa Cruz",
      "_lat": 37.13374,
      "_lng": -122.20114,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 853061,
          "create_date": "2020-11-14T00:03:45.382483Z",
          "update_date": "2020-11-14T00:03:45.382509Z",
          "organization_id": 68,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605312225.2345157_0_t.png",
          "size": 11272,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -122.20114
    },
    {
      "id": 4226,
      "name": "Santa Fe Springs",
      "correlation_id": "102030",
      "billing_address": {
        "id": 2168,
        "street1": "9501 Norwalk Blvd.",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "90670",
        "lat": 33.9537939,
        "lon": -118.0740669,
        "country": "United States"
      },
      "start_date": "2022-03-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35"
      },
      "parent_id": null,
      "lat": 33.95344,
      "lon": -118.07427,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "McKesson (CBRE)",
      "_label": "McKesson (CBRE)",
      "_name": "Santa Fe Springs",
      "_lat": 33.95344,
      "_lng": -118.07427,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 859826,
          "create_date": "2020-11-17T14:34:24.774886Z",
          "update_date": "2020-11-17T14:34:24.774913Z",
          "organization_id": 220,
          "uploaded_by_id": 13851,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
          "size": 3175,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -118.07427
    },
    {
      "id": 2499,
      "name": "Santa Monica, CA",
      "correlation_id": "102180",
      "billing_address": null,
      "start_date": "2021-05-11T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2157,
        "name": "Charles Schwab-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "abb94fed-143b-44ec-a7e4-e60fe3c850f5"
      },
      "parent_id": null,
      "lat": 38.51429,
      "lon": -122.7143236,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "2e5d6f83-7092-43ee-9bb9-7145a1c73b00",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab-Sub",
      "_label": "Charles Schwab-Sub",
      "_name": "Santa Monica, CA",
      "_lat": 38.51429,
      "_lng": -122.7143236,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7143236
    },
    {
      "id": 5657,
      "name": "Santa Monica (JLL)",
      "correlation_id": "102406",
      "billing_address": null,
      "start_date": "2023-03-10T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5"
      },
      "parent_id": null,
      "lat": 34.0173934,
      "lon": -118.4967516,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "9fb13187-6432-419b-a9ea-39fa48fee2e7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Wells Fargo-Sub",
      "_label": "Wells Fargo-Sub",
      "_name": "Santa Monica (JLL)",
      "_lat": 34.0173934,
      "_lng": -118.4967516,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10566397,
          "create_date": "2024-02-08T21:47:08.190378Z",
          "update_date": "2024-02-08T21:47:08.190400Z",
          "organization_id": 1649,
          "uploaded_by_id": 79782,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
          "size": 2879,
          "description": null,
          "link": null,
          "unique_identifier": "9557cea797cf44f1bcda03aff5231fdf"
        }
      ],
      "lng": -118.4967516
    },
    {
      "id": 1806,
      "name": "San Tan",
      "correlation_id": "102634",
      "billing_address": {
        "id": 3738,
        "street1": "3530 S Val Visa Drive",
        "street2": "",
        "city": "Gilbert",
        "region": "Arizona",
        "postal_code": null,
        "lat": 33.2858296,
        "lon": -111.7575901,
        "country": "United States"
      },
      "start_date": "2023-03-29T20:21:59Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab"
      },
      "parent_id": null,
      "lat": 33.28605,
      "lon": -111.75754,
      "local_timezone": {
        "id": 2253,
        "code": "America/Phoenix",
        "display_name": "America/Phoenix",
        "description": "",
        "child_group": null,
        "alt_sequence": 119
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c9619e89-b869-45bc-bdae-dc199a005480",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false,
      "_client": "Cigna (CBRE) - Sub",
      "_label": "Cigna (CBRE) - Sub",
      "_name": "San Tan",
      "_lat": 33.28605,
      "_lng": -111.75754,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10569426,
          "create_date": "2024-02-09T00:51:18.464478Z",
          "update_date": "2024-02-09T00:51:18.464507Z",
          "organization_id": 2154,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
          "size": 1140257,
          "description": null,
          "link": null,
          "unique_identifier": "168f09f26a77417c9a74216a70b42497"
        }
      ],
      "lng": -111.75754
    },
    {
      "id": 1246,
      "name": "Santa Rosa",
      "correlation_id": "10202",
      "billing_address": {
        "id": 505,
        "street1": "3576 Unocal Place",
        "street2": "",
        "city": "Santa Rosa",
        "region": "California",
        "postal_code": "95403",
        "lat": 38.4814356,
        "lon": -122.7234314,
        "country": "United States"
      },
      "start_date": "2021-06-04T23:59:59Z",
      "total_sq_ft": "1723540",
      "total_buildings": "5",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 58,
        "name": "Medtronic",
        "type": "CUSTOMER",
        "correlation_id": "10187",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b5aa6b6c-f610-4333-be04-fee2fb181a8e"
      },
      "parent_id": null,
      "lat": 38.4817597,
      "lon": -122.7213713,
      "local_timezone": {
        "id": 2438,
        "code": "Africa/Abidjan",
        "display_name": "Africa/Abidjan",
        "description": "",
        "child_group": null,
        "alt_sequence": 304
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "d6ec7ccd-013a-4fa2-8ba7-64f8d3bcf918",
      "is_mobile": true,
      "mobile_live_date": "2021-06-04",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Medtronic",
      "_label": "Medtronic",
      "_name": "Santa Rosa",
      "_lat": 38.4817597,
      "_lng": -122.7213713,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7213713
    },
    {
      "id": 2014,
      "name": "Santa Rosa, CA",
      "correlation_id": "11463",
      "billing_address": {
        "id": 703,
        "street1": "487 Aviation Blvd",
        "street2": "",
        "city": "Santa Rosa",
        "region": "California",
        "postal_code": "95403",
        "lat": 38.5174777,
        "lon": -122.7916103,
        "country": "United States"
      },
      "start_date": "2019-09-01T23:59:59Z",
      "total_sq_ft": "20700",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 208,
        "name": "Bio-Rad",
        "type": "CUSTOMER",
        "correlation_id": "11462",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "86f2e6a9-8c5e-4070-afa7-db939edb358a"
      },
      "parent_id": null,
      "lat": 38.5176191,
      "lon": -122.7939451,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "c9c30ea6-e007-4f55-8223-1ca84fe852bb",
      "is_mobile": true,
      "mobile_live_date": "2021-08-18",
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Bio-Rad",
      "_label": "Bio-Rad",
      "_name": "Santa Rosa, CA",
      "_lat": 38.5176191,
      "_lng": -122.7939451,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7939451
    },
    {
      "id": 2506,
      "name": "Santa Rosa, CA",
      "correlation_id": "102186",
      "billing_address": null,
      "start_date": "2021-04-01T13:44:17Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1942,
        "name": "Charles Schwab",
        "type": "SERVICE_PROVIDER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "bedd3d3f-dd8c-4c65-b5cd-d65409d7b9c8"
      },
      "parent_id": null,
      "lat": 38.4378557,
      "lon": -122.7213232,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "10e3533b-ca77-425b-99ef-94554c14cd41",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Charles Schwab",
      "_label": "Charles Schwab",
      "_name": "Santa Rosa, CA",
      "_lat": 38.4378557,
      "_lng": -122.7213232,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [],
      "lng": -122.7213232
    },
    {
      "id": 4394,
      "name": "Satcom, Richardson, TX",
      "correlation_id": "100355",
      "billing_address": null,
      "start_date": "2022-08-08T00:00:00Z",
      "total_sq_ft": null,
      "total_buildings": "0",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 139,
        "name": "General Dynamics",
        "type": "CUSTOMER",
        "correlation_id": "10687",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70b20b4a-e328-469b-bb77-10ccae53a076"
      },
      "parent_id": null,
      "lat": 0,
      "lon": 0,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "430930cf-d5d7-4bba-a335-602ceec39514",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "General Dynamics",
      "_label": "General Dynamics",
      "_name": "Satcom, Richardson, TX",
      "_lat": 0,
      "_lng": 0,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10686531,
          "create_date": "2024-02-16T12:02:54.014282Z",
          "update_date": "2024-02-16T12:02:54.014302Z",
          "organization_id": 139,
          "uploaded_by_id": 51219,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/4d4ed0a3620c46bcaf5d4ce4588141be_gdcircles_t.png",
          "size": 11030,
          "description": null,
          "link": null,
          "unique_identifier": "4d4ed0a3620c46bcaf5d4ce4588141be"
        }
      ],
      "lng": 0
    },
    {
      "id": 3995,
      "name": "Sausalito",
      "correlation_id": "102105",
      "billing_address": {
        "id": 1970,
        "street1": "2658 Bridgeway",
        "street2": "",
        "city": null,
        "region": "California",
        "postal_code": "94965",
        "lat": 37.868757,
        "lon": -122.5016847,
        "country": "United States"
      },
      "start_date": "2022-07-23T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92"
      },
      "parent_id": null,
      "lat": 37.86851,
      "lon": -122.50189,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "4711f2f6-bb8c-4cfb-aaaf-49edee61dbb7",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Meta (CBRE)",
      "_label": "Meta (CBRE)",
      "_name": "Sausalito",
      "_lat": 37.86851,
      "_lng": -122.50189,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10590144,
          "create_date": "2024-02-09T21:51:56.200234Z",
          "update_date": "2024-02-09T21:51:56.200253Z",
          "organization_id": 1422,
          "uploaded_by_id": 7299,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
          "size": 57293,
          "description": null,
          "link": null,
          "unique_identifier": "14d6f267a8cf4a209ac3764254e3c322"
        }
      ],
      "lng": -122.50189
    },
    {
      "id": 6736,
      "name": "Savannah",
      "correlation_id": "103404",
      "billing_address": null,
      "start_date": "2024-02-01T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 2569,
        "name": "Rolls Royce (JLL)",
        "type": "CUSTOMER",
        "correlation_id": null,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "600d9bbb-1284-448d-abad-06859b6e5b7e"
      },
      "parent_id": null,
      "lat": 32.12006,
      "lon": -81.19108,
      "local_timezone": {
        "id": 2243,
        "code": "America/New_York",
        "display_name": "America/New_York",
        "description": "",
        "child_group": null,
        "alt_sequence": 109
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "0c357314-7864-429c-b60e-9e62ed67bca2",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Rolls Royce (JLL)",
      "_label": "Rolls Royce (JLL)",
      "_name": "Savannah",
      "_lat": 32.12006,
      "_lng": -81.19108,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 10698011,
          "create_date": "2024-02-16T20:21:19.638926Z",
          "update_date": "2024-02-16T20:21:19.638950Z",
          "organization_id": 2569,
          "uploaded_by_id": 65569,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/03e3aeee56694017ab13e66c9c96766f_RR_t.jpg",
          "size": 15030,
          "description": null,
          "link": null,
          "unique_identifier": "03e3aeee56694017ab13e66c9c96766f"
        }
      ],
      "lng": -81.19108
    },
    {
      "id": 5891,
      "name": "SBA-GQ3*",
      "correlation_id": null,
      "billing_address": null,
      "start_date": "2023-10-16T00:00:00Z",
      "total_sq_ft": "0",
      "total_buildings": "1",
      "service_provider": {
        "id": 1,
        "name": "SBM",
        "type": "SERVICE_PROVIDER",
        "correlation_id": "10049",
        "addresses": [
          {
            "id": 82,
            "street1": "5241 Arnold Ave",
            "street2": "",
            "city": "McClellan Park",
            "region": "CA",
            "postal_code": "95652",
            "lat": 38.6630216,
            "lon": -121.3854525,
            "country": "United States"
          }
        ],
        "status": "Unsuspended",
        "uuid": "83d21fba-bbc5-4822-b24d-301335ad65df"
      },
      "customer": {
        "id": 29,
        "name": "Ggl",
        "type": "CUSTOMER",
        "correlation_id": "10061",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1658cd55-8aa3-4d31-b61e-dc8a82287af3"
      },
      "parent_id": null,
      "lat": 34.43392,
      "lon": -119.86371,
      "local_timezone": {
        "id": 2223,
        "code": "America/Los_Angeles",
        "display_name": "America/Los_Angeles",
        "description": "",
        "child_group": null,
        "alt_sequence": 89
      },
      "hours_upload": true,
      "has_SOW": false,
      "action_status": "Unsuspended",
      "uuid": "1720e276-05e5-44f7-a54e-149d73d77338",
      "is_mobile": false,
      "mobile_live_date": null,
      "total_employees": 0,
      "chatbot_sms_phone_number": null,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true,
      "_client": "Ggl",
      "_label": "Ggl",
      "_name": "SBA-GQ3*",
      "_lat": 34.43392,
      "_lng": -119.86371,
      "_grade": "default",
      "_marker": "drop",
      "customer_logo": [
        {
          "id": 852932,
          "create_date": "2020-11-13T23:26:14.549119Z",
          "update_date": "2020-11-13T23:26:14.549144Z",
          "organization_id": 29,
          "uploaded_by_id": 6711,
          "source_id": null,
          "type": "logo",
          "mime_type": "logo",
          "file_name": "assets/1605309974.4638548_unnamed_t.png",
          "size": 4199,
          "description": null,
          "link": null,
          "unique_identifier": null
        }
      ],
      "lng": -119.86371
    }
  ]