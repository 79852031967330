import React, { useRef, useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import "./fullpage.css";
import "./fullpagePrint.css";
import { motion } from "framer-motion";
import ecovadis from "../../assets/images/ecovadis.png";
import avetta from "../../assets/videos/avetta.webm";
import avetta2 from "../../assets/videos/avettacbre.mp4";
import awards from "../../assets/videos/awards.webm";
import Slideshow from "../slideshow/slideshow";
import Chart from "../charts/chart";
import { NewClient } from "./client";
import loop from "../../assets/videos/hero_loop_no_title (1080p).mp4";
import sow from "../../assets/videos/SOW_Manager_SBM.mp4";
import vertical from "../../assets/videos/New_Vertical_Structure.mp4";
import Section from "./section";
import partners from "./nickvalnicole.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import ribbon from "../monthly/assets/ribbon.mp4";
import notificationHEIC from "../monthly/assets/notification_animation.mov";
import notificationWEBM from "../monthly/assets/notification_animation.webm";
import noise from "../../assets/images/noise.png";

import ecovadismov from "../../assets/videos/ecovadis2.mov";
import ecovadisebm from "../../assets/videos/ecovadis.webm";

import greatestworkplaces from '../../assets/images/agwfd-badge_.svg'
import eoc1 from '../../assets/images/eoc/eoc1.jpg';
import eoc2 from '../../assets/images/eoc/eoc2.jpg';
import eoc3 from '../../assets/images/eoc/eoc3.jpg';
import eoc4 from '../../assets/images/eoc/eoc4.jpg';

let baseImages = [
  {
    media_id: 8094819,
    source: "INSPECTION",
    source_id: 1106855,
    area_id: 217119,
    area_name: "1005",
    area_type_name: "Conference Rooms",
    parent_area_id: 217117,
    parent_area_name: "1",
    root_area_id: 217116,
    root_area_name: "1",
    source_date: "2023-09-19T20:54:16.662346Z",
    media_date: "2023-09-19T21:13:16.464361Z",
    file_name: "assets/8625d26a9f074590a16172591c98c4d8_IMG_1542.jpg",
    audit_score_calculated: 4.7,
    type: "JOINT FLEX AUDIT",
  },
  {
    media_id: 8094820,
    source: "INSPECTION",
    source_id: 1106855,
    area_id: 217119,
    area_name: "1005",
    area_type_name: "Conference Rooms",
    parent_area_id: 217117,
    parent_area_name: "1",
    root_area_id: 217116,
    root_area_name: "1",
    source_date: "2023-09-19T20:54:16.662346Z",
    media_date: "2023-09-19T21:13:19.997666Z",
    file_name: "assets/513834135ea3405ebde19e4d7b309e87_IMG_1541.jpg",
    audit_score_calculated: 4.7,
    type: "JOINT FLEX AUDIT",
  },
  {
    media_id: 8094790,
    source: "INSPECTION",
    source_id: 1106855,
    area_id: 217118,
    area_name: "1003",
    area_type_name: "Break Rooms",
    parent_area_id: 217117,
    parent_area_name: "1",
    root_area_id: 217116,
    root_area_name: "1",
    source_date: "2023-09-19T20:54:16.662346Z",
    media_date: "2023-09-19T21:11:37.480117Z",
    file_name: "assets/rcef629f5e0a641b0b588f911f7c8db2d_Picture22.jpg",
    audit_score_calculated: 4.5,
    type: "JOINT FLEX AUDIT",
  },
  {
    media_id: 8094786,
    source: "INSPECTION",
    source_id: 1106855,
    area_id: 217118,
    area_name: "1003",
    area_type_name: "Break Rooms",
    parent_area_id: 217117,
    parent_area_name: "1",
    root_area_id: 217116,
    root_area_name: "1",
    source_date: "2023-09-19T20:54:16.662346Z",
    media_date: "2023-09-19T21:11:31.588695Z",
    file_name: "assets/rd448075467a1416186c1c5518c8118fd_Picture24.jpg",
    audit_score_calculated: 4.5,
    type: "JOINT FLEX AUDIT",
  },
  {
    media_id: 8094454,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474381,
    area_name: "4",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T20:14:33.544307Z",
    file_name:
      "assets/r5f9dbd21ff814822865b4bb109be7650_3D1ACBEAB3B545B48709891079D3E3F4.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094422,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474380,
    area_name: "3",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T20:12:11.661802Z",
    file_name:
      "assets/rc3619477fc564170a8108f2aa9ce73d3_3821002376FB453B9E7797CAAE9B521B.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094434,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474380,
    area_name: "3",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T20:12:18.317477Z",
    file_name:
      "assets/rec92be90d87f4938a72be676386caf7a_C73D32B614094169A1B3C6CDD4224F24.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094400,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474379,
    area_name: "Physical Therapist",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T20:09:14.417190Z",
    file_name:
      "assets/86348e9dc7174affac1d9e3e149ab751_21EF85C5F55346DB9A23E9288233A032.jpeg",
    audit_score_calculated: 3.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094383,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474379,
    area_name: "Physical Therapist",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T20:07:26.711517Z",
    file_name:
      "assets/rd471a3e0489748dc9ddba4515205d6b3_66A801DFEF1D4B529F10CA5A849F2DDE.jpeg",
    audit_score_calculated: 3.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094041,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474444,
    area_name: "Uni Nurse Drug Test Station",
    area_type_name: "Restroom",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T19:39:58.403550Z",
    file_name:
      "assets/r59e58c1b2f364e2585967cf86e6d7d4d_775BD2C4E62F43D1A76F55D83918B299.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094033,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142451,
    area_name: "Conference Room 851 Floor 5",
    area_type_name: "Team Rooms/Conference Rooms",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T19:39:41.006416Z",
    file_name: "assets/r1fffb7ac3f2e4547bd7a4bf2b28a4429_20230918_1617011.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8094033,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142451,
    area_name: "Conference Room 851 Floor 5",
    area_type_name: "Team Rooms/Conference Rooms",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T19:39:41.006416Z",
    file_name: "assets/r1fffb7ac3f2e4547bd7a4bf2b28a4429_20230918_1617011.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8093965,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141992,
    area_name: "1",
    area_type_name: "Stairs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T19:35:37.328381Z",
    file_name: "assets/rfdea90226871431082f97b7387403403_20230918_1619531.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8093965,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141992,
    area_name: "1",
    area_type_name: "Stairs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T19:35:37.328381Z",
    file_name: "assets/rfdea90226871431082f97b7387403403_20230918_1619531.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8093842,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474382,
    area_name: "Break Room",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T19:28:35.466384Z",
    file_name:
      "assets/r86816707f1b64abf8f523d92758e8749_06D9A6459B894FD2A14AA18053CAD532.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8093839,
    source: "INSPECTION",
    source_id: 1104944,
    area_id: 474382,
    area_name: "Break Room",
    area_type_name: "Medical Office",
    parent_area_id: 474377,
    parent_area_name: "1",
    root_area_id: 474376,
    root_area_name: "Engine Plant",
    source_date: "2023-09-14T18:44:43.274952Z",
    media_date: "2023-09-19T19:28:18.464610Z",
    file_name:
      "assets/r6ac3af17303d46bdad8b8c23f1c27a75_74228446AD984FA48795BA30B1014446.jpeg",
    audit_score_calculated: 3.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092779,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141772,
    area_name: "Cubicles Wing",
    area_type_name: "Offices & Cubicles",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T18:36:32.327529Z",
    file_name: "assets/r35ef403cbe7b49208a1004a84a9efa00_20230918_1615401.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092779,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141772,
    area_name: "Cubicles Wing",
    area_type_name: "Offices & Cubicles",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T18:36:32.327529Z",
    file_name: "assets/r35ef403cbe7b49208a1004a84a9efa00_20230918_1615401.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092726,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141525,
    area_name: "Main Hallway",
    area_type_name: "Hallways & Corridors",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T18:34:56.864389Z",
    file_name: "assets/r5cedec5473c44fd7bcbead297219555b_20230918_1620101.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092726,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141525,
    area_name: "Main Hallway",
    area_type_name: "Hallways & Corridors",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T18:34:56.864389Z",
    file_name: "assets/r5cedec5473c44fd7bcbead297219555b_20230918_1620101.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092145,
    source: "INSPECTION",
    source_id: 1101458,
    area_id: 238626,
    area_name: "South Processing Lab",
    area_type_name: "Labs",
    parent_area_id: 238614,
    parent_area_name: "1",
    root_area_id: 238613,
    root_area_name: "Plant 1 - D&E",
    source_date: "2023-09-06T17:03:32.463026Z",
    media_date: "2023-09-19T18:09:19.190156Z",
    file_name: "assets/r35ad3a4fea9b42049c40db79e3ac3a6b_20220524_145349.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092134,
    source: "INSPECTION",
    source_id: 1101458,
    area_id: 238615,
    area_name: "North Processing Lab ",
    area_type_name: "Labs",
    parent_area_id: 238614,
    parent_area_name: "1",
    root_area_id: 238613,
    root_area_name: "Plant 1 - D&E",
    source_date: "2023-09-06T17:03:32.463026Z",
    media_date: "2023-09-19T18:08:47.593135Z",
    file_name: "assets/r79dec55accca4f88b61beef075f185bb_20220707_132253.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8092090,
    source: "INSPECTION",
    source_id: 1101458,
    area_id: 455192,
    area_name: "Kitchenette 302645",
    area_type_name: "Kitchen, Servery & Grab & Go",
    parent_area_id: 238614,
    parent_area_name: "1",
    root_area_id: 238613,
    root_area_name: "Plant 1 - D&E",
    source_date: "2023-09-06T17:03:32.463026Z",
    media_date: "2023-09-19T18:07:10.782282Z",
    file_name: "assets/bb841d16716e4bc7bc2360018096239c_20220707_131911.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091952,
    source: "INSPECTION",
    source_id: 1101458,
    area_id: 238637,
    area_name: "302015",
    area_type_name: "Conference Rooms",
    parent_area_id: 238614,
    parent_area_name: "1",
    root_area_id: 238613,
    root_area_name: "Plant 1 - D&E",
    source_date: "2023-09-06T17:03:32.463026Z",
    media_date: "2023-09-19T18:02:56.510476Z",
    file_name: "assets/r5e718a47ddea4c93be9492a6023ad69b_20220707_132703.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091660,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141493,
    area_name: "Freight",
    area_type_name: "Elevators",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:50:48.841032Z",
    file_name: "assets/raa34e4bb4ac4454fad0a3397bb87fd93_20230918_1618251.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091660,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141493,
    area_name: "Freight",
    area_type_name: "Elevators",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:50:48.841032Z",
    file_name: "assets/raa34e4bb4ac4454fad0a3397bb87fd93_20230918_1618251.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091614,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142071,
    area_name: "8515",
    area_type_name: "Labs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:46:56.244861Z",
    file_name: "assets/r0f80f00840e446708d959887ed61f80d_20230918_1618001.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091614,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142071,
    area_name: "8515",
    area_type_name: "Labs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:46:56.244861Z",
    file_name: "assets/r0f80f00840e446708d959887ed61f80d_20230918_1618001.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091626,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142071,
    area_name: "8515",
    area_type_name: "Labs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:47:39.481062Z",
    file_name: "assets/r5c5e0d24ca3c4d559f8f9c5b75f6a7ae_20230918_1618131.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091626,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 142071,
    area_name: "8515",
    area_type_name: "Labs",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:47:39.481062Z",
    file_name: "assets/r5c5e0d24ca3c4d559f8f9c5b75f6a7ae_20230918_1618131.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091618,
    source: "INSPECTION",
    source_id: 1104252,
    area_id: 473367,
    area_name: "SDI Lab Compute",
    area_type_name: "Labs",
    parent_area_id: 417872,
    parent_area_name: "1",
    root_area_id: 417871,
    root_area_name: "CR3 Cuarto Limpio",
    source_date: "2023-09-13T16:51:14.098513Z",
    media_date: "2023-09-19T17:47:16.198877Z",
    file_name: "assets/rdb41e884ae294cc7acd45c290f7954bc_1694624132640.jpg",
    audit_score_calculated: 100.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091616,
    source: "INSPECTION",
    source_id: 1104252,
    area_id: 473367,
    area_name: "SDI Lab Compute",
    area_type_name: "Labs",
    parent_area_id: 417872,
    parent_area_name: "1",
    root_area_id: 417871,
    root_area_name: "CR3 Cuarto Limpio",
    source_date: "2023-09-13T16:51:14.098513Z",
    media_date: "2023-09-19T17:47:10.609141Z",
    file_name: "assets/rebdb936a1a2e4992848e599dcbeef151_1694624110073.jpg",
    audit_score_calculated: 100.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091519,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141538,
    area_name: "85P31",
    area_type_name: "Janitorial Closet",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:41:10.339836Z",
    file_name: "assets/r52b6a3fba94945bca11ead064a93a72b_20230915_1151561.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091519,
    source: "INSPECTION",
    source_id: 1106448,
    area_id: 141538,
    area_name: "85P31",
    area_type_name: "Janitorial Closet",
    parent_area_id: 141395,
    parent_area_name: "5",
    root_area_id: 141382,
    root_area_name: "Bio 8",
    source_date: "2023-09-19T12:07:46.548820Z",
    media_date: "2023-09-19T17:41:10.339836Z",
    file_name: "assets/r52b6a3fba94945bca11ead064a93a72b_20230915_1151561.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091321,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255411,
    area_name: "2C1-10",
    area_type_name: "Conference Rooms",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T17:33:25.980806Z",
    file_name: "assets/dd72f198e37e4e8090b7c0f67d5e1c63_2con.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091225,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255469,
    area_name: "2",
    area_type_name: "Elevators",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T17:26:47.758124Z",
    file_name: "assets/9ed76b1385814aaba62698631b91cfb9_2ele.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8091050,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255487,
    area_name: "2CD",
    area_type_name: "Janitorial Closet",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T17:18:54.923414Z",
    file_name: "assets/902edff7a8594e558c1839913f9750fb_2jan1.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090673,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255382,
    area_name: "2D",
    area_type_name: "Kitchen, Servery & Grab & Go",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T17:07:48.031306Z",
    file_name: "assets/a06b25aef1994ebe81bcbaeb8cf67369_2kitchen.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090634,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255506,
    area_name: "4",
    area_type_name: "Offices",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T17:06:11.229365Z",
    file_name: "assets/0c42c7aedd9240c1b2ecdbd663ba2c49_2office.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090271,
    source: "INSPECTION",
    source_id: 1104253,
    area_id: 255605,
    area_name: "2D",
    area_type_name: "Stairs",
    parent_area_id: 255378,
    parent_area_name: "2",
    root_area_id: 255368,
    root_area_name: "East",
    source_date: "2023-09-13T16:51:46.691185Z",
    media_date: "2023-09-19T16:49:08.306102Z",
    file_name: "assets/593b4189dba1460d9847e8076c5d1d8e_2stairs.jpg",
    audit_score_calculated: 4.5,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090270,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 180076,
    area_name: "Vending Cafeteria",
    area_type_name: "Kitchen, Servery & Grab & Go",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:49:07.601426Z",
    file_name: "assets/re6bc8693130a4f63a0c328d80dc99447_1695141760810.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090268,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 180076,
    area_name: "Vending Cafeteria",
    area_type_name: "Kitchen, Servery & Grab & Go",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:48:55.142814Z",
    file_name: "assets/r7c162b0ba48f443aa10e117866c253fd_1695141751181.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090184,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 180006,
    area_name: "Einstein Area Break Roo+D47m",
    area_type_name: "Coffee, Break Areas & Pantries",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:46:25.828529Z",
    file_name: "assets/r8632de6d32494dbfb5a1be353f9159ab_1695141715070.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090175,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179862,
    area_name: "PDBMF Mothers",
    area_type_name: "Mothers Rooms",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:46:02.342138Z",
    file_name: "assets/r40ef42222cc140208f0f1f35fcd2376a_1695141167210.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090136,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179862,
    area_name: "PDBMF Mothers",
    area_type_name: "Mothers Rooms",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:45:08.885122Z",
    file_name: "assets/r92d5ba2d3aad405e835db1044225ff99_1695141166716.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090089,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179862,
    area_name: "PDBMF Mothers",
    area_type_name: "Mothers Rooms",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:44:06.785659Z",
    file_name: "assets/r8f3142cb5f6c4e75ada9eb1bffe0ecc4_1695141166200.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8090037,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179862,
    area_name: "PDBMF Mothers",
    area_type_name: "Mothers Rooms",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:41:39.779130Z",
    file_name: "assets/r58e2291d36e44317889e03f66b6a253a_1695141165676.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8089914,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179862,
    area_name: "PDBMF Mothers",
    area_type_name: "Mothers Rooms",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:38:03.819268Z",
    file_name: "assets/r288dcc224609495695bbf425e89e1c24_1695141165331.jpg",
    audit_score_calculated: 5.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8089846,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179932,
    area_name: "Einstein Area R&D",
    area_type_name: "Offices & Cubicles",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:35:31.040193Z",
    file_name: "assets/r686e25d82ac343f7ba39cefa37440e80_1695140748946.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
  {
    media_id: 8089840,
    source: "INSPECTION",
    source_id: 1106655,
    area_id: 179932,
    area_name: "Einstein Area R&D",
    area_type_name: "Offices & Cubicles",
    parent_area_id: 179797,
    parent_area_name: "1",
    root_area_id: 179796,
    root_area_name: "PDBMF",
    source_date: "2023-09-19T16:18:05.023492Z",
    media_date: "2023-09-19T16:34:56.197084Z",
    file_name: "assets/ra51729b4cb6346958baed2f9b551c072_1695140736559.jpg",
    audit_score_calculated: 4.0,
    type: "INTERNAL FLEX AUDIT",
  },
];

export function MotionVideo({ videoUrl }) {
  const [mute, setMute] = useState(true);
  const video = useRef(null);

  function muteVideo() {
    setMute(true);
    video.current.muted = true;
  }

  function videoRestart() {
    video.current.muted = true;
    setMute(true);
    video.current.play();
  }

  function playVideo() {
    video.current.play();
    video.current.currentTime = 0;
    video.current.muted = false;
    video.current.volume = 0.5;
    video.current.onEnded = () => {
      video.current.muted = true;
    };
    setMute(false);
  }

  return (
    <motion.div
      className="rounded-xl relative shadow-2xl aspect-video max-h-[66vh] print-image"
      initial={{ opacity: 0, y: 100, scale: 0.9 }}
      whileInView={{
        opacity: 1,
        y: 0,
        viewport: 0.9,
        scale: 1,
        transition: {
          type: "spring",
          bounce: 0,
          duration: 2,
        },
      }}
    >
      <video
        onEnded={videoRestart}
        onPause={muteVideo}
        ref={video}
        muted
        playsInline
        data-autoplay="true"
        controlsList="nodownload nofullscreen noremoteplayback"
        className="relative z-10 rounded-xl max-h-[66vh] no-fullscreen w-full block print-image"
        preload="auto"
        src={videoUrl}
      ></video>
      <div className="absolute z-20 bottom-0 left-0 w-full flex flex-row gap-2 p-4 items-center justify-end mix-blend-exclusion text-white">
        <span
          onClick={playVideo}
          className="material-icons cursor-pointer hover:text-blue-500"
        >
          play_circle
        </span>
        {mute ? (
          <span
            onClick={muteVideo}
            className="material-icons hover:cursor-pointer hover:text-blue-500"
          >
            volume_up
          </span>
        ) : (
          <span
            onClick={muteVideo}
            className="material-icons cursor-pointer hover:text-blue-500"
          >
            volume_off
          </span>
        )}
      </div>
    </motion.div>
  );
}

export default function Fullpage({ Children, showModal, data, theme }) {
  const [section, setSection] = useState("");
  const [clientSelection] = useState(new NewClient(data));

  const image = {
    initial: {
      y: 100,
    },
    show: {
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  function imageSelection(index) {
    let outputImages, blur;
    if (clientSelection.photos.length) {
      outputImages = clientSelection.photos
        // .sort(() => Math.random() - Math.random())
        .slice(index * 5, index * 5 + 5);
      outputImages = outputImages.concat(outputImages).flat();
      blur = false;
    } else {
      outputImages = baseImages
        .sort(() => Math.random() - Math.random())
        .slice(0, 5);
      outputImages = outputImages.concat(outputImages).flat();
      blur = true;
    }
    return outputImages.map((image, index) => {
      let date = new Date(image.media_date);
      let dateStr = date.toLocaleString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return (
        <div
          key={index}
          className={`h-[40vh] flex-shrink-0 overflow-hidden ml-6 rounded-xl relative`}
        >
          <img
            src={`https://cdn.insitenext.com/${image.file_name}`}
            className={`block h-[40vh] object-cover ${
              blur ? "blur-md" : ""
            } relative z-10`}
            style={{ minWidth: "40ch" }}
          />
          <div className="absolute bottom-0 left-0 px-4 pb-3 z-20">
            <div className="rounded-lg bg-neutral-800/80 px-2 py-1">
              <p className="text-white font-semibold text-xs">
                <FontAwesomeIcon icon={faClock} className="text-white mr-2" />
                {dateStr}
              </p>
            </div>
          </div>
        </div>
      );
    });
  }

  function refreshPage(e) {
    if (!e.shiftKey) return;
    window.location.reload();
  }

  return (
    <ReactFullpage
      //fullpage options
      licenseKey={"LCHQH-KZTCJ-3R8KK-JGQJ6-VBLTJ"}
      // licenseKey={"LCHQH-KZTCJ-3R8KK-JGQJ6-VBLTJ"}
      scrollingSpeed={700} /* Options here */
      scrollBar={true}
      responsiveWidth={1000}
      slidesNavigation={true}
      scrollOverflow={false}
      normalScrollElements={"#modal, .normal-scroll"}
      credits={{ enabled: false, label: "" }}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <video
              autoPlay
              playsInline
              muted
              loop
              src={ribbon}
              className="fixed z-0 top-0 left-0 h-full w-full max-h-none max-w-none object-cover rotate-180 dark:opacity-20 opacity-30"
            ></video>
            <div
              className="fixed z-0 top-0 left-0 h-full w-full bg-blend-multiply"
              style={{
                background:
                  theme !== "light"
                    ? `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`
                    : `url(${noise}), linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0.5), rgba(255,255,255,0.2))`,
              }}
            ></div>
            <div className="section">
              <div className="relative flex-grow h-full w-full overflow-hidden flex flex-col items-center justify-center py-40">
                <video
                  data-autoplay="true"
                  autoPlay
                  playsInline
                  muted
                  loop
                  className="absolute top-0 left-0 z-0 h-full w-full object-cover"
                  src={clientSelection.loop || loop}
                ></video>
                <h1 onClick={refreshPage} className="h1 ">
                  {clientSelection.title || "Workplace Services Transformed"}
                </h1>
                <span className="text-4xl highlight2 orange relative z-10">
                  Your facilities are evolving and so are we
                </span>
              </div>
            </div>

            {/* New Sections */}
            {clientSelection.hide("innovation") && (
              <div className="section relative">
                <div className="min-h-full flex items-center justify-center">
                  <div className="relative h-full  rounded-xl overflow-auto">
                    <div className="h-full w-full rounded-xl ">
                      <div className="relative h-full w-full grid grid-cols-4 pointer-events-auto">
                        {/* <video
                      data-autoplay="true"
                      playsInline
                      muted
                      loop
                      src={ribbon}
                      className="absolute top-0 left-0 h-full w-full max-h-none max-w-none object-cover z-0 rotate-180 opacity-20"
                    ></video>
                    <div
                      className="absolute top-0 left-0 h-full w-full z-0 bg-blend-multiply"
                      style={{
                        background: `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`,
                      }}
                    ></div> */}
                        <div className="h-full col-span-2 flex flex-col justify-center relative z-10 p-24">
                          <p className="monthly-tag text-blue-500 flex items-center">
                            Quality Control{" "}
                            <span className="ml-4 inline-block px-2 py-1 bg-blue-500/10 border-blue-500 text-sm border rounded">
                              Beta
                            </span>
                          </p>
                          <p className="monthly-title">
                            Expediting complaint management through customized
                            exception reporting
                          </p>
                        </div>

                        <div className="relative col-span-2 z-10 h-full overflow-hidden flex items-start justify-center p-24 pb-0">
                          <motion.video
                            muted
                            loop
                            data-autoplay="true"
                            playsInline
                            className="flex-shrink min-h-0 min-w-0 w-3/4"
                            style={{
                              filter:
                                "drop-shadow(-40px 30px 2rem rgba(0,0,0,0.4))",
                            }}
                            initial={{
                              opacity: 0,
                              y: 200,
                              transition: { duration: 0.75, ease: "easeOut" },
                            }}
                            whileInView={{
                              y: 0,
                              opacity: 1,
                              rotateY: "0",
                              scale: 1,
                              transition: { duration: 0.75, ease: "easeOut" },
                            }}
                            viewport={{ marigns: "200px", amount: 0.5 }}
                          >
                            <source
                              src={notificationHEIC}
                              type="video/quicktime"
                              codecs="hvc1"
                            />
                            <source src={notificationWEBM} type="video/webm" />
                          </motion.video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {clientSelection.hide("ecovadis") && (
              <div className="section overflow-hidden relative">
                <div className="min-h-full flex items-center justify-center">
                  <div className="relative h-full  rounded-xl overflow-auto">
                    <div className="h-full w-full rounded-xl overflow-hidden">
                      <div className="relative h-full w-full grid grid-cols-2  pointer-events-auto">
                        {/* <video
                      data-autoplay="true"
                      playsInline
                      muted
                      loop
                      src={ribbon}
                      className="absolute top-0 left-0 h-full w-full max-h-none max-w-none object-cover z-0 rotate-180 opacity-20"
                    ></video>
                    <div
                      className="absolute top-0 left-0 h-full w-full z-0 bg-blend-multiply"
                      style={{
                        background: `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`,
                      }}
                    ></div> */}

                        <div className="relative z-10 h-full overflow-hidden flex items-center justify-center p-24">
                          <motion.video
                            muted
                            loop
                            data-autoplay="true"
                            playsInline
                            className="flex-shrink min-h-0 min-w-0 w-full"
                            style={{
                              transformOrigin: "top",
                            }}
                            initial={{ opacity: 0, y: 200 }}
                            whileInView={{
                              y: 0,
                              opacity: 1,
                              rotateY: "0",
                              transition: { duration: 0.75, ease: "easeOut" },
                            }}
                          >
                            <source
                              src={ecovadismov}
                              type="video/quicktime"
                              codecs="hvc1"
                            />
                            <source src={ecovadisebm} type="video/webm" />
                          </motion.video>
                        </div>
                        <div className="h-full flex flex-col justify-center relative z-10 p-24">
                          <p className="monthly-tag text-blue-500 flex items-center">
                            ESG Update{" "}
                          </p>
                          <p className="monthly-title">
                            Celebrating our
                            <br />
                            forward progress
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {clientSelection.hide("newsweek") && (
              <div className="section overflow-hidden relative">
                <div className="min-h-full flex items-center justify-center">
                  <div className="relative h-full w-full rounded-xl overflow-auto">
                    <div className="h-full w-full rounded-xl overflow-hidden">
                      <div className="relative h-full w-full grid grid-cols-4  pointer-events-auto">
                        {/* <video
                      data-autoplay="true"
                      playsInline
                      muted
                      loop
                      src={ribbon}
                      className="absolute top-0 left-0 h-full w-full max-h-none max-w-none object-cover z-0 rotate-180 opacity-20"
                    ></video>
                    <div
                      className="absolute top-0 left-0 h-full w-full z-0 bg-blend-multiply"
                      style={{
                        background: `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`,
                      }}
                    ></div> */}
                        <div className="h-full col-span-2 flex flex-col justify-center relative z-10 p-24">
                          <p className="monthly-tag text-blue-500 flex items-center">
                            DEI Commitment
                          </p>
                          <p className="monthly-title">
                            The Employer of Choice
                            <br />
                            for Diverse Teams
                          </p>
                        </div>

                        <div className="relative col-span-2 z-10 h-screen overflow-visible flex items-center justify-center p-16">
                          {/* <video
                        muted
                        loop
                        data-autoplay="true"
                        playsInline
                        className="flex-shrink min-h-0 min-w-0 w-full"
                        style={{
                          transformOrigin: "top",
                        }}
                      >
                        <source
                          src={notificationHEIC}
                          type="video/quicktime"
                          codecs="hvc1"
                        />
                        <source src={notificationWEBM} type="video/webm" />
                      </video> */}
                        <motion.img src={greatestworkplaces}
                          className="w-1/2 absolute -left-24 bottom-[5%] z-90"
                        initial={{ opacity: 0, y: 200 }}
                        whileInView={{
                          y: 0,
                          opacity: 1,
                          rotateY: "0",
                          transition: { duration: 0.75, ease: "easeOut" },
                        }}
                        >

                        </motion.img>
                          <img src={eoc1} alt="" className="absolute top-24 left-4 w-1/2 rounded-xl z-20 shadow-xl shadow-black/40" />
                          <img src={eoc2} alt="" className="absolute top-[55%] left-1/3 w-1/2 rounded-xl z-30 shadow-xl shadow-black/40" />
                          <img src={eoc3} alt="" className="absolute top-[30%] left-1/2 w-[45%] rounded-xl z-10 shadow-xl shadow-black/40" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {clientSelection.show("market") && (
              <div className="section overflow-hidden relative">
                <div className="h-screen w-screen overflow-hidden flex flex-col items-center justify-center relative z-20 bg-neutral-100 dark:bg-neutral-900 p-12 py-24 gap-12 ">
                  <div className="absolute z-0 right-0 bottom-0 h-1/2 w-full bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>

                  <div className="relative z-20 flex-shrink-0">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400 text-center">
                      Market Update
                    </p>
                    <p className="h2 text-center">
                      Advanced solutions in the
                      <br />
                      evolving facilities landscape
                    </p>
                  </div>
                  <div
                    className="relative z-10 min-h-0 flex-grow-1 mx-auto w-full"
                    style={{ perspective: "100vw", height: "max-content" }}
                  >
                    <motion.video
                      alt=""
                      className="print-image rounded-xl max-h-full mx-auto h-full w-auto"
                      data-autoplay="true"
                      playsInline
                      muted
                      controls
                      loop
                      initial={{ opacity: 0, y: 200 }}
                      whileInView={{
                        y: 0,
                        opacity: 1,
                        rotateY: "0",
                        transition: { duration: 0.75, ease: "easeOut" },
                      }}
                    >
                      <source
                        src={
                          "https://player.vimeo.com/progressive_redirect/playback/873457762/rendition/1080p/file.mp4?loc=external&log_user=0&signature=8b7577c2a931f1696fdb203a7a5f4f49b2b36a980fa714977b70dc1ee1bf71b2"
                        }
                      />
                    </motion.video>
                  </div>
                </div>
              </div>
            )}
            {clientSelection.show("avetta") && (
              <div className="section overflow-hidden relative">
                <div className="h-screen w-screen overflow-hidden flex flex-row items-center relative z-20 bg-neutral-100 dark:bg-neutral-900 ">
                  <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>

                  <div className="w-1/2 relative z-20 p-12">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400">
                      Award Winning
                    </p>
                    <p className="h2 max-w-[24ch]">
                      SBM continues to be recognized for the work we do both for
                      our customers and our employees
                    </p>
                  </div>
                  <div
                    className="w-1/2 h-full relative z-10 flex items-center justify-center"
                    style={{ perspective: "100vw" }}
                  >
                    <motion.video
                      alt=""
                      className=" print-image h-full w-full object-cover"
                      data-autoplay="true"
                      playsInline
                      muted
                      loop
                      initial={{ opacity: 0, y: 200 }}
                      whileInView={{
                        y: 0,
                        opacity: 0.8,
                        rotateY: "0",
                        transition: { duration: 0.75, ease: "easeOut" },
                      }}
                    >
                      <source src={avetta2} type='video/mp4; codecs="hvc1"' />
                      <source src={awards} type="video/webm" />
                    </motion.video>
                  </div>
                </div>
              </div>
            )}
            {/* {clientSelection.hide('photogallery') && clientSelection.photos.length > 0 && <div className="section overflow-hidden relative">
              <div className="h-screen w-screen overflow-hidden flex flex-row items-center relative z-20 bg-neutral-100 dark:bg-neutral-900 ">
              <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>

                <div className="w-1/2 relative z-20 p-12">
                  <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400">
                    New 4insite Feature
                  </p>
                  <p className="h2 max-w-[24ch]">
                    An interactive visual experience that lets you see what we see
                  </p>
                </div>
                <div className="w-1/2 h-full relative z-10 flex flex-col items-start justify-center gap-6 overflow-hidden" >
                  <div className="absolute z-90 left-0 w-1/4 h-full bg-gradient-to-r from-neutral-100 dark:from-neutral-900 to-transparent"></div>
                  <div className="marquee-row inline-flex flex-row">
                    {imageSelection(0)}
                  </div>
                  <div className="marquee-row-2 inline-flex flex-row self-end">
                    {imageSelection(1)}
                  </div>
                </div>
              </div>
            </div>} */}
            {/* End New Sections */}

            {/* Old Sections */}
            {clientSelection.show("partners") && (
              <div className="section overflow-hidden relative">
                <div className="h-screen w-screen overflow-hidden flex flex-col items-center gap-6 relative z-20 bg-gradient-to-b from-neutral-100/0 via-neutral-100/0 to-neutral-100/80 dark:from-black/0 dark:via-black/0 dark:to-black/40 ">
                  <div className="w-3/4 relative z-20 p-12">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400 text-center">
                      Leadership Update
                    </p>
                    <p className="h2 text-center max-w-[24ch] mx-auto">
                      SBM is proud to announce the addition of 3
                      new&nbsp;partners
                    </p>
                  </div>
                  <motion.img
                    src={partners}
                    alt=""
                    className="w-full h-full object-top object-cover origin-bottom print-image"
                    initial={{ opacity: 0, y: 0 }}
                    whileInView={{
                      y: 0,
                      opacity: 1,
                      transition: { duration: 0.75, ease: "easeOut" },
                    }}
                  />
                </div>
              </div>
            )}
            {clientSelection.show("verticals") && (
              <div className="section relative">
                <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>
                <div className="relative h-full w-full grid grid-cols-2 2xl:grid-cols-3 grid-rows-1 gap-6 max-w-screen-4xl mx-auto py-4 px-12">
                  <div className="flex flex-col items-start justify-center">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400">
                      Aligning to Serve You Better
                    </p>
                    <p className="h2">Our New Vertical Structure</p>
                  </div>
                  <div className="2xl:col-span-2 flex items-center justify-center">
                    <MotionVideo videoUrl={vertical} />
                  </div>
                </div>
              </div>
            )}
            {clientSelection.show("training") && (
              <div className="section relative">
                <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>
                <div className="relative h-full w-full grid grid-cols-2 2xl:grid-cols-3 grid-rows-1 gap-6 max-w-screen-4xl mx-auto py-4 px-12  overflow-hidden">
                  <div className="h-full flex flex-col items-start justify-center">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400">
                      World-Class Training Content
                    </p>
                    <h1 className="h2">
                      New trainings specific to your industry
                    </h1>
                  </div>
                  <motion.div
                    variants={container}
                    initial={"initial"}
                    whileInView={"show"}
                    className="h-full w-full relative 2xl:col-span-2"
                  >
                    <motion.img
                      variants={image}
                      src="https://cdn.insitenext.com/assets/b48dae96705144beb0a2f262ce18431d_AerialLiftforManuthumbnail.png"
                      alt=""
                      className="h-1/3 rounded-xl aspect-video object-cover absolute z-20 top-[5%] shadow-lg dark:shadow-neutral-900 shadow-neutral-600/60 print-image"
                    />
                    <motion.img
                      variants={image}
                      src="https://cdn.insitenext.com/assets/5024367828634d3e82b6e73987689272_JetBridgeCleaning71.png"
                      alt=""
                      className="h-1/3 rounded-xl aspect-video object-cover absolute z-30 top-[20%] left-[35%] shadow-lg dark:shadow-neutral-900 shadow-neutral-600/60 print-image"
                    />
                    <motion.img
                      variants={image}
                      onClick={() =>
                        showModal(
                          null,
                          "https://cdn.insitenext.com/assets/4e73eba0925446b6a469984a73b4405c_situational_awareness_for_automotive_manufacturing720p_31.mp4"
                        )
                      }
                      src="https://cdn.insitenext.com/assets/b5c8c918df1d4872aa0fc498143ebed9_SituationalAwareness.png"
                      alt=""
                      className="h-1/3 rounded-xl aspect-video object-cover z-30 absolute top-[45%] left-[5%]  shadow-lg dark:shadow-neutral-900 shadow-neutral-600/60 print-image"
                    />
                    <motion.img
                      variants={image}
                      src="https://cdn.insitenext.com/assets/d7f3cee5448b497bbc523062fce07c2d_COLOCleaningThumbnail.png"
                      alt=""
                      className="h-1/3 rounded-xl aspect-video object-cover absolute z-40 top-[60%] left-[40%] shadow-lg dark:shadow-neutral-900 shadow-neutral-600/60 print-image"
                    />
                  </motion.div>
                </div>
              </div>
            )}
            {clientSelection.show("esg") && (
              <div className="section relative">
                <div className="absolute z-0 right-0 bottom-0 h-1/3 w-full bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>
                <div className="relative h-screen overflow-hidden w-full flex flex-col items-start justify-center gap-4 2xl:gap-6 max-w-screen-4xl mx-auto py-4 px-12">
                  <div className="flex flex-col items-start justify-center">
                    <p className=" text-3xl mb-2 text-orange-500 dark:text-blue-400">
                      Environmental, Social, Governance Updates
                    </p>
                    <h1 className="h2 2xl:max-w-screen-lg">
                      SBM earns EcoVadis Bronze for outstanding ESG practices
                    </h1>
                    <p className="2xl:text-xl my-4 text-neutral-900 dark:text-neutral-100 max-w-screen-lg">
                      The third-party assessment includes evaluation of SBM’s
                      policies, procedures, and performance across several
                      areas, including HR, Safety, Sustainability, Ethics,
                      Labor, and Training, among others.
                    </p>
                  </div>
                  <motion.div
                    variants={container}
                    initial={"initial"}
                    whileInView={"show"}
                    className="grid grid-cols-4 grid-rows-1 gap-6"
                  >
                    <motion.div
                      variants={image}
                      className="flex items-center justify-center bg-white dark:bg-neutral-700 shadow-xl p-6 2xl:p-12 rounded-xl"
                    >
                      <div
                        className="rounded-xl relative w-full"
                        initial={{ opacity: 0, y: 100, scale: 0.9 }}
                      >
                        <img
                          className="block max-w-xl mx-auto w-full relative z-20 adrop-shadow-[0_15px_10px_rgba(0,0,0,0.5)] print-image"
                          src={ecovadis}
                          alt=""
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      variants={image}
                      className="flex items-center justify-center bg-white dark:bg-neutral-700 shadow-xl p-6 2xl:p-12 rounded-xl"
                    >
                      <Chart
                        title={"SBM Diversity Overview"}
                        data={[
                          { name: "Non-Minority", y: 21 },
                          { name: "Minority", y: 79 },
                        ]}
                      />
                    </motion.div>
                    <motion.div
                      variants={image}
                      className="flex items-center justify-center bg-white dark:bg-neutral-700 shadow-xl p-6 2xl:p-12 rounded-xl"
                    >
                      <Chart
                        title={"SBM Executive Leadership"}
                        data={[
                          { name: "Non-Minority", y: 32 },
                          { name: "Minority", y: 68 },
                        ]}
                      />
                    </motion.div>
                    <motion.div
                      variants={image}
                      className="flex items-center justify-center bg-white dark:bg-neutral-700 shadow-xl p-6 2xl:p-12 rounded-xl"
                    >
                      <Chart
                        title={"SBM New Hires by Gender"}
                        data={[
                          { name: "Male", y: 49 },
                          { name: "Female", y: 51 },
                        ]}
                      />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            )}
            {clientSelection.show("sow") && (
              <div className="section relative">
                <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>
                <div className="relative h-full w-full grid grid-cols-2 2xl:grid-cols-3 grid-rows-1 gap-6 max-w-screen-4xl mx-auto py-4 px-12">
                  <div className="flex flex-col items-start justify-center">
                    <p className="text-3xl mb-2 text-orange-500 dark:text-blue-400">
                      Scope of Work Manager
                    </p>
                    <p className="h2 ">Measure, Manage, & Improve</p>
                  </div>
                  <div className="flex 2xl:col-span-2 items-center justify-center">
                    <MotionVideo videoUrl={sow} />
                  </div>
                </div>
              </div>
            )}
            {/* End Old Sections */}

            {clientSelection.events.map((event, index) => {
              return (
                // <section className="section" key={index}>
                <Section showModal={showModal} slide={event} key={index} />
                // </section>
              );
            })}
              {/* <Section showModal={showModal} slide={{type: 'amr-equipment'}} /> */}

            {/* {clientSelection.events.length > 0 && (
            <div className="section slideshow-section">
              <div className="relative h-screen w-full flex items-center justify-center">
                <div className="absolute z-0 right-0 top-0 h-full w-1/2 bg-gradient-to-b from-orange-500/20 to-orange-500/10 dark:from-blue-400/20 dark:to-blue-400/10"></div>
                <p className="absolute z-10 uppercase video-left text-neutral-800 dark:text-neutral-200 font-extrabold text-2xl lg:text-5xl 2xl:text-7xl leading-[1.1] print-hide-title">{section}</p>
                <p className="absolute z-40 uppercase video-left text-neutral-800 dark:text-neutral-200 font-extrabold text-2xl lg:text-5xl 2xl:text-7xl leading-[1.1] text-stroke print-hide-title">{section}</p>
                <div className="z-20 h-screen w-full relative flex items-center justify-center p-12 slideshow-container">
                    <div className="aspect-video max-h-full rounded-lg overflow-hidden shadow-xl select-none ">
                      <Slideshow setSection={setSection} showModal={showModal} currentSection={section} slides={clientSelection.events}/>
                    </div>
                </div>
              </div>
            </div>
            )} */}
            {Children}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}
