export const cbreSelectedClients = [
    {
        "id": 75,
        "name": "AbbVie",
        "type": "CUSTOMER",
        "correlation_id": "10135",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "267a5974-551c-4f3c-8bb1-fc8cb6e6437e",
        "logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM"
    },
    {
        "id": 280,
        "name": "Adient",
        "type": "CUSTOMER",
        "correlation_id": "11602",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "d4affd5e-1b58-47fa-9888-cc35a7481a7c",
        "logo_url": "assets/1605624157.877317_adientvectorlogosmall_t.png"
    },
    {
        "id": 281,
        "name": "ASML (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11671",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5d48c550-59a7-4017-8897-0ce78fc6794d",
        "logo_url": "assets/1607359606.6159544_ASML_t.png"
    },
    {
        "id": 253,
        "name": "Biogen (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11428",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "55abaac4-7852-4686-be88-409bed152f1d",
        "logo_url": "assets/1605623761.388814_Biogen01_t.png"
    },
    {
        "id": 269,
        "name": "Chemours (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100008",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "930d637c-d6ff-4734-a6e0-1f1e78b8876e",
        "logo_url": "assets/1607369039.649657_Chemours_t.png"
    },
    {
        "id": 1018,
        "name": "Chubb Insurance (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [
            {
                "id": 1442,
                "street1": "202 Halls Mill Rd A",
                "street2": "",
                "city": "Whitehouse Station",
                "region": "New Jersey",
                "postal_code": "08889",
                "lat": 40.6459052,
                "lon": -74.7647032,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "b4cb1896-744c-4a3a-a07f-1d75aad07033",
        "logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png"
    },
    {
        "id": 219,
        "name": "Cigna (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11525",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "2a63cb8e-3a0d-423f-8ba8-a1a3859ebc42",
        "logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png"
    },
    {
        "id": 2154,
        "name": "Cigna (CBRE) - Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "48b3374a-0943-46e0-9709-5907432f40ab",
        "logo_url": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png"
    },
    {
        "id": 90,
        "name": "Clorox (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10394",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f62cf9fa-612e-44fa-9813-ce2607cc371f",
        "logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg"
    },
    {
        "id": 2145,
        "name": "Coca Cola",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6f2eeac2-a3ef-4201-9814-c8b2ebc6d667",
        "logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg"
    },
    {
        "id": 1547,
        "name": "Collins Aerospace (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "af546d13-be70-42f2-ac60-720c0ac733cd",
        "logo_url": "assets/6617f7d3448b41d9a49d9f54dc0abe45_Screenshot20240216at07.23_t.42"
    },
    {
        "id": 352,
        "name": "Covestro (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [
            {
                "id": 100,
                "street1": "1 Covestro Cirlce",
                "street2": "",
                "city": "Pittsurgh",
                "region": "Pennsylvania",
                "postal_code": "15205",
                "lat": 40.443517,
                "lon": -80.163434,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "932bcd1f-c316-4ddb-88c5-6b98c3c13292",
        "logo_url": "assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg"
    },
    {
        "id": 468,
        "name": "Deutsche Bank",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "70c3c45a-b6f5-4100-8cda-01f5d085c42a",
        "logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png"
    },
    {
        "id": 273,
        "name": "Elanco (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11589",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "315c1b3f-c033-442b-b3e7-f9650c5ad39b",
        "logo_url": "assets/1607378591.6016922_Elanco_t.png"
    },
    {
        "id": 72,
        "name": "Eli Lilly (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10042",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "b700fe06-1095-467a-81a1-e2e363b7a17a",
        "logo_url": "assets/1605311746.7267878_Artboard8_t.png"
    },
    {
        "id": 315,
        "name": "Ford",
        "type": "CUSTOMER",
        "correlation_id": "315",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "6294e6b9-7c45-4d57-938e-092e8ded75dd",
        "logo_url": "assets/1605311926.006089_Artboard11_t.png"
    },
    {
        "id": 137,
        "name": "GM",
        "type": "CUSTOMER",
        "correlation_id": "10828",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "f6e481f5-4d2d-467f-af66-aa4c3d326e9f",
        "logo_url": "assets/1605312005.83312_GM_logo_t.jpg"
    },
    {
        "id": 33,
        "name": "GSK (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "10264",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "4218fd8e-cac0-4db7-a800-73823d3df849",
        "logo_url": "assets/1607961353.7332714_GSK_t.jpeg"
    },
    {
        "id": 470,
        "name": "IQVIA (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [
            {
                "id": 573,
                "street1": "2400 Ellis road",
                "street2": "",
                "city": "Durham",
                "region": "North Carolina",
                "postal_code": "27703",
                "lat": 35.9353484,
                "lon": -78.8545872,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "d7f7ea55-d5e5-4f58-9ffe-85e17bde2246",
        "logo_url": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png"
    },
    {
        "id": 63,
        "name": "Juniper",
        "type": "CUSTOMER",
        "correlation_id": "10989",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "9ba78891-40df-480f-bce1-a24565a99a30",
        "logo_url": "assets/1605624533.566394_JN_t.png"
    },
    {
        "id": 2240,
        "name": "Lam Research (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1e7b2470-0302-45b1-b34a-667694a5b5eb",
        "logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png"
    },
    {
        "id": 220,
        "name": "McKesson (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11558",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "c7e2b2ef-b52c-4c16-b90f-d89fcbf48c35",
        "logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg"
    },
    {
        "id": 267,
        "name": "Merck (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "100011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "0e8d11d0-dc26-4c6e-9796-4b5553bb904a",
        "logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png"
    },
    {
        "id": 1422,
        "name": "Meta (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "a293e608-3e9b-4636-9409-f17eea4a9b92",
        "logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png"
    },
    {
        "id": 8,
        "name": "Microsoft (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11269",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "26725540-bdc5-48a6-83fe-c0f2796d7df1",
        "logo_url": "assets/1605312306.0531929_Artboard4_t.png"
    },
    {
        "id": 5,
        "name": "Nvidia (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11034",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "1c39849c-f884-456b-9642-ee55b07a76b6",
        "logo_url": "assets/1605311860.3280911_Artboard10_t.png"
    },
    {
        "id": 477,
        "name": "Organon (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "08a94e30-365e-43b4-b5b2-acb91ade287c",
        "logo_url": "assets/bd52cc204125445db6a196cad602f4ca_Organon_t.png"
    },
    {
        "id": 438,
        "name": "Pratt & Whitney (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "101371",
        "addresses": [
            {
                "id": 273,
                "street1": "17900 Bee Line HWY",
                "street2": "",
                "city": "Jupitor",
                "region": "Florida",
                "postal_code": "33478",
                "lat": 26.9047849,
                "lon": -80.3040239,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "250a2c64-7e26-4b6d-8f36-167c56bcee3d",
        "logo_url": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg"
    },
    {
        "id": 311,
        "name": "Siemens (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "7e18d041-a341-42b2-a49a-9c10ba6df5aa",
        "logo_url": "assets/1607637318.9761856_Siemens_t.png"
    },
    {
        "id": 504,
        "name": "Sikorsky (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [
            {
                "id": 813,
                "street1": "17900 Bee Line Hwy",
                "street2": "",
                "city": "Jupiter",
                "region": "Florida",
                "postal_code": "33478",
                "lat": 26.9044924,
                "lon": -80.303552,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "0b2421c4-c0b9-46f1-a856-48420d7102b1",
        "logo_url": "assets/ebf7179e73d34ec99956aa4f54bd6943_Image_Editor5_t.png"
    },
    {
        "id": 485,
        "name": "State Farm (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [
            {
                "id": 732,
                "street1": "8225 Bent Branch Road",
                "street2": "",
                "city": "Irving",
                "region": "Texas",
                "postal_code": "75063",
                "lat": 32.9185433,
                "lon": -96.9908672,
                "country": "United States"
            }
        ],
        "status": "Unsuspended",
        "uuid": "4db37d49-2a09-458d-b3c1-ca6fa693fd08",
        "logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png"
    },
    {
        "id": 180,
        "name": "Takeda (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": "11217",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8175e5ad-91ed-4975-ba1f-e13a5f4f2fc9",
        "logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg"
    },
    {
        "id": 921,
        "name": "Tenaya(CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "24364b66-c922-4036-bafc-f9b54e54a934",
        "logo_url": "assets/a68fea5f3e804ce2b1571142f2a6fa11_tenaya_therapeutics_logo_t.jpeg"
    },
    {
        "id": 1648,
        "name": "TGS",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cecd50a4-ad75-4178-b1af-47fc306e65bc",
        "logo_url": "assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg"
    },
    {
        "id": 303,
        "name": "Uber (CBRE)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "02169ab0-9d82-439b-bcb9-f0d94d0e3629",
        "logo_url": "assets/1607636171.7063708_Uber_t.jpg"
    },
    {
        "id": 803,
        "name": "Vantiva (CBRE)",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "72641938-f560-4c4f-aebc-c1fbab13ecc8",
        "logo_url": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png"
    },
    {
        "id": 2155,
        "name": "VMware (CBRE) (SG)",
        "type": "CUSTOMER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "5e9eef4f-7392-4edc-b76b-ee3f9375d752",
        "logo_url": 0
    },
    {
        "id": 87,
        "name": "Wells Fargo",
        "type": "CUSTOMER",
        "correlation_id": "10011",
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "cf289e75-29f9-4c9e-8a3c-0967e7989c42",
        "logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png"
    },
    {
        "id": 1649,
        "name": "Wells Fargo-Sub",
        "type": "SERVICE_PROVIDER",
        "correlation_id": 0,
        "addresses": [],
        "status": "Unsuspended",
        "uuid": "8f66b031-b584-4d7a-a55d-6cc448b1ddd5",
        "logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png"
    }
]

export const cbreSelectedSites = [
  {
      "id": 5849,
      "name": "10 Exchange Place, NJ",
      "customer_name": "Chubb Insurance (CBRE)",
      "customer": 1018,
      "parent_id": 0,
      "correlation_id": "102763",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6747,
      "name": "1633 Fairlane Circle",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103414",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5681,
      "name": "2047 Lakewood Blvd*",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": 0,
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "eda9d9ff-83be-4934-a5ea-468c801ea128",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5511,
      "name": "3rd - Brannan",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102325",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "a8f911c1-11e3-4fa0-8382-2f0a79f6457b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6742,
      "name": "800 Republic",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103422",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5851,
      "name": "AEC – Advanced Electrification Center – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102804",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2623,
      "name": "Albany, OH",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101564",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/North_Dakota/Center",
      "uuid": "cb4abfa7-fddc-4aac-872c-7bd811c6fc36",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2632,
      "name": "Alpharetta, GA",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101577",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2252,
      "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 5847,
      "correlation_id": "101246",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5775,
      "name": "AOC",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102624",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5687,
      "name": "Apache Junction",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102622",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "491e8f78-3149-4575-b854-eb644c6daa09",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1800,
      "name": "Arrowhead",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102701",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "309d2a98-4019-4aec-b07a-27da30b0af9f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 4836,
      "name": "Asheville, NC*",
      "customer_name": "Pratt & Whitney (CBRE)",
      "customer": 438,
      "parent_id": 0,
      "correlation_id": "102208",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3839,
      "name": "Baltimore, MD*",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "101882",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/St_Vincent",
      "uuid": "dc8e03a6-5376-40d6-b762-5e21b492d5cf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3999,
      "name": "Bannockburn",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "102072",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Kentucky/Louisville",
      "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3996,
      "name": "Bayfront",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102100",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2640,
      "name": "Bethlehem",
      "customer_name": "IQVIA (CBRE)",
      "customer": 470,
      "parent_id": 0,
      "correlation_id": "101568",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "7dd14439-d031-4edb-80a1-d29ebbbae2e6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4231,
      "name": "Blings Ops*",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "101962",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Regina",
      "uuid": "8a48eee0-2e12-4296-bd2c-fb7877f8da28",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1962,
      "name": "Bloomfield",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "11570",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "674967cb-d560-4440-809d-d682f82678b8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2264,
      "name": "Bothell, WA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101294",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "908fc000-28d0-439e-9912-ff88967db144",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1798,
      "name": "Branchburg",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "11523",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3992,
      "name": "Burlingame",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102107",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1955,
      "name": "Cambridge",
      "customer_name": "Biogen (CBRE)",
      "customer": 253,
      "parent_id": 0,
      "correlation_id": "11429",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5828,
      "name": "Cambridge Central*",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "102748",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "447bf290-3990-4d32-b24d-f7addb23fd34",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3299,
      "name": "Cambridge Kendall SQ",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "102095",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1327,
      "name": "Campus Chandler -AZ",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "10336",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Denver",
      "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2087,
      "name": "Campus - MN",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "11692",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Menominee",
      "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5858,
      "name": "CBB – Crash Barrier – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102792",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5544,
      "name": "Cedar Rapids Downtown",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102358",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "b81ab5f3-6dcd-4e50-969b-56157fdb7d82",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5497,
      "name": "Central",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102405",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1647,
      "name": "Central SQ",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "11218",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1809,
      "name": "Chandler",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102702",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Boise",
      "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1486,
      "name": "Cherokee, PA",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "10071",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5977,
      "name": "CHICAGO 20*",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102872",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "3fa7ed8b-d7e3-4f0b-900e-3db28c9a580f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5978,
      "name": "CHICAGO 22*",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102873",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "e88bcf7b-e607-4a1c-a6f8-fb435f30a6df",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2267,
      "name": "Chicago, IL",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101289",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3994,
      "name": "Chilco",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102102",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5790,
      "name": "CIC",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102718",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "0646f543-0824-4de7-89a6-2af2c217f0b4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1823,
      "name": "Cincinnati",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "11560",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4225,
      "name": "City of Industry",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102094",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "48e90c2c-d6f9-4a38-bde4-a7d63f3c4b35",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1808,
      "name": "CJ Harris",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102703",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3851,
      "name": "Classic",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102106",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2256,
      "name": "Clermont, KY",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101254",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Louisville",
      "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2016,
      "name": "Clinton",
      "customer_name": "Elanco (CBRE)",
      "customer": 273,
      "parent_id": 0,
      "correlation_id": "11591",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5776,
      "name": "Columbia, MO*",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "102623",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "78ab5a19-75d1-4f21-a6bb-966bed3a0b2d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6751,
      "name": "Commerce Park 2 15080",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103420",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6740,
      "name": "Commerce Park 2 15090",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103412",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5575,
      "name": "Concord, NC",
      "customer_name": "Eli Lilly (CBRE)",
      "customer": 72,
      "parent_id": 0,
      "correlation_id": "102414",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2633,
      "name": "Concordville, PA",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101579",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6076,
      "name": "Cover My Meds 2",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "103005",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2471,
      "name": "Cover My Meds Columbus",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101428",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5859,
      "name": "DDE – Driving Dynamics East – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102790",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5860,
      "name": "DDW – Driving Dynamics West – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102791",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1674,
      "name": "Denver",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "11281",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Denver",
      "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5831,
      "name": "Denver Service Center",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102586",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Denver",
      "uuid": "aa9ac4b5-0c72-4ca1-8e5a-aac497872ead",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1325,
      "name": "Des Moines",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "10334",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2120,
      "name": "Des Moines, IA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "11728",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2220,
      "name": "DSC 1 & 2",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103417",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 5979,
      "name": "DSM05*",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102874",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "aec9ad2f-3351-4612-a2d3-584bd2976987",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2222,
      "name": "DTF – Drivability Test Facility – Allen Park, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 5847,
      "correlation_id": "101285",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2651,
      "name": "Dulles, VA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101538",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5744,
      "name": "Duluth PCS (CBRE)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "102587",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "a4ae81ac-bbb8-454a-8aee-bf2d22b3f093",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2576,
      "name": "Durham",
      "customer_name": "IQVIA (CBRE)",
      "customer": 470,
      "parent_id": 0,
      "correlation_id": "101454",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3687,
      "name": "Durham, NC",
      "customer_name": "Eli Lilly (CBRE)",
      "customer": 72,
      "parent_id": 0,
      "correlation_id": "102059",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1487,
      "name": "Durham, NC",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "10072",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5862,
      "name": "DYNO – Dynamometer – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102802",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2088,
      "name": "East Town - MN",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "11694",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2608,
      "name": "Ellabell, GA",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101549",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "4bd33d9e-e410-4fde-8de1-492858a2075d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5861,
      "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102805",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5863,
      "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102810",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "21922489-dd81-4295-be37-291ba4839fae",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4124,
      "name": "Exton",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "102089",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "f7086bec-946e-4b69-9337-bc15669afd06",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4819,
      "name": "Fairdale",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102156",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "e5fce2e5-69cc-4a8f-b03d-6dd1b0794590",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6746,
      "name": "Fairlane Business Park 3",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103413",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2221,
      "name": "Fairlane Business Park 5",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103409",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 2217,
      "name": "Fairlane North",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103411",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 6744,
      "name": "Fairlane Plaza",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103418",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5864,
      "name": "FEL – Ford Engineering Lab – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102785",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6698,
      "name": "Fluor - Lebanon*",
      "customer_name": "Eli Lilly (CBRE)",
      "customer": 72,
      "parent_id": 0,
      "correlation_id": "103374",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Indiana/Petersburg",
      "uuid": "6c63ba96-6852-4283-80f0-1a75f0cfd8f7",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5973,
      "name": "Fremont",
      "customer_name": "Lam Research (CBRE)",
      "customer": 2240,
      "parent_id": 0,
      "correlation_id": "102868",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5794,
      "name": "Fulton County Airport Hangar",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102721",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "8baabbd9-e6e5-413f-abbb-be0d5d268562",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2215,
      "name": "FXC – Ford Experience Center - Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 5847,
      "correlation_id": "101286",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
      "chatbot_sms_phone_number": "1",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2611,
      "name": "Gahanna",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102231",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "430bc9e7-1d5f-44b5-9ad7-9b7d10a354f9",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2467,
      "name": "Gallatin, TN",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "101427",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Louisville",
      "uuid": "ae7d3ed1-544e-4b11-b40f-ba2a6e458a13",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3997,
      "name": "Gateway",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102104",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "517ee32c-80d0-4e44-8ce1-f0e0432c248e",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2017,
      "name": "Greenfield, IN",
      "customer_name": "Elanco (CBRE)",
      "customer": 273,
      "parent_id": 0,
      "correlation_id": "11590",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Indiana/Indianapolis",
      "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2616,
      "name": "Groveport",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102232",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "3bb6dd38-6fa1-4f73-8565-8051e79ab1be",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5865,
      "name": "GTL – Gas Turbine Labs – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102799",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2163,
      "name": "Harleysville, PA",
      "customer_name": "Siemens (CBRE)",
      "customer": 311,
      "parent_id": 0,
      "correlation_id": "101244",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "9e1ba37b-3af4-446a-9c9b-ac7e4baa4825",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5976,
      "name": "Hillsboro",
      "customer_name": "Lam Research (CBRE)",
      "customer": 2240,
      "parent_id": 0,
      "correlation_id": "102871",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5981,
      "name": "IAD11*",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102876",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "07572032-5a22-4b19-970f-4adad56c25e0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1324,
      "name": "Illinois",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "10333",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Indiana/Indianapolis",
      "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1469,
      "name": "Indianapolis, IN",
      "customer_name": "Eli Lilly (CBRE)",
      "customer": 72,
      "parent_id": 2655,
      "correlation_id": "10045",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1880,
      "name": "Indy",
      "customer_name": "Elanco (CBRE)",
      "customer": 273,
      "parent_id": 0,
      "correlation_id": "101071",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Indianapolis",
      "uuid": "f6d700cf-a4c8-44d7-8d83-1ce085a21b8d",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2566,
      "name": "Interchange",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101510",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Kentucky/Louisville",
      "uuid": "87cfe09e-ff48-43e9-98b4-c52f9fb9ffbf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5565,
      "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102400",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
      "chatbot_sms_phone_number": "7347896550",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4227,
      "name": "Irvine",
      "customer_name": "TGS",
      "customer": 1648,
      "parent_id": 0,
      "correlation_id": "101853",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2618,
      "name": "Irving, TX",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101556",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/North_Dakota/Center",
      "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2069,
      "name": "Irving, TX",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "11596",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6750,
      "name": "IT Bic",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103421",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6599,
      "name": "ITHQ-C",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103370",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1968,
      "name": "Jacksonville",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "11582",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2664,
      "name": "Jacksonville FL",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101553",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2635,
      "name": "Jollyville (Austin)",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101581",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Fort_Wayne",
      "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2445,
      "name": "Jupiter, FL",
      "customer_name": "Pratt & Whitney (CBRE)",
      "customer": 438,
      "parent_id": 0,
      "correlation_id": "101371",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Louisville",
      "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2634,
      "name": "Kalamazoo, MI",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101578",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "791209fc-57ee-4271-bd07-90aa1e8978d8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2590,
      "name": "Laguna South, WA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101532",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "abb421e8-0707-42f0-b247-657048924721",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3432,
      "name": "LaVergne",
      "customer_name": "Vantiva (CBRE)",
      "customer": 803,
      "parent_id": 0,
      "correlation_id": "102064",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "0782cfc4-1138-4ee2-9a49-a1adfc897e25",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5837,
      "name": "LEP - Lima Engine Plant - Lima, OH",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102753",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5879,
      "name": "Lexington Columbia Construction*",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "102767",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b85cdcfb-d2fc-48d2-87c2-361b88590586",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3398,
      "name": "Lexington, MA",
      "customer_name": "Takeda (CBRE)",
      "customer": 180,
      "parent_id": 0,
      "correlation_id": "101955",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2643,
      "name": "Lincoln South",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101590",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2148,
      "name": "Lincoln Square 2",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101307",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "0158ddd5-982a-4899-adcf-d8bf368c44ee",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5974,
      "name": "Livermore",
      "customer_name": "Lam Research (CBRE)",
      "customer": 2240,
      "parent_id": 0,
      "correlation_id": "102869",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5534,
      "name": "Lodi Vineyard (JLL)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "102352",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "4ef63fbd-7124-4bfb-81e5-0eb8c16bcabb",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5656,
      "name": "Los Angeles Cash Vault (JLL)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "102407",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "10297d83-39ff-44d2-8463-246944bce406",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6684,
      "name": "LTP – Livonia Transmission Plant – Wayne, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "103373",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "da632665-87df-4e1a-8ccf-6819d4038f95",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1956,
      "name": "Madison, NJ",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "11432",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2624,
      "name": "Malta, NY",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101563",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "eb1902d2-9a86-4850-bb32-1404cefdba7d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2162,
      "name": "Manteno, IL",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101248",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2444,
      "name": "Market Street",
      "customer_name": "Uber (CBRE)",
      "customer": 303,
      "parent_id": 0,
      "correlation_id": "101368",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "476b5b30-02ec-41da-b74a-86b56fbe7ab4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2586,
      "name": "Mason",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101518",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Kentucky/Louisville",
      "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5495,
      "name": "McKnight Road",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102320",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3144,
      "name": "Memphis",
      "customer_name": "Vantiva (CBRE)",
      "customer": 803,
      "parent_id": 0,
      "correlation_id": "102013",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "8a33df06-5844-4653-bd10-077459324b24",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1974,
      "name": "Michigan (CBRE)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "11579",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "20a980a5-f48d-444c-b160-beb18ee9afc3",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 1,
      "name": "Milford PG, MI",
      "customer_name": "GM",
      "customer": 137,
      "parent_id": 0,
      "correlation_id": "10872",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2141,
      "name": "Mission Bay",
      "customer_name": "Uber (CBRE)",
      "customer": 303,
      "parent_id": 0,
      "correlation_id": "101234",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5745,
      "name": "Moberly (CBRE)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "102588",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "c69889fb-e12c-4c02-9f86-f6491aa86793",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5866,
      "name": "MOB – Maintenance & Operations – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102800",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4324,
      "name": "MOON TWP PA",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102077",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "e02e2b9e-07c4-40f5-a6c6-ef2a900d31f7",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2656,
      "name": "Murfreesboro",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101555",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Louisville",
      "uuid": "69c31c57-abb7-405d-9a8c-60e05813605b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1997,
      "name": "Newark, DE",
      "customer_name": "Chemours (CBRE)",
      "customer": 269,
      "parent_id": 0,
      "correlation_id": "11634",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5714,
      "name": "New York",
      "customer_name": "Deutsche Bank",
      "customer": 468,
      "parent_id": 0,
      "correlation_id": "102580",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1815,
      "name": "Norte",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102706",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "92d39c75-3b17-4076-b411-4f84bd97f2a8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 4447,
      "name": "North Dakota (CBRE)",
      "customer_name": "Wells Fargo-Sub",
      "customer": 1649,
      "parent_id": 0,
      "correlation_id": "101963",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chihuahua",
      "uuid": "b2771291-3b98-47c4-bb33-3844fb643bd2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1802,
      "name": "North Valley",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102704",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "1140ded6-7083-44f9-86e0-5374fc09baa4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1491,
      "name": "North Wales, PA",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "10077",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "a153d850-6493-4210-a779-9ec041201f67",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5793,
      "name": "Northyard",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102717",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1768,
      "name": "Oak Hill, NY",
      "customer_name": "GSK (CBRE)",
      "customer": 33,
      "parent_id": 0,
      "correlation_id": "11475",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2439,
      "name": "Oakland, CA",
      "customer_name": "Clorox (CBRE)",
      "customer": 90,
      "parent_id": 0,
      "correlation_id": "101366",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5852,
      "name": "OB1 – Office Building 1 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102787",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5853,
      "name": "OB2 – Office Building 2 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102786",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5854,
      "name": "OB3 – Office Building 3 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102808",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5855,
      "name": "OB4 – Office Building 4 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102811",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5856,
      "name": "OB5 – Office Building 5 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102788",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5857,
      "name": "OB6 – Office Building 6 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102812",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5498,
      "name": "Old Saint Anthony/OSA*",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102322",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1803,
      "name": "Palm Valley",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102705",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "60b3860e-c67b-449f-be64-9bbcad37cebd",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 5515,
      "name": "Palo Alto",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102324",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1807,
      "name": "Paradise Valley",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102629",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Dawson",
      "uuid": "ec867db4-3974-42ce-bc29-5d61f69ae6a8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1811,
      "name": "Paseo",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102630",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "685ec8d0-0246-4b4c-baa1-6c43c9d2acbf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5870,
      "name": "PDC – Product Development Center - Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102784",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5792,
      "name": "PETC",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102719",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "29dba90e-76e1-4a8f-93be-eeefa24cff6b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5869,
      "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102803",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5809,
      "name": "Pharma Facility",
      "customer_name": "Biogen (CBRE)",
      "customer": 253,
      "parent_id": 0,
      "correlation_id": "102736",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5537,
      "name": "Philadelphia, PA",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102347",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1810,
      "name": "Phoenix Central",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102631",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2333,
      "name": "Pittsburg",
      "customer_name": "Covestro (CBRE)",
      "customer": 352,
      "parent_id": 0,
      "correlation_id": "101846",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1766,
      "name": "Pleasanton",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "11471",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1356,
      "name": "Pleasanton",
      "customer_name": "Clorox (CBRE)",
      "customer": 90,
      "parent_id": 0,
      "correlation_id": "10395",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2071,
      "name": "Plymouth",
      "customer_name": "Adient",
      "customer": 280,
      "parent_id": 0,
      "correlation_id": "11603",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 7,
      "name": "Puget Sound, WA (CBRE)",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "11277",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1995,
      "name": "Puget Sound, WA (Compass)",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "11669",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1805,
      "name": "Queen Creek",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102632",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "6e8c47b4-e6f3-4658-951f-f2b342053d1d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 3470,
      "name": "Redlands",
      "customer_name": "Deutsche Bank",
      "customer": 468,
      "parent_id": 0,
      "correlation_id": "101921",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/St_Barthelemy",
      "uuid": "219a07d3-50c9-4f61-ba29-3cc2aeeb14ef",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1801,
      "name": "Red Mountain",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102633",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "4b9e0380-2649-4b06-88fc-bf2d670dafbf",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 2348,
      "name": "Reno, NV",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101333",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3467,
      "name": "Richmond, IN",
      "customer_name": "Chubb Insurance (CBRE)",
      "customer": 1018,
      "parent_id": 0,
      "correlation_id": "102047",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "519c7469-e232-466e-9e16-fb9c702efc59",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5499,
      "name": "Ridgedale",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102323",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2589,
      "name": "Roseville",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "101530",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "123db32d-6425-45a7-a771-e015c599d908",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2637,
      "name": "RTP Bio Facility",
      "customer_name": "Biogen (CBRE)",
      "customer": 253,
      "parent_id": 0,
      "correlation_id": "101586",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1626,
      "name": "San Antonio, TX",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "11180",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2072,
      "name": "San Diego, CA",
      "customer_name": "ASML (CBRE)",
      "customer": 281,
      "parent_id": 0,
      "correlation_id": "11672",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3993,
      "name": "San Francisco",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102103",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5817,
      "name": "San Francisco, CA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102741",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2570,
      "name": "Santa Ana",
      "customer_name": "Deutsche Bank",
      "customer": 468,
      "parent_id": 0,
      "correlation_id": "101514",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 839,
      "name": "Santa Clara, CA",
      "customer_name": "Nvidia (CBRE)",
      "customer": 5,
      "parent_id": 0,
      "correlation_id": "11085",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2266,
      "name": "Santa Clara, CA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "101290",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4226,
      "name": "Santa Fe Springs",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "102030",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1806,
      "name": "San Tan",
      "customer_name": "Cigna (CBRE) - Sub",
      "customer": 2154,
      "parent_id": 0,
      "correlation_id": "102634",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "c9619e89-b869-45bc-bdae-dc199a005480",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 3995,
      "name": "Sausalito",
      "customer_name": "Meta (CBRE)",
      "customer": 1422,
      "parent_id": 0,
      "correlation_id": "102105",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "4711f2f6-bb8c-4cfb-aaaf-49edee61dbb7",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1814,
      "name": "Scottsdale (Skysong)",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102635",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "1b828096-e8e3-4647-828c-8608cfb8389e",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 5491,
      "name": "Shoreview Data Center",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102527",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "bb785cef-87b8-435e-b5a6-c0cb74728cc9",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5496,
      "name": "Shoreview Operations CTR",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102319",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5871,
      "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102789",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5500,
      "name": "Sioux Falls Main",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102554",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1673,
      "name": "Sioux Falls OPS",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "11280",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/North_Dakota/Center",
      "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1816,
      "name": "South Mountain",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102636",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "6099def8-9e0b-4883-9f22-d6cb2ae87bd0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2604,
      "name": "Springhouse, PA",
      "customer_name": "Organon (CBRE)",
      "customer": 477,
      "parent_id": 0,
      "correlation_id": "101548",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "6100a342-7776-41ab-a4a9-9e4a092fb692",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5651,
      "name": "Springhouse Plant, PA",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "102528",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5872,
      "name": "SRC – Service Research Center – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102809",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1813,
      "name": "Stapley",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102637",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "36c16e7b-11db-4b03-b2a0-d7d67985f7ca",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2612,
      "name": "St. Louis (Express Scripts)",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "101552",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1323,
      "name": "St. Louis, MO",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "10332",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Kentucky/Louisville",
      "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
      "chatbot_sms_phone_number": "3147893557",
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": true,
      "use_area_links": true
  },
  {
      "id": 1666,
      "name": "St. Louis, MO",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "11270",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/St_Johns",
      "uuid": "c63a0b01-8803-4f82-bceb-eed4af6ada8d",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 6335,
      "name": "St. Petersburg*",
      "customer_name": "McKesson (CBRE)",
      "customer": 220,
      "parent_id": 0,
      "correlation_id": "103363",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "d6067b11-8ffd-4843-89f5-4e40036439a2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1819,
      "name": "Sun City",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102638",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1818,
      "name": "Sun City West",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102639",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "2aefa12a-781f-491e-a736-c5e0415005c1",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2172,
      "name": "Sunnyvale",
      "customer_name": "Uber (CBRE)",
      "customer": 303,
      "parent_id": 0,
      "correlation_id": "101250",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1151,
      "name": "Sunnyvale, CA",
      "customer_name": "Juniper",
      "customer": 63,
      "parent_id": 0,
      "correlation_id": "10990",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5813,
      "name": "Suntec",
      "customer_name": "VMware (CBRE) (SG)",
      "customer": 2155,
      "parent_id": 0,
      "correlation_id": "102731",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "Asia/Singapore",
      "uuid": "5822414e-91a4-4972-930b-dbbb34bcabb4",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2350,
      "name": "Superstition Springs",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 2533,
      "correlation_id": "102640",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "563ae7fc-1776-4457-8803-0f751025b6e8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2226,
      "name": "TFC – The Factory – Detroit, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102781",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5538,
      "name": "Thomas Edison",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102349",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "b82f11dc-9828-47ad-ae9b-b8379522f558",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5975,
      "name": "Tualatin",
      "customer_name": "Lam Research (CBRE)",
      "customer": 2240,
      "parent_id": 0,
      "correlation_id": "102870",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
      "chatbot_sms_phone_number": "",
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2627,
      "name": "Tulsa, OK",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101575",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Fort_Wayne",
      "uuid": "d6f64213-b922-467f-8c79-04580edbd9ce",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3233,
      "name": "Union City",
      "customer_name": "Tenaya(CBRE)",
      "customer": 921,
      "parent_id": 0,
      "correlation_id": "102031",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1459,
      "name": "Upper Gwynedd, PA",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "10031",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5875,
      "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102794",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5874,
      "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102798",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2055,
      "name": "Waco, TX",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "11667",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1198,
      "name": "Waukegan, IL",
      "customer_name": "AbbVie",
      "customer": 75,
      "parent_id": 0,
      "correlation_id": "10136",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1464,
      "name": "Wayzata",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "10038",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/North_Dakota/Center",
      "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5530,
      "name": "Wenatchee, WA",
      "customer_name": "Microsoft (CBRE)",
      "customer": 8,
      "parent_id": 0,
      "correlation_id": "102417",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Los_Angeles",
      "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5567,
      "name": "West Des Moines Campus",
      "customer_name": "Wells Fargo",
      "customer": 87,
      "parent_id": 0,
      "correlation_id": "102361",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2686,
      "name": "West Palm Beach",
      "customer_name": "Sikorsky (CBRE)",
      "customer": 504,
      "parent_id": 0,
      "correlation_id": "101672",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1490,
      "name": "West Point, PA",
      "customer_name": "Merck (CBRE)",
      "customer": 267,
      "parent_id": 0,
      "correlation_id": "10076",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 1812,
      "name": "Westridge",
      "customer_name": "Cigna (CBRE)",
      "customer": 219,
      "parent_id": 0,
      "correlation_id": "102641",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Phoenix",
      "uuid": "d818e965-5360-4456-a460-9d794f4588a0",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 3336,
      "name": "Whitehouse Station, NJ",
      "customer_name": "Chubb Insurance (CBRE)",
      "customer": 1018,
      "parent_id": 0,
      "correlation_id": "102048",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2639,
      "name": "Winter Haven",
      "customer_name": "State Farm (CBRE)",
      "customer": 485,
      "parent_id": 0,
      "correlation_id": "101554",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "8cd64e8b-2467-42c2-8bb3-fe2aca91c0e9",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5777,
      "name": "WOCC",
      "customer_name": "Coca Cola",
      "customer": 2145,
      "parent_id": 0,
      "correlation_id": "102720",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 2214,
      "name": "WPE – Wagner Place East – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102782",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Detroit",
      "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": false
  },
  {
      "id": 5878,
      "name": "WPW – Wagner Place West – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102783",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "0880b424-7af9-404e-9336-727138961123",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5876,
      "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102795",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 5877,
      "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
      "customer_name": "Ford",
      "customer": 315,
      "parent_id": 0,
      "correlation_id": "102796",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/New_York",
      "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  },
  {
      "id": 4125,
      "name": "York, NE",
      "customer_name": "Collins Aerospace (CBRE)",
      "customer": 1547,
      "parent_id": 0,
      "correlation_id": "102087",
      "service_provider": 1,
      "has_SOW": false,
      "local_timezone": "America/Chicago",
      "uuid": "ee153d8f-a7fd-4c7f-b56b-e0060da0230e",
      "chatbot_sms_phone_number": 0,
      "chatbot_sms_country_code": "1",
      "chatbot_is_active": false,
      "use_area_links": true
  }
]

export const cbreBilling = [
    {
      "client_id": 8,
      "client_name": "Microsoft (CBRE)",
      "logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "program_count": 4,
      "total_amount": 16138362.26,
      "owner_poc_full_name": "Shane Follmann",
      "customer_poc_full_name": "BJ Singletary",
      "owner_poc": {
        "id": 3744,
        "last_name": "Follmann",
        "photo_url": "assets/1587505724.8169758_ShaneFollmann_t.jpg",
        "first_name": "Shane"
      },
      "customer_poc": {
        "id": 43187,
        "last_name": "Singletary",
        "photo_url": "",
        "first_name": "BJ"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 15010098.87
        },
        {
          "id": 6,
          "name": "Recycle/Diversion",
          "total_amount": 922116.75
        },
        {
          "id": 138,
          "name": "General Maintenance",
          "total_amount": 453.68
        },
        {
          "id": 278,
          "name": "Virus Response",
          "total_amount": 205692.96
        }
      ],
      "sites_count": 14,
      "sites": [
        {
          "id": 2266,
          "name": "Santa Clara, CA",
          "total_amount": 605977.12,
          "site_start_date": "2020-12-07T23:59:59+00:00"
        },
        {
          "id": 5817,
          "name": "San Francisco, CA",
          "total_amount": 181331.4,
          "site_start_date": "2020-12-07T00:00:00+00:00"
        },
        {
          "id": 2590,
          "name": "Laguna South, WA",
          "total_amount": 91075.96,
          "site_start_date": "2021-08-31T07:00:00+00:00"
        },
        {
          "id": 2348,
          "name": "Reno, NV",
          "total_amount": 132853.11,
          "site_start_date": "2021-02-16T08:00:00+00:00"
        },
        {
          "id": 2651,
          "name": "Dulles, VA",
          "total_amount": 303961.24,
          "site_start_date": "2021-09-22T23:59:59+00:00"
        },
        {
          "id": 1995,
          "name": "Puget Sound, WA (Compass)",
          "total_amount": 1652100.15,
          "site_start_date": "2018-01-01T23:59:59+00:00"
        },
        {
          "id": 2267,
          "name": "Chicago, IL",
          "total_amount": 404210.84,
          "site_start_date": "2020-12-07T23:59:59+00:00"
        },
        {
          "id": 7,
          "name": "Puget Sound, WA (CBRE)",
          "total_amount": 11857239.81,
          "site_start_date": "2017-12-07T23:59:59+00:00"
        },
        {
          "id": 2148,
          "name": "Lincoln Square 2",
          "total_amount": 66949.43,
          "site_start_date": "2017-12-06T08:00:00+00:00"
        },
        {
          "id": 2264,
          "name": "Bothell, WA",
          "total_amount": 77275.92,
          "site_start_date": "2020-12-07T23:59:59+00:00"
        },
        {
          "id": 5978,
          "name": "CHICAGO 22*",
          "total_amount": 7854,
          "site_start_date": "2023-11-21T00:00:00+00:00"
        },
        {
          "id": 1666,
          "name": "St. Louis, MO",
          "total_amount": 20288.84,
          "site_start_date": "2018-07-01T23:59:59+00:00"
        },
        {
          "id": 5530,
          "name": "Wenatchee, WA",
          "total_amount": 174406.16,
          "site_start_date": "2022-11-16T19:04:54+00:00"
        },
        {
          "id": 2120,
          "name": "Des Moines, IA",
          "total_amount": 562838.28,
          "site_start_date": "2020-05-14T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 1422,
      "client_name": "Meta (CBRE)",
      "logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "program_count": 1,
      "total_amount": 10807745.45,
      "owner_poc_full_name": "Luna Duarte",
      "customer_poc_full_name": "Alex Worth",
      "owner_poc": {
        "id": 21850,
        "last_name": "Duarte",
        "photo_url": "assets/1597765547.152738_Lunaphoto_t.jpg",
        "first_name": "Luna"
      },
      "customer_poc": {
        "id": 80336,
        "last_name": "Worth",
        "photo_url": "",
        "first_name": "Alex"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 10807745.45
        }
      ],
      "sites_count": 7,
      "sites": [
        {
          "id": 3993,
          "name": "San Francisco",
          "total_amount": 1726486.86,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        },
        {
          "id": 3995,
          "name": "Sausalito",
          "total_amount": 52105.2,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        },
        {
          "id": 3996,
          "name": "Bayfront",
          "total_amount": 5008916.49,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        },
        {
          "id": 3997,
          "name": "Gateway",
          "total_amount": 286153.88,
          "site_start_date": "2022-07-25T00:00:00+00:00"
        },
        {
          "id": 3992,
          "name": "Burlingame",
          "total_amount": 1293461.15,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        },
        {
          "id": 3994,
          "name": "Chilco",
          "total_amount": 338845.51,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        },
        {
          "id": 3851,
          "name": "Classic",
          "total_amount": 2101776.36,
          "site_start_date": "2022-07-23T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 72,
      "client_name": "Eli Lilly (CBRE)",
      "logo_url": "assets/1605311746.7267878_Artboard8_t.png",
      "program_count": 2,
      "total_amount": 7235487.35,
      "owner_poc_full_name": "Sara Brake",
      "customer_poc_full_name": "Chad Tuttle",
      "owner_poc": {
        "id": 8763,
        "last_name": "Brake",
        "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
        "first_name": "Sara"
      },
      "customer_poc": {
        "id": 45129,
        "last_name": "Tuttle",
        "photo_url": "",
        "first_name": "Chad"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 6976922.45
        },
        {
          "id": 3,
          "name": "GMP",
          "total_amount": 258564.9
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 3687,
          "name": "Durham, NC",
          "total_amount": 801913.44,
          "site_start_date": "2022-07-01T08:00:00+00:00"
        },
        {
          "id": 1469,
          "name": "Indianapolis, IN",
          "total_amount": 6375850.38,
          "site_start_date": "2020-10-09T23:59:59+00:00"
        },
        {
          "id": 5575,
          "name": "Concord, NC",
          "total_amount": 57723.53,
          "site_start_date": "2022-07-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 267,
      "client_name": "Merck (CBRE)",
      "logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "program_count": 10,
      "total_amount": 7005085.74,
      "owner_poc_full_name": "Jenna Rosa",
      "customer_poc_full_name": "Michon Blake",
      "owner_poc": {
        "id": 37079,
        "last_name": "Rosa",
        "photo_url": "assets/b845d0960f214a4b8df444745692370c_2023Headshot_t.jpg",
        "first_name": "Jenna"
      },
      "customer_poc": {
        "id": 19788,
        "last_name": "Blake",
        "photo_url": "",
        "first_name": "Michon"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 4431441.48
        },
        {
          "id": 8,
          "name": "Mat Services",
          "total_amount": 84631.04
        },
        {
          "id": 135,
          "name": "Lab Services",
          "total_amount": 11377.88
        },
        {
          "id": 147,
          "name": "Landscape",
          "total_amount": 42823.09
        },
        {
          "id": 148,
          "name": "Shredding/Document Disposal",
          "total_amount": 137.9
        },
        {
          "id": 158,
          "name": "Pest Control",
          "total_amount": 133416.23
        },
        {
          "id": 172,
          "name": "Supplier Management Fee",
          "total_amount": 32267.97
        },
        {
          "id": 196,
          "name": "Uniforms",
          "total_amount": 618364.09
        },
        {
          "id": 204,
          "name": "Hazardous Waste",
          "total_amount": 1633041.39
        },
        {
          "id": 325,
          "name": "IFM Managed Services",
          "total_amount": 17584.67
        }
      ],
      "sites_count": 6,
      "sites": [
        {
          "id": 1459,
          "name": "Upper Gwynedd, PA",
          "total_amount": 947110.06,
          "site_start_date": "2009-05-01T23:59:59+00:00"
        },
        {
          "id": 1486,
          "name": "Cherokee, PA",
          "total_amount": 251331.35,
          "site_start_date": "2013-11-01T23:59:59+00:00"
        },
        {
          "id": 1491,
          "name": "North Wales, PA",
          "total_amount": 88681.78,
          "site_start_date": "2010-07-01T23:59:59+00:00"
        },
        {
          "id": 1490,
          "name": "West Point, PA",
          "total_amount": 5180513.68,
          "site_start_date": "2010-07-01T23:59:59+00:00"
        },
        {
          "id": 5651,
          "name": "Springhouse Plant, PA",
          "total_amount": 126223.7,
          "site_start_date": "2009-05-01T00:00:00+00:00"
        },
        {
          "id": 1487,
          "name": "Durham, NC",
          "total_amount": 411225.17,
          "site_start_date": "2010-11-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 87,
      "client_name": "Wells Fargo",
      "logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "program_count": 3,
      "total_amount": 5329643.49,
      "owner_poc_full_name": "Miriam Ledezma",
      "customer_poc_full_name": "James Dunnican",
      "owner_poc": {
        "id": 11598,
        "last_name": "Ledezma",
        "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
        "first_name": "Miriam"
      },
      "customer_poc": {
        "id": 78718,
        "last_name": "Dunnican",
        "photo_url": "",
        "first_name": "James"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 5320635.21
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 5488.52
        },
        {
          "id": 278,
          "name": "Virus Response",
          "total_amount": 3519.76
        }
      ],
      "sites_count": 24,
      "sites": [
        {
          "id": 5498,
          "name": "Old Saint Anthony/OSA*",
          "total_amount": 5267.37,
          "site_start_date": "2013-11-01T00:00:00+00:00"
        },
        {
          "id": 5567,
          "name": "West Des Moines Campus",
          "total_amount": 627089.53,
          "site_start_date": "2023-02-01T00:00:00+00:00"
        },
        {
          "id": 5544,
          "name": "Cedar Rapids Downtown",
          "total_amount": 9579.66,
          "site_start_date": "2024-01-01T00:00:00+00:00"
        },
        {
          "id": 5538,
          "name": "Thomas Edison",
          "total_amount": 18699.6,
          "site_start_date": "2024-01-01T00:00:00+00:00"
        },
        {
          "id": 5537,
          "name": "Philadelphia, PA",
          "total_amount": 335652.66,
          "site_start_date": "2023-02-01T00:00:00+00:00"
        },
        {
          "id": 5515,
          "name": "Palo Alto",
          "total_amount": 42273.84,
          "site_start_date": "2023-01-03T00:00:00+00:00"
        },
        {
          "id": 5511,
          "name": "3rd - Brannan",
          "total_amount": 15738.56,
          "site_start_date": "2021-08-01T00:00:00+00:00"
        },
        {
          "id": 5491,
          "name": "Shoreview Data Center",
          "total_amount": 54118.08,
          "site_start_date": "2021-09-01T00:00:00+00:00"
        },
        {
          "id": 5495,
          "name": "McKnight Road",
          "total_amount": 29311.25,
          "site_start_date": "2014-03-07T00:00:00+00:00"
        },
        {
          "id": 1626,
          "name": "San Antonio, TX",
          "total_amount": 741339.12,
          "site_start_date": "2023-02-01T00:00:00+00:00"
        },
        {
          "id": 1673,
          "name": "Sioux Falls OPS",
          "total_amount": 100113.76,
          "site_start_date": "2018-10-01T23:59:59+00:00"
        },
        {
          "id": 1674,
          "name": "Denver",
          "total_amount": 93470.37,
          "site_start_date": "2021-08-01T00:00:00+00:00"
        },
        {
          "id": 5496,
          "name": "Shoreview Operations CTR",
          "total_amount": 282339.9,
          "site_start_date": "2021-09-01T00:00:00+00:00"
        },
        {
          "id": 5831,
          "name": "Denver Service Center",
          "total_amount": 22431.56,
          "site_start_date": "2021-08-01T23:26:31+00:00"
        },
        {
          "id": 1323,
          "name": "St. Louis, MO",
          "total_amount": 858188.82,
          "site_start_date": "2009-09-30T23:59:59+00:00"
        },
        {
          "id": 1324,
          "name": "Illinois",
          "total_amount": 56799.96,
          "site_start_date": "2014-03-06T23:59:59+00:00"
        },
        {
          "id": 1325,
          "name": "Des Moines",
          "total_amount": 279129.01,
          "site_start_date": "2014-05-01T23:59:59+00:00"
        },
        {
          "id": 1327,
          "name": "Campus Chandler -AZ",
          "total_amount": 614758.3,
          "site_start_date": "2013-11-01T23:59:59+00:00"
        },
        {
          "id": 5497,
          "name": "Central",
          "total_amount": 25226.08,
          "site_start_date": "2013-11-01T00:00:00+00:00"
        },
        {
          "id": 1464,
          "name": "Wayzata",
          "total_amount": 29495.13,
          "site_start_date": "2014-03-07T00:00:00+00:00"
        },
        {
          "id": 2088,
          "name": "East Town - MN",
          "total_amount": 567201.92,
          "site_start_date": "2014-05-01T23:59:59+00:00"
        },
        {
          "id": 5499,
          "name": "Ridgedale",
          "total_amount": 13959.36,
          "site_start_date": "2013-11-01T00:00:00+00:00"
        },
        {
          "id": 5500,
          "name": "Sioux Falls Main",
          "total_amount": 22089.3,
          "site_start_date": "2018-09-30T00:00:00+00:00"
        },
        {
          "id": 2087,
          "name": "Campus - MN",
          "total_amount": 485370.35,
          "site_start_date": "2014-05-01T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 315,
      "client_name": "Ford",
      "logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "program_count": 1,
      "total_amount": 4218752.94,
      "owner_poc_full_name": "Bryan Belville",
      "customer_poc_full_name": "Daniel Johnson",
      "owner_poc": {
        "id": 80079,
        "last_name": "Belville",
        "photo_url": "assets/re1ef2b8782b04382a4f29f07b98dbfea_IMG_5382_t.jpg",
        "first_name": "Bryan"
      },
      "customer_poc": {
        "id": 83854,
        "last_name": "Johnson",
        "photo_url": "",
        "first_name": "Daniel"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 4218752.94
        }
      ],
      "sites_count": 40,
      "sites": [
        {
          "id": 6751,
          "name": "Commerce Park 2 15080",
          "total_amount": 726.77,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 2252,
          "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
          "total_amount": 49163.3,
          "site_start_date": "2021-09-01T23:59:59+00:00"
        },
        {
          "id": 2226,
          "name": "TFC – The Factory – Detroit, MI",
          "total_amount": 21800.57,
          "site_start_date": "2020-11-09T00:00:00+00:00"
        },
        {
          "id": 2222,
          "name": "DTF – Drivability Test Facility – Allen Park, MI",
          "total_amount": 52567.7,
          "site_start_date": "2020-11-09T23:59:59+00:00"
        },
        {
          "id": 2220,
          "name": "DSC 1 & 2",
          "total_amount": 3105.07,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 2215,
          "name": "FXC – Ford Experience Center - Dearborn, MI",
          "total_amount": 175601.8,
          "site_start_date": "2021-09-01T23:59:59+00:00"
        },
        {
          "id": 2214,
          "name": "WPE – Wagner Place East – Dearborn, MI",
          "total_amount": 4634.88,
          "site_start_date": "2020-11-09T00:00:00+00:00"
        },
        {
          "id": 5565,
          "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
          "total_amount": 104073.02,
          "site_start_date": "2020-11-09T18:17:26+00:00"
        },
        {
          "id": 5837,
          "name": "LEP - Lima Engine Plant - Lima, OH",
          "total_amount": 602233.8,
          "site_start_date": "2023-11-01T00:00:00+00:00"
        },
        {
          "id": 5851,
          "name": "AEC – Advanced Electrification Center – Dearborn, MI",
          "total_amount": 190770.74,
          "site_start_date": "2020-11-09T14:49:12+00:00"
        },
        {
          "id": 5852,
          "name": "OB1 – Office Building 1 – Dearborn, MI",
          "total_amount": 59411.42,
          "site_start_date": "2020-11-09T20:36:35+00:00"
        },
        {
          "id": 5853,
          "name": "OB2 – Office Building 2 – Dearborn, MI",
          "total_amount": 200611.77,
          "site_start_date": "2020-11-09T20:25:25+00:00"
        },
        {
          "id": 5854,
          "name": "OB3 – Office Building 3 – Dearborn, MI",
          "total_amount": 29937.39,
          "site_start_date": "2020-11-09T14:51:51+00:00"
        },
        {
          "id": 5855,
          "name": "OB4 – Office Building 4 – Dearborn, MI",
          "total_amount": 124229.18,
          "site_start_date": "2020-11-09T14:52:23+00:00"
        },
        {
          "id": 5856,
          "name": "OB5 – Office Building 5 – Dearborn, MI",
          "total_amount": 79903.06,
          "site_start_date": "2020-11-09T14:53:12+00:00"
        },
        {
          "id": 5857,
          "name": "OB6 – Office Building 6 – Dearborn, MI",
          "total_amount": 98646.7,
          "site_start_date": "2020-11-09T19:09:38+00:00"
        },
        {
          "id": 5858,
          "name": "CBB – Crash Barrier – Dearborn, MI",
          "total_amount": 26170.24,
          "site_start_date": "2023-09-28T00:00:00+00:00"
        },
        {
          "id": 5859,
          "name": "DDE – Driving Dynamics East – Dearborn, MI",
          "total_amount": 116910.18,
          "site_start_date": "2020-11-09T23:13:19+00:00"
        },
        {
          "id": 5860,
          "name": "DDW – Driving Dynamics West – Dearborn, MI",
          "total_amount": 49233.32,
          "site_start_date": "2020-11-09T23:10:36+00:00"
        },
        {
          "id": 5861,
          "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
          "total_amount": 165985.04,
          "site_start_date": "2021-09-01T23:06:03+00:00"
        },
        {
          "id": 5862,
          "name": "DYNO – Dynamometer – Dearborn, MI",
          "total_amount": 220222.62,
          "site_start_date": "2020-11-09T23:08:22+00:00"
        },
        {
          "id": 5863,
          "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
          "total_amount": 266423.78,
          "site_start_date": "2020-11-09T23:04:23+00:00"
        },
        {
          "id": 5864,
          "name": "FEL – Ford Engineering Lab – Dearborn, MI",
          "total_amount": 31070.82,
          "site_start_date": "2023-12-27T23:02:32.782+00:00"
        },
        {
          "id": 5865,
          "name": "GTL – Gas Turbine Labs – Dearborn, MI",
          "total_amount": 25121.27,
          "site_start_date": "2020-11-09T23:01:20+00:00"
        },
        {
          "id": 5866,
          "name": "MOB – Maintenance & Operations – Dearborn, MI",
          "total_amount": 43791.87,
          "site_start_date": "2020-11-09T15:11:07+00:00"
        },
        {
          "id": 5868,
          "name": "Powerhouse – Dearborn, MI",
          "total_amount": 22349.12,
          "site_start_date": "2023-12-27T22:27:32.734+00:00"
        },
        {
          "id": 5869,
          "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
          "total_amount": 49129.94,
          "site_start_date": "2020-11-09T22:21:54+00:00"
        },
        {
          "id": 5870,
          "name": "PDC – Product Development Center - Dearborn, MI",
          "total_amount": 1184194.7,
          "site_start_date": "2020-11-09T15:07:19+00:00"
        },
        {
          "id": 5871,
          "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
          "total_amount": 37918.6,
          "site_start_date": "2020-11-09T22:33:42+00:00"
        },
        {
          "id": 5872,
          "name": "SRC – Service Research Center – Dearborn, MI",
          "total_amount": 33306.04,
          "site_start_date": "2020-11-09T22:48:55+00:00"
        },
        {
          "id": 5874,
          "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
          "total_amount": 10102.92,
          "site_start_date": "2023-12-27T20:40:48+00:00"
        },
        {
          "id": 5875,
          "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
          "total_amount": 17839.71,
          "site_start_date": "2020-11-09T22:53:51+00:00"
        },
        {
          "id": 5876,
          "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
          "total_amount": 17075,
          "site_start_date": "2020-11-09T17:22:27+00:00"
        },
        {
          "id": 5877,
          "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
          "total_amount": 23864.13,
          "site_start_date": "2020-11-09T15:09:34+00:00"
        },
        {
          "id": 5878,
          "name": "WPW – Wagner Place West – Dearborn, MI",
          "total_amount": 27767.4,
          "site_start_date": "2020-11-09T20:34:25+00:00"
        },
        {
          "id": 6599,
          "name": "ITHQ-C",
          "total_amount": 12576.1,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 6742,
          "name": "800 Republic",
          "total_amount": 3105.07,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 6744,
          "name": "Fairlane Plaza",
          "total_amount": 27123.54,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 6746,
          "name": "Fairlane Business Park 3",
          "total_amount": 9252.87,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        },
        {
          "id": 6750,
          "name": "IT Bic",
          "total_amount": 801.49,
          "site_start_date": "2024-02-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 180,
      "client_name": "Takeda (CBRE)",
      "logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "program_count": 3,
      "total_amount": 3978934.55,
      "owner_poc_full_name": "Luis Cabrera",
      "customer_poc_full_name": "Edward Mccarthy",
      "owner_poc": {
        "id": 5102,
        "last_name": "Cabrera",
        "photo_url": "assets/1577824247.6788175_LuisCabreraHeadshot_07252019_t.jpg",
        "first_name": "Luis"
      },
      "customer_poc": {
        "id": 95701,
        "last_name": "Mccarthy",
        "photo_url": "",
        "first_name": "Edward"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 3834071.32
        },
        {
          "id": 3,
          "name": "GMP",
          "total_amount": 131292.23
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 13571
        }
      ],
      "sites_count": 5,
      "sites": [
        {
          "id": 3299,
          "name": "Cambridge Kendall SQ",
          "total_amount": 963535.74,
          "site_start_date": "2018-07-01T00:00:00+00:00"
        },
        {
          "id": 1647,
          "name": "Central SQ",
          "total_amount": 1247671.9,
          "site_start_date": "2018-07-01T23:59:59+00:00"
        },
        {
          "id": 3999,
          "name": "Bannockburn",
          "total_amount": 185025.64,
          "site_start_date": "2022-07-01T00:00:00+00:00"
        },
        {
          "id": 4124,
          "name": "Exton",
          "total_amount": 44430.04,
          "site_start_date": "2022-07-01T00:00:00+00:00"
        },
        {
          "id": 3398,
          "name": "Lexington, MA",
          "total_amount": 1538271.23,
          "site_start_date": "2022-07-01T07:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 5,
      "client_name": "Nvidia (CBRE)",
      "logo_url": "assets/1605311860.3280911_Artboard10_t.png",
      "program_count": 6,
      "total_amount": 2772004.56,
      "owner_poc_full_name": "Stuart Kelloff",
      "customer_poc_full_name": "Arman Bagdasaryan",
      "owner_poc": {
        "id": 84308,
        "last_name": "Kelloff",
        "photo_url": "assets/r9caff6d0b6b04667bbfebdad11191ea7_StuKelloffsProfilePic_t.png",
        "first_name": "Stuart"
      },
      "customer_poc": {
        "id": 22290,
        "last_name": "Bagdasaryan",
        "photo_url": "",
        "first_name": "Arman"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 2651567.39
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 2765.2
        },
        {
          "id": 6,
          "name": "Recycle/Diversion",
          "total_amount": 2860.76
        },
        {
          "id": 136,
          "name": "Event Support",
          "total_amount": 13933.72
        },
        {
          "id": 138,
          "name": "General Maintenance",
          "total_amount": 95372.98
        },
        {
          "id": 151,
          "name": "Cafe/Kitchen",
          "total_amount": 5504.51
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 839,
          "name": "Santa Clara, CA",
          "total_amount": 2772004.56,
          "site_start_date": "2017-12-18T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 253,
      "client_name": "Biogen (CBRE)",
      "logo_url": "assets/1605623761.388814_Biogen01_t.png",
      "program_count": 3,
      "total_amount": 2627074.28,
      "owner_poc_full_name": "Matthew Borsick",
      "customer_poc_full_name": "Lee Johnson",
      "owner_poc": {
        "id": 73885,
        "last_name": "Borsick",
        "photo_url": "assets/e885d28c1ee54708a7b4e945a938bc60_BorsickProfessionProfilePicture_t.jpg",
        "first_name": "Matthew"
      },
      "customer_poc": {
        "id": 49746,
        "last_name": "Johnson",
        "photo_url": "",
        "first_name": "Lee"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 2506850.53
        },
        {
          "id": 3,
          "name": "GMP",
          "total_amount": 117040.75
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 3183
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 5809,
          "name": "Pharma Facility",
          "total_amount": 468058.94,
          "site_start_date": "2021-10-31T21:21:28+00:00"
        },
        {
          "id": 1955,
          "name": "Cambridge",
          "total_amount": 1140409.68,
          "site_start_date": "2018-12-22T00:00:00+00:00"
        },
        {
          "id": 2637,
          "name": "RTP Bio Facility",
          "total_amount": 1018605.66,
          "site_start_date": "2021-11-01T07:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 220,
      "client_name": "McKesson (CBRE)",
      "logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "program_count": 1,
      "total_amount": 2184783.79,
      "owner_poc_full_name": "Zachary Shock",
      "customer_poc_full_name": "Marissa Anderson",
      "owner_poc": {
        "id": 53748,
        "last_name": "Shock",
        "photo_url": "assets/re414fb84d6864b0c89361fa3f7846b16_Me_t.jpg",
        "first_name": "Zachary"
      },
      "customer_poc": {
        "id": 83641,
        "last_name": "Anderson",
        "photo_url": "",
        "first_name": "Marissa"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 2184783.79
        }
      ],
      "sites_count": 16,
      "sites": [
        {
          "id": 6076,
          "name": "Cover My Meds 2",
          "total_amount": 99024.96,
          "site_start_date": "2024-01-31T23:45:54.42+00:00"
        },
        {
          "id": 2608,
          "name": "Ellabell, GA",
          "total_amount": 21748,
          "site_start_date": "2021-08-16T16:59:22+00:00"
        },
        {
          "id": 2611,
          "name": "Gahanna",
          "total_amount": 4614.92,
          "site_start_date": "2021-09-15T17:23:04+00:00"
        },
        {
          "id": 2256,
          "name": "Clermont, KY",
          "total_amount": 443857.53,
          "site_start_date": "2020-09-28T23:59:59+00:00"
        },
        {
          "id": 4225,
          "name": "City of Industry",
          "total_amount": 9888.56,
          "site_start_date": "2022-05-09T00:00:00+00:00"
        },
        {
          "id": 4226,
          "name": "Santa Fe Springs",
          "total_amount": 483547.37,
          "site_start_date": "2022-03-01T00:00:00+00:00"
        },
        {
          "id": 1968,
          "name": "Jacksonville",
          "total_amount": 76419.53,
          "site_start_date": "2019-08-31T00:00:00+00:00"
        },
        {
          "id": 2162,
          "name": "Manteno, IL",
          "total_amount": 180176.09,
          "site_start_date": "2020-09-09T23:59:59+00:00"
        },
        {
          "id": 2069,
          "name": "Irving, TX",
          "total_amount": 471894.68,
          "site_start_date": "2019-10-06T23:59:59+00:00"
        },
        {
          "id": 2471,
          "name": "Cover My Meds Columbus",
          "total_amount": 235346.44,
          "site_start_date": "2021-06-04T23:59:59+00:00"
        },
        {
          "id": 2566,
          "name": "Interchange",
          "total_amount": 11633.16,
          "site_start_date": "2021-06-01T12:00:00+00:00"
        },
        {
          "id": 4324,
          "name": "MOON TWP PA",
          "total_amount": 15096,
          "site_start_date": "2022-08-09T07:00:00+00:00"
        },
        {
          "id": 4819,
          "name": "Fairdale",
          "total_amount": 10764.76,
          "site_start_date": "2022-12-15T00:00:00+00:00"
        },
        {
          "id": 2586,
          "name": "Mason",
          "total_amount": 48905.56,
          "site_start_date": "2021-08-20T00:36:12+00:00"
        },
        {
          "id": 2589,
          "name": "Roseville",
          "total_amount": 40909.32,
          "site_start_date": "2021-07-01T12:00:00+00:00"
        },
        {
          "id": 2616,
          "name": "Groveport",
          "total_amount": 30956.91,
          "site_start_date": "2021-09-16T17:27:57+00:00"
        }
      ]
    },
    {
      "client_id": 468,
      "client_name": "Deutsche Bank",
      "logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
      "program_count": 3,
      "total_amount": 2136643.76,
      "owner_poc_full_name": "Sarah Behrman",
      "customer_poc_full_name": "John Papadopoulos",
      "owner_poc": {
        "id": 85087,
        "last_name": "Behrman",
        "photo_url": "assets/311b982448d24b42ab6b2bf771685a0b_SarahBehrmanpic_t.jfif",
        "first_name": "Sarah"
      },
      "customer_poc": {
        "id": 86307,
        "last_name": "Papadopoulos",
        "photo_url": "",
        "first_name": "John"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 2097410.43
        },
        {
          "id": 223,
          "name": "Material Handling",
          "total_amount": 582.05
        },
        {
          "id": 278,
          "name": "Virus Response",
          "total_amount": 38651.28
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 2570,
          "name": "Santa Ana",
          "total_amount": 342826.69,
          "site_start_date": "2023-07-01T17:45:08+00:00"
        },
        {
          "id": 5714,
          "name": "New York",
          "total_amount": 1755165.79,
          "site_start_date": "2023-07-01T00:00:00+00:00"
        },
        {
          "id": 3470,
          "name": "Redlands",
          "total_amount": 38651.28,
          "site_start_date": "2023-07-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 303,
      "client_name": "Uber (CBRE)",
      "logo_url": "assets/1607636171.7063708_Uber_t.jpg",
      "program_count": 2,
      "total_amount": 1639213.02,
      "owner_poc_full_name": "Jose Esquivel Calderon",
      "customer_poc_full_name": "Eduardo Solaeche",
      "owner_poc": {
        "id": 83565,
        "last_name": "Esquivel Calderon",
        "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
        "first_name": "Jose"
      },
      "customer_poc": {
        "id": 49391,
        "last_name": "Solaeche",
        "photo_url": "",
        "first_name": "Eduardo"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 1633612.02
        },
        {
          "id": 130,
          "name": "Window Cleaning",
          "total_amount": 5601
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 2444,
          "name": "Market Street",
          "total_amount": 36379.2,
          "site_start_date": "2023-11-22T18:13:37+00:00"
        },
        {
          "id": 2141,
          "name": "Mission Bay",
          "total_amount": 1336857.7,
          "site_start_date": "2020-07-24T00:00:00+00:00"
        },
        {
          "id": 2172,
          "name": "Sunnyvale",
          "total_amount": 265976.12,
          "site_start_date": "2020-09-14T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 219,
      "client_name": "Cigna (CBRE)",
      "logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "program_count": 2,
      "total_amount": 1429129.49,
      "owner_poc_full_name": "Brandy Earls",
      "customer_poc_full_name": "Michael Lloyd",
      "owner_poc": {
        "id": 36177,
        "last_name": "Earls",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "first_name": "Brandy"
      },
      "customer_poc": {
        "id": 36058,
        "last_name": "Lloyd",
        "photo_url": "",
        "first_name": "Michael"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 1353867.91
        },
        {
          "id": 278,
          "name": "Virus Response",
          "total_amount": 75261.58
        }
      ],
      "sites_count": 15,
      "sites": [
        {
          "id": 2350,
          "name": "Superstition Springs",
          "total_amount": 23083.22,
          "site_start_date": "2021-06-04T23:59:59+00:00"
        },
        {
          "id": 1807,
          "name": "Paradise Valley",
          "total_amount": 21822.98,
          "site_start_date": "2019-07-29T00:00:00+00:00"
        },
        {
          "id": 1808,
          "name": "CJ Harris",
          "total_amount": 57479.06,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1809,
          "name": "Chandler",
          "total_amount": 25099.3,
          "site_start_date": "2019-07-15T23:59:59+00:00"
        },
        {
          "id": 1810,
          "name": "Phoenix Central",
          "total_amount": 132591.28,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1811,
          "name": "Paseo",
          "total_amount": 51850.4,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1812,
          "name": "Westridge",
          "total_amount": 43064,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1813,
          "name": "Stapley",
          "total_amount": 42711.44,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1814,
          "name": "Scottsdale (Skysong)",
          "total_amount": 14568,
          "site_start_date": "2019-07-29T00:00:00+00:00"
        },
        {
          "id": 1816,
          "name": "South Mountain",
          "total_amount": 15571.46,
          "site_start_date": "2019-07-29T00:00:00+00:00"
        },
        {
          "id": 1818,
          "name": "Sun City West",
          "total_amount": 17508.84,
          "site_start_date": "2019-07-15T13:54:25+00:00"
        },
        {
          "id": 1819,
          "name": "Sun City",
          "total_amount": 57637.46,
          "site_start_date": "2019-07-15T00:00:00+00:00"
        },
        {
          "id": 1962,
          "name": "Bloomfield",
          "total_amount": 634289.92,
          "site_start_date": "2019-08-26T07:00:00+00:00"
        },
        {
          "id": 2467,
          "name": "Gallatin, TN",
          "total_amount": 10563.95,
          "site_start_date": "2019-02-01T00:00:00+00:00"
        },
        {
          "id": 2612,
          "name": "St. Louis (Express Scripts)",
          "total_amount": 281288.18,
          "site_start_date": "2021-07-26T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 75,
      "client_name": "AbbVie",
      "logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "program_count": 1,
      "total_amount": 1411488.16,
      "owner_poc_full_name": "Corey Wallace",
      "customer_poc_full_name": "Paul Leydet",
      "owner_poc": {
        "id": 80212,
        "last_name": "Wallace",
        "photo_url": "assets/61c4197397a9465383b2fa6fbb46e986_ProfessionalHeadshotCENTERED_t.jpg",
        "first_name": "Corey"
      },
      "customer_poc": {
        "id": 68250,
        "last_name": "Leydet",
        "photo_url": "",
        "first_name": "Paul"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 1411488.16
        }
      ],
      "sites_count": 6,
      "sites": [
        {
          "id": 1956,
          "name": "Madison, NJ",
          "total_amount": 267754.86,
          "site_start_date": "2019-01-14T23:59:59+00:00"
        },
        {
          "id": 1766,
          "name": "Pleasanton",
          "total_amount": 241235.46,
          "site_start_date": "2019-02-11T00:00:00+00:00"
        },
        {
          "id": 1823,
          "name": "Cincinnati",
          "total_amount": 41586.88,
          "site_start_date": "2019-07-23T23:59:59+00:00"
        },
        {
          "id": 2055,
          "name": "Waco, TX",
          "total_amount": 237974.1,
          "site_start_date": "2020-02-17T00:00:00+00:00"
        },
        {
          "id": 1198,
          "name": "Waukegan, IL",
          "total_amount": 293096.69,
          "site_start_date": "2013-01-01T00:00:00+00:00"
        },
        {
          "id": 1798,
          "name": "Branchburg",
          "total_amount": 329840.17,
          "site_start_date": "2019-07-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 137,
      "client_name": "GM",
      "logo_url": "assets/1605312005.83312_GM_logo_t.jpg",
      "program_count": 1,
      "total_amount": 1383438.07,
      "owner_poc_full_name": "Pauline Schermerhorn",
      "customer_poc_full_name": "Dave Painter",
      "owner_poc": {
        "id": 9243,
        "last_name": "Schermerhorn",
        "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
        "first_name": "Pauline"
      },
      "customer_poc": {
        "id": 89065,
        "last_name": "Painter",
        "photo_url": "",
        "first_name": "Dave"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 1383438.07
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 1,
          "name": "Milford PG, MI",
          "total_amount": 1383438.07,
          "site_start_date": "2017-04-07T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 438,
      "client_name": "Pratt & Whitney (CBRE)",
      "logo_url": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
      "program_count": 3,
      "total_amount": 1058406.6,
      "owner_poc_full_name": "Angela Hileman",
      "customer_poc_full_name": "Elaine Armstrong",
      "owner_poc": {
        "id": 50588,
        "last_name": "Hileman",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "first_name": "Angela"
      },
      "customer_poc": {
        "id": 46721,
        "last_name": "Armstrong",
        "photo_url": "",
        "first_name": "Elaine"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 1046508.92
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 1543.32
        },
        {
          "id": 172,
          "name": "Supplier Management Fee",
          "total_amount": 10354.36
        }
      ],
      "sites_count": 2,
      "sites": [
        {
          "id": 2445,
          "name": "Jupiter, FL",
          "total_amount": 382602.92,
          "site_start_date": "2021-03-31T23:59:59+00:00"
        },
        {
          "id": 4836,
          "name": "Asheville, NC*",
          "total_amount": 675803.68,
          "site_start_date": "2023-12-27T20:26:33+00:00"
        }
      ]
    },
    {
      "client_id": 2145,
      "client_name": "Coca Cola",
      "logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "program_count": 1,
      "total_amount": 909042.38,
      "owner_poc_full_name": "Allen Cornett",
      "customer_poc_full_name": "Doug Helland",
      "owner_poc": {
        "id": 93551,
        "last_name": "Cornett",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "first_name": "Allen"
      },
      "customer_poc": {
        "id": 87045,
        "last_name": "Helland",
        "photo_url": "",
        "first_name": "Doug"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 909042.38
        }
      ],
      "sites_count": 6,
      "sites": [
        {
          "id": 5793,
          "name": "Northyard",
          "total_amount": 27788,
          "site_start_date": "2023-07-01T22:18:54+00:00"
        },
        {
          "id": 5792,
          "name": "PETC",
          "total_amount": 9788,
          "site_start_date": "2023-07-01T22:19:48+00:00"
        },
        {
          "id": 5790,
          "name": "CIC",
          "total_amount": 598,
          "site_start_date": "2023-07-01T15:35:36+00:00"
        },
        {
          "id": 5794,
          "name": "Fulton County Airport Hangar",
          "total_amount": 8658,
          "site_start_date": "2023-07-01T22:32:05+00:00"
        },
        {
          "id": 5777,
          "name": "WOCC",
          "total_amount": 240024,
          "site_start_date": "2023-07-01T21:35:47+00:00"
        },
        {
          "id": 5775,
          "name": "AOC",
          "total_amount": 622186.38,
          "site_start_date": "2023-07-01T21:35:03+00:00"
        }
      ]
    },
    {
      "client_id": 63,
      "client_name": "Juniper",
      "logo_url": "assets/1605624533.566394_JN_t.png",
      "program_count": 4,
      "total_amount": 671845.61,
      "owner_poc_full_name": "Stuart Kelloff",
      "customer_poc_full_name": "Martha Loza",
      "owner_poc": {
        "id": 84308,
        "last_name": "Kelloff",
        "photo_url": "assets/r9caff6d0b6b04667bbfebdad11191ea7_StuKelloffsProfilePic_t.png",
        "first_name": "Stuart"
      },
      "customer_poc": {
        "id": 64124,
        "last_name": "Loza",
        "photo_url": "",
        "first_name": "Martha"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 606141.61
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 8840
        },
        {
          "id": 136,
          "name": "Event Support",
          "total_amount": 12582
        },
        {
          "id": 138,
          "name": "General Maintenance",
          "total_amount": 44282
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 1151,
          "name": "Sunnyvale, CA",
          "total_amount": 671845.61,
          "site_start_date": "2017-09-05T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 2240,
      "client_name": "Lam Research (CBRE)",
      "logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "program_count": 1,
      "total_amount": 635230.79,
      "owner_poc_full_name": "Mark Schlerf",
      "customer_poc_full_name": "William Encinas",
      "owner_poc": {
        "id": 6257,
        "last_name": "Schlerf",
        "photo_url": "assets/r5650e02bde4045fbbfdfbb393a84f241_Mark_headshot_green2_t.jpg",
        "first_name": "Mark"
      },
      "customer_poc": {
        "id": 96120,
        "last_name": "Encinas",
        "photo_url": "",
        "first_name": "William"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 635230.79
        }
      ],
      "sites_count": 4,
      "sites": [
        {
          "id": 5974,
          "name": "Livermore",
          "total_amount": 70489.82,
          "site_start_date": "2024-01-30T00:00:00+00:00"
        },
        {
          "id": 5975,
          "name": "Tualatin",
          "total_amount": 245203.29,
          "site_start_date": "2024-01-30T00:00:00+00:00"
        },
        {
          "id": 5976,
          "name": "Hillsboro",
          "total_amount": 5649.6,
          "site_start_date": "2024-01-30T00:00:00+00:00"
        },
        {
          "id": 5973,
          "name": "Fremont",
          "total_amount": 313888.08,
          "site_start_date": "2024-01-30T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 803,
      "client_name": "Vantiva (CBRE)",
      "logo_url": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
      "program_count": 1,
      "total_amount": 457428.18,
      "owner_poc_full_name": "Ryan Blackwood",
      "customer_poc_full_name": "Lonnie Davis",
      "owner_poc": {
        "id": 47231,
        "last_name": "Blackwood",
        "photo_url": "assets/1619727421.457733_pic_t.jpg",
        "first_name": "Ryan"
      },
      "customer_poc": {
        "id": 70219,
        "last_name": "Davis",
        "photo_url": "",
        "first_name": "Lonnie"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 457428.18
        }
      ],
      "sites_count": 2,
      "sites": [
        {
          "id": 3144,
          "name": "Memphis",
          "total_amount": 369741.48,
          "site_start_date": "2022-04-01T19:10:39+00:00"
        },
        {
          "id": 3432,
          "name": "LaVergne",
          "total_amount": 87686.7,
          "site_start_date": "2022-05-02T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 485,
      "client_name": "State Farm (CBRE)",
      "logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "program_count": 1,
      "total_amount": 441550.03,
      "owner_poc_full_name": "Brandy Earls",
      "customer_poc_full_name": "Glenn Carreathers",
      "owner_poc": {
        "id": 36177,
        "last_name": "Earls",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "first_name": "Brandy"
      },
      "customer_poc": {
        "id": 42911,
        "last_name": "Carreathers",
        "photo_url": "",
        "first_name": "Glenn"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 441550.03
        }
      ],
      "sites_count": 12,
      "sites": [
        {
          "id": 2634,
          "name": "Kalamazoo, MI",
          "total_amount": 8332.96,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2635,
          "name": "Jollyville (Austin)",
          "total_amount": 53850.34,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2639,
          "name": "Winter Haven",
          "total_amount": 23834,
          "site_start_date": "2021-10-01T23:59:59+00:00"
        },
        {
          "id": 2643,
          "name": "Lincoln South",
          "total_amount": 46855.77,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2656,
          "name": "Murfreesboro",
          "total_amount": 17569.68,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2664,
          "name": "Jacksonville FL",
          "total_amount": 30532.88,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2618,
          "name": "Irving, TX",
          "total_amount": 76285.48,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2623,
          "name": "Albany, OH",
          "total_amount": 15701.04,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2624,
          "name": "Malta, NY",
          "total_amount": 19048.2,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2627,
          "name": "Tulsa, OK",
          "total_amount": 16721.4,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2632,
          "name": "Alpharetta, GA",
          "total_amount": 114804.24,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        },
        {
          "id": 2633,
          "name": "Concordville, PA",
          "total_amount": 18014.04,
          "site_start_date": "2021-10-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 1018,
      "client_name": "Chubb Insurance (CBRE)",
      "logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
      "program_count": 1,
      "total_amount": 433306.83,
      "owner_poc_full_name": "Anthony George",
      "customer_poc_full_name": "David Clement",
      "owner_poc": {
        "id": 80120,
        "last_name": "George",
        "photo_url": "assets/bbe0076382a141d185130abb19139898_attachment1692585641270_t.jpg",
        "first_name": "Anthony"
      },
      "customer_poc": {
        "id": 71369,
        "last_name": "Clement",
        "photo_url": "",
        "first_name": "David"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 433306.83
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 3336,
          "name": "Whitehouse Station, NJ",
          "total_amount": 360348.06,
          "site_start_date": "2022-04-01T00:00:00+00:00"
        },
        {
          "id": 3467,
          "name": "Richmond, IN",
          "total_amount": 27095.28,
          "site_start_date": "2022-04-01T00:00:00+00:00"
        },
        {
          "id": 5849,
          "name": "10 Exchange Place, NJ",
          "total_amount": 45863.49,
          "site_start_date": "2022-04-01T17:01:19+00:00"
        }
      ]
    },
    {
      "client_id": 33,
      "client_name": "GSK (CBRE)",
      "logo_url": "assets/1607961353.7332714_GSK_t.jpeg",
      "program_count": 4,
      "total_amount": 395823.16,
      "owner_poc_full_name": "Ahmed Adebola",
      "customer_poc_full_name": "Edward Harsen",
      "owner_poc": {
        "id": 633,
        "last_name": "Adebola",
        "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
        "first_name": "Ahmed"
      },
      "customer_poc": {
        "id": 51519,
        "last_name": "Harsen",
        "photo_url": "",
        "first_name": "Edward"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 112738
        },
        {
          "id": 3,
          "name": "GMP",
          "total_amount": 148246.76
        },
        {
          "id": 6,
          "name": "Recycle/Diversion",
          "total_amount": 123583.4
        },
        {
          "id": 135,
          "name": "Lab Services",
          "total_amount": 11255
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 1768,
          "name": "Oak Hill, NY",
          "total_amount": 395823.16,
          "site_start_date": "2019-03-01T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 90,
      "client_name": "Clorox (CBRE)",
      "logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
      "program_count": 4,
      "total_amount": 387346.3,
      "owner_poc_full_name": "Teodoro Mayorquin",
      "customer_poc_full_name": "Noemi Lopez",
      "owner_poc": {
        "id": 43756,
        "last_name": "Mayorquin",
        "photo_url": "assets/rd4e3af53a4c840108b684e90fac49b44_Tedspicture_t.png",
        "first_name": "Teodoro"
      },
      "customer_poc": {
        "id": 21346,
        "last_name": "Lopez",
        "photo_url": "",
        "first_name": "Noemi"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 368520.05
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 13024.45
        },
        {
          "id": 136,
          "name": "Event Support",
          "total_amount": 1620.8
        },
        {
          "id": 151,
          "name": "Cafe/Kitchen",
          "total_amount": 4181
        }
      ],
      "sites_count": 2,
      "sites": [
        {
          "id": 2439,
          "name": "Oakland, CA",
          "total_amount": 136661.44,
          "site_start_date": "2021-04-01T23:59:59+00:00"
        },
        {
          "id": 1356,
          "name": "Pleasanton",
          "total_amount": 250684.86,
          "site_start_date": "2015-07-01T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 281,
      "client_name": "ASML (CBRE)",
      "logo_url": "assets/1607359606.6159544_ASML_t.png",
      "program_count": 1,
      "total_amount": 380968.42,
      "owner_poc_full_name": "Jose De La Rosa",
      "customer_poc_full_name": "Anna Aspegren",
      "owner_poc": {
        "id": 86586,
        "last_name": "De La Rosa",
        "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
        "first_name": "Jose"
      },
      "customer_poc": {
        "id": 22229,
        "last_name": "Aspegren",
        "photo_url": "",
        "first_name": "Anna"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 380968.42
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2072,
          "name": "San Diego, CA",
          "total_amount": 380968.42,
          "site_start_date": "2020-03-04T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 273,
      "client_name": "Elanco (CBRE)",
      "logo_url": "assets/1607378591.6016922_Elanco_t.png",
      "program_count": 4,
      "total_amount": 299642.11,
      "owner_poc_full_name": "Arturo Vargas",
      "customer_poc_full_name": "Bill Fitzgerald  ",
      "owner_poc": {
        "id": 10657,
        "last_name": "Vargas",
        "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
        "first_name": "Arturo"
      },
      "customer_poc": {
        "id": 23395,
        "last_name": "",
        "photo_url": "",
        "first_name": "Bill Fitzgerald "
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 268564.11
        },
        {
          "id": 3,
          "name": "GMP",
          "total_amount": 27618
        },
        {
          "id": 6,
          "name": "Recycle/Diversion",
          "total_amount": 2540
        },
        {
          "id": 135,
          "name": "Lab Services",
          "total_amount": 920
        }
      ],
      "sites_count": 3,
      "sites": [
        {
          "id": 2016,
          "name": "Clinton",
          "total_amount": 161848.34,
          "site_start_date": "2018-01-01T23:59:59+00:00"
        },
        {
          "id": 1880,
          "name": "Indy",
          "total_amount": 19763.68,
          "site_start_date": "2020-01-01T23:59:59+00:00"
        },
        {
          "id": 2017,
          "name": "Greenfield, IN",
          "total_amount": 118030.09,
          "site_start_date": "2012-10-01T23:59:59+00:00"
        }
      ]
    },
    {
      "client_id": 470,
      "client_name": "IQVIA (CBRE)",
      "logo_url": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
      "program_count": 1,
      "total_amount": 231738.44,
      "owner_poc_full_name": "James Hartz",
      "customer_poc_full_name": "Joseph Gilbert",
      "owner_poc": {
        "id": 14445,
        "last_name": "Hartz",
        "photo_url": "assets/ff480aa2d9fd4e238599b6556e0d351e_JimsPic2_t.jpg",
        "first_name": "James"
      },
      "customer_poc": {
        "id": 68891,
        "last_name": "Gilbert",
        "photo_url": "",
        "first_name": "Joseph"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 231738.44
        }
      ],
      "sites_count": 2,
      "sites": [
        {
          "id": 2576,
          "name": "Durham",
          "total_amount": 221535.82,
          "site_start_date": "2021-06-01T00:00:00+00:00"
        },
        {
          "id": 2640,
          "name": "Bethlehem",
          "total_amount": 10202.62,
          "site_start_date": "2021-06-01T08:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 352,
      "client_name": "Covestro (CBRE)",
      "logo_url": "assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg",
      "program_count": 1,
      "total_amount": 230298.71,
      "owner_poc_full_name": "Jeremy Fick",
      "customer_poc_full_name": "Arlene Smith",
      "owner_poc": {
        "id": 89532,
        "last_name": "Fick",
        "photo_url": "assets/r148dc71f25494c2cb6fa97f64fe0e9d4_image_50424065_t.JPG",
        "first_name": "Jeremy"
      },
      "customer_poc": {
        "id": 68567,
        "last_name": "Smith",
        "photo_url": "",
        "first_name": "Arlene"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 230298.71
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2333,
          "name": "Pittsburg",
          "total_amount": 230298.71,
          "site_start_date": "2021-01-07T08:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 1648,
      "client_name": "TGS",
      "logo_url": "assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg",
      "program_count": 1,
      "total_amount": 172239.49,
      "owner_poc_full_name": "Ana Garcia",
      "customer_poc_full_name": "Danielle Hunter",
      "owner_poc": {
        "id": 49618,
        "last_name": "Garcia",
        "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
        "first_name": "Ana"
      },
      "customer_poc": {
        "id": 95231,
        "last_name": "Hunter",
        "photo_url": "",
        "first_name": "Danielle"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 172239.49
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 4227,
          "name": "Irvine",
          "total_amount": 172239.49,
          "site_start_date": "2023-02-21T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 504,
      "client_name": "Sikorsky (CBRE)",
      "logo_url": "assets/ebf7179e73d34ec99956aa4f54bd6943_Image_Editor5_t.png",
      "program_count": 1,
      "total_amount": 170246.92,
      "owner_poc_full_name": "Angela Hileman",
      "customer_poc_full_name": "Junior Belfort",
      "owner_poc": {
        "id": 50588,
        "last_name": "Hileman",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "first_name": "Angela"
      },
      "customer_poc": {
        "id": 47432,
        "last_name": "Belfort",
        "photo_url": "",
        "first_name": "Junior"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 170246.92
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2686,
          "name": "West Palm Beach",
          "total_amount": 170246.92,
          "site_start_date": "2020-04-10T08:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 921,
      "client_name": "Tenaya(CBRE)",
      "logo_url": "assets/a68fea5f3e804ce2b1571142f2a6fa11_tenaya_therapeutics_logo_t.jpeg",
      "program_count": 2,
      "total_amount": 153203.05,
      "owner_poc_full_name": "Sergio Diaz",
      "customer_poc_full_name": "Andrew Cusack",
      "owner_poc": {
        "id": 9242,
        "last_name": "Diaz",
        "photo_url": "",
        "first_name": "Sergio"
      },
      "customer_poc": {
        "id": 77040,
        "last_name": "Cusack",
        "photo_url": "",
        "first_name": "Andrew"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 152399.05
        },
        {
          "id": 223,
          "name": "Material Handling",
          "total_amount": 804
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 3233,
          "name": "Union City",
          "total_amount": 153203.05,
          "site_start_date": "2022-03-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 280,
      "client_name": "Adient",
      "logo_url": "assets/1605624157.877317_adientvectorlogosmall_t.png",
      "program_count": 1,
      "total_amount": 141102.15,
      "owner_poc_full_name": "Danielle Estes",
      "customer_poc_full_name": "Todd Battle",
      "owner_poc": {
        "id": 250,
        "last_name": "Estes",
        "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
        "first_name": "Danielle"
      },
      "customer_poc": {
        "id": 23832,
        "last_name": "Battle",
        "photo_url": "",
        "first_name": "Todd"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 141102.15
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2071,
          "name": "Plymouth",
          "total_amount": 141102.15,
          "site_start_date": "2019-10-13T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 269,
      "client_name": "Chemours (CBRE)",
      "logo_url": "assets/1607369039.649657_Chemours_t.png",
      "program_count": 2,
      "total_amount": 118195.15,
      "owner_poc_full_name": "Juan Rodriguez McLean",
      "customer_poc_full_name": "Guy Berg",
      "owner_poc": {
        "id": 4526,
        "last_name": "Rodriguez McLean",
        "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
        "first_name": "Juan"
      },
      "customer_poc": {
        "id": 21246,
        "last_name": "Berg",
        "photo_url": "",
        "first_name": "Guy"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 114425.4
        },
        {
          "id": 5,
          "name": "Floor Care",
          "total_amount": 3769.75
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 1997,
          "name": "Newark, DE",
          "total_amount": 118195.15,
          "site_start_date": "2019-12-13T07:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 1649,
      "client_name": "Wells Fargo-Sub",
      "logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "program_count": 1,
      "total_amount": 114275.04,
      "owner_poc_full_name": "Miriam Ledezma",
      "customer_poc_full_name": "James Dunnican",
      "owner_poc": {
        "id": 11598,
        "last_name": "Ledezma",
        "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
        "first_name": "Miriam"
      },
      "customer_poc": {
        "id": 78718,
        "last_name": "Dunnican",
        "photo_url": "",
        "first_name": "James"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 114275.04
        }
      ],
      "sites_count": 6,
      "sites": [
        {
          "id": 4447,
          "name": "North Dakota (CBRE)",
          "total_amount": 34946.56,
          "site_start_date": "2022-08-10T00:00:00+00:00"
        },
        {
          "id": 5745,
          "name": "Moberly (CBRE)",
          "total_amount": 14604.64,
          "site_start_date": "2023-05-12T00:00:00+00:00"
        },
        {
          "id": 5744,
          "name": "Duluth PCS (CBRE)",
          "total_amount": 5634.72,
          "site_start_date": "2023-05-12T00:00:00+00:00"
        },
        {
          "id": 5534,
          "name": "Lodi Vineyard (JLL)",
          "total_amount": 11422.92,
          "site_start_date": "2023-02-01T00:00:00+00:00"
        },
        {
          "id": 5656,
          "name": "Los Angeles Cash Vault (JLL)",
          "total_amount": 42109.6,
          "site_start_date": "2023-03-09T00:00:00+00:00"
        },
        {
          "id": 1974,
          "name": "Michigan (CBRE)",
          "total_amount": 5556.6,
          "site_start_date": "2019-08-28T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 2154,
      "client_name": "Cigna (CBRE) - Sub",
      "logo_url": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
      "program_count": 2,
      "total_amount": 100889.47,
      "owner_poc_full_name": "Brandy Earls",
      "customer_poc_full_name": "Michael Lloyd",
      "owner_poc": {
        "id": 36177,
        "last_name": "Earls",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "first_name": "Brandy"
      },
      "customer_poc": {
        "id": 36058,
        "last_name": "Lloyd",
        "photo_url": "",
        "first_name": "Michael"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 87711.37
        },
        {
          "id": 278,
          "name": "Virus Response",
          "total_amount": 13178.1
        }
      ],
      "sites_count": 8,
      "sites": [
        {
          "id": 1801,
          "name": "Red Mountain",
          "total_amount": 8066,
          "site_start_date": "2023-03-29T20:31:33+00:00"
        },
        {
          "id": 1803,
          "name": "Palm Valley",
          "total_amount": 33180.78,
          "site_start_date": "2023-03-29T20:25:15+00:00"
        },
        {
          "id": 1805,
          "name": "Queen Creek",
          "total_amount": 5629.76,
          "site_start_date": "2023-03-29T00:00:00+00:00"
        },
        {
          "id": 1806,
          "name": "San Tan",
          "total_amount": 14087.52,
          "site_start_date": "2023-03-29T20:21:59+00:00"
        },
        {
          "id": 3839,
          "name": "Baltimore, MD*",
          "total_amount": 21946.65,
          "site_start_date": "2022-06-14T00:00:00+00:00"
        },
        {
          "id": 5687,
          "name": "Apache Junction",
          "total_amount": 7867.64,
          "site_start_date": "2023-03-29T20:24:25+00:00"
        },
        {
          "id": 1815,
          "name": "Norte",
          "total_amount": 4513.76,
          "site_start_date": "2023-03-29T00:00:00+00:00"
        },
        {
          "id": 1800,
          "name": "Arrowhead",
          "total_amount": 5597.36,
          "site_start_date": "2023-03-29T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 1547,
      "client_name": "Collins Aerospace (CBRE)",
      "logo_url": "assets/6617f7d3448b41d9a49d9f54dc0abe45_Screenshot20240216at07.23_t.42",
      "program_count": 1,
      "total_amount": 54554.04,
      "owner_poc_full_name": "Angela Hileman",
      "customer_poc_full_name": "Elaine Armstrong",
      "owner_poc": {
        "id": 50588,
        "last_name": "Hileman",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "first_name": "Angela"
      },
      "customer_poc": {
        "id": 46721,
        "last_name": "Armstrong",
        "photo_url": "",
        "first_name": "Elaine"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 54554.04
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 4125,
          "name": "York, NE",
          "total_amount": 54554.04,
          "site_start_date": "2022-06-01T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 2155,
      "client_name": "VMware (CBRE) (SG)",
      "logo_url": 0,
      "program_count": 2,
      "total_amount": 50336,
      "owner_poc_full_name": "PHUA KOK YEOW",
      "customer_poc_full_name": "Wong Michelle",
      "owner_poc": {
        "id": 36751,
        "last_name": "KOK YEOW",
        "photo_url": "assets/6d9ddd5675634f8c9fd7db3f283caabe_PhilipPhua_t.jpeg",
        "first_name": "PHUA"
      },
      "customer_poc": {
        "id": 88630,
        "last_name": "Michelle",
        "photo_url": "",
        "first_name": "Wong"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 50285
        },
        {
          "id": 136,
          "name": "Event Support",
          "total_amount": 51
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 5813,
          "name": "Suntec",
          "total_amount": 50336,
          "site_start_date": "2023-07-31T17:48:50+00:00"
        }
      ]
    },
    {
      "client_id": 311,
      "client_name": "Siemens (CBRE)",
      "logo_url": "assets/1607637318.9761856_Siemens_t.png",
      "program_count": 1,
      "total_amount": 33604,
      "owner_poc_full_name": "James Hartz",
      "customer_poc_full_name": "Bob Flint",
      "owner_poc": {
        "id": 14445,
        "last_name": "Hartz",
        "photo_url": "assets/ff480aa2d9fd4e238599b6556e0d351e_JimsPic2_t.jpg",
        "first_name": "James"
      },
      "customer_poc": {
        "id": 41003,
        "last_name": "Flint",
        "photo_url": "",
        "first_name": "Bob"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 33604
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2163,
          "name": "Harleysville, PA",
          "total_amount": 33604,
          "site_start_date": "2020-09-08T00:00:00+00:00"
        }
      ]
    },
    {
      "client_id": 477,
      "client_name": "Organon (CBRE)",
      "logo_url": "assets/bd52cc204125445db6a196cad602f4ca_Organon_t.png",
      "program_count": 1,
      "total_amount": 20454.2,
      "owner_poc_full_name": "Justin Homa",
      "customer_poc_full_name": "Allen Foglietta",
      "owner_poc": {
        "id": 14268,
        "last_name": "Homa",
        "photo_url": "assets/1617302638.4097366_me_t.jpg",
        "first_name": "Justin"
      },
      "customer_poc": {
        "id": 94973,
        "last_name": "Foglietta",
        "photo_url": "",
        "first_name": "Allen"
      },
      "programs": [
        {
          "id": 1,
          "name": "Custodial",
          "total_amount": 20454.2
        }
      ],
      "sites_count": 1,
      "sites": [
        {
          "id": 2604,
          "name": "Springhouse, PA",
          "total_amount": 20454.2,
          "site_start_date": "2021-08-25T22:26:53+00:00"
        }
      ]
    }
  ]

export const cbreSiteScorecards = [
    {
      "customer_id": 1018,
      "customer_name": "Chubb Insurance (CBRE)",
      "customer_logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
      "contract_id": 5849,
      "contract_name": "10 Exchange Place, NJ",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 4505,
        "email_address": "gcastro@sbmcorp.com",
        "photo_url": "",
        "name": "Gloria Castro",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 71877,
        "email_address": "Malik.Jusino@Chubb.com",
        "photo_url": "",
        "name": "Malik JUsino",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6747,
      "contract_name": "1633 Fairlane Circle",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5511,
      "contract_name": "3rd - Brannan",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91379,
        "email_address": "vromero@sbmcorp.com",
        "photo_url": "assets/d2948913035e4974a865fdc6a23b9d03_1000003743_t.jpg",
        "name": "Victor Romero",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 82661,
        "email_address": "eduardo.andrade@wellsfargo.com",
        "photo_url": "",
        "name": "Eddie Andrade",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6742,
      "contract_name": "800 Republic",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 96559,
        "email_address": "smokren@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5851,
      "contract_name": "AEC – Advanced Electrification Center – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41923,
        "email_address": "ljohn521@ford.com",
        "photo_url": "",
        "name": "LeAunte Johnson",
        "receives_emails": false
      },
      "overall_score": 3.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2252,
      "contract_name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87768,
        "email_address": "chewit13@ford.com",
        "photo_url": "",
        "name": "Cassidy Hewitt",
        "receives_emails": false
      },
      "overall_score": 4.42,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5775,
      "contract_name": "AOC",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 87045,
        "email_address": "dhelland@coca-cola.com",
        "photo_url": "",
        "name": "Doug Helland",
        "receives_emails": false
      },
      "overall_score": 2.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2623,
      "contract_name": "Albany, OH",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 67400,
        "email_address": "pmurtha@sbmcorp.com",
        "photo_url": "assets/eac1ac2587504314933fc4fc13be5836_patrick_t.jpg",
        "name": "Patrick Murtha",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 52714,
        "email_address": "joe.matijasich@cbre.com",
        "photo_url": "",
        "name": "Joe Matijasich",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2632,
      "contract_name": "Alpharetta, GA",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 25558,
        "email_address": "jrodriguez6@sbmcorp.com",
        "photo_url": "assets/09b698cde762467e950106dacfaaf750_IMG_2892_t.jpg",
        "name": "Jose Rodriguez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 80979,
        "email_address": "Jerry.Ransom@cbre.com",
        "photo_url": "",
        "name": "Jerry Ransom",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 438,
      "customer_name": "Pratt & Whitney (CBRE)",
      "customer_logo_url": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
      "contract_id": 4836,
      "contract_name": "Asheville, NC*",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91431,
        "email_address": "",
        "photo_url": "assets/rd8a79925d61a40fa8201f128ea42a03c_IMG_11081_t.jpg",
        "name": "Lisa Ellsworth",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 95730,
        "email_address": "cjenkins@cbre.com",
        "photo_url": "",
        "name": "Colton Jenkins",
        "receives_emails": false
      },
      "overall_score": 2.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "contract_id": 3999,
      "contract_name": "Bannockburn",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 9323,
        "email_address": "aoneill@sbmcorp.com",
        "photo_url": "assets/2e083fe1df1c4a30b2547d7f6af0313d_Andrew_Omeill_29433_Websize_t.jpg",
        "name": "Andrew O'Neill",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 79378,
        "email_address": "monica.may@cbre.com",
        "photo_url": "",
        "name": "Monica May",
        "receives_emails": true
      },
      "overall_score": 4.6,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3996,
      "contract_name": "Bayfront",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 74889,
        "email_address": "lvazquez@sbmcorp.com",
        "photo_url": "assets/c485321de6cb4f738e5ab1dab3b42111_IMG_0011_t.jpg",
        "name": "Luis Vazquez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78267,
        "email_address": "jvanover@meta.com",
        "photo_url": "",
        "name": "Jamie Vanover",
        "receives_emails": true
      },
      "overall_score": 4.94,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 470,
      "customer_name": "IQVIA (CBRE)",
      "customer_logo_url": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
      "contract_id": 2640,
      "contract_name": "Bethlehem",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 14445,
        "email_address": "jhartz@sbmcorp.com",
        "photo_url": "assets/ff480aa2d9fd4e238599b6556e0d351e_JimsPic2_t.jpg",
        "name": "James Hartz",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 68891,
        "email_address": "Gilbert.Joseph@iqvia.com",
        "photo_url": "",
        "name": "Joseph Gilbert",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1962,
      "contract_name": "Bloomfield",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 43919,
        "email_address": "jbianchi@sbmcorp.com",
        "photo_url": "assets/1612299821.4215329_MITPhoto_t.jpg",
        "name": "Julie Bianchi",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 35943,
        "email_address": "patti.masse@cigna.com",
        "photo_url": "",
        "name": "Patti Masse",
        "receives_emails": true
      },
      "overall_score": 4.73,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2264,
      "contract_name": "Bothell, WA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 52772,
        "email_address": "itaminez@sbmcorp.com",
        "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
        "name": "Ivan Taminez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 43700,
        "email_address": "v-mariah@microsoft.com",
        "photo_url": "",
        "name": "Maria  Hoxsie",
        "receives_emails": false
      },
      "overall_score": 4.84,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.4
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.4
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 1798,
      "contract_name": "Branchburg",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 77361,
        "email_address": "ilawson@sbmcorp.com",
        "photo_url": "assets/ef1d853c11aa42f3926c4f637e27e726_me_t.jpg",
        "name": "Iris Lawson",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 91366,
        "email_address": "robert.fuller@abbvie.com",
        "photo_url": "",
        "name": "Robert Fuller",
        "receives_emails": true
      },
      "overall_score": 4.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3992,
      "contract_name": "Burlingame",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 74852,
        "email_address": "dfultcher@sbmcorp.com",
        "photo_url": "assets/ec180ddc59c54d4b8cd491c337f50852_DarrylFultcher_t.jpg",
        "name": "Darryl Fultcher",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 90317,
        "email_address": "Franciscohuato@meta.com",
        "photo_url": "",
        "name": "Francisco Huato",
        "receives_emails": true
      },
      "overall_score": 4.92,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5858,
      "contract_name": "CBB – Crash Barrier – Dearborn, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.19,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5790,
      "contract_name": "CIC",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 88477,
        "email_address": "S.Wyatt@cictesting.com",
        "photo_url": "",
        "name": "Steven Wyatt",
        "receives_emails": false
      },
      "overall_score": 2,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1808,
      "contract_name": "CJ Harris",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 253,
      "customer_name": "Biogen (CBRE)",
      "customer_logo_url": "assets/1605623761.388814_Biogen01_t.png",
      "contract_id": 1955,
      "contract_name": "Cambridge",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 862,
        "email_address": "lguerrero@sbmcorp.com",
        "photo_url": "db7fd315-1467-ad1b-98f6-ceaf8ce28800.png",
        "name": "Lisangela Guerrero",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87527,
        "email_address": "julian.rosario@biogen.com",
        "photo_url": "",
        "name": "Julian Rosario",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "contract_id": 3299,
      "contract_name": "Cambridge Kendall SQ",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8276,
        "email_address": "jperez-castro@sbmcorp.com",
        "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
        "name": "Jonathan Perez-Castro",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 82339,
        "email_address": "gualter.almeida@takeda.com",
        "photo_url": "",
        "name": "Gualter Almeida",
        "receives_emails": true
      },
      "overall_score": 4.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 2087,
      "contract_name": "Campus - MN",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 36255,
        "email_address": "chiristine.a.dovolas@wellsfargo.com",
        "photo_url": "",
        "name": "Christine Dovolas",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1327,
      "contract_name": "Campus Chandler -AZ",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 14607,
        "email_address": "mrodriguez1@sbmcorp.com",
        "photo_url": "assets/1589890170.5798569_Michaelpic002_t.jpg",
        "name": "Michael Rodriguez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 80050,
        "email_address": "Timothy.Kulpa@wellsfargo.com",
        "photo_url": "",
        "name": "Tim Kulpa",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5544,
      "contract_name": "Cedar Rapids Downtown",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 93652,
        "email_address": "rebecca.l.miller@wellsfargo.com",
        "photo_url": "",
        "name": "Rebecca Miller",
        "receives_emails": false
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5497,
      "contract_name": "Central",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "contract_id": 1647,
      "contract_name": "Central SQ",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8276,
        "email_address": "jperez-castro@sbmcorp.com",
        "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
        "name": "Jonathan Perez-Castro",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 82339,
        "email_address": "gualter.almeida@takeda.com",
        "photo_url": "",
        "name": "Gualter Almeida",
        "receives_emails": true
      },
      "overall_score": 4.92,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1809,
      "contract_name": "Chandler",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": true
      },
      "overall_score": 4.6,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 1486,
      "contract_name": "Cherokee, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 78834,
        "email_address": "tmengle@sbmcorp.com",
        "photo_url": "assets/86750cab82464433bf763755726f8547_PXL_20220917_111531890_t.jpg",
        "name": "Timothy Mengle",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 49519,
        "email_address": "Charles.Winters@merck.com",
        "photo_url": "",
        "name": "Chuck Winters",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2267,
      "contract_name": "Chicago, IL",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 87845,
        "email_address": "jsims@sbmcorp.com",
        "photo_url": "assets/520fd15308c2468eb566bffa91d7f4af_JonathanSimsPhoto_t.jpg",
        "name": "Jonathan Sims",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 69865,
        "email_address": "v-katedukatt@microsoft.com",
        "photo_url": "",
        "name": "Kate  Dukatt",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3994,
      "contract_name": "Chilco",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 75677,
        "email_address": "n/a",
        "photo_url": "assets/6134c9c969f84c3dae3dcb0ea0759894_thumbnail_IMG_05781_t.jpg",
        "name": "Joanna Flores",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78237,
        "email_address": "jmacarena@meta.com",
        "photo_url": "",
        "name": "Jessica Macarena",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 1823,
      "contract_name": "Cincinnati",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 82635,
        "email_address": "jnunez@sbmcorp.com",
        "photo_url": "assets/b5a64e4995664b52b8854d61a05c571c_707385932B0E4C3EAD704C042619EE64_t.jpeg",
        "name": "Juan Nunez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 76313,
        "email_address": "bruce.dunn@abbvie.com",
        "photo_url": "",
        "name": "Bruce Dunn",
        "receives_emails": true
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3851,
      "contract_name": "Classic",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 85280,
        "email_address": "254174@placeholder.com",
        "photo_url": "assets/92becf9e71ba4cedae28ec54872ac577_ProfilePic_t.jpg",
        "name": "Joel Patino Corona",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78915,
        "email_address": "jenniferpete@meta.com",
        "photo_url": "",
        "name": "Jennifer Pete",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2256,
      "contract_name": "Clermont, KY",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 76561,
        "email_address": "",
        "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
        "name": "Jamie Clevenger",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92937,
        "email_address": "tamiko.fomby@mckesson.com",
        "photo_url": "",
        "name": "Tamiko Fomby",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 273,
      "customer_name": "Elanco (CBRE)",
      "customer_logo_url": "assets/1607378591.6016922_Elanco_t.png",
      "contract_id": 2016,
      "contract_name": "Clinton",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8763,
        "email_address": "sblystone@sbmcorp.com",
        "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
        "name": "Sara Brake",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 75525,
        "email_address": "brad.morris@network.elancoah.com",
        "photo_url": "",
        "name": "Brad Morris",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6751,
      "contract_name": "Commerce Park 2 15080",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 96559,
        "email_address": "smokren@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6740,
      "contract_name": "Commerce Park 2 15090",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 96559,
        "email_address": "smokren@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 72,
      "customer_name": "Eli Lilly (CBRE)",
      "customer_logo_url": "assets/1605311746.7267878_Artboard8_t.png",
      "contract_id": 5575,
      "contract_name": "Concord, NC",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 52818,
        "email_address": "dbaker@sbmcorp.com",
        "photo_url": "assets/7db12f89ffcc47288c0dca9785d6efd6_dbakerphoto_t.jpg",
        "name": "Duriel Baker",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 74912,
        "email_address": "vaughn_justin@network.lilly.com",
        "photo_url": "",
        "name": "Justin Vaughn",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 6076,
      "contract_name": "Cover My Meds 2",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 92415,
        "email_address": "dgonce@sbmcorp.com",
        "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
        "name": "Dan Gonce",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 81276,
        "email_address": "Cody.johnson@mckesson.com",
        "photo_url": "",
        "name": "Cody Johnson",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2471,
      "contract_name": "Cover My Meds Columbus",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 92415,
        "email_address": "dgonce@sbmcorp.com",
        "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
        "name": "Dan Gonce",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 81276,
        "email_address": "Cody.johnson@mckesson.com",
        "photo_url": "",
        "name": "Cody Johnson",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5859,
      "contract_name": "DDE – Driving Dynamics East – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 3.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5860,
      "contract_name": "DDW – Driving Dynamics West – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.13,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2220,
      "contract_name": "DSC 1 & 2",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2222,
      "contract_name": "DTF – Drivability Test Facility – Allen Park, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41977,
        "email_address": "ahoyle1@ford.com",
        "photo_url": "",
        "name": "Ashley Hoyle",
        "receives_emails": false
      },
      "overall_score": 4.35,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5862,
      "contract_name": "DYNO – Dynamometer – Dearborn, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 87432,
        "email_address": "kwojtkow@ford.com",
        "photo_url": "",
        "name": "Ken Wojtkowiak",
        "receives_emails": false
      },
      "overall_score": 3.44,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1674,
      "contract_name": "Denver",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6407,
        "email_address": "mvega@sbmcorp.com",
        "photo_url": "assets/5c6481735bfc43c3bc29c5a4ce7ac31a_15aa73416e134c0ba83abf6633db4530_mariavega_t_t.jpg",
        "name": "Maria Vega",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 38308,
        "email_address": "candace.persichetti@wellsfargo.com",
        "photo_url": "",
        "name": "Candace Persichetti",
        "receives_emails": true
      },
      "overall_score": 4.83,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5831,
      "contract_name": "Denver Service Center",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6407,
        "email_address": "mvega@sbmcorp.com",
        "photo_url": "assets/5c6481735bfc43c3bc29c5a4ce7ac31a_15aa73416e134c0ba83abf6633db4530_mariavega_t_t.jpg",
        "name": "Maria Vega",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 38308,
        "email_address": "candace.persichetti@wellsfargo.com",
        "photo_url": "",
        "name": "Candace Persichetti",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1325,
      "contract_name": "Des Moines",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 76247,
        "email_address": "Jason.Smith@wellsfargo.com",
        "photo_url": "",
        "name": "Jason  Smith",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2120,
      "contract_name": "Des Moines, IA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 40101,
        "email_address": "jwolff@sbmcorp.com",
        "photo_url": "assets/1598794289.4717073_Jasonpic_t.jpg",
        "name": "Jason Wolff",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 68467,
        "email_address": "v-vhageman@microsoft.com",
        "photo_url": "",
        "name": "Valerie Hageman",
        "receives_emails": false
      },
      "overall_score": 4.92,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2651,
      "contract_name": "Dulles, VA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 52772,
        "email_address": "itaminez@sbmcorp.com",
        "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
        "name": "Ivan Taminez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 54901,
        "email_address": "v-smccloud@microsoft.com",
        "photo_url": "",
        "name": "Scott McCloud",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 5744,
      "contract_name": "Duluth PCS (CBRE)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 470,
      "customer_name": "IQVIA (CBRE)",
      "customer_logo_url": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
      "contract_id": 2576,
      "contract_name": "Durham",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 69427,
        "email_address": "lalejo@sbmcorp.com",
        "photo_url": "assets/781c8ed781e941c9ab70e438618e0e22_IMG_4001_t.jpg",
        "name": "Lenin Alejo",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 91329,
        "email_address": "Amos.king@cbre.com",
        "photo_url": "",
        "name": "Amos King",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 1487,
      "contract_name": "Durham, NC",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 1536,
        "email_address": "mdelcid@sbmcorp.com",
        "photo_url": "170edc3f-5efe-cf99-64c1-9492eef45d40.png",
        "name": "Maria Delcid",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 19814,
        "email_address": "Kimberly_Paul@merck.com",
        "photo_url": "",
        "name": "Kimberly Plaul ",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 72,
      "customer_name": "Eli Lilly (CBRE)",
      "customer_logo_url": "assets/1605311746.7267878_Artboard8_t.png",
      "contract_id": 3687,
      "contract_name": "Durham, NC",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 92121,
        "email_address": "tracy.howe@network.lilly.com",
        "photo_url": "",
        "name": "Tracy Howe",
        "receives_emails": true
      },
      "overall_score": 4.73,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5861,
      "contract_name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41973,
        "email_address": "jroring@ford.com",
        "photo_url": "",
        "name": "Joseph Roring",
        "receives_emails": false
      },
      "overall_score": 3.63,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5863,
      "contract_name": "EVB – Experimental Vehicle Building – Dearborn, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 3.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 2088,
      "contract_name": "East Town - MN",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 37066,
        "email_address": "Mark.Goulding@wellsfargo.com",
        "photo_url": "",
        "name": "Mark  Goulding ",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2608,
      "contract_name": "Ellabell, GA",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 25558,
        "email_address": "jrodriguez6@sbmcorp.com",
        "photo_url": "assets/09b698cde762467e950106dacfaaf750_IMG_2892_t.jpg",
        "name": "Jose Rodriguez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95248,
        "email_address": "Chip.Brown@McKesson.com",
        "photo_url": "",
        "name": "Chip Brown",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "contract_id": 4124,
      "contract_name": "Exton",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8276,
        "email_address": "jperez-castro@sbmcorp.com",
        "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
        "name": "Jonathan Perez-Castro",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 79378,
        "email_address": "monica.may@cbre.com",
        "photo_url": "",
        "name": "Monica May",
        "receives_emails": true
      },
      "overall_score": 4.58,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5864,
      "contract_name": "FEL – Ford Engineering Lab – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2215,
      "contract_name": "FXC – Ford Experience Center - Dearborn, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41976,
        "email_address": "rwangle2@ford.com",
        "photo_url": "",
        "name": "Renee Wangler",
        "receives_emails": false
      },
      "overall_score": 4.04,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 4819,
      "contract_name": "Fairdale",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 76561,
        "email_address": "",
        "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
        "name": "Jamie Clevenger",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92937,
        "email_address": "tamiko.fomby@mckesson.com",
        "photo_url": "",
        "name": "Tamiko Fomby",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6746,
      "contract_name": "Fairlane Business Park 3",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 96559,
        "email_address": "smokren@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2221,
      "contract_name": "Fairlane Business Park 5",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2217,
      "contract_name": "Fairlane North",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6744,
      "contract_name": "Fairlane Plaza",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2240,
      "customer_name": "Lam Research (CBRE)",
      "customer_logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "contract_id": 5973,
      "contract_name": "Fremont",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91069,
        "email_address": "javalos2@sbmcorp.com",
        "photo_url": "assets/r3026b2f17be24745aaf88981e040fac7_20231018_132507_t.jpg",
        "name": "Jhonatan Avalos Limon",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94707,
        "email_address": "lued.zarate@lamresearch.com",
        "photo_url": "",
        "name": "Lued Zarate",
        "receives_emails": true
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5794,
      "contract_name": "Fulton County Airport Hangar",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 95157,
        "email_address": "mitrahan@coca-cola.com",
        "photo_url": "",
        "name": "Mitchell Trahan",
        "receives_emails": false
      },
      "overall_score": 2,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5865,
      "contract_name": "GTL – Gas Turbine Labs – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41981,
        "email_address": "tlamans1@ford.com",
        "photo_url": "",
        "name": "Terry Lamansky",
        "receives_emails": false
      },
      "overall_score": 4.38,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2611,
      "contract_name": "Gahanna",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 92415,
        "email_address": "dgonce@sbmcorp.com",
        "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
        "name": "Dan Gonce",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 96216,
        "email_address": "Kyle.Lepere@McKesson.com",
        "photo_url": "",
        "name": "Kyle  Lepere",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 2467,
      "contract_name": "Gallatin, TN",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12740,
        "email_address": "jsandoval@sbmcorp.com",
        "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
        "name": "Jaime Sandoval Davila",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 64839,
        "email_address": "Scott.Walker@Cigna.com",
        "photo_url": "",
        "name": "Scott Walker",
        "receives_emails": true
      },
      "overall_score": 4.67,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3997,
      "contract_name": "Gateway",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 75677,
        "email_address": "n/a",
        "photo_url": "assets/6134c9c969f84c3dae3dcb0ea0759894_thumbnail_IMG_05781_t.jpg",
        "name": "Joanna Flores",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 80336,
        "email_address": "aworth@meta.com",
        "photo_url": "",
        "name": "Alex Worth",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 273,
      "customer_name": "Elanco (CBRE)",
      "customer_logo_url": "assets/1607378591.6016922_Elanco_t.png",
      "contract_id": 2017,
      "contract_name": "Greenfield, IN",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 10657,
        "email_address": "avargas@sbmcorp.com",
        "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
        "name": "Arturo Vargas",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 43059,
        "email_address": "ronald.mosier@elanco.com",
        "photo_url": "",
        "name": "Ronald Mosier",
        "receives_emails": true
      },
      "overall_score": 4.23,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2616,
      "contract_name": "Groveport",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 92415,
        "email_address": "dgonce@sbmcorp.com",
        "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
        "name": "Dan Gonce",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 55784,
        "email_address": "Charles.Jennings@McKesson.com",
        "photo_url": "",
        "name": "Charles Jennings",
        "receives_emails": true
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 311,
      "customer_name": "Siemens (CBRE)",
      "customer_logo_url": "assets/1607637318.9761856_Siemens_t.png",
      "contract_id": 2163,
      "contract_name": "Harleysville, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 4526,
        "email_address": "jrodriguez4@sbmcorp.com",
        "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
        "name": "Juan Rodriguez McLean",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41003,
        "email_address": "Bob.Flint@cbre.com",
        "photo_url": "",
        "name": "Bob Flint",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2240,
      "customer_name": "Lam Research (CBRE)",
      "customer_logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "contract_id": 5976,
      "contract_name": "Hillsboro",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 94688,
        "email_address": "vbenitez@sbmcorp.com",
        "photo_url": "assets/re5d03eb192304fff83827dc750d2f867_20240130_135715_t.jpg",
        "name": "Veronica Benitez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 93805,
        "email_address": "anthony.delsignore@lamresearch.com",
        "photo_url": "",
        "name": "Anthony Delsignore",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5565,
      "contract_name": "ION – Ford Battery Center of Excellence – Romulus, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41972,
        "email_address": "shorst@ford.com",
        "photo_url": "",
        "name": "Scott Horst",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6750,
      "contract_name": "IT Bic",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 6599,
      "contract_name": "ITHQ-C",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94218,
        "email_address": "smokrens@ford.com",
        "photo_url": "",
        "name": "Steve Mokrenski",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1324,
      "contract_name": "Illinois",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 77207,
        "email_address": "dcowan@sbmcorp.com",
        "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
        "name": "Damian Cowan",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 22354,
        "email_address": "josh.prince@cbre.com",
        "photo_url": "",
        "name": "Josh Prince ",
        "receives_emails": true
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 72,
      "customer_name": "Eli Lilly (CBRE)",
      "customer_logo_url": "assets/1605311746.7267878_Artboard8_t.png",
      "contract_id": 1469,
      "contract_name": "Indianapolis, IN",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8763,
        "email_address": "sblystone@sbmcorp.com",
        "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
        "name": "Sara Brake",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 24953,
        "email_address": "garrison_rich_b@network.lilly.com",
        "photo_url": "",
        "name": "Rich Garrison",
        "receives_emails": true
      },
      "overall_score": 4.41,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 3.63
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 273,
      "customer_name": "Elanco (CBRE)",
      "customer_logo_url": "assets/1607378591.6016922_Elanco_t.png",
      "contract_id": 1880,
      "contract_name": "Indy",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 10657,
        "email_address": "avargas@sbmcorp.com",
        "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
        "name": "Arturo Vargas",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 43059,
        "email_address": "ronald.mosier@elanco.com",
        "photo_url": "",
        "name": "Ronald Mosier",
        "receives_emails": true
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2566,
      "contract_name": "Interchange",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 76561,
        "email_address": "",
        "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
        "name": "Jamie Clevenger",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92937,
        "email_address": "tamiko.fomby@mckesson.com",
        "photo_url": "",
        "name": "Tamiko Fomby",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1648,
      "customer_name": "TGS",
      "customer_logo_url": "assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg",
      "contract_id": 4227,
      "contract_name": "Irvine",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 49618,
        "email_address": "anagarcia@sbmcorp.com",
        "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
        "name": "Ana Garcia",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 95231,
        "email_address": "danielle@tgscm.com",
        "photo_url": "",
        "name": "Danielle Hunter",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2069,
      "contract_name": "Irving, TX",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 10000,
        "email_address": "cromero-pulido@sbmcorp.com",
        "photo_url": "1038ca6c-e7e4-de20-de01-1253b4c2891a.png",
        "name": "Carlos Romero",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94982,
        "email_address": "josh.garland@mckesson.com",
        "photo_url": "",
        "name": "Josh Garland",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2618,
      "contract_name": "Irving, TX",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12233,
        "email_address": "RSanchez@sbmcorp.com",
        "photo_url": "assets/1619016635.6029003_me_t.jpg",
        "name": "Rosalia Sanchez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 51702,
        "email_address": "nathan.perry@cbre.com",
        "photo_url": "",
        "name": "Nathan Perry",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 1968,
      "contract_name": "Jacksonville",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15343,
        "email_address": "dlawless@sbmcorp.com",
        "photo_url": "",
        "name": "Donavan lawless",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 23617,
        "email_address": "Kendell.Harper@cbre.com",
        "photo_url": "",
        "name": "kendell Harper ",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2664,
      "contract_name": "Jacksonville FL",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12388,
        "email_address": "cenderica@sbmcorp.com",
        "photo_url": "assets/0f5ebb444fba4b6db302929404569ba0_chr_t.jpg",
        "name": "Christian Mendieta",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95126,
        "email_address": "greg.mccabe@cbre.com",
        "photo_url": "",
        "name": "Greg McCabe",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2635,
      "contract_name": "Jollyville (Austin)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 2981,
        "email_address": "bnunley@sbmcorp.com",
        "photo_url": "assets/b659e1d43b284974b4c655acf4c1ab4d_BrentNunleyheadshotcolor_t.jpg",
        "name": "Brent Nunley",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94935,
        "email_address": "celestino.moreno@cbre.com",
        "photo_url": "",
        "name": "Celestino Moreno",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 438,
      "customer_name": "Pratt & Whitney (CBRE)",
      "customer_logo_url": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
      "contract_id": 2445,
      "contract_name": "Jupiter, FL",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 90442,
        "email_address": "",
        "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
        "name": "Melody Batule",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 46720,
        "email_address": "brian.coleman@cbre.com",
        "photo_url": "",
        "name": "Brian Coleman",
        "receives_emails": true
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5837,
      "contract_name": "LEP - Lima Engine Plant - Lima, OH",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91897,
        "email_address": "rfrey@sbmcorp.com",
        "photo_url": "",
        "name": "Ryan Frey",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 91611,
        "email_address": "gary.vongerichten@leadec-services.com",
        "photo_url": "",
        "name": "Gary VonGerichten",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 803,
      "customer_name": "Vantiva (CBRE)",
      "customer_logo_url": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
      "contract_id": 3432,
      "contract_name": "LaVergne",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12740,
        "email_address": "jsandoval@sbmcorp.com",
        "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
        "name": "Jaime Sandoval Davila",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 70219,
        "email_address": "lonnie.davis@cbre.com",
        "photo_url": "",
        "name": "Lonnie Davis",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2590,
      "contract_name": "Laguna South, WA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 855,
        "email_address": "psmith@sbmcorp.com",
        "photo_url": "assets/bfa928ca31d046de9010f02cd0dba0c7_Capture_t.JPG",
        "name": "Paul Smith",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 75795,
        "email_address": "v-mihirs@microsoft.com",
        "photo_url": "",
        "name": "Michelle Hirschy",
        "receives_emails": false
      },
      "overall_score": 4.89,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.35
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.35
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "contract_id": 3398,
      "contract_name": "Lexington, MA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 8276,
        "email_address": "jperez-castro@sbmcorp.com",
        "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
        "name": "Jonathan Perez-Castro",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 93025,
        "email_address": "joseph.seaver@takeda.com",
        "photo_url": "",
        "name": "Joseph Seaver",
        "receives_emails": true
      },
      "overall_score": 4.33,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.13
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2643,
      "contract_name": "Lincoln South",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 48393,
        "email_address": "nvillalobos@sbmcorp.com",
        "photo_url": "assets/bb6ba2cdc8b14c43ae94f3550aa86c00_NicolasVillalobos_t.jpg",
        "name": "Nicolas Villalobos",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 52887,
        "email_address": "gerald.dailey@cbre.com",
        "photo_url": "",
        "name": "Jerry Dailey",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2148,
      "contract_name": "Lincoln Square 2",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 80157,
        "email_address": "sduncan@sbmcorp.com",
        "photo_url": "assets/53251f594e4d4b539212bfd353eec134_5.24_t.2023",
        "name": "Steven Duncan",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 83592,
        "email_address": "v-dcockriel@microsoft.com",
        "photo_url": "",
        "name": "Danny Cockriel",
        "receives_emails": false
      },
      "overall_score": 4.93,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.1
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2240,
      "customer_name": "Lam Research (CBRE)",
      "customer_logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "contract_id": 5974,
      "contract_name": "Livermore",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91069,
        "email_address": "javalos2@sbmcorp.com",
        "photo_url": "assets/r3026b2f17be24745aaf88981e040fac7_20231018_132507_t.jpg",
        "name": "Jhonatan Avalos Limon",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94707,
        "email_address": "lued.zarate@lamresearch.com",
        "photo_url": "",
        "name": "Lued Zarate",
        "receives_emails": true
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 5534,
      "contract_name": "Lodi Vineyard (JLL)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 1757,
        "email_address": "odelrio@sbmcorp.com",
        "photo_url": "assets/2cbc7b25e22345be91eed9e2fbfa8387_Upright2_t.jpg",
        "name": "Orlando Del Rio",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 83510,
        "email_address": "Erin.Evans@wellsfargo.com",
        "photo_url": "",
        "name": "Erin Evans",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 5656,
      "contract_name": "Los Angeles Cash Vault (JLL)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6408,
        "email_address": "ijimenez@sbmcorp.com",
        "photo_url": "assets/af3d30e0e7224df3b68c77a9803bcdf1_me001_t.JPG",
        "name": "Ismael Vazquez Jimenez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87369,
        "email_address": "Catonya.Merkens@wellsfargo.com",
        "photo_url": "",
        "name": "Catonya Merkens",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5866,
      "contract_name": "MOB – Maintenance & Operations – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 94567,
        "email_address": "sgearha2@ford.com",
        "photo_url": "",
        "name": "Sean Gearhart",
        "receives_emails": false
      },
      "overall_score": 4.44,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 4324,
      "contract_name": "MOON TWP PA",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 633,
        "email_address": "aadebola@sbmcorp.com",
        "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
        "name": "Ahmed Adebola",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95250,
        "email_address": "tom.gollob@mckesson.com",
        "photo_url": "",
        "name": "Tom Gollab",
        "receives_emails": true
      },
      "overall_score": 3.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 1956,
      "contract_name": "Madison, NJ",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15291,
        "email_address": "jrodriguez1@sbmcorp.com",
        "photo_url": "b54fe224-fb8f-b632-ea1d-8749616cbce1.png",
        "name": "John Rodriguez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 20651,
        "email_address": "robert.sgaramella@abbvie.com",
        "photo_url": "",
        "name": "Robert Sgaramella",
        "receives_emails": true
      },
      "overall_score": 4.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2162,
      "contract_name": "Manteno, IL",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 21905,
        "email_address": "wnelson1@sbmcorp.com",
        "photo_url": "assets/150f6bf5a30a4ffe811541a5c83c20b9_William_Nelson_29542_t.jpg",
        "name": "William Nelson",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 90323,
        "email_address": "bill.sauber@mckesson.com",
        "photo_url": "",
        "name": "Bill Sauber",
        "receives_emails": true
      },
      "overall_score": 4.98,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 303,
      "customer_name": "Uber (CBRE)",
      "customer_logo_url": "assets/1607636171.7063708_Uber_t.jpg",
      "contract_id": 2444,
      "contract_name": "Market Street",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 83565,
        "email_address": "jesquivel2@sbmcorp.com",
        "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
        "name": "Jose Esquivel Calderon",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 49391,
        "email_address": "esolae@ext.uber.com",
        "photo_url": "",
        "name": "Eduardo Solaeche",
        "receives_emails": false
      },
      "overall_score": 4.81,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2586,
      "contract_name": "Mason",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 82635,
        "email_address": "jnunez@sbmcorp.com",
        "photo_url": "assets/b5a64e4995664b52b8854d61a05c571c_707385932B0E4C3EAD704C042619EE64_t.jpeg",
        "name": "Juan Nunez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 50287,
        "email_address": "Tim.Miller@McKesson.com",
        "photo_url": "",
        "name": "Tim Miller",
        "receives_emails": true
      },
      "overall_score": 4.98,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5495,
      "contract_name": "McKnight Road",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 803,
      "customer_name": "Vantiva (CBRE)",
      "customer_logo_url": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
      "contract_id": 3144,
      "contract_name": "Memphis",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 9455,
        "email_address": "KHammons@sbmcorp.com",
        "photo_url": "assets/1588177218.5716636_IMG_0748_t.PNG",
        "name": "Kevin Hammons",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 70219,
        "email_address": "lonnie.davis@cbre.com",
        "photo_url": "",
        "name": "Lonnie Davis",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 1974,
      "contract_name": "Michigan (CBRE)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6642,
        "email_address": "ozahedi@sbmcorp.com",
        "photo_url": "7a9bd5dc-1f71-4a3a-9360-5bed93f51c3f.png",
        "name": "Omid Zahedi",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95623,
        "email_address": "amanda.augustine@wellsfargo.com",
        "photo_url": "",
        "name": "Amanda Augustine",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 137,
      "customer_name": "GM",
      "customer_logo_url": "assets/1605312005.83312_GM_logo_t.jpg",
      "contract_id": 1,
      "contract_name": "Milford PG, MI",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 9243,
        "email_address": "pschermerhorn@sbmcorp.com",
        "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
        "name": "Pauline Schermerhorn",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95933,
        "email_address": "jennifer.costa@cbre.com",
        "photo_url": "",
        "name": "Jennifer Costa",
        "receives_emails": true
      },
      "overall_score": 4.83,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 303,
      "customer_name": "Uber (CBRE)",
      "customer_logo_url": "assets/1607636171.7063708_Uber_t.jpg",
      "contract_id": 2141,
      "contract_name": "Mission Bay",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 83565,
        "email_address": "jesquivel2@sbmcorp.com",
        "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
        "name": "Jose Esquivel Calderon",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 49391,
        "email_address": "esolae@ext.uber.com",
        "photo_url": "",
        "name": "Eduardo Solaeche",
        "receives_emails": false
      },
      "overall_score": 4.85,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 5745,
      "contract_name": "Moberly (CBRE)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 77207,
        "email_address": "dcowan@sbmcorp.com",
        "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
        "name": "Damian Cowan",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 67865,
        "email_address": "Kevin.Juhas@wellsfargo.com",
        "photo_url": "",
        "name": "Kevin Juhas",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2656,
      "contract_name": "Murfreesboro",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12740,
        "email_address": "jsandoval@sbmcorp.com",
        "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
        "name": "Jaime Sandoval Davila",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 54211,
        "email_address": "Joe.Collier@cbre.com",
        "photo_url": "",
        "name": "Joe Collier",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 468,
      "customer_name": "Deutsche Bank",
      "customer_logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
      "contract_id": 5714,
      "contract_name": "New York",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 51639,
        "email_address": "gvasquez@sbmcorp.com",
        "photo_url": "assets/69023b7a3148465f9d076de3dc1a02cd_4insightprofilepic_t.jpg",
        "name": "Gabriel Vasquez Rosado",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 86885,
        "email_address": "tristan.testagrose@db.com",
        "photo_url": "",
        "name": "Tristan Testagrose ",
        "receives_emails": true
      },
      "overall_score": 4.82,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 269,
      "customer_name": "Chemours (CBRE)",
      "customer_logo_url": "assets/1607369039.649657_Chemours_t.png",
      "contract_id": 1997,
      "contract_name": "Newark, DE",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 88984,
        "email_address": "mhasen@sbmcorp.com",
        "photo_url": "assets/rc5411d9285f940bc8b60cc28527147f4_Resized_20231003_133950_t.jpeg",
        "name": "Mark Hasen",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 21246,
        "email_address": "Guy.Berg@cbre.com",
        "photo_url": "",
        "name": "Guy Berg",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "contract_id": 4447,
      "contract_name": "North Dakota (CBRE)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 5851,
        "email_address": "mhockersmith@sbmcorp.com",
        "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
        "name": "Michelle Hockersmith",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 21999,
        "email_address": "gretchen.petersen@wellsfargo.com",
        "photo_url": "",
        "name": "Gretchen Petersen",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1802,
      "contract_name": "North Valley",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 25572,
        "email_address": "amandaeppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 1491,
      "contract_name": "North Wales, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 627,
        "email_address": "dargueta@sbmcorp.com",
        "photo_url": "assets/r5a94c65f33d34a23a34b9af0b77e31c3_photo_t.PNG",
        "name": "Douglas Argueta",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 42948,
        "email_address": "jim.gregg@merck.com",
        "photo_url": "",
        "name": "Jim Gregg",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5793,
      "contract_name": "Northyard",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87045,
        "email_address": "dhelland@coca-cola.com",
        "photo_url": "",
        "name": "Doug Helland",
        "receives_emails": true
      },
      "overall_score": 2,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5852,
      "contract_name": "OB1 – Office Building 1 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 79187,
        "email_address": "lmoor179@ford.com",
        "photo_url": "",
        "name": "Lovell Moore",
        "receives_emails": false
      },
      "overall_score": 4.69,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5853,
      "contract_name": "OB2 – Office Building 2 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41923,
        "email_address": "ljohn521@ford.com",
        "photo_url": "",
        "name": "LeAunte Johnson",
        "receives_emails": false
      },
      "overall_score": 3.88,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5854,
      "contract_name": "OB3 – Office Building 3 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 79187,
        "email_address": "lmoor179@ford.com",
        "photo_url": "",
        "name": "Lovell Moore",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5855,
      "contract_name": "OB4 – Office Building 4 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 87432,
        "email_address": "kwojtkow@ford.com",
        "photo_url": "",
        "name": "Ken Wojtkowiak",
        "receives_emails": false
      },
      "overall_score": 3.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5856,
      "contract_name": "OB5 – Office Building 5 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 79187,
        "email_address": "lmoor179@ford.com",
        "photo_url": "",
        "name": "Lovell Moore",
        "receives_emails": false
      },
      "overall_score": 4.56,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5857,
      "contract_name": "OB6 – Office Building 6 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41923,
        "email_address": "ljohn521@ford.com",
        "photo_url": "",
        "name": "LeAunte Johnson",
        "receives_emails": false
      },
      "overall_score": 3.88,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 33,
      "customer_name": "GSK (CBRE)",
      "customer_logo_url": "assets/1607961353.7332714_GSK_t.jpeg",
      "contract_id": 1768,
      "contract_name": "Oak Hill, NY",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 633,
        "email_address": "aadebola@sbmcorp.com",
        "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
        "name": "Ahmed Adebola",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 91222,
        "email_address": "jasmine.x.ormsby@haleon.com",
        "photo_url": "",
        "name": "Jasmine Ormsby",
        "receives_emails": true
      },
      "overall_score": 4.61,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 3.88
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 90,
      "customer_name": "Clorox (CBRE)",
      "customer_logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
      "contract_id": 2439,
      "contract_name": "Oakland, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 46997,
        "email_address": "John.Spanek@clorox.com",
        "photo_url": "",
        "name": "John Spanek",
        "receives_emails": false
      },
      "overall_score": 4.81,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5498,
      "contract_name": "Old Saint Anthony/OSA*",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5870,
      "contract_name": "PDC – Product Development Center - Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 42520,
        "email_address": "jscott@sbmcorp.com",
        "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
        "name": "Jennifer Scott",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 74753,
        "email_address": "achernic@ford.com",
        "photo_url": "",
        "name": "Amanda Chernic",
        "receives_emails": false
      },
      "overall_score": 3.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5792,
      "contract_name": "PETC",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 87045,
        "email_address": "dhelland@coca-cola.com",
        "photo_url": "",
        "name": "Doug Helland",
        "receives_emails": false
      },
      "overall_score": 2,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5869,
      "contract_name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 87432,
        "email_address": "kwojtkow@ford.com",
        "photo_url": "",
        "name": "Ken Wojtkowiak",
        "receives_emails": false
      },
      "overall_score": 4.92,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2154,
      "customer_name": "Cigna (CBRE) - Sub",
      "customer_logo_url": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
      "contract_id": 1803,
      "contract_name": "Palm Valley",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5515,
      "contract_name": "Palo Alto",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 91379,
        "email_address": "vromero@sbmcorp.com",
        "photo_url": "assets/d2948913035e4974a865fdc6a23b9d03_1000003743_t.jpg",
        "name": "Victor Romero",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 82337,
        "email_address": "James.Bostwick@wellsfargo.com",
        "photo_url": "",
        "name": "James Bostwick",
        "receives_emails": true
      },
      "overall_score": 4.81,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1807,
      "contract_name": "Paradise Valley",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1811,
      "contract_name": "Paseo",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 253,
      "customer_name": "Biogen (CBRE)",
      "customer_logo_url": "assets/1605623761.388814_Biogen01_t.png",
      "contract_id": 5809,
      "contract_name": "Pharma Facility",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 55078,
        "email_address": "cpurefoy@sbmcorp.com",
        "photo_url": "assets/r8eac2d26be94487996a23e46c0ebcc3d_2930D4F17F48419DBD5712F6C2B24686_t.jpeg",
        "name": "Chitara Purefoy",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 64885,
        "email_address": "timothy.odonnell@biogen.com",
        "photo_url": "",
        "name": "Tim Odonnell",
        "receives_emails": true
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5537,
      "contract_name": "Philadelphia, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6557,
        "email_address": "radams@sbmcorp.com",
        "photo_url": "assets/b5d0b252459e487c9f1177f9c341d1c6_bob_t.jpg",
        "name": "Robert Adams",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 86032,
        "email_address": "Yvette.Torres2@wellsfargo.com",
        "photo_url": "",
        "name": "Yvette Torres",
        "receives_emails": true
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1810,
      "contract_name": "Phoenix Central",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": true
      },
      "overall_score": 4.94,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 352,
      "customer_name": "Covestro (CBRE)",
      "customer_logo_url": "assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg",
      "contract_id": 2333,
      "contract_name": "Pittsburg",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 89532,
        "email_address": "jfick@sbmcorp.com",
        "photo_url": "assets/r148dc71f25494c2cb6fa97f64fe0e9d4_image_50424065_t.JPG",
        "name": "Jeremy Fick",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 68425,
        "email_address": "thomas.caranhe@cbre.com",
        "photo_url": "",
        "name": "Thomas Caranhe",
        "receives_emails": false
      },
      "overall_score": 3.79,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 2.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 90,
      "customer_name": "Clorox (CBRE)",
      "customer_logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
      "contract_id": 1356,
      "contract_name": "Pleasanton",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 21346,
        "email_address": "noemi.lopez@clorox.com",
        "photo_url": "",
        "name": "Noemi Lopez",
        "receives_emails": false
      },
      "overall_score": 4.94,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 1766,
      "contract_name": "Pleasanton",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 43756,
        "email_address": "tmayorquin@sbmcorp.com",
        "photo_url": "assets/rd4e3af53a4c840108b684e90fac49b44_Tedspicture_t.png",
        "name": "Teodoro Mayorquin",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 88053,
        "email_address": "javier.salyrosas@abbvie.com",
        "photo_url": "",
        "name": "Javier Sal Y Rosas",
        "receives_emails": true
      },
      "overall_score": 4.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 280,
      "customer_name": "Adient",
      "customer_logo_url": "assets/1605624157.877317_adientvectorlogosmall_t.png",
      "contract_id": 2071,
      "contract_name": "Plymouth",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 250,
        "email_address": "destes@sbmcorp.com",
        "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
        "name": "Danielle Estes",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 22051,
        "email_address": "Heather.serwa@cbre.com",
        "photo_url": "",
        "name": "Heather Serwa",
        "receives_emails": false
      },
      "overall_score": 4.69,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 7,
      "contract_name": "Puget Sound, WA (CBRE)",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 80157,
        "email_address": "sduncan@sbmcorp.com",
        "photo_url": "assets/53251f594e4d4b539212bfd353eec134_5.24_t.2023",
        "name": "Steven Duncan",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 12880,
        "email_address": "v-jevelh@microsoft.com",
        "photo_url": "",
        "name": "Jerome Velhagen",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 1995,
      "contract_name": "Puget Sound, WA (Compass)",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 1440,
        "email_address": "jgraham@sbmcorp.com",
        "photo_url": "assets/b47c89977e474321b37bcccd8329d283_IMG_9086_t.jpg",
        "name": "Jimmy Graham",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 43185,
        "email_address": "v-mteman@microsoft.com",
        "photo_url": "",
        "name": "Mathew Teman",
        "receives_emails": false
      },
      "overall_score": 4.85,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.1
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.1
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 253,
      "customer_name": "Biogen (CBRE)",
      "customer_logo_url": "assets/1605623761.388814_Biogen01_t.png",
      "contract_id": 2637,
      "contract_name": "RTP Bio Facility",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 55078,
        "email_address": "cpurefoy@sbmcorp.com",
        "photo_url": "assets/r8eac2d26be94487996a23e46c0ebcc3d_2930D4F17F48419DBD5712F6C2B24686_t.jpeg",
        "name": "Chitara Purefoy",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 64885,
        "email_address": "timothy.odonnell@biogen.com",
        "photo_url": "",
        "name": "Tim Odonnell",
        "receives_emails": true
      },
      "overall_score": 3.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 3.13
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.88
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.38
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.38
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.63
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 468,
      "customer_name": "Deutsche Bank",
      "customer_logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
      "contract_id": 3470,
      "contract_name": "Redlands",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 79998,
        "email_address": "",
        "photo_url": "assets/d957c4d3f71a40e18bff000c58542f84_305137486_5207966462632028_7525157243343718431_n_t.jpg",
        "name": "Ana Weekly",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 86065,
        "email_address": "julio.chavez@db.com",
        "photo_url": "",
        "name": "Julio Chavez",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2348,
      "contract_name": "Reno, NV",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 52772,
        "email_address": "itaminez@sbmcorp.com",
        "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
        "name": "Ivan Taminez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 45353,
        "email_address": "v-joesor@microsoft.com",
        "photo_url": "",
        "name": "Joel  Rojas",
        "receives_emails": false
      },
      "overall_score": 4.93,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.1
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1018,
      "customer_name": "Chubb Insurance (CBRE)",
      "customer_logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
      "contract_id": 3467,
      "contract_name": "Richmond, IN",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 19382,
        "email_address": "moropeza@sbmcorp.com",
        "photo_url": "assets/1600282454.3660638_2706cec21_t.jpg",
        "name": "Maria Oropeza Ruz",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 81164,
        "email_address": "david.tryon@chubb.com",
        "photo_url": "",
        "name": "David Tryon",
        "receives_emails": true
      },
      "overall_score": 4.72,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5499,
      "contract_name": "Ridgedale",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 2589,
      "contract_name": "Roseville",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 1757,
        "email_address": "odelrio@sbmcorp.com",
        "photo_url": "assets/2cbc7b25e22345be91eed9e2fbfa8387_Upright2_t.jpg",
        "name": "Orlando Del Rio",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 90480,
        "email_address": "mary.hillier-davidson@mckesson.com",
        "photo_url": "",
        "name": "Mare Hillier-Davidson",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5871,
      "contract_name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.13,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5872,
      "contract_name": "SRC – Service Research Center – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 69080,
        "email_address": "dnels111@ford.com",
        "photo_url": "",
        "name": "David Nelson",
        "receives_emails": false
      },
      "overall_score": 4.19,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1626,
      "contract_name": "San Antonio, TX",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 81228,
        "email_address": "amartinez@sbmcorp.com",
        "photo_url": "assets/r13fa4956fdad4f7e90f73dac8a061d34_Profilepic3_t.jpg",
        "name": "Angelica Martinez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 81279,
        "email_address": "Deon.Arroyo@wellsfargo.com",
        "photo_url": "",
        "name": "Deon Arroyo",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 281,
      "customer_name": "ASML (CBRE)",
      "customer_logo_url": "assets/1607359606.6159544_ASML_t.png",
      "contract_id": 2072,
      "contract_name": "San Diego, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 86586,
        "email_address": "jrosa3@sbmcorp.com",
        "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
        "name": "Jose De La Rosa",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 22229,
        "email_address": "anna.aspegren@asml.com",
        "photo_url": "",
        "name": "Anna Aspegren",
        "receives_emails": false
      },
      "overall_score": 4.13,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3993,
      "contract_name": "San Francisco",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 75239,
        "email_address": "n/a",
        "photo_url": "assets/5a90e29d17494672a5509fe21081ee52_JosePhoto_t.jpg",
        "name": "Jose Oviedo Ascencio",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78238,
        "email_address": "mmaruyama@meta.com",
        "photo_url": "",
        "name": "Michael Maruyama",
        "receives_emails": true
      },
      "overall_score": 4.94,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 5817,
      "contract_name": "San Francisco, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 36398,
        "email_address": "zhauck@sbmcorp.com",
        "photo_url": "assets/r83be7835cd3b4a909131fb2c9177c3a3_Screenshot20230930at12.04_t.18PM",
        "name": "Zane Hauck",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 89130,
        "email_address": "v-ampasc@microsoft.com",
        "photo_url": "",
        "name": "Amber Pascual",
        "receives_emails": false
      },
      "overall_score": 4.89,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.35
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 468,
      "customer_name": "Deutsche Bank",
      "customer_logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
      "contract_id": 2570,
      "contract_name": "Santa Ana",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 79998,
        "email_address": "",
        "photo_url": "assets/d957c4d3f71a40e18bff000c58542f84_305137486_5207966462632028_7525157243343718431_n_t.jpg",
        "name": "Ana Weekly",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 86065,
        "email_address": "julio.chavez@db.com",
        "photo_url": "",
        "name": "Julio Chavez",
        "receives_emails": true
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 5,
      "customer_name": "Nvidia (CBRE)",
      "customer_logo_url": "assets/1605311860.3280911_Artboard10_t.png",
      "contract_id": 839,
      "contract_name": "Santa Clara, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 4724,
        "email_address": "jmontoya@sbmcorp.com",
        "photo_url": "af402568-7938-cb54-9138-9dc8b6e9f24d.png",
        "name": "Jesus Montoya",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 13683,
        "email_address": "dmarquez@nvidia.com",
        "photo_url": "",
        "name": "David Marquez  ",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 2266,
      "contract_name": "Santa Clara, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 45311,
        "email_address": "n/a",
        "photo_url": "assets/re3e61ca418574a0a9ec8f513223e28df_IMG_2459_t.jpeg",
        "name": "Erickson Alejo",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 43412,
        "email_address": "v-jacont@microsoft.com",
        "photo_url": "",
        "name": "Jackie Contreras",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "contract_id": 4226,
      "contract_name": "Santa Fe Springs",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 49618,
        "email_address": "anagarcia@sbmcorp.com",
        "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
        "name": "Ana Garcia",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 90480,
        "email_address": "mary.hillier-davidson@mckesson.com",
        "photo_url": "",
        "name": "Mare Hillier-Davidson",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "contract_id": 3995,
      "contract_name": "Sausalito",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 75239,
        "email_address": "n/a",
        "photo_url": "assets/5a90e29d17494672a5509fe21081ee52_JosePhoto_t.jpg",
        "name": "Jose Oviedo Ascencio",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78238,
        "email_address": "mmaruyama@meta.com",
        "photo_url": "",
        "name": "Michael Maruyama",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1814,
      "contract_name": "Scottsdale (Skysong)",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5491,
      "contract_name": "Shoreview Data Center",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5496,
      "contract_name": "Shoreview Operations CTR",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5500,
      "contract_name": "Sioux Falls Main",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 5851,
        "email_address": "mhockersmith@sbmcorp.com",
        "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
        "name": "Michelle Hockersmith",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 21999,
        "email_address": "gretchen.petersen@wellsfargo.com",
        "photo_url": "",
        "name": "Gretchen Petersen",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1673,
      "contract_name": "Sioux Falls OPS",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 5851,
        "email_address": "mhockersmith@sbmcorp.com",
        "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
        "name": "Michelle Hockersmith",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 21999,
        "email_address": "gretchen.petersen@wellsfargo.com",
        "photo_url": "",
        "name": "Gretchen Petersen",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1816,
      "contract_name": "South Mountain",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 5651,
      "contract_name": "Springhouse Plant, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 10215,
        "email_address": "yluciano@sbmcorp.com",
        "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
        "name": "Yaritza Luciano-Lopez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 64535,
        "email_address": "edward.siegler@merck.com",
        "photo_url": "",
        "name": "Edward  Siegler",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 477,
      "customer_name": "Organon (CBRE)",
      "customer_logo_url": "assets/bd52cc204125445db6a196cad602f4ca_Organon_t.png",
      "contract_id": 2604,
      "contract_name": "Springhouse, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 10215,
        "email_address": "yluciano@sbmcorp.com",
        "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
        "name": "Yaritza Luciano-Lopez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94973,
        "email_address": "jeremy.foglietta@organon.com",
        "photo_url": "",
        "name": "Allen Foglietta",
        "receives_emails": true
      },
      "overall_score": 4.84,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 2612,
      "contract_name": "St. Louis (Express Scripts)",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 11682,
        "email_address": "asmith@sbmcorp.com",
        "photo_url": "assets/1587576767.6580079_AndySmithFinal_t.png",
        "name": "Andrew Smith",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 55332,
        "email_address": "Hope.scott@express-scripts.com",
        "photo_url": "",
        "name": "Hope Scott",
        "receives_emails": true
      },
      "overall_score": 4.83,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1323,
      "contract_name": "St. Louis, MO",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 77207,
        "email_address": "dcowan@sbmcorp.com",
        "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
        "name": "Damian Cowan",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 54808,
        "email_address": "David.Strieker@wellsfargo.com",
        "photo_url": "",
        "name": "David Strieker",
        "receives_emails": true
      },
      "overall_score": 4.4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 1666,
      "contract_name": "St. Louis, MO",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 70948,
        "email_address": "bfoshage@sbmcorp.com",
        "photo_url": "assets/4c104cff96f541979962d8406cea44b6_cropped2369537953099717766.jpg",
        "name": "Bernard Foshage",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 36006,
        "email_address": "jagidc@microsoft.com",
        "photo_url": "",
        "name": "Jasmyn Gidcumb",
        "receives_emails": false
      },
      "overall_score": 4.88,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1813,
      "contract_name": "Stapley",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1819,
      "contract_name": "Sun City",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1818,
      "contract_name": "Sun City West",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 303,
      "customer_name": "Uber (CBRE)",
      "customer_logo_url": "assets/1607636171.7063708_Uber_t.jpg",
      "contract_id": 2172,
      "contract_name": "Sunnyvale",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 44199,
        "email_address": "esuzrez@sbmcorp.com",
        "photo_url": "assets/1611186503.5467808_profilepic_t.jpg",
        "name": "Edgar Suarez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 49391,
        "email_address": "esolae@ext.uber.com",
        "photo_url": "",
        "name": "Eduardo Solaeche",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 63,
      "customer_name": "Juniper",
      "customer_logo_url": "assets/1605624533.566394_JN_t.png",
      "contract_id": 1151,
      "contract_name": "Sunnyvale, CA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6727,
        "email_address": "esanchez1@sbmcorp.com",
        "photo_url": "assets/a686535912b44ebca3bc8933686b93e9_jhfgyuf_t.PNG",
        "name": "Enrique Sanchez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 51521,
        "email_address": "chouston@juniper.net",
        "photo_url": "",
        "name": "Cynthia Houston",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2155,
      "customer_name": "VMware (CBRE) (SG)",
      "customer_logo_url": 0,
      "contract_id": 5813,
      "contract_name": "Suntec",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 36751,
        "email_address": "ppkyeow@sbmcorp.com",
        "photo_url": "assets/6d9ddd5675634f8c9fd7db3f283caabe_PhilipPhua_t.jpeg",
        "name": "PHUA KOK YEOW",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 88629,
        "email_address": "ljalynn@vmware.com",
        "photo_url": "",
        "name": "Jalynn Lim",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 2350,
      "contract_name": "Superstition Springs",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 25572,
        "email_address": "amandaeppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2226,
      "contract_name": "TFC – The Factory – Detroit, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92232,
        "email_address": "abby.schmidt@michigancentral.com",
        "photo_url": "",
        "name": "Abby Schmidt",
        "receives_emails": false
      },
      "overall_score": 4.38,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5538,
      "contract_name": "Thomas Edison",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": 0,
      "customer_poc": {
        "employee_id": 93652,
        "email_address": "rebecca.l.miller@wellsfargo.com",
        "photo_url": "",
        "name": "Rebecca Miller",
        "receives_emails": false
      },
      "overall_score": 4.75,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2240,
      "customer_name": "Lam Research (CBRE)",
      "customer_logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "contract_id": 5975,
      "contract_name": "Tualatin",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 94688,
        "email_address": "vbenitez@sbmcorp.com",
        "photo_url": "assets/re5d03eb192304fff83827dc750d2f867_20240130_135715_t.jpg",
        "name": "Veronica Benitez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 93805,
        "email_address": "anthony.delsignore@lamresearch.com",
        "photo_url": "",
        "name": "Anthony Delsignore",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 921,
      "customer_name": "Tenaya(CBRE)",
      "customer_logo_url": "assets/a68fea5f3e804ce2b1571142f2a6fa11_tenaya_therapeutics_logo_t.jpeg",
      "contract_id": 3233,
      "contract_name": "Union City",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 76717,
        "email_address": "rtorres@sbmcorp.com",
        "photo_url": "assets/38c51e476653467fb0993184c3c0f71d_thumbnail_IMG_8688_t.jpg",
        "name": "Roberto Torres",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 77040,
        "email_address": "andrew.cusack@cbre.com",
        "photo_url": "",
        "name": "Andrew Cusack",
        "receives_emails": true
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 1459,
      "contract_name": "Upper Gwynedd, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 627,
        "email_address": "dargueta@sbmcorp.com",
        "photo_url": "assets/r5a94c65f33d34a23a34b9af0b77e31c3_photo_t.PNG",
        "name": "Douglas Argueta",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 19788,
        "email_address": "michon.blake@merck.com",
        "photo_url": "",
        "name": "Michon Blake",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5875,
      "contract_name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5874,
      "contract_name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 88524,
        "email_address": "kfrench@sbmcorp.com",
        "photo_url": "assets/33bc0d34794147d19e48a8b1ff7f3a52_WeddingPhoto_t.png",
        "name": "Kristin French",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 95619,
        "email_address": "sgearha@ford.com",
        "photo_url": "",
        "name": "Sean Geahart",
        "receives_emails": false
      },
      "overall_score": 4.44,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "contract_id": 5777,
      "contract_name": "WOCC",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87045,
        "email_address": "dhelland@coca-cola.com",
        "photo_url": "",
        "name": "Doug Helland",
        "receives_emails": false
      },
      "overall_score": 2,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 2214,
      "contract_name": "WPE – Wagner Place East – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 90714,
        "email_address": "hgreyer1@ford.com",
        "photo_url": "",
        "name": "Heather Greyerbiehl",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5878,
      "contract_name": "WPW – Wagner Place West – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15516,
        "email_address": "dmcgee@sbmcorp.com",
        "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
        "name": "Donna McGee",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 90714,
        "email_address": "hgreyer1@ford.com",
        "photo_url": "",
        "name": "Heather Greyerbiehl",
        "receives_emails": false
      },
      "overall_score": 4.5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5876,
      "contract_name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 4.25,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "contract_id": 5877,
      "contract_name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15071,
        "email_address": "scurtis@sbmcorp.com",
        "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
        "name": "Stacy Curtis",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 92227,
        "email_address": "ljohns80@ford.com",
        "photo_url": "",
        "name": "Larry Johnson",
        "receives_emails": false
      },
      "overall_score": 3.94,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 2055,
      "contract_name": "Waco, TX",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 69532,
        "email_address": "areyna@sbmcorp.com",
        "photo_url": "assets/fc2b090f50eb4fa3819079dc693004ee_StandardFemale111_t.png",
        "name": "Anna Reyna",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 45715,
        "email_address": "emceathron@abbvie.com",
        "photo_url": "",
        "name": "Ed McEathron",
        "receives_emails": true
      },
      "overall_score": 4.27,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "contract_id": 1198,
      "contract_name": "Waukegan, IL",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 15095,
        "email_address": "mpallan@sbmcorp.com",
        "photo_url": "assets/270f1c6b1f674897871279e472bbbb59_Madeleine_Nelson_29437_Websize_t.jpg",
        "name": "Madeleine Nelson",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 77968,
        "email_address": "tim.lopez@abbvie.com",
        "photo_url": "",
        "name": "Tim Lopez",
        "receives_emails": false
      },
      "overall_score": 4.19,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 1464,
      "contract_name": "Wayzata",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 3480,
        "email_address": "cmartinez@sbmcorp.com",
        "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
        "name": "Cesar Albino Martinez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 39987,
        "email_address": "sheri.a.belchak@wellsfargo.com",
        "photo_url": "",
        "name": "Sheri Belchak",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "contract_id": 5530,
      "contract_name": "Wenatchee, WA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 52772,
        "email_address": "itaminez@sbmcorp.com",
        "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
        "name": "Ivan Taminez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 43543,
        "email_address": "v-meparo@microsoft.com",
        "photo_url": "",
        "name": "Megan Paro",
        "receives_emails": false
      },
      "overall_score": 4.93,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "contract_id": 5567,
      "contract_name": "West Des Moines Campus",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 85470,
        "email_address": "avelarde@sbmcorp.com",
        "photo_url": "assets/r1f9118c97c8d4627a8f75865cefe94d0_cropped216840175318362083.jpg",
        "name": "Alexis Diaz Velarde",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 76431,
        "email_address": "Chris.Mcallister@wellsfargo.com",
        "photo_url": "",
        "name": "Chris  Mcallister",
        "receives_emails": true
      },
      "overall_score": 3.91,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 504,
      "customer_name": "Sikorsky (CBRE)",
      "customer_logo_url": "assets/ebf7179e73d34ec99956aa4f54bd6943_Image_Editor5_t.png",
      "contract_id": 2686,
      "contract_name": "West Palm Beach",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 90442,
        "email_address": "",
        "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
        "name": "Melody Batule",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 47432,
        "email_address": "junior.belfort@cbre.com",
        "photo_url": "",
        "name": "Junior Belfort",
        "receives_emails": true
      },
      "overall_score": 4.23,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 2.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "contract_id": 1490,
      "contract_name": "West Point, PA",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 2988,
        "email_address": "pbotto@sbmcorp.com",
        "photo_url": "192869d8-cac2-8b12-a124-85d67f827795.png",
        "name": "Paul Botto",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 21019,
        "email_address": "erik_dickinson@merck.com",
        "photo_url": "",
        "name": "Erik Dickinson ",
        "receives_emails": true
      },
      "overall_score": 4.92,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "contract_id": 1812,
      "contract_name": "Westridge",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 46011,
        "email_address": "aascencio@sbmcorp.com",
        "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
        "name": "Alan Ascencio Chavez",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 41863,
        "email_address": "amanda.eppel@cigna.com",
        "photo_url": "",
        "name": "Amanda Eppel",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1018,
      "customer_name": "Chubb Insurance (CBRE)",
      "customer_logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
      "contract_id": 3336,
      "contract_name": "Whitehouse Station, NJ",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 6596,
        "email_address": "yduran@sbmcorp.com",
        "photo_url": "assets/1602274287.711383_Yasmiry_t.jpg",
        "name": "Yasmiry Duran",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 71369,
        "email_address": "david.clement@chubb.com",
        "photo_url": "",
        "name": "David Clement",
        "receives_emails": true
      },
      "overall_score": 4.85,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.7
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "contract_id": 2639,
      "contract_name": "Winter Haven",
      "contract_is_mobile": true,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 12388,
        "email_address": "cenderica@sbmcorp.com",
        "photo_url": "assets/0f5ebb444fba4b6db302929404569ba0_chr_t.jpg",
        "name": "Christian Mendieta",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95126,
        "email_address": "greg.mccabe@cbre.com",
        "photo_url": "",
        "name": "Greg McCabe",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "customer_id": 1547,
      "customer_name": "Collins Aerospace (CBRE)",
      "customer_logo_url": "assets/6617f7d3448b41d9a49d9f54dc0abe45_Screenshot20240216at07.23_t.42",
      "contract_id": 4125,
      "contract_name": "York, NE",
      "contract_is_mobile": false,
      "service_provider_id": 1,
      "service_provider_name": "SBM",
      "service_provider_poc": {
        "employee_id": 48393,
        "email_address": "nvillalobos@sbmcorp.com",
        "photo_url": "assets/bb6ba2cdc8b14c43ae94f3550aa86c00_NicolasVillalobos_t.jpg",
        "name": "Nicolas Villalobos",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 73494,
        "email_address": "ricky.merica@cbre.com",
        "photo_url": "",
        "name": "Ricky Merica",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    }
  ]

  export const cbreCustomerScorecards =  [
    {
      "number_of_contracts": 1,
      "customer_id": 281,
      "customer_name": "ASML (CBRE)",
      "customer_logo_url": "assets/1607359606.6159544_ASML_t.png",
      "service_provider_poc": {
        "employee_id": 86586,
        "email_address": "jrosa3@sbmcorp.com",
        "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
        "name": "Jose De La Rosa",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 22229,
        "email_address": "anna.aspegren@asml.com",
        "photo_url": "",
        "name": "Anna Aspegren",
        "receives_emails": true
      },
      "overall_score": 4.13,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 6,
      "customer_id": 75,
      "customer_name": "AbbVie",
      "customer_logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
      "service_provider_poc": {
        "employee_id": 80212,
        "email_address": "cwallace@sbmcorp.com",
        "photo_url": "assets/61c4197397a9465383b2fa6fbb46e986_ProfessionalHeadshotCENTERED_t.jpg",
        "name": "Corey Wallace",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 68250,
        "email_address": "paul.leydet@cbre.com",
        "photo_url": "",
        "name": "Paul Leydet",
        "receives_emails": true
      },
      "overall_score": 4.71,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.833333333333333
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.666666666666667
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.125
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.583333333333333
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.708333333333333
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.333333333333333
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.833333333333333
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.9
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.8
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 280,
      "customer_name": "Adient",
      "customer_logo_url": "assets/1605624157.877317_adientvectorlogosmall_t.png",
      "service_provider_poc": {
        "employee_id": 250,
        "email_address": "destes@sbmcorp.com",
        "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
        "name": "Danielle Estes",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 23832,
        "email_address": "Todd.battle@cbre.com",
        "photo_url": "",
        "name": "Todd Battle",
        "receives_emails": true
      },
      "overall_score": 4.69,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 3,
      "customer_id": 253,
      "customer_name": "Biogen (CBRE)",
      "customer_logo_url": "assets/1605623761.388814_Biogen01_t.png",
      "service_provider_poc": {
        "employee_id": 73885,
        "email_address": "mborsick@sbmcorp.com",
        "photo_url": "assets/e885d28c1ee54708a7b4e945a938bc60_BorsickProfessionProfilePicture_t.jpg",
        "name": "Matthew Borsick",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 49746,
        "email_address": "lee.johnson@biogen.com",
        "photo_url": "",
        "name": "Lee Johnson",
        "receives_emails": true
      },
      "overall_score": 4.49,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.375
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.065
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.44
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.19
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.46
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.793333333333333
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.71
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.833333333333333
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.67
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 269,
      "customer_name": "Chemours (CBRE)",
      "customer_logo_url": "assets/1607369039.649657_Chemours_t.png",
      "service_provider_poc": {
        "employee_id": 88984,
        "email_address": "mhasen@sbmcorp.com",
        "photo_url": "assets/rc5411d9285f940bc8b60cc28527147f4_Resized_20231003_133950_t.jpeg",
        "name": "Mark Hasen",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 21246,
        "email_address": "Guy.Berg@cbre.com",
        "photo_url": "",
        "name": "Guy Berg",
        "receives_emails": true
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 3,
      "customer_id": 1018,
      "customer_name": "Chubb Insurance (CBRE)",
      "customer_logo_url": "assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
      "service_provider_poc": {
        "employee_id": 80120,
        "email_address": "ageorge@sbmcorp.com",
        "photo_url": "assets/bbe0076382a141d185130abb19139898_attachment1692585641270_t.jpg",
        "name": "Anthony George",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 71369,
        "email_address": "david.clement@chubb.com",
        "photo_url": "",
        "name": "David Clement",
        "receives_emails": true
      },
      "overall_score": 4.8,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.1
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.583333333333333
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 16,
      "customer_id": 219,
      "customer_name": "Cigna (CBRE)",
      "customer_logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
      "service_provider_poc": {
        "employee_id": 36177,
        "email_address": "bearls@sbmcorp.com",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "name": "Brandy Earls",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 36058,
        "email_address": "michael.lloyd@cigna.com",
        "photo_url": "",
        "name": "Michael Lloyd",
        "receives_emails": true
      },
      "overall_score": 4.79,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.3125
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5625
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.875
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.6875
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.90625
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.9375
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 8,
      "customer_id": 2154,
      "customer_name": "Cigna (CBRE) - Sub",
      "customer_logo_url": "assets/168f09f26a77417c9a74216a70b42497_Image_Editor1_t.png",
      "service_provider_poc": {
        "employee_id": 36177,
        "email_address": "bearls@sbmcorp.com",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "name": "Brandy Earls",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 36058,
        "email_address": "michael.lloyd@cigna.com",
        "photo_url": "",
        "name": "Michael Lloyd",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 2,
      "customer_id": 90,
      "customer_name": "Clorox (CBRE)",
      "customer_logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
      "service_provider_poc": {
        "employee_id": 43756,
        "email_address": "tmayorquin@sbmcorp.com",
        "photo_url": "assets/rd4e3af53a4c840108b684e90fac49b44_Tedspicture_t.png",
        "name": "Teodoro Mayorquin",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 21346,
        "email_address": "noemi.lopez@clorox.com",
        "photo_url": "",
        "name": "Noemi Lopez",
        "receives_emails": true
      },
      "overall_score": 4.88,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 6,
      "customer_id": 2145,
      "customer_name": "Coca Cola",
      "customer_logo_url": "assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
      "service_provider_poc": {
        "employee_id": 93551,
        "email_address": "acornett@sbmcorp.com",
        "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
        "name": "Allen Cornett",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 87045,
        "email_address": "dhelland@coca-cola.com",
        "photo_url": "",
        "name": "Doug Helland",
        "receives_emails": false
      },
      "overall_score": 2.04,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 2
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 2.0833333333333335
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 1547,
      "customer_name": "Collins Aerospace (CBRE)",
      "customer_logo_url": "assets/6617f7d3448b41d9a49d9f54dc0abe45_Screenshot20240216at07.23_t.42",
      "service_provider_poc": {
        "employee_id": 48393,
        "email_address": "nvillalobos@sbmcorp.com",
        "photo_url": "assets/bb6ba2cdc8b14c43ae94f3550aa86c00_NicolasVillalobos_t.jpg",
        "name": "Nicolas Villalobos",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 46721,
        "email_address": "elaine.armstrong@cbre.com",
        "photo_url": "",
        "name": "Elaine Armstrong",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 352,
      "customer_name": "Covestro (CBRE)",
      "customer_logo_url": "assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg",
      "service_provider_poc": {
        "employee_id": 89532,
        "email_address": "jfick@sbmcorp.com",
        "photo_url": "assets/r148dc71f25494c2cb6fa97f64fe0e9d4_image_50424065_t.JPG",
        "name": "Jeremy Fick",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 68567,
        "email_address": "Arlene.Smith@covestro.com",
        "photo_url": "",
        "name": "Arlene Smith",
        "receives_emails": false
      },
      "overall_score": 3.79,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 2.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.25
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 3,
      "customer_id": 468,
      "customer_name": "Deutsche Bank",
      "customer_logo_url": "assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
      "service_provider_poc": {
        "employee_id": 85087,
        "email_address": "sbehrman@sbmcorp.com",
        "photo_url": "assets/311b982448d24b42ab6b2bf771685a0b_SarahBehrmanpic_t.jfif",
        "name": "Sarah Behrman",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 86307,
        "email_address": "John.Papadopoulos@cbre.com",
        "photo_url": "",
        "name": "John Papadopoulos",
        "receives_emails": true
      },
      "overall_score": 4.73,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.333333333333333
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 3,
      "customer_id": 273,
      "customer_name": "Elanco (CBRE)",
      "customer_logo_url": "assets/1607378591.6016922_Elanco_t.png",
      "service_provider_poc": {
        "employee_id": 10657,
        "email_address": "avargas@sbmcorp.com",
        "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
        "name": "Arturo Vargas",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 23395,
        "email_address": "Fitzgerald_william_p@network.lilly.com",
        "photo_url": "",
        "name": "Bill Fitzgerald  ",
        "receives_emails": true
      },
      "overall_score": 4.66,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.416666666666667
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.666666666666667
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.583333333333333
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.583333333333333
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.666666666666667
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 4,
      "customer_id": 72,
      "customer_name": "Eli Lilly (CBRE)",
      "customer_logo_url": "assets/1605311746.7267878_Artboard8_t.png",
      "service_provider_poc": {
        "employee_id": 8763,
        "email_address": "sblystone@sbmcorp.com",
        "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
        "name": "Sara Brake",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 45129,
        "email_address": "tuttle_chad_wayne@network.lilly.com",
        "photo_url": "",
        "name": "Chad Tuttle",
        "receives_emails": true
      },
      "overall_score": 4.58,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.815
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.19
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.505
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.38
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.815
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.375
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.315
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.876666666666667
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 43,
      "customer_id": 315,
      "customer_name": "Ford",
      "customer_logo_url": "assets/1605311926.006089_Artboard11_t.png",
      "service_provider_poc": {
        "employee_id": 80079,
        "email_address": "bbelville@sbmcorp.com",
        "photo_url": "assets/re1ef2b8782b04382a4f29f07b98dbfea_IMG_5382_t.jpg",
        "name": "Bryan Belville",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 83854,
        "email_address": "daniel.johnson@leadec-services.com",
        "photo_url": "",
        "name": "Daniel Johnson",
        "receives_emails": true
      },
      "overall_score": 4.33,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.3125
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.4375
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.6875
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.875
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.225806451612903
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.5961538461538463
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.339285714285714
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.215116279069767
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 137,
      "customer_name": "GM",
      "customer_logo_url": "assets/1605312005.83312_GM_logo_t.jpg",
      "service_provider_poc": {
        "employee_id": 9243,
        "email_address": "pschermerhorn@sbmcorp.com",
        "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
        "name": "Pauline Schermerhorn",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 89065,
        "email_address": "dave.painter@gm.com",
        "photo_url": "",
        "name": "Dave Painter",
        "receives_emails": true
      },
      "overall_score": 4.83,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 33,
      "customer_name": "GSK (CBRE)",
      "customer_logo_url": "assets/1607961353.7332714_GSK_t.jpeg",
      "service_provider_poc": {
        "employee_id": 633,
        "email_address": "aadebola@sbmcorp.com",
        "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
        "name": "Ahmed Adebola",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 51519,
        "email_address": "edward.c.harsen@gsk.com",
        "photo_url": "",
        "name": "Edward Harsen",
        "receives_emails": false
      },
      "overall_score": 4.61,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 3.88
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.13
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.88
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 2,
      "customer_id": 470,
      "customer_name": "IQVIA (CBRE)",
      "customer_logo_url": "assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
      "service_provider_poc": {
        "employee_id": 14445,
        "email_address": "jhartz@sbmcorp.com",
        "photo_url": "assets/ff480aa2d9fd4e238599b6556e0d351e_JimsPic2_t.jpg",
        "name": "James Hartz",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 68891,
        "email_address": "Gilbert.Joseph@iqvia.com",
        "photo_url": "",
        "name": "Joseph Gilbert",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 63,
      "customer_name": "Juniper",
      "customer_logo_url": "assets/1605624533.566394_JN_t.png",
      "service_provider_poc": {
        "employee_id": 6727,
        "email_address": "esanchez1@sbmcorp.com",
        "photo_url": "assets/a686535912b44ebca3bc8933686b93e9_jhfgyuf_t.PNG",
        "name": "Enrique Sanchez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 64124,
        "email_address": "mloza@juniper.net",
        "photo_url": "",
        "name": "Martha Loza",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 4,
      "customer_id": 2240,
      "customer_name": "Lam Research (CBRE)",
      "customer_logo_url": "assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
      "service_provider_poc": {
        "employee_id": 6257,
        "email_address": "mschlerf@sbmcorp.com",
        "photo_url": "assets/r5650e02bde4045fbbfdfbb393a84f241_Mark_headshot_green2_t.jpg",
        "name": "Mark Schlerf",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 96120,
        "email_address": "william.encinas@cbre.com",
        "photo_url": "",
        "name": "William Encinas",
        "receives_emails": true
      },
      "overall_score": 4.63,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.625
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 17,
      "customer_id": 220,
      "customer_name": "McKesson (CBRE)",
      "customer_logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
      "service_provider_poc": {
        "employee_id": 53748,
        "email_address": "zshock@sbmcorp.com",
        "photo_url": "assets/re414fb84d6864b0c89361fa3f7846b16_Me_t.jpg",
        "name": "Zachary Shock",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 83641,
        "email_address": "Marissa.Anderson@McKesson.com",
        "photo_url": "",
        "name": "Marissa Anderson",
        "receives_emails": true
      },
      "overall_score": 4.98,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.944444444444445
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.85
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 6,
      "customer_id": 267,
      "customer_name": "Merck (CBRE)",
      "customer_logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
      "service_provider_poc": {
        "employee_id": 37079,
        "email_address": "jrosa@sbmcorp.com",
        "photo_url": "assets/b845d0960f214a4b8df444745692370c_2023Headshot_t.jpg",
        "name": "Jenna Rosa",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 19788,
        "email_address": "michon.blake@merck.com",
        "photo_url": "",
        "name": "Michon Blake",
        "receives_emails": false
      },
      "overall_score": 4.99,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.833333333333333
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 7,
      "customer_id": 1422,
      "customer_name": "Meta (CBRE)",
      "customer_logo_url": "assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
      "service_provider_poc": {
        "employee_id": 21850,
        "email_address": "lduarte@sbmcorp.com",
        "photo_url": "assets/1597765547.152738_Lunaphoto_t.jpg",
        "name": "Luna Duarte",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 80336,
        "email_address": "aworth@meta.com",
        "photo_url": "",
        "name": "Alex Worth",
        "receives_emails": true
      },
      "overall_score": 4.96,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.875
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.875
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 18,
      "customer_id": 8,
      "customer_name": "Microsoft (CBRE)",
      "customer_logo_url": "assets/1605312306.0531929_Artboard4_t.png",
      "service_provider_poc": {
        "employee_id": 3744,
        "email_address": "sfollmann@sbmcorp.com",
        "photo_url": "assets/1587505724.8169758_ShaneFollmann_t.jpg",
        "name": "Shane Follmann",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 43187,
        "email_address": "v-robsin@microsoft.com",
        "photo_url": "",
        "name": "BJ Singletary",
        "receives_emails": false
      },
      "overall_score": 4.93,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.8954545454545455
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.875
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.925
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.895833333333333
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.833333333333333
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.696153846153846
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 5,
      "customer_name": "Nvidia (CBRE)",
      "customer_logo_url": "assets/1605311860.3280911_Artboard10_t.png",
      "service_provider_poc": {
        "employee_id": 4724,
        "email_address": "jmontoya@sbmcorp.com",
        "photo_url": "af402568-7938-cb54-9138-9dc8b6e9f24d.png",
        "name": "Jesus Montoya",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 22290,
        "email_address": "abagdasaryan@nvidia.com",
        "photo_url": "",
        "name": "Arman Bagdasaryan",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 477,
      "customer_name": "Organon (CBRE)",
      "customer_logo_url": "assets/bd52cc204125445db6a196cad602f4ca_Organon_t.png",
      "service_provider_poc": {
        "employee_id": 10215,
        "email_address": "yluciano@sbmcorp.com",
        "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
        "name": "Yaritza Luciano-Lopez",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 94973,
        "email_address": "jeremy.foglietta@organon.com",
        "photo_url": "",
        "name": "Allen Foglietta",
        "receives_emails": true
      },
      "overall_score": 4.84,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.25
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 2,
      "customer_id": 438,
      "customer_name": "Pratt & Whitney (CBRE)",
      "customer_logo_url": "assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
      "service_provider_poc": {
        "employee_id": 50588,
        "email_address": "ahileman@sbmcorp.com",
        "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
        "name": "Angela Hileman",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 46721,
        "email_address": "elaine.armstrong@cbre.com",
        "photo_url": "",
        "name": "Elaine Armstrong",
        "receives_emails": true
      },
      "overall_score": 4.17,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 3
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 3.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 311,
      "customer_name": "Siemens (CBRE)",
      "customer_logo_url": "assets/1607637318.9761856_Siemens_t.png",
      "service_provider_poc": {
        "employee_id": 4526,
        "email_address": "jrodriguez4@sbmcorp.com",
        "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
        "name": "Juan Rodriguez McLean",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 41003,
        "email_address": "Bob.Flint@cbre.com",
        "photo_url": "",
        "name": "Bob Flint",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 504,
      "customer_name": "Sikorsky (CBRE)",
      "customer_logo_url": "assets/ebf7179e73d34ec99956aa4f54bd6943_Image_Editor5_t.png",
      "service_provider_poc": {
        "employee_id": 90442,
        "email_address": "",
        "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
        "name": "Melody Batule",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 47432,
        "email_address": "junior.belfort@cbre.com",
        "photo_url": "",
        "name": "Junior Belfort",
        "receives_emails": true
      },
      "overall_score": 4.23,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 2.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 3.75
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 13,
      "customer_id": 485,
      "customer_name": "State Farm (CBRE)",
      "customer_logo_url": "assets/bceb470097b94120918c0fc266d302e1_11_t.png",
      "service_provider_poc": {
        "employee_id": 36177,
        "email_address": "bearls@sbmcorp.com",
        "photo_url": "assets/1591197101.0470648_Approachable_t.jpg",
        "name": "Brandy Earls",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 42911,
        "email_address": "glenn.carreathers@cbre.com",
        "photo_url": "",
        "name": "Glenn Carreathers",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 1648,
      "customer_name": "TGS",
      "customer_logo_url": "assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg",
      "service_provider_poc": {
        "employee_id": 49618,
        "email_address": "anagarcia@sbmcorp.com",
        "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
        "name": "Ana Garcia",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 95231,
        "email_address": "danielle@tgscm.com",
        "photo_url": "",
        "name": "Danielle Hunter",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 7,
      "customer_id": 180,
      "customer_name": "Takeda (CBRE)",
      "customer_logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
      "service_provider_poc": {
        "employee_id": 5102,
        "email_address": "lcabrera@Sbmcorp.com",
        "photo_url": "assets/1577824247.6788175_LuisCabreraHeadshot_07252019_t.jpg",
        "name": "Luis Cabrera",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 95701,
        "email_address": "edward.mccarthy@cbre.com",
        "photo_url": "",
        "name": "Edward Mccarthy",
        "receives_emails": true
      },
      "overall_score": 4.68,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.5625
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.8125
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.65
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.65
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.8
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.55
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.526
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.876
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 921,
      "customer_name": "Tenaya(CBRE)",
      "customer_logo_url": "assets/a68fea5f3e804ce2b1571142f2a6fa11_tenaya_therapeutics_logo_t.jpeg",
      "service_provider_poc": {
        "employee_id": 76717,
        "email_address": "rtorres@sbmcorp.com",
        "photo_url": "assets/38c51e476653467fb0993184c3c0f71d_thumbnail_IMG_8688_t.jpg",
        "name": "Roberto Torres",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 77040,
        "email_address": "andrew.cusack@cbre.com",
        "photo_url": "",
        "name": "Andrew Cusack",
        "receives_emails": false
      },
      "overall_score": 4,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 3,
      "customer_id": 303,
      "customer_name": "Uber (CBRE)",
      "customer_logo_url": "assets/1607636171.7063708_Uber_t.jpg",
      "service_provider_poc": {
        "employee_id": 83565,
        "email_address": "jesquivel2@sbmcorp.com",
        "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
        "name": "Jose Esquivel Calderon",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 49391,
        "email_address": "esolae@ext.uber.com",
        "photo_url": "",
        "name": "Eduardo Solaeche",
        "receives_emails": false
      },
      "overall_score": 4.89,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.583333333333333
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.333333333333333
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 1,
      "customer_id": 2155,
      "customer_name": "VMware (CBRE) (SG)",
      "customer_logo_url": 0,
      "service_provider_poc": {
        "employee_id": 36751,
        "email_address": "ppkyeow@sbmcorp.com",
        "photo_url": "assets/6d9ddd5675634f8c9fd7db3f283caabe_PhilipPhua_t.jpeg",
        "name": "PHUA KOK YEOW",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 88630,
        "email_address": "michellen@vmware.com",
        "photo_url": "",
        "name": "Wong Michelle",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 2,
      "customer_id": 803,
      "customer_name": "Vantiva (CBRE)",
      "customer_logo_url": "assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
      "service_provider_poc": {
        "employee_id": 47231,
        "email_address": "rblackwood@sbmcorp.com",
        "photo_url": "assets/1619727421.457733_pic_t.jpg",
        "name": "Ryan Blackwood",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 70219,
        "email_address": "lonnie.davis@cbre.com",
        "photo_url": "",
        "name": "Lonnie Davis",
        "receives_emails": true
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 24,
      "customer_id": 87,
      "customer_name": "Wells Fargo",
      "customer_logo_url": "assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
      "service_provider_poc": {
        "employee_id": 11598,
        "email_address": "mledezma@sbmcorp.com",
        "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
        "name": "Miriam Ledezma",
        "receives_emails": true
      },
      "customer_poc": {
        "employee_id": 78718,
        "email_address": "james.dunnican@wellsfargo.com",
        "photo_url": "",
        "name": "James Dunnican",
        "receives_emails": true
      },
      "overall_score": 4.8,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 4.638888888888889
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 4.722222222222222
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 4.916666666666667
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 4.888888888888889
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 4.75
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 4.653846153846154
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 4.923076923076923
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 4.730769230769231
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 4.769230769230769
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 4.884615384615385
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 4.916666666666667
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 4.822916666666667
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    },
    {
      "number_of_contracts": 8,
      "customer_id": 1649,
      "customer_name": "Wells Fargo-Sub",
      "customer_logo_url": "assets/9557cea797cf44f1bcda03aff5231fdf_1_t.png",
      "service_provider_poc": {
        "employee_id": 11598,
        "email_address": "mledezma@sbmcorp.com",
        "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
        "name": "Miriam Ledezma",
        "receives_emails": false
      },
      "customer_poc": {
        "employee_id": 78718,
        "email_address": "james.dunnican@wellsfargo.com",
        "photo_url": "",
        "name": "James Dunnican",
        "receives_emails": false
      },
      "overall_score": 5,
      "overall_score_list": [
        {
          "month": "2023-03-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-04-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-05-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-06-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-07-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-08-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-09-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-10-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-11-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2023-12-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-01-01T00:00:00+00:00",
          "overall_score": 0
        },
        {
          "month": "2024-02-01T00:00:00+00:00",
          "overall_score": 5
        },
        {
          "month": "2024-03-01T00:00:00+00:00",
          "overall_score": 0
        }
      ]
    }
  ]

  export const cbreComplaints = {
    "chart": "GetComplaintsTrends",
    "number": "1",
    "results": [
        {
            "mo": "2023-03-01T00:00:00",
            "in_scope": 90,
            "out_of_scope": 35
        },
        {
            "mo": "2023-04-01T00:00:00",
            "in_scope": 56,
            "out_of_scope": 21
        },
        {
            "mo": "2023-05-01T00:00:00",
            "in_scope": 73,
            "out_of_scope": 30
        },
        {
            "mo": "2023-06-01T00:00:00",
            "in_scope": 72,
            "out_of_scope": 28
        },
        {
            "mo": "2023-07-01T00:00:00",
            "in_scope": 86,
            "out_of_scope": 35
        },
        {
            "mo": "2023-08-01T00:00:00",
            "in_scope": 83,
            "out_of_scope": 64
        },
        {
            "mo": "2023-09-01T00:00:00",
            "in_scope": 69,
            "out_of_scope": 47
        },
        {
            "mo": "2023-10-01T00:00:00",
            "in_scope": 107,
            "out_of_scope": 34
        },
        {
            "mo": "2023-11-01T00:00:00",
            "in_scope": 62,
            "out_of_scope": 12
        },
        {
            "mo": "2023-12-01T00:00:00",
            "in_scope": 61,
            "out_of_scope": 13
        },
        {
            "mo": "2024-01-01T00:00:00",
            "in_scope": 99,
            "out_of_scope": 22
        },
        {
            "mo": "2024-02-01T00:00:00",
            "in_scope": 72,
            "out_of_scope": 12
        }
    ],
    "most_recent_record": "2024-03-01T04:15:13.678000Z",
    "time_scale": "month"
}

  export const cbreEscalatedRepeat = {
    "chart": "GetComplaintCountsByMonth",
    "number": "7",
    "results": [
        {
            "mo": "2023-03-01T00:00:00",
            "total": 125,
            "total_is_escalated": 7,
            "total_is_repeat_complaint": 7
        },
        {
            "mo": "2023-04-01T00:00:00",
            "total": 77,
            "total_is_escalated": 3,
            "total_is_repeat_complaint": 3
        },
        {
            "mo": "2023-05-01T00:00:00",
            "total": 103,
            "total_is_escalated": 3,
            "total_is_repeat_complaint": 10
        },
        {
            "mo": "2023-06-01T00:00:00",
            "total": 100,
            "total_is_escalated": 4,
            "total_is_repeat_complaint": 12
        },
        {
            "mo": "2023-07-01T00:00:00",
            "total": 121,
            "total_is_escalated": 4,
            "total_is_repeat_complaint": 11
        },
        {
            "mo": "2023-08-01T00:00:00",
            "total": 147,
            "total_is_escalated": 11,
            "total_is_repeat_complaint": 12
        },
        {
            "mo": "2023-09-01T00:00:00",
            "total": 116,
            "total_is_escalated": 3,
            "total_is_repeat_complaint": 7
        },
        {
            "mo": "2023-10-01T00:00:00",
            "total": 141,
            "total_is_escalated": 7,
            "total_is_repeat_complaint": 22
        },
        {
            "mo": "2023-11-01T00:00:00",
            "total": 74,
            "total_is_escalated": 7,
            "total_is_repeat_complaint": 5
        },
        {
            "mo": "2023-12-01T00:00:00",
            "total": 74,
            "total_is_escalated": 4,
            "total_is_repeat_complaint": 5
        },
        {
            "mo": "2024-01-01T00:00:00",
            "total": 121,
            "total_is_escalated": 9,
            "total_is_repeat_complaint": 12
        },
        {
            "mo": "2024-02-01T00:00:00",
            "total": 84,
            "total_is_escalated": 3,
            "total_is_repeat_complaint": 7
        }
    ],
    "most_recent_record": "2024-03-01T04:15:13.678000Z",
    "time_scale": "month"
}

export const cbreIncidents = {
  "chart": "GetIncidentTypesByMonth",
  "number": "10",
  "results": [
      {
          "mo": "2023-03-01T00:00:00",
          "first_aid": 7,
          "recordable": 3,
          "report_only": 18,
          "near_miss": 1
      },
      {
          "mo": "2023-04-01T00:00:00",
          "first_aid": 10,
          "recordable": 1,
          "report_only": 14,
          "near_miss": 2
      },
      {
          "mo": "2023-05-01T00:00:00",
          "first_aid": 7,
          "recordable": 1,
          "report_only": 14,
          "near_miss": 2
      },
      {
          "mo": "2023-06-01T00:00:00",
          "first_aid": 8,
          "recordable": 2,
          "report_only": 14,
          "near_miss": 1
      },
      {
          "mo": "2023-07-01T00:00:00",
          "first_aid": 2,
          "recordable": 1,
          "report_only": 11,
          "near_miss": 0
      },
      {
          "mo": "2023-08-01T00:00:00",
          "first_aid": 6,
          "recordable": 3,
          "report_only": 18,
          "near_miss": 2
      },
      {
          "mo": "2023-09-01T00:00:00",
          "first_aid": 5,
          "recordable": 0,
          "report_only": 7,
          "near_miss": 3
      },
      {
          "mo": "2023-10-01T00:00:00",
          "first_aid": 8,
          "recordable": 4,
          "report_only": 6,
          "near_miss": 2
      },
      {
          "mo": "2023-11-01T00:00:00",
          "first_aid": 9,
          "recordable": 3,
          "report_only": 14,
          "near_miss": 0
      },
      {
          "mo": "2023-12-01T00:00:00",
          "first_aid": 5,
          "recordable": 1,
          "report_only": 11,
          "near_miss": 2
      },
      {
          "mo": "2024-01-01T00:00:00",
          "first_aid": 4,
          "recordable": 1,
          "report_only": 12,
          "near_miss": 2
      },
      {
          "mo": "2024-02-01T00:00:00",
          "first_aid": 4,
          "recordable": 0,
          "report_only": 15,
          "near_miss": 2
      }
  ],
  "most_recent_record": "2024-02-29T13:00:00Z",
  "time_scale": "month"
}

export const cbreSafetyClients = {
  "chart": "GetRecordableCountsByClient",
  "number": "30",
  "results": [
      {
          "id": 1422,
          "name": "Meta (CBRE)",
          "number_of_recordables": 5,
          "number_of_incidents": 70,
          "latest_recordable_date": "2023-11-15T10:15:00Z",
          "trir": 1.99
      },
      {
          "id": 72,
          "name": "Eli Lilly (CBRE)",
          "number_of_recordables": 3,
          "number_of_incidents": 93,
          "latest_recordable_date": "2023-10-18T20:00:00Z",
          "trir": 1.14
      },
      {
          "id": 87,
          "name": "Wells Fargo",
          "number_of_recordables": 3,
          "number_of_incidents": 48,
          "latest_recordable_date": "2024-01-31T00:40:00Z",
          "trir": 1.46
      },
      {
          "id": 8,
          "name": "Microsoft (CBRE)",
          "number_of_recordables": 2,
          "number_of_incidents": 145,
          "latest_recordable_date": "2023-10-30T16:30:00Z",
          "trir": 0.5
      },
      {
          "id": 438,
          "name": "Pratt & Whitney (CBRE)",
          "number_of_recordables": 2,
          "number_of_incidents": 12,
          "latest_recordable_date": "2023-08-09T17:43:00Z",
          "trir": 5.62
      },
      {
          "id": 219,
          "name": "Cigna (CBRE)",
          "number_of_recordables": 1,
          "number_of_incidents": 10,
          "latest_recordable_date": "2023-10-06T00:55:00Z",
          "trir": 1.49
      },
      {
          "id": 468,
          "name": "Deutsche Bank",
          "number_of_recordables": 1,
          "number_of_incidents": 10,
          "latest_recordable_date": "2023-08-26T01:00:00Z",
          "trir": 2.11
      },
      {
          "id": 267,
          "name": "Merck (CBRE)",
          "number_of_recordables": 1,
          "number_of_incidents": 44,
          "latest_recordable_date": "2023-12-14T13:45:00Z",
          "trir": 0.7
      },
      {
          "id": 137,
          "name": "GM",
          "number_of_recordables": 1,
          "number_of_incidents": 13,
          "latest_recordable_date": "2023-07-26T10:55:00Z",
          "trir": 2
      },
      {
          "id": 5,
          "name": "Nvidia (CBRE)",
          "number_of_recordables": 1,
          "number_of_incidents": 10,
          "latest_recordable_date": "2023-05-02T09:10:00Z",
          "trir": 1.45
      },
      {
          "id": 280,
          "name": "Adient",
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2019-10-13T00:00:00Z",
          "trir": 0
      },
      {
          "id": 281,
          "name": "ASML (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2020-03-04T23:59:59Z",
          "trir": 0
      },
      {
          "id": 303,
          "name": "Uber (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 11,
          "latest_recordable_date": "2020-07-24T00:00:00Z",
          "trir": 0
      },
      {
          "id": 311,
          "name": "Siemens (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2020-09-08T00:00:00Z",
          "trir": 0
      },
      {
          "id": 315,
          "name": "Ford",
          "number_of_recordables": 0,
          "number_of_incidents": 27,
          "latest_recordable_date": "2023-01-10T18:06:00Z",
          "trir": 0
      },
      {
          "id": 352,
          "name": "Covestro (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2021-01-07T08:00:00Z",
          "trir": 0
      },
      {
          "id": 470,
          "name": "IQVIA (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2021-06-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 477,
          "name": "Organon (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2021-08-25T22:26:53Z",
          "trir": 0
      },
      {
          "id": 485,
          "name": "State Farm (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2021-10-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 504,
          "name": "Sikorsky (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2020-04-10T08:00:00Z",
          "trir": 0
      },
      {
          "id": 803,
          "name": "Vantiva (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2022-04-01T19:10:39Z",
          "trir": 0
      },
      {
          "id": 921,
          "name": "Tenaya(CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2022-03-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 1018,
          "name": "Chubb Insurance (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2022-04-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 1547,
          "name": "Collins Aerospace (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2022-06-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 1648,
          "name": "TGS",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2023-02-21T00:00:00Z",
          "trir": 0
      },
      {
          "id": 1649,
          "name": "Wells Fargo-Sub",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2019-08-28T00:00:00Z",
          "trir": 0
      },
      {
          "id": 2145,
          "name": "Coca Cola",
          "number_of_recordables": 0,
          "number_of_incidents": 9,
          "latest_recordable_date": "2023-07-01T15:35:36Z",
          "trir": 0
      },
      {
          "id": 2154,
          "name": "Cigna (CBRE) - Sub",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2022-06-14T00:00:00Z",
          "trir": 0
      },
      {
          "id": 2155,
          "name": "VMware (CBRE) (SG)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2023-07-31T17:48:50Z",
          "trir": 0
      },
      {
          "id": 2240,
          "name": "Lam Research (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": "2024-01-30T00:00:00Z",
          "trir": 0
      },
      {
          "id": 33,
          "name": "GSK (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": "2021-10-15T08:15:00Z",
          "trir": 0
      },
      {
          "id": 63,
          "name": "Juniper",
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": "2019-11-15T07:30:00Z",
          "trir": 0
      },
      {
          "id": 75,
          "name": "AbbVie",
          "number_of_recordables": 0,
          "number_of_incidents": 13,
          "latest_recordable_date": "2022-09-13T23:45:00Z",
          "trir": 0
      },
      {
          "id": 90,
          "name": "Clorox (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2016-04-07T14:00:00Z",
          "trir": 0
      },
      {
          "id": 180,
          "name": "Takeda (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 28,
          "latest_recordable_date": "2018-07-01T00:00:00Z",
          "trir": 0
      },
      {
          "id": 220,
          "name": "McKesson (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 12,
          "latest_recordable_date": "2022-11-21T19:45:00Z",
          "trir": 0
      },
      {
          "id": 253,
          "name": "Biogen (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 44,
          "latest_recordable_date": "2021-08-12T14:00:00Z",
          "trir": 0
      },
      {
          "id": 269,
          "name": "Chemours (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": "2022-04-20T17:13:00Z",
          "trir": 0
      },
      {
          "id": 273,
          "name": "Elanco (CBRE)",
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": "2012-10-01T23:59:59Z",
          "trir": 0
      }
  ],
  "most_recent_record": "2024-02-29T13:00:00Z"
}

export const cbreSafetySites = {
  "chart": "GetRecordableCountsBySite",
  "number": "25",
  "results": [
      {
          "id": 1469,
          "name": "Indianapolis, IN",
          "customer_id": 72,
          "customer_name": "Eli Lilly (CBRE)",
          "contract_start_date": "2020-10-09T23:59:59Z",
          "manager_contact": {
              "last_name": "Brake",
              "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Sara",
              "employee_id": 8763
          },
          "number_of_recordables": 3,
          "number_of_incidents": 92,
          "latest_recordable_date": "2023-10-18T20:00:00Z",
          "trir": 1.24
      },
      {
          "id": 1323,
          "name": "St. Louis, MO",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2009-09-30T23:59:59Z",
          "manager_contact": {
              "last_name": "Cowan",
              "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Damian",
              "employee_id": 77207
          },
          "number_of_recordables": 2,
          "number_of_incidents": 7,
          "latest_recordable_date": "2024-01-31T00:40:00Z",
          "trir": 5.65
      },
      {
          "id": 3996,
          "name": "Bayfront",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Vazquez",
              "photo_url": "assets/c485321de6cb4f738e5ab1dab3b42111_IMG_0011_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Luis",
              "employee_id": 74889
          },
          "number_of_recordables": 2,
          "number_of_incidents": 18,
          "latest_recordable_date": "2023-11-14T08:28:00Z",
          "trir": 2.11
      },
      {
          "id": 3851,
          "name": "Classic",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Patino Corona",
              "photo_url": "assets/92becf9e71ba4cedae28ec54872ac577_ProfilePic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Joel",
              "employee_id": 85280
          },
          "number_of_recordables": 2,
          "number_of_incidents": 26,
          "latest_recordable_date": "2023-10-30T22:15:00Z",
          "trir": 4.13
      },
      {
          "id": 3992,
          "name": "Burlingame",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Fultcher",
              "photo_url": "assets/ec180ddc59c54d4b8cd491c337f50852_DarrylFultcher_t.jpg",
              "positions": [
                  {
                      "id": 45,
                      "name": "Asst Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Darryl",
              "employee_id": 74852
          },
          "number_of_recordables": 1,
          "number_of_incidents": 14,
          "latest_recordable_date": "2023-11-15T10:15:00Z",
          "trir": 3.24
      },
      {
          "id": 5511,
          "name": "3rd - Brannan",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2021-08-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Romero",
              "photo_url": "assets/d2948913035e4974a865fdc6a23b9d03_1000003743_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Victor",
              "employee_id": 91379
          },
          "number_of_recordables": 1,
          "number_of_incidents": 1,
          "latest_recordable_date": "2023-08-07T19:15:00Z",
          "trir": 134.47
      },
      {
          "id": 5714,
          "name": "New York",
          "customer_id": 468,
          "customer_name": "Deutsche Bank",
          "contract_start_date": "2023-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Vasquez Rosado",
              "photo_url": "assets/69023b7a3148465f9d076de3dc1a02cd_4insightprofilepic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Gabriel",
              "employee_id": 51639
          },
          "number_of_recordables": 1,
          "number_of_incidents": 9,
          "latest_recordable_date": "2023-08-26T01:00:00Z",
          "trir": 3.75
      },
      {
          "id": 2445,
          "name": "Jupiter, FL",
          "customer_id": 438,
          "customer_name": "Pratt & Whitney (CBRE)",
          "contract_start_date": "2021-03-31T23:59:59Z",
          "manager_contact": {
              "last_name": "Batule",
              "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Melody",
              "employee_id": 90442
          },
          "number_of_recordables": 1,
          "number_of_incidents": 8,
          "latest_recordable_date": "2023-08-09T17:43:00Z",
          "trir": 4.04
      },
      {
          "id": 7,
          "name": "Puget Sound, WA (CBRE)",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2017-12-07T23:59:59Z",
          "manager_contact": {
              "last_name": "Duncan",
              "photo_url": "assets/53251f594e4d4b539212bfd353eec134_5.24_t.2023",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Steven",
              "employee_id": 80157
          },
          "number_of_recordables": 1,
          "number_of_incidents": 112,
          "latest_recordable_date": "2023-10-30T16:30:00Z",
          "trir": 0.34
      },
      {
          "id": 4836,
          "name": "Asheville, NC*",
          "customer_id": 438,
          "customer_name": "Pratt & Whitney (CBRE)",
          "contract_start_date": "2023-12-27T20:26:33Z",
          "manager_contact": {
              "last_name": "Ellsworth",
              "photo_url": "assets/rd8a79925d61a40fa8201f128ea42a03c_IMG_11081_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Lisa",
              "employee_id": 91431
          },
          "number_of_recordables": 1,
          "number_of_incidents": 4,
          "latest_recordable_date": "2023-03-13T15:00:00Z",
          "trir": 9.26
      },
      {
          "id": 1995,
          "name": "Puget Sound, WA (Compass)",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2018-01-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Graham",
              "photo_url": "assets/b47c89977e474321b37bcccd8329d283_IMG_9086_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jimmy",
              "employee_id": 1440
          },
          "number_of_recordables": 1,
          "number_of_incidents": 18,
          "latest_recordable_date": "2023-03-31T07:15:00Z",
          "trir": 2.49
      },
      {
          "id": 1,
          "name": "Milford PG, MI",
          "customer_id": 137,
          "customer_name": "GM",
          "contract_start_date": "2017-04-07T23:59:59Z",
          "manager_contact": {
              "last_name": "Schermerhorn",
              "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Pauline",
              "employee_id": 9243
          },
          "number_of_recordables": 1,
          "number_of_incidents": 13,
          "latest_recordable_date": "2023-07-26T10:55:00Z",
          "trir": 2
      },
      {
          "id": 839,
          "name": "Santa Clara, CA",
          "customer_id": 5,
          "customer_name": "Nvidia (CBRE)",
          "contract_start_date": "2017-12-18T23:59:59Z",
          "manager_contact": {
              "last_name": "Montoya",
              "photo_url": "af402568-7938-cb54-9138-9dc8b6e9f24d.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jesus",
              "employee_id": 4724
          },
          "number_of_recordables": 1,
          "number_of_incidents": 10,
          "latest_recordable_date": "2023-05-02T09:10:00Z",
          "trir": 1.45
      },
      {
          "id": 1490,
          "name": "West Point, PA",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2010-07-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Botto",
              "photo_url": "192869d8-cac2-8b12-a124-85d67f827795.png",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Paul",
              "employee_id": 2988
          },
          "number_of_recordables": 1,
          "number_of_incidents": 26,
          "latest_recordable_date": "2023-12-14T13:45:00Z",
          "trir": 1.01
      },
      {
          "id": 1962,
          "name": "Bloomfield",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-08-26T07:00:00Z",
          "manager_contact": {
              "last_name": "Bianchi",
              "photo_url": "assets/1612299821.4215329_MITPhoto_t.jpg",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Julie",
              "employee_id": 43919
          },
          "number_of_recordables": 1,
          "number_of_incidents": 4,
          "latest_recordable_date": "2023-10-06T00:55:00Z",
          "trir": 5.5
      },
      {
          "id": 1673,
          "name": "Sioux Falls OPS",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2018-10-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Hockersmith",
              "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Michelle",
              "employee_id": 5851
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1674,
          "name": "Denver",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2021-08-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Vega",
              "photo_url": "assets/5c6481735bfc43c3bc29c5a4ce7ac31a_15aa73416e134c0ba83abf6633db4530_mariavega_t_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Maria",
              "employee_id": 6407
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1766,
          "name": "Pleasanton",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2019-02-11T00:00:00Z",
          "manager_contact": {
              "last_name": "Mayorquin",
              "photo_url": "assets/rd4e3af53a4c840108b684e90fac49b44_Tedspicture_t.png",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Teodoro",
              "employee_id": 43756
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1768,
          "name": "Oak Hill, NY",
          "customer_id": 33,
          "customer_name": "GSK (CBRE)",
          "contract_start_date": "2019-03-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Adebola",
              "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  },
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  },
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ahmed",
              "employee_id": 633
          },
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": "2021-10-15T08:15:00Z",
          "trir": 0
      },
      {
          "id": 1798,
          "name": "Branchburg",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2019-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Lawson",
              "photo_url": "assets/ef1d853c11aa42f3926c4f637e27e726_me_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Iris",
              "employee_id": 77361
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1800,
          "name": "Arrowhead",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1801,
          "name": "Red Mountain",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T20:31:33Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1802,
          "name": "North Valley",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T15:08:23Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1803,
          "name": "Palm Valley",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T20:25:15Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1805,
          "name": "Queen Creek",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1806,
          "name": "San Tan",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T20:21:59Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1807,
          "name": "Paradise Valley",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1808,
          "name": "CJ Harris",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1809,
          "name": "Chandler",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T23:59:59Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "",
              "positions": [
                  {
                      "id": 41,
                      "name": "Asst Mgr, Operations",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 61013
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1810,
          "name": "Phoenix Central",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1811,
          "name": "Paseo",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1812,
          "name": "Westridge",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1813,
          "name": "Stapley",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1814,
          "name": "Scottsdale (Skysong)",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1815,
          "name": "Norte",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1816,
          "name": "South Mountain",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-29T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1818,
          "name": "Sun City West",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T13:54:25Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1819,
          "name": "Sun City",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-07-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2020-07-18T02:45:00Z",
          "trir": 0
      },
      {
          "id": 1823,
          "name": "Cincinnati",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2019-07-23T23:59:59Z",
          "manager_contact": {
              "last_name": "Nunez",
              "photo_url": "assets/b5a64e4995664b52b8854d61a05c571c_707385932B0E4C3EAD704C042619EE64_t.jpeg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Juan",
              "employee_id": 82635
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1880,
          "name": "Indy",
          "customer_id": 273,
          "customer_name": "Elanco (CBRE)",
          "contract_start_date": "2020-01-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Vargas",
              "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Arturo",
              "employee_id": 10657
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1955,
          "name": "Cambridge",
          "customer_id": 253,
          "customer_name": "Biogen (CBRE)",
          "contract_start_date": "2018-12-22T00:00:00Z",
          "manager_contact": {
              "last_name": "Guerrero",
              "photo_url": "db7fd315-1467-ad1b-98f6-ceaf8ce28800.png",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Lisangela",
              "employee_id": 862
          },
          "number_of_recordables": 0,
          "number_of_incidents": 5,
          "latest_recordable_date": "2021-08-12T14:00:00Z",
          "trir": 0
      },
      {
          "id": 1956,
          "name": "Madison, NJ",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2019-01-14T23:59:59Z",
          "manager_contact": {
              "last_name": "Rodriguez",
              "photo_url": "b54fe224-fb8f-b632-ea1d-8749616cbce1.png",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "John",
              "employee_id": 15291
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1968,
          "name": "Jacksonville",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2019-08-31T00:00:00Z",
          "manager_contact": {
              "last_name": "lawless",
              "photo_url": "",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donavan",
              "employee_id": 15343
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1974,
          "name": "Michigan (CBRE)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2019-08-28T00:00:00Z",
          "manager_contact": {
              "last_name": "Zahedi",
              "photo_url": "7a9bd5dc-1f71-4a3a-9360-5bed93f51c3f.png",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Omid",
              "employee_id": 6642
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1997,
          "name": "Newark, DE",
          "customer_id": 269,
          "customer_name": "Chemours (CBRE)",
          "contract_start_date": "2019-12-13T07:00:00Z",
          "manager_contact": {
              "last_name": "Hasen",
              "photo_url": "assets/rc5411d9285f940bc8b60cc28527147f4_Resized_20231003_133950_t.jpeg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Mark",
              "employee_id": 88984
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": "2022-04-20T17:13:00Z",
          "trir": 0
      },
      {
          "id": 2016,
          "name": "Clinton",
          "customer_id": 273,
          "customer_name": "Elanco (CBRE)",
          "contract_start_date": "2018-01-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Brake",
              "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  },
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Sara",
              "employee_id": 8763
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2017,
          "name": "Greenfield, IN",
          "customer_id": 273,
          "customer_name": "Elanco (CBRE)",
          "contract_start_date": "2012-10-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Vargas",
              "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Arturo",
              "employee_id": 10657
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2055,
          "name": "Waco, TX",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2020-02-17T00:00:00Z",
          "manager_contact": {
              "last_name": "Reyna",
              "photo_url": "assets/fc2b090f50eb4fa3819079dc693004ee_StandardFemale111_t.png",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Anna",
              "employee_id": 69532
          },
          "number_of_recordables": 0,
          "number_of_incidents": 7,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2069,
          "name": "Irving, TX",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2019-10-06T23:59:59Z",
          "manager_contact": {
              "last_name": "Romero",
              "photo_url": "1038ca6c-e7e4-de20-de01-1253b4c2891a.png",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Carlos",
              "employee_id": 10000
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2071,
          "name": "Plymouth",
          "customer_id": 280,
          "customer_name": "Adient",
          "contract_start_date": "2019-10-13T00:00:00Z",
          "manager_contact": {
              "last_name": "Estes",
              "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Danielle",
              "employee_id": 250
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2072,
          "name": "San Diego, CA",
          "customer_id": 281,
          "customer_name": "ASML (CBRE)",
          "contract_start_date": "2020-03-04T23:59:59Z",
          "manager_contact": {
              "last_name": "De La Rosa",
              "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 86586
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2087,
          "name": "Campus - MN",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-05-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 5,
          "latest_recordable_date": "2017-03-03T15:00:00Z",
          "trir": 0
      },
      {
          "id": 2088,
          "name": "East Town - MN",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-05-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 7,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2120,
          "name": "Des Moines, IA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2020-05-14T00:00:00Z",
          "manager_contact": {
              "last_name": "Wolff",
              "photo_url": "assets/1598794289.4717073_Jasonpic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jason",
              "employee_id": 40101
          },
          "number_of_recordables": 0,
          "number_of_incidents": 6,
          "latest_recordable_date": "2023-02-16T14:25:00Z",
          "trir": 0
      },
      {
          "id": 2141,
          "name": "Mission Bay",
          "customer_id": 303,
          "customer_name": "Uber (CBRE)",
          "contract_start_date": "2020-07-24T00:00:00Z",
          "manager_contact": {
              "last_name": "Esquivel Calderon",
              "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 83565
          },
          "number_of_recordables": 0,
          "number_of_incidents": 9,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2148,
          "name": "Lincoln Square 2",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2017-12-06T08:00:00Z",
          "manager_contact": {
              "last_name": "Duncan",
              "photo_url": "assets/53251f594e4d4b539212bfd353eec134_5.24_t.2023",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Steven",
              "employee_id": 80157
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2162,
          "name": "Manteno, IL",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2020-09-09T23:59:59Z",
          "manager_contact": {
              "last_name": "Nelson",
              "photo_url": "assets/150f6bf5a30a4ffe811541a5c83c20b9_William_Nelson_29542_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "William",
              "employee_id": 21905
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2163,
          "name": "Harleysville, PA",
          "customer_id": 311,
          "customer_name": "Siemens (CBRE)",
          "contract_start_date": "2020-09-08T00:00:00Z",
          "manager_contact": {
              "last_name": "Rodriguez McLean",
              "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Juan",
              "employee_id": 4526
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2172,
          "name": "Sunnyvale",
          "customer_id": 303,
          "customer_name": "Uber (CBRE)",
          "contract_start_date": "2020-09-14T23:59:59Z",
          "manager_contact": {
              "last_name": "Suarez",
              "photo_url": "assets/1611186503.5467808_profilepic_t.jpg",
              "positions": [
                  {
                      "id": 45,
                      "name": "Asst Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Edgar",
              "employee_id": 44199
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2214,
          "name": "WPE – Wagner Place East – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T00:00:00Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2215,
          "name": "FXC – Ford Experience Center - Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2021-09-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2217,
          "name": "Fairlane North",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2220,
          "name": "DSC 1 & 2",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2221,
          "name": "Fairlane Business Park 5",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2222,
          "name": "DTF – Drivability Test Facility – Allen Park, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:59:59Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2226,
          "name": "TFC – The Factory – Detroit, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T00:00:00Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2252,
          "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2021-09-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2256,
          "name": "Clermont, KY",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2020-09-28T23:59:59Z",
          "manager_contact": {
              "last_name": "Clevenger",
              "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jamie",
              "employee_id": 76561
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2264,
          "name": "Bothell, WA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2020-12-07T23:59:59Z",
          "manager_contact": {
              "last_name": "Taminez",
              "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ivan",
              "employee_id": 52772
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2266,
          "name": "Santa Clara, CA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2020-12-07T23:59:59Z",
          "manager_contact": {
              "last_name": "Alejo",
              "photo_url": "assets/re3e61ca418574a0a9ec8f513223e28df_IMG_2459_t.jpeg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Erickson",
              "employee_id": 45311
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2267,
          "name": "Chicago, IL",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2020-12-07T23:59:59Z",
          "manager_contact": {
              "last_name": "Sims",
              "photo_url": "assets/520fd15308c2468eb566bffa91d7f4af_JonathanSimsPhoto_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jonathan",
              "employee_id": 87845
          },
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2333,
          "name": "Pittsburg",
          "customer_id": 352,
          "customer_name": "Covestro (CBRE)",
          "contract_start_date": "2021-01-07T08:00:00Z",
          "manager_contact": {
              "last_name": "Fick",
              "photo_url": "assets/r148dc71f25494c2cb6fa97f64fe0e9d4_image_50424065_t.JPG",
              "positions": [
                  {
                      "id": 3099,
                      "name": "Manager, Multi Site",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jeremy",
              "employee_id": 89532
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2348,
          "name": "Reno, NV",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2021-02-16T08:00:00Z",
          "manager_contact": {
              "last_name": "Taminez",
              "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ivan",
              "employee_id": 52772
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2022-06-13T18:45:00Z",
          "trir": 0
      },
      {
          "id": 2350,
          "name": "Superstition Springs",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2021-06-04T23:59:59Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2439,
          "name": "Oakland, CA",
          "customer_id": 90,
          "customer_name": "Clorox (CBRE)",
          "contract_start_date": "2021-04-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Mccoy",
              "photo_url": "assets/95a124518db5413abed90f423f9f0786_Headshot_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Artis",
              "employee_id": 52629
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2444,
          "name": "Market Street",
          "customer_id": 303,
          "customer_name": "Uber (CBRE)",
          "contract_start_date": "2023-11-22T18:13:37Z",
          "manager_contact": {
              "last_name": "Esquivel Calderon",
              "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 83565
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2467,
          "name": "Gallatin, TN",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2019-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Sandoval Davila",
              "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jaime",
              "employee_id": 12740
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2471,
          "name": "Cover My Meds Columbus",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-06-04T23:59:59Z",
          "manager_contact": {
              "last_name": "Gonce",
              "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Dan",
              "employee_id": 92415
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2566,
          "name": "Interchange",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-06-01T12:00:00Z",
          "manager_contact": {
              "last_name": "Clevenger",
              "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jamie",
              "employee_id": 76561
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2570,
          "name": "Santa Ana",
          "customer_id": 468,
          "customer_name": "Deutsche Bank",
          "contract_start_date": "2023-07-01T17:45:08Z",
          "manager_contact": {
              "last_name": "Weekly",
              "photo_url": "assets/d957c4d3f71a40e18bff000c58542f84_305137486_5207966462632028_7525157243343718431_n_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ana",
              "employee_id": 79998
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2576,
          "name": "Durham",
          "customer_id": 470,
          "customer_name": "IQVIA (CBRE)",
          "contract_start_date": "2021-06-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Alejo",
              "photo_url": "assets/781c8ed781e941c9ab70e438618e0e22_IMG_4001_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Lenin",
              "employee_id": 69427
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2586,
          "name": "Mason",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-08-20T00:36:12Z",
          "manager_contact": {
              "last_name": "Nunez",
              "photo_url": "assets/b5a64e4995664b52b8854d61a05c571c_707385932B0E4C3EAD704C042619EE64_t.jpeg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Juan",
              "employee_id": 82635
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2589,
          "name": "Roseville",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-07-01T12:00:00Z",
          "manager_contact": {
              "last_name": "Del Rio",
              "photo_url": "assets/2cbc7b25e22345be91eed9e2fbfa8387_Upright2_t.jpg",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Orlando",
              "employee_id": 1757
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2590,
          "name": "Laguna South, WA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2021-08-31T07:00:00Z",
          "manager_contact": {
              "last_name": "Smith",
              "photo_url": "assets/bfa928ca31d046de9010f02cd0dba0c7_Capture_t.JPG",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Paul",
              "employee_id": 855
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2604,
          "name": "Springhouse, PA",
          "customer_id": 477,
          "customer_name": "Organon (CBRE)",
          "contract_start_date": "2021-08-25T22:26:53Z",
          "manager_contact": {
              "last_name": "Luciano-Lopez",
              "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Yaritza",
              "employee_id": 10215
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2608,
          "name": "Ellabell, GA",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-08-16T16:59:22Z",
          "manager_contact": {
              "last_name": "Rodriguez",
              "photo_url": "assets/09b698cde762467e950106dacfaaf750_IMG_2892_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 25558
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2611,
          "name": "Gahanna",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-09-15T17:23:04Z",
          "manager_contact": {
              "last_name": "Gonce",
              "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Dan",
              "employee_id": 92415
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2612,
          "name": "St. Louis (Express Scripts)",
          "customer_id": 219,
          "customer_name": "Cigna (CBRE)",
          "contract_start_date": "2021-07-26T23:59:59Z",
          "manager_contact": {
              "last_name": "Smith",
              "photo_url": "assets/1587576767.6580079_AndySmithFinal_t.png",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Andrew",
              "employee_id": 11682
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": "2021-10-05T17:00:00Z",
          "trir": 0
      },
      {
          "id": 2616,
          "name": "Groveport",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2021-09-16T17:27:57Z",
          "manager_contact": {
              "last_name": "Gonce",
              "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Dan",
              "employee_id": 92415
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2618,
          "name": "Irving, TX",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Sanchez",
              "photo_url": "assets/1619016635.6029003_me_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Rosalia",
              "employee_id": 12233
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2623,
          "name": "Albany, OH",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Murtha",
              "photo_url": "assets/eac1ac2587504314933fc4fc13be5836_patrick_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Patrick",
              "employee_id": 67400
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2624,
          "name": "Malta, NY",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Adebola",
              "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ahmed",
              "employee_id": 633
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2627,
          "name": "Tulsa, OK",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Sanchez",
              "photo_url": "assets/1619016635.6029003_me_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Rosalia",
              "employee_id": 12233
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2632,
          "name": "Alpharetta, GA",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Rodriguez",
              "photo_url": "assets/09b698cde762467e950106dacfaaf750_IMG_2892_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 25558
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2633,
          "name": "Concordville, PA",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Rodriguez",
              "photo_url": "assets/09b698cde762467e950106dacfaaf750_IMG_2892_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 25558
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2634,
          "name": "Kalamazoo, MI",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Estes",
              "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Danielle",
              "employee_id": 250
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2635,
          "name": "Jollyville (Austin)",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Nunley",
              "photo_url": "assets/b659e1d43b284974b4c655acf4c1ab4d_BrentNunleyheadshotcolor_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Brent",
              "employee_id": 2981
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2637,
          "name": "RTP Bio Facility",
          "customer_id": 253,
          "customer_name": "Biogen (CBRE)",
          "contract_start_date": "2021-11-01T07:00:00Z",
          "manager_contact": {
              "last_name": "Purefoy",
              "photo_url": "assets/r8eac2d26be94487996a23e46c0ebcc3d_2930D4F17F48419DBD5712F6C2B24686_t.jpeg",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Chitara",
              "employee_id": 55078
          },
          "number_of_recordables": 0,
          "number_of_incidents": 33,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2639,
          "name": "Winter Haven",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Mendieta",
              "photo_url": "assets/0f5ebb444fba4b6db302929404569ba0_chr_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Christian",
              "employee_id": 12388
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2640,
          "name": "Bethlehem",
          "customer_id": 470,
          "customer_name": "IQVIA (CBRE)",
          "contract_start_date": "2021-06-01T08:00:00Z",
          "manager_contact": {
              "last_name": "Hartz",
              "photo_url": "assets/ff480aa2d9fd4e238599b6556e0d351e_JimsPic2_t.jpg",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  },
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "James",
              "employee_id": 14445
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2643,
          "name": "Lincoln South",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Villalobos",
              "photo_url": "assets/bb6ba2cdc8b14c43ae94f3550aa86c00_NicolasVillalobos_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Nicolas",
              "employee_id": 48393
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2651,
          "name": "Dulles, VA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2021-09-22T23:59:59Z",
          "manager_contact": {
              "last_name": "Taminez",
              "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ivan",
              "employee_id": 52772
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2656,
          "name": "Murfreesboro",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Sandoval Davila",
              "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jaime",
              "employee_id": 12740
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2664,
          "name": "Jacksonville FL",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2021-10-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Mendieta",
              "photo_url": "assets/0f5ebb444fba4b6db302929404569ba0_chr_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Christian",
              "employee_id": 12388
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 2686,
          "name": "West Palm Beach",
          "customer_id": 504,
          "customer_name": "Sikorsky (CBRE)",
          "contract_start_date": "2020-04-10T08:00:00Z",
          "manager_contact": {
              "last_name": "Batule",
              "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Melody",
              "employee_id": 90442
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3144,
          "name": "Memphis",
          "customer_id": 803,
          "customer_name": "Vantiva (CBRE)",
          "contract_start_date": "2022-04-01T19:10:39Z",
          "manager_contact": {
              "last_name": "Hammons",
              "photo_url": "assets/1588177218.5716636_IMG_0748_t.PNG",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Kevin",
              "employee_id": 9455
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3233,
          "name": "Union City",
          "customer_id": 921,
          "customer_name": "Tenaya(CBRE)",
          "contract_start_date": "2022-03-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Diaz",
              "photo_url": "",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Sergio",
              "employee_id": 9242
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3299,
          "name": "Cambridge Kendall SQ",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2018-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Perez-Castro",
              "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jonathan",
              "employee_id": 8276
          },
          "number_of_recordables": 0,
          "number_of_incidents": 10,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3336,
          "name": "Whitehouse Station, NJ",
          "customer_id": 1018,
          "customer_name": "Chubb Insurance (CBRE)",
          "contract_start_date": "2022-04-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Duran",
              "photo_url": "assets/1602274287.711383_Yasmiry_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Yasmiry",
              "employee_id": 6596
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3398,
          "name": "Lexington, MA",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2022-07-01T07:00:00Z",
          "manager_contact": {
              "last_name": "Perez-Castro",
              "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jonathan",
              "employee_id": 8276
          },
          "number_of_recordables": 0,
          "number_of_incidents": 5,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3432,
          "name": "LaVergne",
          "customer_id": 803,
          "customer_name": "Vantiva (CBRE)",
          "contract_start_date": "2022-05-02T00:00:00Z",
          "manager_contact": {
              "last_name": "Sandoval Davila",
              "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jaime",
              "employee_id": 12740
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3467,
          "name": "Richmond, IN",
          "customer_id": 1018,
          "customer_name": "Chubb Insurance (CBRE)",
          "contract_start_date": "2022-04-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Oropeza Ruz",
              "photo_url": "assets/1600282454.3660638_2706cec21_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Maria",
              "employee_id": 19382
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3470,
          "name": "Redlands",
          "customer_id": 468,
          "customer_name": "Deutsche Bank",
          "contract_start_date": "2023-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Weekly",
              "photo_url": "assets/d957c4d3f71a40e18bff000c58542f84_305137486_5207966462632028_7525157243343718431_n_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ana",
              "employee_id": 79998
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3687,
          "name": "Durham, NC",
          "customer_id": 72,
          "customer_name": "Eli Lilly (CBRE)",
          "contract_start_date": "2022-07-01T08:00:00Z",
          "manager_contact": {
              "last_name": "Brake",
              "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Sara",
              "employee_id": 8763
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3839,
          "name": "Baltimore, MD*",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2022-06-14T00:00:00Z",
          "manager_contact": {
              "last_name": "Saykawlard",
              "photo_url": "assets/f92ed3889e9d45139d4fb783eb797fd4_LaneySaykawlard_Ms.Gaithersburg2_t.jpg",
              "positions": [
                  {
                      "id": 197,
                      "name": "Sr Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Laney",
              "employee_id": 52590
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3993,
          "name": "San Francisco",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Oviedo Ascencio",
              "photo_url": "assets/5a90e29d17494672a5509fe21081ee52_JosePhoto_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 75239
          },
          "number_of_recordables": 0,
          "number_of_incidents": 10,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3994,
          "name": "Chilco",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Flores",
              "photo_url": "assets/6134c9c969f84c3dae3dcb0ea0759894_thumbnail_IMG_05781_t.jpg",
              "positions": [
                  {
                      "id": 27,
                      "name": "Custodial Supervisor",
                      "is_secondary": false
                  }
              ],
              "first_name": "Joanna",
              "employee_id": 75677
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3995,
          "name": "Sausalito",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-23T00:00:00Z",
          "manager_contact": {
              "last_name": "Oviedo Ascencio",
              "photo_url": "assets/5a90e29d17494672a5509fe21081ee52_JosePhoto_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jose",
              "employee_id": 75239
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3997,
          "name": "Gateway",
          "customer_id": 1422,
          "customer_name": "Meta (CBRE)",
          "contract_start_date": "2022-07-25T00:00:00Z",
          "manager_contact": {
              "last_name": "Flores",
              "photo_url": "assets/6134c9c969f84c3dae3dcb0ea0759894_thumbnail_IMG_05781_t.jpg",
              "positions": [
                  {
                      "id": 27,
                      "name": "Custodial Supervisor",
                      "is_secondary": false
                  }
              ],
              "first_name": "Joanna",
              "employee_id": 75677
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 3999,
          "name": "Bannockburn",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2022-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "O'Neill",
              "photo_url": "assets/2e083fe1df1c4a30b2547d7f6af0313d_Andrew_Omeill_29433_Websize_t.jpg",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Andrew",
              "employee_id": 9323
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4124,
          "name": "Exton",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2022-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Perez-Castro",
              "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jonathan",
              "employee_id": 8276
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4125,
          "name": "York, NE",
          "customer_id": 1547,
          "customer_name": "Collins Aerospace (CBRE)",
          "contract_start_date": "2022-06-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Villalobos",
              "photo_url": "assets/bb6ba2cdc8b14c43ae94f3550aa86c00_NicolasVillalobos_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Nicolas",
              "employee_id": 48393
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4225,
          "name": "City of Industry",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2022-05-09T00:00:00Z",
          "manager_contact": {
              "last_name": "Garcia",
              "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ana",
              "employee_id": 49618
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4226,
          "name": "Santa Fe Springs",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2022-03-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Garcia",
              "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ana",
              "employee_id": 49618
          },
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": "2022-11-21T19:45:00Z",
          "trir": 0
      },
      {
          "id": 4227,
          "name": "Irvine",
          "customer_id": 1648,
          "customer_name": "TGS",
          "contract_start_date": "2023-02-21T00:00:00Z",
          "manager_contact": {
              "last_name": "Garcia",
              "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ana",
              "employee_id": 49618
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4231,
          "name": "Blings Ops*",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2022-07-14T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4324,
          "name": "MOON TWP PA",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2022-08-09T07:00:00Z",
          "manager_contact": {
              "last_name": "Adebola",
              "photo_url": "assets/1619111420.6064503_RefusedphotoMale_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ahmed",
              "employee_id": 633
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4447,
          "name": "North Dakota (CBRE)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2022-08-10T00:00:00Z",
          "manager_contact": {
              "last_name": "Hockersmith",
              "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Michelle",
              "employee_id": 5851
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 4819,
          "name": "Fairdale",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2022-12-15T00:00:00Z",
          "manager_contact": {
              "last_name": "Clevenger",
              "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jamie",
              "employee_id": 76561
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5491,
          "name": "Shoreview Data Center",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2021-09-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5495,
          "name": "McKnight Road",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-03-07T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5496,
          "name": "Shoreview Operations CTR",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2021-09-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5497,
          "name": "Central",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2013-11-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5498,
          "name": "Old Saint Anthony/OSA*",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2013-11-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5499,
          "name": "Ridgedale",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2013-11-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5500,
          "name": "Sioux Falls Main",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2018-09-30T00:00:00Z",
          "manager_contact": {
              "last_name": "Hockersmith",
              "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Michelle",
              "employee_id": 5851
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5515,
          "name": "Palo Alto",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2023-01-03T00:00:00Z",
          "manager_contact": {
              "last_name": "Romero",
              "photo_url": "assets/d2948913035e4974a865fdc6a23b9d03_1000003743_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Victor",
              "employee_id": 91379
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5530,
          "name": "Wenatchee, WA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2022-11-16T19:04:54Z",
          "manager_contact": {
              "last_name": "Taminez",
              "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ivan",
              "employee_id": 52772
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5534,
          "name": "Lodi Vineyard (JLL)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2023-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Del Rio",
              "photo_url": "assets/2cbc7b25e22345be91eed9e2fbfa8387_Upright2_t.jpg",
              "positions": [
                  {
                      "id": 38,
                      "name": "Reg Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Orlando",
              "employee_id": 1757
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5537,
          "name": "Philadelphia, PA",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2023-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Adams",
              "photo_url": "assets/b5d0b252459e487c9f1177f9c341d1c6_bob_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Robert",
              "employee_id": 6557
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5538,
          "name": "Thomas Edison",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2024-01-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Beckman",
              "photo_url": "assets/1588791845.2409494_TimPic_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Timothy",
              "employee_id": 2482
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5544,
          "name": "Cedar Rapids Downtown",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2024-01-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Beckman",
              "photo_url": "assets/1588791845.2409494_TimPic_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Timothy",
              "employee_id": 2482
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5565,
          "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T18:17:26Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5567,
          "name": "West Des Moines Campus",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2023-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Diaz Velarde",
              "photo_url": "assets/r1f9118c97c8d4627a8f75865cefe94d0_cropped216840175318362083.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alexis",
              "employee_id": 85470
          },
          "number_of_recordables": 0,
          "number_of_incidents": 9,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5575,
          "name": "Concord, NC",
          "customer_id": 72,
          "customer_name": "Eli Lilly (CBRE)",
          "contract_start_date": "2022-07-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Baker",
              "photo_url": "assets/7db12f89ffcc47288c0dca9785d6efd6_dbakerphoto_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Duriel",
              "employee_id": 52818
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5651,
          "name": "Springhouse Plant, PA",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2009-05-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Luciano-Lopez",
              "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Yaritza",
              "employee_id": 10215
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5656,
          "name": "Los Angeles Cash Vault (JLL)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2023-03-09T00:00:00Z",
          "manager_contact": {
              "last_name": "Vazquez Jimenez",
              "photo_url": "assets/af3d30e0e7224df3b68c77a9803bcdf1_me001_t.JPG",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ismael",
              "employee_id": 6408
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5681,
          "name": "2047 Lakewood Blvd*",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2023-04-01T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5687,
          "name": "Apache Junction",
          "customer_id": 2154,
          "customer_name": "Cigna (CBRE) - Sub",
          "contract_start_date": "2023-03-29T20:24:25Z",
          "manager_contact": {
              "last_name": "Ascencio Chavez",
              "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Alan",
              "employee_id": 46011
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5744,
          "name": "Duluth PCS (CBRE)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2023-05-12T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5745,
          "name": "Moberly (CBRE)",
          "customer_id": 1649,
          "customer_name": "Wells Fargo-Sub",
          "contract_start_date": "2023-05-12T00:00:00Z",
          "manager_contact": {
              "last_name": "Cowan",
              "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Damian",
              "employee_id": 77207
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5775,
          "name": "AOC",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T21:35:03Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 6,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5776,
          "name": "Columbia, MO*",
          "customer_id": 485,
          "customer_name": "State Farm (CBRE)",
          "contract_start_date": "2023-06-05T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5777,
          "name": "WOCC",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T21:35:47Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 3,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5790,
          "name": "CIC",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T15:35:36Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5792,
          "name": "PETC",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T22:19:48Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5793,
          "name": "Northyard",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T22:18:54Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5794,
          "name": "Fulton County Airport Hangar",
          "customer_id": 2145,
          "customer_name": "Coca Cola",
          "contract_start_date": "2023-07-01T22:32:05Z",
          "manager_contact": {
              "last_name": "Cornett",
              "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Allen",
              "employee_id": 93551
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5809,
          "name": "Pharma Facility",
          "customer_id": 253,
          "customer_name": "Biogen (CBRE)",
          "contract_start_date": "2021-10-31T21:21:28Z",
          "manager_contact": {
              "last_name": "Purefoy",
              "photo_url": "assets/r8eac2d26be94487996a23e46c0ebcc3d_2930D4F17F48419DBD5712F6C2B24686_t.jpeg",
              "positions": [
                  {
                      "id": 14,
                      "name": "Mgr, Custodial",
                      "is_secondary": false
                  }
              ],
              "first_name": "Chitara",
              "employee_id": 55078
          },
          "number_of_recordables": 0,
          "number_of_incidents": 6,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5813,
          "name": "Suntec",
          "customer_id": 2155,
          "customer_name": "VMware (CBRE) (SG)",
          "contract_start_date": "2023-07-31T17:48:50Z",
          "manager_contact": {
              "last_name": "KOK YEOW",
              "photo_url": "assets/6d9ddd5675634f8c9fd7db3f283caabe_PhilipPhua_t.jpeg",
              "positions": [
                  {
                      "id": 241,
                      "name": "Sr Mgr, Transitions",
                      "is_secondary": false
                  }
              ],
              "first_name": "PHUA",
              "employee_id": 36751
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5817,
          "name": "San Francisco, CA",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2020-12-07T00:00:00Z",
          "manager_contact": {
              "last_name": "Hauck",
              "photo_url": "assets/r83be7835cd3b4a909131fb2c9177c3a3_Screenshot20230930at12.04_t.18PM",
              "positions": [
                  {
                      "id": 713,
                      "name": "Assoc Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Zane",
              "employee_id": 36398
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5828,
          "name": "Cambridge Central*",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2023-07-24T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5831,
          "name": "Denver Service Center",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2021-08-01T23:26:31Z",
          "manager_contact": {
              "last_name": "Vega",
              "photo_url": "assets/5c6481735bfc43c3bc29c5a4ce7ac31a_15aa73416e134c0ba83abf6633db4530_mariavega_t_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Maria",
              "employee_id": 6407
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5837,
          "name": "LEP - Lima Engine Plant - Lima, OH",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2023-11-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Frey",
              "photo_url": "",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Ryan",
              "employee_id": 91897
          },
          "number_of_recordables": 0,
          "number_of_incidents": 16,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5849,
          "name": "10 Exchange Place, NJ",
          "customer_id": 1018,
          "customer_name": "Chubb Insurance (CBRE)",
          "contract_start_date": "2022-04-01T17:01:19Z",
          "manager_contact": {
              "last_name": "Castro",
              "photo_url": "",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  },
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Gloria",
              "employee_id": 4505
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5851,
          "name": "AEC – Advanced Electrification Center – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T14:49:12Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5852,
          "name": "OB1 – Office Building 1 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T20:36:35Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2022-11-23T14:20:00Z",
          "trir": 0
      },
      {
          "id": 5853,
          "name": "OB2 – Office Building 2 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T20:25:25Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5854,
          "name": "OB3 – Office Building 3 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T14:51:51Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5855,
          "name": "OB4 – Office Building 4 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T14:52:23Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5856,
          "name": "OB5 – Office Building 5 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T14:53:12Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5857,
          "name": "OB6 – Office Building 6 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T19:09:38Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5858,
          "name": "CBB – Crash Barrier – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2023-09-28T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5859,
          "name": "DDE – Driving Dynamics East – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:13:19Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5860,
          "name": "DDW – Driving Dynamics West – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:10:36Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5861,
          "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2021-09-01T23:06:03Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5862,
          "name": "DYNO – Dynamometer – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:08:22Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5863,
          "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:04:23Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2021-03-03T14:00:00Z",
          "trir": 0
      },
      {
          "id": 5864,
          "name": "FEL – Ford Engineering Lab – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2023-12-27T23:02:32.782000Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2023-01-10T18:06:00Z",
          "trir": 0
      },
      {
          "id": 5865,
          "name": "GTL – Gas Turbine Labs – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T23:01:20Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5866,
          "name": "MOB – Maintenance & Operations – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T15:11:07Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5869,
          "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T22:21:54Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5870,
          "name": "PDC – Product Development Center - Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T15:07:19Z",
          "manager_contact": {
              "last_name": "Scott",
              "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jennifer",
              "employee_id": 42520
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5871,
          "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T22:33:42Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5872,
          "name": "SRC – Service Research Center – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T22:48:55Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5874,
          "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2023-12-27T20:40:48Z",
          "manager_contact": {
              "last_name": "French",
              "photo_url": "assets/33bc0d34794147d19e48a8b1ff7f3a52_WeddingPhoto_t.png",
              "positions": [
                  {
                      "id": 74,
                      "name": "Account Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Kristin",
              "employee_id": 88524
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5875,
          "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T22:53:51Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5876,
          "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T17:22:27Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5877,
          "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T15:09:34Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5878,
          "name": "WPW – Wagner Place West – Dearborn, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2020-11-09T20:34:25Z",
          "manager_contact": {
              "last_name": "McGee",
              "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Donna",
              "employee_id": 15516
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5879,
          "name": "Lexington Columbia Construction*",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2023-09-25T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5973,
          "name": "Fremont",
          "customer_id": 2240,
          "customer_name": "Lam Research (CBRE)",
          "contract_start_date": "2024-01-30T00:00:00Z",
          "manager_contact": {
              "last_name": "Avalos Limon",
              "photo_url": "assets/r3026b2f17be24745aaf88981e040fac7_20231018_132507_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jhonatan",
              "employee_id": 91069
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5974,
          "name": "Livermore",
          "customer_id": 2240,
          "customer_name": "Lam Research (CBRE)",
          "contract_start_date": "2024-01-30T00:00:00Z",
          "manager_contact": {
              "last_name": "Avalos Limon",
              "photo_url": "assets/r3026b2f17be24745aaf88981e040fac7_20231018_132507_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jhonatan",
              "employee_id": 91069
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5975,
          "name": "Tualatin",
          "customer_id": 2240,
          "customer_name": "Lam Research (CBRE)",
          "contract_start_date": "2024-01-30T00:00:00Z",
          "manager_contact": {
              "last_name": "Benitez",
              "photo_url": "assets/re5d03eb192304fff83827dc750d2f867_20240130_135715_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Veronica",
              "employee_id": 94688
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5976,
          "name": "Hillsboro",
          "customer_id": 2240,
          "customer_name": "Lam Research (CBRE)",
          "contract_start_date": "2024-01-30T00:00:00Z",
          "manager_contact": {
              "last_name": "Benitez",
              "photo_url": "assets/re5d03eb192304fff83827dc750d2f867_20240130_135715_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Veronica",
              "employee_id": 94688
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5977,
          "name": "CHICAGO 20*",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2023-11-23T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5978,
          "name": "CHICAGO 22*",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2023-11-21T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5979,
          "name": "DSM05*",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2023-11-22T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 5981,
          "name": "IAD11*",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2023-11-22T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6076,
          "name": "Cover My Meds 2",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2024-01-31T23:45:54.420000Z",
          "manager_contact": {
              "last_name": "Gonce",
              "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Dan",
              "employee_id": 92415
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6335,
          "name": "St. Petersburg*",
          "customer_id": 220,
          "customer_name": "McKesson (CBRE)",
          "contract_start_date": "2023-12-13T15:20:43Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6599,
          "name": "ITHQ-C",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6684,
          "name": "LTP – Livonia Transmission Plant – Wayne, MI",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": 0,
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6698,
          "name": "Fluor - Lebanon*",
          "customer_id": 72,
          "customer_name": "Eli Lilly (CBRE)",
          "contract_start_date": "2024-01-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Brake",
              "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Sara",
              "employee_id": 8763
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6740,
          "name": "Commerce Park 2 15090",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6742,
          "name": "800 Republic",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6744,
          "name": "Fairlane Plaza",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6746,
          "name": "Fairlane Business Park 3",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6747,
          "name": "1633 Fairlane Circle",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6750,
          "name": "IT Bic",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 6751,
          "name": "Commerce Park 2 15080",
          "customer_id": 315,
          "customer_name": "Ford",
          "contract_start_date": "2024-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Curtis",
              "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Stacy",
              "employee_id": 15071
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1151,
          "name": "Sunnyvale, CA",
          "customer_id": 63,
          "customer_name": "Juniper",
          "contract_start_date": "2017-09-05T23:59:59Z",
          "manager_contact": {
              "last_name": "Sanchez",
              "photo_url": "assets/a686535912b44ebca3bc8933686b93e9_jhfgyuf_t.PNG",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Enrique",
              "employee_id": 6727
          },
          "number_of_recordables": 0,
          "number_of_incidents": 4,
          "latest_recordable_date": "2019-11-15T07:30:00Z",
          "trir": 0
      },
      {
          "id": 1198,
          "name": "Waukegan, IL",
          "customer_id": 75,
          "customer_name": "AbbVie",
          "contract_start_date": "2013-01-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Nelson",
              "photo_url": "assets/270f1c6b1f674897871279e472bbbb59_Madeleine_Nelson_29437_Websize_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Madeleine",
              "employee_id": 15095
          },
          "number_of_recordables": 0,
          "number_of_incidents": 6,
          "latest_recordable_date": "2022-09-13T23:45:00Z",
          "trir": 0
      },
      {
          "id": 1324,
          "name": "Illinois",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-03-06T23:59:59Z",
          "manager_contact": {
              "last_name": "Cowan",
              "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Damian",
              "employee_id": 77207
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1325,
          "name": "Des Moines",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-05-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Beckman",
              "photo_url": "assets/1588791845.2409494_TimPic_t.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Timothy",
              "employee_id": 2482
          },
          "number_of_recordables": 0,
          "number_of_incidents": 2,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1327,
          "name": "Campus Chandler -AZ",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2013-11-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Rodriguez",
              "photo_url": "assets/1589890170.5798569_Michaelpic002_t.jpg",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Michael",
              "employee_id": 14607
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1356,
          "name": "Pleasanton",
          "customer_id": 90,
          "customer_name": "Clorox (CBRE)",
          "contract_start_date": "2015-07-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Mccoy",
              "photo_url": "assets/95a124518db5413abed90f423f9f0786_Headshot_t.jpg",
              "positions": [
                  {
                      "id": 1175,
                      "name": "Account Dir",
                      "is_secondary": false
                  }
              ],
              "first_name": "Artis",
              "employee_id": 52629
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": "2016-04-07T14:00:00Z",
          "trir": 0
      },
      {
          "id": 1459,
          "name": "Upper Gwynedd, PA",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2009-05-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Argueta",
              "photo_url": "assets/r5a94c65f33d34a23a34b9af0b77e31c3_photo_t.PNG",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Douglas",
              "employee_id": 627
          },
          "number_of_recordables": 0,
          "number_of_incidents": 8,
          "latest_recordable_date": "2020-01-13T14:12:00Z",
          "trir": 0
      },
      {
          "id": 1464,
          "name": "Wayzata",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2014-03-07T00:00:00Z",
          "manager_contact": {
              "last_name": "Albino Martinez",
              "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Cesar",
              "employee_id": 3480
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1486,
          "name": "Cherokee, PA",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2013-11-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Mengle",
              "photo_url": "assets/86750cab82464433bf763755726f8547_PXL_20220917_111531890_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Timothy",
              "employee_id": 78834
          },
          "number_of_recordables": 0,
          "number_of_incidents": 8,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1487,
          "name": "Durham, NC",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2010-11-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Delcid",
              "photo_url": "170edc3f-5efe-cf99-64c1-9492eef45d40.png",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Maria",
              "employee_id": 1536
          },
          "number_of_recordables": 0,
          "number_of_incidents": 1,
          "latest_recordable_date": "2013-01-03T15:00:00Z",
          "trir": 0
      },
      {
          "id": 1491,
          "name": "North Wales, PA",
          "customer_id": 267,
          "customer_name": "Merck (CBRE)",
          "contract_start_date": "2010-07-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Argueta",
              "photo_url": "assets/r5a94c65f33d34a23a34b9af0b77e31c3_photo_t.PNG",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Douglas",
              "employee_id": 627
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1626,
          "name": "San Antonio, TX",
          "customer_id": 87,
          "customer_name": "Wells Fargo",
          "contract_start_date": "2023-02-01T00:00:00Z",
          "manager_contact": {
              "last_name": "Martinez",
              "photo_url": "assets/r13fa4956fdad4f7e90f73dac8a061d34_Profilepic3_t.jpg",
              "positions": [
                  {
                      "id": 17,
                      "name": "Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Angelica",
              "employee_id": 81228
          },
          "number_of_recordables": 0,
          "number_of_incidents": 10,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1647,
          "name": "Central SQ",
          "customer_id": 180,
          "customer_name": "Takeda (CBRE)",
          "contract_start_date": "2018-07-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Perez-Castro",
              "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
              "positions": [
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  },
                  {
                      "id": 84,
                      "name": "Sr Site Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Jonathan",
              "employee_id": 8276
          },
          "number_of_recordables": 0,
          "number_of_incidents": 13,
          "latest_recordable_date": 0,
          "trir": 0
      },
      {
          "id": 1666,
          "name": "St. Louis, MO",
          "customer_id": 8,
          "customer_name": "Microsoft (CBRE)",
          "contract_start_date": "2018-07-01T23:59:59Z",
          "manager_contact": {
              "last_name": "Foshage",
              "photo_url": "assets/4c104cff96f541979962d8406cea44b6_cropped2369537953099717766.jpg",
              "positions": [
                  {
                      "id": 8,
                      "name": "Area Mgr",
                      "is_secondary": false
                  }
              ],
              "first_name": "Bernard",
              "employee_id": 70948
          },
          "number_of_recordables": 0,
          "number_of_incidents": 0,
          "latest_recordable_date": 0,
          "trir": 0
      }
  ],
  "most_recent_record": "2024-02-29T13:00:00Z"
}


export const cbreSOW = [{
  "client": "AbbVie",
  "logo_url": "https://cdn.4insite.com/assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
  "routes": 25,
  "sow_coverage": 65,
  "average_audit": 4.79,
  "sow_audited": 62,
  "sites": [{
          "site": "Branchburg",
          "buildings": 3,
          "areas": 247,
          "routes": 15,
          "sow_coverage": 95,
          "average_audit": 4.72,
          "sow_audited": 91
      },
      {
          "site": "Cincinnati",
          "buildings": 2,
          "areas": 87,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 5.00,
          "sow_audited": 12
      },
      {
          "site": "Madison, NJ",
          "buildings": 5,
          "areas": 629,
          "routes": 0,
          "sow_coverage": 100,
          "average_audit": 4.96,
          "sow_audited": 51
      },
      {
          "site": "Pleasanton",
          "buildings": 4,
          "areas": 147,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.99,
          "sow_audited": 100
      }, {
          "site": "Waco, TX",
          "buildings": 1,
          "areas": 117,
          "routes": 9,
          "sow_coverage": 46,
          "average_audit": 4.15,
          "sow_audited": 53
      },
      {
          "site": "Waukegan, IL",
          "buildings": 0,
          "areas": 26,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.35,
          "sow_audited": 61
      }
  ]
},
{
  "client": "Adient",
  "logo_url": "https://cdn.4insite.com/assets/1605624157.877317_adientvectorlogosmall_t.png",
  "routes": 6,
  "sow_coverage": 100,
  "average_audit": 4.35,
  "sow_audited": 77,
  "sites": [{
      "site": "Plymouth",
      "buildings": 1,
      "areas": 280,
      "routes": 6,
      "sow_coverage": 100,
      "average_audit": 4.35,
      "sow_audited": 77
  }]
},
{
  "client": "ASML",
  "logo_url": "https://cdn.4insite.com/assets/1607359606.6159544_ASML_t.png",
  "routes": 11,
  "sow_coverage": 91,
  "average_audit": 3.61,
  "sow_audited": 19,
  "sites": [{
      "site": "San Diego",
      "buildings": 11,
      "areas": 430,
      "routes": 11,
      "sow_coverage": 91,
      "average_audit": 3.61,
      "sow_audited": 19
  }]
},
{
  "client": "Biogen",
  "logo_url": "https://cdn.4insite.com/assets/1605623761.388814_Biogen01_t.png",
  "routes": 36,
  "sow_coverage": 44,
  "average_audit": 4.69,
  "sow_audited": 47,
  "sites": [{
          "site": "Cambridge",
          "buildings": 4,
          "areas": 1002,
          "routes": 31,
          "sow_coverage": 70,
          "average_audit": 4.80,
          "sow_audited": 70
      },
      {
          "site": "Pharma Facility",
          "buildings": 5,
          "areas": 316,
          "routes": 3,
          "sow_coverage": 8,
          "average_audit": 4.68,
          "sow_audited": 37
      },
      {
          "site": "RTP Bio Facility",
          "buildings": 8,
          "areas": 955,
          "routes": 2,
          "sow_coverage": 4,
          "average_audit": 4.29,
          "sow_audited": 27
      }
  ]
},
{
  "client": "Chemours",
  "logo_url": "https://cdn.4insite.com/assets/1607369039.649657_Chemours_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.68,
  "sow_audited": 45,
  "sites": [{
      "site": "Newark, DE",
      "buildings": 1,
      "areas": 250,
      "routes": 0,
      "sow_coverage": 0,
      "average_audit": 4.68,
      "sow_audited": 45
  }]
},
{
  "client": "Chubb Insurance",
  "logo_url": "https://cdn.4insite.com/assets/b6e3c419c49b4746a9cdfaf251371b61_12_t.png",
  "routes": 21,
  "sow_coverage": 64,
  "average_audit": 4.49,
  "sow_audited": 23,
  "sites": [{
          "site": "10 Exchange Place, NJ",
          "buildings": 1,
          "areas": 249,
          "routes": 1,
          "sow_coverage": 0,
          "average_audit": 4.51,
          "sow_audited": 6
      },
      {
          "site": "Richmond, IN",
          "buildings": 1,
          "areas": 70,
          "routes": 2,
          "sow_coverage": 99,
          "average_audit": 4.53,
          "sow_audited": 85
      },
      {
          "site": "Whitehouse Station, NJ",
          "buildings": 6,
          "areas": 604,
          "routes": 18,
          "sow_coverage": 85,
          "average_audit": 4.45,
          "sow_audited": 24
      }
  ]
},
{
  "client": "Cigna",
  "logo_url": "https://cdn.4insite.com/assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
  "routes": 1,
  "sow_coverage": 4,
  "average_audit": 4.23,
  "sow_audited": 48,
  "sites": [{
          "site": "Bloomfield",
          "buildings": 4,
          "areas": 143,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.95,
          "sow_audited": 81
      },
      {
          "site": "Chandler",
          "buildings": 1,
          "areas": 65,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.59,
          "sow_audited": 46
      },
      {
          "site": "CJ Harris",
          "buildings": 1,
          "areas": 47,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.56,
          "sow_audited": 38
      },
      {
          "site": "Gallatin, TN",
          "buildings": 1,
          "areas": 39,
          "routes": 1,
          "sow_coverage": 99,
          "average_audit": 3.93,
          "sow_audited": 51
      },
      {
          "site": "North Valley",
          "buildings": 1,
          "areas": 23,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.54,
          "sow_audited": 73
      },
      {
          "site": "Paradise Valley",
          "buildings": 1,
          "areas": 36,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Paseo",
          "buildings": 1,
          "areas": 61,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.60,
          "sow_audited": 49
      },
      {
          "site": "Phoenix Central",
          "buildings": 1,
          "areas": 72,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.55,
          "sow_audited": 30
      },
      {
          "site": "Scottsdale Skysong",
          "buildings": 1,
          "areas": 21,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 30
      },
      {
          "site": "South Mountain",
          "buildings": 1,
          "areas": 23,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.62,
          "sow_audited": 56
      },
      {
          "site": "Stapley",
          "buildings": 1,
          "areas": 75,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.56,
          "sow_audited": 24
      },
      {
          "site": "St. Louis Express Scripts",
          "buildings": 2,
          "areas": 793,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.24,
          "sow_audited": 52
      },
      {
          "site": "Sun City",
          "buildings": 2,
          "areas": 72,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.56,
          "sow_audited": 34
      },
      {
          "site": "Sun City West",
          "buildings": 1,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.67,
          "sow_audited": 63
      },
      {
          "site": "Superstition Springs",
          "buildings": 1,
          "areas": 50,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Westridge",
          "buildings": 1,
          "areas": 45,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.56,
          "sow_audited": 57
      }
  ]
},
{
  "client": "Cigna Sub",
  "logo_url": "https://cdn.4insite.com/assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 0,
  "sow_audited": 0,
  "sites": [{
          "site": "Apache Junction",
          "buildings": 1,
          "areas": 25,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Apache Junction",
          "buildings": 1,
          "areas": 25,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Arrowhead",
          "buildings": 1,
          "areas": 25,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Baltimore, MD",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Norte",
          "buildings": 1,
          "areas": 17,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Palm Valley",
          "buildings": 1,
          "areas": 28,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Queen Creek",
          "buildings": 1,
          "areas": 14,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Red Mountain",
          "buildings": 1,
          "areas": 24,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "San Tan",
          "buildings": 1,
          "areas": 19,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      }

  ]
},
{
  "client": "Clorox",
  "logo_url": "https://cdn.4insite.com/assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.97,
  "sow_audited": 98,
  "sites": [{
          "site": "Oakland, CA",
          "buildings": 1,
          "areas": 166,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.97,
          "sow_audited": 98
      },
      {
          "site": "Pleasanton",
          "buildings": 7,
          "areas": 443,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.97,
          "sow_audited": 98
      }
  ]
},
{
  "client": "Coca Cola",
  "logo_url": "https://cdn.4insite.com/assets/9182f361e2ff41d3bded657bde583842_1ec1d2ce366d1f603b1bde70ae508063_t.jpg",
  "routes": 14,
  "sow_coverage": 66,
  "average_audit": 4.30,
  "sow_audited": 31,
  "sites": [{
          "site": "AOC",
          "buildings": 7,
          "areas": 2633,
          "routes": 14,
          "sow_coverage": 70,
          "average_audit": 4.34,
          "sow_audited": 32
      },
      {
          "site": "CIC",
          "buildings": 1,
          "areas": 26,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 5.00,
          "sow_audited": 3
      },
      {
          "site": "Fulton County Airport Hangar",
          "buildings": 1,
          "areas": 20,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Northyard",
          "buildings": 2,
          "areas": 74,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "PETC",
          "buildings": 1,
          "areas": 24,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "WOCC",
          "buildings": 1,
          "areas": 55,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.95,
          "sow_audited": 74
      }
  ]
},
{
  "client": "Collins Aerospace",
  "logo_url": "https://cdn.4insite.com/assets/6617f7d3448b41d9a49d9f54dc0abe45_Screenshot20240216at07.23_t.42",
  "routes": 2,
  "sow_coverage": 100,
  "average_audit": 4.50,
  "sow_audited": 100,
  "sites": [{
      "site": "York, NE",
      "buildings": 1,
      "areas": 35,
      "routes": 2,
      "sow_coverage": 100,
      "average_audit": 4.50,
      "sow_audited": 100
  }]
},
{
  "client": "Covestro",
  "logo_url": "https://cdn.4insite.com/assets/edac7c8f818243409ff8b58d2def6560_2_t.jpg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 3.60,
  "sow_audited": 66,
  "sites": [{
      "site": "Pittsburg",
      "buildings": 8,
      "areas": 130,
      "routes": 0,
      "sow_coverage": 0,
      "average_audit": 3.60,
      "sow_audited": 66
  }]
},
{
  "client": "Deutsche Bank",
  "logo_url": "https://cdn.4insite.com/assets/dc7aa29633644a68beb3aeb2abd0e74a_Image_Editor2_t.png",
  "routes": 52,
  "sow_coverage": 99,
  "average_audit": 4.43,
  "sow_audited": 63,
  "sites": [{
          "site": "New York",
          "buildings": 3,
          "areas": 950,
          "routes": 32,
          "sow_coverage": 99,
          "average_audit": 4.44,
          "sow_audited": 62
      },
      {
          "site": "Redlands",
          "buildings": 1,
          "areas": 7,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.46,
          "sow_audited": 100
      },
      {
          "site": "Santa Ana",
          "buildings": 1,
          "areas": 21,
          "routes": 18,
          "sow_coverage": 100,
          "average_audit": 4.21,
          "sow_audited": 95
      }
  ]
},
{
  "client": "Elanco",
  "logo_url": "https://cdn.4insite.com/assets/1607378591.6016922_Elanco_t.png",
  "routes": 12,
  "sow_coverage": 51,
  "average_audit": 4.41,
  "sow_audited": 60,
  "sites": [{
          "site": "Clinton",
          "buildings": 27,
          "areas": 218,
          "routes": 8,
          "sow_coverage": 72,
          "average_audit": 4.42,
          "sow_audited": 91
      },
      {
          "site": "Greenfield, IN",
          "buildings": 6,
          "areas": 365,
          "routes": 4,
          "sow_coverage": 29,
          "average_audit": 4.16,
          "sow_audited": 47
      },
      {
          "site": "Indy",
          "buildings": 2,
          "areas": 123,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.81,
          "sow_audited": 42
      }
  ]
},
{
  "client": "Eli Lilly",
  "logo_url": "https://cdn.4insite.com/assets/1605311746.7267878_Artboard8_t.png",
  "routes": 231,
  "sow_coverage": 86,
  "average_audit": 4.19,
  "sow_audited": 49,
  "sites": [{
          "site": "Concord, NC",
          "buildings": 2,
          "areas": 51,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.35,
          "sow_audited": 25
      },
      {
          "site": "Durham, NC",
          "buildings": 11,
          "areas": 118,
          "routes": 20,
          "sow_coverage": 75,
          "average_audit": 4.34,
          "sow_audited": 94
      },
      {
          "site": "Fluor Lebanon",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Indianapolis, IN",
          "buildings": 111,
          "areas": 7036,
          "routes": 211,
          "sow_coverage": 87,
          "average_audit": 4.18,
          "sow_audited": 48
      }
  ]
},
{
  "client": "Ford",
  "logo_url": "https://cdn.4insite.com/assets/1605311926.006089_Artboard11_t.png",
  "routes": 82,
  "sow_coverage": 29,
  "average_audit": 3.49,
  "sow_audited": 28,
  "sites": [{
          "site": "1633 Fairlane Circle",
          "buildings": 1,
          "areas": 57,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.56,
          "sow_audited": 63
      },
      {
          "site": "800 Republic",
          "buildings": 1,
          "areas": 18,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.24,
          "sow_audited": 100
      },
      {
          "site": "AEC Advanced Electrification Center Dearborn, MI",
          "buildings": 1,
          "areas": 200,
          "routes": 5,
          "sow_coverage": 100,
          "average_audit": 3.50,
          "sow_audited": 79
      },
      {
          "site": "AMC Advanced Manufacturing Center Redford, MI",
          "buildings": 1,
          "areas": 65,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 3.61,
          "sow_audited": 67
      },
      {
          "site": "CBB Crash Barrier Dearborn, MI",
          "buildings": 1,
          "areas": 69,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 3.80,
          "sow_audited": 53
      },
      {
          "site": "Commerce Park 2 15080",
          "buildings": 1,
          "areas": 21,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.51,
          "sow_audited": 100
      },
      {
          "site": "Commerce Park 2 15090",
          "buildings": 1,
          "areas": 22,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.42,
          "sow_audited": 72
      },
      {
          "site": "DDE Driving Dynamics East Dearborn, MI",
          "buildings": 1,
          "areas": 177,
          "routes": 4,
          "sow_coverage": 100,
          "average_audit": 3.42,
          "sow_audited": 19
      },
      {
          "site": "DDW Driving Dynamics West Dearborn, MI",
          "buildings": 1,
          "areas": 61,
          "routes": 2,
          "sow_coverage": 97,
          "average_audit": 3.38,
          "sow_audited": 39
      },
      {
          "site": "DSC 1&2",
          "buildings": 2,
          "areas": 33,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.21,
          "sow_audited": 96
      },
      {
          "site": "DTF Drivability Test Facility Allen Park, MI",
          "buildings": 1,
          "areas": 99,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 3.68,
          "sow_audited": 70
      },
      {
          "site": "DYNO Dynamometer Dearborn, MI",
          "buildings": 1,
          "areas": 17,
          "routes": 7,
          "sow_coverage": 97,
          "average_audit": 3.37,
          "sow_audited": 11
      },
      {
          "site": "EMDO Engineering Manufacturing Development Operations Dearborn, MI",
          "buildings": 1,
          "areas": 18,
          "routes": 5,
          "sow_coverage": 73,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "EVB Experimental Vehicle Building Dearborn, MI",
          "buildings": 1,
          "areas": 191,
          "routes": 5,
          "sow_coverage": 100,
          "average_audit": 3.53,
          "sow_audited": 13
      },
      {
          "site": "Fairlane Business Park 3",
          "buildings": 1,
          "areas": 32,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.90,
          "sow_audited": 93
      },
      {
          "site": "Fairlane Business Park 5",
          "buildings": 1,
          "areas": 73,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.04,
          "sow_audited": 87
      },
      {
          "site": "Fairlane North",
          "buildings": 2,
          "areas": 143,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.66,
          "sow_audited": 27
      },
      {
          "site": "Fairlane Plaza",
          "buildings": 2,
          "areas": 87,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.97,
          "sow_audited": 66
      },
      {
          "site": "FEL Ford Engineering Lab Dearborn, MI",
          "buildings": 1,
          "areas": 7,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "FXC Ford Experience Center Dearborn, MI",
          "buildings": 1,
          "areas": 85,
          "routes": 3,
          "sow_coverage": 100,
          "average_audit": 3.52,
          "sow_audited": 95
      },
      {
          "site": "GTL Gas Turbine Labs Dearborn, MI",
          "buildings": 1,
          "areas": 27,
          "routes": 1,
          "sow_coverage": 97,
          "average_audit": 3.56,
          "sow_audited": 70
      },
      {
          "site": "ION Ford Battery Center of Excellence Romulus, MI",
          "buildings": 1,
          "areas": 34,
          "routes": 2,
          "sow_coverage": 97,
          "average_audit": 4.22,
          "sow_audited": 73
      },
      {
          "site": "IT Bic",
          "buildings": 1,
          "areas": 75,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.57,
          "sow_audited": 78
      },
      {
          "site": "ITHQ-C",
          "buildings": 3,
          "areas": 88,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.97,
          "sow_audited": 69
      },
      {
          "site": "LEP Lima Engine Plant Lima, OH",
          "buildings": 5,
          "areas": 228,
          "routes": 16,
          "sow_coverage": 100,
          "average_audit": 3.25,
          "sow_audited": 99
      },
      {
          "site": "LTP Livonia Transmission Plant Wayne, MI",
          "buildings": 5,
          "areas": 320,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.80,
          "sow_audited": 1
      },
      {
          "site": "MOB Maintenance & Operations Dearborn, MI",
          "buildings": 1,
          "areas": 56,
          "routes": 2,
          "sow_coverage": 98,
          "average_audit": 3.53,
          "sow_audited": 51
      },
      {
          "site": "OB1 Office Building 1 Dearborn, MI",
          "buildings": 1,
          "areas": 26,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 3.61,
          "sow_audited": 73
      },
      {
          "site": "OB2 Office Building 2 Dearborn, MI",
          "buildings": 1,
          "areas": 277,
          "routes": 5,
          "sow_coverage": 99,
          "average_audit": 3.43,
          "sow_audited": 46
      },
      {
          "site": "OB3 Office Building 3 Dearborn, MI",
          "buildings": 1,
          "areas": 33,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.18,
          "sow_audited": 57
      },
      {
          "site": "OB4 Office Building 4 Dearborn, MI",
          "buildings": 1,
          "areas": 151,
          "routes": 6,
          "sow_coverage": 23,
          "average_audit": 3.41,
          "sow_audited": 19
      },
      {
          "site": "OB5 Office Building 5 Dearborn, MI",
          "buildings": 1,
          "areas": 66,
          "routes": 2,
          "sow_coverage": 98,
          "average_audit": 3.68,
          "sow_audited": 43
      },
      {
          "site": "OB6 Office Building 6 Dearborn, MI",
          "buildings": 1,
          "areas": 163,
          "routes": 3,
          "sow_coverage": 100,
          "average_audit": 3.54,
          "sow_audited": 17
      },
      {
          "site": "PDC Product Development Center Dearborn, MI",
          "buildings": 1,
          "areas": 3702,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.75,
          "sow_audited": 11
      },
      {
          "site": "PFSL Powertrain & Fuel Cell Systems Lab Dearborn, MI",
          "buildings": 1,
          "areas": 61,
          "routes": 1,
          "sow_coverage": 99,
          "average_audit": 3.72,
          "sow_audited": 40
      },
      {
          "site": "SIL Safety Innovation Laboratory Dearborn, MI",
          "buildings": 1,
          "areas": 113,
          "routes": 2,
          "sow_coverage": 99,
          "average_audit": 3.63,
          "sow_audited": 38
      },
      {
          "site": "SRC Service Research Center Dearborn, MI",
          "buildings": 1,
          "areas": 68,
          "routes": 2,
          "sow_coverage": 98,
          "average_audit": 3.66,
          "sow_audited": 26
      },
      {
          "site": "TFC The Factory Detroit, MI",
          "buildings": 1,
          "areas": 115,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 3.62,
          "sow_audited": 48
      },
      {
          "site": "VDAB Vehicle Dynamics Area Dearborn, MI",
          "buildings": 1,
          "areas": 19,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.53,
          "sow_audited": 73
      },
      {
          "site": "VEMC Vehicle Energy Management Center Dearborn, MI",
          "buildings": 1,
          "areas": 17,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.01,
          "sow_audited": 100
      },
      {
          "site": "WPE Wagner Place East Dearborn, MI",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "WPW Wagner Place West Dearborn, MI",
          "buildings": 1,
          "areas": 110,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 3.26,
          "sow_audited": 14
      },
      {
          "site": "WT3 Wind Tunnel 3 Dearborn, MI",
          "buildings": 1,
          "areas": 36,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.72,
          "sow_audited": 52
      },
      {
          "site": "WT4&5 Wind Tunnel 4&5 Dearborn, MI",
          "buildings": 1,
          "areas": 43,
          "routes": 1,
          "sow_coverage": 98,
          "average_audit": 3.68,
          "sow_audited": 53
      }
      
  ]
},
{
  "client": "GM",
  "logo_url": "https://cdn.4insite.com/assets/1605312005.83312_GM_logo_t.jpg",
  "routes": 52,
  "sow_coverage": 96,
  "average_audit": 4.58,
  "sow_audited": 100,
  "sites": [{
          "site": "Milford PG, MI",
          "buildings": 52,
          "areas": 2585,
          "routes": 52,
          "sow_coverage": 96,
          "average_audit": 4.58,
          "sow_audited": 100
      }
  ]
},
{
  "client": "GSK",
  "logo_url": "https://cdn.4insite.com/assets/1607961353.7332714_GSK_t.jpeg",
  "routes": 6,
  "sow_coverage": 48,
  "average_audit": 4.11,
  "sow_audited": 58,
  "sites": [{
          "site": "Oak Hill NY",
          "buildings": 2,
          "areas": 114,
          "routes": 6,
          "sow_coverage": 48,
          "average_audit": 4.11,
          "sow_audited": 58
      }
  ]
},
{
  "client": "IQVIA",
  "logo_url": "https://cdn.4insite.com/assets/5fc2aad4b42343a4886a3ce342496b83_iqvialogocolor_t.png",
  "routes": 1,
  "sow_coverage": 48,
  "average_audit": 4.74,
  "sow_audited": 29,
  "sites": [{
          "site": "Bethlehem",
          "buildings": 1,
          "areas": 24,
          "routes": 1,
          "sow_coverage": 48,
          "average_audit": 4.44,
          "sow_audited": 50
      },
      {
          "site": "Durham",
          "buildings": 5,
          "areas": 829,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.77,
          "sow_audited": 28
      }
  ]
},
{
  "client": "Juniper",
  "logo_url": "https://cdn.4insite.com/assets/1605624533.566394_JN_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.77,
  "sow_audited": 50,
  "sites": [{
          "site": "Sunnyvale, CA",
          "buildings": 8,
          "areas": 1136,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.77,
          "sow_audited": 50
      }
  ]
},
{
  "client": "Lam Research",
  "logo_url": "https://cdn.4insite.com/assets/ee68f0369eec4944829b45f1ba8b5011_4insiteLam_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 3.95,
  "sow_audited": 71,
  "sites": [{
          "site": "Fremont",
          "buildings": 11,
          "areas": 701,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.71,
          "sow_audited": 62
      },
      {
          "site": "Hillsboro",
          "buildings": 1,
          "areas": 15,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.17,
          "sow_audited": 93
      },
      {
          "site": "Livermore",
          "buildings": 1,
          "areas": 65,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.87,
          "sow_audited": 40
      },
      {
          "site": "Tualatin",
          "buildings": 14,
          "areas": 436,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.17,
          "sow_audited": 91
      }
  ]
},
{
  "client": "McKesson",
  "logo_url": "https://cdn.4insite.com/assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
  "routes": 8,
  "sow_coverage": 8,
  "average_audit": 4.59,
  "sow_audited": 55,
  "sites": [{
          "site": "City of Industry",
          "buildings": 1,
          "areas": 6,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.15,
          "sow_audited": 100
      },
      {
          "site": "Clement, KY",
          "buildings": 1,
          "areas": 28,
          "routes": 3,
          "sow_coverage": 9,
          "average_audit": 4.67,
          "sow_audited": 100
      },
      {
          "site": "Cover My Meds 2",
          "buildings": 1,
          "areas": 233,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.30,
          "sow_audited": 36
      },
      {
          "site": "Cover My Meds Columbus",
          "buildings": 1,
          "areas": 310,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.21,
          "sow_audited": 61
      },
      {
          "site": "Ellabell, GA",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 5.00,
          "sow_audited": 100
      },
      {
          "site": "Fairdale",
          "buildings": 1,
          "areas": 12,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.32,
          "sow_audited": 100
      },
      {
          "site": "Gahanna",
          "buildings": 1,
          "areas": 15,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.13,
          "sow_audited": 80
      },
      {
          "site": "Groveport",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Interchange",
          "buildings": 1,
          "areas": 12,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.80,
          "sow_audited": 100
      },
      {
          "site": "Irving, TX",
          "buildings": 2,
          "areas": 939,
          "routes": 3,
          "sow_coverage": 6,
          "average_audit": 4.82,
          "sow_audited": 67
      },
      {
          "site": "Jacksonville",
          "buildings": 1,
          "areas": 223,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 5.00,
          "sow_audited": 3
      },
      {
          "site": "Manteno, IL",
          "buildings": 1,
          "areas": 35,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.26,
          "sow_audited": 94
      },
      {
          "site": "MOON TWP PA",
          "buildings": 1,
          "areas": 18,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.20,
          "sow_audited": 27
      },
      {
          "site": "Roseville",
          "buildings": 1,
          "areas": 13,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 4.24,
          "sow_audited": 100
      },
      {
          "site": "Santa Fe Springs",
          "buildings": 1,
          "areas": 7,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.51,
          "sow_audited": 100
      },
      {
          "site": "St. Petersburg",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      }
      
  ]
},
{
  "client": "Merck",
  "logo_url": "https://cdn.4insite.com/assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
  "routes": 109,
  "sow_coverage": 92,
  "average_audit": 4.00,
  "sow_audited": 44,
  "sites": [{
          "site": "Cherokee, PA",
          "buildings": 12,
          "areas": 162,
          "routes": 4,
          "sow_coverage": 100,
          "average_audit": 3.93,
          "sow_audited": 100
      },
      {
          "site": "Durham, NC",
          "buildings": 20,
          "areas": 185,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.89,
          "sow_audited": 38
      },
      {
          "site": "North Wales, PA",
          "buildings": 4,
          "areas": 63,
          "routes": 3,
          "sow_coverage": 100,
          "average_audit": 4.57,
          "sow_audited": 90
      },
      {
          "site": "Springhouse Plant, PA",
          "buildings": 1,
          "areas": 34,
          "routes": 3,
          "sow_coverage": 100,
          "average_audit": 4.52,
          "sow_audited": 100
      },
      {
          "site": "Upper Gwynedd, PA",
          "buildings": 5,
          "areas": 319,
          "routes": 8,
          "sow_coverage": 24,
          "average_audit": 4.34,
          "sow_audited": 92
      },
      {
          "site": "West Point, PA",
          "buildings": 43,
          "areas": 6145,
          "routes": 91,
          "sow_coverage": 96,
          "average_audit": 3.95,
          "sow_audited": 39
      }
      
  ]
},
{
  "client": "Meta",
  "logo_url": "https://cdn.4insite.com/assets/14d6f267a8cf4a209ac3764254e3c322_Image_Editor3_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.70,
  "sow_audited": 59,
  "sites": [{
          "site": "Bayfront",
          "buildings": 7,
          "areas": 1946,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.86,
          "sow_audited": 40
      },
      {
          "site": "Burlingame",
          "buildings": 6,
          "areas": 835,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.79,
          "sow_audited": 85
      },
      {
          "site": "Chilco",
          "buildings": 8,
          "areas": 386,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.76,
          "sow_audited": 61
      },
      {
          "site": "Classic",
          "buildings": 10,
          "areas": 1231,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.31,
          "sow_audited": 77
      },
      {
          "site": "Gateway",
          "buildings": 6,
          "areas": 429,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.79,
          "sow_audited": 20
      },
      {
          "site": "San Francisco",
          "buildings": 3,
          "areas": 986,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.68,
          "sow_audited": 70
      },
      {
          "site": "Sausalito",
          "buildings": 1,
          "areas": 56,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.72,
          "sow_audited": 58
      }
      
  ]
},
{
  "client": "Microsoft",
  "logo_url": "https://cdn.4insite.com/assets/1605312306.0531929_Artboard4_t.png",
  "routes": 313,
  "sow_coverage": 92,
  "average_audit": 4.34,
  "sow_audited": 17,
  "sites": [{
          "site": "2047 Lakewood Blvd",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Bothell, WA",
          "buildings": 1,
          "areas": 21,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.77,
          "sow_audited": 71
      },
      {
          "site": "CHICAGO 20",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "CHICAGO 22",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Chicago, IL",
          "buildings": 5,
          "areas": 355,
          "routes": 8,
          "sow_coverage": 99,
          "average_audit": 4.34,
          "sow_audited": 100
      },
      {
          "site": "Des Moines, IA",
          "buildings": 8,
          "areas": 302,
          "routes": 16,
          "sow_coverage": 73,
          "average_audit": 4.82,
          "sow_audited": 73
      },
      {
          "site": "DSM05",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Dulles, VA",
          "buildings": 3,
          "areas": 115,
          "routes": 5,
          "sow_coverage": 100,
          "average_audit": 4.60,
          "sow_audited": 100
      },
      {
          "site": "IAD11",
          "buildings": 1,
          "areas": 1,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Laguna South, WA",
          "buildings": 1,
          "areas": 56,
          "routes": 4,
          "sow_coverage": 100,
          "average_audit": 4.29,
          "sow_audited": 98
      },
      {
          "site": "Lincoln Square 2",
          "buildings": 1,
          "areas": 109,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.67,
          "sow_audited": 32
      },
      {
          "site": "Puget Sound, WA (CBRE)",
          "buildings": 83,
          "areas": 37885,
          "routes": 219,
          "sow_coverage": 92,
          "average_audit": 4.09,
          "sow_audited": 13
      },
      {
          "site": "Puget Sound, WA (Compass)",
          "buildings": 29,
          "areas": 531,
          "routes": 32,
          "sow_coverage": 61,
          "average_audit": 4.81,
          "sow_audited": 60
      },
      {
          "site": "Reno, NV",
          "buildings": 2,
          "areas": 230,
          "routes": 5,
          "sow_coverage": 95,
          "average_audit": 4.42,
          "sow_audited": 99
      },
      {
          "site": "San Francisco, CA",
          "buildings": 1,
          "areas": 129,
          "routes": 5,
          "sow_coverage": 100,
          "average_audit": 4.61,
          "sow_audited": 100
      },
      {
          "site": "Santa Clara, CA",
          "buildings": 9,
          "areas": 203,
          "routes": 14,
          "sow_coverage": 100,
          "average_audit": 4.60,
          "sow_audited": 100
      },
      {
          "site": "St. Louis, MO",
          "buildings": 1,
          "areas": 38,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 4.52,
          "sow_audited": 71
      },
      {
          "site": "Wenatchee, WA",
          "buildings": 1,
          "areas": 30,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.41,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "NVIDIA",
  "logo_url": "https://cdn.4insite.com/assets/1605311860.3280911_Artboard10_t.png",
  "routes": 80,
  "sow_coverage": 98,
  "average_audit": 4.89,
  "sow_audited": 59,
  "sites": [{
          "site": "Santa Clara, CA",
          "buildings": 14,
          "areas": 1985,
          "routes": 80,
          "sow_coverage": 98,
          "average_audit": 4.89,
          "sow_audited": 59
      }
      
  ]
},
{
  "client": "Organon",
  "logo_url": "https://cdn.4insite.com/assets/bd52cc204125445db6a196cad602f4ca_Organon_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.29,
  "sow_audited": 100,
  "sites": [{
          "site": "Springhouse, PA",
          "buildings": 1,
          "areas": 5,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.29,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "Pratt & Whitney",
  "logo_url": "https://cdn.4insite.com/assets/789a82307f294d5ea7c748b88c882565_3_t.jpg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.12,
  "sow_audited": 52,
  "sites": [{
          "site": "Asheville, NC",
          "buildings": 1,
          "areas": 2,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Jupiter, Fl",
          "buildings": 14,
          "areas": 309,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.12,
          "sow_audited": 52
      }
      
  ]
},
{
  "client": "Siemens",
  "logo_url": "https://cdn.4insite.com/assets/1607637318.9761856_Siemens_t.png",
  "routes": 2,
  "sow_coverage": 65,
  "average_audit": 4.50,
  "sow_audited": 100,
  "sites": [{
          "site": "Harleysville, PA",
          "buildings": 2,
          "areas": 53,
          "routes": 2,
          "sow_coverage": 65,
          "average_audit": 4.50,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "Sikorsky",
  "logo_url": "https://cdn.4insite.com/assets/ebf7179e73d34ec99956aa4f54bd6943_Image_Editor5_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.08,
  "sow_audited": 84,
  "sites": [{
          "site": "West Palm Beach",
          "buildings": 4,
          "areas": 84,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.08,
          "sow_audited": 84
      }
      
  ]
},
{
  "client": "State Farm",
  "logo_url": "https://cdn.4insite.com/assets/bceb470097b94120918c0fc266d302e1_11_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.65,
  "sow_audited": 28,
  "sites": [{
          "site": "Albony, OH",
          "buildings": 1,
          "areas": 45,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.78,
          "sow_audited": 20
      },
      {
          "site": "Alpharetta, GA",
          "buildings": 1,
          "areas": 47,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.80,
          "sow_audited": 10
      },
      {
          "site": "Columbia, MO",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Concordville, PA",
          "buildings": 1,
          "areas": 35,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.49,
          "sow_audited": 100
      },
      {
          "site": "Irving, TX",
          "buildings": 1,
          "areas": 42,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.47,
          "sow_audited": 47
      },
      {
          "site": "Jacksonville, Fl",
          "buildings": 1,
          "areas": 49,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Jollyville (Austin)",
          "buildings": 5,
          "areas": 96,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.43,
          "sow_audited": 53
      },
      {
          "site": "Kalamazoo, MI",
          "buildings": 1,
          "areas": 43,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 5.00,
          "sow_audited": 2
      },
      {
          "site": "Lincoln South",
          "buildings": 1,
          "areas": 54,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.94,
          "sow_audited": 100
      },
      {
          "site": "Malta, NY",
          "buildings": 1,
          "areas": 37,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Murfreesboro",
          "buildings": 1,
          "areas": 95,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.60,
          "sow_audited": 18
      },
      {
          "site": "Tulsa, OK",
          "buildings": 1,
          "areas": 17,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Winter Haven",
          "buildings": 1,
          "areas": 112,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      }
      
  ]
},
{
  "client": "Takeda",
  "logo_url": "https://cdn.4insite.com/assets/1605623711.8547332_ogp_logo_t.jpg",
  "routes": 45,
  "sow_coverage": 42,
  "average_audit": 4.29,
  "sow_audited": 66,
  "sites": [{
          "site": "Bannockburn",
          "buildings": 1,
          "areas": 106,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.61,
          "sow_audited": 68
      },
      {
          "site": "Cambridge Central",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Cambridge Kendall SQ",
          "buildings": 3,
          "areas": 1010,
          "routes": 23,
          "sow_coverage": 64,
          "average_audit": 4.10,
          "sow_audited": 66
      },
      {
          "site": "Central SQ",
          "buildings": 4,
          "areas": 1545,
          "routes": 21,
          "sow_coverage": 50,
          "average_audit": 4.38,
          "sow_audited": 58
      },
      {
          "site": "Exton",
          "buildings": 1,
          "areas": 96,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.53,
          "sow_audited": 71
      },
      {
          "site": "Lexington Columbia Construction",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Lexington, MA",
          "buildings": 16,
          "areas": 1046,
          "routes": 1,
          "sow_coverage": 0,
          "average_audit": 4.30,
          "sow_audited": 76
      }
      
  ]
},
{
  "client": "Tenaya",
  "logo_url": "https://cdn.4insite.com/assets/a68fea5f3e804ce2b1571142f2a6fa11_tenaya_therapeutics_logo_t.jpeg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.63,
  "sow_audited": 86,
  "sites": [{
          "site": "Bannockburn",
          "buildings": 1,
          "areas": 22,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.63,
          "sow_audited": 86
      }
      
  ]
},
{
  "client": "TGS",
  "logo_url": "https://cdn.4insite.com/assets/9860bdb84f0540ccb7875fa48053238b_1000003654_t.jpg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 2.63,
  "sow_audited": 100,
  "sites": [{
          "site": "Irvine",
          "buildings": 1,
          "areas": 8,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 2.63,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "Uber",
  "logo_url": "https://cdn.4insite.com/assets/1607636171.7063708_Uber_t.jpg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.52,
  "sow_audited": 48,
  "sites": [{
          "site": "Market Street",
          "buildings": 1,
          "areas": 279,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.97,
          "sow_audited": 12
      },
      {
          "site": "Mission Bay",
          "buildings": 5,
          "areas": 1324,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.65,
          "sow_audited": 52
      },
      {
          "site": "Sunnyvale",
          "buildings": 2,
          "areas": 243,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.23,
          "sow_audited": 70
      }
      
  ]
},
{
  "client": "Vantiva",
  "logo_url": "https://cdn.4insite.com/assets/75e64d0f01e5470495cc79f21e44e8e0_Image_Editor4_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.08,
  "sow_audited": 97,
  "sites": [{
          "site": "LaVergne",
          "buildings": 1,
          "areas": 20,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.05,
          "sow_audited": 70
      },
      {
          "site": "Memphis",
          "buildings": 4,
          "areas": 209,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.08,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "VMware",
  "logo_url": "https://sbmupdates.s3.amazonaws.com/clients_isolated/VMware.svg",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 4.02,
  "sow_audited": 100,
  "sites": [{
          "site": "Suntec",
          "buildings": 1,
          "areas": 77,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.02,
          "sow_audited": 100
      }
      
  ]
},
{
  "client": "Wells Fargo",
  "logo_url": "https://cdn.4insite.com/assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
  "routes": 212,
  "sow_coverage": 97,
  "average_audit": 4.17,
  "sow_audited": 73,
  "sites": [{
          "site": "3rd Brannan",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Campus Chandler, AZ",
          "buildings": 5,
          "areas": 877,
          "routes": 13,
          "sow_coverage": 100,
          "average_audit": 3.78,
          "sow_audited": 77
      },
      {
          "site": "Campus, MN",
          "buildings": 3,
          "areas": 330,
          "routes": 16,
          "sow_coverage": 100,
          "average_audit": 4.53,
          "sow_audited": 98
      },
      {
          "site": "Cedar Rapids Downtown",
          "buildings": 1,
          "areas": 48,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.16,
          "sow_audited": 91
      },
      {
          "site": "Central",
          "buildings": 1,
          "areas": 25,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.66,
          "sow_audited": 92
      },
      {
          "site": "Denver",
          "buildings": 1,
          "areas": 29,
          "routes": 4,
          "sow_coverage": 100,
          "average_audit": 4.00,
          "sow_audited": 96
      },
      {
          "site": "Denver Service Center",
          "buildings": 1,
          "areas": 28,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.00,
          "sow_audited": 100
      },
      {
          "site": "Des Moines",
          "buildings": 8,
          "areas": 489,
          "routes": 9,
          "sow_coverage": 100,
          "average_audit": 4.39,
          "sow_audited": 20
      },
      {
          "site": "East Town, MN",
          "buildings": 2,
          "areas": 776,
          "routes": 19,
          "sow_coverage": 100,
          "average_audit": 4.87,
          "sow_audited": 91
      },
      {
          "site": "Illinois",
          "buildings": 5,
          "areas": 88,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 3.63,
          "sow_audited": 59
      },
      {
          "site": "McKnight Road",
          "buildings": 1,
          "areas": 35,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 4.73,
          "sow_audited": 88
      },
      {
          "site": "Old Saint Anthony/OSA",
          "buildings": 1,
          "areas": 22,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.56,
          "sow_audited": 77
      },
      {
          "site": "Palo Alto",
          "buildings": 1,
          "areas": 50,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.59,
          "sow_audited": 72
      },
      {
          "site": "Philadelphia, PA",
          "buildings": 1,
          "areas": 151,
          "routes": 11,
          "sow_coverage": 100,
          "average_audit": 3.96,
          "sow_audited": 99
      },
      {
          "site": "Ridgedale",
          "buildings": 1,
          "areas": 26,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 4.60,
          "sow_audited": 100
      },
      {
          "site": "San Antonio, TX",
          "buildings": 11,
          "areas": 459,
          "routes": 42,
          "sow_coverage": 100,
          "average_audit": 4.70,
          "sow_audited": 80
      },
      {
          "site": "Shoreview Data Center",
          "buildings": 1,
          "areas": 26,
          "routes": 1,
          "sow_coverage": 100,
          "average_audit": 4.55,
          "sow_audited": 73
      },
      {
          "site": "Shoreview Operations CTR",
          "buildings": 1,
          "areas": 94,
          "routes": 9,
          "sow_coverage": 100,
          "average_audit": 3.86,
          "sow_audited": 46
      },
      {
          "site": "Sioux Falls Main",
          "buildings": 1,
          "areas": 16,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.36,
          "sow_audited": 93
      },
      {
          "site": "Sioux Falls OPS",
          "buildings": 1,
          "areas": 72,
          "routes": 6,
          "sow_coverage": 100,
          "average_audit": 4.23,
          "sow_audited": 77
      },
      {
          "site": "St. Louis, MO",
          "buildings": 5,
          "areas": 1229,
          "routes": 41,
          "sow_coverage": 100,
          "average_audit": 3.86,
          "sow_audited": 73
      },
      {
          "site": "Thomas Edison",
          "buildings": 1,
          "areas": 39,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 4.02,
          "sow_audited": 92
      },
      {
          "site": "Wayzata",
          "buildings": 1,
          "areas": 24,
          "routes": 2,
          "sow_coverage": 100,
          "average_audit": 4.50,
          "sow_audited": 95
      },
      {
          "site": "West Des Moines Campus",
          "buildings": 7,
          "areas": 615,
          "routes": 27,
          "sow_coverage": 95,
          "average_audit": 3.67,
          "sow_audited": 60
      }
      
  ]
},
{
  "client": "Wells Fargo Sub",
  "logo_url": "https://cdn.4insite.com/assets/320ac12a50b64a148d2b21f7138ea2d8_1_t.png",
  "routes": 0,
  "sow_coverage": 0,
  "average_audit": 3.49,
  "sow_audited": 19,
  "sites": [{
          "site": "Blings Ops",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Duluth PCS",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Lodi Vineyard",
          "buildings": 1,
          "areas": 18,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.00,
          "sow_audited": 94
      },
      {
          "site": "Los Angeles Cash Vault",
          "buildings": 1,
          "areas": 11,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 3.90,
          "sow_audited": 81
      },
      {
          "site": "Michigan",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "Moberly",
          "buildings": 0,
          "areas": 0,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      },
      {
          "site": "North Dakota",
          "buildings": 1,
          "areas": 105,
          "routes": 0,
          "sow_coverage": 0,
          "average_audit": 0,
          "sow_audited": 0
      }
      
  ]
}].filter(c => c.average_audit > 0);

export const cbreRetention = {
  "total": 2641,
  "tenureGroups": [
    {
      "rangeTitle": "< 6 Months",
      "rangeSubtitle": "Employed for less than 6 months",
      "percentage": 0.17,
      "total": 453,
      "positions": 29,
      "employees": [
        {
          "id": 92287,
          "person": {
            "id": 91553,
            "first_name": "Adan",
            "last_name": "Flores",
            "photo_url": "assets/482fab34cb204558b8fa7e83e90a6941_Adan2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "176eef56-f1d9-4676-86bb-c418206407f7"
        },
        {
          "id": 94058,
          "person": {
            "id": 93259,
            "first_name": "Adelfa",
            "last_name": "Rodriguez",
            "photo_url": "assets/17d0f645e39e4913b0f2cb20355fa003_Adolfa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0933fe65-4b6b-426f-afaa-f5137ab4330d"
        },
        {
          "id": 92138,
          "person": {
            "id": 91413,
            "first_name": "Adrian",
            "last_name": "Ipanaque Nizama",
            "photo_url": "assets/7b5dcdb73764425a91dd96f12ab8acf5_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4bc4b54b-ea47-4c9b-8869-d2aea3d38761"
        },
        {
          "id": 90214,
          "person": {
            "id": 89505,
            "first_name": "Adriana",
            "last_name": "Bonilla",
            "photo_url": "assets/4e464f162d9848e7888cc535a9832af7_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f7d169e9-499f-48ab-82e1-5f3f61100707"
        },
        {
          "id": 89418,
          "person": {
            "id": 88713,
            "first_name": "Ahjanee",
            "last_name": "Hollis",
            "photo_url": "assets/b023bd3e186f4a198bc6a16b8730b43e_IMG_4897_t.jpeg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fe37b426-fd29-46e9-a433-30711ef0f871"
        },
        {
          "id": 92844,
          "person": {
            "id": 92078,
            "first_name": "Aide",
            "last_name": "Vargas",
            "photo_url": "assets/9addcb2fa9fe459abcfbe193cd5a5b4e_1000002805_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a26c6963-966f-4a0d-9676-b55be52c6989"
        },
        {
          "id": 71498,
          "person": {
            "id": 70964,
            "first_name": "Akram Ahmed Mohammad",
            "last_name": "Alattab",
            "photo_url": "assets/e3b26163994b44be8f5affdd2429cf42_ScreenShot20220609at5.33_t.37PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "683d0331-e82c-4e86-a01e-a5459b78fc06"
        },
        {
          "id": 94867,
          "person": {
            "id": 94067,
            "first_name": "Alberto",
            "last_name": "Mbumba",
            "photo_url": "assets/rc074f8832b3d41a08bd72386edc1356e_temp_file_.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1643e071-0b29-4d7c-bbcd-25220b3bf73e"
        },
        {
          "id": 90171,
          "person": {
            "id": 89462,
            "first_name": "Alexia",
            "last_name": "Daglow",
            "photo_url": "assets/39d5160bcc7f4aca93bd7289381016dd_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65df613a-85c5-4ec0-b80d-bcd05f916e35"
        },
        {
          "id": 92601,
          "person": {
            "id": 91835,
            "first_name": "Alexia",
            "last_name": "Eason",
            "photo_url": "assets/a2dadc433d764ff593f807e98a1edd29_IMG_4910_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1aaaff39-d904-458f-bf6c-638b892d6000"
        },
        {
          "id": 91203,
          "person": {
            "id": 90484,
            "first_name": "Alicia",
            "last_name": "Tyree",
            "photo_url": "assets/e45a28e3002b4b7f96cbf42a87dc03bd_IMG_0626_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7956,
              "name": "Shift C",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d300b43-9508-45ea-9878-969efd033af2"
        },
        {
          "id": 91986,
          "person": {
            "id": 91261,
            "first_name": "Alisha",
            "last_name": "Metcalf",
            "photo_url": "assets/a50d30ff9c9a4f7894bb04337926e8e1_20240305_103646_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf413db0-eb36-4016-aa70-a2e869322e08"
        },
        {
          "id": 93781,
          "person": {
            "id": 92982,
            "first_name": "Aliver",
            "last_name": "Elizondo",
            "photo_url": "assets/2c255452075840f0b48a9e6800722b24_aliver_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "673b369f-1070-4d23-a605-d9c581777d9e"
        },
        {
          "id": 93551,
          "person": {
            "id": 92752,
            "first_name": "Allen",
            "last_name": "Cornett",
            "photo_url": "assets/e2791ea768b04b1eb1af2dc48279538a_StandardMale221_t.png",
            "phone_number": "4705768377",
            "email_address": "acornett@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54282407-fc8b-4e7c-bd74-830da99fe491"
        },
        {
          "id": 72879,
          "person": {
            "id": 72342,
            "first_name": "Al Seddiq",
            "last_name": "Murshed Qasem",
            "photo_url": "assets/3dc975bdfac340a39a4b0e3a3e733931_ScreenShot20220817at5.52_t.31PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9039301c-91de-4da3-bc79-41e41707b71a"
        },
        {
          "id": 94178,
          "person": {
            "id": 93379,
            "first_name": "Alseen",
            "last_name": "Daniel",
            "photo_url": "assets/031b18f981004340883cfcdf8140014a_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8783,
              "name": "Swing",
              "contract": {
                "id": 6076,
                "name": "Cover My Meds 2",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1778756c-07fd-43f6-95a3-3ea006627ab2"
        },
        {
          "id": 89654,
          "person": {
            "id": 88949,
            "first_name": "Amadelia",
            "last_name": "Valle",
            "photo_url": "assets/r9a9b2081f9da4fb296757daec33b0a30_16964434059926868957377690406225_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33a34327-c4ef-4deb-a10a-dad50ea7f417"
        },
        {
          "id": 93521,
          "person": {
            "id": 92722,
            "first_name": "Amayda",
            "last_name": "Rojas Moran",
            "photo_url": "assets/3b7bfdfa7be74b28b50533a066be1c76_1000002410_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd19595b-589c-4844-97cf-a999c2f7e2b8"
        },
        {
          "id": 93614,
          "person": {
            "id": 92815,
            "first_name": "Amina",
            "last_name": "Ahmed",
            "photo_url": "assets/454046d7edb14ee5b55fd41bc3fe9322_FemalePFP_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "584171cd-cbde-4f00-9691-d6b026bc15df"
        },
        {
          "id": 92135,
          "person": {
            "id": 91410,
            "first_name": "Amivi",
            "last_name": "Abalo",
            "photo_url": "assets/r5e8a5e2230e642768afce318d371173f_Berys_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27363350-3876-41f0-9b4d-5bbb4c21250b"
        },
        {
          "id": 93769,
          "person": {
            "id": 92970,
            "first_name": "Amparo",
            "last_name": "Lachos",
            "photo_url": "assets/ea5ad3e1cc3e40229142e78ee10f23e6_AmaparoLacho_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56aed70a-ca91-4205-85f6-b45637cfafdf"
        },
        {
          "id": 91911,
          "person": {
            "id": 91187,
            "first_name": "Ana",
            "last_name": "Avila",
            "photo_url": "assets/r6aeafaca060848df964300ef8df0f2c9_ana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19b86152-f601-4bf7-b5a5-5050e853b478"
        },
        {
          "id": 92484,
          "person": {
            "id": 91718,
            "first_name": "Anais",
            "last_name": "Guerrero",
            "photo_url": "assets/15a3e09c37c547a7be6c82b26529f94e_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5060,
              "name": "Swing",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b8197a10-0494-4cd2-aa5d-1fc0149d2019"
        },
        {
          "id": 90451,
          "person": {
            "id": 89738,
            "first_name": "Andrea",
            "last_name": "Alvarez Coronado",
            "photo_url": "assets/16c152d1963b40e88fbf6f6b2dc70f56_unnamed_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1165,
              "name": "Site Admin Asst",
              "name_abbreviation": "ADMIN"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2ef699da-f10b-4de4-86e6-83dc347490f1"
        },
        {
          "id": 91269,
          "person": {
            "id": 90550,
            "first_name": "Andrea",
            "last_name": "Robinson",
            "photo_url": "assets/b7bfe12076884ef28e6d2f96e7f12871_AndreaM.Robinson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90bc9b85-8cbb-4fa8-830c-6bf2329ef37f"
        },
        {
          "id": 46235,
          "person": {
            "id": 45773,
            "first_name": "Andrew",
            "last_name": "Hernandez",
            "photo_url": "assets/1617824654.8005874_AndrewHSBMpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2651,
              "name": "Swing",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d09891f2-c238-4695-8024-bc979562c70d"
        },
        {
          "id": 97103,
          "person": {
            "id": 96304,
            "first_name": "Andrew",
            "last_name": "Martinez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61fb282f-d90a-493a-a70d-5b9b59d98136"
        },
        {
          "id": 92981,
          "person": {
            "id": 92215,
            "first_name": "Andrew",
            "last_name": "Morgan",
            "photo_url": "assets/4b50a6f5cdd34c87982c975909ca44e0_AndrewMorgan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2fc1f5c5-2db5-4705-96b9-e3e715d50c0f"
        },
        {
          "id": 91547,
          "person": {
            "id": 90829,
            "first_name": "Angel",
            "last_name": "Rivera Garcia",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3034,
              "name": "Day",
              "contract": {
                "id": 2586,
                "name": "Mason",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3258a9ab-14f6-4a92-9b62-3dfe1675c919"
        },
        {
          "id": 93757,
          "person": {
            "id": 92958,
            "first_name": "Angela",
            "last_name": "Garcia",
            "photo_url": "assets/357ae7c9ebfa4a93a7cc43d44c552b01_AngelaGarcia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd01eade-cc19-444c-930a-a8298a031a11"
        },
        {
          "id": 94146,
          "person": {
            "id": 93347,
            "first_name": "Anjelina",
            "last_name": "Cao",
            "photo_url": "assets/2acb2b25b4ff46b1af341f1e49d86302_20240305_110739_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "603cecf4-0287-49ed-a93d-208fd5d06773"
        },
        {
          "id": 96841,
          "person": {
            "id": 96042,
            "first_name": "Annette",
            "last_name": "Chaparro",
            "photo_url": "assets/ra0df0d8fb5f44a7ba8974aef2139968c_image0000015_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b887ae69-48a9-47b5-ad6d-2e9c7d2611b5"
        },
        {
          "id": 91298,
          "person": {
            "id": 90580,
            "first_name": "Anthony",
            "last_name": "Conner",
            "photo_url": "assets/d5b2af6cb2d944e1b0bc78444ce77428_IMG_1150_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8257b58c-7e08-40f3-beef-aea89e44d0b0"
        },
        {
          "id": 92090,
          "person": {
            "id": 91365,
            "first_name": "Anthony",
            "last_name": "Edge",
            "photo_url": "assets/c264eea96fdf47ba95a655b1163b07e1_ProfilePicturev2_t.jpg",
            "phone_number": "4254444677",
            "email_address": "aedge@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1176,
              "name": "Mgr, Operations",
              "name_abbreviation": "OPMGR"
            }
          ],
          "shifts": [
            {
              "id": 4384,
              "name": "None",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27824ed1-7ece-4e21-a9cf-e78d684301ff"
        },
        {
          "id": 95139,
          "person": {
            "id": 94338,
            "first_name": "Anthony",
            "last_name": "Espana",
            "photo_url": "assets/37f983999be742f592a35d514c4443e1_MalePFP_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1caf7bda-b72b-40ce-994c-a41a8afd3d57"
        },
        {
          "id": 96542,
          "person": {
            "id": 95743,
            "first_name": "Antonio",
            "last_name": "Galvan Godinez",
            "photo_url": "assets/df726c7cd8714238a8d71183e680a54c_85HpQe5D83894038_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b76aac2-9120-498f-8ea4-c0bcef19b32b"
        },
        {
          "id": 46304,
          "person": {
            "id": 45835,
            "first_name": "Arismelt",
            "last_name": "Medrano Sanchez",
            "photo_url": "assets/1617307858.1884704_Resized_20210401_152016_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4525,
              "name": "Day",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e5a5b74-33d1-4d60-82cf-7bc26727891f"
        },
        {
          "id": 93634,
          "person": {
            "id": 92835,
            "first_name": "Arnoldo",
            "last_name": "Nunez",
            "photo_url": "assets/c4a0cedc304a4ce5823e773cfd378cd2_Arnoldo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9fef305b-2b46-4464-af52-9a158a27b378"
        },
        {
          "id": 95087,
          "person": {
            "id": 94287,
            "first_name": "Artemiza",
            "last_name": "Miranda Fuentes De Coronado",
            "photo_url": "assets/r32facef4bca0475785eba952b3166a9c_20240206_161817_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5bcfd72-32de-467c-a5b1-98a37601de95"
        },
        {
          "id": 41955,
          "person": {
            "id": 41547,
            "first_name": "Arthur",
            "last_name": "Tucker",
            "photo_url": "assets/1614706250.2043734_ArthurTucker_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8870,
              "name": "Shift A",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "063809a2-1983-4879-b050-66957cd60841"
        },
        {
          "id": 95089,
          "person": {
            "id": 94289,
            "first_name": "Arturo",
            "last_name": "Llamas-Sotelo",
            "photo_url": "assets/rd81771025e83426ebd2d03df9ffe582f_20240206_161829_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2675693d-e1fe-4356-b717-ab95c558d75b"
        },
        {
          "id": 97214,
          "person": {
            "id": 96415,
            "first_name": "Ashley",
            "last_name": "Escareno",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e13d7d8-aa11-4159-94f2-75b04f7c43e7"
        },
        {
          "id": 95090,
          "person": {
            "id": 94290,
            "first_name": "Ayurami",
            "last_name": "Villalobos",
            "photo_url": "assets/r704bcee2d95b493d9c95fefb3bf6da14_20240206_161909_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "762f0edd-45a2-4ff2-a0a8-02b613f3d919"
        },
        {
          "id": 73866,
          "person": {
            "id": 73321,
            "first_name": "Azzam",
            "last_name": "Hussein",
            "photo_url": "assets/eb45c118a04e4a91bb3be6675004a8b5_ScreenShot20220922at11.07_t.12AM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28625c75-0f3f-4ade-8e1e-d21cd0af248d"
        },
        {
          "id": 93607,
          "person": {
            "id": 92808,
            "first_name": "Bakar",
            "last_name": "Muya",
            "photo_url": "assets/acf4f2585006437ca924dc9baf97e0cb_BMuya4insite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e71c89b-ee21-4dc7-82eb-f62f8529687b"
        },
        {
          "id": 72255,
          "person": {
            "id": 71718,
            "first_name": "Bandar Abdo",
            "last_name": "Al Zabedi",
            "photo_url": "assets/3a7403d215ef47b78d4e27800195cb3f_ScreenShot20220609at5.30_t.19PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f68e5c3-1ee2-49e2-9767-6941d988cbd5"
        },
        {
          "id": 97334,
          "person": {
            "id": 96535,
            "first_name": "Bellanira",
            "last_name": "Pineda",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "70c0fd88-3fcc-4436-b366-e14af37abfe0"
        },
        {
          "id": 89578,
          "person": {
            "id": 88873,
            "first_name": "Benedict",
            "last_name": "Ojastro",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7486b4ca-955d-4414-90e7-4c9268b80b4f"
        },
        {
          "id": 89231,
          "person": {
            "id": 88526,
            "first_name": "Berenice",
            "last_name": "Garcia",
            "photo_url": "assets/re8557a33e12e4123a8dce5810894e134_IMG_7939_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7833,
              "name": "Weekend-Graveyard",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a15fd9b3-4764-4842-b713-c04f3038ead1"
        },
        {
          "id": 94061,
          "person": {
            "id": 93262,
            "first_name": "Bernardo",
            "last_name": "Moreno",
            "photo_url": "assets/99878d5307fa42a5b5a9592f5aa0d4f1_Bernardo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "55728bd6-d72c-41eb-bd1d-281e1a77e3b9"
        },
        {
          "id": 92136,
          "person": {
            "id": 91411,
            "first_name": "Berys",
            "last_name": "Peguero",
            "photo_url": "assets/r980020b1a63a41faa02ce670fe361306_Berys_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c4c9983-960c-43d5-aa97-0a2629960196"
        },
        {
          "id": 91680,
          "person": {
            "id": 90960,
            "first_name": "Billy",
            "last_name": "Perrine",
            "photo_url": "assets/601eb4c8597e4a93935911591e79bcb2_20231115_001640_t.jpg",
            "phone_number": "4192342283",
            "email_address": "bperrine@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7954,
              "name": "Shift A2",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04cf54ba-e00d-443b-9710-03812e0f1173"
        },
        {
          "id": 90786,
          "person": {
            "id": 90071,
            "first_name": "Blanca",
            "last_name": "Gonzalez Martinez",
            "photo_url": "assets/c34bc55ade534fa28f97b4123eac5720_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7745,
              "name": "Swing",
              "contract": {
                "id": 1819,
                "name": "Sun City",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04ad5929-bf48-4343-8f2e-114e234752a0"
        },
        {
          "id": 93775,
          "person": {
            "id": 92976,
            "first_name": "Blanca",
            "last_name": "Martinez",
            "photo_url": "assets/54372c0dd441406f9c731774ca5c2bff_BlancaMartinez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea1881c2-eb97-4af5-b6db-8397a79697f3"
        },
        {
          "id": 9909,
          "person": {
            "id": 9909,
            "first_name": "Blanca",
            "last_name": "Segura De Solorzano",
            "photo_url": "assets/34005d0d85774713b4b3c17e10b3b26f_Blanca_t.jpg",
            "phone_number": "",
            "email_address": "228603@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "93122d69-7c09-40fd-ad84-4e0b8ad9de38"
        },
        {
          "id": 96771,
          "person": {
            "id": 95971,
            "first_name": "Blanca",
            "last_name": "Torres Gonzalez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5060,
              "name": "Swing",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87d281fa-24fd-4847-bf8a-eaddbf9c3689"
        },
        {
          "id": 96129,
          "person": {
            "id": 95330,
            "first_name": "Brandon",
            "last_name": "Travis",
            "photo_url": "assets/fbf06d8998d74a2e83030fde8302bb27_MalePFP_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "705ec717-3dd5-4158-b0ee-372ef026d307"
        },
        {
          "id": 91189,
          "person": {
            "id": 90470,
            "first_name": "Brandon",
            "last_name": "Zwetow-Backus",
            "photo_url": "assets/8b23062ac29440c0b9ea7f76e6d862a8_BZwetow_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e91c818-2096-4a69-9c11-5573e4b2fe5a"
        },
        {
          "id": 91200,
          "person": {
            "id": 90481,
            "first_name": "Brenda",
            "last_name": "Benjamin",
            "photo_url": "assets/c7d2e9d17ec14c479b50c401db0db2cf_IMG_0617_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7954,
              "name": "Shift A2",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac867a0a-642a-458a-998f-48b594ef1977"
        },
        {
          "id": 94491,
          "person": {
            "id": 93691,
            "first_name": "Brett",
            "last_name": "Pettiford",
            "photo_url": "assets/42fd039e6d744f1e9a23ea90cdad2a5e_Brett4insitephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f00a7910-4f0b-4b73-9ade-dde689ddf86b"
        },
        {
          "id": 92319,
          "person": {
            "id": 91585,
            "first_name": "Brian",
            "last_name": "Anderson",
            "photo_url": "assets/55ad258f40564119aa9b246e50b04577_1000001955_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "df4cf7be-96ed-43e8-a2c2-be639c464d82"
        },
        {
          "id": 96010,
          "person": {
            "id": 95210,
            "first_name": "Brian",
            "last_name": "Garcia",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ccd7683-7daa-42d2-9142-f5832a777507"
        },
        {
          "id": 91208,
          "person": {
            "id": 90489,
            "first_name": "Brianna",
            "last_name": "Dunnigan",
            "photo_url": "assets/76180eabf2b144b483a2548f7aee9d2b_Image102023at9.56AM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "82938ab1-47de-4dd0-a241-7586700bd31c"
        },
        {
          "id": 96272,
          "person": {
            "id": 95472,
            "first_name": "Brigida",
            "last_name": "Villalobos",
            "photo_url": "assets/5704ab6c8c1645f9888675c3aee2cd9c_BrigidaVillalobos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e52f9ff-4480-4b1c-af07-bfdd498d84b0"
        },
        {
          "id": 93985,
          "person": {
            "id": 93186,
            "first_name": "Brineka",
            "last_name": "Bailey",
            "photo_url": "assets/2bfa76c0a488406bb3fef59051907547_IMG_5671_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e68b3d9-8d73-4c90-a9b5-68577d3d537c"
        },
        {
          "id": 89594,
          "person": {
            "id": 88889,
            "first_name": "Brittany",
            "last_name": "Willis",
            "photo_url": "assets/f4faa381b3574fc1a99deb635ca3dcbf_BWillisInsitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "08f76e57-fc1c-4006-b515-bba7def5f0a1"
        },
        {
          "id": 91207,
          "person": {
            "id": 90488,
            "first_name": "Brittney",
            "last_name": "Gambrel",
            "photo_url": "assets/22d0122756de42cdb5a21f07f5772d58_IMG_0611_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7955,
              "name": "Shift B",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9fa636c-965f-40d5-9486-c9e0ec207aa7"
        },
        {
          "id": 89850,
          "person": {
            "id": 89144,
            "first_name": "Brooke",
            "last_name": "Bodnar",
            "photo_url": "assets/ba84ba765de44fc4a2e111cdb80e87f1_Id_t.png",
            "phone_number": "+15153880454",
            "email_address": "bbodnar@sbmcorp.com"
          },
          "positions": [
            {
              "id": 41,
              "name": "Asst Mgr, Operations",
              "name_abbreviation": "AOPMGR"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8bc84732-2f8e-4291-b1f3-e79acfce81d2"
        },
        {
          "id": 94176,
          "person": {
            "id": 93377,
            "first_name": "Caiden",
            "last_name": "Paauwe",
            "photo_url": "assets/1615ec8f5ab14478bf0409195e5d7f32_Headshot_t.jpg",
            "phone_number": "+13176496718",
            "email_address": ""
          },
          "positions": [
            {
              "id": 41,
              "name": "Asst Mgr, Operations",
              "name_abbreviation": "AOPMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dab7c664-0ad7-48a0-b7f8-08a1ae55f0bf"
        },
        {
          "id": 96754,
          "person": {
            "id": 95954,
            "first_name": "Caleb",
            "last_name": "Shaw",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "69291eb6-4188-48b8-8e72-7f318c99cd1a"
        },
        {
          "id": 91090,
          "person": {
            "id": 90372,
            "first_name": "Carl",
            "last_name": "Lavoncher",
            "photo_url": "assets/13f09791d98040708f9a98cdd4f4420f_carlpic_t.jpg",
            "phone_number": "+13177525408",
            "email_address": "clavoncher@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a2f15878-8bce-4e97-a58e-26875c1d68fb"
        },
        {
          "id": 89580,
          "person": {
            "id": 88875,
            "first_name": "Carlos",
            "last_name": "Aponte",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7fecdf23-62f6-496b-895f-6e0251cc05f0"
        },
        {
          "id": 92100,
          "person": {
            "id": 91375,
            "first_name": "Carlos",
            "last_name": "Morales Avelar",
            "photo_url": "assets/a2f23f3e12864f038b7dd765c6504cb4_Carlos3_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34c6fca2-13b6-4365-8eb2-bd10abc1ff5d"
        },
        {
          "id": 97270,
          "person": {
            "id": 96471,
            "first_name": "Carlos",
            "last_name": "Vega",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "36847103-b820-4911-bc07-29836bd3b07e"
        },
        {
          "id": 89576,
          "person": {
            "id": 88871,
            "first_name": "Carolina",
            "last_name": "Cardona Lainez",
            "photo_url": "assets/967ec055614946e1878f06b931e796d8_Carolina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24e3154d-322d-4b27-8bcc-becfc4910ed7"
        },
        {
          "id": 11775,
          "person": {
            "id": 11775,
            "first_name": "Carolina",
            "last_name": "Murcia",
            "photo_url": "assets/r11f3599029df41d3a5cfc51d386aafa9_CarolinaMurcia_t.jpeg",
            "phone_number": "9737518709",
            "email_address": "103126@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2419,
              "name": "Swing",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5bd1907e-6e64-46e0-9f91-3efd36f00bb7"
        },
        {
          "id": 94019,
          "person": {
            "id": 93220,
            "first_name": "Cassius",
            "last_name": "Mccoy",
            "photo_url": "assets/r1f1f97da8c63497cbbb0ea8d5e29b07d_IMG_2655_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2522,
              "name": "On-Call",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d9ab691-dc96-4792-90d4-9dfa88848a38"
        },
        {
          "id": 92716,
          "person": {
            "id": 91950,
            "first_name": "Cecilia",
            "last_name": "Fregoso",
            "photo_url": "assets/0f0205c0b7cb4def91363f34dcb01326_1600981067826_t.jfif",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 8334,
              "name": "None",
              "contract": {
                "id": 5530,
                "name": "Wenatchee, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "029b73f0-6784-4b5e-bd60-6feba94292d9"
        },
        {
          "id": 90583,
          "person": {
            "id": 89868,
            "first_name": "Celia",
            "last_name": "Gonzalez",
            "photo_url": "assets/a8ade9a655704ffeb571ed84efa3d61b_AvatarFemaleStandardInsite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7867,
              "name": "Night",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a3bc86a-dba6-4212-a502-ff82a274948b"
        },
        {
          "id": 9684,
          "person": {
            "id": 9684,
            "first_name": "Cenia",
            "last_name": "Guevara Alvarez",
            "photo_url": "0f7a3119-d429-ee89-05e0-15bdf988b250.png",
            "phone_number": "",
            "email_address": "227975@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7996,
              "name": "On-Call",
              "contract": {
                "id": 2664,
                "name": "Jacksonville FL",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b5e2836-cab0-4d26-90fa-32ce058175bf"
        },
        {
          "id": 94266,
          "person": {
            "id": 93466,
            "first_name": "Cesar",
            "last_name": "Armijos",
            "photo_url": "assets/0d18069ac2af47959d612f8f9f95bb55_CesarAphotoforbadge_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a6f6ff2-6db7-4592-af7a-f456bec5efef"
        },
        {
          "id": 92644,
          "person": {
            "id": 91876,
            "first_name": "Chaepin",
            "last_name": "Jones",
            "photo_url": "assets/99dbe7e1453b45ffb6b5de758fb4f068_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b961cad8-1a77-4bfe-80cb-e78412e81a56"
        },
        {
          "id": 96669,
          "person": {
            "id": 95870,
            "first_name": "Christina",
            "last_name": "Boyer",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39e969a0-f25b-4962-98b3-c8b861b936dc"
        },
        {
          "id": 94060,
          "person": {
            "id": 93261,
            "first_name": "Claudia",
            "last_name": "Del Cid",
            "photo_url": "assets/0045455275644e9a9a5ad17d4ebabcda_Claudia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0880de20-ab6f-4e08-acec-4373cbcf4af1"
        },
        {
          "id": 94639,
          "person": {
            "id": 93839,
            "first_name": "Claudia",
            "last_name": "Estrada Sarinana",
            "photo_url": "assets/9cdf65773193446ea16cf687d90be58c_ClaudiaEstrada_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "23153b95-e786-4c89-a07d-c8e6a3aff48f"
        },
        {
          "id": 91550,
          "person": {
            "id": 90832,
            "first_name": "Claudia",
            "last_name": "Mendoza",
            "photo_url": "assets/76135dbe59064a768c6d0dd620238a10_20240201_122044_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3064,
              "name": "Swing",
              "contract": {
                "id": 2163,
                "name": "Harleysville, PA",
                "customer_id": 311,
                "customer_name": "Siemens (CBRE)",
                "uuid": "9e1ba37b-3af4-446a-9c9b-ac7e4baa4825",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2547a6f2-e7fb-4240-8dba-e7473a297fcc"
        },
        {
          "id": 89666,
          "person": {
            "id": 88961,
            "first_name": "Concepcion",
            "last_name": "Sanabria",
            "photo_url": "assets/8fa20978b10d4a3cabd1dd9a9daa9ec6_20230919_200112_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d393d73f-3e71-47eb-a0cd-8686839b4e8d"
        },
        {
          "id": 95067,
          "person": {
            "id": 94267,
            "first_name": "Cory",
            "last_name": "Lott",
            "photo_url": "assets/3ca02fea63694ebf98fa3403a7681c4e_Cory_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d90b6f77-105b-4b5f-a353-ad62782bc6fc"
        },
        {
          "id": 91508,
          "person": {
            "id": 90790,
            "first_name": "Courtney",
            "last_name": "Jeter",
            "photo_url": "assets/333c1f1a1cb944eda69abf17f11fc1e6_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c109d56c-748a-4088-af36-e95dfd777188"
        },
        {
          "id": 93635,
          "person": {
            "id": 92836,
            "first_name": "Cruz",
            "last_name": "Esquivel",
            "photo_url": "assets/7a54273df6ee43bf81774389fccda90f_CruzE_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f54cf0bb-7c70-4637-857d-d6ac1a095ef2"
        },
        {
          "id": 94845,
          "person": {
            "id": 94045,
            "first_name": "Crystal",
            "last_name": "Santos",
            "photo_url": "assets/22cc2fe178a548c6807f3cddc8712316_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "46e5c081-cb4a-4dc6-84eb-f4de8f360376"
        },
        {
          "id": 93986,
          "person": {
            "id": 93187,
            "first_name": "Curtesia",
            "last_name": "Williams",
            "photo_url": "assets/d793f744d45d4f25a9bda17a8478aebc_IMG_5670_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "578a6fce-6b84-4970-aa81-a034dd4aeeff"
        },
        {
          "id": 94810,
          "person": {
            "id": 94010,
            "first_name": "Daja",
            "last_name": "Walker",
            "photo_url": "assets/74f505ed62134ac993427bfcc80db4c6_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65baaf6a-c277-4141-bd3f-ed84b8390538"
        },
        {
          "id": 92722,
          "person": {
            "id": 91956,
            "first_name": "Damaris",
            "last_name": "Rivas De Escobar",
            "photo_url": "assets/c059d2d0fe8a49509817866b57e160c7_IMG_0156_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7576,
              "name": "On-Call",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92a21794-2b24-4768-aafc-a6d447b4a0e7"
        },
        {
          "id": 92415,
          "person": {
            "id": 91670,
            "first_name": "Dan",
            "last_name": "Gonce",
            "photo_url": "assets/r7fc4472257bc4317b3cdadb8e5235dee_Me_t.jpg",
            "phone_number": "",
            "email_address": "dgonce@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2841,
              "name": "Day",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a0a0bb5b-e173-4609-a882-97ff79d98b7e"
        },
        {
          "id": 93458,
          "person": {
            "id": 92659,
            "first_name": "Daniel",
            "last_name": "Cerrillo",
            "photo_url": "assets/5493136c5ebd4f328bee944232839e64_1000000742_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ecb45671-6683-4066-9d25-7110e3e1dc7f"
        },
        {
          "id": 73567,
          "person": {
            "id": 73027,
            "first_name": "David",
            "last_name": "Bush",
            "photo_url": "assets/bd1b4f6ac51748a4b524ef63fe6e95fb_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3104768-fe07-4a0b-94e2-fc0a0d697981"
        },
        {
          "id": 95096,
          "person": {
            "id": 94296,
            "first_name": "Davona",
            "last_name": "Cochran",
            "photo_url": "assets/f969db99afbe482d9d4edb1d116f5ba6_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ec155fa-ad91-4bf5-9c2f-30b98d6866a9"
        },
        {
          "id": 46311,
          "person": {
            "id": 45842,
            "first_name": "Daymary",
            "last_name": "Serrat Govin",
            "photo_url": "assets/1617301948.0205736_IMG20210331WA0007_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4525,
              "name": "Day",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "53d4b5d0-68df-4a22-8cdc-0835fb9c7719"
        },
        {
          "id": 91205,
          "person": {
            "id": 90486,
            "first_name": "Dennis",
            "last_name": "Howard",
            "photo_url": "assets/48cd2cc7020c475e8adecdd438881c8a_IMG_0618_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb27bbc3-77e4-4998-8e4a-b8b33dfb2679"
        },
        {
          "id": 93554,
          "person": {
            "id": 92755,
            "first_name": "Deronne",
            "last_name": "Guice",
            "photo_url": "assets/3f7bc6d9e5564d91bc9384b8288af3ea_DeronneGuicePhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6aeba793-396f-4438-befd-16f28a20e505"
        },
        {
          "id": 89653,
          "person": {
            "id": 88948,
            "first_name": "Derrick",
            "last_name": "Clay",
            "photo_url": "assets/r12b88595c02042479557a5c9603d779f_16964445777201079814116603541689_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81bc8fee-f131-4351-925b-5073305bae52"
        },
        {
          "id": 91201,
          "person": {
            "id": 90482,
            "first_name": "DeShay",
            "last_name": "Wright",
            "photo_url": "assets/76fd9173615a49a7a6852464015daf99_IMG_0624_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7955,
              "name": "Shift B",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7321203e-cda6-440e-9fd3-25798d69f4fb"
        },
        {
          "id": 91649,
          "person": {
            "id": 90929,
            "first_name": "DeVante",
            "last_name": "Williams",
            "photo_url": "assets/r6e639a96beb44c6da0bb39026bd5a61b_D_t.png",
            "phone_number": "4193038711",
            "email_address": "dwilliams2@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 7955,
              "name": "Shift B",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "22649fbb-8566-43f0-9196-4d421a3e9c1e"
        },
        {
          "id": 92604,
          "person": {
            "id": 91838,
            "first_name": "Dexter",
            "last_name": "Whiteside",
            "photo_url": "assets/b182aec73eaf4b3dbd35ff82b2482775_IMG_4943_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76d93924-a6a9-432d-961a-24332df18d26"
        },
        {
          "id": 91463,
          "person": {
            "id": 90745,
            "first_name": "Diana",
            "last_name": "Acosta Botero",
            "photo_url": "assets/59b03f9225244acea9af7567694bd457_DianaPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5194,
              "name": "On-Call",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a8571d25-3514-45d2-b32c-827e90049710"
        },
        {
          "id": 94479,
          "person": {
            "id": 93679,
            "first_name": "Diana",
            "last_name": "Salas",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "93639992-65ce-4616-8a12-f6b985c4a1c1"
        },
        {
          "id": 91088,
          "person": {
            "id": 90370,
            "first_name": "Dina",
            "last_name": "Avila Holguin",
            "photo_url": "assets/f58d39e49ad14d9c87ede118ac0d0745_Dinaspicture20231026_142334_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9c2343c-3c6e-4acd-926d-4d39057072e2"
        },
        {
          "id": 24711,
          "person": {
            "id": 24632,
            "first_name": "Dinh",
            "last_name": "Truong",
            "photo_url": "assets/1590650500.609926_20200528_001554_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-09-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9bac71b3-e76d-430b-8d0a-d5dbdc3739eb"
        },
        {
          "id": 91198,
          "person": {
            "id": 90479,
            "first_name": "Dominique",
            "last_name": "McGraw",
            "photo_url": "assets/f2794887eca04b02b1c8163bb66bb0f4_IMG_0621_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40d21dcb-dbbf-4d34-a60f-decef22ae8f3"
        },
        {
          "id": 92133,
          "person": {
            "id": 91408,
            "first_name": "Donald",
            "last_name": "Leiva Mendoza",
            "photo_url": "assets/r67c03e48d1d7455b9c9965bf815f6a08_Screenshot20231116at3.00_t.39PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0a8b48a-51cc-4836-9863-7b700d582408"
        },
        {
          "id": 97042,
          "person": {
            "id": 96243,
            "first_name": "Donald",
            "last_name": "Wilson",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf3c13b2-2eed-4990-b224-20973e700ad0"
        },
        {
          "id": 91792,
          "person": {
            "id": 91071,
            "first_name": "Donna",
            "last_name": "Peek",
            "photo_url": "assets/0103894064e24d52a304566039939777_IMG_4534_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40d64968-2f3f-4b42-b4a3-350158bd9b1a"
        },
        {
          "id": 90985,
          "person": {
            "id": 90268,
            "first_name": "Donny",
            "last_name": "Blankenship",
            "photo_url": "assets/58e3ec62af024ec3ad4639c124d68a91_attachment1709650711196_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "047cce61-eed0-459f-9157-4adf804d9779"
        },
        {
          "id": 97370,
          "person": {
            "id": 96573,
            "first_name": "Dwane",
            "last_name": "Truitt",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5969e241-208f-4ab5-8249-878c5e9fd646"
        },
        {
          "id": 94117,
          "person": {
            "id": 93318,
            "first_name": "Edfidio",
            "last_name": "Cano",
            "photo_url": "assets/92c668fe68b248e2a604ef9b79fa2c18_Edifidio_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a22fc980-2878-4a15-8029-bfe33976bc70"
        },
        {
          "id": 91311,
          "person": {
            "id": 90593,
            "first_name": "Edgar",
            "last_name": "Alvarado",
            "photo_url": "assets/d5f25373c8bb47ad866e765a39549c5a_EdgarAlvarado_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ef62757-c798-4aab-85c4-382cab3906a9"
        },
        {
          "id": 95257,
          "person": {
            "id": 94455,
            "first_name": "Edgar",
            "last_name": "Cardenas Fabian",
            "photo_url": "assets/9ec6c77453e94ffaaec0f73017dcc457_image5771_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3db48e03-d623-409d-800b-48296726f015"
        },
        {
          "id": 96763,
          "person": {
            "id": 95963,
            "first_name": "Edgar",
            "last_name": "Zambrano",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19930610-a103-4ef2-9231-793a05b36439"
        },
        {
          "id": 90497,
          "person": {
            "id": 89783,
            "first_name": "Edinson",
            "last_name": "Salazar Clavijo",
            "photo_url": "assets/88526ac8cb614113ac4c4b64a949b01f_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2663,
              "name": "Swing",
              "contract": {
                "id": 1968,
                "name": "Jacksonville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b191ca6-a3f2-48a1-973c-789b4b8a5825"
        },
        {
          "id": 91041,
          "person": {
            "id": 90323,
            "first_name": "Edith",
            "last_name": "Russi Monroy",
            "photo_url": "assets/dd226228d7cf486eb44a016f759f9443_IMG_1148_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9fe4e06-91b4-4f03-8a66-733a1b2f0fb8"
        },
        {
          "id": 93506,
          "person": {
            "id": 92707,
            "first_name": "Eduardo",
            "last_name": "Iturralde Balladares",
            "photo_url": "assets/r9f1c247be7734c0995bd1e7cd1f54159_Eduardophoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f19107b-78bb-465d-be1a-2624df6d1e5e"
        },
        {
          "id": 94068,
          "person": {
            "id": 93269,
            "first_name": "Eduardo",
            "last_name": "Orozco",
            "photo_url": "assets/3319c4f72b754cd3869b7bb2942fde01_Eduardo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0896ea3-d197-4897-b268-63e6e9f1c814"
        },
        {
          "id": 92284,
          "person": {
            "id": 91550,
            "first_name": "Eduardo",
            "last_name": "Vidrio",
            "photo_url": "assets/a7fd8892f0194bd584e5f960e426d78b_EduardoArturoVidrio_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ace5b632-cf41-4ad7-9aa2-1307c079e619"
        },
        {
          "id": 94063,
          "person": {
            "id": 93264,
            "first_name": "Elaine",
            "last_name": "Godoy Martinez",
            "photo_url": "assets/663f82e4fddc4f4fa09428c61672b64f_Elaine_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30f57c42-4e2c-4ed1-a4c6-cba6a340237a"
        },
        {
          "id": 93758,
          "person": {
            "id": 92959,
            "first_name": "Elba",
            "last_name": "Barajas",
            "photo_url": "assets/5b8897e10e8941c3bcb51f55a029fc8e_elva_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8700,
              "name": "Day",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "32043e88-676a-4665-b281-c624d77d6f85"
        },
        {
          "id": 97007,
          "person": {
            "id": 96208,
            "first_name": "Elba",
            "last_name": "Zelaya",
            "photo_url": "assets/r05791e0a4c7f413785de45e021163f50_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "445c7bf1-c652-4170-996e-958c13bc0779"
        },
        {
          "id": 94040,
          "person": {
            "id": 93241,
            "first_name": "Eli",
            "last_name": "Varguez",
            "photo_url": "assets/1223acb3b62a4a0ca692ef8e6f0a0e2e_Eli_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a97d33a5-e2db-4209-84ec-46f9d215153d"
        },
        {
          "id": 49105,
          "person": {
            "id": 48611,
            "first_name": "Elizabeth",
            "last_name": "Trejo",
            "photo_url": "assets/637504d7b8244ba0ace0d8080784ad5f_elizabeth_t.jpg",
            "phone_number": "",
            "email_address": "238762@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d414eb4-0f5b-4e70-a834-ef7ef5c84e58"
        },
        {
          "id": 93639,
          "person": {
            "id": 92840,
            "first_name": "Elva",
            "last_name": "Valdez",
            "photo_url": "assets/8c311faf8e0640859ce4b75b01ad41c9_elvavaldez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81e9b1de-256a-4924-b499-c37558246637"
        },
        {
          "id": 91384,
          "person": {
            "id": 90666,
            "first_name": "Elver",
            "last_name": "Rojas Mercado",
            "photo_url": "assets/r2863fc7f1a2e4884aec1e0c64aa51a63_ElverRojas88_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "58901884-c957-4e4c-a100-25799c258bc7"
        },
        {
          "id": 8737,
          "person": {
            "id": 8737,
            "first_name": "Elvira",
            "last_name": "Uscanga",
            "photo_url": "assets/ef806b1efd4d4092b47a654653192b45_elvirapic_t.jpg",
            "phone_number": "",
            "email_address": "210507@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f867d23-d80a-43fa-aae4-628922ec0a5d"
        },
        {
          "id": 94750,
          "person": {
            "id": 93950,
            "first_name": "Emiljana",
            "last_name": "Bylyku",
            "photo_url": "assets/r5aeb346fafb4459899db52066cd6f6f0_Emily_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8869,
              "name": "Shift A",
              "contract": {
                "id": 2217,
                "name": "Fairlane North",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45ad0176-c3f4-4386-89a9-4178f40a0e1e"
        },
        {
          "id": 93849,
          "person": {
            "id": 93050,
            "first_name": "Emily",
            "last_name": "Harshman",
            "photo_url": "assets/accae27f005e48369900f7c49315101d_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be4ff480-4a40-47d2-915e-bda29cafb5fc"
        },
        {
          "id": 91601,
          "person": {
            "id": 90881,
            "first_name": "Emina",
            "last_name": "Halley",
            "photo_url": "assets/r3ce2a917e66c4316b07424c84588f73b_temp_file_.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5aa134c-d884-4ea8-a06e-00b0f0e01a9c"
        },
        {
          "id": 97009,
          "person": {
            "id": 96210,
            "first_name": "Emmitt",
            "last_name": "Davis",
            "photo_url": "assets/re6d1b01a19924ede867b718dda485506_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5934463c-a685-4ff4-80f1-4ec58b66da46"
        },
        {
          "id": 95939,
          "person": {
            "id": 95139,
            "first_name": "Erica",
            "last_name": "Ely",
            "photo_url": "assets/8da70661160d4f4aa880bc2e99dc0363_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2771,
              "name": "Swing",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e3e8c02-91e3-4235-b27d-8f1f1c7fbc85"
        },
        {
          "id": 45311,
          "person": {
            "id": 44875,
            "first_name": "Erickson",
            "last_name": "Alejo",
            "photo_url": "assets/re3e61ca418574a0a9ec8f513223e28df_IMG_2459_t.jpeg",
            "phone_number": "+15164622261",
            "email_address": "n/a"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7799,
              "name": "Day",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            },
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb6d1999-36d0-4094-b474-07dd292cc782"
        },
        {
          "id": 93776,
          "person": {
            "id": 92977,
            "first_name": "Erikson",
            "last_name": "Acosta Reategui",
            "photo_url": "assets/76969011289c4f61b50a79615cb80377_1000002411_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a2928b52-bc71-46d7-a5b2-433688900ae2"
        },
        {
          "id": 94748,
          "person": {
            "id": 93948,
            "first_name": "Erin",
            "last_name": "Nathan",
            "photo_url": "assets/e098e0962fa24d468c55e4e7bc30bd9a_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c8917e1-8ae0-4e73-95a7-421cb2263527"
        },
        {
          "id": 92678,
          "person": {
            "id": 91912,
            "first_name": "Esperanza",
            "last_name": "Vigil-Rosas",
            "photo_url": "assets/35da0203772144cd9d971e6847185599_esperanza2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "683d17aa-60a7-4ec0-b1ac-c6454db5dd10"
        },
        {
          "id": 94069,
          "person": {
            "id": 93270,
            "first_name": "Eugenio",
            "last_name": "Mendoza",
            "photo_url": "assets/6060f31a8b6e451cb1469d2a959eda4c_Eugenio_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06ad5a25-3d6f-43a4-bc46-cd1099370db0"
        },
        {
          "id": 47446,
          "person": {
            "id": 46961,
            "first_name": "Eva",
            "last_name": "Vera Diaz",
            "photo_url": "assets/1619450624.5123692_EvaVeraDiazHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34b37e05-2bb8-4d4a-b2bb-4ee2aa7f660a"
        },
        {
          "id": 94090,
          "person": {
            "id": 93291,
            "first_name": "Evangelina",
            "last_name": "Lopez-Sanchez",
            "photo_url": "assets/0436837cf95a4260beed66410f82efa6_Evanpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7196,
              "name": "Night",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-01-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1f092d5-0b88-4d7f-b22d-0809de3f1bd9"
        },
        {
          "id": 96596,
          "person": {
            "id": 95797,
            "first_name": "Eylen",
            "last_name": "Moran Diaz",
            "photo_url": "assets/7093825111154b08b3de816276639b50_Lorena_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b34f2101-83d2-483d-960e-67117b68bfdb"
        },
        {
          "id": 92992,
          "person": {
            "id": 92226,
            "first_name": "Fanny",
            "last_name": "Jimenez",
            "photo_url": "assets/0d54e119b2054e879b508c7fea316161_Fannyspicture20240124_072213_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "41c37435-a9b4-4dcd-b84a-394d40f667b8"
        },
        {
          "id": 93301,
          "person": {
            "id": 92532,
            "first_name": "Fatuma",
            "last_name": "Hussein",
            "photo_url": "assets/4eb5f9a22cb1478598860406cbe5e14d_FatumaHusseinPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24c21d6c-2450-423f-b9ec-bb275ee60f3a"
        },
        {
          "id": 93738,
          "person": {
            "id": 92939,
            "first_name": "Feras",
            "last_name": "Alkhatib",
            "photo_url": "assets/fbd4ffcf21ed4cadb5da1540af1c0ecf_1000003583_t.jpg",
            "phone_number": "3178641817",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "788d7344-01d9-4ba4-b5b8-64b55f16cd99"
        },
        {
          "id": 93792,
          "person": {
            "id": 92993,
            "first_name": "Fidelina",
            "last_name": "Magana",
            "photo_url": "assets/87576395c5b241388be72946e40335f0_Fidelina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90fa9815-ba6d-4111-b806-98885daec701"
        },
        {
          "id": 93627,
          "person": {
            "id": 92828,
            "first_name": "Flor Karina",
            "last_name": "Espindola Calderon",
            "photo_url": "assets/69372c98b8e54a9bb0da9c69c652e752_FlorEspidola_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a55a4255-b33a-4bbc-94f3-17b8a7432dbd"
        },
        {
          "id": 93768,
          "person": {
            "id": 92969,
            "first_name": "Francisca",
            "last_name": "Fabian Xuana",
            "photo_url": "assets/6f3422e8d98a46b193175d0f6ca7deb3_FranciscoFabian_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b91540eb-4451-4639-808d-cc5ad8c8fc59"
        },
        {
          "id": 91859,
          "person": {
            "id": 91136,
            "first_name": "Francisca",
            "last_name": "Padilla Pinzon",
            "photo_url": "assets/b7d5e077032b41d78ed5e394b413d451_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c38504c-3656-4dd5-beec-a5e59e97dafd"
        },
        {
          "id": 89667,
          "person": {
            "id": 88962,
            "first_name": "Francisco",
            "last_name": "Chavez Molina",
            "photo_url": "assets/f4b6b7846f944c91ad6b478f10a8a323_20230918_204330_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "077a9a70-7bcf-4394-af52-a7db03421b14"
        },
        {
          "id": 96534,
          "person": {
            "id": 95735,
            "first_name": "Francisco",
            "last_name": "Marcelo",
            "photo_url": "assets/8ac285101c8b4039aa6850730b811f97_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8396200f-257b-47b6-a113-40f3e2c1e18c"
        },
        {
          "id": 86907,
          "person": {
            "id": 86234,
            "first_name": "Frederick",
            "last_name": "Jones",
            "photo_url": "assets/c79de1ab2f6743508267e079ab233c21_IMG_1888_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f11d549e-ff23-4933-8b85-e19975d489c8"
        },
        {
          "id": 93911,
          "person": {
            "id": 93112,
            "first_name": "Fredy",
            "last_name": "Gomez Estrada",
            "photo_url": "assets/de4ebfe580bc42a3af6a41e79fa6f934_Photofor4Insite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92e4fe0e-b202-489e-8fd1-9ea14b196087"
        },
        {
          "id": 92249,
          "person": {
            "id": 91516,
            "first_name": "Gary",
            "last_name": "Gabriel",
            "photo_url": "assets/54ded41639fd4e4e966872c143e6ff39_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8ac2404f-5495-473a-a25d-e5bdf8bf41aa"
        },
        {
          "id": 97130,
          "person": {
            "id": 96331,
            "first_name": "Gaudy",
            "last_name": "Montero Mora",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7180,
              "name": "On-Call",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c5143ee-352a-473e-b342-1732d38ec423"
        },
        {
          "id": 93628,
          "person": {
            "id": 92829,
            "first_name": "Gerardo",
            "last_name": "Marin",
            "photo_url": "assets/d45e8261ad75439e820a04b10ca2341a_Gerardo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb65e24b-45b8-4cfe-8e1d-f797b56311c3"
        },
        {
          "id": 44981,
          "person": {
            "id": 44537,
            "first_name": "Gerson",
            "last_name": "Colon Ramirez",
            "photo_url": "assets/1612560583.932346_GersonSBMPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2651,
              "name": "Swing",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1d89303e-9721-478b-a868-589320f8a835"
        },
        {
          "id": 96022,
          "person": {
            "id": 95222,
            "first_name": "Gilma",
            "last_name": "Gamez",
            "photo_url": "assets/r76fa770fd46e45a88af8ddf25f5feaf4_20240227_172342002_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff0cb754-156a-4c1c-936e-8f80259ce91a"
        },
        {
          "id": 91806,
          "person": {
            "id": 91085,
            "first_name": "Glorimar",
            "last_name": "Flores",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de34ca4c-c195-4a68-9f67-05b01c6858c0"
        },
        {
          "id": 93227,
          "person": {
            "id": 92459,
            "first_name": "Gregory",
            "last_name": "Matheis",
            "photo_url": "assets/9d119a670fce4438a0b78ea8f7544810_gmhs_t.jpg",
            "phone_number": "3179953762",
            "email_address": "gmatheis@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "932776dd-3676-4ae9-8cd9-e81624dde2ef"
        },
        {
          "id": 93777,
          "person": {
            "id": 92978,
            "first_name": "Griselda",
            "last_name": "Madrigal",
            "photo_url": "assets/524ea269f19c4aa4bdeafc35a1b5424c_griselda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 8700,
              "name": "Day",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f775008-d9ea-4342-80a4-105e92caff20"
        },
        {
          "id": 91723,
          "person": {
            "id": 91003,
            "first_name": "Halie",
            "last_name": "Petty",
            "photo_url": "assets/r008edbfabc034e01946cc5f7a67721c3_HaliePetty_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "298df186-6bc9-496e-b233-f648e30c3e3e"
        },
        {
          "id": 41692,
          "person": {
            "id": 41305,
            "first_name": "Halima",
            "last_name": "Bilal",
            "photo_url": "assets/2583cd6f35b942ecbd5a84afcd7dba25_HBilalpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "20e7a334-c80e-44b1-af88-b1c50bee00b4"
        },
        {
          "id": 94762,
          "person": {
            "id": 93962,
            "first_name": "Hamida",
            "last_name": "Vaka",
            "photo_url": "assets/16d9fae2878b48e797ea5cc1694289b8_HamidaVakaPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8871,
              "name": "Shift B",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1eff4e0f-d6c5-4b54-997c-6d0efede7fae"
        },
        {
          "id": 89534,
          "person": {
            "id": 88829,
            "first_name": "Harold",
            "last_name": "Pajuelo Velasco",
            "photo_url": "assets/d4e2ae5a66b64c33aedf6b1c1d2d7962_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7ff9486-e793-4992-a65e-6c2934337ef6"
        },
        {
          "id": 90702,
          "person": {
            "id": 89987,
            "first_name": "Heidy",
            "last_name": "Palma-Ramos",
            "photo_url": "assets/20abb4273e214310bab63b3954d99bd4_Resized_20231010_1007201_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2021,
              "name": "Day",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2aaec205-e4e0-4841-84a6-49616754baf4"
        },
        {
          "id": 19280,
          "person": {
            "id": 19402,
            "first_name": "Heidy",
            "last_name": "Sanquintin",
            "photo_url": "assets/1591794420.7896373_heidysanquintin_t.PNG",
            "phone_number": "+17814965603",
            "email_address": "hsanquintin@sbmcorp.com"
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9429a0d5-697d-44c2-99b7-72cc423ba70c"
        },
        {
          "id": 91120,
          "person": {
            "id": 90401,
            "first_name": "Hilda",
            "last_name": "Molina",
            "photo_url": "assets/50fa72aba0c849b0888ab5d0f4d4c7d1_attachment1698707570486_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e17b081-019d-4bb4-b668-434063a0233d"
        },
        {
          "id": 92256,
          "person": {
            "id": 91523,
            "first_name": "Honorio",
            "last_name": "Cerezo Perez",
            "photo_url": "assets/c356aa80fb7546999e9cc8866d05490c_Chino_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d231d84-fcbe-4156-b4c4-6604c38861e6"
        },
        {
          "id": 94664,
          "person": {
            "id": 93864,
            "first_name": "Hugo",
            "last_name": "Garcia",
            "photo_url": "assets/011d5cab57854989948325f19417c36f_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0a2a96f-5d04-4179-9599-a18530084f69"
        },
        {
          "id": 94828,
          "person": {
            "id": 94028,
            "first_name": "Inmar",
            "last_name": "Zetino Garcia",
            "photo_url": "assets/70c9964d173c4ffaa461d1b6d1ff1a78_InmarProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "634eff04-5e74-44f4-94bc-77f2ac98fbfd"
        },
        {
          "id": 94655,
          "person": {
            "id": 93855,
            "first_name": "Iris",
            "last_name": "Aguirre",
            "photo_url": "assets/aa80c7f74f58479ab7d138fee19f6efc_IrisfayeAguirre_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30104952-f409-4a07-9d4a-bc2c9fbe7eb4"
        },
        {
          "id": 89894,
          "person": {
            "id": 89188,
            "first_name": "Iysis",
            "last_name": "Harris",
            "photo_url": "assets/4ab6e49a99804086becd2b481e06f518_Iysisphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e219185-a49c-4b70-82c5-00c0fa412962"
        },
        {
          "id": 93770,
          "person": {
            "id": 92971,
            "first_name": "Jackeline",
            "last_name": "Lizarraga",
            "photo_url": "assets/d54da81e40a943f7929303e88ce3da43_JackelineL_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd8e409e-035e-46ab-b59a-2b670a411097"
        },
        {
          "id": 93767,
          "person": {
            "id": 92968,
            "first_name": "Jacqueline",
            "last_name": "Hernandez",
            "photo_url": "assets/423b2314a6df4cca87c5151f124742ae_JaquelineHernandez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da206098-f549-468a-9e77-1c45d193cf0d"
        },
        {
          "id": 93980,
          "person": {
            "id": 93181,
            "first_name": "Jacqueline",
            "last_name": "Infante",
            "photo_url": "assets/c09d0b3ba28644c88ba4b5d96a660c58_Jacquieline_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cbdade29-b59c-4efb-8daf-f5a012c9c399"
        },
        {
          "id": 95965,
          "person": {
            "id": 95165,
            "first_name": "Jalin",
            "last_name": "Clodfelter",
            "photo_url": "assets/c39f484f90da40f1a1a99cb132ada040_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f7885032-da74-40df-9ad2-678b4caba483"
        },
        {
          "id": 91091,
          "person": {
            "id": 90373,
            "first_name": "Janet",
            "last_name": "Davis",
            "photo_url": "assets/2abd1548f429444b860c9a2fa2b5a2a4_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7471,
              "name": "Swing",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c7425733-ead4-4b57-ac0a-4b975b843bbe"
        },
        {
          "id": 93752,
          "person": {
            "id": 92953,
            "first_name": "Janet",
            "last_name": "Ortiz",
            "photo_url": "assets/880dd2954ea94c638103fcfc7f6eaaf0_janet_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8700,
              "name": "Day",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e2e5d01-808e-42ca-946c-426f990e379e"
        },
        {
          "id": 94042,
          "person": {
            "id": 93243,
            "first_name": "Janet",
            "last_name": "Ruiz",
            "photo_url": "assets/582daf88f3614d84baf8fca2ff5ed12e_Janet_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0b96b50-7490-450c-be12-ce44ad8a91d1"
        },
        {
          "id": 92139,
          "person": {
            "id": 91414,
            "first_name": "Janitza",
            "last_name": "Oliveras Santiago",
            "photo_url": "assets/3c74b88e43a1436a86bbd0449b5cde0e_IMG_4982_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "632ed041-35b6-41d4-867e-6c662513f1f1"
        },
        {
          "id": 94044,
          "person": {
            "id": 93245,
            "first_name": "Jean Luc",
            "last_name": "Lamoreux",
            "photo_url": "assets/cd0a2c01bab1410399ec73a6930984d0_Jean_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8721,
              "name": "Night",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "442c0062-cfbc-4d2a-963d-f7a5d3a390cd"
        },
        {
          "id": 74484,
          "person": {
            "id": 73942,
            "first_name": "Jeannie",
            "last_name": "Hom",
            "photo_url": "assets/r90c1238b6fa74a3d9da21a6e70b12bff_20230620_132714_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bdc9f3d3-0f39-4e61-9b11-be8cdb943031"
        },
        {
          "id": 96676,
          "person": {
            "id": 95877,
            "first_name": "Jeanquita",
            "last_name": "Spencer",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "338c2b6a-70b3-4f0b-a013-7927168504d7"
        },
        {
          "id": 92486,
          "person": {
            "id": 91720,
            "first_name": "Jeffery",
            "last_name": "Knipp",
            "photo_url": "assets/6bc202e0ad6547dcb105b20b57945426_JKnippinsitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dfbe68ff-d83d-40a8-a543-968095372854"
        },
        {
          "id": 94046,
          "person": {
            "id": 93247,
            "first_name": "Jennifer",
            "last_name": "Avila",
            "photo_url": "assets/a3815001375f4b418ced4de8555d1308_Jennifer_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8721,
              "name": "Night",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c52b9ba7-74c3-4f1a-b048-b0729332f973"
        },
        {
          "id": 94759,
          "person": {
            "id": 93959,
            "first_name": "Jennifer",
            "last_name": "Jackson",
            "photo_url": "assets/370ee60841a146d2a7115498c8a6bb25_Jennifer_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8873,
              "name": "Shift A",
              "contract": {
                "id": 6746,
                "name": "Fairlane Business Park 3",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d371fc55-8663-473f-b354-1791519328b7"
        },
        {
          "id": 97008,
          "person": {
            "id": 96209,
            "first_name": "Jennifer",
            "last_name": "Lough",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "224840b9-3684-4443-a849-a267ef46cf6d"
        },
        {
          "id": 94051,
          "person": {
            "id": 93252,
            "first_name": "Jennifer",
            "last_name": "Marang",
            "photo_url": "assets/89b7bb0012334f049ae7a2cded49782b_JenniferM_t.jpg",
            "phone_number": "5033510125",
            "email_address": "NA"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42011166-0801-4465-a391-8e6bcc9971ee"
        },
        {
          "id": 93979,
          "person": {
            "id": 93180,
            "first_name": "Jeovana",
            "last_name": "Loredo",
            "photo_url": "assets/6f851292bec64dc0b62cff7dce1aee48_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c556ce28-5386-4e17-9f6b-0f604f4d9e7d"
        },
        {
          "id": 90206,
          "person": {
            "id": 89497,
            "first_name": "Jericho",
            "last_name": "Aguba",
            "photo_url": "assets/1f44c86c9f2447c79524525cb8ec7dee_Image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "562e7f70-d048-40dd-bc6a-881ae0cf0be2"
        },
        {
          "id": 95098,
          "person": {
            "id": 94298,
            "first_name": "Jessica",
            "last_name": "Rodriguez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2651,
              "name": "Swing",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1d196a9-7067-421c-a29c-b700c8ec2510"
        },
        {
          "id": 42156,
          "person": {
            "id": 41746,
            "first_name": "Jewell",
            "last_name": "Richardson",
            "photo_url": "assets/1605818571.88867_RefusedphotoFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e16720f-e5de-42f3-810c-33a12877224f"
        },
        {
          "id": 95173,
          "person": {
            "id": 94372,
            "first_name": "Jezanae",
            "last_name": "Hicks",
            "photo_url": "assets/a59a07d39b574012abe79ee6f6b59297_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d553b9b9-011c-491c-946a-de3f33d47ad5"
        },
        {
          "id": 91069,
          "person": {
            "id": 90351,
            "first_name": "Jhonatan",
            "last_name": "Avalos Limon",
            "photo_url": "assets/r3026b2f17be24745aaf88981e040fac7_20231018_132507_t.jpg",
            "phone_number": "+14086797445",
            "email_address": "javalos2@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d4bd927-3222-4d8a-bf33-af2e3b6604c2"
        },
        {
          "id": 95970,
          "person": {
            "id": 95170,
            "first_name": "Jillian",
            "last_name": "Bellettini",
            "photo_url": "assets/4a4913a61dba4e86be3fc46d75b0845f_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 2522,
              "name": "On-Call",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7561ec23-d9b3-4cc9-ad34-f2b246281676"
        },
        {
          "id": 54139,
          "person": {
            "id": 53596,
            "first_name": "Jina",
            "last_name": "Zhao",
            "photo_url": "assets/1e961a24d6094910958984302616a097_jinazahopic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76459466-abfd-401e-868b-4698ef5d8357"
        },
        {
          "id": 94045,
          "person": {
            "id": 93246,
            "first_name": "Joceline",
            "last_name": "Bolanos",
            "photo_url": "assets/ede1eeb26b3e4d90b23301f9c2f1a329_Jocelyn_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "659cd714-c5d4-4391-8749-0bc446049e4a"
        },
        {
          "id": 94492,
          "person": {
            "id": 93692,
            "first_name": "John",
            "last_name": "Donald",
            "photo_url": "assets/da22d9bb72d24dd0aba6d26cfe7ce6cf_John4insitephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "63e6adf7-0f7c-4f79-8638-b6f8e8734a2c"
        },
        {
          "id": 90220,
          "person": {
            "id": 89511,
            "first_name": "John",
            "last_name": "Ross",
            "photo_url": "assets/16ef3f3baa5247d3a8877592bce65aa6_JohnRoss_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a419985d-df66-49c0-8b49-2b5c70364fcd"
        },
        {
          "id": 83801,
          "person": {
            "id": 83162,
            "first_name": "Jontae",
            "last_name": "Holcomb",
            "photo_url": "assets/c6ec9b65d8f64bfc8d71e3b2c67de3cf_JontaeHolcomb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1523,
              "name": "Day",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c73012f9-6eae-4cac-a673-0ca74e06e735"
        },
        {
          "id": 96677,
          "person": {
            "id": 95878,
            "first_name": "Jordan",
            "last_name": "Lowe",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6e5d823-e612-4b39-8cc8-d1e50f318a3d"
        },
        {
          "id": 93995,
          "person": {
            "id": 93196,
            "first_name": "Jose",
            "last_name": "Diaz Dimas",
            "photo_url": "assets/b1fb16e4044b47faab8821d95fca014e_20240124_014831_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b308e0dd-d566-472a-b4dc-9610405824b4"
        },
        {
          "id": 74248,
          "person": {
            "id": 73706,
            "first_name": "Jose",
            "last_name": "Flores Lopez",
            "photo_url": "assets/76c18a55fe064887928213505ad5e63b_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7636,
              "name": "On-Call",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "794f63ec-618e-47ed-ac9d-fa016c282729"
        },
        {
          "id": 97382,
          "person": {
            "id": 96585,
            "first_name": "Jose",
            "last_name": "Medina Loya",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fecc52d1-e112-4619-8d7b-9b80fc6a70f1"
        },
        {
          "id": 92927,
          "person": {
            "id": 92161,
            "first_name": "Jose",
            "last_name": "Mora Torres",
            "photo_url": "assets/r0a6dcf60f8384b348b1acf02b300ccd9_image0_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8434,
              "name": "On-Call",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1481896a-7ae0-40c8-9953-6fbc1fe3196c"
        },
        {
          "id": 93638,
          "person": {
            "id": 92839,
            "first_name": "Jose",
            "last_name": "Pavao",
            "photo_url": "assets/ce99531f958b4e4c8d89ab37c66fd50f_JosePavo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "207c9733-fab5-440b-a9ee-3d55ae06e365"
        },
        {
          "id": 91136,
          "person": {
            "id": 90417,
            "first_name": "Jose",
            "last_name": "Pedroza Vasquez",
            "photo_url": "assets/371b34f1e7864b0f86a4e740f6585c3e_20231101_153536_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2841,
              "name": "Day",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "571effd7-87c5-43a4-b5f1-4525c3f6a959"
        },
        {
          "id": 93653,
          "person": {
            "id": 92854,
            "first_name": "Jose",
            "last_name": "Pulido Moran",
            "photo_url": "assets/d5011464f4494f859f79a6f3c7c344f5_20240118_164753_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2a9b2e5-dce4-4c17-b8f7-2f42798bc581"
        },
        {
          "id": 76933,
          "person": {
            "id": 76358,
            "first_name": "JQuon",
            "last_name": "Baxter",
            "photo_url": "assets/a4db007824ae482baa0a33cdadd601d8_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28bc03e2-abf5-4fa0-8602-9ea914c74c80"
        },
        {
          "id": 95962,
          "person": {
            "id": 95162,
            "first_name": "Juan",
            "last_name": "Barrios Fernandez",
            "photo_url": "assets/397ab9879d884cd2abe563ab8bb1a112_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1245,
              "name": "Swing",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7aae79ac-61b7-4133-9f1e-ef85ea1e3789"
        },
        {
          "id": 94172,
          "person": {
            "id": 93373,
            "first_name": "Juan",
            "last_name": "Cervantes",
            "photo_url": "assets/05c55804ff4c4712b5fa7aa8ca1381ef_IMG_5673_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4066754a-c83a-4173-85ff-1a0d367db44d"
        },
        {
          "id": 91085,
          "person": {
            "id": 90367,
            "first_name": "Juan",
            "last_name": "Flores Martinez",
            "photo_url": "assets/952bd125d8eb4c6c8d5d61aab6476834_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7715,
              "name": "Swing",
              "contract": {
                "id": 5793,
                "name": "Northyard",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c82d91a9-0765-4a99-8cfd-0da9501f0eb6"
        },
        {
          "id": 93755,
          "person": {
            "id": 92956,
            "first_name": "Juan",
            "last_name": "Ramirez",
            "photo_url": "assets/d31117201bea4acab8760b0b2150b103_JuanRamirez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "541126b6-f583-4c14-ae74-cab1f5fdce7b"
        },
        {
          "id": 94159,
          "person": {
            "id": 93360,
            "first_name": "Juana",
            "last_name": "Lopez Corralees",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6170b01-0a9c-44bd-beff-b5e5bb0380a5"
        },
        {
          "id": 91874,
          "person": {
            "id": 91151,
            "first_name": "Judy",
            "last_name": "Lobo",
            "photo_url": "assets/f45668b450d64e92992d5aa823f98f3b_2.JudyLobo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f87e2df-63c9-4756-bade-a0642b19c1aa"
        },
        {
          "id": 93622,
          "person": {
            "id": 92823,
            "first_name": "Julio",
            "last_name": "Medrano Calero",
            "photo_url": "assets/777828772e644e49aa134a18375f50c6_juilo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38f45964-871c-430c-bec2-8f430f749132"
        },
        {
          "id": 93677,
          "person": {
            "id": 92878,
            "first_name": "Justice",
            "last_name": "Cao",
            "photo_url": "assets/7efc516ec03444f2983941caf648bf6c_20240305_110750_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0a8a7b0d-754d-4360-b303-f18cf994aa69"
        },
        {
          "id": 93848,
          "person": {
            "id": 93049,
            "first_name": "Justin",
            "last_name": "Machado",
            "photo_url": "assets/534c5f6f4ba04c5e8ae625e4d0fceb1e_JustinMachado20240212_130805_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc4885e5-56f9-4931-a4b9-3909b9f3994b"
        },
        {
          "id": 94775,
          "person": {
            "id": 93975,
            "first_name": "Kaiden",
            "last_name": "Kurzawa",
            "photo_url": "assets/fd35c2084852417fb3b2e2a5800497e1_kaiden_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1114,
              "name": "Day",
              "contract": {
                "id": 1486,
                "name": "Cherokee, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b898b44a-87a8-426f-a216-1ed5ae992b60"
        },
        {
          "id": 91934,
          "person": {
            "id": 91210,
            "first_name": "Katina",
            "last_name": "Hernandez",
            "photo_url": "assets/1e4e2c591f1547fc819bae5561536e91_20231120_192954.jpgARACELIS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bf27c6f7-fbd5-4ea3-a522-9ca9a1c6a1ae"
        },
        {
          "id": 94758,
          "person": {
            "id": 93958,
            "first_name": "Keisha",
            "last_name": "Lake",
            "photo_url": "assets/ad8f751a497d49aea12589eaeb28d12e_KeishaLakePhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8871,
              "name": "Shift B",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7903df18-2d2b-4c6d-bdc1-58fa83980cd2"
        },
        {
          "id": 97010,
          "person": {
            "id": 96211,
            "first_name": "Keithlanae",
            "last_name": "Fuqua",
            "photo_url": "assets/691d029c8759466a833012f639a60130_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2223510a-6a05-4312-987e-cc153f29086e"
        },
        {
          "id": 91925,
          "person": {
            "id": 91201,
            "first_name": "Kelianna",
            "last_name": "Rogers",
            "photo_url": "assets/62513c5d483c4a929dddda5821349d4d_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf1ce7e2-2490-497e-948b-8cdadd385178"
        },
        {
          "id": 91827,
          "person": {
            "id": 91105,
            "first_name": "Kenneth",
            "last_name": "Dowe",
            "photo_url": "assets/9736a7735cc145049f89b7886bb8876e_KDowepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9d1f8d2-5559-4e45-b7dc-ee576b646357"
        },
        {
          "id": 94686,
          "person": {
            "id": 93886,
            "first_name": "Kerri",
            "last_name": "Pauley",
            "photo_url": "assets/r62e49d44757e4fd89eeb90b7c1993cd7_20240130_135728_t.jpg",
            "phone_number": "5036166735",
            "email_address": "259621@placeholder.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "73fbba74-d831-4c20-84be-76ba4b422fbf"
        },
        {
          "id": 91539,
          "person": {
            "id": 90821,
            "first_name": "Kevin",
            "last_name": "Figueroa",
            "photo_url": "assets/c6af2787d86a46079a4e7c5ad18395e6_KevinFprofilepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b4026e3a-28b7-4f16-b47f-9cd00deb3cf7"
        },
        {
          "id": 94657,
          "person": {
            "id": 93857,
            "first_name": "Kevin",
            "last_name": "Towns",
            "photo_url": "assets/cd812a51bc39476da923c5420855c416_kevinpic2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 8777,
              "name": "Day",
              "contract": {
                "id": 6684,
                "name": "LTP – Livonia Transmission Plant – Wayne, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "da632665-87df-4e1a-8ccf-6819d4038f95",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24cd3095-d335-4a93-bc7a-195c116f77ef"
        },
        {
          "id": 91933,
          "person": {
            "id": 91209,
            "first_name": "Kevin",
            "last_name": "Vasquez",
            "photo_url": "assets/f2c124139d07415bb4bcdcc53a041708_20231120_201405.jpgKEVIN_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31927911-aa53-4602-84ea-7cd695c3233e"
        },
        {
          "id": 97050,
          "person": {
            "id": 96251,
            "first_name": "Keyona",
            "last_name": "Farris",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65d3f61b-0a8b-4e19-86e3-b865a629de2f"
        },
        {
          "id": 88504,
          "person": {
            "id": 87803,
            "first_name": "Khaldon",
            "last_name": "Haidar",
            "photo_url": "assets/bcdd48c4698048128596fdb1dac002c4_Screenshot20231031at12.03_t.44PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "190fe8ac-65ad-4fd1-8fa3-9b0753cb5dbf"
        },
        {
          "id": 93315,
          "person": {
            "id": 92546,
            "first_name": "Khaliq",
            "last_name": "Wilks",
            "photo_url": "assets/c9f0c6515ab543279f4dc4ada9151666_Khaliq_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6daa594b-9bd9-4e7f-afa2-f28320b630e7"
        },
        {
          "id": 95253,
          "person": {
            "id": 94451,
            "first_name": "Kimberly",
            "last_name": "Robinson",
            "photo_url": "assets/72f7d005fc1c43d59ab4acb2288a10af_KimberlyRobinson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 9127,
              "name": "Shift B",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cbf305d4-ab72-4b60-8e3b-6fc0a6b21db2"
        },
        {
          "id": 92492,
          "person": {
            "id": 91726,
            "first_name": "Kiya",
            "last_name": "Sabean",
            "photo_url": "assets/2345d489f0734648aafb1af516116eda_Snapchat712800094_t.jpg",
            "phone_number": "+14199968537",
            "email_address": "258473@placeholder.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8791,
              "name": "None",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2fca17d3-ec35-4653-94a3-cbf7506d2958"
        },
        {
          "id": 96843,
          "person": {
            "id": 96044,
            "first_name": "Koran",
            "last_name": "Chasten",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27f11e48-8dc0-4ac1-b484-e51c0a68f4eb"
        },
        {
          "id": 93226,
          "person": {
            "id": 92458,
            "first_name": "Laquana",
            "last_name": "Parker",
            "photo_url": "assets/6df8d90eb8c746a9afa3d59ab54e93a0_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ce7a3d0-f71f-45cd-9e5c-676b6fa7bec4"
        },
        {
          "id": 92598,
          "person": {
            "id": 91832,
            "first_name": "Larry",
            "last_name": "Smith",
            "photo_url": "assets/9b17bd7ef8224234bfa545f167e629d5_IMG_4909_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aed8e4c0-7c4a-4e31-92e1-5c3aa74d25cd"
        },
        {
          "id": 91743,
          "person": {
            "id": 91022,
            "first_name": "Latoi",
            "last_name": "Downing",
            "photo_url": "assets/6318c0d78f8c4a87a479a0509d56a5d4_Latoyapicture20231120_114826_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc66a0ee-4f76-429f-8f81-8b8906f5d9fe"
        },
        {
          "id": 90211,
          "person": {
            "id": 89502,
            "first_name": "LaTonya",
            "last_name": "Thomas",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3a7d7907-38e7-4dde-aebb-900240b065e1"
        },
        {
          "id": 96640,
          "person": {
            "id": 95841,
            "first_name": "Latrisha",
            "last_name": "Evans",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8783,
              "name": "Swing",
              "contract": {
                "id": 6076,
                "name": "Cover My Meds 2",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f0adb90-bcfa-4244-9a4b-5c7f2fb83095"
        },
        {
          "id": 97381,
          "person": {
            "id": 96584,
            "first_name": "Laura",
            "last_name": "Castro Madrigal",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2157,
              "name": "Day",
              "contract": {
                "id": 1766,
                "name": "Pleasanton",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3022b9e2-8619-4c04-8922-fb5e4dd1fa2d"
        },
        {
          "id": 93625,
          "person": {
            "id": 92826,
            "first_name": "Laura",
            "last_name": "Ortiz Alvarez",
            "photo_url": "assets/302f8c4c3713457db7fcbe24ea028d15_LauraOrtiz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2814a25f-8968-4a4c-988b-e5b441bbb1e7"
        },
        {
          "id": 91457,
          "person": {
            "id": 90739,
            "first_name": "Laura",
            "last_name": "Stear",
            "photo_url": "assets/f96f924904b4473986b933394e41293a_LauraStearPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2788,
              "name": "Swing",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dfa193a5-1840-478d-84f0-2dae6a2913e8"
        },
        {
          "id": 92889,
          "person": {
            "id": 92123,
            "first_name": "Legna",
            "last_name": "Rangel",
            "photo_url": "assets/093177e077e243dc9c5dfa5bf23854f1_20231226_212142_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "50a3a518-43cf-41c1-bde6-bd3ad7d23ce3"
        },
        {
          "id": 89554,
          "person": {
            "id": 88849,
            "first_name": "Leny",
            "last_name": "Hernandez Platero",
            "photo_url": "assets/e8fbcc8cd41247f986f678ebedf0117e_IMG_0989_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "130c5ba3-b251-4078-aac7-abdcf675105f"
        },
        {
          "id": 24425,
          "person": {
            "id": 24363,
            "first_name": "Lesli",
            "last_name": "Escalante Guerra",
            "photo_url": "assets/1614296133.4172966_cropped8000388453875892458.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0da6178-e955-438a-ade3-6443e3567974"
        },
        {
          "id": 94958,
          "person": {
            "id": 94158,
            "first_name": "Lidia",
            "last_name": "Salgado",
            "photo_url": "assets/6257ec8e098d456bb17c34fde3f4741b_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c8dfe5a2-8aa1-48e7-939b-e89101d274d6"
        },
        {
          "id": 91202,
          "person": {
            "id": 90483,
            "first_name": "Lindsay",
            "last_name": "Jones",
            "photo_url": "assets/90e1bc8c526c4850832ce1b279e06c33_IMG_0610_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7954,
              "name": "Shift A2",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2d682ba-906e-4e54-9949-86a6bd485bba"
        },
        {
          "id": 95255,
          "person": {
            "id": 94453,
            "first_name": "Lirim",
            "last_name": "Bylyku",
            "photo_url": "assets/a0a1e86930524754a8afe3eca553469b_lirim_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8875,
              "name": "Shift A",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8bfd551b-084b-4784-b890-a85dc67bc6d5"
        },
        {
          "id": 91431,
          "person": {
            "id": 90713,
            "first_name": "Lisa",
            "last_name": "Ellsworth",
            "photo_url": "assets/rd8a79925d61a40fa8201f128ea42a03c_IMG_11081_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ad25544-6f22-4228-b65f-d7f67193dcb2"
        },
        {
          "id": 97371,
          "person": {
            "id": 96574,
            "first_name": "Lorianie",
            "last_name": "Betancourt-Montanez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2651,
              "name": "Swing",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "20407919-78a9-4db7-8646-6615f9096b46"
        },
        {
          "id": 93636,
          "person": {
            "id": 92837,
            "first_name": "Lourdes",
            "last_name": "Garcia",
            "photo_url": "assets/e0e2dc3c423d45ca81d04a64c3719498_LourdesGarcia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d29e851a-bbff-4305-aaf4-75cb73672db6"
        },
        {
          "id": 93623,
          "person": {
            "id": 92824,
            "first_name": "Lucia",
            "last_name": "Corrales",
            "photo_url": "assets/3edd3bea1ce14ec4be6a11941e332873_LuciaCorrales_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40717b77-fa27-4182-986c-bc63e0461145"
        },
        {
          "id": 94050,
          "person": {
            "id": 93251,
            "first_name": "Lucia",
            "last_name": "Solano Flores",
            "photo_url": "assets/efd7c60fbead4dde9d95ff8d496cb844_Lucia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1d5a780-2a2a-44ec-867a-4e2b418df4f7"
        },
        {
          "id": 92620,
          "person": {
            "id": 91854,
            "first_name": "Luis",
            "last_name": "Garcia",
            "photo_url": "assets/r389184162f6044a7931ba3e85d569b2d_20231220_163715_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "22a6d5a4-df4b-4422-9978-a0d5d4adba80"
        },
        {
          "id": 94049,
          "person": {
            "id": 93250,
            "first_name": "Luis",
            "last_name": "Hernandez",
            "photo_url": "assets/8a3dacb9c8844606b878d5c7f13dad9b_Luis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d2612e9-7575-4978-b161-b96473dfc6fd"
        },
        {
          "id": 93762,
          "person": {
            "id": 92963,
            "first_name": "Luis",
            "last_name": "Valencia Naranjo",
            "photo_url": "assets/547c218be09a40ca812864ddedfc9588_LuisValencia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b54206a-aca8-4e00-ba2a-65908e2021f5"
        },
        {
          "id": 97128,
          "person": {
            "id": 96329,
            "first_name": "Luisangel",
            "last_name": "Torres Gonzalez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31aad968-3291-4908-a5bf-7a2f23a18989"
        },
        {
          "id": 95846,
          "person": {
            "id": 95047,
            "first_name": "Luz",
            "last_name": "Munoz Murillo",
            "photo_url": "assets/e80db4bd82454b1a9f7eea65cf8f49f2_luz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3144,
              "name": "Swing",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da577335-7747-4fe4-b174-589b71994c19"
        },
        {
          "id": 96586,
          "person": {
            "id": 95787,
            "first_name": "Mabel",
            "last_name": "Beltran Agudelo",
            "photo_url": "assets/27b4faf459454a6c844c113d0f0713e5_Mabel_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0236c42d-5b60-4782-acc5-a1318ffeb292"
        },
        {
          "id": 94638,
          "person": {
            "id": 93838,
            "first_name": "Magali",
            "last_name": "Hernandez",
            "photo_url": "assets/c0154d3ad67b418a95bef223688a7bf7_Magalispic20240213_113604_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97ad0ece-19ce-4d9d-ab8a-3fe8d4a07064"
        },
        {
          "id": 91389,
          "person": {
            "id": 90671,
            "first_name": "Ma Guadalupe",
            "last_name": "Perez Martinez",
            "photo_url": "assets/df5c1207ea96422f9474935cebbd0cfe_Lupepictures_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b5f1166-da68-45c5-869b-11cbd5a32b97"
        },
        {
          "id": 94091,
          "person": {
            "id": 93292,
            "first_name": "Maira",
            "last_name": "Jimenez",
            "photo_url": "assets/rf2c46bbd547749348dd90ea6ba9ae371_SBMPICTURE_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8c446ca-4fec-40f1-bc66-0ca5995b7fdc"
        },
        {
          "id": 95398,
          "person": {
            "id": 94601,
            "first_name": "Makayla",
            "last_name": "Decker",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3292774-f12e-4fa4-97d1-1cd960257a90"
        },
        {
          "id": 96660,
          "person": {
            "id": 95861,
            "first_name": "Maliq",
            "last_name": "Cannon",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7955,
              "name": "Shift B",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba9b7536-772d-4d28-8868-8a147ff53e1f"
        },
        {
          "id": 73935,
          "person": {
            "id": 73395,
            "first_name": "Manuel",
            "last_name": "Camarillo",
            "photo_url": "assets/c25869d886c3474e9011cfec50120f4c_1000002712_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea5d0a21-fa24-4769-8f0b-ad96ca2bdd85"
        },
        {
          "id": 97218,
          "person": {
            "id": 96419,
            "first_name": "Manuel",
            "last_name": "Garcia",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "96815219-b231-48b3-8ec9-7d24eb6764e3"
        },
        {
          "id": 94234,
          "person": {
            "id": 93434,
            "first_name": "Manuela",
            "last_name": "Lorenzana",
            "photo_url": "assets/0b46db22cb35471991b823d6ab8eef43_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0f3ca4c9-409e-48c8-bdb6-33d11eeb0657"
        },
        {
          "id": 95239,
          "person": {
            "id": 94437,
            "first_name": "Manuel Fernando",
            "last_name": "Valla Cutuhuan",
            "photo_url": "assets/83f415301bee484792bf69283abd2acb_20240220_190033_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f517f21b-9500-4b42-a7bc-e2e48fa14626"
        },
        {
          "id": 88216,
          "person": {
            "id": 87516,
            "first_name": "Marcelo",
            "last_name": "Zelaya",
            "photo_url": "assets/565a86ece6504c6696e144808261e009_20230815_142232_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4285a5cd-762f-4aec-a5d2-860a5a50520d"
        },
        {
          "id": 97268,
          "person": {
            "id": 96469,
            "first_name": "Marco",
            "last_name": "Montoya Cocuy",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dff93aae-29fc-4d10-aecf-0ed0e3b35887"
        },
        {
          "id": 93632,
          "person": {
            "id": 92833,
            "first_name": "Marco",
            "last_name": "Torres",
            "photo_url": "assets/f6e66950c3ec46d78f57ad5d5cc70af7_marco_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "758a732b-89d7-42d1-800f-452d82ae8113"
        },
        {
          "id": 92745,
          "person": {
            "id": 91979,
            "first_name": "Maria",
            "last_name": "Camacho Santiago",
            "photo_url": "assets/d8d0a5334a6c453c96e9df1b73c7eb01_MicrosoftTeamsimageFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30c35dfe-6cda-4010-a778-c236ddae4f6a"
        },
        {
          "id": 89579,
          "person": {
            "id": 88874,
            "first_name": "Maria",
            "last_name": "Cardona",
            "photo_url": "assets/rf069e8ec1af8465894a127187ba1f39f_Mariapicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b5b7d6d-6817-4c4b-8585-b2890b6083b6"
        },
        {
          "id": 91640,
          "person": {
            "id": 90920,
            "first_name": "Maria",
            "last_name": "De La Luz Tejada",
            "photo_url": "assets/c601d43dc1c54804bbcd70bf7cb5e7b6_MariaLuzDeLaLuzTejada_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a9b602d-2543-4d49-882d-f639acf0c607"
        },
        {
          "id": 92677,
          "person": {
            "id": 91911,
            "first_name": "Maria",
            "last_name": "DeLaRosa",
            "photo_url": "assets/9f1dab927b95483aa1c97594f3251d5e_1000001828_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8162aa35-8d15-4f6e-8fae-2026681bee0c"
        },
        {
          "id": 89655,
          "person": {
            "id": 88950,
            "first_name": "Maria",
            "last_name": "Flores",
            "photo_url": "assets/da1af70df0e6437c8b7df9077ae679a9_16969925054002938084570267779949_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f89217fe-080a-4c58-a1ad-c644b3653578"
        },
        {
          "id": 91416,
          "person": {
            "id": 90698,
            "first_name": "Maria",
            "last_name": "Garcia",
            "photo_url": "assets/r13bc25d544134e1cbfd3eb8bb0dbbe94_MariaGpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e506c96-0207-49f8-b4a8-03543139fac5"
        },
        {
          "id": 94077,
          "person": {
            "id": 93278,
            "first_name": "Maria",
            "last_name": "Huerta",
            "photo_url": "assets/5eafe01a6339488ab432f55115f0bf9f_20240119_100610_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5bbdc54c-4e9a-4bfc-a8a1-356a9dc4a2f9"
        },
        {
          "id": 90510,
          "person": {
            "id": 89796,
            "first_name": "Maria",
            "last_name": "Joya Granados",
            "photo_url": "assets/97828c83e32e4a0b936a9e25fd3200e1_MariaJoya_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "58ef27dd-3bba-4784-b252-d7e58821a0d7"
        },
        {
          "id": 92078,
          "person": {
            "id": 91353,
            "first_name": "Maria",
            "last_name": "Martinez",
            "photo_url": "assets/c9013630dde54d28afaa1ac18b552200_20231122_192804.jpgMaria_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7875,
              "name": "Swing",
              "contract": {
                "id": 1491,
                "name": "North Wales, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "a153d850-6493-4210-a779-9ec041201f67",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c76aa4ca-f583-43f0-87b4-fc88fbe8a69a"
        },
        {
          "id": 94047,
          "person": {
            "id": 93248,
            "first_name": "Maria",
            "last_name": "Mendoza",
            "photo_url": "assets/502ffb35f8f1441c9393329bb67c4404_Martha_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ee2089c-8a60-4652-9aed-e0d803757d9c"
        },
        {
          "id": 91866,
          "person": {
            "id": 91143,
            "first_name": "Maria",
            "last_name": "Ojeda Carreon",
            "photo_url": "assets/202acb8a49a54cb3b10128aa4f93e365_FemalePhotofornophoto_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8038,
              "name": "Day",
              "contract": {
                "id": 5831,
                "name": "Denver Service Center",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aa9ac4b5-0c72-4ca1-8e5a-aac497872ead",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "14764c8b-581f-4dc0-881f-e60cd09a75c9"
        },
        {
          "id": 93630,
          "person": {
            "id": 92831,
            "first_name": "Maria",
            "last_name": "Perez",
            "photo_url": "assets/f315ae3b92c0486692285cee2c20d442_MariaPerez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c8347c29-3e7b-4a14-974a-2485f0ac2fd1"
        },
        {
          "id": 90514,
          "person": {
            "id": 89800,
            "first_name": "Maria",
            "last_name": "Rivera Mendoza",
            "photo_url": "assets/7fb83546da5e4efeac9f3bb5f4d7ace6_1000002421_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d7633b5-4ae9-496c-9631-337792b5558f"
        },
        {
          "id": 96009,
          "person": {
            "id": 95209,
            "first_name": "Maria",
            "last_name": "Rosales",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b9f7c71-da54-4831-b8bc-3617676c03fb"
        },
        {
          "id": 94225,
          "person": {
            "id": 93425,
            "first_name": "Maria Angeles",
            "last_name": "Avalos De Hernandez",
            "photo_url": "assets/2fa9fb2e253b4acaa4855a3dcea88057_MariaAngeles_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "159713f1-10af-4dc4-8793-65de24f64b71"
        },
        {
          "id": 94122,
          "person": {
            "id": 93323,
            "first_name": "Maria De La Luz",
            "last_name": "Robledo Alvarez",
            "photo_url": "assets/eeda7d47edb341c8b4c1b935746ee3d0_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "10255c1e-7445-461b-a847-d7da46cfe94b"
        },
        {
          "id": 94116,
          "person": {
            "id": 93317,
            "first_name": "Maria Del Carmen",
            "last_name": "Avila",
            "photo_url": "assets/c92bb4519c924297b404b264f4062ad2_Marina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "015a9711-dd36-44a4-b0e9-41ff8cf6ad9c"
        },
        {
          "id": 93759,
          "person": {
            "id": 92960,
            "first_name": "Maria Del Carmen",
            "last_name": "De Real",
            "photo_url": "assets/42d97f5bb91a40e08c50122e1a361865_MariaDelCarmen_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb36c7cc-cdd5-4a7a-94f4-97e14eaf961b"
        },
        {
          "id": 93784,
          "person": {
            "id": 92985,
            "first_name": "Maria Guadalupe",
            "last_name": "Farias",
            "photo_url": "assets/48d168eb864b41908f1720a5097d6e12_MariaFarias_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e8a0871-9d88-4b23-a535-1c32231d3dcc"
        },
        {
          "id": 89658,
          "person": {
            "id": 88953,
            "first_name": "Maria Vianney",
            "last_name": "Mendoza",
            "photo_url": "assets/ede1e0e0c6e64ddcb9619d97a5b8853d_Vianney_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1840213d-5092-48c1-a4ff-a66d14a63ca7"
        },
        {
          "id": 69038,
          "person": {
            "id": 68503,
            "first_name": "Mariel",
            "last_name": "Chavez",
            "photo_url": "assets/8f91342e61a2463f885ef6ff16e15e91_Resized_20220314_122337_t.jpg",
            "phone_number": "4845503292",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "333f1cb6-1c25-4038-b0d3-523811410373"
        },
        {
          "id": 94113,
          "person": {
            "id": 93314,
            "first_name": "Marina",
            "last_name": "Hernandez",
            "photo_url": "assets/44126417c77942bd8af69d0800a4f9ab_Marina1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56ba495a-6996-4f21-b4da-63ed9ddf3758"
        },
        {
          "id": 97335,
          "person": {
            "id": 96536,
            "first_name": "Marina",
            "last_name": "Orozco",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-03-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d447d7c-67cb-43ae-bcb3-e3028c400e41"
        },
        {
          "id": 97383,
          "person": {
            "id": 96586,
            "first_name": "Mario",
            "last_name": "Luna",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2824,
              "name": "Swing",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "79f9d211-3a6c-4a9b-9593-7774095b4dd3"
        },
        {
          "id": 93763,
          "person": {
            "id": 92964,
            "first_name": "Marisela",
            "last_name": "Mier",
            "photo_url": "assets/f2d48e8ac8c14c4388a1a4717599d58e_maricela_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f5a11bb2-fba9-487d-868b-8e6e8278818a"
        },
        {
          "id": 94478,
          "person": {
            "id": 93678,
            "first_name": "Marisnelia",
            "last_name": "Tona Bolivar",
            "photo_url": "assets/c1685b79ca414875ac98cdc79b557244_IMG_20240223_105537_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff56f554-1cc9-44d7-b618-4de45113fda0"
        },
        {
          "id": 91830,
          "person": {
            "id": 91108,
            "first_name": "Markus",
            "last_name": "McCoy",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c29dbf7a-6d98-404d-9e5b-87cf8f39a30e"
        },
        {
          "id": 93668,
          "person": {
            "id": 92869,
            "first_name": "Marsha",
            "last_name": "Keehan",
            "photo_url": "assets/71918efe5c1e484c96234d7a083d58ab_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "14fa0458-b9ef-497b-b300-90e1ef525ac7"
        },
        {
          "id": 90793,
          "person": {
            "id": 90078,
            "first_name": "Martha",
            "last_name": "Barahona",
            "photo_url": "assets/4e09b9f3edb2465abb1b43d00bb3d53b_MarthaBarahona_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2978,
              "name": "Swing",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "723c0448-7e91-496d-a105-b23817df81dc"
        },
        {
          "id": 93633,
          "person": {
            "id": 92834,
            "first_name": "Martha",
            "last_name": "Garcia",
            "photo_url": "assets/b222fc6d93ad4d4bba2ec99fc5da6b02_MarthaGarcia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7e0c754-9d59-48e8-91fb-c4ca8631f260"
        },
        {
          "id": 94115,
          "person": {
            "id": 93316,
            "first_name": "Martha",
            "last_name": "Hernandez",
            "photo_url": "assets/52a5537dbaea461eb0b4eccbb1c1317f_Martha2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb5761a4-a117-4b4c-95c9-5a71918c3047"
        },
        {
          "id": 94687,
          "person": {
            "id": 93887,
            "first_name": "Martha",
            "last_name": "Leon Espino",
            "photo_url": "assets/r3999d455b61d46e49ab74a733ad906be_20240130_135736_t.jpg",
            "phone_number": "(503) 536-5029",
            "email_address": "mespino@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47824e2d-d0ae-4d88-949c-889ed19e5244"
        },
        {
          "id": 93756,
          "person": {
            "id": 92957,
            "first_name": "Mauricio",
            "last_name": "Guevara Sanchez",
            "photo_url": "assets/7fcba16b441748b5ab0eaa782776e90d_mauricio_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40f5f61b-6444-41c8-b035-ca9c69844ccb"
        },
        {
          "id": 91195,
          "person": {
            "id": 90476,
            "first_name": "Mayly",
            "last_name": "Santos",
            "photo_url": "assets/e9cd3e2e6d6b48069b8310366185d3e1_MaylySantos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3144,
              "name": "Swing",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb022d20-ccb9-4751-ab03-f7292874f432"
        },
        {
          "id": 94953,
          "person": {
            "id": 94153,
            "first_name": "Melissa",
            "last_name": "Irving",
            "photo_url": "assets/rf5c1ce2a84254aa7bc86331b3ce4dc02_M.Irvingphoto_t.png",
            "phone_number": "3134391815",
            "email_address": "mirving@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8767,
              "name": "Day",
              "contract": {
                "id": 6747,
                "name": "1633 Fairlane Circle",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            },
            {
              "id": 8871,
              "name": "Shift B",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8717,
              "name": "Unassigned",
              "contract": {
                "id": 2221,
                "name": "Fairlane Business Park 5",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8729,
              "name": "Unassigned",
              "contract": {
                "id": 6740,
                "name": "Commerce Park 2 15090",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8718,
              "name": "Unassigned",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8755,
              "name": "Unassigned",
              "contract": {
                "id": 6751,
                "name": "Commerce Park 2 15080",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8730,
              "name": "Unassigned",
              "contract": {
                "id": 2217,
                "name": "Fairlane North",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8756,
              "name": "Unassigned",
              "contract": {
                "id": 6746,
                "name": "Fairlane Business Park 3",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8757,
              "name": "Unassigned",
              "contract": {
                "id": 6750,
                "name": "IT Bic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8727,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8728,
              "name": "Unassigned",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11f8652c-2251-4d4f-a3a2-20e71143dddc"
        },
        {
          "id": 90442,
          "person": {
            "id": 89729,
            "first_name": "Melody",
            "last_name": "Batule",
            "photo_url": "assets/re32ed90be3ef4a4291dbdd5253bc4429_IMG3557_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21d445a8-3ee4-42f2-8dac-7b4b7aff40b9"
        },
        {
          "id": 94041,
          "person": {
            "id": 93242,
            "first_name": "Melva",
            "last_name": "Argueta",
            "photo_url": "assets/81ad74a21ab74ff6a8d0b96639a6209a_IMG_1568_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4aecdcbc-a1ca-4728-bc31-553e9f98b70d"
        },
        {
          "id": 93739,
          "person": {
            "id": 92940,
            "first_name": "Mercedes",
            "last_name": "Walker",
            "photo_url": "assets/r591887e2ec40439e8541c04b26dfcca4_1000003542_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ec6e84f1-9d0c-4e6e-87d1-c1663d17eaf5"
        },
        {
          "id": 93858,
          "person": {
            "id": 93059,
            "first_name": "Meyona",
            "last_name": "Clark",
            "photo_url": "assets/05e1b2a0e89b4e9fbe0afd8688e4acaa_Meyona_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8686,
              "name": "Swing",
              "contract": {
                "id": 5544,
                "name": "Cedar Rapids Downtown",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b81ab5f3-6dcd-4e50-969b-56157fdb7d82",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "50f154c1-0fbb-425d-8ea1-47c99415dbb6"
        },
        {
          "id": 93349,
          "person": {
            "id": 92580,
            "first_name": "Michael",
            "last_name": "Gordon",
            "photo_url": "assets/00f4fc063e0449c9a152a378fcabd1c5_MichaelGordon_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8912bdc0-a2ac-47dd-ac8e-50c7029105b4"
        },
        {
          "id": 93308,
          "person": {
            "id": 92539,
            "first_name": "Michael",
            "last_name": "Lewis",
            "photo_url": "assets/81f24ac43c6b41d5866dcf515defe56a_MLewis4insite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c0f40a4-24bb-484f-9e13-47802439741e"
        },
        {
          "id": 92494,
          "person": {
            "id": 91728,
            "first_name": "Miguel",
            "last_name": "Gonzalez",
            "photo_url": "assets/7e6f93cac8c442e3914497dead556d0d_miguelangel_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a6213df-0a83-471f-9b6a-bf49c6f010af"
        },
        {
          "id": 94073,
          "person": {
            "id": 93274,
            "first_name": "Mileidys",
            "last_name": "Ochoa",
            "photo_url": "assets/b9c34a5debb74ab9a191d4dad020462c_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb657bcf-c9ef-4559-9c3e-8f48b5bebfa4"
        },
        {
          "id": 93782,
          "person": {
            "id": 92983,
            "first_name": "Minerva",
            "last_name": "Hinostroza",
            "photo_url": "assets/a9e7e48db0754c49af92dc092973a5d8_minerva_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21c3ac16-e58c-4622-8b66-0be052219f3d"
        },
        {
          "id": 90731,
          "person": {
            "id": 90016,
            "first_name": "Mireya",
            "last_name": "Ocampo",
            "photo_url": "assets/7336cb719f224575852a9e9a65628dd3_MireyaOcampo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f085aa4e-c8bc-4b70-a67d-d05948ca9de5"
        },
        {
          "id": 94109,
          "person": {
            "id": 93310,
            "first_name": "Miriam",
            "last_name": "Ake",
            "photo_url": "assets/bc46e40f46874076952942ff6172fe28_Miriam_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e02b2f81-030a-4165-92b4-e30fe14fdf0b"
        },
        {
          "id": 93637,
          "person": {
            "id": 92838,
            "first_name": "Miriam",
            "last_name": "Medina",
            "photo_url": "assets/7baf9e184aa24c27b1446ff9a62057a3_MiriamMedina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb51d46a-360a-495e-80e6-259a4e0a6bc8"
        },
        {
          "id": 93451,
          "person": {
            "id": 92652,
            "first_name": "Miriam",
            "last_name": "Valdivia Villegas",
            "photo_url": "assets/24bcd2b86fe1497c8669521c64712e65_20231227_013319_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c93438ae-11b2-45bf-ba45-a3d44171be56"
        },
        {
          "id": 94057,
          "person": {
            "id": 93258,
            "first_name": "Monica",
            "last_name": "Aguilera",
            "photo_url": "assets/6971cd8604aa4c5baa3f938674b09ecb_Monica_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e4b78cf-0df6-47e8-82b8-56656e0d2656"
        },
        {
          "id": 95415,
          "person": {
            "id": 94618,
            "first_name": "Monica",
            "last_name": "Gutierrez Beltran",
            "photo_url": "assets/fa285ba09bc74f94b603fbb273efb4f7_Screenshot20240220at3.18_t.52PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7495efc9-7de5-45f6-b583-e0d433a8b992"
        },
        {
          "id": 96656,
          "person": {
            "id": 95857,
            "first_name": "Monica",
            "last_name": "Mora",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "66a74efe-0e6e-4151-be41-62af9ec8da80"
        },
        {
          "id": 95960,
          "person": {
            "id": 95160,
            "first_name": "Moreida",
            "last_name": "Torres Fuenmayor",
            "photo_url": "assets/9bc3805d32754e97972d4b8e5824ddc0_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1245,
              "name": "Swing",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2371f4ed-b3d7-48f9-a930-cde99d438547"
        },
        {
          "id": 94760,
          "person": {
            "id": 93960,
            "first_name": "Musa",
            "last_name": "Moheisen",
            "photo_url": "assets/4c06c00874814f888c23aff0237febf0_musa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8872,
              "name": "Shift A",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e3f8bba-52f1-4aff-86a1-98555e356923"
        },
        {
          "id": 94761,
          "person": {
            "id": 93961,
            "first_name": "Myrvette",
            "last_name": "Blaceri",
            "photo_url": "assets/00a280197776466f987bd465d6dcfd70_MyrvetteBlaceri_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8871,
              "name": "Shift B",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2edad664-5e03-44d9-b565-9952bc7c4d0f"
        },
        {
          "id": 90748,
          "person": {
            "id": 90033,
            "first_name": "Nancy",
            "last_name": "Gonzalez",
            "photo_url": "assets/6d8772ebdbc644428b43f7bf82f56533_1000000788_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7395,
              "name": "Day",
              "contract": {
                "id": 5530,
                "name": "Wenatchee, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d5e7017-b0cf-4d21-a979-db6662ee490b"
        },
        {
          "id": 96291,
          "person": {
            "id": 95491,
            "first_name": "Nardis",
            "last_name": "Taylor Hebbert",
            "photo_url": "assets/b5ca1bdc14e74d7197849ef17171da47_Screenshot20240307at12.38_t.34PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4bafbbed-49f4-4902-aa05-19931c761624"
        },
        {
          "id": 95899,
          "person": {
            "id": 95100,
            "first_name": "Nayeli",
            "last_name": "Lugardo-Blancas",
            "photo_url": "assets/f5d4cbc46bf042a3abfcf56cbffb686a_Nayelispicture20240308_064110_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "209b89fe-23b8-49b9-a2a7-ac7021034bae"
        },
        {
          "id": 94076,
          "person": {
            "id": 93277,
            "first_name": "Neisy",
            "last_name": "Vilchez Gonzalez",
            "photo_url": "assets/9b07f76eaa764bbfbdf65954d2045dd4_Neisy_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "17eca936-b521-42ef-a3f6-ebcb31d2044c"
        },
        {
          "id": 75608,
          "person": {
            "id": 75072,
            "first_name": "Nguyen",
            "last_name": "Nguyen",
            "photo_url": "assets/c55e8b20d17d4e048cee0981e068ec19_IMG_0815_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-11-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47d7dcbc-e022-4205-8cf8-1c4158677c41"
        },
        {
          "id": 96441,
          "person": {
            "id": 95642,
            "first_name": "Nicholas",
            "last_name": "Lee",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8cc083a-5805-4ab9-adc2-2a34bc5d4aec"
        },
        {
          "id": 91718,
          "person": {
            "id": 90998,
            "first_name": "Nickolas",
            "last_name": "Williamson",
            "photo_url": "assets/bf5879ef287a4f1d9c2e97e2a89053a0_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8002,
              "name": "On-Call",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d477f6e-5a26-4f99-8d24-000ca644ffca"
        },
        {
          "id": 94053,
          "person": {
            "id": 93254,
            "first_name": "Nicole",
            "last_name": "Slater",
            "photo_url": "assets/b3247e29c1074610bd23a6ccf086f618_Nicole_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8739,
              "name": "Day",
              "contract": {
                "id": 5976,
                "name": "Hillsboro",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7737bfdb-3f4d-427e-8577-6648e36ee3cd"
        },
        {
          "id": 93304,
          "person": {
            "id": 92535,
            "first_name": "Nikki",
            "last_name": "Mapp",
            "photo_url": "assets/e7a4583d10f44279a91cc5bf09d7e680_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bbb79cf7-ba91-456a-beaa-b8300419aca4"
        },
        {
          "id": 89886,
          "person": {
            "id": 89180,
            "first_name": "Noel",
            "last_name": "Rodriguez",
            "photo_url": "assets/981ac18644ad475bab6b71f23c56ead3_noelpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81b8868d-0fd3-4f02-b5ef-3fbb231b0972"
        },
        {
          "id": 94056,
          "person": {
            "id": 93257,
            "first_name": "Norma",
            "last_name": "Pena",
            "photo_url": "assets/436d8cf58aa7476fba50faf58f99dba9_Norma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc43af2a-c545-4298-9af1-0a0fc06dc5af"
        },
        {
          "id": 91909,
          "person": {
            "id": 91185,
            "first_name": "Octavio",
            "last_name": "Avila",
            "photo_url": "assets/3c711e2d23ef418aa129f0cfbab9684f_Avila_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5812c813-8501-44ad-89df-51d6488f3269"
        },
        {
          "id": 81100,
          "person": {
            "id": 80469,
            "first_name": "Odette",
            "last_name": "Nkobwa",
            "photo_url": "assets/b6152531896c4443a22741fded1aae5d_OdetteNkobwaCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "03e888d0-fd07-40f2-86b5-86a4cfb3d521"
        },
        {
          "id": 91391,
          "person": {
            "id": 90673,
            "first_name": "Olga",
            "last_name": "Ochoa Herrera",
            "photo_url": "assets/1ee8e3ee59a443f2a048e90771b94ee5_OlgaOchoa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ef83936f-99ca-42ad-a4c7-d498a0cda3c3"
        },
        {
          "id": 90487,
          "person": {
            "id": 89773,
            "first_name": "Omar",
            "last_name": "Franco",
            "photo_url": "assets/a736b91e9f2a41e28705a55bfc46ab67_IMG_8994_t.jpg",
            "phone_number": "6024881334",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2418,
              "name": "Swing",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7e5ae7b-9128-4575-9f0f-fc3572c71c94"
        },
        {
          "id": 95528,
          "person": {
            "id": 94731,
            "first_name": "Omar",
            "last_name": "Perez Sotolongo",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2198d391-b41b-46dc-99ee-40785e763056"
        },
        {
          "id": 94055,
          "person": {
            "id": 93256,
            "first_name": "Orion",
            "last_name": "Ballway",
            "photo_url": "assets/61ba620d835f47adb1cd28ff42fa801d_Orion_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8721,
              "name": "Night",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65aa94fe-0e15-45f3-bc50-3c7217fa7960"
        },
        {
          "id": 93312,
          "person": {
            "id": 92543,
            "first_name": "Oscar",
            "last_name": "Torres",
            "photo_url": "assets/13599e0850cc463592bc3226b48f96bd_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d023c70f-68f6-411a-a098-af2ccc97d74a"
        },
        {
          "id": 90488,
          "person": {
            "id": 89774,
            "first_name": "Osei",
            "last_name": "Yaw",
            "photo_url": "assets/66b29bc1b732419f8fbbe8e0224a7e0c_20231013_155712_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f95fa1d-a5c6-4dc0-be05-36b28fc54849"
        },
        {
          "id": 93624,
          "person": {
            "id": 92825,
            "first_name": "Oswar",
            "last_name": "Tomas Palacios",
            "photo_url": "assets/rf7b54fc5f9de4c2bba5e624faa7d3683_1000000264_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7978555d-a6ba-4796-b6c5-ffa14caf74a0"
        },
        {
          "id": 93113,
          "person": {
            "id": 92345,
            "first_name": "Otilia",
            "last_name": "Jimenez Torres",
            "photo_url": "assets/318ead10a17742c8b701b2b4baead127_OtiliaJimenez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8515e015-cd9c-4da7-af35-e8b8c1e692ec"
        },
        {
          "id": 91825,
          "person": {
            "id": 91103,
            "first_name": "Paul",
            "last_name": "Starks",
            "photo_url": "assets/37500513c3714b4495042fe9a62c35db_PStarkspicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "329b250d-2d24-4b6a-8bfa-f229e6a65162"
        },
        {
          "id": 95077,
          "person": {
            "id": 94277,
            "first_name": "Paulette",
            "last_name": "McNeal",
            "photo_url": "assets/r0fcc3bd105e34d7b9857504bd621d9af_Paula_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d3aaab4c-63a7-41ac-a8d0-fe1c66b620ff"
        },
        {
          "id": 93801,
          "person": {
            "id": 93002,
            "first_name": "Pedro",
            "last_name": "Flores Corrales",
            "photo_url": "assets/45f1c86718fb4db88248ea70196d6b28_perjo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e4248e9-3b3c-4b49-9116-3ec489d3ac0b"
        },
        {
          "id": 93393,
          "person": {
            "id": 92624,
            "first_name": "Percious",
            "last_name": "Koonce",
            "photo_url": "assets/7e59934d09ef4c5985e59992d4be3e61_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ddd9035-6046-48c6-b1be-beca7025f858"
        },
        {
          "id": 92652,
          "person": {
            "id": 91886,
            "first_name": "Perla",
            "last_name": "Ortiz",
            "photo_url": "assets/3ad1cbc48e0946b381f9d373276b001b_Perla4insite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2978,
              "name": "Swing",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39d47a2c-7bab-4e41-a74e-c28b3fb0826c"
        },
        {
          "id": 93084,
          "person": {
            "id": 92317,
            "first_name": "Priscilla",
            "last_name": "Luevano",
            "photo_url": "assets/18ca8f6f247248c9988f2a8bffa2b653_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f3fff32c-1d70-4bfa-90a5-cc6c7e86da2c"
        },
        {
          "id": 93389,
          "person": {
            "id": 92620,
            "first_name": "Qrunisha",
            "last_name": "Brown",
            "photo_url": "assets/c6f9b2b7293d4e36a943df818131a027_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ab189d1-378a-49f4-8445-2a60f3fca651"
        },
        {
          "id": 89582,
          "person": {
            "id": 88877,
            "first_name": "Quillar",
            "last_name": "Christian",
            "photo_url": "assets/39032e32550647e59c45c9318b914bf5_QuillarChristianimage_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dbe6c555-d11d-4dd2-b3d6-731ad740c881"
        },
        {
          "id": 93654,
          "person": {
            "id": 92855,
            "first_name": "Rafael",
            "last_name": "Galue Nunez",
            "photo_url": "assets/08d2119dabf043fc82d8fd8cacc5229c_20240118_164811_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40b17e08-105a-4e26-8e40-fffae04d3950"
        },
        {
          "id": 91721,
          "person": {
            "id": 91001,
            "first_name": "Raho",
            "last_name": "Mohamed",
            "photo_url": "assets/c93ea671591447c182b6c741bb0ecdea_RahoHeadshotDEC2023_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e58fa2c7-a7db-4cf9-8bf8-bf6fb9343aa6"
        },
        {
          "id": 96762,
          "person": {
            "id": 95962,
            "first_name": "Raimina",
            "last_name": "Smith",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7637,
              "name": "Swing",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be9b696c-fd99-4e80-9497-32f4a9d44c87"
        },
        {
          "id": 67277,
          "person": {
            "id": 66735,
            "first_name": "Raman",
            "last_name": "Khanna",
            "photo_url": "assets/063bc2b3f841487ba5bbedce67bb6d5a_ScreenShot20220206at6.23_t.49PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e801a72-a7e1-4344-a79e-a0576ff536c5"
        },
        {
          "id": 93641,
          "person": {
            "id": 92842,
            "first_name": "Rayhar",
            "last_name": "Figuracion",
            "photo_url": "assets/1bc78bc8df224e8ca34e487f7007b35f_Rayhar_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd94738c-85b3-4979-909d-012328ee02d5"
        },
        {
          "id": 96756,
          "person": {
            "id": 95956,
            "first_name": "Reece",
            "last_name": "Adams",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5867,
              "name": "Swing",
              "contract": {
                "id": 4124,
                "name": "Exton",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "f7086bec-946e-4b69-9337-bc15669afd06",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f4f83f13-b357-455d-8ed1-31b12cf7837b"
        },
        {
          "id": 93783,
          "person": {
            "id": 92984,
            "first_name": "Reyna",
            "last_name": "Narvaez",
            "photo_url": "assets/24700e73610142e99db7cf03b75a81f4_reina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb8212ac-60a1-472a-a3fd-0380d78c59ab"
        },
        {
          "id": 91210,
          "person": {
            "id": 90491,
            "first_name": "Richard",
            "last_name": "Williams",
            "photo_url": "assets/f0e3ac8c5e2142359dc3fae4c20fbf16_IMG_0609_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7955,
              "name": "Shift B",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "728423e4-5ecf-42ab-8b69-cf40db7ff5f6"
        },
        {
          "id": 96162,
          "person": {
            "id": 95364,
            "first_name": "Rina",
            "last_name": "Osorio",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "71461123-b3a2-4afa-a95e-2dbf27814a9a"
        },
        {
          "id": 92189,
          "person": {
            "id": 91464,
            "first_name": "Rita",
            "last_name": "Lima",
            "photo_url": "assets/dc3b407a273d42e8afb272efe23dcbc9_RitaL._t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7890,
              "name": "Day",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "187e1e7c-c981-43fe-9231-61d2f0ddc104"
        },
        {
          "id": 96592,
          "person": {
            "id": 95793,
            "first_name": "Rob",
            "last_name": "Green",
            "photo_url": "assets/dc579bc55b474d0e94eaefb2ebf29562_RobertGreenProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "871a9dde-0bcb-4bcb-b579-a9225ebda7a5"
        },
        {
          "id": 93864,
          "person": {
            "id": 93065,
            "first_name": "Roberto",
            "last_name": "Chavez Rivera",
            "photo_url": "assets/a2e81eb4ab8b43f8b43c05d4884ad997_1000003573_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9887a676-0051-4222-89fa-22f36f613ac6"
        },
        {
          "id": 74269,
          "person": {
            "id": 73727,
            "first_name": "Rodrigo",
            "last_name": "Baltazar",
            "photo_url": "assets/c54d49715b3a4dacb445dc32f6b237fb_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9e6d0fd3-a946-4de5-aabd-bfaaf39f978b"
        },
        {
          "id": 91204,
          "person": {
            "id": 90485,
            "first_name": "Ronald",
            "last_name": "Bartell",
            "photo_url": "assets/db2bd139b6d14ed6904a57026f475bf4_Image102023at9.38AM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2947,
              "name": "Powered Industrial Vehicle Operator",
              "name_abbreviation": "PIVOPER"
            }
          ],
          "shifts": [
            {
              "id": 7954,
              "name": "Shift A2",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d40f718c-be6e-4965-87ec-31b267895f7a"
        },
        {
          "id": 93235,
          "person": {
            "id": 92467,
            "first_name": "Ronnie",
            "last_name": "Marks",
            "photo_url": "assets/497d3ebe40a44a2e8b13650634795623_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "045c9c17-de76-4b96-9758-0b74918eb259"
        },
        {
          "id": 92715,
          "person": {
            "id": 91949,
            "first_name": "Rosa",
            "last_name": "Cruz Cecilio",
            "photo_url": "assets/rd5694afae16141f2a78a2d9cd5ff6046_C20C7B364920475B8CEEBADE3DDE4EF6_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1942,
              "name": "Day",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5f49fe1-599b-46d3-8794-b1b873c8d17e"
        },
        {
          "id": 93631,
          "person": {
            "id": 92832,
            "first_name": "Rosa",
            "last_name": "Marquez",
            "photo_url": "assets/00b8fabdba284df789500b80b19ff5b6_RosaMarquez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d1fa1766-8a58-45e3-b8a9-e5f90a5de5a2"
        },
        {
          "id": 94157,
          "person": {
            "id": 93358,
            "first_name": "Rosa",
            "last_name": "Ortega",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a86c6b16-eb68-48bd-b797-0cb616b68a28"
        },
        {
          "id": 93761,
          "person": {
            "id": 92962,
            "first_name": "Rosa",
            "last_name": "Sepulveda",
            "photo_url": "assets/a78c52796ac64aab832ff769af83a3f6_rosaSepulveda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb6ba913-7c55-482a-b03d-e8115f031663"
        },
        {
          "id": 95490,
          "person": {
            "id": 94693,
            "first_name": "Rosalba",
            "last_name": "Martinez Diaz",
            "photo_url": "assets/b24b684caca145c58dad67deb6822b5b_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7cd3990c-13e3-46c0-a22c-ffbac138497a"
        },
        {
          "id": 93609,
          "person": {
            "id": 92810,
            "first_name": "Rosario",
            "last_name": "De La O Diaz",
            "photo_url": "assets/f5ac8977d1674fe0a34ff280c50dd426_FemalePFP_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7180,
              "name": "On-Call",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bfc9a8ce-79c9-4037-984a-e33bbbad5f9d"
        },
        {
          "id": 91683,
          "person": {
            "id": 90963,
            "first_name": "Roxana",
            "last_name": "Romero Ortega",
            "photo_url": "assets/3991c1c89692476db24f9f8d4051307d_IMG_20231102_085638_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dfb34db8-edc2-4789-ba6c-c84eca572812"
        },
        {
          "id": 93316,
          "person": {
            "id": 92547,
            "first_name": "Rubileydi",
            "last_name": "Felix",
            "photo_url": "assets/6b8669a63b744f72b47b86ee612aed18_Rubileydi_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8b7e2c83-3a2a-49a5-a2de-c6f2ac675f3c"
        },
        {
          "id": 757,
          "person": {
            "id": 757,
            "first_name": "Ruth",
            "last_name": "Pernillo Caal",
            "photo_url": "assets/7fc9a4e10ca5449e9238fc5433c39a13_IMG_67721_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2497,
              "name": "Swing",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21aa5a66-822c-4929-b151-317813b1c13a"
        },
        {
          "id": 91897,
          "person": {
            "id": 91173,
            "first_name": "Ryan",
            "last_name": "Frey",
            "photo_url": "",
            "phone_number": "4193033775",
            "email_address": "rfrey@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "55497c16-4243-4343-a9fc-8bb0f035383f"
        },
        {
          "id": 89847,
          "person": {
            "id": 89141,
            "first_name": "Ryan",
            "last_name": "Miller",
            "photo_url": "assets/r890175c592384c0db07dc2839f446c84_RyanMillerprofile_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1490,
              "name": "Night",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-09-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5884501a-af70-49bf-9de8-835af871d758"
        },
        {
          "id": 94485,
          "person": {
            "id": 93685,
            "first_name": "Salvadora",
            "last_name": "Cifuentes",
            "photo_url": "assets/rfb7346aef00544638cab2b92d8c2c9ad_1000004667_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6299ff12-dced-4bd2-9fe7-6832c22c8808"
        },
        {
          "id": 18916,
          "person": {
            "id": 19051,
            "first_name": "Sameer",
            "last_name": "Qadoora",
            "photo_url": "assets/1588029626.5955286_Sameer_t.jpg",
            "phone_number": "",
            "email_address": "231803@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "289d1cfa-ee14-4f78-ace9-42d63128067d"
        },
        {
          "id": 92605,
          "person": {
            "id": 91839,
            "first_name": "Samuel",
            "last_name": "Ogunmodede",
            "photo_url": "assets/786d5d18699f42feb281b3acf45e4954_IMG_4914_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "249991c5-b769-41a5-9e5e-bff57827d384"
        },
        {
          "id": 97051,
          "person": {
            "id": 96252,
            "first_name": "Sandra",
            "last_name": "Vasquez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90970e59-e28f-4af7-9322-7d980c76bc5f"
        },
        {
          "id": 92977,
          "person": {
            "id": 92211,
            "first_name": "Sanjuana",
            "last_name": "Pacheco",
            "photo_url": "assets/9f61176962254cb6939c37e31230547b_SanJuana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b29efe3-b065-4995-8733-112b90e7a2ce"
        },
        {
          "id": 92892,
          "person": {
            "id": 92126,
            "first_name": "Sarah",
            "last_name": "Downing",
            "photo_url": "assets/6dbe89e92b4241feb313c802be408bcb_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92587984-43ab-4325-a93b-ec17b1d4de23"
        },
        {
          "id": 94844,
          "person": {
            "id": 94044,
            "first_name": "Seth",
            "last_name": "Westmoreland",
            "photo_url": "assets/f28b7ce6aa26426ea4ac0b26111c5cdc_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "63e4c4e0-bbf0-4221-8274-51b3e99f54b7"
        },
        {
          "id": 90692,
          "person": {
            "id": 89977,
            "first_name": "Sevelle",
            "last_name": "Meeks",
            "photo_url": "assets/79ce43c51c904badb73c571c88f732ec_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1df57f54-53fc-4f60-858e-2ab5633e1b07"
        },
        {
          "id": 94033,
          "person": {
            "id": 93235,
            "first_name": "Shakendra",
            "last_name": "Ligon",
            "photo_url": "assets/eaf7c36cda364bd2a66816a724e7c984_attachment1709650770875_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ccd22fe1-5734-4fb7-9b4b-718ca6b4fc6e"
        },
        {
          "id": 91209,
          "person": {
            "id": 90490,
            "first_name": "Shanpri",
            "last_name": "Manley",
            "photo_url": "assets/r22ba429141434c15973971bb757cc2c1_Shampri_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7953,
              "name": "Shift A",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d3e19b3-16bd-463e-aa29-10c392b56dab"
        },
        {
          "id": 91197,
          "person": {
            "id": 90478,
            "first_name": "Shaquan",
            "last_name": "Swain",
            "photo_url": "assets/06df1c562c654bcf94c108c13010a793_ShaquanSwain_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "48e2fe45-2485-435b-9f8d-e6da564ce8c6"
        },
        {
          "id": 74652,
          "person": {
            "id": 74109,
            "first_name": "Sharifa",
            "last_name": "Abdullayeva",
            "photo_url": "assets/r040a414dc2a34632bf1f47d069c80af6_sharifa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8178f85-5be8-4f12-8728-19f05d52ce6b"
        },
        {
          "id": 89851,
          "person": {
            "id": 89145,
            "first_name": "Shawn",
            "last_name": "Aho",
            "photo_url": "assets/5361af7818994a39b27c055afd1fe71b_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7471,
              "name": "Swing",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5fe66c49-fab8-4e4a-9c67-309078420b4c"
        },
        {
          "id": 93957,
          "person": {
            "id": 93158,
            "first_name": "Shawn",
            "last_name": "Metcalf",
            "photo_url": "assets/1cde3212eb194e9c8e6cf9714fe3d93d_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b4990c86-c764-4726-bceb-5e5bc52c4dab"
        },
        {
          "id": 93225,
          "person": {
            "id": 92457,
            "first_name": "Sheila",
            "last_name": "Simpson",
            "photo_url": "assets/92ee181bc1c54e90a9651d8d443f4caf_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25ace896-cb14-4556-89d6-7ddab76c7979"
        },
        {
          "id": 74209,
          "person": {
            "id": 73667,
            "first_name": "Silder",
            "last_name": "Mejia",
            "photo_url": "assets/c9b476720c1f4cbbb5e9e317c89c0afb_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de1c7ec6-04c7-4a44-8d07-89e104e705cc"
        },
        {
          "id": 96468,
          "person": {
            "id": 95669,
            "first_name": "Silvia",
            "last_name": "Travino Reyes",
            "photo_url": "assets/2f62d2a39d1e468a810455e19234d1da_Silviapicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ffe79423-e7f0-4a27-866d-ce08b24a5e2d"
        },
        {
          "id": 93771,
          "person": {
            "id": 92972,
            "first_name": "Sonia",
            "last_name": "Rafael Chavez",
            "photo_url": "assets/2741f4b58b87435d9a1f69c610fda94b_SoniaRafael_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95e9ba83-c9c8-4fdd-bb15-e3ef2af06510"
        },
        {
          "id": 25295,
          "person": {
            "id": 25184,
            "first_name": "Sosfiyas",
            "last_name": "Tres",
            "photo_url": "assets/1587958044.6529171_tres_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-09-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bdb4ecce-181f-4f4a-a9b9-d0f62ab2f7df"
        },
        {
          "id": 92095,
          "person": {
            "id": 91370,
            "first_name": "Sovankiry",
            "last_name": "Sang",
            "photo_url": "assets/37214b9ce1c6436f8a940270a1044791_thumbnail_IMG_6255_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b10a52f6-1942-49b8-b3e3-806dbebd1975"
        },
        {
          "id": 90733,
          "person": {
            "id": 90018,
            "first_name": "Starly",
            "last_name": "Santil",
            "photo_url": "assets/rf5e395a68db44bf0a226e98f4acb7752_starly_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01ffbdfa-699a-4cc0-a7be-b926d7f19954"
        },
        {
          "id": 94066,
          "person": {
            "id": 93267,
            "first_name": "Sterling",
            "last_name": "Ballway",
            "photo_url": "assets/1c928813c8a34ecea4817c231f72895b_Sterling_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "640b7549-3867-48cb-aec9-fdc1aef3b5c2"
        },
        {
          "id": 90696,
          "person": {
            "id": 89981,
            "first_name": "Steve",
            "last_name": "Valdez",
            "photo_url": "assets/a7a6f93819dc4f20b1984dd4473ffe6d_SteveValdez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c00d2c0-9e1c-4a47-93e5-029ce2dc5af9"
        },
        {
          "id": 94954,
          "person": {
            "id": 94154,
            "first_name": "Steven",
            "last_name": "Chang",
            "photo_url": "assets/9638b3ab51ad4dd0a12b9231936e8fbe_Image45_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87930274-991c-46db-96bc-2b2a0ab5a704"
        },
        {
          "id": 89849,
          "person": {
            "id": 89143,
            "first_name": "Steven",
            "last_name": "Papas",
            "photo_url": "assets/rae62eebb35f4476cb4b14eb6e81ca3fd_IMG_2022_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 1490,
              "name": "Night",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-09-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd279ef6-9d8b-41f8-a406-a4f9dc485a16"
        },
        {
          "id": 96643,
          "person": {
            "id": 95844,
            "first_name": "Steven",
            "last_name": "Parkman",
            "photo_url": "assets/de4b6e6cbe5548d79d3fd2c3d62e55c3_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6161cd4-82f1-419a-858e-321545ab2e7c"
        },
        {
          "id": 96769,
          "person": {
            "id": 95969,
            "first_name": "Sulbey",
            "last_name": "Marquez Capa",
            "photo_url": "assets/7233870ce0004b16a26a261318748046_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a2bc7ce9-5df1-43bc-b4e6-bd2913f3ad48"
        },
        {
          "id": 91429,
          "person": {
            "id": 90711,
            "first_name": "Susana",
            "last_name": "Saldana",
            "photo_url": "assets/ree70fdc9d3cb4c099e72971d52f36ce0_attachment1693513185242Susana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2157,
              "name": "Day",
              "contract": {
                "id": 1766,
                "name": "Pleasanton",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56e327ca-cc59-4129-b6e1-01faf1a31ec1"
        },
        {
          "id": 91280,
          "person": {
            "id": 90561,
            "first_name": "TAN",
            "last_name": "BEE LEE",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7855,
              "name": "Day",
              "contract": {
                "id": 5813,
                "name": "Suntec",
                "customer_id": 2155,
                "customer_name": "VMware (CBRE) (SG)",
                "uuid": "5822414e-91a4-4972-930b-dbbb34bcabb4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f9b682cb-12c8-4e01-b2a4-f9a3e19b0c03"
        },
        {
          "id": 96670,
          "person": {
            "id": 95871,
            "first_name": "Taniya",
            "last_name": "Barton",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d5b6c3f-f4e6-4b18-8bb6-3e4b1183aa9c"
        },
        {
          "id": 95254,
          "person": {
            "id": 94452,
            "first_name": "Tarlisa",
            "last_name": "Howard",
            "photo_url": "assets/0e3eeb2e87bc485e99bb95deb1826d80_tarlisa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 9126,
              "name": "Shift B",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "17709414-91d5-4883-9057-8a1aa0a1619f"
        },
        {
          "id": 92024,
          "person": {
            "id": 91299,
            "first_name": "Teara",
            "last_name": "Edwards",
            "photo_url": "assets/1f6aa4592c1d4eaabb1f64a51521eb18_TEdwardspic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "636656a3-b3b3-4547-bcfc-d8e826fb0576"
        },
        {
          "id": 92603,
          "person": {
            "id": 91837,
            "first_name": "Terrianna",
            "last_name": "Cox",
            "photo_url": "assets/81f4ac2c60b9491ab3ec2f0eb8818302_IMG_4913_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc4e5858-3245-4586-9347-289f532ca041"
        },
        {
          "id": 91686,
          "person": {
            "id": 90966,
            "first_name": "Thomas",
            "last_name": "Price",
            "photo_url": "assets/c402997cea834c49a6f62f484f7bbe78_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99ebdf9c-94b7-4846-80b4-177c3ef17e4e"
        },
        {
          "id": 94227,
          "person": {
            "id": 93427,
            "first_name": "Thomas",
            "last_name": "Rohan",
            "photo_url": "assets/3b1c9015dfe74ec8a95e82e6593d042e_Thomas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4c776d82-798f-4812-847f-412882a925e5"
        },
        {
          "id": 90132,
          "person": {
            "id": 89424,
            "first_name": "Tiffany",
            "last_name": "Revesz",
            "photo_url": "assets/e75824b3fac3492a89ca8df5ecb635c9_NewHirePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42de781d-ae9a-4733-9efa-004375bc1113"
        },
        {
          "id": 94031,
          "person": {
            "id": 93233,
            "first_name": "Travis",
            "last_name": "Westbrook",
            "photo_url": "assets/447bd55661f94bfca9987fb83b31f3d4_IMG_5672_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "35b9c86b-7980-43b5-9ca0-8e7afed18845"
        },
        {
          "id": 94032,
          "person": {
            "id": 93234,
            "first_name": "Tyler",
            "last_name": "Davis",
            "photo_url": "assets/1ddc8a8d7bf14a1ab153f4b8fe830aaa_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18849800-97d1-4097-b3f3-23545d851423"
        },
        {
          "id": 97137,
          "person": {
            "id": 96338,
            "first_name": "Tyrese",
            "last_name": "Daniels",
            "photo_url": "assets/47c31e40892d4afaa8442ddef97ee9f3_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "904ace57-b63d-44f0-b257-56f14f73428f"
        },
        {
          "id": 91681,
          "person": {
            "id": 90961,
            "first_name": "Vanessa",
            "last_name": "Bello Bastidas",
            "photo_url": "assets/r4e8e8f598b7c4b61b0cf9988f852d1c6_20231213_162647_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30715bbd-ba53-44c0-bf19-98fba5316ce9"
        },
        {
          "id": 93821,
          "person": {
            "id": 93022,
            "first_name": "Vernon",
            "last_name": "Dorman",
            "photo_url": "assets/5af93a50cfb94b269764c738ec119edb_VernonDorman_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c8690116-956f-4484-bf72-7e3c2695398a"
        },
        {
          "id": 94688,
          "person": {
            "id": 93888,
            "first_name": "Veronica",
            "last_name": "Benitez",
            "photo_url": "assets/re5d03eb192304fff83827dc750d2f867_20240130_135715_t.jpg",
            "phone_number": "5035092883",
            "email_address": "vbenitez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 8702,
              "name": "Unassigned",
              "contract": {
                "id": 5976,
                "name": "Hillsboro",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2024-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a6d14b5-b66b-4189-ab33-d2cf05f84d6e"
        },
        {
          "id": 91988,
          "person": {
            "id": 91263,
            "first_name": "Veronica",
            "last_name": "Collier",
            "photo_url": "assets/9f9fbc1fba8349459e50bcd0701fdbab_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8783,
              "name": "Swing",
              "contract": {
                "id": 6076,
                "name": "Cover My Meds 2",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "998dcbd2-f18e-48d7-a469-f7f1ddc4dd63"
        },
        {
          "id": 88565,
          "person": {
            "id": 87864,
            "first_name": "Veronica",
            "last_name": "Hernandez De Zecena",
            "photo_url": "assets/fc4d9081644445419646cf7c52f7b09e_veronica_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "70b6a993-a5d1-4d3f-b626-aa13ed3fc8a1"
        },
        {
          "id": 93780,
          "person": {
            "id": 92981,
            "first_name": "Veronica",
            "last_name": "Oseguera",
            "photo_url": "assets/f89c7708fa6d439ab3f0e5f5fe3abc12_VeronicaOseguera_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5398854c-c696-41cc-b46e-e31e9f8a6ce0"
        },
        {
          "id": 93754,
          "person": {
            "id": 92955,
            "first_name": "Veronica",
            "last_name": "Rodriguez",
            "photo_url": "assets/8fc7a676b6c04b86af3e85729f06d4ce_veronicaRodriguez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac4ec2be-2fa9-4dc7-97de-e33be8188665"
        },
        {
          "id": 94747,
          "person": {
            "id": 93947,
            "first_name": "Veronica",
            "last_name": "Tobon Ordaz",
            "photo_url": "assets/756b046fbdb84e8e8fd5ccebb98ce4be_IMG_5959_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38fded3c-ce1f-4028-8a39-5ae21d5a7f7f"
        },
        {
          "id": 93626,
          "person": {
            "id": 92827,
            "first_name": "Vicente",
            "last_name": "Zarate",
            "photo_url": "assets/55eda068442b47b49f245ffb0c304f42_VicenteZarate_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "806e2f90-f0b1-4da0-9423-4f1dfbcd23e3"
        },
        {
          "id": 93753,
          "person": {
            "id": 92954,
            "first_name": "Victor",
            "last_name": "Aguilar",
            "photo_url": "assets/fedba476cb2b4959a0a49c1ca80a5c8f_victor_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8701,
              "name": "Swing",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd543f10-ec70-40d5-97a7-a497a241b123"
        },
        {
          "id": 91379,
          "person": {
            "id": 90661,
            "first_name": "Victor",
            "last_name": "Romero",
            "photo_url": "assets/d2948913035e4974a865fdc6a23b9d03_1000003743_t.jpg",
            "phone_number": "7078051605",
            "email_address": "vromero@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 7216,
              "name": "Day",
              "contract": {
                "id": 5522,
                "name": "Fremont Center Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ce645c9b-9112-4fe0-86c9-6040179ba04c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7287,
              "name": "Day",
              "contract": {
                "id": 5566,
                "name": "550 California (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "78c1e2c7-0d04-4da4-834d-f78a80e70012",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7259,
              "name": "Day",
              "contract": {
                "id": 5551,
                "name": "333 Market (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f7d4a594-a35d-40ca-9396-0f9db82700ff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7213,
              "name": "Night",
              "contract": {
                "id": 5523,
                "name": "San Francisco/Head Office (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "98ed1974-2405-49f9-96f9-e1c60e22ef93",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            },
            {
              "id": 7210,
              "name": "None",
              "contract": {
                "id": 5515,
                "name": "Palo Alto",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7221,
              "name": "Swing",
              "contract": {
                "id": 5520,
                "name": "San Leandro Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "080cf858-d8ec-4300-9a1a-9f90a2b1c6ca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad5df3b8-0fa1-4603-8577-a930007414a7"
        },
        {
          "id": 93613,
          "person": {
            "id": 92814,
            "first_name": "Vilma",
            "last_name": "Lopez",
            "photo_url": "assets/4841df885d2c4fd69417d369ea2f670d_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b502d19a-a170-4c34-a5d7-2275fcc28ae9"
        },
        {
          "id": 94930,
          "person": {
            "id": 94130,
            "first_name": "Virginia",
            "last_name": "Ferguson",
            "photo_url": "assets/62fc23d3bae44c6fac9ccfe257eb9630_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2599,
              "name": "Swing",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae6de86d-6547-4cf0-8aa8-a7936e8ecdfe"
        },
        {
          "id": 91828,
          "person": {
            "id": 91106,
            "first_name": "Virginia",
            "last_name": "Vazquez",
            "photo_url": "assets/02c22eb429294948a3c77780a6fa40e3_3.VirginiaVasquez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7479dc7e-ad26-40a2-9ad0-8980312426e6"
        },
        {
          "id": 93845,
          "person": {
            "id": 93046,
            "first_name": "Viridiana",
            "last_name": "Cadena Madrigal",
            "photo_url": "assets/59883e84bd424c8fbb9ee5296b9ec1dc_Screenshot20240209094631_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "515da41b-9316-466c-a1f2-9d59829ab713"
        },
        {
          "id": 94059,
          "person": {
            "id": 93260,
            "first_name": "Wade",
            "last_name": "Smith",
            "photo_url": "assets/32eccd3d97ac49edb8479520a669ab56_Wade_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8710,
              "name": "Swing",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2524815-b1d9-4d7d-8833-74d2c64ef74d"
        },
        {
          "id": 94177,
          "person": {
            "id": 93378,
            "first_name": "Willia",
            "last_name": "Eckerson",
            "photo_url": "assets/1838b63a16f948bd91c5c5237c19576b_1000004573_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1d2df848-ebeb-4064-9902-7796a11d2b91"
        },
        {
          "id": 96657,
          "person": {
            "id": 95858,
            "first_name": "William",
            "last_name": "Bland",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ed1efe3-8fe7-4685-894f-17a83bff23f3"
        },
        {
          "id": 90881,
          "person": {
            "id": 90165,
            "first_name": "Wilmer",
            "last_name": "Vanegas",
            "photo_url": "assets/3c8219a11a5049f7a94c4fdf0912c592_wilmer_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-10-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca4f2d22-6dbd-40aa-ac5a-d3b4a159ab50"
        },
        {
          "id": 94763,
          "person": {
            "id": 93963,
            "first_name": "Xochitl",
            "last_name": "Alvarez",
            "photo_url": "assets/ab37ca34867749f9b1f40a0c4a2beb6d_sochi_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8870,
              "name": "Shift A",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2024-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf13c6f0-dd8d-4985-adfa-580488c4afab"
        },
        {
          "id": 89552,
          "person": {
            "id": 88847,
            "first_name": "Yah",
            "last_name": "Assale",
            "photo_url": "assets/edfb71f26a644a6ebbcebacdb3b996b8_20230928_101602.jpgYAH_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0bfd45d-e01f-4c9d-abf9-a341d37ce945"
        },
        {
          "id": 97212,
          "person": {
            "id": 96413,
            "first_name": "Yajexis",
            "last_name": "Santil Marcano",
            "photo_url": "assets/57e34619888b41d88f13a9a6d65c5e1a_20240304_190350_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b5b9958-df80-4faa-907c-08190637d330"
        },
        {
          "id": 92533,
          "person": {
            "id": 91767,
            "first_name": "Yesmi",
            "last_name": "Lorenzo Perez",
            "photo_url": "assets/0357e14d156246ff81cb1cb80d3a6bb6_image000000005_t.heic",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-11-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "12bf892a-8655-4553-bd95-d9afc48c6d5e"
        },
        {
          "id": 92188,
          "person": {
            "id": 91463,
            "first_name": "Yolayne",
            "last_name": "Bermudez",
            "photo_url": "assets/rec0fdaacd3b842cf86f098500b690151_thumbnail_IMG_6053_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd46b6c9-538a-4a28-a022-7206f7ab8cab"
        },
        {
          "id": 91501,
          "person": {
            "id": 90783,
            "first_name": "Yunia",
            "last_name": "Tapanes Jimenez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2cf4697-bcac-4c0a-b34c-14fe92f09768"
        },
        {
          "id": 93223,
          "person": {
            "id": 92455,
            "first_name": "Zackery",
            "last_name": "Cole",
            "photo_url": "assets/c85b11b37cf744e09fa28610834c87f6_ced9e8fbd1fb49a184bcf1a1dda078da_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7cb4fd9-cad0-40a7-bff5-2f9416ec4f20"
        },
        {
          "id": 93760,
          "person": {
            "id": 92961,
            "first_name": "Zayda",
            "last_name": "Mendoza",
            "photo_url": "assets/6a853cfc791e4390a26c5a4c3d92968b_Zayda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2024-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1c9a3de6-b143-4bc1-9859-449e5d5ff15b"
        },
        {
          "id": 89546,
          "person": {
            "id": 88841,
            "first_name": "Zenon",
            "last_name": "Solis",
            "photo_url": "assets/f92a84936011475887a004b953f81820_image000000_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7799,
              "name": "Day",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6594903-8d84-4477-b307-8c9694731ff0"
        },
        {
          "id": 73952,
          "person": {
            "id": 73412,
            "first_name": "Zhi Hua",
            "last_name": "Cai",
            "photo_url": "assets/b9586e953ae943fc965d7f2b71f3613c_ZhihuaCaiHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2024-01-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90f5ca69-f19a-44ff-aeb3-5cd9ecd990e9"
        },
        {
          "id": 94067,
          "person": {
            "id": 93268,
            "first_name": "Zulmar",
            "last_name": "Ramirez",
            "photo_url": "assets/058e088f93f64a24b64b5707e66218f7_Zulmar_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 49,
              "name": "Cleanroom Tech",
              "name_abbreviation": "CLNTC"
            }
          ],
          "shifts": [
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2024-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b0562ee-63e1-4959-b909-b430caa47164"
        }
      ]
    },
    {
      "rangeTitle": "6 Months - 1 Year",
      "rangeSubtitle": "Employed for 6 months to 1 year",
      "percentage": 0.13,
      "total": 331,
      "positions": 30,
      "employees": [
        {
          "id": 88458,
          "person": {
            "id": 87757,
            "first_name": "AaRinique",
            "last_name": "Ruffin",
            "photo_url": "assets/caa12c37db9b482c9009b189cca59854_20230815_130707_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "98b66a29-8cb5-4fb6-a5d5-f5d5dea6444a"
        },
        {
          "id": 83088,
          "person": {
            "id": 82453,
            "first_name": "Abelardo",
            "last_name": "Gonzalez",
            "photo_url": "assets/8a784d4ee514424abbfd9458fec54ad6_AGInSitephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d34fe6c3-0815-47e4-bb96-398c8cdcd95e"
        },
        {
          "id": 84964,
          "person": {
            "id": 84316,
            "first_name": "Adriel",
            "last_name": "Calderon",
            "photo_url": "assets/5b3df2b014b0447985d0a4cac8b6e003_AdrielCalderon_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6cce5ad9-3422-485a-b999-36d32ed1dedc"
        },
        {
          "id": 86785,
          "person": {
            "id": 86112,
            "first_name": "Ajmone",
            "last_name": "Kukaj",
            "photo_url": "assets/1795bb11e00345be8146ec6387c79e7f_368b2407e43f460587ab62055a7cc80e_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a1fd06c-69da-44bf-9cfa-f66c1f16a5bf"
        },
        {
          "id": 87065,
          "person": {
            "id": 86389,
            "first_name": "Alba",
            "last_name": "Sanchez",
            "photo_url": "assets/5f1644dcb6cb46e5bfded64ea8133307_IMG_2611_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9336a8cb-a41f-4ad1-9f5e-df6c056ee463"
        },
        {
          "id": 87476,
          "person": {
            "id": 86788,
            "first_name": "Alba",
            "last_name": "Vannasouk",
            "photo_url": "assets/2fdcaf4126c74574957fe1a92b3a52cf_Resized_Resized_20230731_105629_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "601ac7d1-d0a9-4966-9d3d-101d7db117cb"
        },
        {
          "id": 84251,
          "person": {
            "id": 83610,
            "first_name": "Aleidy Roxana",
            "last_name": "Ruiz",
            "photo_url": "assets/ecf8a6f7b31d4cfe94bbaf5ccb4e259a_ALEIDYR_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6cf17cb1-6456-45e3-8711-e05fc69c644e"
        },
        {
          "id": 84684,
          "person": {
            "id": 84038,
            "first_name": "Alejandra",
            "last_name": "Bravo",
            "photo_url": "assets/c13088edc05c450eb6aaf9e409785659_thumbnail_image0_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8f877dc6-7130-437b-94ad-c9566c17c781"
        },
        {
          "id": 64106,
          "person": {
            "id": 63570,
            "first_name": "Alejandro",
            "last_name": "Ayala-Huertas",
            "photo_url": "assets/r75573a41f1d741ac95eba976e0b9e39b_Alejandro_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-05-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "13f2f8a0-06fe-4bb0-9b7a-7a623f6cadab"
        },
        {
          "id": 85470,
          "person": {
            "id": 84803,
            "first_name": "Alexis",
            "last_name": "Diaz Velarde",
            "photo_url": "assets/r1f9118c97c8d4627a8f75865cefe94d0_cropped216840175318362083.jpg",
            "phone_number": "5154627139",
            "email_address": "avelarde@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7615,
              "name": "None",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c524b5ff-550d-4734-82fc-87adf07db0b2"
        },
        {
          "id": 86830,
          "person": {
            "id": 86157,
            "first_name": "Alford",
            "last_name": "Cox",
            "photo_url": "assets/586c0dfdb23545119eefda790711d3f8_IMG_1889_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f4c2fb02-c88f-499c-b86a-72d5dd5e2b29"
        },
        {
          "id": 74202,
          "person": {
            "id": 73660,
            "first_name": "Alicia",
            "last_name": "Patino",
            "photo_url": "assets/3426cb731ebb4a6e937ca13b9c9d7cf8_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-06-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a0f7b793-afc8-4a46-8b25-88b9467fd709"
        },
        {
          "id": 87656,
          "person": {
            "id": 86966,
            "first_name": "Alicia",
            "last_name": "Robinson",
            "photo_url": "assets/f283818c95874143acf54b3eb3123640_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31eed4c5-7faa-4eff-b07a-fb76b3693d1a"
        },
        {
          "id": 86854,
          "person": {
            "id": 86181,
            "first_name": "Alida",
            "last_name": "Ortigoza",
            "photo_url": "assets/c011422cd51a4c96b3e6e67af1a3ef34_IMG_2627_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5ce4325-966f-483d-acb6-f3b041d2479d"
        },
        {
          "id": 82056,
          "person": {
            "id": 81425,
            "first_name": "Alin",
            "last_name": "Haro",
            "photo_url": "assets/a7f20c7ac59d4cac8ccbb20668ae4d8f_1000002708_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04d711ba-90e6-435e-bfd7-2f11ce0691a0"
        },
        {
          "id": 85401,
          "person": {
            "id": 84733,
            "first_name": "Altagracia",
            "last_name": "Abreu",
            "photo_url": "assets/re56a60caecf6431e8a312790358f40ca_thumbnail_70547213719__4E6B1DD1FF0045199E4EF7487729B959_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f69d093a-073c-4cfe-858e-6325d6a04c9f"
        },
        {
          "id": 87382,
          "person": {
            "id": 86696,
            "first_name": "Amin",
            "last_name": "Brickle",
            "photo_url": "assets/r5f577e06d329488d8668a84ae1312ccf_amin_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f36fea0e-b4b7-4435-aa77-ac38da02a4e8"
        },
        {
          "id": 87600,
          "person": {
            "id": 86911,
            "first_name": "Ana",
            "last_name": "Saturno",
            "photo_url": "assets/99b8dee0deae4c1f824afc5618aaa90a_IMG_2825_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e4b2424-aa0e-4d8a-b584-6caaa9fd27e0"
        },
        {
          "id": 84616,
          "person": {
            "id": 83973,
            "first_name": "Anareidy",
            "last_name": "Reyes Valdez",
            "photo_url": "assets/bdd526d497dd40d5a6c9ac1a9d451305_AnareidyReyesPhoto_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f8eec70-de0e-4a39-9dd9-d689ffb5d530"
        },
        {
          "id": 87399,
          "person": {
            "id": 86713,
            "first_name": "Andre",
            "last_name": "Clark",
            "photo_url": "assets/ce6ac51b82184221a29b2e0dfd08a514_IMG_2761_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f655f0a-2930-410c-8a01-41d9b9edf1a2"
        },
        {
          "id": 85847,
          "person": {
            "id": 85179,
            "first_name": "Andrea",
            "last_name": "Hudson",
            "photo_url": "assets/rb5552ef8440b4815ab2ba7fd4a966705_Andrea_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 953,
              "name": "Night",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-05-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f4f77a1-73c9-42e4-be1b-f945cfda8ec8"
        },
        {
          "id": 83739,
          "person": {
            "id": 83100,
            "first_name": "Andrea",
            "last_name": "Thompson",
            "photo_url": "assets/rf717fdec21da4c47a7b7c2b52d5db480_IMG_20230512_121648_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61f65126-c1fc-4b28-80fb-edcbbc7f9cf9"
        },
        {
          "id": 87799,
          "person": {
            "id": 87105,
            "first_name": "Andrew",
            "last_name": "Steward",
            "photo_url": "assets/c3b5f2c6ac27425a825163d875abcaa6_AndrewSteward4InsiteProfile_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d4be2078-2489-4b1a-887b-2ad99733957c"
        },
        {
          "id": 69532,
          "person": {
            "id": 68997,
            "first_name": "Anna",
            "last_name": "Reyna",
            "photo_url": "assets/fc2b090f50eb4fa3819079dc693004ee_StandardFemale111_t.png",
            "phone_number": "12546610934",
            "email_address": "areyna@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1419,
              "name": "Day",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f53e3746-346c-4a74-94d0-5619ae40736a"
        },
        {
          "id": 69295,
          "person": {
            "id": 68760,
            "first_name": "Annette",
            "last_name": "Dokie",
            "photo_url": "assets/reb93d5d5c2d04ccc8505021feebbf170_Annette_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2926958-dfea-411a-92cd-044a7a2358e6"
        },
        {
          "id": 80120,
          "person": {
            "id": 79496,
            "first_name": "Anthony",
            "last_name": "George",
            "photo_url": "assets/bbe0076382a141d185130abb19139898_attachment1692585641270_t.jpg",
            "phone_number": "3146870120",
            "email_address": "ageorge@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 8052,
              "name": "Charles Schwab (JLL) - Irvine Unassigned",
              "contract": {
                "id": 5823,
                "name": "Irvine",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "24a3dde7-040b-482b-96de-b7443bdc8967",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7903,
              "name": "Charles Schwab (JLL) - Modesto - Unassigned",
              "contract": {
                "id": 2565,
                "name": "Modesto",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "1651ecba-4602-47df-a034-de4565adda69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7904,
              "name": "Charles Schwab (JLL) - Plano Unassigned",
              "contract": {
                "id": 2446,
                "name": "Plano",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "cec6734a-a412-4cc5-94c7-accedc9527ad",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7905,
              "name": "Charles Schwab (JLL) - Santa Rosa Unassigned",
              "contract": {
                "id": 2506,
                "name": "Santa Rosa, CA",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "10e3533b-ca77-425b-99ef-94554c14cd41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8056,
              "name": "Charles Schwab (JLL) -St. Louis, MO Unassigned",
              "contract": {
                "id": 5904,
                "name": "St. Louis, MO",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "338cf298-5d52-40db-8881-9d6ba0f55903",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1500,
              "name": "Charles Schwab (JLL) - Troy Unassigned",
              "contract": {
                "id": 2144,
                "name": "Troy, MI",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "54af0116-a843-4017-a009-026b9328fba4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7902,
              "name": "Chubb Insurance - Richmond Unassigned",
              "contract": {
                "id": 3467,
                "name": "Richmond, IN",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "519c7469-e232-466e-9e16-fb9c702efc59",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7107,
              "name": "Day",
              "contract": {
                "id": 2542,
                "name": "Ft. Collins",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "4fadff3b-5842-4377-9af5-d7f47bf6e914",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2357,
              "name": "Day",
              "contract": {
                "id": 2460,
                "name": "McKinney",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "d6e89f72-f5f0-45eb-bc20-532f7d831237",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7707,
              "name": "Day",
              "contract": {
                "id": 5796,
                "name": "FINA00VRTOH",
                "customer_id": 2149,
                "customer_name": "Financial Vertical Overhead",
                "uuid": "3f0b7393-b50f-4af9-84e7-fc3bcc041d66",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7916,
              "name": "Day",
              "contract": {
                "id": 5849,
                "name": "10 Exchange Place, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5430,
              "name": "Day",
              "contract": {
                "id": 3531,
                "name": "Linthicum",
                "customer_id": 225,
                "customer_name": "T. Rowe Price",
                "uuid": "8b30eac4-5c74-40d5-a1e4-0241ff54a5ad",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 4738,
              "name": "Night",
              "contract": {
                "id": 2559,
                "name": "Sacramento",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "31d7aad2-4ed6-4c30-90db-fbb970c27a8c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            },
            {
              "id": 878,
              "name": "Unassigned",
              "contract": {
                "id": 1609,
                "name": "Gracy Farms",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "03cc456f-3e28-40d0-aebf-3074cbdb38c9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 948,
              "name": "Unassigned",
              "contract": {
                "id": 1845,
                "name": "Owings MLS (JLL)",
                "customer_id": 225,
                "customer_name": "T. Rowe Price",
                "uuid": "9b7a9c36-f4b7-4a27-b54b-2909f4384cf9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7924,
              "name": "Unassigned",
              "contract": {
                "id": 2830,
                "name": "Pratt St.",
                "customer_id": 225,
                "customer_name": "T. Rowe Price",
                "uuid": "3ea78b35-c565-4ea2-8027-eb7a4c16a5bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 180,
              "name": "Unassigned",
              "contract": {
                "id": 1842,
                "name": "CO Springs (JLL)",
                "customer_id": 225,
                "customer_name": "T. Rowe Price",
                "uuid": "ca3e9274-44d6-407c-8088-fd41d2cbaec5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 828,
              "name": "Unassigned",
              "contract": {
                "id": 1850,
                "name": "Lone Tree",
                "customer_id": 1942,
                "customer_name": "Charles Schwab",
                "uuid": "7495e22a-5cb6-414c-9095-c5757849bd23",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2023-05-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5353503e-b1f0-4744-8a7b-61730e129ac8"
        },
        {
          "id": 84845,
          "person": {
            "id": 84198,
            "first_name": "Antonio",
            "last_name": "Castillo",
            "photo_url": "assets/7ee68592c7da4428ab4d540ab922a9dc_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5197,
              "name": "Swing",
              "contract": {
                "id": 3432,
                "name": "LaVergne",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "0782cfc4-1138-4ee2-9a49-a1adfc897e25",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c7bb1a31-ddc2-4bed-8ec0-cbae53c99672"
        },
        {
          "id": 88567,
          "person": {
            "id": 87866,
            "first_name": "Antonio",
            "last_name": "Gusman Aviles",
            "photo_url": "assets/648ef8f878584efc8f34d6e4d5747129_4insitephotoantonio_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2bedf66-0307-4ffe-bf4c-a29ac2a6ecad"
        },
        {
          "id": 88460,
          "person": {
            "id": 87759,
            "first_name": "Antonio",
            "last_name": "Hill",
            "photo_url": "assets/rbc3a92d5819c49f3bee880e60c2e8b7f_AntonioHillphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b550b10b-6e94-486c-b55b-91299f136a14"
        },
        {
          "id": 88563,
          "person": {
            "id": 87862,
            "first_name": "Ariana",
            "last_name": "Harris",
            "photo_url": "assets/44b5668b84ed410fa9e62d8e774f2623_AHarris_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf9134db-dd33-4837-a472-14dbaf93f887"
        },
        {
          "id": 89007,
          "person": {
            "id": 88306,
            "first_name": "Arlette",
            "last_name": "Gonzalez",
            "photo_url": "assets/1b9c6f509ef541caaabbaafda01490d9_IMG_4431_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "df8a825b-7c29-4840-ba81-855bed2951be"
        },
        {
          "id": 88754,
          "person": {
            "id": 88055,
            "first_name": "Ashley",
            "last_name": "Chacon",
            "photo_url": "assets/0efb432df2e444cb810072a4095b44a1_Photo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d11a735-a350-4d09-8741-969d429f829b"
        },
        {
          "id": 88551,
          "person": {
            "id": 87850,
            "first_name": "Ashley",
            "last_name": "Galeana",
            "photo_url": "assets/97b6c5c4a049475a860703f50ad60d7f_AshleyGaleana4InsiteProfile_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83215ce4-9fd6-4526-ad41-1b1e6ad4eba0"
        },
        {
          "id": 87982,
          "person": {
            "id": 87283,
            "first_name": "Ashley",
            "last_name": "Isabell",
            "photo_url": "assets/fc57408db99b46988faeb34d9b02b53d_image00000037_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "621b1785-deab-40dc-9d66-18ae29ef6acd"
        },
        {
          "id": 87211,
          "person": {
            "id": 86531,
            "first_name": "Asia",
            "last_name": "Stubbs",
            "photo_url": "assets/51d4bd34890c4c4790f1b539d5fc977b_IMG_2704_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "14be488d-363b-4b1f-9786-22289f27261e"
        },
        {
          "id": 87146,
          "person": {
            "id": 86468,
            "first_name": "Ayden",
            "last_name": "Hinesley",
            "photo_url": "assets/c82f8a6dab1f409fbcc6128af4742c35_Weston_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7710,
              "name": "None",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "71303697-e080-455b-addf-2f8a823e50f5"
        },
        {
          "id": 15419,
          "person": {
            "id": 15417,
            "first_name": "Bashir",
            "last_name": "Dahir",
            "photo_url": "b2a20393-b7e9-f9d1-200c-aa4a9df7de14.png",
            "phone_number": "",
            "email_address": "230850@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8dd4c3a5-14c2-46c0-9cb6-30f97081894c"
        },
        {
          "id": 86815,
          "person": {
            "id": 86142,
            "first_name": "Belkis",
            "last_name": "Rodriguez De Jesus",
            "photo_url": "assets/6328665b3ef94f588ee412a5cf711ea4_4ca0f708cfff4ef1aa8c7aeb2bf558ac_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24d0318c-32aa-4aa6-97e7-41951546f37a"
        },
        {
          "id": 86788,
          "person": {
            "id": 86115,
            "first_name": "Besa",
            "last_name": "Agolli",
            "photo_url": "assets/95e9fedee8f04eeba5b8f7ac8bc7a7b2_BesaAgolli_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bdcd0509-63b7-475d-8f5c-52f9f498a531"
        },
        {
          "id": 82301,
          "person": {
            "id": 81669,
            "first_name": "Betzaida",
            "last_name": "Pineda Aguilar",
            "photo_url": "assets/7c18e2f66942433a9cee39601f83eb7d_20230221_130515_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0720ce9f-a2b1-462c-9186-f266487e108f"
        },
        {
          "id": 86775,
          "person": {
            "id": 86102,
            "first_name": "Bianca",
            "last_name": "Garcia",
            "photo_url": "assets/48dacce2c79c4501868a27e7765e733d_AlyssaLenihan.1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "810f8d30-4db6-42f4-ac68-510daf863048"
        },
        {
          "id": 25517,
          "person": {
            "id": 25394,
            "first_name": "Bich Phuong Thi",
            "last_name": "Nguyen",
            "photo_url": "assets/1587958389.613251_Ngyuen_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91d69184-b535-4018-9edf-8e779676e262"
        },
        {
          "id": 82487,
          "person": {
            "id": 81854,
            "first_name": "Binti",
            "last_name": "Jama",
            "photo_url": "assets/e64fa2e2fd614428bbfe9acadb7bd2b5_20230327_130318_resized_1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "774b655f-7c45-4ffa-b539-8ed637f65e2e"
        },
        {
          "id": 86185,
          "person": {
            "id": 85516,
            "first_name": "Bisan",
            "last_name": "Khatib",
            "photo_url": "assets/42463e52f7bc43938abe279313f9cf04_IMG_20221017_132024_879_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 25,
              "name": "Compliance Coordinator",
              "name_abbreviation": "Compliance Coord"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ca24e0f-4fd5-4c22-b7b3-5b056c34a26d"
        },
        {
          "id": 88065,
          "person": {
            "id": 87366,
            "first_name": "Bonita",
            "last_name": "Landers",
            "photo_url": "assets/19145005e853476fba0a4fb231ad0c19_Resized_20230807_145246_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "144a52a8-89a1-478a-aa69-ae371c66d92a"
        },
        {
          "id": 88060,
          "person": {
            "id": 87361,
            "first_name": "Brenda",
            "last_name": "Santiago",
            "photo_url": "assets/3aec5fdf0f0d4241ab53d0d6442200c7_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ff991e9-3bb3-467b-94a0-55a8235edc3a"
        },
        {
          "id": 87174,
          "person": {
            "id": 86495,
            "first_name": "Brisa",
            "last_name": "Lopez",
            "photo_url": "assets/cf43558fec084d6191d24e68084c4082_BrisaLopezPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7720,
              "name": "None",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e595c0fa-cf43-41cb-8ee8-ab6f0eece62b"
        },
        {
          "id": 88473,
          "person": {
            "id": 87772,
            "first_name": "Camila",
            "last_name": "Romero Baldoquin",
            "photo_url": "assets/323ef8acc41a4b018a23d76140ea599b_Kamila_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5642d2b1-7e2f-485b-9df1-8c5c9eee3699"
        },
        {
          "id": 87397,
          "person": {
            "id": 86711,
            "first_name": "Carey",
            "last_name": "Carter",
            "photo_url": "assets/b2b5c4f9d11d46b5baade3cd7523dc16_IMG_2762_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0c10f28e-de6f-4f34-a1fe-e9d61089902c"
        },
        {
          "id": 87385,
          "person": {
            "id": 86699,
            "first_name": "Carl",
            "last_name": "Hurley",
            "photo_url": "assets/f0522412933d4f1e9e71331c7682c7bb_IMG_2758_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 28,
              "name": "Grounds Keeper",
              "name_abbreviation": "GRDKPR"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fac489e0-2edc-4e50-a588-c1ac047707ba"
        },
        {
          "id": 83703,
          "person": {
            "id": 83064,
            "first_name": "Carl",
            "last_name": "Scott",
            "photo_url": "assets/827aab837759415cba7ee761d8f59e47_Image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "59d2634b-9a49-4d39-b3ce-b948aed54b49"
        },
        {
          "id": 88556,
          "person": {
            "id": 87855,
            "first_name": "Carlos",
            "last_name": "Aponte",
            "photo_url": "assets/56e2c7b1f4d6448eabccef15920f6b9c_temp_file_.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0122f33e-156c-42cc-ac9d-9af2e96cce78"
        },
        {
          "id": 86786,
          "person": {
            "id": 86113,
            "first_name": "Carlos",
            "last_name": "Espinal",
            "photo_url": "assets/9029cd28b4f94513b04faa61b6ec64b5_609b392b76b242158842fdc16b63c128_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f5e4e1a-858b-47df-b0c3-95228db70d38"
        },
        {
          "id": 88367,
          "person": {
            "id": 87666,
            "first_name": "Cheri",
            "last_name": "Lussier",
            "photo_url": "assets/r1f8e3741f1c244e3bb4fa1d624fee259_IMG_1928_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2522,
              "name": "On-Call",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8dfdb31-8f6b-4031-a102-7e896f52021c"
        },
        {
          "id": 25518,
          "person": {
            "id": 25395,
            "first_name": "Chi",
            "last_name": "Quach",
            "photo_url": "assets/1587958110.1476204_Duc_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3a3c3d2-9a53-4708-bca2-9fe2c5aea6f1"
        },
        {
          "id": 83517,
          "person": {
            "id": 82878,
            "first_name": "Chineta",
            "last_name": "Stewart",
            "photo_url": "assets/9f39e2994a4c4d4baa9f6e9822ad2ed1_ChinetaStewartinsitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cdde9e0f-81ff-4d76-9570-24179678dfc8"
        },
        {
          "id": 86839,
          "person": {
            "id": 86166,
            "first_name": "Chiqulia",
            "last_name": "Cantrell",
            "photo_url": "assets/4c5fcd17b6b946f2887a6ccd96b2dbb2_Capture_t.JPG",
            "phone_number": "+14708254380",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7022ee24-f5b7-4375-8953-f1d2ff857147"
        },
        {
          "id": 89077,
          "person": {
            "id": 88374,
            "first_name": "Christopher",
            "last_name": "Roman",
            "photo_url": "assets/bd89a08db51f42ec82289057a3b09691_Christopher_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7827,
              "name": "Weekend-Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e96ec167-d64f-49cf-8ff3-e7f9acae3ca0"
        },
        {
          "id": 87284,
          "person": {
            "id": 86603,
            "first_name": "Claudia",
            "last_name": "Curiel Quinones",
            "photo_url": "assets/d2b937967bc645458127c0ee6796c5b0_Claudiaspicture20230725_111617_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01163236-a939-4ddb-be5e-a900652a628a"
        },
        {
          "id": 88105,
          "person": {
            "id": 87406,
            "first_name": "Clenis",
            "last_name": "Alvarado",
            "photo_url": "assets/40bb9298d6ee47f5951a79c8f4680d74_IMG_5806_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3191,
              "name": "Day",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "85946546-47e8-4082-b24e-64eb02464631"
        },
        {
          "id": 87438,
          "person": {
            "id": 86750,
            "first_name": "Collen",
            "last_name": "Shaw",
            "photo_url": "assets/rcfc1041f59044eefaa3d16bcc60f6cfc_thumbnail_IMG_99491_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb1abc88-b6b7-4dd6-965a-3aafc8726624"
        },
        {
          "id": 85716,
          "person": {
            "id": 85048,
            "first_name": "Cory",
            "last_name": "Lumpkin",
            "photo_url": "assets/8847cbd3477443da89f34244c9eae9b5_Resized_20230628_173448_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45c7913a-dfe0-4e80-a9fe-8c2f2a09c507"
        },
        {
          "id": 86858,
          "person": {
            "id": 86185,
            "first_name": "Cosha",
            "last_name": "Hawkins",
            "photo_url": "assets/8e495228a1884be9a1b4f697e60f1506_Capture_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7715,
              "name": "Swing",
              "contract": {
                "id": 5793,
                "name": "Northyard",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d3692f08-83cd-407e-a012-fd0dcc8c1e07"
        },
        {
          "id": 86214,
          "person": {
            "id": 85544,
            "first_name": "Cristi",
            "last_name": "Escobar",
            "photo_url": "assets/eeb64c51eafa4ed898b46b948ceead36_20230821_144410_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 8783,
              "name": "Swing",
              "contract": {
                "id": 6076,
                "name": "Cover My Meds 2",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8683432f-b66f-4ac9-bfa8-303e273e0210"
        },
        {
          "id": 88102,
          "person": {
            "id": 87403,
            "first_name": "Cristian",
            "last_name": "Rodriguez",
            "photo_url": "assets/82fede79593d4879a949c97e81a46293_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1412,
              "name": "Day",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d25e2288-9504-4adb-820d-c53e2ccf43e9"
        },
        {
          "id": 87078,
          "person": {
            "id": 86402,
            "first_name": "Cristobal",
            "last_name": "Robles Calderon",
            "photo_url": "assets/63b636f05bb14eb98cdcf8d1a0195668_CristobalRoblesCalderon_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 28,
              "name": "Grounds Keeper",
              "name_abbreviation": "GRDKPR"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b6822e3-f7d8-4f35-ab4a-b8ae52dd9572"
        },
        {
          "id": 19136,
          "person": {
            "id": 19260,
            "first_name": "Cuc",
            "last_name": "Pham",
            "photo_url": "assets/1579900489.3301003_IMG_26331_t.JPG",
            "phone_number": "",
            "email_address": "232040@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-03-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd9bbdba-b05d-4719-8b7f-8104e7b46c94"
        },
        {
          "id": 88028,
          "person": {
            "id": 87329,
            "first_name": "Cynthia",
            "last_name": "Baity",
            "photo_url": "assets/0bc3557298c745f78f06a8a280b7768f_attachment1709650570736_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "149df5ae-3a4e-4c93-b937-dead9bdb6007"
        },
        {
          "id": 87075,
          "person": {
            "id": 86399,
            "first_name": "Cynthia",
            "last_name": "Garcia",
            "photo_url": "assets/67ba1e4729ee4b07a7e775eed13eb223_ChristineSolis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb3d858b-dceb-42ba-a30d-4775766ef4ef"
        },
        {
          "id": 86753,
          "person": {
            "id": 86080,
            "first_name": "Daniel",
            "last_name": "Aranguren",
            "photo_url": "assets/f878b55335514a45a4688cce37c5fee7_Daniel_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd899e7a-7fc0-475e-9809-c3e2ca1568b5"
        },
        {
          "id": 88405,
          "person": {
            "id": 87704,
            "first_name": "Danielle",
            "last_name": "Brand",
            "photo_url": "assets/4408ee77151b4fc0a75671553181ab73_Danielle_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6aaa7d59-93cb-47d6-aa2e-7e23d0f14d5e"
        },
        {
          "id": 82470,
          "person": {
            "id": 81837,
            "first_name": "Daveyon",
            "last_name": "Garner",
            "photo_url": "assets/r90eb01c171c24077a8e32028986b36f6_D.Garnerprofilepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ee9ef737-3475-4cc9-b938-b0f7517dd058"
        },
        {
          "id": 88710,
          "person": {
            "id": 88011,
            "first_name": "Daysi",
            "last_name": "Portan De Ortiz",
            "photo_url": "assets/84d5505c391740a5b43048a6de2ed93f_20230826_214159853_iOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7799,
              "name": "Day",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bcfb01e7-f246-4b88-9a53-d3474adeea51"
        },
        {
          "id": 86849,
          "person": {
            "id": 86176,
            "first_name": "DeBorah",
            "last_name": "McCurtis",
            "photo_url": "assets/bbe7e57acc5f4fb6b7419323a65e1ffb_IMG_2614_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ce907a0c-bd01-4800-bcc5-8a7faafe300a"
        },
        {
          "id": 82357,
          "person": {
            "id": 81725,
            "first_name": "Deidre",
            "last_name": "Foster",
            "photo_url": "assets/5be07b86d9434595abda1c4b5ac16add_NewGlassWashLady2023_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f00e1275-042c-4f0f-9521-3faeb16b295c"
        },
        {
          "id": 85747,
          "person": {
            "id": 85079,
            "first_name": "Demecia",
            "last_name": "Parker",
            "photo_url": "assets/697c7117434f41089b3d952908ae7b85_Demeciapic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42169313-9b32-47ed-b0df-a812efc43094"
        },
        {
          "id": 85162,
          "person": {
            "id": 84514,
            "first_name": "Denise",
            "last_name": "Salinas",
            "photo_url": "assets/95ad22612bd140248580b9f3a9a9cfd5_Denise_2_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2297,
              "name": "Day",
              "contract": {
                "id": 2348,
                "name": "Reno, NV",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54cc9e9f-b383-4a8e-b8b8-805c893b781e"
        },
        {
          "id": 84744,
          "person": {
            "id": 84098,
            "first_name": "Dennis",
            "last_name": "Dantzler",
            "photo_url": "assets/r5d8a2730cf3f482b956dc563fd589b9d_dennis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99e5f004-bec8-45b6-8b7d-a4a4c7635780"
        },
        {
          "id": 88413,
          "person": {
            "id": 87712,
            "first_name": "Desyrae",
            "last_name": "Whitted",
            "photo_url": "assets/r14f4f719ee6849e9bed35266e664a873_Desyraephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5257a294-8525-44b4-bc63-c7498b592fe4"
        },
        {
          "id": 82634,
          "person": {
            "id": 82001,
            "first_name": "Dominique",
            "last_name": "Taylor",
            "photo_url": "assets/e51403a33b674b42bce4bffb2c9ea4f7_DomoniquesProfilePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fbedf114-5b49-49c7-8535-160a7650a82b"
        },
        {
          "id": 82736,
          "person": {
            "id": 82103,
            "first_name": "Doris",
            "last_name": "Jones",
            "photo_url": "assets/34a99789c3b14d0b83cfeee6b16a24ad_20230310_095204_resized002_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3890d9f-0c48-4614-b21b-188d2fe5d2ae"
        },
        {
          "id": 89187,
          "person": {
            "id": 88482,
            "first_name": "Dulce",
            "last_name": "Avitia Valdez",
            "photo_url": "assets/r6b9a5dbeaa224871a4c39701344b9fbc_IMG_0788_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "93866ad1-0e2b-4b54-b1af-48a2cf2e3147"
        },
        {
          "id": 84242,
          "person": {
            "id": 83601,
            "first_name": "Eddie",
            "last_name": "Ortiz",
            "photo_url": "assets/e33aec4de4664fb7a21dc475d77aa07f_Eddie_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 7692,
              "name": "Swing",
              "contract": {
                "id": 5651,
                "name": "Springhouse Plant, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "94a70beb-30d8-47f9-bcd1-a7bae45e1099"
        },
        {
          "id": 89147,
          "person": {
            "id": 88442,
            "first_name": "Edroy",
            "last_name": "McCormick",
            "photo_url": "assets/740420eab3284aaf98d9bbeab7dd6bad_EdroyMcCormickPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4ed94cc7-df5e-4975-9dcc-8750eb9d0903"
        },
        {
          "id": 87141,
          "person": {
            "id": 86463,
            "first_name": "Eduardo",
            "last_name": "Ayala-Casanova",
            "photo_url": "assets/6172d438ae0c4a4cb85b076e19ad17f1_IMG_2702_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1165,
              "name": "Site Admin Asst",
              "name_abbreviation": "ADMIN"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "db06d9fd-4587-41b8-bfad-84eee34df0f9"
        },
        {
          "id": 86811,
          "person": {
            "id": 86138,
            "first_name": "Eduardo",
            "last_name": "Vargas",
            "photo_url": "assets/50fdf163c9604cf1815d432283e7df26_EduardoVargas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e457546-27e5-43f4-b529-bfef0c3eb3ef"
        },
        {
          "id": 89203,
          "person": {
            "id": 88498,
            "first_name": "Edwin",
            "last_name": "Munoz",
            "photo_url": "assets/r831cefe2b7bd4f5e911be264dd1c9e8a_EdwinMunoz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eba32900-a2cb-4799-8a31-b2e7c898bd71"
        },
        {
          "id": 86793,
          "person": {
            "id": 86120,
            "first_name": "Elvis",
            "last_name": "Mejia",
            "photo_url": "assets/8c37c5491f394d0b84a027955d03e688_ElvisMejia_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c4d590e-450e-47df-a646-79d9e37263fc"
        },
        {
          "id": 87027,
          "person": {
            "id": 86352,
            "first_name": "Emily",
            "last_name": "Gonzalez Martinez",
            "photo_url": "assets/r1c85ac1848d8446192471cf2678ddaab_Emilyphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 4535,
              "name": "None",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6479e750-f315-4689-9823-14330c3a4fb9"
        },
        {
          "id": 89012,
          "person": {
            "id": 88311,
            "first_name": "Emma",
            "last_name": "Del Cid Orellana",
            "photo_url": "assets/e08afc8d7d5e4df088c586987f6f3d31_Emma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b84b6e3a-c3f0-4d10-9556-4e9c6389960d"
        },
        {
          "id": 86792,
          "person": {
            "id": 86119,
            "first_name": "Emma",
            "last_name": "Ketek",
            "photo_url": "assets/b40df13635e24a65bd5288163ad71c36_4fb632800ddc4e97b44fac5441303d10_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7a94ccf7-06f4-499f-84eb-a8df929b6f61"
        },
        {
          "id": 88066,
          "person": {
            "id": 87367,
            "first_name": "Emmitt",
            "last_name": "Ramsey",
            "photo_url": "assets/81ab8358d7fb4cb8a41ee27d8b8994d1_IMG_0840_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92f87c03-be91-4894-a1d2-9d5232a09a1e"
        },
        {
          "id": 86846,
          "person": {
            "id": 86173,
            "first_name": "Emperatriz",
            "last_name": "Montoya Llanes",
            "photo_url": "assets/666d8324e7b84c448cc6072b8acaa308_IMG_2481_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b891a750-daa7-4613-9081-3b3e1e900b26"
        },
        {
          "id": 84284,
          "person": {
            "id": 83643,
            "first_name": "Enrique",
            "last_name": "Santiago",
            "photo_url": "assets/3bc5f2835d7a49d4adb3ed191b0d88c3_sbmpict_t.jpg",
            "phone_number": "",
            "email_address": "esantiago@sbmcorp.com"
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9632958d-17e5-4dc3-841f-ee26d82f4fb4"
        },
        {
          "id": 86820,
          "person": {
            "id": 86147,
            "first_name": "Ercilio",
            "last_name": "Diaz",
            "photo_url": "assets/53e3c5a8491f4e0c91f95f9d024dc1a0_18be1e4e5b30465484a64033551276c7_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f22fe9f6-2fa5-43c9-bb29-00f34a4ce4b8"
        },
        {
          "id": 49534,
          "person": {
            "id": 49035,
            "first_name": "Erica",
            "last_name": "Mejia",
            "photo_url": "assets/9ef4216a587b4fdcb395a0e2d7b09d15_20220114_082722_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1419,
              "name": "Day",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd394de1-b293-40f1-8220-7cf5e7c3ed50"
        },
        {
          "id": 83485,
          "person": {
            "id": 82847,
            "first_name": "Erika",
            "last_name": "Nejapa Rodriguez",
            "photo_url": "assets/297858c461854832b1713c0255267bb9_IMG_20230417_220849_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6768d3dc-e493-468d-b256-6f659c7593c5"
        },
        {
          "id": 7613,
          "person": {
            "id": 7613,
            "first_name": "Estela",
            "last_name": "Esparza",
            "photo_url": "assets/a1071fc01b704da5bc4e9e51cd809a09_EstelasProfilePicture_t.jpg",
            "phone_number": "",
            "email_address": "202294@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2db19cc3-cb95-4baa-a4ef-0000ac46fb97"
        },
        {
          "id": 88714,
          "person": {
            "id": 88015,
            "first_name": "Esther",
            "last_name": "Marin Perez",
            "photo_url": "assets/dea087a61ed74f1e97c34fe24d915040_estherMarin_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7799,
              "name": "Day",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "52d506b5-064a-4c9a-a14c-1d11732ad8ba"
        },
        {
          "id": 82122,
          "person": {
            "id": 81491,
            "first_name": "Eugene",
            "last_name": "Burress",
            "photo_url": "assets/fcec0591886b482e9bbbcf66445362fb_attachment1678314839428_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9798245-8551-4d38-b9fd-e9540b53152a"
        },
        {
          "id": 85241,
          "person": {
            "id": 84574,
            "first_name": "Ever",
            "last_name": "Martinez Baranona",
            "photo_url": "assets/676859b7d1cb450182ccf421f13a0e49_IMG_20230524_175800_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b1917f1a-0e10-4ae7-b037-397d8f1bfb29"
        },
        {
          "id": 86794,
          "person": {
            "id": 86121,
            "first_name": "Fabio",
            "last_name": "Comori",
            "photo_url": "assets/2b29d7168f944899a15ad8f1d37036a4_7399fdcb18e84fd2b4f1417f6f3ceeeb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ed5a9e0-be39-47a7-9514-e79c4003691a"
        },
        {
          "id": 11425,
          "person": {
            "id": 11425,
            "first_name": "Fabiola",
            "last_name": "Zamora",
            "photo_url": "userprofile/muqfjm9dzfdahhsamcv9",
            "phone_number": "",
            "email_address": "202338@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f08030a7-82c1-445d-b2c2-5f50174b6044"
        },
        {
          "id": 86819,
          "person": {
            "id": 86146,
            "first_name": "Fadil",
            "last_name": "Ahmetaj",
            "photo_url": "assets/0598e4cdaf22466e8f58dcaae4a207e0_d73f0a3704e945a6a4e381af6713163a_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb84ab34-1680-4e8a-a42e-3e096f649f10"
        },
        {
          "id": 86818,
          "person": {
            "id": 86145,
            "first_name": "Felix M",
            "last_name": "Pena Gonzalez",
            "photo_url": "assets/7105da6bfc914493bc5ba8d736a2886d_b512969f6b2348b08553d768cb10ca50_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e18b6e9c-5ed6-459f-992d-779627517301"
        },
        {
          "id": 88712,
          "person": {
            "id": 88013,
            "first_name": "Feng",
            "last_name": "Wu",
            "photo_url": "assets/796caabbc0ec43d0a619e5c6fcbe800b_20230826_214330874_iOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7816,
              "name": "Swing",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d9e1803-a3a1-4903-a16f-3e3660907af0"
        },
        {
          "id": 86906,
          "person": {
            "id": 86233,
            "first_name": "Freida",
            "last_name": "Johnson",
            "photo_url": "assets/529decc2867449afbd89c9f893656a99_Capture_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b027956b-62e2-4594-902e-432cbce51708"
        },
        {
          "id": 87109,
          "person": {
            "id": 86432,
            "first_name": "Gabriel Antonio",
            "last_name": "Verde Carvallo",
            "photo_url": "assets/rbe79d78ce92b453f9c68618d7f364f6e_FullSizeR001_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b8b87c71-c42a-4dae-acab-c49e232fb3a3"
        },
        {
          "id": 86798,
          "person": {
            "id": 86125,
            "first_name": "Ganimete",
            "last_name": "Sadriu",
            "photo_url": "assets/05a46f28cb384acfa567192feaca4c2b_GanineteSadriu_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fce89760-f863-4089-99cd-8c05bcad5197"
        },
        {
          "id": 88276,
          "person": {
            "id": 87575,
            "first_name": "Gareth",
            "last_name": "Hines",
            "photo_url": "assets/4c710ce1f0d347cda93a26c35e728522_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d5b5f20-8d89-4660-963b-78f86bed1701"
        },
        {
          "id": 86797,
          "person": {
            "id": 86124,
            "first_name": "Giovanni",
            "last_name": "Elao",
            "photo_url": "assets/ca2fb84033cb4da5a82fc4ea407edb59_GiovanniElao_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8b374c28-e32c-4f54-aaf5-e53522ba8b26"
        },
        {
          "id": 73996,
          "person": {
            "id": 73456,
            "first_name": "Giselle",
            "last_name": "Ordonez",
            "photo_url": "assets/r33c0793949b541d187eeab63971d97f5_IMG_1633_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3144,
              "name": "Swing",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7f1d0ba-4591-4374-8479-3025e19d3c1a"
        },
        {
          "id": 88941,
          "person": {
            "id": 88240,
            "first_name": "Grace",
            "last_name": "Gomez",
            "photo_url": "assets/084a643f34cb4dafb956653ce6afb6fe_Screenshot_20231108_120719_Messages_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87b02bfe-a2eb-49d3-8a76-f60b2c15a14b"
        },
        {
          "id": 87063,
          "person": {
            "id": 86387,
            "first_name": "Gregory",
            "last_name": "Hardy",
            "photo_url": "assets/563e9502860847c29a5836a34f85cc0a_IMG_2620_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aba383ea-d6ae-4db0-b735-a77732c14cfc"
        },
        {
          "id": 87553,
          "person": {
            "id": 86864,
            "first_name": "Gustavo",
            "last_name": "Lucumi",
            "photo_url": "assets/r7ab936b2e5914321a76a3c6cf7ac2c1d_thumbnail_IMG_20230808_095013_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f62e83bb-61e5-4f54-88f2-5b6ee6cf9b43"
        },
        {
          "id": 86801,
          "person": {
            "id": 86128,
            "first_name": "Hamdija",
            "last_name": "Nikocevic",
            "photo_url": "assets/89488657be914e50b5f00464fcbd286b_HamdijaNikocevic_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d9580bf-1a12-4034-b618-2237c8ca97d8"
        },
        {
          "id": 89772,
          "person": {
            "id": 89066,
            "first_name": "HAN",
            "last_name": "JOK WAH",
            "photo_url": "assets/r739721d8250446f1b92637e1cb75b6ef_Screenshot_20230919_163851_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7855,
              "name": "Day",
              "contract": {
                "id": 5813,
                "name": "Suntec",
                "customer_id": 2155,
                "customer_name": "VMware (CBRE) (SG)",
                "uuid": "5822414e-91a4-4972-930b-dbbb34bcabb4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91c97a1a-9012-4386-ad25-b21c457ab2f3"
        },
        {
          "id": 86836,
          "person": {
            "id": 86163,
            "first_name": "Harriette",
            "last_name": "Bullock",
            "photo_url": "assets/7f99d99f64504a2eb854fa765532a87e_86836_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3d1202a1-4348-4068-b3fd-027e4c701ec0"
        },
        {
          "id": 83822,
          "person": {
            "id": 83183,
            "first_name": "Harry",
            "last_name": "Kidd",
            "photo_url": "assets/728c334fc59048d381a466c6b4b21d39_IMG_1574_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9209394c-6158-4014-991e-daf9ff490ca0"
        },
        {
          "id": 86808,
          "person": {
            "id": 86135,
            "first_name": "Hasime",
            "last_name": "Rexhepi",
            "photo_url": "assets/bbf4209e88984b958f5c934e7667b29f_HasimeRexhepi_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "02b7c95e-8c37-48a2-a400-eea1cb2cb450"
        },
        {
          "id": 86824,
          "person": {
            "id": 86151,
            "first_name": "Hector",
            "last_name": "Henriquez",
            "photo_url": "assets/5965444c80ec45d9a1df61791c44d9a6_ce224f21016e4d528e29664612072544_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87486e88-1289-4fd8-a015-e97be1136167"
        },
        {
          "id": 86825,
          "person": {
            "id": 86152,
            "first_name": "Ibrahim",
            "last_name": "Khan",
            "photo_url": "assets/4b12ce8632b24c7d97e11a9a9205d931_IbrahamKhan_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7a9ad4e0-c662-4af0-9978-0e788dac2393"
        },
        {
          "id": 88456,
          "person": {
            "id": 87755,
            "first_name": "Idania",
            "last_name": "Perez Ortiz",
            "photo_url": "assets/d43543129297409cbc69e9fa113e5071_20230821_150034_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b37f62a-ddac-4701-ba17-00ffe007d361"
        },
        {
          "id": 52471,
          "person": {
            "id": 51943,
            "first_name": "Irma",
            "last_name": "Lopez Juan",
            "photo_url": "assets/c665fe94246e4595a1674bbb141d414f_irma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "93088984-131f-415b-b840-cd32af4c75b5"
        },
        {
          "id": 85539,
          "person": {
            "id": 84871,
            "first_name": "Isaiah",
            "last_name": "Martinez",
            "photo_url": "assets/5154993071b9416a9505197d8efd5525_IsaiahMartinezpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d91835e-6e45-464d-99c5-58019d2db32b"
        },
        {
          "id": 89089,
          "person": {
            "id": 88386,
            "first_name": "Ivannia",
            "last_name": "Pana Perez",
            "photo_url": "assets/3a8844d1e4b7411e9289f7bcd546f338_Ivannia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6232e56b-f4d4-402e-a243-a1807aa203b5"
        },
        {
          "id": 86807,
          "person": {
            "id": 86134,
            "first_name": "Jacek",
            "last_name": "Topczylko",
            "photo_url": "assets/ab0ea70b56c1448b945f43b098bc0d1c_2023_07_12_22_56_31_MicrosoftTeams_image_7_.png_Photos_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "026b3736-f510-40d0-b36f-809de3a50be8"
        },
        {
          "id": 88493,
          "person": {
            "id": 87792,
            "first_name": "Jacqueline",
            "last_name": "Bedell",
            "photo_url": "assets/r24253e33e5e948db8a5aa2123db165d3_jackieprofilepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2522,
              "name": "On-Call",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67681980-6eed-46d3-a9bd-80055e0e8aae"
        },
        {
          "id": 88923,
          "person": {
            "id": 88222,
            "first_name": "Jaira",
            "last_name": "Estrada",
            "photo_url": "assets/05f3b2fa2fd94568819a95a10581722e_20230905_165848_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2454,
              "name": "Swing",
              "contract": {
                "id": 1198,
                "name": "Waukegan, IL",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0de00eec-ccc8-45ea-b2d3-7c5d20565e0c"
        },
        {
          "id": 88713,
          "person": {
            "id": 88014,
            "first_name": "Jamal",
            "last_name": "Mana",
            "photo_url": "assets/edc8cfe2cdcc4a5ca35a72c16a944600_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7816,
              "name": "Swing",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "823446b2-6343-4608-861c-fa3ea5fef6ec"
        },
        {
          "id": 87437,
          "person": {
            "id": 86749,
            "first_name": "James",
            "last_name": "Hutchins",
            "photo_url": "assets/r740ed682a2644eb782c8b87de66fd8dd_James_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f5d78cd3-862f-4b8d-b6b6-833123569725"
        },
        {
          "id": 87514,
          "person": {
            "id": 86825,
            "first_name": "Jasmine",
            "last_name": "McGhee",
            "photo_url": "assets/0d71ab4aa8914d53bac2e2437447baaf_1000000727_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3076,
              "name": "Swing",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5984c126-63ee-4d49-9ea3-b741a1a0484d"
        },
        {
          "id": 82803,
          "person": {
            "id": 82170,
            "first_name": "Javier",
            "last_name": "Lopez Monroy",
            "photo_url": "assets/40d384b733ba42b18af802de608eff8a_JavierLopezPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f246278a-3e7d-4fb0-bfa3-b21135328f33"
        },
        {
          "id": 87221,
          "person": {
            "id": 86541,
            "first_name": "Jemichael",
            "last_name": "Boyd",
            "photo_url": "assets/151ed38a894f488682da9212aa51303d_IMG_4427_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5c29195-2061-4de7-9adc-6f13c079a580"
        },
        {
          "id": 85242,
          "person": {
            "id": 84575,
            "first_name": "Jermaine",
            "last_name": "Sillman",
            "photo_url": "assets/6d4d652ebd48428bb203d3e61f82ae49_JermaineSillmanimageSBM_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "51a0d67b-3bc1-4a1f-b81f-6ca2c5fc4a5c"
        },
        {
          "id": 4237,
          "person": {
            "id": 4237,
            "first_name": "Jessica",
            "last_name": "Hall",
            "photo_url": "assets/e07052f8c7bf4e6188e1eeb1ab401751_JessicasInsitePicture2023_t.jpg",
            "phone_number": "3177191216",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "af5e86d2-095b-4443-a612-aaa36d8ca5c4"
        },
        {
          "id": 82872,
          "person": {
            "id": 82239,
            "first_name": "Jesus",
            "last_name": "Jacobo-Mascorro",
            "photo_url": "assets/ab927f413d844d71b04e7de4c0857bdc_4SMale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be07d5d2-381a-4740-8473-5b850df40413"
        },
        {
          "id": 88636,
          "person": {
            "id": 87937,
            "first_name": "Jesus",
            "last_name": "Melendez",
            "photo_url": "assets/eadb93c0b2a6476f866c859bac9c2aeb_Resized_Resized_20230908_092430_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5193,
              "name": "Day",
              "contract": {
                "id": 3432,
                "name": "LaVergne",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "0782cfc4-1138-4ee2-9a49-a1adfc897e25",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e33a221-6c1f-495b-98ec-c1a6567d3330"
        },
        {
          "id": 81299,
          "person": {
            "id": 80668,
            "first_name": "JN",
            "last_name": "Pyne",
            "photo_url": "assets/a4f7b325603d4bae97a9ffcd19d3203c_JndayleePyneHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2651,
              "name": "Swing",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a14e9944-a2a0-4ad7-9a2c-4a704b1a37ed"
        },
        {
          "id": 69045,
          "person": {
            "id": 68510,
            "first_name": "Joan",
            "last_name": "Small",
            "photo_url": "assets/5e11f7a7ec7d4ee59a52fe88f66142b6_JoanSmallinsitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e57491b-3a58-46db-a40e-0cdc5578b46b"
        },
        {
          "id": 89014,
          "person": {
            "id": 88313,
            "first_name": "Jocelyn",
            "last_name": "Mendoza Martinez",
            "photo_url": "assets/44a81e0a1e2e4aa4940f242c823a9128_1000008973_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60ce159e-b133-43de-bc14-46c7df73a2e6"
        },
        {
          "id": 85280,
          "person": {
            "id": 84613,
            "first_name": "Joel",
            "last_name": "Patino Corona",
            "photo_url": "assets/92becf9e71ba4cedae28ec54872ac577_ProfilePic_t.jpg",
            "phone_number": "6507056568",
            "email_address": "254174@placeholder.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "deb312dc-df2a-4eea-81b9-60cf936cbe35"
        },
        {
          "id": 83435,
          "person": {
            "id": 82798,
            "first_name": "Johnathan",
            "last_name": "Hopwood",
            "photo_url": "assets/eb84bd1c9e78462fbc7456e9c2879cc5_4SMale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e4c19ed0-65eb-4e64-9906-ca331e121489"
        },
        {
          "id": 82213,
          "person": {
            "id": 81582,
            "first_name": "Johnathan",
            "last_name": "Snyder",
            "photo_url": "assets/fea289fe9cbf428687c4d5358f3d3218_JonathanSnyder_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6156,
              "name": "Day",
              "contract": {
                "id": 4125,
                "name": "York, NE",
                "customer_id": 1547,
                "customer_name": "Collins Aerospace (CBRE)",
                "uuid": "ee153d8f-a7fd-4c7f-b56b-e0060da0230e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86404875-b2be-48dc-aa03-431df6221eae"
        },
        {
          "id": 87845,
          "person": {
            "id": 87151,
            "first_name": "Jonathan",
            "last_name": "Sims",
            "photo_url": "assets/520fd15308c2468eb566bffa91d7f4af_JonathanSimsPhoto_t.jpg",
            "phone_number": "3129756282",
            "email_address": "jsims@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1942,
              "name": "Day",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 9228,
              "name": "Unassigned",
              "contract": {
                "id": 5517,
                "name": "Brawley, CA (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "8d83332f-c8d7-4cae-9e00-4c6aef374956",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2023-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5610db0e-9876-4d0c-b4b9-c8d2c4db9523"
        },
        {
          "id": 87572,
          "person": {
            "id": 86883,
            "first_name": "Jorge",
            "last_name": "Canessa",
            "photo_url": "assets/0af73df7e21c4c8b93e84c2134e45d69_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5109d9af-ca66-4109-99c6-93d0f075d0ad"
        },
        {
          "id": 86806,
          "person": {
            "id": 86133,
            "first_name": "Jorge",
            "last_name": "Cruz",
            "photo_url": "assets/ed5be50c00eb41ccb1851152fdd2d407_MicrosoftTeamsimage6_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb16b0b2-9434-48b3-9eea-cb04caeecf2d"
        },
        {
          "id": 86586,
          "person": {
            "id": 85915,
            "first_name": "Jose",
            "last_name": "De La Rosa",
            "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
            "phone_number": "",
            "email_address": "jrosa3@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3dcb4ede-2e80-48fb-b9f1-5f5a27868e47"
        },
        {
          "id": 87314,
          "person": {
            "id": 86632,
            "first_name": "Jose",
            "last_name": "Liscano-Mendez",
            "photo_url": "assets/05f453e733be46d0a5442effa28571d9_JosePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1458,
              "name": "Day",
              "contract": {
                "id": 2017,
                "name": "Greenfield, IN",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d510743b-f799-4e50-bfd9-243bec30b8c2"
        },
        {
          "id": 83181,
          "person": {
            "id": 82545,
            "first_name": "Jose",
            "last_name": "Miranda Ortega",
            "photo_url": "assets/6c78015fda654273ae58c51943a2aba9_JOSERMIRANDA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "15ba25e1-e7d5-4673-9788-38054d51c2ec"
        },
        {
          "id": 88472,
          "person": {
            "id": 87771,
            "first_name": "Jose",
            "last_name": "Morales",
            "photo_url": "assets/a3b381c57f8242f29a3b7411204d64e5_Jose_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9d58a37-3e0f-44e9-b4be-e210b3c2e596"
        },
        {
          "id": 82055,
          "person": {
            "id": 81424,
            "first_name": "Jose",
            "last_name": "Naranjo",
            "photo_url": "assets/c90127097fcd4dc6a031ec3520787483_1000002722_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c40683bb-1069-47c8-a4c9-51b3320c521f"
        },
        {
          "id": 89080,
          "person": {
            "id": 88377,
            "first_name": "Jose",
            "last_name": "Vega",
            "photo_url": "assets/r87e4d0a7d25440f38c55d2a0a8805be5_Josevega_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d1e1297a-4783-46af-8279-14c22ba9b0a0"
        },
        {
          "id": 88107,
          "person": {
            "id": 87408,
            "first_name": "Joshua",
            "last_name": "Chiesa",
            "photo_url": "assets/83949c64bc5e43d086a53ca8d9a8d8bb_headshot_t.jpg",
            "phone_number": "3133918321",
            "email_address": "256258@placeholder.com"
          },
          "positions": [
            {
              "id": 1176,
              "name": "Mgr, Operations",
              "name_abbreviation": "OPMGR"
            }
          ],
          "shifts": [
            {
              "id": 7995,
              "name": "None",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2bb1a3d6-d8b0-4a12-a08b-a04b63ef90b2"
        },
        {
          "id": 87077,
          "person": {
            "id": 86401,
            "first_name": "Joycelyn",
            "last_name": "McCrary",
            "photo_url": "assets/ccd64fcd792b48019310b3ef150a58ff_IMG_2605_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c157eb68-8d9a-4f77-be61-73febd38fc0d"
        },
        {
          "id": 86250,
          "person": {
            "id": 85580,
            "first_name": "Juan",
            "last_name": "Arias Ramon",
            "photo_url": "assets/9c71a393c7394c588961d685598d8f4d_20230612_224848_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b7a3e70-f9b4-4253-9fee-075cfc80b383"
        },
        {
          "id": 86660,
          "person": {
            "id": 85987,
            "first_name": "Juan",
            "last_name": "Bermudez",
            "photo_url": "assets/rf2d3dc02bb0d4129a1587a514cecf1b6_DaveProfilePic_t.jpg",
            "phone_number": "224-381-0044",
            "email_address": "jbermudez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "262c1299-1c78-40ae-87a8-a3ad39ecf0f3"
        },
        {
          "id": 82570,
          "person": {
            "id": 81937,
            "first_name": "Juan",
            "last_name": "Grajeda Sanchez",
            "photo_url": "assets/eb6f2c4877f94772a71e700508c110b5_JuanGrajeda.6_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 12,
              "name": "Custodian, Warehouse",
              "name_abbreviation": "WCUST"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae5640f5-88e6-4447-9bda-5ae052f9a787"
        },
        {
          "id": 88084,
          "person": {
            "id": 87385,
            "first_name": "Juan",
            "last_name": "Guerrero",
            "photo_url": "assets/rd7353b624f9545e982c481a525d8aa9c_thumbnail_IMG_3507_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2a584051-85a1-409a-9f0e-fb59447c7d20"
        },
        {
          "id": 82635,
          "person": {
            "id": 82002,
            "first_name": "Juan",
            "last_name": "Nunez",
            "photo_url": "assets/b5a64e4995664b52b8854d61a05c571c_707385932B0E4C3EAD704C042619EE64_t.jpeg",
            "phone_number": "5134730561",
            "email_address": "jnunez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 2017,
              "name": "Day",
              "contract": {
                "id": 1070,
                "name": "Oakley Station",
                "customer_id": 26,
                "customer_name": "Elevance",
                "uuid": "f0074666-0561-4294-b3d9-6a6eea89297b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2145,
              "name": "Day",
              "contract": {
                "id": 1702,
                "name": "REG702",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "190ff85a-39a8-44fa-909e-7aa8db7cdc4d",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3034,
              "name": "Day",
              "contract": {
                "id": 2586,
                "name": "Mason",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7128,
              "name": "Day",
              "contract": {
                "id": 4829,
                "name": "CVG Endeavor & Terminal",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "48f5ca10-b834-475e-8e6f-9d88dee0340b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7130,
              "name": "Day",
              "contract": {
                "id": 4821,
                "name": "CVG Reservations",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "d1ecc0c3-0068-443c-9e6d-9dbc2e9db589",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2345,
              "name": "Day",
              "contract": {
                "id": 2417,
                "name": "CVG Terminal",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "aa7a6be0-4565-4c05-be68-8a83ddcdd3e9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2076,
              "name": "Day",
              "contract": {
                "id": 1823,
                "name": "Cincinnati",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7840,
              "name": "Night",
              "contract": {
                "id": 4821,
                "name": "CVG Reservations",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "d1ecc0c3-0068-443c-9e6d-9dbc2e9db589",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            },
            {
              "id": 7841,
              "name": "Swing",
              "contract": {
                "id": 4821,
                "name": "CVG Reservations",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "d1ecc0c3-0068-443c-9e6d-9dbc2e9db589",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            },
            {
              "id": 2564,
              "name": "Swing",
              "contract": {
                "id": 1265,
                "name": "Mason",
                "customer_id": 26,
                "customer_name": "Elevance",
                "uuid": "ae5ef51c-b3ad-4dd7-b69b-a6d51d75081a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7847,
              "name": "Swing",
              "contract": {
                "id": 4829,
                "name": "CVG Endeavor & Terminal",
                "customer_id": 391,
                "customer_name": "Delta",
                "uuid": "48f5ca10-b834-475e-8e6f-9d88dee0340b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 693,
              "name": "Unassigned",
              "contract": {
                "id": 1823,
                "name": "Cincinnati",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 192,
              "name": "Unassigned",
              "contract": {
                "id": 1512,
                "name": "Cincinnati",
                "customer_id": 140,
                "customer_name": "Mallinckrodt",
                "uuid": "d6949178-e3ce-453c-977c-22303053af1a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2023-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "004ae6d8-89c6-459f-8900-236bc8cd2254"
        },
        {
          "id": 86826,
          "person": {
            "id": 86153,
            "first_name": "Julio",
            "last_name": "Arroyo",
            "photo_url": "assets/9361d9de50594138b676ff2c57a0b9ae_IMG_5082_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4219d8a9-64ae-4684-aa3e-ea893e6f51cf"
        },
        {
          "id": 86856,
          "person": {
            "id": 86183,
            "first_name": "Kadiatu",
            "last_name": "Khannu",
            "photo_url": "assets/14c8103e792849879a37f5c3cbf9391b_KadiatuKhannu_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e14d5a1-7d25-4b8d-a28b-ef15b7423f42"
        },
        {
          "id": 89404,
          "person": {
            "id": 88699,
            "first_name": "Kamisha",
            "last_name": "Lee",
            "photo_url": "assets/c42d91a3940e4149a7e03943e3139329_IMG_3848_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e3945d1-cf16-4db5-8c9e-513292694507"
        },
        {
          "id": 68758,
          "person": {
            "id": 68222,
            "first_name": "Karen",
            "last_name": "Clark",
            "photo_url": "assets/b2f791df11a44e1ca71cb807ddbe46d0_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "278fc935-e32f-433f-8b2e-a1c1e30f29e2"
        },
        {
          "id": 88875,
          "person": {
            "id": 88174,
            "first_name": "Karen",
            "last_name": "Gonzalez Gaitan",
            "photo_url": "assets/ra7e6a7d307244d9790c336fa280a1596_KarenGonzalez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25183783-4de2-4ec0-9076-feab55995910"
        },
        {
          "id": 87519,
          "person": {
            "id": 86830,
            "first_name": "Karla",
            "last_name": "Salmeron",
            "photo_url": "assets/4c1e0459347a48449db900a92f793a1f_KarlaSalmeron_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d39f0df3-a541-40de-90aa-5397d4280b8c"
        },
        {
          "id": 86752,
          "person": {
            "id": 86079,
            "first_name": "Katina",
            "last_name": "Stubbs",
            "photo_url": "assets/1dc7be39794f425ab2b020e3cf095c66_20230801_0946151_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd471c6b-b4e0-4ccc-a936-bda488ca47f9"
        },
        {
          "id": 87611,
          "person": {
            "id": 86922,
            "first_name": "Katrina",
            "last_name": "Jones",
            "photo_url": "assets/r65c8f649b6214adfba0f687ccf9d51f9_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a378320c-e0c2-4dbf-9c0e-e1c3a245e39f"
        },
        {
          "id": 82725,
          "person": {
            "id": 82092,
            "first_name": "Kayla",
            "last_name": "Morgan",
            "photo_url": "assets/rc706daff52e044e091266ff07ae48b4f_4insitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2960b5ed-11b0-45e7-a304-449983d7a5b3"
        },
        {
          "id": 87025,
          "person": {
            "id": 86350,
            "first_name": "Kelly",
            "last_name": "Prudencio Velez",
            "photo_url": "assets/177911baee434d8b805a05d3817549bb_20230731_153518_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7679,
              "name": "None",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc2d4806-8c71-4c42-b7b4-09152a562db3"
        },
        {
          "id": 86670,
          "person": {
            "id": 85997,
            "first_name": "KenBrianna",
            "last_name": "Rumsey",
            "photo_url": "assets/2c3fba8480914e77904f28f3dab2eb66_KenBrianapicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "769b4273-7480-47d1-be77-788dc7a4c4ca"
        },
        {
          "id": 88874,
          "person": {
            "id": 88173,
            "first_name": "Kenneth",
            "last_name": "Caswell",
            "photo_url": "assets/4ac3747815934750938b4a6530f2932d_KCprofilepic_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2628,
              "name": "On-Call",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4bf185b4-f472-4e2c-a3ab-b1aa169b3f21"
        },
        {
          "id": 87250,
          "person": {
            "id": 86569,
            "first_name": "Kenyan",
            "last_name": "Holland",
            "photo_url": "assets/2dded98683ad4ddf912766a113107e3f_1newheadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1176,
              "name": "Mgr, Operations",
              "name_abbreviation": "OPMGR"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "add4640d-40f9-4c42-b18c-3a010a861e16"
        },
        {
          "id": 88040,
          "person": {
            "id": 87341,
            "first_name": "Keyana",
            "last_name": "Diggs",
            "photo_url": "assets/f94476f1482a4c59bfb205c9ec55bc5f_KeyanaProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7951,
              "name": "Weekend-Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d13b644-0962-4ae7-bdce-43528d09696e"
        },
        {
          "id": 49012,
          "person": {
            "id": 48518,
            "first_name": "Khang",
            "last_name": "Le",
            "photo_url": "assets/1f035cc7403f448f973898d783339aed_08.2022_t.SBM",
            "phone_number": "206-688-9831",
            "email_address": "kle@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3db52936-2913-4794-919c-9820a60810bc"
        },
        {
          "id": 19127,
          "person": {
            "id": 19251,
            "first_name": "Kiem",
            "last_name": "Hoang",
            "photo_url": "assets/1578634663.0340765_IMG_23711_t.JPG",
            "phone_number": "",
            "email_address": "232022@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-03-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b142c25-18de-4de2-8d57-55ba8a14c6a3"
        },
        {
          "id": 86001,
          "person": {
            "id": 85333,
            "first_name": "Kim",
            "last_name": "Anderson",
            "photo_url": "assets/02618b9f2af34fcf8af9694f2aacb37b_20230608_102725_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e94df47-fe0e-48cb-a4ac-ee75c5d75eeb"
        },
        {
          "id": 88524,
          "person": {
            "id": 87823,
            "first_name": "Kristin",
            "last_name": "French",
            "photo_url": "assets/33bc0d34794147d19e48a8b1ff7f3a52_WeddingPhoto_t.png",
            "phone_number": "925-597-2356",
            "email_address": "kfrench@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8767,
              "name": "Day",
              "contract": {
                "id": 6747,
                "name": "1633 Fairlane Circle",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1514,
              "name": "Day",
              "contract": {
                "id": 2251,
                "name": "RIC – Research & Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8730,
              "name": "Unassigned",
              "contract": {
                "id": 2217,
                "name": "Fairlane North",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8108,
              "name": "Unassigned",
              "contract": {
                "id": 2214,
                "name": "WPE – Wagner Place East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8729,
              "name": "Unassigned",
              "contract": {
                "id": 6740,
                "name": "Commerce Park 2 15090",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8103,
              "name": "Unassigned",
              "contract": {
                "id": 5864,
                "name": "FEL – Ford Engineering Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8728,
              "name": "Unassigned",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8727,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8755,
              "name": "Unassigned",
              "contract": {
                "id": 6751,
                "name": "Commerce Park 2 15080",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8736,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8717,
              "name": "Unassigned",
              "contract": {
                "id": 2221,
                "name": "Fairlane Business Park 5",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8107,
              "name": "Unassigned",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8732,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8756,
              "name": "Unassigned",
              "contract": {
                "id": 6746,
                "name": "Fairlane Business Park 3",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8731,
              "name": "Unassigned",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8757,
              "name": "Unassigned",
              "contract": {
                "id": 6750,
                "name": "IT Bic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8718,
              "name": "Unassigned",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2023-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06e2b69a-b905-4200-bb9d-e4a814d222ba"
        },
        {
          "id": 88376,
          "person": {
            "id": 87675,
            "first_name": "Kristopher",
            "last_name": "Sterling",
            "photo_url": "assets/83c75e19c39f4dd7b5f96f5172e20ad2_kris_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99d5123e-6b12-4088-a22a-456d913f5470"
        },
        {
          "id": 86842,
          "person": {
            "id": 86169,
            "first_name": "Lagrina",
            "last_name": "Simmons",
            "photo_url": "assets/b80ceb075852454f9403c121064f0d28_LagrinaSimmons_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "02004827-d905-4653-8431-108d48c04bce"
        },
        {
          "id": 72853,
          "person": {
            "id": 72316,
            "first_name": "Laura",
            "last_name": "Paiva Flores",
            "photo_url": "assets/3feb108157b74965bad243dae204f34a_lauranewfoto_t.jpg",
            "phone_number": "5035607789",
            "email_address": ""
          },
          "positions": [
            {
              "id": 64,
              "name": "Field Mgr, HR",
              "name_abbreviation": "HRFMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "26f25a2b-740b-4f4f-8dfa-87476aa03266"
        },
        {
          "id": 87008,
          "person": {
            "id": 86333,
            "first_name": "Laura",
            "last_name": "Pavon",
            "photo_url": "assets/2e217ee3d1e64a9cb6a1663a360f0e2b_IMG_5825_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60873911-ac62-42dc-95aa-71f30edf7227"
        },
        {
          "id": 86823,
          "person": {
            "id": 86150,
            "first_name": "Leonel",
            "last_name": "Perez Moreno",
            "photo_url": "assets/d594a7bc0e2c409bbb11ab591302b39d_a74a48b275d440ca91d42951372d6fa0_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de1d0465-2980-41d0-ae05-e9bd30e6ddd3"
        },
        {
          "id": 84961,
          "person": {
            "id": 84313,
            "first_name": "Leshon",
            "last_name": "Jones",
            "photo_url": "assets/r17678470974e46c2b3bb5e844ed83999_20230816_1127441_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72b21133-862e-47c8-89a6-eb5356c0a5c1"
        },
        {
          "id": 74850,
          "person": {
            "id": 74306,
            "first_name": "Lesvia",
            "last_name": "Barreno",
            "photo_url": "assets/7cdddcda1f554272b4aab8904fdca79d_IMG_1248_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1942,
              "name": "Day",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5117f775-b49d-4029-819e-baa66ebfbb53"
        },
        {
          "id": 87609,
          "person": {
            "id": 86920,
            "first_name": "Leticia",
            "last_name": "Burrus",
            "photo_url": "assets/825b1bfb4c1c48b8b02f74373adf4d06_Leticiaspicture20230725_112233_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8778f0c-f119-434e-9060-eb599a145f07"
        },
        {
          "id": 86805,
          "person": {
            "id": 86132,
            "first_name": "Liliana",
            "last_name": "Mejia",
            "photo_url": "assets/ead8e20804fa46fd8fdc3b7cba1358f2_cf5d4d10feb54c4d9ed9b51ea1a611c4_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "26917ec6-345c-41e1-ae8b-692a2c38dc41"
        },
        {
          "id": 86328,
          "person": {
            "id": 85658,
            "first_name": "Linda",
            "last_name": "Parker",
            "photo_url": "assets/rd1433630d8d44b1d987aa1ed2c7d23fe_IMG_3861_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba957eea-13bd-41dd-ba00-f2209adfb69c"
        },
        {
          "id": 89003,
          "person": {
            "id": 88302,
            "first_name": "Liria",
            "last_name": "Neyra Ferrada",
            "photo_url": "assets/8c5420fa090e421aa0f1811912c9d0a6_Liria_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80f94eba-8322-471c-97a7-5385135dfacc"
        },
        {
          "id": 85718,
          "person": {
            "id": 85050,
            "first_name": "Lisa",
            "last_name": "Nguyen",
            "photo_url": "assets/c71fef77a2fa494190b7fc834d638c43_thumbnail_image01_t.jpg",
            "phone_number": "+12536785952",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3048,
              "name": "HR Field Administrator",
              "name_abbreviation": "HRFADM"
            }
          ],
          "shifts": [
            {
              "id": 4384,
              "name": "None",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-05-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7eb6413c-ba3a-45d8-80ef-61666a8ed1f3"
        },
        {
          "id": 86843,
          "person": {
            "id": 86170,
            "first_name": "Lorraine",
            "last_name": "Sewell",
            "photo_url": "assets/094c101efc6849e4af701d6e1d3aac7c_IMG_1887_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a4c60a6a-657b-46e4-8907-cef5fa615769"
        },
        {
          "id": 86841,
          "person": {
            "id": 86168,
            "first_name": "Lourdes",
            "last_name": "Pabon Martinez",
            "photo_url": "assets/bca5cea8fb074efa9ddc9b2e7dc8ce27_IMG_2483_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11dd0474-c702-4a54-a198-e76a9ba0462f"
        },
        {
          "id": 84696,
          "person": {
            "id": 84050,
            "first_name": "Lucio",
            "last_name": "Verdin Lugo",
            "photo_url": "assets/88d40335e8944e7599b1980a628c9770_thumbnail_IMG_1214_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2934,
              "name": "Assistant Safety Supervisor",
              "name_abbreviation": "ASSTSS"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb005ef1-3347-4153-add7-e22edda78f08"
        },
        {
          "id": 87750,
          "person": {
            "id": 87056,
            "first_name": "Luis",
            "last_name": "Betancourt-Velazquez",
            "photo_url": "assets/5853525cda3b4fef92330b72048358ed_Luis_t.jpg",
            "phone_number": "984-298-5710",
            "email_address": "lbetancourt@sbmcorp.com"
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9256b9e-86ce-4dad-b5ce-ba95c5505464"
        },
        {
          "id": 82895,
          "person": {
            "id": 82262,
            "first_name": "Luis",
            "last_name": "Lopez",
            "photo_url": "assets/raf2a30ac1b024b288ce97d5451834cee_luislopez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6af27313-9070-4727-a59b-b8fb7fa93a5c"
        },
        {
          "id": 87073,
          "person": {
            "id": 86397,
            "first_name": "Majuma",
            "last_name": "Nuro",
            "photo_url": "assets/b43e346c88ae4d199c13769129611e0a_Majuma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7437,
              "name": "None",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9c15a2b-138f-4297-8f59-3917470c1832"
        },
        {
          "id": 86853,
          "person": {
            "id": 86180,
            "first_name": "Maleia",
            "last_name": "Lewis",
            "photo_url": "assets/2827188677354ab9ac0cc105cf6ffa54_IMG_2607_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bf3480cd-ff48-4e07-a08c-34f4e589714c"
        },
        {
          "id": 19125,
          "person": {
            "id": 19249,
            "first_name": "Manh",
            "last_name": "Hoang",
            "photo_url": "assets/1580527432.7166674_20200130_175725_resized_t.jpg",
            "phone_number": "",
            "email_address": "232020@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-04-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d43c623d-fed4-4bb5-8444-9c69e90f62e8"
        },
        {
          "id": 87601,
          "person": {
            "id": 86912,
            "first_name": "Maria",
            "last_name": "Elizalde",
            "photo_url": "assets/1d460e2a5f9e439c9da54e48677fdf8c_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e9fddb1-3f65-47d6-b4fd-2a43d20fe7a4"
        },
        {
          "id": 88702,
          "person": {
            "id": 88003,
            "first_name": "Maria",
            "last_name": "Garcia De Huerta",
            "photo_url": "assets/b0e3142318ea4800a4a76bafe355b5a4_maria_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2400,
              "name": "Swing",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd331b52-97ff-4720-8808-39ff4e1a5bec"
        },
        {
          "id": 87825,
          "person": {
            "id": 87131,
            "first_name": "Maria",
            "last_name": "Gonzalez Jaramillo",
            "photo_url": "assets/b8d2292d4f2e459ba83716b6f0973efe_a80fd54a3d594d2fb4b7f16b4b3a9f62_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ed6b49e-6cc7-47f5-a5e7-7dbd22a6bc52"
        },
        {
          "id": 86821,
          "person": {
            "id": 86148,
            "first_name": "Maria",
            "last_name": "Hanys",
            "photo_url": "assets/0344e50d0add428288806d8b25a4cb55_a969af2b874247c6b653c71b1c02931e_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c243f1da-746f-41a8-b1c8-0c77e4f436b6"
        },
        {
          "id": 85472,
          "person": {
            "id": 84805,
            "first_name": "Maria",
            "last_name": "Hernandez",
            "photo_url": "assets/abfd0dedfc0c4063b56cb47f4acd4fe1_image000000_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eaa31602-bcd8-49f5-827d-cb3166a43ea0"
        },
        {
          "id": 88316,
          "person": {
            "id": 87615,
            "first_name": "Maria",
            "last_name": "Magalhaes",
            "photo_url": "assets/b3315bfaddfe44ee8e796314cac53d5a_123_11_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c8ecdbf-619d-48aa-afbb-2238539fac92"
        },
        {
          "id": 89013,
          "person": {
            "id": 88312,
            "first_name": "Maria",
            "last_name": "Mendoza",
            "photo_url": "assets/0eb28af5eb744102a280e4f8f34c1cd4_MariaM_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "095b8a13-8db6-4dd7-8d40-d1261ace6f8d"
        },
        {
          "id": 89010,
          "person": {
            "id": 88309,
            "first_name": "Maria",
            "last_name": "Morales Piedrahita",
            "photo_url": "assets/054ab11ad7ff4ceea93eb644f2e63f57_Angela_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f853ce27-e1ad-4465-93f7-ccf634b3fdd7"
        },
        {
          "id": 46205,
          "person": {
            "id": 45743,
            "first_name": "Maria",
            "last_name": "Sandoval Marin",
            "photo_url": "assets/83704564791c48e3ad057f656cbe5c01_MariaSandovalShot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2497,
              "name": "Swing",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0307f15-b511-46e6-8413-cf384dee3d4c"
        },
        {
          "id": 88873,
          "person": {
            "id": 88172,
            "first_name": "Maria",
            "last_name": "Silva Del Cid",
            "photo_url": "assets/r5c6d1cf7faac4729b1ff45b54f97c1e0_MariaSilva_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4be9b4a0-0d53-40b9-aaa7-72669edbe5b1"
        },
        {
          "id": 3047,
          "person": {
            "id": 3047,
            "first_name": "Maria",
            "last_name": "Torres Guerra",
            "photo_url": "d15a9e02-5bcf-c66f-85db-4a1e73f3715b.png",
            "phone_number": "9045555555",
            "email_address": "221016@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3178,
              "name": "Day",
              "contract": {
                "id": 2664,
                "name": "Jacksonville FL",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae58db3d-9828-456c-8683-948aca01a0c8"
        },
        {
          "id": 86829,
          "person": {
            "id": 86156,
            "first_name": "Maria",
            "last_name": "Vergara",
            "photo_url": "assets/e2c9a93cf179406f85c5332a3f82d345_IMG_2480_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1eb749dd-230f-4ea4-8647-f131544eefec"
        },
        {
          "id": 86837,
          "person": {
            "id": 86164,
            "first_name": "Marion",
            "last_name": "Chatman",
            "photo_url": "assets/4540f0d32c654f4db8bf54788a89c2f8_IMG_2616_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e1b063e9-6da9-421d-b207-eed87eb56114"
        },
        {
          "id": 84814,
          "person": {
            "id": 84167,
            "first_name": "Mark",
            "last_name": "Curtis",
            "photo_url": "assets/cbc881c289514efebf3335fad765484c_MarkCurtis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4ebf94c1-1388-4684-91e0-bfc21cd5f1ab"
        },
        {
          "id": 89006,
          "person": {
            "id": 88305,
            "first_name": "Martha",
            "last_name": "Concepcion Giron",
            "photo_url": "assets/37a33e11d0784cadacd90820534bbab0_20230908_124708_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c6b7a78-2027-47cb-a882-2170b8b19c42"
        },
        {
          "id": 89186,
          "person": {
            "id": 88481,
            "first_name": "Martha",
            "last_name": "Estrada",
            "photo_url": "assets/r1a1c1c6fe0ff4aeba5514d1f0c073ff2_IMG_0790_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "85a4e26a-6cc4-49ac-b27f-2439b2e846a6"
        },
        {
          "id": 86804,
          "person": {
            "id": 86131,
            "first_name": "Martha",
            "last_name": "Yumbla",
            "photo_url": "assets/4134733555194096a2ae35ffdb0991e8_MartaYumbla_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "477592ed-36c7-45af-9471-f1d65cc50ae2"
        },
        {
          "id": 87826,
          "person": {
            "id": 87132,
            "first_name": "Marvin",
            "last_name": "Carr",
            "photo_url": "assets/6c8a8a44f9d14d8b890c8f94b3455c11_sbmpic2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8bbd7a8b-945c-4f11-92e4-68af8e69c910"
        },
        {
          "id": 85351,
          "person": {
            "id": 84684,
            "first_name": "Maxwell",
            "last_name": "Anyanwu",
            "photo_url": "assets/e663e38cebf64d6b85482faf0637842c_thumbnail_Resized_Resized_20230511_010430_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "565ef017-0871-496a-95a5-6d8708cce9ef"
        },
        {
          "id": 85543,
          "person": {
            "id": 84875,
            "first_name": "Maydelin",
            "last_name": "Formoso Rizo",
            "photo_url": "assets/cf4b55f84f574e16a9f0bd5ffa7d7734_PHOTOIDMAYDELIN_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3191,
              "name": "Day",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a21314b4-601a-48fa-bd81-ebb487cce88f"
        },
        {
          "id": 74582,
          "person": {
            "id": 74040,
            "first_name": "Melanie",
            "last_name": "McNeil",
            "photo_url": "assets/dcefa4931d834508a260365546ca8409_Melanie_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e11bec2e-dc28-43e3-b2a6-25233780e0ec"
        },
        {
          "id": 86803,
          "person": {
            "id": 86130,
            "first_name": "Mersida",
            "last_name": "Dervisevic",
            "photo_url": "assets/8b535f4965384a9abc5bc3f079af6e4b_MersidaDervievic_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b9907b8a-4740-469e-985e-65ba5002598f"
        },
        {
          "id": 87222,
          "person": {
            "id": 86542,
            "first_name": "Michael",
            "last_name": "Berry",
            "photo_url": "assets/0d35d23cee464e2faecaa58928583628_IMG_2629_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 28,
              "name": "Grounds Keeper",
              "name_abbreviation": "GRDKPR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "debc5b62-c25b-4d6a-9279-d62e8b630eff"
        },
        {
          "id": 87037,
          "person": {
            "id": 86362,
            "first_name": "Michael",
            "last_name": "Farmer",
            "photo_url": "assets/011d5d0e6a1d4abc8d7751e1a5683976_IMG_2621_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7716,
              "name": "Swing",
              "contract": {
                "id": 5792,
                "name": "PETC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "29dba90e-76e1-4a8f-93be-eeefa24cff6b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86d7ef17-52e1-487d-b9bd-867474ef2b46"
        },
        {
          "id": 86755,
          "person": {
            "id": 86082,
            "first_name": "Michael",
            "last_name": "Felber",
            "photo_url": "assets/77074b193c67462695ed0a250709d55b_attachment1709650694605_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7223,
              "name": "Swing",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dfae3275-c0d0-4ebf-a135-71e56233652c"
        },
        {
          "id": 88027,
          "person": {
            "id": 87328,
            "first_name": "Michael",
            "last_name": "Wood",
            "photo_url": "assets/254fdb7e39294619bd2e062ff5beee63_attachment1709650747167_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "53aebd3e-9ba9-4fad-b7ad-b12250e5dd2b"
        },
        {
          "id": 86802,
          "person": {
            "id": 86129,
            "first_name": "Milagros",
            "last_name": "Mercado",
            "photo_url": "assets/26ef25bb4a5e41058abe1cd88c2a8823_b4960f88a69746b4a27b7617f4137a8e_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f06893f5-430a-4187-a5a7-cb9463c88245"
        },
        {
          "id": 86863,
          "person": {
            "id": 86190,
            "first_name": "Miranda",
            "last_name": "Salihu",
            "photo_url": "assets/f4ae310d1a7d4c358bdc2a63935785c7_MirandaSalihu_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76603b91-14a8-4a6d-a03f-aa397fdabe8f"
        },
        {
          "id": 89416,
          "person": {
            "id": 88711,
            "first_name": "Mireya",
            "last_name": "Del Real Bravo",
            "photo_url": "assets/01dc78199c6849e8bb72a55aff278020_IMG_1728_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5af71260-28dc-477e-991d-c7ec1290d27b"
        },
        {
          "id": 72346,
          "person": {
            "id": 71808,
            "first_name": "Miriam",
            "last_name": "Reyes",
            "photo_url": "assets/93bb60f0c3a149a19d2226b5d2a3e478_20230518_143700_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09d21243-fdf7-4c54-9114-c4e9a9db2d91"
        },
        {
          "id": 74329,
          "person": {
            "id": 73787,
            "first_name": "Mirian",
            "last_name": "Prieto",
            "photo_url": "assets/19b40b41292b4c86bc00084f7cbda313_mirianprieto_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-04-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "22f04f5e-dec1-4ede-b210-ba4afd52792f"
        },
        {
          "id": 39420,
          "person": {
            "id": 39057,
            "first_name": "Mukaram",
            "last_name": "Nasirova",
            "photo_url": "assets/1596209853.7778728_MukaramPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2221,
              "name": "Day",
              "contract": {
                "id": 2148,
                "name": "Lincoln Square 2",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0158ddd5-982a-4899-adcf-d8bf368c44ee",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a2101328-511d-4194-a0d9-5bc244cf7565"
        },
        {
          "id": 84575,
          "person": {
            "id": 83932,
            "first_name": "Mulugeta",
            "last_name": "Kasahun",
            "photo_url": "assets/83897beaccc14800a95b416473e1e613_20230413_155322_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8782,
              "name": "Day",
              "contract": {
                "id": 6076,
                "name": "Cover My Meds 2",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "985fd791-93bf-4bbe-b85f-a84d6b50e46e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8ef838de-9879-4280-981f-10ec6562edc1"
        },
        {
          "id": 87283,
          "person": {
            "id": 86602,
            "first_name": "Nakiya",
            "last_name": "Nash",
            "photo_url": "assets/a81d0856ea164b898a7e0187250403b8_Nakiyaspic20230725_124338_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "533aea86-b1c4-47d3-97f3-06f923e8c48d"
        },
        {
          "id": 88750,
          "person": {
            "id": 88051,
            "first_name": "Nancy",
            "last_name": "Mota",
            "photo_url": "assets/3236270b57dd4ece9a3cce49cdb12837_20230826_214139325_iOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7799,
              "name": "Day",
              "contract": {
                "id": 5817,
                "name": "San Francisco, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0a271815-c12f-4b3f-b2b7-f885e8f78d48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b1da7d91-41c6-4c3b-87ef-e37b8971fbce"
        },
        {
          "id": 84651,
          "person": {
            "id": 84006,
            "first_name": "Nazha",
            "last_name": "Ouatach",
            "photo_url": "assets/ab8250e6a7c94946ae6ff36f3b29d899_Screenshot20230626at10.46_t.55AM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2616,
              "name": "On-Call",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-03-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "466864ef-f32c-4c73-a8f5-107f5389b143"
        },
        {
          "id": 83751,
          "person": {
            "id": 83112,
            "first_name": "Ndaye",
            "last_name": "Mbidi",
            "photo_url": "assets/40f3553593854ae29883bda05d10991c_NdayeMbidiProfilePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "84b530ed-0f07-4ccf-8cde-c361a2fe9061"
        },
        {
          "id": 87313,
          "person": {
            "id": 86631,
            "first_name": "Nelson",
            "last_name": "Guerra-Suarez",
            "photo_url": "assets/6e93e4e5e459403989817aa353464923_Nelsonpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1458,
              "name": "Day",
              "contract": {
                "id": 2017,
                "name": "Greenfield, IN",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a0899592-d3a4-49cc-9253-305eda3704f6"
        },
        {
          "id": 86817,
          "person": {
            "id": 86144,
            "first_name": "Nercida",
            "last_name": "Gomez",
            "photo_url": "assets/5da9fdcb95324cb08ba71275654edad5_NercidaGomez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d16dbf00-a58b-4212-8bd6-42b090422342"
        },
        {
          "id": 86002,
          "person": {
            "id": 85334,
            "first_name": "Nicholas",
            "last_name": "Grimes",
            "photo_url": "assets/r1a9e5bcaf8014ed1bc5318920100a270_20230607_185004_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "173865d1-e963-4413-92ef-48f88a2e4a32"
        },
        {
          "id": 89209,
          "person": {
            "id": 88504,
            "first_name": "Nidia",
            "last_name": "Espana",
            "photo_url": "assets/aa45ed73bcee440fb7838cf1c0772251_Nidia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "670b447a-ea87-4552-9390-7d5251629892"
        },
        {
          "id": 87883,
          "person": {
            "id": 87187,
            "first_name": "Norma",
            "last_name": "Mares Salas",
            "photo_url": "assets/95bb485686484a3283ca45b2803a48ac_IMG_3160_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c093396c-a0b5-4b3a-ad0b-7c38d7dc899e"
        },
        {
          "id": 83940,
          "person": {
            "id": 83301,
            "first_name": "Ofelia",
            "last_name": "Perez Murrieta",
            "photo_url": "assets/fecd615d197b42ce988d26e60223330d_IMG20230321WA0000_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2371,
              "name": "Swing",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "863e1ff8-4f23-41f2-a7b1-1323d121d1cc"
        },
        {
          "id": 88555,
          "person": {
            "id": 87854,
            "first_name": "Omar",
            "last_name": "Hooks",
            "photo_url": "assets/2a6803e5b5884f4a8615ef4056a9e4a9_image000000_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3f387c3-c9e6-4b20-b772-36817228d507"
        },
        {
          "id": 74307,
          "person": {
            "id": 73765,
            "first_name": "Oralia",
            "last_name": "Molina Gonzalez",
            "photo_url": "assets/6f1da5fb920b4c27824b326fec2acebc_image001_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61651b30-2579-4af7-9e52-6df6151c4f99"
        },
        {
          "id": 74277,
          "person": {
            "id": 73735,
            "first_name": "Orlin",
            "last_name": "Salazar",
            "photo_url": "assets/872b3597185849b1b0f72f02831f1cee_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b328321c-9a3a-4df8-b477-e783702af5f7"
        },
        {
          "id": 88699,
          "person": {
            "id": 88000,
            "first_name": "Oscar",
            "last_name": "Pedraza",
            "photo_url": "assets/e01d95f528b540f7b579180bd1f6b8a1_39b0f855b68849d296738e99f2434a7d_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a8a4568-51c4-485d-90a1-e2fbc416b03b"
        },
        {
          "id": 89771,
          "person": {
            "id": 89065,
            "first_name": "OW",
            "last_name": "SIEW CHAN",
            "photo_url": "assets/ra47434dca8c54e2b9c550f9142ed2968_Screenshot_20230919_163649_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7855,
              "name": "Day",
              "contract": {
                "id": 5813,
                "name": "Suntec",
                "customer_id": 2155,
                "customer_name": "VMware (CBRE) (SG)",
                "uuid": "5822414e-91a4-4972-930b-dbbb34bcabb4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e68c3766-9519-4b86-aa74-1dd6eb19f551"
        },
        {
          "id": 88208,
          "person": {
            "id": 87508,
            "first_name": "Pablin",
            "last_name": "Mendoza Rodriguez",
            "photo_url": "assets/59e411dd79a14fb8abf617086a70c8b9_1000008972_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b232d018-256d-46db-8cdb-6e4ac8afc07c"
        },
        {
          "id": 86905,
          "person": {
            "id": 86232,
            "first_name": "Pamela",
            "last_name": "Houston",
            "photo_url": "assets/339c3d234aef41dc811f7612974401ba_IMG_1884_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fcc70f91-e50d-4279-bea3-89a917df14d1"
        },
        {
          "id": 87473,
          "person": {
            "id": 86785,
            "first_name": "Phillip",
            "last_name": "Kolc",
            "photo_url": "assets/334a849e09b64abcb398119bd5fc172c_StandardMale221_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "419cb5e0-2798-4ff9-b271-eeeb01400fb6"
        },
        {
          "id": 86515,
          "person": {
            "id": 85844,
            "first_name": "Phuc",
            "last_name": "Pham",
            "photo_url": "assets/0a2df5d5f9dd40c88b01bee072c67a2a_20230907_210131_t.jpg",
            "phone_number": "(425) 791-1624",
            "email_address": "ppham@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-06-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "08d0b537-9f40-4e85-be88-b5472f67b46a"
        },
        {
          "id": 86800,
          "person": {
            "id": 86127,
            "first_name": "Pjeter",
            "last_name": "Gjoka",
            "photo_url": "assets/948ace17af1d49d7aa0129295beb9e37_1b97885443ac4531b5b2b6ed05e1718d_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61258124-1d45-4764-a97a-26bad1c922a3"
        },
        {
          "id": 86799,
          "person": {
            "id": 86126,
            "first_name": "Premara",
            "last_name": "Ek",
            "photo_url": "assets/eb714a4197a44a318fbffb7e636023ac_2f6230c59c564eebbb00038c7890cbe5_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89b19ff5-ea66-4c9b-9c0a-fde5d3b2f2a1"
        },
        {
          "id": 86921,
          "person": {
            "id": 86248,
            "first_name": "Priscilla",
            "last_name": "Settles",
            "photo_url": "assets/2839436741c044bfbe3091edf0fcb382_IMG_2615_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33b0be68-5882-4484-a854-e1cb00a38fda"
        },
        {
          "id": 87607,
          "person": {
            "id": 86918,
            "first_name": "Qiaojing",
            "last_name": "Ruan",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7636,
              "name": "On-Call",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e201311-a647-46b3-96ea-896143211f2d"
        },
        {
          "id": 87390,
          "person": {
            "id": 86704,
            "first_name": "Quanetta",
            "last_name": "Boyd",
            "photo_url": "assets/11364cc18d9c481c9068e2a0419a0284_IMG_2763_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7715,
              "name": "Swing",
              "contract": {
                "id": 5793,
                "name": "Northyard",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c09def5e-60d9-43bb-b063-7b9276784c72"
        },
        {
          "id": 87061,
          "person": {
            "id": 86385,
            "first_name": "Quentin",
            "last_name": "Hickman",
            "photo_url": "assets/ef70b9584c954c249ba462ca3260bf9f_IMG_2699_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 28,
              "name": "Grounds Keeper",
              "name_abbreviation": "GRDKPR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ff21b2b-624f-46ef-a0b3-0e18e15e538f"
        },
        {
          "id": 86852,
          "person": {
            "id": 86179,
            "first_name": "Querita",
            "last_name": "Hall",
            "photo_url": "assets/b9fe5d5be35241a48d785533758c1deb_IMG_1890_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c40ed87-7d81-4d66-8b75-141468861bb5"
        },
        {
          "id": 85568,
          "person": {
            "id": 84900,
            "first_name": "Qwain",
            "last_name": "Stewart",
            "photo_url": "assets/ad9bf3e282a44bb487c86916b4c89f71_Q_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b9b329e3-935a-4f06-92d9-efafd2f8db6d"
        },
        {
          "id": 86795,
          "person": {
            "id": 86122,
            "first_name": "Rachael",
            "last_name": "Rambert",
            "photo_url": "assets/d31e01d88e934374901b03dd43b95ab4_thumbnail_IMG_5102_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7996ad0d-81f5-4c64-b0b0-5dd58a161888"
        },
        {
          "id": 89208,
          "person": {
            "id": 88503,
            "first_name": "Rachel",
            "last_name": "Cotton",
            "photo_url": "assets/c852be44a16b4427ba88ee0a08f98663_20231107_113355_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11393270-0c10-40d3-aaba-10609e5c8aa2"
        },
        {
          "id": 88492,
          "person": {
            "id": 87791,
            "first_name": "Ranisha",
            "last_name": "Jackson",
            "photo_url": "assets/caca95621f224209947b7b5ee2b3a21b_RanishaJackson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99877b4e-6ec4-4310-8ac8-cb9072db5be9"
        },
        {
          "id": 85897,
          "person": {
            "id": 85229,
            "first_name": "Raymond",
            "last_name": "Mainville",
            "photo_url": "assets/r4f0ff56d2c8b4bdcbfecb31996235adc_IMG_1926_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 2796,
              "name": "Swing",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea44ebff-1c22-453f-bea5-2977193474a2"
        },
        {
          "id": 82217,
          "person": {
            "id": 81586,
            "first_name": "Reina",
            "last_name": "Hidalgo",
            "photo_url": "assets/48df5311cef04cc498e686ebb24ba090_reinahidalgo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb8b3f99-1422-42ab-bc20-ded061be08d9"
        },
        {
          "id": 87610,
          "person": {
            "id": 86921,
            "first_name": "Rhonda",
            "last_name": "Tolliver",
            "photo_url": "assets/5253e0f7f0df4ccab05500d2db3f11f7_image000000_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "844fa49d-f2d2-4153-8a46-a1645b0a2241"
        },
        {
          "id": 83504,
          "person": {
            "id": 82866,
            "first_name": "Rita",
            "last_name": "McClellan",
            "photo_url": "assets/11ed69d85a1d4788a8cdedccfabf31ee_AnnMcClellan4insite_t.jpg",
            "phone_number": "1 (919) 453-4233",
            "email_address": "amcclellan@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1174,
              "name": "Mgr, GMP",
              "name_abbreviation": "GMPMGR"
            }
          ],
          "shifts": [
            {
              "id": 7896,
              "name": "None",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "182c6eff-6a1f-4c64-979e-2a7b7be1ff79"
        },
        {
          "id": 86822,
          "person": {
            "id": 86149,
            "first_name": "Robert",
            "last_name": "Alini",
            "photo_url": "assets/6eb0ff1470514cab98e70b167e4f9083_RobertAlini_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8bf5ed4d-3549-4276-b595-d7ba9819ef71"
        },
        {
          "id": 84809,
          "person": {
            "id": 84162,
            "first_name": "Robin",
            "last_name": "Perry",
            "photo_url": "assets/a79cb3f72c7a44ab89f32ef87a2a0d41_RobinPerry_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f60869e8-687d-41ea-9a4a-2e0617fa933b"
        },
        {
          "id": 88093,
          "person": {
            "id": 87394,
            "first_name": "Roger",
            "last_name": "Pimentel",
            "photo_url": "assets/db7ab4d8ad9d47d3948225e19efd39ff_rogerpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3229730-c557-44c4-957d-79a2838c8e94"
        },
        {
          "id": 88715,
          "person": {
            "id": 88016,
            "first_name": "Ronald",
            "last_name": "St Jock",
            "photo_url": "assets/rfbdfbfa928584f18a63429c85ddf9156_IMG_1949_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 2796,
              "name": "Swing",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "840a95c6-1087-4232-8049-a09dd2c6a68d"
        },
        {
          "id": 87411,
          "person": {
            "id": 86725,
            "first_name": "Rosa",
            "last_name": "Ponce",
            "photo_url": "assets/r70cb55d131a2452faa3d2b295e6c36db_20230721_2134411_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54787a2f-ff30-486e-ab37-60ae994a272e"
        },
        {
          "id": 86587,
          "person": {
            "id": 85916,
            "first_name": "Rosalia",
            "last_name": "Lopez De Cardenas",
            "photo_url": "assets/062a249abc4942b6bf241463d145a90d_RosaliaLopezPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f2c535c-1bda-417d-8a15-d6fd9d092e30"
        },
        {
          "id": 86814,
          "person": {
            "id": 86141,
            "first_name": "Rosanna",
            "last_name": "Ventura",
            "photo_url": "assets/ba3b6cd4687042b481dd0e849f380342_RosannaVentura_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fce8bdcf-b236-4d37-bf6d-9c8585e27b74"
        },
        {
          "id": 86118,
          "person": {
            "id": 85450,
            "first_name": "Rosario",
            "last_name": "Rugama",
            "photo_url": "assets/9f89751246bd4cd8a10b59cfc38c1b3e_pro64elfokV_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 5193,
              "name": "Day",
              "contract": {
                "id": 3432,
                "name": "LaVergne",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "0782cfc4-1138-4ee2-9a49-a1adfc897e25",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ffcf616-8434-48b0-b66b-c40ad41d24bc"
        },
        {
          "id": 87079,
          "person": {
            "id": 86403,
            "first_name": "Rosenda",
            "last_name": "Mejia",
            "photo_url": "assets/220cd1df48b3494dbcd6ba2ec503d94e_IMG_2628_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7717,
              "name": "Swing",
              "contract": {
                "id": 5794,
                "name": "Fulton County Airport Hangar",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "8baabbd9-e6e5-413f-abbb-be0d5d268562",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "26a112ec-726d-44d6-be57-aeaeb08b9cc0"
        },
        {
          "id": 86796,
          "person": {
            "id": 86123,
            "first_name": "Sanjin",
            "last_name": "Preljvukaj",
            "photo_url": "assets/02fbaa7ed49f49fba1a668bbd281fd0d_SanjinPrejvukaj_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b96cd341-2ba5-47ff-9d4b-816003ee6062"
        },
        {
          "id": 89011,
          "person": {
            "id": 88310,
            "first_name": "Santos",
            "last_name": "Morales De Zavala",
            "photo_url": "assets/r2c7810e3f0bf43c78251dce7f7346899_Santos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ced43211-0d2d-4ce5-a5a3-85ea5ea317a2"
        },
        {
          "id": 85087,
          "person": {
            "id": 84439,
            "first_name": "Sarah",
            "last_name": "Behrman",
            "photo_url": "assets/311b982448d24b42ab6b2bf771685a0b_SarahBehrmanpic_t.jfif",
            "phone_number": "9298014813",
            "email_address": "sbehrman@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 2307,
              "name": "Day",
              "contract": {
                "id": 2431,
                "name": "CORPW014",
                "customer_id": 290,
                "customer_name": "SBM-H",
                "uuid": "d356b7e2-042e-4761-830a-4bbbe92d9653",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7200,
              "name": "Day",
              "contract": {
                "id": 3470,
                "name": "Redlands",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "219a07d3-50c9-4f61-ba29-3cc2aeeb14ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3001,
              "name": "Swing",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0a2be94-ac71-4518-b368-0bf488d9dd29"
        },
        {
          "id": 88749,
          "person": {
            "id": 88050,
            "first_name": "Sergia",
            "last_name": "Santander Lopez",
            "photo_url": "assets/9b3b1b9046a64064b2a526ea10c5c866_20230830_142439_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ec52f10a-0824-40b5-92c8-21656cc55a2b"
        },
        {
          "id": 87171,
          "person": {
            "id": 86492,
            "first_name": "Sharda",
            "last_name": "Russell",
            "photo_url": "assets/67ab82548a6b448c85b8b79666bd9441_ShardaRussellInsite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7437,
              "name": "None",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c92a5cd-d5d3-46cb-bcfd-d95ca783164b"
        },
        {
          "id": 89207,
          "person": {
            "id": 88502,
            "first_name": "Sharon",
            "last_name": "Thomas",
            "photo_url": "assets/cecd9d5486544e75beac426cd6dad3a6_20231107_113524_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4452252d-2268-4a0c-9c89-4c37c8247cd7"
        },
        {
          "id": 88408,
          "person": {
            "id": 87707,
            "first_name": "Shawn",
            "last_name": "Lucas",
            "photo_url": "assets/rb17512e130324b89a48dad3e227f6bc2_Shawnphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "541f05c1-a412-43b3-8c99-552f912ef857"
        },
        {
          "id": 86319,
          "person": {
            "id": 85649,
            "first_name": "Sherry",
            "last_name": "Stevenson",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e55e0d5-974b-48a2-9dea-f510d1284981"
        },
        {
          "id": 86860,
          "person": {
            "id": 86187,
            "first_name": "Sheryl",
            "last_name": "Hudson",
            "photo_url": "assets/86516d42cd9142eeb9d0e8f7084c332c_SherylHudson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "58e7730b-0ac9-47c2-89f1-226720c8ff62"
        },
        {
          "id": 86840,
          "person": {
            "id": 86167,
            "first_name": "Shirley",
            "last_name": "Wiley",
            "photo_url": "assets/b2b05487bedb43ea83f9c57d61125ce2_ShirleyWiley_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3c607e8f-84a2-41df-ba0c-d9c187663fbe"
        },
        {
          "id": 84971,
          "person": {
            "id": 84323,
            "first_name": "Shukri",
            "last_name": "Abdi",
            "photo_url": "assets/rcd708038638f425f91dcb58b9a93b4d3_20230928_170354_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ceeba022-37c2-44ef-96f6-2b595f902ed1"
        },
        {
          "id": 86791,
          "person": {
            "id": 86118,
            "first_name": "Sklkim",
            "last_name": "Marke",
            "photo_url": "assets/c81775d0bf584f5ab77bf3514b3e5ca8_222d8080c7ab48bdafb83c6e29f3feef_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bcc59a88-b406-41c4-a9e1-9233dd6ecee8"
        },
        {
          "id": 86861,
          "person": {
            "id": 86188,
            "first_name": "Stacy",
            "last_name": "Howard",
            "photo_url": "assets/f3cfc62f3ecc4afd8ed03c1e233f73d7_IMG_1892_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c062d1c2-cb30-488b-aa07-d27adbe370ac"
        },
        {
          "id": 87659,
          "person": {
            "id": 86969,
            "first_name": "Stephanie",
            "last_name": "Martinez",
            "photo_url": "assets/0f8c09762b934163a48162d49e216a1e_STephanieMartinez4insitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3c70a917-5372-4433-a711-4f6d0a08f5ae"
        },
        {
          "id": 83883,
          "person": {
            "id": 83244,
            "first_name": "Susan",
            "last_name": "Kenyon",
            "photo_url": "assets/af53eab903bb438abc0be9c2fc3e427d_SueInSite_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-03-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8703085-62ba-4c75-8000-80efee5e7a29"
        },
        {
          "id": 87413,
          "person": {
            "id": 86727,
            "first_name": "Takila",
            "last_name": "Beech",
            "photo_url": "assets/8e492dec294e45dc9d4ff65121c59f88_takilabeech4insiteprofilepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3da03751-9037-43e6-8cbb-af92d7c1be80"
        },
        {
          "id": 86816,
          "person": {
            "id": 86143,
            "first_name": "Tania",
            "last_name": "Perez",
            "photo_url": "assets/779359a41ab34825a9886cc5e670e3e7_image001_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f2e6aef1-e961-473a-a036-428254ef56df"
        },
        {
          "id": 87387,
          "person": {
            "id": 86701,
            "first_name": "Tanisha",
            "last_name": "Gilstrap",
            "photo_url": "assets/660fb3b5b27b4508a75792fcebad5ff8_IMG_2769_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9ee19bd-fa69-45f8-a153-ec9f678fc0f2"
        },
        {
          "id": 88557,
          "person": {
            "id": 87856,
            "first_name": "Tashauna",
            "last_name": "Howard",
            "photo_url": "assets/r68b2e3b3043e4c6a84a9348524331b4d_TashaunaProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a7ab3630-244b-4c7c-abc8-86e19cb2d4de"
        },
        {
          "id": 85092,
          "person": {
            "id": 84444,
            "first_name": "Temeka",
            "last_name": "Williamson",
            "photo_url": "assets/82afdb2a7b08457ca89722afb8ac9992_TemekaWilliamson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b94facb-6ddb-412e-bdcf-1f4f53cf3e96"
        },
        {
          "id": 86848,
          "person": {
            "id": 86175,
            "first_name": "Tequica",
            "last_name": "McClendon",
            "photo_url": "assets/88402ae732b742fd9653cf51c95fe3d2_IMG_2617_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89128453-4776-4a88-84bb-b856e94b4e0a"
        },
        {
          "id": 88640,
          "person": {
            "id": 87941,
            "first_name": "Teresa",
            "last_name": "Cruz Guerrero",
            "photo_url": "assets/91128dc6e4f945db8efb78f2b776bb13_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2376,
              "name": "Swing",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5af2c84-759a-48e2-873e-88686666ccf1"
        },
        {
          "id": 86810,
          "person": {
            "id": 86137,
            "first_name": "Theodore",
            "last_name": "Jeanmary",
            "photo_url": "assets/49617795ebcc46f2b6019c817514cb63_961361a610b34782a3b82b63ce7891e5_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7468931-cd2c-453b-8984-b05a0b851ac4"
        },
        {
          "id": 87029,
          "person": {
            "id": 86354,
            "first_name": "Theodore",
            "last_name": "Saulsberry",
            "photo_url": "assets/8343e4e4920c4688840b837781ed87bc_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4986,
              "name": "None",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2023-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b10a1294-a9cb-47fc-b99a-52282ff93cad"
        },
        {
          "id": 19126,
          "person": {
            "id": 19250,
            "first_name": "Thi",
            "last_name": "Hoang",
            "photo_url": "assets/1580527338.217258_20200131_015551_resized_t.jpg",
            "phone_number": "",
            "email_address": "232021@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-03-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38f3d152-23ad-4711-b09e-5a44910b64a2"
        },
        {
          "id": 19133,
          "person": {
            "id": 19257,
            "first_name": "Thi",
            "last_name": "Pham",
            "photo_url": "assets/1580527295.3800085_20200130_175733_resized_t.jpg",
            "phone_number": "",
            "email_address": "232036@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-04-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a80e1225-477b-4581-809d-a704b356dc4e"
        },
        {
          "id": 18909,
          "person": {
            "id": 19044,
            "first_name": "Thi",
            "last_name": "Tran",
            "photo_url": "assets/1578637660.8893285_IMG_23741_t.JPG",
            "phone_number": "",
            "email_address": "231818@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-04-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e835b0e4-dfc3-4c2f-b49e-c608e905975f"
        },
        {
          "id": 18917,
          "person": {
            "id": 19052,
            "first_name": "Thi My",
            "last_name": "Nguyen",
            "photo_url": "assets/1578637767.8031728_IMG_23721_t.JPG",
            "phone_number": "",
            "email_address": "231807@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fae245df-7855-4da6-93bf-64466c32da69"
        },
        {
          "id": 86835,
          "person": {
            "id": 86162,
            "first_name": "Tiffani",
            "last_name": "Cameron",
            "photo_url": "assets/42190d7b80f34505a42c6a3472eb3f8e_Capture_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3cc9f5cf-2b8c-45a4-8053-4df6d5718189"
        },
        {
          "id": 88026,
          "person": {
            "id": 87327,
            "first_name": "Tiffany",
            "last_name": "Haydock",
            "photo_url": "assets/d0acab10769e4f92941bbbfd816d1b5b_attachment1709650615103_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7222,
              "name": "Day",
              "contract": {
                "id": 4836,
                "name": "Asheville, NC*",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "ad7c0ff4-42a9-4c73-9cd3-eba5229bd7bd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e07beb3c-1f3a-4c0f-9b98-df88870fedbb"
        },
        {
          "id": 87216,
          "person": {
            "id": 86536,
            "first_name": "Timothy",
            "last_name": "Jacobs",
            "photo_url": "assets/05475102f71e4f169cf03948093643f4_IMG_2618_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e40553a8-15d3-4216-91da-62cd1570f384"
        },
        {
          "id": 84623,
          "person": {
            "id": 83980,
            "first_name": "Timothy",
            "last_name": "Johnson",
            "photo_url": "assets/176ad050283d45d284e8c6ec028ce14f_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "05c4f797-2355-4993-af6d-ef7d8acdcc84"
        },
        {
          "id": 86828,
          "person": {
            "id": 86155,
            "first_name": "Tosha",
            "last_name": "Whitaker",
            "photo_url": "assets/fd9b6120575343be92a98deb6ce334fa_IMG_1886_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7666,
              "name": "Swing",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4eb0b6e5-382b-4f63-a5e5-36a95d81b150"
        },
        {
          "id": 89205,
          "person": {
            "id": 88500,
            "first_name": "Troy",
            "last_name": "Greer",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5fa18a69-1ca3-435b-ba0d-68ae2f769600"
        },
        {
          "id": 88074,
          "person": {
            "id": 87375,
            "first_name": "Tyesha",
            "last_name": "Bennett",
            "photo_url": "assets/3a3a51f514104b99b481a5813c0bb69e_Screenshot20231107141537_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f2c1aaa-432f-4c7e-b2bd-70df2ac5db02"
        },
        {
          "id": 87224,
          "person": {
            "id": 86543,
            "first_name": "Tyshaun",
            "last_name": "Hawkins",
            "photo_url": "assets/b763fe02b18e4a6aaeebfef272afed78_Tyshaun_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7710,
              "name": "None",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "144b735b-0f58-4e62-ba01-29998203347d"
        },
        {
          "id": 86253,
          "person": {
            "id": 85583,
            "first_name": "Uventila",
            "last_name": "De Santiago",
            "photo_url": "assets/r453b1d29e8c5435286259b0afb79a33f_Tila_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "77951cb0-a937-4b0f-9e7a-31efdc43b130"
        },
        {
          "id": 3889,
          "person": {
            "id": 3889,
            "first_name": "Vernetta",
            "last_name": "Thomas",
            "photo_url": "assets/1588000479.0069008_20190220_170806_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5037,
              "name": "Swing",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c40df5d-ff31-41a9-8412-497666d77d95"
        },
        {
          "id": 82804,
          "person": {
            "id": 82171,
            "first_name": "Victor",
            "last_name": "Yupa Zaruma",
            "photo_url": "assets/6803d3bbd6ab4d53b3f5669a1ee94373_VZ_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f84d61e-4df3-44ba-bd2c-799e1d621e00"
        },
        {
          "id": 85534,
          "person": {
            "id": 84866,
            "first_name": "Virginia",
            "last_name": "Bailey",
            "photo_url": "assets/22658c6ad819418db8cf5125c4ecc178_IMG_20230530_161815_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a59d6345-e41f-4010-8f1b-33a4bb9ed7f3"
        },
        {
          "id": 9853,
          "person": {
            "id": 9853,
            "first_name": "William",
            "last_name": "Crouse",
            "photo_url": "assets/7359a2dc2dd940f2a721dd05b8e5452e_20210811_121328_t.jpg",
            "phone_number": "3143715988",
            "email_address": "wcrouse@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2104,
              "name": "Day",
              "contract": {
                "id": 1324,
                "name": "Illinois",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "64224bb4-c347-4f72-8a90-d8933aa8f898"
        },
        {
          "id": 42643,
          "person": {
            "id": 42223,
            "first_name": "William",
            "last_name": "Rose",
            "photo_url": "assets/551a07ff429a414b873c8bbf2479dcc9_newprofilepic_t.jpg",
            "phone_number": "9476001499",
            "email_address": "wrose@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-05-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ef03e42-a877-4722-9912-c03379ff0cef"
        },
        {
          "id": 72026,
          "person": {
            "id": 71491,
            "first_name": "Willie",
            "last_name": "Pryor",
            "photo_url": "assets/6cfd663bf18e455d9f94877e87aede6b_willie_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 107,
              "name": "Glass Wash Equip Prep",
              "name_abbreviation": "GMPGW"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4cfdcb71-185e-409c-b51f-978281901441"
        },
        {
          "id": 87215,
          "person": {
            "id": 86535,
            "first_name": "Xochilt",
            "last_name": "Gonzalez",
            "photo_url": "assets/671578c2f80d4c32b4708db3b8f88042_IMG_4433_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7713,
              "name": "Day",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c62650c6-953f-4957-bcd5-d16d53918614"
        },
        {
          "id": 47724,
          "person": {
            "id": 47239,
            "first_name": "Xuan",
            "last_name": "Ly",
            "photo_url": "assets/1620084986.937435_XuanLyHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2023-07-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e38d299-686d-4453-8b5c-db3fea7ed649"
        },
        {
          "id": 54180,
          "person": {
            "id": 53637,
            "first_name": "Yadira",
            "last_name": "Gonzalez",
            "photo_url": "assets/ae111d5087964894940d79f94bd52b45_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dafd888f-648c-4a03-ad24-66bc25413ad6"
        },
        {
          "id": 87017,
          "person": {
            "id": 86342,
            "first_name": "Yamilet",
            "last_name": "Rojas",
            "photo_url": "assets/1f4cee8c70e74a0dacb0bf4792838e16_Yamiletrojas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7368,
              "name": "Day",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7fcd4ce3-99e7-4563-93f0-89557cc65d82"
        },
        {
          "id": 84986,
          "person": {
            "id": 84338,
            "first_name": "Yanira",
            "last_name": "Escobar",
            "photo_url": "assets/717c075ea07a492dbae79235c56dfe72_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-04-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b460d038-a2a5-4fd8-87e7-589e354ff514"
        },
        {
          "id": 87671,
          "person": {
            "id": 86981,
            "first_name": "Yaudi",
            "last_name": "Sanchez Ramirez",
            "photo_url": "assets/a5196d430aa04078b33a934f35196267_yadui_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-07-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d018dea-6501-40d7-982b-d211a7460454"
        },
        {
          "id": 86864,
          "person": {
            "id": 86191,
            "first_name": "Yelissa",
            "last_name": "Gomez",
            "photo_url": "assets/facd80ee4531485894e8a5a3911dccf8_YelissaGomez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4448ad77-cd57-4fe1-8b02-61f85aa874ef"
        },
        {
          "id": 82675,
          "person": {
            "id": 82042,
            "first_name": "Yeny",
            "last_name": "Galo",
            "photo_url": "assets/2f7e541e6c254e2ea4dd1b463b6ecba2_20230321_185022_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "317d008f-2087-4c72-a49f-83e1590cc2c3"
        },
        {
          "id": 86809,
          "person": {
            "id": 86136,
            "first_name": "Yobania",
            "last_name": "Morel",
            "photo_url": "assets/6abfb9308e804938a2abd3d09ca4d5be_ccdfc1e7932649738232896e8a034a8e_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28db0a0f-fa3e-405e-8f3a-7e7825bec486"
        },
        {
          "id": 89217,
          "person": {
            "id": 88512,
            "first_name": "Yoshiro",
            "last_name": "Toda",
            "photo_url": "assets/92de9766960f4762be4e52433149dc23_1000000182_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1245,
              "name": "Swing",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9baa2b5a-dae3-4fe7-8d35-41851f82c523"
        },
        {
          "id": 1662,
          "person": {
            "id": 1662,
            "first_name": "Yudy",
            "last_name": "Argueta",
            "photo_url": "assets/rdb9c7e8cf84845e89471ea9a1e728775_temp_file_.jpg",
            "phone_number": "",
            "email_address": "206092@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7240e108-c939-4221-8dba-acebd0c874de"
        },
        {
          "id": 85898,
          "person": {
            "id": 85230,
            "first_name": "Yvette",
            "last_name": "Harris",
            "photo_url": "assets/7accb05a311347cb8b5ba8a9aaf68ff5_StandardFemale111_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-05-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "82b69773-980d-4c7b-a1d1-c81da15c68ea"
        },
        {
          "id": 84252,
          "person": {
            "id": 83611,
            "first_name": "Zachary",
            "last_name": "Mackovitch",
            "photo_url": "assets/e5769a73c1bf44cd81dd5b11cf18ea5f_IMG_20230725_072614_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1114,
              "name": "Day",
              "contract": {
                "id": 1486,
                "name": "Cherokee, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-04-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "acb808da-330a-42d6-b0a2-4b1e26b00671"
        },
        {
          "id": 86787,
          "person": {
            "id": 86114,
            "first_name": "Zenel",
            "last_name": "Rugova",
            "photo_url": "assets/070730f570a14ce884e22bb179c5a33e_ZenelRugova_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e581ab2-57f3-4ebb-a707-485395e409f1"
        }
      ]
    },
    {
      "rangeTitle": "1 - 3 Years",
      "rangeSubtitle": "Employed for 1 to 3 years",
      "percentage": 0.33,
      "total": 875,
      "positions": 44,
      "employees": [
        {
          "id": 70629,
          "person": {
            "id": 70093,
            "first_name": "Aaron",
            "last_name": "Slate",
            "photo_url": "assets/e071285e5c134021ab00a048a25aaae9_Resized_20220517_102618_t.jpeg",
            "phone_number": "1 (313) 858-6526",
            "email_address": "aslate@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7995,
              "name": "None",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "612c34aa-e772-422f-971d-ae9af011b7ba"
        },
        {
          "id": 81116,
          "person": {
            "id": 80485,
            "first_name": "Achol",
            "last_name": "Mabior",
            "photo_url": "assets/14409ac7d8214442b7bc8b0f663272ac_AcholMabiorCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a551042f-7e9a-431e-baf3-af9aecb0deb9"
        },
        {
          "id": 62872,
          "person": {
            "id": 62336,
            "first_name": "Adelina",
            "last_name": "Valdez Serrano",
            "photo_url": "assets/r2d40f3ab68084c50874cf1163069d0ae_Adelina2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7612bc0d-1907-49d6-b6a3-51a5ad224f50"
        },
        {
          "id": 75459,
          "person": {
            "id": 74924,
            "first_name": "Adilio",
            "last_name": "Guerra",
            "photo_url": "assets/c7f36854a81b4360a0da8b1236d9412b_1_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd4730c3-e4b5-4452-8058-17b1788749e6"
        },
        {
          "id": 75458,
          "person": {
            "id": 74923,
            "first_name": "Adilson Moreira",
            "last_name": "Cecilio",
            "photo_url": "assets/36adff7a6a614b7f8f8993d209c846f3_AdilsonMoreiraPct_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f884d98-c3b1-4fb5-bc39-e53952c87cd5"
        },
        {
          "id": 74051,
          "person": {
            "id": 73510,
            "first_name": "Adrian",
            "last_name": "Rendon",
            "photo_url": "assets/5e002f5cb2fe4614874f1fb599f64083_AndrianRendanTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "600274fb-e690-44cc-ae21-07aa511970df"
        },
        {
          "id": 71563,
          "person": {
            "id": 71029,
            "first_name": "Agustina",
            "last_name": "Garcia",
            "photo_url": "assets/909695ae86d84dd5917532dd3037af8c_AgustinaGarcia_DB_1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3001,
              "name": "Swing",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "efe36d32-e73e-4d94-8783-c09218d076e5"
        },
        {
          "id": 74190,
          "person": {
            "id": 73648,
            "first_name": "Ahmed Ali Mohamed",
            "last_name": "Alhabil",
            "photo_url": "assets/d762ead14ffb43b3a7bffc3353eae2d6_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0779bdd-928d-4288-8253-ea1fba07ac8d"
        },
        {
          "id": 54191,
          "person": {
            "id": 53648,
            "first_name": "Aida",
            "last_name": "Cuevas",
            "photo_url": "assets/29342a6232b841c8b23ae3db42a74f59_IMG_20221104_4564_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2dae4370-1ed2-4c80-8665-4b5becb71d6a"
        },
        {
          "id": 74880,
          "person": {
            "id": 74336,
            "first_name": "Aida",
            "last_name": "Rivera Rosado",
            "photo_url": "assets/r97a2f94365084176919b43c503b263b9_processed7d635f74f35d43a59088da069416a53d_nVWdfdX8_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3099,
              "name": "Day",
              "contract": {
                "id": 2623,
                "name": "Albany, OH",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "cb4abfa7-fddc-4aac-872c-7bd811c6fc36",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "05e23c39-f29a-4dc7-9914-6d1a05573a1d"
        },
        {
          "id": 46011,
          "person": {
            "id": 45552,
            "first_name": "Alan",
            "last_name": "Ascencio Chavez",
            "photo_url": "assets/1eca1432a1ce40fe91c6ea483327ab05_IMG_0017_t.jpg",
            "phone_number": "6023498094",
            "email_address": "aascencio@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75d568b5-f4bc-4c5b-95e6-4bb5f5beb280"
        },
        {
          "id": 54779,
          "person": {
            "id": 54234,
            "first_name": "Alan",
            "last_name": "Barradas Zarate",
            "photo_url": "assets/rb72ade0fc73b44ed9501db54456b49f3_Capture1_t.png",
            "phone_number": "+13174760554",
            "email_address": "abarradas-zarate@sbmcorp.com"
          },
          "positions": [
            {
              "id": 176,
              "name": "Mgr, Operations-Quality",
              "name_abbreviation": "GMPQLT"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "70fefcb2-4514-4af8-ae69-35b932527dad"
        },
        {
          "id": 74185,
          "person": {
            "id": 73643,
            "first_name": "Alba",
            "last_name": "Guzman",
            "photo_url": "assets/5ee7e3731ddd4861a4b1f2e7f9e56d75_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ec4674e-4f92-4bf0-9fa7-91dfc55b96ac"
        },
        {
          "id": 70974,
          "person": {
            "id": 70437,
            "first_name": "Alberto",
            "last_name": "Almeida",
            "photo_url": "assets/85984c953d5946acbc764c72818b5905_AlbertoAlmeidaChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "82e73eb4-41cc-4164-9554-1ae3c9343c1c"
        },
        {
          "id": 53721,
          "person": {
            "id": 53179,
            "first_name": "Alex",
            "last_name": "Ostiguin",
            "photo_url": "assets/484ca6febd9a4adfb2a13432a5f64ea2_AlexOstiguinPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3165,
              "name": "Day",
              "contract": {
                "id": 2643,
                "name": "Lincoln South",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "127b2581-73ae-4cfb-bd38-e1ef94cf7914"
        },
        {
          "id": 63974,
          "person": {
            "id": 63438,
            "first_name": "Alex",
            "last_name": "Reyes Lazo",
            "photo_url": "assets/r3eb3f411fd7b4d43b8365ab235db86b6_Alex_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3183,
              "name": "Night",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-12-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5df2d230-df2d-4a68-921e-a176f9f0f038"
        },
        {
          "id": 81239,
          "person": {
            "id": 80607,
            "first_name": "Alexander",
            "last_name": "Garza",
            "photo_url": "assets/53ed7590fb684eacba87e2da32010c7f_IMG_0648alexgarza_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "093b55e7-bf3c-4c3f-83a6-cd75d1c586ce"
        },
        {
          "id": 80600,
          "person": {
            "id": 79970,
            "first_name": "Alexander",
            "last_name": "Pichardo",
            "photo_url": "assets/r47cf1aa103004722a9138eec7072a29b_AlexPichardo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37fa62e9-3fc1-4bb4-a64c-8455b51778a8"
        },
        {
          "id": 48965,
          "person": {
            "id": 48471,
            "first_name": "Alfred",
            "last_name": "Shamu",
            "photo_url": "assets/ra323427cbbc84191bebb570568f29705_Alfred_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2241,
              "name": "Night",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-06-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "794d476c-e928-408a-9a26-ab2eef5c00eb"
        },
        {
          "id": 74193,
          "person": {
            "id": 73651,
            "first_name": "Ali",
            "last_name": "Al Orimi",
            "photo_url": "assets/ae893e62fac04763909115f6f1a33610_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ffffb5c-4a0d-4560-be37-d4baaba83e43"
        },
        {
          "id": 75343,
          "person": {
            "id": 74808,
            "first_name": "Alicia",
            "last_name": "Krout",
            "photo_url": "assets/af233d252f1f49d2a40042364453a666_thumbnail_20220823_221200_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ffdc10b-e860-423e-8fd1-92fd7183506c"
        },
        {
          "id": 52002,
          "person": {
            "id": 51479,
            "first_name": "Alicia",
            "last_name": "Primus",
            "photo_url": "assets/6d8af4de2aaf4830b4afeeb317868995_AliciaPrimus_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "583e9dd0-d942-46d1-bcfd-345ccd397792"
        },
        {
          "id": 75460,
          "person": {
            "id": 74925,
            "first_name": "Allan",
            "last_name": "Kibirige",
            "photo_url": "assets/1d12602d9732482e9c0984edea63b973_2_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "77054c96-9421-43bc-ba3a-4b0e26c86d6f"
        },
        {
          "id": 79279,
          "person": {
            "id": 78669,
            "first_name": "Allison",
            "last_name": "Martinez",
            "photo_url": "assets/96bd7deff38a479e80012a76843d4f5e_AllisonMartinez_t.jpg",
            "phone_number": "+16502168607",
            "email_address": "amartinez4@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-11-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b46b6939-468a-411a-a2b7-8f0297fcfca9"
        },
        {
          "id": 74381,
          "person": {
            "id": 73839,
            "first_name": "Alma",
            "last_name": "Contreras Torres",
            "photo_url": "assets/2737a7896c4a4fcda935a8183f07795e_KARINAVAL_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6578e520-df59-4338-bf55-002b58801992"
        },
        {
          "id": 74352,
          "person": {
            "id": 73810,
            "first_name": "Alma",
            "last_name": "Rivera",
            "photo_url": "assets/f14d19210b48413abc3e914683998973_almarivera_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ca27b16-e125-4778-8d68-0aae487c8dfe"
        },
        {
          "id": 18810,
          "person": {
            "id": 18947,
            "first_name": "Alma",
            "last_name": "Romero",
            "photo_url": "assets/24a33a8802034e96803e40cc6c03dd7a_Alma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a59ddeff-30bf-4e6b-b5d3-02de99ad4d24"
        },
        {
          "id": 74228,
          "person": {
            "id": 73686,
            "first_name": "Amalia",
            "last_name": "Mateos",
            "photo_url": "assets/2aaabfc1c8d34c59ad7e469c4207aad4_AMALIAMATEOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0942f60e-4162-4f68-b728-ecff042e0012"
        },
        {
          "id": 49325,
          "person": {
            "id": 48830,
            "first_name": "Amber",
            "last_name": "Noland",
            "photo_url": "assets/9e3bd06e0bd448a3bba9b4539d755d28_ALN_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d0f3711-e3f7-4eda-b1f4-61b5349fd97d"
        },
        {
          "id": 74373,
          "person": {
            "id": 73831,
            "first_name": "America",
            "last_name": "Vazquez",
            "photo_url": "assets/24435419fb00401cb9858da2e5c2ab22_AMERICAVASQUEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5414fbcb-b61d-4f87-99aa-4fed2c252d37"
        },
        {
          "id": 51589,
          "person": {
            "id": 51069,
            "first_name": "Amina",
            "last_name": "Abdi",
            "photo_url": "assets/dd3ef53375354845988fae6f847ecd47_amina_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2a444430-c4fa-4254-9013-5ad93fd3e232"
        },
        {
          "id": 50385,
          "person": {
            "id": 49884,
            "first_name": "Ana",
            "last_name": "Acevedo De Patino",
            "photo_url": "assets/0db6f80552e34940b68b853634b9c616_anamk_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3036,
              "name": "Swing",
              "contract": {
                "id": 2589,
                "name": "Roseville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "123db32d-6425-45a7-a771-e015c599d908",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-06-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d1a5b82-c35b-496f-9cd5-4c537e504180"
        },
        {
          "id": 51785,
          "person": {
            "id": 51262,
            "first_name": "Ana",
            "last_name": "Alvarez",
            "photo_url": "assets/f72ad03e56d74a159df73c6dbf90a244_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3082,
              "name": "Day",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb56633c-05df-4b1e-9968-8dcd2c8b50cd"
        },
        {
          "id": 75461,
          "person": {
            "id": 74926,
            "first_name": "Ana",
            "last_name": "Cardenas",
            "photo_url": "assets/b74b79198ddd42f2ba031aae92a2d550_3_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "63abcc85-5434-4379-bdac-c3fa103632c1"
        },
        {
          "id": 71452,
          "person": {
            "id": 70918,
            "first_name": "Ana",
            "last_name": "Gaona Espejo",
            "photo_url": "assets/d5fb5d96bea9434d920a4518c05d9717_IMG_20221109_57176_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-05-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7d4e03ce-4be0-49c9-8713-21c8f399144c"
        },
        {
          "id": 49618,
          "person": {
            "id": 49118,
            "first_name": "Ana",
            "last_name": "Garcia",
            "photo_url": "assets/a51d501dd2484196b3d5f4cadfc42c05_20210801_145644_t.jpg",
            "phone_number": "949-228-3166",
            "email_address": "anagarcia@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 2047,
              "name": "Day",
              "contract": {
                "id": 1703,
                "name": "REG701",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "7824e0bc-aaae-4704-b288-88361e83c20e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6058,
              "name": "Day",
              "contract": {
                "id": 4223,
                "name": "Tustin",
                "customer_id": 1645,
                "customer_name": "AVID",
                "uuid": "04b34261-89e2-4155-9aa3-72e2040bfec7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6321,
              "name": "Day",
              "contract": {
                "id": 3476,
                "name": "Torrance, CA*",
                "customer_id": 424,
                "customer_name": "CPI Satcom",
                "uuid": "ee6320ca-2c95-416c-9f1e-a58b8df34e3a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8726,
              "name": "Day",
              "contract": {
                "id": 6737,
                "name": "Cypress",
                "customer_id": 2569,
                "customer_name": "Rolls Royce (JLL)",
                "uuid": "b31aab58-8a05-4e4e-8521-8ea959a5a432",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6388,
              "name": "Swing",
              "contract": {
                "id": 4225,
                "name": "City of Industry",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "48e90c2c-d6f9-4a38-bde4-a7d63f3c4b35",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7619,
              "name": "Swing",
              "contract": {
                "id": 4228,
                "name": "Ontario",
                "customer_id": 754,
                "customer_name": "Wieland",
                "uuid": "d3b6ca9a-3cf1-4171-96ea-9f7809c6c586",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7702,
              "name": "Unassigned",
              "contract": {
                "id": 5770,
                "name": "DJT6 – Riverside, CA",
                "customer_id": 24,
                "customer_name": "Amazon",
                "uuid": "bb831662-5e47-4d40-a790-7ec796275140",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2021-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e1e1004-eb25-4785-ae45-6c58a3c4fee5"
        },
        {
          "id": 74212,
          "person": {
            "id": 73670,
            "first_name": "Ana",
            "last_name": "Guevara",
            "photo_url": "assets/6cff749ccd9641eab4571f59bb8ca7da_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5f7382b6-71e4-4706-94a3-0f4b1f06ae9f"
        },
        {
          "id": 43386,
          "person": {
            "id": 42966,
            "first_name": "Ana",
            "last_name": "Hernandez",
            "photo_url": "assets/1608145625.0314255_AnaHernandezmicro_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28c56812-b474-4c0b-86bc-da895872dba0"
        },
        {
          "id": 50378,
          "person": {
            "id": 49877,
            "first_name": "Ana",
            "last_name": "Jimenez",
            "photo_url": "assets/r96bd44f79cd645fbbe9dafe928ed55db_E9C3F5EC98C84C1BBF10DC39417CD679_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-07-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f5bd111-f36c-4425-a634-e428da07e1dd"
        },
        {
          "id": 73441,
          "person": {
            "id": 72901,
            "first_name": "Ana",
            "last_name": "Lopez Candelas",
            "photo_url": "assets/a5caedcdc76a431abeda7538e2ffeb9b_IMG_20220708_145724_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7692,
              "name": "Swing",
              "contract": {
                "id": 5651,
                "name": "Springhouse Plant, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1c6d5591-bda7-4967-919b-febc7a9dd261"
        },
        {
          "id": 67908,
          "person": {
            "id": 67366,
            "first_name": "Ana",
            "last_name": "Martinez",
            "photo_url": "assets/3f846f766ce74ab886bde5463237c58b_IMG_7987_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a25baa1-002e-46ee-a4b3-d3c6f9158083"
        },
        {
          "id": 74201,
          "person": {
            "id": 73659,
            "first_name": "Ana",
            "last_name": "Moreno De Alfaro",
            "photo_url": "assets/1e637b4cc26047aa93c2aed97c884d40_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f51bd161-8817-42bb-910e-f03c1833ed42"
        },
        {
          "id": 67276,
          "person": {
            "id": 66734,
            "first_name": "Ana",
            "last_name": "Orellana",
            "photo_url": "assets/56f2b7a426dc40bcbeb3a02afc075db5_AnaOrellana_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75cb105e-13a6-4205-8432-69b2c1655786"
        },
        {
          "id": 74115,
          "person": {
            "id": 73573,
            "first_name": "Ana",
            "last_name": "Pacheco",
            "photo_url": "assets/ec44b733125a4876a0626b5090228610_ANAPACHECO_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0880b20-9d62-4eb7-b411-c33ab08a1c44"
        },
        {
          "id": 81193,
          "person": {
            "id": 80561,
            "first_name": "Ana",
            "last_name": "Rodriguez",
            "photo_url": "assets/f3d950ac70e74e1bb99bd4c92246fe0b_AnaRodriguezHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f7eafc5-1425-49cf-854e-f1f3028ef6b0"
        },
        {
          "id": 74210,
          "person": {
            "id": 73668,
            "first_name": "Ana",
            "last_name": "Serpas De Cortez",
            "photo_url": "assets/c59828bdc61a41e7a258f0096e0790a3_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "039d24ee-3d93-4973-a052-74eec384451a"
        },
        {
          "id": 75463,
          "person": {
            "id": 74928,
            "first_name": "Ana",
            "last_name": "Valenzuela",
            "photo_url": "assets/55a7aa2ef9a6476d9c03b8cdb3827872_5_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5ea74af-1b78-4bcd-9f75-1c3a668729f1"
        },
        {
          "id": 79998,
          "person": {
            "id": 79374,
            "first_name": "Ana",
            "last_name": "Weekly",
            "photo_url": "assets/d957c4d3f71a40e18bff000c58542f84_305137486_5207966462632028_7525157243343718431_n_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 7555,
              "name": "Day",
              "contract": {
                "id": 5683,
                "name": "Van Nuys, CA",
                "customer_id": 2133,
                "customer_name": "Anheuser-Busch",
                "uuid": "3f4dbab6-4aec-4a7f-8386-3417b6c569a8",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7418,
              "name": "Day",
              "contract": {
                "id": 5549,
                "name": "Valencia, CA",
                "customer_id": 2103,
                "customer_name": "Boston Scientific",
                "uuid": "86fa4184-0ef6-416d-8d50-5acdb09cdcd7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2047,
              "name": "Day",
              "contract": {
                "id": 1703,
                "name": "REG701",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "7824e0bc-aaae-4704-b288-88361e83c20e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2780,
              "name": "Swing",
              "contract": {
                "id": 1499,
                "name": "North Hollywood* (JLL)",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "76b6673c-40cc-4372-969d-f28e79165887",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7255d12c-e517-4bba-b85a-0b781dfc4ec9"
        },
        {
          "id": 74467,
          "person": {
            "id": 73925,
            "first_name": "Ana G",
            "last_name": "Escobar",
            "photo_url": "assets/592f276ded4f48ea9eafb48c274f789a_ANAE_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b11f0847-4fa1-42da-9339-05db63f0de95"
        },
        {
          "id": 74498,
          "person": {
            "id": 73956,
            "first_name": "Ana Isabel",
            "last_name": "Martinez",
            "photo_url": "assets/60720f9f818f4dca8f73321bff16c129_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6170d9b-6e8a-4955-8cd0-2508670abcb2"
        },
        {
          "id": 75464,
          "person": {
            "id": 74929,
            "first_name": "Anamin",
            "last_name": "Tejada De Paz",
            "photo_url": "assets/d568a561bfc649a68f3ec6af216d0472_6_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb3d57fd-7f43-4cb8-8180-d20c6be69ef7"
        },
        {
          "id": 74298,
          "person": {
            "id": 73756,
            "first_name": "Ana Rosa",
            "last_name": "Brambila",
            "photo_url": "assets/0d9f835508494cf9aeb58f344bd76d4f_IMG_20230623_032943_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6623,
              "name": "Day",
              "contract": {
                "id": 3994,
                "name": "Chilco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2748576-452d-4c9b-8142-4cfe17ff021f"
        },
        {
          "id": 74285,
          "person": {
            "id": 73743,
            "first_name": "Ana Rosa",
            "last_name": "Perez",
            "photo_url": "assets/0215cd42720c4961b1456d69bfc73349_ANAPERE_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ecec309f-76f4-47fd-b3a6-14c48ce48c34"
        },
        {
          "id": 79004,
          "person": {
            "id": 78399,
            "first_name": "Andrea",
            "last_name": "Rivera Lopez",
            "photo_url": "assets/6142353c4a104256ad6ff1e6cd21fd58_20221219_141822_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cfc5f354-1896-4afa-84a0-139f965b6da7"
        },
        {
          "id": 74386,
          "person": {
            "id": 73844,
            "first_name": "Andrea",
            "last_name": "Robles Sandoval",
            "photo_url": "assets/9d9bdcc3ad39465ab863924fb0b5372e_ANDREAROBLES_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24f1096b-8d90-459c-85c2-1229559f0410"
        },
        {
          "id": 79947,
          "person": {
            "id": 79324,
            "first_name": "Andrea",
            "last_name": "Rodriguez Duran",
            "photo_url": "assets/d7095dc2817b4eb9b41d96304d37f046_thumbnail_20221228_174759_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "175b1644-d078-48e9-89f0-0b3ccfea7e58"
        },
        {
          "id": 74139,
          "person": {
            "id": 73597,
            "first_name": "Angel",
            "last_name": "Arteaga-Roman",
            "photo_url": "assets/r3a7a3e315e9d42129e9a43fc06348760_IMG_8346_t.JPEG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5c56176-31a3-45b0-9db8-313c49443308"
        },
        {
          "id": 74463,
          "person": {
            "id": 73921,
            "first_name": "Angel",
            "last_name": "Orellana Oliva",
            "photo_url": "assets/7cf7fe42743647d79a3db045482e62b9_ANGELORELLANA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb1a4e7e-0e72-41a0-89fa-792e74ae7277"
        },
        {
          "id": 74427,
          "person": {
            "id": 73885,
            "first_name": "Angel",
            "last_name": "Vera Martinez",
            "photo_url": "assets/66942d4f8bfb44d5aa674837235b08f7_ANGELVERA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "010f354f-4eef-44c3-9a2a-4726e7e31a9b"
        },
        {
          "id": 78307,
          "person": {
            "id": 77720,
            "first_name": "Angela",
            "last_name": "Basham",
            "photo_url": "assets/re1a0de86a4e3489fa529ef217cbc560a_Image_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2788,
              "name": "Swing",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0aeafe06-39c2-48ce-937b-eea97f84d396"
        },
        {
          "id": 74301,
          "person": {
            "id": 73759,
            "first_name": "Angela",
            "last_name": "Gomez",
            "photo_url": "assets/13dee2e7ef524e29ad13df1ae649cc29_ANGELAGOMEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3642a542-2d3c-4f75-b1c9-910bfcd128c8"
        },
        {
          "id": 74043,
          "person": {
            "id": 73502,
            "first_name": "Angela",
            "last_name": "Hernandez Gonzalez",
            "photo_url": "assets/7c19c8d41e6a486f9dc01a04727e017f_AngelaHernandez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc8538f9-5337-478e-acf5-d469732e2152"
        },
        {
          "id": 54223,
          "person": {
            "id": 53680,
            "first_name": "Angelica",
            "last_name": "Herrera",
            "photo_url": "assets/b6e2e02816c848c684afa95f930b2a31_AngelicaHerrera_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f409276-80a9-4890-a764-d2a7fc6cebd3"
        },
        {
          "id": 74159,
          "person": {
            "id": 73617,
            "first_name": "Angelica",
            "last_name": "Martinez",
            "photo_url": "assets/c9610fe7acdb4eac90d16514d6bc52cd_ANGELICAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6878b62d-1129-46a1-9f46-3ca963c62b66"
        },
        {
          "id": 81228,
          "person": {
            "id": 80596,
            "first_name": "Angelica",
            "last_name": "Martinez",
            "photo_url": "assets/r13fa4956fdad4f7e90f73dac8a061d34_Profilepic3_t.jpg",
            "phone_number": "(737) 329 - 9205",
            "email_address": "amartinez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "faaaddce-6923-47c1-828a-8adc2a52a42e"
        },
        {
          "id": 53722,
          "person": {
            "id": 53180,
            "first_name": "Angelina",
            "last_name": "Cardoso",
            "photo_url": "assets/d5c7f3c1c6434f0ebf5302a82a9e46ef_AngelinaCardosoPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3164,
              "name": "Swing",
              "contract": {
                "id": 2643,
                "name": "Lincoln South",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25836457-298f-489c-80c2-0a0e65a78181"
        },
        {
          "id": 74459,
          "person": {
            "id": 73917,
            "first_name": "Angelina",
            "last_name": "Sanchez Zepeda",
            "photo_url": "assets/ed66eb2098554557904aeed5e43ae4a8_ANGELINASANCHEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff808e13-f7e1-4f95-ac05-8800efd727bc"
        },
        {
          "id": 79196,
          "person": {
            "id": 78588,
            "first_name": "Anita",
            "last_name": "Macy",
            "photo_url": "assets/da9fbdc1c1324055bda8c01b779d3e38_AnitaMacy_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b480485c-37e1-42dd-9b5d-e686cb9650aa"
        },
        {
          "id": 79849,
          "person": {
            "id": 79229,
            "first_name": "Anthony",
            "last_name": "Cozart",
            "photo_url": "assets/536156232b5d4d5ca7d26c15f8a843ae_Anthony_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7196,
              "name": "Night",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-12-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a0ffb4c-417c-4e6d-9d55-43d51d41e3e3"
        },
        {
          "id": 73476,
          "person": {
            "id": 72936,
            "first_name": "Anthony",
            "last_name": "Latham",
            "photo_url": "assets/rb9198127a8af4fe699b36d04b167f882_anthony_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "581c1b57-2043-41ae-bf28-a3327f2bbbf4"
        },
        {
          "id": 72506,
          "person": {
            "id": 71967,
            "first_name": "Antoinette",
            "last_name": "Perry",
            "photo_url": "assets/1c9e4d2b065342308fd9f365c8e23d02_Antoinette_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "247fe47a-19d4-4a4b-923e-c56cf8836283"
        },
        {
          "id": 80593,
          "person": {
            "id": 79963,
            "first_name": "Anton",
            "last_name": "Buford",
            "photo_url": "assets/3a1c69bd44354d809c4990fdf0bed973_AnotonInsite_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aa5109c9-a05b-4abb-adde-9e3f7ff0613c"
        },
        {
          "id": 74183,
          "person": {
            "id": 73641,
            "first_name": "Apolonio",
            "last_name": "Rodriguez Morfin",
            "photo_url": "assets/5862da4d560542fea1900ec70983bb7d_APOLONINORODRIGUEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9818d8e7-25c2-4399-acb6-be3d56ca62ca"
        },
        {
          "id": 74326,
          "person": {
            "id": 73784,
            "first_name": "Aquilina",
            "last_name": "Pineda",
            "photo_url": "assets/066e5c9e30ef45b0aea2349996b1d225_AQUILIANAPINEDA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f88b5b1b-21ec-42d9-84d5-f42d271779e6"
        },
        {
          "id": 67899,
          "person": {
            "id": 67357,
            "first_name": "Araceli",
            "last_name": "Contreras Flores",
            "photo_url": "assets/7fc113743fe74258a82530a2e1c9b135_IMG_7187_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a8375406-3339-4c4c-aba0-5bdec4f98283"
        },
        {
          "id": 74177,
          "person": {
            "id": 73635,
            "first_name": "Araceli",
            "last_name": "Garcia",
            "photo_url": "assets/2a502b07b0634be3aaf0779ba2ffe046_IMG_4015_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "32a9d8b7-4093-4662-96e0-479733b796c2"
        },
        {
          "id": 70976,
          "person": {
            "id": 70439,
            "first_name": "Armando",
            "last_name": "Munguia",
            "photo_url": "assets/f4af2fa5406247e4b22ee422519139ed_ArmandoMunguiaChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6036fb5b-4363-4c2d-a0ab-885ef70a084d"
        },
        {
          "id": 75465,
          "person": {
            "id": 74930,
            "first_name": "Asdrubal",
            "last_name": "Tabares",
            "photo_url": "assets/2dd8eee8e3f64944a38dd2441f9a8613_7_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "16ae5623-21ec-4d2b-9802-a730f108f1f4"
        },
        {
          "id": 74395,
          "person": {
            "id": 73853,
            "first_name": "Aura",
            "last_name": "Hernandez Argueta",
            "photo_url": "assets/b65959fed5504459b1e22a9bf4154a47_AURAH_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75771438-767c-4b05-8f71-4dbc7a93c014"
        },
        {
          "id": 74135,
          "person": {
            "id": 73593,
            "first_name": "Aura",
            "last_name": "Lopez",
            "photo_url": "assets/4b491e3cd1af4fce86d97c8e0f2d7bd4_AURALOPEZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9223b543-2246-44ff-8930-0c2bf4a4dd1f"
        },
        {
          "id": 71115,
          "person": {
            "id": 70578,
            "first_name": "Aurea",
            "last_name": "Cespedes Ortiz",
            "photo_url": "assets/1c766f521ab44a3ebe167bc5e70668e9_thumbnail_IMG_20220504_105919_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d125c9a-1285-4614-9703-935f47ad7934"
        },
        {
          "id": 76064,
          "person": {
            "id": 75523,
            "first_name": "Avyahna",
            "last_name": "Fields",
            "photo_url": "assets/9e6b434593754177af62c07631fe1065_20220907_130739_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2841,
              "name": "Day",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06aa6a28-a1dd-4f59-9ed3-858d9f220426"
        },
        {
          "id": 81108,
          "person": {
            "id": 80477,
            "first_name": "Ayawavi",
            "last_name": "Adedje",
            "photo_url": "assets/14d218cff79b4dbd907e4feea1aac7c8_AyawaviAdedjeHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "711bfb74-9e0d-4140-8a5a-d935f8472847"
        },
        {
          "id": 55066,
          "person": {
            "id": 54520,
            "first_name": "Ayda",
            "last_name": "Henao",
            "photo_url": "assets/rbf65fd913c0049779749988a14210557_E4E3DA0E3F9545089BBCBE6412EFE317_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3eb549b-7db0-48ef-84e4-512321e56318"
        },
        {
          "id": 46151,
          "person": {
            "id": 45689,
            "first_name": "Ayelech",
            "last_name": "Gurara",
            "photo_url": "assets/44f75f89abb44f1d8dde166ff7fdce81_IMG_20211014_155529_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb5af99d-c315-4ac4-b2d6-9f3e15c9d6f4"
        },
        {
          "id": 51007,
          "person": {
            "id": 50499,
            "first_name": "Barbara",
            "last_name": "Tamayo Banos",
            "photo_url": "assets/8cd44c8e1ef3416f989da0b0f0a6ad65_20210719_131816banosbadge_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57f50b31-9a04-4c62-a6ed-0e68d99df588"
        },
        {
          "id": 52060,
          "person": {
            "id": 51536,
            "first_name": "Beatriz",
            "last_name": "Monge Landaverde",
            "photo_url": "assets/r9fa18b30b9f844a3a76a158da757847b_Beatriz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5062,
              "name": "Night",
              "contract": {
                "id": 1766,
                "name": "Pleasanton",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "84e997e7-0972-4fe0-8dae-4f68bb8008b4"
        },
        {
          "id": 79554,
          "person": {
            "id": 78937,
            "first_name": "Belkis M",
            "last_name": "Rudolfo Carrillo",
            "photo_url": "assets/ef748b81da694898b30f082ecfdd4b03_Belkis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3000,
              "name": "Swing",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "825c639f-d879-4c9d-8aea-75e8e99b6508"
        },
        {
          "id": 74341,
          "person": {
            "id": 73799,
            "first_name": "Benjamin",
            "last_name": "Guizar Hernandez",
            "photo_url": "assets/r21b834451e454836a47c39742087c0e2_IMG_0047_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "44795d18-9596-4c5c-bf6e-4deb70d5251f"
        },
        {
          "id": 54282,
          "person": {
            "id": 53738,
            "first_name": "Berenice",
            "last_name": "Abarca Diaz",
            "photo_url": "assets/r664d0d00e853404f9ac9cb55bcf67de4_BereniceAbarca_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d86c4f3-688b-4063-bf73-f04b6d8e43c2"
        },
        {
          "id": 80919,
          "person": {
            "id": 80289,
            "first_name": "Bernard",
            "last_name": "Adams",
            "photo_url": "assets/431a3863fbfc49d18709f8c85a74ece3_AdamsBernard251434image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e168ec1-cf3c-4ed9-835d-feb5c70c14d6"
        },
        {
          "id": 64873,
          "person": {
            "id": 64331,
            "first_name": "Bernardo",
            "last_name": "Agustin",
            "photo_url": "assets/66f99e8f31774d019943f7fb40dbd22e_20220202_061622_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c5b4ffb-b1ad-403d-8d44-6aff1f30b2b8"
        },
        {
          "id": 74283,
          "person": {
            "id": 73741,
            "first_name": "Bertha",
            "last_name": "Millan",
            "photo_url": "assets/a2b40054d61e457d963d1144a6b3b3f8_berthamilan_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49c054c8-6aac-4acb-884e-0d1d5ed8d5cf"
        },
        {
          "id": 72660,
          "person": {
            "id": 72122,
            "first_name": "Bertha",
            "last_name": "Tafoya Flores",
            "photo_url": "assets/9247d6a23460492786d75a828951fbf2_Bertha_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "579e32e6-9d87-4980-a048-bf6a050000f7"
        },
        {
          "id": 74046,
          "person": {
            "id": 73505,
            "first_name": "Blanca",
            "last_name": "Berganza",
            "photo_url": "assets/8474768affb1473692ec28ccfbe1f4da_BlancaBerganza_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2ce76e3a-1f54-45d0-9d74-1cb6ff84b989"
        },
        {
          "id": 74294,
          "person": {
            "id": 73752,
            "first_name": "Blanca",
            "last_name": "Bermudez",
            "photo_url": "assets/93503686351e4c1d88a0da03998acc16_BLANCAB_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25046d7f-027f-49bd-bffb-f9b38968a18a"
        },
        {
          "id": 74281,
          "person": {
            "id": 73739,
            "first_name": "Blanca",
            "last_name": "Merlos Morales",
            "photo_url": "assets/2a215fd378ce42bb9bf2cab3bf239d2f_IMG_6104_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1a28635-6206-49f1-a8a1-9d5431b0c956"
        },
        {
          "id": 52768,
          "person": {
            "id": 52230,
            "first_name": "Blanca",
            "last_name": "Munoz De Zuniga",
            "photo_url": "assets/b5ce00a5290b4a72a5849165776bd836_81274920d9314eda92d61ef589105a32_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3083,
              "name": "Swing",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43cf9112-a1c4-4360-bd8d-dae600e1ddb6"
        },
        {
          "id": 75466,
          "person": {
            "id": 74931,
            "first_name": "Blanca",
            "last_name": "Tejeda",
            "photo_url": "assets/850ccab724194ced96513ae9f63bcc1c_8_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "187d5b18-9942-464f-9021-ccb2f221d476"
        },
        {
          "id": 50047,
          "person": {
            "id": 49548,
            "first_name": "Blanca",
            "last_name": "Turcios Galvez",
            "photo_url": "assets/r9bc34616d7e94f6294c2d487585735f1_20210728_170750_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-07-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b0eaea0-9b90-458a-9348-d9eb8940f33a"
        },
        {
          "id": 74208,
          "person": {
            "id": 73666,
            "first_name": "Bonnie",
            "last_name": "Chan",
            "photo_url": "assets/6d0b5281c6124771bce976d76d452970_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42d23f16-6457-4456-8ca5-095b24071c5d"
        },
        {
          "id": 48388,
          "person": {
            "id": 47897,
            "first_name": "Brandon",
            "last_name": "Krause",
            "photo_url": "assets/d4e61288b1cf406ca772ec7c4101e26a_HeadshotBrandon1_t.jpg",
            "phone_number": "2678940077",
            "email_address": "bkrause@sbmcorp.com"
          },
          "positions": [
            {
              "id": 94,
              "name": "Proj Mgr",
              "name_abbreviation": "PRJMGR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f3b2a6a5-af60-4d05-9f9b-d7a8e9e62565"
        },
        {
          "id": 75039,
          "person": {
            "id": 74493,
            "first_name": "Brandon",
            "last_name": "Omorogieva",
            "photo_url": "assets/0729874a477e4e68a2a076639a6ed365_StandardMale_t.png",
            "phone_number": "",
            "email_address": "248166@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3082,
              "name": "Day",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c9c447da-fbc7-4e5e-896c-4920c77ef24f"
        },
        {
          "id": 19155,
          "person": {
            "id": 19279,
            "first_name": "Brenda",
            "last_name": "Romero Amaya",
            "photo_url": "assets/1579640407.167941_BrendaRomero_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "08376e7f-ce18-4a66-9b0c-f9d46aa8d40c"
        },
        {
          "id": 69067,
          "person": {
            "id": 68532,
            "first_name": "Brian",
            "last_name": "Keenan",
            "photo_url": "assets/4b782a8f6c534936ab6208114b769b88_20220330_081326_t.jpg",
            "phone_number": "4128578876",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4aa05447-515e-41ef-adcd-d276acec75fe"
        },
        {
          "id": 78311,
          "person": {
            "id": 77724,
            "first_name": "Brian",
            "last_name": "Thompkins",
            "photo_url": "assets/79db8f5217f84b63bee4ea080306dcc4_Brian_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "950492b1-3087-4ef2-a522-7e863ef3d775"
        },
        {
          "id": 42026,
          "person": {
            "id": 41616,
            "first_name": "Bridgette",
            "last_name": "Lewis",
            "photo_url": "assets/1604509629.9173334_BLewispicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80d23115-5f45-4fd0-9aa7-5112f0b7918a"
        },
        {
          "id": 70966,
          "person": {
            "id": 70429,
            "first_name": "Briseida",
            "last_name": "Chavez",
            "photo_url": "assets/1e8bac42e67c49a68e3983b58f5be465_BriseidaChavezChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd4e69ce-5466-4faa-af6b-d9e32ad9a621"
        },
        {
          "id": 69600,
          "person": {
            "id": 69065,
            "first_name": "Brittany",
            "last_name": "Jones",
            "photo_url": "assets/7451a0a2a8bb49bfb8e60e3349175c07_BrittanyJones_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "73b0e254-7def-45dd-8290-c03b349679ad"
        },
        {
          "id": 74976,
          "person": {
            "id": 74432,
            "first_name": "Bryan",
            "last_name": "Gonzales",
            "photo_url": "assets/599b2a917466487581bd53b2ab6ac09d_BryanGonzalez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60786803-c5fc-4aaa-ad4b-3d69a78a63b4"
        },
        {
          "id": 76344,
          "person": {
            "id": 75776,
            "first_name": "Byron",
            "last_name": "De Leon",
            "photo_url": "assets/r5790a7db59de413aa7d3b46204e43803_FullSizeR_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7501,
              "name": "Night",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a872859a-8182-4f11-bed9-0dc4021d0f5c"
        },
        {
          "id": 47031,
          "person": {
            "id": 46546,
            "first_name": "Cameron",
            "last_name": "Drazic",
            "photo_url": "assets/358cc327eccc49cdb7f8a2ec6a0925dc_CDINSITE_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "177083b0-9e20-4fc1-a06d-0c3779a0862b"
        },
        {
          "id": 71629,
          "person": {
            "id": 71094,
            "first_name": "Camila",
            "last_name": "Morales",
            "photo_url": "assets/5421c09174b74395b45628aa4c76e348_thumbnail_IMG_20220722_165419_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4cda22ec-e823-4fc8-ac9c-f4d0a98a3e0f"
        },
        {
          "id": 76650,
          "person": {
            "id": 76082,
            "first_name": "Candelaria",
            "last_name": "Alvarez",
            "photo_url": "assets/f2126eaa685a44889b73b07eb938d05a_cande_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca66c16f-9306-4029-8bf9-23ed14a4b964"
        },
        {
          "id": 51179,
          "person": {
            "id": 50665,
            "first_name": "Candelaria",
            "last_name": "Horta Corona",
            "photo_url": "assets/43c7cc727f3c4cdd86255970dc13d5e0_CandelariaCoronaHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3038,
              "name": "Swing",
              "contract": {
                "id": 2590,
                "name": "Laguna South, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "abb421e8-0707-42f0-b247-657048924721",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aacc71f0-cbc2-4b7e-9818-1c5b90c8f620"
        },
        {
          "id": 53705,
          "person": {
            "id": 53163,
            "first_name": "Candida",
            "last_name": "Perdomo Recio",
            "photo_url": "assets/7251ad65bbc543bcb88667b3c47b8aab_CandidaPicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ced3963b-cdde-4e3c-864b-688fc06aea55"
        },
        {
          "id": 47226,
          "person": {
            "id": 46741,
            "first_name": "Carl Jason",
            "last_name": "Gonzales",
            "photo_url": "assets/1618934340.813372_CarlGonzalesHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5ec80c0-d563-45d8-a11e-d84a8b14e4f6"
        },
        {
          "id": 78033,
          "person": {
            "id": 77448,
            "first_name": "Carlos",
            "last_name": "Castaneda",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49d8b2ee-50df-4176-8050-78f2c8a1e112"
        },
        {
          "id": 54483,
          "person": {
            "id": 53936,
            "first_name": "Carlos",
            "last_name": "Pereyra",
            "photo_url": "assets/5adbe5fe05314405b7420a131d1141a1_CarlosPereyra_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3135,
              "name": "Swing",
              "contract": {
                "id": 2640,
                "name": "Bethlehem",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "7dd14439-d031-4edb-80a1-d29ebbbae2e6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b449b3d8-6f05-4ad7-bfdf-f7cc16793772"
        },
        {
          "id": 74233,
          "person": {
            "id": 73691,
            "first_name": "Carlos",
            "last_name": "Rodriguez Herrera",
            "photo_url": "assets/e9ac604f8d3a480fa64766a4b551c936_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fa788438-f5ba-4c25-bb35-62d29d8a2f97"
        },
        {
          "id": 54269,
          "person": {
            "id": 53725,
            "first_name": "Carmela",
            "last_name": "Garcia Hernandez",
            "photo_url": "assets/e845da35213c4a008675ea4e4e49a646_Photo1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b375ed95-3fb7-44c8-9732-9afd4bdeb94e"
        },
        {
          "id": 81126,
          "person": {
            "id": 80495,
            "first_name": "Carmen",
            "last_name": "Mendez",
            "photo_url": "assets/c5aa113a4bdf449099d40025215acd2b_CarmenMendezHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "768f57c1-0cf4-4060-b865-3f5788e5ee33"
        },
        {
          "id": 75473,
          "person": {
            "id": 74938,
            "first_name": "Caro",
            "last_name": "Ruiz De Aguilar",
            "photo_url": "assets/4e9917a42fd748799090e5b92193b8ad_9_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f61f2503-0b88-47a2-a821-24ed193a8ba3"
        },
        {
          "id": 52154,
          "person": {
            "id": 51629,
            "first_name": "Carol",
            "last_name": "Doucette",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3098,
              "name": "Day",
              "contract": {
                "id": 2624,
                "name": "Malta, NY",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "eb1902d2-9a86-4850-bb32-1404cefdba7d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83663352-2c94-4ce4-a8c9-fd8c00712f5b"
        },
        {
          "id": 74181,
          "person": {
            "id": 73639,
            "first_name": "Carol",
            "last_name": "Tafur",
            "photo_url": "assets/028968aeca8f4bb58da8effc19c56ac1_CAROLTAFUR_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc50e355-361e-4940-a10f-9138bf0e60eb"
        },
        {
          "id": 48109,
          "person": {
            "id": 47621,
            "first_name": "Carolina",
            "last_name": "Morales Palomares",
            "photo_url": "assets/ea3a95979ce74b0697c68fd6bb7c78ae_IMG_20221104_45712_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da7a931f-1fb4-40df-a831-c7c0f0c1feac"
        },
        {
          "id": 75505,
          "person": {
            "id": 74970,
            "first_name": "Carolyn",
            "last_name": "Greiman",
            "photo_url": "assets/ae9b11d41a834c9981d46aa1e8fe484c_IMG_1233_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e399938-ee0e-46a1-8abc-f170e2694a8b"
        },
        {
          "id": 55357,
          "person": {
            "id": 54809,
            "first_name": "Catherine",
            "last_name": "Wiseman",
            "photo_url": "assets/9ee70832ab44406b9707a4bda77482b7_CAthy_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7224,
              "name": "On-Call",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc289a1c-e7a5-4ee0-be59-6daf2084ad4d"
        },
        {
          "id": 74126,
          "person": {
            "id": 73584,
            "first_name": "Cecilia",
            "last_name": "Silva Tovar",
            "photo_url": "assets/9f23ad825c5e46ebb0b252ca59877225_CECILIA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf668186-ac45-4543-ab02-5e6801134c33"
        },
        {
          "id": 78043,
          "person": {
            "id": 77458,
            "first_name": "Cedric",
            "last_name": "Boatwright",
            "photo_url": "assets/r115b981798be46348913f9db7c50fb64_41A977A80B3A49EC81A54384719C6495_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f6ee4843-da6f-4b08-9eac-b2dd91babd20"
        },
        {
          "id": 74306,
          "person": {
            "id": 73764,
            "first_name": "Celia",
            "last_name": "Bucio Calderon",
            "photo_url": "assets/9ede9d97c9c149d8ae571f0fe0ad3b75_BUCIOC_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "063a11dc-12a2-4a03-9764-ff5cb1738c09"
        },
        {
          "id": 74053,
          "person": {
            "id": 73512,
            "first_name": "Cesar",
            "last_name": "Gonzalez",
            "photo_url": "assets/27abb88e72cd4d929042880d4ff18964_CesarGonzalezTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b4826f1d-4c41-4dc2-b754-f03b8f41b2e3"
        },
        {
          "id": 54853,
          "person": {
            "id": 54308,
            "first_name": "Channy",
            "last_name": "Chea",
            "photo_url": "assets/7af0f3ba519f412592e60c0b22d89688_Channy_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d8ffec7-9858-419f-8565-1de5fab773fc"
        },
        {
          "id": 72413,
          "person": {
            "id": 71874,
            "first_name": "Charles",
            "last_name": "Jurbala",
            "photo_url": "assets/c59974d78ba943b99822bad6a326f0bb_imageCJ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7224,
              "name": "On-Call",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7587321e-0c86-4dd6-8300-9eb71e8a5837"
        },
        {
          "id": 68883,
          "person": {
            "id": 68345,
            "first_name": "Chasity",
            "last_name": "Herald",
            "photo_url": "assets/4ecb8a3b01744ca69c58f72f36a97cc9_20220309_061731_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89fee892-6c8b-44e8-a4db-54449395aaff"
        },
        {
          "id": 37428,
          "person": {
            "id": 37147,
            "first_name": "Cheyenne",
            "last_name": "Brothwell",
            "photo_url": "assets/1592255451.9075634_che_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95fd4426-958c-4884-9888-161a448ea429"
        },
        {
          "id": 81119,
          "person": {
            "id": 80488,
            "first_name": "Chico",
            "last_name": "Lewis",
            "photo_url": "assets/9bcadf55bf9e4b4893519031caee10b2_ChicoLewisCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae1aea81-0ee5-4ec5-9eee-42471a6d53c6"
        },
        {
          "id": 68352,
          "person": {
            "id": 67809,
            "first_name": "Chimaobi",
            "last_name": "Emenike",
            "photo_url": "assets/4e9ac1fdd774469a83831fa5b1fc5b34_20220315_180953_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-02-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b72c07f9-dd8e-4993-873e-feff2e4c6d23"
        },
        {
          "id": 81243,
          "person": {
            "id": 80611,
            "first_name": "Chirag",
            "last_name": "Patel",
            "photo_url": "assets/4620dec9f528488f895742697163bfde_IMG_0674Chirag_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e09d3e6-bc12-46a6-addb-64bbb5aec000"
        },
        {
          "id": 55078,
          "person": {
            "id": 54532,
            "first_name": "Chitara",
            "last_name": "Purefoy",
            "photo_url": "assets/r8eac2d26be94487996a23e46c0ebcc3d_2930D4F17F48419DBD5712F6C2B24686_t.jpeg",
            "phone_number": "9196371143",
            "email_address": "cpurefoy@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7890,
              "name": "Day",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de68fa35-aa01-4215-951b-a0588b867fd5"
        },
        {
          "id": 74225,
          "person": {
            "id": 73683,
            "first_name": "Chit Na",
            "last_name": "Chong",
            "photo_url": "assets/a2f6d2a9e11543bdbfec0f47a5deb2a3_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bcf4d095-e8db-4fcc-ba1f-083bad0f4110"
        },
        {
          "id": 81350,
          "person": {
            "id": 80719,
            "first_name": "Christian",
            "last_name": "Morales",
            "photo_url": "assets/811c3dd32b4247afb194dd49aa7d62cb_Portrait1_t.jpg",
            "phone_number": "+19195039041",
            "email_address": ""
          },
          "positions": [
            {
              "id": 41,
              "name": "Asst Mgr, Operations",
              "name_abbreviation": "AOPMGR"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "812457ed-c89f-4e8f-87ab-396012561c7f"
        },
        {
          "id": 74191,
          "person": {
            "id": 73649,
            "first_name": "Christian",
            "last_name": "Pavon",
            "photo_url": "assets/c5ff59fe463d43f3b97372cf133313b3_CHRISTIANPAVON_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fccd7705-3950-45df-80ac-8faee0a03414"
        },
        {
          "id": 47325,
          "person": {
            "id": 46839,
            "first_name": "Christian",
            "last_name": "Renteria Vera",
            "photo_url": "assets/1618934575.58497_ChristianVeraHS_t.jpg",
            "phone_number": "4253229567",
            "email_address": "crenteria@sbmcorp.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f93ff05-b1c7-4ac6-be17-3ef575164097"
        },
        {
          "id": 74547,
          "person": {
            "id": 74005,
            "first_name": "Christina",
            "last_name": "Cooper",
            "photo_url": "assets/9522c4b9e80346a287e076ba6aa507d7_IMG_3731_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd8e8a46-bb44-45ad-8f65-5042a2c2f0ee"
        },
        {
          "id": 67385,
          "person": {
            "id": 66843,
            "first_name": "Christine",
            "last_name": "Volante",
            "photo_url": "assets/rd6029ba50b7240e5981a4aa2bea093ff_chrissyheadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3112,
              "name": "Day",
              "contract": {
                "id": 2633,
                "name": "Concordville, PA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a8cd9ab4-2ac2-4578-9be8-580d5f624338"
        },
        {
          "id": 68228,
          "person": {
            "id": 67685,
            "first_name": "Ciara",
            "last_name": "Whitley",
            "photo_url": "assets/59555638186b457bac0d53db7043d1bb_CiaraWhitley_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7990,
              "name": "Day",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "973922e9-6f14-4b90-8e59-0a13aab03fda"
        },
        {
          "id": 81330,
          "person": {
            "id": 80699,
            "first_name": "Cindy",
            "last_name": "Galaviz Valdez",
            "photo_url": "assets/r054128e8d5894b5297f7df87d838958e_image0000008_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1882b784-5bd5-4860-81c3-5262e638cfc4"
        },
        {
          "id": 74412,
          "person": {
            "id": 73870,
            "first_name": "Claudia",
            "last_name": "Salazar",
            "photo_url": "assets/d4342fbccb62445cb2b92d21bb8cd769_CLAUDIASALAZAR_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c4257ab1-19b0-4ecc-9d7e-18fb8b9ed24c"
        },
        {
          "id": 55261,
          "person": {
            "id": 54714,
            "first_name": "Clifton",
            "last_name": "Rhodes",
            "photo_url": "assets/re739ddb8d59941cda7b03b6bfe38943c_Cliftonpic_t.jpeg",
            "phone_number": "6106077221",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2936,
              "name": "Day",
              "contract": {
                "id": 2163,
                "name": "Harleysville, PA",
                "customer_id": 311,
                "customer_name": "Siemens (CBRE)",
                "uuid": "9e1ba37b-3af4-446a-9c9b-ac7e4baa4825",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3be2e4af-7c0a-4173-9bd9-297244541a54"
        },
        {
          "id": 70693,
          "person": {
            "id": 70157,
            "first_name": "Conrad",
            "last_name": "Smith",
            "photo_url": "assets/10e25665585a4e93ae725b0c9e7300c5_20220429_111408_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2599,
              "name": "Swing",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2bff5dec-2687-41e4-8710-160b9c7d6154"
        },
        {
          "id": 69146,
          "person": {
            "id": 68611,
            "first_name": "Conrado",
            "last_name": "Paredes Ponce",
            "photo_url": "assets/r594bbe6a515d4b3bbc5e6b8d9211898a_MicrosoftTeamsimage6_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a94e0db-54e1-4fec-9eec-d938e1eaf4b7"
        },
        {
          "id": 74157,
          "person": {
            "id": 73615,
            "first_name": "Consuelo",
            "last_name": "Martinez Gonzalez",
            "photo_url": "assets/13016bda8fab4f66842094d94c129048_IMG_1292_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90bac56d-512a-487c-9aeb-b46733c81802"
        },
        {
          "id": 2461,
          "person": {
            "id": 2461,
            "first_name": "Coretta",
            "last_name": "Bell",
            "photo_url": "assets/1588000180.9440286_20190220_141812_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f0b7c2a-caa3-462d-b66e-c1d58d8069b0"
        },
        {
          "id": 78460,
          "person": {
            "id": 77872,
            "first_name": "Courtney",
            "last_name": "Shaw",
            "photo_url": "assets/9524ca3a392148b9a585f2907e7e27b3_Courtney_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37692f37-68ed-45d4-880f-ea2e1f24c8e4"
        },
        {
          "id": 71123,
          "person": {
            "id": 70586,
            "first_name": "Craig",
            "last_name": "Johnson",
            "photo_url": "assets/a6593fc7af0a460d85028ced9c6b90b8_Craig_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3144,
              "name": "Swing",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a9232ac-65c3-4790-8e28-2728ffe0de28"
        },
        {
          "id": 74305,
          "person": {
            "id": 73763,
            "first_name": "Cristian",
            "last_name": "Torres Flores",
            "photo_url": "assets/7b3667a46adf4e18b54163b3a8d16aeb_CRISTIANTORRES_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "52906e39-86bb-4387-bafe-d128987a1123"
        },
        {
          "id": 74309,
          "person": {
            "id": 73767,
            "first_name": "Cynthia",
            "last_name": "Molina",
            "photo_url": "assets/8f2ecbca7573429f93df72466b6dacbf_IMG_6159_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6626,
              "name": "Night",
              "contract": {
                "id": 3994,
                "name": "Chilco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5841c33e-ca16-463f-a942-72e68d03610c"
        },
        {
          "id": 74278,
          "person": {
            "id": 73736,
            "first_name": "Cynthia",
            "last_name": "Velasquez Reyes",
            "photo_url": "assets/d6d6f16a9fe64092b7aa685223ebbabd_CynthiaPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6623,
              "name": "Day",
              "contract": {
                "id": 3994,
                "name": "Chilco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "527fd53d-48d7-449e-97d0-8f3a55982689"
        },
        {
          "id": 81303,
          "person": {
            "id": 80672,
            "first_name": "Dahir",
            "last_name": "Ali",
            "photo_url": "assets/930f06afbea1496b86a1a4f454a184b2_DiriyeAli2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0f8fdf8-ec22-448a-9d16-53ce30fae0db"
        },
        {
          "id": 54141,
          "person": {
            "id": 53598,
            "first_name": "Daisy",
            "last_name": "Cruz",
            "photo_url": "assets/40b569b538414a2cbb0a6a5137b3876f_DaisyCruz_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb35bc43-4c7a-40d3-a007-05a313fb63a4"
        },
        {
          "id": 75467,
          "person": {
            "id": 74932,
            "first_name": "Dalcia",
            "last_name": "De La Cruz Mejia",
            "photo_url": "assets/5393374bd2d941838d1daa725ed7284f_10_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "017c35e1-d20f-43c2-a19f-fbe55bae9977"
        },
        {
          "id": 72767,
          "person": {
            "id": 72230,
            "first_name": "Damaris",
            "last_name": "Moreno",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "02c287e5-1e1c-46aa-a6b9-2b4ee6bdeb80"
        },
        {
          "id": 77207,
          "person": {
            "id": 76630,
            "first_name": "Damian",
            "last_name": "Cowan",
            "photo_url": "assets/86cd1eb23d5f4023bfb038b6d0b73b29_ProfilePicture_t.jpg",
            "phone_number": "3148098173",
            "email_address": "dcowan@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2104,
              "name": "Day",
              "contract": {
                "id": 1324,
                "name": "Illinois",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7437,
              "name": "None",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "78adf478-4bd2-4ffd-909a-cbe15604b46f"
        },
        {
          "id": 73943,
          "person": {
            "id": 73403,
            "first_name": "Daniel",
            "last_name": "Hilliard",
            "photo_url": "assets/4557897adce0453c9c486c7d06b3af5e_20220817_105125_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45adf8d4-9e16-4e15-8248-c7433c1462c7"
        },
        {
          "id": 73106,
          "person": {
            "id": 72568,
            "first_name": "Daniel",
            "last_name": "Munoz",
            "photo_url": "assets/r21b7dea7c45441ac8fb3a84d889ac968_20220728_085216_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5701,
              "name": "Day",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e3423e09-81b7-45ce-b5d1-708b5dc6cfc1"
        },
        {
          "id": 74124,
          "person": {
            "id": 73582,
            "first_name": "Daniel",
            "last_name": "Ortega Sim",
            "photo_url": "assets/dd3514beeafc4617b36bb08571e8b362_DANIELS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8912ef6b-82f5-4d82-ad2d-eeb9cecda5b7"
        },
        {
          "id": 54279,
          "person": {
            "id": 53735,
            "first_name": "Daniela",
            "last_name": "Martinez Mendez",
            "photo_url": "assets/ee67e380ce204c1fa1c1266763f7b055_DanielaMendez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49f9b4c3-c6da-4100-a93d-d8d4daedc964"
        },
        {
          "id": 54187,
          "person": {
            "id": 53644,
            "first_name": "Danieli",
            "last_name": "Garcia Pichardo",
            "photo_url": "assets/c160344c678c4ff9bd91c8104d3044d7_DanielleGarcia_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "68622964-cbf1-4cb0-a9ee-66f027a88f31"
        },
        {
          "id": 73503,
          "person": {
            "id": 72963,
            "first_name": "Dariana",
            "last_name": "Vilorio",
            "photo_url": "assets/f87a810c25ac48c9bb2247ce5758c280_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5868,
              "name": "Day",
              "contract": {
                "id": 4124,
                "name": "Exton",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "f7086bec-946e-4b69-9337-bc15669afd06",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c16f419b-05ca-448c-9745-da45b736f430"
        },
        {
          "id": 77938,
          "person": {
            "id": 77354,
            "first_name": "Darieon",
            "last_name": "Kornegay",
            "photo_url": "assets/rb54cfdacd57447d89eace479e391802f_333FAE3F6D654CA29D7B7C2DF75DA0CA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e03bc96d-bbbc-457c-9afe-4b8bc5833acc"
        },
        {
          "id": 76839,
          "person": {
            "id": 76264,
            "first_name": "Darrin",
            "last_name": "Jones",
            "photo_url": "assets/495d7f3845c5409a93fac09486ffc7a8_DarrinsInsitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b4b65b3-83e1-44a4-92b6-91c4bf56e8c9"
        },
        {
          "id": 74852,
          "person": {
            "id": 74308,
            "first_name": "Darryl",
            "last_name": "Fultcher",
            "photo_url": "assets/ec180ddc59c54d4b8cd491c337f50852_DarrylFultcher_t.jpg",
            "phone_number": "6502083979",
            "email_address": "dfultcher@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2ee70e0a-a272-4e06-ad06-3abc4c2b9f43"
        },
        {
          "id": 71749,
          "person": {
            "id": 71214,
            "first_name": "Daviana",
            "last_name": "Avalo-Quezada",
            "photo_url": "assets/9492f6f70c2c4bc99c2ebed45c36f7f5_D_t.jpg",
            "phone_number": "6172514725",
            "email_address": "dquezada@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1174,
              "name": "Mgr, GMP",
              "name_abbreviation": "GMPMGR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34e16e43-eb34-4608-a131-9950de94aaa1"
        },
        {
          "id": 53807,
          "person": {
            "id": 53264,
            "first_name": "David",
            "last_name": "Allen",
            "photo_url": "assets/e6867c57bf094e23a325b01ff5528e48_333333333_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "00244061-10e4-416e-a20d-f4d6e8621fe2"
        },
        {
          "id": 37750,
          "person": {
            "id": 37465,
            "first_name": "David",
            "last_name": "Cornelious",
            "photo_url": "assets/1592256869.5443797_DJR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd995e91-f992-44eb-90de-56a4c30aefc1"
        },
        {
          "id": 69573,
          "person": {
            "id": 69038,
            "first_name": "David",
            "last_name": "Hall",
            "photo_url": "assets/c94d3e6ce3024937b3ed1651688fb36b_DHallPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5037,
              "name": "Swing",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5f2d1406-d9c3-477b-b8b5-b40366295c75"
        },
        {
          "id": 46075,
          "person": {
            "id": 45615,
            "first_name": "David",
            "last_name": "Hull",
            "photo_url": "assets/c05cce826bd94b879ed2a72cd79dcefa_DavidHull_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f422a50b-6853-4f55-8cb1-6b5c318043a9"
        },
        {
          "id": 73942,
          "person": {
            "id": 73402,
            "first_name": "David",
            "last_name": "Lade",
            "photo_url": "assets/9d1577a23b1a4f2ca70e14e85f47eaa9_Snapchat250712931_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2375,
              "name": "Swing",
              "contract": {
                "id": 2162,
                "name": "Manteno, IL",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27d456a9-3421-4792-b70a-323f5ca6c998"
        },
        {
          "id": 49617,
          "person": {
            "id": 49117,
            "first_name": "David",
            "last_name": "Perry",
            "photo_url": "assets/c5c932f7810a4c998c4b11a3a77cc3f9_DavidPerry_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 953,
              "name": "Night",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-06-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1c885712-0556-445b-ba51-86b258aa3866"
        },
        {
          "id": 71248,
          "person": {
            "id": 70714,
            "first_name": "Deandre",
            "last_name": "Lane",
            "photo_url": "assets/3706edf1e65b475da08acb7619120bdf_DeAndreLanepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-05-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4cb9db81-3179-4833-aca3-df79e576b7d3"
        },
        {
          "id": 49842,
          "person": {
            "id": 49343,
            "first_name": "Debra",
            "last_name": "Ford",
            "photo_url": "assets/17e3243beed64e779cc52d53a8706e23_thumbnail_20210727_15102111111_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2991,
              "name": "Swing",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ffc264f0-6505-4fd5-a070-db36f29ebc81"
        },
        {
          "id": 74219,
          "person": {
            "id": 73677,
            "first_name": "Denia",
            "last_name": "Rodriguez Herrera",
            "photo_url": "assets/545b17e511c842b6a7cf2edd78f5c085_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fac60ab0-8c48-41c0-901b-d0e160a0076a"
        },
        {
          "id": 54075,
          "person": {
            "id": 53532,
            "first_name": "Deqa",
            "last_name": "Ibrahim",
            "photo_url": "assets/fc771ac50d3d436eb23091d9a764a826_deqaphoto_t.jpg",
            "phone_number": "",
            "email_address": "dibrahim@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0e67533-f0de-46fa-9360-10c2e0ca6442"
        },
        {
          "id": 51892,
          "person": {
            "id": 51369,
            "first_name": "Derek",
            "last_name": "Lambus",
            "photo_url": "assets/a4f9143886b34612bc85f10af639397c_DerekLambus_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4620736e-4acf-491b-8382-e1f350373c43"
        },
        {
          "id": 81308,
          "person": {
            "id": 80677,
            "first_name": "Destiny",
            "last_name": "Maldonado",
            "photo_url": "assets/0b1d46359ba545b78f2baab86015eca4_DestinyMaldonadoPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e3cad7fc-477e-43cb-bce9-10ea3cba0701"
        },
        {
          "id": 80918,
          "person": {
            "id": 80288,
            "first_name": "Destiny",
            "last_name": "Snyder",
            "photo_url": "assets/b167a6715f47497098436c1e24555a53_SnyderDestiny251433image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7777e33e-bad3-41fc-9d24-cfbb2b68432d"
        },
        {
          "id": 81127,
          "person": {
            "id": 80496,
            "first_name": "Diamant",
            "last_name": "Ndalla",
            "photo_url": "assets/a14a6c5805a948d1868927f5f593dbdb_DiamantNdallaHQ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba09ed22-1eca-4210-b397-4b1f69bf12d0"
        },
        {
          "id": 47338,
          "person": {
            "id": 46852,
            "first_name": "Diana",
            "last_name": "Sanchez",
            "photo_url": "assets/1618921614.85877_DianaSanchez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72a1f7dc-f390-4b17-bab4-139f4c6dd02a"
        },
        {
          "id": 67292,
          "person": {
            "id": 66750,
            "first_name": "Dianna",
            "last_name": "Carr",
            "photo_url": "assets/92ba73fb81de424dadb2057e722dda3d_insitepic_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0be14a5b-3bc1-47e1-8d07-43abc32dd602"
        },
        {
          "id": 69720,
          "person": {
            "id": 69185,
            "first_name": "Dianne",
            "last_name": "Ponte",
            "photo_url": "assets/r972920a3d96b41ac83ec2fa67fbd75b6_Resized_20220519_1314001_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9777ca8f-ec8b-4819-a542-3363214b25d4"
        },
        {
          "id": 74493,
          "person": {
            "id": 73951,
            "first_name": "Diany Julissa",
            "last_name": "Sanchez",
            "photo_url": "assets/1c7d9bc2f65b4c47a9d12393d9f052cd_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c46a567-2216-4768-814f-627c8cab413c"
        },
        {
          "id": 75468,
          "person": {
            "id": 74933,
            "first_name": "Dionicia",
            "last_name": "Amaya Mejia",
            "photo_url": "assets/af0dc29b9f7b4c76a644426e8b0afc02_11_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3d4177dc-c116-4098-b1f5-5f237af304d5"
        },
        {
          "id": 79430,
          "person": {
            "id": 78817,
            "first_name": "Dionne",
            "last_name": "Lomax",
            "photo_url": "assets/de7b5dbdff6947ec8579383db7ecab23_dionne_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33aae76f-64d5-410f-8fa7-b47b9878e8d8"
        },
        {
          "id": 72546,
          "person": {
            "id": 72008,
            "first_name": "Dolores",
            "last_name": "Holler",
            "photo_url": "assets/5216daf68106409aa1247819e60dc3cc_08.2022_t.SBM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 101,
              "name": "Coord, Office",
              "name_abbreviation": "OFFCRD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0429bf90-c6d5-4b6a-9fdf-08ffe1f776c5"
        },
        {
          "id": 64104,
          "person": {
            "id": 63568,
            "first_name": "Domingo",
            "last_name": "Ramirez",
            "photo_url": "assets/ecc3eb7cd1d24059baae90108bbd6513_Resized_R_2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-01-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f99e4803-d485-458b-8e83-acfbf0c15390"
        },
        {
          "id": 14755,
          "person": {
            "id": 14756,
            "first_name": "Donald",
            "last_name": "Brown",
            "photo_url": "870c81ae-1282-c1a0-4acc-b7beba7d49c4.png",
            "phone_number": "",
            "email_address": "230019@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc94902d-5d2f-4d01-90db-661fb1a42993"
        },
        {
          "id": 14758,
          "person": {
            "id": 14759,
            "first_name": "Donovan",
            "last_name": "Noble",
            "photo_url": "ad45dbb5-0d78-c497-44f4-97c9425d5817.png",
            "phone_number": "",
            "email_address": "230022@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6fd329f0-cfec-4091-957a-d2fa7996a99c"
        },
        {
          "id": 63998,
          "person": {
            "id": 63462,
            "first_name": "Dorinda",
            "last_name": "Buck",
            "photo_url": "assets/cc8ddb51f53a4c80a82f10c4ce90eb5c_Elevator147021_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0102757b-049b-44c7-84b9-8675752a541c"
        },
        {
          "id": 78644,
          "person": {
            "id": 78050,
            "first_name": "Doris",
            "last_name": "Coss",
            "photo_url": "assets/d37dcb2447f8420b83a077a4c76816f8_doris_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-11-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "299cd1f4-248f-4c7b-ae4a-35c8a1b500c6"
        },
        {
          "id": 74218,
          "person": {
            "id": 73676,
            "first_name": "Douglas",
            "last_name": "Earle",
            "photo_url": "assets/fc0d133f10a04a829dfd56460500c387_255F0536503A4E50932D6A7A5A36054F_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27e48b11-1cdd-4e0f-8dd4-ef7c0fa02a38"
        },
        {
          "id": 81129,
          "person": {
            "id": 80498,
            "first_name": "Douglas",
            "last_name": "Flores",
            "photo_url": "assets/f58356f1a987434c93d08b364747105e_DouglassFlores2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9156cb38-60d9-4c67-b0c4-c256d4a395c9"
        },
        {
          "id": 51012,
          "person": {
            "id": 50504,
            "first_name": "Durga",
            "last_name": "Acharya",
            "photo_url": "assets/e10d30fc67114514aa1a9bf9e2cb0487_durga_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b55e00a5-48e8-4d37-b152-783f7b334611"
        },
        {
          "id": 52818,
          "person": {
            "id": 52281,
            "first_name": "Duriel",
            "last_name": "Baker",
            "photo_url": "assets/7db12f89ffcc47288c0dca9785d6efd6_dbakerphoto_t.jpg",
            "phone_number": "4632453888",
            "email_address": "dbaker@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7637,
              "name": "Swing",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7f89274-8324-4c24-940c-3806dea75e18"
        },
        {
          "id": 51964,
          "person": {
            "id": 51441,
            "first_name": "Earlene",
            "last_name": "Woodson",
            "photo_url": "assets/6089d1e951924c5ebc8f1724c05899d8_EarleneWoodson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca968a39-875c-474a-adfd-c056ca7995af"
        },
        {
          "id": 77363,
          "person": {
            "id": 76784,
            "first_name": "Ebizael",
            "last_name": "Roche",
            "photo_url": "assets/e6e89c3a9ad94a979aed70c5e3ce5af8_EB_t.jpg",
            "phone_number": "+14807971624",
            "email_address": "eroche@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83c5a8ed-6814-457c-8ecf-d96adac66ac7"
        },
        {
          "id": 47477,
          "person": {
            "id": 46992,
            "first_name": "Edgar",
            "last_name": "Diaz Ortiz",
            "photo_url": "assets/rddef6de90cfc46e097e09d4ca79aea68_IMG_20210412_075050_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5059,
              "name": "Night",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3626b7b1-76e7-40b6-9129-0bf5e05215ae"
        },
        {
          "id": 74738,
          "person": {
            "id": 74195,
            "first_name": "Eduardo",
            "last_name": "Cruz",
            "photo_url": "assets/d57ab127bc1c43d1b42d09c76eac58d7_EduardoCruz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a70a7557-836b-415c-b40b-59fee9268cf4"
        },
        {
          "id": 51008,
          "person": {
            "id": 50500,
            "first_name": "Eduviges",
            "last_name": "Tijera Villanueva",
            "photo_url": "assets/03bb683efc774a1d8bbfacbc429edc76_edu_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0fd6e9d9-8865-4113-b91f-97fc93fe6c6b"
        },
        {
          "id": 48560,
          "person": {
            "id": 48068,
            "first_name": "Edward",
            "last_name": "Gomez",
            "photo_url": "assets/6a0ab59f96fa46af94c81be1eac7b8e4_EdGomez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-05-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83bfd7de-50d8-4bc6-9718-9ecf43d5783d"
        },
        {
          "id": 74156,
          "person": {
            "id": 73614,
            "first_name": "Edwin",
            "last_name": "Estrada Paredes",
            "photo_url": "assets/019eda149a0d4149bd1eee517381cfbd_EDWINESTRADA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "795721b1-3e73-4856-8a44-652aa3a9c23e"
        },
        {
          "id": 67264,
          "person": {
            "id": 66722,
            "first_name": "Elda",
            "last_name": "Vasquez",
            "photo_url": "assets/53e6a411d5c34a40b6993a62fbe8aead_IMG_20221109_58225_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "207ec7c7-7767-44ed-9e97-0c3513bcb576"
        },
        {
          "id": 47108,
          "person": {
            "id": 46622,
            "first_name": "Eliana",
            "last_name": "Barrero Urzola",
            "photo_url": "assets/1b39335af6354bc99eb6759d9b756abb_20221201_210214_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "44a0e973-7ec1-49d2-b5b2-a39f2fa86c77"
        },
        {
          "id": 74448,
          "person": {
            "id": 73906,
            "first_name": "Elisa",
            "last_name": "Huiltron Sanchez",
            "photo_url": "assets/2a581b2791c8426085275c9ed3e5e25c_ELISAB_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "63437034-2892-4c3e-99e6-b9a990a4e98b"
        },
        {
          "id": 46329,
          "person": {
            "id": 45860,
            "first_name": "Elizabeth",
            "last_name": "Chacon Almaguer",
            "photo_url": "assets/1617301990.65901_IMG_2117_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "622831dc-95b5-4882-ab10-eb88d6179d2b"
        },
        {
          "id": 74232,
          "person": {
            "id": 73690,
            "first_name": "Elizabeth",
            "last_name": "Ramirez",
            "photo_url": "assets/d5793281a8e841d28f8f39809f34472f_ELIZABETHR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7adadfcd-e4cb-4b1f-b9bf-3c5a7dc8841c"
        },
        {
          "id": 74411,
          "person": {
            "id": 73869,
            "first_name": "Elizabeth",
            "last_name": "Ruiz",
            "photo_url": "assets/8df1d95988e44c158c73c45d40e532ca_ELIZABETHRUIZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1d48f81-8e85-410f-b7e3-64d69f229ca7"
        },
        {
          "id": 80923,
          "person": {
            "id": 80293,
            "first_name": "Ellen",
            "last_name": "Williams",
            "photo_url": "assets/99ba4ff23be449138c306870eb8f2814_WilliamsEllen251439image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e4228b60-411a-476e-862d-406f632bd628"
        },
        {
          "id": 46223,
          "person": {
            "id": 45761,
            "first_name": "Elsa",
            "last_name": "Garcia",
            "photo_url": "assets/abfb146a3d544c3e9ac2290fe777de5c_Elsa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2817,
              "name": "Day",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0b3fd5d-f856-43ab-8c15-62aaecb86f46"
        },
        {
          "id": 81247,
          "person": {
            "id": 80615,
            "first_name": "Elsa",
            "last_name": "Hernandez",
            "photo_url": "assets/148d8606b2204e2cba786ad1b5c6fdd8_IMG_0643ElsaHernandez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38352c80-5557-4e49-a8f1-f0562d698552"
        },
        {
          "id": 75082,
          "person": {
            "id": 74535,
            "first_name": "Elsa",
            "last_name": "Mancia",
            "photo_url": "assets/377ff54801bd40b18a809b005d493062_ELSAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf0ebef7-5a04-41bf-a4a3-5377240178bf"
        },
        {
          "id": 69007,
          "person": {
            "id": 68472,
            "first_name": "Elsa",
            "last_name": "Marin de Valle",
            "photo_url": "assets/fbde5a35a41a4f66a9144d16ee204a3e_20220322_172616_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c560c034-be5a-4e54-8174-1ea2431e4331"
        },
        {
          "id": 73940,
          "person": {
            "id": 73400,
            "first_name": "Elsie",
            "last_name": "Bailey",
            "photo_url": "assets/4e59e231cb5d487fb9e9d9b2883c07c6_Elsie_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fe24acee-2e87-41fe-9a31-94a9734f16bc"
        },
        {
          "id": 74240,
          "person": {
            "id": 73698,
            "first_name": "Elvia",
            "last_name": "Miranda",
            "photo_url": "assets/8632782f582e4560be7eded1b7a49068_ELVIAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cbe00a33-784e-4024-b276-338bcc381964"
        },
        {
          "id": 51581,
          "person": {
            "id": 51061,
            "first_name": "Emanuel",
            "last_name": "Castro",
            "photo_url": "assets/aba878a418d9418698d8be0b8c9c6db2_6_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c6a58d6-b933-4400-bcac-37573361d61f"
        },
        {
          "id": 79201,
          "person": {
            "id": 78593,
            "first_name": "Emanuel",
            "last_name": "Richardson",
            "photo_url": "assets/cc00ad4700e24f75be98703ee49928b7_Image_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2249,
              "name": "Day",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07fcb77a-3950-419f-a7aa-5b7ea928751e"
        },
        {
          "id": 74222,
          "person": {
            "id": 73680,
            "first_name": "Emilio",
            "last_name": "Sanchez",
            "photo_url": "assets/6bfd9afbd38a47878559f72b23d52ef9_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ee279876-22d7-49d7-9068-f2af0c9696ce"
        },
        {
          "id": 64034,
          "person": {
            "id": 63498,
            "first_name": "Emily",
            "last_name": "Waszak",
            "photo_url": "assets/ded967b1766f46e2a4d9de5d5e4f2d6d_Resized_20230619_121717_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2148,
              "name": "Day",
              "contract": {
                "id": 2162,
                "name": "Manteno, IL",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f5d04171-f7a0-412c-a72f-f883ec551501"
        },
        {
          "id": 74361,
          "person": {
            "id": 73819,
            "first_name": "Emma",
            "last_name": "Villegas",
            "photo_url": "assets/5525143930b14a9d957d99c7419bb262_EMMAVILLEGAS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "adbcbe51-dc66-4a4f-ad0f-1ec86c4b0664"
        },
        {
          "id": 51648,
          "person": {
            "id": 51128,
            "first_name": "Emrah",
            "last_name": "Cizmic",
            "photo_url": "assets/bd180732551a4ac1b640a02760865847_08.2022_t.SBM",
            "phone_number": "",
            "email_address": "ecizmic@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3006,
              "name": "Manager, Digital Transformation",
              "name_abbreviation": "MGDXFM"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba81931a-4029-43c7-aaf7-699375081c5d"
        },
        {
          "id": 81248,
          "person": {
            "id": 80616,
            "first_name": "Enrique",
            "last_name": "Palos",
            "photo_url": "assets/c30c1f6cebc944908267c2a2459b7eb8_IMG_0670Enrique_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d087d48e-f369-4610-83e4-5b375c3e1f69"
        },
        {
          "id": 74406,
          "person": {
            "id": 73864,
            "first_name": "Erendira",
            "last_name": "Cisneros Gomez",
            "photo_url": "assets/r32190b667cf04829899e93a3584fe0f6_IMG_4789_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5441eb86-2767-4cb3-a704-30dd42a35135"
        },
        {
          "id": 81105,
          "person": {
            "id": 80474,
            "first_name": "Eric",
            "last_name": "Eale",
            "photo_url": "assets/0b396ea6a4f0413ba6297fc8ddc4d6cc_EricEaleHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8827e02c-0950-4720-902f-0282e02aa4ed"
        },
        {
          "id": 78600,
          "person": {
            "id": 78006,
            "first_name": "Eric",
            "last_name": "Mayers",
            "photo_url": "assets/1af2d13a78394825bc068a6940c369a4_EricMayerHeadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2372,
              "name": "Swing",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e10c3a6c-541f-45c2-8961-7067f7cdaf0b"
        },
        {
          "id": 79559,
          "person": {
            "id": 78942,
            "first_name": "Eric",
            "last_name": "Spinelli",
            "photo_url": "assets/95a3794651394b78b7d6d9612c4f726d_EricSpinellipic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-12-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "177d0d03-e960-4776-b20b-b7ad75199bc3"
        },
        {
          "id": 74290,
          "person": {
            "id": 73748,
            "first_name": "Erick",
            "last_name": "Miranda",
            "photo_url": "assets/72baea0aceab4a39be7274229796d2f9_ERICKMIR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "88a2ea2b-5afd-40bc-bb1f-e7ffaee85d56"
        },
        {
          "id": 75474,
          "person": {
            "id": 74939,
            "first_name": "Ernanes",
            "last_name": "Braga",
            "photo_url": "assets/838541f6747242a5b56ae0c3dc659c8f_12_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e73fd384-1337-443a-ac86-14dafeb87d7f"
        },
        {
          "id": 81249,
          "person": {
            "id": 80617,
            "first_name": "Ervin",
            "last_name": "Cienfuegos",
            "photo_url": "assets/c33d77799d284db085e470614efe11bf_IMG_0664ervin_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8941d768-a0c6-4765-9776-b30b0cdc8b0c"
        },
        {
          "id": 72995,
          "person": {
            "id": 72457,
            "first_name": "Esly",
            "last_name": "Zavala",
            "photo_url": "assets/0b667ef4fb4f46aa8079d221b5a31044_esly_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-06-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ee24d35-40f7-4181-a87a-83b0467c901f"
        },
        {
          "id": 75469,
          "person": {
            "id": 74934,
            "first_name": "Esmar",
            "last_name": "Merida Cano",
            "photo_url": "assets/022f7bacd1c14d27ac0b20cf02cdc734_13_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9813a121-33a7-4561-a0e3-d5beff0ae4b4"
        },
        {
          "id": 74153,
          "person": {
            "id": 73611,
            "first_name": "Esmeralda",
            "last_name": "Baltazar Estrada",
            "photo_url": "assets/2e16db03bcba42aebf25860d9b268d6c_ESMERALDABA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "180da7e0-07db-4432-a073-10c3306bc0d9"
        },
        {
          "id": 74397,
          "person": {
            "id": 73855,
            "first_name": "Esmeralda",
            "last_name": "Hernandez Avila",
            "photo_url": "assets/65e9e8a2525842f6a5d59b9bd5b2b959_ESMERALDAHERNANDEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ffb71ba6-9f3d-46f4-af6d-68afea905622"
        },
        {
          "id": 78608,
          "person": {
            "id": 78014,
            "first_name": "Esmeralda",
            "last_name": "Nunez",
            "photo_url": "assets/2687883e6c2e429492d0dba37e3d58c6_download_t.png",
            "phone_number": "n/a",
            "email_address": "enunez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c4a58e4-6a4c-405e-95c3-ef51d9996f1b"
        },
        {
          "id": 74121,
          "person": {
            "id": 73579,
            "first_name": "Esmilda",
            "last_name": "Palacios",
            "photo_url": "assets/ab75a197a87c490fbd6c52a24c6b45dc_ESMILDAP_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c6ee834b-8af4-4940-8124-c8d97930f62b"
        },
        {
          "id": 71114,
          "person": {
            "id": 70577,
            "first_name": "Esperanza",
            "last_name": "Cespedes Ortiz",
            "photo_url": "assets/a3d920b6633d4e5e9d6fb332e3a8c8b4_EsperanzasInsitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4ff398fa-b3fe-44a7-9958-1502306d7f1e"
        },
        {
          "id": 51737,
          "person": {
            "id": 51214,
            "first_name": "Essie",
            "last_name": "Adjafio",
            "photo_url": "assets/06b16830b3fb435ba2ece70b43928b2b_essie_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43aa35ac-e35d-49fd-8d74-9728fc486f52"
        },
        {
          "id": 50773,
          "person": {
            "id": 50267,
            "first_name": "Estefani",
            "last_name": "Flores-Jimenez",
            "photo_url": "assets/0762e4adee4d442387def59c602921b5_7_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27c2d71f-8779-4a2f-b80c-2f50a35a3af8"
        },
        {
          "id": 74356,
          "person": {
            "id": 73814,
            "first_name": "Estela",
            "last_name": "Cerda Contreras",
            "photo_url": "assets/9a2d2c7fe85c4d50b02ea4ae72a4fafa_ESTELACERDA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4c4684fd-79a8-4117-aa56-1693f08344fc"
        },
        {
          "id": 74114,
          "person": {
            "id": 73572,
            "first_name": "Esther",
            "last_name": "Figueroa",
            "photo_url": "assets/e9752dfdf078485aa1c7bb32aa8d032d_IMG_00502_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "df43e6c0-3703-4087-a23e-0614a1a256b4"
        },
        {
          "id": 74539,
          "person": {
            "id": 73997,
            "first_name": "Esther",
            "last_name": "Mora Tapia",
            "photo_url": "assets/47dc0bbfbb53478cac1ec257386065ab_esthermo_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6586,
              "name": "Day",
              "contract": {
                "id": 3995,
                "name": "Sausalito",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "4711f2f6-bb8c-4cfb-aaaf-49edee61dbb7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2c5affe-8f68-48c7-9563-bdb9c2b0a99e"
        },
        {
          "id": 51955,
          "person": {
            "id": 51432,
            "first_name": "Eugene",
            "last_name": "Hegre",
            "photo_url": "assets/5b00ec8397a0455ebb9819a500aa0a55_20210930_110305_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff3ed50b-eb3f-4694-83cf-adb2953c364f"
        },
        {
          "id": 81484,
          "person": {
            "id": 80855,
            "first_name": "Evelia",
            "last_name": "Alvarez",
            "photo_url": "assets/r91d9b09ed2da46268346e59d3ce15c82_IMG_0056Copy_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7395,
              "name": "Day",
              "contract": {
                "id": 5530,
                "name": "Wenatchee, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab683c89-241e-4784-943d-d41b8186417a"
        },
        {
          "id": 74234,
          "person": {
            "id": 73692,
            "first_name": "Evelyn",
            "last_name": "Garcia",
            "photo_url": "assets/8220b0cb205f4367a36126622e1a79a2_EVELYNGARCIA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3a70b989-386c-4479-b1d7-0992751064ea"
        },
        {
          "id": 75470,
          "person": {
            "id": 74935,
            "first_name": "Evelyn",
            "last_name": "Ochoa",
            "photo_url": "assets/81bf9f17f4844770bebfd7ef7a85c85f_14_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3051ad26-86a6-4806-99e9-78c87be24cf6"
        },
        {
          "id": 74464,
          "person": {
            "id": 73922,
            "first_name": "Evelyn",
            "last_name": "Ortiz Cardozo",
            "photo_url": "assets/a8a9121b8b6d4ef8a69cbeca260ca3c3_EVELYNORTIZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "246eb8b9-5ee6-4860-b57c-791840cd784a"
        },
        {
          "id": 81250,
          "person": {
            "id": 80618,
            "first_name": "Everardo",
            "last_name": "Marquez",
            "photo_url": "assets/93660c82a94c44c68afcf1601de52acd_IMG_0659Everardomarquez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "03749ca1-406b-461b-9501-e65f93502fbc"
        },
        {
          "id": 46693,
          "person": {
            "id": 46212,
            "first_name": "Fabiola",
            "last_name": "Saucedo Hernandez",
            "photo_url": "assets/1617290515.2735817_fabiolaClorox_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2627,
              "name": "Swing",
              "contract": {
                "id": 2439,
                "name": "Oakland, CA",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad362f7c-65d6-4111-9226-9a775739f02c"
        },
        {
          "id": 81132,
          "person": {
            "id": 80501,
            "first_name": "Fatoumatta",
            "last_name": "Jammeh",
            "photo_url": "assets/c0ec4881a38c427cb75a23b57e9a509b_FatoumattaJammehCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0d080be-6272-451e-ac38-9cbd7b2f5e06"
        },
        {
          "id": 81109,
          "person": {
            "id": 80478,
            "first_name": "Fatuma",
            "last_name": "Mohamud",
            "photo_url": "assets/fc13f4bfeb9540a6a6c3e18d5ba7e342_FatumaMohamudCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b8b9a576-045f-4b1f-b04f-e731b0b5e799"
        },
        {
          "id": 81113,
          "person": {
            "id": 80482,
            "first_name": "Fatuma",
            "last_name": "Yussuf",
            "photo_url": "assets/180e4d847cb440868af85cff2af1c7e4_FatumaYussufCampus_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ff34141-ba20-45fd-b8d9-a2e5878805aa"
        },
        {
          "id": 81310,
          "person": {
            "id": 80679,
            "first_name": "Fay",
            "last_name": "Anderson",
            "photo_url": "assets/r779772465d6a4dcbb66ccac1b65adfb4_fay_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5037,
              "name": "Swing",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3cef745a-150a-4620-adec-14b30df20755"
        },
        {
          "id": 72496,
          "person": {
            "id": 71957,
            "first_name": "Felicitas",
            "last_name": "Godinez",
            "photo_url": "assets/9f73044cd04c4d0784461c6ef70bada6_IMG_20221108_12679_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc84d638-ceaa-4744-8603-de32f9ac1749"
        },
        {
          "id": 74164,
          "person": {
            "id": 73622,
            "first_name": "Fernando",
            "last_name": "Faburrieta Ramirez",
            "photo_url": "assets/031c32bf923b47a9b5c75629c2586ec8_FERNANDORAMIREZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4c7ca359-2139-483c-9439-0d132f8a5d30"
        },
        {
          "id": 74174,
          "person": {
            "id": 73632,
            "first_name": "Fernando",
            "last_name": "Figueroa Martinez",
            "photo_url": "assets/fa6ea9c3f71b493da68e592aebac12f8_FERNANDOFIGUERO_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f51a1e34-add7-435b-9412-bd292eb4bfb7"
        },
        {
          "id": 74213,
          "person": {
            "id": 73671,
            "first_name": "Fernando",
            "last_name": "Merino Temoche",
            "photo_url": "assets/84d573ca8b104e859553c63b23e6a9a3_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f862398-a0e2-41d4-bd5c-a6aa1c51e957"
        },
        {
          "id": 74131,
          "person": {
            "id": 73589,
            "first_name": "Fidelio",
            "last_name": "Soriano Rodriguez",
            "photo_url": "assets/6b6f58eb4710493fa1cb88c13e89bbf1_IMG_0159_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bfaa04c8-0e9d-4f65-a944-3c0d2fa3047e"
        },
        {
          "id": 75472,
          "person": {
            "id": 74937,
            "first_name": "Filomena Maria",
            "last_name": "Barcelos Mendes Borges",
            "photo_url": "assets/701e2fd91b8b42c4a06f381af5ef04ab_IMG_0232_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30b39bd1-ef15-4055-9439-53a933883cee"
        },
        {
          "id": 81251,
          "person": {
            "id": 80619,
            "first_name": "Flor",
            "last_name": "Hernandez",
            "photo_url": "assets/5f81c54db70f40a7a88082c773016f1b_IMG_0655FlorHernandez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "62139eb4-b40d-4e3a-96c3-0c234065a7ff"
        },
        {
          "id": 68855,
          "person": {
            "id": 68317,
            "first_name": "Franceli",
            "last_name": "Benitez Perez",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3156,
              "name": "Day",
              "contract": {
                "id": 2635,
                "name": "Jollyville (Austin)",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "404ecc53-b6af-411e-a3f9-44cc4aad22b0"
        },
        {
          "id": 76355,
          "person": {
            "id": 75787,
            "first_name": "Frances",
            "last_name": "Rhodes",
            "photo_url": "assets/3d1dbd2685524aa889124043909c45f4_FrancesRhodesPicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab64f622-ce83-44ae-ba9e-5a3aee18f5ce"
        },
        {
          "id": 540,
          "person": {
            "id": 540,
            "first_name": "Francisca",
            "last_name": "Bernal",
            "photo_url": "assets/6595b8cdc7e34264b7f2d1c4d52d0635_FranciscaBernalPhoto_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b7ffd553-d2c5-49cf-8fff-1053555eaeeb"
        },
        {
          "id": 64864,
          "person": {
            "id": 64322,
            "first_name": "Francisco",
            "last_name": "Naupa Huayhua",
            "photo_url": "assets/87c3e8918ab24d0c82d6dddd4a4a07c7_StandardMaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3062,
              "name": "Swing",
              "contract": {
                "id": 2586,
                "name": "Mason",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "6e971341-9fed-47f3-9b5b-314f74d2a2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "db82f105-3e9c-4451-b36e-8be2604cfec5"
        },
        {
          "id": 74376,
          "person": {
            "id": 73834,
            "first_name": "Francisco",
            "last_name": "Velasquez Chiliseo",
            "photo_url": "assets/9b9640bbae4a483ead24c0a7452b639d_FRANCISCOVELASQUEZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43cb2ccb-a20e-4174-a62a-e4257c361893"
        },
        {
          "id": 82251,
          "person": {
            "id": 81620,
            "first_name": "Freddy",
            "last_name": "Ortega Loaisiga",
            "photo_url": "assets/e62db46e999a485ea3227da8fe70cbfe_20230516_214458_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1510c5c9-f478-4775-825c-4338c4b9a9a8"
        },
        {
          "id": 74418,
          "person": {
            "id": 73876,
            "first_name": "Freddy Jaime",
            "last_name": "Amado Vizcarra",
            "photo_url": "assets/396e7e3f60734e5da4b88a7ff0e365ff_FREDDIEV_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47b6672b-ed4b-49a1-8bb3-648d8a259872"
        },
        {
          "id": 50581,
          "person": {
            "id": 50077,
            "first_name": "Froila",
            "last_name": "Figaro Dishmey",
            "photo_url": "assets/rb4e47f1df8714e5fb50598c546000b4b_Froila_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c50af3dc-8838-4a26-9a0f-bf87a7c1e483"
        },
        {
          "id": 5512,
          "person": {
            "id": 5512,
            "first_name": "Gabriel",
            "last_name": "Lopez",
            "photo_url": "assets/955999ba33da40138f17bafa794eec05_GabrielLopez_t.jpeg",
            "phone_number": "8622547719",
            "email_address": "215301@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2419,
              "name": "Swing",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09f46b12-8d75-4455-9b31-65ab5f6e014f"
        },
        {
          "id": 74491,
          "person": {
            "id": 73949,
            "first_name": "Gabriel",
            "last_name": "Luna",
            "photo_url": "assets/5c914e73286c447e9bf342ee10e6134c_Attachment_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f1873758-d4bb-43a5-bfd4-810736194fa5"
        },
        {
          "id": 51639,
          "person": {
            "id": 51119,
            "first_name": "Gabriel",
            "last_name": "Vasquez Rosado",
            "photo_url": "assets/69023b7a3148465f9d076de3dc1a02cd_4insightprofilepic_t.jpg",
            "phone_number": "6176426814",
            "email_address": "gvasquez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7669,
              "name": "Day",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18f0c8ed-89a3-4152-b087-76c383b4d617"
        },
        {
          "id": 74314,
          "person": {
            "id": 73772,
            "first_name": "Gabriela",
            "last_name": "Gonzalez",
            "photo_url": "assets/e723aa252e864018be673c04107d3b1c_GABRIELAGONZALEZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fb10605e-1e4a-4af7-aa19-09ac63fbc71a"
        },
        {
          "id": 75662,
          "person": {
            "id": 75122,
            "first_name": "Gabrielle",
            "last_name": "Truss",
            "photo_url": "assets/8da9d5b3d1ea450ea43ccd501effe21a_GabrielleTrusspic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bba6c09e-bd59-4bce-8e8d-28048b4167d4"
        },
        {
          "id": 69120,
          "person": {
            "id": 68585,
            "first_name": "GAO",
            "last_name": "YUAN",
            "photo_url": "assets/3f5912f6eca145a284065b2769e447b0_GaoYuan_t.jpg",
            "phone_number": "(658) 268-2014",
            "email_address": ""
          },
          "positions": [
            {
              "id": 41,
              "name": "Asst Mgr, Operations",
              "name_abbreviation": "AOPMGR"
            }
          ],
          "shifts": [
            {
              "id": 7855,
              "name": "Day",
              "contract": {
                "id": 5813,
                "name": "Suntec",
                "customer_id": 2155,
                "customer_name": "VMware (CBRE) (SG)",
                "uuid": "5822414e-91a4-4972-930b-dbbb34bcabb4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "96e7de2b-00de-4365-ab5c-ddf07ab231ab"
        },
        {
          "id": 74496,
          "person": {
            "id": 73954,
            "first_name": "Garrett",
            "last_name": "Sharp",
            "photo_url": "assets/r4da7bf044b7d43e6bfaf7c7f54dc14b2_IMG_7874_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97b3a843-5ef2-43d7-b155-ef14040b90e1"
        },
        {
          "id": 81121,
          "person": {
            "id": 80490,
            "first_name": "Gaye",
            "last_name": "Karlar",
            "photo_url": "assets/d61dc8a0e2a64713937470f7119fe533_GayeKarlarCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f46ef5c-a0ba-45f5-8ef7-b8d54ae8180a"
        },
        {
          "id": 70970,
          "person": {
            "id": 70433,
            "first_name": "Generoso",
            "last_name": "Capellan",
            "photo_url": "assets/ba5e50ebbb744cb683ba78d1e9af1b07_GenerosoCapellanChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a7f7677a-22c1-44d1-9103-8e975f1ef04c"
        },
        {
          "id": 74155,
          "person": {
            "id": 73613,
            "first_name": "Gilberto",
            "last_name": "Madrigal Suarez",
            "photo_url": "assets/124a4009278947ffa3e0e9e5de9f1581_GILBERTOM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4778df7d-6585-4517-8927-a5e3a04c904d"
        },
        {
          "id": 81120,
          "person": {
            "id": 80489,
            "first_name": "Gina",
            "last_name": "Etongo",
            "photo_url": "assets/75af9f0ed432460398a70487c904d7c0_GinaEtongoHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "995aa7f8-fb78-40aa-aa3c-c9183f086294"
        },
        {
          "id": 67267,
          "person": {
            "id": 66725,
            "first_name": "Gipsi",
            "last_name": "Perdomo",
            "photo_url": "assets/r3ec682bc462b4eb48d189280cc1ee31e_Gipsi_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac02a2c1-916e-49c4-b67e-e874fb062d8e"
        },
        {
          "id": 73504,
          "person": {
            "id": 72964,
            "first_name": "Gladis",
            "last_name": "Zamora Granda",
            "photo_url": "assets/rb50ebc49688c492aa9735065e2ee1438_Gladis_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "386f58bc-fee6-4d22-a3df-289c6615d1db"
        },
        {
          "id": 74045,
          "person": {
            "id": 73504,
            "first_name": "Gladys",
            "last_name": "Ruiz",
            "photo_url": "assets/7e38ede9be09424f89957f0e9efa6240_GladysRuizTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bbdb5210-9a0e-4fae-89ee-3c4f2905f505"
        },
        {
          "id": 74034,
          "person": {
            "id": 73493,
            "first_name": "Gladys",
            "last_name": "Tabares",
            "photo_url": "assets/f1290653a8b34153b143f11ff2fc3a67_GladysTavarezTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ffced31e-59d8-42ca-b2d5-43ca0b80f3b2"
        },
        {
          "id": 74495,
          "person": {
            "id": 73953,
            "first_name": "Glendon",
            "last_name": "Tatu",
            "photo_url": "assets/r384227f1e4cf4cff8dd43a4680a32bff_20230620_130947_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b7b67fe5-df36-4d85-93e7-5feb9f9b7b21"
        },
        {
          "id": 81095,
          "person": {
            "id": 80464,
            "first_name": "Gloria",
            "last_name": "Garcia",
            "photo_url": "assets/e9cbd47e8c8243b0832b5a3eefa8a84e_Gloria_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2873c86f-6906-499d-b3d0-543e646ff36e"
        },
        {
          "id": 51266,
          "person": {
            "id": 50751,
            "first_name": "Gloria",
            "last_name": "Giraldo",
            "photo_url": "assets/rcf25c8d066cc4397b4e8e65a11c06b8a_616C6333753A4C3EAF23F21C8BB75968_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76736f8e-db9c-4db8-849b-0532f0a12085"
        },
        {
          "id": 81252,
          "person": {
            "id": 80620,
            "first_name": "Gloria",
            "last_name": "Hernandez",
            "photo_url": "assets/0061dac43e094cb2a87d799767f2cfb2_IMG_0661gloriahernandez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bf34520c-88d0-456c-854d-8351d2ef5c8f"
        },
        {
          "id": 49140,
          "person": {
            "id": 48645,
            "first_name": "Greyson",
            "last_name": "Wolff",
            "photo_url": "assets/97fe50f144c5412badd672c00a6c85be_ProfilePic_t.jpg",
            "phone_number": "503-484-4240",
            "email_address": "gwolff@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 7829,
              "name": "1-Day",
              "contract": {
                "id": 5798,
                "name": "CEP – Cleveland Engine Plant – Brook Park, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8b656f84-9cf0-4ae3-b729-5b6e4e3fd8da",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7830,
              "name": "1-Day",
              "contract": {
                "id": 5797,
                "name": "OHAP – Ohio Assembly Plant – Avon Lake, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "1f3084fd-8ac6-41f4-a224-8ed7cbd74791",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7588,
              "name": "Day",
              "contract": {
                "id": 5711,
                "name": "Automotive Vertical Overhead SBM",
                "customer_id": 2122,
                "customer_name": "CRM NAD",
                "uuid": "68195b19-6be3-4148-979c-89a492606cd3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7932,
              "name": "Unassigned",
              "contract": {
                "id": 5837,
                "name": "LEP - Lima Engine Plant - Lima, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "67fc2d57-1bf7-473f-9b85-a5f22075dd5d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0eeaac2b-89c9-4f43-abdc-0d40de47152e"
        },
        {
          "id": 74162,
          "person": {
            "id": 73620,
            "first_name": "Gricelda",
            "last_name": "Martinez",
            "photo_url": "assets/a3778f56d5554bcaa9ae467ff3cebb7c_GRISCELAMAR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de19397a-a9de-41b4-9354-aca3017aa94b"
        },
        {
          "id": 78258,
          "person": {
            "id": 77672,
            "first_name": "Guadalupe",
            "last_name": "Cruz Paz",
            "photo_url": "assets/04546acb50814a13af680640212c8849_Guadalupe_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "12a9ef87-0f10-4226-b89a-926eb0352dbb"
        },
        {
          "id": 74462,
          "person": {
            "id": 73920,
            "first_name": "Guadalupe",
            "last_name": "Ontiveros",
            "photo_url": "assets/0417f5087764432c95a16cb63e520cfc_IMG_1236_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97f52749-eed2-4ffa-8b6e-a178fb7c8578"
        },
        {
          "id": 73107,
          "person": {
            "id": 72569,
            "first_name": "Guillermina",
            "last_name": "Ayon",
            "photo_url": "assets/5fcb7a69b62c4dc69606dd3e7951cd1f_20220713_173548_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bbb9df64-2b19-476d-b4e2-3c402785fffa"
        },
        {
          "id": 46770,
          "person": {
            "id": 46286,
            "first_name": "Habtai",
            "last_name": "Asfaha",
            "photo_url": "assets/1617312974.7842433_Picture1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2627,
              "name": "Swing",
              "contract": {
                "id": 2439,
                "name": "Oakland, CA",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80552ba8-e39b-4992-91a7-23348abd5e95"
        },
        {
          "id": 45802,
          "person": {
            "id": 45353,
            "first_name": "Hafedh",
            "last_name": "Abdullah",
            "photo_url": "assets/1615486512.0375588_HafedhAbdullah_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a82bca3-c0d1-459d-b280-f408616740b9"
        },
        {
          "id": 54067,
          "person": {
            "id": 53524,
            "first_name": "Haley",
            "last_name": "Goode",
            "photo_url": "assets/7f4637ff1a40428ba0e7afb40171d423_e89e6c44640b4511a74aee41d4c5f294_StandardFemale_t_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1523,
              "name": "Day",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31e0d482-0ac0-4680-bd8d-3b83bab2ca3e"
        },
        {
          "id": 47020,
          "person": {
            "id": 46535,
            "first_name": "Hatari",
            "last_name": "Kasigwa",
            "photo_url": "assets/1619642978.6246195_20210422_004312_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ff23a25-4884-4daf-9ded-4379eb204add"
        },
        {
          "id": 79362,
          "person": {
            "id": 78751,
            "first_name": "Haydee",
            "last_name": "Sanchez",
            "photo_url": "assets/b95a0b25ba054248bfeadefcf57bc736_HaydeeSanchez.1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8cf87935-d06d-4033-860e-fcc9ca266172"
        },
        {
          "id": 74223,
          "person": {
            "id": 73681,
            "first_name": "Hazel",
            "last_name": "Lopez",
            "photo_url": "assets/644ea8da1db843c19b6aa88f751c7c0f_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b7df903b-bdc8-4527-8f4d-7d0fd254fe37"
        },
        {
          "id": 69835,
          "person": {
            "id": 69298,
            "first_name": "Heather",
            "last_name": "Combs",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4938,
              "name": "Swing",
              "contract": {
                "id": 2616,
                "name": "Groveport",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3bb6dd38-6fa1-4f73-8565-8051e79ab1be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2480e2c0-434e-48a2-952d-f0d78c4a34fd"
        },
        {
          "id": 69138,
          "person": {
            "id": 68603,
            "first_name": "Heather",
            "last_name": "Curry",
            "photo_url": "assets/53ade39d539e414b8f0e23beff8f16ef_20220412_081252_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f9aedf26-8705-4230-b0f1-9d8806e225c6"
        },
        {
          "id": 75475,
          "person": {
            "id": 74940,
            "first_name": "Hector",
            "last_name": "Martinez",
            "photo_url": "assets/fbe2a1ee2db94a048deb55d56b6df128_16_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e45d14d3-9def-4052-a6e7-5fa7ca27d59d"
        },
        {
          "id": 74037,
          "person": {
            "id": 73496,
            "first_name": "Hector",
            "last_name": "Montoya",
            "photo_url": "assets/d2a3b3a7915e4f64abbadbd0181a71be_HectorMontoyaTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b2cac32-c29c-4679-8be0-a9647c74a445"
        },
        {
          "id": 74221,
          "person": {
            "id": 73679,
            "first_name": "Hector",
            "last_name": "Ramirez Perez",
            "photo_url": "assets/70230c6c7ea04569a8ea677cc66d5f80_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e50c10bc-36bd-4d0a-a59e-b8b02c33de96"
        },
        {
          "id": 52469,
          "person": {
            "id": 51941,
            "first_name": "Helen",
            "last_name": "Williams",
            "photo_url": "assets/5df1fc866041477aa63fca4728ec05ac_1472Nov133_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21fe80b5-ae24-4393-958c-aaa607822259"
        },
        {
          "id": 74050,
          "person": {
            "id": 73509,
            "first_name": "Henry",
            "last_name": "Ovalles",
            "photo_url": "assets/e9343592397546e18b09aea716c403cc_17_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "940934f0-4708-4e2b-8f09-a7dab4761841"
        },
        {
          "id": 75946,
          "person": {
            "id": 75407,
            "first_name": "Herbert",
            "last_name": "McGhee",
            "photo_url": "assets/bbf64829f6ba43289b417b1e14aa5fd5_1000000728_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3076,
              "name": "Swing",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eaa954b6-b7fe-4bb6-9b6b-b22b49d1cbe9"
        },
        {
          "id": 75476,
          "person": {
            "id": 74941,
            "first_name": "Hermes Javier",
            "last_name": "Feliciano Rodriguez",
            "photo_url": "assets/d4b6a1cf569148e5b0bc09ad9a1b5fd2_HermesFelicianoPct_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4eeeb026-3c17-4435-aba2-60419bc5e397"
        },
        {
          "id": 69291,
          "person": {
            "id": 68756,
            "first_name": "Hilda",
            "last_name": "Del Cid Pineda",
            "photo_url": "assets/0a8e381eeea949ab9e7112f81960d530_EE1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9e1f8f46-f8e2-4264-841e-dba5217ddb66"
        },
        {
          "id": 80839,
          "person": {
            "id": 80209,
            "first_name": "Hitanshu",
            "last_name": "Pandit",
            "photo_url": "assets/32278e8df92d4769817bc682d3a166b0_42E61BDA5B2443E180103E9AE0374349_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2979,
              "name": "Manager in Training",
              "name_abbreviation": "MIT"
            },
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7105,
              "name": "None",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5450aded-1980-4903-b4f5-3de9e92bb5a7"
        },
        {
          "id": 69614,
          "person": {
            "id": 69079,
            "first_name": "Hubert",
            "last_name": "Riley",
            "photo_url": "assets/ra131b841cc3947f3b78a2b1423ed5724_me_t.jpg",
            "phone_number": "9016562294",
            "email_address": "hriley@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 4986,
              "name": "None",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2022-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a8d50f17-c461-4d25-98c2-a0e87ede04d8"
        },
        {
          "id": 75477,
          "person": {
            "id": 74942,
            "first_name": "Hugo",
            "last_name": "Cuevas",
            "photo_url": "assets/7ee0546c69864aa9914ca126756ed715_18_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54c93e88-7e40-4666-ae9f-a05983885ada"
        },
        {
          "id": 79946,
          "person": {
            "id": 79323,
            "first_name": "Hugues",
            "last_name": "Jean-Baptiste",
            "photo_url": "assets/edef7370240e43f7bcf76041e38584a7_Huguespicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1458,
              "name": "Day",
              "contract": {
                "id": 2017,
                "name": "Greenfield, IN",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "171f87da-7571-4d5d-a211-2432e1bd3518"
        },
        {
          "id": 68773,
          "person": {
            "id": 68237,
            "first_name": "Hung",
            "last_name": "Nguyen",
            "photo_url": "assets/01713d45bf964b82a782824361ab88fe_HungNguyen_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c26b0068-9f45-4d1d-a195-7741fa14ed45"
        },
        {
          "id": 69719,
          "person": {
            "id": 69184,
            "first_name": "Idalmis",
            "last_name": "Cruz",
            "photo_url": "assets/ed6c8ac40cd645b0937649a0f447a9c0_Resized_20220523_161015_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f133da1-fbd7-48ce-91cd-0b9cdccc8555"
        },
        {
          "id": 74151,
          "person": {
            "id": 73609,
            "first_name": "Imelda",
            "last_name": "Banuelos",
            "photo_url": "assets/4899f1471ead4e598426aa8bc601cb12_IMELDAB_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2db07be7-9c99-4eb4-8850-25cb0ed896b1"
        },
        {
          "id": 74346,
          "person": {
            "id": 73804,
            "first_name": "Iraida",
            "last_name": "Rios-Chavez",
            "photo_url": "assets/85acd8199c8c43adaf639433a6dc356e_IRALDARIOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2ff50a6d-23c9-47d8-843c-b2f73313da50"
        },
        {
          "id": 81253,
          "person": {
            "id": 80621,
            "first_name": "Irene",
            "last_name": "Marquez",
            "photo_url": "assets/8e03d6807bab4b0a940e2411a2e438e6_IMG_0658irenemarquez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff5909e7-42ef-4c72-a149-e32d90e2a5f5"
        },
        {
          "id": 74362,
          "person": {
            "id": 73820,
            "first_name": "Irene",
            "last_name": "Villarruel",
            "photo_url": "assets/r6045bcbfa881453c84cd9209ebcd7328_IMG_0012_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "601d159e-b3e2-4dc2-81fa-b3d75a84629e"
        },
        {
          "id": 77361,
          "person": {
            "id": 76782,
            "first_name": "Iris",
            "last_name": "Lawson",
            "photo_url": "assets/ef1d853c11aa42f3926c4f637e27e726_me_t.jpg",
            "phone_number": "7324894145",
            "email_address": "ilawson@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1a1bab3d-6654-4808-8420-bf95acbf8618"
        },
        {
          "id": 75818,
          "person": {
            "id": 75283,
            "first_name": "Irma",
            "last_name": "Tadeo Hernandez",
            "photo_url": "assets/ae750e1cbb934ba5a5430925288d29b6_20220829_001606_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2418,
              "name": "Swing",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "078f0187-0b40-43f0-b4f6-29ec46d07cca"
        },
        {
          "id": 74291,
          "person": {
            "id": 73749,
            "first_name": "Isaac",
            "last_name": "Perez",
            "photo_url": "assets/fea62f5898364414882c8e1b0bbe7753_ISSACP_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f4d945a-4a21-4df1-b1ce-8956097702c9"
        },
        {
          "id": 48577,
          "person": {
            "id": 48085,
            "first_name": "Isaac",
            "last_name": "Rogers",
            "photo_url": "assets/40807278e9cd4d2f83ebf96669a8895a_irpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "58c0dfc3-e4b6-4c9b-9c9c-2c4626aae233"
        },
        {
          "id": 69692,
          "person": {
            "id": 69157,
            "first_name": "Isaac",
            "last_name": "Rogers",
            "photo_url": "assets/bef88cf474a44260b77b6c6f70f9f57b_IRogersPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37cf6b10-d745-4906-9b99-1bcf73294786"
        },
        {
          "id": 46315,
          "person": {
            "id": 45846,
            "first_name": "Isabel",
            "last_name": "Torres Gonzalez",
            "photo_url": "assets/1617639475.7756543_Resized_Resized_20210405_114026_225007688482655_t.jpg",
            "phone_number": "N/A",
            "email_address": "n/a"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "48219159-a097-4b8a-881e-9f346438f7bd"
        },
        {
          "id": 78026,
          "person": {
            "id": 77441,
            "first_name": "Isaiah",
            "last_name": "Soremekun",
            "photo_url": "assets/r5fc9d6fe6a1c49f780d22b85a174a74b_IsaiahSoremekunpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ee6a55f5-ff56-4cff-976d-3487db6e1b2b"
        },
        {
          "id": 75478,
          "person": {
            "id": 74943,
            "first_name": "Isalino",
            "last_name": "Melo",
            "photo_url": "assets/a64b8b9a986047cfb485cc391b5b2923_19_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0565c6d6-0af6-41e8-9df0-3d52a26bbf09"
        },
        {
          "id": 74368,
          "person": {
            "id": 73826,
            "first_name": "Israel",
            "last_name": "Villa",
            "photo_url": "assets/788921cd5a934043af8af6f6da6a85eb_ISMAELV_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2ee234e-f5c3-4b02-ad4b-8ab96ad1772c"
        },
        {
          "id": 64871,
          "person": {
            "id": 64329,
            "first_name": "Issa",
            "last_name": "Parajon",
            "photo_url": "assets/r9d66b2a0f2d448378e9e1b03bca9ab17_IPPIC_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "51a93b9d-77b5-4608-aa99-9346cb6da278"
        },
        {
          "id": 52772,
          "person": {
            "id": 52234,
            "first_name": "Ivan",
            "last_name": "Taminez",
            "photo_url": "assets/cea023299d7244d5a3f33222fd83e1cc_Ivan_t.jpg",
            "phone_number": "2029898121",
            "email_address": "itaminez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04b35a61-d7a7-4b93-b376-7df348a24c57"
        },
        {
          "id": 85715,
          "person": {
            "id": 85047,
            "first_name": "Ivan",
            "last_name": "Valencia Damaso",
            "photo_url": "assets/63c14ef2495c4f2eb15bd197cf52792e_1000003338_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c1e70d4a-68ba-4644-9a04-765abf5077d2"
        },
        {
          "id": 81254,
          "person": {
            "id": 80622,
            "first_name": "Ivonne",
            "last_name": "Santana de Lopez",
            "photo_url": "assets/3722d439d3c043a1a299bed6a3419a10_IMG_0668ivonnesantana_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91aa54b6-02a1-4edc-9abf-004a9cae226f"
        },
        {
          "id": 78221,
          "person": {
            "id": 77635,
            "first_name": "Jacqueline",
            "last_name": "Arreguin",
            "photo_url": "assets/08e03cb7516a4a118c3f12d42d5f05d5_IMG_1168_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7654,
              "name": "Day",
              "contract": {
                "id": 1819,
                "name": "Sun City",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab2a54a2-dbc2-4d4e-bfd4-a159e8f1130c"
        },
        {
          "id": 71171,
          "person": {
            "id": 70637,
            "first_name": "Jacqueline",
            "last_name": "Rodriguez Jimenez",
            "photo_url": "assets/c4b2d2d5843c4d238bb4391051385b59_Screenshot_20220513184232_Messages1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-05-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2fbb20ba-d0b4-40b3-bd65-23c78ea6f4e0"
        },
        {
          "id": 81106,
          "person": {
            "id": 80475,
            "first_name": "Jailah",
            "last_name": "Nayou",
            "photo_url": "assets/8820427aa205448093d0b656c31e1ee5_JuliahNayouCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ee673b7-dad1-42ee-92ab-9681093cb336"
        },
        {
          "id": 75483,
          "person": {
            "id": 74948,
            "first_name": "Jaime",
            "last_name": "Alvarado",
            "photo_url": "assets/377f8cf3131244a3a31e5f901061f6ec_21_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1aa92669-f926-4275-a633-f29f867c76bd"
        },
        {
          "id": 51287,
          "person": {
            "id": 50771,
            "first_name": "Jaime",
            "last_name": "Alvarenga Ascencio",
            "photo_url": "assets/32ac8ae09d3441808e51936fd21c60eb_JaimeALvarngaHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3038,
              "name": "Swing",
              "contract": {
                "id": 2590,
                "name": "Laguna South, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "abb421e8-0707-42f0-b247-657048924721",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "44cc07b1-b454-438b-a5e3-eef2ccae0dc1"
        },
        {
          "id": 24814,
          "person": {
            "id": 24735,
            "first_name": "Jaime",
            "last_name": "Soria",
            "photo_url": "assets/1589292743.7434437_Jaime_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8a9d6d65-e1a7-4727-af81-3292b468fed8"
        },
        {
          "id": 80921,
          "person": {
            "id": 80291,
            "first_name": "James",
            "last_name": "Swilley",
            "photo_url": "assets/858cd21149bd4f0cab9f163b3ded1a0f_SwilleyJames251436image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ec88ded7-c599-4c8e-bb5b-274200a326d0"
        },
        {
          "id": 76561,
          "person": {
            "id": 75993,
            "first_name": "Jamie",
            "last_name": "Clevenger",
            "photo_url": "assets/459d193a55544eab9b6160c8b54ecb11_WIN_20230328_06_36_00_Pro_t.jpg",
            "phone_number": "",
            "email_address": "jclevenger@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 2249,
              "name": "Day",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f1fd6c2-c242-4b63-a329-364915ebd7ef"
        },
        {
          "id": 79420,
          "person": {
            "id": 78807,
            "first_name": "Jan",
            "last_name": "Stoyer",
            "photo_url": "assets/r171bae3e45a84712b4f2cfcd2f67f637_IMG_1958_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2796,
              "name": "Swing",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cedfce31-dd01-4217-b3d5-2c8801936c3f"
        },
        {
          "id": 39007,
          "person": {
            "id": 38652,
            "first_name": "Jara",
            "last_name": "Lule",
            "photo_url": "assets/1597936623.0201988_Resized_20200721_101244_196816104150956_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "314ab059-c5b2-4290-96f1-b94208fcc20c"
        },
        {
          "id": 76046,
          "person": {
            "id": 75506,
            "first_name": "Jasmin",
            "last_name": "Arriola",
            "photo_url": "assets/71f4cee6f815434fa061e9c50ab7796b_IMG_8478_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a5dd7b3-cda2-4c29-9039-043c12a4e5b9"
        },
        {
          "id": 50775,
          "person": {
            "id": 50269,
            "first_name": "Jasmine",
            "last_name": "Flores-Gimenez",
            "photo_url": "assets/be704e57189f489fbc64e4b08c97bf4a_5_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "450113e3-4002-43ef-8663-00c40219528b"
        },
        {
          "id": 45839,
          "person": {
            "id": 45388,
            "first_name": "Jasmine",
            "last_name": "Morgan",
            "photo_url": "assets/rf0f54e356f824bea8bba16c1372d1268_20230607_185012_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ff22797b-5530-4412-bf7d-684ecf10e184"
        },
        {
          "id": 53534,
          "person": {
            "id": 52994,
            "first_name": "Jason",
            "last_name": "Grant",
            "photo_url": "assets/d488f6ed6967486e89e34ea7b8c9e32c_JGrantbadgepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1df7cc59-62c4-4299-ba79-49392092679c"
        },
        {
          "id": 74730,
          "person": {
            "id": 74187,
            "first_name": "Jason",
            "last_name": "Velasco Calderon",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae53dd62-dd5f-46eb-8fb2-1747793310b6"
        },
        {
          "id": 72643,
          "person": {
            "id": 72105,
            "first_name": "Jaylen",
            "last_name": "Hardy",
            "photo_url": "assets/20ab8743bb714e01bbf0235ccd907896_thumbnail_20221207_162307_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2ab2c37-ff12-415c-b176-438bdd9414ad"
        },
        {
          "id": 74172,
          "person": {
            "id": 73630,
            "first_name": "Jazmine",
            "last_name": "Fuentes",
            "photo_url": "assets/e7eb3b74cc7345919f4391ef276c5fcf_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc36f350-c763-40b0-9db5-2a0d1676e5a3"
        },
        {
          "id": 81255,
          "person": {
            "id": 80623,
            "first_name": "Jean Luc",
            "last_name": "Garza",
            "photo_url": "assets/dd3957ca5a9f4bef9c759c83ef208fc2_IMG_0649johnlucgarza_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "64aa6d1d-1723-43b5-9185-1e168fc2f46e"
        },
        {
          "id": 74224,
          "person": {
            "id": 73682,
            "first_name": "Jeannette",
            "last_name": "Montoya Lopez",
            "photo_url": "assets/efdeddafa1c64e31bae413f120162ecb_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1e564322-cddf-41ca-8aa9-616c701f98fc"
        },
        {
          "id": 51536,
          "person": {
            "id": 51016,
            "first_name": "Jeffrey",
            "last_name": "Edwards",
            "photo_url": "assets/657348216f7241faa16ac52d883cdfd9_IMG_1739_t.jpg",
            "phone_number": "N/A",
            "email_address": "jedwards@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8901,
              "name": "Swing",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2021-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38c06b49-4841-4c27-b1ef-6fd74af88b8d"
        },
        {
          "id": 80922,
          "person": {
            "id": 80292,
            "first_name": "Jennifer",
            "last_name": "Wise",
            "photo_url": "assets/ac70f7eb7f6a44e99845cf51809b9a0f_WiseJennifer251437image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2116f31e-c790-43ee-943a-184b0e286e58"
        },
        {
          "id": 80354,
          "person": {
            "id": 79725,
            "first_name": "Jeremy",
            "last_name": "Gage",
            "photo_url": "assets/a8bf61fc1e344987a16c425738ad8c0a_PXL_20230110_020035498_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 94,
              "name": "Proj Mgr",
              "name_abbreviation": "PRJMGR"
            }
          ],
          "shifts": [
            {
              "id": 7938,
              "name": "None",
              "contract": {
                "id": 5844,
                "name": "Technology Vertical Overhead SBM",
                "customer_id": 2122,
                "customer_name": "CRM NAD",
                "uuid": "75bb1f52-f279-4b65-a284-985e156b27f4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8703,
              "name": "Unassigned",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8702,
              "name": "Unassigned",
              "contract": {
                "id": 5976,
                "name": "Hillsboro",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2023-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5f28fbef-a529-4ccd-a49f-2da9e4e8e683"
        },
        {
          "id": 75479,
          "person": {
            "id": 74944,
            "first_name": "Jermaine",
            "last_name": "Harris",
            "photo_url": "assets/9a088bce96d649118552f35e8499f338_22_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2d23cb9-ebf1-4935-ac7e-5be79784edd2"
        },
        {
          "id": 68407,
          "person": {
            "id": 67864,
            "first_name": "Jerome",
            "last_name": "Brown",
            "photo_url": "assets/f7e1f2985bae4f0ab138cbb68a93b2d5_Jerome_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2372,
              "name": "Swing",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "611cb49c-d2c6-4bde-bd59-75940d4e04ca"
        },
        {
          "id": 54412,
          "person": {
            "id": 53865,
            "first_name": "Jerry",
            "last_name": "Howard",
            "photo_url": "assets/af71c8e80670484ea47b62c878ae33ac_JerryHoward_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "94b90bf6-5b15-4545-b027-908f978b2823"
        },
        {
          "id": 78571,
          "person": {
            "id": 77977,
            "first_name": "Jesse",
            "last_name": "Bell",
            "photo_url": "assets/735bab91cbf84655acc162fbbb741020_JesseBellpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83eb3bbc-64a2-481f-8116-fe39f584bfa5"
        },
        {
          "id": 80201,
          "person": {
            "id": 79577,
            "first_name": "Jesse",
            "last_name": "Coppedge",
            "photo_url": "assets/rae6601ee27274417aef47b5922c43c09_JesseCoppedgeHeadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7224,
              "name": "On-Call",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a44283ee-36f1-4616-af58-e9d533c7f2b6"
        },
        {
          "id": 73963,
          "person": {
            "id": 73423,
            "first_name": "Jesse",
            "last_name": "Dunbar",
            "photo_url": "assets/0ca4de8901154a6fa7316f58b85457e5_08.2022_t.SBM",
            "phone_number": "4253640090",
            "email_address": "jdunbar@sbmcorp.com"
          },
          "positions": [
            {
              "id": 215,
              "name": "Sr Mgr, Ntnl Sustain",
              "name_abbreviation": "SNSMG"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b3e7a4fd-e779-4654-a443-a6e8a978bef3"
        },
        {
          "id": 74134,
          "person": {
            "id": 73592,
            "first_name": "Jesse",
            "last_name": "Hipilito",
            "photo_url": "assets/7c76881488a34ffea5416d532ebd5621_JESSEH_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06caef08-4eab-420e-ad0e-f7051b219ac2"
        },
        {
          "id": 50584,
          "person": {
            "id": 50080,
            "first_name": "Jessica",
            "last_name": "Cosme Mosquera",
            "photo_url": "assets/6c82092df8ad47e7b239507ad6d49047_processedJessicaCosme_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cedb4507-8c14-4968-90f1-b9ca11a4f616"
        },
        {
          "id": 74407,
          "person": {
            "id": 73865,
            "first_name": "Jesus",
            "last_name": "Cortez",
            "photo_url": "assets/9cae49243c2347249b3a0dedd2f97d1d_JESUSCORTEZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b4de0f5-a746-476e-95b8-76c198312802"
        },
        {
          "id": 69369,
          "person": {
            "id": 68834,
            "first_name": "Jianjun",
            "last_name": "Ma",
            "photo_url": "assets/f993d9785f7a42c8a88d88b723ffb4f9_JianJun_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f3e22e32-539e-47a1-af16-bfaac566b292"
        },
        {
          "id": 74235,
          "person": {
            "id": 73693,
            "first_name": "Jie Na",
            "last_name": "Li",
            "photo_url": "assets/66fc079125344ebbb002e73c0f68b61e_25E1460EEC8B4DFFBA91EAA8A4D2D215_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8901287a-48d5-4a0d-8f3b-8b6226363881"
        },
        {
          "id": 74538,
          "person": {
            "id": 73996,
            "first_name": "Jindi",
            "last_name": "Chen",
            "photo_url": "assets/f84545a1f2f94f2f94bb1a7fb74a9f16_F1B55150D31F428AB7863E5D4F403F2D_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1fbab0c9-fa1b-48c8-bb3d-9418681df4dd"
        },
        {
          "id": 54140,
          "person": {
            "id": 53597,
            "first_name": "Jing",
            "last_name": "Wu De Ng",
            "photo_url": "assets/99fc6fdcf1bd41d08b9fc1cfc75f6bdc_wifeofrui_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "934a5186-3a45-48ae-acb6-ccc2d78db908"
        },
        {
          "id": 74243,
          "person": {
            "id": 73701,
            "first_name": "Jin Ye",
            "last_name": "Chen",
            "photo_url": "assets/d5927db7731f4ac0bc25ec9b510cf383_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b619153a-36f7-441d-856d-6a44c8f24d21"
        },
        {
          "id": 75677,
          "person": {
            "id": 75137,
            "first_name": "Joanna",
            "last_name": "Flores",
            "photo_url": "assets/6134c9c969f84c3dae3dcb0ea0759894_thumbnail_IMG_05781_t.jpg",
            "phone_number": "n/a",
            "email_address": "n/a"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6094,
              "name": "Swing",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f751672e-7675-42dc-b9ab-f8484a812d2b"
        },
        {
          "id": 51014,
          "person": {
            "id": 50506,
            "first_name": "Joe",
            "last_name": "Sagbo",
            "photo_url": "assets/172cc734c31a4f898a0886e9b48489eb_joe_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "452322d3-6be3-4756-8681-be3ce268e654"
        },
        {
          "id": 71406,
          "person": {
            "id": 70872,
            "first_name": "Joel",
            "last_name": "Formoso",
            "photo_url": "assets/6eeb6d66cd91475b815bae9fa5c4af24_IMG_6461_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3000,
              "name": "Swing",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f6cc0b57-dd66-4c68-8b1b-6620eca3dd45"
        },
        {
          "id": 72651,
          "person": {
            "id": 72113,
            "first_name": "John",
            "last_name": "Brown",
            "photo_url": "assets/r2f893920fdfe4590972baa61696a5e44_20230103_121421_t.jpg",
            "phone_number": "3143565605",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "773d04a9-7e18-4abe-b2ff-d93c3dc3708c"
        },
        {
          "id": 64060,
          "person": {
            "id": 63524,
            "first_name": "Jonathan",
            "last_name": "Borgford",
            "photo_url": "assets/r7492356d547d4035921fc60f947f9321_20220107_141924_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2181,
              "name": "Day",
              "contract": {
                "id": 2264,
                "name": "Bothell, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "908fc000-28d0-439e-9912-ff88967db144",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a1bfef64-af3c-44c0-8f2c-687ed75e366b"
        },
        {
          "id": 49268,
          "person": {
            "id": 48773,
            "first_name": "Jonathan",
            "last_name": "Kruer",
            "photo_url": "assets/r57e86b5a0ced4cbd80aff92822a206a6_jonathan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2249,
              "name": "Day",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a812edac-0972-4be2-bb64-42c8b6a95923"
        },
        {
          "id": 74342,
          "person": {
            "id": 73800,
            "first_name": "Jorge",
            "last_name": "Guerrero Flores",
            "photo_url": "assets/406b84b4de7740da96579acce61ad260_jorgeg_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c846f73f-2b30-41da-baaf-3b22414e460f"
        },
        {
          "id": 7105,
          "person": {
            "id": 7105,
            "first_name": "Jose",
            "last_name": "Barrera",
            "photo_url": "7d5df6ad-e0b5-a447-b46a-4653ce81bb34.png",
            "phone_number": "",
            "email_address": "106432@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7891,
              "name": "Night",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3aa8f1f0-d656-455a-adc3-60050e5b9a9e"
        },
        {
          "id": 76834,
          "person": {
            "id": 76259,
            "first_name": "Jose",
            "last_name": "Carcamo",
            "photo_url": "assets/rca73969feaee4dd1b334c89822641861_JOSEC4INSITE_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "903664c9-0216-438c-a577-6624fa171406"
        },
        {
          "id": 77056,
          "person": {
            "id": 76481,
            "first_name": "Jose",
            "last_name": "Cruz",
            "photo_url": "assets/328c8a6defa943ada276a03d46e5edf0_JoseCruz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c75940b6-0540-45f3-9548-7bf51bcc4f83"
        },
        {
          "id": 74253,
          "person": {
            "id": 73711,
            "first_name": "Jose",
            "last_name": "Ferrusquia",
            "photo_url": "assets/baecc31b917f4aa19e30078d25e18bd2_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc12b278-80dd-4e4c-a368-f913f1f3d3b1"
        },
        {
          "id": 71016,
          "person": {
            "id": 70479,
            "first_name": "Jose",
            "last_name": "Gomez",
            "photo_url": "assets/e5726835284b45ca818e6830ad9d8241_IMG_2861_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7916,
              "name": "Day",
              "contract": {
                "id": 5849,
                "name": "10 Exchange Place, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8653155-a0f4-4b60-8311-17f18e3284e0"
        },
        {
          "id": 74304,
          "person": {
            "id": 73762,
            "first_name": "Jose",
            "last_name": "Gomez Ruvalcaba",
            "photo_url": "assets/6b491670298b495f9e8bcff089a8b31a_JoseGomez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8cb7f6a9-9d1c-402b-9f36-a9b4de17b667"
        },
        {
          "id": 74488,
          "person": {
            "id": 73946,
            "first_name": "Jose",
            "last_name": "Lomeli",
            "photo_url": "assets/cd99ca8b64fe4ef9b69fb0b81e5877a0_JOSELOM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b44dd66d-fb3c-45fe-9500-6eded66ea33f"
        },
        {
          "id": 75481,
          "person": {
            "id": 74946,
            "first_name": "Jose",
            "last_name": "Marte",
            "photo_url": "assets/8d1366c1a29144e9a32807ae1454e4a8_23_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aaa3de12-e7a7-4aa2-bf8d-291dfeacd3a6"
        },
        {
          "id": 74165,
          "person": {
            "id": 73623,
            "first_name": "Jose",
            "last_name": "Medina",
            "photo_url": "assets/c1d4b68b9960482c8fcfc670ae76c8c7_JOSEMEDINA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f6659a2-1518-42a7-bd7d-bbac0f4d5250"
        },
        {
          "id": 75239,
          "person": {
            "id": 74691,
            "first_name": "Jose",
            "last_name": "Oviedo Ascencio",
            "photo_url": "assets/5a90e29d17494672a5509fe21081ee52_JosePhoto_t.jpg",
            "phone_number": "650-291-9071",
            "email_address": "n/a"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f07f6104-c93b-438e-a82c-83cd8c5f48e8"
        },
        {
          "id": 51269,
          "person": {
            "id": 50754,
            "first_name": "Jose",
            "last_name": "Rivera",
            "photo_url": "assets/rc7dfc2e518af4d6e986ba79ccb58a305_joserivera_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 1490,
              "name": "Night",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-09-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1108b587-24e7-46bd-938c-3a7481c5516b"
        },
        {
          "id": 74250,
          "person": {
            "id": 73708,
            "first_name": "Jose",
            "last_name": "Rivera",
            "photo_url": "assets/76feb16b541541d8bd97879d4de77000_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0eb8aa90-46de-42c2-be69-7a8a09135b3d"
        },
        {
          "id": 74364,
          "person": {
            "id": 73822,
            "first_name": "Jose",
            "last_name": "Villanueva",
            "photo_url": "assets/d55e94a75d0145c8bc79b45facd57cd5_JOSEVILLA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "034c63f8-278c-473b-b95a-1643b7bc28ed"
        },
        {
          "id": 74489,
          "person": {
            "id": 73947,
            "first_name": "Jose De Jesus",
            "last_name": "Lopez Munoz",
            "photo_url": "assets/32fb8e18a65d41b4a4570b09e8a9659e_joselopez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6109fb8f-5e91-401d-a178-eec2294f95ed"
        },
        {
          "id": 68949,
          "person": {
            "id": 68411,
            "first_name": "Josefa",
            "last_name": "Rodriguez",
            "photo_url": "assets/3783c6e2257f45229315ed859e8bb787_JosefaRodriguez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4ca4af3d-29a6-47c0-a6a5-f5bdc2729559"
        },
        {
          "id": 74350,
          "person": {
            "id": 73808,
            "first_name": "Josefina",
            "last_name": "Cardenas",
            "photo_url": "assets/9b3cac33aeed4a1884f0922b19cedae1_Josefinanewpic_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7096,
              "name": "Night",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54c8d038-23fd-42c6-985b-b4a1ab1c7e37"
        },
        {
          "id": 74147,
          "person": {
            "id": 73605,
            "first_name": "Josefina",
            "last_name": "Maciel De Martinez",
            "photo_url": "assets/c6943a6079be4ae19ddbdd291aeb69ad_JOSEFINAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "700337b2-af46-4b81-9c5a-2a4b8deb31da"
        },
        {
          "id": 74287,
          "person": {
            "id": 73745,
            "first_name": "Josefina",
            "last_name": "Perez Lopez",
            "photo_url": "assets/aa06ec9a81054cd1852e9ca013b69d28_JOSEFINAPER_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6623,
              "name": "Day",
              "contract": {
                "id": 3994,
                "name": "Chilco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ef930ade-b459-4862-940e-920f290ffaf0"
        },
        {
          "id": 74286,
          "person": {
            "id": 73744,
            "first_name": "Josefina",
            "last_name": "Perez Munguia",
            "photo_url": "assets/r94070744b36f4e7ea6e45fa1cd005c5c_IMG_6344_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b6279148-2605-4813-a634-aa39575e8727"
        },
        {
          "id": 74357,
          "person": {
            "id": 73815,
            "first_name": "Josefina Garcia",
            "last_name": "Chavez",
            "photo_url": "assets/r151be4fe963f495bacf8702257998b83_IMG_3721_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b74cf999-5129-41f2-86f3-4d4838520c4d"
        },
        {
          "id": 49267,
          "person": {
            "id": 48772,
            "first_name": "Joseph",
            "last_name": "Baber",
            "photo_url": "assets/f2a757ac1183452487d52f1295f2f327_joseph_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ec16294-7411-4a39-a509-edd7139334de"
        },
        {
          "id": 49285,
          "person": {
            "id": 48790,
            "first_name": "Joseph",
            "last_name": "Elliott",
            "photo_url": "assets/4ce100f1b272466fbbed8667a5b137d0_joepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9d2d69d-fa1c-4db0-a603-7fc3b7787bdb"
        },
        {
          "id": 54151,
          "person": {
            "id": 53608,
            "first_name": "Joseph",
            "last_name": "McLaughlin",
            "photo_url": "assets/3793f15212c0449b957eee861797d2e1_1246666_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 7895,
              "name": "Swing",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "566cd768-243e-4e4e-ba7e-a32ce2d959d9"
        },
        {
          "id": 49847,
          "person": {
            "id": 49348,
            "first_name": "Josmar",
            "last_name": "Torres",
            "photo_url": "assets/f207bca29c8548adb56e69091ed6410b_Josmar_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb97a7ef-c716-4447-b063-0028c54378bf"
        },
        {
          "id": 74274,
          "person": {
            "id": 73732,
            "first_name": "Josue",
            "last_name": "Arreola",
            "photo_url": "assets/4a5352d53a5d47f18e1c27f20b2069a5_JOSUEARRE_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9096ad76-68f1-4394-bb0e-46206ff42a8e"
        },
        {
          "id": 74282,
          "person": {
            "id": 73740,
            "first_name": "J Ricardo",
            "last_name": "Toriz Arias",
            "photo_url": "assets/d72db59eee2c43839ae6c3ac54c14d15_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d707cf30-2c9c-411a-b841-b9e4babe1461"
        },
        {
          "id": 74441,
          "person": {
            "id": 73899,
            "first_name": "Juan",
            "last_name": "Algarate",
            "photo_url": "assets/b5a570e74e40400482dd91f318406762_JUANALGA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d35a417d-1b2d-4389-b2fb-15f30dfefe8e"
        },
        {
          "id": 73108,
          "person": {
            "id": 72570,
            "first_name": "Juan",
            "last_name": "Caceres",
            "photo_url": "assets/d9fb7e2a1e544c9fa1ff8f6df3baa110_20220713_173404_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7758d2e-77cc-4527-ae5c-d5c6f0cc847b"
        },
        {
          "id": 81256,
          "person": {
            "id": 80624,
            "first_name": "Juan",
            "last_name": "Compean",
            "photo_url": "assets/d9c589ea6f0f4efaab3dbd2af4da5335_IMG_0645JuanCompean_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0baed104-a347-48be-9cb9-be619be26e2a"
        },
        {
          "id": 74401,
          "person": {
            "id": 73859,
            "first_name": "Juan",
            "last_name": "Cortez Pacheco",
            "photo_url": "assets/99f8553b6be84ebab2f085961cd003ab_JUANCORTEZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e9c07e6-161e-4fba-9809-b678f2aa63fe"
        },
        {
          "id": 74255,
          "person": {
            "id": 73713,
            "first_name": "Juan",
            "last_name": "Cunich",
            "photo_url": "assets/f58cbe3af0214587aba1dc723c5ca924_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9797b584-b1aa-454c-bd53-a51eba5469fe"
        },
        {
          "id": 69077,
          "person": {
            "id": 68542,
            "first_name": "Juan",
            "last_name": "Garcia",
            "photo_url": "assets/004dc5b8b3054fed86aea664ecb8b204_PXL_20220324_133922992_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 11,
              "name": "Cage Washer",
              "name_abbreviation": "Cage Washer"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "367729bb-6354-40b3-8938-4a65ff623289"
        },
        {
          "id": 74308,
          "person": {
            "id": 73766,
            "first_name": "Juan",
            "last_name": "Godinez Alatorre",
            "photo_url": "assets/f2a787c5312449a8baaaaa904e6aca76_JUANG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2573566f-c92c-41c8-8ba2-cfdd1833193f"
        },
        {
          "id": 54181,
          "person": {
            "id": 53638,
            "first_name": "Juan",
            "last_name": "Guerrero Mejias",
            "photo_url": "assets/r661138dbfe1743039b6a16abc07b9e5e_juanguerrero_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3183,
              "name": "Night",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f691bdeb-3522-4361-9d53-b6347f5b66df"
        },
        {
          "id": 74399,
          "person": {
            "id": 73857,
            "first_name": "Juan",
            "last_name": "Hernandez Melchor",
            "photo_url": "assets/dd4b38518f094b9b9df2b21dee68c935_IMG_6089_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a50ebcc2-9be4-481e-b9a8-2ad58553ba3d"
        },
        {
          "id": 67599,
          "person": {
            "id": 67057,
            "first_name": "Juan",
            "last_name": "Lozano",
            "photo_url": "assets/b1e25acf06da4cc2b898422065a9edec_001_t.jpg",
            "phone_number": "6693018232",
            "email_address": "jlozano@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4169df82-1516-4c08-af78-cb1c51a176c2"
        },
        {
          "id": 75676,
          "person": {
            "id": 75136,
            "first_name": "Juan",
            "last_name": "Perez",
            "photo_url": "assets/305d0c612a8d4e55bacc2323619b6957_thumbnail_Image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "921cdde0-7ebc-43a9-9610-43653db6cd22"
        },
        {
          "id": 78472,
          "person": {
            "id": 77880,
            "first_name": "Juan",
            "last_name": "Renderos",
            "photo_url": "assets/920e864c65424263ac46cb674fb2220d_JuanRenderos.1_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6057,
              "name": "Swing",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2448a932-25dc-4d17-88e1-ecbe5fb9be6d"
        },
        {
          "id": 72233,
          "person": {
            "id": 71697,
            "first_name": "Juan",
            "last_name": "Seminario Buendia",
            "photo_url": "assets/49eac51e22af49b59cfa4ab80fecf7c3_JuanSeminariosInsitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8ff7150-49ab-482f-b7e4-3f09ae3d75ad"
        },
        {
          "id": 9448,
          "person": {
            "id": 9448,
            "first_name": "Juana",
            "last_name": "Castellano",
            "photo_url": "assets/1589307184.965943_20200512_102540_resized_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bbca5ab3-846a-49f5-9354-c324e9cbeb14"
        },
        {
          "id": 75482,
          "person": {
            "id": 74947,
            "first_name": "Juana",
            "last_name": "Mora Camacho",
            "photo_url": "assets/739bb5edb65647a694a1b77333b40ce9_juana111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75691077-1af4-46f7-a3a6-f5a8daabbc2c"
        },
        {
          "id": 52808,
          "person": {
            "id": 52271,
            "first_name": "Juana",
            "last_name": "Reyes Villatoro",
            "photo_url": "assets/rf50555a475c843828cf5a329cdff0d0e_JuanaReyes_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae189af7-07f8-4be0-9afc-70fe642ebf51"
        },
        {
          "id": 74204,
          "person": {
            "id": 73662,
            "first_name": "Judith",
            "last_name": "Landaverde De Abrego",
            "photo_url": "assets/57d5ad789dec450380b19a8d5c06df0f_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d23527a5-574b-46df-9feb-da3d329844ad"
        },
        {
          "id": 74173,
          "person": {
            "id": 73631,
            "first_name": "Julian",
            "last_name": "Galeana Hernandes",
            "photo_url": "assets/9582202f477d49b99827fa92a0698464_JULIANG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f7913f83-97c5-45b9-8d32-049382495e51"
        },
        {
          "id": 80001,
          "person": {
            "id": 79377,
            "first_name": "Juliette",
            "last_name": "Aguilar",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7200,
              "name": "Day",
              "contract": {
                "id": 3470,
                "name": "Redlands",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "219a07d3-50c9-4f61-ba29-3cc2aeeb14ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91f112a3-3a55-4853-8826-63e603e0c6fb"
        },
        {
          "id": 74182,
          "person": {
            "id": 73640,
            "first_name": "Julio",
            "last_name": "Mercado",
            "photo_url": "assets/7ab84848f9ac47c5a518e9bcb794419d_JULIOMERCADO_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "786c3ac6-c340-407f-87ce-775a9d12b31a"
        },
        {
          "id": 74196,
          "person": {
            "id": 73654,
            "first_name": "Justin",
            "last_name": "Chan",
            "photo_url": "assets/3527aacf4b474463bd6718b50422cfcc_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33b696aa-d949-43b3-aa16-8ef0639d5c75"
        },
        {
          "id": 46241,
          "person": {
            "id": 45779,
            "first_name": "Justin",
            "last_name": "Crouch",
            "photo_url": "assets/1618404285.2965395_JCProfilePic_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d613663c-8ad2-4c8b-8a31-9a2c35911dee"
        },
        {
          "id": 74591,
          "person": {
            "id": 74049,
            "first_name": "Justin",
            "last_name": "Hong",
            "photo_url": "assets/r11538cb6489e480d87e49671a7da6e1e_20230620_103806_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c2b7ab31-ac43-41bd-9dfd-58214a072c18"
        },
        {
          "id": 74200,
          "person": {
            "id": 73658,
            "first_name": "Justina",
            "last_name": "Hernandez",
            "photo_url": "assets/ecb75ba4db5e4b3bb4991050e62efc6a_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4b14fea1-b879-461a-bccd-4f52f0349a03"
        },
        {
          "id": 46300,
          "person": {
            "id": 45831,
            "first_name": "Justine",
            "last_name": "Rodgers",
            "photo_url": "assets/1618404378.951626_JustineProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd1b9a9d-d597-4e70-9b65-0f5983b6a48d"
        },
        {
          "id": 49699,
          "person": {
            "id": 49199,
            "first_name": "Kaitlynn",
            "last_name": "Schermerhorn",
            "photo_url": "assets/7e0e1d08f89842dbb182e10e0cbf238a_KS_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b448a374-146e-49e1-8bbc-5c3335f1a82b"
        },
        {
          "id": 72844,
          "person": {
            "id": 72307,
            "first_name": "Karen",
            "last_name": "Tobar Balcarcel",
            "photo_url": "assets/a301e149ec7d4a0aa9a4e0d87eeb998e_KarenPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2559,
              "name": "Swing",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b5d0b41-c654-41a1-ad27-cc2b14f1d845"
        },
        {
          "id": 74293,
          "person": {
            "id": 73751,
            "first_name": "Karina",
            "last_name": "Barrientos Merlos",
            "photo_url": "assets/3a173a362c87450b98b8481716f96571_KARINABARR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "440b497a-d16e-414d-8b4f-7a1f69b4f634"
        },
        {
          "id": 74197,
          "person": {
            "id": 73655,
            "first_name": "Karina",
            "last_name": "Ulloa",
            "photo_url": "assets/6c0eead3bf9e420dacef54c12a4b4ce2_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b1782df-68ba-4997-9fc0-b9845cdad0fb"
        },
        {
          "id": 74279,
          "person": {
            "id": 73737,
            "first_name": "Karla",
            "last_name": "Ibarra",
            "photo_url": "assets/7b36a5bb839e4ef5962ebcc10c2696f4_karla_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6618,
              "name": "Swing",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4038e99c-c2f8-474b-af77-1bbba19c50ab"
        },
        {
          "id": 79929,
          "person": {
            "id": 79306,
            "first_name": "Karla",
            "last_name": "Perez Hernandez",
            "photo_url": "assets/0609b80d339f491896f33a962977c684_KarlaPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "432ff8de-5d15-4848-9c83-986ceea6105c"
        },
        {
          "id": 76727,
          "person": {
            "id": 76158,
            "first_name": "Karm",
            "last_name": "Hubbard",
            "photo_url": "assets/a32dac587cea4059be48df3b163e6b46_KarmHubbard_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d81dee8-901a-45ca-a704-c03d3c93c976"
        },
        {
          "id": 69066,
          "person": {
            "id": 68531,
            "first_name": "Kathryn",
            "last_name": "Levkulich",
            "photo_url": "assets/7ea6875ece64444681f3ed3aa2ecf7a9_20220330_083128_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b276adb0-925b-42a3-976e-0288a0dec76b"
        },
        {
          "id": 73089,
          "person": {
            "id": 72551,
            "first_name": "Kayla",
            "last_name": "Edwards",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 3117,
              "name": "Day",
              "contract": {
                "id": 2608,
                "name": "Ellabell, GA",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "4bd33d9e-e410-4fde-8de1-492858a2075d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fe561da5-0505-436e-902b-520bcd42fffe"
        },
        {
          "id": 74415,
          "person": {
            "id": 73873,
            "first_name": "Kelin",
            "last_name": "Caceres Olivo",
            "photo_url": "assets/1e369b988d714760ba421802e4e24d79_KELINC_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e5dd3c3-3c29-4e54-bb10-173fbed4735b"
        },
        {
          "id": 81258,
          "person": {
            "id": 80626,
            "first_name": "Kelvin",
            "last_name": "Lopez",
            "photo_url": "assets/53a38dbc86d54973a5c664dcd65869be_IMG_0663kelvinlopez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97fa896f-debf-4479-a175-4024c4f36ef3"
        },
        {
          "id": 74302,
          "person": {
            "id": 73760,
            "first_name": "Kelvin",
            "last_name": "Torres Flores",
            "photo_url": "assets/b0137639921c40b1b855e203d3bb80b1_KELVINT_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "724347c1-18e7-417c-9804-87c40fabbf7d"
        },
        {
          "id": 48347,
          "person": {
            "id": 47856,
            "first_name": "Kendryck",
            "last_name": "Cobo",
            "photo_url": "assets/9c198fa6fa6b4f5c8dece968c3ee180e_IMG_2098_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1458,
              "name": "Day",
              "contract": {
                "id": 2017,
                "name": "Greenfield, IN",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b1e4da4-ca32-439c-894d-d4e5890cb186"
        },
        {
          "id": 11057,
          "person": {
            "id": 11057,
            "first_name": "Kenia",
            "last_name": "Beltran",
            "photo_url": "9c422bf2-393f-2dde-9dfd-da719b87227e.png",
            "phone_number": "",
            "email_address": "229323@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8533,
              "name": "Day",
              "contract": {
                "id": 1813,
                "name": "Stapley",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "36c16e7b-11db-4b03-b2a0-d7d67985f7ca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4cbf8191-2fd4-402e-81e9-944989206b19"
        },
        {
          "id": 72512,
          "person": {
            "id": 71973,
            "first_name": "Kenneth",
            "last_name": "Trice",
            "photo_url": "assets/087ee96ffb324d59b940852f628ce5b0_Kenneth_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6be9f16-c88a-4181-82b6-85c25118a3a5"
        },
        {
          "id": 48991,
          "person": {
            "id": 48497,
            "first_name": "Kennia",
            "last_name": "Lopez Santos",
            "photo_url": "assets/23ab5e60e0054a53948eb253ea476102_thumbnail_20210623_230610_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-06-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "00159d34-061e-4cdb-a991-f5ff197a29e4"
        },
        {
          "id": 77937,
          "person": {
            "id": 77353,
            "first_name": "Kentori",
            "last_name": "Poole",
            "photo_url": "assets/re872365f1bb54ea68ded5523f583390e_0052BD71207442AA889C37FE8731A293_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b726b982-abb1-4b4d-bc67-1088dc8aef41"
        },
        {
          "id": 72148,
          "person": {
            "id": 71613,
            "first_name": "Kerry",
            "last_name": "McCraney",
            "photo_url": "assets/dfaffc024cef4378bba95136c3cbdd1c_20220713_101237_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "12e1964f-d3a8-4725-a060-48a4b6c10d7d"
        },
        {
          "id": 74035,
          "person": {
            "id": 73494,
            "first_name": "Kerynez",
            "last_name": "Gonzalez",
            "photo_url": "assets/f65c5ab779fc4c2f837e35d9b6ea2dc3_KerynezGonzalezTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6240cd7-497b-43aa-83ec-f946379b2aa6"
        },
        {
          "id": 75890,
          "person": {
            "id": 75351,
            "first_name": "Kevin",
            "last_name": "Arevalo",
            "photo_url": "assets/7e77d3a82a69475c8a3fe316c64d43e5_IMG_6708_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0c623cc-e07b-4dbb-a7f7-7fbe5b913993"
        },
        {
          "id": 75678,
          "person": {
            "id": 75138,
            "first_name": "Keyara",
            "last_name": "Harris",
            "photo_url": "assets/r7197333c5f654d49b50a6354ea55709d_26D264D4C93A4941A1B1221089B26716_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "58e5f9ad-6200-4c1c-a6b2-7704b42bcfd7"
        },
        {
          "id": 81107,
          "person": {
            "id": 80476,
            "first_name": "Khadijo",
            "last_name": "Abdi",
            "photo_url": "assets/r6489024169a3406a82597fd54e4b0037_KhadijoAbdiCampus_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f90f9fb-9a69-4ef4-be88-c9b65e4b8d1b"
        },
        {
          "id": 50887,
          "person": {
            "id": 50380,
            "first_name": "Kimberly",
            "last_name": "Ka",
            "photo_url": "assets/r9fa9bef611a14018b1ce5bc5b71c57af_Resized_20210825_183826_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9466d1b9-1ced-4915-ac80-72ba990b4252"
        },
        {
          "id": 69130,
          "person": {
            "id": 68595,
            "first_name": "Kimberly",
            "last_name": "Velasco",
            "photo_url": "assets/b2d39fa8d0d9482b9b24a020d406e69c_Kim2_t.PNG",
            "phone_number": "3178641040",
            "email_address": "kvelasco@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b825ef4a-90bc-4795-9edc-3abfa03c2ebb"
        },
        {
          "id": 50815,
          "person": {
            "id": 50309,
            "first_name": "Kimsan",
            "last_name": "Chim",
            "photo_url": "assets/e952e189d1164250b85b6f7ea558dee9_08.2022_t.SBM",
            "phone_number": "4582626934",
            "email_address": "kchim@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-07-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f79db03b-7005-4990-89cb-3f693d4a3cfe"
        },
        {
          "id": 76048,
          "person": {
            "id": 75508,
            "first_name": "Kirstin Leigh",
            "last_name": "Bantolina",
            "photo_url": "assets/2c77a9f6e3eb4a21a8e7d682e54d659c_kirstenphoto_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34d50736-3913-4276-a1c5-2861b3f085a7"
        },
        {
          "id": 79077,
          "person": {
            "id": 78472,
            "first_name": "Kiva",
            "last_name": "Bell",
            "photo_url": "assets/03386124ebef4645a88ba02a92008a52_MicrosoftTeamsimageFemale_t.png",
            "phone_number": "6789782464",
            "email_address": "248873@placeholder.com"
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-11-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d797c579-d29b-4e67-987d-fb03f791d279"
        },
        {
          "id": 81122,
          "person": {
            "id": 80491,
            "first_name": "Korto",
            "last_name": "Gabriel",
            "photo_url": "assets/d3dfcf29e1534a6598f00020594884a5_KortoGabrielCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cff84a41-abd9-46a0-80b8-399b1f919af0"
        },
        {
          "id": 54850,
          "person": {
            "id": 54305,
            "first_name": "Kylan",
            "last_name": "Smith",
            "photo_url": "assets/e43208d424bf44e287527040031c5bd9_kyimage_t.jpg",
            "phone_number": "3025821460",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1412,
              "name": "Day",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30c6e3c1-c0b4-4378-a37a-8b0504b098b9"
        },
        {
          "id": 67285,
          "person": {
            "id": 66743,
            "first_name": "Kyle",
            "last_name": "Lawrence",
            "photo_url": "assets/12d93a30284c459a8c80e521efb5cf5a_KLINSITE_t.jpg",
            "phone_number": "",
            "email_address": "243487@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25bc0a25-89d4-4f99-9247-5ccea13bdc63"
        },
        {
          "id": 76495,
          "person": {
            "id": 75927,
            "first_name": "Kyle",
            "last_name": "Oesch",
            "photo_url": "assets/dd77f8e5128f4a77bf5e39bbb67516e9_KylesInsitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 107,
              "name": "Glass Wash Equip Prep",
              "name_abbreviation": "GMPGW"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "db7a028b-e14c-4901-ba75-0b9275c9f9dc"
        },
        {
          "id": 69568,
          "person": {
            "id": 69033,
            "first_name": "Kymetra",
            "last_name": "Gates",
            "photo_url": "assets/b54a78ea9a734c4a917fc3ce4ba2a688_KGatesPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6fdabe21-707e-4757-8ac4-a8a7cb045e4a"
        },
        {
          "id": 54735,
          "person": {
            "id": 54190,
            "first_name": "Kyung",
            "last_name": "Nam",
            "photo_url": "assets/bbb6c5711a5f418f80767d151397cf76_kuyangnam_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8095249e-e6cb-4d5d-a974-343984937082"
        },
        {
          "id": 54427,
          "person": {
            "id": 53880,
            "first_name": "Lamart",
            "last_name": "Hamilton",
            "photo_url": "assets/d7468da7641d4cf5bb32e2155f785478_20230103_132324_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b653172-f8b0-4f48-9dae-5cf8dd3be20c"
        },
        {
          "id": 78474,
          "person": {
            "id": 77882,
            "first_name": "Larry",
            "last_name": "Bundrum",
            "photo_url": "assets/696bc3fb61f6462d839dad0f23dc2f22_20230427_171018_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "26c36304-77f5-41f1-969c-ba3006c599ec"
        },
        {
          "id": 80920,
          "person": {
            "id": 80290,
            "first_name": "Larry",
            "last_name": "Henderson",
            "photo_url": "assets/a9006a1d454b40dd80c85e0e251fbd12_HendersonLarry251435image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8677,
              "name": "Day",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "747e2cf7-4d33-458d-9330-1e8e33e93986"
        },
        {
          "id": 63996,
          "person": {
            "id": 63460,
            "first_name": "Larry",
            "last_name": "Mitchell",
            "photo_url": "assets/cdc795da3b8d4b6f8a5c31805b0eeb08_LarryM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d163421-7c14-4195-9bd9-1f881b3976ed"
        },
        {
          "id": 74785,
          "person": {
            "id": 74242,
            "first_name": "Laura",
            "last_name": "Alvarez",
            "photo_url": "assets/8d5f598a7080402a8102512f6a34e09a_LauraAlvarezPicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e90e1499-0dc8-4301-851b-2f951e231535"
        },
        {
          "id": 74143,
          "person": {
            "id": 73601,
            "first_name": "Laura",
            "last_name": "Ayala Medina",
            "photo_url": "assets/r4b5daeab5ecf45b58177b43789b9b89a_lauraayala_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "10ea6c15-a482-404d-9802-1f57aa79e6de"
        },
        {
          "id": 74317,
          "person": {
            "id": 73775,
            "first_name": "Laura",
            "last_name": "Morales",
            "photo_url": "assets/39a80474c5f74fe1ada68fd30b9ad6c5_LAURAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b034700e-90b6-42e0-abcb-6c3c181cf024"
        },
        {
          "id": 78931,
          "person": {
            "id": 78326,
            "first_name": "Laura",
            "last_name": "Zapata",
            "photo_url": "assets/7b63f24cb73e4ef8b81503975ef692e6_Laura_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ed8d33a-bb2c-417a-bdba-a063c9d91b64"
        },
        {
          "id": 72757,
          "person": {
            "id": 72220,
            "first_name": "Laureano",
            "last_name": "Tapanes Dorta",
            "photo_url": "assets/16f85ebc695c45198ecb701832f42550_laureano_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "548651f8-351f-4f59-aef1-554eea01e490"
        },
        {
          "id": 81103,
          "person": {
            "id": 80472,
            "first_name": "Laurie",
            "last_name": "Hart",
            "photo_url": "assets/7affabaa74ff436ebc14b09975517da3_LaurieHartCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d2d24b3-a796-4403-9568-cbbe58510531"
        },
        {
          "id": 72254,
          "person": {
            "id": 71717,
            "first_name": "Lawrence",
            "last_name": "Credle",
            "photo_url": "assets/cf4b580070294dcdae2533c00b896dc0_sbmprofilepic_t.jpg",
            "phone_number": "2673701598",
            "email_address": "lcredle@sbmcorp.com"
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f87c7ee-c638-4b56-a5ce-8040ead16288"
        },
        {
          "id": 69427,
          "person": {
            "id": 68892,
            "first_name": "Lenin",
            "last_name": "Alejo",
            "photo_url": "assets/781c8ed781e941c9ab70e438618e0e22_IMG_4001_t.jpg",
            "phone_number": "9848377631",
            "email_address": "lalejo@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 3191,
              "name": "Day",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bf72025a-0bc1-4ae9-8e6e-d4ad11b76aac"
        },
        {
          "id": 71128,
          "person": {
            "id": 70591,
            "first_name": "Leonardo",
            "last_name": "Bautista",
            "photo_url": "assets/a47c6b01df7b48cebadc537b81dc0381_thumbnail_IMG_8842_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 5056,
              "name": "Day",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8dcd3e5-7149-4807-8bfa-43e3bb7f6985"
        },
        {
          "id": 53659,
          "person": {
            "id": 53119,
            "first_name": "Leonel",
            "last_name": "Perez-Garduno",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 3156,
              "name": "Day",
              "contract": {
                "id": 2635,
                "name": "Jollyville (Austin)",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f2129fc-515c-4d20-9c8d-ad266a5c65e5"
        },
        {
          "id": 74297,
          "person": {
            "id": 73755,
            "first_name": "Leonel",
            "last_name": "Torres",
            "photo_url": "assets/6f6f466107904f7f8407d2b9f0403f5e_LEOELT_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a4bb7b71-badd-4370-a1e7-dc0e654e8ca8"
        },
        {
          "id": 74267,
          "person": {
            "id": 73725,
            "first_name": "Leonor",
            "last_name": "Martinez Miranda",
            "photo_url": "assets/d3a4bf8381e84a8cb3a0f50b6c9f8081_LEONARM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "74fe1988-de3e-4422-b775-6eb9c6129884"
        },
        {
          "id": 69693,
          "person": {
            "id": 69158,
            "first_name": "Leroy",
            "last_name": "Brewer",
            "photo_url": "assets/65a90b7ca4ba4238afc9eab188ac1134_LBrewerPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "133d9ed1-188e-4e10-a85f-f963ca02f6bd"
        },
        {
          "id": 72135,
          "person": {
            "id": 71600,
            "first_name": "Leslie",
            "last_name": "Batiste",
            "photo_url": "assets/r22af1c1ba81047d7b285a6394589c24f_20220607_150635_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11411a65-a0a1-4f09-b73c-c638939f7fb7"
        },
        {
          "id": 79514,
          "person": {
            "id": 78898,
            "first_name": "Leslie",
            "last_name": "Zabalza",
            "photo_url": "assets/189a7c19b1c24427bb87b553979e3c0f_Image_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd4c85e4-a77a-4155-b863-b789104a4067"
        },
        {
          "id": 79247,
          "person": {
            "id": 78638,
            "first_name": "Leticia",
            "last_name": "Pacheco",
            "photo_url": "assets/361738ef6ec845f3827a9ccc0cb11b17_20221201_205801_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "647580be-8100-410c-abc7-fac48778b910"
        },
        {
          "id": 79769,
          "person": {
            "id": 79150,
            "first_name": "Leydi",
            "last_name": "Diaz Izaguirre",
            "photo_url": "assets/634a5d9d5a174ad4bb841558e4e595d9_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9a71fa3-2e88-463c-9b30-deb3a35da6b8"
        },
        {
          "id": 81259,
          "person": {
            "id": 80627,
            "first_name": "Leyla",
            "last_name": "Rivera",
            "photo_url": "assets/e57bb9afba46401e9446b8e271e31077_IMG_0673Leyla_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f1743206-3b74-4209-88f6-976ec999bed5"
        },
        {
          "id": 74199,
          "person": {
            "id": 73657,
            "first_name": "Lian Yan",
            "last_name": "Lin",
            "photo_url": "assets/f7bf797ad29c4d6b817e55441e3e3d3d_C7975EFAE1BC4B219B741EC3FE607A4B_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a1d55e90-c127-4bcd-8daa-2311967c6128"
        },
        {
          "id": 48202,
          "person": {
            "id": 47713,
            "first_name": "Lidia",
            "last_name": "Castillo De Avila",
            "photo_url": "assets/74dcdd2790b742acb50eece782607de8_IMG_20221201_12744_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-05-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5167a9eb-047b-4787-a881-19cd5157d199"
        },
        {
          "id": 11679,
          "person": {
            "id": 11679,
            "first_name": "Lilia",
            "last_name": "Pantoja",
            "photo_url": "assets/1590624290.2917142_20200512_102622_resized_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "306ea90d-aece-466d-9c2b-230cade413a9"
        },
        {
          "id": 68947,
          "person": {
            "id": 68409,
            "first_name": "Liliam",
            "last_name": "Munoz Lopez",
            "photo_url": "assets/fe75d003962d4258b3b9d4dd38de4dae_20220413_2054091_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba569fe6-128e-4c90-97ef-73f2a269c5c8"
        },
        {
          "id": 52324,
          "person": {
            "id": 51798,
            "first_name": "Liliana",
            "last_name": "Gomez",
            "photo_url": "assets/45a38d945c614ec6a343325474725aed_IMG_0369_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7745,
              "name": "Swing",
              "contract": {
                "id": 1819,
                "name": "Sun City",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "927429ed-0bcf-45fc-962f-4cd5bec6ffa0"
        },
        {
          "id": 48959,
          "person": {
            "id": 48465,
            "first_name": "Liliana",
            "last_name": "Martinez",
            "photo_url": "assets/181cbe83268e41e48cd4801b7e577b70_1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2719,
              "name": "Swing",
              "contract": {
                "id": 2348,
                "name": "Reno, NV",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-06-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "974d130e-7ac2-425c-9630-33f81233d43d"
        },
        {
          "id": 73110,
          "person": {
            "id": 72572,
            "first_name": "Lina",
            "last_name": "Arboleda Cadavid",
            "photo_url": "assets/b8b9124843a1408493c2fa6763d6ff52_20220713_173738_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a968048a-9fb2-422f-bc08-06e1926461fd"
        },
        {
          "id": 77941,
          "person": {
            "id": 77357,
            "first_name": "Linda",
            "last_name": "Koonce",
            "photo_url": "assets/8ce1a875839b4ec9abf37f523329e27e_thumbnail_IMG_4078_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9e3e1bab-beec-4518-a335-4cfb91471cc8"
        },
        {
          "id": 51384,
          "person": {
            "id": 50867,
            "first_name": "Linh",
            "last_name": "La",
            "photo_url": "assets/a3f09ec7333c46088f615e07e774a0e3_20210630_111142MaiLinhLa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d700eeab-cb0d-473a-9b5d-49176d7ab82c"
        },
        {
          "id": 49485,
          "person": {
            "id": 48989,
            "first_name": "Lino",
            "last_name": "Escudero Jose",
            "photo_url": "assets/r4c2c2b6f396b4097a08849c15c4b100d_LINO_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2160,
              "name": "Night",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d76bad0-6c45-4c3a-bcbb-7a1ad9126031"
        },
        {
          "id": 73281,
          "person": {
            "id": 72742,
            "first_name": "Linwood",
            "last_name": "Gerald",
            "photo_url": "assets/d5b8c0bd6bb34b5992a1c403c91bdb3c_thumbnail_IMG_20220720_165443_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5492bd63-95e9-4649-8478-95233b855725"
        },
        {
          "id": 53656,
          "person": {
            "id": 53116,
            "first_name": "Lisa",
            "last_name": "Butler",
            "photo_url": "assets/fffcf687b9df492cb5297d53086a7457_lisabutler_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2249,
              "name": "Day",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4b0cd3f8-89c3-44e1-8a70-b011c0dc18bb"
        },
        {
          "id": 78315,
          "person": {
            "id": 77728,
            "first_name": "Lisandra",
            "last_name": "Mairena Garcia",
            "photo_url": "assets/93e686806dcd4c8da4333f498b761301_Lisandra_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3191,
              "name": "Day",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf0a029c-d9da-455f-885f-4d39afb106ce"
        },
        {
          "id": 48962,
          "person": {
            "id": 48468,
            "first_name": "Lizmarie",
            "last_name": "Lopez Lamboy",
            "photo_url": "assets/aacdea5fb57d46ca8f638a71c74f131b_20210623_061252.jpgLiZ_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1128325f-3bcc-4974-8be5-3c5df23373f1"
        },
        {
          "id": 74336,
          "person": {
            "id": 73794,
            "first_name": "Lorena",
            "last_name": "Gonzalez",
            "photo_url": "assets/e9bcd40f51454aaf9c89c972e88f371c_LORENAG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b2b8647-6691-42c6-8c0e-bcbead002803"
        },
        {
          "id": 74384,
          "person": {
            "id": 73842,
            "first_name": "Lorenzo",
            "last_name": "Tucson",
            "photo_url": "assets/66e2126f34a941319d9fb7ffc41de0fc_LORENZOT_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e44e054b-88fe-489f-9a64-11575cef2666"
        },
        {
          "id": 69565,
          "person": {
            "id": 69030,
            "first_name": "Louise",
            "last_name": "Brown",
            "photo_url": "assets/d46ea335eaaf4b08aeab7552c8f2bff5_LBrownPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56a78f3e-418b-49b1-8122-27decef26335"
        },
        {
          "id": 73442,
          "person": {
            "id": 72902,
            "first_name": "Lourdes",
            "last_name": "Argueta",
            "photo_url": "assets/0a79216e4cc841d890037c3960cd631a_IMG_20220708_145845_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "949f21a3-d3d2-4375-ab19-95eb80d43028"
        },
        {
          "id": 76757,
          "person": {
            "id": 76187,
            "first_name": "Lucas",
            "last_name": "Vasquez",
            "photo_url": "assets/e79ad2a1a391458abcee51fd97833e18_thumbnail_IMG_4083_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7890,
              "name": "Day",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b8cd6839-4ed4-4d48-996a-bfcbe4b5d2f6"
        },
        {
          "id": 74247,
          "person": {
            "id": 73705,
            "first_name": "Lucero Nayeli",
            "last_name": "Jacobo Tena",
            "photo_url": "assets/d2faaf955ce04517aebf3370e3cd97b2_LUCEROJA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0820922-9247-47c5-9e68-3c91609b160c"
        },
        {
          "id": 74266,
          "person": {
            "id": 73724,
            "first_name": "Lucia",
            "last_name": "Gonzalez Quezada",
            "photo_url": "assets/ebc604b5021d4f3189d44e90f7749caa_LUCIAG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6618,
              "name": "Swing",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7207a931-88b9-4e9b-a2d4-432e30b8b871"
        },
        {
          "id": 81135,
          "person": {
            "id": 80504,
            "first_name": "Lucila",
            "last_name": "Martinez Lopez",
            "photo_url": "assets/e2ff2a15d9a64f34bc8b993c345f8e0c_LucilaMartinezLopezHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "68891e66-a80a-4a3d-b08c-83c294795d5e"
        },
        {
          "id": 69686,
          "person": {
            "id": 69151,
            "first_name": "Ludwin",
            "last_name": "Otalora Agusto",
            "photo_url": "assets/17048619aec54bef80b3ebcb68b9e884_IMG_20221108_9248_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-04-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "293e0083-5044-403d-a04d-08e35430b9ce"
        },
        {
          "id": 74316,
          "person": {
            "id": 73774,
            "first_name": "Lugarda",
            "last_name": "Mora Gomez",
            "photo_url": "assets/9f6e294b00804905960dccefbe3c74c8_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39affd43-baf0-43cf-bbf3-89d5a3f8257a"
        },
        {
          "id": 74252,
          "person": {
            "id": 73710,
            "first_name": "Luis",
            "last_name": "Galvez",
            "photo_url": "assets/69f2dce04bf246eea6171cb1cfd8eb47_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2864db79-877e-448b-9975-ba3966393614"
        },
        {
          "id": 74340,
          "person": {
            "id": 73798,
            "first_name": "Luis",
            "last_name": "Gonzalez",
            "photo_url": "assets/3ed17ca81bc74224aa52b3dea51b7b9e_LUISGONZO_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07023189-31ad-4b58-b0a4-4c905cba1ace"
        },
        {
          "id": 75048,
          "person": {
            "id": 74502,
            "first_name": "Luis",
            "last_name": "Gualsaqui Panama",
            "photo_url": "assets/97766440e5d541f9a47f1bedaab70560_LuisPanamaPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7408,
              "name": "Swing",
              "contract": {
                "id": 5491,
                "name": "Shoreview Data Center",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bb785cef-87b8-435e-b5a6-c0cb74728cc9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b12bbd9f-661d-4504-ab58-811f3a8e4fe4"
        },
        {
          "id": 74814,
          "person": {
            "id": 74270,
            "first_name": "Luis",
            "last_name": "Medina",
            "photo_url": "assets/1da8c79134d64ab8bd0592c0fd0cbb33_LuisMedina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "00ffeb17-0f6a-45a9-8eeb-ec081778c5f4"
        },
        {
          "id": 74444,
          "person": {
            "id": 73902,
            "first_name": "Luis",
            "last_name": "Nuno",
            "photo_url": "assets/b6eb6bddfe0244d08c1b57e7e25a517c_LUISNUNO_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bf90226d-e68a-47b4-886c-d88f3020a40d"
        },
        {
          "id": 37802,
          "person": {
            "id": 37517,
            "first_name": "Luis",
            "last_name": "Romero Quizhpi",
            "photo_url": "assets/1595430877.3264318_Resized_20200629_1454501_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2654,
              "name": "Swing",
              "contract": {
                "id": 1464,
                "name": "Wayzata",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a9f6241-ff5f-4429-83f1-96170e1ba9e8"
        },
        {
          "id": 75486,
          "person": {
            "id": 74951,
            "first_name": "Luis",
            "last_name": "Torres",
            "photo_url": "assets/3b11dedbb52f46eb91ffcc68a05b1e3c_luis111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30cfd054-3cbd-4864-9b75-1e8d63d86d48"
        },
        {
          "id": 74889,
          "person": {
            "id": 74345,
            "first_name": "Luis",
            "last_name": "Vazquez",
            "photo_url": "assets/c485321de6cb4f738e5ab1dab3b42111_IMG_0011_t.jpg",
            "phone_number": "+16508237365",
            "email_address": "lvazquez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 6094,
              "name": "Swing",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a169c4a6-ef6d-417d-8630-a7f49cb63b6d"
        },
        {
          "id": 70978,
          "person": {
            "id": 70441,
            "first_name": "Luz",
            "last_name": "Reyes",
            "photo_url": "assets/aeb1f496e4754f1a85e5cc9cfceeecb8_LuzReyesChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f21bbe80-6b7a-4109-a2e4-93d7da2b3cae"
        },
        {
          "id": 46511,
          "person": {
            "id": 46033,
            "first_name": "Luz",
            "last_name": "Vega",
            "photo_url": "assets/1619619178.2849672_20210428_100849_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5455b8f-05bf-4c5f-bf18-000074aee0fd"
        },
        {
          "id": 81260,
          "person": {
            "id": 80628,
            "first_name": "Lydia",
            "last_name": "Emmott",
            "photo_url": "assets/4b4191b2a78c4809b0b0fff1a4b45db8_IMG_0654lydiaE_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0de9a225-9be5-416d-a07f-94a8bf1a4d0a"
        },
        {
          "id": 78572,
          "person": {
            "id": 77978,
            "first_name": "Lynette",
            "last_name": "Greene",
            "photo_url": "assets/c8c43c781f9045cf8e2639eb322ba2a5_Lynettebadgephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3000,
              "name": "Swing",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b43469f1-d43c-48c7-8292-ab184862fcf4"
        },
        {
          "id": 80924,
          "person": {
            "id": 80294,
            "first_name": "Lynyatta",
            "last_name": "Fleming",
            "photo_url": "assets/9c5fa2afd4ef49a78a3e3abccd7ca6e7_FlemingLynyatta251441image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ade27fa-0520-4aef-9d49-559c22153c9b"
        },
        {
          "id": 74431,
          "person": {
            "id": 73889,
            "first_name": "Ma",
            "last_name": "De Leon",
            "photo_url": "assets/e5d5001f231b4c058aa56ed1418ef277_MARIADELEON_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb89c88a-bdce-4c4e-b53e-e35f7685e7c2"
        },
        {
          "id": 46479,
          "person": {
            "id": 46001,
            "first_name": "Maher",
            "last_name": "Al Waeel",
            "photo_url": "assets/1617317482.0149071_IMG_4306_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b638d06-4f2f-42a6-8cc4-00efa0ff7e61"
        },
        {
          "id": 74466,
          "person": {
            "id": 73924,
            "first_name": "Ma Leonila",
            "last_name": "Duran Penaloza",
            "photo_url": "assets/6164ab65a29f444bb4879cdc65c9c0f1_LEONILAD_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b41e60b1-2a5c-4971-ab12-4f853e01b0dc"
        },
        {
          "id": 81388,
          "person": {
            "id": 80757,
            "first_name": "Manuel",
            "last_name": "Eagen",
            "photo_url": "assets/6bdb4d25421e497c9cd3ff309caa4bbf_ManuelEagenPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9230a83c-884d-4a95-9758-78a69dc686fd"
        },
        {
          "id": 74106,
          "person": {
            "id": 73564,
            "first_name": "Manuela",
            "last_name": "Tapia Estolano",
            "photo_url": "assets/2f157177a51240c48ec15b4c5a2c4217_MANUELATAPIA_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aecfd690-bce7-415b-bf78-869aeab265e8"
        },
        {
          "id": 80062,
          "person": {
            "id": 79437,
            "first_name": "Marbella",
            "last_name": "Vargas",
            "photo_url": "assets/2a7995b83e94493198e74bd3c993ef8b_Marbella_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7210,
              "name": "None",
              "contract": {
                "id": 5515,
                "name": "Palo Alto",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd307121-e1a5-4096-add0-b8b735b12289"
        },
        {
          "id": 75046,
          "person": {
            "id": 74500,
            "first_name": "Marcelina",
            "last_name": "Torres Ortiz",
            "photo_url": "assets/e5733357690e49d2a48495ecf8aa8213_maercelina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25bddb92-3411-48f0-83c5-55feb17142e5"
        },
        {
          "id": 68089,
          "person": {
            "id": 67547,
            "first_name": "Marcello",
            "last_name": "Ulmer",
            "photo_url": "assets/r7817e685bd7145238512cbde746d347f_SelfPortrait1_t.jpg",
            "phone_number": "3478606037",
            "email_address": "mulmer@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7665,
              "name": "Swing",
              "contract": {
                "id": 5714,
                "name": "New York",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "125a007d-6dec-4372-b61b-a7114b5f7990",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-02-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a464ac46-e192-4f3c-96af-08ede33e29a8"
        },
        {
          "id": 74111,
          "person": {
            "id": 73569,
            "first_name": "Marcos",
            "last_name": "Escamilla Millan",
            "photo_url": "assets/f7f680d5356f4188876ee1087d96293a_MARCOSES_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07980263-dc47-45c5-9d4e-9ead1362d2aa"
        },
        {
          "id": 78869,
          "person": {
            "id": 78268,
            "first_name": "Marcos",
            "last_name": "Zoquier",
            "photo_url": "assets/00c6b4766bad4c6b8ae8d74dcad83130_20221205_1412081_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 11,
              "name": "Cage Washer",
              "name_abbreviation": "Cage Washer"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca489ab8-4a35-4334-b6f4-75e16361a850"
        },
        {
          "id": 74354,
          "person": {
            "id": 73812,
            "first_name": "Margarita",
            "last_name": "Cardenas",
            "photo_url": "assets/e3ed757531b34d4292e3e52f32294ddc_MARGARITACAR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "17b5d403-a943-4ac8-9114-4580f2c68945"
        },
        {
          "id": 68419,
          "person": {
            "id": 67876,
            "first_name": "Margarita",
            "last_name": "Martinez Sanchez",
            "photo_url": "assets/a4078d742c0946299816cc9aef4350a5_output_image1651776412182001_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-02-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8b06b55-5061-4cb6-9be4-8acb0c1d7f9a"
        },
        {
          "id": 74112,
          "person": {
            "id": 73570,
            "first_name": "Margarita",
            "last_name": "Santillan Flores",
            "photo_url": "assets/c308d6671e5f455ab206b22aea7b1d39_MARGARITAS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf075808-2b83-48db-9115-e1f4f1f7d00e"
        },
        {
          "id": 74130,
          "person": {
            "id": 73588,
            "first_name": "Margarita",
            "last_name": "Solis Hernandez",
            "photo_url": "assets/e1dd05f58ec14f20b5f6b2b4a62badb7_MARGARITAS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2956a17-ba93-4f45-9d53-4052fee43a5a"
        },
        {
          "id": 75037,
          "person": {
            "id": 74491,
            "first_name": "Margarito",
            "last_name": "Soriano Magdaleno",
            "photo_url": "assets/3b414ad7db414baf9ff3690d93b0d72a_MargaritoSoriano_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3001,
              "name": "Swing",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fbafca8a-9cc6-4b92-bb5a-271fd2ff0d01"
        },
        {
          "id": 74435,
          "person": {
            "id": 73893,
            "first_name": "Maria",
            "last_name": "Alcoser",
            "photo_url": "assets/70af30b68bde4e569d4884031a3f0023_Capturad_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19efbf4a-1382-4bdc-9ff9-0536b4b6836c"
        },
        {
          "id": 11433,
          "person": {
            "id": 11433,
            "first_name": "Maria",
            "last_name": "Aquino Rosas",
            "photo_url": "644f55f8-50d3-9c33-2383-622e8ea1cdfb.png",
            "phone_number": "",
            "email_address": "227552@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9503eb02-4f29-4ac8-bec6-133367f2417b"
        },
        {
          "id": 70244,
          "person": {
            "id": 69705,
            "first_name": "Maria",
            "last_name": "Arellano Ramos",
            "photo_url": "assets/a1612b90c7b147b8a24e344de97b843c_lupe_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60f10318-29d5-4ed8-b235-8300857e5069"
        },
        {
          "id": 74138,
          "person": {
            "id": 73596,
            "first_name": "Maria",
            "last_name": "Arias",
            "photo_url": "assets/d152fb0c308c480fa18e88da490e9927_MARIAARIAS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b00ce326-76cc-461f-8075-cd2db007e543"
        },
        {
          "id": 74160,
          "person": {
            "id": 73618,
            "first_name": "Maria",
            "last_name": "Barrera Del Carmen",
            "photo_url": "assets/de0d8e52b4344c20b22df8267f5a4ed5_MARIABARRERA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89a1ce4f-514a-4f67-b040-6063dd23395f"
        },
        {
          "id": 74402,
          "person": {
            "id": 73860,
            "first_name": "Maria",
            "last_name": "Barrera Salazar",
            "photo_url": "assets/00eaa4d4604c4d3db1680e126064a889_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "163fcf0f-19d3-4dc8-a6ed-480939846eb7"
        },
        {
          "id": 47917,
          "person": {
            "id": 47429,
            "first_name": "Maria",
            "last_name": "Blanco-Mendoza",
            "photo_url": "assets/c05d1c75a2004780be13a4de052e0662_MariaBlanco_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5abf9ea-4b5d-4e00-80ed-09678da467d5"
        },
        {
          "id": 74300,
          "person": {
            "id": 73758,
            "first_name": "Maria",
            "last_name": "Brambila",
            "photo_url": "assets/c3dcc8d57b2a44cdb2650354c11b1e61_IMG_20230621_004314_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c955662-8ec3-43ee-ae70-64037545fe7e"
        },
        {
          "id": 75666,
          "person": {
            "id": 75126,
            "first_name": "Maria",
            "last_name": "Calles",
            "photo_url": "assets/5d852e5ba26e4a6db06dd721d0f8b810_MariaCallesphoto_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b3506f1c-eb4c-4a91-a0d3-c5085e3e7f95"
        },
        {
          "id": 74033,
          "person": {
            "id": 73492,
            "first_name": "Maria",
            "last_name": "Cardona",
            "photo_url": "assets/e9b6d48047fb4348901015fa38e54869_MariaCardonaTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fe31dbb2-7b8b-4978-892b-4dabd91314c2"
        },
        {
          "id": 69043,
          "person": {
            "id": 68508,
            "first_name": "Maria",
            "last_name": "Charlesman",
            "photo_url": "assets/rdd0abc53c0974ef89f3685235a430010_PXL_20211103_113948699_t.jpg",
            "phone_number": "8572729456",
            "email_address": "mcharlesman@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ffdac1a-2255-4249-ae74-2c1cb4317342"
        },
        {
          "id": 5194,
          "person": {
            "id": 5194,
            "first_name": "Maria",
            "last_name": "Chimborazo Guaman",
            "photo_url": "d3b2b942-7814-a68b-63c1-c1903e09744b.png",
            "phone_number": "",
            "email_address": "227808@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3041,
              "name": "Night",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "52de9841-62b5-44b4-8cc0-1ac9edf25db6"
        },
        {
          "id": 75485,
          "person": {
            "id": 74950,
            "first_name": "Maria",
            "last_name": "Cruz Romano",
            "photo_url": "assets/1e9e7e2098854fcaad49853af595cc3a_maraicruz1111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6fbd6fa4-4caa-4b11-bc90-49a4cf115eaf"
        },
        {
          "id": 74405,
          "person": {
            "id": 73863,
            "first_name": "Maria",
            "last_name": "Cruz Vasquez",
            "photo_url": "assets/f7038ce2ea0f458d9109d60ac4309e9e_MARIACRUZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e3e473e1-9473-4315-9f87-96c5eaa0c88d"
        },
        {
          "id": 74048,
          "person": {
            "id": 73507,
            "first_name": "Maria",
            "last_name": "Cuevas De Oller",
            "photo_url": "assets/d706ce0323e54a3480cd8aaf70006f69_MariaOmerTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b507a1b4-3fde-4c8b-b1c7-e5343522ee0f"
        },
        {
          "id": 50532,
          "person": {
            "id": 50028,
            "first_name": "Maria",
            "last_name": "De Santiago Ceballos",
            "photo_url": "assets/r2576d4b09d4340ceb71d776f5b73cd17_Maria_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d31bfd7-ecf8-4e68-b00e-54aa3b829556"
        },
        {
          "id": 70977,
          "person": {
            "id": 70440,
            "first_name": "Maria",
            "last_name": "Echeverria",
            "photo_url": "assets/51f3a1c5f0f44f8fba67db31c97510da_MariaEcheverriaChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3cb1d875-d440-4917-9a23-6caac97cb37f"
        },
        {
          "id": 74170,
          "person": {
            "id": 73628,
            "first_name": "Maria",
            "last_name": "Figueroa",
            "photo_url": "assets/a5cca654fa6a4d60b4c403ab6e7ff905_MARIAFIG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b71456cd-7a9b-423a-8b14-98280abb420a"
        },
        {
          "id": 74175,
          "person": {
            "id": 73633,
            "first_name": "Maria",
            "last_name": "Garcia",
            "photo_url": "assets/ee9b488839ed4bd6b4940ac4b9e5e822_MARIAGARCIA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd43ab5f-9990-4f64-93ee-5b227a4a727c"
        },
        {
          "id": 74339,
          "person": {
            "id": 73797,
            "first_name": "Maria",
            "last_name": "Grajeda",
            "photo_url": "assets/5a306998d96941a6b8c11502f2678ce5_MARIAGRAJEDA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6618,
              "name": "Swing",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "23aa012a-dfeb-4855-90fe-2e0d9caf2781"
        },
        {
          "id": 74338,
          "person": {
            "id": 73796,
            "first_name": "Maria",
            "last_name": "Granados Guillen",
            "photo_url": "assets/ef5fc660a7df4dd3bc7c050501597cdb_MARIAGG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89c0f623-794f-4c3e-86e3-5f60c2ce952d"
        },
        {
          "id": 79768,
          "person": {
            "id": 79149,
            "first_name": "Maria",
            "last_name": "Gutierrez",
            "photo_url": "assets/2f4d4db9a31d499e806d9b5e0bd8b528_MariaGutierrezPhoto_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2371,
              "name": "Swing",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cbc53a02-566b-486c-a8ae-9edfddc8a04b"
        },
        {
          "id": 81263,
          "person": {
            "id": 80631,
            "first_name": "Maria",
            "last_name": "Harmon",
            "photo_url": "assets/4677a2aa1d3844b383354aee4a1a7306_mariaharmon_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "23d19628-09da-473b-9070-5b0cbaa6fcc7"
        },
        {
          "id": 74242,
          "person": {
            "id": 73700,
            "first_name": "Maria",
            "last_name": "Hernandez Rodriguez",
            "photo_url": "assets/58bfb972b7554d47bde44b869898d9c2_Image_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7598b447-2af3-4f0e-abe0-eb454fe79934"
        },
        {
          "id": 53720,
          "person": {
            "id": 53178,
            "first_name": "Maria",
            "last_name": "Leon Castillo",
            "photo_url": "assets/93a651f8d5c64f95a84078c796a76fc2_MariaLeonCastilloPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3164,
              "name": "Swing",
              "contract": {
                "id": 2643,
                "name": "Lincoln South",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8836a4e9-3215-443a-88db-4d1d64c95b32"
        },
        {
          "id": 47270,
          "person": {
            "id": 46784,
            "first_name": "Maria",
            "last_name": "Leyva Machado",
            "photo_url": "assets/55aaa4b3ab8645fcaaab5d033795e520_MariaL_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2817,
              "name": "Day",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31b8f699-175f-43f8-9f78-0a46b4e8c0bc"
        },
        {
          "id": 74141,
          "person": {
            "id": 73599,
            "first_name": "Maria",
            "last_name": "Lovejoy",
            "photo_url": "assets/2a057206e17a4b99bf7119b43621347b_MARIALOVEJ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06e807af-e9ca-4c48-88e7-b769beae4968"
        },
        {
          "id": 80063,
          "person": {
            "id": 79438,
            "first_name": "Maria",
            "last_name": "Magana",
            "photo_url": "assets/f54c73c12f7d445283ac8d0a2dab84e2_MariaMagana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8499,
              "name": "Swing",
              "contract": {
                "id": 5515,
                "name": "Palo Alto",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd7ab8e6-3df6-4c30-beb8-b70fbe3da863"
        },
        {
          "id": 54184,
          "person": {
            "id": 53641,
            "first_name": "Maria",
            "last_name": "Martinez Salgado",
            "photo_url": "assets/8ff9b8e984f64a81b0c6b2f9677b4d6b_MariaMartinezSalgado_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7890,
              "name": "Day",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b235b01-9b2d-4827-b6a0-dd72bbc687b2"
        },
        {
          "id": 47727,
          "person": {
            "id": 47242,
            "first_name": "Maria",
            "last_name": "Matamoros",
            "photo_url": "assets/699f5a610b1c430897f603e622b9f34b_MariaMatamoros_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-04-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "040c27ad-2cd0-4730-ad26-f78f93f66c1a"
        },
        {
          "id": 74284,
          "person": {
            "id": 73742,
            "first_name": "Maria",
            "last_name": "Miranda-Vargas",
            "photo_url": "assets/943e6156a6be40f18fd6b7dd05e25cae_MARIAMIRANDA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76f0d0fd-856d-4d8c-8dd4-2fbd8331f159"
        },
        {
          "id": 69601,
          "person": {
            "id": 69066,
            "first_name": "Maria",
            "last_name": "Morales",
            "photo_url": "assets/0c73324255124c84847159a0dde5471c_20220420_202009361_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2418,
              "name": "Swing",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01ba56f5-3927-4984-bced-bb4c408e1131"
        },
        {
          "id": 48998,
          "person": {
            "id": 48504,
            "first_name": "Maria",
            "last_name": "Moreno",
            "photo_url": "assets/92ce78a9f33d4a489cee6e8bd373056c_MariaMoreno_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c937d70-be40-41d4-9c17-27cfd5895f0c"
        },
        {
          "id": 74254,
          "person": {
            "id": 73712,
            "first_name": "Maria",
            "last_name": "Moreno Garcia",
            "photo_url": "assets/30ff5dc17ba64f44b83435c3459abb31_DEFB0E6674584B31941CA38ACC4659FD_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "889e4ded-3919-4542-a67c-c3aaf0a86ee2"
        },
        {
          "id": 77946,
          "person": {
            "id": 77362,
            "first_name": "Maria",
            "last_name": "Mota",
            "photo_url": "assets/c08b3b4eb1b0462b96c8a905cff1aeb2_mariamotainsitephoto_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "644da751-eeae-4e3c-bfa6-7585c4082111"
        },
        {
          "id": 74322,
          "person": {
            "id": 73780,
            "first_name": "Maria",
            "last_name": "Navarrete De Cecena",
            "photo_url": "assets/85cb234bfc23433f910323117d8333bb_mariacesena_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7db5cb83-e52f-45d2-9986-d5efa254cb7d"
        },
        {
          "id": 46216,
          "person": {
            "id": 45754,
            "first_name": "Maria",
            "last_name": "Orellana",
            "photo_url": "assets/56daebcba5934ba7820efeba16c3ca00_Maria_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2817,
              "name": "Day",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f35114e-73a6-41db-a717-c6acfe164a6b"
        },
        {
          "id": 74943,
          "person": {
            "id": 74399,
            "first_name": "Maria",
            "last_name": "Penaloza",
            "photo_url": "assets/53438e0af93d4d3d8d5e75d2e5d1482b_MariaPenaloza_1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca2bf0be-fdbd-4e20-acda-a4bdddad3f5e"
        },
        {
          "id": 74328,
          "person": {
            "id": 73786,
            "first_name": "Maria",
            "last_name": "Polanco Solis",
            "photo_url": "assets/7a72ea17b5f44eed8a337132fbdbff79_MARIAPOLAN_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9940a980-148b-4659-b985-9e84405089c6"
        },
        {
          "id": 25461,
          "person": {
            "id": 25341,
            "first_name": "Maria",
            "last_name": "Quizhpi Quizhpi",
            "photo_url": "assets/1589375992.52344_Resized_20200512_150605_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "94293b5a-d18a-413b-829e-8c730a00f280"
        },
        {
          "id": 74380,
          "person": {
            "id": 73838,
            "first_name": "Maria",
            "last_name": "Rivera",
            "photo_url": "assets/66825c9bdde84ebd8412e753002429e7_MARIARIVERA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37d6c094-4edf-4cd2-9dff-ac3aa7b38012"
        },
        {
          "id": 74387,
          "person": {
            "id": 73845,
            "first_name": "Maria",
            "last_name": "Robles",
            "photo_url": "assets/5b5543aaf60d451ba8196568907c8c03_MARIAROBELS__t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "64e70222-7dd5-470d-b9e8-27e5ebb3252d"
        },
        {
          "id": 46323,
          "person": {
            "id": 45854,
            "first_name": "Maria",
            "last_name": "Rodriguez",
            "photo_url": "assets/a037d6d6abc942afa89bdb8ae6a6ec80_mariarodrigez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c311a274-2efe-4361-8e2f-e2b39e84a773"
        },
        {
          "id": 54729,
          "person": {
            "id": 54184,
            "first_name": "Maria",
            "last_name": "Rodriguez",
            "photo_url": "assets/9369591630d249b89e2cdf7dbe3ab0f7_Mariarodriguez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2ff2da39-c6ef-46f5-beab-c4e2dd44a38b"
        },
        {
          "id": 76569,
          "person": {
            "id": 76001,
            "first_name": "Maria",
            "last_name": "Rosario",
            "photo_url": "assets/bf52ea48e3fe451abc0b8037e76586f0_thumbnail_Image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8300d777-c8c6-43a3-8a05-3610f9c832b1"
        },
        {
          "id": 1960,
          "person": {
            "id": 1960,
            "first_name": "Maria",
            "last_name": "Sabatino",
            "photo_url": "assets/7a76dba173974e87a4a85aa70cdff2b5_Maria_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 5201,
              "name": "Swing",
              "contract": {
                "id": 2633,
                "name": "Concordville, PA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "efe9ee9c-dbf0-4473-b131-da857d7b6c95"
        },
        {
          "id": 55060,
          "person": {
            "id": 54514,
            "first_name": "Maria",
            "last_name": "Sabori Ochoa",
            "photo_url": "assets/4086ff807346453fb09294b347d00ae6_Mariainsitepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c683810a-1892-4741-981b-c9f81a415ecb"
        },
        {
          "id": 73954,
          "person": {
            "id": 73414,
            "first_name": "Maria",
            "last_name": "Sanchez",
            "photo_url": "assets/r8e773744dd6b42729eaf8d768823c219_mariasanchez4insitepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d95a746-fce0-42a9-a7fc-20cea0c7a19d"
        },
        {
          "id": 67606,
          "person": {
            "id": 67064,
            "first_name": "Maria",
            "last_name": "Sandoval",
            "photo_url": "assets/4d0942df87b84cde972f4906adb56ceb_IMG_0642_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8535,
              "name": "Day",
              "contract": {
                "id": 1811,
                "name": "Paseo",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "685ec8d0-0246-4b4c-baa1-6c43c9d2acbf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dbae8d38-0efb-41ca-8711-15566170a09f"
        },
        {
          "id": 69144,
          "person": {
            "id": 68609,
            "first_name": "Maria",
            "last_name": "Torres",
            "photo_url": "assets/880628ffbfda4cf9970a9cab104ebe7b_MariaTorres_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-03-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9267904f-dd9c-485f-900a-64b3dc3a2ad0"
        },
        {
          "id": 46904,
          "person": {
            "id": 46419,
            "first_name": "Maria",
            "last_name": "Uribe",
            "photo_url": "assets/1618352571.738726_1234_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2346,
              "name": "Day",
              "contract": {
                "id": 2439,
                "name": "Oakland, CA",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2464741c-d44b-4bf0-a91d-3fe6e754ff3d"
        },
        {
          "id": 74036,
          "person": {
            "id": 73495,
            "first_name": "Maria",
            "last_name": "Valdez",
            "photo_url": "assets/42669aa9b70445e7b48769415c859c7f_MariaValdezTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a11a8f10-0a3e-46b1-a757-f5e2dc9e7f08"
        },
        {
          "id": 70975,
          "person": {
            "id": 70438,
            "first_name": "Maria",
            "last_name": "Villegas",
            "photo_url": "assets/304317c2dfa7410aaf7b27c86f389f3f_MariaVillegasChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8453fb6-aad8-4114-84eb-bf520636a25f"
        },
        {
          "id": 74258,
          "person": {
            "id": 73716,
            "first_name": "Maria",
            "last_name": "Zepeda De Rivera",
            "photo_url": "assets/85f88e97130c4b6ea728faeffb1882b8_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09725f54-9e43-499e-8896-ee5619fdce39"
        },
        {
          "id": 74166,
          "person": {
            "id": 73624,
            "first_name": "Maria Gloria",
            "last_name": "Fernandez Barreto",
            "photo_url": "assets/c56da3fe53fa459b83deee24d5e032c8_MariaFernandez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e207e48f-b10f-402d-8e26-760b84f51d7a"
        },
        {
          "id": 46520,
          "person": {
            "id": 46042,
            "first_name": "Mariana",
            "last_name": "Gayos",
            "photo_url": "assets/1618403489.66704_MarianaProfilePic_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e1e8e537-d67f-48df-b258-b2765cbf244d"
        },
        {
          "id": 47111,
          "person": {
            "id": 46625,
            "first_name": "Mariana",
            "last_name": "Rivera",
            "photo_url": "assets/1619489269.0575795_mariana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2719,
              "name": "Swing",
              "contract": {
                "id": 2348,
                "name": "Reno, NV",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cfb2e6e4-b044-464f-9375-4aa5185f91a4"
        },
        {
          "id": 74432,
          "person": {
            "id": 73890,
            "first_name": "Maribel",
            "last_name": "Hernandez",
            "photo_url": "assets/d44d88cc3d784c048501dba8c43cac5d_1000000741_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "94270d83-6034-488d-b3f9-ca2617757f50"
        },
        {
          "id": 46324,
          "person": {
            "id": 45855,
            "first_name": "Maribel",
            "last_name": "Valdez",
            "photo_url": "assets/1617310153.8779223_Resized_20210401_163605_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ad73fb0-b4b6-445e-8711-5dac33c143eb"
        },
        {
          "id": 45843,
          "person": {
            "id": 45392,
            "first_name": "Maribel",
            "last_name": "Velarde",
            "photo_url": "assets/1616080499.1321652_MaribelPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8b985c39-6cba-4a23-a664-af4f8c7145cf"
        },
        {
          "id": 74497,
          "person": {
            "id": 73955,
            "first_name": "Maricela",
            "last_name": "Celis Meza",
            "photo_url": "assets/74e13fb7844242ad84470f57bb7862da_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8927c5df-e495-42ee-aed5-22b0c9018677"
        },
        {
          "id": 70388,
          "person": {
            "id": 69853,
            "first_name": "Maricela",
            "last_name": "Gomez",
            "photo_url": "assets/2fabb0bd16b0433e86927703036034b2_Resized_Resized_20220913_051225002.jpegmaricela_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3acaa016-513f-43e6-9c0a-a98f901c9fe9"
        },
        {
          "id": 74438,
          "person": {
            "id": 73896,
            "first_name": "Maricela",
            "last_name": "Hernandez",
            "photo_url": "assets/reb26a4e6652d4fbf96c05a48cd573618_IMG_0046_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "981fe2b2-fb5c-4144-8d14-9b8ce4004dbe"
        },
        {
          "id": 81112,
          "person": {
            "id": 80481,
            "first_name": "Marie",
            "last_name": "Ndobe",
            "photo_url": "assets/1975968536b845c6b7b3eb8c734d7fc2_MarieNdobeCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e73fc517-7c44-4140-8120-b2bb03b865d9"
        },
        {
          "id": 74120,
          "person": {
            "id": 73578,
            "first_name": "Marina",
            "last_name": "Patino",
            "photo_url": "assets/re45bd057b84a4b79958f8ff82b98fbc0_IMG_0398_t.jpg",
            "phone_number": "+16698371891",
            "email_address": "247558@placeholder.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d0f7217a-39b3-4c2f-bd82-f2b9da3a10ac"
        },
        {
          "id": 43164,
          "person": {
            "id": 42739,
            "first_name": "Mario",
            "last_name": "Celis Acosta",
            "photo_url": "assets/1607866820.7468715_mario_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc1c9c65-0e03-486e-b7c8-4a340cecd054"
        },
        {
          "id": 74741,
          "person": {
            "id": 74198,
            "first_name": "Mario",
            "last_name": "Figueroa",
            "photo_url": "assets/9e3e74d6045e49a2a8cb2e87940041d5_MarioFigueroa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2dbefe58-b939-4c13-9dca-ac1be658beb8"
        },
        {
          "id": 74351,
          "person": {
            "id": 73809,
            "first_name": "Mario",
            "last_name": "Rivera",
            "photo_url": "assets/d39e80f071434054b94443353313450e_mariorivera_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39dca5eb-8ef6-4e41-af0f-51557f0e7eed"
        },
        {
          "id": 74271,
          "person": {
            "id": 73729,
            "first_name": "Mario",
            "last_name": "Zapanta",
            "photo_url": "assets/545a3ae4d4364755b3584c3c29543bfe_mariozap_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1956df80-35ac-47fd-b70a-3f0c8d61e8c0"
        },
        {
          "id": 74413,
          "person": {
            "id": 73871,
            "first_name": "Mario",
            "last_name": "Zarate Montes",
            "photo_url": "assets/893da50ae1854795b7155640bd34b989_marioazarate_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e12fa519-bdc1-44a1-a323-cfd549d06a22"
        },
        {
          "id": 75488,
          "person": {
            "id": 74953,
            "first_name": "Mario Ernesto",
            "last_name": "Gonzalez Pacheco",
            "photo_url": "assets/a7035ca55a4e4249ba78ea2c7d56b56f_mario111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc53dac2-83bb-45f4-9989-8d5d80ca334d"
        },
        {
          "id": 74227,
          "person": {
            "id": 73685,
            "first_name": "Marisol",
            "last_name": "Garcia",
            "photo_url": "assets/05f6b1f8d3ed4e8984b3493e4fdf21b5_Marisol_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            },
            {
              "id": 2546,
              "name": "Restroom Attendant",
              "name_abbreviation": "RRMATT"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "344eb4a6-c206-4682-843c-13a2921f1152"
        },
        {
          "id": 81264,
          "person": {
            "id": 80632,
            "first_name": "Maritza",
            "last_name": "Samayoa de Chavez",
            "photo_url": "assets/cc0aeb2058c346da9d86270e3df359dd_IMG_0644Samayoa_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4469c931-6717-4247-91f3-f68cc5b2dbb6"
        },
        {
          "id": 73173,
          "person": {
            "id": 72634,
            "first_name": "Marlene",
            "last_name": "Gutierrez",
            "photo_url": "assets/b22ebe81fdf648bf9158ec415cba611b_IMG_01121_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7745,
              "name": "Swing",
              "contract": {
                "id": 1819,
                "name": "Sun City",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c5f394b-c86a-4175-a185-a1e33ac0420f"
        },
        {
          "id": 47730,
          "person": {
            "id": 47245,
            "first_name": "Marlon",
            "last_name": "Guillen",
            "photo_url": "assets/1619789457.9172907_MarlonGuillen_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2de07cc-5096-48e0-bc15-0cfae66ea237"
        },
        {
          "id": 74245,
          "person": {
            "id": 73703,
            "first_name": "Marta",
            "last_name": "Mendez Navarrete",
            "photo_url": "assets/4bfccc16c3544c14b068a2b9f84f2fa6_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ef76ecb3-19e2-4d1f-8158-1fa19e1a093d"
        },
        {
          "id": 74458,
          "person": {
            "id": 73916,
            "first_name": "Marta",
            "last_name": "Oliva",
            "photo_url": "assets/d8ed47dca782449b9409750c9d754179_MARTAOILVIA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "246af3bb-13d6-4e40-9e89-757f179f3ada"
        },
        {
          "id": 46476,
          "person": {
            "id": 45998,
            "first_name": "Marta",
            "last_name": "Reyes",
            "photo_url": "assets/cf9fbec7153e453aa4e69e69364d0824_reyes_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5749b0e1-85f2-4dd4-a72e-eae8d8d7ba4e"
        },
        {
          "id": 70982,
          "person": {
            "id": 70445,
            "first_name": "Marta Lilian",
            "last_name": "Martinez",
            "photo_url": "assets/ee9a29a0da1b42e19fa11bdca36012be_MartaLMartinezChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e5f7a60-8486-412e-85da-2bf91333e232"
        },
        {
          "id": 74214,
          "person": {
            "id": 73672,
            "first_name": "Martha",
            "last_name": "Navarro",
            "photo_url": "assets/268c1d2dc2744493b7d873d67226b1e3_MIRIAMN_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6618,
              "name": "Swing",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3a0c30f-5ad6-4fed-8a9d-f3918bb0b304"
        },
        {
          "id": 74460,
          "person": {
            "id": 73918,
            "first_name": "Martha Galvez",
            "last_name": "Sanchez",
            "photo_url": "assets/987afbadaeb1437696637dbbdaf39fe9_marthasan_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac1b7a42-2f20-447f-8408-6d74326df8c2"
        },
        {
          "id": 46007,
          "person": {
            "id": 45548,
            "first_name": "Martin",
            "last_name": "Flores",
            "photo_url": "assets/a8b4a8257ebf464ca5f8c27a3d28e4eb_20210914_060650_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e47c3858-b522-4111-b8f2-43eb2e42622c"
        },
        {
          "id": 74032,
          "person": {
            "id": 73491,
            "first_name": "Martina",
            "last_name": "Camarillo",
            "photo_url": "assets/5e13a16f03154fd39a58859078ca9526_1000002709_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8801851a-ab91-41be-8158-12e53e89e5d8"
        },
        {
          "id": 58440,
          "person": {
            "id": 57904,
            "first_name": "Martina",
            "last_name": "Vergara",
            "photo_url": "assets/23dbe54a93894e928984924926c6ac78_MVphoto_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b82de242-88aa-44e5-83ba-27b94d24a322"
        },
        {
          "id": 81136,
          "person": {
            "id": 80505,
            "first_name": "Mary",
            "last_name": "Mayen",
            "photo_url": "assets/5bbe46ceee8143649b4238ac31a20bd4_MaryMahenCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bfb91e10-c239-4bac-9443-1be111570d94"
        },
        {
          "id": 63475,
          "person": {
            "id": 62939,
            "first_name": "Mary",
            "last_name": "Moses",
            "photo_url": "assets/f0651cd0016e4dab9a281ba360016f52_Marysphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4525,
              "name": "Day",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5e7a042-7421-4d84-9cfb-6389a3e0ef2b"
        },
        {
          "id": 49694,
          "person": {
            "id": 49194,
            "first_name": "Mary",
            "last_name": "Ruz",
            "photo_url": "assets/31db400d896c4872ba85bdf22eb8eda3_1000004574_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-06-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "597d8b61-26b0-4ddc-aa40-872e2c5d7c46"
        },
        {
          "id": 74044,
          "person": {
            "id": 73503,
            "first_name": "Mary",
            "last_name": "Sosa",
            "photo_url": "assets/9a61837cbd084303b47b41bf7fc57cb4_MaryASosaTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "807bb817-095e-4a8b-bfd9-4be3d48bdf81"
        },
        {
          "id": 74260,
          "person": {
            "id": 73718,
            "first_name": "Matilde",
            "last_name": "Garcia Sanchez",
            "photo_url": "assets/2b159a5c9e7a4c4997cf694bf7f162a5_MATILDEG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "70c3473f-40e3-4deb-9af4-e395e7252559"
        },
        {
          "id": 73885,
          "person": {
            "id": 73346,
            "first_name": "Matthew",
            "last_name": "Borsick",
            "photo_url": "assets/e885d28c1ee54708a7b4e945a938bc60_BorsickProfessionProfilePicture_t.jpg",
            "phone_number": "9195001228",
            "email_address": "mborsick@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5afc729f-19cc-4c45-bb12-b6f41fa09251"
        },
        {
          "id": 80268,
          "person": {
            "id": 79640,
            "first_name": "Matthew",
            "last_name": "Porter",
            "photo_url": "assets/07ef41ed4ca644d3a20aa46be4ee1863_MattPorterPhoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea658b2b-e49c-4baf-b48b-c6ea5a8ef956"
        },
        {
          "id": 46480,
          "person": {
            "id": 46002,
            "first_name": "Maurice",
            "last_name": "Garcia",
            "photo_url": "assets/2adfe5718afb49ca96b7903d220c092a_Mauricephoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a252938-3a08-4272-ad69-01e8ee27ecd4"
        },
        {
          "id": 45547,
          "person": {
            "id": 45103,
            "first_name": "Maxwell",
            "last_name": "Leggiero",
            "photo_url": "assets/04de87cd26944b168b6c9c075be26fb4_08.2022_t.SBM",
            "phone_number": "1(425) 444-5781",
            "email_address": "mleggiero@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5507db1f-3202-4326-bae0-b4ffe2d2ebd7"
        },
        {
          "id": 70973,
          "person": {
            "id": 70436,
            "first_name": "Mayra",
            "last_name": "Castaneda",
            "photo_url": "assets/df1b5e2b450348efa3f2171c49fa7ce7_MayraCastanedaChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f41a3670-9d14-4986-805a-7a5dc8165c7a"
        },
        {
          "id": 51649,
          "person": {
            "id": 51129,
            "first_name": "Mayra",
            "last_name": "Hinojosa Rodriguez",
            "photo_url": "assets/91fab42eee1b41869c781db1edc916de_IMG_20221108_9641_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-09-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd626ec3-b52d-48b9-8cd1-28b0c480ed53"
        },
        {
          "id": 68263,
          "person": {
            "id": 67720,
            "first_name": "Melanie",
            "last_name": "Swords",
            "photo_url": "",
            "phone_number": "2488967012",
            "email_address": "mswords@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2077,
              "name": "Day",
              "contract": {
                "id": 1701,
                "name": "DIV007",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "32ffcc81-b133-43fb-9065-ab51728b919f",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1523,
              "name": "Day",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8962,
              "name": "Unassigned",
              "contract": {
                "id": 6672,
                "name": "DET2 – Utica, MI",
                "customer_id": 24,
                "customer_name": "Amazon",
                "uuid": "6ec58c2d-6467-4f49-bdb4-291e6a5c3ca8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1279,
              "name": "Unassigned Detroit MI",
              "contract": {
                "id": 2084,
                "name": "Detroit, MI",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "810946b0-f603-405f-a1f7-c5bc7f28901d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2022-02-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f272bba2-dd5f-4bbe-a587-259b90db2a22"
        },
        {
          "id": 81268,
          "person": {
            "id": 80636,
            "first_name": "Melisa",
            "last_name": "Munoz",
            "photo_url": "assets/r03508031266c441a941b6fde2d972981_melisamunoz_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1f3d1f75-8b1a-4651-8088-b75c7eac0639"
        },
        {
          "id": 52842,
          "person": {
            "id": 52305,
            "first_name": "Melissa",
            "last_name": "Vasquez",
            "photo_url": "assets/acc8523138d14e23b6ec5ee96efb525a_M.Vasquezstaffphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3146,
              "name": "Day",
              "contract": {
                "id": 2627,
                "name": "Tulsa, OK",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d6f64213-b922-467f-8c79-04580edbd9ce",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "94103bb7-1d52-40fd-9a73-c4fe2c7a7eb1"
        },
        {
          "id": 46686,
          "person": {
            "id": 46205,
            "first_name": "Meredith",
            "last_name": "Martin",
            "photo_url": "assets/093b4055047646379f6a546607eba4a7_mer_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c50ec955-5625-4b24-89d5-aa4f40a23e1b"
        },
        {
          "id": 74477,
          "person": {
            "id": 73935,
            "first_name": "Meron",
            "last_name": "Kelati",
            "photo_url": "assets/0a4fa744c8a34c0fade050aa2236e0ec_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6756448a-be2d-4b35-8e8c-85de71a2dfc5"
        },
        {
          "id": 75429,
          "person": {
            "id": 74894,
            "first_name": "Michael",
            "last_name": "Beason",
            "photo_url": "assets/58154ef187f449aaa6762f2114af3f70_IMG_1234_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bcafd1b8-e09a-4646-8d91-debe1806cec4"
        },
        {
          "id": 77209,
          "person": {
            "id": 76632,
            "first_name": "Michael",
            "last_name": "Bible",
            "photo_url": "assets/f7d26bf7b69c4584947c883c0053e9d6_MichaelBible_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6156,
              "name": "Day",
              "contract": {
                "id": 4125,
                "name": "York, NE",
                "customer_id": 1547,
                "customer_name": "Collins Aerospace (CBRE)",
                "uuid": "ee153d8f-a7fd-4c7f-b56b-e0060da0230e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6540b32-a2a6-481e-a8e4-6a85d95db764"
        },
        {
          "id": 69826,
          "person": {
            "id": 69289,
            "first_name": "Michael",
            "last_name": "Feltner",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4938,
              "name": "Swing",
              "contract": {
                "id": 2616,
                "name": "Groveport",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3bb6dd38-6fa1-4f73-8565-8051e79ab1be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "502638d4-508f-4fa2-8e3e-629934fc4cdf"
        },
        {
          "id": 74478,
          "person": {
            "id": 73936,
            "first_name": "Michael",
            "last_name": "Rosillo",
            "photo_url": "assets/747e2ca3297f4a8285be2cb7011190d9_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01fb5a5b-1129-4b8d-af8c-4d86f7035c47"
        },
        {
          "id": 68779,
          "person": {
            "id": 68243,
            "first_name": "Michael",
            "last_name": "Schneider",
            "photo_url": "assets/03297674f3f84e15ab518fe984c90c5b_MichaelSchneider_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a0026b4b-3fc5-449d-b469-bc22a0ede2d7"
        },
        {
          "id": 74330,
          "person": {
            "id": 73788,
            "first_name": "Michele",
            "last_name": "Quiros",
            "photo_url": "assets/08b9ff5817f349a78d4a6847a6788a09_MICHELEQUIRIOS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97e708d4-c598-498f-9df6-16e6ff576776"
        },
        {
          "id": 74546,
          "person": {
            "id": 74004,
            "first_name": "Michelle",
            "last_name": "Mungcal",
            "photo_url": "assets/r920cc6b8136c4e65aaa2aa21fa6e8762_20230620_100005_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2743,
              "name": "Conference Room Ambassador",
              "name_abbreviation": "CRAMBS"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6e8d3a8-690d-42c7-b8fc-094936cb093c"
        },
        {
          "id": 77593,
          "person": {
            "id": 77012,
            "first_name": "Miesha",
            "last_name": "Tardy",
            "photo_url": "assets/f4d465fb5d1e4344855d60bba08fcbc4_MieshasInsitePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 107,
              "name": "Glass Wash Equip Prep",
              "name_abbreviation": "GMPGW"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0a96ae76-34f8-4e63-af90-437303734007"
        },
        {
          "id": 74261,
          "person": {
            "id": 73719,
            "first_name": "Miguel",
            "last_name": "Chamale",
            "photo_url": "assets/c31c1e29321a4fc4ab3b90648102abec_MIGUELCHAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "234a2473-ae49-441d-922d-834331b20124"
        },
        {
          "id": 78777,
          "person": {
            "id": 78182,
            "first_name": "Miguel",
            "last_name": "Gutierrez Vasquez",
            "photo_url": "assets/887af00a85cd48e884cee57cd1936770_MiguelG_t.jfif",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d5640767-a2a3-4f3e-acfd-fdf9d834de22"
        },
        {
          "id": 73936,
          "person": {
            "id": 73396,
            "first_name": "Miguel",
            "last_name": "Recinos",
            "photo_url": "assets/733a35414fdc438fa73e944549ca1de9_20220809_091041_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80f7bf9b-ba39-42da-924a-8cc38b4e7176"
        },
        {
          "id": 74041,
          "person": {
            "id": 73500,
            "first_name": "Milagro",
            "last_name": "Elvir Nunez",
            "photo_url": "assets/1f7889e7b40b426f9dcdc615feb2e377_MilagroElvirTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea2ca9b2-10f3-43d0-a8e7-8d392ebed8d9"
        },
        {
          "id": 18685,
          "person": {
            "id": 18824,
            "first_name": "Mildred",
            "last_name": "Ochoa",
            "photo_url": "4286884b-d1e1-b41d-fa1c-26d22ce7f40e.png",
            "phone_number": "",
            "email_address": "231391@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e93f438a-5952-42db-b9d6-89156cb554fa"
        },
        {
          "id": 2955,
          "person": {
            "id": 2955,
            "first_name": "Mirna",
            "last_name": "Hernandez Rivera",
            "photo_url": "fbd6447f-efe6-2e87-e71a-25e17e4920c6.png",
            "phone_number": "",
            "email_address": "206530@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 3094,
              "name": "Swing",
              "contract": {
                "id": 2632,
                "name": "Alpharetta, GA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e596ec8-e7f0-4647-a067-09b3d087fc5b"
        },
        {
          "id": 81196,
          "person": {
            "id": 80564,
            "first_name": "Mirzet",
            "last_name": "Sakanovic",
            "photo_url": "assets/9fc04ebe291d402c989275a80940a64d_MirzetSakanovicCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c7c60aa5-9834-4a8e-a891-2b93b95c07f3"
        },
        {
          "id": 82776,
          "person": {
            "id": 82143,
            "first_name": "Modesta",
            "last_name": "Ortega Romero",
            "photo_url": "assets/6a36f281894d46ca837049b25061add7_1000002710_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0f8b6c3d-eaaa-4651-ad85-fba005906ba4"
        },
        {
          "id": 46499,
          "person": {
            "id": 46021,
            "first_name": "Mohamed",
            "last_name": "Ali",
            "photo_url": "assets/1617302887.9550326_MohammedAli_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1beaaf3d-fa94-41b1-8d2d-30da4a8d70ab"
        },
        {
          "id": 77797,
          "person": {
            "id": 77213,
            "first_name": "Mohammed",
            "last_name": "Pen",
            "photo_url": "assets/r62f306a7c223401daa728320854653d4_20221205_154529_t.jpg",
            "phone_number": "4255485255",
            "email_address": "mpen@sbmcorp.com"
          },
          "positions": [
            {
              "id": 363,
              "name": "Supervisor",
              "name_abbreviation": "Spvsr"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-10-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7fe89e9c-4038-4418-8fb3-38e771253b8d"
        },
        {
          "id": 74430,
          "person": {
            "id": 73888,
            "first_name": "Monica",
            "last_name": "Mata Castro",
            "photo_url": "assets/00381319cc784b53ac3b9f49fb87cc44_MONICAMATA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "20689930-446d-4eb1-9b4c-d67257995bfa"
        },
        {
          "id": 74186,
          "person": {
            "id": 73644,
            "first_name": "Monica",
            "last_name": "Navarrete De Nunez",
            "photo_url": "assets/f86214f6dc9a4715b0e8f38c7d2ae6ce_MONICANUNUEZS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d4baa7f-a669-4ceb-a578-01d17862b18a"
        },
        {
          "id": 47050,
          "person": {
            "id": 46565,
            "first_name": "Muhizi",
            "last_name": "Jean",
            "photo_url": "assets/1619643004.4366581_20210422_004259_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d6373bc-35fd-4e57-a0b9-c08ac14a1c68"
        },
        {
          "id": 47925,
          "person": {
            "id": 47437,
            "first_name": "Mui",
            "last_name": "Ly",
            "photo_url": "assets/09ba36852d6d43d2a5320121f72b09a8_MuiLyHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "78aef214-15a1-46ba-a1e9-309b08b9b60d"
        },
        {
          "id": 75490,
          "person": {
            "id": 74955,
            "first_name": "Myrtil",
            "last_name": "Jacmel",
            "photo_url": "assets/387d1e4489d54c4a9cfe316d4e9014e0_myrtil111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7d3320e-9a32-4c40-be1c-147026bc078a"
        },
        {
          "id": 55098,
          "person": {
            "id": 54552,
            "first_name": "Nancy",
            "last_name": "Cardenas",
            "photo_url": "assets/ebd16dd1e1a1401798d0fed6726b42d8_NancyCardenas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d9b8985-885c-4c84-a570-83e9b3f4b826"
        },
        {
          "id": 69145,
          "person": {
            "id": 68610,
            "first_name": "Nancy",
            "last_name": "Rosado",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 3096,
              "name": "Day",
              "contract": {
                "id": 2632,
                "name": "Alpharetta, GA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a6697b7-62e7-41f3-9724-730fddefca8d"
        },
        {
          "id": 51013,
          "person": {
            "id": 50505,
            "first_name": "Nar",
            "last_name": "Acharya",
            "photo_url": "assets/75ae940549994e608280a608c1dc7193_Nar_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e1d81dc5-3f3d-4f38-8dc8-9284f6578d1a"
        },
        {
          "id": 74192,
          "person": {
            "id": 73650,
            "first_name": "Natali",
            "last_name": "Salazar Alcantara",
            "photo_url": "assets/7772244a00f8446b9cb61e97882e5da9_NATALYS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9167f0e-1394-4b45-b4e2-537d9538d22d"
        },
        {
          "id": 52121,
          "person": {
            "id": 51596,
            "first_name": "Natalia",
            "last_name": "Martinez",
            "photo_url": "assets/0ebec0c0bcd54bff95480e60a38cb04f_image000000_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7718,
              "name": "Day",
              "contract": {
                "id": 5790,
                "name": "CIC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "0646f543-0824-4de7-89a6-2af2c217f0b4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be7127ed-19a5-4eca-9dc1-5f10d34afa8b"
        },
        {
          "id": 63981,
          "person": {
            "id": 63445,
            "first_name": "Natalie",
            "last_name": "Carle",
            "photo_url": "assets/d54be4e7b3d949bdb187df2cc3b09b0b_Janlastadminaudits171_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2985,
              "name": "Day",
              "contract": {
                "id": 1880,
                "name": "Indy",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f6d700cf-a4c8-44d7-8d83-1ce085a21b8d",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "77b7385b-8a01-4e79-b8cb-1bf36cc1b55d"
        },
        {
          "id": 74437,
          "person": {
            "id": 73895,
            "first_name": "Nelida",
            "last_name": "Carrillo",
            "photo_url": "assets/b71ceadbf4244bf2897e89f866d2c0f5_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65519b49-8e82-4487-a9a7-eebf3fb00863"
        },
        {
          "id": 72756,
          "person": {
            "id": 72219,
            "first_name": "Nellys",
            "last_name": "Garcia",
            "photo_url": "assets/fa40fe61fbf4402e8c845a7c1a895a0e_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ef8d04b-2fce-43e1-994f-28ea2b071de6"
        },
        {
          "id": 52762,
          "person": {
            "id": 52224,
            "first_name": "Nelson",
            "last_name": "Jimenez",
            "photo_url": "assets/rabfaf180527e4b028f35aa101dda549b_NelsonJimenez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 3128,
              "name": "Day",
              "contract": {
                "id": 2651,
                "name": "Dulles, VA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "86680ecb-2502-4ce5-b85a-1de25f07d976",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9cc3b7bc-1a11-4e03-8e7b-e4fc15a85d31"
        },
        {
          "id": 70634,
          "person": {
            "id": 70098,
            "first_name": "Nga",
            "last_name": "Tran",
            "photo_url": "assets/3b7b72119aab4c0cbd1f7e4ad70add8b_NgaTran_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21f20114-9032-47e8-a75d-51e849d95675"
        },
        {
          "id": 52063,
          "person": {
            "id": 51539,
            "first_name": "Niaz",
            "last_name": "Alqadhi",
            "photo_url": "assets/0f4f222fff7742cb8b6bef9e1ed1790a_IMG_6750F3EDE4061_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            },
            {
              "id": 2546,
              "name": "Restroom Attendant",
              "name_abbreviation": "RRMATT"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11387dc6-06eb-4e78-876d-9ef27c61e228"
        },
        {
          "id": 76489,
          "person": {
            "id": 75921,
            "first_name": "Nicholas",
            "last_name": "Johnson",
            "photo_url": "assets/536bcbd66e0f4b269b03bfdca478b5e1_NicholasJohnsonPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f26012db-1bf0-4cf8-95fb-3ad062599798"
        },
        {
          "id": 45360,
          "person": {
            "id": 44920,
            "first_name": "Nichole",
            "last_name": "Dudely",
            "photo_url": "assets/1614192054.3801367_NicholeDudleyInsitePhoto_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-02-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5af12cec-db66-4773-b5dd-4173b0a0b021"
        },
        {
          "id": 3663,
          "person": {
            "id": 3663,
            "first_name": "Nidia",
            "last_name": "Orellana",
            "photo_url": "assets/a260ad7523014615aad2c57d16d3ec7e_nidiapic_t.jpg",
            "phone_number": "",
            "email_address": "217155@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04e60cc9-31e0-4e4c-99d2-4c779bf6ca6f"
        },
        {
          "id": 46503,
          "person": {
            "id": 46025,
            "first_name": "Nimia",
            "last_name": "Rivera Rosales",
            "photo_url": "assets/1617303078.7672472_Mimia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            },
            {
              "id": 2546,
              "name": "Restroom Attendant",
              "name_abbreviation": "RRMATT"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92bf545e-740d-4816-bde7-a1076b0c5aca"
        },
        {
          "id": 51059,
          "person": {
            "id": 50551,
            "first_name": "Nina",
            "last_name": "Markoff",
            "photo_url": "assets/b4e10c663ef84350876bc92d830ddf6f_NinaM_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4717cef8-3509-4154-b06c-6ed271355619"
        },
        {
          "id": 67232,
          "person": {
            "id": 66690,
            "first_name": "Nita",
            "last_name": "Summers",
            "photo_url": "assets/r1969020511ef48e29f0a3ed58d119abb_20220216_093837_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33a947ff-acd3-482f-ac2a-11c5c8d71160"
        },
        {
          "id": 47321,
          "person": {
            "id": 46835,
            "first_name": "Noemy",
            "last_name": "Higareda",
            "photo_url": "assets/rf921338b70814dc0949597c17b1d85f2_cropped2618110405704902506.jpg",
            "phone_number": "7752003372",
            "email_address": "238707@placeholder.com"
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 2719,
              "name": "Swing",
              "contract": {
                "id": 2348,
                "name": "Reno, NV",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "7ea2bbc1-1311-41d6-b392-7ab6b8a1d019",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b7f5db7d-345e-416a-ab06-b6aa9b335de6"
        },
        {
          "id": 74975,
          "person": {
            "id": 74431,
            "first_name": "Nohemi",
            "last_name": "Magana",
            "photo_url": "assets/3ea799a955a64b46addbe0a606743876_NohemiMagana_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "68bfc714-44af-46ea-a545-bb718fe77ceb"
        },
        {
          "id": 47501,
          "person": {
            "id": 47016,
            "first_name": "Norma",
            "last_name": "Loera",
            "photo_url": "assets/1619643028.9088295_1391_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2371,
              "name": "Swing",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5f05ea2f-9541-4a64-ad4a-0fddf30a9d50"
        },
        {
          "id": 70245,
          "person": {
            "id": 69706,
            "first_name": "Norma",
            "last_name": "Mena De Lopez",
            "photo_url": "assets/38d4b28c18754601b9742e8f088889a6_norma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6de72e9c-7243-4c02-a05b-36be515661a9"
        },
        {
          "id": 73111,
          "person": {
            "id": 72573,
            "first_name": "Norma",
            "last_name": "Paz",
            "photo_url": "assets/199abfd809ff40d38b1c9c4200fa68a9_20220713_173342_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5702,
              "name": "Swing",
              "contract": {
                "id": 3999,
                "name": "Bannockburn",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "dc3073a6-eefb-4fc1-a8c5-be2966ef0a41",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3cde8107-2406-4ccd-89d3-5cfb9af76825"
        },
        {
          "id": 81117,
          "person": {
            "id": 80486,
            "first_name": "Nyiraruhanga",
            "last_name": "Nyabeza",
            "photo_url": "assets/4a8555b34e4c49d79abb26c1b59be4ed_NyabezaNyiraruhangaCampus2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7284,
              "name": "Swing",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0095e1ed-ee23-418f-81ee-11c7a775a0fd"
        },
        {
          "id": 75491,
          "person": {
            "id": 74956,
            "first_name": "Obed",
            "last_name": "Amaya",
            "photo_url": "assets/db1bd19cc70d487aa45cd8095e046a56_ObedAmayaPct_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "46624745-e902-4354-8370-924290bfb5fb"
        },
        {
          "id": 49358,
          "person": {
            "id": 48862,
            "first_name": "Ofelia",
            "last_name": "Gutierrez",
            "photo_url": "assets/c914469da73e45ca9d29c2aa0b1ab452_ofelia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34e4ce11-eb98-40c4-b2e1-d2b1d0e7f892"
        },
        {
          "id": 74453,
          "person": {
            "id": 73911,
            "first_name": "Ofelia",
            "last_name": "Pacheco Vargas",
            "photo_url": "assets/11857fcb42734caba2326da2b4775179_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "15bfceab-9443-4334-b35a-c7d2d1726bf5"
        },
        {
          "id": 52743,
          "person": {
            "id": 52205,
            "first_name": "Ofelia",
            "last_name": "Salazar Gutierrez",
            "photo_url": "assets/8c36d5078de94f5da1335fa1cbda861d_Ofelia_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4fb1a850-c2c4-4488-9f6e-d0e459e1b2a9"
        },
        {
          "id": 71264,
          "person": {
            "id": 70730,
            "first_name": "Ohelmi",
            "last_name": "Alcala",
            "photo_url": "assets/86383c3f990e4c23be28041c9c0cac16_MicrosoftTeamsimage_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8005,
              "name": "Night",
              "contract": {
                "id": 5849,
                "name": "10 Exchange Place, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-05-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8addbff6-5111-45b5-9497-760acbaf4a8a"
        },
        {
          "id": 45333,
          "person": {
            "id": 44897,
            "first_name": "Olga",
            "last_name": "Reyes",
            "photo_url": "assets/1617641369.2389448_oreyes_t.jpg",
            "phone_number": "4807420860",
            "email_address": "oreyes@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 2371,
              "name": "Swing",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-02-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40975db3-7886-47de-99b1-c5f4d4b6949b"
        },
        {
          "id": 75493,
          "person": {
            "id": 74958,
            "first_name": "Olga Elcidia",
            "last_name": "Lopez",
            "photo_url": "assets/08af282fc5b349deb8c1897fa131fc97_olga111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0edb5b8-bed4-4cf5-928b-e0c0a6c93662"
        },
        {
          "id": 14761,
          "person": {
            "id": 14762,
            "first_name": "Omar",
            "last_name": "Daley",
            "photo_url": "8db53658-3676-2aeb-3052-c51713fd562b.png",
            "phone_number": "",
            "email_address": "230025@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2647ed2-c2d6-4073-a570-8c71cad8120b"
        },
        {
          "id": 74038,
          "person": {
            "id": 73497,
            "first_name": "Oscar",
            "last_name": "Herrera",
            "photo_url": "assets/2fd951bae5344add955298487f83faff_OscarHerreraTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6030,
              "name": "Night",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b86806bb-0a40-4445-af79-6def0abd10dd"
        },
        {
          "id": 75495,
          "person": {
            "id": 74960,
            "first_name": "Oswaldo",
            "last_name": "Carrillo",
            "photo_url": "assets/efb83e340f5f40b484b10b0a344bda18_oswaldo111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1af739dd-0779-4a1b-a954-900339ab4d1b"
        },
        {
          "id": 74116,
          "person": {
            "id": 73574,
            "first_name": "Pablo",
            "last_name": "Cartagena",
            "photo_url": "assets/5c7dd5b2e3224d8a88f38cf2a4e57b81_IMG_2796_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "36f76ace-6e4d-4ec2-a143-7defa37758a4"
        },
        {
          "id": 74132,
          "person": {
            "id": 73590,
            "first_name": "Pablo",
            "last_name": "Linares",
            "photo_url": "assets/d06f089f775644ddb6c71880029abca9_PABLO_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67425451-2328-4762-b636-f19671527e33"
        },
        {
          "id": 53068,
          "person": {
            "id": 52528,
            "first_name": "Pamela",
            "last_name": "Kenyon",
            "photo_url": "assets/r92488f673caf4c11b34e5cb6bfade168_pic_t.jpg",
            "phone_number": "2487876816",
            "email_address": "pkenyon@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56f1d376-e9cc-4676-9762-976ec7464224"
        },
        {
          "id": 54230,
          "person": {
            "id": 53687,
            "first_name": "Patricia",
            "last_name": "Mendez",
            "photo_url": "assets/3db8a5c08a464ddcbb9f05942a96048c_patriciamendez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "464ce01e-b991-4e01-b1a9-9561b2f0487a"
        },
        {
          "id": 74388,
          "person": {
            "id": 73846,
            "first_name": "Patricia",
            "last_name": "Urquilla",
            "photo_url": "assets/57b4a874748c4361b44673f7b7210a38_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a177c81d-7c12-4758-95ee-f65b6a35198b"
        },
        {
          "id": 67400,
          "person": {
            "id": 66858,
            "first_name": "Patrick",
            "last_name": "Murtha",
            "photo_url": "assets/eac1ac2587504314933fc4fc13be5836_patrick_t.jpg",
            "phone_number": "2794652642",
            "email_address": "pmurtha@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 1402,
              "name": "Cardinal Health (JLL) Twinsburg",
              "contract": {
                "id": 2132,
                "name": "Twinsburg",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "6b9b4156-e1da-45e1-af4f-a6e925c973b0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 2145,
              "name": "Day",
              "contract": {
                "id": 1702,
                "name": "REG702",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "190ff85a-39a8-44fa-909e-7aa8db7cdc4d",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1641,
              "name": "Day",
              "contract": {
                "id": 1829,
                "name": "Columbus Division",
                "customer_id": 74,
                "customer_name": "Abbott",
                "uuid": "6d5eaf02-88a9-4ee3-b5ad-0fad61a551da",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7923,
              "name": "Day",
              "contract": {
                "id": 5633,
                "name": "Grove City",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "26203c87-9f1b-4a69-ba0d-73679f67e49b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2841,
              "name": "Day",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7601,
              "name": "Day",
              "contract": {
                "id": 5693,
                "name": "New Albany, OH (JLL)",
                "customer_id": 11,
                "customer_name": "Amgen",
                "uuid": "9fe9617f-b92e-4780-a3f1-8d20e0b55a31",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8803,
              "name": "Day",
              "contract": {
                "id": 6665,
                "name": "CLE3 – Euclid, OH",
                "customer_id": 24,
                "customer_name": "Amazon",
                "uuid": "a87683f7-75f8-45a3-ab59-13ca16c69915",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-01-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6e84043-60db-4115-bee3-10270f656394"
        },
        {
          "id": 52556,
          "person": {
            "id": 52021,
            "first_name": "Patrick",
            "last_name": "Rush",
            "photo_url": "assets/c9a637a99dea4d308c57479ac2e4b802_PatrickRushLicense_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c0b848f-17ac-4c59-8627-56f96a2e335f"
        },
        {
          "id": 77553,
          "person": {
            "id": 76973,
            "first_name": "Paul",
            "last_name": "Kessler",
            "photo_url": "assets/e465bb8df7d64a4c82df5ce499eeb351_PaulKessler_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7116,
              "name": "Swing",
              "contract": {
                "id": 4125,
                "name": "York, NE",
                "customer_id": 1547,
                "customer_name": "Collins Aerospace (CBRE)",
                "uuid": "ee153d8f-a7fd-4c7f-b56b-e0060da0230e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7d1a0275-5f08-4dcf-97ef-d3ef2c52a504"
        },
        {
          "id": 74105,
          "person": {
            "id": 73563,
            "first_name": "Paulina",
            "last_name": "Velazquez Hernandez",
            "photo_url": "assets/75effe67072f47f4a14538435c9724ab_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6623,
              "name": "Day",
              "contract": {
                "id": 3994,
                "name": "Chilco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "b3cbbb45-6aa7-4332-94b2-88b9f7e1768c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24399b00-5b64-4cdf-9341-2da5eadaf5b2"
        },
        {
          "id": 52224,
          "person": {
            "id": 51699,
            "first_name": "Pe",
            "last_name": "War",
            "photo_url": "assets/c7ef81b6037c4e55bac06071a5d6c820_PeWarsProfilePicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c43f8d25-fc9c-4864-af17-00a7095c1323"
        },
        {
          "id": 46316,
          "person": {
            "id": 45847,
            "first_name": "Pedro",
            "last_name": "Diaz",
            "photo_url": "assets/1617301752.5551734_123_11_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f690b8a0-18cd-461c-8c5f-32249d306430"
        },
        {
          "id": 70980,
          "person": {
            "id": 70443,
            "first_name": "Perla",
            "last_name": "Ordonez",
            "photo_url": "assets/c929d757e119461db126853c3b52893e_PerlaOrdonezChubb_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c44fc80-6bb1-447c-b73f-4471853ab4bb"
        },
        {
          "id": 74311,
          "person": {
            "id": 73769,
            "first_name": "Petra",
            "last_name": "Gonzalez De Valdez",
            "photo_url": "assets/232368038b7a461c9a49fd9ba920f6cc_PETRAV_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87723c38-ef1c-48bc-b956-58f80008f788"
        },
        {
          "id": 64131,
          "person": {
            "id": 63594,
            "first_name": "Phil",
            "last_name": "Martinez",
            "photo_url": "assets/46442a5113a747d19cdc794871dafca7_download_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-01-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b146e6c0-5485-4762-be34-39bffab25741"
        },
        {
          "id": 72414,
          "person": {
            "id": 71875,
            "first_name": "Ping",
            "last_name": "Lu",
            "photo_url": "assets/d4c8ac7b876f456b8656f8911717a1e1_20220624_132018_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c44a62c-50ed-46a5-9f39-2f39b0474ac4"
        },
        {
          "id": 80323,
          "person": {
            "id": 79694,
            "first_name": "Porsha",
            "last_name": "Thomas",
            "photo_url": "assets/f1ba76c98f7b49708beb2801f81f83bb_IMG_0306_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dc0c31c8-4368-44b6-9468-7c25c39b9376"
        },
        {
          "id": 48949,
          "person": {
            "id": 48455,
            "first_name": "Punmony",
            "last_name": "Prach",
            "photo_url": "assets/61daf15227fc44db89b76e1a797bca68_PunmonyHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0431b95d-e355-45ef-9c95-4f25248bc828"
        },
        {
          "id": 81538,
          "person": {
            "id": 80909,
            "first_name": "Quinn",
            "last_name": "Relihan",
            "photo_url": "assets/468fe727558749b59fd65b9b94c4e2cd_headshotQR_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 151,
              "name": "Mgr, Ntnl Sustain",
              "name_abbreviation": "NSUMGR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d95d6ee-038a-42d2-9357-610b65cf0aff"
        },
        {
          "id": 72405,
          "person": {
            "id": 71866,
            "first_name": "Raed",
            "last_name": "Hasan",
            "photo_url": "assets/41aa51fc146a4214837d1fd70898d1c9_RaedHasan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ebc37b2-4de7-4567-92b4-32abd4d40640"
        },
        {
          "id": 74320,
          "person": {
            "id": 73778,
            "first_name": "Rafael",
            "last_name": "Morfin",
            "photo_url": "assets/ea05f39fa6504ce7b13a60344488fe95_RAFAELMOR_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "381b3dda-b4dc-4fc7-9e8c-e28d38b6af6e"
        },
        {
          "id": 73761,
          "person": {
            "id": 73220,
            "first_name": "Rahel",
            "last_name": "Ngejah",
            "photo_url": "assets/a1f8c612d5bd4479af3c3bd2bc100d3d_RahelN_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2559,
              "name": "Swing",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "263ba783-402d-4985-aa10-c58ffb41a426"
        },
        {
          "id": 80603,
          "person": {
            "id": 79973,
            "first_name": "Rakell",
            "last_name": "Walters",
            "photo_url": "assets/1d0ba5fb15574918831eefa5e720167f_thumbnail_IMG_4023_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fdc19c4b-9c27-49ca-a703-38d348691c93"
        },
        {
          "id": 74409,
          "person": {
            "id": 73867,
            "first_name": "Ramiro",
            "last_name": "Ruiz Sosa",
            "photo_url": "assets/dcc1ebd0141c4cc58609831a1910816a_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3d1b9fe-8ce3-401d-b7b3-daeea8151d2b"
        },
        {
          "id": 74168,
          "person": {
            "id": 73626,
            "first_name": "Ramon",
            "last_name": "Flores",
            "photo_url": "assets/r063e4bc988b54e26957e5ec0eb5d4d5d_IMG_0048_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9f1b4f0-b6c9-4f89-996c-8fb9a1e8320c"
        },
        {
          "id": 80355,
          "person": {
            "id": 79726,
            "first_name": "Ramon",
            "last_name": "Sanchez",
            "photo_url": "assets/98cec53fd5d543d0a03c1304b4430126_RamonSanchezProfilePic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e844f684-da6f-4e9c-99de-cfc300e62267"
        },
        {
          "id": 74179,
          "person": {
            "id": 73637,
            "first_name": "Ramona",
            "last_name": "Mendoza Ruiz",
            "photo_url": "assets/9bd770cf689d4026a2f6fc1b4a519ac1_ROMERAMENDOZA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c152cad3-8fed-44fb-9222-7afa8281242d"
        },
        {
          "id": 46500,
          "person": {
            "id": 46022,
            "first_name": "Raquel",
            "last_name": "Urquidi Gonzalez",
            "photo_url": "assets/6964577a0bce49b0a61f71338efc9873_Raquelphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27b9ae26-8b50-4023-b392-46da1478841a"
        },
        {
          "id": 74382,
          "person": {
            "id": 73840,
            "first_name": "Raul",
            "last_name": "Trabanino Chacon",
            "photo_url": "assets/r3de99488e4014daa814c2143262ca00a_IMG_0062_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "10f6ef53-9165-417d-99ba-d3f98d14dc74"
        },
        {
          "id": 75435,
          "person": {
            "id": 74900,
            "first_name": "Raynna",
            "last_name": "Antunez",
            "photo_url": "assets/rc2aa2726f7e341a2a606f131d26c354f_20221222_102450_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1709,
              "name": "Day",
              "contract": {
                "id": 1198,
                "name": "Waukegan, IL",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "679f08fb-6b4d-4684-aed3-443ad08fa57f"
        },
        {
          "id": 47934,
          "person": {
            "id": 47446,
            "first_name": "Reina",
            "last_name": "Hercules",
            "photo_url": "assets/4dfcf1c9b1f1447e8c431aba0f457d25_ReinaHercules_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-05-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0c8dd48e-f6b0-46c7-81ef-28cc3ce10cc0"
        },
        {
          "id": 67787,
          "person": {
            "id": 67245,
            "first_name": "Renina",
            "last_name": "Morris",
            "photo_url": "assets/r58fad8d67d344fcc8a9cf079f9f3397c_Nina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2249,
              "name": "Day",
              "contract": {
                "id": 2256,
                "name": "Clermont, KY",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "246fbd5b-1059-4869-8049-1cc1a7975c3f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1d3c3754-d3e8-4e8f-ad33-93a400c777dd"
        },
        {
          "id": 74543,
          "person": {
            "id": 74001,
            "first_name": "Ren Min",
            "last_name": "Wu",
            "photo_url": "assets/913ea8467ad04d50a6dd9732d01ea6fb_F0CFD85A34914CC082E37604657DF5D1_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e7241aa-0046-4d1c-8138-0106d5aff5c4"
        },
        {
          "id": 74264,
          "person": {
            "id": 73722,
            "first_name": "Reynaldo",
            "last_name": "Lopez",
            "photo_url": "assets/13480255c1404c90959c1f357f12b828_0E6237A95A7D46E892BEFC62B8788571_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3dfd322b-4759-48c3-8d9b-f4c94df82e04"
        },
        {
          "id": 74436,
          "person": {
            "id": 73894,
            "first_name": "Ricardo",
            "last_name": "Diaz Cortez",
            "photo_url": "assets/ac2760d4c7c64a138fce753a9a528bf6_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "84a04ae5-0f29-4dc5-9e64-63b6f611f126"
        },
        {
          "id": 46492,
          "person": {
            "id": 46014,
            "first_name": "Ricardo",
            "last_name": "Rivera",
            "photo_url": "assets/1617302864.500938_StandardMale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 4524,
              "name": "Swing",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e9ccb13-90c3-4d8a-8ed9-695d301385c5"
        },
        {
          "id": 75500,
          "person": {
            "id": 74965,
            "first_name": "Ricardo Antonio",
            "last_name": "Hernandez",
            "photo_url": "assets/f198b6eebbca4eea8e133d5273cf2c35_ricardo111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "199baff1-9c50-47cb-9eef-ef39dc87c7ae"
        },
        {
          "id": 81269,
          "person": {
            "id": 80637,
            "first_name": "Richard",
            "last_name": "Carreon",
            "photo_url": "assets/92da39d9808c454dadf02e83e3646b8f_IMG_0671Richard_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "495f34e3-7274-44d7-8042-f91c9b52b001"
        },
        {
          "id": 73512,
          "person": {
            "id": 72972,
            "first_name": "Richard",
            "last_name": "Minehart",
            "photo_url": "assets/7cf8a4aa58b742bca14e3bf45ac5a669_GREG_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-06-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cb8eb9a4-d94b-4d87-985e-b007096157c1"
        },
        {
          "id": 76563,
          "person": {
            "id": 75995,
            "first_name": "Richard",
            "last_name": "Roberson",
            "photo_url": "assets/9bf786d434da4d948ea6dcd4663fb40f_IMG_2709_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 107,
              "name": "Glass Wash Equip Prep",
              "name_abbreviation": "GMPGW"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6cf9feba-a876-4642-8e5e-d0055f325b1a"
        },
        {
          "id": 80925,
          "person": {
            "id": 80295,
            "first_name": "Rita",
            "last_name": "Watkins",
            "photo_url": "assets/390472cfa9054ce698f3ca818523b24e_WatkinsRita251442image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04eea813-ae6e-441d-9a15-fb7f15b93d51"
        },
        {
          "id": 6557,
          "person": {
            "id": 6557,
            "first_name": "Robert",
            "last_name": "Adams",
            "photo_url": "assets/b5d0b252459e487c9f1177f9c341d1c6_bob_t.jpg",
            "phone_number": "3016243682",
            "email_address": "radams@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7453,
              "name": "On-Call",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e8446d7-a4d0-4825-a029-c7e413ae1aa2"
        },
        {
          "id": 52737,
          "person": {
            "id": 52199,
            "first_name": "Robert",
            "last_name": "Gomez",
            "photo_url": "assets/28fdcfeb25e34a98ae8eed4841b2973d_4SMale_t.png",
            "phone_number": "2544080891",
            "email_address": ""
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 1419,
              "name": "Day",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19db837d-ea7a-4c0b-b023-fb6c0f3270d6"
        },
        {
          "id": 48435,
          "person": {
            "id": 47944,
            "first_name": "Robert",
            "last_name": "Herschel",
            "photo_url": "assets/r0a197db86a4e4bffbb00bb8b1771b715_20210625_225520_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1490,
              "name": "Night",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-05-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e69b1cce-0f99-496c-aa51-c685d2d151bb"
        },
        {
          "id": 75501,
          "person": {
            "id": 74966,
            "first_name": "Roberto",
            "last_name": "Alas",
            "photo_url": "assets/0a83a834582240609fd5a60944af6d4a_robalas111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 6619,
              "name": "Night",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "038ca161-54dd-42bc-bf71-35c3158601a8"
        },
        {
          "id": 75497,
          "person": {
            "id": 74962,
            "first_name": "Roberto",
            "last_name": "Portillo",
            "photo_url": "assets/ca474cb05212454d9d9c3c392c89564d_robertop111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dca7f8b3-0b37-4c2b-bdc2-c0470cf2cba4"
        },
        {
          "id": 74445,
          "person": {
            "id": 73903,
            "first_name": "Roberto",
            "last_name": "Salmeron",
            "photo_url": "assets/b888cbbe89df409686d0c3b37013f903_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6e5d9c6-69bb-4fe5-8740-d6b55f9cf659"
        },
        {
          "id": 76005,
          "person": {
            "id": 75465,
            "first_name": "Roberto",
            "last_name": "Silva",
            "photo_url": "assets/r9309a62335504797b24d09b472a9f47a_RobertoSilvaPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31744c5a-775c-49d2-ad10-94d87b17c107"
        },
        {
          "id": 76717,
          "person": {
            "id": 76148,
            "first_name": "Roberto",
            "last_name": "Torres",
            "photo_url": "assets/38c51e476653467fb0993184c3c0f71d_thumbnail_IMG_8688_t.jpg",
            "phone_number": "",
            "email_address": "rtorres@sbmcorp.com"
          },
          "positions": [
            {
              "id": 362,
              "name": "Site Supv",
              "name_abbreviation": "SITSUP"
            }
          ],
          "shifts": [
            {
              "id": 5060,
              "name": "Swing",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0a8b798d-5bce-45e1-bd9d-e10fa59acc44"
        },
        {
          "id": 48728,
          "person": {
            "id": 48234,
            "first_name": "Rocio",
            "last_name": "Chavez Zavala",
            "photo_url": "assets/59d147ec04014a49bec607bd1a12c27e_ReAudittoiletstains018_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49a6e17d-7311-4623-a635-df833f301037"
        },
        {
          "id": 78243,
          "person": {
            "id": 77657,
            "first_name": "Romeo",
            "last_name": "Martinez",
            "photo_url": "assets/bfaca67aafaa4db79ad039748886a65e_thumbnail_Image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3183,
              "name": "Night",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d5fad21-5604-4c45-ba2e-e75860f936f0"
        },
        {
          "id": 739,
          "person": {
            "id": 739,
            "first_name": "Ronnie",
            "last_name": "Lowery",
            "photo_url": "5aeac6bf-f43e-eb5a-42a6-63d8bffe6ef5.png",
            "phone_number": "",
            "email_address": "211135@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e30b3aa-30ca-4b7c-b561-3e65e405dcbc"
        },
        {
          "id": 63999,
          "person": {
            "id": 63463,
            "first_name": "Ronnie",
            "last_name": "Russell",
            "photo_url": "assets/21bca684f61540ce88b7ad66b74eba30_RonnieRussell_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e38fc6d1-5910-4018-a305-cf88ef6539d5"
        },
        {
          "id": 74313,
          "person": {
            "id": 73771,
            "first_name": "Rosa",
            "last_name": "Buenrostro Moncada",
            "photo_url": "assets/70edbb2f1c9b4c57a1b8651f9b5429e8_ROSAB_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6486,
              "name": "Night",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01f8b9fb-074f-4b52-b41e-d322371404d8"
        },
        {
          "id": 52766,
          "person": {
            "id": 52228,
            "first_name": "Rosa",
            "last_name": "Caraballo Hernandez",
            "photo_url": "assets/2100e4e12ef04dcba67306b9ed21935d_IMG20220110WA0002002_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-10-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b01d8df4-cf88-4ce2-b3ac-8cace44217a6"
        },
        {
          "id": 74113,
          "person": {
            "id": 73571,
            "first_name": "Rosa",
            "last_name": "Estrada Paredes",
            "photo_url": "assets/2d8d319867424e04baf83d8295e66ed8_rosaestrada_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "edae3260-0e34-43a0-af3b-9d1cc279a5a6"
        },
        {
          "id": 74133,
          "person": {
            "id": 73591,
            "first_name": "Rosa",
            "last_name": "Legaspe",
            "photo_url": "assets/4436fccd60db42a58564951d0b014290_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5c17910-2964-4ce4-a817-a588f85b4253"
        },
        {
          "id": 74207,
          "person": {
            "id": 73665,
            "first_name": "Rosa",
            "last_name": "Soto",
            "photo_url": "assets/e2c492a1fc0c4f279082df471e6bcb5a_1E3C91A0458C40E685176F07B10A61BA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6bb8e79-e318-42fd-826f-5f982c513f7e"
        },
        {
          "id": 78928,
          "person": {
            "id": 78323,
            "first_name": "Rosa",
            "last_name": "Torres",
            "photo_url": "assets/d19f7e6f2fa24503b04b8ea91a545f55_20221209_122842Rosa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fea75af9-63ad-4e87-83b0-9a632e773585"
        },
        {
          "id": 74101,
          "person": {
            "id": 73559,
            "first_name": "Rosalina",
            "last_name": "Reyes Rivas",
            "photo_url": "assets/5263a2c1a564462daaa999a749d8600d_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "528b79d2-9fa9-4d8e-b29a-562c2ff54bce"
        },
        {
          "id": 63480,
          "person": {
            "id": 62944,
            "first_name": "Roselaine",
            "last_name": "Jean-Louis",
            "photo_url": "assets/a011a2dd33944521b6d168dc057c9d8f_Roselynsphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4525,
              "name": "Day",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b9eb8ba5-55e7-4b1a-99fa-daef6cd4b343"
        },
        {
          "id": 74408,
          "person": {
            "id": 73866,
            "first_name": "Roselia",
            "last_name": "Villalobos Birrueta",
            "photo_url": "assets/468753292752491a9918df2b736b549d_ROSALIAV_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bec8524a-c044-4b5d-ac3f-bd61fa6c4c49"
        },
        {
          "id": 49455,
          "person": {
            "id": 48959,
            "first_name": "Rotilda",
            "last_name": "Ahilon Pablo",
            "photo_url": "assets/2d99abf4b360491fbe0c8e4a9798227d_RotildaPablo_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2980,
              "name": "Night",
              "contract": {
                "id": 2439,
                "name": "Oakland, CA",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "50f13194-9381-4667-82dc-ffdbb62f48ba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-06-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "844971e3-b180-463b-9344-b9b54d56a3e2"
        },
        {
          "id": 74047,
          "person": {
            "id": 73506,
            "first_name": "Ruber",
            "last_name": "Gonzalez",
            "photo_url": "assets/30f54fd65b18401ebf4303f56c6fcd55_RoberGonzalezTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d0fa8f2-72d3-4809-97df-e5a2422fc638"
        },
        {
          "id": 54144,
          "person": {
            "id": 53601,
            "first_name": "Rui",
            "last_name": "Ng Perez",
            "photo_url": "assets/e84920981d33402287661acba4521cdc_RUi_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-11-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e6efa8b1-d801-4972-8875-39c2c593bf27"
        },
        {
          "id": 69616,
          "person": {
            "id": 69081,
            "first_name": "Rukia",
            "last_name": "Maalin",
            "photo_url": "assets/9b3582c1387743e4b7c6d76b37d274d8_RukiaMinsitepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "989ef437-afd7-4d5f-8856-08a354a6d0c7"
        },
        {
          "id": 49718,
          "person": {
            "id": 49218,
            "first_name": "Ruth",
            "last_name": "Green",
            "photo_url": "assets/02156d8572c842249004d959816ac568_RG_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f17df990-e00c-4a16-b00a-2e2ccab296a5"
        },
        {
          "id": 52064,
          "person": {
            "id": 51540,
            "first_name": "Sadeq",
            "last_name": "Alabdi",
            "photo_url": "assets/be68402add164ba4a0d96c75ed888a69_IMG_2E227DAAC6161_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2378,
              "name": "Night",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-09-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b8f2bb67-399a-40d4-8ddf-541434533167"
        },
        {
          "id": 54195,
          "person": {
            "id": 53652,
            "first_name": "Saeed",
            "last_name": "Essa",
            "photo_url": "assets/b07f8498f45842649864983511777f27_Saeed_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e531c87-f1d7-4469-a2cd-4bcf38c3f5b7"
        },
        {
          "id": 74211,
          "person": {
            "id": 73669,
            "first_name": "Salah",
            "last_name": "Alawag",
            "photo_url": "assets/0fc22b72cbb54e2ea1676e185202c90d_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b3fb771-9364-443f-8725-9a091a7d0d99"
        },
        {
          "id": 74171,
          "person": {
            "id": 73629,
            "first_name": "Salvador",
            "last_name": "Garcia Alguizar",
            "photo_url": "assets/d151459fecc94efdb7514fadc1a3ec78_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "69368a42-8f8a-42b4-88bb-5c1c15f4acb2"
        },
        {
          "id": 74396,
          "person": {
            "id": 73854,
            "first_name": "Salvador",
            "last_name": "Hernandez Cardenas",
            "photo_url": "assets/d3a8ba1c313d4871b4af131439fcfdd5_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "82f0dd3a-66ed-4e8a-98c6-810a53da34d4"
        },
        {
          "id": 49185,
          "person": {
            "id": 48690,
            "first_name": "Sam",
            "last_name": "Hulaiqa",
            "photo_url": "assets/f5ce5f9c4de14221aa18ac820dd32bce_ScreenShot20210702at8.55_t.35AM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-06-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "220fe135-b590-4035-b36b-5a38b6847362"
        },
        {
          "id": 75238,
          "person": {
            "id": 74690,
            "first_name": "Sammy",
            "last_name": "Arroyo",
            "photo_url": "assets/r8c790443aef8434b9baba6a65e8003a9_SammyArroyoProfile_t.jpg",
            "phone_number": "+17322599865",
            "email_address": ""
          },
          "positions": [
            {
              "id": 21,
              "name": "Sr Mgr, EHS",
              "name_abbreviation": "SREHSM"
            }
          ],
          "shifts": [
            {
              "id": 2008,
              "name": "Day",
              "contract": {
                "id": 1728,
                "name": "JONE11OVHD",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "19fa1beb-120b-486b-8174-954fdcc6b09f",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1200,
              "name": "Day",
              "contract": {
                "id": 1489,
                "name": "Branchburg, NJ",
                "customer_id": 12,
                "customer_name": "Merck (Sodexo)",
                "uuid": "37c37ca0-8ac6-4644-bbc1-0df1f4026893",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1248,
              "name": "Day",
              "contract": {
                "id": 1241,
                "name": "Madison, NJ",
                "customer_id": 12,
                "customer_name": "Merck (Sodexo)",
                "uuid": "f5af5d3e-3441-40c5-8257-80fb1d2bebc0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1209,
              "name": "Day",
              "contract": {
                "id": 1466,
                "name": "Kenilworth, NJ",
                "customer_id": 12,
                "customer_name": "Merck (Sodexo)",
                "uuid": "71c53ff5-4eb5-402c-89bb-131cfdb13aca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3353,
              "name": "Day",
              "contract": {
                "id": 2700,
                "name": "S. San Francisco, CA",
                "customer_id": 207,
                "customer_name": "Merck",
                "uuid": "19357bd2-151c-4ac5-b2ed-4f0f2c2184f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 397,
                "name": "Day"
              }
            },
            {
              "id": 669,
              "name": "Day",
              "contract": {
                "id": 15,
                "name": "Rahway, NJ",
                "customer_id": 12,
                "customer_name": "Merck (Sodexo)",
                "uuid": "b8fd40fb-39dc-42ed-ace9-4ea5feb43a87",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1226,
              "name": "Day",
              "contract": {
                "id": 1488,
                "name": "Elkton, VA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "59641b88-bed7-45db-96cc-bda6bfd68c44",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1204,
              "name": "Day",
              "contract": {
                "id": 1491,
                "name": "North Wales, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "a153d850-6493-4210-a779-9ec041201f67",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2106,
              "name": "Day",
              "contract": {
                "id": 1484,
                "name": "Whitehouse Station, NJ",
                "customer_id": 12,
                "customer_name": "Merck (Sodexo)",
                "uuid": "b7ce3d26-1397-4b23-b832-8eb836fd8f61",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1371,
              "name": "Day",
              "contract": {
                "id": 1993,
                "name": "Cambridge, MA",
                "customer_id": 207,
                "customer_name": "Merck",
                "uuid": "0c9b1a03-eaca-4d0e-b83c-6d3d3190acc5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1116,
              "name": "Day",
              "contract": {
                "id": 1483,
                "name": "Boston, MA",
                "customer_id": 207,
                "customer_name": "Merck",
                "uuid": "70961969-4841-4626-951d-159771c6f1b1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1917,
              "name": "Day",
              "contract": {
                "id": 1622,
                "name": "LPO, PR",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "14ae0803-c9a9-4ecb-b778-2a5ceb53bbe3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7692,
              "name": "Swing",
              "contract": {
                "id": 5651,
                "name": "Springhouse Plant, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57aa4686-7902-470c-84ef-033705d7072a"
        },
        {
          "id": 70972,
          "person": {
            "id": 70435,
            "first_name": "Sandra",
            "last_name": "Alvarez",
            "photo_url": "assets/7d6b8f4be208471ab00156e1d084344c_SandraAlvarezChubbv_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e75f3a9-f54b-4142-a51f-4fd299561532"
        },
        {
          "id": 10904,
          "person": {
            "id": 10904,
            "first_name": "Sandra",
            "last_name": "Calderon",
            "photo_url": "assets/70c44bf101304d7da838965ed7942322_Sandra_t.PNG",
            "phone_number": "6502917517",
            "email_address": "224991@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83974875-a023-4eec-9d9e-28cf3e2fce36"
        },
        {
          "id": 74158,
          "person": {
            "id": 73616,
            "first_name": "Sandra",
            "last_name": "Martinez",
            "photo_url": "assets/d8b65766c79143b489b3db81e8d46ade_SANDRAMARTINEZ_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "257148c8-5c83-4864-98e0-a09492dacb42"
        },
        {
          "id": 74811,
          "person": {
            "id": 74267,
            "first_name": "Sandra",
            "last_name": "Valle Acevedo",
            "photo_url": "assets/8bead2964fe845e4a01e93a31e481577_sandrapic_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43c8d295-5add-4fce-8ddc-a6c950ee5080"
        },
        {
          "id": 51135,
          "person": {
            "id": 50622,
            "first_name": "Santa",
            "last_name": "Rai",
            "photo_url": "assets/ra992eaccc67443a5a2151b74e4ea7b2b_SantaRai_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7508,
              "name": "Swing",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1a3c5085-9f73-4760-9015-b02ee0edb09b"
        },
        {
          "id": 75498,
          "person": {
            "id": 74963,
            "first_name": "Santos",
            "last_name": "Carrillo",
            "photo_url": "assets/8ae786bfa1d844f6ad37086092f359de_santos111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "73a65d9f-ffc6-4752-bad1-e4e0083262d6"
        },
        {
          "id": 74426,
          "person": {
            "id": 73884,
            "first_name": "Sara",
            "last_name": "Cortez",
            "photo_url": "assets/b648621b987c489b8bc29742f265a9fb_sara_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "16d03660-c541-46a9-8d36-f54aeb2f4d8c"
        },
        {
          "id": 47041,
          "person": {
            "id": 46556,
            "first_name": "Sara",
            "last_name": "Gaitan",
            "photo_url": "assets/1619654020.692796_ScreenShot20210428at4.35_t.32PM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2378,
              "name": "Night",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6abb326c-7958-403a-9b19-7e38d02360c8"
        },
        {
          "id": 48716,
          "person": {
            "id": 48222,
            "first_name": "Saybah",
            "last_name": "Kabbah",
            "photo_url": "assets/ra46600e717c04eab9df1eb123ab94c5b_Resized_received_208545191115103_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5f3b00ff-16b5-44c7-805d-4b7a342eea40"
        },
        {
          "id": 51297,
          "person": {
            "id": 50781,
            "first_name": "Sean",
            "last_name": "Fields",
            "photo_url": "assets/f00953d3996644f5bde1f511c6f96e85_SeanFieldsHS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3038,
              "name": "Swing",
              "contract": {
                "id": 2590,
                "name": "Laguna South, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "abb421e8-0707-42f0-b247-657048924721",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92ffecb1-aa06-4624-ab03-0388821de683"
        },
        {
          "id": 13851,
          "person": {
            "id": 13852,
            "first_name": "Sean",
            "last_name": "Henderson",
            "photo_url": "assets/1579710508.5742571_IMG_2424_t.PNG",
            "phone_number": "3143995156",
            "email_address": "shenderson@sbmcorp.com"
          },
          "positions": [
            {
              "id": 368,
              "name": "VP, Global Acct Mgmt",
              "name_abbreviation": "VPGAM"
            }
          ],
          "shifts": [
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1514,
              "name": "Day",
              "contract": {
                "id": 2251,
                "name": "RIC – Research & Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 4978,
              "name": "None",
              "contract": {
                "id": 2431,
                "name": "CORPW014",
                "customer_id": 290,
                "customer_name": "SBM-H",
                "uuid": "d356b7e2-042e-4761-830a-4bbbe92d9653",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            },
            {
              "id": 7583,
              "name": "Shift C",
              "contract": {
                "id": 5638,
                "name": "KCAP - 210 Warehouse - Kansas City, MO",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e37d8760-fbb5-4a1d-839b-4b8ce4f95c66",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8105,
              "name": "Unassigned",
              "contract": {
                "id": 5868,
                "name": "Powerhouse – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0d84e8b7-49f2-4140-8852-e9f6f0cc2a46",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8103,
              "name": "Unassigned",
              "contract": {
                "id": 5864,
                "name": "FEL – Ford Engineering Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8108,
              "name": "Unassigned",
              "contract": {
                "id": 2214,
                "name": "WPE – Wagner Place East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8107,
              "name": "Unassigned",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2021-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8d9a44e-d51d-4a4b-9c27-8b3e6e5b3a86"
        },
        {
          "id": 77948,
          "person": {
            "id": 77364,
            "first_name": "Segundo",
            "last_name": "Rodriguez",
            "photo_url": "assets/re951391ef0e740f5bf1a3af6e5508a0b_Segundopic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a914d2d4-5fd0-4ee5-a62c-e1b7ec0baff0"
        },
        {
          "id": 47109,
          "person": {
            "id": 46623,
            "first_name": "Segundo",
            "last_name": "Romero",
            "photo_url": "assets/r0392c55a86314b4a81ba0b60ff541adc_SegundoRomero_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7202,
              "name": "Swing",
              "contract": {
                "id": 5497,
                "name": "Central",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6c0a9be-2da5-4ff4-9130-2f2c684ae4e2"
        },
        {
          "id": 76006,
          "person": {
            "id": 75466,
            "first_name": "Sergio",
            "last_name": "Fontes",
            "photo_url": "assets/r334ad31d9f1f412f836b8fc36cfb100a_SergioFontes_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18975b16-0573-43ba-88f9-62821e3fbf45"
        },
        {
          "id": 72404,
          "person": {
            "id": 71865,
            "first_name": "Shan",
            "last_name": "Kirkland",
            "photo_url": "assets/5f986176f7f04c40b87dd481c37ff9e8_Shan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5463,
              "name": "Swing",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-06-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "adfd0ba3-bf76-48b0-a91b-6ec25a81d8b7"
        },
        {
          "id": 72191,
          "person": {
            "id": 71656,
            "first_name": "Shane",
            "last_name": "Skrob",
            "photo_url": "assets/abc9700768b44fb8bb1f84d180f44c5b_Shane_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-05-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9cb99fc3-d9ef-4d2a-9b17-2f0f691e11d1"
        },
        {
          "id": 54234,
          "person": {
            "id": 53691,
            "first_name": "Shannon",
            "last_name": "Lockett",
            "photo_url": "assets/ff30c0adfbfb4e66a90f1f1a7b884834_shannonlockett_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2dd27430-e90c-466e-ba2f-0e5e4501b625"
        },
        {
          "id": 63482,
          "person": {
            "id": 62946,
            "first_name": "Sharon",
            "last_name": "White",
            "photo_url": "assets/09cda17a1d5c42ada2b2ab4eac05115c_Sharonsphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4525,
              "name": "Day",
              "contract": {
                "id": 2686,
                "name": "West Palm Beach",
                "customer_id": 504,
                "customer_name": "Sikorsky (CBRE)",
                "uuid": "5dd767aa-3715-4f6f-8636-d97805d394be",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cdd82f67-d728-4ae2-a092-987ff5e6de33"
        },
        {
          "id": 81356,
          "person": {
            "id": 80725,
            "first_name": "Shermeka",
            "last_name": "Gilmore",
            "photo_url": "assets/f773901c1acb4a08b3f454b496ad71b4_20230206_082353_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2841,
              "name": "Day",
              "contract": {
                "id": 2471,
                "name": "Cover My Meds Columbus",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "3490b8a1-2295-4b0c-ba39-8e018e738699",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a808007-c6a2-48b9-802f-6ec38d5c9beb"
        },
        {
          "id": 74433,
          "person": {
            "id": 73891,
            "first_name": "Shirley",
            "last_name": "Aceituno Aleman",
            "photo_url": "assets/c8202fcc62f9476aa8911cf2a4ca581f_SHIRLEYA_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99c23c65-08cd-4dda-8a1a-cb09fa64f549"
        },
        {
          "id": 74335,
          "person": {
            "id": 73793,
            "first_name": "Silvia",
            "last_name": "Cardenas Correa",
            "photo_url": "assets/8aa8c75cc4ea43b4b559455f6800f1ec_SILVIACC_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09c2ee38-52d5-4d2a-8cbe-08c6527257e5"
        },
        {
          "id": 75502,
          "person": {
            "id": 74967,
            "first_name": "Silvia",
            "last_name": "Cisneros De Rivas",
            "photo_url": "assets/8adb5fa3837244f4bb3bd65151d009ad_silvia111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1487128b-567a-4df7-b598-3c9b7fa7382f"
        },
        {
          "id": 74816,
          "person": {
            "id": 74272,
            "first_name": "Silvia",
            "last_name": "Lopez",
            "photo_url": "assets/71a88b3089dc40c3a1bcd2b7f1432cd8_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0f31a042-63e4-4ee0-96f7-e6a92a40aee4"
        },
        {
          "id": 74289,
          "person": {
            "id": 73747,
            "first_name": "Silvia",
            "last_name": "Perez Ortega",
            "photo_url": "assets/a608de6280ba41cda59d96d50305c59f_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b04bf833-e870-4a27-bc43-dfd54e20acce"
        },
        {
          "id": 74216,
          "person": {
            "id": 73674,
            "first_name": "Silvia",
            "last_name": "Rangel",
            "photo_url": "assets/ca94d5e8967f4231ad09160c24dd7037_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "52893167-926f-493c-a233-9cb07b0d85ea"
        },
        {
          "id": 69072,
          "person": {
            "id": 68537,
            "first_name": "Simeon",
            "last_name": "De Los Santos",
            "photo_url": "assets/67705dc9bad243f8b1f6fb183bf6c586_IMG_20220324_081337_2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-03-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45de6465-77e6-4404-89c2-5d877c72d553"
        },
        {
          "id": 78868,
          "person": {
            "id": 78267,
            "first_name": "Sirena",
            "last_name": "Macias",
            "photo_url": "assets/047ea7f6022e4bb4946b21d835dd0ad5_SIRENAMACIAS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95ecd02d-79da-442a-877d-59f3e2da65df"
        },
        {
          "id": 80269,
          "person": {
            "id": 79641,
            "first_name": "Socorro",
            "last_name": "Vega",
            "photo_url": "assets/5ec60d41f5e944e2aded4011eff72a9d_4SFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7226,
              "name": "Day",
              "contract": {
                "id": 5511,
                "name": "3rd - Brannan",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "a8f911c1-11e3-4fa0-8382-2f0a79f6457b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd286aee-1768-4033-b5b9-f93141684801"
        },
        {
          "id": 74263,
          "person": {
            "id": 73721,
            "first_name": "Sofia",
            "last_name": "Alvarez",
            "photo_url": "assets/cc1de7e6096c4fd38444c5d153af9e45_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ee1672e-021d-4909-96f3-031cfedc40b1"
        },
        {
          "id": 2592,
          "person": {
            "id": 2592,
            "first_name": "Sofia",
            "last_name": "Mejia",
            "photo_url": "17817785-79db-51f9-84ce-3bbc7b6cc723.png",
            "phone_number": "9802199158",
            "email_address": "102864@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7491,
              "name": "Day",
              "contract": {
                "id": 5575,
                "name": "Concord, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "d3363f25-2599-4eff-a321-1f670862abdd",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7eb003fd-d0ee-413e-91b6-885b4a18901b"
        },
        {
          "id": 81271,
          "person": {
            "id": 80639,
            "first_name": "Sonia",
            "last_name": "Anguiano",
            "photo_url": "assets/fcd9e9f354f54f37b56bc11b3d9f81a4_IMG_0677Sonia_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aa3b7ed1-d7cb-41f1-9883-99c616fc506f"
        },
        {
          "id": 74220,
          "person": {
            "id": 73678,
            "first_name": "Sonia",
            "last_name": "Fernandez",
            "photo_url": "assets/93e4804923fa4f4b857a9664a6c0c5d3_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ede62e1-1f3e-413d-b53a-57f80f280b1e"
        },
        {
          "id": 74321,
          "person": {
            "id": 73779,
            "first_name": "Sonia",
            "last_name": "Morales",
            "photo_url": "assets/4338342be5154637b42b8f03a7e64e69_SARAM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ade08d94-bf75-4c06-98c8-ebaa1ffe0ca0"
        },
        {
          "id": 51787,
          "person": {
            "id": 51264,
            "first_name": "Sonia",
            "last_name": "Ventura De Sorto",
            "photo_url": "assets/c6eddd8d4957406f865e5542e206d97a_3_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3082,
              "name": "Day",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad7eee83-7227-4fcd-915e-231c337e9135"
        },
        {
          "id": 78301,
          "person": {
            "id": 77714,
            "first_name": "Soo Duk",
            "last_name": "Sim",
            "photo_url": "assets/fdbb78c356974d408d317878fa88d4db_E366538BBE3A482FB92FF2A948FB3633_t.jpg",
            "phone_number": "1 (619) 983-9038",
            "email_address": "jsim@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 2047,
              "name": "Day",
              "contract": {
                "id": 1703,
                "name": "REG701",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "7824e0bc-aaae-4704-b288-88361e83c20e",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 4983,
              "name": "Day",
              "contract": {
                "id": 3172,
                "name": "San Diego",
                "customer_id": 74,
                "customer_name": "Abbott",
                "uuid": "81576ce5-e7dc-4251-a867-c9382695dedf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5705,
              "name": "Day",
              "contract": {
                "id": 3794,
                "name": "Mesa Rim",
                "customer_id": 74,
                "customer_name": "Abbott",
                "uuid": "aee3bb3a-acee-4a9b-b1fe-05c267cf7593",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2534,
              "name": "Swing",
              "contract": {
                "id": 1583,
                "name": "San Diego, CA (JLL)",
                "customer_id": 158,
                "customer_name": "HP",
                "uuid": "8188f4e0-572f-44c0-a828-bd6c1f2f109e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 2427,
              "name": "Swing",
              "contract": {
                "id": 1661,
                "name": "Carlsbad, CA",
                "customer_id": 190,
                "customer_name": "Millipore Sigma",
                "uuid": "8684ca88-2620-40ea-8857-94c12a35aab3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 1388,
              "name": "Unassigned - Riverside",
              "contract": {
                "id": 2122,
                "name": "Riverside",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "eee2d1ed-3640-49c7-b453-3e089aba0969",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2022-10-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e84201a3-1ea0-4b66-9832-912ac4967aa1"
        },
        {
          "id": 81118,
          "person": {
            "id": 80487,
            "first_name": "Sophia",
            "last_name": "Pyne",
            "photo_url": "assets/r04ad01c142954abd8a9aa3eaa3a697aa_SophiaPyneHQ2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb5bf843-655b-457f-9176-74f2fe81dd74"
        },
        {
          "id": 74042,
          "person": {
            "id": 73501,
            "first_name": "Sor Angel",
            "last_name": "Pena Sosa",
            "photo_url": "assets/d3ab7490d5594989a39389812c23e58c_SorangelPenaTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "02af530a-6f05-4e40-b645-02f5ec3502c0"
        },
        {
          "id": 48206,
          "person": {
            "id": 47717,
            "first_name": "Spencer",
            "last_name": "Murphy",
            "photo_url": "assets/r55df84f754eb4290a2bbea4ca44780a5_SM_t.png",
            "phone_number": "6149663834",
            "email_address": "smurphy@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1667,
              "name": "Sr Director Global Accounts",
              "name_abbreviation": "SDGACC"
            }
          ],
          "shifts": [
            {
              "id": 7830,
              "name": "1-Day",
              "contract": {
                "id": 5797,
                "name": "OHAP – Ohio Assembly Plant – Avon Lake, OH",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "1f3084fd-8ac6-41f4-a224-8ed7cbd74791",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7588,
              "name": "Day",
              "contract": {
                "id": 5711,
                "name": "Automotive Vertical Overhead SBM",
                "customer_id": 2122,
                "customer_name": "CRM NAD",
                "uuid": "68195b19-6be3-4148-979c-89a492606cd3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1514,
              "name": "Day",
              "contract": {
                "id": 2251,
                "name": "RIC – Research & Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7142,
              "name": "None",
              "contract": {
                "id": 4831,
                "name": "KCAP - Kansas City Assembly Plant - Kansas City, MO",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b97ea5fa-b029-4ad9-84b1-b13252eb78a6",
                "chatbot_sms_phone_number": "8164953660",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8108,
              "name": "Unassigned",
              "contract": {
                "id": 2214,
                "name": "WPE – Wagner Place East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8107,
              "name": "Unassigned",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8103,
              "name": "Unassigned",
              "contract": {
                "id": 5864,
                "name": "FEL – Ford Engineering Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2021-05-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cffc2b06-533e-452a-8000-9f0b5b157d06"
        },
        {
          "id": 50309,
          "person": {
            "id": 49807,
            "first_name": "Stacy",
            "last_name": "Kratoska",
            "photo_url": "assets/062bcabc17e642f5b302d19f50627731_IMG_1610_t.jpg",
            "phone_number": "3178649541",
            "email_address": "skratoska@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf9393b3-ba1f-4419-9c42-b030b1a24115"
        },
        {
          "id": 81102,
          "person": {
            "id": 80471,
            "first_name": "Stephen",
            "last_name": "Luttenegger",
            "photo_url": "assets/96927eb63763402d9fb838e3344d8776_StephenLuttenegerUniversity2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "123ebf50-34a5-4957-a0c1-58599e16aec3"
        },
        {
          "id": 80157,
          "person": {
            "id": 79533,
            "first_name": "Steven",
            "last_name": "Duncan",
            "photo_url": "assets/53251f594e4d4b539212bfd353eec134_5.24_t.2023",
            "phone_number": "+14255470678",
            "email_address": "sduncan@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2229f80c-8925-477f-8b4d-2bb98c1df5de"
        },
        {
          "id": 51178,
          "person": {
            "id": 50664,
            "first_name": "Steven",
            "last_name": "Nguyen",
            "photo_url": "assets/310a132f062644bbb1b2bcad38a0c56e_StevenNguyenBadgepic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-08-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fd40d4a4-8bf9-48a3-b7e3-22e34ca38a20"
        },
        {
          "id": 48961,
          "person": {
            "id": 48467,
            "first_name": "Sulermy",
            "last_name": "Rodriguez",
            "photo_url": "assets/a1709b2f4c0a4634833a09289e0337f9_thumbnail_20210624_165746_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-05-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ce24a6e-8a9c-41ac-8ed6-0322b38c789e"
        },
        {
          "id": 74229,
          "person": {
            "id": 73687,
            "first_name": "Suliman",
            "last_name": "Nasher",
            "photo_url": "assets/750461ef03404cf6a851f39ad0c93565_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6654df22-1076-4b1d-80f2-cd90aa737b1b"
        },
        {
          "id": 74217,
          "person": {
            "id": 73675,
            "first_name": "Sussy",
            "last_name": "Camero Mogollon",
            "photo_url": "assets/df007619f1964b9496eebc0925ce3f4b_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2484fd9e-3e47-4b60-9d6e-d8b24e353893"
        },
        {
          "id": 74744,
          "person": {
            "id": 74201,
            "first_name": "Suyapa",
            "last_name": "Marquez",
            "photo_url": "assets/r768d5bac3d8c47f793ffcaafb18a0d75_suyapa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c524c23-7e2a-4138-af44-97efc5c3f1b2"
        },
        {
          "id": 74231,
          "person": {
            "id": 73689,
            "first_name": "Sylvester",
            "last_name": "Ellis",
            "photo_url": "assets/d17eadd9e54c44d885263bca6e976125_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5905323f-b9ea-4beb-bc7c-d8b86c84ccc5"
        },
        {
          "id": 69176,
          "person": {
            "id": 68641,
            "first_name": "Tai",
            "last_name": "Danh",
            "photo_url": "assets/7fa46f39c88746c6b322a27186c068a9_TaiDanh_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da1bebb5-8a7a-49e7-a3ca-d4fecd3c09b3"
        },
        {
          "id": 74137,
          "person": {
            "id": 73595,
            "first_name": "Tania",
            "last_name": "Araujo Hernandez",
            "photo_url": "assets/43975aaac8864b56a76ce211516775ac_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f12e7997-88f2-4c60-bbab-e00fa4dd0190"
        },
        {
          "id": 71811,
          "person": {
            "id": 71279,
            "first_name": "Taylor",
            "last_name": "Naud",
            "photo_url": "assets/2bd5f25f2bd841a19d6e41156fc48073_08.2022_t.SBM",
            "phone_number": "425-970-0195",
            "email_address": "tnaud@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1dc8dfb0-86ff-4b62-987f-45fd69716532"
        },
        {
          "id": 76147,
          "person": {
            "id": 75579,
            "first_name": "Taylor",
            "last_name": "Wattenberg",
            "photo_url": "assets/fca0e506f7bf41a798f7c415a0d3833b_Pic.Med_t.jpg",
            "phone_number": "1(425) 866-3409",
            "email_address": "taywattenberg@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e33dc61b-c408-4ece-92e6-18f0402b27f1"
        },
        {
          "id": 70620,
          "person": {
            "id": 70084,
            "first_name": "Teneshia",
            "last_name": "Vaughn",
            "photo_url": "assets/c07ba65a13e0437b8ab0299097ba0a8a_20220504_065415_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "55609e38-f76c-4bd7-8b5e-d31ed861b113"
        },
        {
          "id": 74040,
          "person": {
            "id": 73499,
            "first_name": "Teresa",
            "last_name": "Ascencio",
            "photo_url": "assets/a952e23b264e4840a0e86875876ef5bc_TeresaAscencioTakeda_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e36bcc21-6f89-47a7-b050-d4ce6894d104"
        },
        {
          "id": 45722,
          "person": {
            "id": 45276,
            "first_name": "Teresa",
            "last_name": "Barrios Nava",
            "photo_url": "assets/0c554a02a58e4c509fba2b54bdd067cc_TERESABARRIOS_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6783,
              "name": "Swing",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "002b195d-0818-45a6-8850-83041273b1ff"
        },
        {
          "id": 53930,
          "person": {
            "id": 53387,
            "first_name": "Theresa",
            "last_name": "Estrada",
            "photo_url": "assets/5f684b659c854def822200ec9cbf85d3_TE_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f84aeec7-4f0e-4488-bf6b-907ea468c82a"
        },
        {
          "id": 52606,
          "person": {
            "id": 52070,
            "first_name": "Thi Lay",
            "last_name": "Tran",
            "photo_url": "assets/417d375b33b64fc0b1a146e0c3031633_ThiLayTran_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "301410ed-294b-4c9e-bc63-41efb4cff2a9"
        },
        {
          "id": 54232,
          "person": {
            "id": 53689,
            "first_name": "Thomas",
            "last_name": "Mercer",
            "photo_url": "assets/bdf4f27b3d2147afa73d5b80a86abab2_thomas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3166,
              "name": "Day",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "faaf3afb-5156-4458-9a8c-a479c7e65ff5"
        },
        {
          "id": 78834,
          "person": {
            "id": 78238,
            "first_name": "Timothy",
            "last_name": "Mengle",
            "photo_url": "assets/86750cab82464433bf763755726f8547_PXL_20220917_111531890_t.jpg",
            "phone_number": "570-540-6522",
            "email_address": "tmengle@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1114,
              "name": "Day",
              "contract": {
                "id": 1486,
                "name": "Cherokee, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4109c226-f872-4b8a-a313-3387f2c6916b"
        },
        {
          "id": 53954,
          "person": {
            "id": 53411,
            "first_name": "Timothy",
            "last_name": "Shelton",
            "photo_url": "assets/fa5b486d8ab54d66a44f41b58510e846_WhatsAppImage20221205at08.20_t.59",
            "phone_number": "",
            "email_address": "tshelton@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3174,
              "name": "Day",
              "contract": {
                "id": 2656,
                "name": "Murfreesboro",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "69c31c57-abb7-405d-9a8c-60e05813605b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "485bf9b1-649b-43d8-aa93-8c0a944147b4"
        },
        {
          "id": 77023,
          "person": {
            "id": 76448,
            "first_name": "Tirzah",
            "last_name": "Mills",
            "photo_url": "assets/28c7f2a71a624a9283aa8dedfe0283d8_TirzahMillsHeadshot2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 75,
              "name": "Site Sustainability Mgr",
              "name_abbreviation": "SSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ccd4da0b-6c6a-4e26-b778-099d7460e58e"
        },
        {
          "id": 74465,
          "person": {
            "id": 73923,
            "first_name": "Tomas",
            "last_name": "Diaz",
            "photo_url": "assets/db0b3fa4918547c794a27259f0f7682f_TOMASD_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cf4bd7b0-efa2-409d-bffd-cbb5fabb48b9"
        },
        {
          "id": 54192,
          "person": {
            "id": 53649,
            "first_name": "Tomasa",
            "last_name": "Terron Soto",
            "photo_url": "assets/843434e523ad49f7b1eb73eac679ece2_tomasasoto_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7890,
              "name": "Day",
              "contract": {
                "id": 5809,
                "name": "Pharma Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3062c37-45c0-4e38-a7df-3db04cf11fe3",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-11-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8a362dc5-b19d-418c-9155-1f49d4ecb127"
        },
        {
          "id": 14745,
          "person": {
            "id": 14746,
            "first_name": "Tony",
            "last_name": "Williams",
            "photo_url": "20ef785b-c66f-9e5c-f563-b573e1900e75.png",
            "phone_number": "",
            "email_address": "230009@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac3c1ec7-f104-420b-9609-f7c34399666c"
        },
        {
          "id": 80927,
          "person": {
            "id": 80297,
            "first_name": "Torrance",
            "last_name": "Chandler",
            "photo_url": "assets/49a5a73916b249ac834e2c4a220c92d7_ChandlerTorrance251440image_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7448,
              "name": "Swing",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "235f49cb-3f7d-439d-ae2d-c5c9bbff14ba"
        },
        {
          "id": 80400,
          "person": {
            "id": 79770,
            "first_name": "Tracy",
            "last_name": "Darden",
            "photo_url": "assets/cb23a48e96e94453a161bcf0e5854f67_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 5403,
              "name": "Day",
              "contract": {
                "id": 3687,
                "name": "Durham, NC",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "b4fa3833-0a28-45e3-b008-3099a500f4e4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f46a0d78-0218-4b43-bb03-31fbd1c8cd0a"
        },
        {
          "id": 70285,
          "person": {
            "id": 69745,
            "first_name": "Trinity",
            "last_name": "Levine",
            "photo_url": "assets/944909f48aef4452a2b21976484d661c_4insitepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45528969-c74a-474b-bedd-2cf0039bbb73"
        },
        {
          "id": 72073,
          "person": {
            "id": 71538,
            "first_name": "Tulio Manuel",
            "last_name": "Sosa",
            "photo_url": "assets/rfc00cac4817f4b1cbf931e9efef79cb7_tulio_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-05-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c566578-ef7c-40a5-a042-b3946de11b45"
        },
        {
          "id": 75503,
          "person": {
            "id": 74968,
            "first_name": "Urbano",
            "last_name": "Azevedo",
            "photo_url": "assets/462482aea69e43fdaf6ef71ddb910a81_Urbano111_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f750c0b-70d5-41ae-a7ad-8fcafbbd57bd"
        },
        {
          "id": 74136,
          "person": {
            "id": 73594,
            "first_name": "Vanessa",
            "last_name": "Arellano Ortiz",
            "photo_url": "assets/f31153517d4e4f1aa03bb4ed71391673_vane_t.jpeg",
            "phone_number": "6506987506",
            "email_address": "vortiz@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6094,
              "name": "Swing",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-02-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "979b3b32-286c-4269-b3b1-12468c825a7e"
        },
        {
          "id": 74410,
          "person": {
            "id": 73868,
            "first_name": "Vanessa",
            "last_name": "Sainz Reyes",
            "photo_url": "assets/9e89ae12f39e4c3f8932f59485ab7be6_VANESSASAINS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 6095,
              "name": "Night",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ae6a59a-fee9-4399-9573-6ac26c219f7c"
        },
        {
          "id": 75242,
          "person": {
            "id": 74694,
            "first_name": "Vanna",
            "last_name": "Pen",
            "photo_url": "assets/7becc42ce52d4bff9a01949787e99e91_VannaPen248267_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1164,
              "name": "Tech, Recycle (Loc 6 WA)",
              "name_abbreviation": "6RECTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-08-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1a22515b-9bd3-40bf-8858-23043e3eba71"
        },
        {
          "id": 74391,
          "person": {
            "id": 73849,
            "first_name": "Veronica",
            "last_name": "Guizar Zuniga",
            "photo_url": "assets/f922870dd8b147f487eba7392dd2d2fa_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6096,
              "name": "Day",
              "contract": {
                "id": 3992,
                "name": "Burlingame",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "2fc13ff1-8384-4272-ae27-2e38cf7713ef",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b63f49cd-d427-4945-abe0-a91176ef046b"
        },
        {
          "id": 73941,
          "person": {
            "id": 73401,
            "first_name": "Veronica",
            "last_name": "Lade",
            "photo_url": "assets/aa69966c868b416c99740bd96ffc4bc8_20230616152028003_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6028,
              "name": "Night",
              "contract": {
                "id": 2162,
                "name": "Manteno, IL",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0dcd2fb8-ef00-45e5-a944-10918e8afa8c"
        },
        {
          "id": 74323,
          "person": {
            "id": 73781,
            "first_name": "Veronica",
            "last_name": "Najera Cardenas",
            "photo_url": "assets/3810d04fe66b49c9b010115af122fa1e_VERONICAN_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "041cd0ec-0aa9-4d44-9a83-788ec6d9e516"
        },
        {
          "id": 74268,
          "person": {
            "id": 73726,
            "first_name": "Veronica",
            "last_name": "Oseguera",
            "photo_url": "assets/008f6697aa184bda9571b445d46b0fca_VERONICAOS_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b739219-154c-4c68-ab55-e64413026d0b"
        },
        {
          "id": 74129,
          "person": {
            "id": 73587,
            "first_name": "Veronica",
            "last_name": "Siqueiros Solis",
            "photo_url": "assets/0c4a65e0a3eb48a798c48265daa6a020_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 6618,
              "name": "Swing",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cdad212c-0fcc-45e9-8f5b-5ae1a52b0734"
        },
        {
          "id": 54933,
          "person": {
            "id": 54387,
            "first_name": "Vi Binh",
            "last_name": "Chuong",
            "photo_url": "assets/6f75f867611f4b8a9628ab6c92247822_Vi_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a667b9d9-b7da-491a-b76f-4293b4b292b0"
        },
        {
          "id": 81272,
          "person": {
            "id": 80640,
            "first_name": "Victor",
            "last_name": "Garza",
            "photo_url": "assets/eac6c46bfd2e4a3586b8cc72aebbdd1c_IMG_0647garza_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd7bca33-0890-4335-b277-429782872cfc"
        },
        {
          "id": 14735,
          "person": {
            "id": 14736,
            "first_name": "Victoria",
            "last_name": "Campbell",
            "photo_url": "dd47df93-743b-1614-3d3f-4d28c27dbd16.png",
            "phone_number": "",
            "email_address": "229998@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-05-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae8aa496-b653-42f9-8a18-414283feaa56"
        },
        {
          "id": 52572,
          "person": {
            "id": 52037,
            "first_name": "Vilma",
            "last_name": "Del Pozo",
            "photo_url": "assets/r06ab3187d9c24f2fbafe33f33df4863e_Vilma_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 3120,
              "name": "Day",
              "contract": {
                "id": 2639,
                "name": "Winter Haven",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "8cd64e8b-2467-42c2-8bb3-fe2aca91c0e9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2ee93ac-092f-4840-9059-21666f20a353"
        },
        {
          "id": 81273,
          "person": {
            "id": 80641,
            "first_name": "Virgie",
            "last_name": "Macias",
            "photo_url": "assets/5e33f329d8a6416a922e91bd5d881e34_IMG_0656virgie_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7524,
              "name": "None",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2023-01-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a20e7560-490e-489f-b8c9-79a2c6270dba"
        },
        {
          "id": 74817,
          "person": {
            "id": 74273,
            "first_name": "Vivian",
            "last_name": "Hernandez",
            "photo_url": "assets/1a428e0abcbc4210acd28e399d506178_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b3f387a-f0fd-4a17-afc0-ff2a2b49f9d4"
        },
        {
          "id": 50690,
          "person": {
            "id": 50185,
            "first_name": "Vuthy",
            "last_name": "Chan",
            "photo_url": "assets/3086681c18014e14beef70da59a2813a_vuthycanpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2021-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f2012e5-8290-4eeb-a043-69e5aec7af14"
        },
        {
          "id": 79552,
          "person": {
            "id": 78935,
            "first_name": "Waldir",
            "last_name": "Tejada Burgos",
            "photo_url": "assets/a7833cbd111d42f08aa5f3e58b1099a0_waldir_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3000,
              "name": "Swing",
              "contract": {
                "id": 2576,
                "name": "Durham",
                "customer_id": 470,
                "customer_name": "IQVIA (CBRE)",
                "uuid": "12dc8c4e-4706-47ec-a8e2-d89fd110383f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-12-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b78064d-964e-48c1-a581-a50716e3edb7"
        },
        {
          "id": 72846,
          "person": {
            "id": 72309,
            "first_name": "Walter",
            "last_name": "Bentley",
            "photo_url": "assets/ea6a3a5b69da43939e7dc4e8a3566a00_Walt_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-06-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c4203ba-11e0-43a5-a8c7-1047b44a2569"
        },
        {
          "id": 72200,
          "person": {
            "id": 71665,
            "first_name": "Wandy",
            "last_name": "Lara",
            "photo_url": "assets/673c056d503b4dbda080495a50a7ed57_thumbnail_Wandybadgepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-05-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27ce22b7-5559-416e-801f-d3df47f91c8b"
        },
        {
          "id": 74241,
          "person": {
            "id": 73699,
            "first_name": "Wei Di",
            "last_name": "Chen",
            "photo_url": "assets/2e494bb0fa4844199f8d871bb269d3ca_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            },
            {
              "id": 2546,
              "name": "Restroom Attendant",
              "name_abbreviation": "RRMATT"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "edb0de55-2aa1-49d9-b574-13623f38aec8"
        },
        {
          "id": 70416,
          "person": {
            "id": 69881,
            "first_name": "Wendie",
            "last_name": "Honeysett",
            "photo_url": "assets/e89e6c44640b4511a74aee41d4c5f294_StandardFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3179,
              "name": "Day",
              "contract": {
                "id": 2634,
                "name": "Kalamazoo, MI",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "791209fc-57ee-4271-bd07-90aa1e8978d8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-04-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ec2d499-8003-4fce-a4b5-e3507e570ccc"
        },
        {
          "id": 85712,
          "person": {
            "id": 85044,
            "first_name": "William",
            "last_name": "Bermudez Jimenez",
            "photo_url": "assets/1cee624784b44d468f8bf85c84b5356c_WilliamBermudez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6027,
              "name": "Day",
              "contract": {
                "id": 4226,
                "name": "Santa Fe Springs",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "870af846-4292-4889-a372-74fda3d7f0b9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e0217b9b-8229-4bd0-b71e-a0792d1d5d8e"
        },
        {
          "id": 74450,
          "person": {
            "id": 73908,
            "first_name": "Willie",
            "last_name": "Humphreys",
            "photo_url": "assets/674cec8a78ce4c2ca7c675f28e36832e_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6625,
              "name": "Day",
              "contract": {
                "id": 3851,
                "name": "Classic",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "7f7d06d7-0fdd-46a7-8641-9b7cba7eee48",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a6eb8837-0613-4f0c-9efa-507a70f6fba6"
        },
        {
          "id": 69569,
          "person": {
            "id": 69034,
            "first_name": "Willie",
            "last_name": "Norwood",
            "photo_url": "assets/241041b7b1704c42bc4f3292a7ae05ee_WNorwoodPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-03-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "362a6eba-fef5-4a5b-9554-e0c865d60403"
        },
        {
          "id": 80537,
          "person": {
            "id": 79907,
            "first_name": "Wilmer",
            "last_name": "Lopez Amezcua",
            "photo_url": "assets/f16653af085f43528776fe80cc543005_20230202_200906_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e6a9a706-d2e8-461c-a564-4ebf83559c31"
        },
        {
          "id": 74238,
          "person": {
            "id": 73696,
            "first_name": "Xue",
            "last_name": "Tan",
            "photo_url": "assets/14410b6d9f27498ca1b7d67fad93f2aa_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6093,
              "name": "Swing",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9e1070d7-0198-47c9-91d4-89856ff8c1da"
        },
        {
          "id": 74371,
          "person": {
            "id": 73829,
            "first_name": "Yadira",
            "last_name": "Vazquez",
            "photo_url": "assets/3cf8db9bcda2459b95068b83f6c60c10_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "973367dd-4f26-40ca-a157-c769d79d7398"
        },
        {
          "id": 68235,
          "person": {
            "id": 67692,
            "first_name": "Yakishka",
            "last_name": "Hayes",
            "photo_url": "assets/44cd7ff0679e467aa9c50106406603e5_Yakishka_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-02-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8b737460-1ced-4196-9694-13e09ae6d02f"
        },
        {
          "id": 74403,
          "person": {
            "id": 73861,
            "first_name": "Yassenia",
            "last_name": "De Leon Escobar",
            "photo_url": "assets/ae0da2de96cb44b5be2e3b834848fc8e_YASSENIAD_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6090,
              "name": "Night",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2022-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40124a2a-2849-4766-bbad-971b670a7ad1"
        },
        {
          "id": 75492,
          "person": {
            "id": 74957,
            "first_name": "Ydaly",
            "last_name": "Lopez Padilla",
            "photo_url": "assets/d6cce897287248d9992248989ce9bdd9_20_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6025,
              "name": "Swing",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e2e502e-0778-4f8c-a2ba-fdea992ec27b"
        },
        {
          "id": 80220,
          "person": {
            "id": 79596,
            "first_name": "Yennyc",
            "last_name": "Garcia Herrera",
            "photo_url": "assets/60b0dc6ba55147c08a66898e1dc90027_Jennyphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2023-01-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7cb87416-3eaf-40a2-b1a8-e488f5a145d5"
        },
        {
          "id": 46308,
          "person": {
            "id": 45839,
            "first_name": "Yerri",
            "last_name": "Lopez De Orozco",
            "photo_url": "assets/1617307694.502953_IMG_7626_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2332,
              "name": "Day",
              "contract": {
                "id": 2445,
                "name": "Jupiter, FL",
                "customer_id": 438,
                "customer_name": "Pratt & Whitney (CBRE)",
                "uuid": "e3419d91-82fc-41da-840f-e1fa9d5eb4f0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "acb1ba60-c429-4857-9594-5e3bb39b2ce8"
        },
        {
          "id": 54725,
          "person": {
            "id": 54180,
            "first_name": "Yoani",
            "last_name": "Almonte",
            "photo_url": "assets/64208bf07bf14a61b1d45c95e2864ba8_yoanialmonte_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3167,
              "name": "Swing",
              "contract": {
                "id": 2637,
                "name": "RTP Bio Facility",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "b3bcf622-c560-44ad-9883-1633fc125cb8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-12-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65742071-5d00-448b-9bd4-98ed975a17b4"
        },
        {
          "id": 7043,
          "person": {
            "id": 7043,
            "first_name": "Yolanda",
            "last_name": "Espinoza",
            "photo_url": "assets/5c450351a9564a90808834c5faf4b3f0_20230630_213857_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-09-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38c3cca2-5960-435c-b235-41b598657d89"
        },
        {
          "id": 79993,
          "person": {
            "id": 79369,
            "first_name": "Yrene",
            "last_name": "Ferreras De Sena",
            "photo_url": "assets/ce2d309994944895b575b6a67005026b_4SFemale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7916,
              "name": "Day",
              "contract": {
                "id": 5849,
                "name": "10 Exchange Place, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-12-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "71732b45-f9dd-4bad-ac78-b665801c96ef"
        },
        {
          "id": 75283,
          "person": {
            "id": 74734,
            "first_name": "Yun",
            "last_name": "Kem",
            "photo_url": "assets/bca70865533d4e529827b5ebeac7ffcd_YunKem_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "abcecf69-a4f3-470c-b401-7bc45b4ff8b8"
        },
        {
          "id": 52410,
          "person": {
            "id": 51884,
            "first_name": "Zachary",
            "last_name": "Crawley",
            "photo_url": "assets/02a3e97d6ab64f0eb3bdc07a7a2d4d4c_IMG_1700_t.jpg",
            "phone_number": "3174994717",
            "email_address": "zcrawley@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a5d6578-e142-4115-8416-5b1f35e00aac"
        },
        {
          "id": 75480,
          "person": {
            "id": 74945,
            "first_name": "Zoel",
            "last_name": "Medina",
            "photo_url": "assets/rb33318ff7a0942bab40bbdf0c76e4204_z.EE1takeda_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80e03abc-18d5-431e-9d9d-e9181c89b192"
        },
        {
          "id": 74118,
          "person": {
            "id": 73576,
            "first_name": "Zulma",
            "last_name": "Joachin",
            "photo_url": "assets/46d5088c07ce4e98bab278c204f5942b_Capture_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2022-07-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a3d73c2-1f81-4c8d-82ae-75d05e7c1f57"
        }
      ]
    },
    {
      "rangeTitle": "3 - 5 Years",
      "rangeSubtitle": "Employed for 3 to 5 years",
      "percentage": 0.12,
      "total": 305,
      "positions": 32,
      "employees": [
        {
          "id": 11225,
          "person": {
            "id": 11225,
            "first_name": "Aaron",
            "last_name": "Laroche",
            "photo_url": "64d3d91b-36e2-3cba-3b25-ef668df1dcdf.png",
            "phone_number": "",
            "email_address": "198356@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ff056ac-904d-462e-b5e8-45a16a304881"
        },
        {
          "id": 12488,
          "person": {
            "id": 12488,
            "first_name": "Adda",
            "last_name": "Bautista",
            "photo_url": "assets/1598400132.3260944_adda_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 5056,
              "name": "Day",
              "contract": {
                "id": 3233,
                "name": "Union City",
                "customer_id": 921,
                "customer_name": "Tenaya(CBRE)",
                "uuid": "9e7abada-eea7-4849-b0c6-e2bab7292276",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-05-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7eb01b07-d1aa-42f3-b040-abf8f7c48631"
        },
        {
          "id": 41958,
          "person": {
            "id": 41550,
            "first_name": "Alan",
            "last_name": "White",
            "photo_url": "assets/1604500494.7070644_IMG_0270_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7971,
              "name": "Swing",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19c7a296-50af-4dc8-b365-e4136fca6430"
        },
        {
          "id": 19457,
          "person": {
            "id": 19567,
            "first_name": "Albert",
            "last_name": "Garcia",
            "photo_url": "assets/1589306825.2602842_20200512_102528_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "753b82e6-e8bd-4646-9d44-5778f332e4ad"
        },
        {
          "id": 41941,
          "person": {
            "id": 41533,
            "first_name": "Albert",
            "last_name": "Mitchell",
            "photo_url": "assets/1606254906.5450294_AlbertMitchell_t.jpg",
            "phone_number": "",
            "email_address": "236221@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67199992-6058-4424-995c-fd5fe4375711"
        },
        {
          "id": 42023,
          "person": {
            "id": 41613,
            "first_name": "Alex",
            "last_name": "Bland",
            "photo_url": "assets/1614806764.1993403_alex_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e24ca53-e373-4932-82d4-71488199a882"
        },
        {
          "id": 19722,
          "person": {
            "id": 19815,
            "first_name": "Alexander",
            "last_name": "Sera",
            "photo_url": "assets/1594678941.8834553_seraheadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-01-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e583092d-fc3c-49b1-8cab-a4d062530927"
        },
        {
          "id": 19531,
          "person": {
            "id": 19637,
            "first_name": "Alfreda",
            "last_name": "Slaughter",
            "photo_url": "assets/1580263248.0008395_Freda2_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2372,
              "name": "Swing",
              "contract": {
                "id": 1997,
                "name": "Newark, DE",
                "customer_id": 269,
                "customer_name": "Chemours (CBRE)",
                "uuid": "9ca4755e-410f-4637-9310-7fc40b8bcb96",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-12-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "567754a9-e885-4b54-bb5b-3ecf258bbef6"
        },
        {
          "id": 43332,
          "person": {
            "id": 42913,
            "first_name": "Alicia",
            "last_name": "Garcia Garcia",
            "photo_url": "assets/1608318576.1185186_AliciaGarcias_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2d67b04-e63b-442d-9985-9f478883693f"
        },
        {
          "id": 42242,
          "person": {
            "id": 41832,
            "first_name": "Alonda",
            "last_name": "Horton",
            "photo_url": "assets/1604508997.8758492_AHortonpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8901,
              "name": "Swing",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b091594-b841-49dc-821c-229536c34113"
        },
        {
          "id": 42589,
          "person": {
            "id": 42170,
            "first_name": "Alonzo",
            "last_name": "Sweatt",
            "photo_url": "assets/1607105573.2561703_AlonzoSweatt_t.jpg",
            "phone_number": "2485614672",
            "email_address": "asweatt@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7971,
              "name": "Swing",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b03f9a3b-27a3-4716-a109-55ccd1acdda9"
        },
        {
          "id": 19350,
          "person": {
            "id": 19471,
            "first_name": "Alyce",
            "last_name": "Saylor",
            "photo_url": "assets/1583421735.1421053_AlyceSaylor2_t.jpg",
            "phone_number": "",
            "email_address": "232135@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f0d20e4-b016-4f84-9312-0113a5d44fd1"
        },
        {
          "id": 43279,
          "person": {
            "id": 42861,
            "first_name": "Amy",
            "last_name": "Reed",
            "photo_url": "assets/1608145643.4778936_amy2_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1490,
              "name": "Night",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "35f53f5b-fb9c-4cbc-9b76-d46fa0252172"
        },
        {
          "id": 42338,
          "person": {
            "id": 41925,
            "first_name": "Ana",
            "last_name": "Alvarado",
            "photo_url": "assets/1605285428.2574816_AnaMariaAlvarado_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2333,
              "name": "Day",
              "contract": {
                "id": 2444,
                "name": "Market Street",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "476b5b30-02ec-41da-b74a-86b56fbe7ab4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d1c4439-622b-4578-9327-3bfc1e7c8531"
        },
        {
          "id": 711,
          "person": {
            "id": 711,
            "first_name": "Ana",
            "last_name": "Martinez",
            "photo_url": "f8e5ed6b-be8f-135c-b5e9-23e71de4062d.png",
            "phone_number": "",
            "email_address": "229050@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ab4a62b-9fdc-44d4-8104-58d8ade5b09c"
        },
        {
          "id": 21898,
          "person": {
            "id": 21892,
            "first_name": "Ana",
            "last_name": "Mendoza",
            "photo_url": "assets/1583440550.302972_72F69EBE0D374E6B89022F3FA7CF71F4_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d97f513-d176-4a5f-bebf-f9634958cf05"
        },
        {
          "id": 65701,
          "person": {
            "id": 65159,
            "first_name": "Andre",
            "last_name": "Martin",
            "photo_url": "assets/add0aff16d0d42dda73cef9367b511cf_4SMale_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1bb04e96-c4d8-4a95-a340-3f00c5cba599"
        },
        {
          "id": 41940,
          "person": {
            "id": 41532,
            "first_name": "Andrew",
            "last_name": "Lafata",
            "photo_url": "assets/1614701018.3965254_AndrewLafata_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "253ee27f-beb1-44a9-adbe-87a96b9ccbc3"
        },
        {
          "id": 41957,
          "person": {
            "id": 41549,
            "first_name": "Angela",
            "last_name": "Ellingson",
            "photo_url": "assets/1604597529.5181863_RefusedphotoFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "479577aa-0040-4ae6-9bdb-65d78da7ff6d"
        },
        {
          "id": 6251,
          "person": {
            "id": 6251,
            "first_name": "Angela",
            "last_name": "Spears",
            "photo_url": "201d1ab6-43fc-f47d-be34-77bb1210addf.png",
            "phone_number": "",
            "email_address": "228901@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-06-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "532cdc9f-1451-45f4-a112-7468fca7ccf9"
        },
        {
          "id": 626,
          "person": {
            "id": 626,
            "first_name": "Angela",
            "last_name": "Warren",
            "photo_url": "ae167412-11e5-db63-7ead-1d826984300d.png",
            "phone_number": "",
            "email_address": "226817@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2628,
              "name": "On-Call",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2019-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "711ae5c3-23ca-449f-9173-57918cfa4aab"
        },
        {
          "id": 41123,
          "person": {
            "id": 40747,
            "first_name": "Angy",
            "last_name": "Medina Olarte",
            "photo_url": "assets/1602590967.0920908_AngyMedinaGMPTech_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e994a8d0-d338-4335-a528-345055bb1113"
        },
        {
          "id": 42098,
          "person": {
            "id": 41688,
            "first_name": "Aniqua",
            "last_name": "Price",
            "photo_url": "assets/1606311692.1242611_AniquaPrice_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c871311d-377f-4c38-8e2e-e5b4bf98ed5a"
        },
        {
          "id": 4809,
          "person": {
            "id": 4809,
            "first_name": "Ann",
            "last_name": "Hankins",
            "photo_url": "0b180c47-6842-52c2-2318-76800a7bd282.png",
            "phone_number": "",
            "email_address": "227588@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-03-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c3943ef-b950-4290-8110-87c015a64dc0"
        },
        {
          "id": 42030,
          "person": {
            "id": 41620,
            "first_name": "Ann",
            "last_name": "Jackson",
            "photo_url": "assets/1604509467.2519658_AJacksonpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a29ceea2-28af-4242-a8fd-015c5bf3db4f"
        },
        {
          "id": 42241,
          "person": {
            "id": 41831,
            "first_name": "Annalisa",
            "last_name": "Cribbs",
            "photo_url": "assets/1607973254.7474325_AnnalisaCribbs_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1c701334-2d63-41bd-b369-75abb47de3c8"
        },
        {
          "id": 42680,
          "person": {
            "id": 42260,
            "first_name": "Antoine",
            "last_name": "Shepard",
            "photo_url": "assets/1606932509.5639663_Me2_t.jpg",
            "phone_number": "2485616283",
            "email_address": "ashepard@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b30ba5dc-a7f2-4cbb-b79b-29c69c786c03"
        },
        {
          "id": 42024,
          "person": {
            "id": 41614,
            "first_name": "Antoinette",
            "last_name": "Boose",
            "photo_url": "assets/1604611243.3774152_30_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2098204-0e21-456a-b44c-c428b7ab142d"
        },
        {
          "id": 41093,
          "person": {
            "id": 40717,
            "first_name": "Antonio",
            "last_name": "Aleman",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8523a7bf-d6d9-4bd8-a869-b7440d321006"
        },
        {
          "id": 14741,
          "person": {
            "id": 14742,
            "first_name": "Aston",
            "last_name": "Daley",
            "photo_url": "bacce0ce-8fd1-1bd5-9ed6-415792895f07.png",
            "phone_number": "",
            "email_address": "230005@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42e54c86-81c1-4654-b96d-e54aaa07799b"
        },
        {
          "id": 344,
          "person": {
            "id": 344,
            "first_name": "Aura",
            "last_name": "Mazariegos-Ramirez",
            "photo_url": "assets/1606232843.873866_imagejpeg_03_t.jpg",
            "phone_number": "",
            "email_address": "229055@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8092e678-dee6-43ea-8be9-2f1cc8da6128"
        },
        {
          "id": 42092,
          "person": {
            "id": 41682,
            "first_name": "Ayesha",
            "last_name": "Sabir",
            "photo_url": "assets/1604609059.4280765_13_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c8ec060a-451d-4b78-b8fb-ebe4f25943be"
        },
        {
          "id": 25463,
          "person": {
            "id": 25343,
            "first_name": "Balvina",
            "last_name": "Tamayo-Soto",
            "photo_url": "assets/r93f2d3894fcd43858e56ad2e6186c993_Balvina_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 3096,
              "name": "Day",
              "contract": {
                "id": 2632,
                "name": "Alpharetta, GA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-04-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "77940d4c-6e24-4440-939e-88e6e014521a"
        },
        {
          "id": 39884,
          "person": {
            "id": 39519,
            "first_name": "Bertha",
            "last_name": "Holder",
            "photo_url": "assets/1600667376.4630766_20200914_181456_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a039dbbd-3d1c-408e-914a-ac88ced251de"
        },
        {
          "id": 41959,
          "person": {
            "id": 41551,
            "first_name": "Betty",
            "last_name": "Moffett",
            "photo_url": "assets/1604509304.2948046_BMoffettpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21e7d0c9-76ec-494f-9fbd-ea9951d481ce"
        },
        {
          "id": 24179,
          "person": {
            "id": 24132,
            "first_name": "Blanca",
            "last_name": "Hernandez",
            "photo_url": "assets/1617640857.1728466_IMG_0188_t.JPG",
            "phone_number": "",
            "email_address": "233312@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8532,
              "name": "Swing",
              "contract": {
                "id": 1812,
                "name": "Westridge",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "d818e965-5360-4456-a460-9d794f4588a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49f68695-b917-48dc-88a7-9ecee80cf85b"
        },
        {
          "id": 42175,
          "person": {
            "id": 41765,
            "first_name": "Brandon",
            "last_name": "Rodriguez",
            "photo_url": "assets/1608127214.275704_BrandonRodrigues_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e97817df-e154-479c-9917-c2d95b58e96d"
        },
        {
          "id": 19139,
          "person": {
            "id": 19263,
            "first_name": "Brenda",
            "last_name": "Tostado Lopez",
            "photo_url": "assets/1577776047.9164042_yes2_t.jpg",
            "phone_number": "",
            "email_address": "232043@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "730a6062-97de-48e8-8bc9-8e8454e314e7"
        },
        {
          "id": 42039,
          "person": {
            "id": 41629,
            "first_name": "Brenda",
            "last_name": "Williams",
            "photo_url": "assets/1608045997.9724169_BrendaWilliams244_t.jpg",
            "phone_number": "",
            "email_address": "236244@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "af3493f4-1f35-4372-b871-084fbc954553"
        },
        {
          "id": 42641,
          "person": {
            "id": 42221,
            "first_name": "Caleb",
            "last_name": "Sparks",
            "photo_url": "assets/1605630328.8790097_calebpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31bebc74-6499-4414-bf1c-2c14cdd3ea6a"
        },
        {
          "id": 19161,
          "person": {
            "id": 19285,
            "first_name": "Candelaria",
            "last_name": "Avalos",
            "photo_url": "assets/1579640430.6205127_CandelariaAvalos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f2ad46b6-2cfb-4b19-bb24-d96781c1352f"
        },
        {
          "id": 20126,
          "person": {
            "id": 20201,
            "first_name": "Carlos",
            "last_name": "Bonilla Guevara",
            "photo_url": "assets/1580319811.4495296_BonillaCarlos2_t.jpeg",
            "phone_number": "",
            "email_address": "232565@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0324a136-d813-4af1-b129-b271407b3341"
        },
        {
          "id": 42022,
          "person": {
            "id": 41612,
            "first_name": "Catherine",
            "last_name": "Avery",
            "photo_url": "assets/1614880429.4740229_CatherineAvery_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da8a80ca-c501-4d24-9a5e-34cb1eee10e6"
        },
        {
          "id": 65653,
          "person": {
            "id": 65111,
            "first_name": "Chardonnay",
            "last_name": "Conner",
            "photo_url": "",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ecd91947-df96-448f-995c-c054a95539bc"
        },
        {
          "id": 42034,
          "person": {
            "id": 41624,
            "first_name": "Charisse",
            "last_name": "Tuggle",
            "photo_url": "assets/1614178020.4504032_CharisseTuggle_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45be8edc-20dc-4a33-a871-3a37dc17aadd"
        },
        {
          "id": 42100,
          "person": {
            "id": 41690,
            "first_name": "Cheri",
            "last_name": "Hickman",
            "photo_url": "assets/1605286632.7413254_cherri_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "62813f32-1973-4b36-90bb-c983f9f7ce73"
        },
        {
          "id": 14774,
          "person": {
            "id": 14775,
            "first_name": "Cherifatou",
            "last_name": "Moustapha",
            "photo_url": "d2b1d264-0e4c-cda0-a8e1-c4e8208bfb0c.png",
            "phone_number": "",
            "email_address": "230050@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c0332ee-a093-4546-aa62-0aef35fdab68"
        },
        {
          "id": 42059,
          "person": {
            "id": 41649,
            "first_name": "Christina",
            "last_name": "Blevins",
            "photo_url": "assets/ab95125c095c4287a9f1d114eb8e2918_ChristinaBlevins_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb217ba9-163b-48e5-b561-99b9b759fbaf"
        },
        {
          "id": 42021,
          "person": {
            "id": 41611,
            "first_name": "Claudia",
            "last_name": "Barnes",
            "photo_url": "assets/1662ff4864ec4acdb9b481aafef2f5b9_CB_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e706dd9b-144e-4828-8801-9f234d1597c2"
        },
        {
          "id": 15400,
          "person": {
            "id": 15398,
            "first_name": "Cleotide",
            "last_name": "Perez-Perez",
            "photo_url": "assets/1585693786.258906_YanethInsitePicture_t.png",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4719102b-17d9-46c4-9266-dbaf3aadd5b5"
        },
        {
          "id": 21236,
          "person": {
            "id": 21247,
            "first_name": "Cong",
            "last_name": "Dinh",
            "photo_url": "assets/d924bb6670cd451e9d8b9e5d2297d9d2_IMG_20211014_155837_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-02-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "747eb11a-bf15-495d-bcb7-e480f81f03b8"
        },
        {
          "id": 18814,
          "person": {
            "id": 18951,
            "first_name": "Cuong",
            "last_name": "Lam",
            "photo_url": "assets/1576045390.438697_CuongLam_t.png",
            "phone_number": "",
            "email_address": "231631@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-11-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad905d27-0614-49f8-83b9-237e691e8d95"
        },
        {
          "id": 43362,
          "person": {
            "id": 42942,
            "first_name": "Daisy",
            "last_name": "Villeda",
            "photo_url": "assets/1607965927.5430276_DaisyBothellNHO_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2181,
              "name": "Day",
              "contract": {
                "id": 2264,
                "name": "Bothell, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "908fc000-28d0-439e-9912-ff88967db144",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "84c67b08-dd9d-411d-99c8-b3b73ebe9010"
        },
        {
          "id": 42046,
          "person": {
            "id": 41636,
            "first_name": "Daneisha",
            "last_name": "Taylor",
            "photo_url": "assets/1604597767.429624_RefusedphotoFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5ab7924-1ead-436a-98a0-bcc2d44452f9"
        },
        {
          "id": 42044,
          "person": {
            "id": 41634,
            "first_name": "Danika",
            "last_name": "Taylor-Lake",
            "photo_url": "assets/1604602843.2847698_lake_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 814,
              "name": "CSR Lead (UAW 245)",
              "name_abbreviation": "CSLUAW"
            }
          ],
          "shifts": [
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0c5c11d7-0445-4ade-a6bf-36821ca20388"
        },
        {
          "id": 37743,
          "person": {
            "id": 37458,
            "first_name": "David",
            "last_name": "Cornelious",
            "photo_url": "assets/1592255672.8194847_Diii_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-06-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca43bb9f-9703-4387-871a-22e7f2b8d3e0"
        },
        {
          "id": 9915,
          "person": {
            "id": 9915,
            "first_name": "David",
            "last_name": "Thompsett",
            "photo_url": "69561eb4-5acb-44ce-3402-320f2adfea88.png",
            "phone_number": "",
            "email_address": "228424@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-04-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3132ceda-3384-42bf-9ae7-4092ff3fb843"
        },
        {
          "id": 7020,
          "person": {
            "id": 7020,
            "first_name": "David",
            "last_name": "Verner",
            "photo_url": "assets/r60d812e0061b447bad22aef120780d4f_Davidprofilepic_t.jpg",
            "phone_number": "",
            "email_address": "228536@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2796,
              "name": "Swing",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-04-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a5229f3-b269-4fa3-b16f-8d1ca838394f"
        },
        {
          "id": 13878,
          "person": {
            "id": 13879,
            "first_name": "Delmi",
            "last_name": "Trujillo",
            "photo_url": "assets/423719bb9c26465aa31f25cc1eb2f275_DelmiTrujillo_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-10-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "32320d73-e7ce-4a3c-ae67-42fea3deb453"
        },
        {
          "id": 42062,
          "person": {
            "id": 41652,
            "first_name": "Dequane",
            "last_name": "Crawford Cooley",
            "photo_url": "assets/1614701082.957024_DequaneCrawleyCooley_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "979fd819-6308-405d-a67d-0010115da453"
        },
        {
          "id": 42386,
          "person": {
            "id": 41973,
            "first_name": "Derek",
            "last_name": "Thomas",
            "photo_url": "assets/1606311376.2404256_DerekThomas_t.undefined",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7960,
              "name": "Swing",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "504e9fb3-fb2c-4936-8073-f4ac8c5d097a"
        },
        {
          "id": 42171,
          "person": {
            "id": 41761,
            "first_name": "Destiny",
            "last_name": "Hampton",
            "photo_url": "assets/1604612833.8446708_45_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be82e227-aa82-45c0-b70f-1045d400e102"
        },
        {
          "id": 5858,
          "person": {
            "id": 5858,
            "first_name": "Destiny",
            "last_name": "Reed",
            "photo_url": "assets/ra46198e9aaaa4b8c84f34ea20604aacd_IMG_1995_t.jpeg",
            "phone_number": "5182698940",
            "email_address": "dreed1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 55,
              "name": "Trainer, Custodial",
              "name_abbreviation": "CUSTRN"
            }
          ],
          "shifts": [
            {
              "id": 2796,
              "name": "Swing",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4274d031-ef60-462d-9f20-0b9e5714e50a"
        },
        {
          "id": 42061,
          "person": {
            "id": 41651,
            "first_name": "Diane",
            "last_name": "Pomaville",
            "photo_url": "assets/1604612346.418114_40_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86945f66-9367-4243-9b04-f68185f0cfc2"
        },
        {
          "id": 42201,
          "person": {
            "id": 41791,
            "first_name": "Donald",
            "last_name": "Herman",
            "photo_url": "assets/1604502456.543458_DonHerman_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "90a73555-b92f-4dcc-a0b0-caa9d665b076"
        },
        {
          "id": 5869,
          "person": {
            "id": 5869,
            "first_name": "Donald",
            "last_name": "Tyrell",
            "photo_url": "7febdd59-8e8c-b668-504d-ed8b05335e89.png",
            "phone_number": "",
            "email_address": "202301@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47c1c017-4ae8-4e8c-8dcf-b9033f0624ee"
        },
        {
          "id": 15343,
          "person": {
            "id": 15336,
            "first_name": "Donavan",
            "last_name": "lawless",
            "photo_url": "",
            "phone_number": "",
            "email_address": "dlawless@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 1927,
              "name": "Day",
              "contract": {
                "id": 1968,
                "name": "Jacksonville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1803,
              "name": "Day",
              "contract": {
                "id": 1690,
                "name": "RE1702",
                "customer_id": 200,
                "customer_name": "East",
                "uuid": "e75a5807-1fa1-423a-9b55-9e3f80691f90",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86b43281-09fd-4c9b-b74c-ee518b6809e0"
        },
        {
          "id": 15516,
          "person": {
            "id": 15513,
            "first_name": "Donna",
            "last_name": "McGee",
            "photo_url": "assets/rbcbc5f7142f14779b04d0f115de1074e_IMG_20240122_133727_t.jpg",
            "phone_number": "7346793482",
            "email_address": "dmcgee@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1514,
              "name": "Day",
              "contract": {
                "id": 2251,
                "name": "RIC – Research & Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8235,
              "name": "Day",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7971,
              "name": "Swing",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-10-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e0c65243-9ef9-4c54-9096-378daa98b9d9"
        },
        {
          "id": 1554,
          "person": {
            "id": 1554,
            "first_name": "Duy",
            "last_name": "Pham",
            "photo_url": "e2841eff-3ff3-a6f7-998d-9c894965fbde.png",
            "phone_number": "",
            "email_address": "229584@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "73e469d1-3a46-4236-8929-3d62edab578f"
        },
        {
          "id": 780,
          "person": {
            "id": 780,
            "first_name": "Eder",
            "last_name": "Vargas",
            "photo_url": "assets/f49011f42d51448a843f479289f27500_IMG_20221108_13275_t.jpg",
            "phone_number": "",
            "email_address": "227771@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5608630b-8d75-4b29-b143-1977707747a3"
        },
        {
          "id": 44199,
          "person": {
            "id": 43763,
            "first_name": "Edgar",
            "last_name": "Suarez",
            "photo_url": "assets/1611186503.5467808_profilepic_t.jpg",
            "phone_number": "6507404674",
            "email_address": "esuzrez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 2817,
              "name": "Day",
              "contract": {
                "id": 2172,
                "name": "Sunnyvale",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "d9f82c63-44ff-4113-aa3a-6f511eeffb81",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-01-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3e00795f-eb0c-419c-a798-add06adcadc5"
        },
        {
          "id": 19170,
          "person": {
            "id": 19294,
            "first_name": "Edith",
            "last_name": "Buruca",
            "photo_url": "assets/1579645815.2171333_EdithBuruca_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7bc13b75-0468-4980-a274-98125c42525c"
        },
        {
          "id": 42239,
          "person": {
            "id": 41829,
            "first_name": "Edward",
            "last_name": "Zawacki",
            "photo_url": "assets/1606255006.9856393_EdwardZawacki_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "baf094ce-53e2-4ede-a87f-eb4cb03d2680"
        },
        {
          "id": 39633,
          "person": {
            "id": 39267,
            "first_name": "Emily",
            "last_name": "Spooner",
            "photo_url": "assets/1597262865.9162066_20200812_145947_t.jpg",
            "phone_number": "3147420459",
            "email_address": "espooner@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cadc4950-874d-40b5-81b4-0ce9a236416e"
        },
        {
          "id": 6727,
          "person": {
            "id": 6727,
            "first_name": "Enrique",
            "last_name": "Sanchez",
            "photo_url": "assets/a686535912b44ebca3bc8933686b93e9_jhfgyuf_t.PNG",
            "phone_number": "4089640073",
            "email_address": "esanchez1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "678b29c9-c719-47a7-a307-961b3ca0f98c"
        },
        {
          "id": 4525,
          "person": {
            "id": 4525,
            "first_name": "Esmeralda",
            "last_name": "Amador",
            "photo_url": "assets/98a2eccf40d14f6e80474e7f715742bd_IMG_20221108_1126_t.jpg",
            "phone_number": "",
            "email_address": "228775@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-05-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "26624bcc-fe6b-4c3e-9e56-926c8d6ea86c"
        },
        {
          "id": 43127,
          "person": {
            "id": 42702,
            "first_name": "Esmeralda",
            "last_name": "Pedraza Nieto",
            "photo_url": "assets/1607479931.1768587_esme_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5e4fd6ef-8df9-4429-a43f-fe2745692436"
        },
        {
          "id": 25703,
          "person": {
            "id": 25569,
            "first_name": "Esteban",
            "last_name": "Villa Eufracio",
            "photo_url": "assets/1587574896.3927157_EEphoto_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-04-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "66f10307-78b9-41ac-82f0-0a0fb7e83c8e"
        },
        {
          "id": 21471,
          "person": {
            "id": 21470,
            "first_name": "Ethel",
            "last_name": "Torres Ortiz De Bou",
            "photo_url": "assets/1583960984.700501_Ethel_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-02-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bd28459c-d5be-4efc-bbfa-0f98274d3e44"
        },
        {
          "id": 15247,
          "person": {
            "id": 15240,
            "first_name": "Evan",
            "last_name": "Salazar",
            "photo_url": "fbe8f437-f8d8-45c0-9a03-2932870fb5cb.png",
            "phone_number": "",
            "email_address": "230732@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "00708538-ff71-4a44-b2ef-56a00e922222"
        },
        {
          "id": 15405,
          "person": {
            "id": 15403,
            "first_name": "Fernando",
            "last_name": "Gamez Lopez",
            "photo_url": "9e223a42-a85c-6ec4-501b-08cc4e62d76c.png",
            "phone_number": "",
            "email_address": "230798@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-10-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56ab27ca-ff7c-4375-b3b7-00e6887af3de"
        },
        {
          "id": 19202,
          "person": {
            "id": 19326,
            "first_name": "Florentino",
            "last_name": "Bueno",
            "photo_url": "assets/1579651016.0553982_FlorentinoBueno_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a232bf64-3f6d-42f4-a90b-7057e1abe72a"
        },
        {
          "id": 14993,
          "person": {
            "id": 14989,
            "first_name": "Franklin",
            "last_name": "Patino",
            "photo_url": "assets/4f8a0636843a4d7ebd8348116f66c5dc_IMG_0319_t.jpeg",
            "phone_number": "6179499926",
            "email_address": "fpatino@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-08-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0cb56a5e-4bcc-4d89-bfce-93ed30a65e6d"
        },
        {
          "id": 42210,
          "person": {
            "id": 41800,
            "first_name": "Freda",
            "last_name": "Hamilton",
            "photo_url": "assets/1608329549.9666686_FredaHamilton_t.jpg",
            "phone_number": "",
            "email_address": "236401@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72bca831-6c83-4235-90ed-88fbb4649d01"
        },
        {
          "id": 14612,
          "person": {
            "id": 14613,
            "first_name": "Freselam",
            "last_name": "Tadesse",
            "photo_url": "84b5156d-6e22-c233-4d95-e74eb038f327.png",
            "phone_number": "",
            "email_address": "229802@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "605193ca-eb5a-4923-a74a-da61ee66abe2"
        },
        {
          "id": 42086,
          "person": {
            "id": 41676,
            "first_name": "Gary",
            "last_name": "Griffin",
            "photo_url": "assets/1604598185.4381473_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "35134d06-ed2d-4d94-b8e7-8eeed0f0e758"
        },
        {
          "id": 65709,
          "person": {
            "id": 65167,
            "first_name": "Geneva",
            "last_name": "Austin",
            "photo_url": "assets/50ffaf28c09044f6a73fcb9a5fe64770_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 9324,
              "name": "Swing",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2bc89ff4-0a0f-4b13-9205-3c4ab34d9692"
        },
        {
          "id": 15302,
          "person": {
            "id": 15295,
            "first_name": "Genoveva",
            "last_name": "Racines",
            "photo_url": "25d9e2d3-10bb-bd52-8140-55687dc78fd8.png",
            "phone_number": "9045373491",
            "email_address": "230605@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1927,
              "name": "Day",
              "contract": {
                "id": 1968,
                "name": "Jacksonville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2939d7f-1c8e-4203-a26d-7050b8e2f2c9"
        },
        {
          "id": 14747,
          "person": {
            "id": 14748,
            "first_name": "George",
            "last_name": "Rookwood",
            "photo_url": "81cb3466-d4b0-e0d6-03e5-b0ad71bd974d.png",
            "phone_number": "",
            "email_address": "230011@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "581ea31c-63f6-44b0-8c57-4822ea259ea5"
        },
        {
          "id": 5976,
          "person": {
            "id": 5976,
            "first_name": "Glenroy",
            "last_name": "Channer",
            "photo_url": "assets/2cc2b2fd6e9843c9befbae81b20df9c9_thumbnail_IMG_20220722_154019_t.jpg",
            "phone_number": "",
            "email_address": "229229@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-06-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0f8b2ac0-5099-4a32-b894-050b8a5d966b"
        },
        {
          "id": 65652,
          "person": {
            "id": 65110,
            "first_name": "Gloria",
            "last_name": "Armstrong",
            "photo_url": "assets/2a2105cd6c1e4711acdf6bc2325aa675_4SFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3a4af977-1e8f-4751-806f-0c61b6b0b444"
        },
        {
          "id": 14749,
          "person": {
            "id": 14750,
            "first_name": "Gloria",
            "last_name": "Paredes",
            "photo_url": "80419214-9b60-b486-4a92-330a4635a4bd.png",
            "phone_number": "",
            "email_address": "230013@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f240ec33-a5dd-4887-af23-e1e0ca1bd497"
        },
        {
          "id": 3278,
          "person": {
            "id": 3278,
            "first_name": "Guillermo",
            "last_name": "Giraldo",
            "photo_url": "99c61e5d-d31b-80fe-e1cc-7556664392a9.png",
            "phone_number": "",
            "email_address": "229063@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "788730ae-01b1-428c-b4f7-4688dede130e"
        },
        {
          "id": 15119,
          "person": {
            "id": 15115,
            "first_name": "Guo",
            "last_name": "Lu",
            "photo_url": "28a1f2de-4efe-c709-7c9f-b835e7872dab.png",
            "phone_number": "4256389894",
            "email_address": "230492@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f845f28-0d12-41c0-8354-adabe53dce8f"
        },
        {
          "id": 42129,
          "person": {
            "id": 41719,
            "first_name": "Hadjii",
            "last_name": "Caldwell",
            "photo_url": "assets/1604509405.6337793_HCaldwellpicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8469ab9b-e2e5-4233-98ac-c6d416e0438d"
        },
        {
          "id": 42157,
          "person": {
            "id": 41747,
            "first_name": "Harvard",
            "last_name": "Bostic",
            "photo_url": "assets/1606864045.2347686_20201201_1759582_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5a32b4c-f615-4dd0-832e-017358c0bfdb"
        },
        {
          "id": 350,
          "person": {
            "id": 350,
            "first_name": "Hawa",
            "last_name": "Mahamud",
            "photo_url": "7bfeffa7-028c-85d0-491e-4a7cc5ce34fd.png",
            "phone_number": "",
            "email_address": "226396@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-10-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd4d5218-3240-4023-89f2-2733b36e6dc2"
        },
        {
          "id": 42238,
          "person": {
            "id": 41828,
            "first_name": "Hector",
            "last_name": "Gonzalez",
            "photo_url": "assets/1606752540.1528876_hector_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "735f258c-911a-4939-807f-c52296ec0c18"
        },
        {
          "id": 18383,
          "person": {
            "id": 18365,
            "first_name": "Heidi",
            "last_name": "Clark",
            "photo_url": "f7db5247-129f-86b0-f0ea-c62b18bf5bf5.png",
            "phone_number": "",
            "email_address": "231398@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-11-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cea0b83d-bcaa-4921-9d03-d7d77849d60c"
        },
        {
          "id": 39937,
          "person": {
            "id": 39572,
            "first_name": "Heidy",
            "last_name": "Orellana Duarte",
            "photo_url": "assets/77cc66e4104749c2bf86a84a99633f64_IMG_20221104_58942_t.jpg",
            "phone_number": "",
            "email_address": "235043@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ed32ef8-086e-455c-aa7a-db9eb76cdbcd"
        },
        {
          "id": 42228,
          "person": {
            "id": 41818,
            "first_name": "Hensekh",
            "last_name": "El",
            "photo_url": "assets/1604502795.508276_Hensekh_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99403381-4d8d-4c8a-abbf-861102212fe4"
        },
        {
          "id": 15012,
          "person": {
            "id": 15008,
            "first_name": "Hien",
            "last_name": "Hoang",
            "photo_url": "e876bcab-6ab3-4719-d8a7-26b4fba0dc93.png",
            "phone_number": "",
            "email_address": "230315@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-08-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "791a20be-d5f3-43cf-878a-d15e45a8589f"
        },
        {
          "id": 42216,
          "person": {
            "id": 41806,
            "first_name": "Holly",
            "last_name": "Crunk",
            "photo_url": "assets/1604501926.204286_holly_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7970,
              "name": "Day",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e5765c3-a2ca-423c-a709-59407dbd38a0"
        },
        {
          "id": 15491,
          "person": {
            "id": 15489,
            "first_name": "Hung",
            "last_name": "Tran",
            "photo_url": "2a40ee11-6d19-5e12-5a9d-223baf686b31.png",
            "phone_number": "4252867854",
            "email_address": "230937@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-10-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "412c8605-959c-42df-8fb8-3bb57cad9cf2"
        },
        {
          "id": 183,
          "person": {
            "id": 183,
            "first_name": "Iliana",
            "last_name": "Figueroa",
            "photo_url": "e22312f9-0807-a7be-d07c-fca07abcb945.png",
            "phone_number": "6692618080",
            "email_address": "ifigueroa@sbmcorp.com"
          },
          "positions": [
            {
              "id": 38,
              "name": "Reg Mgr",
              "name_abbreviation": "REGMGR"
            }
          ],
          "shifts": [
            {
              "id": 6091,
              "name": "Day",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8700,
              "name": "Day",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8664,
              "name": "Swing",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2fec69f-6db8-4103-a5d4-352a3bf29feb"
        },
        {
          "id": 2723,
          "person": {
            "id": 2723,
            "first_name": "Isidro",
            "last_name": "Corona",
            "photo_url": "8283b126-74d8-0434-d318-2a4ad80f16ff.png",
            "phone_number": "",
            "email_address": "227516@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e419af6-fa73-4e61-90a1-9c46a087a148"
        },
        {
          "id": 42225,
          "person": {
            "id": 41815,
            "first_name": "Jacob",
            "last_name": "Seigel",
            "photo_url": "assets/1604598218.4111063_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b9f93f7-bc86-4765-988f-f03a62f09677"
        },
        {
          "id": 37422,
          "person": {
            "id": 37141,
            "first_name": "Jaime",
            "last_name": "Romero Quizhpi",
            "photo_url": "assets/1593542267.231309_Resized_20200629_145313_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d182a3ee-5ce8-43dd-b906-c95975da2dcc"
        },
        {
          "id": 39659,
          "person": {
            "id": 39293,
            "first_name": "James",
            "last_name": "Ryder",
            "photo_url": "assets/1600967644.0044231_20200923_144038_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4de9c40b-1a8f-408b-9b8e-b5d27acbd17e"
        },
        {
          "id": 42249,
          "person": {
            "id": 41839,
            "first_name": "James",
            "last_name": "Thompson-Flake",
            "photo_url": "assets/1604948988.9783006_StandardMaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7f7d95a-124c-43ab-9d47-7c6e03fd3833"
        },
        {
          "id": 42122,
          "person": {
            "id": 41712,
            "first_name": "Jarrell",
            "last_name": "Colbert",
            "photo_url": "assets/1604502096.911476_Colbert_t.PNG",
            "phone_number": "3132662357",
            "email_address": "jcolbert@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 8767,
              "name": "Day",
              "contract": {
                "id": 6747,
                "name": "1633 Fairlane Circle",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            },
            {
              "id": 8804,
              "name": "None",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8730,
              "name": "Unassigned",
              "contract": {
                "id": 2217,
                "name": "Fairlane North",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8728,
              "name": "Unassigned",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8731,
              "name": "Unassigned",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8757,
              "name": "Unassigned",
              "contract": {
                "id": 6750,
                "name": "IT Bic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8718,
              "name": "Unassigned",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8729,
              "name": "Unassigned",
              "contract": {
                "id": 6740,
                "name": "Commerce Park 2 15090",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8755,
              "name": "Unassigned",
              "contract": {
                "id": 6751,
                "name": "Commerce Park 2 15080",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8727,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8756,
              "name": "Unassigned",
              "contract": {
                "id": 6746,
                "name": "Fairlane Business Park 3",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8717,
              "name": "Unassigned",
              "contract": {
                "id": 2221,
                "name": "Fairlane Business Park 5",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca228a6e-12bb-431e-bbc0-bf07ff0ef9fa"
        },
        {
          "id": 40101,
          "person": {
            "id": 39736,
            "first_name": "Jason",
            "last_name": "Wolff",
            "photo_url": "assets/1598794289.4717073_Jasonpic_t.jpg",
            "phone_number": "5154439631",
            "email_address": "jwolff@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c2ee3555-6355-4dd3-b78b-07b4605f08cb"
        },
        {
          "id": 43149,
          "person": {
            "id": 42724,
            "first_name": "Javier",
            "last_name": "Lino",
            "photo_url": "assets/1608498221.1688547_JavierLino_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ad15b8e-1d1f-4824-8644-c6a576a5e336"
        },
        {
          "id": 42161,
          "person": {
            "id": 41751,
            "first_name": "Jeffery",
            "last_name": "Woodford",
            "photo_url": "assets/1604598241.976656_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ade802e4-022f-4f1e-854c-023a6387c00a"
        },
        {
          "id": 12426,
          "person": {
            "id": 12426,
            "first_name": "Jennifer",
            "last_name": "Defreese",
            "photo_url": "c1ef6a53-5ef1-f607-8140-fdbdf2504e65.png",
            "phone_number": "5188196042",
            "email_address": "jbutterworth@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1877,
              "name": "Day",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "536bf198-b00b-49d1-8525-981bba37969e"
        },
        {
          "id": 42520,
          "person": {
            "id": 42104,
            "first_name": "Jennifer",
            "last_name": "Scott",
            "photo_url": "assets/1609257174.9805477_20170706_0446051320_t.jpg",
            "phone_number": "2485612316",
            "email_address": "jscott@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8108,
              "name": "Unassigned",
              "contract": {
                "id": 2214,
                "name": "WPE – Wagner Place East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8107,
              "name": "Unassigned",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e6aaa33a-0520-41a7-a4b8-ff16b83f5323"
        },
        {
          "id": 42087,
          "person": {
            "id": 41677,
            "first_name": "Jessica",
            "last_name": "Boehmer",
            "photo_url": "assets/1604502741.375266_Boehner_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8235,
              "name": "Day",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dcf49e78-4772-4238-803b-48a14a71d6f0"
        },
        {
          "id": 14732,
          "person": {
            "id": 14733,
            "first_name": "Jesus",
            "last_name": "Ruiz",
            "photo_url": "b1ed53ff-e76f-09d4-6af4-fc78908c123a.png",
            "phone_number": "",
            "email_address": "229996@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d944b200-64ff-4a8f-a28f-244decd35f6a"
        },
        {
          "id": 15120,
          "person": {
            "id": 15116,
            "first_name": "Jinlian",
            "last_name": "Zhu",
            "photo_url": "aeb32f23-ed3d-ec80-ee4b-9b5ba1302344.png",
            "phone_number": "",
            "email_address": "230497@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d012ce8c-5efa-4a0f-85fc-6adaebecbc1b"
        },
        {
          "id": 37749,
          "person": {
            "id": 37464,
            "first_name": "Joan",
            "last_name": "Lane",
            "photo_url": "assets/1592256454.9899845_joan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-06-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "531b5fef-7ee1-4783-80b0-bf31e7e19a38"
        },
        {
          "id": 2329,
          "person": {
            "id": 2329,
            "first_name": "Joel",
            "last_name": "Vazquez Vega",
            "photo_url": "9c2aa2b2-f17b-83b1-6692-a2179035140f.png",
            "phone_number": "2678270619",
            "email_address": "jvega@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21bbe056-041c-4188-ae29-151373b864ff"
        },
        {
          "id": 15291,
          "person": {
            "id": 15284,
            "first_name": "John",
            "last_name": "Rodriguez",
            "photo_url": "b54fe224-fb8f-b632-ea1d-8749616cbce1.png",
            "phone_number": "8623098938",
            "email_address": "jrodriguez1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0f29298-c042-4e9a-a592-d6307fc9d5ae"
        },
        {
          "id": 7024,
          "person": {
            "id": 7024,
            "first_name": "Jorge",
            "last_name": "Yanez Bonilla",
            "photo_url": "a497e1b6-c6f2-7215-ed6c-1e2e05a65ea8.png",
            "phone_number": "",
            "email_address": "208142@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2417ab34-2c5d-4879-9d84-8ee163e40d68"
        },
        {
          "id": 41271,
          "person": {
            "id": 40891,
            "first_name": "Jose",
            "last_name": "Mendieta Moctezuma",
            "photo_url": "assets/f31a3c706e1e4f48bff293a11609fa6b_JOSEMMMM_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6094,
              "name": "Swing",
              "contract": {
                "id": 3996,
                "name": "Bayfront",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "16527633-c656-43a7-9e04-5ba98838813c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e6e24787-779a-45c0-b1c5-140419929504"
        },
        {
          "id": 19426,
          "person": {
            "id": 19538,
            "first_name": "Jose",
            "last_name": "Rodriguez",
            "photo_url": "assets/1589894926.3259833_joseprofilephoto_t.jpg",
            "phone_number": "5188199308",
            "email_address": "232284@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2522,
              "name": "On-Call",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2019-12-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ebd0669-ce71-479c-91b4-826c497e4383"
        },
        {
          "id": 24854,
          "person": {
            "id": 24772,
            "first_name": "Jose",
            "last_name": "Rodriguez",
            "photo_url": "assets/1595274048.6668751_JoseRodriguez_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8532,
              "name": "Swing",
              "contract": {
                "id": 1812,
                "name": "Westridge",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "d818e965-5360-4456-a460-9d794f4588a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b55a0f6-f3a4-40bd-a058-b2f7a9ca4159"
        },
        {
          "id": 14754,
          "person": {
            "id": 14755,
            "first_name": "Jose",
            "last_name": "Santiago",
            "photo_url": "3afbe5e7-e77e-1140-12ff-539ef6a4dc06.png",
            "phone_number": "",
            "email_address": "230018@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad9dc121-02e7-4fd7-a48c-63fa06681737"
        },
        {
          "id": 11937,
          "person": {
            "id": 11937,
            "first_name": "Jose",
            "last_name": "Umana",
            "photo_url": "assets/1584035433.8518238_JoseUmana_t.jpg",
            "phone_number": "857-266-4035",
            "email_address": "208148@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c6a0e14-389d-4ce9-af5e-6bb9f5c16511"
        },
        {
          "id": 41487,
          "person": {
            "id": 41104,
            "first_name": "Joseph",
            "last_name": "Morton",
            "photo_url": "assets/1603810071.0221865_joe_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7514,
              "name": "Day",
              "contract": {
                "id": 5500,
                "name": "Sioux Falls Main",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "825ffb24-2283-448f-a54b-69efeba84caf"
        },
        {
          "id": 6266,
          "person": {
            "id": 6266,
            "first_name": "Joseph",
            "last_name": "Sommer",
            "photo_url": "3306a212-15e2-cc74-f5f7-463af12259ad.png",
            "phone_number": "",
            "email_address": "227321@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1877,
              "name": "Day",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b4b96ef-3fc0-46d1-b864-f13cc2553dd2"
        },
        {
          "id": 42233,
          "person": {
            "id": 41823,
            "first_name": "Joseph",
            "last_name": "Williams",
            "photo_url": "assets/1606752910.7119834_joseph_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c6e98a3-6376-43c3-8325-41f7bbe5bcd6"
        },
        {
          "id": 42381,
          "person": {
            "id": 41968,
            "first_name": "Josette",
            "last_name": "Sexton",
            "photo_url": "assets/1605896754.9255052_FemaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da0e9bf4-877c-42d5-bf4b-6bcef6759ca5"
        },
        {
          "id": 19124,
          "person": {
            "id": 19248,
            "first_name": "Josue",
            "last_name": "Estrada",
            "photo_url": "4cf0dab4-8d04-d96b-15f1-b3a8de78148d.png",
            "phone_number": "",
            "email_address": "232019@placeholder.com"
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-11-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "668c5011-3a64-44bb-966e-ef6519d05aa2"
        },
        {
          "id": 41221,
          "person": {
            "id": 40842,
            "first_name": "Joyce",
            "last_name": "Kinartail",
            "photo_url": "assets/1603212557.0725355_JoyceHayes001_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c492ef0-f974-4bd1-8d67-90d5e6b5f85a"
        },
        {
          "id": 19351,
          "person": {
            "id": 19472,
            "first_name": "Joyce",
            "last_name": "Smith",
            "photo_url": "assets/842e5593246a4297813292bfc498b2e0_Resized_20220218_124123002_t.jpg",
            "phone_number": "",
            "email_address": "232136@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8fa460c-b005-4087-a220-c61e8a2b94cf"
        },
        {
          "id": 43342,
          "person": {
            "id": 42923,
            "first_name": "Juan",
            "last_name": "Aguilar Perez",
            "photo_url": "assets/1608318626.638877_JuanAguilar_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "949a0b49-8a0a-4cdc-8fdd-cce975468a47"
        },
        {
          "id": 21895,
          "person": {
            "id": 21889,
            "first_name": "Juana",
            "last_name": "Valencia",
            "photo_url": "assets/1583441338.6342685_D0609ED12DC443BEA973D2885B68107A_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72369492-80ec-4049-ba28-3302ea513903"
        },
        {
          "id": 43919,
          "person": {
            "id": 43494,
            "first_name": "Julie",
            "last_name": "Bianchi",
            "photo_url": "assets/1612299821.4215329_MITPhoto_t.jpg",
            "phone_number": "8608940176",
            "email_address": "jbianchi@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-01-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cd2b2ab1-5c1b-4653-b4b4-1d965096bd4d"
        },
        {
          "id": 24409,
          "person": {
            "id": 24347,
            "first_name": "Justo",
            "last_name": "Romero-Pallchizaca",
            "photo_url": "assets/1590613704.6946704_Capture_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 7487,
              "name": "Swing",
              "contract": {
                "id": 5499,
                "name": "Ridgedale",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "01362f5b-8a06-4153-b205-66058e1cb884"
        },
        {
          "id": 42234,
          "person": {
            "id": 41824,
            "first_name": "Karen",
            "last_name": "Brown",
            "photo_url": "assets/1604500630.7901497_IMG_0267_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "68ffd864-2779-436a-82b0-babbe5ed967d"
        },
        {
          "id": 24750,
          "person": {
            "id": 24671,
            "first_name": "Karissa",
            "last_name": "Leite",
            "photo_url": "assets/1588878279.3119562_karissa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-03-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1dde6f04-5ae7-4d82-af4a-d4dc773cfaa2"
        },
        {
          "id": 21886,
          "person": {
            "id": 21880,
            "first_name": "Karla",
            "last_name": "Saavedra",
            "photo_url": "assets/1583440571.0881894_B0730BFA713344DC8D3631E3B19F318E_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1417,
              "name": "Day",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "20548024-508f-4d58-912e-0e1a4a800aa3"
        },
        {
          "id": 643,
          "person": {
            "id": 643,
            "first_name": "Keilin",
            "last_name": "Diaz Santos",
            "photo_url": "61223f67-976d-9a31-023a-d3e883f8ba2d.png",
            "phone_number": "",
            "email_address": "229583@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e5f8354-586c-461e-9005-4cd7a0bed5ac"
        },
        {
          "id": 42532,
          "person": {
            "id": 42116,
            "first_name": "Keith",
            "last_name": "Penn",
            "photo_url": "assets/1605897574.8054273_MaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "adf5c65e-7580-404a-a5d6-3330b9ba5f11"
        },
        {
          "id": 42152,
          "person": {
            "id": 41742,
            "first_name": "Kelly",
            "last_name": "Freeman",
            "photo_url": "assets/1608329663.9410708_KellyFreeman_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43c30715-2871-4c9f-98f4-21abbcd9b53a"
        },
        {
          "id": 42146,
          "person": {
            "id": 41736,
            "first_name": "Kenneth",
            "last_name": "Greficz",
            "photo_url": "assets/1604611481.2089763_33_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f73f09a0-0601-47a8-9d8f-f74476521445"
        },
        {
          "id": 42300,
          "person": {
            "id": 41885,
            "first_name": "Kenny",
            "last_name": "Samples",
            "photo_url": "assets/1607113133.4465823_16071130678908756179352433424228_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "db8deb3f-9c1a-4d48-bc3e-9925a11f0b36"
        },
        {
          "id": 42223,
          "person": {
            "id": 41813,
            "first_name": "Kevin",
            "last_name": "Coleman",
            "photo_url": "assets/1608329716.0502095_KevinColeman_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "534dd3ca-6b0f-4f2a-8a95-0784eeab9968"
        },
        {
          "id": 24329,
          "person": {
            "id": 24270,
            "first_name": "Khary",
            "last_name": "Hall",
            "photo_url": "assets/1592506768.8982403_KharyHall_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2599,
              "name": "Swing",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89193566-b02e-43b5-9579-3ea1028f9b4f"
        },
        {
          "id": 43484,
          "person": {
            "id": 43060,
            "first_name": "Kimberly",
            "last_name": "McGriff",
            "photo_url": "assets/9163bee446664329937902b97615209e_KimberlyMcGriff_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "889c1a87-98cd-4ea9-b7f1-f6739c23904b"
        },
        {
          "id": 43610,
          "person": {
            "id": 43184,
            "first_name": "Kong",
            "last_name": "Leng",
            "photo_url": "assets/1609181721.0400658_KongLengheadshot002_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1dd93528-66c4-4d4b-8be2-d964bd7ecc0e"
        },
        {
          "id": 43331,
          "person": {
            "id": 42912,
            "first_name": "Kosal",
            "last_name": "Yim",
            "photo_url": "assets/1608318686.2736106_KosalYim_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2d2d5151-052f-4697-a7cc-3551ea64531b"
        },
        {
          "id": 37752,
          "person": {
            "id": 37467,
            "first_name": "Kristel",
            "last_name": "Paco-Maldonado",
            "photo_url": "assets/c5128fadf9b34f8a8febba30991baf11_Headshot_t.jpg",
            "phone_number": "4252197153",
            "email_address": "kmaldonado@sbmcorp.com"
          },
          "positions": [
            {
              "id": 588,
              "name": "Mgr,Portfolio Database",
              "name_abbreviation": "PPMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-06-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8076e52b-9c26-4467-ac77-88f42034bce9"
        },
        {
          "id": 42218,
          "person": {
            "id": 41808,
            "first_name": "Lakira",
            "last_name": "Anderson",
            "photo_url": "assets/1606754878.2666485_lakira_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c866600-a193-4e2d-a57a-33f1240740b5"
        },
        {
          "id": 42224,
          "person": {
            "id": 41814,
            "first_name": "Larry",
            "last_name": "Broome",
            "photo_url": "assets/1604508762.2277286_LBroomepicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "426146ca-eeab-4645-8e4a-a3193ea5b8c2"
        },
        {
          "id": 9775,
          "person": {
            "id": 9775,
            "first_name": "Larry",
            "last_name": "Woodson",
            "photo_url": "assets/c00f25b97e7747859175578c16969071_20230901_161531_t.jpg",
            "phone_number": "",
            "email_address": "229545@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2223,
              "name": "Day",
              "contract": {
                "id": 1666,
                "name": "St. Louis, MO",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "c63a0b01-8803-4f82-bceb-eed4af6ada8d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b03d9571-eb57-4c88-a417-940b72386294"
        },
        {
          "id": 42222,
          "person": {
            "id": 41812,
            "first_name": "Latanya",
            "last_name": "Parks",
            "photo_url": "assets/1604508667.8416924_LParkspicture_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "956834ab-ad33-4e78-bd6d-e73f83e90d0d"
        },
        {
          "id": 42212,
          "person": {
            "id": 41802,
            "first_name": "Lavell",
            "last_name": "McCree",
            "photo_url": "assets/1604598870.6937048_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76816893-8866-44a5-bafd-8e0420799462"
        },
        {
          "id": 42220,
          "person": {
            "id": 41810,
            "first_name": "Lawrence",
            "last_name": "Huffman",
            "photo_url": "assets/1604598917.9956982_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8409a04c-28aa-4421-9984-ab7b8e21a393"
        },
        {
          "id": 39874,
          "person": {
            "id": 39509,
            "first_name": "Lazaro",
            "last_name": "Chinchilla",
            "photo_url": "assets/1598011027.787253_LazaroChinchillaFloorTech_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04f2e692-70c8-49ee-bb7b-9c6d0ce6e2ee"
        },
        {
          "id": 15305,
          "person": {
            "id": 15298,
            "first_name": "Leandro",
            "last_name": "Muniz Hernandez",
            "photo_url": "09872433-1b4e-06d6-6c4f-a4fd9afc85db.png",
            "phone_number": "",
            "email_address": "230608@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "430c7d70-ac59-4c91-84c3-46070409b19b"
        },
        {
          "id": 15492,
          "person": {
            "id": 15490,
            "first_name": "Leang",
            "last_name": "Ngy",
            "photo_url": "fed246a8-e5ab-c9c7-f02f-5fd6f01643cb.png",
            "phone_number": "",
            "email_address": "230943@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0f58aa79-c1a0-4866-837d-fa5c24d9d4f6"
        },
        {
          "id": 24177,
          "person": {
            "id": 24130,
            "first_name": "Leanna",
            "last_name": "Carlson",
            "photo_url": "assets/1583523233.7721772_c1_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-03-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d070d288-7e93-4219-96a8-b9013022dd6b"
        },
        {
          "id": 19188,
          "person": {
            "id": 19312,
            "first_name": "Liliana",
            "last_name": "Ibarra Martinez",
            "photo_url": "assets/1579638960.1884043_LilianaIbarra_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c76ada94-30e4-4eef-8b35-8c52c73dbdb1"
        },
        {
          "id": 42227,
          "person": {
            "id": 41817,
            "first_name": "Lisa",
            "last_name": "Homberg",
            "photo_url": "assets/1612465606.2629392_cropped145554628588934928.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2870fef0-1531-4278-954a-ee8ff7ab8e5f"
        },
        {
          "id": 862,
          "person": {
            "id": 862,
            "first_name": "Lisangela",
            "last_name": "Guerrero",
            "photo_url": "db7fd315-1467-ad1b-98f6-ceaf8ce28800.png",
            "phone_number": "6179875206",
            "email_address": "lguerrero@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-02-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2fa6363-af8f-4dd2-bf54-c60d80e33cc7"
        },
        {
          "id": 43660,
          "person": {
            "id": 43233,
            "first_name": "Louis",
            "last_name": "Owens",
            "photo_url": "assets/1610417691.3063762_louispic_t.jpg",
            "phone_number": "",
            "email_address": "237173@placeholder.com"
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "802cb653-e567-47b7-bec5-d19380a8f2ad"
        },
        {
          "id": 24903,
          "person": {
            "id": 24818,
            "first_name": "Louise",
            "last_name": "Melendez",
            "photo_url": "assets/1587660657.7947114_LouisCorderoInsitephoto_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1419,
              "name": "Day",
              "contract": {
                "id": 2055,
                "name": "Waco, TX",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "fc72e55a-3e5d-4646-b496-ad01eb7a73c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-03-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8de89472-0c76-427f-bd7f-df30690a82b8"
        },
        {
          "id": 25466,
          "person": {
            "id": 25346,
            "first_name": "Luan",
            "last_name": "Nguyen",
            "photo_url": "assets/1587958332.0398617_LNguyen_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-04-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6126268a-9fd3-4322-80c6-72957a43a076"
        },
        {
          "id": 42226,
          "person": {
            "id": 41816,
            "first_name": "Luann",
            "last_name": "Ramirez",
            "photo_url": "assets/c31e5d3643254972bda31052e47e333b_cropped5137764014867839501.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6bdbd7c9-3c90-4fc5-b2bb-daeafd19b402"
        },
        {
          "id": 42231,
          "person": {
            "id": 41821,
            "first_name": "Luciana",
            "last_name": "Gaines",
            "photo_url": "assets/1604500668.9074109_LGaines_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d55ae0e-89db-4293-8a19-5a6087bb5df3"
        },
        {
          "id": 5102,
          "person": {
            "id": 5102,
            "first_name": "Luis",
            "last_name": "Cabrera",
            "photo_url": "assets/1577824247.6788175_LuisCabreraHeadshot_07252019_t.jpg",
            "phone_number": "6173978186",
            "email_address": "lcabrera@Sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 7398,
              "name": "Day",
              "contract": {
                "id": 5634,
                "name": "TAKE00OVRHD",
                "customer_id": 2117,
                "customer_name": "Takeda",
                "uuid": "19c0982e-8824-4781-a83f-95f2cacf1c3e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-12-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60d97a65-d5b0-450e-93ae-8b11da64b04d"
        },
        {
          "id": 67437,
          "person": {
            "id": 66895,
            "first_name": "Luz",
            "last_name": "Betancur",
            "photo_url": "assets/re616d8d65ae6441fabf0abce0712ffec_luzspic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-07-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb4d15bd-29fb-4efb-9d98-1e47ca2bcd4f"
        },
        {
          "id": 45119,
          "person": {
            "id": 44672,
            "first_name": "Luz",
            "last_name": "Jimenez",
            "photo_url": "assets/1616080372.8892226_LuzPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2021-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f639519b-a200-4778-99ba-53d5bdbee7c4"
        },
        {
          "id": 25139,
          "person": {
            "id": 25040,
            "first_name": "Ma",
            "last_name": "Rodriguez",
            "photo_url": "assets/1593020215.6808083_MaLuisa_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7656,
              "name": "Swing",
              "contract": {
                "id": 1808,
                "name": "CJ Harris",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "68c22cbe-2b3c-41b6-b995-a79ef87e97be"
        },
        {
          "id": 40611,
          "person": {
            "id": 40244,
            "first_name": "Makayla",
            "last_name": "Guerrero",
            "photo_url": "assets/1600719920.9571664_IMG_0508_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1174,
              "name": "Mgr, GMP",
              "name_abbreviation": "GMPMGR"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c219df93-fc6e-4bbc-94c6-6a117f310078"
        },
        {
          "id": 42246,
          "person": {
            "id": 41836,
            "first_name": "Malcolm",
            "last_name": "Walker",
            "photo_url": "assets/1604611401.613957_32_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "356f513f-75dd-4d62-920e-7c97762dd50b"
        },
        {
          "id": 15293,
          "person": {
            "id": 15286,
            "first_name": "Manuel",
            "last_name": "Matamoros",
            "photo_url": "assets/1606232924.9246824_IMG_0371._t.jpg",
            "phone_number": "",
            "email_address": "230588@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-09-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5d44d46-08a4-4800-8ba6-214efc285cd6"
        },
        {
          "id": 14510,
          "person": {
            "id": 14511,
            "first_name": "Manuel",
            "last_name": "Rodriguez-Figueroa",
            "photo_url": "43548474-d7e9-8385-41f9-0faa3b74d75a.png",
            "phone_number": "",
            "email_address": "230155@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d60fd8e0-2dd5-4e8e-913d-097e558122cc"
        },
        {
          "id": 12060,
          "person": {
            "id": 12060,
            "first_name": "Marcelino",
            "last_name": "Pastor",
            "photo_url": "98914569-eff1-ca59-6f11-d630495cb193.png",
            "phone_number": "",
            "email_address": "228242@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d5891ea8-bd61-48a8-8ba3-db6ae90de4f5"
        },
        {
          "id": 42135,
          "person": {
            "id": 41725,
            "first_name": "Margaret",
            "last_name": "Neubert",
            "photo_url": "assets/1614806820.3826225_MargaretNeubert2_t.jpg",
            "phone_number": "13132588522",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1c75f179-b400-4c87-8904-1471e52a390f"
        },
        {
          "id": 43370,
          "person": {
            "id": 42950,
            "first_name": "Maria",
            "last_name": "Aguilar Perez",
            "photo_url": "assets/1608318056.334801_MariaAguilar_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b711b687-18b3-412f-ac96-5320daa0c055"
        },
        {
          "id": 42332,
          "person": {
            "id": 41919,
            "first_name": "Maria",
            "last_name": "Balbino Medina",
            "photo_url": "assets/1605285500.5740132_MariaBalbino_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "edaae17a-4315-4f89-a2c7-56b11b665dbd"
        },
        {
          "id": 43365,
          "person": {
            "id": 42945,
            "first_name": "Maria",
            "last_name": "Campos Barragan",
            "photo_url": "assets/1608318410.661263_MariaCampos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ac2211e-7469-46a1-af1f-1e4857794d61"
        },
        {
          "id": 24171,
          "person": {
            "id": 24124,
            "first_name": "Maria",
            "last_name": "Escobar De Alvarez",
            "photo_url": "assets/1584402546.0350983_20200313_192042_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ccf7c00-01e6-4765-a74e-569c99c4a80d"
        },
        {
          "id": 15992,
          "person": {
            "id": 15987,
            "first_name": "Maria",
            "last_name": "Gonzalez",
            "photo_url": "assets/1609788121.453706_MariaG_t.jpg",
            "phone_number": "",
            "email_address": "231288@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6158c48e-ab10-4996-8eb5-3753be116e29"
        },
        {
          "id": 38967,
          "person": {
            "id": 38615,
            "first_name": "Maria",
            "last_name": "Gonzalez",
            "photo_url": "assets/1605911450.929166_MariaGonzalez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6fa8c7e5-a7fe-4ea5-8fe7-252cc972feb1"
        },
        {
          "id": 15500,
          "person": {
            "id": 15498,
            "first_name": "Maria",
            "last_name": "Guerrero",
            "photo_url": "059454f8-88d0-acbd-2e1c-234608d58f5c.png",
            "phone_number": "",
            "email_address": "230954@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2011,
              "name": "Day",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "405753b6-2dff-4ceb-be3a-823af8573a07"
        },
        {
          "id": 15414,
          "person": {
            "id": 15412,
            "first_name": "Maria",
            "last_name": "Hernandez",
            "photo_url": "4aee4010-93ed-c286-2969-07e0a54bac3f.png",
            "phone_number": "",
            "email_address": "230825@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2011,
              "name": "Day",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a45d72a7-dc30-4b38-8286-7f089afff844"
        },
        {
          "id": 41629,
          "person": {
            "id": 41244,
            "first_name": "Maria",
            "last_name": "Hernandez",
            "photo_url": "assets/1606232952.4044333_imagejpeg_0_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-10-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "581f11a1-e7c5-476c-858c-4b7f7249e18c"
        },
        {
          "id": 43388,
          "person": {
            "id": 42968,
            "first_name": "Maria",
            "last_name": "Hernandez",
            "photo_url": "assets/1608317802.0791898_MariaSHernandez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a40fd37-2b73-4d00-9d97-7ffef2f17b8a"
        },
        {
          "id": 43385,
          "person": {
            "id": 42965,
            "first_name": "Maria",
            "last_name": "Mora De Gutierrez",
            "photo_url": "assets/1609798851.631724_MariaMora_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "170290cd-35a7-41a6-a0f2-1b69fb9a9a67"
        },
        {
          "id": 18376,
          "person": {
            "id": 18358,
            "first_name": "Maria",
            "last_name": "Morales De Zavala",
            "photo_url": "e47d01af-13cb-d1d3-8d04-baf50a7d1929.png",
            "phone_number": "",
            "email_address": "231456@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39dfbb76-bb47-4e49-a850-33b989132bc2"
        },
        {
          "id": 19382,
          "person": {
            "id": 19499,
            "first_name": "Maria",
            "last_name": "Oropeza Ruz",
            "photo_url": "assets/1600282454.3660638_2706cec21_t.jpg",
            "phone_number": "3174800746",
            "email_address": "moropeza@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 7839,
              "name": "01-Day",
              "contract": {
                "id": 5764,
                "name": "HLO3 – Lanesville, IN",
                "customer_id": 24,
                "customer_name": "Amazon",
                "uuid": "e764e4d0-232a-4950-a5d7-e26d4eb3aa54",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2050,
              "name": "Day",
              "contract": {
                "id": 1704,
                "name": "REG703",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "f01bce0e-fbc7-412e-947c-c45ab010d904",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7117,
              "name": "Day",
              "contract": {
                "id": 3467,
                "name": "Richmond, IN",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "519c7469-e232-466e-9e16-fb9c702efc59",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7998,
              "name": "Night",
              "contract": {
                "id": 3068,
                "name": "Indy",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "af8c88ab-b969-4cc5-b274-a1b2b521892b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": null
            }
          ],
          "hired_date": "2019-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a862effe-198f-4848-8ae5-9026ade53531"
        },
        {
          "id": 43163,
          "person": {
            "id": 42738,
            "first_name": "Maria",
            "last_name": "Ruiz De Landeros",
            "photo_url": "assets/1607866690.662887_laneros_t.jpg",
            "phone_number": "2243009055",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5df62b8a-ded3-452e-80dd-87f6f178c7ae"
        },
        {
          "id": 41036,
          "person": {
            "id": 40661,
            "first_name": "Maria",
            "last_name": "Trejo Ayala",
            "photo_url": "assets/1606768446.6192625_IMG_9568_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2148,
              "name": "Day",
              "contract": {
                "id": 2162,
                "name": "Manteno, IL",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fcfa3d5b-e507-40e9-8a19-30b07e184173"
        },
        {
          "id": 43389,
          "person": {
            "id": 42969,
            "first_name": "Maria",
            "last_name": "Velazquez",
            "photo_url": "assets/1608318810.4756377_MariaVelasquez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b381f19-7549-4c47-92e3-d9b00de58e92"
        },
        {
          "id": 15417,
          "person": {
            "id": 15415,
            "first_name": "Maria",
            "last_name": "Vitar",
            "photo_url": "0a3ecb70-bfd2-e379-18db-7b5a05f56145.png",
            "phone_number": "",
            "email_address": "230847@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2011,
              "name": "Day",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33766477-f3f9-40ac-934b-db32328c37b5"
        },
        {
          "id": 43118,
          "person": {
            "id": 42693,
            "first_name": "Maricela",
            "last_name": "Flores",
            "photo_url": "assets/1607865724.4038787_Maricela_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2386,
              "name": "Swing",
              "contract": {
                "id": 2267,
                "name": "Chicago, IL",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "3b5b9925-be1f-476f-a0c9-0a4359dce462",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f22b10e3-9c2c-4f71-b80e-57d3c4739e59"
        },
        {
          "id": 39663,
          "person": {
            "id": 39297,
            "first_name": "Mariela",
            "last_name": "Castro",
            "photo_url": "assets/1597064622.6774623_MarielaCastroCustodian_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "460d571a-1786-4e3b-bf65-e4992e5a986e"
        },
        {
          "id": 42372,
          "person": {
            "id": 41959,
            "first_name": "Mark",
            "last_name": "Hicks",
            "photo_url": "assets/1605287505.3457751_36_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 657,
              "name": "Chairperson, Custodial",
              "name_abbreviation": "CUSCHA"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86c5d4b7-77b8-4021-92ad-30da99f986e0"
        },
        {
          "id": 42204,
          "person": {
            "id": 41794,
            "first_name": "Marlo",
            "last_name": "Wilson",
            "photo_url": "assets/1604945668.7848682_StandardFemaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d059e420-97ee-48cb-b912-6593739df703"
        },
        {
          "id": 19178,
          "person": {
            "id": 19302,
            "first_name": "Martha",
            "last_name": "Garcia Nunez",
            "photo_url": "assets/1579640979.0032287_MartaGarcia_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8357cd29-2362-49bc-8bcb-e0844c0d232d"
        },
        {
          "id": 12583,
          "person": {
            "id": 12583,
            "first_name": "Martha",
            "last_name": "Perez Vazquez",
            "photo_url": "assets/1617641471.7302768_IMG_0190_t.JPG",
            "phone_number": "",
            "email_address": "229217@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7655,
              "name": "Day",
              "contract": {
                "id": 1808,
                "name": "CJ Harris",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67709924-46d0-438a-8222-26da1f3ba0d6"
        },
        {
          "id": 42102,
          "person": {
            "id": 41692,
            "first_name": "Mary",
            "last_name": "Bizzell",
            "photo_url": "assets/1604500004.7523005_MBizzel_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8ef4a74-4677-4cca-87bd-def9db357a6c"
        },
        {
          "id": 42248,
          "person": {
            "id": 41838,
            "first_name": "Melvin",
            "last_name": "Whitfield",
            "photo_url": "assets/1606254692.3981113_MelvinWhitfield_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ca40de3-b4c8-42e1-9aa0-eb41b0d93e23"
        },
        {
          "id": 42206,
          "person": {
            "id": 41796,
            "first_name": "Michael",
            "last_name": "Panek",
            "photo_url": "assets/1604502033.957801_Panek_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bea185b3-72bb-4626-af14-9a8ebd6d04bf"
        },
        {
          "id": 14607,
          "person": {
            "id": 14608,
            "first_name": "Michael",
            "last_name": "Rodriguez",
            "photo_url": "assets/1589890170.5798569_Michaelpic002_t.jpg",
            "phone_number": "4807381404",
            "email_address": "mrodriguez1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7443,
              "name": "Day",
              "contract": {
                "id": 5483,
                "name": "Continuum (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "655efe44-7c11-454c-86e3-aaeb6600968a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e4535fa1-d499-41ad-bb7d-dbac8161e059"
        },
        {
          "id": 40606,
          "person": {
            "id": 40239,
            "first_name": "Michael",
            "last_name": "Spurr",
            "photo_url": "assets/1600717830.2716622_IMG_0506_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 128,
              "name": "Stocker",
              "name_abbreviation": "STOCKR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-08-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3f8bc3f0-eaba-4fde-ab73-aea62f121010"
        },
        {
          "id": 42184,
          "person": {
            "id": 41774,
            "first_name": "Michele",
            "last_name": "Brown",
            "photo_url": "assets/1606755268.8505025_michelle_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d3ff1476-dacc-4216-9eb6-e49fe0d50528"
        },
        {
          "id": 5851,
          "person": {
            "id": 5851,
            "first_name": "Michelle",
            "last_name": "Hockersmith",
            "photo_url": "assets/ec7087cda38545be912df4cb737291c0_headshot_t.jpg",
            "phone_number": "6058463030",
            "email_address": "mhockersmith@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7514,
              "name": "Day",
              "contract": {
                "id": 5500,
                "name": "Sioux Falls Main",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "996856a7-59aa-4c04-8682-8f1eba9cd6f7"
        },
        {
          "id": 14474,
          "person": {
            "id": 14475,
            "first_name": "Miguel",
            "last_name": "Canessa",
            "photo_url": "a55a77c3-d2e8-8e97-ab89-fbb8555bd058.png",
            "phone_number": "",
            "email_address": "230035@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "77cbef15-8dfd-46b1-a8f0-2553a95e8705"
        },
        {
          "id": 2028,
          "person": {
            "id": 2028,
            "first_name": "Miriam",
            "last_name": "Ramirez",
            "photo_url": "assets/640c289a309c456db1664c425804263f_IMG_20221108_10303_t.jpg",
            "phone_number": "",
            "email_address": "228513@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "671903b2-0f77-4015-9c02-ead1f97f3242"
        },
        {
          "id": 42136,
          "person": {
            "id": 41726,
            "first_name": "Monica",
            "last_name": "Ramirez",
            "photo_url": "assets/1606758967.7702568_MLRamirezpicture_t.jpg",
            "phone_number": "3132586629",
            "email_address": "236405@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c6ef4c9d-68d7-4363-a4b2-6e3a30a6fcce"
        },
        {
          "id": 41768,
          "person": {
            "id": 41381,
            "first_name": "Nabor",
            "last_name": "Hurtado Hernandez",
            "photo_url": "assets/1603895188.2083905_NaborHurtado_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2160,
              "name": "Night",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c18ee90-4113-4c1a-8653-3689f0e36c79"
        },
        {
          "id": 34,
          "person": {
            "id": 34,
            "first_name": "Nayely",
            "last_name": "Chavarria",
            "photo_url": "assets/9f2071246d31475f994f8adb2478cd61_IMG_20221108_10688_t.jpg",
            "phone_number": "",
            "email_address": "228343@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-04-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ee3c1cc-a69c-4057-bc26-f3c015208443"
        },
        {
          "id": 42183,
          "person": {
            "id": 41773,
            "first_name": "Nicola",
            "last_name": "Braham",
            "photo_url": "assets/1606255193.6181564_Nicola_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bab298ee-9c0f-4d0f-9f68-e16b86ca256d"
        },
        {
          "id": 43367,
          "person": {
            "id": 42947,
            "first_name": "Norma",
            "last_name": "Valencia",
            "photo_url": "assets/1608317936.2723095_NormaValencia_t.jpg",
            "phone_number": "4086652650",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 1881,
              "name": "Day",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "11b3404a-c1b6-488b-bd54-00ddc59096d5"
        },
        {
          "id": 41037,
          "person": {
            "id": 40662,
            "first_name": "Norma",
            "last_name": "Zabalza-Ramos",
            "photo_url": "assets/1603293932.7525063_MITProgramProfilePicture_t.jpg",
            "phone_number": "3179822140",
            "email_address": "nramos@sbmcorp.com"
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e898222a-e274-4ecf-bd29-4cda5c3d58c0"
        },
        {
          "id": 38777,
          "person": {
            "id": 38440,
            "first_name": "Olga",
            "last_name": "Lopez-Tovar",
            "photo_url": "assets/1240705f154941cb950faded07e1c5b7_OlgaPic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "037a769d-5259-4ff9-bb27-c96249d27704"
        },
        {
          "id": 42519,
          "person": {
            "id": 42103,
            "first_name": "Omar",
            "last_name": "Swanson",
            "photo_url": "assets/1614177936.3535557_OmarSwanson_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "885bb335-b640-4d6a-b365-5966bc9dd69f"
        },
        {
          "id": 11169,
          "person": {
            "id": 11169,
            "first_name": "Onofre",
            "last_name": "Rivera",
            "photo_url": "42d65803-a6f4-95f4-d2a6-7a6d68db6039.png",
            "phone_number": "",
            "email_address": "229056@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d771783-2933-4df2-b635-c1ec45ccfc42"
        },
        {
          "id": 20771,
          "person": {
            "id": 20813,
            "first_name": "Osmin",
            "last_name": "Alvarez",
            "photo_url": "assets/1585774005.8992577_AlbertoInsitePic_t.jpg",
            "phone_number": "",
            "email_address": "232766@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2011,
              "name": "Day",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5a0676a-22f3-4d94-b18b-9fe28fafaa53"
        },
        {
          "id": 42179,
          "person": {
            "id": 41769,
            "first_name": "Ouida",
            "last_name": "Vaughns",
            "photo_url": "assets/1604599452.7514212_ouida_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c7cd6ee1-5b2a-4819-8790-c7f6f028a3a0"
        },
        {
          "id": 21470,
          "person": {
            "id": 21469,
            "first_name": "Paola",
            "last_name": "Martinez",
            "photo_url": "assets/1582308675.7996583_PaolaHeadshot_t.jpg",
            "phone_number": "4259997453",
            "email_address": "pmartinez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-02-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7fc2b70a-02c3-4337-9384-db1bf24d853e"
        },
        {
          "id": 2365,
          "person": {
            "id": 2365,
            "first_name": "Patricia",
            "last_name": "Icelo",
            "photo_url": "assets/1589307283.754996_20200512_102453_resized_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-04-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca371a65-dc91-4582-8ba9-33cf41df4e0f"
        },
        {
          "id": 42528,
          "person": {
            "id": 42112,
            "first_name": "Patricia",
            "last_name": "Tinsley",
            "photo_url": "assets/1605538391.724336_20201113_223555_resized_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47b47792-bd12-41de-96dd-7dd8319286ff"
        },
        {
          "id": 15299,
          "person": {
            "id": 15292,
            "first_name": "Perry",
            "last_name": "Moats",
            "photo_url": "9541de5a-7a4e-4999-db18-00687f6563bc.png",
            "phone_number": "",
            "email_address": "230602@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-09-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28734b86-d826-4222-894d-1f3cd4a510f3"
        },
        {
          "id": 11219,
          "person": {
            "id": 11219,
            "first_name": "Peter",
            "last_name": "Tumminello",
            "photo_url": "b4d8c5d6-c1c0-8b85-7127-759c6b0ecbb7.png",
            "phone_number": "",
            "email_address": "227398@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 1877,
              "name": "Day",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "183df624-4d86-4c97-955f-82874e92e523"
        },
        {
          "id": 41950,
          "person": {
            "id": 41542,
            "first_name": "Petrona",
            "last_name": "Garcia Jarquin",
            "photo_url": "assets/1605285537.1542292_PetronaGarcia_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-10-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "43f2b945-8721-46d3-b170-f30d43e2ba78"
        },
        {
          "id": 21453,
          "person": {
            "id": 21452,
            "first_name": "Phu",
            "last_name": "Hoang",
            "photo_url": "assets/1583958543.231821_IMG_4266_t.png",
            "phone_number": "",
            "email_address": "232979@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-02-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f4221ff0-0a81-4b2f-be63-3c9521865e2b"
        },
        {
          "id": 12314,
          "person": {
            "id": 12314,
            "first_name": "Qiying",
            "last_name": "Zhong",
            "photo_url": "e74106dc-ca01-92e2-b518-01305e582042.png",
            "phone_number": "",
            "email_address": "229517@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-07-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc7a40f1-6343-439a-8899-66392c5d9259"
        },
        {
          "id": 40432,
          "person": {
            "id": 40065,
            "first_name": "Radfan",
            "last_name": "Saleh",
            "photo_url": "assets/1599665747.415446_RadfanSaleh_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-09-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0055d6fb-e55c-4d63-9b77-e8d29e595de4"
        },
        {
          "id": 41726,
          "person": {
            "id": 41339,
            "first_name": "Rafael",
            "last_name": "Lovos Recinos",
            "photo_url": "assets/1603895229.5363288_AlexLovos_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2824,
              "name": "Swing",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-10-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "054d56d4-b56d-45f6-8a45-cabde8f265dc"
        },
        {
          "id": 710,
          "person": {
            "id": 710,
            "first_name": "Rafael",
            "last_name": "Villasenor",
            "photo_url": "assets/38b3bc678df94715a6ed4bd69c14dbcc_RafaelVillasenor.1_t.jpg",
            "phone_number": "",
            "email_address": "218674@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3001,
              "name": "Swing",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8cb665d5-9769-4d9e-b882-350dec031da3"
        },
        {
          "id": 43539,
          "person": {
            "id": 43115,
            "first_name": "Rahel",
            "last_name": "Kidane",
            "photo_url": "assets/1608572814.830195_RahelKidaneheadshot002_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-12-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb5e9c3a-e593-410d-961b-f8fbcad3f2af"
        },
        {
          "id": 39009,
          "person": {
            "id": 38654,
            "first_name": "Randolf",
            "last_name": "Contreras",
            "photo_url": "assets/1597347123.8409503_rrr_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-07-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ed801a65-fb5a-4d80-8c0a-2a04482c2bbc"
        },
        {
          "id": 42133,
          "person": {
            "id": 41723,
            "first_name": "Regina",
            "last_name": "Cunigan",
            "photo_url": "assets/1609185611.2583535_ReginaCunigan_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7977,
              "name": "Day",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "770613b4-c696-428d-93ff-29a6d0bf160d"
        },
        {
          "id": 42130,
          "person": {
            "id": 41720,
            "first_name": "Regina",
            "last_name": "Wienclaw",
            "photo_url": "assets/1614700142.9757862_ReginaWeinclaw_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7964,
              "name": "Day",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6aea7868-b0bb-4707-995d-49851214b730"
        },
        {
          "id": 42194,
          "person": {
            "id": 41784,
            "first_name": "Renee",
            "last_name": "Richardson",
            "photo_url": "assets/1604598819.7879133_rene_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7971,
              "name": "Swing",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d58bdfde-373a-4fc7-9fd9-140c332843d9"
        },
        {
          "id": 42205,
          "person": {
            "id": 41795,
            "first_name": "Retha",
            "last_name": "Evans",
            "photo_url": "assets/1604603893.180932_8_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37720e3a-7929-4c4f-b451-19eb0b0ca54e"
        },
        {
          "id": 42207,
          "person": {
            "id": 41797,
            "first_name": "Rhonda",
            "last_name": "Stevenson",
            "photo_url": "assets/1614707135.5304422_RhondaStevenson_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8c2cf95d-b3b9-4042-b2f0-a245301b8a55"
        },
        {
          "id": 14511,
          "person": {
            "id": 14512,
            "first_name": "Ribka",
            "last_name": "Argo",
            "photo_url": "53dbb18f-15b8-1e3e-d5d4-fb27d1cea223.png",
            "phone_number": "",
            "email_address": "230156@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-08-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4029d584-718e-42bc-aa42-684a51e33239"
        },
        {
          "id": 5137,
          "person": {
            "id": 5137,
            "first_name": "Rickey",
            "last_name": "Lynch",
            "photo_url": "f4524c13-33dc-a1ff-22a7-a3ca0e2e2e3e.png",
            "phone_number": "",
            "email_address": "rlynch@sbmcorp.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2436,
              "name": "Swing",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-07-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5de30799-cf10-4953-ae58-2b6ba267fe8f"
        },
        {
          "id": 42644,
          "person": {
            "id": 42224,
            "first_name": "Robert",
            "last_name": "Borgford",
            "photo_url": "assets/b3f3ce379fda459a92de5def19ccaf12_IMG_E0172_t.JPG",
            "phone_number": "425-219-9780",
            "email_address": "rborgford@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ab8c119-d9b1-4ea3-ab7d-2432da4a4e1a"
        },
        {
          "id": 44131,
          "person": {
            "id": 43695,
            "first_name": "Robert",
            "last_name": "Perkins",
            "photo_url": "assets/aacefa650b1d48f29715d70d2a0d461c_robert_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2021-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8590e07-dc32-45fe-8d59-8eb019882dbe"
        },
        {
          "id": 22044,
          "person": {
            "id": 22026,
            "first_name": "Robert",
            "last_name": "Smith",
            "photo_url": "assets/r6143e5171eac4b78887fd2b51ac7c731_BobbyNew_t.jpg",
            "phone_number": "7323519345",
            "email_address": "rrsmith@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 1466,
              "name": "Day",
              "contract": {
                "id": 1072,
                "name": "Bridgewater, NJ - Healthcare",
                "customer_id": 721,
                "customer_name": "Nestle",
                "uuid": "c6c8742b-73a8-4003-a44c-7931f00d6c06",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1977,
              "name": "Day",
              "contract": {
                "id": 1119,
                "name": "Flemington, NJ",
                "customer_id": 48,
                "customer_name": "3M",
                "uuid": "686275cb-42bb-483b-b160-6f509c584ba1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1923,
              "name": "Day",
              "contract": {
                "id": 1685,
                "name": "REG503",
                "customer_id": 200,
                "customer_name": "East",
                "uuid": "942c84c8-29af-44fe-962b-8ba1dcd08e16",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-02-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09e653da-ded9-40aa-8662-fdc5ae334b7c"
        },
        {
          "id": 14756,
          "person": {
            "id": 14757,
            "first_name": "Roberto",
            "last_name": "Coronado",
            "photo_url": "0a83af97-a8f7-d034-b843-e43483622527.png",
            "phone_number": "",
            "email_address": "230020@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "99e513f5-9be3-43b5-8aff-6ad0eb1162a7"
        },
        {
          "id": 42131,
          "person": {
            "id": 41721,
            "first_name": "Robin",
            "last_name": "Parks",
            "photo_url": "assets/1604945783.2710876_StandardFemaleAvatar_t.png",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0874a710-79d8-4bbb-9e7a-71cb8fcfb875"
        },
        {
          "id": 42202,
          "person": {
            "id": 41792,
            "first_name": "Rodney",
            "last_name": "Penn",
            "photo_url": "assets/46fd0979897f4001af3e247d32f48d70_RodneyPenn_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 7970,
              "name": "Day",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b401ef7c-b08f-443a-bb58-7eb224a01c6d"
        },
        {
          "id": 6641,
          "person": {
            "id": 6641,
            "first_name": "Rodrigo",
            "last_name": "Espinal",
            "photo_url": "28676d26-71f0-95dd-81e3-2a8f4374db9b.png",
            "phone_number": "9737236276",
            "email_address": "127434@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2419,
              "name": "Swing",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "025456ae-9d06-4919-9990-e1ae3653520a"
        },
        {
          "id": 42924,
          "person": {
            "id": 42506,
            "first_name": "Rosa",
            "last_name": "Casimiro Dominguez",
            "photo_url": "assets/1613175144.3553572_20210210_180717_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1710,
              "name": "Night",
              "contract": {
                "id": 1198,
                "name": "Waukegan, IL",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-11-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75fc757d-64a7-4e67-baac-397f4eb12508"
        },
        {
          "id": 43368,
          "person": {
            "id": 42948,
            "first_name": "Rosa",
            "last_name": "Quesada",
            "photo_url": "assets/1608318754.2887475_RosaQuesada_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2497,
              "name": "Swing",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4cbd5417-2e50-44b2-b719-89a6c6181f66"
        },
        {
          "id": 15130,
          "person": {
            "id": 15126,
            "first_name": "Rosalba",
            "last_name": "Martinez-Lozano",
            "photo_url": "41d335bf-fcb5-1f5a-c245-f2fe784bb48d.png",
            "phone_number": "",
            "email_address": "230512@placeholder.com"
          },
          "positions": [
            {
              "id": 231,
              "name": "Custodial Lead (Loc 6 WA)",
              "name_abbreviation": "CUSTODIAL LEAD"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f6dd94f4-6b20-4e96-a8e2-cb9acdfdac42"
        },
        {
          "id": 10940,
          "person": {
            "id": 10940,
            "first_name": "Roxana",
            "last_name": "Osoy",
            "photo_url": "assets/8c0d5fe75d814f77bcd99a077f894527_Roxana_t.jpg",
            "phone_number": "317-441-0439",
            "email_address": "227815@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-03-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ddbc16cc-e075-4157-b69d-92d2e153b303"
        },
        {
          "id": 15519,
          "person": {
            "id": 15516,
            "first_name": "Ryan",
            "last_name": "Hardick",
            "photo_url": "bb3f8740-8f79-f234-b6e7-3b0581f85b70.png",
            "phone_number": "",
            "email_address": "230877@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1523,
              "name": "Day",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2d00152-5ed1-444d-a5d0-b88915450413"
        },
        {
          "id": 42176,
          "person": {
            "id": 41766,
            "first_name": "Ryan",
            "last_name": "Wilson",
            "photo_url": "assets/1604599865.758577_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b986e74-59f8-407c-856e-7d8adf8dcac0"
        },
        {
          "id": 43382,
          "person": {
            "id": 42962,
            "first_name": "Salvador",
            "last_name": "Fuentes Morales",
            "photo_url": "assets/1608317748.3605444_SalvadorFuentes_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2497,
              "name": "Swing",
              "contract": {
                "id": 2266,
                "name": "Santa Clara, CA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "35558816-001d-4b9d-9057-f744a45ace8b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b4ec3f1-0939-4cfc-bf28-82cae66bb5ec"
        },
        {
          "id": 24238,
          "person": {
            "id": 24188,
            "first_name": "Samuel",
            "last_name": "Dennemann",
            "photo_url": "assets/1584100618.0655344_IMG_34981_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-03-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83f4365f-48a6-490c-993a-356134188e89"
        },
        {
          "id": 42334,
          "person": {
            "id": 41921,
            "first_name": "Sandra",
            "last_name": "Bernal Flores",
            "photo_url": "assets/1605285564.6018593_SanraBerral_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81e9aadd-26e8-47cf-b995-663da6ff0fc5"
        },
        {
          "id": 18642,
          "person": {
            "id": 18781,
            "first_name": "Sandra",
            "last_name": "Lee",
            "photo_url": "assets/1589485788.2845657_SandraL_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-11-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3babdb13-339c-462c-96e3-b2c8652f825b"
        },
        {
          "id": 8763,
          "person": {
            "id": 8763,
            "first_name": "Sara",
            "last_name": "Brake",
            "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
            "phone_number": "7655050426",
            "email_address": "sblystone@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8161,
              "name": "Swing",
              "contract": {
                "id": 6017,
                "name": "ELIL00OVRHD",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "bfdddb1e-a538-44cc-b871-cae996834a1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-04-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f3529b7-903d-485f-992e-7f4ac44b99a8"
        },
        {
          "id": 18157,
          "person": {
            "id": 18152,
            "first_name": "Shania",
            "last_name": "Hall",
            "photo_url": "e3af6b20-8049-9179-c791-cd48490df01d.png",
            "phone_number": "",
            "email_address": "231351@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0afdb39d-9f06-4c94-8054-137af21c20f7"
        },
        {
          "id": 42096,
          "person": {
            "id": 41686,
            "first_name": "Shari",
            "last_name": "Richmond",
            "photo_url": "assets/1604599973.3102572_RefusedphotoFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7974,
              "name": "Day",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab30bb86-45b7-4b07-8c42-4ae5dd00a795"
        },
        {
          "id": 42174,
          "person": {
            "id": 41764,
            "first_name": "Sheniqua",
            "last_name": "Stamps",
            "photo_url": "assets/1604612667.1595802_43_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0491da88-fe33-4e4c-a7f3-55a0ba6abbd6"
        },
        {
          "id": 41889,
          "person": {
            "id": 41485,
            "first_name": "Silvia",
            "last_name": "Alvarez",
            "photo_url": "assets/1603895138.1166599_SilviaAlvarez_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2157,
              "name": "Day",
              "contract": {
                "id": 1766,
                "name": "Pleasanton",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5578b561-8dc2-4dcb-a6d4-b821b058591b"
        },
        {
          "id": 15071,
          "person": {
            "id": 15067,
            "first_name": "Stacy",
            "last_name": "Curtis",
            "photo_url": "assets/47940a2414854ab994d045afe239a1b6_StacyWhiteBackgroundPic_t.jpg",
            "phone_number": "7346292038",
            "email_address": "scurtis@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 8717,
              "name": "Unassigned",
              "contract": {
                "id": 2221,
                "name": "Fairlane Business Park 5",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b9e77e63-6d8d-4dcf-869b-16035d64226d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8718,
              "name": "Unassigned",
              "contract": {
                "id": 6599,
                "name": "ITHQ-C",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "072d9b44-8ce1-4871-8ca2-5dd49ca5aec8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8731,
              "name": "Unassigned",
              "contract": {
                "id": 6744,
                "name": "Fairlane Plaza",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "a2a95553-97cc-45e7-b52e-7a5d81388124",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8757,
              "name": "Unassigned",
              "contract": {
                "id": 6750,
                "name": "IT Bic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "aec4d0b8-6a67-44bc-a648-a6cc03bca0c6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8756,
              "name": "Unassigned",
              "contract": {
                "id": 6746,
                "name": "Fairlane Business Park 3",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6ef10849-a18c-435e-bff4-85b5ec24768a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8755,
              "name": "Unassigned",
              "contract": {
                "id": 6751,
                "name": "Commerce Park 2 15080",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d42144d6-7452-4f65-8e41-bd720df644d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8754,
              "name": "Unassigned",
              "contract": {
                "id": 6747,
                "name": "1633 Fairlane Circle",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e44fe7ed-4949-4c17-854a-3c45110ac83b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8730,
              "name": "Unassigned",
              "contract": {
                "id": 2217,
                "name": "Fairlane North",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "c6f73172-925b-454f-ad4b-5db8edc4ed77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8729,
              "name": "Unassigned",
              "contract": {
                "id": 6740,
                "name": "Commerce Park 2 15090",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "508442a9-5d2a-4a25-ab46-a1cb1df0c4ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8728,
              "name": "Unassigned",
              "contract": {
                "id": 6742,
                "name": "800 Republic",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "79d7238d-3fb5-474d-923a-3c5bca18b31b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8727,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8732,
              "name": "Unassigned",
              "contract": {
                "id": 2220,
                "name": "DSC 1 & 2",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0a5c8e64-2bcf-4ca4-8b17-243cc171f25a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2019-09-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c90f41b7-be66-49f4-b205-be4b3abfabaf"
        },
        {
          "id": 1306,
          "person": {
            "id": 1306,
            "first_name": "Star",
            "last_name": "Stramel",
            "photo_url": "1c610a89-e886-228e-93aa-3ecef5a3b51d.png",
            "phone_number": "",
            "email_address": "228930@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-06-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7bf9ddf1-d355-4120-b255-d3c655406419"
        },
        {
          "id": 38116,
          "person": {
            "id": 37803,
            "first_name": "Stephanie",
            "last_name": "Romero",
            "photo_url": "assets/3a675ba53899498fb154994b32768cdb_me_t.png",
            "phone_number": "4089819713",
            "email_address": "sromero1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-06-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b683929-b0e5-4197-8f67-e8b366fd4369"
        },
        {
          "id": 42147,
          "person": {
            "id": 41737,
            "first_name": "Stephanie",
            "last_name": "Thompson",
            "photo_url": "assets/1604501970.4334106_Thompson_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7979,
              "name": "Day",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab500bcd-5d08-4d3d-be7c-6fd61222d8e6"
        },
        {
          "id": 42101,
          "person": {
            "id": 41691,
            "first_name": "Sylvia",
            "last_name": "Ramirez",
            "photo_url": "assets/1614700863.1633987_SylviaRamirez_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "826874a7-746c-46c0-ab3d-952d3c7524b9"
        },
        {
          "id": 15115,
          "person": {
            "id": 15111,
            "first_name": "Tai",
            "last_name": "Nguyen",
            "photo_url": "10cc8f74-850c-5570-16af-4b061458c50f.png",
            "phone_number": "",
            "email_address": "230492@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ce1d4b2e-517f-404c-8f3d-4b5ef5cb7ef2"
        },
        {
          "id": 42531,
          "person": {
            "id": 42115,
            "first_name": "Tammy",
            "last_name": "Hawkes",
            "photo_url": "assets/1606752791.8111372_tammy1_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "310a1e94-6480-4163-ab76-83a10e5484e5"
        },
        {
          "id": 42167,
          "person": {
            "id": 41757,
            "first_name": "Tanisha",
            "last_name": "Shirley",
            "photo_url": "assets/1604503128.3214602_Shirley_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5ef29bc-d063-4fb7-8767-ebdc1b714beb"
        },
        {
          "id": 42602,
          "person": {
            "id": 42183,
            "first_name": "Tariq",
            "last_name": "Tawill",
            "photo_url": "assets/1605648305.637314_TariqTawi_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2630,
              "name": "Swing",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aac5c2af-c328-471e-88ea-deaf7fd8de2c"
        },
        {
          "id": 14750,
          "person": {
            "id": 14751,
            "first_name": "Tedhy",
            "last_name": "Garcia",
            "photo_url": "d85cc7d4-6b0e-5f49-2d97-513d202710cd.png",
            "phone_number": "",
            "email_address": "230014@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7bd2134-1ee8-4293-a9ca-21fdb23ee98f"
        },
        {
          "id": 15697,
          "person": {
            "id": 15693,
            "first_name": "Thanh",
            "last_name": "Nguyen",
            "photo_url": "e68d56c6-79ee-1d4f-6dc3-37e56599ace3.png",
            "phone_number": "",
            "email_address": "231047@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-10-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "237ef0e3-9cc5-442d-83c9-ff8b0ed9d93b"
        },
        {
          "id": 18833,
          "person": {
            "id": 18968,
            "first_name": "Thanh",
            "last_name": "Tran",
            "photo_url": "assets/1587715158.3308272_tranh_t.jpg",
            "phone_number": "",
            "email_address": "231663@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-11-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b34a7e63-c1ec-4a6a-9369-cab1f0bee1f3"
        },
        {
          "id": 15123,
          "person": {
            "id": 15119,
            "first_name": "Thao",
            "last_name": "Nguyen",
            "photo_url": "1aa86cb8-31f5-51b8-6624-4c9a84bc8ba6.png",
            "phone_number": "4254269459",
            "email_address": "230496@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "44caf84c-d11d-4363-8e03-3179afd45d0c"
        },
        {
          "id": 15118,
          "person": {
            "id": 15114,
            "first_name": "Thi",
            "last_name": "Nguyen",
            "photo_url": "f1234fda-6b3e-2aef-6910-6be607fef40b.png",
            "phone_number": "4256358588",
            "email_address": "230495@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d352165a-af8f-4816-a83f-c343e040a1d4"
        },
        {
          "id": 42084,
          "person": {
            "id": 41674,
            "first_name": "Thomas",
            "last_name": "Murphy",
            "photo_url": "assets/1606755170.6196108_thomas_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c84ba1f5-464b-49a8-a4c2-dbda38e225ee"
        },
        {
          "id": 41329,
          "person": {
            "id": 40947,
            "first_name": "Thomas",
            "last_name": "Rarick",
            "photo_url": "assets/1602527653.5813794_ThomasProfile_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 1877,
              "name": "Day",
              "contract": {
                "id": 1768,
                "name": "Oak Hill, NY",
                "customer_id": 33,
                "customer_name": "GSK (CBRE)",
                "uuid": "1b31be80-8d43-4810-94cb-60bf7841491b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0bc45198-2455-4938-8a31-6436bcf48cb9"
        },
        {
          "id": 15878,
          "person": {
            "id": 15872,
            "first_name": "Thuan",
            "last_name": "Hoang",
            "photo_url": "b31c7fce-4877-8373-8864-92e6a4af66c9.png",
            "phone_number": "",
            "email_address": "231204@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-10-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f2762d16-3c4d-40ae-abdb-02bbc25d90ed"
        },
        {
          "id": 43609,
          "person": {
            "id": 43183,
            "first_name": "Tiffany",
            "last_name": "Cottrell",
            "photo_url": "assets/1615233664.4599867_18464_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "12146068-51ce-42e5-b861-40c849ad6156"
        },
        {
          "id": 42590,
          "person": {
            "id": 42171,
            "first_name": "Tiffany",
            "last_name": "Douglas",
            "photo_url": "assets/1607719176.9008262_TIffanyDouglas_t.jpg",
            "phone_number": "2485614407",
            "email_address": "tdouglas@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57263d37-3f7b-4b45-8e75-ed0806069510"
        },
        {
          "id": 42168,
          "person": {
            "id": 41758,
            "first_name": "Tonya",
            "last_name": "Arrington",
            "photo_url": "assets/1604593550.9036825_RefusedphotoFemale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57e5647d-9654-4690-893b-15de52abb521"
        },
        {
          "id": 42236,
          "person": {
            "id": 41826,
            "first_name": "Tyrone",
            "last_name": "Glover",
            "photo_url": "assets/1613135520.369299_TyroneGlover_t.jpeg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7966,
              "name": "Day",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57f38a8b-1e70-4990-9b09-05000d5abc63"
        },
        {
          "id": 14742,
          "person": {
            "id": 14743,
            "first_name": "Valquiria",
            "last_name": "Pina",
            "photo_url": "761854d1-5229-22e7-4585-b08f5e797b38.png",
            "phone_number": "",
            "email_address": "230006@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2505,
              "name": "Swing",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7245ea9b-4760-4a53-a5bb-aa39d5c584b6"
        },
        {
          "id": 18920,
          "person": {
            "id": 19055,
            "first_name": "Van",
            "last_name": "Ly",
            "photo_url": "assets/1578634513.744441_IMG_23701_t.JPG",
            "phone_number": "",
            "email_address": "231822@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-11-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0628076e-9b7c-48e7-9c1b-c2178aa66a9b"
        },
        {
          "id": 19717,
          "person": {
            "id": 19810,
            "first_name": "Van",
            "last_name": "Ly",
            "photo_url": "assets/1590686810.708563_vanlyheadshot_t.jpg",
            "phone_number": "",
            "email_address": "232470@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-01-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bceed0f4-c062-44f3-bec0-726469bd9cc7"
        },
        {
          "id": 20929,
          "person": {
            "id": 20956,
            "first_name": "Van",
            "last_name": "Phan",
            "photo_url": "assets/1582093447.933793_IMG_30041_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2020-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2dc622a7-a16c-4859-89c8-d0f098b003ca"
        },
        {
          "id": 14444,
          "person": {
            "id": 14445,
            "first_name": "Veronica",
            "last_name": "Sierra Falcon",
            "photo_url": "assets/rdc13cff3e29449b5b60131d39e1a7fe4_veronica_t.jpg",
            "phone_number": "",
            "email_address": "229833@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-07-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f891ca0b-2326-445a-9522-77e767a38200"
        },
        {
          "id": 8006,
          "person": {
            "id": 8006,
            "first_name": "Victor",
            "last_name": "Campos",
            "photo_url": "assets/84b8a1af393b4d749ad113313a26f045_IMG_20221104_59638_t.jpg",
            "phone_number": "",
            "email_address": "228132@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae710bfe-6a06-45c7-8989-50c1b4ef5fb1"
        },
        {
          "id": 39669,
          "person": {
            "id": 39303,
            "first_name": "Victor",
            "last_name": "Lecler",
            "photo_url": "assets/1597064703.7078874_VictorLeclerFloorTech_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2020-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4028f89a-3edc-4dfc-bc99-ebc41545a500"
        },
        {
          "id": 42055,
          "person": {
            "id": 41645,
            "first_name": "Victoria",
            "last_name": "Malone",
            "photo_url": "assets/1604610008.9482431_19_t.JPG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7970,
              "name": "Day",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ebd88261-8cd9-405a-a096-e37d838d0812"
        },
        {
          "id": 25207,
          "person": {
            "id": 25101,
            "first_name": "Volodymyr",
            "last_name": "Petrash",
            "photo_url": "assets/1586357828.867501_VovoHeadshot_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 83,
              "name": "Lead, Recycle",
              "name_abbreviation": "RECLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-03-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a8ad9536-872f-4df2-a3e9-265dc726fc6e"
        },
        {
          "id": 15038,
          "person": {
            "id": 15034,
            "first_name": "Wan",
            "last_name": "Liu",
            "photo_url": "d08a7030-45a5-3546-d755-aa847b4e45d3.png",
            "phone_number": "",
            "email_address": "230382@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c668c39-a5a9-42f1-b1dd-8d1fcd62b452"
        },
        {
          "id": 42058,
          "person": {
            "id": 41648,
            "first_name": "William",
            "last_name": "McDaniel",
            "photo_url": "assets/1604600251.1303542_RefusedphotoMale_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bca25df5-2ab2-4d00-b1d6-1648feafc53e"
        },
        {
          "id": 21905,
          "person": {
            "id": 21899,
            "first_name": "William",
            "last_name": "Nelson",
            "photo_url": "assets/150f6bf5a30a4ffe811541a5c83c20b9_William_Nelson_29542_t.jpg",
            "phone_number": "4252864035",
            "email_address": "wnelson1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 4556,
              "name": "Day",
              "contract": {
                "id": 2546,
                "name": "Aurora",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "15cd519f-1e4b-4706-9109-399ba283dfde",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2148,
              "name": "Day",
              "contract": {
                "id": 2162,
                "name": "Manteno, IL",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "f771f62b-0aef-4a45-967e-31a4effaea30",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2046,
              "name": "Day",
              "contract": {
                "id": 1747,
                "name": "REG321",
                "customer_id": 203,
                "customer_name": "Central",
                "uuid": "d3e82bd1-e003-4d69-a711-4b63e15788da",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7747,
              "name": "Day",
              "contract": {
                "id": 5765,
                "name": "MLI1– Davenport, IA",
                "customer_id": 24,
                "customer_name": "Amazon",
                "uuid": "49fa8b8d-ecc9-4aee-bc85-6cfa9af3526b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-02-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "85635e05-0109-4d3d-ade8-71dc58fc65f0"
        },
        {
          "id": 41697,
          "person": {
            "id": 41310,
            "first_name": "Xavier",
            "last_name": "Elliott",
            "photo_url": "assets/1605895404.42212_XavierSBMpic_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1884,
              "name": "Day",
              "contract": {
                "id": 2120,
                "name": "Des Moines, IA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "9788063b-76d4-4413-ba45-1481dd0f00a7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-10-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d67c6ffb-eac8-4e48-99c8-ba41bc75fab4"
        },
        {
          "id": 15389,
          "person": {
            "id": 15382,
            "first_name": "Zahra",
            "last_name": "Omar",
            "photo_url": "87fd1191-7afd-a0d3-8f96-94e55803bf5a.png",
            "phone_number": "",
            "email_address": "230830@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e0c749c3-ef18-4595-8337-f31819881c16"
        },
        {
          "id": 36398,
          "person": {
            "id": 36150,
            "first_name": "Zane",
            "last_name": "Hauck",
            "photo_url": "assets/r83be7835cd3b4a909131fb2c9177c3a3_Screenshot20230930at12.04_t.18PM",
            "phone_number": "3104983801",
            "email_address": "zhauck@sbmcorp.com"
          },
          "positions": [
            {
              "id": 713,
              "name": "Assoc Account Dir",
              "name_abbreviation": "ASACDR"
            }
          ],
          "shifts": [
            {
              "id": 7395,
              "name": "Day",
              "contract": {
                "id": 5530,
                "name": "Wenatchee, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "14e2c33f-6a7d-4575-89ab-170b52f3ab71",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-05-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d58f197a-b252-4fbc-aeb1-76ec97878119"
        },
        {
          "id": 38424,
          "person": {
            "id": 38095,
            "first_name": "Zehra",
            "last_name": "Adjemovic",
            "photo_url": "assets/1594675014.2815237_Zehra1Rem_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-07-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3885f90f-ce50-4bbc-8aa7-36a4daa00755"
        },
        {
          "id": 15128,
          "person": {
            "id": 15124,
            "first_name": "Zhou",
            "last_name": "Wu",
            "photo_url": "cf0b77b3-3689-dbe0-c1a0-04705333a148.png",
            "phone_number": "4256337975",
            "email_address": "230510@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f21078a-e5d8-4059-9faf-a9250830376d"
        },
        {
          "id": 41615,
          "person": {
            "id": 41230,
            "first_name": "Zhouying",
            "last_name": "Zhou",
            "photo_url": "assets/1604936210.4642005_ScreenShot20201109at7.36_t.22AM",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6092,
              "name": "Day",
              "contract": {
                "id": 3993,
                "name": "San Francisco",
                "customer_id": 1422,
                "customer_name": "Meta (CBRE)",
                "uuid": "3b1ba713-6f46-4128-9fb9-dad51c75c8c6",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2020-10-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "117fe446-81a2-4992-8258-99e0523d9f45"
        }
      ]
    },
    {
      "rangeTitle": "5+ Years",
      "rangeSubtitle": "Employed for 5 or more years",
      "percentage": 0.26,
      "total": 677,
      "positions": 37,
      "employees": [
        {
          "id": 314,
          "person": {
            "id": 314,
            "first_name": "Adam",
            "last_name": "Marcial",
            "photo_url": "87b3c518-1084-5ca2-18a2-d84e59aa3494.png",
            "phone_number": "3472484618",
            "email_address": "amarcial@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2011-05-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eddcd9d9-bb50-4f02-8d6b-6731cb2ecccd"
        },
        {
          "id": 8321,
          "person": {
            "id": 8321,
            "first_name": "Adan",
            "last_name": "Padilla",
            "photo_url": "assets/b0c36261e68d4e3fb8cd26414edd0af2_IMG_20221104_58325_t.jpg",
            "phone_number": "",
            "email_address": "221995@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "335427f7-949d-468b-aa73-11772382e886"
        },
        {
          "id": 11301,
          "person": {
            "id": 11301,
            "first_name": "Adela",
            "last_name": "Hernandez",
            "photo_url": "b0392430-487e-69f1-1c81-25005fa16c95.png",
            "phone_number": "",
            "email_address": "221675@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65855bac-83d8-44fa-bbe9-8a41f18e5b7f"
        },
        {
          "id": 1978,
          "person": {
            "id": 1978,
            "first_name": "Adela",
            "last_name": "Morfin Bello",
            "photo_url": "assets/3c4fbb51f327498a8b14592ba244d15d_20221116_063815_t.jpg",
            "phone_number": "",
            "email_address": "222094@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07231f5a-67d9-42da-9c2d-148bab3b25bc"
        },
        {
          "id": 8190,
          "person": {
            "id": 8190,
            "first_name": "Adem",
            "last_name": "Kenan",
            "photo_url": "20046cbc-7544-e32a-e889-cd0947769de0.png",
            "phone_number": "4253821614",
            "email_address": "224198@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-05-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3edf097b-3b8c-411e-b3b2-04c2fe66c104"
        },
        {
          "id": 558,
          "person": {
            "id": 558,
            "first_name": "Adriana",
            "last_name": "Beltran",
            "photo_url": "fa1ba329-270b-5401-d52b-bc104599796f.png",
            "phone_number": "",
            "email_address": "225190@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bb44457c-fe50-4e3e-89a8-98c5b6363f69"
        },
        {
          "id": 3655,
          "person": {
            "id": 3655,
            "first_name": "Adriana",
            "last_name": "Manrique",
            "photo_url": "assets/6f8f0adb4d6e48a78d3d161319c23f47_AdrainaManrique_t.jpeg",
            "phone_number": "",
            "email_address": "226854@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2419,
              "name": "Swing",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d860192-d520-41af-8d95-cb9cb1c75718"
        },
        {
          "id": 7741,
          "person": {
            "id": 7741,
            "first_name": "Aida",
            "last_name": "Darkulic",
            "photo_url": "assets/56aac24b0c5e4f29813e537865eeca0b_IMG_20210922_160106009_t.jpg",
            "phone_number": "2064079291",
            "email_address": "ADarkulic@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72d95fa9-3674-46b4-a2dd-2b2ff24264a6"
        },
        {
          "id": 9945,
          "person": {
            "id": 9945,
            "first_name": "Aida",
            "last_name": "Kovac",
            "photo_url": "c504aa93-0312-2c68-1c85-6646852188d3.png",
            "phone_number": "4257712662",
            "email_address": "102321@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9b474d8-24a8-48e4-87cd-55fde19e1aa3"
        },
        {
          "id": 1973,
          "person": {
            "id": 1973,
            "first_name": "Albert",
            "last_name": "Villafane",
            "photo_url": "48e0c0be-23ad-8b1b-abf9-ad8143e5c597.png",
            "phone_number": "(267)257-3400",
            "email_address": "191990@placeholder.com"
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2011-08-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a20cd207-af6d-453c-8d90-507b0821428f"
        },
        {
          "id": 5012,
          "person": {
            "id": 5012,
            "first_name": "Alejandra",
            "last_name": "Sosa",
            "photo_url": "assets/c49499a125c44bfd92aa5a21e982fe57_20221202_094054_t.jpg",
            "phone_number": "",
            "email_address": "220431@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b804fa4-ea49-4a36-ad1a-cc0fca180b1f"
        },
        {
          "id": 11407,
          "person": {
            "id": 11407,
            "first_name": "Alexandra",
            "last_name": "Lara",
            "photo_url": "e1ba0a04-44f3-511c-e88b-ac9b0a3bcf4c.png",
            "phone_number": "(484)221-5034",
            "email_address": "200147@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-06-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "caf528f1-c09d-4679-b1ae-0aa2cc84b911"
        },
        {
          "id": 8551,
          "person": {
            "id": 8551,
            "first_name": "Alfonso",
            "last_name": "Martinez",
            "photo_url": "assets/rcae597b1e5f54235a8d25f07c8a8175d_cropped4278132180704874653.jpg",
            "phone_number": "",
            "email_address": "209518@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2051,
              "name": "Day",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "55fc5643-ddc4-417a-ae4d-54be141ca730"
        },
        {
          "id": 5386,
          "person": {
            "id": 5386,
            "first_name": "Alma",
            "last_name": "Chavez",
            "photo_url": "assets/9d3b721b019c40228179f2ae2c814462_PDCpics008_t.jpg",
            "phone_number": "",
            "email_address": "207876@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-03-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18fbe374-9523-42da-a0e8-59760be429a7"
        },
        {
          "id": 10217,
          "person": {
            "id": 10217,
            "first_name": "Amal",
            "last_name": "Sahal",
            "photo_url": "274162ff-760b-9696-b35b-f4c7d68e8733.png",
            "phone_number": "",
            "email_address": "221623@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "51fdc4a0-9508-4945-940a-d0d51044d3a6"
        },
        {
          "id": 8852,
          "person": {
            "id": 8852,
            "first_name": "Amarilis",
            "last_name": "Reyes De Marroquin",
            "photo_url": "b5b7a212-2846-4b11-fd95-50c0642d2563.png",
            "phone_number": "",
            "email_address": "215037@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-09-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5635de8c-8dd0-45af-9ea3-ca2c08e8d202"
        },
        {
          "id": 8491,
          "person": {
            "id": 8491,
            "first_name": "Amila",
            "last_name": "Custic",
            "photo_url": "8c3f4cd5-ed6e-2a1e-41ca-6dc1fd1998b8.png",
            "phone_number": "",
            "email_address": "101010@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d94ea15-260b-4dfa-90f2-80dd7d99c2a0"
        },
        {
          "id": 2119,
          "person": {
            "id": 2119,
            "first_name": "Amir",
            "last_name": "Dodic",
            "photo_url": "d609943d-1e28-8908-f223-af2ff3aaf942.png",
            "phone_number": "",
            "email_address": "adodic@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e08cee69-9a10-4467-9105-6d066d593fba"
        },
        {
          "id": 1311,
          "person": {
            "id": 1311,
            "first_name": "Amritpal",
            "last_name": "Singh",
            "photo_url": "assets/027bad2c2a994309a2f5343d78902060_Headshort_t.jpg",
            "phone_number": "4255294305",
            "email_address": "asingh@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f4365a8f-daba-467a-a6d5-f92db016bc5a"
        },
        {
          "id": 5526,
          "person": {
            "id": 5526,
            "first_name": "An",
            "last_name": "Trinh",
            "photo_url": "021def35-2d67-f5dd-ae2a-6cf72ed4cd5f.png",
            "phone_number": "",
            "email_address": "104621@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9035cba-af0f-440a-b4ca-0f98e6b5b54f"
        },
        {
          "id": 11813,
          "person": {
            "id": 11813,
            "first_name": "Ana",
            "last_name": "Blanco",
            "photo_url": "2b97721f-1055-a77a-ebbf-f3a046c8d77a.png",
            "phone_number": "9193083206",
            "email_address": "125153@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2471,
              "name": "Swing",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2010-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9c9985f2-3a1d-47a2-b651-1e46df1f9733"
        },
        {
          "id": 4016,
          "person": {
            "id": 4016,
            "first_name": "Ana",
            "last_name": "Cordova",
            "photo_url": "assets/176a4f7ecee546a6a7d79132ac8713b5_IMG_20221104_43906_t.jpg",
            "phone_number": "",
            "email_address": "219223@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-06-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5de62f6-bdc6-4c04-8520-57e444c04112"
        },
        {
          "id": 7370,
          "person": {
            "id": 7370,
            "first_name": "Ana",
            "last_name": "Custodio Batista",
            "photo_url": "53197770-8084-4a6d-e254-5063392a3ab7.png",
            "phone_number": "",
            "email_address": "214782@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c57e7818-2083-4436-8b95-fc1ee7948114"
        },
        {
          "id": 8498,
          "person": {
            "id": 8498,
            "first_name": "Ana",
            "last_name": "Mejia",
            "photo_url": "assets/d774ebd69d114195bcc71fe4c7401207_PicAna_t.jpg",
            "phone_number": "",
            "email_address": "202067@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38dc3104-8544-400f-bf66-064c4da1238a"
        },
        {
          "id": 11406,
          "person": {
            "id": 11406,
            "first_name": "Ana",
            "last_name": "Perusquia",
            "photo_url": "userprofile/itpljaljihusxzcwcc3l",
            "phone_number": "555-555-5555",
            "email_address": "202330@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42696070-71d2-41b2-aac7-d6ea9cd66c81"
        },
        {
          "id": 9217,
          "person": {
            "id": 9217,
            "first_name": "Andrew",
            "last_name": "Christison",
            "photo_url": "2b09a47b-d69a-8fef-c09c-1e65960133ab.png",
            "phone_number": "",
            "email_address": "223434@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-04-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ca0e39e-3840-4419-9ee5-5d270b447da1"
        },
        {
          "id": 11682,
          "person": {
            "id": 11682,
            "first_name": "Andrew",
            "last_name": "Smith",
            "photo_url": "assets/1587576767.6580079_AndySmithFinal_t.png",
            "phone_number": "3143789463",
            "email_address": "asmith@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 6222,
              "name": "Day",
              "contract": {
                "id": 3134,
                "name": "CBRE36OVRHD",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "b584c892-ba18-4bec-a424-ee357c8c3068",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1792,
              "name": "Day",
              "contract": {
                "id": 1962,
                "name": "Bloomfield",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "674967cb-d560-4440-809d-d682f82678b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1821,
              "name": "Day",
              "contract": {
                "id": 1809,
                "name": "Chandler",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "c6e2f419-72bc-4140-8688-21d3803beaba",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7652,
              "name": "Day",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3174,
              "name": "Day",
              "contract": {
                "id": 2656,
                "name": "Murfreesboro",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "69c31c57-abb7-405d-9a8c-60e05813605b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7654,
              "name": "Day",
              "contract": {
                "id": 1819,
                "name": "Sun City",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "3df489ab-4d88-436a-a8ad-a5c4f26879ac",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3112,
              "name": "Day",
              "contract": {
                "id": 2633,
                "name": "Concordville, PA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "4eba69c9-6629-41b6-8344-d90ddb2fe511",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3082,
              "name": "Day",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3179,
              "name": "Day",
              "contract": {
                "id": 2634,
                "name": "Kalamazoo, MI",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "791209fc-57ee-4271-bd07-90aa1e8978d8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3146,
              "name": "Day",
              "contract": {
                "id": 2627,
                "name": "Tulsa, OK",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d6f64213-b922-467f-8c79-04580edbd9ce",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3120,
              "name": "Day",
              "contract": {
                "id": 2639,
                "name": "Winter Haven",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "8cd64e8b-2467-42c2-8bb3-fe2aca91c0e9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3156,
              "name": "Day",
              "contract": {
                "id": 2635,
                "name": "Jollyville (Austin)",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "17c0fd74-19ed-4b71-902f-fac1b456c37d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3098,
              "name": "Day",
              "contract": {
                "id": 2624,
                "name": "Malta, NY",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "eb1902d2-9a86-4850-bb32-1404cefdba7d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3099,
              "name": "Day",
              "contract": {
                "id": 2623,
                "name": "Albany, OH",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "cb4abfa7-fddc-4aac-872c-7bd811c6fc36",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3165,
              "name": "Day",
              "contract": {
                "id": 2643,
                "name": "Lincoln South",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "d8f85727-9545-4613-b233-95f5be40d308",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7996,
              "name": "On-Call",
              "contract": {
                "id": 2664,
                "name": "Jacksonville FL",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3094,
              "name": "Swing",
              "contract": {
                "id": 2632,
                "name": "Alpharetta, GA",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "23e89982-4fef-4fb8-bc11-de8fdcfc08a9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7656,
              "name": "Swing",
              "contract": {
                "id": 1808,
                "name": "CJ Harris",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "7f0da9b5-4d5c-4ff9-b827-83efda8d7316",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 2837,
              "name": "Swing",
              "contract": {
                "id": 2467,
                "name": "Gallatin, TN",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "ae7d3ed1-544e-4b11-b40f-ba2a6e458a13",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7645,
              "name": "Swing",
              "contract": {
                "id": 1810,
                "name": "Phoenix Central",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "5bd3190f-6a98-4834-bdbb-cf527f35cba5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7968,
              "name": "Swing",
              "contract": {
                "id": 1813,
                "name": "Stapley",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "36c16e7b-11db-4b03-b2a0-d7d67985f7ca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-01-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "54af66d1-1f49-4387-9ac1-646433bde779"
        },
        {
          "id": 3368,
          "person": {
            "id": 3368,
            "first_name": "Angel",
            "last_name": "Lora Tejada",
            "photo_url": "1a386e74-62f0-8e3b-f3e4-6ce1ec3f5340.png",
            "phone_number": "3512096697",
            "email_address": "atejada@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "be918ff6-52c2-4ce9-bb77-9d0a74a490a0"
        },
        {
          "id": 8920,
          "person": {
            "id": 8920,
            "first_name": "Angela",
            "last_name": "Thigpen",
            "photo_url": "assets/205d090bf5994e12afbe17bf3722bb3a_Angie_t.jpg",
            "phone_number": "",
            "email_address": "104516@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-01-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ec6b8107-f21a-4aeb-977f-067e5fd34102"
        },
        {
          "id": 7422,
          "person": {
            "id": 7422,
            "first_name": "Angla",
            "last_name": "Berry",
            "photo_url": "8e5757ca-0e1e-df75-5698-118fbe8d5cf8.png",
            "phone_number": "",
            "email_address": "218208@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c6c75da9-9a0f-43f3-aa49-ca45acfe3413"
        },
        {
          "id": 2713,
          "person": {
            "id": 2713,
            "first_name": "Anh",
            "last_name": "Nguyen",
            "photo_url": "a37cfe89-d1da-a477-39e7-bcb0bc3fe858.png",
            "phone_number": "",
            "email_address": "103184@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76190fee-ec58-4c82-b254-808425b6b20c"
        },
        {
          "id": 9905,
          "person": {
            "id": 9905,
            "first_name": "Anh",
            "last_name": "Nguyen",
            "photo_url": "7306ac44-047d-bbd9-2582-c0d8c7084933.png",
            "phone_number": "",
            "email_address": "103171@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3c6c57f7-6936-425e-8fa3-de49316df7e6"
        },
        {
          "id": 6335,
          "person": {
            "id": 6335,
            "first_name": "Anilkumar",
            "last_name": "Patel",
            "photo_url": "assets/921fa3b1c778416e92aaa5a9952855b5_thumbnail_IMG_20220721_203521_t.jpg",
            "phone_number": "",
            "email_address": "197467@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2013-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e26f01b0-e20d-43ee-bb93-9159ff0f1de9"
        },
        {
          "id": 11604,
          "person": {
            "id": 11604,
            "first_name": "Anna",
            "last_name": "McGraw",
            "photo_url": "fe74b327-58fd-0af0-9a02-ca79b35dc6e3.png",
            "phone_number": "",
            "email_address": "202128@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "51077c7f-1f26-42d9-ad19-44ce6a4fe2c9"
        },
        {
          "id": 5522,
          "person": {
            "id": 5522,
            "first_name": "Antonina",
            "last_name": "Danilyuk",
            "photo_url": "becb3e00-cb83-e955-7e44-40d83382a2ee.png",
            "phone_number": "",
            "email_address": "101026@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "16bdedc7-7992-4e1f-b80e-abda81096e98"
        },
        {
          "id": 1767,
          "person": {
            "id": 1767,
            "first_name": "Antonio",
            "last_name": "Mozo Perez",
            "photo_url": "userprofile/q168zgwygkk0ahyffdi9",
            "phone_number": "",
            "email_address": "203375@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2a7c3c8d-eca4-4996-8694-a748bae903c9"
        },
        {
          "id": 8178,
          "person": {
            "id": 8178,
            "first_name": "Antonio",
            "last_name": "Urena Garcia",
            "photo_url": "4ac2ffc4-d3dd-47d3-7073-06d0bb7572f7.png",
            "phone_number": "",
            "email_address": "226718@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b812488-f6e6-4d08-b2c7-64a28f30a551"
        },
        {
          "id": 8093,
          "person": {
            "id": 8093,
            "first_name": "Antwain",
            "last_name": "Major",
            "photo_url": "userprofile/wmgegpmloyqdlz9e24lw",
            "phone_number": "",
            "email_address": "209361@placeholder.com"
          },
          "positions": [
            {
              "id": 54,
              "name": "GMP Aseptic",
              "name_abbreviation": "GMPASP"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2015-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dc9e7521-5333-4412-88f3-501fdb724046"
        },
        {
          "id": 7805,
          "person": {
            "id": 7805,
            "first_name": "Apout",
            "last_name": "Agok",
            "photo_url": "assets/b1e75cb377134d8798ace6096ec5c45e_20200508_120952_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-02-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b7c3afd8-9e69-4d72-885c-f3287b008d73"
        },
        {
          "id": 10845,
          "person": {
            "id": 10845,
            "first_name": "Araceli",
            "last_name": "Blas Escobedo",
            "photo_url": "5645bcd4-4a50-ee22-82d0-6bd9644977af.png",
            "phone_number": "",
            "email_address": "221850@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "89ccf8e7-579c-437d-b945-42e0434d6817"
        },
        {
          "id": 6388,
          "person": {
            "id": 6388,
            "first_name": "Aris",
            "last_name": "Veras",
            "photo_url": "b64e2d9a-04c8-cc7c-c457-55c8956ce4ae.png",
            "phone_number": "",
            "email_address": "226747@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1b456b69-8049-4775-9ff6-73febeeefad7"
        },
        {
          "id": 8605,
          "person": {
            "id": 8605,
            "first_name": "Arturo",
            "last_name": "Robles Perez",
            "photo_url": "assets/503bbc9547a8497cb44bd0cf8cd21841_IMG_20221104_45991_t.jpg",
            "phone_number": "",
            "email_address": "222043@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5ca4f2c-fceb-45bc-8b84-115bc41e0178"
        },
        {
          "id": 3456,
          "person": {
            "id": 3456,
            "first_name": "Asha",
            "last_name": "Mada",
            "photo_url": "b9177f6b-d13b-2e3c-69b1-0c037a3f0985.png",
            "phone_number": "",
            "email_address": "207458@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3086,
              "name": "Day",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "30e58237-93c0-4196-a5f2-05cee17911d5"
        },
        {
          "id": 9101,
          "person": {
            "id": 9101,
            "first_name": "Asha",
            "last_name": "Saed",
            "photo_url": "9d0caf01-789b-f127-f7f2-ddd9d2615333.png",
            "phone_number": "",
            "email_address": "222352@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-01-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81466eda-484a-4aa0-a913-9e0b74b3ad5f"
        },
        {
          "id": 7058,
          "person": {
            "id": 7058,
            "first_name": "Ashley",
            "last_name": "Otis",
            "photo_url": "assets/f3d4c5f052e3418d91a87e630d46d25f_2_t.jpg",
            "phone_number": "",
            "email_address": "204546@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "afd8beef-ed02-40eb-8da4-4d210be3e5e0"
        },
        {
          "id": 6461,
          "person": {
            "id": 6461,
            "first_name": "Audelia",
            "last_name": "Sanchez",
            "photo_url": "assets/0c6947d452f44db99ed76cffd72f990c_IMG_20221104_59133_t.jpg",
            "phone_number": "",
            "email_address": "222021@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4b8cb184-ea8a-4274-8297-5748563b04d0"
        },
        {
          "id": 12398,
          "person": {
            "id": 12398,
            "first_name": "Austin",
            "last_name": "Reichandadter",
            "photo_url": "814ba20f-d20a-9841-e4be-f51dd94689eb.png",
            "phone_number": "",
            "email_address": "221861@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad6bebca-489f-4bc7-a176-b86d2096610a"
        },
        {
          "id": 4135,
          "person": {
            "id": 4135,
            "first_name": "Author",
            "last_name": "Campbell",
            "photo_url": "5da71ba7-1a45-7f05-f125-289dd01c4dfd.png",
            "phone_number": "",
            "email_address": "202300@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4917b2d3-a7b7-4873-b06d-9a44786f963b"
        },
        {
          "id": 6441,
          "person": {
            "id": 6441,
            "first_name": "Bahra",
            "last_name": "Tirak",
            "photo_url": "3c32c3b8-affa-3e18-02d8-e617d4b5e6e5.png",
            "phone_number": "4253299831",
            "email_address": "104542@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "db6f69f9-f5ba-4c14-80db-826677377c34"
        },
        {
          "id": 2924,
          "person": {
            "id": 2924,
            "first_name": "Bai",
            "last_name": "Huang",
            "photo_url": "cc5e1802-1cfd-fb2d-467c-43e56dc90876.png",
            "phone_number": "",
            "email_address": "102072@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7dbeae6-f1a7-4302-ac77-347ee3e70f80"
        },
        {
          "id": 10539,
          "person": {
            "id": 10539,
            "first_name": "Bao",
            "last_name": "Truong",
            "photo_url": "71e06da2-0baf-5fde-7763-c0abd59347e6.png",
            "phone_number": "",
            "email_address": "104632@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ccbaa676-ea04-4617-a2b7-82709a79c043"
        },
        {
          "id": 2331,
          "person": {
            "id": 2331,
            "first_name": "Barbara",
            "last_name": "Torres",
            "photo_url": "userprofile/liivnn2yabyg11tkwaip",
            "phone_number": "",
            "email_address": "202361@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "227470f0-f659-467d-97e6-b7b824c146bf"
        },
        {
          "id": 9043,
          "person": {
            "id": 9043,
            "first_name": "Behireta",
            "last_name": "Dodic",
            "photo_url": "a148ebfa-65da-2799-1072-9acfae6753e6.png",
            "phone_number": "4253937821",
            "email_address": "101220@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f751b2f1-12dd-4efe-89e8-3fe175fe29e0"
        },
        {
          "id": 1053,
          "person": {
            "id": 1053,
            "first_name": "Berentu",
            "last_name": "Dekebo",
            "photo_url": "c498984b-7d43-0cee-8b5e-344d6b1f6ab8.png",
            "phone_number": "",
            "email_address": "101085@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2842f7d3-f1ae-4298-a7c4-fd9b3adcfd07"
        },
        {
          "id": 6704,
          "person": {
            "id": 6704,
            "first_name": "Bich",
            "last_name": "Nguyen",
            "photo_url": "b87e493e-c35e-2927-4001-90618b70cedb.png",
            "phone_number": "",
            "email_address": "103157@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "35479a1f-828b-4e08-b69d-47dc7f5baee9"
        },
        {
          "id": 8098,
          "person": {
            "id": 8098,
            "first_name": "Blanca",
            "last_name": "Canales",
            "photo_url": "8cbf81a7-70f1-a131-4e0b-dd6484ffd574.png",
            "phone_number": "",
            "email_address": "100636@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5a00219-9e6c-442b-8b2a-06d835c9920d"
        },
        {
          "id": 9992,
          "person": {
            "id": 9992,
            "first_name": "Brainard",
            "last_name": "Larry",
            "photo_url": "7558eba6-3d02-ca72-671d-4ac224696bee.png",
            "phone_number": "",
            "email_address": "218379@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b9f83a83-2523-4599-b776-9446f4cb1d6b"
        },
        {
          "id": 2399,
          "person": {
            "id": 2399,
            "first_name": "Brandon",
            "last_name": "Turner",
            "photo_url": "assets/d5820e3a28cd4f00b927a5453174defa_PDCpics012_t.jpg",
            "phone_number": "3172860443",
            "email_address": "bturner@sbmcorp.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "569df97b-7fbb-4a88-b55c-9dd4611e8f48"
        },
        {
          "id": 3311,
          "person": {
            "id": 3311,
            "first_name": "Brenda",
            "last_name": "Morales",
            "photo_url": "6e0b0f9e-eea3-631d-cb76-dc978b5c5f5d.png",
            "phone_number": "6103900867",
            "email_address": "103068@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3960394-77cb-4d66-b0f5-62e27803ca3a"
        },
        {
          "id": 8475,
          "person": {
            "id": 8475,
            "first_name": "Brittany",
            "last_name": "Cierlik",
            "photo_url": "1947ee55-bcc4-c132-1f37-550c481f1226.png",
            "phone_number": "2482383198",
            "email_address": "221114@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25b211b2-c713-4f29-b2f3-caecb34138f0"
        },
        {
          "id": 6025,
          "person": {
            "id": 6025,
            "first_name": "Brittany",
            "last_name": "Gilliam",
            "photo_url": "assets/f639ab7af4a1452d801eec9486c3e875_image000000_t.jpg",
            "phone_number": "",
            "email_address": "202205@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1bc0abdb-0a5d-4bd1-ab8d-468275ae07ef"
        },
        {
          "id": 4054,
          "person": {
            "id": 4054,
            "first_name": "Byambasuren",
            "last_name": "Luvsan",
            "photo_url": "50db5e46-2731-7e5d-8478-e621c45e1b45.png",
            "phone_number": "",
            "email_address": "221943@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "914923c0-d7fa-44cf-99b2-86d896eb52ca"
        },
        {
          "id": 12515,
          "person": {
            "id": 12515,
            "first_name": "Cam",
            "last_name": "Tran",
            "photo_url": "8ebac191-7807-50da-5f5d-672f5423851d.png",
            "phone_number": "",
            "email_address": "104615@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aebde85a-64b9-4ab3-a712-bb99f0df60cb"
        },
        {
          "id": 2947,
          "person": {
            "id": 2947,
            "first_name": "Candida",
            "last_name": "Baez-Melo",
            "photo_url": "dd0db5de-178c-6983-8703-6112eb22f11e.png",
            "phone_number": "",
            "email_address": "226709@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f03bc4a-d4a1-4b57-95b3-12353f733cf6"
        },
        {
          "id": 7946,
          "person": {
            "id": 7946,
            "first_name": "Carl",
            "last_name": "Sledge",
            "photo_url": "473f62b7-4a00-d6ad-06f1-bc17d707ab37.png",
            "phone_number": "3175182422",
            "email_address": "csledge@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f5bfbed5-146b-4f0a-9f69-c516820b9ac2"
        },
        {
          "id": 9490,
          "person": {
            "id": 9490,
            "first_name": "Carlos",
            "last_name": "Beltran",
            "photo_url": "0ce0b502-777f-5289-30a1-39e05e4ab40f.png",
            "phone_number": "",
            "email_address": "226731@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d82fbd42-7723-446b-93ac-e47ef656037a"
        },
        {
          "id": 3666,
          "person": {
            "id": 3666,
            "first_name": "Carlos",
            "last_name": "Diaz",
            "photo_url": "ef960ecc-733c-1ac4-9235-3198a853d927.png",
            "phone_number": "8573127905",
            "email_address": "218135@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f448d51f-b909-4cda-9e27-6650b2eec3f8"
        },
        {
          "id": 9832,
          "person": {
            "id": 9832,
            "first_name": "Carlos",
            "last_name": "Figueroa",
            "photo_url": "b1cc1c2f-1ee2-50d6-70c4-1c0a31e2124c.png",
            "phone_number": "",
            "email_address": "224362@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1f0e5d85-fb0b-45b0-bd55-ac49ffd0a2aa"
        },
        {
          "id": 2967,
          "person": {
            "id": 2967,
            "first_name": "Carlos",
            "last_name": "Flores",
            "photo_url": "assets/4149e7a1c9884b5790d0816c59232625_PDCpics011_t.jpg",
            "phone_number": "",
            "email_address": "195207@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92aa5066-badb-44c5-aca0-52fc553695b3"
        },
        {
          "id": 10000,
          "person": {
            "id": 10000,
            "first_name": "Carlos",
            "last_name": "Romero",
            "photo_url": "1038ca6c-e7e4-de20-de01-1253b4c2891a.png",
            "phone_number": "3166194072",
            "email_address": "cromero-pulido@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2011,
              "name": "Day",
              "contract": {
                "id": 2069,
                "name": "Irving, TX",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "640869e4-f712-4ae1-af14-3be05dc0336d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-05-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "897275ab-1aec-47a5-aadd-b3821a8f3b15"
        },
        {
          "id": 14207,
          "person": {
            "id": 14208,
            "first_name": "Carlos",
            "last_name": "Rosas",
            "photo_url": "assets/75bbbaac5e6841c588e3fd3882b598d1_carlos_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3037,
              "name": "Day",
              "contract": {
                "id": 2589,
                "name": "Roseville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "123db32d-6425-45a7-a771-e015c599d908",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "14324df2-20c7-4367-a752-207b51873764"
        },
        {
          "id": 8716,
          "person": {
            "id": 8716,
            "first_name": "Carlos",
            "last_name": "Villagomez",
            "photo_url": "assets/7f90fd3d4d474a6483c1eccafe02219d_20221202_094143_t.jpg",
            "phone_number": "",
            "email_address": "220440@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b3d47df-8d70-4274-8791-3543e6e992f9"
        },
        {
          "id": 4222,
          "person": {
            "id": 4222,
            "first_name": "Carmela",
            "last_name": "Blanco",
            "photo_url": "7264be31-13bf-c3e7-6b58-6a152695d4bc.png",
            "phone_number": "9192010701",
            "email_address": "125154@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2471,
              "name": "Swing",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2010-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5795c36e-8634-47b7-be07-b80c6bc62abb"
        },
        {
          "id": 1795,
          "person": {
            "id": 1795,
            "first_name": "Carmen",
            "last_name": "Valentin",
            "photo_url": "3075efe3-c164-f8a7-af27-7a32247c819c.png",
            "phone_number": "",
            "email_address": "224395@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49ab866b-ba6a-4e9a-89c2-cb13e2045eca"
        },
        {
          "id": 3320,
          "person": {
            "id": 3320,
            "first_name": "Cedric",
            "last_name": "Coleman",
            "photo_url": "assets/24837fd5aaed451796930d1b93968bb9_Cedric_t.jpg",
            "phone_number": "3179090049",
            "email_address": "ccoleman@sbmcorp.com"
          },
          "positions": [
            {
              "id": 799,
              "name": "Mgr, Office",
              "name_abbreviation": "OFFMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9bdd4d0c-bbb1-4f33-8ecd-5b8822df823b"
        },
        {
          "id": 3480,
          "person": {
            "id": 3480,
            "first_name": "Cesar",
            "last_name": "Albino Martinez",
            "photo_url": "assets/r5fdb596ed32f4363a318e66464577bc9_cropped1753688085851012807.jpg",
            "phone_number": "6127078394",
            "email_address": "cmartinez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7447,
              "name": "Day",
              "contract": {
                "id": 5491,
                "name": "Shoreview Data Center",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bb785cef-87b8-435e-b5a6-c0cb74728cc9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7201,
              "name": "Swing",
              "contract": {
                "id": 5495,
                "name": "McKnight Road",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7202,
              "name": "Swing",
              "contract": {
                "id": 5497,
                "name": "Central",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7203,
              "name": "Swing",
              "contract": {
                "id": 5498,
                "name": "Old Saint Anthony/OSA*",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 2654,
              "name": "Swing",
              "contract": {
                "id": 1464,
                "name": "Wayzata",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7487,
              "name": "Swing",
              "contract": {
                "id": 5499,
                "name": "Ridgedale",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 9299,
              "name": "Unassigned",
              "contract": {
                "id": 5744,
                "name": "Duluth PCS (CBRE)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "a4ae81ac-bbb8-454a-8aee-bf2d22b3f093",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2466df0f-6561-42ea-a4df-f4fdd572a7e1"
        },
        {
          "id": 8109,
          "person": {
            "id": 8109,
            "first_name": "Chan",
            "last_name": "Van",
            "photo_url": "29279e94-4997-9f14-d96a-8bbbabc1a849.png",
            "phone_number": "4255293174",
            "email_address": "cvan@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f72157c5-5b00-4787-a42c-fc653c5d5e93"
        },
        {
          "id": 6539,
          "person": {
            "id": 6539,
            "first_name": "Charles",
            "last_name": "Tinnin",
            "photo_url": "assets/8cfe039c1e0547bfbbb61315933e528d_4_t.jpg",
            "phone_number": "",
            "email_address": "202311@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "20b43533-d91f-4268-9acd-08d1256c66f4"
        },
        {
          "id": 5477,
          "person": {
            "id": 5477,
            "first_name": "Cheng-Jiu",
            "last_name": "Li",
            "photo_url": "99026354-3a47-8c8a-161f-64e1226ef372.png",
            "phone_number": "",
            "email_address": "222844@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-02-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b391c90c-5c7b-44c4-a554-db9b85d6003f"
        },
        {
          "id": 58540,
          "person": {
            "id": 58004,
            "first_name": "Chhoeun",
            "last_name": "Chhan",
            "photo_url": "9397d173-3302-acd3-a5dc-bbed7b7859f2.png",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0fc7dedb-40c2-4708-b4bf-46e539e96ed7"
        },
        {
          "id": 3671,
          "person": {
            "id": 3671,
            "first_name": "Choi",
            "last_name": "Tsang Leung",
            "photo_url": "c4eca239-09b2-b0e5-fead-226214e743c0.png",
            "phone_number": "4253195664",
            "email_address": "221617@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "92b7d25a-14b9-49c2-8f15-3db59e951b9b"
        },
        {
          "id": 3807,
          "person": {
            "id": 3807,
            "first_name": "Christopher",
            "last_name": "Elsey",
            "photo_url": "789156b5-dfbc-bb1b-cf43-bcad19e2b128.png",
            "phone_number": "",
            "email_address": "218381@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "10ebffd6-ba20-4095-be4e-5963865b10d6"
        },
        {
          "id": 7202,
          "person": {
            "id": 7202,
            "first_name": "Chu",
            "last_name": "Li",
            "photo_url": "8232315c-0b9d-0280-c1d9-276abbbdc3f7.png",
            "phone_number": "",
            "email_address": "102443@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5fa6cdc2-8275-46c0-abd9-cc112b7830b0"
        },
        {
          "id": 6111,
          "person": {
            "id": 6111,
            "first_name": "Chung",
            "last_name": "Wong",
            "photo_url": "b9d23c90-36f8-d1f1-e7af-72103bbcfd17.png",
            "phone_number": "",
            "email_address": "104936@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "22e166d7-82da-4589-aefd-e8f7f443705d"
        },
        {
          "id": 12767,
          "person": {
            "id": 12767,
            "first_name": "Claudia",
            "last_name": "Baez Bartolome",
            "photo_url": "assets/1600709667.6036139_p_t.jpg",
            "phone_number": "6127107560",
            "email_address": "cbaez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7201,
              "name": "Swing",
              "contract": {
                "id": 5495,
                "name": "McKnight Road",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-08-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "688051a0-5577-460a-ac15-fdab1ee94ffa"
        },
        {
          "id": 11693,
          "person": {
            "id": 11693,
            "first_name": "Claudia",
            "last_name": "Flores",
            "photo_url": "userprofile/exchafg77rcxzstptu6n",
            "phone_number": "",
            "email_address": "203490@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3d5ab2fa-9d22-425f-ad69-dab3db4c114e"
        },
        {
          "id": 12778,
          "person": {
            "id": 12778,
            "first_name": "Claudia",
            "last_name": "Sanchez",
            "photo_url": "assets/ceaa86784444447f97140fd773a7b7e6_IMG_5952_t.jpg",
            "phone_number": "",
            "email_address": "220481@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c87131c0-abed-45c8-90c5-2d829f0c9a57"
        },
        {
          "id": 2338,
          "person": {
            "id": 2338,
            "first_name": "Claudia",
            "last_name": "Vargas",
            "photo_url": "assets/b79b70a0f92a42dbb564028a92d974e1_20200507_112402_t.jpg",
            "phone_number": "5152018646",
            "email_address": "cvargas@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7451,
              "name": "Day",
              "contract": {
                "id": 5543,
                "name": "University Building",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "5727ea88-f532-4365-aff8-6881a98a80d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7474,
              "name": "unassigned",
              "contract": {
                "id": 5572,
                "name": "Headquarters HMHQ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "76aea59d-6532-4759-8887-190df768c9d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2011-12-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e049243-f7cf-4dad-938f-2ce98a9c3730"
        },
        {
          "id": 11216,
          "person": {
            "id": 11216,
            "first_name": "Claudio",
            "last_name": "Mendioroz Juarez",
            "photo_url": "f0df1caf-ddf2-b254-e201-919eefed21a0.png",
            "phone_number": "",
            "email_address": "CMendioroz@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2378,
              "name": "Night",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d01b996-090d-4deb-b16d-cc20947ba0dc"
        },
        {
          "id": 6707,
          "person": {
            "id": 6707,
            "first_name": "Constance",
            "last_name": "Hendrix",
            "photo_url": "assets/8e80e0fdc8d5457e9c6963af18254a55_pic_t.jpg",
            "phone_number": "3176901753",
            "email_address": "chendrix@sbmcorp.com"
          },
          "positions": [
            {
              "id": 37,
              "name": "Supv, GMP",
              "name_abbreviation": "GMPSUP"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1f4919a7-d87d-42b8-8303-da0f6940b1a9"
        },
        {
          "id": 6820,
          "person": {
            "id": 6820,
            "first_name": "Craig",
            "last_name": "Fields",
            "photo_url": "userprofile/dzf6bptdasdktqu9s7ke",
            "phone_number": "",
            "email_address": "202240@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c5c20c3-2904-4f16-b32e-eaaa1f9982d3"
        },
        {
          "id": 2878,
          "person": {
            "id": 2878,
            "first_name": "Crispino",
            "last_name": "Mejia Ballesteros",
            "photo_url": "88d10c3c-f47c-001d-e1f4-f91de5c33810.png",
            "phone_number": "",
            "email_address": "221576@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8954c45b-b2ea-47ad-903b-a1c6b8101652"
        },
        {
          "id": 12266,
          "person": {
            "id": 12266,
            "first_name": "Cuong",
            "last_name": "Diep",
            "photo_url": "7f6b89e0-04e5-907e-8d03-4c044712fc6e.png",
            "phone_number": "4253432208",
            "email_address": "191880@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "618fc732-ca05-4b88-a349-ce71102a2a2d"
        },
        {
          "id": 10893,
          "person": {
            "id": 10893,
            "first_name": "Curtis",
            "last_name": "Rucker",
            "photo_url": "assets/910d3510c03c45db9c45ad4dcda76202_Curtis_t.jpg",
            "phone_number": "",
            "email_address": "221162@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61be6570-7cee-4569-92cb-e07a23b0d852"
        },
        {
          "id": 6872,
          "person": {
            "id": 6872,
            "first_name": "Cynthia",
            "last_name": "Brock",
            "photo_url": "assets/71b8a2bb247e4dbb9f2b7770a63e0fad_20230303_085911_resized_t.jpg",
            "phone_number": "3174163134",
            "email_address": "cknose@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8da0a2f7-61fa-4726-ae68-69c365637fc8"
        },
        {
          "id": 11703,
          "person": {
            "id": 11703,
            "first_name": "Cynthia",
            "last_name": "Nguyen",
            "photo_url": "cd7aac60-6f50-5751-3ec9-48b7926cab7e.png",
            "phone_number": "4252879489",
            "email_address": "103168@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eb9b7b48-f259-4133-943e-af897104424c"
        },
        {
          "id": 5799,
          "person": {
            "id": 5799,
            "first_name": "Dai",
            "last_name": "Nguyen",
            "photo_url": "ae0d406f-7b1e-c06c-97e1-a547d5e67f7d.png",
            "phone_number": "",
            "email_address": "224652@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ba8984c-8ec0-4739-84df-c965dd2efdbd"
        },
        {
          "id": 8170,
          "person": {
            "id": 8170,
            "first_name": "Daniel",
            "last_name": "Reyes",
            "photo_url": "26db2277-7be0-f3c0-8785-9fb5b4dc0c91.png",
            "phone_number": "(267)642-4895",
            "email_address": "195973@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2012-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "96e1dc67-510e-429c-919e-4429f8b5c677"
        },
        {
          "id": 250,
          "person": {
            "id": 250,
            "first_name": "Danielle",
            "last_name": "Estes",
            "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
            "phone_number": "7342625177",
            "email_address": "destes@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 2050,
              "name": "Day",
              "contract": {
                "id": 1704,
                "name": "REG703",
                "customer_id": 204,
                "customer_name": "West",
                "uuid": "f01bce0e-fbc7-412e-947c-c45ab010d904",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1523,
              "name": "Day",
              "contract": {
                "id": 2071,
                "name": "Plymouth",
                "customer_id": 280,
                "customer_name": "Adient",
                "uuid": "149ae5ed-cfd7-4412-a8bb-46f070282ae3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2339,
              "name": "Day",
              "contract": {
                "id": 2310,
                "name": "Ann Arbor - HEL",
                "customer_id": 347,
                "customer_name": "Honda",
                "uuid": "b9d2f654-0484-4678-9301-1ecfff8efe0d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2271,
              "name": "Day",
              "contract": {
                "id": 2084,
                "name": "Detroit, MI",
                "customer_id": 224,
                "customer_name": "Cardinal Health (JLL)",
                "uuid": "810946b0-f603-405f-a1f7-c5bc7f28901d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f292c86-893e-4b91-8113-1311694acf1f"
        },
        {
          "id": 7806,
          "person": {
            "id": 7806,
            "first_name": "Darin",
            "last_name": "Olrich",
            "photo_url": "dbfcf948-cba3-e20d-0c2b-e0cd2b9dea6f.png",
            "phone_number": "",
            "email_address": "218158@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1a4c6831-8760-45e3-903d-f546e6217dbe"
        },
        {
          "id": 1078,
          "person": {
            "id": 1078,
            "first_name": "Darlene",
            "last_name": "McKnight",
            "photo_url": "78473534-04df-87cf-5f76-4daad7aae4fa.png",
            "phone_number": "4252873794",
            "email_address": "221646@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bfb28c3b-df53-4391-b77a-6a1e2c7e9196"
        },
        {
          "id": 5625,
          "person": {
            "id": 5625,
            "first_name": "Darnell",
            "last_name": "Berry",
            "photo_url": "85d86a42-7bcf-5346-cbb6-bde007ddfd10.png",
            "phone_number": "4123284256",
            "email_address": "dberry@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-08-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6c7e716f-c37a-409f-b43d-de2c65404d40"
        },
        {
          "id": 11681,
          "person": {
            "id": 11681,
            "first_name": "David",
            "last_name": "Hartsoe",
            "photo_url": "c7eea176-d538-ae01-2a42-a333ec04534e.png",
            "phone_number": "",
            "email_address": "218347@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2e6449e5-ba89-4d04-916b-601db2bdb7f8"
        },
        {
          "id": 10192,
          "person": {
            "id": 10192,
            "first_name": "David",
            "last_name": "Mooc",
            "photo_url": "72e0ab8c-59c4-ad0c-1675-e02b0a26b5be.png",
            "phone_number": "4254621400",
            "email_address": "192593@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07dc7694-5445-4fbf-a5e8-2fab2a72f8a0"
        },
        {
          "id": 5211,
          "person": {
            "id": 5211,
            "first_name": "David",
            "last_name": "Royer",
            "photo_url": "assets/68f5c6ab43764fee9195aac87c2a87d4_DavidRoyer_t.jpg",
            "phone_number": "",
            "email_address": "202323@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-06-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b676f3c-8d54-4149-8742-fb42a1e44f9e"
        },
        {
          "id": 7602,
          "person": {
            "id": 7602,
            "first_name": "David",
            "last_name": "Young",
            "photo_url": "assets/31441969b10e466f9e4c2e4691562f85_20211006_121020_t.jpg",
            "phone_number": "",
            "email_address": "202293@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c9bde1cf-3751-4deb-8904-3cd8c80bf052"
        },
        {
          "id": 5852,
          "person": {
            "id": 5852,
            "first_name": "Daynab",
            "last_name": "Kulow",
            "photo_url": "e9f47500-8443-4c80-c045-975ba53923c2.png",
            "phone_number": "",
            "email_address": "224487@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 3203,
              "name": "Swing",
              "contract": {
                "id": 2612,
                "name": "St. Louis (Express Scripts)",
                "customer_id": 219,
                "customer_name": "Cigna (CBRE)",
                "uuid": "aa50bad8-fd9b-4936-9c9c-a5a4c591e2bc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ca8fd67-a067-4153-b362-360261d5a8fa"
        },
        {
          "id": 10398,
          "person": {
            "id": 10398,
            "first_name": "De",
            "last_name": "Dao",
            "photo_url": "3537f3de-b7ac-1678-a411-c9b9e1711966.png",
            "phone_number": "",
            "email_address": "101027@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9d2238b1-e70a-4d18-9c79-3b2feef1b1d6"
        },
        {
          "id": 4037,
          "person": {
            "id": 4037,
            "first_name": "Deanna",
            "last_name": "Phillips",
            "photo_url": "assets/c8fb7a2ded404652b3d74e1273801cf9_DW_t.PNG",
            "phone_number": "6127083686",
            "email_address": "DWangler@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9c0281c-43a5-47d3-8561-98698f1506a1"
        },
        {
          "id": 2027,
          "person": {
            "id": 2027,
            "first_name": "Deborah",
            "last_name": "Rexroat",
            "photo_url": "787c89d4-5a23-e7ca-5b86-9cf3a955ef51.png",
            "phone_number": "",
            "email_address": "202132@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "24e0677b-e66e-4770-b53b-50d58f92bd77"
        },
        {
          "id": 11486,
          "person": {
            "id": 11486,
            "first_name": "Delmis",
            "last_name": "Reyes",
            "photo_url": "a2d36b1f-61c7-05e5-9364-6aa0ac7d20bd.png",
            "phone_number": "2152606140",
            "email_address": "103760@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "74a2a4a8-b9d4-417a-bd34-64a60feb1dea"
        },
        {
          "id": 6286,
          "person": {
            "id": 6286,
            "first_name": "Demetrius",
            "last_name": "Gilmore",
            "photo_url": "b7a63021-52db-14c2-2e53-7b1d253bb678.png",
            "phone_number": "",
            "email_address": "221736@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "28fcf714-e6bd-4d81-9963-bcb7033c165f"
        },
        {
          "id": 9799,
          "person": {
            "id": 9799,
            "first_name": "Demitu",
            "last_name": "Gelge",
            "photo_url": "a21e3ee5-90ee-8cb4-92b2-eeaf9bb0a2b2.png",
            "phone_number": "",
            "email_address": "221830@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0c1aebbc-2d6e-4b65-a4e1-19621cdcd8e7"
        },
        {
          "id": 1343,
          "person": {
            "id": 1343,
            "first_name": "Den",
            "last_name": "Huynh",
            "photo_url": "84a8bcc8-ee5f-bf5b-0fda-e8a408936fae.png",
            "phone_number": "",
            "email_address": "221743@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2799f3db-6ed0-449a-98a4-7b13f5738067"
        },
        {
          "id": 8502,
          "person": {
            "id": 8502,
            "first_name": "Denise",
            "last_name": "Sandoval",
            "photo_url": "981e23b6-e6cb-6daa-f6aa-724a6c8df0b3.png",
            "phone_number": "7142920503",
            "email_address": "dsandoval1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 6553,
              "name": "Swing",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-06-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e0aa6082-4682-4607-8f4d-600843076dbe"
        },
        {
          "id": 4049,
          "person": {
            "id": 4049,
            "first_name": "Dennis",
            "last_name": "Osborn",
            "photo_url": "userprofile/gqzwkd9pphfrdbzl2uge",
            "phone_number": "",
            "email_address": "202244@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bddcec23-9279-40d5-ae69-5d16651fca29"
        },
        {
          "id": 5914,
          "person": {
            "id": 5914,
            "first_name": "Dennis",
            "last_name": "Utkan",
            "photo_url": "c05bc3e0-fbe6-240d-8bda-db65e5176b4e.png",
            "phone_number": "",
            "email_address": "218155@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e71f4473-ce6b-423c-bb28-f84696709fda"
        },
        {
          "id": 10187,
          "person": {
            "id": 10187,
            "first_name": "Derrick",
            "last_name": "Williams",
            "photo_url": "assets/5489a8b63ab047a0b558bf8eaaafde7e_1_t.jpg",
            "phone_number": "",
            "email_address": "206320@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-11-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "65efec22-25ad-4c5c-ab43-30744d1cb971"
        },
        {
          "id": 6238,
          "person": {
            "id": 6238,
            "first_name": "Dewayne",
            "last_name": "Moudy",
            "photo_url": "userprofile/gvixs6hfbxfs7xwrjaac",
            "phone_number": "6128496138",
            "email_address": "DMoudy@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d59f4a1-dfa7-46b9-ae52-b57a2cfc9006"
        },
        {
          "id": 3929,
          "person": {
            "id": 3929,
            "first_name": "Dewei",
            "last_name": "He",
            "photo_url": "f2d45d8b-a26c-4af3-2111-dae302943d03.png",
            "phone_number": "4253899571",
            "email_address": "226669@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33a72206-45b5-4821-a8d3-41cc20d1e8ff"
        },
        {
          "id": 5201,
          "person": {
            "id": 5201,
            "first_name": "Dion",
            "last_name": "Townsel",
            "photo_url": "assets/2e0207d09bdb434ca6e3dbe3c377d0b8_dion_t.jpg",
            "phone_number": "",
            "email_address": "202225@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6805cfa1-ebaf-4715-9387-0b5c039d67d1"
        },
        {
          "id": 6562,
          "person": {
            "id": 6562,
            "first_name": "Dioni",
            "last_name": "Estrella",
            "photo_url": "8a1136c9-3a33-0b13-718d-b47db17f499e.png",
            "phone_number": "",
            "email_address": "226696@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67cc5862-7dad-4b9d-91e3-2e619e20222a"
        },
        {
          "id": 9874,
          "person": {
            "id": 9874,
            "first_name": "Dionisio",
            "last_name": "Mendoza Barreras",
            "photo_url": "assets/ed409d58886a45a68b92642357d7204c_IMG_20221108_11663_t.jpg",
            "phone_number": "408-509-2682",
            "email_address": "220801@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "46364449-ca38-4c70-b22f-4cfe80dffb92"
        },
        {
          "id": 5880,
          "person": {
            "id": 5880,
            "first_name": "Dmitriy",
            "last_name": "Kapralev",
            "photo_url": "f7368596-622e-5e34-09af-5d47acd9a5ff.png",
            "phone_number": "",
            "email_address": "102264@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72fc5f7e-44fe-4cee-a29f-cd207b9c0832"
        },
        {
          "id": 9759,
          "person": {
            "id": 9759,
            "first_name": "Donald",
            "last_name": "Toomey",
            "photo_url": "dd3182c1-b700-10fd-3de6-c052cce18e67.png",
            "phone_number": "",
            "email_address": "207136@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2555,
              "name": "Swing",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42fe3011-60c5-49f8-8308-4c0406d11f0e"
        },
        {
          "id": 7718,
          "person": {
            "id": 7718,
            "first_name": "Dorotha",
            "last_name": "Perkins",
            "photo_url": "a7a1825a-73a0-187f-c46f-d0203d66db15.png",
            "phone_number": "",
            "email_address": "224985@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d96d59b3-2d21-4745-bfa2-c89dbf2fb29b"
        },
        {
          "id": 627,
          "person": {
            "id": 627,
            "first_name": "Douglas",
            "last_name": "Argueta",
            "photo_url": "assets/r5a94c65f33d34a23a34b9af0b77e31c3_photo_t.PNG",
            "phone_number": "7143716667",
            "email_address": "dargueta@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1204,
              "name": "Day",
              "contract": {
                "id": 1491,
                "name": "North Wales, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "a153d850-6493-4210-a779-9ec041201f67",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7692,
              "name": "Swing",
              "contract": {
                "id": 5651,
                "name": "Springhouse Plant, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ab16ba13-f165-4726-b827-8d043b7f45d8"
        },
        {
          "id": 66,
          "person": {
            "id": 66,
            "first_name": "Duc",
            "last_name": "Dang",
            "photo_url": "eeb2e012-3346-47af-22f5-67c119f3603f.png",
            "phone_number": "",
            "email_address": "101024@placeholder.com"
          },
          "positions": [
            {
              "id": 1164,
              "name": "Tech, Recycle (Loc 6 WA)",
              "name_abbreviation": "6RECTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "78d541b4-3012-46db-9303-f48dbbaa0afb"
        },
        {
          "id": 12181,
          "person": {
            "id": 12181,
            "first_name": "Duc",
            "last_name": "Trinh",
            "photo_url": "72a7d1df-2090-83bd-77e2-7074a88410ae.png",
            "phone_number": "",
            "email_address": "221767@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "967d9ccc-6e8f-4531-8204-6b5d84dc111a"
        },
        {
          "id": 3129,
          "person": {
            "id": 3129,
            "first_name": "Earnestine",
            "last_name": "Newson",
            "photo_url": "assets/ce1b25cafecb4002836726c358e91150_earn_t.jpg",
            "phone_number": "",
            "email_address": "202234@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d128cba4-749a-452d-8840-295c3baa511c"
        },
        {
          "id": 4533,
          "person": {
            "id": 4533,
            "first_name": "Eddie",
            "last_name": "Johnston",
            "photo_url": "assets/1586482748.5991156_EddieNew_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 5037,
              "name": "Swing",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06ca3413-03ad-423a-9415-02663c017581"
        },
        {
          "id": 1670,
          "person": {
            "id": 1670,
            "first_name": "Edgar",
            "last_name": "Rios Lagos",
            "photo_url": "3eef672f-3a70-5deb-a1a2-1bba22094b80.png",
            "phone_number": "4252879290",
            "email_address": "221642@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "482a2a1e-7fe1-4560-a4c4-96898c4704c9"
        },
        {
          "id": 3165,
          "person": {
            "id": 3165,
            "first_name": "Edith",
            "last_name": "Tejada",
            "photo_url": "845bcb1a-47ce-a7a8-f040-22aa690c1c7e.png",
            "phone_number": "",
            "email_address": "224348@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aeff814d-91ba-4ce3-9e6f-a9278c36a10e"
        },
        {
          "id": 8385,
          "person": {
            "id": 8385,
            "first_name": "Eduardo",
            "last_name": "Guzman",
            "photo_url": "assets/1bb35e688e4f4ab08256763176932af1_EduardoGuzman_t.jpg",
            "phone_number": "",
            "email_address": "215400@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c69dac02-ea65-419e-b027-15005eaef3f6"
        },
        {
          "id": 7329,
          "person": {
            "id": 7329,
            "first_name": "Eduardo",
            "last_name": "Sanchez",
            "photo_url": "assets/36b6a487a09149d0ab27df4dbc507f22_IMG_2009_t.JPG",
            "phone_number": "3143654036",
            "email_address": "esanchez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 74,
              "name": "Account Mgr",
              "name_abbreviation": "NTAMGR"
            }
          ],
          "shifts": [
            {
              "id": 1144,
              "name": "Arden",
              "contract": {
                "id": 2048,
                "name": "Arden Fair Mall (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4ac8e080-e0ad-438c-bd4b-429fab6acc2b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7454,
              "name": "Day",
              "contract": {
                "id": 5639,
                "name": "El Monte (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "38f98250-7014-43d5-8b80-16a98ee2748f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2104,
              "name": "Day",
              "contract": {
                "id": 1324,
                "name": "Illinois",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7177,
              "name": "Day",
              "contract": {
                "id": 5485,
                "name": "Pinnacle Peak (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c6c40b90-fd55-4595-823d-cab984b85a15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7486,
              "name": "Day",
              "contract": {
                "id": 5636,
                "name": "San Bernardino Main (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "fdcf72af-9050-4465-a007-0c4f715773cc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7447,
              "name": "Day",
              "contract": {
                "id": 5491,
                "name": "Shoreview Data Center",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bb785cef-87b8-435e-b5a6-c0cb74728cc9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7514,
              "name": "Day",
              "contract": {
                "id": 5500,
                "name": "Sioux Falls Main",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7277,
              "name": "Day",
              "contract": {
                "id": 5554,
                "name": "Sunset Park (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "a6620956-b404-4fe4-854a-ed0ff498607d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5597,
              "name": "Day",
              "contract": {
                "id": 3337,
                "name": "Tempe Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "e05b0b1e-f4c4-4202-8a5a-7f959cbbb528",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7451,
              "name": "Day",
              "contract": {
                "id": 5543,
                "name": "University Building",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "5727ea88-f532-4365-aff8-6881a98a80d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7172,
              "name": "Day",
              "contract": {
                "id": 5484,
                "name": "WF Home Equity (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "5749b27c-2ef9-4a49-8b4d-9749dab484e6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7276,
              "name": "Day",
              "contract": {
                "id": 5555,
                "name": "William Barnhart Center (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "44f15fca-ac80-4b6c-9bf9-4b602e84ffb9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7226,
              "name": "Day",
              "contract": {
                "id": 5511,
                "name": "3rd - Brannan",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "a8f911c1-11e3-4fa0-8382-2f0a79f6457b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7287,
              "name": "Day",
              "contract": {
                "id": 5566,
                "name": "550 California (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "78c1e2c7-0d04-4da4-834d-f78a80e70012",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7173,
              "name": "Day",
              "contract": {
                "id": 5486,
                "name": "Butano (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "806dc5df-332a-41ea-939a-6131fe8bd94a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7480,
              "name": "Day",
              "contract": {
                "id": 5502,
                "name": "COB (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d75c0c67-9c54-469a-8168-0a35004c4430",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7443,
              "name": "Day",
              "contract": {
                "id": 5483,
                "name": "Continuum (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "655efe44-7c11-454c-86e3-aaeb6600968a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2021,
              "name": "Day",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 566,
              "name": "Night",
              "contract": {
                "id": 1326,
                "name": "Boise (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4e3ee1f9-e6e4-4e01-ab9e-f4bbd35efa6a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            },
            {
              "id": 7236,
              "name": "None",
              "contract": {
                "id": 5553,
                "name": "Warner Ranch (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bf1b65f9-632f-435b-8e7a-17ad26807fbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7210,
              "name": "None",
              "contract": {
                "id": 5515,
                "name": "Palo Alto",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1143,
              "name": "Santa Rosa",
              "contract": {
                "id": 2013,
                "name": "Santa Rosa Main, CA (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "32daae15-b115-43b6-b535-ae77f7fe38b4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7551,
              "name": "Swing",
              "contract": {
                "id": 5652,
                "name": "Huntington Park (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "dac59c29-ebfb-48b0-b24c-e9d2892f4045",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7201,
              "name": "Swing",
              "contract": {
                "id": 5495,
                "name": "McKnight Road",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7203,
              "name": "Swing",
              "contract": {
                "id": 5498,
                "name": "Old Saint Anthony/OSA*",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7487,
              "name": "Swing",
              "contract": {
                "id": 5499,
                "name": "Ridgedale",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7202,
              "name": "Swing",
              "contract": {
                "id": 5497,
                "name": "Central",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7546,
              "name": "Swing",
              "contract": {
                "id": 5532,
                "name": "Columbus Main (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "43e83099-aa43-4e5e-9a3f-70dd39d77047",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7258,
              "name": "Swing",
              "contract": {
                "id": 5551,
                "name": "333 Market (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f7d4a594-a35d-40ca-9396-0f9db82700ff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7215,
              "name": "Swing",
              "contract": {
                "id": 5522,
                "name": "Fremont Center Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ce645c9b-9112-4fe0-86c9-6040179ba04c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7211,
              "name": "Swing",
              "contract": {
                "id": 5523,
                "name": "San Francisco/Head Office (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "98ed1974-2405-49f9-96f9-e1c60e22ef93",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7221,
              "name": "Swing",
              "contract": {
                "id": 5520,
                "name": "San Leandro Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "080cf858-d8ec-4300-9a1a-9f90a2b1c6ca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7474,
              "name": "unassigned",
              "contract": {
                "id": 5572,
                "name": "Headquarters HMHQ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "76aea59d-6532-4759-8887-190df768c9d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 548,
              "name": "Unassigned",
              "contract": {
                "id": 1329,
                "name": "Virginia Beach*",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "02d82a59-236e-4a68-b3c8-d5e05eae546f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 867,
              "name": "Unassigned",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 746,
              "name": "Unassigned",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1273,
              "name": "Unassigned",
              "contract": {
                "id": 2089,
                "name": "Rose Garden Lane (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d53d9a30-6f23-4d3c-a8c7-70b91e7d715e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7435,
              "name": "Unassigned",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 796,
              "name": "Unassigned",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 148,
              "name": "Unassigned",
              "contract": {
                "id": 1321,
                "name": "ICB (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b2b272f8-f205-49f3-94a0-469a8b2407ce",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 712,
              "name": "Unassigned",
              "contract": {
                "id": 1464,
                "name": "Wayzata",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1274,
              "name": "Unassigned",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2012-09-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ea498e8-906c-49ad-9bde-100d49d4ee15"
        },
        {
          "id": 1324,
          "person": {
            "id": 1324,
            "first_name": "Eebla",
            "last_name": "Liban",
            "photo_url": "6edcab9b-5bcd-fa02-7792-5fa6c8126499.png",
            "phone_number": "",
            "email_address": "221659@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f618df4-a4d7-4d76-9cc5-740f68932412"
        },
        {
          "id": 7391,
          "person": {
            "id": 7391,
            "first_name": "Elena",
            "last_name": "Giuliani",
            "photo_url": "cfecb348-b5cd-f04a-05e3-293f310716c0.png",
            "phone_number": "6105841314",
            "email_address": "101685@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "acebb6c5-5799-42c9-bb0e-76c0d951c3b9"
        },
        {
          "id": 8598,
          "person": {
            "id": 8598,
            "first_name": "Elia",
            "last_name": "Leon",
            "photo_url": "9a86dd02-306a-3955-3304-07372c2a3e57.png",
            "phone_number": "",
            "email_address": "216289@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1263,
              "name": "Night",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2016-12-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "568ee8cc-5e13-45e7-a769-273c35d3ee08"
        },
        {
          "id": 12568,
          "person": {
            "id": 12568,
            "first_name": "Elizabeth",
            "last_name": "Rojas",
            "photo_url": "assets/3ae7af38bbe0428597156f3fe4a2c360_IMG_20221108_13590_t.jpg",
            "phone_number": "",
            "email_address": "225888@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80da557a-edf6-498c-8329-53248b7c8123"
        },
        {
          "id": 10077,
          "person": {
            "id": 10077,
            "first_name": "Elizabeth",
            "last_name": "Romero",
            "photo_url": "assets/528395f7c0004b188d697ab8cc5af0b3_20221202_094531_t.jpg",
            "phone_number": "",
            "email_address": "220449@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ad1f3e2-1b78-4957-8594-755316da5541"
        },
        {
          "id": 8045,
          "person": {
            "id": 8045,
            "first_name": "Ella",
            "last_name": "Pickett",
            "photo_url": "assets/0b9826bfcf6a4f5f91591a374d3fb920_Ella_t.jpg",
            "phone_number": "",
            "email_address": "103509@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21895340-4903-4984-aaf7-aefcf07f1c4e"
        },
        {
          "id": 10318,
          "person": {
            "id": 10318,
            "first_name": "Elsa",
            "last_name": "De Leon De La Rosa",
            "photo_url": "ffedd09c-38c1-0781-d518-88fcfdc90ee2.png",
            "phone_number": "",
            "email_address": "226703@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b24dee6b-1134-4c2f-8983-1fa44ae18444"
        },
        {
          "id": 7213,
          "person": {
            "id": 7213,
            "first_name": "Elsides",
            "last_name": "Vasquez",
            "photo_url": "e67c0a0c-943d-d1dd-465b-eca727fab568.png",
            "phone_number": "",
            "email_address": "226701@placeholder.com"
          },
          "positions": [
            {
              "id": 11,
              "name": "Cage Washer",
              "name_abbreviation": "Cage Washer"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "edb9e766-373b-427e-aae7-afc3ef59b784"
        },
        {
          "id": 3632,
          "person": {
            "id": 3632,
            "first_name": "Elva",
            "last_name": "Martinez",
            "photo_url": "37e13355-62d6-cff0-8a10-8eb85bef8a51.png",
            "phone_number": "9086448972",
            "email_address": "127509@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5169,
              "name": "Swing",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-05-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "514880ef-e1f6-4c91-884e-3d97d483f682"
        },
        {
          "id": 6682,
          "person": {
            "id": 6682,
            "first_name": "Enedina",
            "last_name": "Gonzalez",
            "photo_url": "assets/b5de0761dfd5421c827111bda8503619_IMG_20221104_44180_t.jpg",
            "phone_number": "408-903-8006",
            "email_address": "220546@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "291cdee2-0e3c-4dd2-a9c6-a521e7038a74"
        },
        {
          "id": 8335,
          "person": {
            "id": 8335,
            "first_name": "Enes",
            "last_name": "Tirak",
            "photo_url": "5b61339a-90e0-5b43-626e-d65df485a44d.png",
            "phone_number": "4252196521",
            "email_address": "104541@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "013e7f90-72b2-4505-b1a2-73e1a478edcd"
        },
        {
          "id": 4798,
          "person": {
            "id": 4798,
            "first_name": "Enrique",
            "last_name": "Alvarez Guerrero",
            "photo_url": "af69faaa-bd74-8598-3521-fc5c3f16ca72.png",
            "phone_number": "",
            "email_address": "221658@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d1d8f1fa-3347-4c58-8907-4ea0fd6a2b2a"
        },
        {
          "id": 12543,
          "person": {
            "id": 12543,
            "first_name": "Enrique",
            "last_name": "Rivas",
            "photo_url": "fa5a5ef4-0090-f422-f893-26174c0e2d79.png",
            "phone_number": "",
            "email_address": "221940@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d1efb7a1-5ba7-4663-9bb9-6f3d6cb942d0"
        },
        {
          "id": 4548,
          "person": {
            "id": 4548,
            "first_name": "Enriqueta",
            "last_name": "Vergara",
            "photo_url": "userprofile/ylftxtsgvwwmeng8eoe7",
            "phone_number": "",
            "email_address": "203387@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5eaec454-4420-4f28-9100-0900b41bbb52"
        },
        {
          "id": 9436,
          "person": {
            "id": 9436,
            "first_name": "Equileo",
            "last_name": "Santos Martinez",
            "photo_url": "872a8036-5c8b-1828-4629-f2a7f6db099f.png",
            "phone_number": "",
            "email_address": "224339@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "80f157c2-e2dd-4d59-9814-b81f31396af1"
        },
        {
          "id": 9382,
          "person": {
            "id": 9382,
            "first_name": "Erendira",
            "last_name": "Chacon Cesareo",
            "photo_url": "assets/d0098cd33f05465eaa0146b2074c790f_Resized_20221220_101902_t.jpeg",
            "phone_number": "",
            "email_address": "220475@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b2bff06-4ed4-4239-97f4-933e3d9976f3"
        },
        {
          "id": 811,
          "person": {
            "id": 811,
            "first_name": "Eric",
            "last_name": "Angelo",
            "photo_url": "assets/526aa13a7c734aa3bbb97f6e8864b30f_20220301_103718_t.jpg",
            "phone_number": "",
            "email_address": "221844@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7cb3b146-8b84-4b62-b8b1-3967421c2c41"
        },
        {
          "id": 1155,
          "person": {
            "id": 1155,
            "first_name": "Eric",
            "last_name": "McBride",
            "photo_url": "eb59d0c8-911d-8811-8935-d4bf9ab2ff18.png",
            "phone_number": "",
            "email_address": "225603@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-09-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3a4ca05-176b-432b-b1eb-2423eb2bcf8d"
        },
        {
          "id": 11711,
          "person": {
            "id": 11711,
            "first_name": "Ernestina",
            "last_name": "Salazar",
            "photo_url": "14d0f6e6-bce9-0761-6723-013489203d92.png",
            "phone_number": "",
            "email_address": "225076@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-07-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3076bb4d-3c7a-46ec-89e1-5a2c33f63937"
        },
        {
          "id": 7182,
          "person": {
            "id": 7182,
            "first_name": "Esad",
            "last_name": "Dodic",
            "photo_url": "16d7f3a0-17e1-8f73-a3f6-8354899945fd.png",
            "phone_number": "",
            "email_address": "101218@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eae4be54-9664-4637-81e2-1dc128058214"
        },
        {
          "id": 9822,
          "person": {
            "id": 9822,
            "first_name": "Esther",
            "last_name": "Maciel",
            "photo_url": "assets/3be070cdb3184d81a08118ce7736fdd3_IMG_20221201_1386_t.jpg",
            "phone_number": "",
            "email_address": "223253@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-03-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8ee79333-4b48-4972-b1d0-df6c6c61f337"
        },
        {
          "id": 10934,
          "person": {
            "id": 10934,
            "first_name": "Eva",
            "last_name": "Portillo",
            "photo_url": "139fbd9b-9b8b-cb28-cd64-0a8aac2febf5.png",
            "phone_number": "",
            "email_address": "224916@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7537ed07-bf65-4d09-8457-8d580f4346d1"
        },
        {
          "id": 9652,
          "person": {
            "id": 9652,
            "first_name": "Evaristo",
            "last_name": "Zavala",
            "photo_url": "13d38b07-df6c-8246-bd5e-86e3876889ca.png",
            "phone_number": "2679390523",
            "email_address": "ezavala@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2010-08-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "678ebe65-6813-4591-be8b-94936fc59a3c"
        },
        {
          "id": 4611,
          "person": {
            "id": 4611,
            "first_name": "Everett",
            "last_name": "Centerwall",
            "photo_url": "b5d4f6df-eb83-abe6-9961-c4e80dfb0fbb.png",
            "phone_number": "",
            "email_address": "218467@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "05f7ecb0-ab0e-4ca7-b0ef-67c6c4b7326d"
        },
        {
          "id": 10028,
          "person": {
            "id": 10028,
            "first_name": "Everlides",
            "last_name": "Galofre",
            "photo_url": "267ec949-e79b-23dd-a6f4-c2ceae9895df.png",
            "phone_number": "2673472470",
            "email_address": "egalofre@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "61da1557-4ce0-4e76-9368-58938f0c5713"
        },
        {
          "id": 8000,
          "person": {
            "id": 8000,
            "first_name": "Fabio",
            "last_name": "Osorio",
            "photo_url": "bc9e6087-0c02-7b82-277f-fdd0c8318f56.png",
            "phone_number": "4253444513",
            "email_address": "225896@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d60c8ab-8e3b-4200-ada0-8dc592e93c59"
        },
        {
          "id": 9179,
          "person": {
            "id": 9179,
            "first_name": "Fanny",
            "last_name": "Meeker",
            "photo_url": "e4382979-efe3-0613-770f-74f7f061a95d.png",
            "phone_number": "570 259 5798",
            "email_address": "219094@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1114,
              "name": "Day",
              "contract": {
                "id": 1486,
                "name": "Cherokee, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-05-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "22144307-9c16-4cea-a728-9d926ecedb1c"
        },
        {
          "id": 5066,
          "person": {
            "id": 5066,
            "first_name": "Fanny",
            "last_name": "Viznay Tenezaca",
            "photo_url": "userprofile/zuknzmstkymofpvk7015",
            "phone_number": "",
            "email_address": "203399@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b9b08116-2a52-4a42-a9e8-c18c5f3a3188"
        },
        {
          "id": 8493,
          "person": {
            "id": 8493,
            "first_name": "Fardossa",
            "last_name": "Muhumed",
            "photo_url": "a001e400-a3ae-4aa3-8935-1cf1724e010b.png",
            "phone_number": "",
            "email_address": "224052@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-05-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dc63af55-4666-4462-96c5-e9ce1d1998e5"
        },
        {
          "id": 4333,
          "person": {
            "id": 4333,
            "first_name": "Felix",
            "last_name": "Owusu",
            "photo_url": "assets/8c2790e21dd945dfbce8dd730988e118_thumbnail_IMG_20220725_154104_t.jpg",
            "phone_number": "",
            "email_address": "103335@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f3e9085e-6b67-4458-b31d-93a6048b6f98"
        },
        {
          "id": 5352,
          "person": {
            "id": 5352,
            "first_name": "Feng",
            "last_name": "Chen",
            "photo_url": "7002ce1e-a272-9a43-e4e0-c118147ac266.png",
            "phone_number": "",
            "email_address": "221776@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1bcea052-2cd9-4485-9ca3-e70acc2e1d21"
        },
        {
          "id": 4708,
          "person": {
            "id": 4708,
            "first_name": "Fengnong",
            "last_name": "Huang",
            "photo_url": "e0b417cc-0ca0-6e5b-bf36-1cbc3c10b0fb.png",
            "phone_number": "",
            "email_address": "226665@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "765d6267-9233-4de1-b4b1-c0eea081abb9"
        },
        {
          "id": 1162,
          "person": {
            "id": 1162,
            "first_name": "Fernando",
            "last_name": "Sanchez",
            "photo_url": "assets/34e792c7cc3c4bebab1328a809b8638d_20221116_110455_t.jpg",
            "phone_number": "",
            "email_address": "fsanchez1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7651ba89-af11-4d79-b254-4d93e2558983"
        },
        {
          "id": 11673,
          "person": {
            "id": 11673,
            "first_name": "Florin",
            "last_name": "Zugrav",
            "photo_url": "ccb16a9a-6885-3913-4d9b-25d3590c6cf8.png",
            "phone_number": "3176176073",
            "email_address": "fzugrav@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eadf5fff-7743-4b1e-90dc-71c4058cb928"
        },
        {
          "id": 642,
          "person": {
            "id": 642,
            "first_name": "Florinda",
            "last_name": "Guerrero",
            "photo_url": "047206b2-1d84-bc6e-0f81-c2399504c3db.png",
            "phone_number": "",
            "email_address": "226733@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "811c1500-aa28-4722-affb-954beb851f28"
        },
        {
          "id": 2872,
          "person": {
            "id": 2872,
            "first_name": "Francelis",
            "last_name": "Perez",
            "photo_url": "assets/1594232482.4996624_FrancelisPerezABPhoto_t.PNG",
            "phone_number": "",
            "email_address": "202441@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 2454,
              "name": "Swing",
              "contract": {
                "id": 1198,
                "name": "Waukegan, IL",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "44e6bff3-a8c5-4f5e-ba02-6cf88c9586f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-03-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "78d057c6-e265-43b8-b1e9-18a1c2339f7b"
        },
        {
          "id": 4387,
          "person": {
            "id": 4387,
            "first_name": "Frances",
            "last_name": "Valdez",
            "photo_url": "assets/1253806cb5b54a289ad9bd18c00079fe_IMG_20221104_43643_t.jpg",
            "phone_number": "",
            "email_address": "225886@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fae6f39a-fa51-454c-befb-8c876ecea63f"
        },
        {
          "id": 7944,
          "person": {
            "id": 7944,
            "first_name": "Francia",
            "last_name": "Garcia",
            "photo_url": "userprofile/yesmtjfwyh0unvgl05eu",
            "phone_number": "",
            "email_address": "210998@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3efc8da7-0211-401b-a295-718c331078bb"
        },
        {
          "id": 1265,
          "person": {
            "id": 1265,
            "first_name": "Francisca",
            "last_name": "Morales",
            "photo_url": "4f0a75c9-95ae-156c-1c64-778d406bff93.png",
            "phone_number": "",
            "email_address": "122772@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc417b75-35bf-440e-b849-539f7926917b"
        },
        {
          "id": 9466,
          "person": {
            "id": 9466,
            "first_name": "Francisco",
            "last_name": "Avalos",
            "photo_url": "assets/9a88ca12f9ee4a7fa7dabb03db45f24d_IMG_20221104_59298_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b545324b-2209-4218-b25c-da60b21fcc03"
        },
        {
          "id": 3668,
          "person": {
            "id": 3668,
            "first_name": "Francisco",
            "last_name": "Espinal",
            "photo_url": "df524a4e-c27e-d4b8-5c16-4930db85551c.png",
            "phone_number": "",
            "email_address": "226704@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e0d6f73-75c3-4216-a08a-64dfbbe27a99"
        },
        {
          "id": 5009,
          "person": {
            "id": 5009,
            "first_name": "Francisco",
            "last_name": "Espinoza",
            "photo_url": "4e528a46-8372-977f-bbd7-5f106d800e8f.png",
            "phone_number": "",
            "email_address": "192727@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d9fe4e14-ab60-48cb-9514-45d23326d7a3"
        },
        {
          "id": 7673,
          "person": {
            "id": 7673,
            "first_name": "Francisco",
            "last_name": "Figueroa",
            "photo_url": "userprofile/dsxieccodpysjkxggt5y",
            "phone_number": "",
            "email_address": "202303@placeholder.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc239471-d956-4ee7-88df-cc2ca1d75954"
        },
        {
          "id": 7697,
          "person": {
            "id": 7697,
            "first_name": "Francisco",
            "last_name": "Vargas",
            "photo_url": "assets/9af52d445ef94d0d80650f064df04bef_2_t.jpg",
            "phone_number": "",
            "email_address": "202443@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4e67ee3a-e47b-44ec-9361-ef86bd83bef2"
        },
        {
          "id": 5744,
          "person": {
            "id": 5744,
            "first_name": "Franklin",
            "last_name": "Martinez",
            "photo_url": "f0f462bb-7e2b-ad0e-54a0-fbb44ac7e6c0.png",
            "phone_number": "(267)401-7000",
            "email_address": "192408@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-09-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "daa2032c-8ab4-415e-83fd-a4ef3c5bcf34"
        },
        {
          "id": 11496,
          "person": {
            "id": 11496,
            "first_name": "Gabriela",
            "last_name": "Medina",
            "photo_url": "10ab2f39-8437-c2c5-14f6-52ba757eae46.png",
            "phone_number": "",
            "email_address": "222482@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "62488553-27fa-4dfe-8c4f-21af2b28f16a"
        },
        {
          "id": 6362,
          "person": {
            "id": 6362,
            "first_name": "Gebre",
            "last_name": "Tamaso",
            "photo_url": "fc796d03-1cd6-0cef-53b3-8454abb842dc.png",
            "phone_number": "",
            "email_address": "221791@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c6f3b4ce-ab05-40b2-bb85-778a6e5d0ed0"
        },
        {
          "id": 11222,
          "person": {
            "id": 11222,
            "first_name": "George",
            "last_name": "Lewis",
            "photo_url": "61598b86-9543-a059-4e4c-cfd7ad505063.png",
            "phone_number": "",
            "email_address": "218198@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72ce3ffc-6dd0-417a-a2b4-cba2106c5b02"
        },
        {
          "id": 11521,
          "person": {
            "id": 11521,
            "first_name": "Georgina",
            "last_name": "Sanchez",
            "photo_url": "assets/a97ca6a087eb45c4af103890b706c84d_20221202_094539_t.jpg",
            "phone_number": "",
            "email_address": "220430@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "165a9dc7-40ae-409c-8c2c-400df858fcd3"
        },
        {
          "id": 12738,
          "person": {
            "id": 12738,
            "first_name": "Gerald",
            "last_name": "Towle",
            "photo_url": "526f7478-a197-eb28-eafa-9cd53ff3b7f7.png",
            "phone_number": "",
            "email_address": "218344@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2628,
              "name": "On-Call",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2a59f71-9530-4b52-a79f-1fc0a2980b18"
        },
        {
          "id": 1925,
          "person": {
            "id": 1925,
            "first_name": "Gilberto",
            "last_name": "Banuelos",
            "photo_url": "assets/9432a0f129f844be8467b6be5be0954f_Resized_Resized_20221203_154259001_t.jpg",
            "phone_number": "",
            "email_address": "220359@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2141,
              "name": "Night",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d5fceb81-9265-4c06-958f-3f6595ded7ce"
        },
        {
          "id": 12262,
          "person": {
            "id": 12262,
            "first_name": "Gilberto",
            "last_name": "Ornelas",
            "photo_url": "assets/2eecc31a93334581b2767e56d2d5a954_20200508_103350_t.jpg",
            "phone_number": "",
            "email_address": "gornelas@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57519602-faa9-45e4-afe0-64e065918f44"
        },
        {
          "id": 9361,
          "person": {
            "id": 9361,
            "first_name": "Gina",
            "last_name": "Larenas",
            "photo_url": "b9d30621-06e2-2d7b-e716-02d93d2f7278.png",
            "phone_number": "",
            "email_address": "211969@placeholder.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 3177,
              "name": "Swing",
              "contract": {
                "id": 2664,
                "name": "Jacksonville FL",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "555ef301-d23c-4e70-bba4-2d307cfaaaed",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2016-01-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f89b8f1c-967c-41b4-8cee-f2152655b174"
        },
        {
          "id": 7571,
          "person": {
            "id": 7571,
            "first_name": "Giovanni",
            "last_name": "Lopez",
            "photo_url": "c74a713f-a2ed-2683-03e9-b4cc7a1f63b6.png",
            "phone_number": "(484)538-0400",
            "email_address": "102519@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9ab923e-560b-447d-8ddf-0715c5fc2704"
        },
        {
          "id": 4773,
          "person": {
            "id": 4773,
            "first_name": "Gladys",
            "last_name": "Calle Sepulveda",
            "photo_url": "64954251-9c24-afcb-5b69-51b70dcc90e5.png",
            "phone_number": "",
            "email_address": "224346@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "739bfd82-0254-4a6c-9e3c-cbf8dc9db8d3"
        },
        {
          "id": 4505,
          "person": {
            "id": 4505,
            "first_name": "Gloria",
            "last_name": "Castro",
            "photo_url": "",
            "phone_number": "6093315445",
            "email_address": "gcastro@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 2026,
              "name": "Day",
              "contract": {
                "id": 1147,
                "name": "Short Hills",
                "customer_id": 61,
                "customer_name": "Dun & Bradstreet",
                "uuid": "f0f5a20d-022c-4b6b-8a58-0ec9a7441482",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7916,
              "name": "Day",
              "contract": {
                "id": 5849,
                "name": "10 Exchange Place, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "d5f72890-79e6-4d67-8c18-2f8b1a846086",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1977,
              "name": "Day",
              "contract": {
                "id": 1119,
                "name": "Flemington, NJ",
                "customer_id": 48,
                "customer_name": "3M",
                "uuid": "686275cb-42bb-483b-b160-6f509c584ba1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1923,
              "name": "Day",
              "contract": {
                "id": 1685,
                "name": "REG503",
                "customer_id": 200,
                "customer_name": "East",
                "uuid": "942c84c8-29af-44fe-962b-8ba1dcd08e16",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-03-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f495edb-5a0b-4624-ab89-e440620651a7"
        },
        {
          "id": 9680,
          "person": {
            "id": 9680,
            "first_name": "Gloria",
            "last_name": "Cordoba",
            "photo_url": "assets/b2275f9a1d98498bae5a37eda2b9422b_thumbnail_IMG_20220722_165639_t.jpg",
            "phone_number": "",
            "email_address": "101438@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2010-04-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad6959a0-09b8-4ee6-a114-50d400473a46"
        },
        {
          "id": 9961,
          "person": {
            "id": 9961,
            "first_name": "Gloria",
            "last_name": "Escobar",
            "photo_url": "262e2590-fc46-ca48-934a-427239b9f832.png",
            "phone_number": "",
            "email_address": "224327@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2727dc44-4374-4701-b5ab-2a7abad5124c"
        },
        {
          "id": 7413,
          "person": {
            "id": 7413,
            "first_name": "Gloria",
            "last_name": "Espinoza",
            "photo_url": "assets/3896df8783d9449aa934fab5a1d922d4_IMG_0047_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f3c8e03-35fc-4cf6-aa72-3aafc188ed0d"
        },
        {
          "id": 227,
          "person": {
            "id": 227,
            "first_name": "Gloria",
            "last_name": "Landaverde Hernandez",
            "photo_url": "8460945a-42c4-7158-a8b0-d3ea4038275c.png",
            "phone_number": "",
            "email_address": "224350@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "491ae95e-72f3-40f8-aaa7-78158c868b79"
        },
        {
          "id": 1947,
          "person": {
            "id": 1947,
            "first_name": "Gloria",
            "last_name": "Mason",
            "photo_url": "assets/1588623611.2200363_20190220_144147_t.jpg",
            "phone_number": "9013993238",
            "email_address": "GMason@sbmcorp.com"
          },
          "positions": [
            {
              "id": 14,
              "name": "Mgr, Custodial",
              "name_abbreviation": "CUSMGR"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-11-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "63b7b989-2f99-498e-a7eb-88b6d3dc05c8"
        },
        {
          "id": 8927,
          "person": {
            "id": 8927,
            "first_name": "Graciela",
            "last_name": "Mendoza-Cuevas",
            "photo_url": "assets/f29dde4e648d4deebcfcdff99c4d9cbc_IMG_20221104_41419_t.jpg",
            "phone_number": "",
            "email_address": "116651@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dfcdff1a-fd36-4b5e-b15e-887b5729719b"
        },
        {
          "id": 2087,
          "person": {
            "id": 2087,
            "first_name": "Grant",
            "last_name": "Perry",
            "photo_url": "0951f6a2-3728-46e3-a78c-b8f4f130eafc.png",
            "phone_number": "",
            "email_address": "221677@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de76e287-685a-426e-83fb-3088f2de4864"
        },
        {
          "id": 2222,
          "person": {
            "id": 2222,
            "first_name": "Gregory",
            "last_name": "Hayward",
            "photo_url": "1e815328-e036-b614-63ae-2163721f4015.png",
            "phone_number": "2156929913",
            "email_address": "ghayward@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-02-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b420bbd6-5ffd-4d9a-8885-b87fccbc2895"
        },
        {
          "id": 9368,
          "person": {
            "id": 9368,
            "first_name": "Griselda",
            "last_name": "Cobian",
            "photo_url": "ec8a2225-cb30-60e1-8145-658f396e56ec.png",
            "phone_number": "",
            "email_address": "220259@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-08-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7584dbe-23e2-49f2-bdc4-b93941a61b1a"
        },
        {
          "id": 3867,
          "person": {
            "id": 3867,
            "first_name": "Griset",
            "last_name": "Tejeda De Gonzalez",
            "photo_url": "884c2c9e-f9ef-8951-84fc-6adc90b48da5.png",
            "phone_number": "",
            "email_address": "226755@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea388e86-5e03-4e32-8032-b4146cd95446"
        },
        {
          "id": 6233,
          "person": {
            "id": 6233,
            "first_name": "Guillermina",
            "last_name": "Tejeda",
            "photo_url": "assets/304a7fc13b3e4a86bc74649e494c2aae_IMG_20221104_42213_t.jpg",
            "phone_number": "",
            "email_address": "222025@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91cd905a-a8b3-41e0-9b30-c3d13c641e18"
        },
        {
          "id": 9409,
          "person": {
            "id": 9409,
            "first_name": "Guillermo",
            "last_name": "Reyes",
            "photo_url": "assets/d3ca4a584bef44c5b4af9bc11282df0f_IMG_20221104_42500_t.jpg",
            "phone_number": "",
            "email_address": "222014@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f9196e2e-0716-441c-aa48-756cc8f7faae"
        },
        {
          "id": 12584,
          "person": {
            "id": 12584,
            "first_name": "Gustavo",
            "last_name": "Valdez Valdez",
            "photo_url": "f1e109b5-cfdd-4aab-25c8-47af270d3a97.png",
            "phone_number": "",
            "email_address": "226702@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "52496183-f5e3-49ae-91d7-14c6e37eb298"
        },
        {
          "id": 7532,
          "person": {
            "id": 7532,
            "first_name": "Ha",
            "last_name": "Le",
            "photo_url": "d7c6dac5-e635-b90e-0fb9-6a0a23369e18.png",
            "phone_number": "",
            "email_address": "102376@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27a4238e-5930-4224-a4b7-95f4ec73f588"
        },
        {
          "id": 58544,
          "person": {
            "id": 58008,
            "first_name": "Hang",
            "last_name": "Nguyen",
            "photo_url": "assets/rdbe7f2f493c24d19844916123bb9092c_HangN._t.jpg",
            "phone_number": "4253441075",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "79fba796-0927-46e3-89b9-662d534374a5"
        },
        {
          "id": 6649,
          "person": {
            "id": 6649,
            "first_name": "Hanh",
            "last_name": "Nguyen",
            "photo_url": "876ad8d1-d60b-32a9-9d2e-c6981e945bcb.png",
            "phone_number": "",
            "email_address": "226099@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "340652df-8620-4253-8b0e-ea0551f1947c"
        },
        {
          "id": 117,
          "person": {
            "id": 117,
            "first_name": "Harold",
            "last_name": "Middleton",
            "photo_url": "assets/160731d45e0c46ccbe01fac06fa71c67_Harold_t.jpg",
            "phone_number": "",
            "email_address": "196931@placeholder.com"
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 953,
              "name": "Night",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2012-11-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "211e72f5-ceb3-4096-b866-55df3f961369"
        },
        {
          "id": 9860,
          "person": {
            "id": 9860,
            "first_name": "Hasan",
            "last_name": "Mehinovic",
            "photo_url": "7b1519f5-1769-7a3b-186c-e6fea3e900c4.png",
            "phone_number": "4257770930",
            "email_address": "102853@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3c179a82-c608-4c41-97f8-37da42e208a7"
        },
        {
          "id": 6226,
          "person": {
            "id": 6226,
            "first_name": "Haydee",
            "last_name": "Quinones",
            "photo_url": "2d05ac7c-7509-4895-9312-c40cbf98b8c8.png",
            "phone_number": "",
            "email_address": "226737@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8078a598-7eae-4a5e-90d8-857d69eeab62"
        },
        {
          "id": 9792,
          "person": {
            "id": 9792,
            "first_name": "Helen",
            "last_name": "Buengener",
            "photo_url": "assets/73695989edd44c1ab4ceda4591c9d077_4SFemale_t.jpg",
            "phone_number": "2673479332",
            "email_address": "hbuengener@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2912,
              "name": "Soft Service Account Manager",
              "name_abbreviation": "SSACMG"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2012-03-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "71ce4107-1c65-4d9f-833e-21ae765ac5ca"
        },
        {
          "id": 7441,
          "person": {
            "id": 7441,
            "first_name": "Henry",
            "last_name": "Ortega",
            "photo_url": "78d90f77-083e-66d9-d55a-9b4bcfe00e25.png",
            "phone_number": "",
            "email_address": "103280@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-04-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d19bcb12-b4b3-4126-851d-359f76752277"
        },
        {
          "id": 10460,
          "person": {
            "id": 10460,
            "first_name": "Henry",
            "last_name": "Tejeda",
            "photo_url": "2832d358-92cf-6dbc-9c2c-a6876880c752.png",
            "phone_number": "",
            "email_address": "226698@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "12c179a5-91a7-4f49-9398-c5ca3d661393"
        },
        {
          "id": 6035,
          "person": {
            "id": 6035,
            "first_name": "Hernan",
            "last_name": "Ponte",
            "photo_url": "assets/ba27267f496745bc80532ce069b560df_Hernan_t.jpeg",
            "phone_number": "3173535934",
            "email_address": "219309@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-06-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae7982e4-2284-4163-b06e-2793156f8482"
        },
        {
          "id": 6659,
          "person": {
            "id": 6659,
            "first_name": "Hien",
            "last_name": "Tran",
            "photo_url": "cb64c563-b7ef-7ce5-3639-688457df7b31.png",
            "phone_number": "",
            "email_address": "104612@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "02cf6588-5228-442a-ae71-e7460c868472"
        },
        {
          "id": 2845,
          "person": {
            "id": 2845,
            "first_name": "Hiep",
            "last_name": "Nguyen",
            "photo_url": "30c36422-288e-e437-014b-bbd0d683f008.png",
            "phone_number": "",
            "email_address": "103178@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37d166ee-725d-4bf0-93c5-8b652c885430"
        },
        {
          "id": 196,
          "person": {
            "id": 196,
            "first_name": "Hieu",
            "last_name": "Nguyen",
            "photo_url": "6842d0e6-dd8b-5d51-d737-769265ae6296.png",
            "phone_number": "4252454089",
            "email_address": "103174@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "669fe8f5-2204-4dbe-aa3d-a33de505770c"
        },
        {
          "id": 12042,
          "person": {
            "id": 12042,
            "first_name": "Hieu",
            "last_name": "Nguyen",
            "photo_url": "57dff46f-c96a-0030-b74f-7bc3bae53dea.png",
            "phone_number": "",
            "email_address": "103160@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "597b6b77-41c6-482e-8741-784c73d4cb96"
        },
        {
          "id": 6646,
          "person": {
            "id": 6646,
            "first_name": "Hilary",
            "last_name": "Niess",
            "photo_url": "bcaebe2a-9a06-bab7-8703-8b2670823d08.png",
            "phone_number": "",
            "email_address": "221814@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40798615-81c3-4273-ba8b-831f75e048a2"
        },
        {
          "id": 10038,
          "person": {
            "id": 10038,
            "first_name": "Hilda",
            "last_name": "Moran",
            "photo_url": "assets/efcf84c044664ab1a18c12d504926e52_IMG_20221104_44455_t.jpg",
            "phone_number": "408-307-6385",
            "email_address": "220569@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f4013f8f-a485-4c4c-9d1a-c16c9a52a2f6"
        },
        {
          "id": 4017,
          "person": {
            "id": 4017,
            "first_name": "Hirut",
            "last_name": "Abrah",
            "photo_url": "131df9d4-4b21-473e-99d2-f6bd38097e0a.png",
            "phone_number": "",
            "email_address": "225708@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "15b36fd3-cae6-49ad-8302-13503537b1ca"
        },
        {
          "id": 6199,
          "person": {
            "id": 6199,
            "first_name": "Hoa",
            "last_name": "Do",
            "photo_url": "ad0c9c8a-e822-53d3-30f7-0beb8b87d873.png",
            "phone_number": "",
            "email_address": "224754@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "457b6146-8057-457f-842a-5c0828a11c03"
        },
        {
          "id": 2677,
          "person": {
            "id": 2677,
            "first_name": "Hoa",
            "last_name": "Duong",
            "photo_url": "2fe7cf8b-3d99-8f1a-8c9a-0cfda4e03166.png",
            "phone_number": "4255294790",
            "email_address": "101260@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2a877da1-4bdc-41b3-b421-009d0d7391ef"
        },
        {
          "id": 7246,
          "person": {
            "id": 7246,
            "first_name": "Hoa",
            "last_name": "Liu",
            "photo_url": "3b2ee66f-59c1-2e20-b320-2d313e96ddc5.png",
            "phone_number": "4253829731",
            "email_address": "102466@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38715c1e-7d78-475f-b9f7-5bf5b71218dc"
        },
        {
          "id": 4985,
          "person": {
            "id": 4985,
            "first_name": "Homer",
            "last_name": "Chandler",
            "photo_url": "assets/bcdca6bb7cc4449aa68f2eb7fa46a66b_Homer_t.jpg",
            "phone_number": "",
            "email_address": "100813@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42e1c2de-243f-44ea-896d-690aaaa5912c"
        },
        {
          "id": 8495,
          "person": {
            "id": 8495,
            "first_name": "Hong",
            "last_name": "Diep",
            "photo_url": "assets/1588834480.3713224_hONGDIEP_t.jpg",
            "phone_number": "4253459028",
            "email_address": "101200@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ba8367d-df19-455e-97ed-8f949fba73e9"
        },
        {
          "id": 3322,
          "person": {
            "id": 3322,
            "first_name": "Honoria",
            "last_name": "Torres",
            "photo_url": "65ab55c7-c711-2f8c-718b-79a65210edc9.png",
            "phone_number": "",
            "email_address": "222988@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f30f0c8-c70f-4721-a89c-9204abde7604"
        },
        {
          "id": 6842,
          "person": {
            "id": 6842,
            "first_name": "Howard",
            "last_name": "Malcom",
            "photo_url": "assets/1647c2989dea43fd94d0a156cb0247dc_thumbnail_IMG_20220721_164016_t.jpg",
            "phone_number": "2159243928",
            "email_address": "102633@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0a15bf1e-96d6-472a-b73d-3b36b2a73832"
        },
        {
          "id": 1812,
          "person": {
            "id": 1812,
            "first_name": "Howard",
            "last_name": "Wartnik",
            "photo_url": "assets/e9af444c692f4c6095732489dd9cadd3_IMG_7115_t.JPG",
            "phone_number": "",
            "email_address": "221919@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "976805c3-5ee7-40a4-a6e6-4e22840a2b7c"
        },
        {
          "id": 6796,
          "person": {
            "id": 6796,
            "first_name": "Huan",
            "last_name": "Tran",
            "photo_url": "assets/c267a50bd44140609cf11d3358c6e912_20211005_204058_t.jpg",
            "phone_number": "",
            "email_address": "226404@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "558f8d47-5332-49be-b134-8368d4063a4f"
        },
        {
          "id": 555,
          "person": {
            "id": 555,
            "first_name": "Hue",
            "last_name": "Lai",
            "photo_url": "fda7c07d-c52a-9717-474f-89014babbf92.png",
            "phone_number": "",
            "email_address": "102350@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8f81d974-2d25-4d8a-90ce-c7387a699ed7"
        },
        {
          "id": 11194,
          "person": {
            "id": 11194,
            "first_name": "Humberto",
            "last_name": "Torres Mendoza",
            "photo_url": "assets/e3c22d7b42cb4afaa282e7c7543a2131_Humberto_t.jpg",
            "phone_number": "3179542486",
            "email_address": "hmendoza@sbmcorp.com"
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aaa0bf71-4de1-43d7-9510-b6b6f18574e3"
        },
        {
          "id": 4171,
          "person": {
            "id": 4171,
            "first_name": "Hun",
            "last_name": "Kim",
            "photo_url": "975a9fea-5da9-f1bb-e262-9a314d29ec8d.png",
            "phone_number": "",
            "email_address": "127493@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "62c3c6f8-449d-4d41-aef8-c2ac537328fc"
        },
        {
          "id": 6255,
          "person": {
            "id": 6255,
            "first_name": "Hung",
            "last_name": "Diep",
            "photo_url": "242a0459-2eb3-8af2-7326-2a32cabc7cf8.png",
            "phone_number": "4253829408",
            "email_address": "221746@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc7fa61f-caed-455c-9b7f-9abba0ab2653"
        },
        {
          "id": 8337,
          "person": {
            "id": 8337,
            "first_name": "Hung",
            "last_name": "Hoang",
            "photo_url": "7e369868-8877-4156-199e-efe099440017.png",
            "phone_number": "",
            "email_address": "102052@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac34bd6f-6ff3-4aa7-aea9-e643b8aa6207"
        },
        {
          "id": 1206,
          "person": {
            "id": 1206,
            "first_name": "Huy",
            "last_name": "Tran",
            "photo_url": "99a93109-ae79-38b4-0f96-fa12bc338658.png",
            "phone_number": "",
            "email_address": "225641@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-09-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d6ddcfa3-0653-44b2-b7e1-ae0c3168f338"
        },
        {
          "id": 2108,
          "person": {
            "id": 2108,
            "first_name": "Ibrahim",
            "last_name": "Ismaeil",
            "photo_url": "bbd6789f-17e4-fb68-9ee3-b0657b09f5e4.png",
            "phone_number": "6463463254",
            "email_address": "iismaeil@sbmcorp.com"
          },
          "positions": [
            {
              "id": 363,
              "name": "Supervisor",
              "name_abbreviation": "Spvsr"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5d1d7cd3-c0a4-4cb8-93fa-d2091d9083d7"
        },
        {
          "id": 6319,
          "person": {
            "id": 6319,
            "first_name": "Ignacio",
            "last_name": "Martinez",
            "photo_url": "assets/1592247103.9585752_20200615_075413_resized_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1748ab14-30bd-4065-be11-44b699f010b3"
        },
        {
          "id": 7925,
          "person": {
            "id": 7925,
            "first_name": "Iliana",
            "last_name": "Aquino Mejia",
            "photo_url": "5e9ea8d3-799a-3230-748d-8d3ddb8e403a.png",
            "phone_number": "",
            "email_address": "225806@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2021,
              "name": "Day",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3a69431-bd26-45b5-97ee-b27385ab3cbf"
        },
        {
          "id": 62576,
          "person": {
            "id": 62040,
            "first_name": "Ingrid",
            "last_name": "Bucek",
            "photo_url": "assets/32ab8b6ab3a3434ab1241b5d72d710e5_20220330_081124_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6bdf363b-73b8-4aab-b0a9-9ecffe331756"
        },
        {
          "id": 7016,
          "person": {
            "id": 7016,
            "first_name": "Irma",
            "last_name": "Fajardo",
            "photo_url": "9cb442af-050f-4b11-a3d7-e78fa63003e2.png",
            "phone_number": "",
            "email_address": "225805@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 2021,
              "name": "Day",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8aaa78c4-b3f9-414c-8671-d0bd96956464"
        },
        {
          "id": 7117,
          "person": {
            "id": 7117,
            "first_name": "Isela",
            "last_name": "Morales Dionicio",
            "photo_url": "c8556caf-4e64-720a-489f-6597527d2f35.png",
            "phone_number": "",
            "email_address": "221920@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8dea2118-74eb-48d2-9745-36a9c7188687"
        },
        {
          "id": 6408,
          "person": {
            "id": 6408,
            "first_name": "Ismael",
            "last_name": "Vazquez Jimenez",
            "photo_url": "assets/af3d30e0e7224df3b68c77a9803bcdf1_me001_t.JPG",
            "phone_number": "2132940200",
            "email_address": "ijimenez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 8,
              "name": "Area Mgr",
              "name_abbreviation": "AREMGR"
            }
          ],
          "shifts": [
            {
              "id": 7454,
              "name": "Day",
              "contract": {
                "id": 5639,
                "name": "El Monte (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "38f98250-7014-43d5-8b80-16a98ee2748f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7486,
              "name": "Day",
              "contract": {
                "id": 5636,
                "name": "San Bernardino Main (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "fdcf72af-9050-4465-a007-0c4f715773cc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7551,
              "name": "Swing",
              "contract": {
                "id": 5652,
                "name": "Huntington Park (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "dac59c29-ebfb-48b0-b24c-e9d2892f4045",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7242,
              "name": "Swing",
              "contract": {
                "id": 5553,
                "name": "Warner Ranch (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bf1b65f9-632f-435b-8e7a-17ad26807fbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 9231,
              "name": "Unassigned",
              "contract": {
                "id": 5531,
                "name": "Las Vegas Operations Center (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "59a1084c-5eb2-48ef-bd4b-151f59a727df",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9230,
              "name": "Unassigned",
              "contract": {
                "id": 5653,
                "name": "Fresno Cash Vault (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "048ad58a-d924-466b-a205-575cc2ec91ae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9229,
              "name": "Unassigned",
              "contract": {
                "id": 5655,
                "name": "Brentwood Office - UCBRC (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "71b18377-81b7-4acc-8d81-0b31cd584fc7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9298,
              "name": "Unassigned",
              "contract": {
                "id": 5657,
                "name": "Santa Monica (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "9fb13187-6432-419b-a9ea-39fa48fee2e7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9297,
              "name": "Unassigned",
              "contract": {
                "id": 5512,
                "name": "Stockdale, CA (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "d29601c5-c082-4f2e-8406-ee7ac45e0903",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9295,
              "name": "Unassigned",
              "contract": {
                "id": 5536,
                "name": "Rainbow Sunset (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "bc91fb5d-342e-472f-810c-325fd2099636",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9294,
              "name": "Unassigned",
              "contract": {
                "id": 5525,
                "name": "Main Street Promenade, CA (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "860e989c-2592-4c65-b3d3-4d50a563ec93",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9293,
              "name": "Unassigned",
              "contract": {
                "id": 5656,
                "name": "Los Angeles Cash Vault (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "10297d83-39ff-44d2-8463-246944bce406",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 9296,
              "name": "Unassigned",
              "contract": {
                "id": 5512,
                "name": "Stockdale, CA (JLL)",
                "customer_id": 1649,
                "customer_name": "Wells Fargo-Sub",
                "uuid": "d29601c5-c082-4f2e-8406-ee7ac45e0903",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2014-11-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "82798ce0-7637-490a-a7d3-92b36237ab67"
        },
        {
          "id": 1706,
          "person": {
            "id": 1706,
            "first_name": "Ivelisse",
            "last_name": "Contreras",
            "photo_url": "3d39b095-c08b-13f9-05d8-2d0bbb461f1e.png",
            "phone_number": "",
            "email_address": "224367@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b860fc8-5a24-49d4-9022-e961bb6e1c6b"
        },
        {
          "id": 8542,
          "person": {
            "id": 8542,
            "first_name": "Jack",
            "last_name": "Davis",
            "photo_url": "65ff1cc4-664f-28bd-1b76-254364ee471d.png",
            "phone_number": "",
            "email_address": "218190@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e20412e5-7a5a-4203-ae9e-d8e8ca4e718e"
        },
        {
          "id": 7555,
          "person": {
            "id": 7555,
            "first_name": "Jacob",
            "last_name": "Stubbs",
            "photo_url": "userprofile/oluhfrlklgftphbvz2xk",
            "phone_number": "",
            "email_address": "202136@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "72a4158c-8d66-406f-917a-d0e2947df541"
        },
        {
          "id": 12270,
          "person": {
            "id": 12270,
            "first_name": "Jacqueline",
            "last_name": "Torres",
            "photo_url": "73211b36-cd4d-b8b2-bba1-bc0b57ba0e42.png",
            "phone_number": "2678270617",
            "email_address": "jtorres@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e82cb3d8-9b88-4855-b872-141176461908"
        },
        {
          "id": 2683,
          "person": {
            "id": 2683,
            "first_name": "Jagruti",
            "last_name": "Patel",
            "photo_url": "dfc42a6d-6e08-ca3d-b8b5-5612ea8cfc78.png",
            "phone_number": "",
            "email_address": "194805@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2012-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0061dd1-36ad-4970-bace-b2de0563c60c"
        },
        {
          "id": 11822,
          "person": {
            "id": 11822,
            "first_name": "Jairo",
            "last_name": "Chavez",
            "photo_url": "83370ea6-3876-8336-ec3a-ccae3e8ca5d5.png",
            "phone_number": "",
            "email_address": "217325@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-02-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a1c15c82-b3c1-4a9d-9ed9-81a6dbc9af7e"
        },
        {
          "id": 3772,
          "person": {
            "id": 3772,
            "first_name": "Janice",
            "last_name": "Bobbitt",
            "photo_url": "assets/8b76a9b3e72546e2b1ac825568ff2048_jb_t.jpg",
            "phone_number": "3176176017",
            "email_address": "jbobbitt@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a943939a-d42a-4ddc-b19e-d728c52e2929"
        },
        {
          "id": 1565,
          "person": {
            "id": 1565,
            "first_name": "Jasmina",
            "last_name": "Bektovic",
            "photo_url": "7da3da2e-a91e-2bbf-3bf6-5c2836d07ec7.png",
            "phone_number": "",
            "email_address": "100402@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "96b0dac0-4882-471f-ac01-727e7f3324a2"
        },
        {
          "id": 8835,
          "person": {
            "id": 8835,
            "first_name": "Jason",
            "last_name": "Barnes",
            "photo_url": "afd7085b-40ae-2fc7-176f-14e59d340989.png",
            "phone_number": "2486851400",
            "email_address": "220735@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ce00df48-8317-4e6c-832f-ebad29264490"
        },
        {
          "id": 3811,
          "person": {
            "id": 3811,
            "first_name": "Jeanon",
            "last_name": "Smith",
            "photo_url": "assets/881d59b53e204d0f9cfd7d0c89527223_20210811_121346_t.jpg",
            "phone_number": "2177533489",
            "email_address": "215076@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2104,
              "name": "Day",
              "contract": {
                "id": 1324,
                "name": "Illinois",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ba45a8c-f47d-4222-98ec-a410b40c76ec"
        },
        {
          "id": 2106,
          "person": {
            "id": 2106,
            "first_name": "Jennifer",
            "last_name": "Garcia",
            "photo_url": "c74b6803-6c42-c091-bd38-782c75ddb549.png",
            "phone_number": "",
            "email_address": "217121@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-01-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7a41e6a-af87-43fa-82c0-dbeb964f5e50"
        },
        {
          "id": 8817,
          "person": {
            "id": 8817,
            "first_name": "Jessica",
            "last_name": "Doyle",
            "photo_url": "",
            "phone_number": "7342620526",
            "email_address": "jestes@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2972,
              "name": "Director, Operations",
              "name_abbreviation": "DIROPR"
            }
          ],
          "shifts": [
            {
              "id": 7976,
              "name": "Day",
              "contract": {
                "id": 5859,
                "name": "DDE – Driving Dynamics East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "94619e35-2b52-4310-b0a2-996b73a92dae",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8157,
              "name": "Day",
              "contract": {
                "id": 6024,
                "name": "FORD00OVRHD",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "d3bb11ce-8d8a-4f4c-98fd-0e049671cf49",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2944,
              "name": "Day",
              "contract": {
                "id": 2226,
                "name": "TFC – The Factory – Detroit, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "7b24c107-350b-42bb-81f7-eff86d5f1745",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2049,
              "name": "Day",
              "contract": {
                "id": 2222,
                "name": "DTF – Drivability Test Facility – Allen Park, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "66ddb246-202d-4d86-818d-24d518dfd8aa",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2192,
              "name": "Day",
              "contract": {
                "id": 2252,
                "name": "AMC – Advanced Manufacturing Center (CBRE) – Redford, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2da8dad1-a7df-404f-a223-6e42dcc6419f",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7958,
              "name": "Day",
              "contract": {
                "id": 5854,
                "name": "OB3 – Office Building 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f9e1944c-690a-4410-ab8d-8084e0b892fb",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7959,
              "name": "Day",
              "contract": {
                "id": 5872,
                "name": "SRC – Service Research Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "99eb6139-99e1-4972-8fe9-f649efe97de4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7961,
              "name": "Day",
              "contract": {
                "id": 5855,
                "name": "OB4 – Office Building 4 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "050418f5-0657-4f1e-bdb5-f028132de335",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7972,
              "name": "Day",
              "contract": {
                "id": 5853,
                "name": "OB2 – Office Building 2 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "36531258-e8cc-4520-9164-5ff81759c305",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7973,
              "name": "Day",
              "contract": {
                "id": 5856,
                "name": "OB5 – Office Building 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "59265d94-591b-4dcf-8908-646cf1ab8a69",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7975,
              "name": "Day",
              "contract": {
                "id": 5871,
                "name": "SIL – Safety Innovation Laboratory – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "2d26209c-b4cf-4e0a-b284-3b7d2ffa3807",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7980,
              "name": "Day",
              "contract": {
                "id": 5875,
                "name": "VDAB – Vehicle Dynamics Area – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "343e19b5-96d3-4fca-9496-941240375a86",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7982,
              "name": "Day",
              "contract": {
                "id": 5858,
                "name": "CBB – Crash Barrier – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "f689f70f-317e-4184-b94f-66271284a1bc",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7983,
              "name": "Day",
              "contract": {
                "id": 5860,
                "name": "DDW – Driving Dynamics West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3e6389e7-b5cc-4535-9b45-921a963eb0c1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7984,
              "name": "Day",
              "contract": {
                "id": 5873,
                "name": "TEE – Truck Engine Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b77ae1d4-28c7-4a16-aa4c-f264a23dd14c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7985,
              "name": "Day",
              "contract": {
                "id": 5852,
                "name": "OB1 – Office Building 1 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "e00a888e-3513-4829-9c58-575e188e95fc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7986,
              "name": "Day",
              "contract": {
                "id": 5877,
                "name": "WT4&5 – Wind Tunnel 4 & 5 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d0809dbf-7647-42e7-bfa7-16b174ee9985",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7988,
              "name": "Day",
              "contract": {
                "id": 5866,
                "name": "MOB – Maintenance & Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5df64ff7-c654-471d-8d55-bd17cd31e6d6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7989,
              "name": "Day",
              "contract": {
                "id": 5865,
                "name": "GTL – Gas Turbine Labs – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "51da8d68-c2e9-4023-810b-6c4a8650d1f6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8001,
              "name": "Day",
              "contract": {
                "id": 5857,
                "name": "OB6 – Office Building 6 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "15358955-ef1b-4d3d-baaa-f7c8d43e52f5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7618,
              "name": "Day",
              "contract": {
                "id": 5565,
                "name": "ION – Ford Battery Center of Excellence – Romulus, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "fcc5d426-2a6a-4328-9718-4425618f9aac",
                "chatbot_sms_phone_number": "7347896550",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8009,
              "name": "Day",
              "contract": {
                "id": 5876,
                "name": "WT3 – Wind Tunnel 3 – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "4691ae5d-c763-4c9f-9176-3cf349bfd1b8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1514,
              "name": "Day",
              "contract": {
                "id": 2251,
                "name": "RIC – Research & Engineering – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "3d898f4c-4b38-4736-bc5f-5afc6d1e4519",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3066,
              "name": "Day",
              "contract": {
                "id": 2215,
                "name": "FXC – Ford Experience Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "5380102f-995b-441f-81c4-6820d20a121b",
                "chatbot_sms_phone_number": "1",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8089,
              "name": "Swing",
              "contract": {
                "id": 5878,
                "name": "WPW – Wagner Place West – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0880b424-7af9-404e-9336-727138961123",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7969,
              "name": "Swing",
              "contract": {
                "id": 5862,
                "name": "DYNO – Dynamometer – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b329d5a5-a688-4db5-85e0-453c2a458e79",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7971,
              "name": "Swing",
              "contract": {
                "id": 5861,
                "name": "EMDO – Engineering Manufacturing Development Operations – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "d905158b-3c88-4b6f-a201-2248cc14c707",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7978,
              "name": "Swing",
              "contract": {
                "id": 5851,
                "name": "AEC – Advanced Electrification Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8195a3fb-a084-48a5-90e7-27f27a4b9c6d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7981,
              "name": "Swing",
              "contract": {
                "id": 5863,
                "name": "EVB – Experimental Vehicle Building – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "21922489-dd81-4295-be37-291ba4839fae",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7987,
              "name": "Swing",
              "contract": {
                "id": 5869,
                "name": "PFSL – Powertrain & Fuel Cell Systems Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "9430b70a-08a2-450d-8997-e4a42560c070",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7994,
              "name": "Swing",
              "contract": {
                "id": 5870,
                "name": "PDC – Product Development Center - Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "b2318226-6c6f-4f67-8f2e-71b687ff4a3b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8108,
              "name": "Unassigned",
              "contract": {
                "id": 2214,
                "name": "WPE – Wagner Place East – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "77e2d35e-8a98-4d97-a16d-271f5fd53246",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8106,
              "name": "Unassigned",
              "contract": {
                "id": 5867,
                "name": "PRC – Product Review Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "8c2c7345-3246-460e-b790-8e6f552d6715",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8103,
              "name": "Unassigned",
              "contract": {
                "id": 5864,
                "name": "FEL – Ford Engineering Lab – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "6d03bfc3-41e3-469d-8666-ae9fef1817f8",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8107,
              "name": "Unassigned",
              "contract": {
                "id": 5874,
                "name": "VEMC – Vehicle Energy Management Center – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "87d3e48f-1396-4f31-adb4-46f119459bd8",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 8105,
              "name": "Unassigned",
              "contract": {
                "id": 5868,
                "name": "Powerhouse – Dearborn, MI",
                "customer_id": 315,
                "customer_name": "Ford",
                "uuid": "0d84e8b7-49f2-4140-8852-e9f6f0cc2a46",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "93f87227-be1c-49d7-a2b9-7748ec5e5fb0"
        },
        {
          "id": 4305,
          "person": {
            "id": 4305,
            "first_name": "Jessica",
            "last_name": "Rodriguez",
            "photo_url": "assets/c03123ade541413cad0c21100047e81b_JessicaR_t.jpg",
            "phone_number": "",
            "email_address": "226857@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8e0fadbd-8ad9-4e8b-ba74-b29898b374b2"
        },
        {
          "id": 4712,
          "person": {
            "id": 4712,
            "first_name": "Jessica",
            "last_name": "Rosario",
            "photo_url": "16345832-1944-cb50-0f1c-e400bbfb755b.png",
            "phone_number": "",
            "email_address": "227334@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1458,
              "name": "Day",
              "contract": {
                "id": 2017,
                "name": "Greenfield, IN",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "62a9f103-e603-4c65-99bc-b54214513b15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-02-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "85fbd1e7-d973-44fc-8a42-23532bf99ab7"
        },
        {
          "id": 4724,
          "person": {
            "id": 4724,
            "first_name": "Jesus",
            "last_name": "Montoya",
            "photo_url": "af402568-7938-cb54-9138-9dc8b6e9f24d.png",
            "phone_number": "4087842581",
            "email_address": "jmontoya@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "666524d4-785f-4098-b687-f15213dceaff"
        },
        {
          "id": 58324,
          "person": {
            "id": 714,
            "first_name": "Jesus",
            "last_name": "Ramirez",
            "photo_url": "9556089a-b576-e0fc-492d-71822551eb85.png",
            "phone_number": "",
            "email_address": "226690@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "29921458-611a-4932-b0c8-239402b29368"
        },
        {
          "id": 9805,
          "person": {
            "id": 9805,
            "first_name": "Jesus",
            "last_name": "Serrano Lasalle",
            "photo_url": "assets/r97b0ba9ae63d47edbd0c2f1b25a0f9a1_JesusPic_t.jpg",
            "phone_number": "5139783291",
            "email_address": "jlassalle@sbmcorp.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2717,
              "name": "Swing",
              "contract": {
                "id": 1823,
                "name": "Cincinnati",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "c89147f6-e91d-49af-ad37-d8d79a0b7512",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-09-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a22266d6-660c-4717-8e0e-c50d934112ac"
        },
        {
          "id": 4873,
          "person": {
            "id": 4873,
            "first_name": "Jhoni",
            "last_name": "Romero",
            "photo_url": "assets/de5db009197a4c51b4fc672818f62710_cropped8634233188139413937.jpg",
            "phone_number": "",
            "email_address": "203370@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "39140c4e-7c60-4e3b-90c0-f212463fcc46"
        },
        {
          "id": 1440,
          "person": {
            "id": 1440,
            "first_name": "Jimmy",
            "last_name": "Graham",
            "photo_url": "assets/b47c89977e474321b37bcccd8329d283_IMG_9086_t.jpg",
            "phone_number": "2064745128",
            "email_address": "jgraham@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-06-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c2578a67-db9c-4af2-a628-c2fc1b29089b"
        },
        {
          "id": 4090,
          "person": {
            "id": 4090,
            "first_name": "Jin-Feng",
            "last_name": "Li",
            "photo_url": "f20bf684-6894-a9cb-7fe2-4fd906750a1d.png",
            "phone_number": "",
            "email_address": "102441@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c8b61019-62de-445a-b956-e4371c9d6ecb"
        },
        {
          "id": 8208,
          "person": {
            "id": 8208,
            "first_name": "Jing",
            "last_name": "Chen",
            "photo_url": "b105e198-cf91-0aea-4d03-1c5c28a0e575.png",
            "phone_number": "",
            "email_address": "221914@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1bdfa819-dd30-425a-b149-a9e61ef148fa"
        },
        {
          "id": 2634,
          "person": {
            "id": 2634,
            "first_name": "Joanna",
            "last_name": "Mayfield",
            "photo_url": "assets/ed298514f7cc4a6f8128f9b0e9ac1ccd_20230908_082152_t.jpg",
            "phone_number": "",
            "email_address": "202137@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9f26d85-6efe-4c6d-80e6-d1ab511d54ef"
        },
        {
          "id": 5479,
          "person": {
            "id": 5479,
            "first_name": "Joao",
            "last_name": "Teixeira",
            "photo_url": "fe0d3242-12b8-0c8e-7281-50bb95a70b8c.png",
            "phone_number": "",
            "email_address": "224396@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "19fb1c34-dd01-426e-8fb8-5de743908674"
        },
        {
          "id": 10462,
          "person": {
            "id": 10462,
            "first_name": "John",
            "last_name": "Brangle",
            "photo_url": "assets/97b5cba050174ed6b7a3d5cc0bdbce36_John_t.jpg",
            "phone_number": "",
            "email_address": "218312@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ac328101-0b69-44b9-9732-ffc413a0b549"
        },
        {
          "id": 10361,
          "person": {
            "id": 10361,
            "first_name": "John",
            "last_name": "Tran",
            "photo_url": "assets/r4b4c3a606eb04bb896cdd2347f449ded_cropped4500212426598052073.jpg",
            "phone_number": "",
            "email_address": "221858@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b713da1-1931-4cc8-bdf6-41c583600db7"
        },
        {
          "id": 9304,
          "person": {
            "id": 9304,
            "first_name": "Johnathan",
            "last_name": "Ward",
            "photo_url": "assets/6538e293c26d4d60a095be0d76f41652_JohnWard_t.jpg",
            "phone_number": "",
            "email_address": "202130@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7a518464-1abe-4b53-af37-3766401fa6c7"
        },
        {
          "id": 1455,
          "person": {
            "id": 1455,
            "first_name": "Jonathan",
            "last_name": "Green",
            "photo_url": "userprofile/am85qnzb5y6a8nu7aoaf",
            "phone_number": "",
            "email_address": "202328@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "41028d28-7d84-496c-baea-0d79274424a4"
        },
        {
          "id": 8276,
          "person": {
            "id": 8276,
            "first_name": "Jonathan",
            "last_name": "Perez-Castro",
            "photo_url": "a0eaa43f-55f8-100b-3f33-3b15a3c18588.png",
            "phone_number": "9782016711",
            "email_address": "jperez-castro@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b6db5526-69da-43fc-8397-f3896eee0d70"
        },
        {
          "id": 10839,
          "person": {
            "id": 10839,
            "first_name": "Jordan",
            "last_name": "Huber",
            "photo_url": "6514f3f0-c45f-69a9-5145-102f5d71e625.png",
            "phone_number": "",
            "email_address": "225527@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-09-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1d04a9d3-6080-4620-aff1-c8bd4158847a"
        },
        {
          "id": 2810,
          "person": {
            "id": 2810,
            "first_name": "Jordan",
            "last_name": "Jess",
            "photo_url": "36a7a41a-3abb-2a5b-cc10-b057e3b16dae.png",
            "phone_number": "",
            "email_address": "221707@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "36fef7ac-e840-481b-828e-6653502cf81d"
        },
        {
          "id": 12766,
          "person": {
            "id": 12766,
            "first_name": "Jordan",
            "last_name": "Thompson",
            "photo_url": "b5349719-482f-621f-7d55-701f62ff859c.png",
            "phone_number": "",
            "email_address": "223552@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-04-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "670d7c75-b755-4afa-9d05-0ba6d1d77434"
        },
        {
          "id": 11684,
          "person": {
            "id": 11684,
            "first_name": "Jorge",
            "last_name": "Galo",
            "photo_url": "5798f440-525a-b1b8-baef-5d7ee543661b.png",
            "phone_number": "(267)642-4025",
            "email_address": "125902@placeholder.com"
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-02-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f100f75c-0c8e-499f-a074-f2f296d3eb34"
        },
        {
          "id": 11935,
          "person": {
            "id": 11935,
            "first_name": "Jorge",
            "last_name": "Pedraza",
            "photo_url": "12ea657f-df62-cd25-4e4a-7a69c4c1eed2.png",
            "phone_number": "8582160573",
            "email_address": "jpedraza@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2448,
              "name": "Swing",
              "contract": {
                "id": 2072,
                "name": "San Diego, CA",
                "customer_id": 281,
                "customer_name": "ASML (CBRE)",
                "uuid": "63daba36-4414-456b-8964-1a6c91b7a260",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-05-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a9f18fe4-3be6-4af6-981c-7b7f91670c18"
        },
        {
          "id": 8032,
          "person": {
            "id": 8032,
            "first_name": "Jorge",
            "last_name": "Rivas Portillo",
            "photo_url": "2fd0f170-089b-96bb-9d23-d47e1bd0f10e.png",
            "phone_number": "",
            "email_address": "224335@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "13fa4c9e-3ba5-40df-b6c0-1d64fdeb8981"
        },
        {
          "id": 4872,
          "person": {
            "id": 4872,
            "first_name": "Jose",
            "last_name": "Campos",
            "photo_url": "e1973065-0e0d-4f4b-908d-90fe1476ad15.png",
            "phone_number": "919-265-4169",
            "email_address": "107590@placeholder.com"
          },
          "positions": [
            {
              "id": 31,
              "name": "Lead, Floor Care",
              "name_abbreviation": "FLTCLD"
            }
          ],
          "shifts": [
            {
              "id": 2471,
              "name": "Swing",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1618ad0b-ea12-42d9-93f1-228fadd981bc"
        },
        {
          "id": 3200,
          "person": {
            "id": 3200,
            "first_name": "Jose",
            "last_name": "De La Rosa",
            "photo_url": "7ce12158-2d61-a717-35dc-e0c8f71e4fdf.png",
            "phone_number": "",
            "email_address": "226434@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-11-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0b96431-9273-4ced-9711-dfd47f6677fe"
        },
        {
          "id": 11047,
          "person": {
            "id": 11047,
            "first_name": "Jose",
            "last_name": "Diaz Santos",
            "photo_url": "488c8684-6b37-583b-ec38-db9fba35ee7a.png",
            "phone_number": "",
            "email_address": "jdiaz@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-03-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07d8ea41-8da0-46fa-a3d2-297498b1b17a"
        },
        {
          "id": 2931,
          "person": {
            "id": 2931,
            "first_name": "Jose",
            "last_name": "Flores",
            "photo_url": "userprofile/lcjcxe1teor6p7hcdf0b",
            "phone_number": "",
            "email_address": "202291@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2a588a66-6a07-40d2-8cc9-16ad21eda5d2"
        },
        {
          "id": 12849,
          "person": {
            "id": 12849,
            "first_name": "Jose",
            "last_name": "Galo",
            "photo_url": "0136c0d0-5ecb-cbe5-38e8-47e0eb2ca55a.png",
            "phone_number": "2692038425",
            "email_address": "122712@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-10-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "297514f2-c20f-48ef-a668-21f9fc84e4d1"
        },
        {
          "id": 3403,
          "person": {
            "id": 3403,
            "first_name": "Jose",
            "last_name": "Guerra",
            "photo_url": "4a5ee749-a99d-cc41-8d03-e9211fb36d54.png",
            "phone_number": "",
            "email_address": "224347@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1445dd44-9a8a-40b0-96b9-5d2d68d85b56"
        },
        {
          "id": 536,
          "person": {
            "id": 536,
            "first_name": "Jose",
            "last_name": "Lopez",
            "photo_url": "9780b098-db56-a5a0-143b-27a42bdaec63.png",
            "phone_number": "",
            "email_address": "224343@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f95306d6-96df-4ba8-8e8e-4675f987f728"
        },
        {
          "id": 10463,
          "person": {
            "id": 10463,
            "first_name": "Jose",
            "last_name": "Martinez",
            "photo_url": "assets/b87906d3fede40e8bb240a1fa81eed11_20221202_094653_t.jpg",
            "phone_number": "",
            "email_address": "220429@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "76553d99-358d-486b-b2c2-0b45ce01a20a"
        },
        {
          "id": 1067,
          "person": {
            "id": 1067,
            "first_name": "Jose",
            "last_name": "Nunez Arnaud",
            "photo_url": "7cc8c3e8-a728-1c83-f92f-4774bb7afec6.png",
            "phone_number": "",
            "email_address": "226708@placeholder.com"
          },
          "positions": [
            {
              "id": 96,
              "name": "Inventory Control Driver",
              "name_abbreviation": "ICDRVR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae3c20cd-a89f-48b8-bf30-493691d7293a"
        },
        {
          "id": 1683,
          "person": {
            "id": 1683,
            "first_name": "Jose",
            "last_name": "Perez",
            "photo_url": "dd6c85ec-6b28-d98d-3206-7308779c2e5d.png",
            "phone_number": "6177589519",
            "email_address": "213537@placeholder.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 6024,
              "name": "Day",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-05-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ce2c202-1290-4dab-ba74-d458e3d85ce9"
        },
        {
          "id": 857,
          "person": {
            "id": 857,
            "first_name": "Jose",
            "last_name": "Salinas",
            "photo_url": "assets/bf6e7c67aa5043d380726c3005da576b_IMG_20221104_41962_t.jpg",
            "phone_number": "",
            "email_address": "222012@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f3885b2a-0c84-4104-b0fc-22c76dbff3b2"
        },
        {
          "id": 1396,
          "person": {
            "id": 1396,
            "first_name": "Jose",
            "last_name": "Tellez Vargas",
            "photo_url": "4f29b22f-66df-11d0-ff2a-e0d33eec9eac.png",
            "phone_number": "4253959732",
            "email_address": "221942@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e6cec2bb-03cb-497d-afd7-c9b1a41fac03"
        },
        {
          "id": 11187,
          "person": {
            "id": 11187,
            "first_name": "Jose",
            "last_name": "Vasquez",
            "photo_url": "assets/8fe2b4c075344b59aad1748ecf61d22d_20221202_093854_t.jpg",
            "phone_number": "",
            "email_address": "220447@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18863aed-50e3-446f-8721-57617bd79137"
        },
        {
          "id": 5304,
          "person": {
            "id": 5304,
            "first_name": "Josefa",
            "last_name": "Ceja De Oseguera",
            "photo_url": "assets/c9099ef081d74e13ad8bd87a0af47e65_IMG_20221104_58739_t.jpg",
            "phone_number": "650-518-0496",
            "email_address": "204535@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "425afcb3-4ef9-4cf0-8ad2-912774fa1056"
        },
        {
          "id": 10901,
          "person": {
            "id": 10901,
            "first_name": "Josefa",
            "last_name": "Parada",
            "photo_url": "userprofile/ah4kpzsjadcfmeiyk5ty",
            "phone_number": "",
            "email_address": "202900@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-03-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3086d652-9d6a-494b-9f6d-b420b4acb70f"
        },
        {
          "id": 3362,
          "person": {
            "id": 3362,
            "first_name": "Joseph",
            "last_name": "Hutchinson",
            "photo_url": "assets/d43d4f5750b3455498fc41c06d681ff1_IcePlant006_t.jpg",
            "phone_number": "",
            "email_address": "216911@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-01-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "34ba69ee-5ab3-48df-9e14-da34d9901a94"
        },
        {
          "id": 4984,
          "person": {
            "id": 4984,
            "first_name": "Joseph",
            "last_name": "Poon",
            "photo_url": "eb9d2495-943c-5d1d-3ab0-cae013b3927a.png",
            "phone_number": "",
            "email_address": "103559@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6042cad8-4a73-4646-9617-f71ed38a8e7f"
        },
        {
          "id": 8237,
          "person": {
            "id": 8237,
            "first_name": "Juan",
            "last_name": "Baez",
            "photo_url": "f4b2b7ac-e9cc-05fd-9a2a-1c47ba0437df.png",
            "phone_number": "",
            "email_address": "226728@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95c5260c-a40f-48b9-ae8e-ee71dfcf36d7"
        },
        {
          "id": 2378,
          "person": {
            "id": 2378,
            "first_name": "Juan",
            "last_name": "Cardenas-Rivera",
            "photo_url": "assets/694e832aac0c48f5979b6080ea4c906f_JuanCardenas_t.jpeg",
            "phone_number": "",
            "email_address": "226845@placeholder.com"
          },
          "positions": [
            {
              "id": 160,
              "name": "Tech, Mailroom",
              "name_abbreviation": "MAILTC"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2305771d-d63f-47df-924c-33cef956e0f7"
        },
        {
          "id": 4615,
          "person": {
            "id": 4615,
            "first_name": "Juan",
            "last_name": "Portillo",
            "photo_url": "de277363-4c60-78b1-afe6-bb86e5f25bb4.png",
            "phone_number": "",
            "email_address": "226712@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c952ab79-bf97-4d10-b13f-1b68c47ef067"
        },
        {
          "id": 12834,
          "person": {
            "id": 12834,
            "first_name": "Juana",
            "last_name": "Lopez",
            "photo_url": "b3ffbb8f-6bc0-b9bc-fa71-3444ba8acc56.png",
            "phone_number": "",
            "email_address": "202289@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "963c845b-61dd-4ed6-b1a4-af8d82523266"
        },
        {
          "id": 920,
          "person": {
            "id": 920,
            "first_name": "Juana",
            "last_name": "Torres Mejia",
            "photo_url": "903c4f08-d9d7-d05d-f795-74e076626ea4.png",
            "phone_number": "",
            "email_address": "221734@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "45f983c1-3988-4307-b0cd-d4485c96308f"
        },
        {
          "id": 3901,
          "person": {
            "id": 3901,
            "first_name": "Judith",
            "last_name": "Major",
            "photo_url": "userprofile/x8gclhwag1tx0jxu8azo",
            "phone_number": "3179542482",
            "email_address": "jmajor@sbmcorp.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07ccf608-3a4e-445e-b2e8-4151dd271bad"
        },
        {
          "id": 7379,
          "person": {
            "id": 7379,
            "first_name": "Julia",
            "last_name": "Sorto",
            "photo_url": "6bc063db-dd4d-4884-62a3-67a8cc7e9ce7.png",
            "phone_number": "610-509-0201",
            "email_address": "192009@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "396f98ee-eb01-478d-8440-9a4f5ca2b61f"
        },
        {
          "id": 8562,
          "person": {
            "id": 8562,
            "first_name": "Julio",
            "last_name": "Aguilar",
            "photo_url": "0acb9c70-05b7-7dff-22ff-63d2e644bc56.png",
            "phone_number": "",
            "email_address": "225807@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2520,
              "name": "Swing",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e42bedf3-f258-4039-8526-f5a752d8636c"
        },
        {
          "id": 358,
          "person": {
            "id": 358,
            "first_name": "Julio",
            "last_name": "Barahona Ascencio",
            "photo_url": "dac73d95-3b3d-881b-d4e2-5b0dc291ee51.png",
            "phone_number": "",
            "email_address": "100372@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "063c8ea3-623d-4c86-a897-15d93e7720b8"
        },
        {
          "id": 11757,
          "person": {
            "id": 11757,
            "first_name": "Julio",
            "last_name": "Nunez",
            "photo_url": "30244292-972d-6dda-e73a-86b32866f757.png",
            "phone_number": "",
            "email_address": "226706@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e106e7e2-cd07-4a8a-a99a-e80a169f840c"
        },
        {
          "id": 1758,
          "person": {
            "id": 1758,
            "first_name": "Julio",
            "last_name": "Tejeda",
            "photo_url": "assets/f87f051c34cf4205a7b6c9afebfd9b75_Capture_t.PNG",
            "phone_number": "6175123061",
            "email_address": "jtejeda@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2972,
              "name": "Director, Operations",
              "name_abbreviation": "DIROPR"
            }
          ],
          "shifts": [
            {
              "id": 7398,
              "name": "Day",
              "contract": {
                "id": 5634,
                "name": "TAKE00OVRHD",
                "customer_id": 2117,
                "customer_name": "Takeda",
                "uuid": "19c0982e-8824-4781-a83f-95f2cacf1c3e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 6620,
              "name": "Day",
              "contract": {
                "id": 3398,
                "name": "Lexington, MA",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "9868fdb7-fe2e-443c-a6d5-ab681b567aaf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-10-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ead939b0-3f40-4102-80ff-dd48d97a52e0"
        },
        {
          "id": 1287,
          "person": {
            "id": 1287,
            "first_name": "Julio",
            "last_name": "Trejo Hernandez",
            "photo_url": "b7df30c2-576c-5d8e-0997-811feb173391.png",
            "phone_number": "",
            "email_address": "221777@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "64ed582b-cfb8-49bd-bffa-3a65b4c78ae9"
        },
        {
          "id": 14268,
          "person": {
            "id": 14269,
            "first_name": "Justin",
            "last_name": "Homa",
            "photo_url": "assets/1617302638.4097366_me_t.jpg",
            "phone_number": "2155969073",
            "email_address": "JHoma@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e88c62ea-0f57-4bf9-8c73-743d5fec63c5"
        },
        {
          "id": 7012,
          "person": {
            "id": 7012,
            "first_name": "Juver",
            "last_name": "Padilla",
            "photo_url": "9fbb8dab-678a-cd56-15aa-6bdb818d5be7.png",
            "phone_number": "(215) 6927821",
            "email_address": "225700@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-09-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e290aa75-ca78-4cc5-aee7-1eddf14d63e6"
        },
        {
          "id": 10079,
          "person": {
            "id": 10079,
            "first_name": "Kadir",
            "last_name": "Hayato",
            "photo_url": "079f6409-2c32-a230-ea9e-5c8dba4657a3.png",
            "phone_number": "",
            "email_address": "225466@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-08-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "07ce2628-be00-4e7b-9254-3f8d54a54daf"
        },
        {
          "id": 6309,
          "person": {
            "id": 6309,
            "first_name": "Kadira",
            "last_name": "Dodic",
            "photo_url": "0339e504-88b7-e0aa-9bd2-6a2b87b174f0.png",
            "phone_number": "",
            "email_address": "101219@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "44b92cef-7de2-4695-8da5-1954933d85fa"
        },
        {
          "id": 487,
          "person": {
            "id": 487,
            "first_name": "Kai",
            "last_name": "Fong",
            "photo_url": "fe00e29d-e092-1a20-2565-728021a30eb8.png",
            "phone_number": "",
            "email_address": "101496@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "714142a2-91c8-481b-b76f-85835f6404f5"
        },
        {
          "id": 1183,
          "person": {
            "id": 1183,
            "first_name": "Kaltum",
            "last_name": "Guure",
            "photo_url": "98f69a13-d62f-c277-a097-22c5db23b45b.png",
            "phone_number": "",
            "email_address": "101852@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "716095a5-9657-4a7f-a445-139e3b8b388f"
        },
        {
          "id": 1786,
          "person": {
            "id": 1786,
            "first_name": "Kao",
            "last_name": "Saechao",
            "photo_url": "fd7dea67-7b8b-325f-4d1f-206841f2a511.png",
            "phone_number": "",
            "email_address": "221656@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f2f4fa05-4cc1-4ef1-9344-9e5bc9804c8e"
        },
        {
          "id": 1889,
          "person": {
            "id": 1889,
            "first_name": "Karin",
            "last_name": "Yunda Clavijo",
            "photo_url": "fee18c68-5627-5480-cc4b-dac945c2b3bf.png",
            "phone_number": "9042944773",
            "email_address": "kclavijo@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2663,
              "name": "Swing",
              "contract": {
                "id": 1968,
                "name": "Jacksonville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "519aafa7-5dcc-4c67-ab89-f46a97d2b8d0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-03-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "634bb957-fbbc-4bfc-b799-06084ae6834d"
        },
        {
          "id": 8724,
          "person": {
            "id": 8724,
            "first_name": "Karla",
            "last_name": "Orellana",
            "photo_url": "125b198d-c195-fcff-378f-45fffb37a74b.png",
            "phone_number": "",
            "email_address": "221998@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8f1b90c7-355c-4dc3-8884-eb82837e5fbc"
        },
        {
          "id": 11563,
          "person": {
            "id": 11563,
            "first_name": "Karla",
            "last_name": "Osoy Lopez",
            "photo_url": "954650ac-3991-d4f0-0856-47491a9e5056.png",
            "phone_number": "",
            "email_address": "210687@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aebe88c3-ffe1-48dc-a86e-6aca4351f107"
        },
        {
          "id": 2718,
          "person": {
            "id": 2718,
            "first_name": "Katib",
            "last_name": "Abdullayev",
            "photo_url": "71d0f322-8ec9-82b8-406d-cb701f589b25.png",
            "phone_number": "",
            "email_address": "226670@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a4020ab5-d138-4b08-adbd-bd369e720872"
        },
        {
          "id": 3712,
          "person": {
            "id": 3712,
            "first_name": "Kedija",
            "last_name": "Mudi",
            "photo_url": "7271b49f-43b6-4a44-e5dc-84a9ccfb045f.png",
            "phone_number": "",
            "email_address": "226197@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7db387be-ddad-401c-b85f-028cf6c99011"
        },
        {
          "id": 76,
          "person": {
            "id": 76,
            "first_name": "Kelbesa",
            "last_name": "Dibaba",
            "photo_url": "9d4ada7b-070f-1b3b-c16e-172487cb635c.png",
            "phone_number": "206-518-8765",
            "email_address": "221804@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d3b91c60-cb8c-4bb9-a0fe-927eda7e755b"
        },
        {
          "id": 157,
          "person": {
            "id": 157,
            "first_name": "Kerya",
            "last_name": "Ning",
            "photo_url": "992b28e9-8b48-583f-d2cd-bbbe2a0953c5.png",
            "phone_number": "",
            "email_address": "221723@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cc1df9e0-3ece-4eff-bacf-f74e50181d9a"
        },
        {
          "id": 3037,
          "person": {
            "id": 3037,
            "first_name": "Kevin",
            "last_name": "Dean",
            "photo_url": "assets/8b049544c5bc4ad6b0f27a9c41ff9793_KevinDean_t.jpg",
            "phone_number": "",
            "email_address": "202227@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "629ee250-bb6a-4349-aed4-c071f0ae5297"
        },
        {
          "id": 10283,
          "person": {
            "id": 10283,
            "first_name": "Kevin",
            "last_name": "Hudson",
            "photo_url": "713b0df4-c18c-5a60-7a47-ceb0cd4c99e5.png",
            "phone_number": "4253450780",
            "email_address": "102079@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47327fe7-6ee2-47ce-bb28-7eddc65785fc"
        },
        {
          "id": 6445,
          "person": {
            "id": 6445,
            "first_name": "Kevin",
            "last_name": "White",
            "photo_url": "27cbe332-c76a-1ca1-657f-6d913c512746.png",
            "phone_number": "",
            "email_address": "218216@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "013396d0-83ef-4b7a-97d1-7dbeaa435349"
        },
        {
          "id": 2071,
          "person": {
            "id": 2071,
            "first_name": "Khai",
            "last_name": "Nguyen",
            "photo_url": "03eeb6d4-1998-accf-8f95-89055ebfc8af.png",
            "phone_number": "",
            "email_address": "226191@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c5bb8f78-ebb2-419a-ac46-beee34a23e78"
        },
        {
          "id": 4216,
          "person": {
            "id": 4216,
            "first_name": "Kiet",
            "last_name": "Chung",
            "photo_url": "d5950777-6cba-946f-dbea-3d9316fa51ce.png",
            "phone_number": "2067786075",
            "email_address": "221825@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "50213bec-bfff-4fea-8954-515a6dd64091"
        },
        {
          "id": 2451,
          "person": {
            "id": 2451,
            "first_name": "Kiet",
            "last_name": "Trinh",
            "photo_url": "48a38f9e-b149-3d46-5e25-000573b78ff2.png",
            "phone_number": "",
            "email_address": "104623@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8633e33a-7040-4dd2-9c82-83f507ee5f37"
        },
        {
          "id": 6060,
          "person": {
            "id": 6060,
            "first_name": "Kimberlee",
            "last_name": "Holiday",
            "photo_url": "e3103264-3a9a-c339-2086-89f27742f8da.png",
            "phone_number": "",
            "email_address": "226285@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-11-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "633cdac0-2d77-4b38-8b6e-5f61f79add53"
        },
        {
          "id": 1303,
          "person": {
            "id": 1303,
            "first_name": "Kimberly",
            "last_name": "Hebert",
            "photo_url": "21df31f4-b3ab-d13f-0780-72d04bc663e7.png",
            "phone_number": "",
            "email_address": "218488@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e321bbd6-d4d1-4d9a-b382-8acd7898454c"
        },
        {
          "id": 12575,
          "person": {
            "id": 12575,
            "first_name": "Kinh",
            "last_name": "Ngo",
            "photo_url": "1894fa12-c6fe-aabd-2356-4d21372607c7.png",
            "phone_number": "2065188196",
            "email_address": "103151@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e1730ea8-68de-427f-a4d8-b06ee742db4c"
        },
        {
          "id": 8438,
          "person": {
            "id": 8438,
            "first_name": "Kori",
            "last_name": "Fitzgerald",
            "photo_url": "cbb4c236-d809-4d34-c32b-37a101688434.png",
            "phone_number": "",
            "email_address": "222656@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-02-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c8d2c38-37c6-4c0c-b742-7d5e3d25770f"
        },
        {
          "id": 9741,
          "person": {
            "id": 9741,
            "first_name": "Lamont",
            "last_name": "Scott",
            "photo_url": "0a66cca5-ed1a-a4ea-b15d-1e736ceb532b.png",
            "phone_number": "",
            "email_address": "220081@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d654705-3201-4306-b210-4b61698fab48"
        },
        {
          "id": 67778,
          "person": {
            "id": 67236,
            "first_name": "Lan",
            "last_name": "Dinh",
            "photo_url": "assets/9882d073499c4ae4a39d03132cac7a1c_Lan.Dinh_t.jpg",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b8c144d-4f5f-4777-9dd9-aebc86e71157"
        },
        {
          "id": 11603,
          "person": {
            "id": 11603,
            "first_name": "Laura",
            "last_name": "Browne",
            "photo_url": "07b3f10f-d11c-2fc2-bae4-85e7992181a0.png",
            "phone_number": "",
            "email_address": "219971@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8946c66c-5079-41a7-8565-88a336804be8"
        },
        {
          "id": 11414,
          "person": {
            "id": 11414,
            "first_name": "Leandra",
            "last_name": "Gonzalez",
            "photo_url": "assets/0d3b2d3e886646baa36c2bf1473c3862_20221202_094503_t.jpg",
            "phone_number": "4083178791",
            "email_address": "lgonzalez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2541,
              "name": "Swing",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2010-06-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "84dd698f-edb1-472e-9ecb-fff61f04d39b"
        },
        {
          "id": 9191,
          "person": {
            "id": 9191,
            "first_name": "Ledis",
            "last_name": "Tejeda",
            "photo_url": "eb077f09-0a4f-b2a8-f6cc-57cbf2290d3b.png",
            "phone_number": "",
            "email_address": "226688@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e7b6bf82-3e50-4ca7-b831-ef5bf25d9f7e"
        },
        {
          "id": 1539,
          "person": {
            "id": 1539,
            "first_name": "Lendra",
            "last_name": "Johns",
            "photo_url": "assets/e50e6315caa942f18c49cb7fb7b3bc4f_LJInsite_t.undefined",
            "phone_number": "",
            "email_address": "218180@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9cb57a42-d759-4c33-8d64-3f4e9426765f"
        },
        {
          "id": 1976,
          "person": {
            "id": 1976,
            "first_name": "Lenna",
            "last_name": "Shores",
            "photo_url": "assets/0b212939c9374eae84da6f8f8c48303b_PDCpics009_t.jpg",
            "phone_number": "",
            "email_address": "202157@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-11-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b644dbbf-94cc-4730-9da0-fced0bb7d433"
        },
        {
          "id": 9089,
          "person": {
            "id": 9089,
            "first_name": "Leobardo",
            "last_name": "Sanchez",
            "photo_url": "6343e398-6054-a8a5-ad67-795cdd606420.png",
            "phone_number": "",
            "email_address": "225442@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3bab271e-cfa1-4cf5-ab43-4ac989d3673b"
        },
        {
          "id": 4659,
          "person": {
            "id": 4659,
            "first_name": "Leonardo",
            "last_name": "Silva",
            "photo_url": "assets/d2a0fdec0fc4483f8fe4b50ea7c24083_20221104_000240_t.jpg",
            "phone_number": "4084766885",
            "email_address": "lsilva@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2bd13bb0-9883-4521-b174-a65c08c12c09"
        },
        {
          "id": 3842,
          "person": {
            "id": 3842,
            "first_name": "Li",
            "last_name": "Wu",
            "photo_url": "1e7183f6-6847-6a10-5a17-7b2bbef02a73.png",
            "phone_number": "",
            "email_address": "104952@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cedb1bea-55aa-482e-88e8-8d691cc25749"
        },
        {
          "id": 4422,
          "person": {
            "id": 4422,
            "first_name": "Lidia",
            "last_name": "Gomez",
            "photo_url": "assets/a2f94a38566c43889f16de0ed697f81e_IMG_20221104_4351_t.jpg",
            "phone_number": "",
            "email_address": "225884@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a08f9d67-ece9-4b63-a047-a3bcee8f3ffa"
        },
        {
          "id": 7294,
          "person": {
            "id": 7294,
            "first_name": "Liliana",
            "last_name": "Vizarraga Vasquez",
            "photo_url": "assets/c1eb3d286efe491b93ff0e86a6940f03_IMG_20221104_59820_t.jpg",
            "phone_number": "",
            "email_address": "222036@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2516a375-060a-4e7a-821c-7075a466af20"
        },
        {
          "id": 8201,
          "person": {
            "id": 8201,
            "first_name": "Linda",
            "last_name": "Akers",
            "photo_url": "assets/9241633c22424c0a8dde6d415548144d_Linda_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "284af7a1-6d6c-420e-a539-fc2347977379"
        },
        {
          "id": 11807,
          "person": {
            "id": 11807,
            "first_name": "Linda",
            "last_name": "Cottrell",
            "photo_url": "17442015-608a-26f1-cc3a-4f77021fdd5d.png",
            "phone_number": "7658208052",
            "email_address": "222159@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8b766ef3-71a2-4075-a6ba-e2292706c34a"
        },
        {
          "id": 11166,
          "person": {
            "id": 11166,
            "first_name": "Lisa",
            "last_name": "Placek",
            "photo_url": "491a10f3-fda8-64ee-9855-3a0a60f75874.png",
            "phone_number": "",
            "email_address": "218439@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2289,
              "name": "Day",
              "contract": {
                "id": 2333,
                "name": "Pittsburg",
                "customer_id": 352,
                "customer_name": "Covestro (CBRE)",
                "uuid": "e5bbb319-29ee-443c-afd9-82b62489001a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e93cfd8-dde5-4641-8fe6-7675683e8ff3"
        },
        {
          "id": 1161,
          "person": {
            "id": 1161,
            "first_name": "Ljize",
            "last_name": "Vulaj",
            "photo_url": "521e743a-7630-6671-6116-8a78449dd450.png",
            "phone_number": "",
            "email_address": "218246@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "50e7e8a5-6f68-4036-8d1a-1ad1ecda292e"
        },
        {
          "id": 10612,
          "person": {
            "id": 10612,
            "first_name": "Loan",
            "last_name": "Le",
            "photo_url": "0a1aac54-e396-b8fa-38a3-9f42ee6293c4.png",
            "phone_number": "",
            "email_address": "102375@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e2eb94a2-60fd-4b71-b6a6-204aaa8333b4"
        },
        {
          "id": 172,
          "person": {
            "id": 172,
            "first_name": "Lorena",
            "last_name": "Alvarez",
            "photo_url": "assets/9d06b069b2a74bce9c7543e16f90db5b_IMG_20221104_46574_t.jpg",
            "phone_number": "",
            "email_address": "222011@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b38b5ba3-1066-4edb-b101-6b2bc7759b3f"
        },
        {
          "id": 10619,
          "person": {
            "id": 10619,
            "first_name": "Lorena",
            "last_name": "McCoy",
            "photo_url": "739136ec-f5ec-7123-4f54-153cab79e15a.png",
            "phone_number": "",
            "email_address": "221773@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f6f46ffc-7ea2-4fe1-9eca-030ee50d8f8a"
        },
        {
          "id": 921,
          "person": {
            "id": 921,
            "first_name": "Lucia",
            "last_name": "Comas",
            "photo_url": "5b30cce2-82e8-7ae4-cb2d-ae25768c0e7e.png",
            "phone_number": "2676423366",
            "email_address": "lcomas@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4417256d-2c18-41ba-96ef-45660a64c5a2"
        },
        {
          "id": 2318,
          "person": {
            "id": 2318,
            "first_name": "Luz",
            "last_name": "Lebron",
            "photo_url": "362edd10-b975-3106-16e2-e4e5b5fe1f4a.png",
            "phone_number": "",
            "email_address": "226849@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0c3c6ed2-79f2-45bd-a8bf-bff88df07883"
        },
        {
          "id": 2303,
          "person": {
            "id": 2303,
            "first_name": "Luz",
            "last_name": "Ramos",
            "photo_url": "assets/b5b6a65641f044e992cdba62a4b2cec2_IMG_20221104_43344_t.jpg",
            "phone_number": "",
            "email_address": "119612@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0b6ad4c0-b86d-44a8-9f92-3b6a6c03312d"
        },
        {
          "id": 9551,
          "person": {
            "id": 9551,
            "first_name": "Lyubov",
            "last_name": "Domanskaya",
            "photo_url": "7c661d74-a2ab-479a-89d0-becdec33fc0b.png",
            "phone_number": "",
            "email_address": "225692@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 857,
              "name": "Day",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3347652-fa8a-4fcd-bdd9-3b73a475cb24"
        },
        {
          "id": 3494,
          "person": {
            "id": 3494,
            "first_name": "Lyudmila",
            "last_name": "Moroshan",
            "photo_url": "2c626492-4e26-f197-b6d1-c7df8648d80a.png",
            "phone_number": "",
            "email_address": "103056@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "74c2db80-ac12-4a84-9efc-a6d3d55da7b9"
        },
        {
          "id": 9218,
          "person": {
            "id": 9218,
            "first_name": "Mackency",
            "last_name": "Lara",
            "photo_url": "23ad46bd-9750-80c5-a22a-4321d48ee74c.png",
            "phone_number": "",
            "email_address": "226719@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c9a8cc05-1b75-48a8-9c87-e762361ddf14"
        },
        {
          "id": 3672,
          "person": {
            "id": 3672,
            "first_name": "Magdy",
            "last_name": "Soliman",
            "photo_url": "1cfa32fd-42cd-ebd7-ae71-37e078579329.png",
            "phone_number": "2156096978",
            "email_address": "125734@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2010-11-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f8377e5-15d4-438f-86b5-2759ecf0beb6"
        },
        {
          "id": 9988,
          "person": {
            "id": 9988,
            "first_name": "Mai",
            "last_name": "Le",
            "photo_url": "56323215-a106-20b0-cca5-d50140dc9bb3.png",
            "phone_number": "4253440395",
            "email_address": "221706@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "13bb2f82-608a-4e07-bdce-7f111992bf0f"
        },
        {
          "id": 7341,
          "person": {
            "id": 7341,
            "first_name": "Mai",
            "last_name": "Truong",
            "photo_url": "865685d2-32f6-605e-804b-db8e46ef160f.png",
            "phone_number": "4253193484",
            "email_address": "104633@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a83a3dbb-3c78-44e0-b3b5-67ae2fa83f56"
        },
        {
          "id": 1456,
          "person": {
            "id": 1456,
            "first_name": "Maira",
            "last_name": "Bonilla",
            "photo_url": "assets/r2e11cafcb85547d4b0e5ccdc7ef88728_maira2_t.jpg",
            "phone_number": "",
            "email_address": "214607@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-08-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18d1f57d-b769-4dfc-a423-03df06edcffb"
        },
        {
          "id": 11295,
          "person": {
            "id": 11295,
            "first_name": "Man",
            "last_name": "Chan",
            "photo_url": "3bb4ecee-c75e-d9fa-058e-06f804798a82.png",
            "phone_number": "",
            "email_address": "221824@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1573,
              "name": "Night",
              "contract": {
                "id": 2148,
                "name": "Lincoln Square 2",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "0158ddd5-982a-4899-adcf-d8bf368c44ee",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "14d412d3-8f72-422b-8811-1e8366d6dfb5"
        },
        {
          "id": 3134,
          "person": {
            "id": 3134,
            "first_name": "Mang",
            "last_name": "Ngu",
            "photo_url": "982d071d-ef20-6ec3-588e-1201fe6f5f77.png",
            "phone_number": "4253896608",
            "email_address": "221651@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "386ff7f8-7123-47de-8cab-fddda8fbb7f9"
        },
        {
          "id": 8333,
          "person": {
            "id": 8333,
            "first_name": "Manh",
            "last_name": "La",
            "photo_url": "48d21370-c2ce-41a4-5d5b-8a3b2a703b57.png",
            "phone_number": "4253899566",
            "email_address": "221645@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3299a0fc-7bb6-4adb-9168-da8a3680c9ac"
        },
        {
          "id": 5015,
          "person": {
            "id": 5015,
            "first_name": "Manuel",
            "last_name": "Andrade Alves Goncalves",
            "photo_url": "b6818f83-4057-6cbf-0001-4d21993ab84f.png",
            "phone_number": "",
            "email_address": "224431@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "418e7664-2235-4506-9a05-5b4849464228"
        },
        {
          "id": 6008,
          "person": {
            "id": 6008,
            "first_name": "Manuel",
            "last_name": "Freyre",
            "photo_url": "assets/4dd375cbcdba452c81219bcb7405fa56_IMG_20221104_42774_t.jpg",
            "phone_number": "",
            "email_address": "222026@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "461eec81-ccf4-4b32-b46d-620ff4b6d6af"
        },
        {
          "id": 10119,
          "person": {
            "id": 10119,
            "first_name": "Manuel",
            "last_name": "Jaquez",
            "photo_url": "22f80d16-9137-12ac-94c1-de7111a0e865.png",
            "phone_number": "",
            "email_address": "224354@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e689aa33-26d8-4993-b309-30c2e90f5638"
        },
        {
          "id": 11369,
          "person": {
            "id": 11369,
            "first_name": "Manuel",
            "last_name": "Ortiz",
            "photo_url": "8266c137-c9ec-7d7f-9dea-787663d7a4cd.png",
            "phone_number": "",
            "email_address": "209529@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2661,
              "name": "Swing",
              "contract": {
                "id": 1766,
                "name": "Pleasanton",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0a20384c-68e6-4c12-9468-cc9f3ec518fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9e4898a-e235-4d7e-a475-0de883b29541"
        },
        {
          "id": 3031,
          "person": {
            "id": 3031,
            "first_name": "Manuel",
            "last_name": "Ortiz Huezo",
            "photo_url": "84e96e20-1c09-c121-2aff-6ec82aeaa844.png",
            "phone_number": "",
            "email_address": "226667@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2fcba11b-dfd8-4a58-b926-45161634e319"
        },
        {
          "id": 6948,
          "person": {
            "id": 6948,
            "first_name": "Marcella",
            "last_name": "Kovats",
            "photo_url": "assets/1589307207.1345258_20200512_102234_resized_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-12-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cbc80b8a-f039-4d0a-b5d3-82adad6280fe"
        },
        {
          "id": 12367,
          "person": {
            "id": 12367,
            "first_name": "Marcia",
            "last_name": "McCarthy",
            "photo_url": "631ebc50-6f03-ace4-7ef3-db5ee4558257.png",
            "phone_number": "",
            "email_address": "218517@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ffe4042-0a89-4f40-a6c6-b94ebc828d9f"
        },
        {
          "id": 3425,
          "person": {
            "id": 3425,
            "first_name": "Margarita",
            "last_name": "Reyes",
            "photo_url": "b301e804-b4a3-9241-6126-54cd60666f6b.png",
            "phone_number": "",
            "email_address": "103758@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2009-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d08f51fe-7ec2-445a-a763-a0ade52aaad5"
        },
        {
          "id": 4648,
          "person": {
            "id": 4648,
            "first_name": "Maria",
            "last_name": "Aceves Gonzalez",
            "photo_url": "assets/29e4f10b383b43f5946782a4e5f4f296_20221116_110355_t.jpg",
            "phone_number": "",
            "email_address": "220445@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a69e09d9-d652-4997-a093-1f7b1fcfe375"
        },
        {
          "id": 6030,
          "person": {
            "id": 6030,
            "first_name": "Maria",
            "last_name": "Alvarez Morales",
            "photo_url": "7bcfa6d7-8ab0-9e3d-3992-0b466b4cf769.png",
            "phone_number": "5408201143",
            "email_address": "malvarez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6387,
              "name": "Day",
              "contract": {
                "id": 4227,
                "name": "Irvine",
                "customer_id": 1648,
                "customer_name": "TGS",
                "uuid": "921caa5d-b478-4139-830f-4e8df009b630",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-07-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a0a7de17-cf42-4b90-a192-1d4c90883345"
        },
        {
          "id": 7037,
          "person": {
            "id": 7037,
            "first_name": "Maria",
            "last_name": "Brenis",
            "photo_url": "assets/a190dc7402a246ec98625099a7608c4b_image_6483441_t.JPG",
            "phone_number": "4252688675",
            "email_address": "103954@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6273e521-41eb-4ed3-9a95-bd510c18b62c"
        },
        {
          "id": 2862,
          "person": {
            "id": 2862,
            "first_name": "Maria",
            "last_name": "Chavez",
            "photo_url": "2d2004af-b8e0-b3af-7adf-33de71550766.png",
            "phone_number": "4848388977",
            "email_address": "100816@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-08-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "57b729a9-42f0-491a-b45b-0e966ac73771"
        },
        {
          "id": 1182,
          "person": {
            "id": 1182,
            "first_name": "Maria",
            "last_name": "Contreras",
            "photo_url": "assets/21895bfe8d534bae9875cf2706679562_IMG_20221109_57678_t.jpg",
            "phone_number": "408-772-4807",
            "email_address": "220618@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-09-19",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e130f062-eed4-45c8-b8d8-3e38a9918a70"
        },
        {
          "id": 4274,
          "person": {
            "id": 4274,
            "first_name": "Maria",
            "last_name": "Cornejo",
            "photo_url": "d75cf49d-f409-e9cb-7b3f-b0b095016034.png",
            "phone_number": "",
            "email_address": "202290@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "849d82fa-fcc8-4404-8de0-0195bed56b7c"
        },
        {
          "id": 1253,
          "person": {
            "id": 1253,
            "first_name": "Maria",
            "last_name": "De La Luz Sanchez",
            "photo_url": "assets/199b0324299644cab347888a4ece0591_MariaSanchezProfilePicture_t.jpg",
            "phone_number": "",
            "email_address": "202270@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ef53fb6a-cc08-402e-8561-ad259daff0f8"
        },
        {
          "id": 1536,
          "person": {
            "id": 1536,
            "first_name": "Maria",
            "last_name": "Delcid",
            "photo_url": "170edc3f-5efe-cf99-64c1-9492eef45d40.png",
            "phone_number": "9192133207",
            "email_address": "mdelcid@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1131dae7-f9d9-4ff8-b693-82afe610807c"
        },
        {
          "id": 5539,
          "person": {
            "id": 5539,
            "first_name": "Maria",
            "last_name": "Estrada Perez",
            "photo_url": "assets/4fe96fc264024c1aa3a6b08a64e5c626_IMG_20221104_46231_t.jpg",
            "phone_number": "",
            "email_address": "222089@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3a80f919-1495-47e1-8226-43454195c354"
        },
        {
          "id": 6836,
          "person": {
            "id": 6836,
            "first_name": "Maria",
            "last_name": "Gomez",
            "photo_url": "assets/fc00680176b241ac8df84ef009ef13e2_8_t.jpg",
            "phone_number": "",
            "email_address": "211263@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae40f8ae-1220-4294-a852-7509cce408ba"
        },
        {
          "id": 2711,
          "person": {
            "id": 2711,
            "first_name": "Maria",
            "last_name": "Gurrola",
            "photo_url": "userprofile/ks9d3bijrprpvznjbj2u",
            "phone_number": "",
            "email_address": "209929@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7201,
              "name": "Swing",
              "contract": {
                "id": 5495,
                "name": "McKnight Road",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-08-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6738e8f8-6475-48ce-9119-8bf0ef9d7a4f"
        },
        {
          "id": 9013,
          "person": {
            "id": 9013,
            "first_name": "Maria",
            "last_name": "Guzman",
            "photo_url": "assets/93253a6bc3aa45c5b453ff0d1e69656a_IMG_20221104_38447_t.jpg",
            "phone_number": "",
            "email_address": "221991@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9172bf60-06ff-4c4d-af64-b99811f1b4cf"
        },
        {
          "id": 1192,
          "person": {
            "id": 1192,
            "first_name": "Maria",
            "last_name": "Herrera",
            "photo_url": "ea1200ec-8a51-cd34-d3e6-056709ce5ea0.png",
            "phone_number": "9196199007",
            "email_address": "mherrera@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-10-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91bc116b-39b8-40ac-ac67-af7d14c5390b"
        },
        {
          "id": 10446,
          "person": {
            "id": 10446,
            "first_name": "Maria",
            "last_name": "Lara",
            "photo_url": "assets/a3a1613378fe451caf97a3ade8dd692c_Resized_IMG_0807_t.jpg",
            "phone_number": "",
            "email_address": "220439@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2141,
              "name": "Night",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e8af2b17-555f-4e12-91fe-20772185e53e"
        },
        {
          "id": 9273,
          "person": {
            "id": 9273,
            "first_name": "Maria",
            "last_name": "Martinez",
            "photo_url": "1f78cabf-b619-c77e-b365-0305fc38d4bd.png",
            "phone_number": "",
            "email_address": "226717@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0316841c-0ec5-48d2-bee5-e36d5b940a49"
        },
        {
          "id": 4058,
          "person": {
            "id": 4058,
            "first_name": "Maria",
            "last_name": "Monico",
            "photo_url": "5a44fda2-43c3-5716-644a-9c0ffed27bbb.png",
            "phone_number": "",
            "email_address": "100739@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3953c7dc-0ab4-4753-8da9-8faff7802fa3"
        },
        {
          "id": 2272,
          "person": {
            "id": 2272,
            "first_name": "Maria",
            "last_name": "Ortega Meraz",
            "photo_url": "assets/958c136c924746738ff995d0f5900e33_IMG_20221104_47433_t.jpg",
            "phone_number": "",
            "email_address": "222093@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ff153a8-bad5-4862-b2ce-9b395f00603a"
        },
        {
          "id": 2345,
          "person": {
            "id": 2345,
            "first_name": "Maria",
            "last_name": "Perez",
            "photo_url": "1dcc2ebf-27a7-21d1-2bea-dc8bed794042.png",
            "phone_number": "7209651056",
            "email_address": "103453@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "de846cab-237e-4c41-a040-6250898426a3"
        },
        {
          "id": 7065,
          "person": {
            "id": 7065,
            "first_name": "Maria",
            "last_name": "Rodriguez Garcia",
            "photo_url": "92aebb56-b4be-4a5b-0ce9-1e55666b29bc.png",
            "phone_number": "",
            "email_address": "202335@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e351047d-615d-45a9-8ba6-76ddaa3d9a0b"
        },
        {
          "id": 6656,
          "person": {
            "id": 6656,
            "first_name": "Maria",
            "last_name": "Romero Maldonado",
            "photo_url": "5945e26b-35c0-352c-cd76-fddcd01399a4.png",
            "phone_number": "",
            "email_address": "221934@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0cd028f6-63cc-4a06-baab-4fc7ef5a6a11"
        },
        {
          "id": 6318,
          "person": {
            "id": 6318,
            "first_name": "Maria",
            "last_name": "Sanchez Manzanarez",
            "photo_url": "930a9a81-e288-857f-5d78-fa0cc9051973.png",
            "phone_number": "714-726-4921",
            "email_address": "204967@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2999,
              "name": "Day",
              "contract": {
                "id": 2570,
                "name": "Santa Ana",
                "customer_id": 468,
                "customer_name": "Deutsche Bank",
                "uuid": "8656fe7b-7898-48d3-909b-e0211ce3c01e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-08-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1d532055-1823-43c1-a2b1-37eec5f51cc1"
        },
        {
          "id": 11053,
          "person": {
            "id": 11053,
            "first_name": "Maria",
            "last_name": "Valdez",
            "photo_url": "891ecd49-85e5-dd34-3bec-8c6ecd66e4e6.png",
            "phone_number": "",
            "email_address": "224889@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "05e31f62-a812-4fdb-acb9-68064c440c2c"
        },
        {
          "id": 11949,
          "person": {
            "id": 11949,
            "first_name": "Maribel",
            "last_name": "Emigdio Leal",
            "photo_url": "d8a79b34-dc65-2f45-bb8f-6b68844412b9.png",
            "phone_number": "",
            "email_address": "221740@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "37a7627b-f515-4ad7-9943-5089c2d9710c"
        },
        {
          "id": 1774,
          "person": {
            "id": 1774,
            "first_name": "Marilin",
            "last_name": "Custodio De Montilla",
            "photo_url": "051730db-fd36-a5ff-1223-8fd47e688f22.png",
            "phone_number": "",
            "email_address": "207323@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-02-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aa95e098-f5a2-4743-8cc1-6919cd748386"
        },
        {
          "id": 3055,
          "person": {
            "id": 3055,
            "first_name": "Mario",
            "last_name": "Amador Sanchez",
            "photo_url": "assets/7a59d0fee98e4871b30ccfbb315b649a_IMG_20221104_58528_t.jpg",
            "phone_number": "",
            "email_address": "222098@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "958ce906-980e-4723-b48f-fa1d65b3f9cf"
        },
        {
          "id": 4467,
          "person": {
            "id": 4467,
            "first_name": "Marjorie",
            "last_name": "Colon",
            "photo_url": "e35aef7a-56af-2f54-08f7-4a26167553ac.png",
            "phone_number": "",
            "email_address": "226740@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d3f17a1d-73b2-4d64-b38a-affcc58d7f87"
        },
        {
          "id": 6257,
          "person": {
            "id": 6257,
            "first_name": "Mark",
            "last_name": "Schlerf",
            "photo_url": "assets/r5650e02bde4045fbbfdfbb393a84f241_Mark_headshot_green2_t.jpg",
            "phone_number": "5034907657",
            "email_address": "mschlerf@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 8156,
              "name": "Day",
              "contract": {
                "id": 6019,
                "name": "JONE63OVRHD",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "0c845a54-141b-4548-810b-81ab74389853",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8711,
              "name": "Day",
              "contract": {
                "id": 5975,
                "name": "Tualatin",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "11bc06cf-9168-4f77-ba29-cfb40fb2b445",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            },
            {
              "id": 8739,
              "name": "Day",
              "contract": {
                "id": 5976,
                "name": "Hillsboro",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "8c6baf1d-0b07-4a46-a81d-fe731ca22887",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8700,
              "name": "Day",
              "contract": {
                "id": 5974,
                "name": "Livermore",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "2c83fabc-e313-4b7a-b93e-ffe32d5c3a29",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8665,
              "name": "Day",
              "contract": {
                "id": 5973,
                "name": "Fremont",
                "customer_id": 2240,
                "customer_name": "Lam Research (CBRE)",
                "uuid": "ccd712dd-41eb-4d2c-883a-a143683ca295",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-07-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3118925e-ff7a-4568-a236-763f50555ed0"
        },
        {
          "id": 5907,
          "person": {
            "id": 5907,
            "first_name": "Mark",
            "last_name": "Scullywest",
            "photo_url": "264f0b63-5380-689b-d1ee-10b07a90ca01.png",
            "phone_number": "",
            "email_address": "221847@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d1b72c01-2947-4865-8a68-9b1610fc2f22"
        },
        {
          "id": 4439,
          "person": {
            "id": 4439,
            "first_name": "Mark",
            "last_name": "Tannenbaum",
            "photo_url": "assets/1d376d87348e454598c7f61168468a49_mark_t.jpg",
            "phone_number": "3176462443",
            "email_address": "mtannenbaum@sbmcorp.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ee73b419-221b-44be-89cf-b367110d9cfa"
        },
        {
          "id": 7945,
          "person": {
            "id": 7945,
            "first_name": "Markra",
            "last_name": "Bun",
            "photo_url": "07c7ce92-360f-eb5e-2499-17cd0f51da3c.png",
            "phone_number": "",
            "email_address": "127393@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5b44b194-dc24-4025-bde2-6199eed771f9"
        },
        {
          "id": 2133,
          "person": {
            "id": 2133,
            "first_name": "Marquita",
            "last_name": "Brown",
            "photo_url": "ce2d101e-d804-e495-ec01-1ce40770a9d8.png",
            "phone_number": "2068876032",
            "email_address": "MBrown@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 7667,
              "name": "Day",
              "contract": {
                "id": 5775,
                "name": "AOC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "08bbd6f3-a6c7-44ac-a430-0f57662ddf77",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7718,
              "name": "Day",
              "contract": {
                "id": 5790,
                "name": "CIC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "0646f543-0824-4de7-89a6-2af2c217f0b4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7714,
              "name": "Swing",
              "contract": {
                "id": 5777,
                "name": "WOCC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "bf636c4e-c789-4e9e-b3fd-cf5c9f705bab",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7715,
              "name": "Swing",
              "contract": {
                "id": 5793,
                "name": "Northyard",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "76682df0-5ad2-44ec-ab64-7bf1f45f8376",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7716,
              "name": "Swing",
              "contract": {
                "id": 5792,
                "name": "PETC",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "29dba90e-76e1-4a8f-93be-eeefa24cff6b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7717,
              "name": "Swing",
              "contract": {
                "id": 5794,
                "name": "Fulton County Airport Hangar",
                "customer_id": 2145,
                "customer_name": "Coca Cola",
                "uuid": "8baabbd9-e6e5-413f-abbb-be0d5d268562",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-09-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "83a4305a-9af2-40a0-94c1-0f92f584fb37"
        },
        {
          "id": 12172,
          "person": {
            "id": 12172,
            "first_name": "Martha",
            "last_name": "Rivero Solis",
            "photo_url": "assets/f617d767c99e431691140cec036203f0_IMG_20221104_59466_t.jpg",
            "phone_number": "",
            "email_address": "107433@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ae7f9f3-dbda-4334-83c3-a02b2f8f759c"
        },
        {
          "id": 9513,
          "person": {
            "id": 9513,
            "first_name": "Mary",
            "last_name": "Fowler",
            "photo_url": "10598987-6edf-5d93-4fad-e8de45fc46c9.png",
            "phone_number": "",
            "email_address": "220287@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-08-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8062847e-e8c0-49be-b409-8f7c0b534a28"
        },
        {
          "id": 8769,
          "person": {
            "id": 8769,
            "first_name": "Matthew",
            "last_name": "Schaefer",
            "photo_url": "e97cbb7b-d15f-a55a-7bc5-7d1c39dd9a98.png",
            "phone_number": "9475175753",
            "email_address": "223470@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-03-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0d3dee35-261d-429b-afef-996f23302081"
        },
        {
          "id": 9645,
          "person": {
            "id": 9645,
            "first_name": "Matthew",
            "last_name": "Schornack",
            "photo_url": "ddd150b3-9852-3978-b906-1c022a05cc14.png",
            "phone_number": "",
            "email_address": "218184@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b713afb7-0f28-4211-ab25-8faf527a2c25"
        },
        {
          "id": 1578,
          "person": {
            "id": 1578,
            "first_name": "Maureen",
            "last_name": "Sims",
            "photo_url": "assets/dcf87daed29e4b9baabf6e1b9e917fb9_Maureen_t.jpg",
            "phone_number": "",
            "email_address": "192308@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-09-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae2a242c-073b-45a9-a878-be0c15d348f7"
        },
        {
          "id": 6348,
          "person": {
            "id": 6348,
            "first_name": "Maurice",
            "last_name": "Pindra",
            "photo_url": "90e747e6-2d1e-9dfe-152a-4d5127f25a3f.png",
            "phone_number": "2154446695",
            "email_address": "mpindra@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8efbf362-bf47-4f02-bdcf-8816985cdd26"
        },
        {
          "id": 9540,
          "person": {
            "id": 9540,
            "first_name": "Mauro",
            "last_name": "Barreto",
            "photo_url": "013bf1f1-c510-9a40-bf41-9105db0eefec.png",
            "phone_number": "",
            "email_address": "212724@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-03-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2b10cc77-3534-41d8-8ba3-f0dd507157d9"
        },
        {
          "id": 1479,
          "person": {
            "id": 1479,
            "first_name": "Maxima",
            "last_name": "Martinez Perez",
            "photo_url": "c94beb60-ae30-c063-7236-75a31505b920.png",
            "phone_number": "",
            "email_address": "215730@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 4692,
              "name": "Night",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2016-10-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5126b15-a6d6-4091-87fc-f0ef76a9104e"
        },
        {
          "id": 11970,
          "person": {
            "id": 11970,
            "first_name": "Mayada",
            "last_name": "Defar",
            "photo_url": "4705b7ab-b3ff-3002-197e-ad84ed024649.png",
            "phone_number": "425-529-3028",
            "email_address": "221586@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "13ab7070-f88e-4d69-bf01-609dc074da43"
        },
        {
          "id": 7573,
          "person": {
            "id": 7573,
            "first_name": "Mayra",
            "last_name": "Vega",
            "photo_url": "assets/322c8e1053c44a939181a98da23fa046_Screenshot_20220418061352_TikTok.jpgMV_t.jpg",
            "phone_number": "2156922587",
            "email_address": "mvega1@sbmcorp.com"
          },
          "positions": [
            {
              "id": 799,
              "name": "Mgr, Office",
              "name_abbreviation": "OFFMGR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2013-01-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9908ce41-01b7-4f48-b57e-b353cf086ebe"
        },
        {
          "id": 8590,
          "person": {
            "id": 8590,
            "first_name": "Mediha",
            "last_name": "Kunovac",
            "photo_url": "c57d7918-2b05-836f-7946-2b59a4ab2d05.png",
            "phone_number": "",
            "email_address": "102335@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5840d822-09b3-4fe6-bd10-ad14357a0cbf"
        },
        {
          "id": 3144,
          "person": {
            "id": 3144,
            "first_name": "Mekh",
            "last_name": "Shrestha",
            "photo_url": "57f44081-f68a-3247-cf13-0c950f3b87e3.png",
            "phone_number": "",
            "email_address": "221823@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b2d2cc21-ef5c-492f-9dfe-04cd219fad65"
        },
        {
          "id": 67,
          "person": {
            "id": 67,
            "first_name": "Melania",
            "last_name": "Santos De Zavala",
            "photo_url": "3f7a8fe5-7972-195a-3bf1-52fbdc4d593d.png",
            "phone_number": "267-575-1635",
            "email_address": "104155@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "606ff9f9-2b70-4145-b5b6-3509726dc6d2"
        },
        {
          "id": 14080,
          "person": {
            "id": 14081,
            "first_name": "Melissa",
            "last_name": "Sanford",
            "photo_url": "41fd825c-ce0a-63c1-96d4-3a323caaacc8.png",
            "phone_number": "",
            "email_address": "225315@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7815dceb-7062-41e4-9e9e-8e892cc6c7ea"
        },
        {
          "id": 10777,
          "person": {
            "id": 10777,
            "first_name": "Melkamsira",
            "last_name": "Nazeze",
            "photo_url": "a6fb2813-73bb-71f0-3e3c-42dfb093e131.png",
            "phone_number": "",
            "email_address": "221848@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c0f51e03-4672-4272-b397-70ba4f1baa93"
        },
        {
          "id": 4605,
          "person": {
            "id": 4605,
            "first_name": "Melvin",
            "last_name": "Basilio",
            "photo_url": "509db9c6-1884-622a-5dec-082b9400e5a2.png",
            "phone_number": "(267)231-3856",
            "email_address": "200150@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2013-09-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae270e39-e31d-49c8-94ff-17ccc7d46e8a"
        },
        {
          "id": 11132,
          "person": {
            "id": 11132,
            "first_name": "Melvin",
            "last_name": "Murphy",
            "photo_url": "assets/73307b0d125e4709a6c17bcbf2793f6f_mel_t.jpg",
            "phone_number": "",
            "email_address": "202243@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d75cd62c-69df-4a34-a166-96523b886a6d"
        },
        {
          "id": 7814,
          "person": {
            "id": 7814,
            "first_name": "Mengistu",
            "last_name": "Bereka",
            "photo_url": "d4d64b99-089d-a4a4-31e8-affbdc3af6db.png",
            "phone_number": "",
            "email_address": "221648@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "335aa327-b9bf-4db8-9956-b4238bf0ee1f"
        },
        {
          "id": 4939,
          "person": {
            "id": 4939,
            "first_name": "Mercedita",
            "last_name": "Baguistan",
            "photo_url": "edab973b-01f1-21d7-34a2-d7b26f8fa2a9.png",
            "phone_number": "4252456348",
            "email_address": "221787@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ee980649-c44c-4ecb-83e2-46a3c18008d1"
        },
        {
          "id": 3001,
          "person": {
            "id": 3001,
            "first_name": "Merly",
            "last_name": "Carranza",
            "photo_url": "assets/9028922c10214283b9ef552098b0d3c8_MerlyCarranza_t.jpg",
            "phone_number": "",
            "email_address": "226844@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2419,
              "name": "Swing",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f8f808a5-bdca-40bb-970c-64872c84e532"
        },
        {
          "id": 3692,
          "person": {
            "id": 3692,
            "first_name": "Michelle",
            "last_name": "Bowser-Buford",
            "photo_url": "ffe190f6-4791-03b4-8270-606ef575dc7f.png",
            "phone_number": "",
            "email_address": "218174@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b285e33-98a5-4b54-8591-c5c526be7c74"
        },
        {
          "id": 361,
          "person": {
            "id": 361,
            "first_name": "Miguel",
            "last_name": "Deras",
            "photo_url": "48576ca1-77c2-ecb0-6c71-795e38ace4df.png",
            "phone_number": "",
            "email_address": "226732@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f72932f9-8ef0-4712-b066-c4297cbb7f0e"
        },
        {
          "id": 4213,
          "person": {
            "id": 4213,
            "first_name": "Miguel",
            "last_name": "Gonzalez Baez",
            "photo_url": "bc9a975a-87ed-41f4-c85d-f2f652d9a4f1.png",
            "phone_number": "",
            "email_address": "226766@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "558f63a6-7987-41df-9165-276b0b57cc19"
        },
        {
          "id": 12305,
          "person": {
            "id": 12305,
            "first_name": "Miguel",
            "last_name": "Moreno",
            "photo_url": "df1cf3b3-dc15-2fd9-a031-fe1c3d0520e1.png",
            "phone_number": "",
            "email_address": "226730@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f623ed46-fe40-4d09-935a-033cab9b6d3e"
        },
        {
          "id": 10627,
          "person": {
            "id": 10627,
            "first_name": "Miguel",
            "last_name": "Ramirez",
            "photo_url": "d8a6306a-9a5d-12e2-acd1-6bb463d58cd5.png",
            "phone_number": "",
            "email_address": "222649@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-01-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06b45bb3-c4a1-468c-9e0b-e640e56ef46e"
        },
        {
          "id": 11494,
          "person": {
            "id": 11494,
            "first_name": "Mikhail",
            "last_name": "Koshelev",
            "photo_url": "3cd853e3-e95a-b39b-62c5-b683c5a7af97.png",
            "phone_number": "",
            "email_address": "224241@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-05-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8d7c17dc-628d-46cd-899a-0893c1203f5c"
        },
        {
          "id": 2583,
          "person": {
            "id": 2583,
            "first_name": "Milagros",
            "last_name": "Acosta",
            "photo_url": "assets/d7a08c2d50c6424ca15d45f22adda87f_Milagros_t.jpg",
            "phone_number": "",
            "email_address": "202362@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aa962834-16cf-428b-80a7-3f4b9e7232a0"
        },
        {
          "id": 8990,
          "person": {
            "id": 8990,
            "first_name": "Milaika",
            "last_name": "Perez Morales",
            "photo_url": "75ca08f0-72f3-6446-08c2-d2189cd3dfc1.png",
            "phone_number": "",
            "email_address": "219510@placeholder.com"
          },
          "positions": [
            {
              "id": 32,
              "name": "Maintenance Tech",
              "name_abbreviation": "MAINTC"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "aa425bbd-e184-41f9-a7c4-7caabe592dc5"
        },
        {
          "id": 883,
          "person": {
            "id": 883,
            "first_name": "Mingchi",
            "last_name": "Lei",
            "photo_url": "0d251d00-f786-ef5e-778a-fe9b206eeac0.png",
            "phone_number": "",
            "email_address": "223091@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-03-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "48ac24a8-97ea-43aa-877f-056603d342ee"
        },
        {
          "id": 1732,
          "person": {
            "id": 1732,
            "first_name": "Minh",
            "last_name": "Nguyen",
            "photo_url": "d3724af8-8e7f-c82b-be37-39ee0138a74e.png",
            "phone_number": "",
            "email_address": "221625@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fed0bbb4-dd11-45f2-b5af-46b4baa60d88"
        },
        {
          "id": 11598,
          "person": {
            "id": 11598,
            "first_name": "Miriam",
            "last_name": "Ledezma",
            "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
            "phone_number": "8174010249",
            "email_address": "mledezma@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 1144,
              "name": "Arden",
              "contract": {
                "id": 2048,
                "name": "Arden Fair Mall (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4ac8e080-e0ad-438c-bd4b-429fab6acc2b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1296,
              "name": "Day",
              "contract": {
                "id": 1364,
                "name": "WELL04OVHD",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "7941ea23-471b-405b-a617-203aa94194b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2021,
              "name": "Day",
              "contract": {
                "id": 1674,
                "name": "Denver",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f832f54-fcd8-405e-8c07-118df970d6af",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7454,
              "name": "Day",
              "contract": {
                "id": 5639,
                "name": "El Monte (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "38f98250-7014-43d5-8b80-16a98ee2748f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 2104,
              "name": "Day",
              "contract": {
                "id": 1324,
                "name": "Illinois",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "276cc3c8-5a57-48c5-8f8d-9c3639b048e2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7177,
              "name": "Day",
              "contract": {
                "id": 5485,
                "name": "Pinnacle Peak (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c6c40b90-fd55-4595-823d-cab984b85a15",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7486,
              "name": "Day",
              "contract": {
                "id": 5636,
                "name": "San Bernardino Main (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "fdcf72af-9050-4465-a007-0c4f715773cc",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7447,
              "name": "Day",
              "contract": {
                "id": 5491,
                "name": "Shoreview Data Center",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bb785cef-87b8-435e-b5a6-c0cb74728cc9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7509,
              "name": "Day",
              "contract": {
                "id": 5496,
                "name": "Shoreview Operations CTR",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f1092be9-b555-4468-a418-b64cb67dd8a0",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7514,
              "name": "Day",
              "contract": {
                "id": 5500,
                "name": "Sioux Falls Main",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7277,
              "name": "Day",
              "contract": {
                "id": 5554,
                "name": "Sunset Park (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "a6620956-b404-4fe4-854a-ed0ff498607d",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 5597,
              "name": "Day",
              "contract": {
                "id": 3337,
                "name": "Tempe Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "e05b0b1e-f4c4-4202-8a5a-7f959cbbb528",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7451,
              "name": "Day",
              "contract": {
                "id": 5543,
                "name": "University Building",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "5727ea88-f532-4365-aff8-6881a98a80d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7285,
              "name": "Day",
              "contract": {
                "id": 5567,
                "name": "West Des Moines Campus",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "70f9f047-fbdb-4c50-b293-76bd9d350897",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7172,
              "name": "Day",
              "contract": {
                "id": 5484,
                "name": "WF Home Equity (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "5749b27c-2ef9-4a49-8b4d-9749dab484e6",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7276,
              "name": "Day",
              "contract": {
                "id": 5555,
                "name": "William Barnhart Center (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "44f15fca-ac80-4b6c-9bf9-4b602e84ffb9",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7259,
              "name": "Day",
              "contract": {
                "id": 5551,
                "name": "333 Market (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "f7d4a594-a35d-40ca-9396-0f9db82700ff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7226,
              "name": "Day",
              "contract": {
                "id": 5511,
                "name": "3rd - Brannan",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "a8f911c1-11e3-4fa0-8382-2f0a79f6457b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7287,
              "name": "Day",
              "contract": {
                "id": 5566,
                "name": "550 California (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "78c1e2c7-0d04-4da4-834d-f78a80e70012",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7173,
              "name": "Day",
              "contract": {
                "id": 5486,
                "name": "Butano (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "806dc5df-332a-41ea-939a-6131fe8bd94a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7480,
              "name": "Day",
              "contract": {
                "id": 5502,
                "name": "COB (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d75c0c67-9c54-469a-8168-0a35004c4430",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7443,
              "name": "Day",
              "contract": {
                "id": 5483,
                "name": "Continuum (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "655efe44-7c11-454c-86e3-aaeb6600968a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 566,
              "name": "Night",
              "contract": {
                "id": 1326,
                "name": "Boise (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4e3ee1f9-e6e4-4e01-ab9e-f4bbd35efa6a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            },
            {
              "id": 7210,
              "name": "None",
              "contract": {
                "id": 5515,
                "name": "Palo Alto",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c20eb515-c411-4f88-b294-55ac5285a221",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7236,
              "name": "None",
              "contract": {
                "id": 5553,
                "name": "Warner Ranch (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "bf1b65f9-632f-435b-8e7a-17ad26807fbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1143,
              "name": "Santa Rosa",
              "contract": {
                "id": 2013,
                "name": "Santa Rosa Main, CA (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "32daae15-b115-43b6-b535-ae77f7fe38b4",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7551,
              "name": "Swing",
              "contract": {
                "id": 5652,
                "name": "Huntington Park (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "dac59c29-ebfb-48b0-b24c-e9d2892f4045",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7201,
              "name": "Swing",
              "contract": {
                "id": 5495,
                "name": "McKnight Road",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1f85216f-1688-4aa5-b58a-a11c20ba3230",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7203,
              "name": "Swing",
              "contract": {
                "id": 5498,
                "name": "Old Saint Anthony/OSA*",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d0c33c72-837f-4228-82c4-0768520e8adf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7487,
              "name": "Swing",
              "contract": {
                "id": 5499,
                "name": "Ridgedale",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c2efcba7-ff51-44fe-acaa-30ec3842272f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7211,
              "name": "Swing",
              "contract": {
                "id": 5523,
                "name": "San Francisco/Head Office (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "98ed1974-2405-49f9-96f9-e1c60e22ef93",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7221,
              "name": "Swing",
              "contract": {
                "id": 5520,
                "name": "San Leandro Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "080cf858-d8ec-4300-9a1a-9f90a2b1c6ca",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7202,
              "name": "Swing",
              "contract": {
                "id": 5497,
                "name": "Central",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "15171fe2-1370-40c7-b4d7-d9a261001aff",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7546,
              "name": "Swing",
              "contract": {
                "id": 5532,
                "name": "Columbus Main (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "43e83099-aa43-4e5e-9a3f-70dd39d77047",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7215,
              "name": "Swing",
              "contract": {
                "id": 5522,
                "name": "Fremont Center Ops (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ce645c9b-9112-4fe0-86c9-6040179ba04c",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7474,
              "name": "unassigned",
              "contract": {
                "id": 5572,
                "name": "Headquarters HMHQ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "76aea59d-6532-4759-8887-190df768c9d5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 548,
              "name": "Unassigned",
              "contract": {
                "id": 1329,
                "name": "Virginia Beach*",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "02d82a59-236e-4a68-b3c8-d5e05eae546f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 867,
              "name": "Unassigned",
              "contract": {
                "id": 1673,
                "name": "Sioux Falls OPS",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "aca8d4a2-8a55-475a-b9de-0a583b9bdbb1",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 746,
              "name": "Unassigned",
              "contract": {
                "id": 1626,
                "name": "San Antonio, TX",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "1973bbd7-a085-4492-8554-f20fb50472b2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1273,
              "name": "Unassigned",
              "contract": {
                "id": 2089,
                "name": "Rose Garden Lane (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d53d9a30-6f23-4d3c-a8c7-70b91e7d715e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 7435,
              "name": "Unassigned",
              "contract": {
                "id": 5537,
                "name": "Philadelphia, PA",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "af711043-6700-43f9-8081-47d24bb11abf",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 148,
              "name": "Unassigned",
              "contract": {
                "id": 1321,
                "name": "ICB (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b2b272f8-f205-49f3-94a0-469a8b2407ce",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 1274,
              "name": "Unassigned",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 796,
              "name": "Unassigned",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 712,
              "name": "Unassigned",
              "contract": {
                "id": 1464,
                "name": "Wayzata",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "b08ac454-1105-429e-bcb1-12a3fadacc1f",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2011-04-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "963185e4-6637-457f-8d35-c9601413dcab"
        },
        {
          "id": 1954,
          "person": {
            "id": 1954,
            "first_name": "Mirsada",
            "last_name": "Lizde",
            "photo_url": "dd4464a4-f0df-4a94-5f25-4faa5e1d2a17.png",
            "phone_number": "",
            "email_address": "102471@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3c283cd3-3d9d-4729-9c9e-ac1b36fd94bf"
        },
        {
          "id": 2347,
          "person": {
            "id": 2347,
            "first_name": "Mitchell",
            "last_name": "Keller",
            "photo_url": "aae20bb9-8246-e32e-e847-51c5b563549f.png",
            "phone_number": "",
            "email_address": "218482@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "521adecd-6131-46e3-bf5d-82a6e00c3f3e"
        },
        {
          "id": 12553,
          "person": {
            "id": 12553,
            "first_name": "Mohamed",
            "last_name": "Mohamed",
            "photo_url": "618246b5-8d64-a4e2-ad2f-befc4ac7b14c.png",
            "phone_number": "",
            "email_address": "221911@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "854192bb-27f1-4f0f-b6c4-d355cc49a98b"
        },
        {
          "id": 58244,
          "person": {
            "id": 57708,
            "first_name": "Mom",
            "last_name": "Sman",
            "photo_url": "392c9476-a6a2-d300-6766-0db46180f445.png",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ecdbae26-2452-44f6-aaa9-6ac1a90addc9"
        },
        {
          "id": 749,
          "person": {
            "id": 749,
            "first_name": "Monica",
            "last_name": "Cernas",
            "photo_url": "assets/c98a5c38b7ea4ff4a6acd2b188f8e10e_IMG_20221104_46825_t.jpg",
            "phone_number": "",
            "email_address": "108172@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "29e7ad90-6cc6-4384-be97-278d67e7943c"
        },
        {
          "id": 3067,
          "person": {
            "id": 3067,
            "first_name": "Monica",
            "last_name": "Corrales",
            "photo_url": "assets/rb932c42d408f474f8b88686a68d6c1f2_fotomonica2_t.jpg",
            "phone_number": "7327165416",
            "email_address": "mcorrales@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2509,
              "name": "Swing",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9902367e-b6c7-4edd-9805-aaddffdfd99d"
        },
        {
          "id": 2157,
          "person": {
            "id": 2157,
            "first_name": "Monico",
            "last_name": "Martinez",
            "photo_url": "c25a89a0-7825-003d-bd06-2664e5ef4362.png",
            "phone_number": "",
            "email_address": "226736@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d4f6cb4-50bf-468d-b24e-e1c440eb845a"
        },
        {
          "id": 46,
          "person": {
            "id": 46,
            "first_name": "Mrija",
            "last_name": "Vulaj",
            "photo_url": "7b1a1bd8-066f-0b59-bd6c-cd63aba36621.png",
            "phone_number": "9475176845",
            "email_address": "218487@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6d187350-1a6b-47ef-9a96-57e3ea3a586b"
        },
        {
          "id": 6044,
          "person": {
            "id": 6044,
            "first_name": "Muharem",
            "last_name": "Gubeljic",
            "photo_url": "5d734200-e221-096a-5084-47e4c7a872c4.png",
            "phone_number": "4253736991",
            "email_address": "221624@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75255821-dcd4-44c5-9e9c-9f35904c29f6"
        },
        {
          "id": 11932,
          "person": {
            "id": 11932,
            "first_name": "Mui",
            "last_name": "Duong",
            "photo_url": "da5fe47d-c669-fa4d-828d-c5b30edd4958.png",
            "phone_number": "4252736435",
            "email_address": "101261@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fc2e1b61-2759-493f-bb7e-a0af08618736"
        },
        {
          "id": 7395,
          "person": {
            "id": 7395,
            "first_name": "Mui",
            "last_name": "Thong",
            "photo_url": "e2a48517-db0f-2039-45d6-d8739371c3cb.png",
            "phone_number": "",
            "email_address": "104519@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0484ab0c-6842-4b4d-b06c-6991bfe02c82"
        },
        {
          "id": 3455,
          "person": {
            "id": 3455,
            "first_name": "Muriel",
            "last_name": "Savoye",
            "photo_url": "14fd247a-3e68-f181-9f4a-011a126d469b.png",
            "phone_number": "",
            "email_address": "225234@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-08-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f79fcb87-bb8f-4931-a836-2c211c2d9ef6"
        },
        {
          "id": 2667,
          "person": {
            "id": 2667,
            "first_name": "Nadezhda",
            "last_name": "Khrapova",
            "photo_url": "05a541b5-6160-db9f-62b8-c9ed57c886cc.png",
            "phone_number": "",
            "email_address": "221758@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "87c95e7d-becc-49a6-9e65-4eb8839565bd"
        },
        {
          "id": 8410,
          "person": {
            "id": 8410,
            "first_name": "Nadiya",
            "last_name": "Nykytyuk",
            "photo_url": "352356b8-25b7-7f9e-639d-6d1ff3dbd196.png",
            "phone_number": "",
            "email_address": "225714@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 7514,
              "name": "Day",
              "contract": {
                "id": 5500,
                "name": "Sioux Falls Main",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "9eb611f0-f9ee-4f0c-8ce5-803a0d6e088a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c179983e-e4a3-47b5-a0f2-efe9b05518d7"
        },
        {
          "id": 8140,
          "person": {
            "id": 8140,
            "first_name": "Nai",
            "last_name": "Taejae",
            "photo_url": "assets/361e638087d246acb41acb0230b1e96f_NaisProfilePicture_t.jpg",
            "phone_number": "3176256890",
            "email_address": "ntaejae@sbmcorp.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d794ce3a-969b-48e3-aea3-5ed7cca54e0a"
        },
        {
          "id": 6781,
          "person": {
            "id": 6781,
            "first_name": "Nancy",
            "last_name": "Raffin",
            "photo_url": "5d8d7cd2-dd24-c420-c345-247872fac0ee.png",
            "phone_number": "",
            "email_address": "218177@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b5affd9f-cc19-43d4-9ad3-d318e337886a"
        },
        {
          "id": 102,
          "person": {
            "id": 102,
            "first_name": "Nataliya",
            "last_name": "Kushnirchuk",
            "photo_url": "8db9f475-3e54-e415-f795-3758c6d203ce.png",
            "phone_number": "",
            "email_address": "102339@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 3039,
              "name": "Day",
              "contract": {
                "id": 2590,
                "name": "Laguna South, WA",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "abb421e8-0707-42f0-b247-657048924721",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fdabd55e-be55-47bc-8ff7-cceb033cf590"
        },
        {
          "id": 9351,
          "person": {
            "id": 9351,
            "first_name": "Neftali",
            "last_name": "Aquino",
            "photo_url": "userprofile/tcumzk9e4iozz4sjvoyw",
            "phone_number": "",
            "email_address": "202281@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7ac42fef-e15e-4c46-a01c-8c02177cbe65"
        },
        {
          "id": 9579,
          "person": {
            "id": 9579,
            "first_name": "Nelson",
            "last_name": "Cespedes",
            "photo_url": "assets/72d1dd2bcb65419b9f6e916f9348ecf0_nelson_t.jpg",
            "phone_number": "",
            "email_address": "202288@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a4df6f4-b29d-4120-9530-b9cde55cc917"
        },
        {
          "id": 11794,
          "person": {
            "id": 11794,
            "first_name": "Ness",
            "last_name": "Ilog",
            "photo_url": "1af2d586-96ea-d322-d44d-b79699bad492.png",
            "phone_number": "",
            "email_address": "221647@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c448fc2f-8c83-4886-9c33-27e4c0c9e3bd"
        },
        {
          "id": 608,
          "person": {
            "id": 608,
            "first_name": "Neway",
            "last_name": "Admasu",
            "photo_url": "b6590321-f22b-7d28-990d-a4c5eec8ed8d.png",
            "phone_number": "",
            "email_address": "226543@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-11-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "066da251-640f-4c29-82f1-3cd4e95ca649"
        },
        {
          "id": 657,
          "person": {
            "id": 657,
            "first_name": "Nezira",
            "last_name": "Velic",
            "photo_url": "73e3a942-2812-32ed-735d-c77c50062c2e.png",
            "phone_number": "4253438785",
            "email_address": "104760@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e92180e2-f998-4d66-ae32-50977bcf74e5"
        },
        {
          "id": 11810,
          "person": {
            "id": 11810,
            "first_name": "Ngoc",
            "last_name": "Nguyen",
            "photo_url": "7cd99874-d6e8-2e3e-7d92-54462914f0f9.png",
            "phone_number": "",
            "email_address": "223964@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 2460,
              "name": "Swing",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-05-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ad8f6dd-8ca4-4202-96c8-3375214a07b6"
        },
        {
          "id": 5863,
          "person": {
            "id": 5863,
            "first_name": "Ngoi",
            "last_name": "Phan",
            "photo_url": "assets/6ee5f060acaf46eb9cbd076b312d838a_IMG_20211014_155827_t.jpg",
            "phone_number": "",
            "email_address": "221784@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9d6fd71-2d65-45c9-9e10-ab991a5e4045"
        },
        {
          "id": 10404,
          "person": {
            "id": 10404,
            "first_name": "Nhi",
            "last_name": "Thach",
            "photo_url": "29d968d6-6087-9a3d-1072-8845ff6b3315.png",
            "phone_number": "",
            "email_address": "104512@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "597b1550-ec36-4bac-8db2-e173ffcdeed1"
        },
        {
          "id": 10844,
          "person": {
            "id": 10844,
            "first_name": "Nicholas",
            "last_name": "Galiszewski",
            "photo_url": "assets/0430cad835a04f44914a1cea4f65bafb_ProfilePicture_t.JPG",
            "phone_number": "7329561217",
            "email_address": "NGaliszewski@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1175,
              "name": "Account Dir",
              "name_abbreviation": "NTADIR"
            }
          ],
          "shifts": [
            {
              "id": 1411,
              "name": "Day",
              "contract": {
                "id": 1798,
                "name": "Branchburg",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "e396a411-bfef-4d27-be77-046e5e3e3210",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-06-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7d18579a-dc71-4e82-a10e-322a8ae0bc0a"
        },
        {
          "id": 1495,
          "person": {
            "id": 1495,
            "first_name": "Nicholis",
            "last_name": "Wagner",
            "photo_url": "userprofile/rf5roycdta8838oy6ird",
            "phone_number": "",
            "email_address": "202134@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "42bead4e-b22f-4942-9dc6-9fd6c8ec9773"
        },
        {
          "id": 1170,
          "person": {
            "id": 1170,
            "first_name": "Nicol",
            "last_name": "Galicia Toribio",
            "photo_url": "57294523-2702-60e9-d71c-f5fefc886832.png",
            "phone_number": "",
            "email_address": "221666@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "06696aa4-9b43-42fc-80c7-aa4dc73293d8"
        },
        {
          "id": 763,
          "person": {
            "id": 763,
            "first_name": "Nicolae",
            "last_name": "Suciu",
            "photo_url": "7f089a82-b32e-2c8f-ba96-68fa27bc84c2.png",
            "phone_number": "",
            "email_address": "104447@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b843feb-5cc6-4859-be10-e6c7d0637e2e"
        },
        {
          "id": 10218,
          "person": {
            "id": 10218,
            "first_name": "Nisveta",
            "last_name": "Darkulic",
            "photo_url": "9558e50f-bb1c-b4bc-3dd2-90ad065b28f1.png",
            "phone_number": "4253829338",
            "email_address": "101030@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d51f62e3-777f-496a-9917-e1e79b91c411"
        },
        {
          "id": 3224,
          "person": {
            "id": 3224,
            "first_name": "Norman",
            "last_name": "Nelson",
            "photo_url": "c1e63627-5985-8b35-7d4f-dbda38869be4.png",
            "phone_number": "",
            "email_address": "218384@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1bd9cd96-430b-4651-b9ab-9f48ed9af868"
        },
        {
          "id": 1583,
          "person": {
            "id": 1583,
            "first_name": "Nyaluak",
            "last_name": "Kier",
            "photo_url": "07967121-0da8-b7d4-a587-5cbbc490b3f1.png",
            "phone_number": "",
            "email_address": "221694@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dc833c1e-046e-4931-8de2-78feb3517529"
        },
        {
          "id": 2151,
          "person": {
            "id": 2151,
            "first_name": "Odessa",
            "last_name": "Banks",
            "photo_url": "userprofile/gspt7elu9w4xry2bnkss",
            "phone_number": "",
            "email_address": "202131@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3069b354-42f9-40e4-8f2c-12de9501b57e"
        },
        {
          "id": 6526,
          "person": {
            "id": 6526,
            "first_name": "Ofelia",
            "last_name": "Fonseca Rosas",
            "photo_url": "assets/25e48d3489654c57853612fa13502e37_3_t.jpg",
            "phone_number": "",
            "email_address": "202285@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7822f6f2-ae9b-491a-8888-8a72ccd6a9cd"
        },
        {
          "id": 1934,
          "person": {
            "id": 1934,
            "first_name": "Olga",
            "last_name": "Gil",
            "photo_url": "assets/d72651bccc54449f909f44b6b56e2069_OlgaGil_t.jpg",
            "phone_number": "",
            "email_address": "202039@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04633c09-d4f6-4d66-aef9-1c6afe1f39b4"
        },
        {
          "id": 9952,
          "person": {
            "id": 9952,
            "first_name": "Olga",
            "last_name": "Melnychenko",
            "photo_url": "3ee1ecf6-e949-9ae9-725d-10b75d800871.png",
            "phone_number": "",
            "email_address": "102870@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ba2a8f51-1480-43d1-b16a-3ad082800775"
        },
        {
          "id": 10213,
          "person": {
            "id": 10213,
            "first_name": "Olinda",
            "last_name": "Vaquerano",
            "photo_url": "596627eb-427d-1a2d-ab54-7984fc8182e4.png",
            "phone_number": "",
            "email_address": "224360@placeholder.com"
          },
          "positions": [
            {
              "id": 128,
              "name": "Stocker",
              "name_abbreviation": "STOCKR"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8fec26d9-05f3-487f-b5e8-51220c4603a1"
        },
        {
          "id": 9714,
          "person": {
            "id": 9714,
            "first_name": "Omar",
            "last_name": "Davila Navarro",
            "photo_url": "010f7418-152a-9ec2-d975-7783977043b2.png",
            "phone_number": "",
            "email_address": "221916@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0434b34b-801e-4da2-960a-529a024d96b3"
        },
        {
          "id": 12757,
          "person": {
            "id": 12757,
            "first_name": "Oralia",
            "last_name": "Estrada",
            "photo_url": "assets/c4788b3d267a4a9d9979da47b8950558_IMG_20221108_13890_t.jpg",
            "phone_number": "",
            "email_address": "224275@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18ab677f-2832-4518-a6e4-afb9ea0dde3d"
        },
        {
          "id": 1757,
          "person": {
            "id": 1757,
            "first_name": "Orlando",
            "last_name": "Del Rio",
            "photo_url": "assets/2cbc7b25e22345be91eed9e2fbfa8387_Upright2_t.jpg",
            "phone_number": "6502481390",
            "email_address": "odelrio@sbmcorp.com"
          },
          "positions": [
            {
              "id": 38,
              "name": "Reg Mgr",
              "name_abbreviation": "REGMGR"
            }
          ],
          "shifts": [
            {
              "id": 2126,
              "name": "Day",
              "contract": {
                "id": 2048,
                "name": "Arden Fair Mall (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4ac8e080-e0ad-438c-bd4b-429fab6acc2b",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3209,
              "name": "Day",
              "contract": {
                "id": 1754,
                "name": "REG201",
                "customer_id": 98,
                "customer_name": "Corporate Overhead",
                "uuid": "4eb14343-c9da-4278-9b71-10b22a2e685a",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3037,
              "name": "Day",
              "contract": {
                "id": 2589,
                "name": "Roseville",
                "customer_id": 220,
                "customer_name": "McKesson (CBRE)",
                "uuid": "123db32d-6425-45a7-a771-e015c599d908",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 8737,
              "name": "Swing",
              "contract": {
                "id": 6743,
                "name": "Turner Road Vinters",
                "customer_id": 141,
                "customer_name": "E&J Gallo Winery",
                "uuid": "72c29aa4-10d7-4141-8690-523f81dc3ae2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 7174,
              "name": "Swing",
              "contract": {
                "id": 5486,
                "name": "Butano (JLL)",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "806dc5df-332a-41ea-939a-6131fe8bd94a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 8712,
              "name": "Unassigned",
              "contract": {
                "id": 6701,
                "name": "Modesto Batch Plant",
                "customer_id": 141,
                "customer_name": "E&J Gallo Winery",
                "uuid": "17808225-79bc-4f6f-b4c7-aa0eb81d7805",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            },
            {
              "id": 697,
              "name": "Unassigned",
              "contract": {
                "id": 1894,
                "name": "Fairfield",
                "customer_id": 74,
                "customer_name": "Abbott",
                "uuid": "bafed81d-69a9-42e9-9f93-67a2e3620590",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 5,
                "name": "Unassigned"
              }
            }
          ],
          "hired_date": "2015-09-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b0c29658-1d45-4716-9bb0-4c5ad9e1a62f"
        },
        {
          "id": 2691,
          "person": {
            "id": 2691,
            "first_name": "Oscar",
            "last_name": "Sabaris",
            "photo_url": "3f20feb2-4f85-f45f-fbb9-5c1ce880934b.png",
            "phone_number": "",
            "email_address": "226734@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d589fd7a-c337-469d-b38f-27edb7d401b3"
        },
        {
          "id": 10417,
          "person": {
            "id": 10417,
            "first_name": "Oxana",
            "last_name": "Melikova",
            "photo_url": "009cb071-80d5-3fd4-96b2-dbc8a6520b63.png",
            "phone_number": "",
            "email_address": "221713@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "da34efe7-484e-4eea-a203-6d696e6d67a6"
        },
        {
          "id": 9443,
          "person": {
            "id": 9443,
            "first_name": "Pablo",
            "last_name": "Rangel",
            "photo_url": "1b26d82c-bfe3-ffda-3ce6-40cd95f12fb7.png",
            "phone_number": "",
            "email_address": "209515@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2824,
              "name": "Swing",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fde600eb-f396-40c3-885a-7899a340e86a"
        },
        {
          "id": 10980,
          "person": {
            "id": 10980,
            "first_name": "Pamela",
            "last_name": "Perry",
            "photo_url": "userprofile/ewg2bs4d2hfzqi8fqq8v",
            "phone_number": "",
            "email_address": "203795@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-05-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5a10676-76c4-4b72-ae36-9442c3232d64"
        },
        {
          "id": 1613,
          "person": {
            "id": 1613,
            "first_name": "Patricia",
            "last_name": "Hermosillo",
            "photo_url": "e010d61c-7c11-5722-0e78-c112a7594b90.png",
            "phone_number": "",
            "email_address": "197055@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-12-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ca59190-746b-4fbc-a6e5-286a24a153fc"
        },
        {
          "id": 2988,
          "person": {
            "id": 2988,
            "first_name": "Paul",
            "last_name": "Botto",
            "photo_url": "192869d8-cac2-8b12-a124-85d67f827795.png",
            "phone_number": "9105460348",
            "email_address": "pbotto@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-11-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "291764f6-5a61-4b2e-a48d-7c287cb2c7b7"
        },
        {
          "id": 855,
          "person": {
            "id": 855,
            "first_name": "Paul",
            "last_name": "Smith",
            "photo_url": "assets/bfa928ca31d046de9010f02cd0dba0c7_Capture_t.JPG",
            "phone_number": "7602717405",
            "email_address": "psmith@sbmcorp.com"
          },
          "positions": [
            {
              "id": 197,
              "name": "Sr Area Mgr",
              "name_abbreviation": "SRAREA"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-10-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "60a89284-a13e-4836-b2ac-eb0d2bf78ecf"
        },
        {
          "id": 11540,
          "person": {
            "id": 11540,
            "first_name": "Paula",
            "last_name": "Constant",
            "photo_url": "userprofile/mg6b66bj87nwy5qvwiiz",
            "phone_number": "3174334921",
            "email_address": "pconstant@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad25f22a-2ea6-44f8-b1bd-8c446d558800"
        },
        {
          "id": 7817,
          "person": {
            "id": 7817,
            "first_name": "Paula",
            "last_name": "Jasso",
            "photo_url": "cb414722-0f91-873f-9c17-84b1a401c26f.png",
            "phone_number": "",
            "email_address": "pjasso@sbmcorp.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-08-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "badcd77d-4f0b-4334-b711-037380332b5a"
        },
        {
          "id": 9035,
          "person": {
            "id": 9035,
            "first_name": "Paula",
            "last_name": "Rodriguez Martinez",
            "photo_url": "c81c6743-6bd9-3d39-1dc0-0b16b17b3008.png",
            "phone_number": "",
            "email_address": "221833@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4431b7c1-c302-42b2-9453-21ee112f7bef"
        },
        {
          "id": 9243,
          "person": {
            "id": 9243,
            "first_name": "Pauline",
            "last_name": "Schermerhorn",
            "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
            "phone_number": "2483087214",
            "email_address": "pschermerhorn@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3dd144ea-934f-4d48-88de-700e97a31f60"
        },
        {
          "id": 10396,
          "person": {
            "id": 10396,
            "first_name": "Pech",
            "last_name": "Ung",
            "photo_url": "d60ba755-d7a1-b930-36a1-5979e59d1b1c.png",
            "phone_number": "",
            "email_address": "221790@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6091ccb4-640b-4cb7-be9a-375648135f12"
        },
        {
          "id": 7265,
          "person": {
            "id": 7265,
            "first_name": "Pedro",
            "last_name": "Gomez",
            "photo_url": "ff2d617b-d2a0-fed8-b30f-b0937105d16e.png",
            "phone_number": "",
            "email_address": "226756@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4292323a-b929-4ac7-9888-05edefd9a697"
        },
        {
          "id": 5670,
          "person": {
            "id": 5670,
            "first_name": "Pedro",
            "last_name": "Zepeda",
            "photo_url": "7f06fc98-3c0c-09d3-06e2-7e8b8c21516d.png",
            "phone_number": "",
            "email_address": "224913@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2250,
              "name": "Day",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c7e3dbc3-4507-43da-a4f4-c71602d58b3b"
        },
        {
          "id": 4688,
          "person": {
            "id": 4688,
            "first_name": "Petr",
            "last_name": "Gruy",
            "photo_url": "d910c19d-a181-4d12-3aa5-971a92599030.png",
            "phone_number": "",
            "email_address": "101790@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "940c7ce2-6738-479f-9d88-fc79621c2520"
        },
        {
          "id": 4099,
          "person": {
            "id": 4099,
            "first_name": "Phat",
            "last_name": "Tran",
            "photo_url": "1a1f7eb4-0895-c530-a602-a8a4b10437d4.png",
            "phone_number": "",
            "email_address": "221853@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67a480b1-0aa2-46d1-a9c7-3d58953bb742"
        },
        {
          "id": 10414,
          "person": {
            "id": 10414,
            "first_name": "Phillip",
            "last_name": "Sledge",
            "photo_url": "assets/cb8c4970546f414fb0afdc6714604f5b_Phil_t.jpg",
            "phone_number": "",
            "email_address": "192059@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-08-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "771dd285-4d0b-4bcf-aa6b-d70f0c72162c"
        },
        {
          "id": 8251,
          "person": {
            "id": 8251,
            "first_name": "Phuong",
            "last_name": "Nguyen",
            "photo_url": "9f9c7b0f-d783-316b-2d69-723b3bb9234c.png",
            "phone_number": "",
            "email_address": "103162@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86ac845a-32b0-400c-af46-f6755c6f605a"
        },
        {
          "id": 211,
          "person": {
            "id": 211,
            "first_name": "Phuong",
            "last_name": "Tran",
            "photo_url": "6c47f9d3-7258-0977-a727-1a43d5ae6b4b.png",
            "phone_number": "",
            "email_address": "104601@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a4ea864-1d88-4ed9-84cb-65ced341c6e1"
        },
        {
          "id": 11519,
          "person": {
            "id": 11519,
            "first_name": "Phuong",
            "last_name": "Tran",
            "photo_url": "05bd3b28-b1be-4f55-80ac-6bd41fa07968.png",
            "phone_number": "",
            "email_address": "104604@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2c2a3ce6-4113-4d6b-acb2-ea616bd3313b"
        },
        {
          "id": 11125,
          "person": {
            "id": 11125,
            "first_name": "Piedad",
            "last_name": "Perez Hernandez",
            "photo_url": "f6ff8935-39db-dc51-690d-19bc7b9807a7.png",
            "phone_number": "",
            "email_address": "209522@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2051,
              "name": "Day",
              "contract": {
                "id": 1356,
                "name": "Pleasanton",
                "customer_id": 90,
                "customer_name": "Clorox (CBRE)",
                "uuid": "88e27add-49bf-463e-90a9-aa1af5528f2a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-07-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9244495c-b421-4c21-8213-fde76bfb9709"
        },
        {
          "id": 3061,
          "person": {
            "id": 3061,
            "first_name": "Qali",
            "last_name": "Abdulle",
            "photo_url": "d109bf3e-ebcc-9462-c127-af4a3bb18e39.png",
            "phone_number": "",
            "email_address": "221584@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5a53b3e7-81b0-4f78-b511-cd09d9e57277"
        },
        {
          "id": 10194,
          "person": {
            "id": 10194,
            "first_name": "Quyen",
            "last_name": "Ong",
            "photo_url": "7e026c37-eda1-fc36-7337-2008608a1e45.png",
            "phone_number": "",
            "email_address": "103256@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8dd16f3d-621d-4a8a-8521-9234b1cf4f7d"
        },
        {
          "id": 4080,
          "person": {
            "id": 4080,
            "first_name": "Rachel",
            "last_name": "Elsey",
            "photo_url": "a8f5d5a5-0307-37ae-fa35-6ec0507fc93d.png",
            "phone_number": "",
            "email_address": "221253@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-11-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1034c4b7-e997-4430-aa00-91d5a3609918"
        },
        {
          "id": 9309,
          "person": {
            "id": 9309,
            "first_name": "Rachid",
            "last_name": "Bouaroua",
            "photo_url": "assets/750a5750aa344e1eb7c25912a558870d_rachid_t.PNG",
            "phone_number": "6124377465",
            "email_address": "rbouaroua@sbmcorp.com"
          },
          "positions": [
            {
              "id": 27,
              "name": "Custodial Supervisor",
              "name_abbreviation": "CUSTODIAL SUPERVISOR"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-09-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b597a587-be8a-4242-9403-b9d04df4c35e"
        },
        {
          "id": 11888,
          "person": {
            "id": 11888,
            "first_name": "Rafael",
            "last_name": "Segura Gonzalez",
            "photo_url": "47e375ec-1666-662e-4ae6-853c62a78649.png",
            "phone_number": "",
            "email_address": "226691@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "729fcd87-79bb-4d25-80e0-75804d471ab3"
        },
        {
          "id": 2041,
          "person": {
            "id": 2041,
            "first_name": "Rahel",
            "last_name": "Ula",
            "photo_url": "assets/7ab0cb3669974908893a966e4977f7a4_20200508_115426_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1165,
              "name": "Day",
              "contract": {
                "id": 1325,
                "name": "Des Moines",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "c9ceeff3-20a9-4253-9fc1-c7ca842045fa",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "46eaec4b-b736-488a-8c57-28c64f6b6240"
        },
        {
          "id": 858,
          "person": {
            "id": 858,
            "first_name": "Raisa",
            "last_name": "Petrova",
            "photo_url": "64704188-469a-2a88-42b6-3244c6439774.png",
            "phone_number": "",
            "email_address": "103492@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ea7b27c9-4e62-41e1-ba47-e7ea6a72a17a"
        },
        {
          "id": 9773,
          "person": {
            "id": 9773,
            "first_name": "Ramces",
            "last_name": "Jean",
            "photo_url": "9947c297-a4d6-2e1b-a23c-153f9fd54132.png",
            "phone_number": "",
            "email_address": "216842@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2672,
              "name": "Swing",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2016-12-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "67313a8b-b514-452d-b4ba-de506c457f0a"
        },
        {
          "id": 11718,
          "person": {
            "id": 11718,
            "first_name": "Ramon",
            "last_name": "Gurrola-Hernandez",
            "photo_url": "userprofile/hb1fuxfas0kx7p18g7pb",
            "phone_number": "",
            "email_address": "203436@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6cf08f03-d98d-4c67-a53b-24169cb28a1c"
        },
        {
          "id": 3716,
          "person": {
            "id": 3716,
            "first_name": "Ramona",
            "last_name": "Estevez",
            "photo_url": "c272c895-96c6-251e-db29-a51f4f801604.png",
            "phone_number": "(215)475-1087",
            "email_address": "127435@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-04-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b55421e-5e73-4c19-ad4d-4c71d0c735c0"
        },
        {
          "id": 12179,
          "person": {
            "id": 12179,
            "first_name": "Randolph",
            "last_name": "Scott",
            "photo_url": "aa7fe5e2-7525-6f9a-df77-f3cf208a6471.png",
            "phone_number": "",
            "email_address": "213186@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-04-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d246a410-32ef-42ea-8180-30b31ff4a3be"
        },
        {
          "id": 7608,
          "person": {
            "id": 7608,
            "first_name": "Raquel",
            "last_name": "Cortes Miramontes",
            "photo_url": "assets/1612470729.9431467_20210204_133036_t.png",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 7501,
              "name": "Night",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-05-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7b17fe83-ec53-4d90-9b97-fe8ee8be802a"
        },
        {
          "id": 8586,
          "person": {
            "id": 8586,
            "first_name": "Raquel",
            "last_name": "Torres",
            "photo_url": "6948a48d-b57a-3a89-156e-50927ff50fbd.png",
            "phone_number": "",
            "email_address": "218359@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a1ce7f66-f0fc-4df4-aaf9-fa762b4ed546"
        },
        {
          "id": 5003,
          "person": {
            "id": 5003,
            "first_name": "Raw",
            "last_name": "Bin",
            "photo_url": "6944a49c-7d10-9fb4-25e6-4863dd00450b.png",
            "phone_number": "",
            "email_address": "210346@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2015-09-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b6cdb31f-017f-4507-b361-3cdd87451f57"
        },
        {
          "id": 7259,
          "person": {
            "id": 7259,
            "first_name": "Raysa",
            "last_name": "Pena Urbaez",
            "photo_url": "b8b65276-a325-9ee2-6299-290a073176f5.png",
            "phone_number": "",
            "email_address": "224459@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 6029,
              "name": "Swing",
              "contract": {
                "id": 3299,
                "name": "Cambridge Kendall SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "c5cc4d3b-a54b-4208-91b8-9018904697b7",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a42bb130-fd59-4d7d-b4e3-517347ff20ae"
        },
        {
          "id": 7477,
          "person": {
            "id": 7477,
            "first_name": "Rebecca",
            "last_name": "Colto",
            "photo_url": "66f91b16-2332-cff3-38c6-1904ca1f41b1.png",
            "phone_number": "",
            "email_address": "221817@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "81ad6b83-a788-4a32-8892-8ad51aa05c99"
        },
        {
          "id": 2620,
          "person": {
            "id": 2620,
            "first_name": "Reina",
            "last_name": "Basilio",
            "photo_url": "ec977ae4-e53a-3792-9313-3d35096a10fe.png",
            "phone_number": "",
            "email_address": "191207@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "69c937cc-f5cd-4d79-9461-b39dd2e12e2f"
        },
        {
          "id": 5712,
          "person": {
            "id": 5712,
            "first_name": "Reina",
            "last_name": "Torres",
            "photo_url": "a2ce30a0-e0ac-f60b-032d-be82d289ea62.png",
            "phone_number": "(267)622-3528",
            "email_address": "192006@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2011-08-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e3dcbad6-37d0-4f1d-ab62-c682c6927754"
        },
        {
          "id": 9753,
          "person": {
            "id": 9753,
            "first_name": "Renae",
            "last_name": "Carpenter",
            "photo_url": "06f4ffa2-0ce0-ef60-73b2-3af31ca5fec7.png",
            "phone_number": "",
            "email_address": "202177@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5adcf321-22fd-445a-90db-acabb7211fa4"
        },
        {
          "id": 4061,
          "person": {
            "id": 4061,
            "first_name": "Rene",
            "last_name": "Pacheco",
            "photo_url": "04da1ab9-c7a0-42a9-50f0-c5ae673c7bc7.png",
            "phone_number": "",
            "email_address": "226705@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e3b0474d-ebd4-4a3d-8110-cbb63138bed8"
        },
        {
          "id": 9175,
          "person": {
            "id": 9175,
            "first_name": "Ricardo",
            "last_name": "Echavarria",
            "photo_url": "assets/551a41f2f9ef4f07bb2b7ba70430f0b1_20221202_094323_t.jpg",
            "phone_number": "",
            "email_address": "220434@placeholder.com"
          },
          "positions": [
            {
              "id": 39,
              "name": "Tech, Utility",
              "name_abbreviation": "UTILTC"
            }
          ],
          "shifts": [
            {
              "id": 1913,
              "name": "Day",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95f6fceb-9f71-4f8c-a6b2-439f669fb6ae"
        },
        {
          "id": 10108,
          "person": {
            "id": 10108,
            "first_name": "Ricardo",
            "last_name": "Lugardo Roman",
            "photo_url": "assets/b9f39ffac0ee44a887c26e176625c395_ricardo_t.jpg",
            "phone_number": "",
            "email_address": "202332@placeholder.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ccaae32-fb09-4af7-8bad-b0a109655d74"
        },
        {
          "id": 975,
          "person": {
            "id": 975,
            "first_name": "Rigoberto",
            "last_name": "Baez Lozano",
            "photo_url": "assets/affaf48478184b7281f1866cf1380f03_IMG_20221104_38771_t.jpg",
            "phone_number": "",
            "email_address": "221994@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b65da70-6cca-4cb5-9207-1c7fb8177793"
        },
        {
          "id": 6951,
          "person": {
            "id": 6951,
            "first_name": "Robelia",
            "last_name": "Avelar Cardoso",
            "photo_url": "c9b1c070-82d7-038f-8500-39c63a555839.png",
            "phone_number": "",
            "email_address": "213762@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1891,
              "name": "Day",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-05-24",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c68d7342-529b-41c2-b201-548eb78e666a"
        },
        {
          "id": 1419,
          "person": {
            "id": 1419,
            "first_name": "Roberto",
            "last_name": "Baguistan",
            "photo_url": "e6df9a9a-1d58-91b8-a699-04ab1b843eeb.png",
            "phone_number": "",
            "email_address": "221708@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a384783-8c22-4263-91e1-e0431efd0dfd"
        },
        {
          "id": 12736,
          "person": {
            "id": 12736,
            "first_name": "Roberto",
            "last_name": "De La Paz Carmona",
            "photo_url": "7a32ea4f-a8d2-ee72-3e2b-01d15cf26b84.png",
            "phone_number": "",
            "email_address": "221664@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4fbe982a-84bc-48f6-8067-155cbc3fbf4f"
        },
        {
          "id": 1699,
          "person": {
            "id": 1699,
            "first_name": "Robin",
            "last_name": "Hernandez",
            "photo_url": "e5735834-3a09-9f1b-0452-cee04f23c50d.png",
            "phone_number": "",
            "email_address": "221649@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fa29d8c5-9567-407b-b3db-abc2ffee09f6"
        },
        {
          "id": 257,
          "person": {
            "id": 257,
            "first_name": "Rodolfo",
            "last_name": "Martinez",
            "photo_url": "assets/8e8eb94435d04f4fb5c2903b687c8fa3_IMG_20221104_45420_t.jpg",
            "phone_number": "4084124863",
            "email_address": "rmartinez3@sbmcorp.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2016-10-31",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "31c6b931-cb2e-4134-803d-64088003d586"
        },
        {
          "id": 8234,
          "person": {
            "id": 8234,
            "first_name": "Rodrigo",
            "last_name": "Lopez",
            "photo_url": "assets/8d3d0d217b4442d08603401939a38f66_RL_t.PNG",
            "phone_number": "4804898101",
            "email_address": "r.lopez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 7501,
              "name": "Night",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2012-04-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ad1fc967-43b9-4a24-a2f2-e5f14809bd1d"
        },
        {
          "id": 10796,
          "person": {
            "id": 10796,
            "first_name": "Roger",
            "last_name": "Abbott",
            "photo_url": "a12c7bad-4b26-4ed0-f513-653a9227c257.png",
            "phone_number": "",
            "email_address": "218331@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "49f7dd2d-114b-41f2-8004-1fa6322eca7f"
        },
        {
          "id": 2916,
          "person": {
            "id": 2916,
            "first_name": "Romulo",
            "last_name": "Picon",
            "photo_url": "userprofile/seus3crmbr3vtaktdkkv",
            "phone_number": "",
            "email_address": "204977@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2470,
              "name": "Swing",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-08-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "25bdf23b-3a88-4376-b4ff-aff9c5c4f59d"
        },
        {
          "id": 372,
          "person": {
            "id": 372,
            "first_name": "Rosa",
            "last_name": "Arriola",
            "photo_url": "76c5b4f2-edbd-c132-c5c8-24bd039f1440.png",
            "phone_number": "919-423-9304",
            "email_address": "211444@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2078,
              "name": "Day",
              "contract": {
                "id": 1487,
                "name": "Durham, NC",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "76368a14-4638-4c49-b147-7cf1f3a20bbe",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3ab2968b-0972-45eb-99f7-bd4e8cec88d8"
        },
        {
          "id": 653,
          "person": {
            "id": 653,
            "first_name": "Rosa",
            "last_name": "Blanco",
            "photo_url": "assets/8d161fe1a6a04bd59a538d4a224ab09a_cropped5399008026592064783.jpg",
            "phone_number": "",
            "email_address": "193528@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1798,
              "name": "Day",
              "contract": {
                "id": 1327,
                "name": "Campus Chandler -AZ",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "d34e6e5d-777c-4c11-8be5-fc0662bc4d60",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2016-12-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1afdab82-d9bb-424a-870e-d3249332e205"
        },
        {
          "id": 11119,
          "person": {
            "id": 11119,
            "first_name": "Rosa",
            "last_name": "Familia",
            "photo_url": "566b9afc-7cc1-46a4-69d4-58bddfc098bb.png",
            "phone_number": "",
            "email_address": "226695@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "04248ad5-f369-4c1d-8b03-c927995ae490"
        },
        {
          "id": 1406,
          "person": {
            "id": 1406,
            "first_name": "Rosa",
            "last_name": "Fernandez",
            "photo_url": "assets/rcf06fb602b8e41e7b61d2b0b545da8e9_Rosa_t.jpg",
            "phone_number": "2678270644",
            "email_address": "rfernandez@sbmcorp.com"
          },
          "positions": [
            {
              "id": 363,
              "name": "Supervisor",
              "name_abbreviation": "Spvsr"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0084514d-0809-4947-9ddb-e8235b22b8d0"
        },
        {
          "id": 10782,
          "person": {
            "id": 10782,
            "first_name": "Rosa",
            "last_name": "Martinez",
            "photo_url": "da639a99-cf27-b795-a10b-1bd9d73ae87c.png",
            "phone_number": "",
            "email_address": "226715@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ccb86864-4ac8-4553-961a-44ab890320f1"
        },
        {
          "id": 10962,
          "person": {
            "id": 10962,
            "first_name": "Rosa",
            "last_name": "Olivo Perez",
            "photo_url": "b5fe6256-eb5d-e061-06d5-04b05f8fd164.png",
            "phone_number": "",
            "email_address": "224953@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 793,
              "name": "Night",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-07-15",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eeb6a512-9ed4-4b5b-822d-25edfe507c76"
        },
        {
          "id": 12230,
          "person": {
            "id": 12230,
            "first_name": "Rosalia",
            "last_name": "Atenco Barajas",
            "photo_url": "userprofile/iv3jggs0ixeecx80yapu",
            "phone_number": "",
            "email_address": "203362@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1831,
              "name": "Day",
              "contract": {
                "id": 2087,
                "name": "Campus - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "4968d30b-5dc1-4505-84ea-450f70a88918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7c819039-9e81-4685-a75d-3fd55d95a7d0"
        },
        {
          "id": 10223,
          "person": {
            "id": 10223,
            "first_name": "Rosalia",
            "last_name": "Garcia",
            "photo_url": "assets/21fe78c867b443e5afcc3b001942805b_IMG_20221104_44736_t.jpg",
            "phone_number": "408-550-3449",
            "email_address": "220642@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-09-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5dfdc220-60c2-4107-8103-5e2d7670d96e"
        },
        {
          "id": 8917,
          "person": {
            "id": 8917,
            "first_name": "Rosanna",
            "last_name": "Tavarez Rodriguez",
            "photo_url": "0a1a7e74-de88-b060-2485-3fe050699154.png",
            "phone_number": "",
            "email_address": "222989@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-02-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c893290f-ea88-4408-872f-bfbd0b2255d5"
        },
        {
          "id": 8473,
          "person": {
            "id": 8473,
            "first_name": "Rosario",
            "last_name": "Bermudez Santos",
            "photo_url": "c8f06bef-044e-2914-e849-7c9820f9fc6e.png",
            "phone_number": "4253229418",
            "email_address": "221739@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8370f949-f40d-4787-a5a2-a3caadd0f921"
        },
        {
          "id": 12290,
          "person": {
            "id": 12290,
            "first_name": "Rosette",
            "last_name": "Cherenfant",
            "photo_url": "assets/b39d4cb79ab34309a7b4597ef937b206_thumbnail_IMG_20220721_163148_t.jpg",
            "phone_number": "",
            "email_address": "127405@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-05-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "27585fbc-c308-40b7-82ad-11b856f34258"
        },
        {
          "id": 5321,
          "person": {
            "id": 5321,
            "first_name": "Rui",
            "last_name": "Li",
            "photo_url": "c292bdae-0781-cf8d-4255-bf0edad4173e.png",
            "phone_number": "",
            "email_address": "227256@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2019-02-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e86c308-6f72-4a9a-8849-67d49f7ae783"
        },
        {
          "id": 381,
          "person": {
            "id": 381,
            "first_name": "Rukio",
            "last_name": "Ali",
            "photo_url": "df48858d-cb64-bd46-42dc-fa35cad92857.png",
            "phone_number": "",
            "email_address": "225927@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "880978af-3e00-41d7-865c-9a968ffecb6b"
        },
        {
          "id": 326,
          "person": {
            "id": 326,
            "first_name": "Sabry",
            "last_name": "Matt",
            "photo_url": "f0901895-5b78-0cac-643e-9dea80341487.png",
            "phone_number": "",
            "email_address": "226244@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4f1a9cfc-63c6-40e1-9615-f8c8488e1b8e"
        },
        {
          "id": 2952,
          "person": {
            "id": 2952,
            "first_name": "Sadia",
            "last_name": "Muhamud",
            "photo_url": "e34716e4-1732-256f-6465-5e5d7b517c2e.png",
            "phone_number": "",
            "email_address": "221717@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "004aa68b-6d38-46c3-99f0-01d5ec2d2f67"
        },
        {
          "id": 9550,
          "person": {
            "id": 9550,
            "first_name": "Sakib",
            "last_name": "Darkulic",
            "photo_url": "f0451a01-e67c-ab8b-2db4-437440cbbfd5.png",
            "phone_number": "",
            "email_address": "101031@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0144acaf-2046-4923-9fd8-3307bbd8f3ca"
        },
        {
          "id": 3010,
          "person": {
            "id": 3010,
            "first_name": "Salado",
            "last_name": "Abdulah",
            "photo_url": "85183a87-b5da-759f-5fc8-44ab71b88cf2.png",
            "phone_number": "",
            "email_address": "223466@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-04-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "69f93d90-e8fe-41a7-ace9-3f34ff545216"
        },
        {
          "id": 8574,
          "person": {
            "id": 8574,
            "first_name": "Salah",
            "last_name": "Yusuf",
            "photo_url": "8fb92887-e399-066a-7e1a-9c24b96fe249.png",
            "phone_number": "",
            "email_address": "221605@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b15ab8b-4a39-4a96-baf5-6d5049c229f3"
        },
        {
          "id": 6422,
          "person": {
            "id": 6422,
            "first_name": "Salima",
            "last_name": "Papaladze",
            "photo_url": "4858cb3f-fd68-6b20-b3d9-de07daba4ad6.png",
            "phone_number": "4253624025",
            "email_address": "221757@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ff32c67-4a02-4cf1-83fc-3d3a370dc46b"
        },
        {
          "id": 1169,
          "person": {
            "id": 1169,
            "first_name": "Samey",
            "last_name": "Osman",
            "photo_url": "1566b5ff-a744-bbfe-eb89-94f05d263971.png",
            "phone_number": "",
            "email_address": "221868@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "944a3cc0-a6e2-4f18-b8b6-cea01f2ab0dd"
        },
        {
          "id": 5698,
          "person": {
            "id": 5698,
            "first_name": "Sandra",
            "last_name": "Agudelo",
            "photo_url": "assets/596093a5077345228f7a78bf912c4d97_SandraAgudelo_t.jpg",
            "phone_number": "",
            "email_address": "226839@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "98111d01-4fdd-4224-8556-fe50795b550e"
        },
        {
          "id": 2504,
          "person": {
            "id": 2504,
            "first_name": "Sandra",
            "last_name": "Burns",
            "photo_url": "userprofile/bvtypygruzwzrmljj1ff",
            "phone_number": "3178014565",
            "email_address": "sburns@sbmcorp.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fdc67512-ac53-47c8-acfa-920bf7dbfc5a"
        },
        {
          "id": 10018,
          "person": {
            "id": 10018,
            "first_name": "Sandra",
            "last_name": "Herrera De Reyes",
            "photo_url": "fb2033d8-bdc7-82aa-41e7-600efcda7e01.png",
            "phone_number": "",
            "email_address": "226137@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "91abe9b5-6f0a-4c72-be87-11672b5bb62d"
        },
        {
          "id": 8416,
          "person": {
            "id": 8416,
            "first_name": "Sandra",
            "last_name": "Munoz",
            "photo_url": "userprofile/vvccjfp7ooeal7j4etta",
            "phone_number": "3174178159",
            "email_address": "smunoz@sbmcorp.com"
          },
          "positions": [
            {
              "id": 42,
              "name": "Lead, GMP",
              "name_abbreviation": "GMPLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6e36fc9d-3bb0-4c21-8b4b-dedee5b0376e"
        },
        {
          "id": 3873,
          "person": {
            "id": 3873,
            "first_name": "Santos",
            "last_name": "Benavides",
            "photo_url": "b70b43e7-01cf-053d-c455-d4e5a09a12ca.png",
            "phone_number": "",
            "email_address": "abenevides@sbmcorp.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "261dfe7f-7973-431a-a387-e39252db4c11"
        },
        {
          "id": 654,
          "person": {
            "id": 654,
            "first_name": "Sao",
            "last_name": "Eang",
            "photo_url": "assets/1594876424.4093995_20200715_190457_t.jpg",
            "phone_number": "",
            "email_address": "101276@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "8fe81171-2e61-479a-8a51-d40d547e96f1"
        },
        {
          "id": 8575,
          "person": {
            "id": 8575,
            "first_name": "Sarah",
            "last_name": "Tomes",
            "photo_url": "assets/1592512053.9391258_R4Pic_t.jpeg",
            "phone_number": "3176171846",
            "email_address": "stomes@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2de0046b-0398-46d2-83a4-4b69797cca8a"
        },
        {
          "id": 2725,
          "person": {
            "id": 2725,
            "first_name": "Satit",
            "last_name": "Suwiwat",
            "photo_url": "5e07364d-e0b5-2102-ae61-4e21279a02d2.png",
            "phone_number": "",
            "email_address": "217044@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-01-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9a45bd91-7f75-4aa6-a2a3-4fecffe32bd8"
        },
        {
          "id": 6130,
          "person": {
            "id": 6130,
            "first_name": "Savannah",
            "last_name": "Edwards",
            "photo_url": "77b11022-40d6-202c-ee19-876c6107c784.png",
            "phone_number": "",
            "email_address": "225545@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-09-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3dfd5026-dbb5-48fa-a35e-fe4a80abb7b9"
        },
        {
          "id": 4297,
          "person": {
            "id": 4297,
            "first_name": "Saw",
            "last_name": "Win",
            "photo_url": "2aa0814b-23e6-ccc4-79c2-fce17b6a0042.png",
            "phone_number": "317-529-1454",
            "email_address": "202224@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5e1c3c3-a0ee-4a4f-95e1-831d810713ad"
        },
        {
          "id": 2433,
          "person": {
            "id": 2433,
            "first_name": "Sayonary",
            "last_name": "Sanchez",
            "photo_url": "3e0f3ad6-c3a9-1b5e-afb3-7c38f8ad7453.png",
            "phone_number": "",
            "email_address": "226739@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86d5ae9a-413b-46bb-9f97-8e78813741c1"
        },
        {
          "id": 1867,
          "person": {
            "id": 1867,
            "first_name": "Sead",
            "last_name": "Sadic",
            "photo_url": "9e4001b1-8d57-35bf-a06a-5d7216f701fb.png",
            "phone_number": "",
            "email_address": "104078@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1dd61e31-2855-47e4-a7a5-3312c9ae6b02"
        },
        {
          "id": 9672,
          "person": {
            "id": 9672,
            "first_name": "Segunda",
            "last_name": "Lopez",
            "photo_url": "884bd64b-9971-aab1-d7fc-e98ada4ff046.png",
            "phone_number": "",
            "email_address": "224345@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f28ed2f2-e4f9-491e-a32e-4c061548d294"
        },
        {
          "id": 3799,
          "person": {
            "id": 3799,
            "first_name": "Sejid",
            "last_name": "Kolar",
            "photo_url": "22208627-00ad-ad7e-525a-290bb0fd2e89.png",
            "phone_number": "",
            "email_address": "221711@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0e084e34-049d-443c-abd6-25c1f347ada5"
        },
        {
          "id": 4104,
          "person": {
            "id": 4104,
            "first_name": "Semka",
            "last_name": "Mehinovic",
            "photo_url": "6f95ce39-de4d-eea6-ec04-030f90f8c30e.png",
            "phone_number": "",
            "email_address": "102854@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40d55d24-9395-4f7d-ac75-bae129236b3d"
        },
        {
          "id": 3022,
          "person": {
            "id": 3022,
            "first_name": "Sergey",
            "last_name": "Kruk",
            "photo_url": "3777b5a7-538e-de5d-d526-bfcd55454da4.png",
            "phone_number": "2065188082",
            "email_address": "102331@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7f67ca5e-441a-4e0e-8900-d59ad360ec66"
        },
        {
          "id": 77,
          "person": {
            "id": 77,
            "first_name": "Seu",
            "last_name": "Mat",
            "photo_url": "4496e415-a535-3e3f-a819-7997706341ed.png",
            "phone_number": "",
            "email_address": "222799@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-02-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "18320348-a8b0-47ff-ac01-cc3d668f4f8a"
        },
        {
          "id": 1553,
          "person": {
            "id": 1553,
            "first_name": "Shagitu",
            "last_name": "Hamesu",
            "photo_url": "67810816-100c-54c6-5c1d-786e8cfec49e.png",
            "phone_number": "",
            "email_address": "221805@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "828aed9f-bc3c-4e1c-8a0f-57849ff2e37f"
        },
        {
          "id": 6212,
          "person": {
            "id": 6212,
            "first_name": "Shiomara",
            "last_name": "Lopez Rojas De Taina",
            "photo_url": "assets/22f09b0d5f724d62ba9fb2b2b0d6a9f9_IMG_20221108_12974_t.jpg",
            "phone_number": "408-204-4134",
            "email_address": "220565@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-09-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0ae96b00-9970-4317-b357-612eacf03cef"
        },
        {
          "id": 9782,
          "person": {
            "id": 9782,
            "first_name": "Shirley",
            "last_name": "Witherspoon",
            "photo_url": "userprofile/tzksgjhec8vszfop0mwr",
            "phone_number": "",
            "email_address": "202320@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2014-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ca8323e1-4c33-4147-afc3-9373733cda97"
        },
        {
          "id": 7419,
          "person": {
            "id": 7419,
            "first_name": "Sigfredo",
            "last_name": "Mejia Martinez",
            "photo_url": "dd90ef19-acf5-a733-1966-406dfd68111c.png",
            "phone_number": "",
            "email_address": "224357@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c68f5b3f-2ee9-428b-ad44-be1b095b5536"
        },
        {
          "id": 7338,
          "person": {
            "id": 7338,
            "first_name": "Siham",
            "last_name": "Defar",
            "photo_url": "1481658f-f806-c0d6-b6e0-de392f6120ea.png",
            "phone_number": "",
            "email_address": "222782@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-02-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "fac6b359-30d2-4cd5-9a87-65c5a3494f7b"
        },
        {
          "id": 4423,
          "person": {
            "id": 4423,
            "first_name": "Silas",
            "last_name": "Richardson",
            "photo_url": "4fbae5bc-9d49-a2b6-18f9-2b76306eff6f.png",
            "phone_number": "",
            "email_address": "221792@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6b61481e-0e34-4d9f-85c5-63fa7656b8cd"
        },
        {
          "id": 730,
          "person": {
            "id": 730,
            "first_name": "Silvia",
            "last_name": "Palencia Minas",
            "photo_url": "676cb70d-961c-54c4-9396-6abe0d1fe7a3.png",
            "phone_number": "",
            "email_address": "224342@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1550,
              "name": "Day",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d815890c-38bd-4d2a-83a0-a3b8b3e119f9"
        },
        {
          "id": 8260,
          "person": {
            "id": 8260,
            "first_name": "Sitha",
            "last_name": "Neang",
            "photo_url": "8795cb82-6001-acfe-0e99-f7916f861f7f.png",
            "phone_number": "",
            "email_address": "221798@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "099cd698-5039-434f-9f35-bf93f11d2d9d"
        },
        {
          "id": 10226,
          "person": {
            "id": 10226,
            "first_name": "Smail",
            "last_name": "Bektovic",
            "photo_url": "1f81ab33-ac18-c95c-0249-b0ca3bef420c.png",
            "phone_number": "",
            "email_address": "100403@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4c235490-9ca4-459c-b844-9cd363fb5103"
        },
        {
          "id": 3023,
          "person": {
            "id": 3023,
            "first_name": "Sofia",
            "last_name": "Hernandez",
            "photo_url": "assets/2e8955e0467844199e9652da8e661d0e_PDCpics007_t.jpg",
            "phone_number": "",
            "email_address": "202324@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dc5e325f-4e13-4d4b-9e69-c44bd13bb989"
        },
        {
          "id": 1826,
          "person": {
            "id": 1826,
            "first_name": "Sofia",
            "last_name": "Negash",
            "photo_url": "9d18e032-a163-cc63-b4b5-432738d24d74.png",
            "phone_number": "",
            "email_address": "127533@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "95c3754b-48b8-4d27-9845-884072024de4"
        },
        {
          "id": 11768,
          "person": {
            "id": 11768,
            "first_name": "Sofonias",
            "last_name": "Kidane",
            "photo_url": "assets/1578637550.5996828_IMG_23751_t.JPG",
            "phone_number": "",
            "email_address": "226112@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-10-16",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6596d463-ac5f-4ad0-ba1d-5cd21d1d2f79"
        },
        {
          "id": 8115,
          "person": {
            "id": 8115,
            "first_name": "Solanger",
            "last_name": "Perdomo",
            "photo_url": "8292eba1-319e-b04c-4bde-3a26fe51cc2b.png",
            "phone_number": "",
            "email_address": "226686@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1223,
              "name": "Day",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "40aeae7c-1e4d-4878-8675-ffcbd1f50c33"
        },
        {
          "id": 9664,
          "person": {
            "id": 9664,
            "first_name": "Son",
            "last_name": "Bui",
            "photo_url": "assets/c4ff4d7356f34e95a16c1f98cecf1dfc_IMG_20211014_155832_t.jpg",
            "phone_number": "",
            "email_address": "224860@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-07-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0526c7fc-3f73-49a7-994d-0ce787efe8dc"
        },
        {
          "id": 8466,
          "person": {
            "id": 8466,
            "first_name": "Sonia",
            "last_name": "Villa",
            "photo_url": "assets/r033fc5a48e8840e6b8339c5e3d038046_cropped3189257708862169622.jpg",
            "phone_number": "",
            "email_address": "203534@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2014-04-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7dcece36-5be1-4f58-a7da-92415dc83cf2"
        },
        {
          "id": 3763,
          "person": {
            "id": 3763,
            "first_name": "Sonia",
            "last_name": "Villatoro",
            "photo_url": "fbcddc4c-5ff0-1ee2-3f74-b7361d0bb2fb.png",
            "phone_number": "",
            "email_address": "224923@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2378,
              "name": "Night",
              "contract": {
                "id": 2141,
                "name": "Mission Bay",
                "customer_id": 303,
                "customer_name": "Uber (CBRE)",
                "uuid": "60f04bc9-f0fe-479e-a533-1d79900f7948",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-07-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6bc2c01a-32c1-48ee-8845-cfbb1c0ee65b"
        },
        {
          "id": 59,
          "person": {
            "id": 59,
            "first_name": "Sorath",
            "last_name": "Ngeth",
            "photo_url": "dc96da62-9334-cba6-7cd9-e275b328c996.png",
            "phone_number": "(205) 586-9964",
            "email_address": "sngeth@sbmcorp.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bcc72226-f1d4-413b-aee0-6738368a7dfd"
        },
        {
          "id": 2731,
          "person": {
            "id": 2731,
            "first_name": "Stacey",
            "last_name": "Paddock",
            "photo_url": "98f92ed7-ffc4-ed0f-be11-974a1fb0363c.png",
            "phone_number": "4699880697",
            "email_address": "spaddock@sbmcorp.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 3083,
              "name": "Swing",
              "contract": {
                "id": 2618,
                "name": "Irving, TX",
                "customer_id": 485,
                "customer_name": "State Farm (CBRE)",
                "uuid": "2e02082c-668c-4d73-abb0-06256222dbe2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-08-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4df0cdf4-0126-4d7c-b7f3-b8655fa63393"
        },
        {
          "id": 6978,
          "person": {
            "id": 6978,
            "first_name": "Stacy",
            "last_name": "Tanguay",
            "photo_url": "1d8e7ba1-c237-50ee-536b-69a4bbd3665e.png",
            "phone_number": "",
            "email_address": "218164@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 664,
              "name": "Swing",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "973218b5-b632-4e64-9e32-104fa5c6311c"
        },
        {
          "id": 1648,
          "person": {
            "id": 1648,
            "first_name": "Stephen",
            "last_name": "Kovsky",
            "photo_url": "0c587f72-2654-1e68-8864-d6d9d26618df.png",
            "phone_number": "",
            "email_address": "221863@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d427056f-8fff-4e71-941d-a9f12eb3adf4"
        },
        {
          "id": 1038,
          "person": {
            "id": 1038,
            "first_name": "Steven",
            "last_name": "Vance",
            "photo_url": "53a088a7-12d1-d566-ab6b-697cd0a8fe75.png",
            "phone_number": "+13144573461",
            "email_address": "SVance@sbmcorp.com"
          },
          "positions": [
            {
              "id": 45,
              "name": "Asst Site Mgr",
              "name_abbreviation": "ASTMGR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d3372d1-effb-407c-aa0e-292413138681"
        },
        {
          "id": 9411,
          "person": {
            "id": 9411,
            "first_name": "Su",
            "last_name": "Nguyen",
            "photo_url": "4d19446d-5e10-16fc-2843-60478183a867.png",
            "phone_number": "",
            "email_address": "103179@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "75f1522a-d679-4192-8d74-cbae5ac633e9"
        },
        {
          "id": 11874,
          "person": {
            "id": 11874,
            "first_name": "Sue",
            "last_name": "Chen",
            "photo_url": "f3e35913-2362-b38e-1198-663019347c75.png",
            "phone_number": "",
            "email_address": "221786@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b85dce3e-ee59-4aba-b69d-4860418eb064"
        },
        {
          "id": 3533,
          "person": {
            "id": 3533,
            "first_name": "Suk",
            "last_name": "Lee",
            "photo_url": "0c532e75-2770-7e7f-6a59-79e6d69d637d.png",
            "phone_number": "",
            "email_address": "102396@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "375b1e84-3e37-450d-84b8-21f497fc2979"
        },
        {
          "id": 8527,
          "person": {
            "id": 8527,
            "first_name": "Suxiang",
            "last_name": "Chen",
            "photo_url": "b879a69f-923e-1bfb-b35c-8faa2557d5d2.png",
            "phone_number": "",
            "email_address": "224284@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-06-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e252040f-58e5-4b51-afad-b1b1610615d4"
        },
        {
          "id": 12789,
          "person": {
            "id": 12789,
            "first_name": "Svitlana",
            "last_name": "Bilogan",
            "photo_url": "ca334218-893b-bff8-7096-806a99ce2f56.png",
            "phone_number": "",
            "email_address": "221842@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ae0dada7-be1d-4dbd-9c03-ad2f5e43e9b9"
        },
        {
          "id": 9817,
          "person": {
            "id": 9817,
            "first_name": "Tahira",
            "last_name": "Bastah",
            "photo_url": "c82af34e-5d94-5545-eb94-facf916c4b36.png",
            "phone_number": "",
            "email_address": "100373@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "59094c60-1808-452f-8db7-151efd672692"
        },
        {
          "id": 3152,
          "person": {
            "id": 3152,
            "first_name": "Tamara",
            "last_name": "Ramirez",
            "photo_url": "assets/c9a088b9379c4e6eb590f7bcfd3c4e81_Tamara_t.jpg",
            "phone_number": "",
            "email_address": "226856@placeholder.com"
          },
          "positions": [
            {
              "id": 483,
              "name": "Lead, Mailroom",
              "name_abbreviation": "MAILLD"
            }
          ],
          "shifts": [
            {
              "id": 1413,
              "name": "Day",
              "contract": {
                "id": 1956,
                "name": "Madison, NJ",
                "customer_id": 75,
                "customer_name": "AbbVie",
                "uuid": "0543063d-4e55-4080-a046-2a68caef615a",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-14",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dbefa818-8128-47bf-885f-131af8f3ea84"
        },
        {
          "id": 7017,
          "person": {
            "id": 7017,
            "first_name": "Tarrien",
            "last_name": "Hodges",
            "photo_url": "da9c5f89-58bc-fc4c-3d31-846fdee73feb.png",
            "phone_number": "",
            "email_address": "221871@placeholder.com"
          },
          "positions": [
            {
              "id": 1,
              "name": "Tech, Recycle",
              "name_abbreviation": "RECTC"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a3283906-4ae1-4406-887f-cd902dfb8f9d"
        },
        {
          "id": 9279,
          "person": {
            "id": 9279,
            "first_name": "Tee",
            "last_name": "Pleh",
            "photo_url": "assets/e7ffcb5550974129940b8767562dabe9_tee_t.jpg",
            "phone_number": "",
            "email_address": "214192@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2016-06-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d2cbde47-baf5-44ba-be97-b93aa0914f23"
        },
        {
          "id": 1017,
          "person": {
            "id": 1017,
            "first_name": "Teresa",
            "last_name": "Dreher",
            "photo_url": "014bda64-5f3b-fa9e-29c8-42efc7927362.png",
            "phone_number": "2056842077",
            "email_address": "tdreher@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 1456,
              "name": "Day",
              "contract": {
                "id": 2016,
                "name": "Clinton",
                "customer_id": 273,
                "customer_name": "Elanco (CBRE)",
                "uuid": "f7af7e4d-0eba-44bc-8ed0-a5a2d0506d17",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e48ce1bf-a3bf-4885-be5c-adcf863ee115"
        },
        {
          "id": 3787,
          "person": {
            "id": 3787,
            "first_name": "Teresa",
            "last_name": "Rebidas",
            "photo_url": "a1a4b2eb-2046-a8d7-4361-c185e63f6b0a.png",
            "phone_number": "",
            "email_address": "218382@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2628,
              "name": "On-Call",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": null
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "feae298f-f2b5-4906-8de2-538332bc0ef9"
        },
        {
          "id": 7483,
          "person": {
            "id": 7483,
            "first_name": "Teresa",
            "last_name": "Stewart",
            "photo_url": "assets/b5c8b94744ca416d8a34b0b2600efbb4_Teresa_t.jpg",
            "phone_number": "",
            "email_address": "202245@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "33dfbb10-3033-4804-90e7-309ef0cf0e08"
        },
        {
          "id": 10576,
          "person": {
            "id": 10576,
            "first_name": "Tesfaye",
            "last_name": "Tesfaye",
            "photo_url": "f3d487f5-c978-df63-df4e-67d007313e16.png",
            "phone_number": "4252871382",
            "email_address": "221654@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9f5cb185-e5ba-41b1-84d9-47e2c157ae04"
        },
        {
          "id": 12301,
          "person": {
            "id": 12301,
            "first_name": "Thi",
            "last_name": "Nguyen",
            "photo_url": "4f361423-a115-9811-a6a9-fec48728d0f6.png",
            "phone_number": "",
            "email_address": "226413@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "dd755067-178a-4cab-99a4-d51fdc66c5eb"
        },
        {
          "id": 1955,
          "person": {
            "id": 1955,
            "first_name": "Thoi",
            "last_name": "Dao",
            "photo_url": "c43080f2-ecb7-655e-9984-b4d89c1da3ac.png",
            "phone_number": "",
            "email_address": "101029@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "23f4db77-dd3c-4c75-a9a2-339400ef0c53"
        },
        {
          "id": 4916,
          "person": {
            "id": 4916,
            "first_name": "Thu",
            "last_name": "De",
            "photo_url": "82307813-1d30-11f4-e461-c4f6a973a4a0.png",
            "phone_number": "",
            "email_address": "211264@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2015-11-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f19f01cd-c30f-4721-b331-af44842a745c"
        },
        {
          "id": 4918,
          "person": {
            "id": 4918,
            "first_name": "Thu",
            "last_name": "Tran",
            "photo_url": "aeda8ece-640c-a679-be7c-47f1fb22f6c0.png",
            "phone_number": "",
            "email_address": "104607@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6ab09dce-9354-4d4c-af85-6a8c8a46b3dc"
        },
        {
          "id": 8269,
          "person": {
            "id": 8269,
            "first_name": "Thu",
            "last_name": "Tran",
            "photo_url": "f1fa894f-0243-9a93-25a2-d1ed4d5b4020.png",
            "phone_number": "",
            "email_address": "104600@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a32a2713-8ce7-453e-aa75-e9ab971ed6ca"
        },
        {
          "id": 10106,
          "person": {
            "id": 10106,
            "first_name": "Thuy",
            "last_name": "Ngo",
            "photo_url": "293b3bf3-a0ff-2ea7-7c73-64036b53991b.png",
            "phone_number": "",
            "email_address": "103152@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "617c15e5-ae40-4313-a457-67a2bd2b0eab"
        },
        {
          "id": 3273,
          "person": {
            "id": 3273,
            "first_name": "Thuy",
            "last_name": "Tran",
            "photo_url": "b03ea31b-80c5-f10a-5508-0eee1de43a91.png",
            "phone_number": "",
            "email_address": "104602@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b06bc966-18b9-471f-9984-38d83f2333b4"
        },
        {
          "id": 6420,
          "person": {
            "id": 6420,
            "first_name": "Tien",
            "last_name": "Nguyen",
            "photo_url": "a0169d31-0db7-5cd6-8660-618f3604308e.png",
            "phone_number": "2065037303",
            "email_address": "221797@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5332a8d5-4df2-441e-837c-9d5158427c5e"
        },
        {
          "id": 3314,
          "person": {
            "id": 3314,
            "first_name": "Tigist",
            "last_name": "Birehane",
            "photo_url": "90a74b7f-e5d2-9501-d420-10feaa349c82.png",
            "phone_number": "",
            "email_address": "221771@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f1c06f8e-92fc-4c9d-97eb-3a5a3d5b5dd5"
        },
        {
          "id": 4631,
          "person": {
            "id": 4631,
            "first_name": "Toai",
            "last_name": "Nguyen",
            "photo_url": "347087b5-f4f4-548c-2c0b-b90b8524809b.png",
            "phone_number": "",
            "email_address": "221857@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-09",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2f9f11a0-f832-4844-a7df-745ae69cf919"
        },
        {
          "id": 2986,
          "person": {
            "id": 2986,
            "first_name": "Tolugu",
            "last_name": "Kor",
            "photo_url": "assets/372b63f4653a4c91be4ba8c5aa15f2da_tolugu_t.jpg",
            "phone_number": "",
            "email_address": "208917@placeholder.com"
          },
          "positions": [
            {
              "id": 34,
              "name": "Tech, Lab",
              "name_abbreviation": "LABTC"
            }
          ],
          "shifts": [
            {
              "id": 1218,
              "name": "Swing",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2015-05-27",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e474d5c8-af01-4ba3-98c7-3f82f0a25760"
        },
        {
          "id": 7256,
          "person": {
            "id": 7256,
            "first_name": "Tomas",
            "last_name": "Orona",
            "photo_url": "assets/14cdb9f1f10740a797e2667dd11d8d04_IMG_20221108_14202_t.jpg",
            "phone_number": "510-552-7478",
            "email_address": "222929@placeholder.com"
          },
          "positions": [
            {
              "id": 3,
              "name": "Custodial Lead",
              "name_abbreviation": "LEAD"
            }
          ],
          "shifts": [
            {
              "id": 2433,
              "name": "Swing",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-02-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3296300c-95fe-4ef5-a919-355d8a8626f2"
        },
        {
          "id": 7520,
          "person": {
            "id": 7520,
            "first_name": "Tracey",
            "last_name": "Rought",
            "photo_url": "cf30681d-52c7-115d-964f-752b73f0ec2e.png",
            "phone_number": "570-988-1028",
            "email_address": "211213@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1114,
              "name": "Day",
              "contract": {
                "id": 1486,
                "name": "Cherokee, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "bb2367b9-6c3d-4abc-9400-a88b1fb02a20",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9a5082e-2d13-4e4d-b4b8-878acfa1b88a"
        },
        {
          "id": 5191,
          "person": {
            "id": 5191,
            "first_name": "Trinh",
            "last_name": "Nguyen",
            "photo_url": "addec0e6-e0ea-1dc3-c7e8-41565c1672ea.png",
            "phone_number": "4252874688",
            "email_address": "221763@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0a6a8b5c-90bf-4b86-aa94-130ff54b3035"
        },
        {
          "id": 5488,
          "person": {
            "id": 5488,
            "first_name": "Trong",
            "last_name": "Tran",
            "photo_url": "d0446d0d-5189-4b4b-ebb9-abd8eee67b53.png",
            "phone_number": "",
            "email_address": "104613@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7a6431f7-4dab-4a2f-902a-15412085f5f2"
        },
        {
          "id": 1491,
          "person": {
            "id": 1491,
            "first_name": "Try",
            "last_name": "Nge",
            "photo_url": "7f8e6298-d6a2-2f24-5e99-468dd04b716f.png",
            "phone_number": "",
            "email_address": "221831@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "0cc6ce14-3f74-45b7-9917-b440b99cf137"
        },
        {
          "id": 11291,
          "person": {
            "id": 11291,
            "first_name": "Tsion",
            "last_name": "Gebremedhin",
            "photo_url": "4b3e9c0c-6683-5e67-1be7-09ffe60410b2.png",
            "phone_number": "",
            "email_address": "221636@placeholder.com"
          },
          "positions": [
            {
              "id": 103,
              "name": "Tech, Cafe (Loc 6 WA)",
              "name_abbreviation": "CAFTEC"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e5b34a2b-8e4d-4ed5-b55e-c3e6f53b75b8"
        },
        {
          "id": 11156,
          "person": {
            "id": 11156,
            "first_name": "Tuan",
            "last_name": "Nguyen",
            "photo_url": "assets/1587715461.3246078_tuan_t.jpg",
            "phone_number": "4253959726",
            "email_address": "221815@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9ca71ebb-e52f-435f-ab53-78fba70cb129"
        },
        {
          "id": 7473,
          "person": {
            "id": 7473,
            "first_name": "Tuan",
            "last_name": "Pham",
            "photo_url": "2c60cb11-7117-8c9b-d34f-b4eb85a2e23b.png",
            "phone_number": "",
            "email_address": "221905@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c6d2359d-b009-46ce-891f-50d2126450b0"
        },
        {
          "id": 11411,
          "person": {
            "id": 11411,
            "first_name": "Tuyet",
            "last_name": "Nguyen",
            "photo_url": "a0ad0099-e503-fe05-a7d2-eeb627581fbf.png",
            "phone_number": "",
            "email_address": "103172@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7a877361-273e-4194-87da-2dc09790a51a"
        },
        {
          "id": 6413,
          "person": {
            "id": 6413,
            "first_name": "Tw",
            "last_name": "Whiteside",
            "photo_url": "assets/1588000432.1511097_20190221_075544_t.jpg",
            "phone_number": "",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 5036,
              "name": "Day",
              "contract": {
                "id": 3144,
                "name": "Memphis",
                "customer_id": 803,
                "customer_name": "Vantiva (CBRE)",
                "uuid": "8a33df06-5844-4653-bd10-077459324b24",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-10-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7efccb11-0962-437e-b761-31909c3aa82a"
        },
        {
          "id": 12587,
          "person": {
            "id": 12587,
            "first_name": "Uzeir",
            "last_name": "Custic",
            "photo_url": "ff6bc299-6914-4c61-3a0f-a69ba3c85b5f.png",
            "phone_number": "",
            "email_address": "ucustic@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a77539f-675f-4bf3-853f-0599a5f1b5a3"
        },
        {
          "id": 3236,
          "person": {
            "id": 3236,
            "first_name": "Van",
            "last_name": "Tran",
            "photo_url": "4d96d320-a7d0-f270-0ca2-d1a6672c39fb.png",
            "phone_number": "",
            "email_address": "226666@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "689a1ba1-6e44-4a6d-91e8-8bbc6d91ec33"
        },
        {
          "id": 7804,
          "person": {
            "id": 7804,
            "first_name": "Vanessa",
            "last_name": "McClure",
            "photo_url": "eb346078-5963-efc1-359f-390f6abcbc0c.png",
            "phone_number": "",
            "email_address": "210469@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "001a0472-6105-4ab0-a044-68f690807e33"
        },
        {
          "id": 4187,
          "person": {
            "id": 4187,
            "first_name": "Vera",
            "last_name": "Bilobrovets",
            "photo_url": "6ed1a63a-b449-8aa7-7599-425eae1031e1.png",
            "phone_number": "",
            "email_address": "100446@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "17266730-3e1e-4f53-ab14-f7595a31d662"
        },
        {
          "id": 3485,
          "person": {
            "id": 3485,
            "first_name": "Veronica",
            "last_name": "Hernandez",
            "photo_url": "27c856fd-ee59-7e47-1714-83bd5b8c5b45.png",
            "phone_number": "",
            "email_address": "227004@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2019-01-08",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "32f2548e-21ef-40c3-b837-903cf9a7fbe2"
        },
        {
          "id": 1857,
          "person": {
            "id": 1857,
            "first_name": "Veronica",
            "last_name": "Moreira",
            "photo_url": "6b60e72c-20f4-8c87-c581-8f6226f4d4ee.png",
            "phone_number": "",
            "email_address": "221921@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "eac16d09-0b01-47b0-89cc-4cbd67e07959"
        },
        {
          "id": 1915,
          "person": {
            "id": 1915,
            "first_name": "Veronica",
            "last_name": "Requenes",
            "photo_url": "userprofile/uu90s3kolvqjw3movfre",
            "phone_number": "3174717187",
            "email_address": "vrequenes@sbmcorp.com"
          },
          "positions": [
            {
              "id": 56,
              "name": "Sr Custodial Lead",
              "name_abbreviation": "TEAMLD"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9b078806-dd22-4828-be9c-5603fba864c3"
        },
        {
          "id": 3905,
          "person": {
            "id": 3905,
            "first_name": "Veronica",
            "last_name": "Solorio",
            "photo_url": "assets/647a47fbb2634a998236adc2111ce08d_Resized_Resized_Resized_20221202_2250580011_t.jpeg",
            "phone_number": "",
            "email_address": "220469@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2141,
              "name": "Night",
              "contract": {
                "id": 1151,
                "name": "Sunnyvale, CA",
                "customer_id": 63,
                "customer_name": "Juniper",
                "uuid": "2e10ee98-f921-45c1-9542-d1aca6f3b8ec",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-09-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a2e3d874-3ee5-41f7-848e-c4374511fa53"
        },
        {
          "id": 6627,
          "person": {
            "id": 6627,
            "first_name": "Vickie",
            "last_name": "Jones",
            "photo_url": "assets/77f2abb19ed8482a9a2af83b7ae45165_20210907_143802_t.jpg",
            "phone_number": "",
            "email_address": "207472@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-02-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "773cd647-72d2-4a5d-9658-a6db0e4d6323"
        },
        {
          "id": 2819,
          "person": {
            "id": 2819,
            "first_name": "Victor",
            "last_name": "Molina",
            "photo_url": "79d5356e-0796-3bd0-b1e1-afcf5554cd57.png",
            "phone_number": "",
            "email_address": "225525@placeholder.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "97d1694f-f80e-462e-ab81-0c67205d37a4"
        },
        {
          "id": 5491,
          "person": {
            "id": 5491,
            "first_name": "Victor",
            "last_name": "Offutt",
            "photo_url": "assets/31cac28eac7043838bed0ea25b70396a_Victor_t.jpg",
            "phone_number": "",
            "email_address": "202287@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3b7684b5-41b4-443f-8f63-f1be1af0775c"
        },
        {
          "id": 6088,
          "person": {
            "id": 6088,
            "first_name": "Victor",
            "last_name": "Primus",
            "photo_url": "505411a0-a3dd-9522-e29f-40221f06f4bc.png",
            "phone_number": "2155345436",
            "email_address": "vprimus@sbmcorp.com"
          },
          "positions": [
            {
              "id": 118,
              "name": "Lead, CSR",
              "name_abbreviation": "CSRTLD"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-04-28",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "16988d39-4706-44a4-8023-679b3591264e"
        },
        {
          "id": 9356,
          "person": {
            "id": 9356,
            "first_name": "Victor",
            "last_name": "Reyes",
            "photo_url": "assets/ceb06bf654e04582afe6140588f9c8c8_thumbnail_IMG_20220722_180757_t.jpg",
            "phone_number": "",
            "email_address": "211083@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1192,
              "name": "Day",
              "contract": {
                "id": 1459,
                "name": "Upper Gwynedd, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "e8803824-04c1-4e11-bd7c-39fe5c607132",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-10-29",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86eb0bff-88aa-4758-be32-6ac6c410b017"
        },
        {
          "id": 2690,
          "person": {
            "id": 2690,
            "first_name": "Victor",
            "last_name": "To",
            "photo_url": "4bf13777-568a-0061-2ae7-9a39870f6966.png",
            "phone_number": "",
            "email_address": "104545@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ef64d10a-f9d6-450c-a3ca-59b0d57370ea"
        },
        {
          "id": 12411,
          "person": {
            "id": 12411,
            "first_name": "Vien",
            "last_name": "Do",
            "photo_url": "1d665782-4f2b-e5de-1b7e-f1e4b2e459ce.png",
            "phone_number": "",
            "email_address": "221595@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "9fe03bd6-6d2b-47d7-8a3b-0ed7ade0eb3c"
        },
        {
          "id": 5092,
          "person": {
            "id": 5092,
            "first_name": "Viet",
            "last_name": "Bui",
            "photo_url": "149f95af-5668-29bd-0317-6d949e48a427.png",
            "phone_number": "",
            "email_address": "100554@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "86bc2070-a9db-4ef0-929a-7334e59408f6"
        },
        {
          "id": 1677,
          "person": {
            "id": 1677,
            "first_name": "Vilma",
            "last_name": "Turcios",
            "photo_url": "6b1600a3-52ce-112c-b71d-6dced4808065.png",
            "phone_number": "2677703154",
            "email_address": "101551@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2010-07-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "bc886407-5405-4aab-bee3-cb68cc97da4d"
        },
        {
          "id": 9117,
          "person": {
            "id": 9117,
            "first_name": "Vinh",
            "last_name": "Dang",
            "photo_url": "cbc0d03a-b300-d84b-ce33-3bcfd757a78c.png",
            "phone_number": "4252138951",
            "email_address": "225731@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7de517db-bc10-4008-856a-e0cff32e3426"
        },
        {
          "id": 6527,
          "person": {
            "id": 6527,
            "first_name": "Vinh",
            "last_name": "Pham",
            "photo_url": "3c117e8b-bdf8-aa9a-9c3e-10bc329fe845.png",
            "phone_number": "",
            "email_address": "223956@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-05-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f9f89e56-8a5f-46cf-b5fd-7548d5fe317a"
        },
        {
          "id": 2437,
          "person": {
            "id": 2437,
            "first_name": "Vinh",
            "last_name": "Vo",
            "photo_url": "f1a907d1-8c31-64d7-c3a9-342f77047e7d.png",
            "phone_number": "4252196310",
            "email_address": "104834@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f873e0da-c758-4892-93c0-635cf88f4fab"
        },
        {
          "id": 1288,
          "person": {
            "id": 1288,
            "first_name": "Vu",
            "last_name": "Hien",
            "photo_url": "d1816380-87e3-cf83-9500-b64218ecac25.png",
            "phone_number": "",
            "email_address": "221765@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1157,
              "name": "Day",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f37ffa43-e09c-4eab-a7b2-31c78b1f3a9f"
        },
        {
          "id": 9083,
          "person": {
            "id": 9083,
            "first_name": "Vu",
            "last_name": "Truong",
            "photo_url": "d638585f-f57f-53e9-7748-6f39bbfc1186.png",
            "phone_number": "2063071355",
            "email_address": "221816@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "c3c25516-7aef-4ef3-89cf-66c372434716"
        },
        {
          "id": 11067,
          "person": {
            "id": 11067,
            "first_name": "Wai",
            "last_name": "Wong",
            "photo_url": "assets/1584057786.8839142_IpPic_t.jpg",
            "phone_number": "",
            "email_address": "wwong@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-07-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6a4ec515-2233-42be-bc94-f861f0afe702"
        },
        {
          "id": 2015,
          "person": {
            "id": 2015,
            "first_name": "Wan",
            "last_name": "Zhong",
            "photo_url": "b6b60136-4d2f-bac6-9b41-a2191b18bc94.png",
            "phone_number": "4253619980",
            "email_address": "221768@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b562493d-82c8-4db3-999e-1f842ad7dabb"
        },
        {
          "id": 9281,
          "person": {
            "id": 9281,
            "first_name": "Wanxiu",
            "last_name": "Wu",
            "photo_url": "16420750-ab47-d393-0feb-231b61213e06.png",
            "phone_number": "",
            "email_address": "222776@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-02-13",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "2eaf0646-ef9e-43b3-823a-4ce9197399d3"
        },
        {
          "id": 11405,
          "person": {
            "id": 11405,
            "first_name": "Wei",
            "last_name": "Wu",
            "photo_url": "7dec9266-3b97-32a0-b719-85f31746a9b1.png",
            "phone_number": "",
            "email_address": "226321@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-11-06",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "70193e7d-bece-4aa7-a70c-1dc898e8d534"
        },
        {
          "id": 6998,
          "person": {
            "id": 6998,
            "first_name": "Wendell",
            "last_name": "Adams",
            "photo_url": "1df2b62f-298f-4546-b224-0b0c3a218743.png",
            "phone_number": "",
            "email_address": "219527@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2017-06-25",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1ccfc7d0-3399-4ede-a1a5-4f2c9a0836b4"
        },
        {
          "id": 5797,
          "person": {
            "id": 5797,
            "first_name": "Wendy",
            "last_name": "Baron",
            "photo_url": "assets/32e9ef25dab44a279f390fa379e3c155_Wendyprofile_t.undefined",
            "phone_number": "",
            "email_address": "218178@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 663,
              "name": "Day",
              "contract": {
                "id": 1,
                "name": "Milford PG, MI",
                "customer_id": 137,
                "customer_name": "GM",
                "uuid": "8175e9c1-3a72-423b-ae82-85ae27790502",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-03-30",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4d80d7bc-a1d3-45b5-aa49-753dd2c681fb"
        },
        {
          "id": 2168,
          "person": {
            "id": 2168,
            "first_name": "Wilbin",
            "last_name": "Tejeda Pena",
            "photo_url": "a6830771-a59b-4637-9959-3f89212bdcf4.png",
            "phone_number": "",
            "email_address": "226716@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "cac0fe7a-7628-41c2-a7f5-61c74db315ce"
        },
        {
          "id": 4779,
          "person": {
            "id": 4779,
            "first_name": "Willie",
            "last_name": "Cannon",
            "photo_url": "assets/6c17a94c7bb04971a0e96847bffc8554_Willie_t.jpg",
            "phone_number": "",
            "email_address": "100641@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 954,
              "name": "Day",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2009-05-04",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "21047068-52cc-482e-a2f6-92ae0138e307"
        },
        {
          "id": 3626,
          "person": {
            "id": 3626,
            "first_name": "Wilmar",
            "last_name": "Garcia Soto",
            "photo_url": "7cd9c661-4016-11ac-c2f5-61fcbf6f2071.png",
            "phone_number": "",
            "email_address": "222800@placeholder.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-02-12",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "edebade3-1443-40b8-abf8-00e9d93c8bed"
        },
        {
          "id": 5493,
          "person": {
            "id": 5493,
            "first_name": "Wilson",
            "last_name": "Alvarez Munoz",
            "photo_url": "7851dfe1-55e8-006b-e570-92139e198968.png",
            "phone_number": "",
            "email_address": "224344@placeholder.com"
          },
          "positions": [
            {
              "id": 4,
              "name": "Floor Tech",
              "name_abbreviation": "FLTC"
            }
          ],
          "shifts": [
            {
              "id": 2459,
              "name": "Swing",
              "contract": {
                "id": 1647,
                "name": "Central SQ",
                "customer_id": 180,
                "customer_name": "Takeda (CBRE)",
                "uuid": "41a46a18-0fe9-4589-a0ff-a37982f79570",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-06-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "09b29bae-4d57-41ca-a185-c5b1ca9fad5e"
        },
        {
          "id": 58018,
          "person": {
            "id": 57482,
            "first_name": "Wilson",
            "last_name": "Aucaquizhpi",
            "photo_url": "assets/63b8e5e5f95f4dd5a2606377d27d8f99_Wilson_t.PNG",
            "phone_number": "",
            "email_address": ""
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2621,
              "name": "Swing",
              "contract": {
                "id": 2088,
                "name": "East Town - MN",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "8a4b7143-0ccb-49cc-9135-775c6de107b3",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2016-05-17",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3fbc7a49-4f3f-4091-92ad-556edb358a9f"
        },
        {
          "id": 11537,
          "person": {
            "id": 11537,
            "first_name": "Wo",
            "last_name": "Wu",
            "photo_url": "3fdfa8a0-df09-9cbc-dda0-687d773b0b15.png",
            "phone_number": "",
            "email_address": "221799@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6f3ffab1-626c-47db-8b70-f74d50d82f44"
        },
        {
          "id": 10773,
          "person": {
            "id": 10773,
            "first_name": "Worke",
            "last_name": "Bekele",
            "photo_url": "dc72522f-637c-87bc-6862-7dbdd0b66dd3.png",
            "phone_number": "",
            "email_address": "225106@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-08-02",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "15495b48-ccba-4c64-8503-6625d9e24766"
        },
        {
          "id": 9898,
          "person": {
            "id": 9898,
            "first_name": "Xiaochang",
            "last_name": "Xu",
            "photo_url": "bc5fdc2b-f8f7-3aa8-c422-ecd1e88ace31.png",
            "phone_number": "",
            "email_address": "221690@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5c54959c-1618-4fba-9d5a-db6d16b75de0"
        },
        {
          "id": 8420,
          "person": {
            "id": 8420,
            "first_name": "Xin",
            "last_name": "Lee",
            "photo_url": "e9044292-ca26-a2b0-4b28-2b4f6433b844.png",
            "phone_number": "",
            "email_address": "225743@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-09-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d8c2b9b2-9bd6-42f3-a208-64d12fcb949f"
        },
        {
          "id": 7429,
          "person": {
            "id": 7429,
            "first_name": "Xiomara",
            "last_name": "Aguilar",
            "photo_url": "b9e8a812-9fb6-cea3-2191-e40fae09b620.png",
            "phone_number": "",
            "email_address": "211407@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1204,
              "name": "Day",
              "contract": {
                "id": 1491,
                "name": "North Wales, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "a153d850-6493-4210-a779-9ec041201f67",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f9e2aec4-39be-4fb4-80e1-014bdd90d520"
        },
        {
          "id": 5366,
          "person": {
            "id": 5366,
            "first_name": "Xiomara",
            "last_name": "Meraz Murillo",
            "photo_url": "assets/9979be1557f643b39a651db4b489544f_3_t.jpg",
            "phone_number": "",
            "email_address": "202273@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2014-02-23",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "47eff714-b676-430d-b745-101799672a67"
        },
        {
          "id": 1964,
          "person": {
            "id": 1964,
            "first_name": "Xiu",
            "last_name": "Chen",
            "photo_url": "5e3afbcd-0e63-3097-b5d9-05af66e9217a.png",
            "phone_number": "",
            "email_address": "226673@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "f2de4dcb-0d6c-452f-af6f-0bd5b48729a6"
        },
        {
          "id": 2835,
          "person": {
            "id": 2835,
            "first_name": "Yahaira",
            "last_name": "Mosquea Fajardo",
            "photo_url": "assets/7ac65aab751147cabac3bb0c865e3444_yahaira_t.jpg",
            "phone_number": "",
            "email_address": "211174@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-05",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "56b97282-84a2-4205-a714-a4a7dfa8ac7a"
        },
        {
          "id": 11694,
          "person": {
            "id": 11694,
            "first_name": "Yajaira",
            "last_name": "Luciano-Lopez",
            "photo_url": "assets/c92d3840b2bc47498522582aad487cc6_Yary_t.PNG",
            "phone_number": "2677708640",
            "email_address": "191163@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2011-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d970cfc9-f5d7-438f-9588-99ad9c76aa2c"
        },
        {
          "id": 10042,
          "person": {
            "id": 10042,
            "first_name": "Yan",
            "last_name": "Deng",
            "photo_url": "assets/1583454193.5897171_untitled_t.png",
            "phone_number": "",
            "email_address": "yhdeng@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e9561edd-c032-4d20-bbd5-ab7d229eeb6a"
        },
        {
          "id": 10215,
          "person": {
            "id": 10215,
            "first_name": "Yaritza",
            "last_name": "Luciano-Lopez",
            "photo_url": "ffa9df81-2164-e7e4-7c82-7e5c8473412c.png",
            "phone_number": "2676422735",
            "email_address": "yluciano@sbmcorp.com"
          },
          "positions": [
            {
              "id": 84,
              "name": "Sr Site Mgr",
              "name_abbreviation": "SRSMGR"
            }
          ],
          "shifts": [
            {
              "id": 1204,
              "name": "Day",
              "contract": {
                "id": 1491,
                "name": "North Wales, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "a153d850-6493-4210-a779-9ec041201f67",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 3060,
              "name": "Day",
              "contract": {
                "id": 2598,
                "name": "Lansdale",
                "customer_id": 477,
                "customer_name": "Organon (CBRE)",
                "uuid": "e259507c-7bdb-40b9-8f5a-14706dd3a918",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            },
            {
              "id": 7692,
              "name": "Swing",
              "contract": {
                "id": 5651,
                "name": "Springhouse Plant, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "f0f40fd7-d195-4627-a633-f5b637386449",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            },
            {
              "id": 5862,
              "name": "Swing",
              "contract": {
                "id": 2604,
                "name": "Springhouse, PA",
                "customer_id": 477,
                "customer_name": "Organon (CBRE)",
                "uuid": "6100a342-7776-41ab-a4a9-9e4a092fb692",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2011-05-26",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "48fce526-fa34-49d6-80db-63bdf8429d35"
        },
        {
          "id": 6596,
          "person": {
            "id": 6596,
            "first_name": "Yasmiry",
            "last_name": "Duran",
            "photo_url": "assets/1602274287.711383_Yasmiry_t.jpg",
            "phone_number": "7323407663",
            "email_address": "yduran@sbmcorp.com"
          },
          "positions": [
            {
              "id": 17,
              "name": "Site Mgr",
              "name_abbreviation": "SITMGR"
            }
          ],
          "shifts": [
            {
              "id": 5170,
              "name": "Day",
              "contract": {
                "id": 3336,
                "name": "Whitehouse Station, NJ",
                "customer_id": 1018,
                "customer_name": "Chubb Insurance (CBRE)",
                "uuid": "659cd1dc-4e33-481e-9ca7-9d627e5c45b5",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-04-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "1382a340-5b52-48c4-8d51-d035aa95aaf2"
        },
        {
          "id": 4642,
          "person": {
            "id": 4642,
            "first_name": "Yelena",
            "last_name": "Bykovskiy",
            "photo_url": "assets/1587715207.0569978_yelena_t.jpg",
            "phone_number": "4253751052",
            "email_address": null
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 1158,
              "name": "Night",
              "contract": {
                "id": 1995,
                "name": "Puget Sound, WA (Compass)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "f2b7bbda-6a24-4eb9-a142-a9caf6dfbb1e",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "e0bc8dcf-f261-45ed-a097-41e379c63432"
        },
        {
          "id": 11587,
          "person": {
            "id": 11587,
            "first_name": "Yesenia",
            "last_name": "Figueroa",
            "photo_url": "f899d57c-24ce-6152-e7d8-213441f05713.png",
            "phone_number": "",
            "email_address": "226729@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "7e5a34d7-bf14-496c-ae0c-3af3f0f1ee7f"
        },
        {
          "id": 5742,
          "person": {
            "id": 5742,
            "first_name": "Yevgeniy",
            "last_name": "Belonenko",
            "photo_url": "2d96d38b-054b-77a9-7ab9-72c3dac02351.png",
            "phone_number": "",
            "email_address": "100408@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "38888368-8a06-46bf-8b63-ba1048f098af"
        },
        {
          "id": 2265,
          "person": {
            "id": 2265,
            "first_name": "Ying",
            "last_name": "Chen",
            "photo_url": "66a6e570-da32-d3fb-bb8d-7a8ff698682d.png",
            "phone_number": "4253453356",
            "email_address": "100820@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "daba8073-dded-4387-8a0d-2a8fc5f3b6a5"
        },
        {
          "id": 3996,
          "person": {
            "id": 3996,
            "first_name": "Yolanda",
            "last_name": "Flores",
            "photo_url": "09c1ce28-0406-e2c3-7be9-e0ffae768b98.png",
            "phone_number": "",
            "email_address": "212639@placeholder.com"
          },
          "positions": [
            {
              "id": 7,
              "name": "Tech, GMP",
              "name_abbreviation": "GMPTEC"
            }
          ],
          "shifts": [
            {
              "id": 473,
              "name": "Night",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2016-03-10",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "4a5f2366-f56e-44de-9233-4f922376ea8e"
        },
        {
          "id": 95,
          "person": {
            "id": 95,
            "first_name": "Yong",
            "last_name": "Deng",
            "photo_url": "e3717fd5-be33-cb72-b24d-2350977ef930.png",
            "phone_number": "2064898677",
            "email_address": "ydeng@sbmcorp.com"
          },
          "positions": [
            {
              "id": 18,
              "name": "Cust Foreperson (Loc 6WA)",
              "name_abbreviation": "6FORE"
            }
          ],
          "shifts": [
            {
              "id": 655,
              "name": "Day",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "d7d65d9b-0272-45a3-8124-b68442f9d1b0"
        },
        {
          "id": 9558,
          "person": {
            "id": 9558,
            "first_name": "Ysabel",
            "last_name": "Urena De Calderon",
            "photo_url": "03b5c09a-87ae-42d0-d0c1-929a9b0f940b.png",
            "phone_number": "(215)390-8101",
            "email_address": "211253@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1163,
              "name": "Day",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-11-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b80a89b8-3c67-4416-94cf-fdc6307e4060"
        },
        {
          "id": 11394,
          "person": {
            "id": 11394,
            "first_name": "Yue",
            "last_name": "Huang",
            "photo_url": "d6392346-2c75-c216-182d-e582b57f4387.png",
            "phone_number": "",
            "email_address": "102075@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "b35b4d91-9413-44ae-84c4-96e6bf997665"
        },
        {
          "id": 3525,
          "person": {
            "id": 3525,
            "first_name": "Yue",
            "last_name": "Johnson",
            "photo_url": "e50d3689-541b-c085-134b-29e8d0eaaa19.png",
            "phone_number": "",
            "email_address": "221583@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2017-12-03",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "a5fe0541-d9e6-40a3-8cb7-d0746c2f1212"
        },
        {
          "id": 182,
          "person": {
            "id": 182,
            "first_name": "Yuly",
            "last_name": "Cordero",
            "photo_url": "b22abf9f-75c5-f368-05a0-cee96f488074.png",
            "phone_number": "",
            "email_address": "226814@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2507,
              "name": "Swing",
              "contract": {
                "id": 1955,
                "name": "Cambridge",
                "customer_id": 253,
                "customer_name": "Biogen (CBRE)",
                "uuid": "c568da99-a356-4746-a29c-4a48bc981889",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2018-12-22",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "6adbe489-ef86-405e-9db4-33664a5bc517"
        },
        {
          "id": 1077,
          "person": {
            "id": 1077,
            "first_name": "Yusuf",
            "last_name": "Egal",
            "photo_url": "0d998e53-55a4-91f0-dcf6-686ba41dc946.png",
            "phone_number": "",
            "email_address": "223296@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-03-20",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "73dd6b46-2bd8-40db-bf5a-ee678a8ef15b"
        },
        {
          "id": 12105,
          "person": {
            "id": 12105,
            "first_name": "Yvette",
            "last_name": "Lopez",
            "photo_url": "0a794ed0-92d8-96e7-96f9-321c789382f9.png",
            "phone_number": "(718)316-3221",
            "email_address": "102521@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 2527,
              "name": "Swing",
              "contract": {
                "id": 1490,
                "name": "West Point, PA",
                "customer_id": 267,
                "customer_name": "Merck (CBRE)",
                "uuid": "79c3985a-416b-475a-b701-277f0fb9a3c2",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2009-05-21",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "acb2b66d-cb8c-4812-b6f7-43a3fdbd0587"
        },
        {
          "id": 1763,
          "person": {
            "id": 1763,
            "first_name": "Zahra",
            "last_name": "Maalin",
            "photo_url": "assets/b7437ebfb0384822b3618dbab0a8858b_ZahraMaalin_t.jpg",
            "phone_number": "",
            "email_address": "205808@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 2546,
              "name": "Swing",
              "contract": {
                "id": 1323,
                "name": "St. Louis, MO",
                "customer_id": 87,
                "customer_name": "Wells Fargo",
                "uuid": "ab885110-2d90-4ffa-b1de-5b10fd083201",
                "chatbot_sms_phone_number": "3147893557",
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 2,
                "name": "Swing"
              }
            }
          ],
          "hired_date": "2016-07-07",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "5ef4d821-ebcf-4012-a833-57b8aa92f4a4"
        },
        {
          "id": 8833,
          "person": {
            "id": 8833,
            "first_name": "Zenaida",
            "last_name": "Ledezma",
            "photo_url": "assets/b8749d7e31da456cb32af38e49db6745_IMG_20221104_47101_t.jpg",
            "phone_number": "",
            "email_address": "222039@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1262,
              "name": "Day",
              "contract": {
                "id": 839,
                "name": "Santa Clara, CA",
                "customer_id": 5,
                "customer_name": "Nvidia (CBRE)",
                "uuid": "8fdd31fb-fd27-4590-898d-5898b1762f99",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2017-12-18",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "405f180d-6a4b-455a-8d91-f22e4563b73c"
        },
        {
          "id": 6747,
          "person": {
            "id": 6747,
            "first_name": "Zonghao",
            "last_name": "Guan",
            "photo_url": "48649a66-ec2b-87a2-63ae-847da1b8432c.png",
            "phone_number": "",
            "email_address": "226663@placeholder.com"
          },
          "positions": [
            {
              "id": 2,
              "name": "Custodian",
              "name_abbreviation": "CUST"
            }
          ],
          "shifts": [
            {
              "id": 779,
              "name": "Night",
              "contract": {
                "id": 7,
                "name": "Puget Sound, WA (CBRE)",
                "customer_id": 8,
                "customer_name": "Microsoft (CBRE)",
                "uuid": "1be92db7-4b52-4ebc-88bd-8138b1451236",
                "chatbot_sms_phone_number": null,
                "chatbot_sms_country_code": "1"
              },
              "shift_type": {
                "id": 3,
                "name": "Night"
              }
            }
          ],
          "hired_date": "2018-12-11",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "3aff09d2-83d9-46c6-8782-176aa7a4fd63"
        },
        {
          "id": 68501,
          "person": {
            "id": 3903,
            "first_name": "Zoyla",
            "last_name": "Cervera-Valencia",
            "photo_url": "71d85dd8-f012-1d5c-28a2-4952cf80042e.png",
            "phone_number": "",
            "email_address": "208187@placeholder.com"
          },
          "positions": [
            {
              "id": 6,
              "name": "Customer Service Rep",
              "name_abbreviation": "CSR"
            }
          ],
          "shifts": [
            {
              "id": 1217,
              "name": "Day",
              "contract": {
                "id": 1469,
                "name": "Indianapolis, IN",
                "customer_id": 72,
                "customer_name": "Eli Lilly (CBRE)",
                "uuid": "672dd456-b7a2-4e82-83b5-165f2d4b06a7",
                "chatbot_sms_phone_number": "",
                "chatbot_sms_country_code": ""
              },
              "shift_type": {
                "id": 1,
                "name": "Day"
              }
            }
          ],
          "hired_date": "2015-04-01",
          "terminated_date": null,
          "terminated_reason": null,
          "uuid": "ceedcbf2-d750-44ac-a782-2ace62a0b408"
        }
      ]
    }
  ]
}