import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import  TopTen  from './topten.jsx';
import Audits from './audits.jsx';
import Complaints from './complaints.jsx';
import CSAT from './csat.jsx';
import Turnover from './turnover.jsx';
import Training from './training.jsx';
import Recordables from './incidents.jsx';
import GoodCatches from './goodcatches.jsx';
import Highlights from './highlights.jsx';

import MonthlyHero from './MonthlyHero.jsx';

import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'


export default function MonthlySwitch({data, showModal, selection, show}) {
    var param = data.param;
    switch(param) {
        case 'scorecards':
            return <Scorecards/>;
        case 'top10':
            return <TopTen data={data.data} title={data.title}/>;
        case 'audits':
            return <Audits data={data.data}/>;
        case 'complaints':
            return <Complaints data={data.data}/>;
        case 'csat':
            return <CSAT data={data.data}/>;
        case 'turnover':
            return <Turnover data={data.data}/>;
        case 'training':
            return <Training data={data.data}/>;
        case 'recordables':
            return <Recordables data={data.data}/>;
        case 'goodcatches':
            return <GoodCatches data={data.data} headline={data.headline} story={data.story} />;
        case 'highlights':
            return <Highlights showModal={showModal} />;
        case 'hero': 
            return <MonthlyHero modalOpen={show} data={data.data} selection={selection} showModal={showModal} />;
        default:
            return <h1>default</h1>;
    }
}

function Scorecards({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: false,
                width: '100%',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
                labels: {
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "white",
                    },
                }
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              max: 5,
              min: 0,
              title: '',
              gridLineColor: 'rgb(40, 48, 56)',
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            tooltip: {
                backgroundColor: "rgb(12, 15, 18)",
                outside: true,
                shadow: false,
                style: { color: "#ffffff", fontSize: '16px' },

            },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                stickyTracking: false,
                turboThreshold: 1000,
                findNearestPointBy: "x",
                borderRadius: 10,
                crisp: true,
                groupPadding: 0.0,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: "normal",
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-yellow-500">Scorecards</p>
                    <p className="monthly-title">
                        November Scorecards
                    </p>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                        <Swiper
                        className=" w-full"
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        pagination={{ clickable: true }}
                        >
                            <SwiperSlide className="p-6 pb-12">
                                <p className="text-xl font-bold text-white">Top Movers</p>
                                <p className="text-lg text-white/70">These accounts achieved the greatest scorecard improvements this month.</p>
                                <div className="w-full mt-4 flex flex-col gap-4 ">
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/amgen.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Amgen - New Albany</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-green-400 mr-2'} icon={faArrowUp} />1.0</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-green-700 rounded-lg">4.0</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/HP.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">HP - Palo Alto</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-green-400  mr-2'} icon={faArrowUp} />1.25</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-green-700 rounded-lg">4.5</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Westinghouse.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Westinghouse - Ogden</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-green-400  mr-2'} icon={faArrowUp} />0.75</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-green-700 rounded-lg">5</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Meta - Pittsburgh, PA</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-green-400  mr-2'} icon={faArrowUp} />0.75</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-green-700 rounded-lg">5</div>

                                    </div>
                                </div>

                            </SwiperSlide>
                            <SwiperSlide className="p-6 pb-12">
                            <p className="text-xl font-bold text-white">Opportunities</p>
                                <p className="text-lg text-white/70">This month, 98 out of 102 site scorecards achieved passing results. Four sites failed to meet expectations.</p>
                                <div className="w-full mt-4 flex flex-col gap-4 ">
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Wells.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Boise (JLL)</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-yellow-700 mr-2'} icon={faArrowRight} />No Change</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-yellow-700 rounded-lg">3.0</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Meta - Durham, NC</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-red-700  mr-2'} icon={faArrowDown} />1.25</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-yellow-700 rounded-lg">3.75</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Ford.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Ford - KCAP</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-green-400  mr-2'} icon={faArrowUp} />0.5</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-yellow-700 rounded-lg">3</div>

                                    </div>
                                    <div className="w-full flex flex-row items-center justify-evenly gap-4">
                                        <div className="h-10 w-10 bg-white rounded-full">
                                            <img src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Westinghouse.svg" alt="" className="h-full w-full object-contain" />
                                        </div>
                                        <p className="text-xl font-bold text-white flex-grow">Westinghouse - New Stanton</p>
                                        <div className="text-lg text-neutral-500 flex-shrink-0"><FontAwesomeIcon className={'text-red-700  mr-2'} icon={faArrowDown} />1.25</div>
                                        <div className="flex items-center justify-center text-xl font-bold text-white flex-shrink-0 w-16 h-12 text-center bg-yellow-700 rounded-lg">3.75</div>

                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-full">
                        <Chart className="w-full" data={[{name: "Average Score", data: [{name: "Jun", color: '#5B6671', y:4.8},{name: "July", color: '#5B6671', y:4.75},{name: "Aug", color: '#5B6671', y:4.74},{name: "Sep", color: '#5B6671', y:4.85},{name: 'Oct',color: '#5B6671', y:4.81},{name: 'Nov',color: '#66FFBA', y:4.82}]}]} plotLines={[{value: 4, color: 'white', width: 4, zIndex: 4, }]}/>
                    </div>
                </div>
            </div>
        </Section>
    )
}