import { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {gsap} from 'gsap';

import {add, differenceInYears, differenceInMonths, differenceInDays } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretDown, faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons";

//CBREData
import { cbreIncidents, cbreSafetySites, cbreSafetyClients, cbreSelectedClients } from "./data";

import noise from "../../assets/images/noise.png";

//Highcharts Config
require("highcharts/highcharts-more")(Highcharts);

Highcharts.setOptions({
  colors: [
    "#4AA8FF",
    "#734CFF",
    "#A231D2",
    "#DC00B6",
    "#FF7DB1",
    "#FF4334",
    "#FF6F19",
    "#C29201",
    "#E5C801",
    "#00FF8D",
    "#01CCA7",
    "#D0D5D9",
  ],
});

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
//end Highcharts Config

export default function Incidents({ setShow }) {
  const [categories, setCategories] = useState(
    cbreIncidents.results.map((d) => {
      let date = new Date(d.mo);
      return date.toLocaleString("default", {
        month: "short",
        year: "2-digit",
      });
    })
  );
  const [safetyRecord, setSafetyRecord] = useState(() => {
    let record = cbreSafetyClients.results.map(client => {
        client.sites = cbreSafetySites.results.filter(site => site.customer_id === client.id);
        client.logo_url = cbreSelectedClients.find(c => c.id === client.id).logo_url;
        let latest_recordable_date = new Date(client.latest_recordable_date);
        let today = new Date();
        let days_recordable_free = Math.floor((today - latest_recordable_date) / (1000 * 60 * 60 * 24));
        client.days_recordable_free = days_recordable_free;
        return client;
    })
    return record;    
  }
  )
  const modal = useRef(null);

  function dataToSeries() {
    return [
      {
        name: "Recordables",
        data: cbreIncidents.results.map((d) => {
          return d.recordable;
        }),
        marker: {
          symbol: "circle",
        },
      },
      {
        name: "Incidents",
        data: cbreIncidents.results.map((d) => {
          return d.first_aid + d.report_only + d.near_miss;
        }),
        marker: {
          symbol: "circle",
        },
      },
    ];
  }

  function handleSafetyClick() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("stop");
    modal.current.classList.remove("hidden");
  }

  function handleSafetyClose(e) {
    e.stopPropagation();
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("stop");
    modal.current.classList.add("hidden");
  }

  function IncidentsLineChart({ data }) {
    const options = {
      title: {
        text: "",
      },
      chart: {
        backgroundColor: "transparent",
        type: "line",
        margin: [0, 0, 50, 0],
      },
      legend: {
        verticalAlign: "top",
        enabled: true,
        align: "right",
        layout: "horizontal",
        symbolRadius: 2,
        y: -100,
        // labelFormatter: function () {
        //   return `<div class="w-full flex items-center justify-between"><p>${
        //     this.name
        //   }</p><p>${this.percentage.toFixed(1)}%</p></div>`;
        // },
        useHTML: true,
        itemStyle: {
          color: "#89929B",
          fontWeight: "normal",
          fontSize: "14px",
        },
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        gridLineColor: "rgba(255,255,255,0.3)",
        title: "",
        labels: {
          enabled: false,
        },
      },
      xAxis: {
        categories: categories,
        tickColor: "white",
        lineColor: "rgba(255,255,255,0.3)",
        labels: {
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },
      },
      tooltip: {
        enabled: false,
        backgroundColor: "rgb(12, 15, 18)",
        outside: true,
        shadow: false,
        // formatter: function () {
        //   return `${this.point.name}: ${this.percentage.toFixed(1)}%`;
        // },
        style: { color: "#ffffff", fontSize: "16px" },
      },
      plotOptions: {
        pie: {
          innerSize: "70%",
          labels: {
            enabled: false,
          },
          showInLegend: false,
        },
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
          borderWidth: 0,
          borderColor: "transparent",
          borderRadius: 10,
          style: {},
        },
        series: {
          dataLabels: {
            enabled: true,
            style: {
              color: "white",
              fontSize: "14px",
              textOutline: "none",
            },
          },
          borderRadius: 0,
        },
      },
      series: data,
    };

    return (
      <HighchartsReact
        className={"mx-auto !overflow-visible"}
        highcharts={Highcharts}
        options={options}
      />
    );
  }

  return (
    <section className="min-h-screen w-full max-w-screen-2xl mx-auto flex flex-col items-center justify-center">
      <p className="newmonthly-tag text-primary-300 text-center">Safety</p>
      <p className="newmonthly-title text-center">
        SBM teams work over 3X safer
        <br />
        than the industry average.
      </p>

      <div className="rounded-xl relative w-full max-w-screen-xl">
        <div className="relative w-full rounded-xl new-linear z-10 p-8 overflow-hidden">
          <div className="flex flex-row divide-x-2 divide-white/20 mb-6">
            <div className="border-l-4 border-primary-300 px-6 pr-12 w-64">
              <p className="font-bold text-white">Incidents</p>
              <p className="text-4xl font-normal text-white">
                {cbreIncidents.results
                  .reduce(
                    (a, b) => a + b.first_aid + b.report_only + b.near_miss,
                    0
                  )
                  .toLocaleString("en-US")}
              </p>
            </div>
            <div className=" px-6 pr-12 w-60">
              <p className="font-bold text-white">Recordables</p>
              <p className="text-4xl font-normal text-white">
                {cbreIncidents.results
                  .reduce((a, b) => a + b.recordable, 0)
                  .toLocaleString("en-US")}
              </p>
            </div>
          </div>
          <div className="complaints-chart-container">
            <IncidentsLineChart data={dataToSeries()} />
          </div>
        </div>
      </div>
      <button
        onClick={handleSafetyClick}
        className="new-linear rounded-full mx-auto text-white px-6 py-2 mt-8 uppercase font-medium"
      >
        View By Client <FontAwesomeIcon className="ml-2" icon={faPlus} />
      </button>
      <div ref={modal} className="hidden z-90 fixed top-0 left-0 h-full w-full flex items-center justify-center backdrop-blur-md fade-in opacity-0">
            <button onClick={handleSafetyClose} className="fixed top-4 right-4 z-90 p-4">
                <FontAwesomeIcon icon={faPlus} className="text-white text-4xl rotate-45" />
            </button>
        <div
          className="fixed top-0 left-0 right-0 bottom-0 opacity-90" onClick={handleSafetyClose} 
          style={{
            backgroundImage:
              "radial-gradient(ellipse at top, #24588780, #0C172380 80%), linear-gradient(#0C1723, #0C1723)",
            // backgroundSize: '200vw 200%',
            // backgroundPostion: '-50vw -50vh',
            backgroundRepear: "no-repeat",
          }}
        >
          <div
            className="h-full w-full opacity-50"
            style={{
              backgroundImage: `url(${noise})`,
              backgroundRepeat: "repeat",
              mixBlendMode: "screen",
            }}
          ></div>
        </div>
        <div className="z-50 w-full py-24 min-h-screen overflow-auto max-h-screen pointer-events-none">
          <div className="max-w-screen-xl mx-auto pointer-events-auto">
            <p className="text-xl font-extrabold mb-4 text-white">Safety Results By Client</p>
            <div className="pointer-events-auto">
            <ClientList data={safetyRecord} />
            </div>
          </div>
        </div>
       
      </div>
    </section>
  );
}

function ClientList({ data }) {
  const [clientData, setClientData] = useState(data.sort((a,b) => b.number_of_incidents - a.number_of_incidents) || []);
  const [sortValue, setSortValue] = useState("number_of_incidents");
  const [sortDirection, setSortDirection] = useState("desc");
  function sortByValue(key) {
    let sortedData;
  
    if (sortValue === key) {
      if (sortDirection === "desc") {
        setSortDirection("asc");
        sortedData = [...clientData].sort((a, b) => {
          return typeof a[key] === 'number' ? a[key] - b[key] : b[key].localeCompare(a[key]);
        });
      } else {
        sortedData = [...clientData].sort((a, b) => {
          return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
        });
        setSortDirection("desc");
      }
    } else {
      sortedData = [...clientData].sort((a, b) => {
        return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
      });
      setSortDirection("desc");
      setSortValue(key);
    }
  
    setClientData(sortedData);
  }

  return (
    <div className="col-span-3 overflow-hidden flex flex-col items-start justify-start">
        <div className="w-full rounded-xl overflow-hidden new-linear">
      <div className="w-full rounded-t-xl grid grid-cols-8 ">
        <div className="flex items-center my-4 pl-4 col-span-2">
          <h3
            data-ds2="h3"
            onClick={() => {
                sortByValue('name')
            }}
            className="whitespace-nowrap cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Client
            <span className="ml-2 w-8">
              <FontAwesomeIcon
                icon={
                  sortValue !== "name"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "name"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
        <div className="flex items-center my-4 col-span-2">
          {/* <h3
            data-ds2="h3"
            className="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Owner
          </h3> */}
        </div>
        <div className="flex items-center my-4">
          <h3
            data-ds2="h3"
            onClick={() => {
              sortByValue("number_of_incidents");
            }}
            className="whitespace-nowrap cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Incidents
            <span className="ml-2 w-8">
              <FontAwesomeIcon
                icon={
                  sortValue !== "number_of_incidents"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "number_of_incidents"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
        <div className="flex items-center my-4">
          <h3
            data-ds2="h3"
            onClick={() => {
              sortByValue("number_of_recordables");
            }}
            className="cursor-pointer whitespace-nowrap font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Recordables
            <span className="ml-2 w-10">
              <FontAwesomeIcon
                icon={
                  sortValue !== "number_of_recordables"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "number_of_recordables"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
        <div className="flex items-center my-4 col-span-2">
          <h3
            data-ds2="h3"
            onClick={() => {
              sortByValue("days_recordable_free");
            }}
            className="cursor-pointer whitespace-nowrap font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Recordable Free
            <span className="ml-2 w-6">
              <FontAwesomeIcon
                icon={
                  sortValue !== "days_recordable_free"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "days_recordable_free"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
      </div>
      <div className="h-1 w-full bg-primary-300 relative">
        <div className="absolute top-0 left-0 h-full w-full bg-primary-300 blur-sm"></div>
      </div>
      <div className="w-full flex-grow flex flex-col items-around justify-evenly rounded-b-xl">
        {clientData.map((client, key) => {
          return <ClientRow client={client} key={client.client_id} index={key}  />;
        })}
      </div> 
        </div>
    </div>
  );
}


function ClientRow({ client}) {
    const [showSites, setShowSites] = useState(false);
    function handleShow() {
      let toShow = !showSites;
      setShowSites(toShow);
    }
  
    function siteMap() {
      return client.sites.sort((a, b) => b.total_amount - a.total_amount).map((site, i) => {
          const date = new Date(site.contract_start_date);
          const dateStr = date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              });
        return (
          <>
            {/* <div className=" bg-black/20 border-b border-cyan-800/20 flex flex-row items-center 2xl:py-2 pl-4 col-span-2">
              <div className="w-4 2xl:h-10 mr-2 flex-shrink-0"></div>
              <div className="overflow-hidden pr-4">
                <p className="text-white text-sm font-roboto font-bold leading-none truncate">
                  {site.name}
                </p>
                <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
                  Contract Start: {dateStr}
                </p>
              </div>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center 2xl:py-2 col-span-2">
              {site.owner_poc?.photo_url ? (
                <img
                  src={"https://cdn.4insite.com/" + (site.owner_poc.photo_url.indexOf('asset') >= 0 ? "" : "image/")+ site.owner_poc.photo_url}
                  className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
                />
              ) : (
                <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
                  {site.owner_poc &&
                    site.owner_poc?.name.split(" ")[0].slice(0, 1) +
                      site.owner_poc?.name.split(" ")[0].slice(0, 1)}
                </div>
              )}
              <p className="text-white text-sm font-roboto font-bold">
                {(site.owner_poc &&
                  site.owner_poc.name ) ||
                  "Unassigned"}
              </p>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center 2xl:py-2 col-span-2">
              <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-xs 2xl:text-sm text-white font-black">
                {site.customer_poc &&
                  site.customer_poc?.name?.slice(0, 1) +
                    site.customer_poc?.name.slice(0, 1)}
              </div>
              <p className="text-white text-sm font-roboto font-bold">
                {(site.customer_poc &&
                  site.customer_poc?.name) ||
                  "Unassigned"}
              </p>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center 2xl:py-2 col-span-2">
              <p className="text-white text-sm font-roboto font-bold">
                {(document.referrer === "https://app.4insite.com/" ||
                  document.location.href.indexOf("localhost") > -1) &&
                  site.total_amount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  })}
              </p>
              <div className="w-full flex items-center justify-end">
              <div className="w-24">
                  <div className="h-6 rounded-lg overflow-hidden chart-in" style={{
                      width: site.total_amount / client.total_amount * 100 + "%",
                  }}></div>
              </div>
              </div>
            </div>
            <div className={` bg-black/20 border-b border-cyan-800/20 flex items-center justify-center 2xl:py-2 `}>
              <p
                className={`w-16 py-1 text-center text-neutral-900 text-sm 2xl:text-lg font-black rounded-lg ${
                  site.overall_score >= 4
                    ? "bg-success"
                    : "bg-failure"
                }`}
              >
                {site.overall_score || "--"}
              </p>
            </div> */}
             <div className="bg-black/20 flex border-b border-cyan-800/20  group-hover items-center 2xl:py-2 pl-4 col-span-2">
             <div className="w-4 2xl:h-10 mr-2 flex-shrink-0"></div>
              <div className="overflow-hidden pr-4">
                <p className="text-white text-sm font-roboto font-bold leading-none truncate">
                  {site.name}
                </p>
                <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
                  Contract Start: {dateStr}
                </p>
              </div>
        </div>
        <div className="bg-black/20 flex border-b border-cyan-800/20  items-center 2xl:py-2 col-span-2">
          {/* {client.owner_poc?.photo_url ? (
            <img
              src={"https://cdn.4insite.com/" + (client.owner_poc.photo_url.indexOf('asset') >= 0 ? "" : "image/") + client.owner_poc.photo_url}
              className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
            />
          ) : (
            <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
              {client.owner_poc &&
                client.owner_poc?.first_name?.slice(0, 1) +
                  client.owner_poc?.last_name.slice(0, 1)}
            </div>
          )}
          <p className="text-white text-sm font-roboto font-bold">
            {(client.owner_poc &&
              client.owner_poc?.first_name + " " + client.owner_poc?.last_name) ||
              "Unassigned"}
          </p> */}
        </div>
        <div className="bg-black/20 flex border-b border-cyan-800/20  items-center 2xl:py-2">
          <p className="text-white text-sm font-roboto font-bold">
            {site.number_of_incidents}
          </p>
        </div>
        <div className="bg-black/20 flex border-b border-cyan-800/20  items-center 2xl:py-2">
        <p className="text-white text-sm font-roboto font-bold">
            {site.number_of_recordables}
          </p>
        </div>
        <div className={`bg-black/20 flex border-b border-cyan-800/20  items-center col-span-2 2xl:py-2 `}>
        <p className="text-white text-sm font-roboto font-bold">
            {calculateTime(site.latest_recordable_date || site.contract_start_date)}
            {site.latest_recordable_date === null && <p className="text-xs font-medium text-neutral-600">*Since Contract Start Date</p>}
          </p>
        </div>
          </>
        );
      });
    }

    function calculateTime(date) {
      const today = new Date();
      const date1 = new Date(date);
      const years = differenceInYears(today, date1)
      const months = differenceInMonths(today, add(date1, {years}))
      const days = differenceInDays(today, add(date1, {years, months}))
      const yearStr = years >= 2 ? (years + " years") : years === 1 ? "1 year" : null;
      const monthStr = months >= 2 ? (months + " months") : months === 1 ? "1 month" : null;
      const dayStr = days >= 2 ? (days + " days") : days === 1 ? "1 day" : null;
      return [yearStr, monthStr, dayStr].filter(i => i).join(', ');
    }
  
    return (
      <div
        key={client.client_id}
        className="w-full grid grid-cols-8 group"
      >
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  group-hover items-center 2xl:py-2 pl-4 col-span-2">
          <FontAwesomeIcon onClick={handleShow} icon={showSites ? faCaretDown : faCaretRight} className="text-white cursor-pointer pr-2 py-2 w-4" />
          <button
            onClick={(el) => {
              handleShow();
            }}
          >
            {client.logo_url && client.logo_url.indexOf("https:") < 0 ? (
              <img
                src={"https://cdn.4insite.com/" + client.logo_url}
                className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
              />
            ) : client.logo_url && client.logo_url.indexOf("https:") > -1 ? (
              <img
                src={client.logo_url}
                className="h-6 w-6 p-1 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
              />
            ) : (
              <div className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 bg-neutral-900 flex items-center justify-center text-xl text-white font-black">
                {client.name.slice(0, 1)}
              </div>
            )}
          </button>
          <div>
            <p className="text-white text-sm font-roboto font-bold leading-none">
              {client.name}
            </p>
            <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
              {client.sites.length === 1
                ? "1 Site"
                : `${client.sites.length} Sites`}
            </p>
          </div>
        </div>
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2 col-span-2">
          {/* {client.owner_poc?.photo_url ? (
            <img
              src={"https://cdn.4insite.com/" + (client.owner_poc.photo_url.indexOf('asset') >= 0 ? "" : "image/") + client.owner_poc.photo_url}
              className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
            />
          ) : (
            <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
              {client.owner_poc &&
                client.owner_poc?.first_name?.slice(0, 1) +
                  client.owner_poc?.last_name.slice(0, 1)}
            </div>
          )}
          <p className="text-white text-sm font-roboto font-bold">
            {(client.owner_poc &&
              client.owner_poc?.first_name + " " + client.owner_poc?.last_name) ||
              "Unassigned"}
          </p> */}
        </div>
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2">
          <p className="text-white text-sm font-roboto font-bold">
            {client.number_of_incidents}
          </p>
        </div>
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2">
        <p className="text-white text-sm font-roboto font-bold">
            {client.number_of_recordables}
          </p>
        </div>
        <div className={`flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center col-span-2 2xl:py-2 `}>
        <p className="text-white text-sm font-roboto font-bold">
            {calculateTime(client.latest_recordable_date)}
          </p>
        </div>
        {showSites && siteMap()}
      </div>
    );
  }
  