import React from 'react';
import './modal.css';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.onClose = this.onClose.bind(this);
		this.modal = React.createRef();
		this.closeButton = React.createRef();
		this.modalContainer = React.createRef();
		this.modal = React.createRef();
	}

	componentDidMount() {
		gsap.to('.modal-container', {opacity: 1, duration: 0.3});
	}

	onClose(e) {
		if (e.target !== this.modalContainer.current && e.target !== this.closeButton.current) {return};
		gsap.to('.modal-container', {opacity: 0, duration: 0.3, onComplete: () => {
		this.props.close && this.props.close(e);
		}});
		}

	render() {
		if (!this.props.show) {
			return null;
		}
		let content = ''
		let type = '';
		if (this.props.modalContent) {
			content = this.props.modalContent;
			type = 'flex items-center justify-center';
		} else if (this.props.content.search('mp4') > 0) {
			content = <video className="modal-video" ref={this.modal} controls loop controlsList="nodownload noremoteplayback" disablePictureInPicture autoPlay playsInline src={this.props.content}/>
			type="video"
		} else if (this.props.content.search('jpg') > 0 || this.props.content.search('png') > 0 || this.props.content.search('jpeg') > 0) { 
			content = <img alt="" ref={this.modal} src={this.props.content} className="event-image"></img>
			type = "img"
		} else {
			content = <iframe ref={this.modal} title="modalmodal" src={this.props.content} allow="autoplay; fullscreen" allowFullScreen/>
			if (this.props.content.search('vimeo') > 0) {
				type = "iframe"
			} else {
				type="full"
			}
		}


		return (
			<div ref={this.modal} className="modal-container">
				<div className="relative overflow-y-auto overflow-x-hidden max-h-screen">
				<div onClick={(e) => {this.onClose(e);}} ref={this.modalContainer} className={"modal " + type } id="modal" >
					
					{content}
					{this.props.children}

					{/* <div className="close" onClick={() => this.onClose()}></div> */}
				</div>
				{/* <div className="close" onClick={() => this.onClose()}></div> */}
				</div>
					<button id="close-button" ref={this.closeButton}  className={"toggle-button "} onClick={(e) => this.onClose(e)}>
						<FontAwesomeIcon icon={faXmark} className="text-white pointer-events-none" />
					</button>
			</div>
		);
	}
}
