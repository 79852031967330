import { useEffect, useState, useRef, useCallback } from 'react';

import './toolkit.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import VideoModal from './toolkitslices/videoModal';
import PDFModal from './toolkitslices/pdfModal';

import { Client } from '../../App';
import * as prismic from '@prismicio/client';
import { motion } from "framer-motion";

import {LazyImage} from './image';

export default function Toolkit({showModal, items}) {
    const [articles, setArticles] = useState([]);
    const articleIndex = useRef(1);
    const pageTotal = useRef(0);
    const searchBar = useRef(null);
    const loader = useRef(null);
    const searchForm = useRef(null);
    const [searchStatus, setSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedTerm, setSearchedTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
  
    const fetchData = useCallback(async () => {
      const response = await Client.getByType('qbr-hero', {
        orderings: [
            {field:"my.qbr-hero.publication_date", direction: 'desc'},
            {field:"document.last_publication_date", direction: 'desc'}
        ], pageSize: 20, page: articleIndex.current
      })
      if (response) {
          setArticles(prevArticles => prevArticles.concat(response.results.map(a => a.data)));
          pageTotal.current = response.total_pages;
          articleIndex.current = articleIndex.current + 1;
      }
    }, []);

    const clearSearch = (e) => {
        setSearchResults([]);
        setSearchTerm('');
        setSearchedTerm('');
        setSearch(false);
        searchBar.current.value = '';
    }

    const search = useCallback(async () => {
        setSearchedTerm(searchTerm);
        if (!searchTerm) {
            clearSearch();
            return;
        }
        const response = await Client.query([
            prismic.Predicates.at('document.type', 'qbr-hero'),
            prismic.Predicates.fulltext('document', searchTerm)
        ]);
        if (response) {
            setSearch(true);
            
            setSearchResults(response.results.map(a => a.data));
        }
      }, [searchTerm]);
  
    const fetchDataRef = useRef();
    fetchDataRef.current = fetchData;

    // useEffect(() => {
    //     if (searchTerm) {
    //       search();
    //     }
    //   }, [searchTerm, search]);
  
    useEffect(() => {
      fetchDataRef.current();
  
      var options = {
          root: null,
          rootMargin: "20px",
          threshold: 1.0
      };
  
      const observer = new IntersectionObserver((entities) => {
        const target = entities[0];
        // console.log(articleIndex.current, pageTotal.current);
        if (target.isIntersecting && !(articleIndex.current > pageTotal.current)) {   
          // Fetch more articles here
          fetchDataRef.current();
        }
      }, options);
  
      if (loader.current) {
        observer.observe(loader.current)
      }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event);
        search();
        event.target.blur();
        searchForm.current.blur();
        searchBar.current.blur();
      }

      // detect type of item, and output correct slice component for modal
        const getSlice = (item) => {
            const {article_link, type, article_title, explanation, background_image} = item;
            switch(type) {
                case "video":
                    return <VideoModal video={item.article_link.url} title={item.article_title} story={item.explanation}/>
                case "image":
                    break;
                case "article":
                    break;
                case "pdf":
                    return <PDFModal pdf={item.article_link.url} title={item.article_title} story={item.explanation}/>
                case "iFrame":
                    return <iframe title={article_title} src={article_link.url} className='!relative h-full w-full aspect-video' frameBorder="0"></iframe>;
                default:
                    return <VideoModal video={item.article_link.url} title={item.article_title} story={item.explanation}/>
            }
        }

    return (
        <div>
            <div className="min-h-screen flex flex-col gap-6 items-center justify-start py-16 2xl:py-32 px-6">
                <div className="h-32">
                    <form ref={searchForm} className="search-form relative appearance-none mb-16 border border-neutral-200 rounded-2xl overflow-hidden focus-within:bg-neutral-800 focus-within:shadow-md focus-within:shadow-neutral-950/50 hover:bg-neutral-800 hover:border-transparent focus-within:border-transparent" onSubmit={handleSubmit}>
                        <div className="relative flex items-center justify-center ">
                                {searchTerm.length > 0 && <div tabIndex={1} role="button" aria-label="Clear search" onClick={clearSearch} className="absolute right-12 pr-4 border-r border-neutral-500 text-neutral-500 font-black leading-none text-2xl pointer-cursor flex items-center">✕</div>}
                                <input type="text" id="search" autoComplete="off" ref={searchBar} className="w-[45ch] appearance-none bg-transparent  py-2 px-6 pr-16 text-white focus:outline-none focus:ring-none " onChange={(e) => setSearchTerm(e.target.value)} />
                                <label className="absolute right-4" htmlFor='search'><FontAwesomeIcon icon={solid("magnifying-glass")} className="text-lg text-neutral-500"/></label>
                        </div>
                        {searchTerm.length > 0 && <div className="hidden top-full w-full p-4 flex items-center justify-center search-button">
                            <button onClick={handleSubmit} className="px-4 py-2 w-full bg-blue-500 rounded-lg font-medium uppercase tracking-wide">Search</button>                                
                        </div>}
                    </form>
                </div>
                {!searchStatus  && <div className="grid grid-cols-4 grid-rows-2 gap-6 max-w-screen-2xl w-full h-[50vh] min-h-[400px]">
                    {items.slice(0,5).map((item, index) => {
                        return (
                            <div onClick={(el) => {showModal(el, null, getSlice(item)
                            //<VideoModal video={item.article_link.url} title={item.article_title} story={item.explanation}/>
                            )}} key={index} className={`cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20 ${index === 0 ? "col-span-2 row-span-2" : ""}`}>
                                {/* <img src={item.background_image?.url} alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" /> */}
                                <LazyImage src={item.background_image?.url} placeholder={item.background_image?.thumb?.url} className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out"/>
                                {/* <picture>
                                    <source media="(min-width: 200px)" srcset={`${item.background_image?.url} 1x`}/>
                                    <img src={item.background_image?.thumb?.url} loading="lazy"  className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out"/>
                                </picture> */}
                                <div className="relative z-20 h-full w-full p-8 py-6 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                                    <h1 className="article-title">{item.article_title[0].text}</h1>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div onClick={(el) => {showModal(el, null, <VideoModal video={"https://player.vimeo.com/progressive_redirect/playback/795006549/rendition/1080p/file.mp4?loc=external&log_user=0&signature=db4cd7cd54020f3df49171530b73a55ecb3d1201c63c31b02b93a4c41df9a30e"}/>)}} className="cursor-pointer group rounded-xl h-[50vh] w-full relative row-span-2 col-span-3 overflow-hidden shadow-xl shadow-neutral-950/20">
                        
                        <video playsInline autoPlay muted loop src="https://about.sbm.management/value/assets/sowmsft-969fd796.mp4" className="absolute z-10 top-0 left-0 h-full w-full object-cover group-hover:scale-110 transition-transform duration-500 ease-in-out "></video>
                        <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                            <h1 className="text-4xl font-extrabold text-white">SOW Manager</h1>
                            p.
                        </div>
                    </div>
                    <div onClick={(el) => {showModal(el, null, <video autoPlay playsInline controls src="https://player.vimeo.com/progressive_redirect/playback/350005695/rendition/720p/file.mp4?loc=external&log_user=0&signature=a3b0e6f88974e485b2a7645c491ef25daa3446fe2b6368bbca81e5efde1c9bf2"></video>)}} className="cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20">
                        <img src="https://sbmpresentation.s3-us-west-1.amazonaws.com/toolkit/img/Associate_app.png" alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" />
                        <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                            <h1 className="text-2xl font-extrabold text-white">Associate App</h1>
                        </div>
                    </div>
                    <div onClick={(el) => {showModal(el, null, <video autoPlay playsInline controls src="https://player.vimeo.com/progressive_redirect/playback/350005695/rendition/720p/file.mp4?loc=external&log_user=0&signature=a3b0e6f88974e485b2a7645c491ef25daa3446fe2b6368bbca81e5efde1c9bf2"></video>)}} className="cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20">
                        <img src="https://sbmpresentation.s3-us-west-1.amazonaws.com/toolkit/img/Manager_app.png" alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" />
                        <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                            <h1 className="text-2xl font-extrabold text-white">Manager App</h1>
                        </div>
                    </div>
                    <div onClick={(el) => {showModal(el, null, <video autoPlay playsInline controls src="https://player.vimeo.com/progressive_redirect/playback/873457762/rendition/1080p/file.mp4?loc=external&log_user=0&signature=8b7577c2a931f1696fdb203a7a5f4f49b2b36a980fa714977b70dc1ee1bf71b2"></video>)}} className="cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20">
                        <img src="https://sbmpresentation.s3-us-west-1.amazonaws.com/toolkit/img/Manager_app.png" alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" />
                        <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                            <h1 className="text-2xl font-extrabold text-white">Manager App</h1>
                        </div>
                    </div> */}
                </div>}
                {searchResults.length > 0 && <div className="w-full max-w-screen-2xl grid grid-auto-rows grid-cols-4 gap-6 mx-auto mb-24">

                {searchResults.map((item, index) => {
                    return (
                        <motion.div initial={{ opacity: 0, y: 25, scale: 0.9 }}
                        whileInView={{
                          opacity: 1,
                          y: 0,
                          viewport: 0.9,
                          scale: 1,
                          startOffset: index / 10,
                          transition: {
                            type: "spring",
                            bounce: 0,
                            duration: 1,
                          },
                        }}
                        onClick={(el) => {showModal(el, null, getSlice(item) )}} key={index} className={`h-64 cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20`}>
                                <img src={item.background_image?.url || `https://picsum.photos/id/${index}/400` } alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" />
                                <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                                    <h1 className="article-title">{item.article_title[0].text}</h1>
                                </div>
                            </motion.div>
                    )
                })}
                </div>}

                {searchStatus && searchResults.length === 0 && <p className="text-white text-center text-xl ">No results found for "{searchedTerm}"</p>}
                <div className="flex flex-row items-center justify-center gap-10 mx-auto mt-8">
                    <a className="font-lato font-bold text-lg dark:text-white hover:text-blue-500 dark:hover:text-blue-500 transition-colors duration-300" href='https://sbmmobilefeatures.s3.us-west-1.amazonaws.com/index.html' target="_blank" rel="noreferrer" referrerPolicy='noreferrer'>Intro to the Associate App<FontAwesomeIcon className="ml-2" icon={solid("arrow-up-right-from-square")} /></a>
                    <a className="font-lato font-bold text-lg dark:text-white hover:text-blue-500 dark:hover:text-blue-500 transition-colors duration-300" href='https://sbmmobilefeatures.s3.us-west-1.amazonaws.com/manager20.html' target="_blank" rel="noreferrer" referrerPolicy='noreferrer'>Intro to the Manager App<FontAwesomeIcon className="ml-2" icon={solid("arrow-up-right-from-square")} /></a>
                    <button className="font-lato font-bold text-lg dark:text-white hover:text-blue-500 dark:hover:text-blue-500 transition-colors duration-300" onClick={(el) => {showModal(el, null, <iframe src='https://about-sbm.s3-us-west-1.amazonaws.com/_/clients.html' className='!relative h-full w-full aspect-video' frameBorder="0"></iframe> )}}>Our Clients<FontAwesomeIcon className="ml-2" icon={solid("expand")} /></button>
                </div>
            </div>

                <div className="px-6">
            <div className="relative max-w-screen-2xl w-full grid grid-auto-rows grid-cols-3 2xl:grid-cols-4 gap-6 mx-auto mb-24">
                {!searchStatus && articles.slice(5).map((item, index) => {
                    return (
                        <motion.div initial={{ opacity: 0, y: 25, scale: 0.9 }}
                        whileInView={{
                          opacity: 1,
                          y: 0,
                          viewport: 0.9,
                          scale: 1,
                          startOffset: index / 10,
                          transition: {
                            type: "spring",
                            bounce: 0,
                            duration: 1,
                          },
                        }}
                        onClick={(el) => {showModal(el, null, getSlice(item) )}} key={index} className={`h-64 cursor-pointer group rounded-xl relative overflow-hidden shadow-xl shadow-neutral-950/20`}>
                                <img src={item.background_image?.url || `https://picsum.photos/id/${index}/400` } alt="" className="absolute top-0 left-0 w-full h-full object-cover object-top group-hover:scale-110 transition-transform duration-500 ease-in-out" />
                                <div className="relative z-20 h-full w-full p-8 flex items-start justify-end flex-col bg-gradient-to-b from-transparent via-black/20 to-black/80">
                                    <h1 className="article-title">{item.article_title[0].text}</h1>
                                </div>
                            </motion.div>
                    )
                })}
                <div ref={loader} className="absolute bottom-[50vh] w-full opacity-0 pointer-events-none"></div>
            </div>

                </div>
                <div className="mb-10">
                    { !(articleIndex >= pageTotal) && <h2 className="text-white text-center">Loading More Articles...</h2>}
                </div>
        </div>
    )
}