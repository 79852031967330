import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

import Markdown from 'react-markdown'

export default function StoryModal({story}) {
    return (
        <div className="bg-neutral-900/80 backdrop-blur p-16 rounded-xl max-w-screen-xl text-white story overflow-auto" style={{maxHeight: '80vh'}}>
            <Markdown>{story}</Markdown>

        </div>
    )
}

export function StoryModalButton({story, showModal}) {
    return (
    <div className="absolute top-0 right-0 p-6 z-40">
        <FontAwesomeIcon onClick={(el) => showModal(el, null, <StoryModal story={`# A Night at the Museum

#### Each day, SBM services a variety of dynamic workspaces. Through our partnership with Boeing, our scope of work soars to new heights at the Future of Flight Museum.

## Sky High Service

As part of the Boeing Everett Factory, the Boeing Future of Flight Museum is a unique space. In addition to common areas and deep cleaning, the SBM team maintains the museum’s airplane exhibits. Between the preservation of life-size airplane structures, and smaller, more meticulous exhibits, we service the museum with the utmost care. 

* The Boeing Everett Factory is the largest building in the world by volume, measuring about 4.3 million square feet and 13.3 million cubic meters.
* The Boeing Everett team uses heavy duty equipment, such as boom lifts, scissor lifts, and SpaceVacs for the large airplane-sized exhibits. A chemical-free cleaning process is adopted to ensure no harm is brought to the historic museum pieces.
* SBM supports a range of diverse events every day, servicing spaces with foot traffic of about 300+ attendees each week. Past events include marriage proposals, Celebrations of Life, high school prom nights, international student field trips, Hispanic Heritage Month celebrations, local political interviews, and the retirement celebration of the Model 747. 

## Soaring Feedback

We understand the importance of maintaining a high standard, and we take it seriously. The strict attention to detail while servicing Boeing Everett’s Museum facilitates a strong partnership, resulting in a happy workforce. Client partners praise Fatima’s team and their commitment to quality service. This is evident in the average 4.85/5.00 on client surveys and 4.91/5.00 in joint audits in 2023. Looking forward, the sky is the limit on what the Boeing-SBM relationship can accomplish. 

**Quotes:**

“The museum and its employees have become like family to me, and the atmosphere here is wonderful.
It's hard to believe I've been working at the museum for the past four years. It's a pleasure to work in creating such an exceptional environment.”
— Fatima Saunders - SBM Boeing Everett Site Manager

“SBM has continued with seasonal deep cleans to stay ahead of our peak attendance volume and support our public tour launch schedule. Many thanks!”
— Christina Medlyn - General Manager Boeing Future of Flight 

"The Future of Flight and JLL are very thankful for the partnership with SBM. I appreciate how Fatima and SBM treat their employees. This has led to employees with great attitudes and long tenures. Thank you, Fatima, Desiree, Dani, and Tao!"
— Joseph Sutton - JLL Operating Engineer `}/>)} icon={faCirclePlus} className="text-white hover:opacity-50 text-2xl cursor-pointer"/>
    </div>
    )
}