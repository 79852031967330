import {useState, useEffect, useRef} from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Switch from './switch';

//CBREData 
import {cbreComplaints, cbreEscalatedRepeat} from './data';

//Highcharts Config
require("highcharts/highcharts-more")(Highcharts);

Highcharts.setOptions({
    colors: ['#4AA8FF', '#734CFF', '#A231D2', '#DC00B6', '#FF7DB1', '#FF4334', '#FF6F19', '#C29201', '#E5C801', '#00FF8D', '#01CCA7', '#D0D5D9']
});

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});
//end Highcharts Config


export default function Complaints() {
    const [complaintSelection, setComplaintSelection] = useState('complaints');
    const [categories, setCategories] = useState(cbreComplaints.results.map((d) => {
        let date = new Date(d.mo);
        return date.toLocaleString('default', {month: 'short', year: '2-digit'})
    }));
    const [value, setValue] = useState(false);


    function dataToSeries(type ) {
        if (type === 'complaints') {
            return [
                {
                    name: 'In Scope',
                    data: cbreComplaints.results.map((d) => {return d.in_scope} ),
                    marker: {
                        symbol: 'circle'
                    }
                },
                {
                    name: "Out of Scope",
                    data: cbreComplaints.results.map((d) => {return d.out_of_scope}),
                    marker: {
                        symbol: 'circle'
                    }
                }
            ]
        } else {
            return [
                {
                name:'Escalations',
                data: cbreEscalatedRepeat.results.map((d) => {return d.total_is_escalated}),
                                    marker: {
                        symbol: 'circle'
                    }
        },
                {
                name:'Repeat',
                data: cbreEscalatedRepeat.results.map((d) => {return d.total_is_repeat_complaint}),
                                    marker: {
                        symbol: 'circle'
                    }
        }
    ]

    }}

    function handleChange() {
        if (complaintSelection === 'complaints') {
            setComplaintSelection('escalations')
        } else {
            setComplaintSelection('complaints')
        }
    }

    function ComplaintsAreaSpline({data}) {
        const options = {
          title: {
            text: "",
          },
          chart: {
            backgroundColor: "transparent",
            type: complaintSelection === "complaints" ? "line" : "column",
            margin: [0, 0, 50, 0],
          },
          legend: {
            verticalAlign: 'top',
            enabled: true,
            align: 'right',
            layout: "horizontal",
            symbolRadius: 2,
            y: -100,
            // labelFormatter: function () {
            //   return `<div class="w-full flex items-center justify-between"><p>${
            //     this.name
            //   }</p><p>${this.percentage.toFixed(1)}%</p></div>`;
            // },
            useHTML: true,
            itemStyle: {
              color: "#89929B",
              fontWeight: "normal",
              fontSize: "14px",
            },
          },
          credits: {
            enabled: false,
          },
          yAxis: {
            gridLineColor: "rgba(255,255,255,0.3)",
            title: '',
            labels: {
                enabled: false,
            }
          },
          xAxis: {
            categories: categories,
            tickColor: "white",
            lineColor: "rgba(255,255,255,0.3)",
            labels: {
              style: {
                color: "white",
                fontWeight:"bold"
              },
            },
          },
          tooltip: {
            enabled: false,
            backgroundColor: "rgb(12, 15, 18)",
            outside: true,
            shadow: false,
            // formatter: function () {
            //   return `${this.point.name}: ${this.percentage.toFixed(1)}%`;
            // },
            style: { color: "#ffffff", fontSize: "16px" },
          },
          plotOptions: {
            pie: {
              innerSize: "70%",
              labels: {
                enabled: false,
              },
              showInLegend: false,
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                },
                borderWidth: 0,
                borderColor: "transparent",
                borderRadius: 10,
                style: {
                }
            },
            series: {
              dataLabels: {
                enabled: true,
                style: {
                    color: "white",
                    fontSize: '14px',
                    textOutline: 'none'
                }
              },
              borderRadius: 0,
            },
          },
          series: data,
        };
    
        return (
          <HighchartsReact
            className={"mx-auto !overflow-visible"}
            highcharts={Highcharts}
            options={options}
          />
        );
      }

    return(
        <section className="min-h-screen w-full max-w-screen-2xl mx-auto flex flex-col items-center justify-center">
             <p className="newmonthly-tag text-primary-300 text-center">
                Complaints & Escalations
            </p>
            <p className="newmonthly-title text-center">
                For us, every complaint is an opportunity to <br/>improve our service and
                your experience.
            </p>
            <div className="flex items-center justify-center mb-6" >
                <p className="text-right w-40 mr-4 text-white">In Scope/Out of Scope</p>
            <Switch
        isOn={value}
        onColor={"#734CFF"}
        offColor={"#4AA8FF"}
        handleToggle={() => {handleChange();setValue(!value)}}
      />
                <p className="text-left w-40 ml-4 text-white">Escalations/Repeat</p>

            </div>
            <div className="rounded-xl relative w-full max-w-screen-xl">
                <div className="relative w-full rounded-xl new-linear z-10 p-8 overflow-hidden">
                    <div className="flex flex-row divide-x-2 divide-white/20 mb-6">
                        <div className="border-l-4 border-primary-300 px-6 pr-12 w-64">
                            <p className="font-bold text-white">Total Complaints</p>
                            <p className="text-4xl font-normal text-white">{
                            cbreEscalatedRepeat.results.reduce((a,b) => a + b.total,0).toLocaleString('en-US')
                            }</p>
                        </div>
                        {complaintSelection === 'complaints' && (
                            <>
                        <div className=" px-6 pr-12 w-60">
                            <p className="font-bold text-white">In Scope</p>
                            <p className="text-4xl font-normal text-white">{
                            cbreComplaints.results.reduce((a,b) => a + b.in_scope,0).toLocaleString('en-US')
                            }</p>
                        </div>
                        <div className=" px-6 pr-12 w-60">
                            <p className="font-bold text-white">Out of Scope</p>
                            <p className="text-4xl font-normal text-white">{
                            cbreComplaints.results.reduce((a,b) => a + b.out_of_scope,0).toLocaleString('en-US')
                            }</p>
                        </div>
                            </>
                        )}
                        {complaintSelection === 'escalations' && (
                            <>
                        <div className=" px-6 pr-12 w-60">
                            <p className="font-bold text-white">Escalated</p>
                            <p className="text-4xl font-normal text-white">{
                            cbreEscalatedRepeat.results.reduce((a,b) => a + b.total_is_escalated,0).toLocaleString('en-US')
                            }</p>
                        </div>
                        <div className=" px-6 pr-12 w-60">
                            <p className="font-bold text-white">Repeat</p>
                            <p className="text-4xl font-normal text-white">{
                            cbreEscalatedRepeat.results.reduce((a,b) => a + b.total_is_repeat_complaint,0).toLocaleString('en-US')
                            }</p>
                        </div></>

                        )}
                    </div>
                    <div className="complaints-chart-container">
                    <ComplaintsAreaSpline data={dataToSeries(complaintSelection)}/>
                    </div>
                </div>
            </div>
        </section> 
    )
}