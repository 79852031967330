import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './charts.css';
import { InView }  from 'react-intersection-observer';

require("highcharts/highcharts-more")(Highcharts);

Highcharts.setOptions({
    colors: ['#4AA8FF', '#A230D2', '#DC00B6', '#FF7DB1', '#FF4333', '#FF6E19', '#E5C800', '#00FF8D', '#01CCA6']
});

Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

export default function Chart({title, data}) {

    const options = {
        title: {
          text: ''
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                borderColor: '',
                showInLegend: true,
                startAngle: 180,
                
            },
            series: {
                legend: {
                    enabled: true,
                },
                lables: {
                    enbaled: false
                }
            }
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
        },
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            spacing: 0,
            spacingTop:10,
            height: '100%'
        },
    
        series: [{
          data: data ? data : [{name: "people", y: 1},{name: "people2", y: 2},{name: "people3", y: 3},{name: "people4", y: 4},{name: "people5", y: 5}],
          dataLabels: {
            enabled: false
        }
        }]
      }
      

    return (
        <InView options={{threshold: 1}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className="h-full w-full">
                <p className="text-lg 2xl:text-xl font-bold dark:text-white">{title}</p>
              {inView && <HighchartsReact highcharts={Highcharts} options={options}/>}
            </div>
            )}
        </InView>
    )
}

export function CustomChart({title, data, type, height, chart, legend, plotOptions, tooltip, series, categories, xAxis, yAxis, spacingTop, spacing}) {
    const options = {
        title: {
          text:  '',
          color: 'white',
        },
        credits: {
            enabled: false,
        },
        plotOptions: plotOptions || {
            pie: {
                innerSize: '70%',
                borderColor: '',
                showInLegend: true,
                startAngle: 0,
            },
            series: {
                legend: {
                    enabled: true,
                },
                lables: {
                    enbaled: false
                }
            },
            column: {
                borderWidth:0,
                borderColor: 'transparent',
                borderRadius: '10px'
            }
        },
        legend: legend || {
            enabled: true,
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'vertical',
            itemMarginBottom: 10,
            itemStyle: {
                color: 'white',
                fontSize: '16px !important'
            }
        },
        xAxis: xAxis || {
            categories: categories,
            labels: {
                style: {
                    color: 'white',
                }
            },
            gridLineColor: "rgb(91, 102, 113)",
            lineColor: "rgb(91, 102, 113)",
        },
        yAxis: yAxis || {
            
            labels: {
              style: {
                fontSize: "12px",
                fontWeight: "bold",
                color: "rgb(91, 102, 113)",
              },
              formatter: function () { return this.value + "%"; }
            },
            min: 0,
            title: '',
            gridLineDashStyle: "ShortDash",
            gridLineColor: "rgb(91, 102, 113)",
            reversedStacks: false,
            scrollbar: { enabled: true },
            index: 0,
          },
        tooltip: tooltip || {
            backgroundColor: "rgb(12, 15, 18)",
            outside: true,
            shadow: false,
            style: { color: "#ffffff" , fontSize: '16px'},
            headerFormat: '',
            pointFormat: '{point.name}: <b>{point.y:.1f}%</b>'
        },
        chart: chart || {
            type: type || 'pie',
            backgroundColor: 'transparent',
            spacing: spacing || 0,
            spacingTop: spacingTop || 10,
            height: height || '100%',
        },
    
        series: series || [{
          data: data ? data : [{name: "people", y: 1},{name: "people2", y: 2},{name: "people3", y: 3},{name: "people4", y: 4},{name: "people5", y: 5}],
          dataLabels: {
            enabled: false
        }
        }]
      }

      return (
        <InView options={{threshold: 1}}>
        {({ inView, ref, entry }) => (
            <div ref={ref} className="h-full w-full">
                {title && <p className="text-lg 2xl:text-xl font-bold dark:text-white mb-4">{title}</p>}
              {inView && <HighchartsReact highcharts={Highcharts} options={options}/>}
            </div>
            )}
        </InView>
    )
}