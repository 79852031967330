import { useRef } from 'react';
import { RichText } from "prismic-reactjs"
import "./story.scss";


export default function PDFModal({pdf, title, story}) {
    const articleContainer = useRef(null);
    
    function fadeIn(el) {
        el.target.classList.remove("opacity-0");
        el.target.play();
    }

    function showHideInfo() {
        articleContainer.current.classList.toggle("closed");
    }

    return (
        // <div className="h-full w-full flex items-center justify-center">
        <div className="fixed top-0 left-0 right-0 bottom-0 p-16 pointer-events-none">
        <div className="pointer-events-auto flex flex-row h-full w-full min-h-none max-h-full rounded-xl overflow-hidden bg-neutral-900  shadow-neutral-900/40 shadow-xl">
            <div className="col-span-2 h-full w-full p-6 flex items-center justify-center">
                <object className="h-full w-full" data={pdf + '#view=fitV'} type="application/pdf"></object>
            </div>
            {story.length > 0 && <div id="feature-article" ref={articleContainer} className="relative closed">
                <div onClick={showHideInfo}  className=" group absolute cursor-pointer left-0 top-1/2 h-12 w-12 rounded-l-xl bg-neutral-800 p-2 flex flex-col items-center justify-center gap-2 z-20" style={{transform: "translate(calc(1px - 100%), -50%)"}}>
                    {/* <div className="h-1 bg-neutral-900 w-full rounded-full"></div>
                    <div className="h-1 bg-neutral-900 w-full rounded-full"></div>
                    <div className="h-1 bg-neutral-900 w-full rounded-full"></div> */}
                    <div className="space-y-2 hamburger">
                        <span className="block h-1 w-8 origin-center rounded-full bg-neutral-100 transition-transform ease-in-out ggroup-hover:translate-y-1.5 ggroup-hover:rotate-45"></span>
                        <span className="block h-1 w-6 origin-center rounded-full bg-neutral-100 transition-transform ease-in-out ggroup-hover:w-8 ggroup-hover:-translate-y-1.5 ggroup-hover:-rotate-45"></span>
                    </div>
                </div>
                <div className="relative h-full overflow-y-auto p-6 lg:p-12 bg-neutral-800 text-neutral-950 border-l border-neutral-600/20  z-10" style={{width: "min(50vw, 500px)"}}>
                {RichText.render(title)}
                {RichText.render(story)}
                {/* <div className="border-t pt-4 border-neutral-400">
                    <h2 className="text-2xl font-bold text-neutral-900">Materials</h2>
                    <a href={video} download={`${title}.mp4`}><button>Download</button></a>
                </div> */}
                </div>
            </div>}
        </div>
        </div>
        // </div>
    )
}