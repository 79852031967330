import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import { react, useRef, useState } from "react";

import gsap from "gsap";

import MonthlySwitch from "./monthlyswitch";

import nyc from "./assets/nyc.jpg";
import puget from "./assets/puget.jpg";
import rtp from "./assets/rtp.jpeg";
import lab from "./assets/lab.jpeg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faUsers, faStar } from "@fortawesome/free-solid-svg-icons";

function BasicStory() {
  const [active, setActive] = useState(false);
  const slide = useRef(null);

  return (
    <SwiperSlide
      ref={slide}
      className="h-screen rounded-xl overflow-hidden no-scrollbar"
    >
      <div className="py-16 min-h-full w-full flex items-center">
        <div className="relative w-full rounded-xl overflow-hidden bg-blue-950 pointer-events-auto">
          <div className="relative">
            <img
              src={nyc}
              alt=""
              className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
            />
            <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
            <div className="p-16 relative z-10">
              <img
                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/deutsche-bank.svg"
                alt=""
                className="mx-auto my-16 brightness-0 invert h-16"
              />
            </div>
          </div>
          <div className="p-8 pt-6 relative">
            <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-blue-500">
              Highlight
            </p>
            <h4 className="text-5xl font-bold text-white pr-6">
              Sky High Success in 
              <br />
              New York City Market Entrance
            </h4>
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="absolute top-6 right-8 text-2xl text-white"
            />
            <div className="max-h-0 overflow-hidden"></div>
          </div>

          {/* <p className="text-3xl font-normal text-white mb-6">
                              SBM’s support during critical union negotiations saved CBRE
                              clients $XXXXX.
                            </p> */}
        </div>
      </div>
    </SwiperSlide>
  );
}

export default function Highlights2({ data, showModal }) {
  
  
  function handleClick(el) {
    el.preventDefault();
    if (el.currentTarget.parentNode.parentNode.parentNode.style['transition-duration'].length > 3) return
    if (!el.currentTarget.closest('.swiper-slide').classList.contains("swiper-slide-active")) return;
    let story = el.currentTarget.querySelector("#story");
    let headerImage = el.currentTarget.querySelector(".header-image")
    if (!story) return;
    if (story.classList.contains("max-h-0") && (story.style.maxHeight === '' || story.style.maxHeight === '0px') ) {
      let tl = gsap.timeline();

      tl.to(story, {maxHeight: story.scrollHeight, duration: 0.5, ease: 'power2.inOut'})
      .fromTo(headerImage, {maxHeight: headerImage?.scrollHeight + 'px', opacity:1}, {maxHeight: '0px', duration: 0.5, ease: 'power2.inOut', opacity: 0}, 0);
    } else {
      gsap.to(story, {maxHeight: 0, duration: 0.5, ease: 'power2.inOut'})
      gsap.to(headerImage, {maxHeight: headerImage?.scrollHeight + 'px', opacity: 1, ease: 'power2.inOut'});
    }

  }

  function handleSwiperScroll(event) {
    let swiperContainer = event.currentTarget;
    let activeSlide = swiperContainer.querySelector(".swiper-slide-active");
    let activeElement = event.target.closest('.swiper-slide-active');
    if (activeSlide === activeElement) return;
    console.log(activeSlide);
    activeSlide.scrollTop += event.deltaY;
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
      <div
        onWheel={(event) => handleSwiperScroll(event)}
        className="h-full w-full monthly-swiper no-w-auto mx-auto"
      >
        <Swiper
          className="h-screen"
          spaceBetween={0}
          slidesPerView={3}
          centeredSlides={true}
          slideToClickedSlide={true}
          navigation={true}
          modules={[Navigation]}
        >
          {/* <SwiperSlide className="h-screen overflow-y-auto no-scrollbar ">
            <div className="py-16 min-h-full w-full flex items-center">
              <div onClick={el => handleClick(el)} className="relative w-full rounded-xl overflow-hidden bg-blue-950 pointer-events-auto cursor-pointer">
                <div className="relative header-image overflow-hidden">
                  <img
                    src={nyc}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10">
                    <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/deutsche-bank.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    />
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-blue-500">
                    Highlight
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                  Sky High Success in 
              <br />
              New York City Market Entrance
                  </h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="absolute top-6 right-8 text-2xl text-white"
                  />
                  <div id="story" className="max-h-0 overflow-hidden">
                <div className="flex flex-row items-center gap-1 my-6">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-500 text-xl"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-500 text-xl"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-500 text-xl"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-500 text-xl"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-yellow-500 text-xl"
                  />
                  <p className="text-xl font-normal text-white">(5.0)</p>

                </div>
                  <p className="text-3xl font-normal text-white mb-6 mt-6">
                  Survey Feedback
                </p>
                <p className="text-lg font-normal text-white mb-4">
                SBM came into this account without a track record of servicing NYC offices much less a headquarters building in Manhattan. They not only delivered excellent service, but they have knocked it out of the park. As I have stated before… They are not a cleaning company that uses technology. They are more of a technology company that delivers cleaning. 
                </p>
                <p className="text-lg font-normal text-white mb-4">
                We have seen our complaints associated with cleaning all but disappear. Their customer service, response and the way they stepped in to manage the existing team is excellent. You can actually note a difference in their employees satisfaction working for them that trickles throughout.                </p>
                <div className="p-1 flex flex-row items-center">
                  <div className="h-12 w-12 rounded-full bg-blue-500 grid place-items-center">
                    <p className="text-lg font-bold text-center">GL</p>
                  </div>
                  <div>
                  <p className="ml-2 text-white text-lg font-bold">Gene Limia</p>
                  <p className="ml-2 text-sm text-blue-500">gene.limia@cbre.com</p>
                  </div>

                </div>
                  </div>
                </div>


              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
            <div className="py-16 min-h-full w-full flex items-center">
              <div onClick={el => handleClick(el)} className="relative w-full rounded-xl overflow-hidden bg-emerald-950 pointer-events-auto">
                <div className="relative">
                  <img
                    src={puget}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10">
                    <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Microsoft.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    />
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-emerald-500">
                    Opportunity
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                   Enhancing processes for growth & retention
                  </h4>
                 
                </div>


              </div>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
            <div className="py-16 min-h-full w-full flex items-center">
              <div
                onClick={(el) => handleClick(el)}
                className="relative w-full rounded-xl overflow-hidden bg-cyan-950 pointer-events-auto cursor-pointer"
              >
                <div className="relative header-image overflow-hidden">
                  <img
                    src={rtp}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10">
                    <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Biogen.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    />
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-cyan-500">
                    Highlight
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                    From CAPA to
                    <br />
                    contract renewal
                  </h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="absolute top-6 right-8 text-2xl text-white"
                  />
                  <div id="story" className="max-h-0 overflow-hidden">
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                      Problems
                    </p>
                    <ul className="list-blue-disc list-outside text-lg text-white mb-4">
                      <li className="ml-8 mb-2">
                        The site was significantly understaffed, with wages
                        below market value. It was difficult to recruit and
                        retain employees.
                      </li>
                      <li className="ml-8 mb-2">
                        The portfolio had low CSAT scores and low customer
                        satisfaction as a result of the turnover and lack of
                        consistency with the staff understanding the scope of
                        work/requirements.{" "}
                      </li>
                      <li className="ml-8 mb-2">
                        Deviations in the GMP scope was a primary concern from a
                        quality perspective.{" "}
                      </li>
                    </ul>
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                      Solutions
                    </p>
                    <ul className="list-blue-disc list-outside text-lg text-white mb-4">
                      <li className="ml-8 mb-2">
                        Under the leadership of Matt Borsick, an SBM
                        Manager-In-Training (MIT), SBM revamped the SOW and
                        optimized routes to align with Biogen’s and CBRE’s
                        expectations. The scope became more specific to
                        frequency requests that have been worked into daily
                        schedules
                      </li>
                      <li className="ml-8 mb-2">
                        Biogen and CBRE approved funding to increase wages,
                        which weren’t competitive with industry and non-industry
                        rates. SBM also focused on cultural alignment with the
                        new and incumbent associates.{" "}
                      </li>
                      <li className="ml-8 mb-2">
                        SBM held job fairs, recruiting and hiring several new
                        employees and solidifying structure of leadership at
                        each facility. The new RTP GMP Manager has been a top
                        performer.{" "}
                      </li>
                      <li className="ml-8 mb-2">
                        GMP training was significantly increased, including
                        lunch & learns and 1:1 training with new and existing
                        employees. Training includes site- and client-specific
                        learning.
                      </li>
                    </ul>
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                      RTP Results
                    </p>
                    <p className="text-lg text-white">
                      Employee wages{" "}
                      <strong className="font-black">increased by 11%</strong>{" "}
                      across the board
                    </p>
                    <hr className="my-4 border-t border-cyan-800" />
                    <p className="text-lg text-white">
                      Reached{" "}
                      <strong className="font-black">
                        2 years recordable-free
                      </strong>
                    </p>
                    <hr className="my-4 border-t border-cyan-800" />
                    <p className="text-lg text-white">
                      <strong className="font-black">
                        Zero Regulatory findings
                      </strong>{" "}
                      over the past year
                    </p>
                    <hr className="my-4 border-t border-cyan-800" />
                    <p className="text-lg text-white">
                      <strong className="font-black">
                        2-year Contract extension
                      </strong>{" "}
                      for all facilities
                    </p>
                    <hr className="my-4 border-t border-cyan-800" />
                    <p className="text-lg text-white">
                      <strong className="font-black">48% improvement </strong>{" "}
                      in turnover rate
                    </p>
                    <hr className="my-4 border-t border-cyan-800" />
                  </div>
                </div>

                {/* <p className="text-3xl font-normal text-white mb-6">
                              SBM’s support during critical union negotiations saved CBRE
                              clients $XXXXX.
                            </p> */}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
            <div className="py-16 min-h-full w-full flex items-center">
              <div
                onClick={(el) => handleClick(el)}
                className="relative w-full rounded-xl overflow-hidden bg-teal-950 pointer-events-auto"
              >
                <div className="relative header-image overflow-hidden">
                  <img
                    src={lab}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10 flex items-center justify-center gap-6">
                    <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Merck.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    />
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-teal-500">
                    Opportunity
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                    Making the transition to Dynamic Clean
                  </h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="absolute top-6 right-8 text-2xl text-white"
                  />
                  <div id="story" className="max-h-0 overflow-hidden">
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                    In late 2022, CBRE launched the Dynamic Cleaning initiative, revolutionizing janitorial services by tailoring them to tenant usage patterns. SBM, our trusted janitorial service provider, played a crucial role in implementing and optimizing this program, showcasing our collaborative expertise in facility management.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    The inaugural site for Dynamic Cleaning was Merck's Upper Gwynedd location, strategically chosen for its highly administrative makeup.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    Working hand in hand with CBRE's Smart FM Team, SBM integrated Dynamic Cleaning into our cleaning protocols.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    SBM's involvement from the outset was pivotal. Leveraging our deep understanding of janitorial services at the UG location, we enhanced and refined the initial implementation of Dynamic Cleaning, maximizing its impact.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    Dynamic Cleaning delivered impressive results at Merck Upper Gwynedd, yielding a noteworthy 19.29% savings.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    Encouraged by this success, we continue collaborating with CBRE on implementation opportunities of Dynamic Cleaning at Takeda and intend to explore further opportunities within the life sciences sector.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
            <div className="py-16 min-h-full w-full flex items-center">
              <div
                onClick={(el) => handleClick(el)}
                className="relative w-full rounded-xl overflow-hidden bg-red-950 pointer-events-auto"
              >
                <div className="relative header-image overflow-hidden">
                  <img
                    src={lab}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10 flex items-center justify-center gap-6">
                    <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Lilly.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    />
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-red-500">
                    Opportunity
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                    A Study in Collaboration
                  </h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="absolute top-6 right-8 text-2xl text-white"
                  />
                  <div id="story" className="max-h-0 overflow-hidden">
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                      SBM has had the privilege of providing service at Eli
                      Lilly Indianapolis for over 10 years. This includes
                      performing work inside the GMP production space. Through
                      the years, there have been some ups and downs in
                      performance.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                      In late 2022, it became apparent that a foundational
                      change was needed to consistently deliver the level and
                      quality of services that CBRE and Lilly require. As a
                      result, the following actions were taken:
                    </p>
                    <ul className="list-blue-disc list-outside text-lg text-white mb-4">
                      <li className="ml-8 mb-2">
                        Assessment of performance to standardized quality and
                        safety processes.
                      </li>
                      <li className="ml-8 mb-2">
                        Leadership and structure changes to support the needed
                        foundational changes.
                      </li>
                      <li className="ml-8 mb-2">
                        Addition of quality manager and enhanced Safety manager
                        support.
                      </li>
                      <li className="ml-8 mb-2">
                        Engagement of “off-account” senior leadership support,
                        including the COO and SVP of the Life Science vertical.
                      </li>
                      <li className="ml-8 mb-2">
                        Established weekly joint review of quality process
                        management with CBRE.
                      </li>
                    </ul>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                      The last point was critical to the ultimate success of the
                      improvement initiative. CBRE leadership was generous with
                      their time. For the first 6 months of 2023, monthly review
                      meetings occurred and were attended by senior CBRE
                      leadership. The discussions in these meetings were direct
                      and supportive at the same time. In fact, in early 2023
                      CBRE indicated their intent to conduct an RFP for the
                      Indianapolis site. They also provided clear criteria for
                      SBM to avoid such an action and supported our efforts to
                      achieve those criteria.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                      Today, there is consensus that SBM is now in sustaining
                      mode. We continue to partner to identify and engage
                      continuous improvement actions. Without the high level of
                      partnership and collaboration, the results would likely
                      have been much different.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
            <div className="py-16 min-h-full w-full flex items-center">
              <div
                onClick={(el) => handleClick(el)}
                className="relative w-full rounded-xl overflow-hidden bg-orange-950 pointer-events-auto cursor-pointer"
              >
                <div className="relative header-image overflow-hidden">
                  <img
                    src={
                      "https://sbmmanagement.com/public/images/backgrounds/collage-wider.jpg"
                    }
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  />
                  <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                  <div className="p-16 relative z-10 flex items-center justify-center gap-6">
                    <div className="my-16 h-16 w-16 rounded-full border-2 border-white flex items-center justify-center gap-6">
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="text-3xl text-white"
                      />
                    </div>
                    <p className="text-5xl font-extrabold whitespace-nowrap text-white">
                      SBM People
                    </p>
                    {/* <img
                      src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Biogen.svg"
                      alt=""
                      className="mx-auto my-16 brightness-0 invert h-16"
                    /> */}
                  </div>
                </div>
                <div className="p-8 pt-6 relative">
                  <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-orange-500">
                    Highlight
                  </p>
                  <h4 className="text-5xl font-bold text-white pr-6">
                    Retaining tenured teams in a changing market
                  </h4>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="absolute top-6 right-8 text-2xl text-white"
                  />
                  <div id="story" className="max-h-0 overflow-hidden">
                    <p className="text-3xl font-normal text-white mb-6 mt-6">
                      As unemployment hit a 54-year record low in 2023, SBM
                      maintained 18% annualized turnover across our CBRE
                      portfolio—10X better than industry average.
                    </p>
                    <p className="text-lg font-normal text-white mb-4 last:mb-0">
                      Our CBRE portfolio is comprised of tenured and engaged
                      associates, with 40% of employees employed with us for 3+
                      years, and many nearing 15 years. Our employer-of-choice
                      practices go beyond market-competitive wages and benefits,
                      and include:
                    </p>
                    <ul className="list-blue-disc list-outside text-lg text-white mb-4">
                      <li className="ml-8 mb-2">
                        cultural alignment inspiring ownership approach
                      </li>
                      <li className="ml-8 mb-2">
                        financial opportunity with the employee scorecard bonus
                        program
                      </li>
                      <li className="ml-8 mb-2">
                        empowerment through our mobile platform
                      </li>
                      <li className="ml-8 mb-2">
                        training and career growth paths
                      </li>
                      <li className="ml-8 mb-2">
                        employee programs and initiatives
                      </li>
                    </ul>
                    <hr className="mt-20 mb-12 border-t border-orange-700" />

                    <div className="flex flex-row gap-6">
                      <div className="w-1/3">
                        <p className="text-6xl font-normal text-white">
                          40
                          <span className="text-4xl text-orange-700 font-normal uppercase">
                            %
                          </span>
                        </p>
                        <p className="text-orange-700 text-sm font-normal">
                          of our CBRE team has been with us 3+ years!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
