import "./monthlyhero.css";

// import { Route, Routes, Link } from "react-router-dom";
import {useSearchParams} from 'react-router-dom'
import { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import { CustomChart } from "../charts/chart.js";

import { gsap } from "gsap";

import { motion, useInView } from "framer-motion";

//videos for cards
import sowloop from "./assets/sowloop2b.mp4";
import ecovadismov from "../../assets/videos/ecovadis2.mov";
import ecovadisebm from "../../assets/videos/ecovadis.webm";
import sus from "../../assets/videos/sus.mp4";
import marketupdates from "../../assets/videos/marketupdates.gif";
import highlightsmov from "../../assets/videos/highlights.mov";
import highlightswebm from "../../assets/videos/highlights.webm";
import darkscorecardgeneric from "../../assets/images/darkscorecardgeneric.png";
import december from "../../assets/images/december.png";

import recordablefree from "../../assets/images/recordablefreemilestones.png";
import genericKCLoopPoster from "../../assets/images/generickcloop.png";

import newSafety from "./assets/safety.mp4";
import newInnovation from "./assets/innovation.mp4";
import newTraining from "./assets/training.mp4";
import newOverview from "./assets/overview2.mp4";
import newESG from "./assets/esg2.mp4";
import newHighlights from "./assets/highlights.mp4";
import newMarketUpdates from "./assets/clientspaces.mp4";
import center from "./assets/center.mp4";

import safetyhelmet from './assets/safety.svg'
import lab from "./assets/lab.jpeg";


import ribbon from "./assets/ribbon.mp4";
import notificationHEIC from "./assets/notification_animation.mov";
import notificationWEBM from "./assets/notification_animation.webm";

import training from './assets/training2.mp4'
import quality from './assets/qualityloop.mp4'
import safety from './assets/safetyloop1.mp4'

import mbe from './assets/mbeflip.mp4'

import pricing from './assets/pricing.mp4'

import sbmCBRE from "./assets/sbm-cbre.svg";
import sbm from "./assets/sbm.svg";
import cbre from "./assets/cbre.svg";
import cbreverticals from './assets/cbreverticals.png'

import MonthlySwitch from "./monthlyswitch";
import Highlights2 from "./highlights2.jsx";

import nFormatter from "../../_archive/functions/nFormatter.jsx";

import { data as cbreData, allCBRE } from "./cbredata.jsx";
import noise from '../../assets/images/noise.png'

import MonthlyCard from "./monthlyCard.jsx";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import auditsAI from "../newMonthly/assets/auditsai.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronRight,
  faAnglesUp,
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";

import ArrowButton from "./button.jsx";

import takedaSample from './assets/takedaspotlight.pdf'

import benchmarking1 from './assets/benchmarking1.png'
import benchmarking2 from './assets/benchmarking2.png'
import benchmarking3 from './assets/benchmarking3.png'

import Benchmarking from "./benchmarking.jsx";

const colors = [
  "#4BA8FF",
  "#734CFF",
  "#A230D2",
  "#DB33B6",
  "#FF7DB1",
  "#FF4332",
  "#FF6E19",
  "#C29200",
  "#E0C942",
  "#02FA8E",
  "#02CCA6",
  "#02CCA6",
  "#48A4FA",
];

function rgbaColors(index, opacity) {
  const color = colors[index];
  const hex = color.slice(1); // Remove the '#' character
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity || "0.7"})`;
}

function scorecardColor(score) {
  if (score > 4) return "bg-emerald-600";
  else if (score > 3) return "bg-yellow-600";
  else if (score > 0) return "bg-rose-600";
  else return "bg-gray-600";
}

function VideoBG({ src, modalOpen, classList }) {
  const video = useRef(null);
  // useEffect(() => {
  //   if (modalOpen) {
  //     video.current.pause();
  //   } else {
  //     video.current.play();
  //   }

  // }, [modalOpen])
  return (
    <video
      ref={video}
      // autoPlay
      onMouseEnter={(el) => {
        el.currentTarget.play();
      }}
      onMouseLeave={(el) => {
        el.currentTarget.pause();
        el.currentTarget.currentTime = 0;
      }}
      playsInline
      muted
      preload="auto"
      onCanPlay={(el) => {
        el.target.classList.remove("opacity-0");
      }}
      // src={sowloop}
      className={`absolute z-0 top-0 left-0 h-full w-full transition-all ggroup-hover:opacity-70 ggroup-hover:scale-110 duration-500 ease-in-out object-cover opacity-0 ${classList}`}
    >
      {src.map((src, i) => {
        return (
          <source
            key={i}
            src={src}
            type={
              src.indexOf("mov") >= 0
                ? "video/quicktime"
                : src.indexOf("webm") >= 0
                ? "video/webm"
                : ""
            }
            codecs={src.indexOf("mov") >= 0 ? "hvc1" : ""}
          />
        );
      })}
    </video>
  );
}
export default function MonthlyHero({ data, selection, showModal, modalOpen }) {
  const [newUI, setNewUI] = useState(true);

  
  let params = new URL(document.location).searchParams;
  let full = params.get('full');


  function handleClick(el) {
    el.preventDefault();
    if (el.currentTarget.parentNode.parentNode.parentNode.style['transition-duration'].length > 3) return
    if (!el.currentTarget.closest('.swiper-slide').classList.contains("swiper-slide-active")) return;
    let story = el.currentTarget.querySelector("#story");
    let headerImage = el.currentTarget.querySelector(".header-image")
    if (!story) return;
    if (story.classList.contains("max-h-0") && (story.style.maxHeight === '' || story.style.maxHeight === '0px') ) {
      let tl = gsap.timeline();

      tl.to(story, {maxHeight: story.scrollHeight, duration: 0.5, ease: 'power2.inOut'})
      .fromTo(headerImage, {maxHeight: headerImage?.scrollHeight + 'px', opacity:1}, {maxHeight: '0px', duration: 0.5, ease: 'power2.inOut', opacity: 0}, 0);
    } else {
      gsap.to(story, {maxHeight: 0, duration: 0.5, ease: 'power2.inOut'})
      gsap.to(headerImage, {maxHeight: headerImage?.scrollHeight + 'px', opacity: 1, ease: 'power2.inOut'});
    }

  }

  function newUIShade() {
    return (
      <div
        className="absolute top-0 left-0 h-full w-full opacity-100 bg-gradient-to-b from-black/90 via-black/70 to-black/10 pointer-events-none"
        style={{ zIndex: 1 }}
      ></div>
    );
  }

  function newUIButton() {
    return (
      <div className="absolute bottom-4 left-6 z-40 rounded-full pointer-events-none">
        <FontAwesomeIcon
          className={
            "text-white group-hover:translate-x-2 transition-transform duration-300 ease-in-out mr-2 text-2xl"
          }
          icon={faChevronCircleRight}
        />
      </div>
    );
  }

  function checkLocal() {
    if (
      document.location.href.indexOf("localhost") > -1 ||
      document.referrer === "https://app.4insite.com/"
    ) {
      return true;
    } else {
      return true; 
    }
  }

  if (selection === "cbre") {
    return (
      <div className="h-screen w-full p-12 grid grid-cols-12 grid-rows-6 gap-6 pt-20 mx-auto">
        <div
          className="monthly-hero-card row-span-2 col-span-3 group cursor-pointer"
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="monthly-swiper  fixed top-0 left-0 bottom-0 right-0 pointer-events-none">
                <div className="relative h-full w-full">
                  <Swiper
                    className="h-screen"
                    spaceBetween={0}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    navigation={true}
                    modules={[Navigation]}
                  >
                    <SwiperSlide className=" h-screen overflow-auto no-scrollbar">
                      <div className="py-16 min-h-full flex items-center w-[80vw]">
                        <div
                          className="bg-neutral-900 rounded-xl p-20 pt-16 pointer-events-auto flex-grow w-full bg-contain bg-no-repeat bg-center"
                          style={{
                            height: "80vh",
                            backgroundImage: `url(${cbreverticals}`,
                          }}
                        >
                          <p className="monthly-tag text-emerald-500">
                            SBM | CBRE Verticals
                          </p>
                          <h4 className="monthly-title mb-6">
                            A competitive advantage
                            <br />
                            across all verticals.
                          </h4>
                          <p className="text-5xl font-bold text-white flex flex-row items-end mb-6">
                            {(checkLocal() && "$18.5M ") || "$XXM "}
                            <span className="ml-2 flex-grow-0 block text-base px-3 py-1 border-2 border-yellow-500 text-yellow-500 rounded-lg">
                              <FontAwesomeIcon
                                className={"mr-1"}
                                icon={faAnglesUp}
                              />
                              2023 Growth: 8.6%
                            </span>
                            <span className="ml-2 flex-grow-0 block text-base px-3 py-1 border-emerald-500 border-2 text-emerald-500 rounded-lg">
                              <FontAwesomeIcon
                                className={"mr-1"}
                                icon={faAnglesUp}
                              />
                              2024 Growth Target: 25%
                            </span>
                          </p>
                          <div className=" h-full"></div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className=" h-screen overflow-auto no-scrollbar ">
                      <div className="py-16 min-h-full flex items-center ">
                        <div className="bg-neutral-900 rounded-xl p-20 w-full pointer-events-auto max-w-[60vw]">
                          <p className="monthly-tag text-orange-500">
                            Partnership
                          </p>
                          <h4 className="monthly-title mb-6">
                          Our Clients Have Choices.<br/>They Continue to Choose Us. 
                          </h4>
                          <p className="text-xl font-normal text-neutral-600">
                            Working together and building on our joint success,
                            SBM and CBRE were able to secure non-competetive
                            contract extensions across 8 key account portfolios
                            while also retaining 100% of the accounts we support
                            together.
                          </p>
                          <div className="flex flex-row flex-wrap items-center justify-evenly gap-6 py-4 mb-12 mt-16">
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Microsoft.svg"
                              alt=""
                              className="h-20 w-1/5"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Biogen.svg"
                              alt=""
                              className="h-20 w-1/5"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Ford_full_color.svg"
                              alt=""
                              className="h-20 w-1/5"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Nvidia_w.svg"
                              alt=""
                              className="h-20 w-1/5  "
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Juniper.svg"
                              alt=""
                              className="h-18 w-1/5 invert brightness-0"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Novartis_w.svg"
                              alt=""
                              className="h-20 w-1/5"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Cigna.svg"
                              alt=""
                              className="h-24 w-1/5"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Pratt.svg"
                              alt=""
                              className="h-20 w-1/5 invert"
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    {/* <SwiperSlide className=" h-screen overflow-auto no-scrollbar ">
                      <div className="py-16 min-h-full flex items-center ">
                        <div className="bg-neutral-900 rounded-xl p-20 pt-16 pointer-events-auto w-screen max-w-[60vw]">
                          <p className="monthly-tag text-orange-500">SBM Vertical Breakdown</p>
                          <div className="grid grid-cols-3 gap-6 mt-6">
                            <div
                              className="p-8 pt-6 rounded-xl bg-neutral-800 col-span-3"
                              style={{  }}
                            >
                              <div className="flex flex-row justify-between items-baseline">

                              </div>
                              <CustomChart
                                title=""
                                type="packedbubble"
                                height="400px"
                                spacing={[10,0,10,0]}
                                legend={{
                                  enabled: true,
                                  align: "center",
                                  verticalAlign: "bottom",
                                  layout: "horizontal",
                                  itemMarginBottom: 10,
                                  itemStyle: {
                                    color: "white",
                                    fontSize: "16px !important",
                                  },
                                }}
                                tooltip={{enabled: true,
                                format: '<b>{series.name}</b>: <b>{point.value}%</b><br/>',
                                backgroundColor: "rgb(12, 15, 18)",
                                outside: true,
                                shadow: false,
                                style: { color: "#ffffff" , fontSize: '16px'}
                              }}
                                plotOptions={{
                                  packedbubble: {
                                    minSize: "30%",
                                    maxSize: "200%",
                                    draggable: false,  

                                    //zMin: 0,
                                    //zMax: 1000,
                                    layoutAlgorithm: {
                                      splitSeries: false,
                                      gravitationalConstant: 0.02,
                                      seriesInteraction: true,
                                      dragBetweenSeries: false,
                                    },
                                    dataLabels: {
                                      enabled: true,
                                      format: "{series.name}: <span class=font-normal>{point.value}</span>%",
                                      align: "center",
                                      filter: {
                                        property: "y",
                                        operator: ">=",
                                        value: 8
                                      },
                                      style: {
                                        color: "white",
                                          fontSize: "14px",
                                        textOutline: "none",
                                        fontWeight: "bold"
                                      }
                                    }
                                  }
                                }}
                                series={[
                                  {
                                    name: "Technology",
                                    data: [20.5],
                                  },
                                  {
                                    name: "Life Sciences",
                                    data: [26.2],
                                  },
                                  {
                                    name: "Automotive",
                                    data: [14.4],
                                  },
                                  {
                                    name: "Finance",
                                    data: [9.7],
                                  },
                                  {
                                    name: "Manufacturing",
                                    data: [13.9],
                                  },
                                  {
                                    name: "Aviation",
                                    data: [6.1],
                                  },
                                  {
                                    name: "Distribution",
                                    data: [5.3],
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                    {/* <SwiperSlide className="rounded-xl h-screen overflow-auto max-w-screen-md no-scrollbar pointer-events-auto">
                      <div className="py-16 min-h-fullflex items-center">
                        <div className="bg-neutral-800 rounded-xl p-20 w-full">
                          <h4 className="text-5xl font-bold text-white mb-12">
                            Partnering Together <br />
                            Winning Together
                          </h4>
                          <p className="text-xl font-bold text-white">
                            Notable RFP Wins
                          </p>
                          <div className="grid grid-cols-4 place-items-center gap-6 py-4 mb-12">
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Lilly.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Microsoft.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                          </div>
                          <p className="text-xl font-bold text-white">
                            Non-Competitive Renewals
                          </p>
                          <div className="grid grid-cols-4 place-items-center gap-6 py-4 mb-12">
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Biogen.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Ford.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Nvidia.svg"
                              alt=""
                              className="h-12 max-w-[8em] "
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Juniper.svg"
                              alt=""
                              className="h-12 max-w-[8em] invert brightness-0"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Novartis.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Cigna.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Pratt.svg"
                              alt=""
                              className="h-12 max-w-[8em]"
                            />
                          </div>
                          <p className="text-xl font-bold text-white">
                            Upcoming RFPs
                          </p>
                          <div className="grid grid-cols-4 place-items-center gap-6 py-4 mb-12">
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/deutsche-bank.svg"
                              alt=""
                              className="h-12 max-w-[8em] invert brightness-0"
                            />
                            <img
                              src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Abbvie.svg"
                              alt=""
                              className="h-12 max-w-[8em] invert brightness-0"
                            />
                          </div>
                          <hr className="mt-20 mb-12 border-t border-neutral-700" />
                          <div className="flex flex-row gap-6">
                            <div className="w-1/3">
                              <p className="text-6xl font-normal text-white">
                                XX
                                <span className="text-4xl text-neutral-600 font-normal uppercase">
                                  M
                                </span>
                              </p>
                              <p className="text-neutral-600 text-sm font-normal">
                                potential dollars in the pipeline.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            )
          }
        >
          {newUIButton()}
          <VideoBG src={[sowloop]} modalOpen={modalOpen} />
          {/* <video
            autoPlay
            loop
            playsInline
            muted
            preload="auto"
            onCanPlay={(el) => {
              el.target.classList.remove("opacity-0");
            }}
            src={sowloop}
            className="absolute z-0 top-0 left-0 h-full w-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out object-cover opacity-0"
          ></video> */}
          <div
            className={`relative z-10 h-full w-full bg-gradient-to-b pointer-events-none ${
              newUI
                ? "from-black/80 via-black/40 to-black/20"
                : "from-neutral-800/80 via-neutral-800/10 to-neutral-800/0"
            } p-6 `}
          >
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Relationship Updates
            </p>
          </div>
        </div>

        <div
          className="col-span-5 row-span-2 monthly-hero-card group cursor-pointer"
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
                <div className="h-full w-full monthly-swiper no-w-auto mx-auto">
                  <Swiper
                    className="h-screen "
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    navigation={true}
                    modules={[Navigation]}
                  >
                    <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                      <div className="py-16 min-h-full flex items-center justify-center">
                        <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto">
                        <video
                            autoPlay
                            loop
                            playsInline
                            muted
                            poster={safety}
                            onCanPlay={(el) => {
                              el.target.classList.remove("opacity-0");
                            }}
                            src={safety}
                            className="w-full opacity-0 aspect-video duration-500 ease-in-out relative z-0"
                          ></video>
                          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center gap-8 justify-evenly z-10">
                            <img src={sbm} alt="" />
                            <p style={{fontFamily: 'Gotham Black, Montserrat', fontWeight: 900, lineHeight: 1}} className="font-gotham text-white font-ultra text-7xl text-center">Over <span className="highlight2 orange">3x Safer</span> than the<br/> Industry Average</p>
                            <div className="flex flex-row items-center justify-center gap-12 py-12 w-[60%]">
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">93<span className="opacity-70 text-3xl">%</span></p>
                              <p className="text-lg font-bold text-center text-white">of Sites Recordable-Free</p>
                              </div>
                              <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">13<span className="opacity-70 text-3xl">K+</span></p>
                              <p className="text-lg font-bold text-center text-white">Good Catches</p>
                              </div>
                              {/* <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">50+</p>
                              <p className="text-lg font-bold text-center text-white">Skills</p>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        </div>
                        </SwiperSlide>
                    {/* <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                      <div className="py-16 min-h-full flex items-center">
                        <div className="relative w-screen h- p-12 rounded-xl bg-neutral-900 pointer-events-auto flex flex-row items-center justify-center">
                          <div className="w-full relative z-20">
                            <p className="monthly-tag text-orange-500">
                              SBM's commitment to safety is unmatched
                            </p>
                            <p className="monthly-title mb-6">
                              Choosing any other provider is just too risky.
                            </p>
                            <p className="text-3xl text-neutral-100">
                              With safety rates 3x better than the industy
                              average, SBM continues to raise the bar for
                              Safety.
                            </p>
                          </div>
                          <div className="w-full z-20">
                            <video
                              className="w-full aspect-video transition-opacity duration-500 opacity-0 !rounded-xl"
                              onCanPlay={(el) =>
                                el.currentTarget.classList.remove("opacity-0")
                              }
                              autoPlay
                              playsInline
                              muted
                              loop
                              src="https://player.vimeo.com/progressive_redirect/playback/740443151/rendition/720p/file.mp4?loc=external&log_user=0&signature=2f2bf70ffeadc6863b3db523a3429e70aff81bbe54d1387f0802126eeee486d4"
                            ></video>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                    <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-2xl">
                      <div className="py-16 min-h-full flex items-center">
                        <div className="w-[80vw] rounded-xl bg-neutral-900 pointer-events-auto">
                          <MonthlySwitch
                            data={{
                              param: "goodcatches",
                              headline:
                                "Preventing incidents before they happen",
                              story:
                                "We proactively identified 13,000 good catches, protecting CBRE clients’ facilities and the health and safety of everyone in them. ",
                              data: [
                                {
                                  name: "Good Catches",
                                  data: [
                                    { name: "Spills/Leaks", y: 2877 },
                                    { name: "Slips/Trips", y: 1507 },
                                    { name: "Maintenance", y: 3320 },
                                    { name: "Behavior", y: 630 },
                                    { name: "Equipment", y: 1473 },
                                    { name: "Environmental", y: 1286 },
                                    { name: "Improper Storage", y: 1942 },
                                    { name: "Chemical", y: 111 },
                                    { name: "Lifting", y: 44 },
                                  ],
                                },
                              ],
                            }}
                          />
                        </div>
                        {/* <div className="relative bg-neutral-800 flex items-end justify-start pointer-events-auto">
                      <video
                        data-autoplay="true"
                        preload="auto"
                        loop
                        mute
                        playsInline
                        src="https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/msp2.mp4"
                        className="absolute z-10 top-0 left-0 h-full w-full object-cover"
                      ></video>
                      <div
                        className="relative flex flex-col p-8 items-center justify-evenly z-30 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10"
                        style={{ transform: "translateZ(-1px)" }}
                      >
                        <p className="text-xl font-extrabold text-center">Catching safety issues before they become incidents.</p>
                        <div>
                        <p className="text-9xl font-black text-white text-center">
                          1072
                        </p>
                        <p className="text-neutral-300 text-lg font-normal text-center">
                          Good Catches Reported
                        </p>
                        </div>
                      </div>
                    </div> */}
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar">
                  <div className="py-16 min-h-full flex items-center">
                    <div className="relative bg-neutral-800 w-full flex items-end justify-start pointer-events-auto">
                      <video
                        data-autoplay="true"
                        preload="auto"
                        loop
                        mute
                        playsInline
                        src="https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/msp2.mp4"
                        className="absolute z-10 top-0 left-0 h-full w-full object-cover"
                      ></video>
                      <div
                        className="relative flex flex-col p-8 items-center justify-evenly z-30 h-full w-full bg-gradient-to-t from-neutral-950 to-transparent via-neutral-950/10"
                        style={{ transform: "translateZ(-1px)" }}
                      >
                        <p className="text-xl font-extrabold text-center">Making sure our team and yours arrives home safe</p>
                        <div>
                        <p className="text-9xl font-black text-white text-center">
                          0.58
                        </p>
                        <p className="text-neutral-300 text-lg font-normal text-center">
                          TRIR
                        </p>
                        </div>
                      </div>
                    </div>
                    </div>
                  </SwiperSlide> */}

                    <SwiperSlide className="h-screen overflow-hidden no-scrollbar w-screen max-w-screen-md">
                      <div className="py-16 min-h-full w-full flex items-center overflow-auto no-scrollbar ">
                        <div className="relative w-full bg-neutral-800 rounded-xl p-16 pointer-events-auto">
                          <p className="monthly-tag text-emerald-500">
                            Highlights
                          </p>
                          <p className="monthly-title text-white">
                            Safety Leaders
                          </p>
                          <p className="text-lg font-normal mt-6 text-white">
                            While, 93% of our sites were recordable free in
                            2023, here are the top 5 sites with the lowest TRIR.
                          </p>
                          <div className="grid grid-cols-3 gap-4 w-full mt-6 items-center">
                            <p className="text-sm text-neutral-600 font-bold">
                              Site
                            </p>
                            <p className="text-sm text-neutral-600 font-bold text-center">
                              2023 Hours Worked
                            </p>
                            {/* <p className="text-sm text-neutral-600 font-bold text-center">
                             2023 Recordables
                            </p> */}
                            <p className="text-sm text-neutral-600 font-bold text-right">
                             Days Recordable free
                            </p>
                            <div className="col-span-3 border-b border-neutral-600"></div>

                            <div className="flex flex-row items-center gap-2">
                              <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                                <img
                                  src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Takeda.svg"
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="text-lg font-extrabold leading-none text-white">
                                  Takeda
                                </p>
                              </div>
                            </div>
                            <p className="text-lg text-white font-normal text-center">
                              244k
                            </p>
                           {/* <p className="text-lg text-white font-normal text-center">
                              0
                            </p> */}
                            <div className="flex justify-end">
                              <p className="inline-block text-lg font-black py-1 w-16 text-center bg-emerald-500 rounded text-white">
                                509
                              </p>
                            </div>
                           
                            <div className="flex flex-row items-center gap-2">
                              <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                                <img
                                  src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Biogen.svg"
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="text-lg font-extrabold leading-none text-white">
                                  Biogen
                                </p>
                              </div>
                            </div>
                            <p className="text-lg text-white font-normal text-center">
                              188K
                            </p>
                           {/* <p className="text-lg text-white font-normal text-center">
                              0
                            </p> */}
                            <div className="flex justify-end">
                              <p className="inline-block text-lg font-black py-1 w-16 text-center bg-emerald-500 rounded text-white">
                                893
                              </p>
                            </div>

                            <div className="flex flex-row items-center gap-2">
                              <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                                <img
                                  src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Novartis.svg"
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="text-lg font-extrabold leading-none text-white">
                                  Novartis
                                </p>
                              </div>
                            </div>
                            <p className="text-lg text-white font-normal text-center">
                              163K
                            </p>
                           {/* <p className="text-lg text-white font-normal text-center">
                              0
                            </p> */}
                            <div className="flex justify-end">
                              <p className="inline-block text-lg font-black py-1 w-16 text-center bg-emerald-500 rounded text-white">
                                930
                              </p>
                            </div>

                            <div className="flex flex-row items-center gap-2">
                              <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                                <img
                                  src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Mckesson.svg"
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="text-lg font-extrabold leading-none text-white">
                                  Mckesson
                                </p>
                              </div>
                            </div>
                            <p className="text-lg text-white font-normal text-center">
                              134k
                            </p>
                           {/* <p className="text-lg text-white font-normal text-center">
                              0
                            </p> */}
                            <div className="flex justify-end">
                              <p className="inline-block text-lg font-black py-1 w-16 text-center bg-emerald-500 rounded text-white">
                                426
                              </p>
                            </div>

                            <div className="flex flex-row items-center gap-2">
                              <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                                <img
                                  src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Uber.svg"
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="text-lg font-extrabold leading-none text-white">
                                  Uber
                                </p>
                              </div>
                            </div>
                            <p className="text-lg text-white font-normal text-center">
                              103K
                            </p>
                           {/* <p className="text-lg text-white font-normal text-center">
                              0
                            </p> */}
                            <div className="flex justify-end">
                              <p className="inline-block text-lg font-black py-1 w-16 text-center bg-emerald-500 rounded text-white">
                                528
                              </p>
                            </div>
                           
                           
                           
                           
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    {/* <SwiperSlide className="h-screen overflow-hidden no-scrollbar w-screen max-w-screen-md">
                    <div className="py-16 min-h-full w-full flex items-center overflow-auto no-scrollbar ">
                      <div className="relative bg-neutral-800 rounded-xl p-16 pointer-events-auto w-full">
                        <p className="monthly-tag text-rose-500">
                          Opportunities
                        </p>
                        <p className="monthly-title text-white">
                          Lowest Performing Accounts
                        </p>
                        <div className="grid grid-cols-3 gap-4 w-full mt-6 items-center">
                          <p className="text-sm text-neutral-600 font-bold">
                            Site
                          </p>
                          <p className="text-sm text-neutral-600 font-bold text-center">
                            Recordables
                          </p>
                          <p className="text-sm text-neutral-600 font-bold text-right">
                            2023 TRIR
                          </p>
                          <div className="col-span-3 border-b border-neutral-600"></div>

                          <div className="flex flex-row items-center gap-2">
                            <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                              <img
                                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Pratt.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="text-lg font-extrabold leading-none text-white">
                                Pratt & Whitney
                              </p>
                              <p className=" text-neutral-500 font-normal">
                                Asheville
                              </p>
                            </div>
                          </div>
                          <p className="text-lg text-white font-normal text-center">
                            1
                          </p>
                          <div className="flex justify-end">
                            <p className="inline-block text-lg font-black py-1 w-16 text-center bg-rose-500 text-white rounded">
                              11.39
                            </p>
                          </div>

                          <div className="flex flex-row items-center gap-2">
                            <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                              <img
                                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Microsoft.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="text-lg font-extrabold leading-none text-white">
                                Microsoft
                              </p>
                              <p className=" text-neutral-500 font-normal">
                                Des Moines, IA
                              </p>
                            </div>
                          </div>
                          <p className="text-lg text-white font-normal text-center">
                            2
                          </p>
                          <div className="flex justify-end">
                            <p className="inline-block text-lg font-black py-1 w-16 text-center bg-rose-500 text-white rounded">
                              5.08
                            </p>
                          </div>

                          <div className="flex flex-row items-center gap-2">
                            <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                              <img
                                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="text-lg font-extrabold leading-none text-white">
                                Meta
                              </p>
                              <p className=" text-neutral-500 font-normal">
                                Menlo Park
                              </p>
                            </div>
                          </div>
                          <p className="text-lg text-white font-normal text-center">
                            2
                          </p>
                          <div className="flex justify-end">
                            <p className="inline-block text-lg font-black py-1 w-16 text-center bg-rose-500 text-white rounded">
                              4.34
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-2">
                            <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                              <img
                                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Meta.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="text-lg font-extrabold leading-none text-white">
                                Meta
                              </p>
                              <p className=" text-neutral-500 font-normal">
                                Classic
                              </p>
                            </div>
                          </div>
                          <p className="text-lg text-white font-normal text-center">
                            2
                          </p>
                          <div className="flex justify-end">
                            <p className="inline-block text-lg font-black py-1 w-16 text-center bg-rose-500 text-white rounded">
                              2.38
                            </p>
                          </div>

                          <div className="flex flex-row items-center gap-2">
                            <div className="h-12 w-12 rounded bg-white p-1 grid place-items-center">
                              <img
                                src="https://sbmupdates.s3.amazonaws.com/clients_isolated/Lilly.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="text-lg font-extrabold leading-none text-white">
                                Eli Lilly
                              </p>
                              <p className=" text-neutral-500 font-normal">
                                Indianapolis
                              </p>
                            </div>
                          </div>
                          <p className="text-lg text-white font-normal text-center">
                            5
                          </p>
                          <div className="flex justify-end">
                            <p className="inline-block text-lg font-black py-1 w-16 text-center bg-rose-500 text-white rounded">
                              1.94
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            )
          }
        >
          <VideoBG
            src={[
              newUI
                ? newSafety
                : "https://player.vimeo.com/progressive_redirect/playback/541136931/rendition/720p/file.mp4?loc=external&log_user=0&signature=8d70912bcdd44ff85e8e1c8eda503eb81669c006d97afe63efa2723345cfc229",
            ]}
            modalOpen={modalOpen}
          />
          <div
            className={`relative z-10 h-full w-full bg-gradient-to-b pointer-events-none ${
              newUI
                ? "from-black/80 via-black/40 to-black/20"
                : "from-neutral-800/90 via-neutral-800/10 to-neutral-800/0"
            } p-6 `}
          >
            {newUIButton()}

            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Safety
            </p>
          </div>
        </div>

        <div
          onClick={(el) => showModal(el, null, <Highlights2 />)}
          className="monthly-hero-card row-span-4 col-span-4 cursor-pointer bg-neutral-800 flex flex-col group point"
        >
          {newUIButton()}
          <div
            className={`relative z-10 bg-gradient-to-b pointer-events-none ${
              newUI
                ? "h-full w-full from-black/80 via-black/40 to-black/20"
                : ""
            } p-6 `}
          >
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Highlights & Opportunities
            </p>
          </div>
          <div
            className={
              newUI
                ? "absolute z-0 top-0 left-0 h-full w-full transition-all ggroup-hover:opacity-70 ggroup-hover:scale-110 duration-500 ease-in-out object-cover"
                : "h-full w-full flex-shrink min-h-0 flex items-center justify-center"
            }
          >
            {newUI ? (
              <VideoBG src={[newHighlights]} modalOpen={showModal} />
            ) : (
              <video
                autoPlay
                playsInline
                muted
                loop
                preload="auto"
                onCanPlay={(el) => {
                  el.target.classList.remove("opacity-0");
                }}
                className={
                  newUI
                    ? "h-full w-full object-cover opacity-0"
                    : `block object-fit min-h-0 flex-shrink max-h-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out opacity-0 pointer-events-none`
                }
              >
                <source
                  src={highlightsmov}
                  type="video/quicktime"
                  codecs="hvc1"
                />
                <source src={highlightswebm} type="video/webm" />
              </video>
            )}
          </div>
        </div>
        <div
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="fixed top-0 left-0 right-0 bottom-0 monthly-swiper no-w-auto pointer-events-none">
                <Swiper
                  className="h-screen"
                  spaceBetween={0}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  navigation={true}
                  modules={[Navigation]}
                >
                  <SwiperSlide className=" h-screen overflow-auto no-scrollbar">
                    <div className="p-16 px-32 min-h-full flex items-center overflow-auto no-scrollbar ">
                      <div className="w-full min-h-full p-12 bg-neutral-900 rounded-2xl pointer-events-auto">
                        <p className="monthly-tag text-blue-400 text-center">
                          Our Joint Clients
                        </p>
                        <p className="monthly-title text-center mb-6">
                          World Class Results 
                          <br />
                          For World Class Clients
                        </p>
                        <div className="flex flex-row mb-12 justify-center items-center gap-12">
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              55
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Clients
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              208
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Sites
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              34
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              States
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              3
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Countries
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-row justify-center flex-wrap gap-8">
                          {allCBRE.logos
                            .sort((a, b) => {
                              return b.sqft - a.sqft;
                            })
                            .map((logo) => (
                              <div className="rounded-2xl flex flex-col items-center overflow-hidden bg-white">
                                <div className=" flex flex-row p-6 pt-4 pb-2 gap-4">
                                  <img
                                    className="block max-h-[4rem] min-h-[2rem] w-52 object-contain"
                                    alt={logo.client}
                                    src={`https://sbmupdates.s3.amazonaws.com/clients_isolated/${logo.logo}.svg`}
                                  />
                                  <div
                                    className={`p-2 pb-1 rounded-xl h-16 flex flex-col items-center justify-center ${scorecardColor(
                                      logo.scorecard
                                    )}`}
                                  >
                                    <p className="text-xs font-bold text-white text-center uppercase leading-0 mb-0">
                                      Avg Score
                                    </p>
                                    <p className="text-lg text-white font-black text-center">
                                      {logo.scorecard}<span className="text-base opacity-70">/5</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-evenly w-full px-2 pb-2">
                                  <div className="w-1/3 p-2 pb-1">
                                    <p className="text-xs font-bold text-gray-400 text-center uppercase leading-0 mb-0">
                                      Sites
                                    </p>
                                    <p className="text-lg text-gray-400 font-black text-center">
                                      {logo.sites}
                                    </p>
                                  </div>
                                  <div className="w-1/3 p-2 pb-1">
                                    <p className="text-xs font-bold text-gray-400 text-center uppercase leading-0 mb-0">
                                      SqFt
                                    </p>
                                    <p className="text-lg text-gray-400 font-black text-center">
                                      {nFormatter(logo.sqft, 1)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" h-screen overflow-auto no-scrollbar px-32">
                    <div className="py-16 min-h-full flex items-center overflow-auto no-scrollbar ">
                      <div className="w-full min-h-full py-12 bg-neutral-900/70 rounded-2xl backdrop-blur-xl flex items-center justify-center pointer-events-auto">
                        <MonthlySwitch
                          data={{
                            param: "top10",
                            data: cbreData,
                            title: {
                              tag: "Year-End Performance Overview",
                              headline: checkLocal()
                                ? "SBM’s top ten CBRE portfolios account for 71.5% of your $18.5M spend."
                                : "SBM's top ten CBRE portfolios account for 71.5% of your spend.",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            )
          }
          className="monthly-hero-card relative group row-span-4 col-span-4 cursor-pointer bg-neutral-800"
        >
          {newUI && newUIShade()}
          {newUIButton()}
          {newUI && (
            <VideoBG src={[newOverview]} modalOpen={modalOpen} />
            // <video
            //   playsInline
            //   autoPlay
            //   muted
            //   loop
            //   className="absolute z-0 h-full w-full object-cover object-center group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out"
            //   src={newOverview}
            // ></video>
          )}
          <div className="relative z-20 w-full p-6 pointer-events-none">
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Overview
            </p>
          </div>
          <div
            className={
              newUI
                ? "hidden"
                : "h-full w-full px-6 flex items-start justify-center relative"
            }
          >
            <img
              className="relative z-10 w-full group-hover:scale-105 group-hovver:opacity-80 transition-all duration-500 ease-in-out shadow-xl shadow-black/40"
              src={darkscorecardgeneric}
              alt=""
            />
            <div className="absolute z-20 top-1/2 left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-neutral-800 pointer-events-none"></div>
          </div>
        </div>
        <div
          className="monthly-hero-card relative row-span-2 col-span-4 bg-neutral-800 flex flex-col items-center justify-center bg-cover"
          style={{ backgroundImage: newUI ? "none" : `url(${december})` }}
        >
          {newUI && (
            <video
              playsInline
              autoPlay
              muted
              loop
              className="absolute z-0 h-full w-full object-cover"
              style={{
                filter: "hue-rotate(308deg) brightness(0.6) contrast(1.1)",
              }}
              src={center}
            ></video>
          )}
          <img src={cbre} className="block relkative z-20 h-10 mb-4" alt="" />
          <p className="relative z-20 text-5xl font-black text-white text-lato text-center">
            2023 Year-In-Review
          </p>
          <img src={sbm} className="block relkative z-20 h-8 mt-4" alt="" />
        </div>
        {/* <div
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="bg-neutral-800 rounded-xl max-w-screen-md overflow-auto pt-6">
                <div className="px-20 py-12">
                  <video
                    autoPlay
                    loop
                    playsInline
                    muted
                    poster={genericKCLoopPoster}
                    onCanPlay={(el) => {
                      el.target.classList.remove("opacity-0");
                    }}
                    className="w-full object-left object-contain opacity-0 h-48 duration-500 ease-in-out mb-8 block"
                  >
                    <source
                      src={ecovadismov}
                      type="video/quicktime"
                      codecs="hvc1"
                    />
                    <source src={ecovadisebm} type="video/webm" />
                  </video>
                  <h4 className="text-5xl font-bold text-white mb-8 mt-12">
                    Celebrating our <br />
                    forward progress.
                  </h4>
                  <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    “ESG” may be a trending buzzword, but we’ve long been
                    heralded for practicing its core tenets. We do this not for
                    compliance but because we believe conducting our business in
                    a socially responsible and sustainable manner is simply the
                    right thing to do.
                  </p>
                  <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    Our industry-leading Silver rating through EcoVadis
                    recognizes our dedication to making marked advancements in
                    ESG processes across our organization.
                  </p>
                  <hr className="mt-12 mb-12 border-t border-neutral-700" />
                  <div className="flex flex-row gap-6">
                    <div className="w-1/3">
                      <p className="text-6xl font-normal text-white">
                        82
                        <span className="text-4xl text-neutral-600 font-normal uppercase">
                          nd
                        </span>
                      </p>
                      <p className="text-neutral-600 text-sm font-normal">
                        percentile of all companies participating in Ecovadis,
                        earning Silver in 2023.
                      </p>
                    </div>
                    <div className="w-1/3">
                      <p className="text-6xl font-normal text-white">
                        63
                        <span className="text-4xl text-neutral-600 font-normal uppercase">
                          /100
                        </span>
                      </p>
                      <p className="text-neutral-600 text-sm font-normal">
                        overall score with highest performance in Environment,
                        Labor, and Human Rights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          className={
            "monthly-hero-card row-span-2 cursor-pointer bg-neutral-800 p-2 pt-6 group flex flex-col items-center justify-start"
          }
        >
          {newUIButton()}
          <p
            className={
              newUI
                ? "relative z-10 text-4xl font-black text-lato text-left text-white w-full px-6"
                : "text-xl font-black text-lato text-center text-white"
            }
          >
            ESG Updates
          </p>
          {newUI && newUIShade()}
          <video
            autoPlay
            playsInline
            muted
            loop
            preload="auto"
            onCanPlay={(el) => {
              el.target.classList.remove("opacity-0");
            }}
            className={
              newUI
                ? "absolute z-0 top-0 left-0 h-full w-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out object-cover opacity-0"
                : "object-fit flex-shrink min-h-0 transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out opacity-0"
            }
          >
            <source
              src={newUI ? newESG : ecovadismov}
              type="video/quicktime"
              codecs="hvc1"
            />
            <source src={newUI ? newESG : ecovadisebm} type="video/webm" />
          </video>
        </div> */}

        <div
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
                <div className="h-full w-full monthly-swiper no-w-auto mx-auto">
                  <Swiper
                    className="h-screen "
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    navigation={true}
                    modules={[Navigation]}
                  >
                     <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                      <div className="py-16 min-h-full flex items-center justify-center">
                        <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto">
                        <video
                            autoPlay
                            loop
                            playsInline
                            muted
                            poster={genericKCLoopPoster}
                            onCanPlay={(el) => {
                              el.target.classList.remove("opacity-0");
                            }}
                            src={quality}
                            className="w-full opacity-0 aspect-video duration-500 ease-in-out relative z-0"
                          ></video>
                          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col w-full items-center gap-8 justify-around z-10">
                            <img src={sbm} alt="" />
                            <p style={{fontFamily: 'Gotham Black, Montserrat', fontWeight: 900, lineHeight: 1}} classNAme="font-gotham text-white font-ultra text-7xl text-center">Leading Results through <br/><span className="highlight2 orange">Unrivaled Innovation</span></p>
                            <div className="flex flex-row items-center justify-center gap-12 py-12 w-[60%]">
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">4.35<span className=" opacity-70 text-3xl">/5</span></p>
                              <p className="text-lg font-bold text-center text-white">Avg. Audit Score</p>
                              </div>
                              <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">4.48<span className=" opacity-70 text-3xl">/5</span></p>
                              <p className="text-lg font-bold text-center text-white">Avg. CSAT</p>
                              </div>
                              {/*                               <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>

                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">50+</p>
                              <p className="text-lg font-bold text-center text-white">Skills</p>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                          <div className="min-h-full flex items-center justify-center">
                            <div className="relative h-full bg-neutral-900 rounded-xl overflow-auto">
                            <div className="h-full w-full rounded-xl overflow-hidden">
                  <div className="relative h-full w-full grid grid-cols-5 bg-neutral-800 pointer-events-auto">
                    <video
                      autoPlay
                      playsInline
                      muted
                      loop
                      src={ribbon}
                      className="absolute top-0 left-0 h-full w-full max-h-none max-w-none object-cover z-0 rotate-180 opacity-20"
                    ></video>
                    <div
                      className="absolute top-0 left-0 h-full w-full z-0 bg-blend-multiply"
                      style={{
                        background: `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`,
                      }}
                    ></div>
                    <div className="h-full col-span-3 flex flex-col justify-center relative z-10 p-24">
                      <p className="monthly-tag text-blue-500 flex items-center">
                        Quality Control{" "}
                        <span className="ml-4 inline-block px-2 py-1 bg-blue-500/10 border-blue-500 text-sm border rounded">
                          Beta
                        </span>
                      </p>
                      <p className="monthly-title">
                        Expediting complaint management through customized
                        exception reporting
                      </p>
                    </div>

                    <div className="relative col-span-2 z-10 h-full overflow-hidden flex items-center justify-center">
                      <video
                        muted
                        loop
                        autoPlay
                        playsInline
                        className="flex-shrink min-h-0"
                        style={{
                          transform: "translateY(7%) scale(1.2)",
                          transformOrigin: "top",
                        }}
                      >
                        <source
                          src={notificationHEIC}
                          type="video/quicktime"
                          codecs="hvc1"
                        />
                        <source src={notificationWEBM} type="video/webm" />
                      </video>
                    </div>
                  </div>
                </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>

              // <div class="fixed top-0 left-0 right-0 bottom-0 pointer-events-none p-16">
                // <div className="h-full w-full rounded-xl overflow-hidden">
                //   <div className="relative h-full w-full grid grid-cols-5 bg-neutral-800 pointer-events-auto">
                //     <video
                //       autoPlay
                //       playsInline
                //       muted
                //       loop
                //       src={ribbon}
                //       className="absolute top-0 left-0 h-full w-full max-h-none max-w-none object-cover z-0 rotate-180 opacity-20"
                //     ></video>
                //     <div
                //       className="absolute top-0 left-0 h-full w-full z-0 bg-blend-multiply"
                //       style={{
                //         background: `url(${noise}), linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.5), rgba(0,0,0,0.2))`,
                //       }}
                //     ></div>
                //     <div className="h-full col-span-3 flex flex-col justify-center relative z-10 p-24">
                //       <p className="monthly-tag text-blue-500 flex items-center">
                //         Quality Control{" "}
                //         <span className="ml-4 inline-block px-2 py-1 bg-blue-500/10 border-blue-500 text-sm border rounded">
                //           Beta
                //         </span>
                //       </p>
                //       <p className="monthly-title">
                //         Expediting complaint management through customized
                //         exception reporting
                //       </p>
                //     </div>

                //     <div className="relative col-span-2 z-10 h-full overflow-hidden flex items-center justify-center">
                //       <video
                //         muted
                //         loop
                //         autoPlay
                //         playsInline
                //         className="flex-shrink min-h-0"
                //         style={{
                //           transform: "translateY(7%) scale(1.2)",
                //           transformOrigin: "top",
                //         }}
                //       >
                //         <source
                //           src={notificationHEIC}
                //           type="video/quicktime"
                //           codecs="hvc1"
                //         />
                //         <source src={notificationWEBM} type="video/webm" />
                //       </video>
                //     </div>
                //   </div>
                // </div>
              // </div>
            )
          }
          className="monthly-hero-card row-span-2 col-span-5 group cursor-pointer"
        >
          {newUIButton()}
          <VideoBG
            src={[newInnovation]}
            classList={"object-top"}
            modalOpen={modalOpen}
          />
          {/* <video
            autoPlay
            loop
            playsInline
            muted
            preload="auto"
            onCanPlay={(el) => {
              el.target.classList.remove("opacity-0");
            }}
            src={newInnovation}
            className="absolute z-0 top-0 left-0 h-full w-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out object-cover opacity-0"
          ></video> */}
          <div
            className={`relative z-10 h-full w-full bg-gradient-to-b pointer-events-none ${
              newUI
                ? "from-black/80 via-black/40 to-black/20"
                : "from-neutral-800/80 via-neutral-800/10 to-neutral-800/0"
            } p-6 `}
          >
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Quality & Innovations
            </p>
          </div>
        </div>
        <div
          className="monthly-hero-card row-span-2 col-span-3 group cursor-pointer"
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
                <div className="h-full w-full monthly-swiper no-w-auto mx-auto">
                  <Swiper
                    className="h-screen "
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    navigation={true}
                    modules={[Navigation]}
                  >
                     <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                      <div className="py-16 min-h-full flex items-center justify-center">
                        <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto">
                        <video
                            autoPlay
                            loop
                            playsInline
                            muted
                            poster={genericKCLoopPoster}
                            onCanPlay={(el) => {
                              el.target.classList.remove("opacity-0");
                            }}
                            src={training}
                            className="w-full opacity-0 aspect-video duration-500 ease-in-out relative z-0"
                          ></video>
                          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center gap-8 justify-around z-10">
                            <img src={sbm} alt="" />
                            <p style={{fontFamily: 'Gotham Black, Montserrat', fontWeight: 900, lineHeight: 1}} className="font-gotham text-white font-ultra text-7xl ">The Only Way to <br/><span className="highlight2 orange">Validate Training</span></p>
                            <div className="flex flex-row items-center justify-center gap-12 py-12 w-[60%]">
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">400+</p>
                              <p className="text-lg font-bold text-center text-white">Training Courses</p>
                              </div>
                              <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">10</p>
                              <p className="text-lg font-bold text-center text-white">Learning Journeys</p>
                              </div>
                              <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                              <div className="w-1/4">
                              <p className="text-5xl font-bold text-center text-white">50+</p>
                              <p className="text-lg font-bold text-center text-white">Skills</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        </SwiperSlide>
                    <SwiperSlide className="h-screen rounded-xl overflow-auto no-scrollbar max-w-screen-3xl">
                      <div className="py-16 min-h-full flex items-center justify-center ">
                        <div className="bg-neutral-900 rounded-xl max-w-screen-lg overflow-auto pointer-events-auto">
                          {/* <video
                            autoPlay
                            loop
                            playsInline
                            muted
                            poster={genericKCLoopPoster}
                            onCanPlay={(el) => {
                              el.target.classList.remove("opacity-0");
                            }}
                            src="https://player.vimeo.com/progressive_redirect/playback/896368531/rendition/1080p/file.mp4?loc=external&log_user=0&signature=a396afe73b365e2eacda03e6983532fa39e38e96fc58e550c4240f7899341ff9"
                            className="w-full opacity-0 aspect-video duration-500 ease-in-out"
                          ></video> */}
                          <div className="p-20 py-12">
                            <h4 className="text-5xl font-bold text-white mb-8">
                              Dark green results begin with our industry-leading
                              training program.
                            </h4>
                            <p className="text-3xl font-normal text-white mb-6 mt-6">
                              SBM offers the only training program in our
                              industry that can validate all employees are fully
                              trained to deliver a dark green client experience.
                            </p>
                            <ul className="list-blue-disc list-outside text-xl text-white mb-12">
                              <li className="ml-8 mb-2">
                                Position-specific training assignments
                              </li>
                              <li className="ml-8 mb-2">
                                Mobile access to our training library of over
                                400 courses
                              </li>
                              <li className="ml-8 mb-2">
                                Custom client and site trainings
                              </li>
                              <li className="ml-8 mb-2">
                                App-enabled course completion
                              </li>
                              <li className="ml-8 mb-2">
                                Individual transcripts to validate compliance
                              </li>
                            </ul>
                            <video
                              controls
                              src="https://player.vimeo.com/progressive_redirect/playback/789115779/rendition/1080p/file.mp4?loc=external&log_user=0&signature=4f8d8c917d93a88dc49686737df996aa0be87ea198b3d1caafe5912c24af5ed4"
                            className="w-full rounded-xl"
                            ></video>
                            <hr className="h-8 border-none" />
                            <ArrowButton
                              label="Explore the Knowledge Center"
                              newTab={true}
                              href="https://app.4insite.com/training/knowledge-center/course-library"
                            />
                            <hr className="mt-20 mb-12 border-t border-neutral-700" />
                            <div className="flex flex-row items-center justify-center gap-12 py-12 w-[60%]">
                              <div className="w-1/3">
                                <p className="text-6xl font-normal text-white">
                                  95
                                  <span className="text-4xl text-neutral-500 font-normal uppercase">
                                    %
                                  </span>
                                </p>
                                <p className="text-neutral-500 text-sm font-normal">
                                  training compliance across the CBRE portfolio.
                                </p>
                              </div>
                              <div className="w-1/3">
                                <p className="text-6xl font-normal text-white">
                                  45
                                  <span className="text-4xl text-neutral-500 font-normal uppercase">
                                    k
                                  </span>
                                </p>
                                <p className="text-neutral-500 text-sm font-normal">
                                  hours of training documented in the last year
                                  across the CBRE Portfolio.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            )
          }
        >
          {newUIButton()}
          <VideoBG
            src={[
              newUI
                ? newTraining
                : "https://player.vimeo.com/progressive_redirect/playback/537950245/rendition/540p/file.mp4?loc=external&log_user=0&signature=d271249ee12c6feff3e9cdb145c384ee38d5d7f29fa33f3ec588e24a857df912",
            ]}
            modalOpen={modalOpen}
          />
          <div
            className={`relative z-10 h-full w-full bg-gradient-to-b pointer-events-none ${
              newUI
                ? "from-black/80 via-black/40 to-black/20"
                : "from-neutral-800/90 via-neutral-800/10 to-neutral-800/0"
            } p-6 `}
          >
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Training
            </p>
          </div>
        </div>

        {/* <div
          className="monthly-hero-card row-span-2 group cursor-pointer"
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="bg-neutral-800 rounded-xl max-w-screen-md overflow-auto">
                <video
                  autoPlay
                  loop
                  playsInline
                  muted
                  onCanPlay={(el) => {
                    el.target.classList.remove("opacity-0");
                  }}
                  className="w-full opacity-0 aspect-[21/9] object-cover duration-500 ease-in-out"
                >
                  <source src="https://player.vimeo.com/progressive_redirect/playback/873457762/rendition/720p/file.mp4?loc=external&log_user=0&signature=ba282ff4db86cb81e99bd6bda70f20ad7887571c7704e5b5186e4c20f5339d54" />
                </video>
                <div className="p-16 py-12">
                  <h4 className="text-5xl font-bold text-white mb-8">
                    Union Negotiations
                  </h4>
                  <p
                    className="text-3xl font-normal text-white mb-6"
                    style={{ textWrap: "pretty" }}
                  >
                    SBM’s support of critical union negotiations secured a{" "}
                    <span className="font-black text-fb">
                      cost avoidance of $1.1M annually
                    </span>{" "}
                    for CBRE clients.
                  </p>
                  <p className="text-lg font-normal text-white mb-4 last:mb-0">
                    SBM's labor relations team acted in good faith on behalf of
                    CBRE clients to navigate tense SEIU Local 32BJ negotiations
                    across 12 key markets. All negotiations successfully reached
                    an agreement prior to expiration, avoiding potential
                    stoppages and mitigating most of the Union’s demands and
                    lowering total economic impact to 3-5% year over year.
                  </p>
                  
                  <p className="text-lg font-extrabold text-white mb-2">
                    Original union demands included:
                  </p>
                  <ul className="list-blue-disc list-outside text-lg text-white mb-4">
                    <li className="ml-8">
                      Restrictions related to temporary labor and workforce
                      reductions
                    </li>
                    <li className="ml-8">
                      Mandatory PT to FT role conversions
                    </li>
                    <li className="ml-8">
                      10% + annual wage increases & unprecedented pension
                      increases
                    </li>
                    <li className="ml-8">
                      3+ additional holidays & PTO Increases
                    </li>
                  </ul>

                 
                </div>
               
              </div>
            )
          }
        >
          {newUIButton()}
          {newUI ? (
            <video
              autoPlay
              loop
              playsInline
              muted
              src={newMarketUpdates}
              preload="auto"
              onCanPlay={(el) => {
                el.target.classList.remove("opacity-0");
              }}
              className="absolute z-0 top-0 left-0 h-full w-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out object-cover opacity-0"
            ></video>
          ) : (
            <img
              src={marketupdates}
              alt=""
              onLoad={(el) => {
                el.target.classList.remove("opacity-0");
              }}
              className="absolute z-0 top-0 left-0 h-full w-full transition-all group-hover:opacity-70 group-hover:scale-110 duration-500 ease-in-out object-cover opacity-0"
            />
          )}

          <div
            className={`relative z-10 h-full w-full bg-gradient-to-b ${
              newUI
                ? "from-black/80 via-black/40 to-black/20"
                : "from-neutral-800/90 via-neutral-800/10 to-neutral-800/0"
            } p-6 `}
          >
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Market Updates
            </p>
          </div>
        </div> */}
      </div>
    );
  } else if (selection === "cbreLS") {
    return (
      <div className="h-screen w-full p-12 grid grid-cols-12 grid-rows-6 gap-6 pb-20 mx-auto">
        <div
          id="confidential"
          className="fixed z-[1400] bottom-0 p-2 left-0 w-full pointer-events-none bg-neutral-800/80 backdrop-blur-xl flex flex-row items-center justify-between"
        >
          <img
            className="h-8"
            src="https://sbmupdates.s3.amazonaws.com/assets/sbm.svg"
          />
          <p className="text-center uppercase font-bold tracking-wide text-xs text-white ">
            SBM Confidential
          </p>
        </div>
        <MonthlyCard
          colSpan={"col-span-5"}
          rowSpan={"row-span-2"}
          src={[newSafety]}
          showModal={showModal}
          title={"Safety"}
          poster={null}
        >
          <div className="monthly-swiper  fixed top-0 left-0 bottom-0 right-0 pointer-events-none">
            <div className="relative h-full w-full">
              <Swiper
                className="h-screen"
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={"auto"}
                navigation={true}
                modules={[Navigation]}
              >
                <SwiperSlide className=" h-screen overflow-auto no-scrollbar">
                  <div className="py-16 min-h-full flex items-center justify-center w-[80vw]">
                    <div className="relative rounded-3xl flex h-full overflow-hidden pointer-events-auto">
                      <SlideVideo
                        src={
                          "https://player.vimeo.com/progressive_redirect/playback/199079277/rendition/1080p/file.mp4?loc=external&log_user=0&signature=cfc4a0681e99cf574c2996e7ce6d0e86e9269e4960f26e81c29ca0a53e5ac340"
                        }
                      />
                      {/* <video
                        muted
                        autoPlay
                        src="https://player.vimeo.com/progressive_redirect/playback/199079277/rendition/1080p/file.mp4?loc=external&log_user=0&signature=cfc4a0681e99cf574c2996e7ce6d0e86e9269e4960f26e81c29ca0a53e5ac340"
                        className="m-0 relative z-0 rounded-xl !max-h-[80vh] aspect-video"
                      ></video> */}
                      <div className="absolute top-0 left-0 h-full w-full z-20 p-12 flex items-end bg-gradient-to-b from-transparent to-neutral-900 rounded-xl overflow-hidden" style={{transform: 'translateZ(-10px)'}}>
                        <p className="text-white text-3xl font-bold mb-0 pb-0">
                          Slips, Trips, & Falls
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen overflow-auto no-scrollbar">
                  <div className="py-16 min-h-full flex items-center">
                    <div className="w-[80vw] rounded-xl bg-neutral-900 pointer-events-auto p-12">
                      <p className="monthly-tag text-orange-500">Recordables</p>
                      <p className="monthly-title">Monthly Trends</p>
                      <Chart
                        data={[
                          {
                            name: "Recordables",
                            data: [0, 2, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0],
                          },
                        ]}
                        options={{
                          type: "column",
                          categories: [
                            "Jan 23",
                            "Feb 23",
                            "Mar 23",
                            "Apr 23",
                            "May 23",
                            "Jun 23",
                            "Jul 23",
                            "Aug 23",
                            "Sep 23",
                            "Oct 23",
                            "Nov 23",
                            "Dec 23",
                            "Jan 24",
                            "Feb 24",
                          ],
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-2xl">
                  <div className="py-16 min-h-full flex items-center">
                    <div className="w-[80vw] rounded-xl bg-neutral-900 pointer-events-auto">
                      <MonthlySwitch
                        data={{
                          param: "goodcatches",
                          headline: "Preventing incidents before they happen",
                          story:
                            "We proactively identified 4,672 good catches, protecting CBRE clients’ facilities and the health and safety of everyone in them. ",
                          data: [
                            {
                              name: "Good Catches",
                              data: [
                                { name: "Spills/Leaks", y: 1239 },
                                { name: "Slips/Trips", y: 612 },
                                { name: "Maintenance", y: 1432 },
                                { name: "Behavior", y: 111 },
                                { name: "Equipment", y: 582 },
                                { name: "Environmental", y: 363 },
                                { name: "Improper Storage", y: 902 },
                                { name: "Chemical", y: 75 },
                                { name: "Lifting", y: 12 },
                              ],
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
                  <div className="py-16 min-h-full w-full flex items-center">
                    <div
                      onClick={(el) => handleClick(el)}
                      className="relative max-w-screen-md rounded-xl overflow-hidden bg-teal-950 pointer-events-auto"
                    >
                      <div className="relative header-image overflow-hidden">
                        <img
                          src={lab}
                          alt=""
                          className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                        />
                        <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                        <div className="p-16 relative z-10 flex items-center justify-center gap-6">
                          <img
                            src={safetyhelmet}
                            alt=""
                            className="mx-auto my-16 brightness-0 invert h-16"
                          />
                        </div>
                      </div>
                      <div className="p-8 pt-6 relative">
                        <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-teal-500">
                          HSE Initiatives
                        </p>
                        <h4 className="text-5xl font-bold text-white pr-6">
                          Increased Focus on Upstream Initiatives
                        </h4>
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="absolute top-6 right-8 text-2xl text-white"
                        />
                        <div id="story" className="max-h-0 overflow-hidden">
                          <p className="text-3xl font-normal text-white mt-6">
                            Employee Empowerment
                          </p>
                          <ul className="text-lg list-blue-disc font-normal text-white mb-4 last:mb-0">
                            <li className="ml-6">
                              Increased behavioral auditing and 1:1 coaching
                            </li>
                            <li className="ml-6">
                              Refreshing safety committees
                            </li>
                          </ul>
                          <p className="text-3xl font-normal text-white mt-6">
                          Stop the Job
                          </p>
                          <ul className="text-lg list-blue-disc font-normal text-white mb-4 last:mb-0">
                            <li className="ml-6">
                            Employee recognition for appropriately engaging this
                            </li>

                          </ul>
                          <p className="text-3xl font-normal text-white mt-6">
                          Incident Reporting
                          </p>
                          <ul className="text-lg list-blue-disc font-normal text-white mb-4 last:mb-0">
                            <li className="ml-6">
                            Employee involvement in investigations
                            </li>

                          </ul>
                          <p className="text-3xl font-normal text-white mt-6">
                          Good Catch/Hazard Identification
                          </p>
                          <ul className="text-lg list-blue-disc font-normal text-white mb-4 last:mb-0">
                            <li className="ml-6">
                            Updated and more robust training and education
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className=" h-screen overflow-auto no-scrollbar">
                  <div className="py-16 min-h-full flex items-center w-[80vw]">
                    <div className="relative rounded-3xl overflow-hidden pointer-events-auto">
                      <SlideVideo
                        src={
                          "https://player.vimeo.com/progressive_redirect/playback/154693695/rendition/1080p/file.mp4?loc=external&log_user=0&signature=afb52908584f15183f87dc5ef5373bf9e1f51877bec0717e12fcb310daf92cea"
                        }
                      />
                      {/* <video
                        autoPlay
                        muted
                        src="https://player.vimeo.com/progressive_redirect/playback/154693695/rendition/1080p/file.mp4?loc=external&log_user=0&signature=afb52908584f15183f87dc5ef5373bf9e1f51877bec0717e12fcb310daf92cea"
                        className="m-0 relative z-0 rounded-xl w-full aspect-video"
                      ></video> */}
                      <div className="absolute top-0 left-0 h-full w-full z-20 p-12 flex items-end bg-gradient-to-b from-transparent to-neutral-900 rounded-xl overflow-hidden" style={{transform: 'translateZ(-10px)'}}>
                        <p className="text-white text-3xl font-bold mb-0 pb-0">
                          Supporting Our Client's Right to Operate
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </MonthlyCard>

        <MonthlyCard
          colSpan={"col-span-3"}
          rowSpan={"row-span-2"}
          src={[newESG]}
          showModal={showModal}
          title={"Sustainability"}
          poster={null}
        >
          <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
            <div className="h-full w-full monthly-swiper no-w-auto mx-auto">
              <Swiper
                className="h-screen "
                spaceBetween={0}
                slidesPerView={2.5}
                centeredSlides={true}
                navigation={true}
                modules={[Navigation]}
              >
                <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
                  <div className="py-16 min-h-full w-full flex items-center justify-center">
                    <div className="relative w-full rounded-xl overflow-hidden bg-teal-950 pointer-events-auto">
                      <div className="relative header-image overflow-hidden">
                        <motion.video
                          muted
                          loop
                          autoPlay
                          playsInline
                          className="flex-shrink min-h-0 min-w-0 w-full h-[40vh] object-contain py-4"
                          style={{
                            transformOrigin: "top",
                          }}
                          initial={{ opacity: 0, y: 200 }}
                          whileInView={{
                            y: 0,
                            opacity: 1,
                            rotateY: "0",
                            transition: { duration: 0.75, ease: "easeOut" },
                          }}
                        >
                          <source
                            src={ecovadismov}
                            type="video/quicktime"
                            codecs="hvc1"
                          />
                          <source src={ecovadisebm} type="video/webm" />
                        </motion.video>
                        <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                      </div>
                      <div className="p-8 pt-6 relative">
                        <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-teal-500">
                          ESG Updates
                        </p>
                        <h4 className="text-5xl font-bold text-white pr-6">
                          Celebrating our Forward Progress
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
                  <div className="py-16 min-h-full w-full flex items-center justify-center">
                    <div className="relative w-full rounded-xl overflow-hidden bg-teal-950 pointer-events-auto">
                      <div className="relative header-image overflow-hidden">
                        {/* <img
                    src={"lab"}
                    alt=""
                    className="absolute block z-10 object-cover object-center top-0 left-0 h-full w-full grayscale opacity-20 mix-blend-screen"
                  /> */}
                        <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                        <div className="p-16 relative z-10 flex items-center justify-center gap-6">
                          <img
                            src="https://sbmupdates.s3.amazonaws.com/assets/emitwise-main-logo.svg"
                            alt=""
                            className="mx-auto my-16 brightness-0 invert h-16"
                          />
                        </div>
                      </div>
                      <div className="p-8 pt-6 relative">
                        <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-teal-500">
                          Emitwise
                        </p>
                        <h4 className="text-5xl font-bold text-white pr-6">
                          SBM is scheduled to be rolled into the next launch
                          phase
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen overflow-y-auto no-scrollbar">
                  <div className="py-16 min-h-full w-full flex items-center justify-center">
                    <div className="relative w-full rounded-xl overflow-hidden bg-teal-950 pointer-events-auto">
                      <div className="relative header-image overflow-hidden">
                        <div className="absolute z-10 top-0 left-0 h-full w-full bg-gradient-to-b from-transparent to-black/60 mix-blend-multiply"></div>
                        <div className="relative z-10 flex items-center justify-center gap-6 bg-white">
                          <motion.video
                            muted
                            loop
                            autoPlay
                            playsInline
                            className="flex-shrink min-h-0 min-w-0 w-full h-[40vh] object-contain"
                            style={{
                              transformOrigin: "top",
                            }}
                            initial={{ opacity: 0, y: 200 }}
                            whileInView={{
                              threshold: 0.9,
                              y: 0,
                              opacity: 1,
                              rotateY: "0",
                              transition: { duration: 0.75, ease: "easeOut" },
                            }}
                          >
                            <source src={mbe} />
                          </motion.video>
                        </div>
                      </div>
                      <div className="p-8 pt-6 relative">
                        <p className="text-lg uppercase tracking-wide font-semibold mb-2 text-teal-500">
                          Diversity Partner Program
                        </p>
                        <h4 className="text-5xl font-bold text-white pr-6">
                          We continue to enhance and improve our Diverse
                          Supplier Partnership Program
                        </h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </MonthlyCard>

        <MonthlyCard
          colSpan={"col-span-4"}
          rowSpan={"row-span-4"}
          src={[newHighlights]}
          showModal={showModal}
          title={"Highlights & Opportunities"}
          poster={null}
        >
          <Highlights2 />
        </MonthlyCard>

        <MonthlyCard
          colSpan={"col-span-4"}
          rowSpan={"row-span-4"}
          src={[newOverview]}
          showModal={showModal}
          title={"Supplier Performance"}
          poster={null}
        >
          <div className="monthly-swiper fixed top-0 left-0 bottom-0 right-0 pointer-events-none">
            <div className="relative h-full w-full">
              <Swiper
                className="h-screen"
                spaceBetween={0}
                centeredSlides={true}
                slidesPerView={"auto"}
                navigation={true}
                modules={[Navigation]}
              >
                <SwiperSlide className="h-screen overflow-auto no-scrollbar">
                  <div className="py-16 min-h-full flex items-center">
                    <div className="w-[80vw] rounded-xl bg-neutral-900 pointer-events-auto p-12">
                      <p className="monthly-tag text-orange-500">
                        Account Performance
                      </p>
                      <p className="monthly-title">iPerform Scores</p>
                      <Chart
                        data={iPerformData}
                        options={{
                          type: "column",
                          categories: iPerformData[0].data.map((i) => i.name),
                          // plotLines: [
                          //   {
                          //     color: "white", // Color value
                          //     dashStyle: "dashed", // Style of the plot line. Default to solid
                          //     value: 3.2, // Value of where the line will appear
                          //     width: 1, // Width of the line
                          //   },
                          // ],
                        }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen overflow-auto no-scrollbar">
                  <div className="py-16 min-h-full max-w-screen flex items-center">
                    <div className="max-w-screen-2xl rounded-xl overflow-hidden bg-neutral-900 pointer-events-auto">
                      <div className="grid grid-cols-2">
                        <div className="p-12 pr-20">
                          <p className="monthly-tag text-orange-500 mb-8">
                            Account Performance
                          </p>
                          <p className="monthly-title">
                            i-Perform Best Practices
                          </p>
                          <p className="font-black text-orange-500 text-2xl mt-6">
                            The Process
                          </p>
                          <ul className="list-disc text-white text-lg ">
                            <li className="font-normal ml-6 mt-2">
                              Latresha Jackson, Senior Sourcing Manager at CBRE,
                              has developed an iPerform Review Process involving
                              all stakeholders to facilitate improvements.
                              <ul className="list-disc">
                                <li className="font-normal ml-6 mt-2">
                                  <strong>Tracking Participation:</strong>{" "}
                                  Achieved 100% participation target from July
                                  to December 2023.
                                </li>
                                <li className="font-normal ml-6 mt-2">
                                  <strong>
                                    Reviewing Key Indicators (KI):
                                  </strong>{" "}
                                  Conducts thorough reviews of each KI with all
                                  participants and actively seeks feedback.
                                </li>
                                <li className="font-normal ml-6 mt-2">
                                  <strong>Addressing Comments:</strong>{" "}
                                  Addresses comments as applicable and initiates
                                  discussions where no comments have been
                                  provided.
                                </li>
                                <li className="font-normal ml-6 mt-2">
                                  <strong>Action Items:</strong> Creates
                                  actionable steps to foster continuous
                                  improvement.
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <p className="font-black text-orange-500 text-2xl mt-6">
                            The Benefit
                          </p>
                          <ul className="list-disc text-white text-lg ">
                            <li className="ml-6 mt-2 font-black">
                              This approach fosters collaboration within the
                              iPerform process and offers a transparent pathway
                              to improvement through data sharing.
                            </li>
                          </ul>
                        </div>
                        <div className="h-full p-12 bg-neutral-850">
                          <div
                            className="grid grid-cols-5 gap-x-2 h-full grid-flow-col"
                            style={{ gridTemplateRows: "repeat(12, 1fr)" }}
                          >
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-black p-2 py-4 ">
                              KI Category
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              1.2 Operational  Performance
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              2.2 Supplier Management Team
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              3.2 Pricing and Cost Reduction
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              4.2 Quality Performance
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 whitespace-nowrap overflow-hidden text-ellipsis">
                              5.2 Best Practices, Innovation & Technology
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              6.2 Risk and Compliance
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              7.2 Safety
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              8.2 Sustainability
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              9.2 Janitorial
                            </div>
                            <div className="col-span-2 text-sm self-center text-neutral-500 font-bold p-2 py-4 line-clamp-1 text-ellipsis">
                              10.2 Overall Customer Satisfaction
                            </div>
                            <div className="h-full w-full flex items-center text-lg self-center font-black col-span-2">
                              <p className="px-4 py-4 text-white text-left font-black">
                                Overall
                              </p>
                            </div>
                            <div className="relative h-full w-full bg-neutral-800 flex items-center justify-center rounded-t-xl text-sm font-black text-white p-2 text-center self-center">
                              <div className="absolute -top-2 left-0 w-full h-2 px-8">
                                <div className="h-full w-full bg-orange-500 rounded-t-xl"></div>
                              </div>
                              JUL-DEC 22
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.33
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.47
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-yellow/20 rounded-lg text-cbre-yellow">
                                2.80
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.40
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.40
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                4.00
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.47
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.47
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.27
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center rounded-b-xl text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.40
                              </p>
                            </div>
                            <div className="h-full w-full flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 font-black text-cbre-light-green text-xl">
                                3.34
                              </p>
                            </div>
                            <div className="relative h-full w-full bg-neutral-800 flex items-center justify-center rounded-t-xl text-sm font-black text-white p-2 text-center self-center">
                              <div className="absolute -top-2 left-0 w-full h-2 px-8">
                                <div className="h-full w-full bg-orange-500 rounded-t-xl"></div>
                              </div>
                              JAN-JUN 23
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.06
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.29
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.06
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-yellow/20 rounded-lg text-cbre-yellow">
                                2.94
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-yellow/20 rounded-lg text-cbre-yellow">
                                2.88
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                4.00
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                3.59
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.41
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.16
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center rounded-b-xl text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.18
                              </p>
                            </div>
                            <div className="h-full w-full flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 text-cbre-light-green text-xl font-black">
                                3.20
                              </p>
                            </div>
                            <div className="relative h-full w-full bg-neutral-800 flex items-center justify-center rounded-t-xl text-sm font-black text-white p-2 text-center self-center">
                              <div className="absolute -top-2 left-0 w-full h-2 px-8">
                                <div className="h-full w-full bg-orange-500 rounded-t-xl"></div>
                              </div>
                              JUL-DEC 23
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.25
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                3.50
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.00
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.12
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-yellow/20 rounded-lg text-cbre-yellow">
                                2.94
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                3.81
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-dark-green/20 rounded-lg text-cbre-dark-green">
                                3.50
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.25
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.23
                              </p>
                            </div>
                            <div className="h-full w-full bg-neutral-800 flex items-center justify-center rounded-b-xl text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 bg-cbre-light-green/20 rounded-lg text-cbre-light-green">
                                3.31
                              </p>
                            </div>
                            <div className="h-full w-full flex items-center justify-center text-center text-lg self-center font-black ">
                              <p className="px-4 py-2 text-cbre-light-green text-xl font-black">
                                3.25
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-[80vw] rounded-xl bg-neutral-900 pointer-events-auto p-12">
                      <p className="monthly-tag text-orange-500">
                        Account Performance
                      </p>
                      <p className="monthly-title">Ranked Comparison</p>
                      <Chart
                        data={[
                          {
                            name: "iPerform Rank",
                            data: [
                              {
                                name: "Eli Lilly",
                                y: 1,
                                color: "#90F6B9",
                              },
                              {
                                name: "Siemens",
                                y: 2,
                                color: "#90F6B9",
                              },
                              {
                                name: "Novartis",
                                y: 3,
                                color: "#90F6B9",
                              },
                              {
                                name: "IQVIA",
                                y: 4,
                                color: "#90F6B9",
                              },
                              {
                                name: "Elanco",
                                y: 5,
                                color: "#90F6B9",
                              },
                              {
                                name: "Merck",
                                y: 6,
                                color: "#90F6B9",
                              },
                              {
                                name: "Biogen",
                                y: 7,
                                color: "#90F6B9",
                              },
                              {
                                name: "Takeda",
                                y: 8,
                                color: "#90F6B9",
                              },
                              {
                                name: "Abbvie",
                                y: 9,
                                color: "#90F6B9",
                              },
                              {
                                name: "McKesson",
                                y: 10,
                                color: "#90F6B9",
                              },
                            ],
                          },
                          {
                            name: "SBM Rank",
                            data: [
                              {
                                name: "Eli Lilly",
                                y: 1,
                                color: "#f76120",
                              },
                              {
                                name: "Siemens",
                                y: 3,
                                color: "#f76120",
                              },
                              {
                                name: "Novartis",
                                y: 3.08,
                                color: "#f76120",
                              },
                              {
                                name: "IQVIA",
                                y: 5,
                                color: "#f76120",
                              },
                              {
                                name: "Elanco",
                                y: 8,
                                color: "#f76120",
                              },
                              {
                                name: "Merck",
                                y: 2,
                                color: "#f76120",
                              },
                              {
                                name: "Biogen",
                                y: 6,
                                color: "#f76120",
                              },
                              {
                                name: "Takeda",
                                y: 7,
                                color: "#f76120",
                              },
                              {
                                name: "Abbvie",
                                y: 9,
                                color: "#f76120",
                              },
                              {
                                name: "McKesson",
                                y: 10,
                                color: "#f76120",
                              },
                            ],
                          },
                        ]}
                        options={{
                          type: "column",
                          categories: [
                            {
                              name: "Eli Lilly",
                              y: 2.88,
                              color: "#FEEC7E",
                            },
                            {
                              name: "Siemens",
                              y: 2.99,
                              color: "#FEEC7E",
                            },
                            {
                              name: "Novartis",
                              y: 3.08,
                              color: "#90F6B9",
                            },
                            {
                              name: "IQVIA",
                              y: 3.08,
                              color: "#90F6B9",
                            },
                            {
                              name: "Elanco",
                              y: 3.14,
                              color: "#90F6B9",
                            },
                            {
                              name: "Merck",
                              y: 3.28,
                              color: "#90F6B9",
                            },
                            {
                              name: "Biogen",
                              y: 3.33,
                              color: "#90F6B9",
                            },
                            {
                              name: "Takeda",
                              y: 3.35,
                              color: "#90F6B9",
                            },
                            {
                              name: "Abbvie",
                              y: 3.52,
                              color: "#378D59",
                            },
                            {
                              name: "McKesson",
                              y: 3.6,
                              color: "#378D59",
                            },
                          ].map((i) => i.name),
                        }}
                      />
                    </div> */}
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </MonthlyCard>
        {/* <div
          onClick={(el) =>
            showModal(
              el,
              null,
              <div className="fixed top-0 left-0 right-0 bottom-0 monthly-swiper no-w-auto pointer-events-none">
                <Swiper
                  className="h-screen"
                  spaceBetween={0}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  navigation={true}
                  modules={[Navigation]}
                >
                  <SwiperSlide className=" h-screen overflow-auto no-scrollbar">
                    <div className="p-16 px-32 min-h-full flex items-center overflow-auto no-scrollbar ">
                      <div className="w-full min-h-full p-12 bg-neutral-900 rounded-2xl pointer-events-auto">
                        <p className="monthly-tag text-blue-400 text-center">
                          Our Joint Clients
                        </p>
                        <p className="monthly-title text-center mb-6">
                          World Class Results 
                          <br />
                          For World Class Clients
                        </p>
                        <div className="flex flex-row mb-12 justify-center items-center gap-12">
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              55
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Clients
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              208
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Sites
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              34
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              States
                            </p>
                          </div>
                          <div className="w-48">
                            <p className="text-5xl text-blue-400 font-black text-center">
                              3
                            </p>
                            <p className="text-lg text-neutral-500 font-bold text-center">
                              Countries
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-row justify-center flex-wrap gap-8">
                          {allCBRE.logos
                            .sort((a, b) => {
                              return b.sqft - a.sqft;
                            })
                            .map((logo) => (
                              <div className="rounded-2xl flex flex-col items-center overflow-hidden bg-white">
                                <div className=" flex flex-row p-6 pt-4 pb-2 gap-4">
                                  <img
                                    className="block max-h-[4rem] min-h-[2rem] w-52 object-contain"
                                    alt={logo.client}
                                    src={`https://sbmupdates.s3.amazonaws.com/clients_isolated/${logo.logo}.svg`}
                                  />
                                  <div
                                    class={`p-2 pb-1 rounded-xl h-16 flex flex-col items-center justify-center ${scorecardColor(
                                      logo.scorecard
                                    )}`}
                                  >
                                    <p className="text-xs font-bold text-white text-center uppercase leading-0 mb-0">
                                      Avg Score
                                    </p>
                                    <p className="text-lg text-white font-black text-center">
                                      {logo.scorecard}<span className="text-base opacity-70">/5</span>
                                    </p>
                                  </div>
                                </div>
                                <div className="flex flex-row items-center justify-evenly w-full px-2 pb-2">
                                  <div class="w-1/3 p-2 pb-1">
                                    <p className="text-xs font-bold text-gray-400 text-center uppercase leading-0 mb-0">
                                      Sites
                                    </p>
                                    <p className="text-lg text-gray-400 font-black text-center">
                                      {logo.sites}
                                    </p>
                                  </div>
                                  <div class="w-1/3 p-2 pb-1">
                                    <p className="text-xs font-bold text-gray-400 text-center uppercase leading-0 mb-0">
                                      SqFt
                                    </p>
                                    <p className="text-lg text-gray-400 font-black text-center">
                                      {nFormatter(logo.sqft, 1)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className=" h-screen overflow-auto no-scrollbar px-32">
                    <div className="py-16 min-h-full flex items-center overflow-auto no-scrollbar ">
                      <div className="w-full min-h-full py-12 bg-neutral-900/70 rounded-2xl backdrop-blur-xl flex items-center justify-center pointer-events-auto">
                        <MonthlySwitch
                          data={{
                            param: "top10",
                            data: cbreData,
                            title: {
                              tag: "Year-End Performance Overview",
                              headline: checkLocal()
                                ? "SBM’s top ten CBRE portfolios account for 71.5% of your $18.5M spend."
                                : "SBM's top ten CBRE portfolios account for 71.5% of your spend.",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            )
          }
          className="monthly-hero-card relative group row-span-4 col-span-4 cursor-pointer bg-neutral-800"
        >
          {newUI && newUIShade()}
          {newUIButton()}
          {newUI && (
            <VideoBG src={[newOverview]} modalOpen={modalOpen} />
          )}
          <div className="relative z-20 w-full p-6 pointer-events-none">
            <p
              className={
                newUI
                  ? "text-4xl font-black text-lato text-left text-white"
                  : "text-xl font-black text-lato text-center text-white"
              }
            >
              Overview
            </p>
          </div>
          <div
            className={
              newUI
                ? "hidden"
                : "h-full w-full px-6 flex items-start justify-center relative"
            }
          >
            <img
              className="relative z-10 w-full group-hover:scale-105 group-hovver:opacity-80 transition-all duration-500 ease-in-out shadow-xl shadow-black/40"
              src={darkscorecardgeneric}
              alt=""
            />
            <div className="absolute z-20 top-1/2 left-0 w-full h-1/2 bg-gradient-to-b from-transparent to-neutral-800 pointer-events-none"></div>
          </div>
        </div> */}
        <div
          className="monthly-hero-card relative row-span-2 col-span-4 bg-neutral-800 flex flex-col items-center justify-center bg-cover"
          style={{ backgroundImage: newUI ? "none" : `url(${december})` }}
        >
          {newUI && (
            <video
              playsInline
              autoPlay
              muted
              loop
              className="absolute z-0 h-full w-full object-cover"
              style={{
                filter: "hue-rotate(312deg) brightness(0.6) contrast(1.1)",
              }}
              src={center}
            ></video>
          )}
          <div className="flex flex-row items-baseline">
          <img src={cbre} className="block relkative z-20 h-6 2xl:h-8 mb-4" alt="" />
              <p className="text-xl 2xl:text-4xl text-white z-20 ml-4">Life Science</p>
          </div>
          <p className="relative z-20 text-2xl 2xl:text-5xl font-black text-white text-lato text-center">
            2023 Year-In-Review
          </p>
          <img src={sbm} className="block relkative z-20 h-8 mt-4" alt="" />
        </div>

        <MonthlyCard
          colSpan={"col-span-5"}
          rowSpan={"row-span-2"}
          src={[newInnovation]}
          objectPosition="object-top"
          showModal={showModal}
          title={"Innovations"}
          poster={null}
        >
          <div className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none">
            <div className="h-full w-full monthly-swiper no-w-auto mx-auto">
              <Swiper
                className="h-screen "
                spaceBetween={0}
                slidesPerView={"auto"}
                centeredSlides={true}
                navigation={true}
                modules={[Navigation]}
              >
                <SwiperSlide className="h-screen rounded-xl overflow-hidden no-scrollbar max-w-screen-3xl">
                  <div className="py-16 min-h-full flex items-center justify-center">
                    <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto">
                      <video
                        autoPlay
                        loop
                        playsInline
                        muted
                        poster={genericKCLoopPoster}
                        onCanPlay={(el) => {
                          el.target.classList.remove("opacity-0");
                        }}
                        src={quality}
                        className="w-full opacity-0 aspect-video duration-500 ease-in-out relative z-0"
                      ></video>
                      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col w-full items-center gap-8 justify-around z-10">
                        <img src={sbm} alt="" />
                        <p
                          style={{
                            fontFamily: "Gotham Black, Montserrat",
                            fontWeight: 900,
                            lineHeight: 1,
                          }}
                          className="font-gotham text-white font-ultra text-7xl text-center"
                        >
                          Leading Results through <br />
                          <span className="highlight2 orange">
                            Unrivaled Innovation
                          </span>
                        </p>
                        {/* <div className="flex flex-row items-center justify-center gap-12 py-12 w-[60%]">
                          <div className="w-1/4">
                            <p className="text-5xl font-bold text-center text-white">
                              4.35
                              <span className=" opacity-70 text-3xl">/5</span>
                            </p>
                            <p className="text-lg font-bold text-center text-white">
                              Avg. Audit Score
                            </p>
                          </div>
                          <div className="flex-shrink-0 h-full w-[1px] flex-grow-0 bg-white"></div>
                          <div className="w-1/4">
                            <p className="text-5xl font-bold text-center text-white">
                              4.48
                              <span className=" opacity-70 text-3xl">/5</span>
                            </p>
                            <p className="text-lg font-bold text-center text-white">
                              Avg. CSAT
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screen rounded-xl overflow-auto no-scrollbar max-w-screen-3xl">
                  <div className="py-16 min-h-full flex items-center justify-center">
                    <div className="relative bg-neutral-900 rounded-xl h-[80vh] no-scrollbar overflow-auto pointer-events-auto w-full max-h-full">
                      <div className="p-12 px-6">
                        <p className="monthly-tag text-orange-500">
                          Robotics Literacy
                        </p>
                        <p className="monthly-title">
                          Assessing AMR Equipment{" "}
                        </p>
                      </div>
                      <div className="grid grid-cols-10 w-full border-b-2 border-neutral-700 sticky top-0 bg-neutral-900 z-20">
                        <div className="py-4 px-6 col-span-2">
                          <p className=" font-black uppercase text-white">
                            Equipment
                          </p>
                        </div>
                        <div className="py-4 px-6 col-span-2">
                          <p className="text-lg font-bold text-white"></p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            Efficiency
                          </p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            Run Time
                          </p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            Value
                          </p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            ROI
                          </p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            Safety
                          </p>
                        </div>
                        <div className="py-4 px-6">
                          <p className=" font-black uppercase text-white text-center">
                            Rating
                          </p>
                        </div>
                      </div>
                      {innovationList.map((item, i) => (
                        <div key={i}
                          className={`grid grid-cols-10 w-full border-b border-neutral-800 last:border-b-0 z-10 ${
                            item.highlight === true ? "bg-success/10" : ""
                          }`}
                        >
                          <div className="py-4 px-6 col-span-1">
                            <img
                              src={item.img}
                              alt=""
                              className="w-full aspect-square object-contain"
                            />
                          </div>
                          <div className="py-4 px-6 col-span-3">
                            <p className="text-lg font-black text-white">
                              {item.system}
                            </p>
                            <p className="text-sm font-bold uppercase  text-white/80">
                              {item.type}
                            </p>
                            <p className="text-sm font-normal text-white/80">
                              {item.floorTypes}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                              {item.efficiency}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                              {item.runtime}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p className="text-lg font-black text-white/80 text-center  py-2 rounded-xl ">
                              {item.value}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p className="text-lg font-black text-white/80 text-center w-20 py-2 rounded-xl ">
                              {item.roi}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p className="text-lg font-black text-white/80  text-center w-20 py-2 rounded-xl ">
                              {item.safety}
                            </p>
                          </div>
                          <div className="py-4 px-6 flex items-center justify-center">
                            <p
                              className={`text-lg font-black text-white text-center w-20 py-2 rounded-xl ${
                                item.highlight === true
                                  ? "bg-success/20"
                                  : "bg-neutral-700"
                              }`}
                            >
                              {item.rating}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screeen rounded-xl overflow-auto no-scrollbar max-w-screen-3xl">
                  <div className="py-16 min-h-full flex items-center justify-center no-scrollbar">
                    <iframe
                      src={
                        takedaSample +
                        "#toolbar=0&navpanes=0&scrollbar=0&view=FitH"
                      }
                      type="application/pdf"
                      style={{ height: "90vh" }}
                      className="h-[90vh] w-full w-screen max-w-screen-2xl flex-g object-cover rounded-2xl z-0 pointer-events-auto"
                    ></iframe>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="h-screeen rounded-xl overflow-auto no-scrollbar max-w-screen-3xl">
                  <div className="py-16 min-h-full flex items-center justify-center">
                    <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto ">
                      <img
                        src={
                          "https://sbmupdates.s3.amazonaws.com/assets/Office%20digital%20network.jpeg"
                        }
                        alt="auditsAI"
                        className=" w-full h-full object-cover rounded-2xl z-0 max-w-[50vw]"
                      />
                      <div className="absolute top-0 left-0 h-full w-full p-8">
                        <p className="monthly-tag text-orange-500">
                          New Technology
                        </p>
                        <p className="monthly-title">The Sensor Landscape</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {
                  full === "true" && 
                <SwiperSlide className="h-screeen rounded-xl overflow-auto no-scrollbar max-w-screen-3xl">
                  <div className="py-16 min-h-full flex items-center justify-center no-scrollbar">
                    <div className="relative bg-neutral-900 rounded-xl overflow-auto pointer-events-auto ">
                      <img
                        src={auditsAI}
                        alt="auditsAI"
                        className=" w-full h-full object-cover rounded-2xl z-0 max-h-[80vh]"
                      />
                      <div className="absolute top-0 left-0 h-full w-full p-8">
                        <p className="monthly-tag text-orange-500">
                          Artificial Intelligence
                        </p>
                        <p className="monthly-title">Enhancing our Platform</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                }
              </Swiper>
            </div>
          </div>
        </MonthlyCard>

        <MonthlyCard
          colSpan={"col-span-3"}
          rowSpan={"row-span-2"}
          src={[pricing]}
          showModal={showModal}
          title={"Pricing"}
          poster={null}
        >
          {/* <Benchmarking /> */}
          <div className="fixed top-0 left-0 h-screen w-screen no-scrollbar overflow-auto pointer-events-none">
            <div className="py-16 min-h-full flex items-center justify-center z-20">
              <div className="relative rounded-xl flex flex-col max-w-screen-2xl pointer-events-auto overflow-auto">
                <img src={benchmarking1} alt="" className="w-full" />
                <img src={benchmarking2} alt="" className="w-full" />
                <img src={benchmarking3} alt="" className="w-full" />
              </div>
            </div>
          </div>
        </MonthlyCard>
      </div>
    );
  } else {
    return null;
  }
}


export function Chart({options, data, plotLines}) {
  const chartOptions = {
      title: {
        text: "",
      },
      chart: {
        backgroundColor: "transparent",
        type: options?.type || "pie",
        spacing: [24, 24, 24, 24],
        height: '400px',
      },
      legend: {
          enabled: options.legend ? true : false,
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          itemMarginBottom: 10,
          itemStyle: {
              color: 'white',
              fontSize: '16px !important'
          }
      },
      colors: [
          "#4BA8FF",
          "#734CFF",
          "#A230D2",
          "#DB33B6",
          "#FF7DB1",
          "#FF4332",
          "#FF6E19",
          "#C29200",
          "#E0C942",
          "#02FA8E",
          "#02CCA6",
          "#02CCA6",
          "#48A4FA",
        ],
      credits: {
        enabled: false,
      },
      xAxis: {
          categories: options.categories || [],
          labels: {
              style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
              },
          },
      },
      yAxis: {
        gridLineDashStyle: "ShortDash",
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: "white",
          },
        },
        min: 0,
        title: '',
        gridLineColor: "rgba(0,0,0,0.4)",
        reversedStacks: false,
        scrollbar: { enabled: true },
        index: 0,
        plotLines: options?.plotLines?.length ? options.plotLines : []
      },
      tooltip: {
          backgroundColor: "rgb(12, 15, 18)",
          outside: true,
          shadow: false,
          style: { color: "#ffffff" , fontSize: '16px'},
      },
      plotOptions: {
          pie: {
              innerSize: '70%',
              showInLegend: true,
              dataLabels: options.dataLabels || {
                  enabled: options.dataLabels ? true : false,
                  style: {
                      color: "white",
                      textOutline: "none",
                      fontSize: '16px'
                  },
                  distance: -25,
              }
          },
          column: {
            borderWidth: 0,
            borderColor: "transparent",
            borderRadius: 10,
            
          },
          series: {
              labels: {
                  enabled: false
              }
          },
      },
      series: data,
    };

    return <HighchartsReact className="w-full" highcharts={Highcharts} options={chartOptions} />
}

export const innovationList = [
  {
    system: "Tennant T7AMR",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/01_Tennant%20T7AMR.png",
    type: "Scubber, Ride-On",
    efficiency: 8,
    runtime: 5,
    highlight: true,
    value: 3,
    roi: 8, 
    safety: 'TBD',
    rating: 6.6,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Corridors and Open Areas with 10 FT MAX Turn Radius"
  },
  {
    system: "Taski GS EcoBot 50P",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/02_Taski%20GS%20EcoBot%2050P.png",
    type: "Scrubber, Walk-Behind",
    efficiency: 6,
    runtime: 8,
    value: 7,
    roi: 3, 
    safety: 'TBD',
    rating: 4.7,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Areas with Narrow Egress (< 36 IN) (Cafeterias, etc.), Corridors and Open Areas"
  },
  {
    system: "Taski GS EcoBot 40",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/03_Taski%20GS%20EcoBot%2040.png",
    type: "Vacuum, Walk-Behind",
    efficiency: 5,
    runtime: 8,
    value: 7,
    roi: 3, 
    safety: 'TBD',
    rating: 4.4,
    floorTypes: "Commercial Environments, Carpet & Other Surfaces, Areas with Narrow Egress (< 34 IN) (Cube Farms, etc.), Corridors and Open Areas"
  },
  {
    system: "Softbank-Gausium V40",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/04_Softbank-Gausium%20V40.png",
    type: "Vacuum, Walk-Behind",
    efficiency: 6,
    runtime: 8,
    value: 6,
    roi: 2, 
    safety: 'TBD',
    rating: 4.0,
    floorTypes: "Commercial Environments, Carpet & Other Surfaces, Areas with Narrow Egress (< 34 IN) (Cube Farms, etc.), Corridors and Open Areas"
  },
  {
    system: "Softbank-Gausium S50",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/05_Softbank-Gausium%20S50.png",
    type: "Scrubber, Walk-Behind",
    efficiency: 6,
    runtime: 8,
    value: 6,
    roi: 2, 
    safety: 'TBD',
    rating: 4.0,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Areas with Narrow Egress (< 36 IN) (Cafeterias, etc.), Corridors and Open Areas"
  },
  {
    system: "Tennant T380AMR",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/06_Tennant%20T380AMR.png",
    type: "Scrubber, Ride-On",
    efficiency: 6,
    runtime: 5,
    value: 4,
    roi: 2, 
    safety: 'TBD',
    rating: 3.5,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Corridors and Open Areas with 7 FT MAX Turn Radius"
  },
  {
    system: "ICE Cobotics COBI 18",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/07_ICE%20Cobotics%20COBI%2018.png",
    type: "Scrubber, Walk-Behind",
    efficiency: 3,
    runtime: 5,
    value: 9,
    roi: 3, 
    safety: 'TBD',
    rating: 3.5,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Areas with Narrow Egress (< 24 IN) (Cafeterias, etc.), Corridors and Open Areas"
  },
  {
    system: "Softbank Whiz",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/08_whiz.png",
    type: "Vacuum, Walk-Behind",
    efficiency: 3,
    runtime: 5,
    value: 7,
    roi: 3, 
    safety: 'TBD',
    rating: 3.3,
    floorTypes: "Commercial Environments, Carpet & Other Surfaces, Areas with Narrow Egress (< 24 IN) (Cube Farms, etc.), Corridors and Open Areas"
  },
  {
    system: "Softbank-Gausium Phantas",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/09_Softbank-Gausium%20Phantas.png",
    type: "Scrubber / Sweeper / Vaccum, Walk-Behind",
    efficiency: 3,
    runtime: 8,
    value: 5,
    roi: 2, 
    safety: 'TBD',
    rating: 3.0,
    floorTypes: "Commercial Environments, Carpet, Hard Floor, and Other Surfaces, Areas with Narrow Egress (< 24 IN) (Cube Farms, Cafeterias, etc.), Corridors and Open Areas, *Provides Edge Cleaning"
  },
  {
    system: "Karcher Kira CV 60/1 RS",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/10_Karcher%20Kira%20CV%2060-1%20RS.png",
    type: "Vacuum, Stand-On",
    efficiency: 6,
    runtime: 5,
    value: 3,
    roi: 1, 
    safety: 'TBD',
    rating: 3.0,
    floorTypes: "Commercial Environments, Carpet & Other Surfaces, Corridors and Open Areas with 4 FT MAX Turn Radius"
  },
  {
    system: "Karcher Kira B100 R",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/11_Karcher%20Kira%20B100%20R.png",
    type: "Scrubber, Ride-On",
    efficiency: 5,
    runtime: 3,
    value: 3,
    roi: 1, 
    safety: 'TBD',
    rating: 2.5,
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Corridors and Open Areas with 10 FT MAX Turn Radius"
  },
  {
    system: "Tennant T16AMR",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/12_Tennant%20T16AMR.png",
    type: "Industtrial Scrubber, Ride-On",
    efficiency: 5,
    runtime: 3,
    value: 2,
    roi: 1, 
    safety: 'TBD',
    rating: 2.4,
    floorTypes: "Industrial Environments, Hard Floor Surfaces, Corridors and Open Areas with 11 FT MAX Turn Radius"
  },
  {
    system: "ICE EMMA RS26L",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/13_ICE%20EMMA%20RS26L.png",
    type: "Scrubber, Ride-On",
    efficiency: 8,
    runtime: 3,
    value: "Discontinued",
    roi: 8, 
    safety: 'TBD',
    rating: "N/A",
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Corridors and Open Areas with 10 FT MAX Turn Radius"
  },
  {
    system: "Taski SwingoBot 2000",
    img: "https://sbmupdates.s3.amazonaws.com/equipment/14_Taski%20SwingoBot%202000.png",
    type: "Scrubber, Walk-Behind",
    efficiency: 5,
    runtime: 5,
    value: "Discontinued",
    roi: 2, 
    safety: 'TBD',
    rating: "N/A",
    floorTypes: "Commercial Environments, Hard Floor Surfaces, Corridors and Open Areas with 5 FT MAX Turn Radius"
  },
]

function SlideVideo ({src}) {
  const ref = useRef(null)
  const isInView = useInView(ref, {threshold: 1, amount: 0.9, triggerOnce: false})

  useEffect(() => {

    if (isInView) {
      // Check if the video is ready to be played
      // while (ref.current.readyState < 3) { continue;};
      ref.current.play();
      // if (ref.current.readyState >= 3) {
      //   ref.current.play().catch(e => console.log(e));
      // }
    } else { 
      // Check if the video is ready to be paused
      if (ref.current.readyState >= 2) {
        ref.current.pause();
      }
    }
}, [isInView])

  return (
    <video
      ref = {ref}
      muted
      // autoPlay
      src={src}
      className="m-0 relative z-10 rounded-xl !max-h-[80vh] aspect-video flex-grow"
    ></video>
  );
}


const iPerformData = [
  {
    name: "iPerform Scores",
    data: [
      {
        name: "Eli Lilly",
        y: 2.88,
        color: "#FEEC7E",
      },
      // {
      //   name: "Siemens",
      //   y: 2.99,
      //   color: "#FEEC7E",
      // },
      {
        name: "Novartis",
        y: 3.16,
        color: "#90F6B9",
      },
      {
        name: "IQVIA",
        y: 3.35,
        color: "#90F6B9",
      },
      {
        name: "Elanco",
        y: 2.98,
        color: "#FEEC7E",
      },
      {
        name: "Merck",
        y: 3.25,
        color: "#90F6B9",
      },
      {
        name: "Biogen",
        y: 3.39,
        color: "#90F6B9",
      },
      {
        name: "Takeda",
        y: 3.20,
        color: "#90F6B9",
      },
      {
        name: "Abbvie",
        y: 3.61,
        color: "#378D59",
      },
      // {
      //   name: "McKesson",
      //   y: 3.6,
      //   color: "#378D59",
      // },
    ].sort((a,b) => a.y - b.y)
  },
]