import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation } from "swiper";
import { motion } from "framer-motion";
import { useSwiperSlide } from 'swiper/react';


import auditsAI from './assets/auditsai.png'
import threeDScorecard from './assets/3dScorecard4.png'

function SlideButton({direction}) {
    const swiper = useSwiper();

    if (direction==="next") {
        return (
            <button onClick={() => swiper.slideNext()} className="swiper-button-next">hi</button>
        )
    } else {
        return (
            <button onClick={() => swiper.slidePrev()} className="swiper-button-prev">bye</button>
        )
    
    }
}

export default function Next() {
    return (
        <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center ">
        <p className="newmonthly-tag text-primary-300 text-center">
          Looking Forward
        </p>
        <p className="newmonthly-title text-center">
          Transforming the future<br/>of facility services.
        </p>
        <div className="w-full">
            <Swiper
            className="!overflow-visible"
            spaceBetween={50}
            slidesPerView={'auto'}
            centeredSlides={true}
            navigation={true}
            modules={[Navigation]}
            onSlideChange={(swiper) => {
                console.log(swiper);
                let video = swiper.slides[swiper.activeIndex].querySelector('video');
                if (video) {
                    video.play();
                }
                let oldVideo = swiper.slides[swiper.previousIndex].querySelector('video');
                if (oldVideo) {
                    oldVideo.pause();
                    oldVideo.currentTime = 0;
                }
            }}
            >
            <>
            <SwiperSlide className="!w-[50vw]">
                <div className="new-linear p-8 rounded-2xl h-[50vh] relative">
                    <img src={threeDScorecard} alt="scorecards" className="absolute top-0 left-0 w-full h-full object-left-top object-cover rounded-2xl z-10"/>

                    <p className="uppercase tracking-wide font-bold text-sm text-primary-300 mb-2 relative z-10">Scorecards</p>
                    <p className="text-white font-extrabold text-2xl leading-tight relative z-10">The True Measure of Excellence</p>
                </div>
            </SwiperSlide>
            <SwiperSlide className="!h-[50vh] !w-[28.125vh]">
                <div className="new-linear p-8 rounded-2xl relative h-full">
                    <video muted loop src="https://player.vimeo.com/progressive_redirect/playback/395823126/rendition/720p/file.mp4?loc=external&log_user=0&signature=5720a05b2a23bbf9ebd29e71744d1598c7a21a26983068d17dc59010dbf097a7" className="absolute top-0 left-0 z-0 h-full w-full object-cover rounded-2xl"></video>
                    <div className="absolute top-0 left-0 h-full w-full z-10 bg-gradient-to-b from-black via-black/60 to-black/10 rounded-2xl"></div>

                    <p className="uppercase tracking-wide font-bold text-sm text-primary-300 mb-2 relative z-10">Report-Its</p>
                    <p className="text-white font-extrabold text-2xl leading-tight relative z-10">The Eyes & Ears of Your Facility</p>
                </div>            
            </SwiperSlide>
            <SwiperSlide className="!h-[50vh] !w-[88vh]">
            {({ isActive }) => (
                <div className="new-linear p-8 rounded-2xl h-full">
                    <motion.video autoPlay={isActive ? true : false} muted loop src="https://player.vimeo.com/progressive_redirect/playback/896368531/rendition/720p/file.mp4?loc=external&log_user=0&signature=08037c5421030c4d8b1a0d4b8afdaf1eb0f9175a2677e0c9e31b6f1d7f14b539" className="absolute top-0 left-0 z-0 h-full w-full object-cover rounded-2xl"></motion.video>
                    <div className="absolute top-0 left-0 h-full w-full z-10 bg-gradient-to-b from-black to-black/10 rounded-2xl"></div>
                    <p className="uppercase tracking-wide font-bold text-sm text-primary-300 mb-2 relative z-10">Knowledge Center</p>
                    <p className="text-white font-extrabold text-2xl leading-tight relative z-10">Properly Trained and Prepared for Every&nbsp;Task</p>
                </div>
    )}
            </SwiperSlide>
            <SwiperSlide className="!w-[30vw]">
                <div className="relative new-linear p-8 rounded-2xl h-[50vh]">
                    <img src={auditsAI} alt="auditsAI" className="absolute top-0 left-0 w-full h-full object-cover rounded-2xl z-0"/>

                    <p className="uppercase tracking-wide font-bold text-sm text-primary-300 mb-2 relative z-20">Artificial Intelligence</p>
                    <p className="text-white font-extrabold text-2xl leading-tight relative z-20">Streamlining & Standardizing Audits with the Power of 4insite</p>
                </div>
            </SwiperSlide>
            <span className="z-90" slot="container-end">
                <SlideButton direction="prev" />
                <SlideButton direction="next" />
            </span>
            </>
        </Swiper>
        </div>
      </section>
    )
}