// TODO: Adjust sort to always sort from the billing state, so billing is always the secondary sort method. 

import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ArrowButton from "../monthly/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  cbreBilling as rootData,
  cbreSiteScorecards,
  cbreCustomerScorecards,
} from "./data";

require("highcharts/highcharts-more")(Highcharts);

Highcharts.setOptions({
  colors: [
    "#4AA8FF",
    "#734CFF",
    "#A231D2",
    "#DC00B6",
    "#FF7DB1",
    "#FF4334",
    "#FF6F19",
    "#C29201",
    "#E5C801",
    "#00FF8D",
    "#01CCA7",
    "#D0D5D9",
  ],
});

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

const colors = [
  "#4BA8FF",
  "#734CFF",
  "#A230D2",
  "#DB33B6",
  "#FF7DB1",
  "#FF4332",
  "#FF6E19",
  "#C29200",
  "#E0C942",
  "#02FA8E",
  "#02CCA6",
  "#02CCA6",
  "#48A4FA",
];

export default function Intro({ data, title }) {
  const [clientData, setClientData] = useState(
    consolidateScores(
      data || rootData,
      cbreCustomerScorecards,
      cbreSiteScorecards
    )
  );
  const [clients, setClients] = useState(reduceData(data || rootData));
  const [total, setTotal] = useState(
    clients[0].data.reduce((acc, curr) => acc + (curr.y || 0), 0)
  );

  function reduceData(data) {
    let sortedData = data.sort((a, b) => b.total_amount - a.total_amount);
    let outputData = sortedData.slice(0, 10);
    outputData.push({
      client_name: "Others",
      total_amount: sortedData
        .slice(10)
        .reduce((acc, curr) => acc + (curr.total_amount || 0), 0),
    });

    return [
      {
        data: outputData.map((client, i) => {
          return {
            name: client.client_name,
            y: client.total_amount,
            color: client.client_name === "Others" ? "#D1D5D9" : colors[i],
          };
        }),
      },
    ];
  }

  function consolidateScores(billingData, customerScorecards, siteScorecards) {
    let consolidatedData = [];
    billingData.forEach((client) => {
      let customer = customerScorecards.find(
        (scorecard) => scorecard.customer_id === client.client_id
      );
      client.sites.forEach((site) => {
        let scoreCardSite = siteScorecards.find(
          (scorecard) => scorecard.contract_id === site.id
        );
        site.overall_score = scoreCardSite?.overall_score;
        site.overall_score_list = scoreCardSite?.overall_score_list;
        site.owner_poc = scoreCardSite?.service_provider_poc;
        site.customer_poc = scoreCardSite?.customer_poc;
      });
      consolidatedData.push({
        ...client,
        overall_score: customer?.overall_score,
        overall_score_list: customer?.overall_score_list,
      });
    });
    return consolidatedData;
  }

  function BillingPieChart({ data }) {
    const options = {
      title: {
        text: "",
      },
      chart: {
        backgroundColor: "transparent",
        type: "pie",
        margin: [0, 0, 0, 0],
        height: "100%",
      },
      legend: {
        enabled: true,
        layout: "vertical",
        symbolRadius: 2,
        labelFormatter: function () {
          return `<div class="w-full flex items-center justify-between"><p>${
            this.name
          }</p><p>${this.percentage.toFixed(1)}%</p></div>`;
        },
        useHTML: true,
        itemStyle: {
          color: "white",
          fontWeight: "bold",
          fontSize: "14px !important",
        },
      },
      colors: colors,
      credits: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: "rgb(12, 15, 18)",
        outside: true,
        shadow: false,
        formatter: function () {
          return `${this.point.name}: ${this.percentage.toFixed(1)}%`;
        },
        style: { color: "#ffffff", fontSize: "16px" },
      },
      plotOptions: {
        pie: {
          innerSize: "70%",
          labels: {
            enabled: false,
          },
          showInLegend: false,
        },
        series: {
          dataLabels: {
            enabled: false,
          },
          borderRadius: 0,
        },
      },
      series: data,
    };

    return (
      <HighchartsReact
        className={"mx-auto"}
        style={{ height: "30vh", width: "30vh" }}
        highcharts={Highcharts}
        options={options}
      />
    );
  }

  return (
    <section className="w-full max-w-screen-2xl mx-auto py-24 relative z-20">
      <div className="relative flex flex-between w-full">
        <div className="w-full">
          <p className="newmonthly-tag text-primary-300">
            {title?.tag || "Overview"}
          </p>
          <p className="newmonthly-title mb-6 max-w-[32ch]">
          Our top ten accounts comprise<br/>80% of our $18M monthly spend.
            {/* <span>
              <ArrowButton
                label="View All Scorecards"
                href="https://app.4insite.com/site-scorecards"
                newTab={true}
              />
            </span> */}
          </p>
        </div>
        <div className="self-end px-6 py-4 bg-success text-neutral-900 rounded-lg mb-6 flex-shrink-0">
          <p className="text-center font-bold text-neutral-900 leading-none mb-2">
            Avg. Score
          </p>
          <p className="text-center text-4xl font-black text-neutral-900">
            4.65<span className="text-base text-neutral-900/50"> / 5</span>
          </p>
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-6 ">
        <ClientList data={clientData} total={total}/>
        <div className="">
          <div className="sticky top-8 new-linear rounded-xl">
            <div className="">
              <div className="flex items-center py-4 pl-4 col-span-2">
                <h3
                  data-ds2="h3"
                  className="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
                >
                  Clients
                </h3>
              </div>
            </div>
            <div className="h-1 w-full bg-primary-300 relative">
        <div className="absolute top-0 left-0 h-full w-full bg-primary-300 blur-sm"></div>
      </div>
            <div className="mx-auto pt-2 pb-4">
              <div
                className="mx-auto aspect-square p-4"
                style={{ width: "100%" }}
              >
                <BillingPieChart data={clients} />
              </div>
              <div className="w-full px-6 flex flex-col items-start justify-evenly">
                {clients[0].data.map((client, i) => {
                  return (
                    <div key={i} className="flex items-center mt-1 last:mb-2 w-full">
                      <div
                        className="w-4 h-4 rounded-full mr-2 flex-shrink-0"
                        style={{
                          backgroundColor: i === 10 ? "#D1D5D9" : colors[i],
                        }}
                      ></div>
                      <p className="text-white text-xs 2xl:text-sm font-roboto font-bold w-full">
                        {client.name}
                      </p>
                      <p className="text-white text-xs 2xl:text-sm font-roboto font-bold text-right w-full">
                        {((client.y / total) * 100).toFixed(1)}%
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ClientList({ data, total }) {
  const [clientData, setClientData] = useState(data || []);
  const [sortValue, setSortValue] = useState("total_amount");
  const [sortDirection, setSortDirection] = useState("desc");
  function sortByValue(key) {
    let sortedData;
  
    if (sortValue === key) {
      if (sortDirection === "desc") {
        setSortDirection("asc");
        sortedData = [...clientData].sort((a, b) => {
          return typeof a[key] === 'number' ? a[key] - b[key] : b[key].localeCompare(a[key]);
        });
      } else {
        sortedData = [...clientData].sort((a, b) => {
          return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
        });
        setSortDirection("desc");
      }
    } else {
      sortedData = [...clientData].sort((a, b) => {
        return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
      });
      setSortDirection("desc");
      setSortValue(key);
    }
  
    setClientData(sortedData);
  }

  return (
    <div className="col-span-3 overflow-hidden flex flex-col items-start justify-start">
        <div className="w-full rounded-xl overflow-hidden new-linear">
      <div className="w-full rounded-t-xl grid grid-cols-9 ">
        <div className="flex items-center my-4 pl-4 col-span-2">
          <h3
            data-ds2="h3"
            onClick={() => {
                sortByValue('client_name')
            }}
            className="whitespace-nowrap !cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Client
            <span className="ml-2 w-8">
              <FontAwesomeIcon
                icon={
                  sortValue !== "client_name"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "client_name"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
        <div className="flex items-center my-4 col-span-2">
          <h3
            data-ds2="h3"
            className="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Owner
          </h3>
        </div>
        <div className="flex items-center my-4 col-span-2">
          <h3
            data-ds2="h3"
            className="font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Customer POC
          </h3>
        </div>
        <div className="flex items-center my-4 col-span-2">
          <h3
            data-ds2="h3"
            onClick={() => {
              sortByValue("total_amount");
            }}
            className="whitespace-nowrap cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Billing
            <span className="ml-2 w-4">
              <FontAwesomeIcon
                icon={
                  sortValue !== "total_amount"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "total_amount"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
        <div className="flex items-center justify-center my-4">
          <h3
            data-ds2="h3"
            onClick={() => {
              sortByValue("overall_score");
            }}
            className="whitespace-nowrap cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
          >
            Scorecard
            <span className="ml-2 w-8">
              <FontAwesomeIcon
                icon={
                  sortValue !== "overall_score"
                    ? faCaretRight
                    : sortDirection === "desc"
                    ? faCaretDown
                    : faCaretUp
                }
                className={`${
                  sortValue === "overall_score"
                    ? "text-blue-400"
                    : "text-neutral-600 dark:text-neutral-500"
                }  mr-2`}
              />
            </span>
          </h3>
        </div>
      </div>
      <div className="h-1 w-full bg-primary-300 relative">
        <div className="absolute top-0 left-0 h-full w-full bg-primary-300 blur-sm"></div>
      </div>
      <div className=" w-full flex-grow flex flex-col items-around justify-evenly rounded-b-xl">
        {clientData.map((client, key) => {
          return <ClientRow client={client} key={client.client_id} index={key} total={total} />;
        })}
      </div> 
        </div>
    </div>
  );
}

function ClientRow({ client, index, total}) {
  const [showSites, setShowSites] = useState(false);
    const [color, setColor] = useState(index >= 10 ? '#D1D5D9' : colors[index])
  function handleShow() {
    let toShow = !showSites;
    setShowSites(toShow);
  }

  function fixPhoto(url) {
    if (url.indexOf('_t') < 0 && url.indexOf('asset') < 0) {
      var lastDot = url.lastIndexOf('.');
      url = "image/" + url.slice(0, lastDot) + '_t' + url.slice(lastDot);
    } else if (url.indexOf('asset') < 0) {
      url =  "image/" + url;
    }
    return url
  }

  function siteMap() {
    return client.sites.sort((a, b) => b.total_amount - a.total_amount).map((site, i) => {
        const date = new Date(site.site_start_date);
        const dateStr = date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            });
      return (
        <>
          <div className=" bg-black/20 border-b border-cyan-400/10 flex flex-row items-center 2xl:py-2 pl-4 col-span-2">
            <div className="w-4 2xl:h-10 mr-2 flex-shrink-0"></div>
            <div className="overflow-hidden pr-4">
              <p className="text-white text-sm font-roboto font-bold leading-none truncate">
                {site.name}
              </p>
              <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
                Contract Start: {dateStr}
              </p>
            </div>
          </div>
          <div className=" bg-black/20 border-b border-cyan-400/20 flex items-center 2xl:py-2 col-span-2">
            {site.owner_poc?.photo_url ? (
              <img
                // src={"https://cdn.4insite.com/" + (site.owner_poc.photo_url.indexOf('asset') >= 0 ? "" : "image/")+ site.owner_poc.photo_url}
                src={"https://cdn.4insite.com/" + fixPhoto(site.owner_poc.photo_url)}
                className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
              />
            ) : (
              <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
                {site.owner_poc &&
                  site.owner_poc?.name.split(" ")[0].slice(0, 1) +
                    site.owner_poc?.name.split(" ")[0].slice(0, 1)}
              </div>
            )}
            <p className="text-white text-sm font-roboto font-bold">
              {(site.owner_poc &&
                site.owner_poc.name ) ||
                "Unassigned"}
            </p>
          </div>
          <div className=" bg-black/20 border-b border-cyan-400/20 flex items-center 2xl:py-2 col-span-2">
            <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-xs 2xl:text-sm text-white font-black">
              {site.customer_poc &&
                site.customer_poc?.name?.slice(0, 1) +
                  site.customer_poc?.name.slice(0, 1)}
            </div>
            <p className="text-white text-sm font-roboto font-bold">
              {(site.customer_poc &&
                site.customer_poc?.name) ||
                "Unassigned"}
            </p>
          </div>
          <div className=" bg-black/20 border-b border-cyan-400/20 flex items-center 2xl:py-2 col-span-2">
            <p className="text-white text-sm font-roboto font-bold">
              {/* {(document.referrer === "https://app.4insite.com/" ||
                document.location.href.indexOf("localhost") > -1) &&
                site.total_amount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })} */}
                {site.total_amount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
            </p>
            <div className="w-full flex items-center justify-end">
            <div className="w-24">
                <div className="h-6 rounded-lg overflow-hidden chart-in" style={{
                    width: site.total_amount / client.total_amount * 100 + "%",
                    background: color
                }}></div>
            </div>
            </div>
          </div>
          <div className={` bg-black/20 border-b border-cyan-400/20 flex items-center justify-center 2xl:py-2 `}>
            <p
              className={`w-16 py-1 text-center text-neutral-900 text-sm 2xl:text-lg font-black rounded-lg ${
                site.overall_score >= 4
                  ? "bg-success"
                  : "bg-failure"
              }`}
            >
              {site.overall_score || "--"}
            </p>
          </div>
        </>
      );
    });
  }

  return (
    <div
      key={client.client_id}
      className="w-full grid grid-cols-9 group"
    >
      <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  group-hover items-center 2xl:py-2 pl-4 col-span-2">
        <FontAwesomeIcon onClick={handleShow} icon={showSites ? faCaretDown : faCaretRight} className="text-white cursor-pointer pr-2 py-2 w-4" />
        <button
          onClick={(el) => {
            handleShow();
          }}
        >
          {client.logo_url && client.logo_url.indexOf("https:") < 0 ? (
            <img
              src={"https://cdn.4insite.com/" + client.logo_url}
              className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
            />
          ) : client.logo_url && client.logo_url.indexOf("https:") > -1 ? (
            <img
              src={client.logo_url}
              className="h-6 w-6 p-1 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
            />
          ) : (
            <div className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 bg-cyan-800/20 flex items-center justify-center text-xl text-white font-black">
              {client.client_name.slice(0, 1)}
            </div>
          )}
        </button>
        <div>
          <p className="text-white text-sm font-roboto font-bold leading-none">
            {client.client_name}
          </p>
          <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
            {client.program_count === 1
              ? "1 Program"
              : `${client.program_count} Programs`}
          </p>
        </div>
      </div>
      <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2 col-span-2">
        {client.owner_poc?.photo_url ? (
          <img
            // src={"https://cdn.4insite.com/" + (client.owner_poc.photo_url.indexOf('asset') >= 0 ? "" : "image/") + client.owner_poc.photo_url}
            src={"https://cdn.4insite.com/" + fixPhoto(client.owner_poc.photo_url)}
            className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 object-cover border-2 border-blue-500"
          />
        ) : (
          <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-sm text-white font-black">
            {client.owner_poc &&
              client.owner_poc?.first_name?.slice(0, 1) +
                client.owner_poc?.last_name.slice(0, 1)}
          </div>
        )}
        <p className="text-white text-sm font-roboto font-bold">
          {(client.owner_poc &&
            client.owner_poc?.first_name + " " + client.owner_poc?.last_name) ||
            "Unassigned"}
        </p>
      </div>
      <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2 col-span-2">
        <div className="h-6 w-6 2xl:w-8 2xl:h-8 rounded-full mr-2 bg-blue-500 flex items-center justify-center text-xs 2xl:text-sm text-white font-black">
          {client.customer_poc &&
            client.customer_poc?.first_name?.slice(0, 1) +
              client.customer_poc?.last_name.slice(0, 1)}
        </div>
        <p className="text-white text-sm font-roboto font-bold">
          {(client.customer_poc &&
            client.customer_poc?.first_name +
              " " +
              client.customer_poc?.last_name) ||
            "Unassigned"}
        </p>
      </div>
      <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center 2xl:py-2 col-span-2">
        <p className="text-white text-sm font-roboto font-bold">
          {/* {(document.referrer === "https://app.4insite.com/" ||
            document.location.href.indexOf("localhost") > -1) &&
            client.total_amount?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })} */}
            {client.total_amount?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
            })}
        </p>
        <div className="w-full flex items-center justify-end">
            <div className="w-24">
                <div className="h-6 rounded-lg overflow-hidden chart-in" style={{
                    width: client.total_amount / total * 100 + "%",
                    background: color
                }}></div>
            </div>
        </div>
      </div>
      <div className={`flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center justify-center 2xl:py-2 `}>
        <p
          className={`w-16 py-1 text-center text-neutral-900 text-sm 2xl:text-lg font-black rounded-lg ${
            client.overall_score >= 4
              ? "bg-success"
              : "bg-failure"
          }`}
        >
          {client.overall_score || "--"}
        </p>
      </div>
      {showSites && siteMap()}
    </div>
  );
}
