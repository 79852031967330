import React, {useState, useEffect, useRef } from 'react';
import Modal from './components/modal.js';
import './scrollbar.css';
import './index.css';
import Fullpage from './components/fullpage/FullPage';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import {
	BrowserRouter,
	Route,
	Routes,
	useNavigate,
	Link, useParams
  } from "react-router-dom";
import AlternateLayout from './alternate/alternate.jsx';
import MonthlyReport from './components/monthly/monthly.jsx';
import NewMonthlyHome from './components/newMonthly/home.jsx';
import Toolkit from './components/toolkit/toolkit';
// import RFI from './components/RFI/rfi.js';
import * as Prismic from '@prismicio/client'
import { set } from 'date-fns';

export const ModalContext = React.createContext();

var local = "0 0 0 0";
var height = "unset";
var overflow = "unset";

const apiEndpoint = "https://sbmtest.cdn.prismic.io/api/v2";
const accessToken =
  "MC5ZQzdlYlJJQUFDRUFkWkYy.Su-_vVx677-977-977-977-9b3ckFifvv73vv71B77-977-9M--_vVHvv73vv70vAO-_vWQWNA7vv73vv70"; // This is where you would add your access token for a Private repository
export const Client = Prismic.createClient(apiEndpoint, {accessToken});


function App() {
	return (
		<BrowserRouter>
			<Root></Root>
		</BrowserRouter>
	)
}

function Root() {
	const [client, setClient] = useState([]);
	const [site, setSite] = useState(null);
	const [show, setShow] = useState(false);
	const [jllMonthly, setJLLMonthly] = useState(false);
	const [cbreMonthly, setCBREMonthly] = useState(false);
	const [cbreLS, setCBRELS] = useState(false);
	const [articles, setArticles] = useState([])
	const [iframeURL, setIframeURL] = useState(null);
	const [theme, setTheme] = useState('light');
	const [modalContent, setModalContent] = useState(null);
	const [specifics, setSpecifics] = useState(null);
	const nav = useRef(null);
	const [transitions, setTransitions] = useState(null);
	const [queryString] = useState(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const src = urlParams.get("src")
		  ? JSON.parse(atob(urlParams.get("src")))
		  : null;
		const clientIDs = urlParams.get("Client")
		  ? urlParams.get("Client").split(",")
		  : src !== null
		  ? src.C
		  : [];
		  const siteIDs = urlParams.get("Site")
		  ? urlParams.get("Site").split(",")
		  : src === null
		  ? null
		  : src.S[0] === null
		  ? null
		  : src.S;
		const theme = urlParams.get('theme');
		setTheme((theme || 'dark').toLowerCase())
		setClient(clientIDs);
		setSite(siteIDs);
		setJLLMonthly(jllReport(clientIDs));
		setCBREMonthly(cbreReport(clientIDs));
		setCBRELS(cbreLSReport(clientIDs));
		return queryString;
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (client === null) return;
		// console.log(compressToEncodedURIComponent('84'))
		refreshCache();
		fetchAndResolveData();
		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape' && e.shiftKey) {
				e.preventDefault();
				console.log('shift escape!')
				window.location.reload()
			}
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client])

	// Function to Resolve Iframe url caching
	function refreshCache() {
		const now = new Date();
		const queryString = window.location.search;
		if (queryString.indexOf('now') < 0 && queryString.length ) {
			navigate(window.location.pathname + window.location.search + '&now=' + now.getTime());
		} else if ( queryString.length === 0 ) {
			navigate(window.location.pathname + window.location.search + '?now=' + now.getTime());

		}
	}

	function specificCombiner(clients, data) {
		let output = clients;
		return output;
	  }

	async function fetchAndResolveData() {
		const response = await Client.getByType('qbr-hero', {
			orderings: [
				{field:"my.qbr-hero.publication_date", direction: 'desc'},
				{field:"document.last_publication_date", direction: 'desc'}
			], 
			pageSize:5
		  })
		  if (response) {
			setArticles(response.results.map(a => a.data))
			}
		let txData;
		if (window.location.origin !== 'https://sbmmgmt.com') {
			txData = await fetch('https://sbmqbrassets.s3-us-west-1.amazonaws.com/transitions.json').then(r => r).then(r => r.json());
		}
		if (client.length === 1 && client.some(r => txData.txClients.includes(r))) {
			// const now = new Date();
			// 	if (queryString.indexOf('now') < 0) {
			// 		navigate('/tx/' + window.location.search + '&now=' + now.getTime())
			// 	} else {
			// 		navigate('/tx/' + window.location.search)
			// 	}
			setTransitions(txData);
		}
		// if (client.length === 1 && client.indexOf('391') >= 0) {
		// 	// Delta 391 punt
		// 	// navigate('https://sbmmgmt.s3-us-west-1.amazonaws.com/index.html')
		// 	window.location = 'https://sbmmgmt.s3-us-west-1.amazonaws.com/index.html';
		// }
		const specificPreData = await Promise.all(
			client.map(async c => {
				let client = await fetch(
					"https://sbmqbrassets.s3.us-west-1.amazonaws.com/specific_data/client_" +
					c +
					".json",
					{
					referrerPolicy: "no-referrer-when-downgrade",
					body: null,
					method: "GET",
					mode: "cors",
					cache: "reload"
					}
				);
				if (client.ok) {
					return client.json();
				}
			})
		  );
		  let specificSiteData = await specificPreData
			.filter(n => n !== undefined)
			.flat();
		  if (site) {
			specificSiteData.forEach(c => {
			  c.sites = c.sites.filter(s => {
				return site.some(element => {
				  return element * 1 === s.site_id;
				});
			  });
			});
			specificSiteData = specificSiteData.filter(c => c.sites.length !== 0);
		  }
		  
	  
		  if (client && !site) {
			let specificData = await specificCombiner(
			  specificSiteData,
			  client
			);
			setSpecifics(specificData);
		  } else if (site) {
			let specificData = await specificCombiner(
			  specificSiteData,
			  client
			);
			setSpecifics(specificData);
		  }
	}

	function showModal(el, url, content) {
		setShow(true);
		setIframeURL(url);
		setModalContent(content);
		let body = document.getElementsByTagName('body')[0];
		body.classList.add('stop');
	}


	function onClose(el) {
		setShow(false);
		setIframeURL(null);
		setModalContent(null);
		let body = document.getElementsByTagName('body')[0];
		body.classList.remove('stop'); 
	}

	function jllReport(client) {
		// const jllClients = '11,31,47,156,81,67,224,628,161,2143,391,315,154,29,137,129,1315,158,166,432,168,49,317,634,257,258,286,298,183,71,275,486,1783,189,225,102,22,87,131,135'.split(',');
		const jllClients = '11,31,47,156,81,67,224,628,161,2143,391,315,154,29,137,129,1315,158,166,432,168,49,317,634,257,258,286,183,71,275,486,1783,189,225,102,22,87,131,135'.split(',');
		if (!client) return false;

		if (client[0] === 'jll') return true;

		if (Math.abs(client.length - jllClients.length) >= 5) {
			return false;
		  }

		  let matching = 0;
		
		  for (let i = 0; i < client.length; i++) {
			if (jllClients.indexOf(client[i]) >= 0) {
			  matching++;
			}
		  }

		  console.log(matching, jllClients.length)

		  if (matching / jllClients.length >= 0.8) {
			return true;
		  } else {
			return false
		  }

		  
		//   navigate('/monthly/' + window.location.search)
		  return true;
	}
	function cbreReport(client) {
		// const jllClients = '11,31,47,156,81,67,224,628,161,2143,391,315,154,29,137,129,1315,158,166,432,168,49,317,634,257,258,286,298,183,71,275,486,1783,189,225,102,22,87,131,135'.split(',');
		const cbreClients = '75,448,280,281,37,253,27,269,1018,219,2154,90,2145,1547,1814,1317,352,468,2126,165,273,72,484,302,315,328,137,33,34,470,1975,63,220,267,1422,8,270,5,477,438,287,252,311,504,485,138,180,921,1648,303,115,803,2155,87,1649'.split(',');
		if (!client) return false;

		if (client[0] === "cbre") return true;

		if (Math.abs(client.length - cbreClients.length) >= 30) {
			return false;
		  }

		  let matching = 0;
		
		  for (let i = 0; i < client.length; i++) {
			if (cbreClients.indexOf(client[i]) >= 0) {
			  matching++;
			}
		  }

		  console.log(matching, cbreClients.length)

		  if (matching / cbreClients.length >= 0.5) {
			return true;
		  } else {
			return false
		  }

		  
		//   navigate('/monthly/' + window.location.search)
		  return true;
	}
	function cbreLSReport(client) {
		// const jllClients = '11,31,47,156,81,67,224,628,161,2143,391,315,154,29,137,129,1315,158,166,432,168,49,317,634,257,258,286,298,183,71,275,486,1783,189,225,102,22,87,131,135'.split(',');
		const cbreClients = '75,253,273,72,33,470,220,267,477,311,180,921'.split(',');
		if (!client) return false;

		if (client[0] === "cbreLS") return true;

		if (Math.abs(client.length - cbreClients.length) >= 30) {
			return false;
		  }

		  let matching = 0;
		
		  for (let i = 0; i < client.length; i++) {
			if (cbreClients.indexOf(client[i]) >= 0) {
			  matching++;
			}
		  }

		  if (matching / cbreClients.length >= 0.5) {
			return true;
		  } else {
			return false
		  }

		  
		//   navigate('/monthly/' + window.location.search)
		  return true;
	}

	return (
		<ModalContext.Provider value={showModal}>

		<div className={`${theme}`}
			style={{margin: local}}
		>

			{local !== "0 0 0 0" ? (<div style={{position: 'fixed', top: '0px', left: '0', height: '60px', width: '100%', background: '#212032'}}></div>) : null}
		<div className={`App bg-neutral-100 dark:bg-neutral-900`} style={{height:height, overflow: overflow, position: 'relative'}}>
			
			{client.length > 0  && <div ref={nav} className=" absolute z-50 mx-auto flex flex-row justify-center items-center bg-bg nav w-full">
				<div className="w-full flex flex-row items-center justify-center py-2 px-2 gap-6 bg-white dark:bg-neutral-800">
				{!jllMonthly && !cbreMonthly && <Link to={"/" + window.location.search} className="font-lato font-bold text-sm dark:text-white">QBR</Link>}
				{jllMonthly && <Link to={"/monthly/" + window.location.search} className="font-lato font-bold text-sm dark:text-white">Monthly</Link>}
				{cbreMonthly && <Link to={"/" + window.location.search} className="font-lato font-bold text-sm dark:text-white">Review</Link>}
				{transitions && <Link to={"/tx/" + window.location.search} className="font-lato font-bold text-sm dark:text-white">Transitions</Link>}
				{/* <a className="font-lato font-bold text-sm dark:text-white" href='https://sbmmobilefeatures.s3.us-west-1.amazonaws.com/index.html' target="_blank" rel="noreferrer" referrerPolicy='noreferrer'>Associate App</a>
				<a className="font-lato font-bold text-sm dark:text-white" href='https://sbmmobilefeatures.s3.us-west-1.amazonaws.com/manager20.html' target="_blank" rel="noreferrer" referrerPolicy='noreferrer'>Manager App</a> */}
				<Link to={"/toolkit/" + window.location.search} className="font-lato font-bold text-sm dark:text-white">Resources</Link>
				</div>
			</div>}
			<Routes>
				<Route path="/tx/:client/*" element={<AlternateLayout clientID={client}/>}></Route>
				<Route path="/tx/*" element={<AlternateLayout clientID={client}/>}></Route>
				<Route path="/transition/:client" element={<AlternateLayout clientID={client} setShow={setShow} showModal={showModal} show={show}/>}></Route>
				<Route path="/monthly/*" element={<MonthlyReport selection={`${jllMonthly ? 'jll' : ''}${cbreMonthly ? 'cbre' : ''}`} showModal={showModal} show={show}/>}></Route>
				<Route path="/toolkit/" element={<Toolkit showModal={showModal} items={articles} />}></Route>
				<Route path="/summary/:clientId" loader={({ params }) => {console.log(params.teamId); }} element={<Test  showModal={showModal} />}></Route>
				{/* {!jllMonthly && !cbreMonthly && !cbreLS && <Route path="/*" element={specifics && <div className="z-10 relative"><Fullpage theme={theme} data={specifics} clients={client} showModal={showModal} /></div>}></Route>}
				{jllMonthly && <Route path="/*" element={<MonthlyReport selection={"jll"} showModal={showModal} show={show}/>}></Route>}
				{cbreMonthly && <Route path="/*" element={<NewMonthlyHome selection={"cbre"} setShow={setShow} showModal={showModal} show={show}/>}></Route>}
				{cbreLS && <Route path="/*" element={<MonthlyReport selection={"cbreLS"} setShow={setShow} showModal={showModal} show={show}/>}></Route>} */}
				<Route path="/cbre/" element={<NewMonthlyHome selection={"cbre"} setShow={setShow} showModal={showModal} show={show}/> }></Route>
				<Route path="/cbre2/" element={<MonthlyReport selection={"cbre"} showModal={showModal} show={show}/>}></Route>
				<Route path="/jll/" element={<MonthlyReport selection={"jll"} showModal={showModal} show={show}/>}></Route>
				<Route path="/cbreLS/" element={<MonthlyReport selection={"cbreLS"} setShow={setShow} showModal={showModal} show={show}/>}></Route>
			</Routes>
		{show && (iframeURL || modalContent) && (
			<Modal
			  show={show}
			  content={iframeURL}
			  close={onClose}
			  modalContent={modalContent}
			/>
		  )}
		</div>
		<div className="scrollhide"></div>
		</div>
		</ModalContext.Provider>
	);
}

export default App;


function Test({showModal}) {
	const [specifics, setSpecifics] = useState(null);
	let params = useParams();
	const theme = params.theme || 'dark';
	const client = decompressFromEncodedURIComponent(params.clientId).split(',');
	useEffect(() => {
		async function fetchAndResolveData() {
			const specificPreData = await Promise.all(
				client.map(async c => {
					let client = await fetch(
						"https://sbmqbrassets.s3.us-west-1.amazonaws.com/specific_data/client_" +
						c +
						".json",
						{
						referrerPolicy: "no-referrer-when-downgrade",
						body: null,
						method: "GET",
						mode: "cors",
						cache: "reload"
						}
					);
					if (client.ok) {
						return client.json();
					}
				})
			  );
			  let specificSiteData = await specificPreData
				.filter(n => n !== undefined)
				.flat();
				setSpecifics(specificSiteData);
		}
		fetchAndResolveData();
	}, [client])

	return (
		<div className="z-10 relative"><Fullpage theme={theme} data={[]} clients={client} showModal={showModal} /></div>
	)
}

//EwRgLArEA