import React, { useRef, useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { motion } from "framer-motion";
import MonthlySwitch from "./monthlyswitch";
import './monthly.scss';

export function Section({children}) {
    return (
        <div className="section">
          <div className="w-full h-full flex items-center justify-center">
            {children}
          </div>
        </div>
    )
}

function Greeting() {
    const currentHour = new Date().getHours();
    let greeting;
  
    if (currentHour < 12) {
      greeting = 'Good Morning';
    } else if (currentHour < 17) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }
  
    return <h1>{greeting}</h1>;
  }

export default function MonthlyReport({Children, showModal, data, show, selection}) {

//   useEffect(() => {
//     if (show) {
//       document.body.style.overflow = 'hidden';
//       document.body.style.paddingRight = '10px';
//     } else {
//       document.body.style.overflow = 'unset';
//       document.body.style.paddingRight = 'unset';
//     }
//   }, [show])

  function handleSelection(selection) {
    if (selection === "jll") {
      return (
        <>  
                <MonthlySwitch data={{param:"top10"}}/>
                <MonthlySwitch data={{param:"scorecards"}}/>
                <MonthlySwitch data={{param:"audits"}}/>
                <MonthlySwitch data={{param:"complaints"}}/>
                <MonthlySwitch data={{param:"csat"}}/>
                <MonthlySwitch data={{param:"turnover"}}/>
                <MonthlySwitch data={{param:"training"}}/>
                <MonthlySwitch data={{param:"recordables"}}/>
                <MonthlySwitch data={{param:"goodcatches"}}/>
                <MonthlySwitch data={{param:"highlights"}} showModal={showModal}/>
        </>
      )
    } else if (selection === "cbre" || selection === "cbreLS") {
      return (
        <>
                <MonthlySwitch show={show} data={{param:"hero", data: [
    {
        "client_id": 27,
        "client_name": "Broadcom (CBRE)",
        "logo_url": "assets/1605624362.6945145_4991646015791417645684447_origin_t.png",
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": "Luna Duarte",
        "customer_poc_full_name": "Luke Armbruster",
        "owner_poc": {
            "id": 21850,
            "last_name": "Duarte",
            "photo_url": "assets/1597765547.152738_Lunaphoto_t.jpg",
            "first_name": "Luna"
        },
        "customer_poc": {
            "id": 35761,
            "last_name": "Armbruster",
            "photo_url": "",
            "first_name": "Luke"
        },
        "programs": null
    },
    {
        "client_id": 115,
        "client_name": "Uber (CBRE) (CR)",
        "logo_url": "assets/1607627456.7434294_UberCR_t.jpg",
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": "John Jaime Rincón",
        "customer_poc_full_name": "Carlos Ortiz ",
        "owner_poc": {
            "id": 16519,
            "last_name": "Rincón",
            "photo_url": "assets/rcc6afba07d254bf89928b722f362caba_JR2023_t.jpg",
            "first_name": "John Jaime"
        },
        "customer_poc": {
            "id": 22368,
            "last_name": "",
            "photo_url": "",
            "first_name": "Carlos Ortiz"
        },
        "programs": null
    },
    {
        "client_id": 165,
        "client_name": "DXC (CR) (CBRE)",
        "logo_url": "assets/1607369241.5121918_DXC_t.png",
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": "John Jaime Rincón",
        "customer_poc_full_name": "Gerardo Porras  Porras ",
        "owner_poc": {
            "id": 16519,
            "last_name": "Rincón",
            "photo_url": "assets/rcc6afba07d254bf89928b722f362caba_JR2023_t.jpg",
            "first_name": "John Jaime"
        },
        "customer_poc": {
            "id": 36336,
            "last_name": "Porras ",
            "photo_url": "",
            "first_name": "Gerardo Porras "
        },
        "programs": null
    },
    {
        "client_id": 287,
        "client_name": "Refinitiv (CR) (CBRE)",
        "logo_url": "assets/1607637238.930703_Refinitiv_t.jpg",
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": "John Jaime Rincón",
        "customer_poc_full_name": "Luis  Villareal ",
        "owner_poc": {
            "id": 16519,
            "last_name": "Rincón",
            "photo_url": "assets/rcc6afba07d254bf89928b722f362caba_JR2023_t.jpg",
            "first_name": "John Jaime"
        },
        "customer_poc": {
            "id": 80375,
            "last_name": "Villareal ",
            "photo_url": "",
            "first_name": "Luis "
        },
        "programs": null
    },
    {
        "client_id": 448,
        "client_name": "Abzena (CBRE)",
        "logo_url": null,
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": "Juan Rodriguez McLean",
        "customer_poc_full_name": "Mike  Boylan",
        "owner_poc": {
            "id": 4526,
            "last_name": "Rodriguez McLean",
            "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
            "first_name": "Juan"
        },
        "customer_poc": {
            "id": 70499,
            "last_name": "Boylan",
            "photo_url": "",
            "first_name": "Mike "
        },
        "programs": null
    },
    {
        "client_id": 484,
        "client_name": "Exxon Mobil (CBRE)",
        "logo_url": null,
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Nelson Hernandez",
        "owner_poc": null,
        "customer_poc": {
            "id": 53984,
            "last_name": "Hernandez",
            "photo_url": "",
            "first_name": "Nelson"
        },
        "programs": null
    },
    {
        "client_id": 1814,
        "client_name": "Cordova 83 Properties",
        "logo_url": null,
        "program_count": null,
        "total_amount": null,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": null
    },
    {
        "client_id": 1422,
        "client_name": "Meta (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 2752848.04,
        "owner_poc_full_name": "Luna Duarte",
        "customer_poc_full_name": "Alex Worth",
        "owner_poc": {
            "id": 21850,
            "last_name": "Duarte",
            "photo_url": "assets/1597765547.152738_Lunaphoto_t.jpg",
            "first_name": "Luna"
        },
        "customer_poc": {
            "id": 80336,
            "last_name": "Worth",
            "photo_url": "",
            "first_name": "Alex"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 2752848.04
            }
        ]
    },
    {
        "client_id": 72,
        "client_name": "Eli Lilly (CBRE)",
        "logo_url": "assets/1605311746.7267878_Artboard8_t.png",
        "program_count": 2,
        "total_amount": 1385961.68,
        "owner_poc_full_name": "Sara Brake",
        "customer_poc_full_name": "Rich Garrison",
        "owner_poc": {
            "id": 8763,
            "last_name": "Brake",
            "photo_url": "assets/e4d1ed9e32874b6e86f60f3f78d4d66d_1584098197.2852464_sara_t_t.jpg",
            "first_name": "Sara"
        },
        "customer_poc": {
            "id": 24953,
            "last_name": "Garrison",
            "photo_url": "",
            "first_name": "Rich"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 1379861.68
            },
            {
                "id": 3,
                "name": "GMP",
                "total_amount": 6100
            }
        ]
    },
    {
        "client_id": 267,
        "client_name": "Merck (CBRE)",
        "logo_url": "assets/1608224240.933131_1605311478.4740288_Artboard5_t_t.png",
        "program_count": 8,
        "total_amount": 1382847.05,
        "owner_poc_full_name": "Jenna Rosa",
        "customer_poc_full_name": "Mike Basalyga",
        "owner_poc": {
            "id": 37079,
            "last_name": "Rosa",
            "photo_url": "assets/b845d0960f214a4b8df444745692370c_2023Headshot_t.jpg",
            "first_name": "Jenna"
        },
        "customer_poc": {
            "id": 38084,
            "last_name": "Basalyga",
            "photo_url": "",
            "first_name": "Mike"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 988344.47
            },
            {
                "id": 8,
                "name": "Mat Services",
                "total_amount": 7853.21
            },
            {
                "id": 135,
                "name": "Lab Services",
                "total_amount": 2844.47
            },
            {
                "id": 158,
                "name": "Pest Control",
                "total_amount": 780.16
            },
            {
                "id": 172,
                "name": "Supplier Management Fee",
                "total_amount": 6208.33
            },
            {
                "id": 196,
                "name": "Uniforms",
                "total_amount": 59737.78
            },
            {
                "id": 204,
                "name": "Hazardous Waste",
                "total_amount": 315711.21
            },
            {
                "id": 325,
                "name": "IFM Managed Services",
                "total_amount": 1367.42
            }
        ]
    },
    {
        "client_id": 87,
        "client_name": "Wells Fargo",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 1022904.83,
        "owner_poc_full_name": "Miriam Ledezma",
        "customer_poc_full_name": "Teresa Braithwait",
        "owner_poc": {
            "id": 11598,
            "last_name": "Ledezma",
            "photo_url": "assets/efa8f63b84e44389b6561fe2e18104a2_M.LedezmaPicture_t.jpg",
            "first_name": "Miriam"
        },
        "customer_poc": {
            "id": 81287,
            "last_name": "Braithwait",
            "photo_url": "",
            "first_name": "Teresa"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 1019861.67
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 3043.16
            }
        ]
    },
    {
        "client_id": 180,
        "client_name": "Takeda (CBRE)",
        "logo_url": "assets/1605623711.8547332_ogp_logo_t.jpg",
        "program_count": 2,
        "total_amount": 905264.16,
        "owner_poc_full_name": "Luis Cabrera",
        "customer_poc_full_name": "Gualter Almeida",
        "owner_poc": {
            "id": 5102,
            "last_name": "Cabrera",
            "photo_url": "assets/1577824247.6788175_LuisCabreraHeadshot_07252019_t.jpg",
            "first_name": "Luis"
        },
        "customer_poc": {
            "id": 82339,
            "last_name": "Almeida",
            "photo_url": "",
            "first_name": "Gualter"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 843137.2
            },
            {
                "id": 3,
                "name": "GMP",
                "total_amount": 62126.96
            }
        ]
    },
    {
        "client_id": 5,
        "client_name": "Nvidia (CBRE)",
        "logo_url": "assets/1605311860.3280911_Artboard10_t.png",
        "program_count": 5,
        "total_amount": 667981.2,
        "owner_poc_full_name": "Stuart Kelloff",
        "customer_poc_full_name": "Arman Bagdasaryan",
        "owner_poc": {
            "id": 84308,
            "last_name": "Kelloff",
            "photo_url": "assets/r9caff6d0b6b04667bbfebdad11191ea7_StuKelloffsProfilePic_t.png",
            "first_name": "Stuart"
        },
        "customer_poc": {
            "id": 22290,
            "last_name": "Bagdasaryan",
            "photo_url": "",
            "first_name": "Arman"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 661843.79
            },
            {
                "id": 5,
                "name": "Floor Care",
                "total_amount": 2200.24
            },
            {
                "id": 6,
                "name": "Recycle/Diversion",
                "total_amount": 715.19
            },
            {
                "id": 136,
                "name": "Event Support",
                "total_amount": 2059.48
            },
            {
                "id": 138,
                "name": "General Maintenance",
                "total_amount": 1162.5
            }
        ]
    },
    {
        "client_id": 253,
        "client_name": "Biogen (CBRE)",
        "logo_url": "assets/1605623761.388814_Biogen01_t.png",
        "program_count": 2,
        "total_amount": 633048.02,
        "owner_poc_full_name": "Matthew Borsick",
        "customer_poc_full_name": "Lee Johnson",
        "owner_poc": {
            "id": 73885,
            "last_name": "Borsick",
            "photo_url": "assets/e885d28c1ee54708a7b4e945a938bc60_BorsickProfessionProfilePicture_t.jpg",
            "first_name": "Matthew"
        },
        "customer_poc": {
            "id": 49746,
            "last_name": "Johnson",
            "photo_url": "",
            "first_name": "Lee"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 632975.8
            },
            {
                "id": 3,
                "name": "GMP",
                "total_amount": 72.22
            }
        ]
    },
    {
        "client_id": 8,
        "client_name": "Microsoft (CBRE)",
        "logo_url": "assets/1605312306.0531929_Artboard4_t.png",
        "program_count": 2,
        "total_amount": 620029.2,
        "owner_poc_full_name": "Shane Follmann",
        "customer_poc_full_name": "CBRE Facility MGMT",
        "owner_poc": {
            "id": 3744,
            "last_name": "Follmann",
            "photo_url": "assets/1587505724.8169758_ShaneFollmann_t.jpg",
            "first_name": "Shane"
        },
        "customer_poc": {
            "id": 74997,
            "last_name": "Facility MGMT",
            "photo_url": "",
            "first_name": "CBRE"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 568605.96
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 51423.24
            }
        ]
    },
    {
        "client_id": 468,
        "client_name": "Deutsche Bank",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 563093.37,
        "owner_poc_full_name": "Sarah Behrman",
        "customer_poc_full_name": "John Papadopoulos",
        "owner_poc": {
            "id": 85087,
            "last_name": "Behrman",
            "photo_url": "assets/311b982448d24b42ab6b2bf771685a0b_SarahBehrmanpic_t.jfif",
            "first_name": "Sarah"
        },
        "customer_poc": {
            "id": 86307,
            "last_name": "Papadopoulos",
            "photo_url": "",
            "first_name": "John"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 553430.55
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 9662.82
            }
        ]
    },
    {
        "client_id": 220,
        "client_name": "McKesson (CBRE)",
        "logo_url": "assets/1605623664.6579034_MCK_Logo_150dpi_t.jpg",
        "program_count": 1,
        "total_amount": 493540.55,
        "owner_poc_full_name": "Zachary Shock",
        "customer_poc_full_name": "Marissa Anderson",
        "owner_poc": {
            "id": 53748,
            "last_name": "Shock",
            "photo_url": "assets/re414fb84d6864b0c89361fa3f7846b16_Me_t.jpg",
            "first_name": "Zachary"
        },
        "customer_poc": {
            "id": 83641,
            "last_name": "Anderson",
            "photo_url": "",
            "first_name": "Marissa"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 493540.55
            }
        ]
    },
    {
        "client_id": 270,
        "client_name": "Novartis (CBRE)",
        "logo_url": "assets/1605623818.436738_novartislogo775x500_t.png",
        "program_count": 1,
        "total_amount": 490271.72,
        "owner_poc_full_name": "Michael Barry",
        "customer_poc_full_name": "John Courville",
        "owner_poc": {
            "id": 8352,
            "last_name": "Barry",
            "photo_url": "assets/1610682875.1928341_worksnip2_t.JPG",
            "first_name": "Michael"
        },
        "customer_poc": {
            "id": 21318,
            "last_name": "Courville",
            "photo_url": "",
            "first_name": "John"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 490271.72
            }
        ]
    },
    {
        "client_id": 303,
        "client_name": "Uber (CBRE)",
        "logo_url": "assets/1607636171.7063708_Uber_t.jpg",
        "program_count": 2,
        "total_amount": 451495.17,
        "owner_poc_full_name": "Jose Esquivel Calderon",
        "customer_poc_full_name": "Eddie Solaeche",
        "owner_poc": {
            "id": 83565,
            "last_name": "Esquivel Calderon",
            "photo_url": "assets/r9ac5ef03f70b45d98e38df6fa721a0e1_PXL_20230328_211357723.PORTRAIT3_t.jpg",
            "first_name": "Jose"
        },
        "customer_poc": {
            "id": 49391,
            "last_name": "Solaeche",
            "photo_url": "",
            "first_name": "Eddie"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 449628.17
            },
            {
                "id": 130,
                "name": "Window Cleaning",
                "total_amount": 1867
            }
        ]
    },
    {
        "client_id": 219,
        "client_name": "Cigna (CBRE)",
        "logo_url": "assets/1605623896.264524_CignaHealthInsurancelogoincanada_t.png",
        "program_count": 2,
        "total_amount": 386023.57,
        "owner_poc_full_name": "Andrew Smith",
        "customer_poc_full_name": "Michael Llloyd",
        "owner_poc": {
            "id": 11682,
            "last_name": "Smith",
            "photo_url": "assets/1587576767.6580079_AndySmithFinal_t.png",
            "first_name": "Andrew"
        },
        "customer_poc": {
            "id": 36058,
            "last_name": "Llloyd",
            "photo_url": "",
            "first_name": "Michael"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 348392.78
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 37630.79
            }
        ]
    },
    {
        "client_id": 137,
        "client_name": "GM",
        "logo_url": "assets/1605312005.83312_GM_logo_t.jpg",
        "program_count": 1,
        "total_amount": 370411.29,
        "owner_poc_full_name": "Pauline Schermerhorn",
        "customer_poc_full_name": "Dave Painter",
        "owner_poc": {
            "id": 9243,
            "last_name": "Schermerhorn",
            "photo_url": "5e139590-126b-c205-d5b8-91b289a5299d.png",
            "first_name": "Pauline"
        },
        "customer_poc": {
            "id": 89065,
            "last_name": "Painter",
            "photo_url": "",
            "first_name": "Dave"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 370411.29
            }
        ]
    },
    {
        "client_id": 1975,
        "client_name": "JPMC (CBRE)",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 291562.39,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Amy Esteves",
        "owner_poc": null,
        "customer_poc": {
            "id": 77127,
            "last_name": "Esteves",
            "photo_url": "",
            "first_name": "Amy"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 244927.64
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 46634.75
            }
        ]
    },
    {
        "client_id": 2145,
        "client_name": "Coca Cola",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 272318.43,
        "owner_poc_full_name": "Marquita Brown",
        "customer_poc_full_name": "Doug  Helland ",
        "owner_poc": {
            "id": 2133,
            "last_name": "Brown",
            "photo_url": "ce2d101e-d804-e495-ec01-1ce40770a9d8.png",
            "first_name": "Marquita"
        },
        "customer_poc": {
            "id": 87045,
            "last_name": "Helland ",
            "photo_url": "",
            "first_name": "Doug "
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 272318.43
            }
        ]
    },
    {
        "client_id": 75,
        "client_name": "AbbVie",
        "logo_url": "assets/c1633f981f03472ab425a5d5e7e47b15_ScreenShot20230127at8.06_t.57AM",
        "program_count": 1,
        "total_amount": 240195.71,
        "owner_poc_full_name": "Corey Wallace",
        "customer_poc_full_name": "Bob Sgaramella",
        "owner_poc": {
            "id": 80212,
            "last_name": "Wallace",
            "photo_url": "assets/f148fc1efb12467c87a8f0d536e8cea3_Profilepic1_t.jpg",
            "first_name": "Corey"
        },
        "customer_poc": {
            "id": 75361,
            "last_name": "Sgaramella",
            "photo_url": "",
            "first_name": "Bob"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 240195.71
            }
        ]
    },
    {
        "client_id": 315,
        "client_name": "Ford",
        "logo_url": "assets/1605311926.006089_Artboard11_t.png",
        "program_count": 1,
        "total_amount": 164166.54,
        "owner_poc_full_name": "Spencer Murphy",
        "customer_poc_full_name": "Derek Thompson",
        "owner_poc": {
            "id": 48206,
            "last_name": "Murphy",
            "photo_url": "assets/r55df84f754eb4290a2bbea4ca44780a5_SM_t.png",
            "first_name": "Spencer"
        },
        "customer_poc": {
            "id": 92544,
            "last_name": "Thompson",
            "photo_url": "",
            "first_name": "Derek"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 164166.54
            }
        ]
    },
    {
        "client_id": 63,
        "client_name": "Juniper",
        "logo_url": "assets/1605624533.566394_JN_t.png",
        "program_count": 4,
        "total_amount": 160026.83,
        "owner_poc_full_name": "Stuart Kelloff",
        "customer_poc_full_name": "Martha Loza",
        "owner_poc": {
            "id": 84308,
            "last_name": "Kelloff",
            "photo_url": "assets/r9caff6d0b6b04667bbfebdad11191ea7_StuKelloffsProfilePic_t.png",
            "first_name": "Stuart"
        },
        "customer_poc": {
            "id": 64124,
            "last_name": "Loza",
            "photo_url": "",
            "first_name": "Martha"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 146660.83
            },
            {
                "id": 5,
                "name": "Floor Care",
                "total_amount": 1998
            },
            {
                "id": 136,
                "name": "Event Support",
                "total_amount": 371
            },
            {
                "id": 138,
                "name": "General Maintenance",
                "total_amount": 10997
            }
        ]
    },
    {
        "client_id": 485,
        "client_name": "State Farm (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 119078.04,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Jay Morris",
        "owner_poc": null,
        "customer_poc": {
            "id": 54203,
            "last_name": "Morris",
            "photo_url": "",
            "first_name": "Jay"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 119078.04
            }
        ]
    },
    {
        "client_id": 803,
        "client_name": "Vantiva (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 110798.9,
        "owner_poc_full_name": "Jaime Sandoval Davila",
        "customer_poc_full_name": "lonnie davis",
        "owner_poc": {
            "id": 12740,
            "last_name": "Sandoval Davila",
            "photo_url": "assets/1581633638.6932049_JaimeSandovalAreaManager_t.jpg",
            "first_name": "Jaime"
        },
        "customer_poc": {
            "id": 70219,
            "last_name": "davis",
            "photo_url": "",
            "first_name": "lonnie"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 110798.9
            }
        ]
    },
    {
        "client_id": 33,
        "client_name": "GSK (CBRE)",
        "logo_url": "assets/1607961353.7332714_GSK_t.jpeg",
        "program_count": 4,
        "total_amount": 98955.79,
        "owner_poc_full_name": "Jennifer Defreese",
        "customer_poc_full_name": "Edward Harsen",
        "owner_poc": {
            "id": 12426,
            "last_name": "Defreese",
            "photo_url": "c1ef6a53-5ef1-f607-8140-fdbdf2504e65.png",
            "first_name": "Jennifer"
        },
        "customer_poc": {
            "id": 51519,
            "last_name": "Harsen",
            "photo_url": "",
            "first_name": "Edward"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 28184.5
            },
            {
                "id": 3,
                "name": "GMP",
                "total_amount": 37061.69
            },
            {
                "id": 6,
                "name": "Recycle/Diversion",
                "total_amount": 30895.85
            },
            {
                "id": 135,
                "name": "Lab Services",
                "total_amount": 2813.75
            }
        ]
    },
    {
        "client_id": 438,
        "client_name": "Pratt & Whitney (CBRE)",
        "logo_url": null,
        "program_count": 3,
        "total_amount": 95622.28,
        "owner_poc_full_name": "Angela Hileman",
        "customer_poc_full_name": "Brian Coleman",
        "owner_poc": {
            "id": 50588,
            "last_name": "Hileman",
            "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
            "first_name": "Angela"
        },
        "customer_poc": {
            "id": 46720,
            "last_name": "Coleman",
            "photo_url": "",
            "first_name": "Brian"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 92386.17
            },
            {
                "id": 5,
                "name": "Floor Care",
                "total_amount": 385.83
            },
            {
                "id": 172,
                "name": "Supplier Management Fee",
                "total_amount": 2850.28
            }
        ]
    },
    {
        "client_id": 90,
        "client_name": "Clorox (CBRE)",
        "logo_url": "assets/1605624405.6843212_506776b1f7de7bbe088bea1c0a78b345_t.jpg",
        "program_count": 2,
        "total_amount": 95442.51,
        "owner_poc_full_name": "Teodoro Mayorquin",
        "customer_poc_full_name": "Noemi Lopez",
        "owner_poc": {
            "id": 43756,
            "last_name": "Mayorquin",
            "photo_url": "assets/rd4e3af53a4c840108b684e90fac49b44_Tedspicture_t.png",
            "first_name": "Teodoro"
        },
        "customer_poc": {
            "id": 21346,
            "last_name": "Lopez",
            "photo_url": "",
            "first_name": "Noemi"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 90242.51
            },
            {
                "id": 5,
                "name": "Floor Care",
                "total_amount": 5200
            }
        ]
    },
    {
        "client_id": 1018,
        "client_name": "Chubb Insurance (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 89061.22,
        "owner_poc_full_name": "Yasmiry Duran",
        "customer_poc_full_name": "David Clement",
        "owner_poc": {
            "id": 6596,
            "last_name": "Duran",
            "photo_url": "assets/1602274287.711383_Yasmiry_t.jpg",
            "first_name": "Yasmiry"
        },
        "customer_poc": {
            "id": 71369,
            "last_name": "Clement",
            "photo_url": "",
            "first_name": "David"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 89061.22
            }
        ]
    },
    {
        "client_id": 281,
        "client_name": "ASML (CBRE)",
        "logo_url": "assets/1607359606.6159544_ASML_t.png",
        "program_count": 1,
        "total_amount": 87887.58,
        "owner_poc_full_name": "Jose De La Rosa",
        "customer_poc_full_name": "Anna  Aspegren",
        "owner_poc": {
            "id": 86586,
            "last_name": "De La Rosa",
            "photo_url": "assets/b410b7defaf74300a45a5d7613d4d26e_1000001389_t.jpg",
            "first_name": "Jose"
        },
        "customer_poc": {
            "id": 22229,
            "last_name": "Aspegren",
            "photo_url": "",
            "first_name": "Anna "
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 87887.58
            }
        ]
    },
    {
        "client_id": 273,
        "client_name": "Elanco (CBRE)",
        "logo_url": "assets/1607378591.6016922_Elanco_t.png",
        "program_count": 4,
        "total_amount": 66330.87,
        "owner_poc_full_name": "Arturo Vargas",
        "customer_poc_full_name": "Brad Morris",
        "owner_poc": {
            "id": 10657,
            "last_name": "Vargas",
            "photo_url": "assets/1601300828.6801038_Arthur_t.jpeg",
            "first_name": "Arturo"
        },
        "customer_poc": {
            "id": 75518,
            "last_name": "Morris",
            "photo_url": "",
            "first_name": "Brad"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 50791.87
            },
            {
                "id": 3,
                "name": "GMP",
                "total_amount": 13809
            },
            {
                "id": 6,
                "name": "Recycle/Diversion",
                "total_amount": 1270
            },
            {
                "id": 135,
                "name": "Lab Services",
                "total_amount": 460
            }
        ]
    },
    {
        "client_id": 352,
        "client_name": "Covestro (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 52119.94,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Arlene Smith",
        "owner_poc": null,
        "customer_poc": {
            "id": 68567,
            "last_name": "Smith",
            "photo_url": "",
            "first_name": "Arlene"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 52119.94
            }
        ]
    },
    {
        "client_id": 470,
        "client_name": "IQVIA (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 51474.71,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Amos King ",
        "owner_poc": null,
        "customer_poc": {
            "id": 91329,
            "last_name": "King ",
            "photo_url": "",
            "first_name": "Amos"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 51474.71
            }
        ]
    },
    {
        "client_id": 1648,
        "client_name": "TGS",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 42304.13,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 42304.13
            }
        ]
    },
    {
        "client_id": 504,
        "client_name": "Sikorsky (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 37985.51,
        "owner_poc_full_name": "Angela Hileman",
        "customer_poc_full_name": "Junior Belfort",
        "owner_poc": {
            "id": 50588,
            "last_name": "Hileman",
            "photo_url": "assets/42083c35b4dc4cb5b7f3eb63c42ced5a_Headshot1_t.JPG",
            "first_name": "Angela"
        },
        "customer_poc": {
            "id": 47432,
            "last_name": "Belfort",
            "photo_url": "",
            "first_name": "Junior"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 37985.51
            }
        ]
    },
    {
        "client_id": 921,
        "client_name": "Tenaya(CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 37024.13,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 37024.13
            }
        ]
    },
    {
        "client_id": 280,
        "client_name": "Adient",
        "logo_url": "assets/1605624157.877317_adientvectorlogosmall_t.png",
        "program_count": 1,
        "total_amount": 34229.42,
        "owner_poc_full_name": "Danielle Estes",
        "customer_poc_full_name": "Todd Battle",
        "owner_poc": {
            "id": 250,
            "last_name": "Estes",
            "photo_url": "assets/6421b2ed3f5044d186b81925b4e64487_Headshot_t.png",
            "first_name": "Danielle"
        },
        "customer_poc": {
            "id": 23832,
            "last_name": "Battle",
            "photo_url": "",
            "first_name": "Todd"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 34229.42
            }
        ]
    },
    {
        "client_id": 302,
        "client_name": "Firmenich SIngapore (CBRE)",
        "logo_url": "assets/1607378757.4569967_Firmenich_t.jpg",
        "program_count": 2,
        "total_amount": 30485,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 29270
            },
            {
                "id": 158,
                "name": "Pest Control",
                "total_amount": 1215
            }
        ]
    },
    {
        "client_id": 2154,
        "client_name": "Cigna (CBRE) - Sub",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 28861.23,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 22272.18
            },
            {
                "id": 278,
                "name": "Virus Response",
                "total_amount": 6589.05
            }
        ]
    },
    {
        "client_id": 269,
        "client_name": "Chemours (CBRE)",
        "logo_url": "assets/1607369039.649657_Chemours_t.png",
        "program_count": 1,
        "total_amount": 28606.35,
        "owner_poc_full_name": "Juan Rodriguez McLean",
        "customer_poc_full_name": "Guy Berg",
        "owner_poc": {
            "id": 4526,
            "last_name": "Rodriguez McLean",
            "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
            "first_name": "Juan"
        },
        "customer_poc": {
            "id": 21246,
            "last_name": "Berg",
            "photo_url": "",
            "first_name": "Guy"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 28606.35
            }
        ]
    },
    {
        "client_id": 252,
        "client_name": "SCB (SG) (CBRE)",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 20353.52,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 19695.52
            },
            {
                "id": 136,
                "name": "Event Support",
                "total_amount": 658
            }
        ]
    },
    {
        "client_id": 1649,
        "client_name": "Wells Fargo-Sub",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 17609.66,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 17609.66
            }
        ]
    },
    {
        "client_id": 34,
        "client_name": "IBM (SG) (CBRE)",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 15574.39,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 14871.89
            },
            {
                "id": 158,
                "name": "Pest Control",
                "total_amount": 702.5
            }
        ]
    },
    {
        "client_id": 2155,
        "client_name": "VMware (CBRE) (SG)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 15038,
        "owner_poc_full_name": "PHUA KOK YEOW",
        "customer_poc_full_name": "Jalynn Lim",
        "owner_poc": {
            "id": 36751,
            "last_name": "KOK YEOW",
            "photo_url": "assets/e3984c699ecc4526995acbdb85fecdb5_02D5BAF097844872996E08B9DF275309_t.jpeg",
            "first_name": "PHUA"
        },
        "customer_poc": {
            "id": 88629,
            "last_name": "Lim",
            "photo_url": "",
            "first_name": "Jalynn"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 15038
            }
        ]
    },
    {
        "client_id": 138,
        "client_name": "Sturges",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 14567.85,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 14567.85
            }
        ]
    },
    {
        "client_id": 1547,
        "client_name": "Collins Aerospace (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 13638.51,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 13638.51
            }
        ]
    },
    {
        "client_id": 311,
        "client_name": "Siemens (CBRE)",
        "logo_url": "assets/1607637318.9761856_Siemens_t.png",
        "program_count": 1,
        "total_amount": 8401,
        "owner_poc_full_name": "Juan Rodriguez McLean",
        "customer_poc_full_name": "Bob Flint",
        "owner_poc": {
            "id": 4526,
            "last_name": "Rodriguez McLean",
            "photo_url": "assets/r29386baf17e94d9bbf139df3bd037174_Picture1_t.png",
            "first_name": "Juan"
        },
        "customer_poc": {
            "id": 41003,
            "last_name": "Flint",
            "photo_url": "",
            "first_name": "Bob"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 8401
            }
        ]
    },
    {
        "client_id": 37,
        "client_name": "AT&T (CBRE)",
        "logo_url": "assets/1607367730.4943886_ATT_t.jpg",
        "program_count": 2,
        "total_amount": 6749,
        "owner_poc_full_name": null,
        "customer_poc_full_name": "Evita  Chandra",
        "owner_poc": null,
        "customer_poc": {
            "id": 45273,
            "last_name": "Chandra",
            "photo_url": "",
            "first_name": "Evita "
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 6549
            },
            {
                "id": 158,
                "name": "Pest Control",
                "total_amount": 200
            }
        ]
    },
    {
        "client_id": 477,
        "client_name": "Organon (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 5528.5,
        "owner_poc_full_name": "Justin Homa",
        "customer_poc_full_name": "William Huddleston",
        "owner_poc": {
            "id": 14268,
            "last_name": "Homa",
            "photo_url": "assets/1617302638.4097366_me_t.jpg",
            "first_name": "Justin"
        },
        "customer_poc": {
            "id": 79099,
            "last_name": "Huddleston",
            "photo_url": "",
            "first_name": "William"
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 5528.5
            }
        ]
    },
    {
        "client_id": 2126,
        "client_name": "DXC (CBRE)",
        "logo_url": null,
        "program_count": 1,
        "total_amount": 5060,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 5060
            }
        ]
    },
    {
        "client_id": 1317,
        "client_name": "Cornerstone (CBRE)",
        "logo_url": null,
        "program_count": 2,
        "total_amount": 4542.85,
        "owner_poc_full_name": null,
        "customer_poc_full_name": null,
        "owner_poc": null,
        "customer_poc": null,
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 4405.72
            },
            {
                "id": 147,
                "name": "Landscape",
                "total_amount": 137.13
            }
        ]
    },
    {
        "client_id": 328,
        "client_name": "GE (CBRE)",
        "logo_url": "assets/1607379294.1564417_GE_t.png",
        "program_count": 1,
        "total_amount": 2756.43,
        "owner_poc_full_name": "Spencer Murphy",
        "customer_poc_full_name": "Eric  Haynes",
        "owner_poc": {
            "id": 48206,
            "last_name": "Murphy",
            "photo_url": "assets/r55df84f754eb4290a2bbea4ca44780a5_SM_t.png",
            "first_name": "Spencer"
        },
        "customer_poc": {
            "id": 43586,
            "last_name": "Haynes",
            "photo_url": "",
            "first_name": "Eric "
        },
        "programs": [
            {
                "id": 1,
                "name": "Custodial",
                "total_amount": 2756.43
            }
        ]
    }
]}}/>
        </>
      )
    }
  }

    if (selection==="jll") {
        return (
        <ReactFullpage
          //fullpage options
          licenseKey={"LCHQH-KZTCJ-3R8KK-JGQJ6-VBLTJ"}
          scrollingSpeed={700} /* Options here */
          scrollBar={true}
          responsiveWidth={1000}
          slidesNavigation={true}
          normalScrollElements={"#modal, .normal-scroll"}
          credits={{ enabled: false, label: "" }}
          render={({ state, fullpageApi }) => {
         return (
              <ReactFullpage.Wrapper>
                <Section>
                  <div className="relative h-full w-full overflow-hidden flex flex-col items-start justify-center p-12">
                    <p className="monthly-tag text-blue-500 relative z-20">{Greeting()}</p>
                    <h1 className="monthly-title relative z-20">Let's take a look at<br/>November</h1>
                    <motion.img
                        initial={{
                            x: '30%',
                          y: '-30%',
                        }}
                        whileInView={{
                          opacity: 1,
                          rotate: 360,
                          scale: 1,
                          x: '30%',
                          y: '-30%',
                          transition: { duration: 600, ease: "linear", repeat: Infinity }}}
                    src="https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/twirl1.svg" alt="" className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2" />
                    <motion.img
                        initial={{
                            x: '-20%',
                          y: '30%',
                          opacity: 0.1,
                        }}
                        whileInView={{
                          opacity: 0.1,
                          rotate: 360,
                          scale: 1,
                          x: '-20%',
                          y: '30%',
                          transition: { duration: 900, ease: "linear", repeat: Infinity }}}
                    src="https://sbmqbrassets.s3-us-west-1.amazonaws.com/november/twirl2.svg" alt="" className="absolute bottom-0 left-0 opacity-0 h-[140vh]" />
                    {/* <span className="text-4xl highlight2 orange relative z-10">
                      Your facilities are evolving and so are we
                    </span> */}
                  </div>
                </Section>
                    {handleSelection(selection)}
              </ReactFullpage.Wrapper>
            )
          }}/> )} else if (selection === "cbre") {
        return (
            <MonthlySwitch show={show} data={{param:"hero"}} selection={"cbre"} showModal={showModal}/>
        )
    } else if (selection === "cbreLS") {
        return (
            <MonthlySwitch show={show} data={{param:"hero"}} selection={"cbreLS"} showModal={showModal}/>
        )
    }
}