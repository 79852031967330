import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import {motion} from 'framer-motion';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHelmetSafety} from '@fortawesome/free-solid-svg-icons'

export default function Recordables({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: false,
                width: '100%',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              min: 0,
              title: '',
              gridLineColor: "rgba(0,0,0,0.4)",
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                borderRadius: 5,
                crisp: true,
                groupPadding: 0.2,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: false,
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    function calendar() {
        let date = new Date('2023-11-01');
        let startDay = date.getDay();
        let daysInMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
        let recordableDays = [0];
        let translatedRecordableDays = recordableDays.map(d => d + startDay )
        // console.log(daysInMonth)
        return Array(daysInMonth + startDay).fill().map((_, i) => {
            return (<div className={` h-8 w-8 2xl:h-16 2xl:w-16 rounded-lg ${i >= startDay ? (translatedRecordableDays.some(function (el) {return el === i})) ? 'bg-rose-500' : 'bg-neutral-700' : ''}`}>
                
            </div>)
        })
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-rose-500">Recordables</p>
                    <p className="monthly-title">
                        SBM logged one recordable incident in November, maintaining our TRIR of 0.64.
                    </p>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                      <div className="p-6">
                          <div className="flex flex-row items-center gap-6">
                              <div className="h-16 w-16 2xl:w-20 2xl:h-20  rounded-full flex-shrink-0 bg-rose-500/20 text-rose-500 flex items-center justify-center ">
                                  <FontAwesomeIcon className={'text-2xl 2xl:text-4xl'} icon={faHelmetSafety} />
                              </div>
                          <div>
                          <p className="text-lg 2xl:text-xl font-bold text-white">Industry-Leading Safety</p>
                          <p className="text-base 2xl:text-lg text-white/70">While we're always in pursuit of a zero-harm work environment, we're proud to maintain a TRIR over <span className="text-rose-400 font-bold">4x lower</span> than the industry average.</p>
                          </div>
                          </div>


                      </div>


              </div>
                </div>
                <div className="w-1/2 flex items-center justify-center pl-6">
                    <div className="rounded-lg bg-neutral-800 overflow-hidden shadow-xl shadow-neutral-950/40">
                        <div className="">
                            <p className="text-center w-full p-4 text-2xl font-black bg-rose-500 text-neutral-900 uppercase">November, 2023</p>
                            <div className="grid grid-cols-7 gap-6 p-8">
                                {calendar()}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Section>
    )
}
  