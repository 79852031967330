import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faClipboardCheck} from '@fortawesome/free-solid-svg-icons'

export default function Audits({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: true,
                align: 'center',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
                labels: {
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "white",
                  },
              }
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              max: 100,
              min: 0,
              title: '',
              gridLineColor:'rgb(40, 48, 56)',
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            tooltip: {
              backgroundColor: "rgb(12, 15, 18)",
              outside: true,
              shadow: false,
              style: { color: "#ffffff", fontSize: '16px' },

          },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                borderRadius: 5,
                crisp: true,
                groupPadding: 0.2,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: false,
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-purple-500">Audits</p>
                    <p className="monthly-title">
                      Audit results remain consistent, reflecting improved calibration. 
                    </p>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                            <div className="p-6">
                                <div className="flex flex-row items-center gap-6">
                                    <div className="h-16 w-16 2xl:w-20 2xl:h-20 rounded-full flex-shrink-0 bg-blue-500/20 text-blue-500 flex items-center justify-center ">
                                        <FontAwesomeIcon className={'text-4xl'} icon={faClipboardCheck} />
                                    </div>
                                <div>
                                <p className="text-lg 2xl:text-xl font-bold text-white">Quality Success</p>
                                <p className="text-base 2xl:text-lg text-white/70">More than 98.3% of all SBM audits in November met or exceeded audit goals, with over 1,500 conducted throughout the month.</p>
                                </div>
                                </div>


                            </div>


                    </div>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                            <div className="p-6">
                                <div className="flex flex-row items-center gap-6">
                                    <div className="h-16 w-16 2xl:w-20 2xl:h-20 rounded-full flex-shrink-0 bg-[#A230D222] text-[#A230D2] flex items-center justify-center ">
                                        <FontAwesomeIcon className={'text-4xl'} icon={faClipboardCheck} />
                                    </div>
                                <div>
                                <p className="text-lg 2xl:text-xl font-bold text-white">Customer Audits</p>
                                <p className="text-base 2xl:text-lg text-white/70">We've continued to see an upward trend in customer audits, an almost 30% improvement over the past 6 months.</p>
                                </div>
                                </div>


                            </div>


                    </div>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <div className="w-full">
                        <Chart className="w-full" data={[
  {
    name: "Internal",
    data: [
      { name: "Jun", color: "#4AA8FF55", y: 84.74 },
      { name: "Jul", color: "#4AA8FF55", y: 84.72 },
      { name: "Aug", color: "#4AA8FF55", y: 86.25 },
      { name: "Sep", color: "#4AA8FF55", y: 87 },
      { name: "Oct", color: "#4AA8FF55", y: 86.99 },
      { name: "Nov", color: "#4AA8FF", y: 86.29 },
    ],
  },
  {
    name: "Joint",
    data: [
      { name: "Jun", color: "#734CFF55", y: 86.33},
      { name: "Jul", color: "#734CFF55", y: 86.49 },
      { name: "Aug", color: "#734CFF55", y: 87.29 },
      { name: "Sep", color: "#734CFF55", y: 87.06 },
      { name: "Oct", color: "#734CFF55", y: 88.68 },
      { name: "Nov", color: "#734CFF", y: 88 }
    ],
  },
  {
    name: "Customer",
    data: [
      { name: "Jun", color: "#A230D255", y: 67.22},
      { name: "Jul", color: "#A230D255", y: 0 },
      { name: "Aug", color: "#A230D255", y: 79.95 },
      { name: "Sep", color: "#A230D255", y: 83.47 },
      { name: "Oct", color: "#A230D255", y: 84.08 },
      { name: "Nov", color: "#A230D2", y: 87.29 }
    ],
  },
]} plotLines={[{value: 80, color: 'white', width: 4, zIndex: 4, }]}/>
                    </div>
                </div>
            </div>
        </Section>
    )
}
  