import { Section } from './monthly';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Swiper, SwiperSlide} from 'swiper/react';

import {motion} from 'framer-motion';

import { Pagination } from 'swiper';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight , faGraduationCap} from '@fortawesome/free-solid-svg-icons'

export default function Training({data}) {
    function Chart({data, plotLines}) {
        const options = {
            title: {
              text: "",
            },
            chart: {
              backgroundColor: "transparent",
              type: "column",
              spacing: [24, 24, 24, 24],
              height: '550px',
            },
            legend: {
                enabled: false,
                width: '100%',
                itemStyle: {
                    color: 'white',
                    fontSize: '16px !important'
                }
            },
            colors: [
              "#4BA8FF",
              "#734CFF",
              "#A230D2",
              "#DB33B6",
              "#FF7DB1",
              "#FF4332",
              "#FF6E19",
              "#C29200",
              "#C29200",
              "#02FA8E",
              "#02CCA6",
              "#02CCA6",
              "#48A4FA",
            ],
            credits: {
              enabled: false,
            },
            xAxis: {
                categories: ['Jun','Jul','Aug', 'Sep', 'Oct', 'Nov'],
            },
            yAxis: {
              gridLineDashStyle: "ShortDash",
              labels: {
                style: {
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "white",
                },
              },
              min: 0,
              title: '',
              gridLineColor: "rgba(0,0,0,0.4)",
              reversedStacks: false,
              scrollbar: { enabled: true },
              index: 0,
              plotLines: plotLines?.length ? plotLines : []
            },
            plotOptions: {
              column: {
                lineWidth: 2,
                allowPointSelect: false,
                showCheckbox: false,
                animation: { duration: 1000 },
                events: {},
                marker: null,
                point: { events: {} },
                dataLabels: {
                  align: null,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "contrast",
                    textOutline: "1px contrast",
                  },
                  verticalAlign: null,
                  x: 0,
                  y: null,
                  padding: 5,
                },
                cropThreshold: 50,
                pointRange: null,
                softThreshold: false,
                states: {
                  normal: { animation: true },
                  hover: {
                    animation: { duration: 50 },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                  },
                  select: { color: "#cccccc", borderColor: "#000000" },
                },
                borderRadius: 5,
                crisp: true,
                groupPadding: 0.2,
                pointPadding: 0.2,
                minPointLength: 3,
                startFromThreshold: true,
                tooltip: {},
                threshold: 0,
                borderWidth: 0,
                borderColor: "transparent",
                stacking: false,
              },
            },
            series: data,
          };

          return <HighchartsReact className="w-full" highcharts={Highcharts} options={options} />
    }

    return (
        <Section>
            <div className="h-full w-full p-12 flex flex-row">
                <div className="w-1/2 flex flex-col items-start justify-center">
                    <p className="monthly-tag text-blue-500">Training</p>
                    <p className="monthly-title">
                        93% of the 2,000+ associates assigned to JLL accounts are 100% compliant with their assigned trainings.
                    </p>
                    <div className="mt-8 rounded-xl bg-neutral-800 w-full">
                      
                      <div className="p-6">
                          <div className="flex flex-row items-center gap-6">
                              <div className="h-16 w-16 2xl:w-20 2xl:h-20 rounded-full flex-shrink-0 bg-blue-500/20 text-blue-500 flex items-center justify-center ">
                              <FontAwesomeIcon className="text-2xl 2xl:text-4xl" icon={faGraduationCap} />
                              </div>
                          <div>
                          <p className="text-lg 2xl:text-xl font-bold text-white">Custom Training</p>
                          <p className="text-base 2xl:text-lg text-white/70">More than 20 Original Courses have been developed and assigned specifically to our team members supporting our joint clients.</p>
                          </div>
                          </div>


                      </div>


              </div>
                </div>
                <div className="w-1/2 flex items-center justify-center pl-6">
                <motion.video
                  alt=""
                  className=" rounded-xl max-h-full mx-auto w-auto"
                  data-autoplay="true"
                  preload="auto"
                  playsInline
                  muted
                  loop
                  initial={{ opacity: 0, y: 200, scale:0.8 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    rotateY: '0',
                    scale: 1,
                    transition: { duration: 0.75, ease: "easeOut" },
                  }}
                >
                    <source src={'https://player.vimeo.com/progressive_redirect/playback/832711163/rendition/720p/file.mp4?loc=external&log_user=0&signature=395ca3fcda70059a1eddb4efc56979eef5a666f45d890b2857d0207100ffa2f8'} />
                    </motion.video>
                    {/* <video data-autoplay="true" loop muted playsInline className="w-full rounded-xl" src=""></video> */}
                </div>
            </div>
        </Section>
    )
}
  