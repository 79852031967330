import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ArrowButton from "../monthly/button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faCaretRight,
  faLocationDot, 
  faBuilding,
  faVectorSquare

} from "@fortawesome/free-solid-svg-icons";

import { cbreSOW } from "./data";


export default function SOW() {
    return (
<section className="relative w-full max-w-screen-2xl mx-auto py-24">
      <div className="relative flex flex-between w-full">
        <div className="w-full">
          <p className="newmonthly-tag text-primary-300 text-center">
            Managing Your Scope of Work
          </p>
          <p className="newmonthly-title mb-6 max-w-[32ch text-center">
            Unmatched confidence that<br/>every task is being performed.
            {/* <span>
              <ArrowButton
                label="View All Scorecards"
                href="https://app.4insite.com/site-scorecards"
                newTab={true}
              />
            </span> */}
          </p>
        </div>
      </div>
      <div className="w-full max-w-screen-xl mx-auto gap-6 ">
        <ClientList data={cbreSOW}/>
      </div>
    </section>
    )
}

function ClientList({ data, total }) {
    const [clientData, setClientData] = useState(cbreSOW || []);
    const [sortValue, setSortValue] = useState("client");
    const [sortDirection, setSortDirection] = useState("desc");
    function sortByValue(key) {
      let sortedData;
    
      if (sortValue === key) {
        if (sortDirection === "desc") {
          setSortDirection("asc");
          sortedData = [...clientData].sort((a, b) => {
            return typeof a[key] === 'number' ? a[key] - b[key] : b[key].localeCompare(a[key]);
          });
        } else {
          sortedData = [...clientData].sort((a, b) => {
            return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
          });
          setSortDirection("desc");
        }
      } else {
        sortedData = [...clientData].sort((a, b) => {
          return typeof a[key] === 'number' ? b[key] - a[key] : a[key].localeCompare(b[key]);
        });
        setSortDirection("desc");
        setSortValue(key);
      }
    
      setClientData(sortedData);
    }
  
    return (
      <div className="col-span-3 overflow-hidden flex flex-col items-start justify-start">
          <div className="w-full rounded-xl overflow-hidden new-linear">
        <div className="w-full rounded-t-xl grid grid-cols-8 ">
          <div className="flex items-center my-4 pl-4 col-span-2">
            <h3
              data-ds2="h3"
              onClick={() => {
                  sortByValue('client')
              }}
              className="cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
            >
              Client
              <span className="ml-2 w-6">
                <FontAwesomeIcon
                  icon={
                    sortValue !== "client"
                      ? faCaretRight
                      : sortDirection === "desc"
                      ? faCaretDown
                      : faCaretUp
                  }
                  className={`${
                    sortValue === "client"
                      ? "text-blue-400"
                      : "text-neutral-600 dark:text-neutral-500"
                  }  mr-2`}
                />
              </span>
            </h3>
          </div>
          <div className=" my-4 col-span-1">
            <h3
              data-ds2="h3"
              onClick={() => {
                sortByValue("routes");
              }} 
              className="whitespace-nowrap cursor-pointer text-center font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
            >
              Routes
              <span className="ml-2 w-4">
              <FontAwesomeIcon
                  icon={
                    sortValue !== "routes"
                      ? faCaretRight
                      : sortDirection === "desc"
                      ? faCaretDown
                      : faCaretUp
                  }
                  className={`${
                    sortValue === "routes"
                      ? "text-blue-400"
                      : "text-neutral-600 dark:text-neutral-500"
                  }  mr-2`}
                />
                </span>
            </h3>
          </div>
          <div className="my-4 col-span-2">
            <h3
              data-ds2="h3"
              onClick={() => {
                sortByValue("sow_coverage");
              }}
              className="whitespace-nowrap cursor-pointer text-center font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
            >

              SOW Coverage
              <span className="ml-2 w-4">
              <FontAwesomeIcon
                  icon={
                    sortValue !== "sow_coverage"
                      ? faCaretRight
                      : sortDirection === "desc"
                      ? faCaretDown
                      : faCaretUp
                  }
                  className={`${
                    sortValue === "sow_coverage"
                      ? "text-blue-400"
                      : "text-neutral-600 dark:text-neutral-500"
                  }  mr-2`}
                />
                </span>
            </h3>
          </div>
          <div className=" my-4 col-span-1">
            <h3
              data-ds2="h3"
              onClick={() => {
                sortByValue("average_audit");
              }}
              className="whitespace-nowrap text-center cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
            >
              Avg Audit
              <span className="ml-2 w-4">
                <FontAwesomeIcon
                  icon={
                    sortValue !== "average_audit"
                      ? faCaretRight
                      : sortDirection === "desc"
                      ? faCaretDown
                      : faCaretUp
                  }
                  className={`${
                    sortValue === "average_audit"
                      ? "text-blue-400"
                      : "text-neutral-600 dark:text-neutral-500"
                  }  mr-2`}
                />
              </span>
            </h3>
          </div>
          <div className=" justify-center my-4 col-span-2">
            <h3
              data-ds2="h3"
              onClick={() => {
                sortByValue("sow_audited");
              }}
              className="whitespace-nowrap text-center cursor-pointer font-roboto font-medium text-base leading-normal text-neutral-600 dark:text-neutral-500 "
            >
              SOW Audited
              <span className="ml-2 w-6">
                <FontAwesomeIcon
                  icon={
                    sortValue !== "sow_audited"
                      ? faCaretRight
                      : sortDirection === "desc"
                      ? faCaretDown
                      : faCaretUp
                  }
                  className={`${
                    sortValue === "sow_audited"
                      ? "text-blue-400"
                      : "text-neutral-600 dark:text-neutral-500"
                  }  mr-2`}
                />
              </span>
            </h3>
          </div>
        </div>
        <div className="h-1 w-full bg-primary-300 relative">
          <div className="absolute top-0 left-0 h-full w-full bg-primary-300 blur-sm"></div>
        </div>
        <div className=" w-full flex-grow flex flex-col items-around justify-evenly rounded-b-xl">
          {clientData.map((client, key) => {
            return <ClientRow client={client} key={client.client_id} index={key} total={total} />;
          })}
        </div> 
          </div>
      </div>
    );
  }
  
  function ClientRow({ client, index, total}) {
    const [showSites, setShowSites] = useState(false);
    function handleShow() {
      let toShow = !showSites;
      setShowSites(toShow);
    }
  
    function siteMap() {
      return client.sites.sort((a, b) => b.total_amount - a.total_amount).map((site, i) => {
          const date = new Date(site.site_start_date);
          const dateStr = date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              });
        return (
          <>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex flex-row items-center 2xl:py-2 pl-4 col-span-2">
              <div className="w-4 2xl:h-10 mr-2 flex-shrink-0"></div>
              <div className="overflow-hidden pr-4">
                <p className="text-white text-sm font-roboto font-bold leading-none truncate">
                  {site.site}
                </p>
                <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
                <FontAwesomeIcon icon={faBuilding} className="mr-1" /> Buildings: {site.buildings}  •  <FontAwesomeIcon icon={faVectorSquare} className="mr-1" />Areas: {site.buildings} 

                </p>
              </div>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center justify-center 2xl:py-2 col-span-1">
            <p className="text-center text-white">
            {site.routes || "--"}
        
            </p>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center justify-center 2xl:py-2 col-span-2">
            <div className="relative w-32 rounded-lg bg-black/20 h-6 overflow-hidden">
            <div className="h-6 bg-primary-700 rounded-lg" style={{
                width: `${site.sow_coverage}%`
            }}></div>
            <div className="absolute top-0 left-0 h-full w-full grid place-items-center">
                <p className="text-white font-bold">{site.sow_coverage}%</p>
            </div>
          </div>
            </div>
            <div className=" bg-black/20 border-b border-cyan-800/20 flex items-center justify-center 2xl:py-2 col-span-1">
            <p
          className={`w-16 py-1 text-center text-neutral-900 text-sm 2xl:text-lg font-black rounded-lg ${
            site.average_audit >= 3
              ? "bg-success"
              : "bg-failure"
          }`}
        >
          {site.average_audit || "--"}
        </p>
            </div>
            <div className={` bg-black/20 border-b border-cyan-800/20 flex items-center justify-center 2xl:py-2 col-span-2 `}>
            <div className="relative w-32 rounded-lg bg-black/20 h-6 overflow-hidden">
            <div className="h-6 bg-primary-700 rounded-lg" style={{
                width: `${site.sow_coverage}%`
            }}></div>
            <div className="absolute top-0 left-0 h-full w-full grid place-items-center">
                <p className="text-white font-bold">{site.sow_coverage}%</p>
            </div>
          </div>
            </div>
          </>
        );
      });
    }
  
    return (
      <div
        key={client.client_id}
        className="w-full grid grid-cols-8 group"
      >
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  group-hover items-center  2xl:py-2 pl-4 col-span-2">
          <FontAwesomeIcon onClick={handleShow} icon={showSites ? faCaretDown : faCaretRight} className="text-white cursor-pointer pr-2 py-2 w-4" />
          <button
            onClick={(el) => {
              handleShow();
            }}
          >
            {client.logo_url && client.logo_url.indexOf("https:") < 0 ? (
              <img
                src={"https://cdn.4insite.com/" + client.logo_url}
                className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
              />
            ) : client.logo_url && client.logo_url.indexOf("https:") > -1 ? (
              <img
                src={client.logo_url}
                className="h-6 w-6 p-1 2xl:w-10 2xl:h-10 rounded mr-2 object-contain bg-white"
              />
            ) : (
              <div className="h-6 w-6 2xl:w-10 2xl:h-10 rounded mr-2 bg-neutral-900 flex items-center justify-center text-xl text-white font-black">
                {client.client_name.slice(0, 1)}
              </div>
            )}
          </button>
          <div className="flex flex-col justify-center">
            <p className="text-white text-sm font-roboto font-bold leading-none mb-1">
              {client.client}
            </p>
            <p className="text-neutral-500 hidden 2xl:inline-block text-xs font-roboto font-medium leading-none">
              <FontAwesomeIcon icon={faLocationDot} className="mr-1" />
              {client.sites.length === 1
                ? "1 Site"
                : `${client.sites.length} Sites`}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center border-b border-cyan-800/20 group-hover:bg-cyan-700/10 2xl:py-2 col-span-1">
        <p className="text-center text-white">
            {client.routes || "--"}
            </p>
        </div>
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10 items-center justify-center 2xl:py-2 col-span-2">
          <div className="relative w-32 rounded-lg bg-black/20 h-6 overflow-hidden">
            <div className="h-6 bg-primary-700 rounded-lg" style={{
                width: `${client.sow_coverage}%`
            }}></div>
            <div className="absolute top-0 left-0 h-full w-full grid place-items-center">
                <p className="text-white font-bold">{client.sow_coverage}%</p>
            </div>
          </div>
        </div>
        <div className="flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10 items-center justify-center 2xl:py-2 col-span-1">
        <p
          className={`w-16 py-1 text-center text-neutral-900 text-sm 2xl:text-lg font-black rounded-lg ${
            client.average_audit >= 3
              ? "bg-success"
              : "bg-failure"
          }`}
        >
          {client.average_audit || "--"}
        </p>
        </div>
        <div className={`flex border-b border-cyan-800/20 group-hover:bg-cyan-700/10  items-center justify-center 2xl:py-2 col-span-2 `}>
        <div className="relative w-32 rounded-lg bg-black/20 h-6 overflow-hidden">
            <div className="h-6 bg-primary-700 rounded-lg" style={{
                width: `${client.sow_audited}%`
            }}></div>
            <div className="absolute top-0 left-0 h-full w-full grid place-items-center">
                <p className="text-white font-bold">{client.sow_audited}%</p>
            </div>
          </div>
        </div>
        {showSites && siteMap()}
      </div>
    );
  }