import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";

const clientHighlights = [
    {
        title: 'LAM Transition',
        description: ''
    },
    {
        title: 'Eli Lilly',
        description: ''
    },
    {
        title: 'Merck Customer Interface',
        description: ''
    },
]

const clientLowlights = [
    {
        title: 'Microsoft',
        description: 'Operations and Procurement are not aligned'
    },
    {
        title: 'Coca-Cola',
        description: ''
    }
]

export default function Highlights() {
  return (
    <section className="min-h-screen w-full max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center ">
      <p className="newmonthly-tag text-primary-300 text-center">
        Highlights & Lowlights
      </p>
      <p className="newmonthly-title text-center">
      Celebrating successes <br/>and surfacing challenges.
      </p>
      <div className="w-full grid grid-cols-2 gap-6">
        <div className="rounded-xl relative">
            <div className="relative h-full w-full rounded-xl new-linear z-10 p-8 overflow-hidden">
                <div className="h-2 w-full absolute top-0 left-0 bg-success mix-blend-screeen">
                </div>
                    <p className="text-2xl text-white font-extrabold">Our Strengths</p>
                    {clientHighlights.map((h,i) => {
                        return (
                            <>
                            <div className="flex flex-row gap-4 items-center justify-start my-4">
                                <div className="relative grid place-items-center">
                                    <div className="z-10 h-14 w-14 rounded-full bg-success grid place-items-center">
                                        <FontAwesomeIcon icon={faPlus} className="text-neutral-900 text-2xl "/>
                                    </div>
                                    <div className="z-0 absolute blur h-14 w-14 rounded-full bg-success/50 grid place-items-center"></div>

                                </div>
                                <div>
                                <p className="text-white font-extrabold text-xl">{h.title}</p>
                                <p className="text-white font-normal">{h.description}</p>
                                </div>
                            </div>
                            {i !== clientHighlights.length - 1 && <div className="w-3/4 mx-auto border-b border-neutral-600/40 border-dashed"></div>}
                            </>
                        )
                    })}
            </div>
        </div>
        <div className="rounded-xl relative">
            <div className="relative h-full w-full rounded-xl new-linear z-10 p-8 overflow-hidden">
                <div className="h-2 w-full absolute top-0 left-0 bg-failure mix-blend-screeen">
                </div>
                    <p className="text-2xl text-white font-extrabold">Our Challenges</p>
                    {clientLowlights.map((h,i) => {
                        return (
                            <>
                            <div className="flex flex-row gap-4 items-center justify-start my-4">
                                <div className="relative grid place-items-center">
                                    <div className="z-10 h-14 w-14 rounded-full bg-failure grid place-items-center">
                                        <FontAwesomeIcon icon={faPlus} className="text-neutral-900 text-2xl rotate-45 "/>
                                    </div>
                                    <div className="z-0 absolute blur h-14 w-14 rounded-full bg-failure/50 grid place-items-center"></div>

                                </div>
                                <div>
                                <p className="text-white font-extrabold text-xl">{h.title}</p>
                                <p className="text-white font-normal">{h.description}</p>
                                </div>
                            </div>
                            {i !== clientHighlights.length - 1 && <div className="w-3/4 mx-auto border-b border-neutral-600/40 border-dashed"></div>}
                            </>
                        )
                    })}
            </div>
        </div>
      </div>
    </section>
  );
}
