import {useRef} from 'react';
import { cbreRetention } from "./data";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/highcharts-more")(Highcharts);

const colors = [
    "#4BA8FF",
    "#734CFF",
    "#A230D2",
    "#DB33B6",
    "#FF7DB1",
    "#FF4332",
    "#FF6E19",
    "#C29200",
    "#E0C942",
    "#02FA8E",
    "#02CCA6",
    "#02CCA6",
    "#48A4FA",
  ];

  

export default function People({ setShow }) {
    const employeeContainer = useRef(null);

  return (
    <section className="min-h-screen w-full max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto py-24 flex flex-col items-center justify-center ">
      <p className="newmonthly-tag text-primary-300 text-center">Our People</p>
      <p className="newmonthly-title text-center">
      Our people-first culture yields tenured teams <br/>and turnover rates 7X better than the industry.
      </p>
      <div className="w-full gap-6">
        <div className="rounded-xl relative">
            <div className="relative h-full w-full rounded-xl new-linear z-10 p-8 overflow-hidden">
          {cbreRetention.tenureGroups.map((group, index) => {
            return (
                <div key={group.rangeTitle} className="flex flex-row divide-x-2 divide-white/20 mb-4 last:mb-0">
                  <div className="border-l-4 flex items-center  px-6 pr-12 w-80" style={{borderColor: colors[index]}}>
                    <p className="text-3xl text-white font-extrabold mb-2">
                      {group.rangeTitle}
                    </p>
                  </div>
                  <div className=" px-6 pr-12 flex-shrink-0">
                    <p className="font-bold text-white/60">Employees</p>
                    <div className="flex flex-row gap-4 items-baseline">
                    <p className="text-4xl font-normal text-white">
                      {group.total.toLocaleString("en-US")}
                    </p>
                    <div className="relative w-32 rounded-lg bg-black/20 h-6 overflow-hidden">
                        <div className="h-6  rounded-lg" style={{
                            width: `${group.percentage* 100}%`,
                            background: colors[index]
                        }}></div>
                        <div className="absolute top-0 left-0 h-full w-full grid place-items-center">
                            <p className="text-white font-bold">{group.percentage*100}%</p>
                        </div>
                    </div>
                    </div>
                    
                  </div>
                  <div ref={employeeContainer} className="relative flex-grow flex flex-row items-center pl-6 -mr-8 w-60">
                    {/* <p className="font-bold text-white/60">Recordables</p>
                    <p className="text-4xl font-normal text-white">
                      {group.percentage * 100}%
                    </p> */}
                    {group.employees.slice(0,21).map((employee, index2) => {
                      var photo = employee.person.photo_url;
                      if (photo.indexOf('_t') < 0 && photo.indexOf('asset') < 0) {
                        //replace last instance of . with _t.
                        var lastDot = photo.lastIndexOf('.');
                        photo = photo.slice(0, lastDot) + '_t' + photo.slice(lastDot);
                      }

                        return (
                            <div className={`relative h-12 w-10 flex-shrink-0 overflow-visible ${index2 >= 8 && 'hidden xl:block'} ${index2 >= 14 ? 'hidden 2xl:block' : ''}`} >
                                <img loading="lazy" src={"https://cdn.4insite.com/" + (employee.person.photo_url.indexOf('asset') >= 0 ? "" : "image/")+ photo} alt="" className="block flex-shrink-0 absolute top-0 left-0 rounded-full border-2 h-12 w-12 max-w-none object-cover" style={{borderColor: colors[index]}}/>
                            </div>
                        )
                    })}
                  </div>
                </div>
            );
        })}
        </div>
        </div>
      </div>
    </section>
  );
}

